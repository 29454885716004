import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography, Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  editNote,
  createNewNote,
  deleteNote,
  setNotesDialogOpen,
  setNotesDialogClosed
} from '../../../actions/psychologicalIndividualClassesActions';
import IndividualNote from './IndividualNote';
import NotesAndObservationsDialog from '../NotesAndObservationsDialog';
import { NoteModel } from '../../../models/psychological/notes/NoteModel';
import EmptyState from '../../common/EmptyState';
import { useIsSpecialist } from '../../../hooks/useIsSpecialist';

const IndividualNotesList = () => {
  const dispatch = useDispatch();

  const notes = useSelector((state) => state.psychologicalIndividualWorkProgram.notes);
  const Ui = useSelector((state) => state.psychologicalIndividualProgramUi.notes);
  const [currentNote, setCurrentNote] = useState(null);
  const assistanceId = useSelector((state) => state.psychologicalIndividualWorkProgram.id);
  const { isSpecialistOrPrincipal } = useIsSpecialist();

  const handleSave = (note) => {
    if (!!note.id) {
      dispatch(editNote(note, assistanceId));
    } else {
      dispatch(createNewNote(note, assistanceId));
    }
    setCurrentNote(null);
  };

  const handleDelete = (data) => {
    dispatch(deleteNote(data, assistanceId));
  };

  const handleNoteEdit = (note) => {
    if (note) {
      setCurrentNote(note);
    } else {
      setCurrentNote(new NoteModel());
    }
    dispatch(setNotesDialogOpen());
  };

  const handleCancel = () => {
    setCurrentNote(null);
    dispatch(setNotesDialogClosed());
  };

  return (
    <Box>
      <Paper sx={{ p: 1 }}>
        <Accordion sx={{ boxShadow: 0 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6">Notatki</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              {isSpecialistOrPrincipal && (
                <Button
                  variant="contained"
                  aria-label="dodaj"
                  sx={{ py: 1, lineHeight: 1 }}
                  onClick={() => handleNoteEdit()}>
                  dodaj
                </Button>
              )}
            </Box>
          </AccordionDetails>
          {!!notes?.length > 0 ? (
            notes?.map((note) => (
              <IndividualNote
                note={note}
                onEdit={(note) => handleNoteEdit(note)}
                onDelete={(note) => handleDelete(note)}
                key={`note-${note.id}`}
                canManage={isSpecialistOrPrincipal}
              />
            ))
          ) : (
            <EmptyState message="Brak" contrast />
          )}
        </Accordion>
      </Paper>
      {Ui.isEditing && (
        <NotesAndObservationsDialog
          handleCancel={handleCancel}
          selectedElement={currentNote}
          onSave={handleSave}
          type="notes"
          Ui={Ui}
        />
      )}
    </Box>
  );
};

export default IndividualNotesList;
