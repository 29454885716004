import { Box, Collapse, ListItem, ListItemText, Paper, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ActivitySwitch from '../../common/activities/ActivitySwitch';
import { useDispatch, useSelector } from 'react-redux';
import * as attendanceActions from '../../../actions/attendanceActions';
import FullCalendar from '../../common/calendar/FullCalendar';
import CalendarDay from '../../common/calendar/Grid/CalendarDay';
import moment from 'moment';
import AbsentDayContent from '../../common/children/attendance/calendar/AbsentDayContent';
import AttendantDayContent from '../../common/children/attendance/calendar/AttendantDayContent';
import CalendarDayContent from '../../common/calendar/Grid/CalendarDayContent';
import DayOffDayContent from '../../common/children/attendance/calendar/DayOffDayContent';
import InvalidDayContent from '../../common/children/attendance/calendar/InvalidDayContent';
import UndeclaredDayContent from '../../common/children/attendance/calendar/UndeclaredDayContent';
import EmptyState from '../../common/EmptyState';

const useStyles = makeStyles(() => ({
  listItemWithGap: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const ChildAttendanceActivitiesCard = (props) => {
  const { isInitiallyExpanded, pupil } = props;
  const cl = useStyles();
  const dispatch = useDispatch();
  const daysOff = useSelector((state) => state.configuration.daysOff);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [isOpen, setIsOpen] = useState(isInitiallyExpanded);
  const [selectedActivity, setSelectedActivity] = useState();
  const [calendar, setCalendar] = useState();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [rerender, setRerender] = useState(false);

  const includeMarkedDays = (day) => {
    const isAttendant = !!(day.droppedOffAt && day.pickedUpAt);
    const isInvalid = !!(day.droppedOffAt && !day.pickedUpAt);
    const isAbsent = !!(day.absenceFrom && day.absenceTo);
    const isUndeclared = !isAttendant && !isInvalid && !isAbsent;
    const dayOff = daysOff.find((d) => moment(d.date).format('YYYY-MM-DD') === moment(day.date).format('YYYY-MM-DD'));
    const isDayOff = !!dayOff;
    return {
      date: moment(day.date).format('YYYY-MM-DD'),
      data: {
        ...day,
        isAttendant,
        isInvalid,
        isAbsent,
        isUndeclared,
        isDayOff,
        dayOffName: isDayOff ? dayOff.name : ''
      }
    };
  };

  const handleReportAttendance = async (day) => {
    await dispatch(
      attendanceActions.reportChildActivityAttendanceAsync(
        pupil.id,
        selectedActivity.id,
        moment(day.date),
        moment(day.date)
      )
    );
    setRerender((prev) => !prev);
  };

  const handleReportAbsence = async (day) => {
    await dispatch(
      attendanceActions.reportChildActivityAbsenceAsync(
        pupil.id,
        selectedActivity.id,
        moment(day.date),
        moment(day.date)
      )
    );
    setRerender((prev) => !prev);
  };

  const handleDateChange = (date) => {
    setSelectedDate(moment(date));
  };

  const renderDayContent = (day, containerWidth, data) => {
    if (data.isAbsent) {
      return (
        <AbsentDayContent
          day={day}
          containerWidth={containerWidth}
          absenceNotes={data.absenceNotes}
          onReportAttendance={() => handleReportAttendance(day)}
          isActivityCalendar={true}
          isReadOnly={false}
          exceededAbsenceReportTime={!!day.data.violatedSchemeDeadlines.length}
          isWeekend={day.isWeekend}
        />
      );
    }
    if (data.isAttendant) {
      return (
        <AttendantDayContent
          day={day}
          containerWidth={containerWidth}
          onReportAttendance={handleReportAttendance}
          onReportAbsence={handleReportAbsence}
          isActivityCalendar={true}
          isReadOnly={false}
        />
      );
    }
    if (day.isWeekend || !day.isCurrentMonth) {
      return <CalendarDayContent day={day} containerWidth={containerWidth} />;
    }
    if (data.isDayOff) {
      return <DayOffDayContent day={day} containerWidth={containerWidth} dayOffName={data.dayOffName} />;
    }
    if (data.isInvalid) {
      return (
        <InvalidDayContent
          day={day}
          containerWidth={containerWidth}
          onReportAttendance={handleReportAttendance}
          onReportAbsence={handleReportAbsence}
          isReadOnly={false}
        />
      );
    }
    if (data.isUndeclared) {
      return (
        <UndeclaredDayContent
          day={day}
          containerWidth={containerWidth}
          onReportAttendance={handleReportAttendance}
          onReportAbsence={handleReportAbsence}
          isReadOnly={false}
          isActivityCalendar={true}
        />
      );
    }
    return <CalendarDayContent day={day} containerWidth={containerWidth} />;
  };

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelectActivity = (activity) => {
    setSelectedActivity(activity);
  };

  useEffect(() => {
    async function getCalendar() {
      setCalendar(
        await dispatch(
          attendanceActions.loadActivitiesAttendanceCalendarAsync(
            selectedActivity.id,
            pupil.id,
            moment(selectedDate).format('YYYY-MM')
          )
        )
      );
    }
    if (selectedActivity) {
      getCalendar();
    }
  }, [selectedActivity, selectedDate, rerender, dispatch, pupil.id]);

  return (
    <Paper elevation={1} sx={{ p: 1, mb: 2 }}>
      <ListItem
        button
        onClick={() => handleOpen()}
        className={cl.listItemWithGap}
        sx={{ display: 'flex', alignItems: 'center' }}>
        <ListItemText
          primary={
            <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
              Obecności na zajęciach dodatkowych
            </Typography>
          }
        />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen}>
        <ListItem
          className={cl.listItemWithGap}
          sx={{ overflow: 'auto', display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
          {!calendar && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mx: 'auto' }}>
              <EmptyState contrast message="Dla podanego dziecka nie zostały wybrane żadne zajęcia dodatkowe" />
            </Box>
          )}
          <ActivitySwitch
            contrast
            onSelectActivity={handleSelectActivity}
            childActivities={pupil.enrolledToActivities}
          />
        </ListItem>
        {calendar ? (
          <FullCalendar
            markedDays={calendar.entries.map((a) => includeMarkedDays(a))}
            onYearOrMonthSelected={(year, month) => handleDateChange(moment(new Date(year, month - 1, 1)))}
            onDaySelected={() => {}}
            onDayRender={(day, contentRender, onDaySelected, containerWidth, isDisabled) => (
              <CalendarDay
                clickable={false}
                extraClassName="no-hover"
                isDisabled={day.data.isDayOff || isDisabled}
                day={day}
                onDaySelected={() => {}}
                contentRender={contentRender}
                containerWidth={containerWidth}
              />
            )}
            onDayContentRender={(day, containerWidth) => renderDayContent(day, containerWidth, day.data)}
            defaultMonth={moment(selectedDate).format('MM')}
            defaultYear={moment(selectedDate).format('YYYY')}
          />
        ) : null}
      </Collapse>
    </Paper>
  );
};

ChildAttendanceActivitiesCard.propTypes = {
  isInitiallyExpanded: PropTypes.bool,
  pupil: PropTypes.object
};

export default ChildAttendanceActivitiesCard;
