import BaseModel from '../BaseModel';

export class VirtualDiscFolderModel extends BaseModel {
  constructor(id = null) {
    super();
    this.id = id;
    this.name = '';
    this.parentId = '';
    this.path = '';
    this.type = 'folder';
    this.color = '';
    this.createdAt = '';
    this.createdBy = '';
    this.uploadedBy = '';
    this.uploadedAt = '';
    this.size = null;
    this.shareForUserIds = [];
    this.shareForUserRoles = [];
    this.shareSettings = new ShareSettingsModel();
  }

  onAssignment(assigned) {
    assigned.shareForUserIds = assigned.shareSettings.shareForUserIds;
    assigned.shareForUserRoles = assigned.shareSettings.shareForUserRoles;
    assigned.uploadedBy = assigned.createdBy;
    assigned.uploadedAt = assigned.createdAt;
  }
}
export class ShareSettingsModel extends BaseModel {
  constructor() {
    super();
    this.sharedAt = null;
    this.shareForUserIds = [];
    this.shareForUserRoles = [];
    this.sharedBy = null;
  }
}
