import moment from 'moment';
import { BaseModelValidator } from '../../../../models/BaseModelValidator';
import { check } from '../../../../models/validation/validateHelper';

export class IPETValidator extends BaseModelValidator {
  constructor() {
    super();

    this.constraints = {
      educationConditionAdaptationTypeAndMethod: (value) =>
        check.isEmpty(value.content) && { content: 'Pole nie może być puste' },
      issueDate: (value) => check.isEmpty(value) && 'Nie podano daty wystawienia',
      name: (value) => check.isEmpty(value) && 'Nie podano nazwy',
      opinionNumber: (value) => check.isEmpty(value) && 'Nie podano numeru orzeczenia',
      parentSupportActions: (value, model) => {
        let sessionErrors = {};
        if (check.isEmpty(value.content)) sessionErrors.content = 'Pole nie może być puste';
        if (check.isEmpty(value.startDate)) sessionErrors.startDate = 'Nie podano daty rozpoczęcia';
        if (moment(value.startDate).isAfter(moment(model.parentSupportActions.endDate)))
          sessionErrors.startDate = 'Data rozpoczęcia nie może być późniejsza niż data zakończenia';

        if (check.isEmpty(value.endDate)) sessionErrors.endDate = 'Nie podano daty zakończenia';
        if (check.isEmpty(value.weeklyHours)) sessionErrors.weeklyHours = 'Nie podano wymiaru godzin';
        return Object.values(sessionErrors).length && sessionErrors;
      },
      programAdaptationScopeAndMethod: (value) =>
        check.isEmpty(value.content) && { content: 'Pole nie może być puste' },
      programDuration: (value) => check.isEmpty(value) && 'Nie podano czasu trwania programu',
      psychologicalCounselingRecommendations: (value) =>
        check.isEmpty(value.content) && { content: 'Pole nie może być puste' },
      teacherSpecialistCooperationScope: (value) =>
        check.isEmpty(value.content) && { content: 'Pole nie może być puste' },
      integratedActions: (value, model) => {
        let sessionErrors = {};
        if (check.isEmpty(value.content)) sessionErrors.content = 'Pole nie może być puste';
        if (check.isEmpty(value.startDate)) sessionErrors.startDate = 'Nie podano daty rozpoczęcia';
        if (moment(value.startDate).isAfter(moment(model.integratedActions.endDate)))
          sessionErrors.startDate = 'Data rozpoczęcia nie może być późniejsza niż data zakończenia';

        if (check.isEmpty(value.endDate)) sessionErrors.endDate = 'Nie podano daty zakończenia';
        if (check.isEmpty(value.weeklyHours)) sessionErrors.weeklyHours = 'Nie podano wymiaru godzin';
        return Object.values(sessionErrors).length && sessionErrors;
      },
      psychologicalSupport: (value, model) => {
        let sessionErrors = {};
        if (check.isEmpty(value.content)) sessionErrors.content = 'Pole nie może być puste';
        if (check.isEmpty(value.startDate)) sessionErrors.startDate = 'Nie podano daty rozpoczęcia';
        if (moment(value.startDate).isAfter(moment(model.psychologicalSupport.endDate)))
          sessionErrors.startDate = 'Data rozpoczęcia nie może być późniejsza niż data zakończenia';

        if (check.isEmpty(value.endDate)) sessionErrors.endDate = 'Nie podano daty zakończenia';
        if (check.isEmpty(value.weeklyHours)) sessionErrors.weeklyHours = 'Nie podano wymiaru godzin';
        return Object.values(sessionErrors).length && sessionErrors;
      },
      rehabilitationSessions: (value, model) => {
        let sessionErrors = {};
        if (check.isEmpty(value.content)) sessionErrors.content = 'Pole nie może być puste';
        if (check.isEmpty(value.startDate)) sessionErrors.startDate = 'Nie podano daty rozpoczęcia';
        if (moment(value.startDate).isAfter(moment(model.rehabilitationSessions.endDate)))
          sessionErrors.startDate = 'Data rozpoczęcia nie może być późniejsza niż data zakończenia';
        if (check.isEmpty(value.endDate)) sessionErrors.endDate = 'Nie podano daty zakończenia';
        if (check.isEmpty(value.weeklyHours)) sessionErrors.weeklyHours = 'Nie podano wymiaru godzin';
        return Object.values(sessionErrors).length && sessionErrors;
      }
    };
  }
}
