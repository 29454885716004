import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import EmptyState from '../EmptyState';

export const ApplicationItemsWrapper = (props) => {
  const { items, children } = props;
  if (items === null || items.length === 0) {
    return (
      <Box sx={{ p: 2 }}>
        <EmptyState message="Brak wniosków" noIcon={false} contrast />
      </Box>
    );
  }

  return children;
};

ApplicationItemsWrapper.propTypes = {
  items: PropTypes.array.isRequired,
  children: PropTypes.any
};
