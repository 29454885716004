import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@mui/material';

const Toggle = ({ onToggle, defaultToggled, label }) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <Switch checked={!!defaultToggled} onClick={(e) => onToggle(e, !defaultToggled)} name="label" color="primary" />
      }
    />
  );
};

Toggle.propTypes = {
  onToggle: PropTypes.func.isRequired,
  defaultToggled: PropTypes.any,
  label: PropTypes.string.isRequired
};

export default Toggle;
