import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as daycareSettingsActions from '../../../actions/daycareSettingsActions';
import { DaycareSettingsModel } from '../../../models/configuration/daycare/DaycareSettingsModels';

// v4
import { Box, List, ListItem, ListItemIcon, ListItemText, Switch, Typography } from '@mui/material';
import FieldsetWrapper from '../../forms/FieldsetWrapper';

class DaycareProperties extends React.Component {
  constructor() {
    super();
    this.state = {
      isUpdating: false
    };
    this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
  }

  async handleConfigurationChange(e, enabled) {
    this.setState({ isUpdating: true });
    const updatedSettings = new DaycareSettingsModel().assign(this.props.configuration);
    updatedSettings.propertiesDefinition[e.target.name] = enabled;
    await this.props.actions.updateConfiguration(updatedSettings);
    this.setState({ isUpdating: false });
  }

  render() {
    const properties = this.props.configuration.propertiesDefinition;
    return (
      <Box>
        <FieldsetWrapper
          component="span"
          legend="Opcje raportowania aktywności"
          invisible={false}
          sx={{ color: (theme) => theme.palette.color.contrast }}>
          <List>
            <ListItem>
              <ListItemIcon>
                <Switch
                  inputProps={{ 'aria-labelledby': 'mood' }}
                  edge="start"
                  color="primary"
                  sx={{ my: 1 }}
                  name="mood"
                  checked={properties.mood}
                  onClick={(e) => this.handleConfigurationChange(e, !properties.mood)}
                  disabled={this.state.isUpdating}
                />
              </ListItemIcon>
              <ListItemText
                id="mood"
                primary="Nastrój"
                secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.color.contrast } }}
                secondary="Pozwala na wybór nastroju dziecka w danym dniu"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Switch
                  inputProps={{ 'aria-labelledby': 'sleepTimeInMinutes' }}
                  edge="start"
                  color="primary"
                  sx={{ my: 1 }}
                  name="sleepTimeInMinutes"
                  checked={properties.sleepTimeInMinutes}
                  onClick={(e) => this.handleConfigurationChange(e, !properties.sleepTimeInMinutes)}
                  disabled={this.state.isUpdating}
                />
              </ListItemIcon>
              <ListItemText
                id="sleepTimeInMinutes"
                primary="Drzemka"
                secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.color.contrast } }}
                secondary="Pozwala na wprowadzenie czasu drzemki"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Switch
                  inputProps={{ 'aria-labelledby': 'meals' }}
                  edge="start"
                  color="primary"
                  sx={{ my: 1 }}
                  name="meals"
                  checked={properties.meals}
                  onClick={(e) => this.handleConfigurationChange(e, !properties.meals)}
                  disabled={this.state.isUpdating}
                />
              </ListItemIcon>
              <ListItemText
                id="meals"
                primary="Posiłki"
                secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.color.contrast } }}
                secondary="Pozwala na wybór posiłków zjedzonych w danym dniu"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Switch
                  inputProps={{ 'aria-labelledby': 'mealsNote' }}
                  edge="start"
                  color="primary"
                  sx={{ my: 1 }}
                  name="mealsNote"
                  checked={properties.mealsNote}
                  onClick={(e) => this.handleConfigurationChange(e, !properties.mealsNote)}
                  disabled={this.state.isUpdating}
                />
              </ListItemIcon>
              <ListItemText
                id="mealsNote"
                primary="Posiłki - opis"
                secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.color.contrast } }}
                secondary="Pozwala na wprowadzenie dodatkowe treści dotyczącej posiłków"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Switch
                  inputProps={{ 'aria-labelledby': 'nappies' }}
                  edge="start"
                  color="primary"
                  sx={{ my: 1 }}
                  name="nappies"
                  checked={properties.nappies}
                  onClick={(e) => this.handleConfigurationChange(e, !properties.nappies)}
                  disabled={this.state.isUpdating}
                />
              </ListItemIcon>
              <ListItemText
                id="nappies"
                primary="Pieluszki"
                secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.color.contrast } }}
                secondary="Pozwala na wybór ilości wykorzystanych pieluszek"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Switch
                  inputProps={{ 'aria-labelledby': 'poos' }}
                  edge="start"
                  color="primary"
                  sx={{ my: 1 }}
                  name="poos"
                  checked={properties.poos}
                  onClick={(e) => this.handleConfigurationChange(e, !properties.poos)}
                  disabled={this.state.isUpdating}
                />
              </ListItemIcon>
              <ListItemText
                id="poos"
                primary="Kupki"
                secondary="Pozwala na wybór ilości kupek"
                secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.color.contrast } }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Switch
                  inputProps={{ 'aria-labelledby': 'note' }}
                  edge="start"
                  color="primary"
                  sx={{ my: 1 }}
                  name="note"
                  checked={properties.note}
                  onClick={(e) => this.handleConfigurationChange(e, !properties.note)}
                  disabled={this.state.isUpdating}
                />
              </ListItemIcon>
              <ListItemText
                id="note"
                primary="Dodatkowa notatka"
                secondary="Pozwala na wprowadzenie dowolnej treści"
                secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.color.contrast } }}
              />
            </ListItem>
          </List>
        </FieldsetWrapper>
      </Box>
    );
  }
}

DaycareProperties.propTypes = {
  configuration: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    configuration: state.configuration.daycare
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(daycareSettingsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DaycareProperties);
