import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoginIcon from '@mui/icons-material/Login';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import * as entrancesActions from '../../../actions/entrancesActions';
import { Box, Button, List, ListItem, Paper } from '@mui/material';

import AttachmentIcon from '@mui/icons-material/Attachment';

//v4
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FilePickerWithRef from '../../common/FilePickerWithRef/FilePickerWithRef';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';

class WorkPlanPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleFilePicked = this.handleFilePicked.bind(this);
    this.handleDownloadFile = this.handleDownloadFile.bind(this);
    this.filePickerRef = React.createRef();
  }

  handleFilePicked(file) {
    this.props.actions.importEntrance(file);
  }

  handleDownloadFile(file) {
    const path = `${file.fileUrl}?name=${encodeURIComponent(file.name)}&mimeType=${encodeURIComponent(file.mimeType)}`;
    window.open(path, '_blank');
  }

  render() {
    return (
      <Box>
        <LoadingRenderWrapper>
          <Box>
            <PageHeaderText title="Wejścia" titleIcon={<LoginIcon />} />
            <ContentWrapper>
              <Paper sx={{ p: 2 }}>
                <List sx={{ p: 0 }}>
                  {this.props.entrances.map((entrance) => (
                    <ListItem
                      sx={{
                        '&:hover': {
                          background: (theme) => theme.palette.background.hover
                        }
                      }}
                      button
                      key={entrance.id}
                      onClick={() => this.handleDownloadFile(entrance)}
                      component="li"
                      aria-label={`Pobierz plik ${entrance.name} `}>
                      <ListItemIcon>
                        <AttachmentIcon />
                      </ListItemIcon>
                      <ListItemText primary={entrance.name} />
                    </ListItem>
                  ))}
                </List>
                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    variant="contained"
                    aria-label="Wgraj plik"
                    onClick={() => this.filePickerRef.current.open()}
                    sx={{ mb: 2 }}>
                    Wgraj plik
                  </Button>
                </Box>
              </Paper>
            </ContentWrapper>
          </Box>
        </LoadingRenderWrapper>
        <FilePickerWithRef ref={this.filePickerRef} onDrop={this.handleFilePicked} />
      </Box>
    );
  }
}

WorkPlanPage.propTypes = {
  groups: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  choosenGroup: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  entrances: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    groups: state.groups,
    ui: state.digitalDiary.workPlanUi,
    choosenGroup: state.digitalDiary.choosenGroup,
    entrances: state.configuration.entrances
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(entrancesActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkPlanPage);
