import React from 'react';
import PropTypes from 'prop-types';
import { Button, Fade, Alert, Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { useShareFileContext } from '../../context/ShareFileContext';

const SaveAndCancel = ({ onCancel }) => {
  const { isMobile, isPrivate, isForbidden } = useShareFileContext();

  const { errors } = useFormikContext();
  const error = errors.atLeastOneItem;
  const isError = !!error || isForbidden;
  const { isSubmitting } = useFormikContext();

  return (
    <Box
      sx={{
        p: 1,
        display: 'flex',
        justifyContent: isMobile ? 'center' : isError ? 'space-between' : 'flex-end',
        alignItems: 'center',
        flexWrap: isMobile ? 'wrap' : 'nowrap'
      }}>
      {isError && (
        <Fade in={true} timeout={1000}>
          <Alert sx={{ width: '100%' }} severity="error">
            {isForbidden ? 'Nie posiadasz uprawnień do zarządzania udostępnianiem jednego z wybranych plików' : error}
          </Alert>
        </Fade>
      )}
      <Box sx={{ display: 'flex', mt: isMobile ? 1 : undefined }}>
        <Button variant="outlinedContrast" sx={{ ml: 2 }} onClick={onCancel} disabled={isSubmitting}>
          anuluj
        </Button>

        <Button
          variant="contained"
          aria-label={isPrivate ? 'Zapisz' : 'Udostępnij'}
          type="submit"
          disabled={isSubmitting || isError}>
          {isPrivate ? 'Zapisz' : 'Udostępnij'}
        </Button>
      </Box>
    </Box>
  );
};

SaveAndCancel.propTypes = {
  error: PropTypes.string,
  onCancel: PropTypes.func
};

export default SaveAndCancel;
