import { FormControlLabel, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useField, useFormikContext } from 'formik';

const FormikCheckbox = ({ name, label, checkedIcon, uncheckedIcon, disabled, style, onChange }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const handleChange = (e) => {
    setFieldValue(name, e.target.checked);
  };

  const checkboxConfig = {
    ...field,
    onChange: onChange || handleChange,
    checked: field.value
  };

  const formControlConfig = {
    label,
    name,
    style,
    disabled
  };

  return (
    <FormControlLabel
      control={
        <Switch
          {...checkboxConfig}
          size="40"
          sx={{
            '& .MuiSwitch-track': { background: (theme) => theme.palette.grey[600], width: '172px !important' },
            '& .MuiSwitch-thumb': { color: (theme) => theme.palette.grey[600], width: '20px', height: '20px' }
          }}
        />
      }
      {...formControlConfig}
    />
  );
};

FormikCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  checkedIcon: PropTypes.element,
  uncheckedIcon: PropTypes.element,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  onChange: PropTypes.func
};

export default FormikCheckbox;
