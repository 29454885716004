'use strict';

import PropTypes from 'prop-types';

import React from 'react';
import { CalculationStatus } from '../../../constants/calculationStatus';
import FilterSelectField from './FilterSelectField';
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';

const StaffSettlementsFilters = ({ selectedStatus, handleStatusChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent={isMobile ? 'center' : 'flex-end'}
      alignItems="center"
      sx={{ mt: 2 }}>
      <FilterSelectField
        sx={{ width: { xs: '100%', sm: 250 }, my: 1 }}
        label="Status"
        onChange={handleStatusChange}
        items={Object.values(CalculationStatus)}
        selectedItem={selectedStatus}
      />
    </Box>
  );
};

StaffSettlementsFilters.propTypes = {
  selectedStatus: PropTypes.string,
  handleStatusChange: PropTypes.func.isRequired
};

export default StaffSettlementsFilters;
