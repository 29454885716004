import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addWopfu, editWopfu } from '../../../../actions/psychologicalActions';
import DialogForm from '../../../forms/DialogForm';
import WopfuForm from './WopfuForm';
import { WOPFUValidator } from './WOPFUValidator';

const WopfuDialog = ({ handleCancel, open, title, id, selected, isReadonly }) => {
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const Ui = useSelector((state) => state.psychologicalHelpUi.wopfu);
  const [errors, setErrors] = useState();

  const save = () => {
    if (!!errors || (errors?.errors && Object.values(errors?.errors).length)) {
      const preparedData = {
        name: data.name,
        issueDate: data.date,
        opinionNumber: data.number,
        individualDevelopmentAndEducationalNeeds: data.needs,
        studentStrengthsAndPreferences: data.strongSides,
        parentSupportScope: data.help,
        reasonsForEducationalFailures: data.failures,
        programEffectivenessAssessment: data.programGrade
      };
      selected ? dispatch(editWopfu(preparedData, selected.id, id)) : dispatch(addWopfu(preparedData, id));
    }
  };
  return (
    <DialogForm
      isEditing={Ui.isEditing}
      isProcessing={Ui.isProcessing}
      header={title}
      maxWidth="md"
      onSave={save}
      hideSubmit={isReadonly}
      onCancel={handleCancel}
      isDialogOpen={Ui.isEditing}
      onValidate={() => new WOPFUValidator().validate(data)}
      onValidationDone={(errors) => setErrors({ errors })}
      statePathToUi=""
      requiredFieldText>
      <WopfuForm setData={setData} selected={selected} isReadonly={isReadonly} errors={errors} />
    </DialogForm>
  );
};

export default WopfuDialog;
