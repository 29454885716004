import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import SelectField from '../../common/SelectField';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  MenuItem,
  Pagination,
  Typography,
  useMediaQuery
} from '@mui/material';
import DatePicker from '../../common/DatePicker';
import moment from 'moment';
import ClearButtonWrapper from '../../common/ClearButtonWrapper';
import { bindActionCreators } from 'redux';
import * as loginHistoryActions from '../../../actions/loginHistoryActions';
import loginHistoryApi from '../../../api/LoginHistoryApi';
import ContentWrapper from '../../common/contentWrapper';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';

const LoginHistoryPage = (props) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const numberArrayOfOptions = [5, 10, 25, 50];
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const loadEvents = (newRow, newPage) => {
    const params = {
      fetchCount: newRow,
      page: newPage
    };
    if (!!dateFrom) params.dateFrom = moment(dateFrom).format('YYYY-MM-DD');
    if (!!dateTo) params.dateTo = moment(dateTo).format('YYYY-MM-DD');
    props.actions.loadLoginHistoryAsync(params);
  };

  const handleChangePage = (event, newValue) => {
    setPage(newValue);
    loadEvents(rowsPerPage, newValue - 1);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(e.target.value);
    setPage(1);
    loadEvents(e.target.value, 0);
  };

  const handleFilter = () => {
    setPage(1);
    loadEvents(rowsPerPage, 0);
  };

  const storeBlobAs = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = filename;
    link.href = url;
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadFile = () => {
    const params = {};
    if (!!dateFrom) params.dateFrom = moment(dateFrom).format('YYYY-MM-DD');
    if (!!dateTo) params.dateTo = moment(dateTo).format('YYYY-MM-DD');
    loginHistoryApi.getLoginHistoryFile(params).then((res) => {
      storeBlobAs(res.response, 'Historia logowań uzytkowników.xml');
    });
  };

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title="Historia logowań użytkowników" titleIcon={<VpnKeyOutlinedIcon />} />
        <ContentWrapper>
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              lg={8}
              sx={{ pb: 2, display: 'flex', justifyContent: { xs: 'space-between', md: 'flex-start' } }}>
              <ClearButtonWrapper onClear={() => setDateFrom(null)}>
                <DatePicker
                  contrast
                  onChange={(val) => setDateFrom(val)}
                  value={dateFrom}
                  floatingLabelText="Logowania od"
                  name="timeFrom"
                />
              </ClearButtonWrapper>
              <ClearButtonWrapper onClear={() => setDateTo(null)}>
                <DatePicker
                  contrast
                  onChange={(val) => setDateTo(val)}
                  value={dateTo}
                  floatingLabelText="Logowania do"
                  name="timeTo"
                />
              </ClearButtonWrapper>
            </Grid>
            <Grid item xs={12} md={6} lg={4} sx={{ pb: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <SelectField
                sx={{
                  width: 88
                }}
                id="rowsPerPage"
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
                floatingLabelText="Wierszy na stronę">
                {numberArrayOfOptions.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </SelectField>
              <Button sx={{ ml: 2 }} variant="contained" aria-label="Filtruj" onClick={handleFilter}>
                Filtruj
              </Button>
            </Grid>
          </Grid>
          <Box>
            <Paper sx={{ p: 3 }}>
              <TableContainer>
                <Table aria-label="Historia logowania" sx={{ p: 2 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="tableHeader">Użytkownik</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="tableHeader">Data logowania</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.loginHistory.loginHistoryList.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:hover': {
                            background: (theme) => theme.palette.background.hover
                          }
                        }}>
                        <TableCell align="left">
                          <Typography variant="tableBodyCell">{row.username}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="tableBodyCell">
                            {moment(row.created_at).format('YYYY-MM-DD HH:mm')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    {props.loginHistory.loginHistoryList.length === 0 && (
                      <TableRow>
                        <TableCell>Brak wyników</TableCell>
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                siblingCount={isMobile ? 0 : 1}
                count={Math.ceil(props.loginHistory.rowNumber / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  my: 2
                }}
              />
            </Paper>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{ mt: 2 }}
                variant="outlined"
                aria-label="Pobierz historię logowań użytkowników (.XML)"
                onClick={handleDownloadFile}>
                Pobierz historię logowań (.XML)
              </Button>
            </Box>
          </Box>
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    loginHistory: state.loginHistory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(loginHistoryActions, dispatch)
  };
};

LoginHistoryPage.propTypes = {
  actions: PropTypes.object.isRequired,
  loginHistory: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginHistoryPage);
