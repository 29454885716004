/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function loginHistoryReducer(state = initialState.loginHistory, action) {
  switch (action.type) {
    case types.LOAD_LOGIN_HISTORY_SUCCESS: {
      return {
        loginHistoryList: [...action.loginHistory.data],
        rowNumber: parseInt(action.loginHistory.rowNumber, 10)
      };
    }
    default:
      return state;
  }
}
