import PropTypes from 'prop-types';
import { List, ListItem, ListItemAvatar, ListItemText, Typography, Paper, Box, Avatar, Grid } from '@mui/material';
import MealAvatar from './MealAvatar';
import EmptyState from '../common/EmptyState';
import { useCallback, useMemo, useState } from 'react';
import { browserHistory } from 'react-router';
import { fromTemplate, routePaths } from '../../routePaths';
import ActionConfirmationButton from '../forms/buttons/ActionConfirmationButton';
import { useDispatch } from 'react-redux';
import { deleteMealCategoryAsync, removeMealFromCategory } from '../../actions/mealCategoriesActions';

const MealsList = ({ meals, category, filterBy }) => {
  const choosedCategoryId = category.id;
  const handleMealClick = (mealId) => {
    browserHistory.push(fromTemplate(routePaths.mealDetails, [mealId]));
  };
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRemovingMeal, setIsRemovingMeal] = useState(false);

  const dispatch = useDispatch();

  const sortedAndFilteredMeals = useMemo(() => {
    if (!filterBy) {
      return meals.sort((a, b) => a.name.localeCompare(b.name));
    }

    const filteredMeals = meals.filter((meal) => meal.name.toLowerCase().includes(filterBy.toLowerCase()));
    return filteredMeals.sort((a, b) => a.name.localeCompare(b.name));
  }, [meals, filterBy]);

  const handleOnRemoveMealFromCategoryClick = useCallback(
    async (choosedCategoryId, mealId) => {
      try {
        setIsRemovingMeal(true);
        await dispatch(removeMealFromCategory(choosedCategoryId, mealId));
      } finally {
        setIsRemovingMeal(false);
      }
    },
    [dispatch]
  );

  const handleCategoryDelete = useCallback(
    async (categoryId) => {
      try {
        setIsDeleting(true);
        await dispatch(deleteMealCategoryAsync(categoryId));
      } finally {
        setIsDeleting(false);
      }
    },
    [dispatch]
  );

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', m: 2, mb: 0 }}>
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            alignItems: 'center',
            wordBreak: 'break-word',
            color: (theme) => theme.palette.color.primary,
            fontWeight: (theme) => theme.typography.fontWeightBold
          }}>
          {category.name}
        </Typography>
        <Avatar
          sx={{
            width: '25px',
            height: '25px',
            background: (theme) => theme.palette.background.transparent,
            color: (theme) => theme.palette.color.primary,
            opacity: 0.7,
            mx: 1,
            fontWeight: (theme) => theme.typography.fontWeightBold
          }}>
          {sortedAndFilteredMeals.length}
        </Avatar>
      </Box>
      <Paper sx={{ minHeight: 104 }}>
        {sortedAndFilteredMeals.length === 0 && meals.length !== 0 && (
          <EmptyState contrast message="Brak wyników wyszukiwania" />
        )}
        {meals.length === 0 && <EmptyState contrast message="Brak posiłków przypisanych do kategorii" />}
        {sortedAndFilteredMeals.length !== 0 && (
          <List sx={{ p: 2 }}>
            {sortedAndFilteredMeals.map((meal) => (
              <ListItem
                key={meal.id}
                sx={{
                  '&:hover': {
                    background: (theme) => theme.palette.background.hover
                  }
                }}>
                <Grid container>
                  <Grid
                    item
                    onClick={() => {
                      handleMealClick(meal.id);
                    }}
                    xs={12}
                    sm={6}
                    md={8}
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      '&:hover': {
                        cursor: 'pointer'
                      }
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemAvatar>
                        <MealAvatar meal={meal} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={meal.name}
                        primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
                        secondaryTypographyProps={{ color: (theme) => theme.palette.color.contrast }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center', sm: 'flex-end' },
                      alignItems: 'center'
                    }}>
                    <ActionConfirmationButton
                      actionLabel="odłącz od kategorii"
                      confirmationText={`Czy na pewno chcesz odłączyć posiłek ${meal.name} od kategorii ${category.name}?`}
                      confirmationTitle="Odłącz posiłek"
                      confirmLabel="odłącz"
                      variant="clearText"
                      onConfirm={() => {
                        handleOnRemoveMealFromCategoryClick(choosedCategoryId, meal.id);
                      }}
                      isLoading={isRemovingMeal}
                      onCancel={() => {}}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 1 }}>
        <ActionConfirmationButton
          actionLabel="usuń kategorię"
          confirmationText={`Czy na pewno chcesz usunąć kategorię ${category.name}?`}
          confirmationTitle="Usuń kategorię"
          confirmLabel="usuń"
          variant="outlined"
          isLoading={isDeleting}
          onConfirm={() => {
            handleCategoryDelete(category.id);
          }}
          onCancel={() => {}}
        />
      </Box>
    </>
  );
};

MealsList.propTypes = {
  meals: PropTypes.array.isRequired,
  category: PropTypes.object.isRequired,
  filterBy: PropTypes.string
};

export default MealsList;
