import React from 'react';
import PropTypes from 'prop-types';
import YearAndMonthOptionsSlider from '../../common/calendar/YearAndMonthOptionsSlider';
import SelectField from '../../common/SelectField';
import Checkbox from '../../common/Checkbox';
import { Alert, Box, Grid, Typography, MenuItem } from '@mui/material';

const SummaryFilters = ({
  isLoading,
  fromMonth,
  fromYear,
  toMonth,
  toYear,
  dueType,
  dontShowNegativeValues,
  totalPaymentsAmount,
  setFromMonth,
  setFromYear,
  setToMonth,
  setToYear,
  options,
  setDueType,
  setDontShowNegativeValues,
  setTotalPaymentsAmount,
  isError
}) => {
  return (
    <>
      <Typography variant="h6" sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
        Wybierz przedział czasowy
      </Typography>
      {isError && (
        <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, pb: 1 }}>
          <Alert
            variant="outlined"
            severity="error"
            sx={{ border: 'none', p: 0, color: (theme) => theme.palette.color.error }}>
            Niepoprawny przedział czasowy
          </Alert>
        </Box>
      )}
      <Grid container>
        <Grid item xs={12} md={6} xl={4} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
          <Box>
            <Typography component="label"> Początek:</Typography>
            <YearAndMonthOptionsSlider
              contrast
              onSelected={(nextYear, nextMonth) => {
                setFromMonth(nextMonth);
                setFromYear(nextYear);
              }}
              disabled={isLoading}
              defaultMonth={fromMonth}
              defaultYear={fromYear}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} xl={4} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
          <Box>
            <Typography component="label"> Koniec: </Typography>
            <YearAndMonthOptionsSlider
              contrast
              onSelected={(nextYear, nextMonth) => {
                setToMonth(nextMonth);
                setToYear(nextYear);
              }}
              disabled={isLoading}
              defaultMonth={toMonth}
              defaultYear={toYear}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          xl={4}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-start', xl: 'flex-end' },
            py: { xs: 1, lg: 0 },
            pt: { xs: 0, lg: 3 }
          }}>
          <SelectField
            contrast
            sx={{ mt: 0.5, minWidth: 150, maxWidth: 250 }}
            floatingLabelText="Rodzaj opłat"
            id="dueType"
            disabled={isLoading}
            value={dueType}
            onChange={(event) => setDueType(event.target.value)}>
            {options.map((type) => {
              return (
                <MenuItem key={type.value} value={type.value}>
                  {type.name}
                </MenuItem>
              );
            })}
          </SelectField>
        </Grid>
      </Grid>
      <Box sx={{ py: 1 }}>
        <Checkbox
          disabled={isLoading}
          label="Pokazuj kwoty zmniejszeń, odpisów oraz ulg bez minusów"
          defaultChecked={dontShowNegativeValues}
          onCheck={(event, isChecked) => setDontShowNegativeValues(isChecked)}
        />
      </Box>
      <Box sx={{ py: 1 }}>
        <Checkbox
          disabled={isLoading}
          label="Wyświetl ogólną sumę wpłat niezależnie od wybranego okresu"
          defaultChecked={totalPaymentsAmount}
          onCheck={(event, isChecked) => setTotalPaymentsAmount(isChecked)}
        />
      </Box>
    </>
  );
};

SummaryFilters.propTypes = {
  isLoading: PropTypes.bool,
  fromMonth: PropTypes.string,
  fromYear: PropTypes.string,
  toMonth: PropTypes.string,
  toYear: PropTypes.string,
  dueType: PropTypes.string,
  dontShowNegativeValues: PropTypes.bool,
  totalPaymentsAmount: PropTypes.bool,
  setFromMonth: PropTypes.func.isRequired,
  setFromYear: PropTypes.func.isRequired,
  setToMonth: PropTypes.func.isRequired,
  setToYear: PropTypes.func.isRequired,
  options: PropTypes.array,
  setDueType: PropTypes.func,
  setDontShowNegativeValues: PropTypes.func,
  setTotalPaymentsAmount: PropTypes.func,
  isError: PropTypes.bool
};

export default SummaryFilters;
