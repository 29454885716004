/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ReliefModel } from '../models/configuration/reliefs/ReliefModels';

export default function reliefsReducer(state = initialState.configuration.reliefs, action) {
  switch (action.type) {
    case types.LOAD_RELIEFS_SUCCESS: {
      return action.reliefs.map((relief) => new ReliefModel().assign(relief));
    }
    case types.CREATE_RELIEF_DETAILS_SUCCESS: {
      const relief = new ReliefModel().assign(action.relief);
      return [...state, relief];
    }
    case types.ARCHIVE_RELIEF_DETAILS_SUCCESS: {
      return [...state.filter((relief) => relief.id !== action.reliefId)];
    }
    default:
      return state;
  }
}
