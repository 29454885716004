import { useCallback, useState } from 'react';
import CardEditForm from '../forms/CardEditForm';
import { DietDetailsForm } from './DietDetailsForm';
import { DietModelValidator } from '../../models/meals/DietModelValidator';

export const DietDetailsCard = ({ diet, title, handleSave, onCancel, onValidate, isLoading }) => {
  const [errors, setErrors] = useState({});
  const [dietDetails, setDietDetails] = useState(diet);

  const handleChange = useCallback(({ target: { name, value } }) => {
    console.log(name, value);
    setDietDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  return (
    <CardEditForm
      onSave={() => handleSave(dietDetails)}
      onCancel={onCancel}
      onValidate={() => new DietModelValidator().validate(dietDetails)}
      onValidationDone={(errors) => setErrors(errors)}
      isProcessing={isLoading}
      isExpanded={true}
      onExpand={() => {}}
      onCollapse={() => {}}
      title={title}
      editForm={<DietDetailsForm diet={dietDetails} onChange={handleChange} errors={errors} />}
    />
  );
};
