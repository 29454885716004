import DeleteAvatar from './DeleteAvatar';
import NumberAvatar from './NumberAvatar';
import { Box } from '@mui/material';
import React from 'react';

const ParticipantsAvatarList = ({ recipients, selectedRecipients, onRemove }) => {
  return (
    <Box sx={{ display: 'flex', pt: 1.5 }}>
      {selectedRecipients.map((r, index) => {
        if (index < 6)
          return (
            <Box sx={{ mr: 1 }} key={r}>
              <DeleteAvatar
                onRemove={() => (onRemove ? onRemove(recipients.find((rec) => rec.id === r)) : undefined)}
                avatarStyles={{ width: 36, height: 36 }}
                title={recipients.find((u) => u.id === r).firstName + ' ' + recipients.find((u) => u.id === r).lastName}
                url={recipients.find((u) => u.id === r)?.photoUrl}
                lastName={recipients.find((u) => u.id === r).lastName}
                firstName={recipients.find((u) => u.id === r).firstName}
              />
            </Box>
          );
      })}
      {selectedRecipients.length > 6 && (
        <NumberAvatar
          sx={{ width: 32, height: 32, fontSize: 16 }}
          tooltipList={selectedRecipients
            .slice(6)
            .map(
              (r) => recipients.find((u) => u.id === r).firstName + ' ' + recipients.find((u) => u.id === r).lastName
            )}
        />
      )}
    </Box>
  );
};
export default ParticipantsAvatarList;
