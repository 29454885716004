import { routePaths } from '../../routePaths';
import { routePathActions } from '../../routePathActions';
import * as userRoles from '../../constants/userRoles';
import { featuresFromPrefixValidator } from '../../utils/featuresFromPrefixValidator';

const anonymousRole = 'anonymous';

const normalAuthMatrix = [
  { route: routePaths.signIn, allowed: [anonymousRole] },
  { route: routePaths.pinSignIn, allowed: [anonymousRole] },
  { route: routePaths.accountActivation, allowed: [anonymousRole] },
  { route: routePaths.passwordExpired, allowed: [anonymousRole] },
  { route: routePaths.passwordRecovery, allowed: [anonymousRole] },
  { route: routePaths.passwordReset, allowed: [anonymousRole] },
  { route: routePaths.loginGov, allowed: [anonymousRole] },
  {
    route: routePaths.statuteChanged,
    allowed: [
      userRoles.legalGuardian,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberCanteenWorker,
      userRoles.staffMemberDigitalDiary,
      userRoles.staffMemberTeacherSupport,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.staffMessageBoard,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberCanteenWorker,
      userRoles.staffMemberDigitalDiary,
      userRoles.staffMemberTeacherSupport,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.home,
    allowed: [
      userRoles.legalGuardian,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberCanteenWorker,
      userRoles.staffMemberDigitalDiary,
      userRoles.staffMemberTeacherSupport,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.userProfile,
    allowed: [
      userRoles.legalGuardian,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberCanteenWorker,
      userRoles.staffMemberTeacherSupport,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.groups,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.groupsArchive,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.groupRegister,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.groupDetails,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.groupArchiveDetails,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.activities,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberDigitalDiary
    ]
  },
  {
    route: routePaths.activitiesArchive,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary
    ]
  },
  {
    route: routePaths.activityRegister,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary
    ]
  },
  {
    route: routePaths.activityDetails,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberDigitalDiary
    ]
  },
  {
    route: routePaths.activityArchiveDetails,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary
    ]
  },
  {
    route: routePaths.children,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberTeacherSupport,
      userRoles.staffMemberSpecialist
    ],
    actions: [
      {
        action: routePathActions.children.registerChild,
        allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
      }
    ]
  },
  {
    route: routePaths.childrenArchive,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacherSupport,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.childRegister,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary
    ]
  },
  {
    route: routePaths.childDetails,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacherSupport,
      userRoles.staffMemberSpecialist
    ],
    actions: [
      {
        action: routePathActions.childDetails.viewChargingSchemesSection,
        allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
      }
    ]
  },
  {
    route: routePaths.meals,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.mealRegister,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.mealDetails,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.mealsArchive,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.diets,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.createDiet,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.dietDetails,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.childFinancialDetails,
    allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.staffFinancialDetails,
    allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.childSummary,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.childArchiveDetails,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.legalGuardians,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary],
    actions: [
      {
        action: routePathActions.legalGuardians.restartActivation,
        allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
      },
      {
        action: routePathActions.legalGuardians.deleteLegalGuardianAccount,
        allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
      },
      {
        action: routePathActions.legalGuardians.resetPassword,
        allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
      }
    ]
  },
  {
    route: routePaths.staffMembers,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.staffMembersArchive,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.staffMemberRegister,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.staffMemberDetails,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.messageBoard,
    allowed: [
      userRoles.legalGuardian,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberDigitalDiary,
      userRoles.staffMemberTeacherSupport
    ],
    actions: [
      {
        action: routePathActions.messageBoard.createPost,
        allowed: [
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberAccountant,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacherSupport
        ]
      },
      {
        action: routePathActions.messageBoard.editPost,
        allowed: [
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberAccountant,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacherSupport
        ]
      },
      {
        action: routePathActions.messageBoard.viewAllPosts,
        allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
      }
    ]
  },
  {
    route: routePaths.messageBoardEvents,
    allowed: [
      userRoles.legalGuardian,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacherSupport
    ],
    actions: [
      {
        action: routePathActions.messageBoard.editPost,
        allowed: [
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberAccountant,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacherSupport
        ]
      },
      {
        action: routePathActions.messageBoard.viewAllPosts,
        allowed: [
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberAccountant,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacherSupport
        ]
      },
      {
        action: routePathActions.messageBoard.editPost,
        allowed: [
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberAccountant,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacherSupport
        ]
      }
    ]
  },
  {
    route: routePaths.eventsCalendar,
    allowed: [
      userRoles.legalGuardian,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacherSupport
    ],
    actions: [
      {
        action: routePathActions.eventsCalendar.createEvent,
        allowed: [
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberAccountant,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacherSupport
        ]
      },
      {
        action: routePathActions.eventsCalendar.editEvent,
        allowed: [
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberAccountant,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacherSupport
        ]
      }
    ]
  },
  {
    route: routePaths.attendance,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.absentTomorrow,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.attendanceMonthlyHistory,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.attendanceDailyHistory,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.activitiesAttendanceSheetPage,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberDigitalDiary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.returnFromOnlinePayments,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.guardianDues,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.guardianDueDetails,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.guardianChildDetails,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.configurationUnit,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.configurationDaysOff,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
  },
  {
    route: routePaths.configurationChargingSchemes,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.configurationReliefs,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.configurationReports,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.configurationAttributesSets,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
  },
  {
    route: routePaths.configurationGuardiansPinService,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
  },
  {
    route: routePaths.configurationQrCodeService,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
  },
  {
    route: routePaths.configurationDaycare,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
  },
  {
    route: routePaths.configurationSettlementsSettings,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.numberingSchemes,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.settlements,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.settlementsExport,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.settlementsGroup,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.settlementsGroupDetails,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.childDueDetails,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.staffMembersSettlements,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.reports,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberCanteenWorker,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.configurationNumberingSchemes,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.guardianChildAttendance,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.privateMessages,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.groupMessages,
    allowed: [
      userRoles.legalGuardian,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.staffMemberPrivateMessages,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacherSupport
    ],
    actions: [
      {
        action: routePathActions.privateMessages.sendToLegalGuardians,
        allowed: [
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberAccountant,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacherSupport
        ]
      },
      {
        action: routePathActions.privateMessages.sendToStaffMembers,
        allowed: [
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacherSupport
        ]
      }
    ]
  },
  {
    route: routePaths.guardianPrivateMessages,
    allowed: [userRoles.legalGuardian]
  },
  { route: routePaths.pinKeypad, allowed: [userRoles.pinDevice] },
  { route: routePaths.pinEntries, allowed: [userRoles.pinDevice] },
  {
    route: routePaths.daycare,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.electronicDiaryTimeTable,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberDigitalDiary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.electronicDiaryTrip,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberDigitalDiary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.electronicDiaryAdditionalActivities,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberDigitalDiary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.electronicDiaryWorkWithParents,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.electronicDiarySaveWork,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.electronicDiaryWorkPlan,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberDigitalDiary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.electronicDiaryFrameworkDaySchedule,
    allowed: [
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberDigitalDiary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.configurationDiagnosisTemplate,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
  },
  {
    route: routePaths.electronicDiaryMessageBoard,
    allowed: [
      userRoles.legalGuardian,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberDigitalDiary,
      userRoles.staffMemberTeacherSupport
    ],
    actions: [
      {
        action: routePathActions.messageBoard.createPost,
        allowed: [
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberAccountant,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberDigitalDiary,
          userRoles.staffMemberTeacherSupport
        ]
      },
      {
        action: routePathActions.messageBoard.editPost,
        allowed: [
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberAccountant,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberDigitalDiary,
          userRoles.staffMemberTeacherSupport
        ]
      },
      {
        action: routePathActions.messageBoard.viewAllPosts,
        allowed: [
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberAccountant,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacherSupport
        ]
      }
    ]
  },
  {
    route: routePaths.entrances,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
  },
  {
    route: routePaths.fkExport,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.symphonyExport,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.settlementsImport,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.guardianApplications,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.applications,
    allowed: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.events,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.changeLog,
    allowed: [
      userRoles.legalGuardian,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberCanteenWorker,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.authorizedBySign,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.childrenOnVacationPeriod,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
  },
  {
    route: routePaths.loginHistory,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.currentUsers,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.overdueList,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.overpaymentsList,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant]
  },
  {
    route: routePaths.expiringReliefs,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
  },
  {
    route: routePaths.takenOverByDebtCollection,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberAccountant, userRoles.staffMemberSecretary]
  },
  {
    route: routePaths.userManual,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.gallery,
    allowed: [
      userRoles.legalGuardian,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberCanteenWorker
    ]
  },
  {
    route: routePaths.staffCalendar,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberCanteenWorker,
      userRoles.staffMemberDigitalDiary,
      userRoles.staffMemberTeacherSupport
    ]
  },
  {
    route: routePaths.staffCalendarDetails,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberCanteenWorker,
      userRoles.staffMemberDigitalDiary
    ]
  },
  {
    route: routePaths.scheduledTransfer,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.virtualDisc,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberDigitalDiary,
      userRoles.staffMemberCanteenWorker,
      userRoles.legalGuardian
    ]
  },
  {
    route: routePaths.authorizedToPickUpChild,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher
    ]
  },
  {
    route: routePaths.mobileDownload,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.psychologicalHelp,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.psychologicalSchemes,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.psychologicalHelpChild,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.psychologicalGroups,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.psychologicalGroupDetails,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.individualClasses,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.individualClassesDetails,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.individualClassesEventsDetails,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.groupClasses,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.individualClassesJournal,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.individualClassesJournalDetails,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.individualClassesJournalEventDetails,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.groupClassesEventsDetails,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.specialistNotes,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberSpecialist, userRoles.staffMemberTeacher]
  },
  {
    route: routePaths.specialistNotesDetails,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberSpecialist, userRoles.staffMemberTeacher]
  },
  {
    route: routePaths.specialistClassScheduleDetails,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberSpecialist, userRoles.staffMemberTeacher]
  },
  {
    route: routePaths.specialistGroupActivitiesDetails,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberSpecialist, userRoles.staffMemberTeacher]
  },
  {
    route: routePaths.specialistWorkPlanProgressDetails,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberSpecialist, userRoles.staffMemberTeacher]
  },
  {
    route: routePaths.groupDiaryEventsDetails,
    allowed: [
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberAccountant,
      userRoles.staffMemberSecretary,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSpecialist
    ]
  },
  {
    route: routePaths.childrenImport,
    allowed: [userRoles.staffMemberPrincipal]
  }
];
const reducedAuthMatrix = [
  { route: routePaths.signIn, allowed: [anonymousRole] },
  { route: routePaths.pinSignIn, allowed: [anonymousRole] },
  { route: routePaths.accountActivation, allowed: [anonymousRole] },
  { route: routePaths.passwordExpired, allowed: [anonymousRole] },
  { route: routePaths.passwordRecovery, allowed: [anonymousRole] },
  { route: routePaths.passwordReset, allowed: [anonymousRole] },
  { route: routePaths.loginGov, allowed: [anonymousRole] },
  {
    route: routePaths.messageBoard,
    allowed: [userRoles.legalGuardian, userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal],
    actions: [
      {
        action: routePathActions.messageBoard.createPost,
        allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
      },
      {
        action: routePathActions.messageBoard.editPost,
        allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
      },
      {
        action: routePathActions.messageBoard.viewAllPosts,
        allowed: [userRoles.staffMemberPrincipal]
      }
    ]
  },
  {
    route: routePaths.statuteChanged,
    allowed: [userRoles.legalGuardian, userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.eventsCalendar,
    allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal, userRoles.legalGuardian],
    actions: [
      {
        action: routePathActions.eventsCalendar.createEvent,
        allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
      },
      {
        action: routePathActions.eventsCalendar.editEvent,
        allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
      }
    ]
  },
  {
    route: routePaths.messageBoardEvents,
    allowed: [userRoles.legalGuardian, userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal],
    actions: [
      {
        action: routePathActions.messageBoard.editPost,
        allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
      },
      {
        action: routePathActions.messageBoard.viewAllPosts,
        allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
      },
      {
        action: routePathActions.messageBoard.editPost,
        allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
      }
    ]
  },
  {
    route: routePaths.home,
    allowed: [userRoles.legalGuardian, userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.userProfile,
    allowed: [userRoles.legalGuardian, userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.student,
    allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal],
    actions: [
      {
        action: routePathActions.student.registerStudent,
        allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberTeacher]
      }
    ]
  },
  {
    route: routePaths.groups,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberTeacher, userRoles.staffMemberSpecialist]
  },
  {
    route: routePaths.groupsArchive,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberTeacher, userRoles.staffMemberSpecialist]
  },
  {
    route: routePaths.groupRegister,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberTeacher, userRoles.staffMemberSpecialist]
  },
  {
    route: routePaths.groupDetails,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberTeacher, userRoles.staffMemberSpecialist]
  },
  {
    route: routePaths.groupArchiveDetails,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberTeacher, userRoles.staffMemberSpecialist]
  },
  {
    route: routePaths.studentArchive,
    allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.studentRegister,
    allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.studentDetails,
    allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.childFinancialDetails,
    allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.staffFinancialDetails,
    allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.childSummary,
    allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.childArchiveDetails,
    allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.staffMembers,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.staffMembersArchive,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.staffMemberRegister,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.staffMemberDetails,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.guardianChildDetails,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.configurationUnit,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.configurationDaysOff,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.numberingSchemes,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.childDueDetails,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.reports,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberTeacher]
  },
  {
    route: routePaths.privateMessages,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.staffMemberPrivateMessages,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberTeacher],
    actions: [
      {
        action: routePathActions.privateMessages.sendToLegalGuardians,
        allowed: [userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
      },
      {
        action: routePathActions.privateMessages.sendToStaffMembers,
        allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberTeacher]
      }
    ]
  },
  {
    route: routePaths.guardianPrivateMessages,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.symphonyExport,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.fkExport,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.entrances,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.guardianApplications,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.applications,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.events,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.authorizedBySign,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.loginHistory,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.currentUsers,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.userManual,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.gallery,
    allowed: [userRoles.legalGuardian, userRoles.staffMemberTeacher, userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.staffCalendar,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberTeacher]
  },
  {
    route: routePaths.staffMessageBoard,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberTeacher]
  },
  {
    route: routePaths.staffCalendarDetails,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberTeacher]
  },
  {
    route: routePaths.virtualDisc,
    allowed: [userRoles.staffMemberPrincipal, userRoles.staffMemberTeacher, userRoles.legalGuardian]
  },
  {
    route: routePaths.mobileDownload,
    allowed: [userRoles.legalGuardian]
  },
  {
    route: routePaths.childrenImport,
    allowed: [userRoles.staffMemberPrincipal]
  },
  // Dodane przy dziennikach specjalistycznych

  {
    route: routePaths.psychologicalGroups,
    allowed: [userRoles.staffMemberPrincipal]
  },

  // {
  //   route: routePaths.individualClasses,
  //   allowed: [userRoles.staffMemberPrincipal]
  // },
  // {
  //   route: routePaths.individualClassesChild,
  //   allowed: [userRoles.staffMemberPrincipal]
  // },
  {
    route: routePaths.groupClasses,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.individualClassesJournal,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.individualClassesJournalDetails,
    allowed: [userRoles.staffMemberPrincipal]
  },
  {
    route: routePaths.individualClassesJournalEventDetails,
    allowed: [userRoles.staffMemberPrincipal]
  },

  {
    route: routePaths.specialistNotes,
    allowed: [userRoles.staffMemberPrincipal]
  }
];

/* eslint no-console:0 */
export const verifyAuth = (state, matchedRoute) => {
  if (matchedRoute === routePaths.unmatched) return false;
  const { auth, configuration } = state;
  const authMatrix = configuration.unit.features.nadarzyn ? reducedAuthMatrix : normalAuthMatrix;

  const authRecord = authMatrix.find((a) => a.route === matchedRoute);
  if (!authRecord) {
    console.warn(`Authorization matrix is not configured for ${matchedRoute}`);
    return false;
  }
  const role = auth.signedIn ? auth.userRole : anonymousRole;
  return authRecord.allowed.includes(role);
};

export function getAllowedActions(route, role) {
  if (route === routePaths.unmatched) return [];

  const features = featuresFromPrefixValidator();

  const authMatrix = features ? reducedAuthMatrix : normalAuthMatrix;

  const authRecord = authMatrix.find((record) => record.route === route);
  if (!authRecord) {
    console.warn(`Authorization matrix is not configured for ${route}`);
    return [];
  }

  return authRecord.actions ? authRecord.actions.filter((a) => a.allowed.includes(role)).map((a) => a.action) : [];
}
