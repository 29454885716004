import PropTypes from 'prop-types';
import React from 'react';
import {
  List,
  ListItemSecondaryAction,
  ListItemText,
  Box,
  Typography,
  ListItem,
  Button,
  ListItemIcon
} from '@mui/material';

import EmptyState from '../../common/EmptyState';
import { getConstantByValue } from '../../../utils/getConstantByValue';
import { attributeObjectTypes } from '../../../constants/attributeObjectTypes';
import _ from 'lodash';
import StackRow from '../../common/StackRow';
import DescriptionIcon from '@mui/icons-material/Description';

const AttributesSetsList = ({ sets, onSelected, onRemove }) => {
  const renderListItem = (set) => {
    return (
      <React.Fragment key={set.id}>
        <ListItem
          button
          onClick={() => onSelected(set)}
          component="li"
          aria-label={`Edytuj zestaw atrybutów ${set.name}`}>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ sx: { fontWeight: 650 } }} primary={set.name} />
          <ListItemSecondaryAction>
            <Button variant="clearText" key="1" onClick={() => onRemove(set)} onCancel={() => {}} aria-label="Usuń">
              usuń
            </Button>
            {/* <DotsMenu>
              {(onClose) => [
                <ActionConfirmationMenuItem
                  key="1"
                  onItemClick={onClose}
                  actionLabel="Usuń"
                  confirmationText="Czy na pewno chcesz usunąć zestaw atrybutów?"
                  confirmationTitle="Usuń zestaw atrybutów"
                  confirmLabel="Usuń"
                  onConfirm={() => onRemove(set)}
                  onCancel={() => {}}
                />
              ]}
            </DotsMenu> */}
          </ListItemSecondaryAction>
        </ListItem>
      </React.Fragment>
    );
  };

  const renderList = (name, groupSet) => {
    return (
      <Box key={name}>
        <StackRow sx={{ alignItems: 'center' }}>
          <Typography variant="h6" sx={{ mx: 1, fontWeight: (theme) => theme.typography.fontWeightBold }}>
            {name}
          </Typography>
        </StackRow>
        <List>{groupSet.map((set) => renderListItem(set))}</List>
      </Box>
    );
  };

  const renderGroupedSets = (groupedSets) => {
    return (
      <Box>
        {Object.keys(groupedSets).map((key) =>
          renderList(getConstantByValue(attributeObjectTypes, key).name, groupedSets[key])
        )}
      </Box>
    );
  };

  return !sets.length ? (
    <EmptyState contrast message="Nie zdefiowano jeszcze żadnych zestawów" />
  ) : (
    renderGroupedSets(_.groupBy(sets, (s) => s.objectType))
  );
};

AttributesSetsList.propTypes = {
  sets: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default AttributesSetsList;
