import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Pagination, Table, useMediaQuery } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import makeStyles from '@mui/styles/makeStyles';
import ScheduledTransferTableHeader from '../components/Table/ScheduledTransferTableHeader';
import EmptyState from '../../common/EmptyState';
import ScheduledTransferTableBody from '../components/Table/ScheduledTransferTableBody';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import _ from 'lodash';

const useTableStyles = makeStyles({
  root: {
    minWidth: 640
  }
});

export const scheduledTransferFilterTypes = {
  ALL: 'all',
  GROUP: 'group',
  ACTIVITY: 'activity',
  ARCHIVE: 'archive'
};

export const scheduledTransferFilterStatusTypes = {
  ALL: 'all',
  SUCCESS: 'success',
  ERROR: 'error'
};

export const scheduledTransferStatusTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  PENDING: 'pending'
};

export const tableTypes = {
  CURRENT: 'current',
  HISTORY: 'history'
};

const ScheduledTransferTableContainer = ({
  transfers,
  onEdit,
  onDelete,
  selectedTransfersIds,
  setSelectedTransfersIds,
  isLoading,
  searchText,
  rowsPerPage,
  isHistory,
  page,
  setPage
}) => {
  const cl = useTableStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [preparedTransfers, setPreparedTransfers] = useState([]);

  useEffect(() => {
    if (searchText.length > 0) {
      setPreparedTransfers(
        transfers
          .filter((item2) =>
            isHistory
              ? item2.status !== scheduledTransferStatusTypes.PENDING
              : item2.status === scheduledTransferStatusTypes.PENDING
          )
          .filter((item) =>
            (
              item.firstName +
              ' ' +
              item.lastName +
              ' ' +
              item.firstName +
              ' ' +
              item.from +
              ' ' +
              item.to +
              ' ' +
              item.from
            )

              .toLowerCase()
              .includes(searchText.toLowerCase())
          )
      );
      setPage(0);
    } else {
      setPage(0);
      setPreparedTransfers(
        transfers.filter((item2) =>
          isHistory
            ? item2.status !== scheduledTransferStatusTypes.PENDING
            : item2.status === scheduledTransferStatusTypes.PENDING
        )
      );
    }
  }, [isHistory, searchText, transfers]);

  const allItemsSelected = preparedTransfers.length > 0 && selectedTransfersIds.length === preparedTransfers.length;
  const itemSelected = (rowId) => selectedTransfersIds.some((id) => id === rowId);

  const handleHeaderSelect = (type) => {
    if (type === 'none') setSelectedTransfersIds([]);
    if (type === 'all') setSelectedTransfersIds(preparedTransfers.map((item) => item.id));
  };

  const handleSelect = (id) => {
    if (selectedTransfersIds.includes(id)) {
      setSelectedTransfersIds(selectedTransfersIds.filter((item) => item !== id));
    } else {
      setSelectedTransfersIds([...selectedTransfersIds, id]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const isHistoryTableEmpty = !transfers.some((item) => item.status !== scheduledTransferStatusTypes.PENDING);
  const isScheduledTransferTableEmpty = !transfers.some((item) => item.status !== scheduledTransferStatusTypes.PENDING);

  return (
    <Box sx={{ overflowX: 'auto' }}>
      {isLoading ? (
        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 300 }}>
          <CircularProgress size={80} />
        </Grid>
      ) : (
        <>
          {preparedTransfers && preparedTransfers.length > 0 ? (
            <>
              <TableContainer sx={{ p: 2 }}>
                <Table size="small" className={cl.root} aria-label="Lista płatności">
                  <ScheduledTransferTableHeader
                    allItemsSelected={allItemsSelected}
                    onSelect={handleHeaderSelect}
                    isHistory={isHistory}
                  />
                  <ScheduledTransferTableBody
                    onSelect={handleSelect}
                    itemSelected={itemSelected}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    transfers={preparedTransfers}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    areActionsDisabled={!!selectedTransfersIds.length}
                    isHistory={isHistory}
                  />
                </Table>
              </TableContainer>
              <Pagination
                siblingCount={isMobile ? 0 : 1}
                page={page + 1}
                count={_.ceil(Object.keys(preparedTransfers).length / rowsPerPage)}
                onChange={handleChangePage}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  my: 2
                }}
              />
            </>
          ) : undefined}
          {isScheduledTransferTableEmpty && !isHistory ? (
            <EmptyState message="Brak planowanych przeniesień" contrast />
          ) : undefined}
          {isHistoryTableEmpty && isHistory ? (
            <EmptyState message="Brak historycznych przeniesień" contrast />
          ) : undefined}
          {preparedTransfers.length === 0 &&
          ((!isHistory && !isScheduledTransferTableEmpty) || (isHistory && !isHistoryTableEmpty)) ? (
            <EmptyState contrast message="Brak wyników wyszukiwania dla zastosowanych kryteriów" />
          ) : undefined}
        </>
      )}
    </Box>
  );
};
ScheduledTransferTableContainer.propTypes = {
  isLoading: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  selectedTransfersIds: PropTypes.array.isRequired,
  setSelectedTransfersIds: PropTypes.func.isRequired,
  transfers: PropTypes.array.isRequired,
  searchText: PropTypes.string,
  reset: PropTypes.bool,
  setReset: PropTypes.func,
  rowsPerPage: PropTypes.number,
  isHistory: PropTypes.bool,
  page: PropTypes.number,
  setPage: PropTypes.func
};

export default ScheduledTransferTableContainer;
