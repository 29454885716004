import React from 'react';
import PropTypes from 'prop-types';
import ShareToRoleCard from '../ShareToRoleCard';
import { Divider, Fade, Grid, useTheme, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MoreGroupsIcon from '../../../../../assets/icons/MoreGroupsIcon';
import IconButtonPipe from '../../../../../components/common/IconButtonPipe';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import EmptyState from '../../../../../components/common/EmptyState';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import { useShareFileContext } from '../../../context/ShareFileContext';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  gridWrapper: {
    gap: 20,
    display: 'grid',
    gridTemplateColumns: ({ isMobile }) => `repeat( auto-fill, minmax(${isMobile ? '100px' : '200px'}, 1fr) )`,
    justifyItems: 'center',
    alignItems: 'center',
    padding: 20
  }
}));

const AvailableRolesContent = ({ onSingleAction, availableList, onShareAllRoles }) => {
  const { isMobile, isDisabled, isSingleShare, isFolder } = useShareFileContext();
  const cl = useStyles({ isMobile });
  const theme = useTheme();
  const isListEmpty = !(availableList.length > 0);
  return (
    <>
      <Grid item xs={12} md={6} className={cl.wrapper} sx={{ overflow: 'scroll' }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}>
          <MoreGroupsIcon
            sx={{
              fontSize: 40,
              mr: 2,
              color: isDisabled ? theme.palette.color.contrast : theme.palette.color.color25
            }}
          />
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightLarge }}>
            Wybierz role, dla których udostępnisz {isFolder ? 'folder' : isSingleShare ? 'plik' : 'pliki'}
          </Typography>
          <Fade in={true} timeout={500}>
            <Box>
              <IconButtonPipe
                onClick={(e) => {
                  e.preventDefault();
                  onShareAllRoles();
                }}
                tooltip="Udostępnij wszystkie role"
                disabled={isDisabled || isListEmpty}>
                <ReplyAllIcon
                  sx={{
                    transform: 'scaleX(-1)',
                    color: isDisabled || isListEmpty ? undefined : theme.palette.color.color25
                  }}
                />
              </IconButtonPipe>
            </Box>
          </Fade>
        </Box>
        {isListEmpty && <EmptyState icon={<SentimentSatisfiedIcon />} message="Wybrano wszystkie role" contrast />}

        <Box className={cl.gridWrapper}>
          {availableList.map((item, index) => (
            <ShareToRoleCard key={index} {...item} onSingleAction={onSingleAction} isSelected={false} />
          ))}
        </Box>
      </Grid>
      <Divider orientation="vertical" sx={{ m: '-1px' }} />
    </>
  );
};

AvailableRolesContent.propTypes = {
  availableList: PropTypes.array.isRequired,
  onShareAllRoles: PropTypes.func.isRequired,
  onSingleAction: PropTypes.func.isRequired
};

export default AvailableRolesContent;
