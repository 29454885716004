import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const ExportFilesIcon = (props) => (
  <SvgIcon viewBox="0 0 366.999 366.999" {...props}>
    <path d="M363.598 247.01c.146-.177.272-.365.409-.547.157-.209.319-.414.464-.632.145-.216.27-.441.402-.662.118-.198.243-.392.352-.596.121-.225.223-.458.332-.688.101-.213.207-.423.298-.643.092-.223.167-.451.248-.678.085-.235.175-.467.248-.708.068-.226.118-.454.176-.683.062-.246.131-.49.181-.741.052-.261.082-.524.12-.788.032-.221.074-.439.096-.664.048-.485.073-.973.074-1.46l.001-.02-.001-.025c0-.486-.025-.971-.073-1.455-.022-.225-.064-.442-.096-.664-.038-.263-.068-.526-.12-.787-.05-.253-.12-.499-.182-.747-.057-.226-.107-.453-.174-.677-.073-.242-.164-.476-.25-.713-.081-.225-.155-.452-.246-.673-.092-.221-.199-.432-.3-.647-.108-.229-.209-.459-.329-.683-.11-.206-.236-.401-.355-.6-.131-.221-.256-.443-.4-.658-.147-.219-.31-.424-.467-.635-.136-.182-.262-.368-.407-.544a14.95 14.95 0 00-.948-1.049c-.016-.016-.029-.034-.045-.05l-37.499-37.501c-5.857-5.857-15.355-5.858-21.213-.001-5.858 5.858-5.858 15.355 0 21.213l11.894 11.895-45.788.002v-78.605c.003-.133.02-.263.02-.396a14.92 14.92 0 00-3.407-9.49l-.064-.079a15.166 15.166 0 00-1.002-1.091c-.132-.131-.255-.272-.393-.398L155.609 22.896a14.985 14.985 0 00-.97-.882c-.104-.087-.212-.169-.318-.253a14.853 14.853 0 00-1.914-1.293c-.112-.063-.22-.132-.334-.193a14.657 14.657 0 00-1.109-.534c-.154-.067-.311-.124-.467-.186a14.539 14.539 0 00-2.158-.668c-.131-.029-.259-.066-.392-.093-.42-.084-.844-.146-1.27-.193-.13-.015-.262-.023-.393-.035a15.014 15.014 0 00-1.06-.054c-.076-.002-.148-.012-.224-.012H15c-8.284 0-15 6.716-15 15v300c0 8.284 6.716 15 15 15h240c8.284 0 15-6.716 15-15v-80.999l45.786.001-11.893 11.893c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394l37.499-37.499.021-.023c.343-.344.667-.703.973-1.076zM160 69.713l58.787 58.787H160V69.713zM240 318.5H30v-270h100v95c0 8.284 6.716 15 15 15h95v64.001l-65.001-.001c-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15l65.001.001V318.5z" />
  </SvgIcon>
);

export default ExportFilesIcon;
