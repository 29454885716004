import PropTypes from 'prop-types';
import React from 'react';
import CardDialogEditForm from '../forms/CardDialogEditForm';
import ChildGuardianForm from '../common/children/guardians/ChildGuardianForm';
import ReadonlyGuardiansList from '../common/children/guardians/ReadonlyGuardiansList';
import ReadonlyGuardianExtendAuthorizationDateForm from '../common/children/guardians/ReadonlyGuardianExtendAuthorizationDateForm';
import { GuardianModel } from '../../models/children/ChildModels';
import { GuardianModelValidator } from '../../models/children/ChildModelsValidator';
import EmptyState from '../common/EmptyState';
import { Box, Divider, Typography } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

class GuardianChildGuardiansCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activeGuardian: new GuardianModel(),
      errors: {}
    };
    this.handleCreate = this.handleCreate.bind(this);
    this.handleGuardianChange = this.handleGuardianChange.bind(this);
    this.handleExtendAuthorizationDate = this.handleExtendAuthorizationDate.bind(this);
    this.handleSignForm = this.handleSignForm.bind(this);
  }

  handleCreate() {
    this.setState({
      activeGuardian: new GuardianModel()
    });
    this.props.onCreate();
  }

  handleExtendAuthorizationDate(guardian) {
    this.setState({
      activeGuardian: new GuardianModel().assign(guardian)
    });
    this.props.onExtendAuthorizationDate();
  }

  handleGuardianChange(event) {
    const field = event.target.name;
    const { activeGuardian } = this.state;
    activeGuardian[field] = event.target.value;
    this.setState({ activeGuardian });
  }

  handleSignForm(guardian) {
    this.props.onSign(guardian.id);
  }

  renderEditForm() {
    return this.state.activeGuardian.id ? (
      <ReadonlyGuardianExtendAuthorizationDateForm
        guardian={this.state.activeGuardian}
        onChange={this.handleGuardianChange}
        errors={this.state.errors}
      />
    ) : (
      <ChildGuardianForm
        guardian={this.state.activeGuardian}
        onChange={this.handleGuardianChange}
        errors={this.state.errors}
      />
    );
  }

  renderReadonlyForm() {
    return (
      <ReadonlyGuardiansList
        guardians={this.props.guardians}
        onSign={(guardian) => this.handleSignForm(guardian)}
        onExtendAuthorizationDate={(guardian) => this.handleExtendAuthorizationDate(guardian)}
        onDownloadAuthorizationDoc={(guardian) => this.props.onDownloadAuthorizationDoc(guardian)}
        unit={this.props.unit}
      />
    );
  }

  render() {
    return (
      <Box>
        <CardDialogEditForm
          title="Osoby upoważnione do odbioru dziecka"
          helperText={
            this.props.unit.features.guardianCrossValidation || this.props.unit.features.govIntegration ? (
              <>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.color.contrast,
                    p: 2,
                    fontWeight: (theme) => theme.typography.fontWeightBold,
                    flexShrink: 0,
                    borderRadius: 5
                  }}>
                  Po dodaniu osoby upoważnionej, podpisz upoważnienie profilem zaufanym wybierając odpowiednią opcję w
                  menu kontekstowym
                  <MoreVert
                    sx={{
                      mb: -1,
                      mx: 1,
                      border: (theme) => `1px solid ${theme.palette.color.primary}`,
                      borderRadius: '50%'
                    }}
                  />
                </Typography>
                <Divider />
              </>
            ) : null
          }
          isInitiallyExpanded={true}
          onSave={() => this.props.onSave(this.state.activeGuardian)}
          onCancel={() => this.props.onCancel(this.state.activeGuardian)}
          onValidate={() => new GuardianModelValidator().validate(this.state.activeGuardian)}
          onValidationDone={(errors) => this.setState({ errors })}
          onAction={this.handleCreate}
          actionLabel="DODAJ"
          actionTitle="Osoba odbierająca"
          isDialogOpen={this.props.isEditing}
          isProcessing={this.props.isProcessing}
          statePathToUi="childDetailsUi.guardians"
          readonlyForm={this.renderReadonlyForm()}
          emptyForm={<EmptyState message="Brak osób upoważnionych do odbioru" contrast />}
          editForm={this.renderEditForm()}
          isEmpty={this.props.guardians.length < 1}
        />
      </Box>
    );
  }
}

GuardianChildGuardiansCard.propTypes = {
  guardians: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  onExtendAuthorizationDate: PropTypes.func.isRequired,
  onDownloadAuthorizationDoc: PropTypes.func.isRequired,
  onSign: PropTypes.func.isRequired,
  unit: PropTypes.object.isRequired
};

export default GuardianChildGuardiansCard;
