import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ScheduledTransferForm, {
  InitialValues,
  validate,
  VALIDATION_SCHEMA
} from '../../../../forms/scheduledTransfer/ScheduledTransferForm';
import FormDialog from '../../../formik/formDialog/FormDialog';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useDispatch } from 'react-redux';
import * as Type from '../../../../constants/groupsAndActivitiesTypes';
import { editScheduledTransfer } from '../../../../actions/scheduledTransferActions';
import moment from 'moment';

const ScheduledTransferEditDialogContainer = ({ isOpen, onClose, data, childrenList, groups, activities }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (values, actions) => {
    setLoading(true);
    const inData = {
      childId: values.selectedChild.id,
      type: values.type.toLowerCase(),
      date: moment(values.date).format('YYYY-MM-DD'),
      enrollmentIDs:
        // eslint-disable-next-line no-nested-ternary
        values.type === Type.GROUP
          ? values.selectedGroup === 'none'
            ? undefined
            : [values.selectedGroup]
          : values.selectedActivities.includes('none')
          ? undefined
          : values.selectedActivities,
      archiveChild: values.type === Type.GROUP && values.selectedGroup === 'none' && values.archiveChild,
      archiveLegalGuardians:
        values.type === Type.GROUP &&
        values.selectedGroup === 'none' &&
        values.archiveChild &&
        values.archiveLegalGuardians
    };
    const child = childrenList.find((item) => item.id === data.childId);
    let from;
    let to;
    if (values.type === Type.GROUP) {
      from = groups.find((item) => child.enrolledToGroup && item.id === child.enrolledToGroup.id);
      to = groups.find((item) => item.id === values.selectedGroup);
    } else {
      from = activities.filter((item) => child.enrolledToActivities.some((x) => x.id === item.id));
      to = activities.filter((item) => values.selectedActivities.includes(item.id));
    }
    const storeData = {
      ...inData,
      from,
      to,
      firstName: child.firstName,
      lastName: child.lastName,
      id: data.id,
      avatarUrl: child.photoUrl
    };
    const error = await dispatch(editScheduledTransfer(data.id, inData, storeData));
    setLoading(false);
    if (error) {
      if (error.date) {
        actions.setFieldError('date', error.date);
      } else {
        actions.setFieldError('generalError', error.generalError);
      }
    } else {
      onClose();
    }
  };

  const initDataMapper = {
    type: data.type.toUpperCase(),
    selectedChild: childrenList.find((item) => item.id === data.childId),
    // eslint-disable-next-line no-nested-ternary
    selectedGroup: data.type === 'group' ? (data.to ? data.to.id : 'none') : '',
    // eslint-disable-next-line no-nested-ternary
    selectedActivities: data.type === 'activity' ? (data.to.length ? data.to.map((item) => item.id) : ['none']) : [],
    archiveChild: !!data.archiveChild,
    archiveLegalGuardians: !!data.archiveLegalGuardians,
    date: moment(data.date)
  };

  const initialValues = new InitialValues().assign(initDataMapper);

  return (
    <FormDialog
      title="Zaplanuj przepięcie dziecka"
      titleIcon={<EventAvailableIcon sx={{ mr: 1, fontSize: 50 }} />}
      open={isOpen}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      validate={validate}
      onSubmit={onSubmit}
      requiredFieldText
      maxWidth="md"
      inProgress={loading}>
      <ScheduledTransferForm childrenList={childrenList} groups={groups} activities={activities} isEdit to={data.to} />
    </FormDialog>
  );
};

ScheduledTransferEditDialogContainer.propTypes = {
  activities: PropTypes.array.isRequired,
  childrenList: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ScheduledTransferEditDialogContainer;
