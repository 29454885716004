import MealsApi from '../api/MealsApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';

export function loadChildMealsByMonthAsync(childId, year, month) {
  return async (dispatch) => {
    try {
      const data = await MealsApi.getChildMealsByMonth(childId, year, month);
      dispatch({ type: types.LOAD_CHILDREN_CALENDAR_MEALS_SUCCESS, data });
    } catch (e) {
      dispatch({ type: types.LOAD_CHILDREN_CALENDAR_MEALS_FAILURE });
      dispatch(notificationActions.showError('Nie udało się pobrać danych na temat posiłków'));
    }
  };
}

export function updateMealByDayAsync(childId, date, meals) {
  return async (dispatch) => {
    try {
      const data = await MealsApi.updateMealByDay(childId, date, meals);
      console.log(data);
      dispatch({ type: types.UPDATE_CHILDREN_CALENDAR_MEALS_SUCCESS, data });
      dispatch(notificationActions.showSuccess(`Udało się zaktualizować posiłki na dzień: ${date}`));
    } catch (e) {
      console.log(e);
      dispatch({ type: types.UPDATE_CHILDREN_CALENDAR_MEALS_FAILURE });
      dispatch(notificationActions.showError('Nie udało się zaktualizować posiłków'));
    }
  };
}
