import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import DatePicker from '../../../common/DatePicker';

const SingleDatePickerTemplate = ({ date, onChange, errors, legend, name }) => {
  return (
    <FieldsetWrapper legend={`Pola dla generowania raportu${legend}`}>
      <Box>
        <DatePicker
          hintText="Na dzień"
          floatingLabelText="Na dzień"
          floatingLabelFixed={false}
          name={name}
          value={date ? new Date(date) : undefined}
          onChange={(e, value) => onChange({ target: { name, value: moment(value).format('YYYY-MM-DD') } })}
          errorText={errors.date}
        />
      </Box>
    </FieldsetWrapper>
  );
};

SingleDatePickerTemplate.propTypes = {
  date: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  info: PropTypes.string
};

export default SingleDatePickerTemplate;
