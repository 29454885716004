import * as types from './actionTypes';
import messageBoardApi from '../api/MessageBoardApi';
import * as notificationActions from './notificationActions';
import { BoardPostCommandModel } from '../models/messageBoard/MessageBoardModels';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';
import userRoles from '../constants/userRoles';
import { MessageBoardFilterModel } from '../models/messageBoardFilter/MessageBoardFilterModels';
import StaffMessageBoardApi from '../api/StaffMessageBoardApi';

export function loadPostsAsync(limit, offset) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_MESSAGE_BOARD_POSTS });
    return messageBoardApi
      .getMessageBoardPosts(limit, offset)
      .then((posts) => dispatch({ type: types.LOAD_MESSAGE_BOARD_POSTS_SUCCESS, posts }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać wpisów dla tablicy'));
        return logger.error(error);
      });
  };
}

export function loadFilteredPostsToMeAsync(params) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_MESSAGE_BOARD_POSTS });
    return messageBoardApi
      .getFilteredMessageBoardPostsToMe(params)
      .then((posts) => dispatch({ type: types.LOAD_MESSAGE_BOARD_POSTS_SUCCESS, posts }))
      .then(() => dispatch({ type: types.FILTER_MESSAGE_BOARD_SUCCESS }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać wpisów dla tablicy'));
        return logger.error(error);
      });
  };
}

export function loadFilteredPostsAsync(params) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_MESSAGE_BOARD_POSTS });
    return messageBoardApi
      .getFilteredMessageBoardPosts(params)
      .then((posts) => dispatch({ type: types.LOAD_MESSAGE_BOARD_POSTS_SUCCESS, posts }))
      .then(() => dispatch({ type: types.FILTER_MESSAGE_BOARD_SUCCESS }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać wpisów dla tablicy'));
        return logger.error(error);
      });
  };
}

export function loadFilteredPostsByRoleAsync(limit, offset, data) {
  return (dispatch, getState) => {
    const { auth } = getState();
    const params = { limit, offset };
    if (!!data.filters.dateFrom) params.dateFrom = data.filters.dateFrom;
    if (!!data.filters.dateTo) params.dateTo = data.filters.dateTo;
    if (!!data.filters.textFilter) params.textFilter = data.filters.textFilter;
    if (data.filters.pinned) params.pinned = data.filters.pinned;
    if (data.filters.authors.length) params.authors = data.filters.authors.join(',');
    if (data.filters.groups.length) params.groups = data.filters.groups.join(',');
    if (data.filters.activities.length) params.activities = data.filters.activities.join(',');

    if (auth.isLegalGuardian() || auth.isStaffMember(userRoles.staffMemberTeacher)) {
      loadFilteredPostsToMeAsync(params)(dispatch);
    } else if (auth.isStaffMember()) {
      loadFilteredPostsAsync(params)(dispatch);
    }
  };
}

export function loadNextPostsAsync(limit, offset) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_NEXT_MESSAGE_BOARD_POSTS });
    return messageBoardApi
      .getMessageBoardPosts(limit, offset)
      .then((posts) => dispatch({ type: types.LOAD_NEXT_MESSAGE_BOARD_POSTS_SUCCESS, posts }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać kolejnych wpisów dla tablicy'));
        return logger.error(error);
      });
  };
}

export function loadNextFilteredPostsAsync(limit, offset, filters) {
  return (dispatch) => {
    const params = { limit, offset };
    if (!!filters.dateFrom) params.dateFrom = filters.dateFrom;
    if (!!filters.dateTo) params.dateTo = filters.dateTo;
    if (!!filters.textFilter) params.textFilter = filters.textFilter;
    if (filters.pinned) params.pinned = filters.pinned;
    if (filters.authors.length) params.authors = filters.authors.join(',');
    if (filters.groups.length) params.groups = filters.groups.join(',');
    if (filters.activities.length) params.activities = filters.activities.join(',');
    dispatch({ type: types.LOAD_NEXT_MESSAGE_BOARD_POSTS });
    return messageBoardApi
      .getFilteredMessageBoardPosts(params)
      .then((posts) => dispatch({ type: types.LOAD_NEXT_MESSAGE_BOARD_POSTS_SUCCESS, posts }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać kolejnych wpisów dla tablicy'));
        return logger.error(error);
      });
  };
}

export function loadEventsAtMonthAsync(date) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_MESSAGE_BOARD_EVENTS_AT_MONTH });
    return messageBoardApi
      .getMessageBoardEventsAtMonth(date)
      .then((posts) => dispatch({ type: types.LOAD_MESSAGE_BOARD_EVENTS_AT_MONTH_SUCCESS, posts }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać wydarzeń dla ${get.displayMonth(date)}`));
        return logger.error(error);
      });
  };
}

export function loadEventsAtDayAsync(date) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_MESSAGE_BOARD_EVENTS_AT_DAY });
    return messageBoardApi
      .getMessageBoardEventsAtDay(date)
      .then((posts) => dispatch({ type: types.LOAD_MESSAGE_BOARD_EVENTS_AT_DAY_SUCCESS, posts }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać wydarzeń z dnia ${get.displayDate(date)}`));
        return logger.error(error);
      });
  };
}

export function loadEventsToMeAtMonthAsync(date) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_MESSAGE_BOARD_EVENTS_TO_ME_AT_MONTH });
    return messageBoardApi
      .getMessageBoardEventsToMeAtMonth(date)
      .then((posts) => dispatch({ type: types.LOAD_MESSAGE_BOARD_EVENTS_TO_ME_AT_MONTH_SUCCESS, posts }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać wydarzeń dla ${get.displayMonth(date)}`));
        return logger.error(error);
      });
  };
}

export function loadEventsToMeAtDayAsync(date) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_MESSAGE_BOARD_EVENTS_TO_ME_AT_DAY });
    return messageBoardApi
      .getMessageBoardEventsToMeAtDay(date)
      .then((posts) => dispatch({ type: types.LOAD_MESSAGE_BOARD_EVENTS_TO_ME_AT_DAY_SUCCESS, posts }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać wydarzeń z dnia ${get.displayDate(date)}`));
        return logger.error(error);
      });
  };
}

export function loadPostsToMeAsync(limit, offset) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_MESSAGE_BOARD_POSTS_TO_ME });
    return messageBoardApi
      .getMessageBoardPostsToMe(limit, offset)
      .then((posts) => dispatch({ type: types.LOAD_MESSAGE_BOARD_POSTS_TO_ME_SUCCESS, posts }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać wpisów dla tablicy'));
        return logger.error(error);
      });
  };
}

export function loadNextPostsToMeAsync(limit, offset) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_NEXT_MESSAGE_BOARD_POSTS_TO_ME });
    return messageBoardApi
      .getMessageBoardPostsToMe(limit, offset)
      .then((posts) => dispatch({ type: types.LOAD_NEXT_MESSAGE_BOARD_POSTS_TO_ME_SUCCESS, posts }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać kolejnych wpisów dla tablicy'));
        return logger.error(error);
      });
  };
}

export function loadNextFilteredPostsToMeAsync(limit, offset, filters) {
  return (dispatch) => {
    const params = { limit, offset };
    if (!!filters.dateFrom) params.dateFrom = filters.dateFrom;
    if (!!filters.dateTo) params.dateTo = filters.dateTo;
    if (filters.authors.length) params.authors = filters.authors.join(',');
    if (filters.groups.length) params.groups = filters.groups.join(',');
    if (filters.activities.length) params.activities = filters.activities.join(',');

    dispatch({ type: types.LOAD_NEXT_MESSAGE_BOARD_POSTS_TO_ME });
    return messageBoardApi
      .getFilteredMessageBoardPostsToMe(params)
      .then((posts) => dispatch({ type: types.LOAD_NEXT_MESSAGE_BOARD_POSTS_TO_ME_SUCCESS, posts }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać kolejnych wpisów dla tablicy'));
        return logger.error(error);
      });
  };
}

export function loadPostsByRoleAsync(limit, offset) {
  return (dispatch, getState) => {
    const { auth } = getState();
    if (
      auth.isLegalGuardian() ||
      auth.isStaffMember([userRoles.staffMemberTeacher, userRoles.staffMemberTeacherSupport])
    ) {
      loadPostsToMeAsync(limit, offset)(dispatch);
    } else if (auth.isStaffMember()) {
      loadPostsAsync(limit, offset)(dispatch);
    }
  };
}

export function loadEventsByRoleAtDayAsync(date) {
  return (dispatch, getState) => {
    const { auth } = getState();
    if (auth.isLegalGuardian() || auth.isStaffMember(userRoles.staffMemberTeacher)) {
      loadEventsToMeAtDayAsync(date)(dispatch);
    } else if (auth.isStaffMember()) {
      loadEventsAtDayAsync(date)(dispatch);
    }
  };
}

export function loadEventsByRoleAtMonthAsync(date) {
  return (dispatch, getState) => {
    const { auth } = getState();
    if (auth.isLegalGuardian() || auth.isStaffMember(userRoles.staffMemberTeacher)) {
      loadEventsToMeAtMonthAsync(date)(dispatch);
    } else if (auth.isStaffMember()) {
      loadEventsAtMonthAsync(date)(dispatch);
    }
  };
}

export function loadFilteredEventsToMeAtMonthAsync(date, params, filters) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_MESSAGE_BOARD_EVENTS_TO_ME_AT_MONTH });
    return messageBoardApi
      .getFilteredMessageBoardEventsToMeAtMonth(date, params, filters)
      .then((posts) => dispatch({ type: types.LOAD_MESSAGE_BOARD_EVENTS_TO_ME_AT_MONTH_SUCCESS, posts }))
      .then(() => dispatch({ type: types.FILTER_MESSAGE_BOARD_SUCCESS, filters }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać wydarzeń dla ${get.displayMonth(date)}`));
        return logger.error(error);
      });
  };
}

export function loadFilteredEventsAtMonthAsync(date, params, filters) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_MESSAGE_BOARD_EVENTS_AT_MONTH });
    return messageBoardApi
      .getFilteredMessageBoardEventsAtMonth(date, params)
      .then((posts) => dispatch({ type: types.LOAD_MESSAGE_BOARD_EVENTS_AT_MONTH_SUCCESS, posts }))
      .then(() => dispatch({ type: types.FILTER_MESSAGE_BOARD_SUCCESS, filters }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać wydarzeń dla ${get.displayMonth(date)}`));
        return logger.error(error);
      });
  };
}

export function loadFilteredEventsByRoleAtMonthAsync(date, filters) {
  return (dispatch, getState) => {
    const params = {};
    if (!!filters.dateFrom) params.dateFrom = filters.dateFrom;
    if (!!filters.dateTo) params.dateTo = filters.dateTo;
    if (!!filters.textFilter) params.textFilter = filters.textFilter;
    if (filters.authors.length) params.authors = filters.authors.join(',');
    if (filters.groups.length) params.groups = filters.groups.join(',');
    if (filters.activities.length) params.activities = filters.activities.join(',');
    const { auth } = getState();
    if (auth.isLegalGuardian() || auth.isStaffMember(userRoles.staffMemberTeacher)) {
      loadFilteredEventsToMeAtMonthAsync(date, params, filters)(dispatch);
    } else if (auth.isStaffMember()) {
      loadFilteredEventsAtMonthAsync(date, params, filters)(dispatch);
    }
  };
}

export function loadLatestEventsAsync(date, limit, offset) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_LATEST_MESSAGE_BOARD_EVENTS });
    return messageBoardApi
      .getLatestMessageBoardEvents(date, limit, offset)
      .then((posts) => dispatch({ type: types.LOAD_LATEST_MESSAGE_BOARD_EVENTS_SUCCESS, posts }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać najbliższych wydarzeń'));
        return logger.error(error);
      });
  };
}

export function loadLatestEventsToMeAsync(date, limit, offset) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_LATEST_MESSAGE_BOARD_EVENTS_TO_ME });
    return messageBoardApi
      .getLatestMessageBoardEventsToMe(date, limit, offset)
      .then((posts) => dispatch({ type: types.LOAD_LATEST_MESSAGE_BOARD_EVENTS_TO_ME_SUCCESS, posts }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać najbliższych wydarzeń'));
        return logger.error(error);
      });
  };
}

export function loadLatestEventsByRoleAsync(date, limit, offset) {
  return (dispatch, getState) => {
    const { auth } = getState();
    if (auth.isLegalGuardian() || auth.isStaffMember(userRoles.staffMemberTeacher)) {
      loadLatestEventsToMeAsync(date, limit, offset)(dispatch);
    } else if (auth.isStaffMember()) {
      loadLatestEventsAsync(date, limit, offset)(dispatch);
    }
  };
}

export function createPostStart() {
  return { type: types.CREATE_MESSAGE_BOARD_POST };
}

export function createPostCancel() {
  return { type: types.CREATE_MESSAGE_BOARD_POST_CANCEL };
}

export function createPostAsync(post) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_MESSAGE_BOARD_POST_SUBMIT });
    return messageBoardApi
      .createMessageBoardPostOrEvent(new BoardPostCommandModel().assignFromQueryModel(post))
      .then((id) => {
        dispatch(notificationActions.showSuccess(`Dodano wpis na tablicy: '${post.title}'`));
        dispatch({
          type: types.CREATE_MESSAGE_BOARD_POST_SUCCESS,
          post: Object.assign(post, { id, publishedAt: Date.now() })
        });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.CREATE_MESSAGE_BOARD_POST_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError(`Nie udało się dodać wpisu na tablicy: '${post.title}'`));
        return logger.error(error);
      });
  };
}

export function updatePostStart() {
  return { type: types.UPDATE_MESSAGE_BOARD_POST };
}

export function updatePostCancel() {
  return { type: types.UPDATE_MESSAGE_BOARD_POST_CANCEL };
}

export function updatePostAsync(post) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_MESSAGE_BOARD_POST_SUBMIT });
    return messageBoardApi
      .updateMessageBoardPostOrEvent(post.id, new BoardPostCommandModel().assignFromQueryModel(post))
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zaktualizowano wpis na tablicy: '${post.title}'`));
        dispatch({ type: types.UPDATE_MESSAGE_BOARD_POST_SUCCESS, post });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.UPDATE_MESSAGE_BOARD_POST_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError(`Nie udało się zaktualizować wpisu na tablicy: '${post.title}'`));
        return logger.error(error);
      });
  };
}

export function deletePostAsync(postId) {
  return (dispatch, getState) => {
    dispatch({ type: types.DELETE_MESSAGE_BOARD_POST });
    return messageBoardApi
      .archiveMessageBoardPost(postId)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Usunięto wpis '${get.postTitle(getState, postId)}'`));
        dispatch({ type: types.DELETE_MESSAGE_BOARD_POST_SUCCESS, postId });
      })
      .catch((error) => {
        const errorText =
          error.response && error.response.body && error.response.body.authorId ? error.response.body.authorId : '';
        dispatch(
          notificationActions.showError(`Nie udało się usunąć wpisu '${get.postTitle(getState, postId)}'. ${errorText}`)
        );
        return logger.error(error);
      });
  };
}

export function clearFilters(date) {
  return (dispatch, getState) => {
    dispatch({ type: types.FILTER_MESSAGE_BOARD_SUCCESS, filters: new MessageBoardFilterModel() });
    const { auth } = getState();
    if (auth.isLegalGuardian() || auth.isStaffMember(userRoles.staffMemberTeacher)) {
      loadEventsToMeAtMonthAsync(date)(dispatch);
    } else if (auth.isStaffMember()) {
      loadEventsAtMonthAsync(date)(dispatch);
    }
  };
}

export function loadPostSeenBy(userId, isStaffMember) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.POST_SEEN_BY });
      let groupList;
      if (isStaffMember) groupList = await StaffMessageBoardApi.getStaffMembersList(userId);
      else groupList = await messageBoardApi.getLegalGuardiansList(userId);
      dispatch({ type: types.POST_SEEN_BY_SUCCESS });
      return groupList;
    } catch (error) {
      dispatch(notificationActions.showError(`Nie udało się załadować użytkowników`));
      dispatch({ type: types.POST_SEEN_BY_FAILURE, errors: error.response.body });
      return logger.error(error);
    }
  };
}
