import { combineReducers } from 'redux';
import auth from './authReducer';
import groups from './groupsReducer';
import groupDetails from './groupDetailsReducer';
import groupDetailsUi from './groupDetailsUiReducer';
import activities from './activitiesReducer';
import activityDetails from './activityDetailsReducer';
import activityDetailsUi from './activityDetailsUiReducer';
import meals from './mealsReducer';
import childrenTakenOverByDebtCollection from './childrenTakenOverByDebtCollectionReducer';
import archivedMeals from './archivedMealsReducer';
import mealsCategories from './mealsCategoriesReducer';
import mealDetails from './mealDetailsReducer';
import mealDetailsUi from './mealDetailsUiReducer';
import childMealsCalendar from './mealsCalendarReducer';
import diets from './dietsReducer';
import dietDetails from './dietDetailsReducer';
import dietDetailsUi from './dietDetailsUiReducer';
import children from './childrenReducer';
import psychologicalChildren from './psychologicalChildrenReducer';

import legalGuardians from './legalGuardiansReducer';
import childDetails from './childDetailsReducer';
import childPersonalBalance from './childPersonalBalanceReducer';
import childDetailsUi from './childDetailsUiReducer';
import childAttendances from './childAttendancesReducer';
import childPsychologicalIndividualAttendances from './childPsychologicalIndividualAttendancesReducer';
import childPsychologicalGroupAttendances from './childPsychologicalGroupAttendancesReducer';

import staffMembers from './staffMembersReducer';
import staffMemberDetails from './staffMemberDetailsReducer';
import staffMemberDetailsUi from './staffMemberDetailsUiReducer';
import messageBoardPosts from './messageBoardPostsReducer';
import messageBoardEvents from './messageBoardEventsReducer';
import messageBoardDigitalDiary from './messageBoardDigitalDiaryReducer';
import messageBoardDigitalDiaryUi from './messageBoardDigitalDiaryUiReducer';
import messageBoardUi from './messageBoardUiReducer';
import messageBoardFilterUi from './messageBoardFilterUiReducer';
import messageBoardFilter from './messageBoardFilterReducer';
import privateMessages from './privateMessagesReducer';
import privateThreads from './privateThreadsReducer';
import attendance from './attendanceReducer';
import absenceTomorrow from './tomorrowAbsenceReducer';
import attendanceUi from './attendanceUiReducer';
import attendanceHistory from './attendanceHistoryReducer';
import legalGuardiansChildren from './legalGuardiansChildrenReducer';
import legalGuardianDashboardUi from './legalGuardianDashboardUiReducer';
import daysOff from './daysOffReducer';
import daysOffUi from './daysOffUiReducer';
import unit from './unitReducer';
import unitAddressUi from './unitAddressUiReducer';
import unitWorkingHoursUi from './unitWorkingHoursUiReducer';
import unitAbsenceRemovalDeadlineUi from './unitAbsenceRemovalDeadlineUiReducer';
import unitPenaltiesUi from './unitPenaltiesUiReducer';
import chargingSchemeDetails from './chargingSchemeDetailsReducer';
import chargingSchemes from './chargingSchemesReducer';
import chargingSchemesUi from './chargingSchemesUiReducer';
import dailySchedulesUiReducer from './dailySchedulesUiReducer';
import dailySchedulesReducer from './dailySchedulesReducer';

import reliefs from './reliefsReducer';
import reliefsUi from './reliefsUiReducer';
import numberingSchemes from './numberingSchemesReducer';
import numberingSchemesUi from './numberingSchemesUiReducer';
import reportsConfiguration from './reportsConfigurationReducer';
import reportsConfigurationUi from './reportsConfigurationUiReducer';
import attributesSets from './attributesSetsReducer';
import attributesSetsUi from './attributesSetsUiReducer';
import settlements from './settlementsReducer';
import settlementsUi from './settlementsUiReducer';
import settlementsSettings from './settlementsSettingsReducer';
import settlementsSettingsUi from './settlementsSettingsUiReducer';
import correctiveDuesCalculationUi from './correctiveDuesCalculationUiReducer';
import settlementsCalculationUi from './settlementsCalculationUiReducer';
import registerPaymentUi from './registerPaymentUiReducer';
import registerPaymentsUi from './registerPaymentsUiReducer';
import registerRebateUi from './registerRebateUiReducer';
import approveDuesUi from './approveDuesUiReducer';
import settlementsListUi from './settlementsListUiReducer';
import childDues from './childDuesReducer';
import childBalanceSummary from './childBalanceSummaryReducer';
import guardianDues from './guardianDuesReducer';
import guardianDueDetails from './guardianDueDetailsReducer';
import generateReportUi from './generateReportUiReducer';
import overdue from './overdueReducer';
import overpayments from './overpaymentsReducer';
import expiringReliefs from './expiringReliefsReducer';
import attendanceSummary from './attendanceSummaryReducer';
import isLoading from './loadingReducer';
import resetPasswordUi from './resetPasswordUiReducer';
import reports from './reportsReducer';
import pinEntries from './pinEntriesReducer';
import * as types from '../actions/actionTypes';
import staffDues from './staffDuesReducer';

import groupsArchive from './groupsArchiveReducer';
import groupArchiveDetails from './groupArchiveDetailsReducer';
import activitiesArchive from './activitiesArchiveReducer';
import activityArchiveDetails from './activityArchiveDetailsReducer';
import childrenArchive from './childrenArchiveReducer';
import childArchiveDetails from './childArchiveDetailsReducer';
import staffMembersArchive from './staffMembersArchiveReducer';
import guardiansPinService from './guardiansPinServiceReducer';
import daycareSettings from './daycareSettingsReducer';
import duesExportSettings from './duesExportSettingsReducer';
import duesFkExportSettings from './duesFkExportSettingsReducer';
import duesExportSettingsUi from './duesExportSettingsUiReducer';
import duesFkExportSettingsUi from './duesFkExportSettingsUiReducer';
import daycare from './daycareReducer';
import daycareUi from './daycareUiReducer';
import childDaycare from './childDaycareReducer';
import settlementsFilters from './settlementsFiltersReducer';
import duesExports from './duesExportsReducer';
import duesFkExports from './duesFkExportsReducer';

import notifications from './notificationsReducer';
import psychologicalChildrenList from './psychologicalChildrenListReducer';
import psychologicalChildrenListUI from './psychologicalChildrenListUIReducer';
import psychologicalHelpDetails from './psychologicalHelpDetailsReducer';
import psychologicalHelpUi from './psychologicalHelpUiReducer';

import psychologicalIndividualWorkProgram from './psychologicalIndividualWorkProgramReducer';
import psychologicalIndividualProgramUi from './psychologicalIndividualProgramUiReducer';
import psychologicalIndividualJournalUi from './psychologicalIndividualJournalUiReducer';

import psychologicalSchemes from './psychologicalSchemesReducer';
import psychologicalSchemesUI from './psychologicalSchemesUIReducer';
import psychologicalGroups from './psychologicalGroupsReducer';
import psychologicalChoosenGroup from './psychologicalChoosenGroupReducer';
import psychologicalGroupClassesAttendance from './PsychologicalGroupClassesAttendanceReducer';
import psychologicalGroupDetails from './psychologicalGroupDetailsReducer';
import psychologicalGroupDetailsUi from './psychologicalGroupDetailsUiReducer';
import psychologicalIndividualAttendance from './psychologicalIndividualAttendanceReducer';

import psychologicalIndividualAttendanceJournal from './psychologicalIndividualJournalAttendanceReducer';

import psychologicalGroupProgramUi from './psychologicalGroupProgramUiReducer';
import psychologicalGroupWorkProgram from './psychologicalGroupWorkProgramReducer';

import routing from './routingReducer';

import diagnosticSchemesSets from './diagnosticSchemesReducer';
import diagnosticSchemesUi from './diagnosticSchemesUiReducer';

import digitalDiaryEvents from './digitalDiaryReducer';
import digitalDiaryUiReducer from './digitalDiaryUiReducer';

import digitalDiaryWorkPlan from './digitalDiaryWorkPlanReducer';
import digitalDiaryWorkPlanUi from './digitalDiaryWorkPlanUiReducer';

import digitalDiaryChoosenGroup from './digitalDiaryChoosenGroupReducer';

import importChildrenUi from './importChildrenUiReducer';
import importChildren from './importChildrenReducer';
import exportChildrenUi from './exportChildrenUiReducer';
import exportChildren from './exportChildrenReducer';

import entrances from './entrancesReducer';
import entranceImportUi from './entranceImportUiRecucer';

import importChildrenSummary from './importChildrenSummaryReducer';
import importChildrenSummaryUi from './importChildrenSummaryUiReducer';

import legalGuardianApplications from './legalGuardianApplicationsReducer';
import applications from './applicationsReducer';
import contrastColor from './theMostImportantReducer';
import theme from './themeReducer';
import events from './eventsReducer';
import loginHistory from './loginHistoryReducer';
import profileHistory from './profileHistoryReducer';
import currentUsers from './currentUsersReducer';
import childDetailsSummary from './childDetailsSummaryReducer';
import { albumsReducer, uiReducer, multimediaReducer, multimediaUiReducer } from './galleryReducer';
import rightBottomNotification from './rightBottomNotificationReducer';
import staffCalendar from './staffCalendarReducer';
import staffMessageBoard from './staffMessageBoardReducer';
import scheduledTransfers from './scheduledTransferReducer';
import virtualDisc from './virtualDiscReducer';
import virtualDiscFolder from './virtualDiscFolderReducer';
import virtualDiscFolderUi from './virtualDiscFolderUiReducer';
import childrenOnVacationPeriod from './childrenOnVacationPeriodReducer';
//import PsychologicalGroupClassesAttendanceReducer from './PsychologicalGroupClassesAttendanceReducer';
import specialistNotes from './specialistNotesReducer';
import specialists from './specialistsReducer';
import specialistNotesUi from './specialistNotesUiReducer';
import psychologicalIndividualJournalWorkProgram from './psychologicalIndividualJournalWorkProgramReducer';
import psychologicalIndividualJournalAttendance from './psychologicalIndividualJournalAttendanceReducer';

const staffDashboardReducers = combineReducers({
  overdue,
  overpayments,
  expiringReliefs,
  attendanceSummary
});

const configurationReducers = combineReducers({
  daysOff,
  daysOffUi,
  unit,
  unitAddressUi,
  unitWorkingHoursUi,
  unitAbsenceRemovalDeadlineUi,
  unitPenaltiesUi,
  chargingSchemeDetails,
  chargingSchemes,
  chargingSchemesUi,
  reliefs,
  reliefsUi,
  settlementsSettings,
  settlementsSettingsUi,
  numberingSchemes,
  numberingSchemesUi,
  reportsConfiguration,
  reportsConfigurationUi,
  attributesSets,
  attributesSetsUi,
  guardiansPinService,
  daycare: daycareSettings,
  duesExportSettings,
  duesFkExportSettings,
  duesExportSettingsUi,
  duesFkExportSettingsUi,
  diagnosticSchemesSets,
  diagnosticSchemesUi,
  importChildren,
  importChildrenUi,
  importChildrenSummary,
  importChildrenSummaryUi,
  exportChildren,
  exportChildrenUi,
  entranceImportUi,
  entrances
});

const archivesReducers = combineReducers({
  groups: groupsArchive,
  groupDetails: groupArchiveDetails,
  activities: activitiesArchive,
  activityDetails: activityArchiveDetails,
  children: childrenArchive,
  childDetails: childArchiveDetails,
  staffMembers: staffMembersArchive
});

const digitalDiary = combineReducers({
  workPlan: digitalDiaryWorkPlan,
  workPlanUi: digitalDiaryWorkPlanUi,
  events: digitalDiaryEvents,
  eventsUi: digitalDiaryUiReducer,
  choosenGroup: digitalDiaryChoosenGroup,
  dailySchedules: dailySchedulesReducer,
  dailySchedulesUi: dailySchedulesUiReducer
});

const gallery = combineReducers({
  albums: albumsReducer,
  ui: uiReducer,
  multimedia: multimediaReducer,
  multimediaUi: multimediaUiReducer
});

const appReducer = combineReducers({
  psychologicalIndividualJournalAttendance,
  psychologicalIndividualJournalWorkProgram,
  routing,
  auth,
  groups,
  groupDetails,
  groupDetailsUi,
  activities,
  activityDetails,
  activityDetailsUi,
  meals,
  archivedMeals,
  mealsCategories,
  mealDetails,
  mealDetailsUi,
  childMealsCalendar,
  diets,
  dietDetails,
  dietDetailsUi,
  absenceTomorrow,
  children,
  childrenTakenOverByDebtCollection,
  psychologicalChildren,
  childDetails,
  childDetailsSummary,
  childDetailsUi,
  childPersonalBalance,
  childAttendances,
  childPsychologicalGroupAttendances,
  childPsychologicalIndividualAttendances,
  psychologicalIndividualAttendanceJournal,
  childDaycare,
  staffMembers,
  staffMemberDetails,
  staffMemberDetailsUi,
  messageBoardPosts,
  messageBoardEvents,
  messageBoardDigitalDiary,
  messageBoardDigitalDiaryUi,
  messageBoardUi,
  messageBoardFilterUi,
  messageBoardFilter,
  privateMessages,
  privateThreads,
  attendance,
  attendanceUi,
  attendanceHistory,
  legalGuardiansChildren,
  legalGuardianDashboardUi,
  legalGuardians,
  configuration: configurationReducers,
  archives: archivesReducers,
  settlements,
  settlementsFilters,
  settlementsUi,
  settlementsCalculationUi,
  correctiveDuesCalculationUi,
  registerPaymentUi,
  registerPaymentsUi,
  registerRebateUi,
  approveDuesUi,
  settlementsListUi,
  childDues,
  childBalanceSummary,
  staffDues,
  guardianDues,
  guardianDueDetails,
  generateReportUi,
  staffDashboard: staffDashboardReducers,
  psychologicalChildrenList,
  psychologicalChildrenListUI,
  psychologicalHelpDetails,
  psychologicalHelpUi,
  psychologicalIndividualWorkProgram,
  psychologicalSchemes,
  psychologicalSchemesUI,
  psychologicalChoosenGroup,
  psychologicalGroups,
  psychologicalGroupClassesAttendance,
  psychologicalGroupDetails,
  psychologicalGroupDetailsUi,
  psychologicalIndividualProgramUi,
  psychologicalIndividualJournalUi,
  specialistNotesUi,
  psychologicalGroupProgramUi,
  psychologicalGroupWorkProgram,
  psychologicalIndividualAttendance,
  specialistNotes,
  specialists,
  notifications,
  isLoading,
  resetPasswordUi,
  reports,
  pinEntries,
  daycare,
  daycareUi,
  duesExports,
  duesFkExports,
  digitalDiary,
  dailySchedulesReducer,
  dailySchedulesUiReducer,
  legalGuardianApplications,
  applications,
  contrastColor,
  theme,
  events,
  loginHistory,
  profileHistory,
  currentUsers,
  gallery,
  rightBottomNotification,
  staffCalendar,
  staffMessageBoard,
  scheduledTransfers,
  virtualDisc,
  virtualDiscFolder,
  virtualDiscFolderUi,
  childrenOnVacationPeriod
});

/*eslint no-param-reassign:0*/

const rootReducer = (state, action) => {
  if (action.type === types.CLEANUP_DATA) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
