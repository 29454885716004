import ApiBase, { ROOT_URL } from './ApiBase';

class NumberingSchemesApi extends ApiBase {
  static getNumberingSchemes() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/configuration/numberingSchemes`).type('application/json'));
  }

  static getNumberingScheme(schemeId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/configuration/numberingSchemes/${schemeId}`).type('application/json')
    );
  }

  static createNumberingScheme(scheme) {
    return super.toPromise(
      (agent) =>
        agent
          .post(`${ROOT_URL}/configuration/numberingSchemes`)
          .send(scheme)
          .set('Location', '')
          .type('application/json'),
      super.filterLocationHeader
    );
  }

  static updateNumberingScheme(schemeId, scheme) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/configuration/numberingSchemes/${schemeId}`).send(scheme).type('application/json')
    );
  }

  static deleteNumberingScheme(schemeId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/configuration/numberingSchemes/${schemeId}`).type('application/json')
    );
  }
}

export default NumberingSchemesApi;
