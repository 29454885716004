import PropTypes from 'prop-types';
import React from 'react';
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import PersonAvatar from '../forms/PersonAvatar';
import { routePaths, fromTemplate } from '../../routePaths';

const StaffMembersList = ({ staffMembers, roles, router }) => {
  return (
    <List sx={{ py: 0 }}>
      {staffMembers.map((member) => {
        return (
          <ListItem
            key={member.id}
            button
            onClick={() => router.push(fromTemplate(routePaths.staffMemberDetails, member.id))}
            component="li"
            aria-label={`Pokaż szczegóły ${member.lastName} ${member.firstName}`}>
            <ListItemAvatar>
              <PersonAvatar
                initials
                alt={member.firstName}
                url={member.photoUrl}
                firstName={member.firstName}
                lastName={member.lastName}
              />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
              secondaryTypographyProps={{ color: (theme) => theme.palette.color.color2 }}
              primary={`${member.lastName} ${member.firstName}`}
              secondary={roles[member.role].name}
            />
            {/* page crushes on nadarzyn domain, cause nadarzyn has only principal and teacher role */}
          </ListItem>
        );
      })}
    </List>
  );
};

StaffMembersList.propTypes = {
  staffMembers: PropTypes.array.isRequired,
  roles: PropTypes.object.isRequired,
  router: PropTypes.any
};

export default StaffMembersList;
