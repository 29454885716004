import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const UnitSchemeElementHeader = ({ title }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography sx={{ color: (theme) => theme.palette.color.primary, fontWeight: 'bold' }} variant="h5">
        {title}
      </Typography>
    </Box>
  );
};

export default UnitSchemeElementHeader;
