import FieldsetWrapper from '../forms/FieldsetWrapper';
import SelectField from '../common/SelectField';
import { Box, FormControl, FormHelperText, MenuItem, TextField } from '@mui/material';
import RichTextEditor from '../forms/richTextEditor/RichTextEditor';
import ClearButtonWrapper from '../common/ClearButtonWrapper';
import DatePicker from '../common/DatePicker';
import TimePicker from '../common/TimePicker';
import DialogForm from '../forms/DialogForm';
import React, { useState } from 'react';
import moment from 'moment/moment';
import { TimeModel } from '../../models/TimeModel';
import { PsychologicalClassesValidator } from '../../models/psychological/PsychologicalClassesModel';
import { uniqueId } from 'lodash';
import EmptyState from '../common/EmptyState';

const ClassesDialog = (props) => {
  const [selectedScheme, setSelectedScheme] = useState(null);
  const [refresh, setRefresh] = useState(uniqueId());
  const [post, setPost] = useState(Object.assign({}, props.post));

  const [errors, setErrors] = useState({});

  const { isMobile } = props;

  const handleTitleChange = (e) => {
    setPost((prevPost) => Object.assign(prevPost, { title: e.target.value }));
  };

  const handleContentChange = (htmlContent) => {
    setSelectedScheme(null);
    setPost((prevPost) => Object.assign(prevPost, { content: htmlContent }));
  };

  const handleEventDateChange = (date) => {
    setPost((prevPost) =>
      Object.assign(prevPost, {
        eventDate: date ? moment(date).format('YYYY-MM-DD') : null
      })
    );
    if (!date) {
      handleEventStartTimeChange(undefined);
      handleEventEndTimeChange(undefined);
    }
  };

  const handleEventStartTimeChange = (time) => {
    setPost((prevPost) =>
      Object.assign(prevPost, { eventStartTime: time ? new TimeModel().parse(time) : new TimeModel() })
    );
    if (!time) {
      handleEventEndTimeChange(undefined);
    }
  };

  const handleEventEndTimeChange = (time) => {
    setPost((prevPost) =>
      Object.assign(prevPost, { eventEndTime: time ? new TimeModel().parse(time) : new TimeModel() })
    );
  };

  const handleOnSave = () => {
    props.onSave(post);
  };
  const handleSchemeChange = (scheme) => {
    setSelectedScheme(scheme);
    setPost((prevPost) => Object.assign(prevPost, { content: `<p>${scheme.content}</p>` }));
    setRefresh(uniqueId());
  };

  return (
    <DialogForm
      errors={errors}
      onSave={handleOnSave}
      onCancel={() => props.onCancel(post)}
      onValidate={() => new PsychologicalClassesValidator().validate(post)}
      onValidationDone={(errors) => {
        setErrors(errors);
      }}
      statePathToUi="messageBoardUi"
      isDialogOpen={props.isEditing}
      isProcessing={props.isProcessing}
      header="Utwórz nowy wpis"
      saveDisabled={Object.values(errors).filter((e) => e !== '').length > 0}
      requiredFieldText>
      <FieldsetWrapper legend="Pola na temat wydarzenia dotyczącego zajęcia dodatkowego">
        <TextField
          sx={{
            fontSize: 24,
            lineHeight: 36
          }}
          label="Tytuł*"
          name="title"
          id="title"
          variant="standard"
          defaultValue={post.title}
          onChange={handleTitleChange}
          fullWidth={true}
          error={!!errors.title}
          helperText={errors.title}
        />
        {!!props.schemesArray && (
          <SelectField
            contrast
            sx={{ mt: 2 }}
            floatingLabelText="Wybierz przygotowany schemat"
            value={selectedScheme}
            onChange={(event) => handleSchemeChange(event.target.value)}>
            {!!props.schemesArray.length > 0 ? (
              props.schemesArray.map((scheme) => (
                <MenuItem key={scheme.templateName} value={scheme}>
                  {scheme.templateName}
                </MenuItem>
              ))
            ) : (
              <EmptyState message={'Brak schematów'} />
            )}
          </SelectField>
        )}
        <FormControl
          error={errors.content}
          sx={{
            width: '100%',
            mb: 3,
            borderBottom: !!errors && !!errors.content ? (theme) => `1px solid ${theme.palette.color.error}` : null
          }}>
          <RichTextEditor key={refresh} onChange={handleContentChange} initialText={post.content} />
          <FormHelperText>{errors.content}</FormHelperText>
        </FormControl>

        {props.canSetDate && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'start', justifyContent: 'space-between' }}>
            <ClearButtonWrapper
              contrast
              onClear={() => handleEventDateChange(undefined)}
              disabled={!props.canClearDate}>
              <DatePicker
                sx={{ width: 200, m: 0 }}
                floatingLabelText="Data wydarzenia"
                textFieldStyle={{ width: 128 }}
                onChange={(e, date) => handleEventDateChange(date)}
                value={post.eventDate}
                name="eventDate"
                errorText={errors.eventDate}
                id="dataWydarzeniaPicker"
              />
            </ClearButtonWrapper>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems="flex-start"
              justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
              <ClearButtonWrapper
                sx={{ mr: props.canClearDate ? 0 : 2 }}
                contrast
                onClear={() => handleEventStartTimeChange(undefined)}
                disabled={!props.canClearDate}>
                <TimePicker
                  floatingLabelText="Rozpoczęcie"
                  disabled={!post.eventDate}
                  textFieldStyle={{ width: 128 }}
                  onChange={(e, time) => handleEventStartTimeChange(time)}
                  value={!!post.eventStartTime ? post.eventStartTime.toDate() : undefined}
                  name="eventStartTime"
                  errorText={errors.eventStartTime}
                />
              </ClearButtonWrapper>
              <ClearButtonWrapper
                contrast
                onClear={() => handleEventEndTimeChange(undefined)}
                disabled={!props.canClearDate}>
                <TimePicker
                  floatingLabelText="Zakończenie"
                  disabled={!post.eventStartTime}
                  textFieldStyle={{ width: 128 }}
                  onChange={(e, time) => handleEventEndTimeChange(time)}
                  value={!!post.eventEndTime ? post.eventEndTime.toDate() : undefined}
                  name="eventEndTime"
                  errorText={errors.eventEndTime}
                />
              </ClearButtonWrapper>
            </Box>
          </Box>
        )}
      </FieldsetWrapper>
    </DialogForm>
  );
};
export default ClassesDialog;
