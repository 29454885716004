import api from '../api/psychologicalApi';
import diaryApi from '../api/DigitalDiaryApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function loadIndividualJournalClassesWorkPlan(date, childId, journalId) {
  return (dispatch) => {
    return api
      .loadIndividualJournalWorkPlan(date, childId, journalId)
      .then((response) => dispatch({ type: types.LOAD_INDIVIDUAL_JOURNAL_CLASSES_CALENDAR, response }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać rozkładu zajęć`));
        return logger.error(error);
      });
  };
}

export function loadIndividualJournalClassesDataDetails(childId, groupId) {
  return (dispatch) => {
    return api
      .loadIndividualJournalWorkPlanDetails(childId, groupId)
      .then((payload) => dispatch({ type: types.LOAD_INDIVIDUAL_JOURNAL_CLASSES, payload, groupId }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać listy obecności`));
        return logger.error(error);
      });
  };
}

export function setIndividualJournalClassesDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_CLASSES_START });
  };
}

export function setIndividualJournalClassesDialogClosed() {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_CLASSES_CANCEL });
  };
}

export function addIndividualJournalClasses(data) {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_CLASSES_SUBMIT });
    return diaryApi
      .addCustomNewEvent(data, data.eventType)
      .then((response) => {
        dispatch({ type: types.ADD_INDIVIDUAL_JOURNAL_CLASSES, response });
        dispatch(notificationActions.showSuccess(`Dodano wpis`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się dodać wpisu`));
        return logger.error(error);
      });
  };
}

export function editIndividualJournalClasses(data) {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_CLASSES_SUBMIT });
    return diaryApi
      .editCustomEvent(data)
      .then(() => {
        dispatch({ type: types.EDIT_INDIVIDUAL_JOURNAL_CLASSES, data });
        dispatch(notificationActions.showSuccess(`Zaktualizowano wpis`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zaktualizować wpisu`));
        return logger.error(error);
      });
  };
}

export function deleteIndividualJournalClasses(postId) {
  return (dispatch) => {
    return diaryApi
      .deleteCustomEvent(postId)
      .then(() => {
        dispatch({ type: types.DELETE_INDIVIDUAL_JOURNAL_CLASSES, postId });
        dispatch(notificationActions.showSuccess(`Usunięto wpis`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć wpisu`));
        return logger.error(error);
      });
  };
}
//----

export function setGeneralDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_PROGRAM_START });
  };
}

export function setGeneralDialogClosed() {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_PROGRAM_CANCEL });
  };
}

export function setNotesDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_PROGRAM_NOTE_START });
  };
}

export function setNotesDialogClosed() {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_PROGRAM_NOTE_CANCEL });
  };
}

export function setObservationsDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION_START });
  };
}

export function setObservationsDialogClosed() {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION_CANCEL });
  };
}

export function createNewObservation(data, assistanceId) {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION_SUBMIT });
    return api
      .createWorkPlanElement(data, assistanceId)
      .then((id) => {
        dispatch({ type: types.ADD_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION, data, id });
        dispatch(notificationActions.showSuccess(`Dodano obserwację ${data.title}`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się dodać obserwacji`));
        return logger.error(error);
      });
  };
}

export function editObservation(data, assistanceId) {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION_SUBMIT });
    return api
      .editWorkPlanElement(data, assistanceId)
      .then((id) => {
        dispatch({ type: types.EDIT_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION, data, id });
        dispatch(notificationActions.showSuccess(`Edytowano obserwację ${data.title}`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się edytować obserwacji`));
        return logger.error(error);
      });
  };
}

export function deleteObservation(data, assistanceId) {
  return (dispatch) => {
    return api
      .deleteWorkPlanElement(data, assistanceId)
      .then((id) => {
        dispatch({ type: types.DELETE_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION, data, id });
        dispatch(notificationActions.showSuccess(`Usunięto obserwację ${data.title}`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć obserwacji`));
        return logger.error(error);
      });
  };
}

//elements

export function createNewProgramElement(data, diaryId) {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_PROGRAM_SUBMIT });
    return api
      .createJournalWorkPlanElement(data, diaryId)
      .then((response) => {
        dispatch({ type: types.ADD_INDIVIDUAL_JOURNAL_PROGRAM, data, response });
        dispatch(notificationActions.showSuccess(`Dodano element`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się dodać elementu`));
        return logger.error(error);
      });
  };
}

export function editProgramElement(data, diaryId) {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_PROGRAM_SUBMIT });
    return api
      .editJournalWorkPlanElement(data, diaryId)
      .then(() => {
        dispatch({ type: types.EDIT_INDIVIDUAL_JOURNAL_PROGRAM, data });
        dispatch(notificationActions.showSuccess(`Zaktualizowano element`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zaktualizować elementu`));
        return logger.error(error);
      });
  };
}

export function deleteProgramElement(data, assistanceId) {
  return (dispatch) => {
    return api
      .deleteJournalWorkPlanElement(data, assistanceId)
      .then(() => {
        dispatch({ type: types.DELETE_INDIVIDUAL_JOURNAL_PROGRAM, data });
        dispatch(notificationActions.showSuccess(`Usunięto element`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć elementu`));
        return logger.error(error);
      });
  };
}

//notatki

export function createNewNoteElement(data, diaryId) {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_PROGRAM_NOTE_SUBMIT });
    return api
      .createJournalWorkPlanElement(data, diaryId)
      .then((response) => {
        dispatch({ type: types.ADD_INDIVIDUAL_JOURNAL_PROGRAM_NOTE, data, response });
        dispatch(notificationActions.showSuccess(`Dodano notatkę`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się dodać notatki`));
        return logger.error(error);
      });
  };
}

export function editNoteElement(data, diaryId) {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_PROGRAM_NOTE_SUBMIT });
    return api
      .editJournalWorkPlanElement(data, diaryId)
      .then(() => {
        dispatch({ type: types.EDIT_INDIVIDUAL_JOURNAL_PROGRAM_NOTE, data });
        dispatch(notificationActions.showSuccess(`Zaktualizowano notatkę`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zaktualizować notatki`));
        return logger.error(error);
      });
  };
}

export function deleteNoteElement(data, assistanceId) {
  return (dispatch) => {
    return api
      .deleteJournalWorkPlanElement(data, assistanceId)
      .then(() => {
        dispatch({ type: types.DELETE_INDIVIDUAL_JOURNAL_PROGRAM_NOTE, data });
        dispatch(notificationActions.showSuccess(`Usunięto notatkę`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć notatki`));
        return logger.error(error);
      });
  };
}

//obserwacje

export function createNewObservationElement(data, diaryId) {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION_SUBMIT });
    return api
      .createJournalWorkPlanElement(data, diaryId)
      .then((response) => {
        dispatch({ type: types.ADD_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION, data, response });
        dispatch(notificationActions.showSuccess(`Dodano notatkę`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się dodać notatki`));
        return logger.error(error);
      });
  };
}

export function editObservationElement(data, diaryId) {
  return (dispatch) => {
    dispatch({ type: types.INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION_SUBMIT });
    return api
      .editJournalWorkPlanElement(data, diaryId)
      .then(() => {
        dispatch({ type: types.EDIT_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION, data });
        dispatch(notificationActions.showSuccess(`Zaktualizowano notatkę`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zaktualizować notatki`));
        return logger.error(error);
      });
  };
}

export function deleteObservationElement(data, assistanceId) {
  return (dispatch) => {
    return api
      .deleteJournalWorkPlanElement(data, assistanceId)
      .then(() => {
        dispatch({ type: types.DELETE_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION, data });
        dispatch(notificationActions.showSuccess(`Usunięto notatkę`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć notatki`));
        return logger.error(error);
      });
  };
}
