import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment/moment';
import makeStyles from '@mui/styles/makeStyles';

const SchoolYearPicker = ({ labelId, label, onChange, value }) => {
  const useStyle = makeStyles((theme) => ({
    icon: {
      fill: theme.palette.color.contrast
    }
  }));
  const year = moment().year();
  const schoolYears = useMemo(() => {
    const prevYear = year - 1;
    const nextYear = year + 1;
    return [
      { name: `${prevYear}/${year}`, id: prevYear.toString() },
      { name: `${year}/${nextYear}`, id: year.toString() }
    ];
  }, [year]);

  const classes = useStyle();

  return (
    <FormControl variant="standard" sx={{ mr: 1 }}>
      <InputLabel
        htmlFor={labelId}
        sx={{
          color: (theme) => theme.palette.color.contrast
        }}>
        {label}
      </InputLabel>
      <Select
        aria-label={label}
        inputProps={{
          id: labelId,
          classes: {
            icon: classes.icon
          }
        }}
        IconComponent={CalendarMonthIcon}
        sx={{
          '& .MuiSelect-iconOpen': { transform: 'rotate(0deg)' },
          color: (theme) => theme.palette.color.color2,
          borderBottom: (theme) => `1px solid ${theme.palette.color.color2}`
        }}
        disableUnderline
        variant="standard"
        value={value || ''}
        onChange={onChange}>
        {schoolYears.map((year) => {
          return (
            <MenuItem key={year.id} value={year.id}>
              {year.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default SchoolYearPicker;
