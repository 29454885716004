import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as get from './getStateHelpers';
import groupsApi from '../api/PsychologicalGroupsApi';
import { browserHistory } from 'react-router';
import { routePaths } from '../routePaths';
import * as logger from '../utils/logger';

export function loadPsychologicalGroupsAsync() {
  return (dispatch) => {
    return groupsApi
      .getGroups()
      .then((groups) => {
        dispatch({ type: types.LOAD_PSYCHOLOGICAL_GROUPS, groups });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać listy grup'));
        return logger.error(error);
      });
  };
}

export function createPsychologicalGroupDetailsStart() {
  return { type: types.CREATE_GROUP_DETAILS };
}

export function createPsychologicalGroupDetailsCancel() {
  return { type: types.CREATE_GROUP_DETAILS_CANCEL };
}

export function createPsychologicalGroupAsync(nextGroupDetails) {
  const groupDetails = Object.assign({}, nextGroupDetails);

  return (dispatch) => {
    return groupsApi
      .createGroup(groupDetails)
      .then((id) => {
        dispatch({ type: types.ADD_PSYCHOLOGICAL_GROUP, groupDetails: Object.assign(groupDetails, id) });
        dispatch(notificationActions.showSuccess(`Utworzono grupę '${groupDetails.name}'`));
      })
      .catch((error) => {
        if (error.status === 412) {
          dispatch(notificationActions.showError(`Nie udało się utworzyć grupy '${groupDetails.name}'`));
          return logger.error(error);
        }
      });
  };
}

export function loadPsychologicalGroupDetailsAsync(id) {
  return (dispatch, getState) => {
    return groupsApi
      .getGroup(id)
      .then((groupDetails) => dispatch({ type: types.LOAD_PSYCHOLOGICAL_GROUP_DETAILS_SUCCESS, groupDetails }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(`Nie udało się pobrać szczegółów grupy '${get.groupName(getState, id)}'`)
        );
        return logger.error(error);
      });
  };
}

export function updatePsychologicalGroupDetailsAsync(nextGroupDetails) {
  const groupDetails = Object.assign({}, nextGroupDetails);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_GROUP_DETAILS_SUBMIT });
    return groupsApi
      .updateGroup(groupDetails.id, groupDetails)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zaktualizowano grupę '${groupDetails.name}'`));
        dispatch({ type: types.UPDATE_PSYCHOLOGICAL_GROUP_SUCCESS, groupDetails });
      })
      .catch((error) => {
        if (error.status === 412) {
          dispatch(notificationActions.showError(`Nie udało się zaktualizować grupy '${groupDetails.name}'`));
          return logger.error(error);
        }
      });
  };
}

export function updateEnrolledChildrenStart() {
  return (dispatch) => {
    dispatch({ type: types.ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP });
  };
}

export function updateEnrolledChildrenAsync(groupId, nextChildrenIds) {
  const childrenIds = [...nextChildrenIds];
  return (dispatch, getState) => {
    dispatch({ type: types.ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_SUBMIT });
    return groupsApi
      .patchGroupChildren(groupId, childrenIds)
      .then(() => {
        const allChildren = getState().children;
        const children = childrenIds.map((id) => allChildren.find((child) => child.id === id));
        dispatch(notificationActions.showSuccess('Zaktualizowano listę przypisanych dzieci'));
        dispatch({ type: types.ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_SUCCESS, children, groupId });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się zaktualizować listy przypisanych dzieci'));
        return logger.error(error);
      });
  };
}

export function updateEnrolledChildrenCancel() {
  return (dispatch) => {
    dispatch({ type: types.ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_CANCEL });
  };
}

export function removeEnrolledChildrenAsync(groupId, childId) {
  return (dispatch, getState) => {
    return groupsApi
      .removeChild(groupId, childId)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Odłączono dziecko '${get.childName(getState, childId)}' od grupy '${get.groupName(getState, groupId)}'`
          )
        );
        dispatch({ type: types.REMOVE_CHILD_FROM_PSYCHOLOGICAL_GROUP_SUCCESS, childId });
      })
      .catch((error) => {
        const errorText =
          error.response && error.response.body && error.response.body.generalError
            ? error.response.body.generalError
            : '';
        dispatch(
          notificationActions.showError(
            `Nie udało się odłączyć dziecka '${get.childName(getState, childId)}' od grupy '${get.groupName(
              getState,
              groupId
            )}'.${errorText}`
          )
        );
        return logger.error(error);
      });
  };
}

export function associateTeacherStart() {
  return (dispatch) => {
    dispatch({ type: types.ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP });
  };
}

export function associateTeacherAsync(groupId, teacherId) {
  return (dispatch, getState) => {
    dispatch({ type: types.ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_SUBMIT });
    return groupsApi
      .associateTeacher(groupId, teacherId)
      .then(() => {
        const teacher = getState().staffMembers.find((member) => member.id === teacherId);
        dispatch(
          notificationActions.showSuccess(
            `Przypisano nauczyciela '${get.staffName(getState, teacherId)}' do grupy '${get.groupName(
              getState,
              groupId
            )}'`
          )
        );
        dispatch({ type: types.ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_SUCCESS, teacher });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({
            type: types.ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_FAILURE,
            errors: error.response.body
          });
        dispatch(
          notificationActions.showError(
            `Nie udało się przypisać nauczyciela '${get.staffName(getState, teacherId)}' do grupy '${get.groupName(
              getState,
              groupId
            )}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function associateTeacherCancel() {
  return (dispatch) => {
    dispatch({ type: types.ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_CANCEL });
  };
}

export function removeTeacherAsync(groupId, teacherId) {
  return (dispatch, getState) => {
    return groupsApi
      .removeTeacher(groupId, teacherId)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Odłączono nauczyciela '${get.staffName(getState, teacherId)}' od grupy '${get.groupName(
              getState,
              groupId
            )}'`
          )
        );
        dispatch({ type: types.REMOVE_TEACHER_FROM_PSYCHOLOGICAL_GROUP_SUCCESS, teacherId });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się odłączyć nauczyciela '${get.staffName(getState, teacherId)}' od grupy '${get.groupName(
              getState,
              groupId
            )}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function archiveGroupStart() {
  return { type: types.ARCHIVE_PSYCHOLOGICAL_GROUP };
}

export function archiveGroupCancel() {
  return { type: types.ARCHIVE_PSYCHOLOGICAL_GROUP_CANCEL };
}

export function archiveGroupDetailsAsync(group) {
  return (dispatch, getState) => {
    dispatch({ type: types.ARCHIVE_PSYCHOLOGICAL_GROUP_SUBMIT });
    return groupsApi
      .deleteGroup(group)
      .then(() => {
        browserHistory.push(routePaths.psychologicalGroups);
        dispatch(notificationActions.showSuccess(`Zarchiwizowano grupę`));
        dispatch({ type: types.ARCHIVE_PSYCHOLOGICAL_GROUP_SUCCESS, groupdId: group.id });
      })
      .catch((error) => {
        if (error.status === 412) {
          dispatch({ type: types.ARCHIVE_PSYCHOLOGICAL_GROUP_FAILURE });
          notificationActions.showError(`Nie udało się zarchiwizować grupy.`);
          return logger.error(error);
        }
      });
  };
}
