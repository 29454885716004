import React from 'react';
import PropTypes from 'prop-types';
import WorkPlanSelectGroup from '../../digitalDiary/workPlan/WorkPlanSelectGroup';
import { Box, Button } from '@mui/material';
import GroupPickerDialog from '../../settlements/GroupPickerDialog';
import { connect } from 'react-redux';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import userRoles from '../../../constants/userRoles';

class GroupSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showGroupPicker: !this.props.isPsychological
        ? !this.props.choosenGroup.id
        : !this.props.psychologicalChoosenGroup.id
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    if (!this.props.showAllGroups && this.props.choosenGroup.id === 'all') {
      this.handleSelect(this.getGroups()[0]);
    }
  }

  handleGroupChangeStart() {
    this.setState({ showGroupPicker: true });
  }

  handleGroupChangeCancel() {
    this.setState({ showGroupPicker: false });
  }

  handleSelect(group) {
    this.setState({ showGroupPicker: false }, () => this.props.onGroupChange(group));
  }

  getGroups() {
    if (this.props.isPsychological) {
      return this.props.psychologicalGroups.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    }
    if (!this.props.unit.teacherAllGroupVisibility) {
      const user = this.props.staffMembers.find((obj) => obj.id === this.props.auth.userId);
      if (user) {
        const groups =
          user.role === 'teacher'
            ? this.props.groups.filter((group) => user.groups.find((userGroup) => userGroup.id === group.id))
            : this.props.groups;
        return groups;
      }
      return [];
    }
    return this.props.groups;
  }

  renderChangeGroupDialog() {
    return (
      <GroupPickerDialog
        groups={this.getGroups()}
        archivedGroups={this.props.showArchivedGroups ? this.props.archivedGroups : []}
        showAllGroups={
          this.props.auth.userRole === userRoles.staffMemberTeacher
            ? this.props.unit.teacherAllGroupVisibility && this.props.showAllGroups
            : this.props.showAllGroups
        }
        open={this.state.showGroupPicker}
        onCancel={() => this.handleGroupChangeCancel()}
        onSelect={this.handleSelect}
        cancelDisabled={
          (this.props.isPsychological ? !this.props.psychologicalChoosenGroup.id : this.props.choosenGroup.id) &&
          this.getGroups().length > 0
        }
      />
    );
  }

  render() {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        <WorkPlanSelectGroup
          contrast={this.props.contrast}
          group={this.props.isPsychological ? this.props.psychologicalChoosenGroup : this.props.choosenGroup}
        />
        <Button
          variant="textOutlined"
          sx={{
            mr: 1,
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'flex-end',
            p: 1,
            color: this.props.contrast ? '#000' : '#fff',
            textTransform: 'uppercase',
            '& .MuiButton-startIcon': { mb: '4px', color: this.props.contrast ? '#000' : '#fff' }
          }}
          startIcon={<ChangeCircleOutlinedIcon />}
          onClick={() => this.handleGroupChangeStart()}>
          Zmień grupę
        </Button>
        {this.state.showGroupPicker ? this.renderChangeGroupDialog() : null}
      </Box>
    );
  }
}

GroupSwitch.propTypes = {
  unit: PropTypes.any,
  choosenGroup: PropTypes.object.isRequired,
  psychologicalChoosenGroup: PropTypes.object.isRequired,
  onGroupChange: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  staffMembers: PropTypes.array.isRequired,
  archivedGroups: PropTypes.array,
  showArchivedGroups: PropTypes.bool.isRequired,
  isPsychological: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    unit: state.configuration.unit,
    choosenGroup: state.digitalDiary.choosenGroup,
    psychologicalChoosenGroup: state.psychologicalChoosenGroup,
    staffMembers: state.staffMembers,
    groups: state.groups,
    auth: state.auth,
    archivedGroups: state.archives.groups,
    psychologicalGroups: state.psychologicalGroups
  };
}

export default connect(mapStateToProps)(GroupSwitch);
