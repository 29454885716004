import BaseModel from '../BaseModel';
import { compareNames } from '../../utils/personNamesComparator';

export class EnrolledChildModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.lastName = '';
    this.firstName = '';
    this.enrolledAt = '';
    this.photoUrl = '';
  }
}

export class ActivityModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.color = '';
    this.availableForLegalGuardians = false;
  }
}

export class ActivityDetailsModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.color = '';
    this.enrolledChildren = [];
    this.availableForLegalGuardians = false;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.enrolledChildren = assigned.enrolledChildren
      .map((e) => new EnrolledChildModel().assign(e))
      .sort(compareNames);
  }
}
