/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import PersonAvatar from '../../forms/PersonAvatar';
import { MenuItem, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Typography } from '@mui/material';
import moment from 'moment';
import DotsMenu from '../../common/menu/DotsMenu';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';

const AttendanceListItem = ({ journalEntry, journalDate, onReportAbsence, onReportAttendance }) => {
  // eslint-disable-next-line react/prop-types
  const GetPickUpAtField = ({ entry }) => {
    return !entry.pickedUpAt ? (
      <Typography
        component="span"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', ml: 0.5 }}>
        Brak
        <PriorityHighOutlinedIcon sx={{ color: (theme) => theme.palette.color.error, mb: '4px' }} />
      </Typography>
    ) : (
      <Typography component="span">{moment(entry.pickedUpAt).format('HH:mm')}</Typography>
    );
  };

  const getAttendanceText = (entry, key) => {
    if (entry.droppedOffAt || entry.pickedUpAt) {
      return (
        <Typography
          component="span"
          key={key}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          {`${moment(entry.droppedOffAt).format('HH:mm')} - `}
          <GetPickUpAtField entry={entry} />
        </Typography>
      );
    }
    return null;
  };

  const getAttendanceActions = (entry, key) => {
    if (entry.droppedOffAt || entry.pickedUpAt) {
      return (
        <DotsMenu key={key}>
          {() => [
            <MenuItem key="1" onClick={() => onReportAttendance(entry)} disabled={entry.isArchived}>
              Zmień godziny obecności
            </MenuItem>,
            <MenuItem key="2" onClick={() => onReportAbsence(entry)} disabled={entry.isArchived}>
              Wycofaj obecność
            </MenuItem>
          ]}
        </DotsMenu>
      );
    }
    return null;
  };

  const getAbsenceText = (entry, key) => {
    if (!entry.droppedOffAt && !entry.pickedUpAt) {
      const absenceFrom = entry.absenceFrom || journalDate;
      const absenceTo = entry.absenceTo || journalDate;
      return (
        <Typography component="span">
          nieobecność {`${moment(absenceFrom).format('DD.MM')} - ${moment(absenceTo).format('DD.MM')}`}
        </Typography>
      );
    }
    return null;
  };

  const getAbsenceActions = (entry, key) => {
    if (!entry.droppedOffAt && !entry.pickedUpAt) {
      return (
        <DotsMenu key={key}>
          {() => [
            <MenuItem key="3" onClick={() => onReportAttendance(entry)} disabled={entry.isArchived}>
              Wprowadź godziny obecności
            </MenuItem>
          ]}
        </DotsMenu>
      );
    }
    return null;
  };

  const ListActions = () => [getAttendanceActions(journalEntry, '3'), getAbsenceActions(journalEntry, '4')];

  return (
    <ListItem
      key={journalEntry.childId}
      sx={{
        '&:hover': {
          background: (theme) => theme.palette.background.hover
        }
      }}>
      <ListItemAvatar>
        <PersonAvatar
          initials
          alt={journalEntry.firstName}
          url={journalEntry.photoUrl}
          firstName={journalEntry.firstName}
          lastName={journalEntry.lastName}
        />
      </ListItemAvatar>
      <ListItemText
        primary={`${journalEntry.lastName} ${journalEntry.firstName}`}
        primaryTypographyProps={{
          fontWeight: (theme) => theme.typography.fontWeightBold
        }}
        secondary={(getAbsenceText(journalEntry, '1'), getAttendanceText(journalEntry, '2'))}
        secondaryTypographyProps={{ color: (theme) => theme.palette.color.contrast }}
      />
      <ListItemSecondaryAction>
        <ListActions />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

AttendanceListItem.propTypes = {
  journalEntry: PropTypes.object.isRequired,
  journalDate: PropTypes.object.isRequired,
  onReportAbsence: PropTypes.func.isRequired,
  onReportAttendance: PropTypes.func.isRequired
};

export default AttendanceListItem;
