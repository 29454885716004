/* eslint-disable no-nested-ternary */

'use strict';

import '../../../node_modules/react-virtualized/styles.css';
import PropTypes from 'prop-types';
import { handleColorSelector } from '../common/settlementStatusColor';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { ChargingReportTypes } from '../../constants/chargingReportTypes';
import { CalculationStatus } from '../../constants/calculationStatus';
import * as definedSortTypes from '../../constants/definedSortTypes';
import EmptyState from '../common/EmptyState';
import numberFormatter from '../../utils/numberFormatter';
import { SettlementModel } from '../../models/settlements/SettlementModels';
import { renderMenuItems } from './SettlementsListDotsItems';
import {
  Box,
  Checkbox,
  Table,
  Typography,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Pagination,
  Tooltip
} from '@mui/material';
import SortedTableCell from '../table/SortedTableCell';
import { getComparator, stableSort } from '../../utils/tableSorting';
import SettlementRow from './SettlementRow';
import _ from 'lodash';

const sortedCells = [
  {
    id: 'number',
    label: 'Numer',
    type: definedSortTypes.STRING_SORT
  },
  {
    id: 'name',
    label: 'Imię i nazwisko',
    type: definedSortTypes.STRING_SORT
  },
  {
    id: 'dueType',
    label: 'Opłata',
    type: definedSortTypes.CHARGING_REPORT_TYPE
  },
  {
    id: 'calculatedTotal',
    label: 'Kwota',
    type: definedSortTypes.NUMBER_SORT
  },
  {
    id: 'calculatedTotalUnadjusted',
    label: 'Do zapłaty',
    type: definedSortTypes.NUMBER_SORT
  },
  {
    id: 'calculationStatus',
    label: 'Status',
    type: definedSortTypes.STRING_SORT
  },
  {
    id: 'calculationDate',
    label: 'Wygenerowano',
    type: definedSortTypes.DATE_SORT
  },
  {
    id: 'maturityDate',
    label: 'Termin płatności',
    type: definedSortTypes.DATE_SORT
  }
];

const statusesWithActions = [
  CalculationStatus.approved.value,
  CalculationStatus.draft.value,
  CalculationStatus.failed.value,
  CalculationStatus.paid.value,
  CalculationStatus.partlyPaid.value
];

const SettlementsList = (props) => {
  const { settlements, selectedSettlementsIds, onSelect, onChoose } = props;
  const data = useMemo(
    () =>
      settlements.map((element) => {
        return new SettlementModel().assign({
          ...element,
          name: element.person.lastName + ' ' + element.person.firstName
        });
      }),
    [settlements]
  );

  const allItemsSelected = useMemo(
    () => data.length > 0 && selectedSettlementsIds.length === data.length,
    [data.length, selectedSettlementsIds.length]
  );
  const itemSelected = useCallback(
    (rowIndex) => {
      return selectedSettlementsIds.includes(rowIndex);
    },
    [selectedSettlementsIds]
  );

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState({ property: 'name', type: definedSortTypes.STRING_SORT });

  // const [page, setPage] = useState(0);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage - 1);
  // };

  const sortedRows = useMemo(() => {
    return stableSort(data, getComparator(order, orderBy));
  }, [data, order, orderBy]);

  const handleRequestSort = useCallback(
    (event, property, type) => {
      const isAsc = orderBy.property === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy({ property, type });
    },
    [order, orderBy.property]
  );

  // const blankContainer = (sx) => (

  // );

  return (
    <>
      {data && data.length > 0 ? (
        <Paper>
          <TableContainer
            component={Box}
            sx={{ py: 1, pl: 1, tableLayout: 'fixed', width: '100%', whiteSpace: 'normal' }}>
            <Table aria-label="Lista płatności">
              <TableHead>
                <TableRow>
                  <TableCell align="center" aria-label="wybierz wszystkie" sx={{ overflow: 'hidden' }}>
                    <Tooltip title="Zaznacz wszystkie elementy w tabeli ">
                      <Checkbox
                        inputProps={{ 'aria-label': 'Zaznacz wszystkie' }}
                        checked={allItemsSelected}
                        onChange={() => (allItemsSelected ? onSelect('none') : onSelect('all'))}
                        sx={{ color: (theme) => theme.palette.color.contrast }}
                      />
                    </Tooltip>
                    <Typography
                      sx={{
                        border: 0,
                        clip: 'rect(0 0 0 0)',
                        height: '1px',
                        ml: '-1px',
                        overflow: 'hidden',
                        p: 0,
                        position: 'absolute',
                        top: 0,
                        width: '1px'
                      }}>
                      Zaznacz wszystkie
                    </Typography>
                  </TableCell>
                  {sortedCells.map((cell) => (
                    <SortedTableCell
                      cell={cell}
                      key={cell.id}
                      order={order}
                      orderBy={orderBy.property}
                      align="left"
                      onRequestSort={handleRequestSort}
                    />
                  ))}
                  <TableCell
                    sx={{
                      p: 0,
                      position: 'sticky',
                      right: '0'
                    }}>
                    <Typography
                      sx={{
                        border: 0,
                        clip: 'rect(0 0 0 0)',
                        height: '1px',
                        margin: '-1px',
                        overflow: 'hidden',
                        p: 0,
                        position: 'absolute',
                        width: '1px'
                      }}>
                      Koniec nagłówka
                    </Typography>
                    <Box
                      sx={{
                        width: '30px',
                        height: '60px'
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.map((row, rowIndex) => {
                  return (
                    <SettlementRow
                      canBePaid={row.personAvailableFunds}
                      row={row}
                      key={row.id}
                      rowIndex={rowIndex}
                      onChoose={onChoose}
                      itemSelected={itemSelected}
                      onSelect={onSelect}
                      renderMenuItems={renderMenuItems}
                      statusesWithActions={statusesWithActions}
                      ChargingReportTypes={ChargingReportTypes}
                      numberFormatter={numberFormatter}
                      handleColorSelector={handleColorSelector}
                      CalculationStatus={CalculationStatus}
                      props={props}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : null}
      {data.length === 0 ? (
        <EmptyState isPaper contrast message="Brak elementów spełniających wybrane kryteria." />
      ) : null}
    </>
  );
};

SettlementsList.propTypes = {
  settlements: PropTypes.array.isRequired,
  selectedSettlementsIds: PropTypes.array,
  onChoose: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  onApprove: PropTypes.func,
  onDelete: PropTypes.func,
  onRegisterPayment: PropTypes.func,
  onRegisterRebate: PropTypes.func,
  onRemoveRebate: PropTypes.func,
  onPrint: PropTypes.func,
  personLabel: PropTypes.string.isRequired,
  onCalculateCorrectiveDues: PropTypes.func
};

export default memo(SettlementsList);
