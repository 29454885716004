import { Button, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper } from '@mui/material';
import ContentWrapper from '../common/contentWrapper';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import DinnerDiningOutlinedIcon from '@mui/icons-material/DinnerDiningOutlined';
import { routePaths } from '../../routePaths';
import { browserHistory } from 'react-router';
import { useMemo, useState } from 'react';
import { SearchBar } from '../common/SearchBar';
import EmptyState from '../common/EmptyState';
import { useSelector } from 'react-redux';
import MealAvatar from './MealAvatar';
import ArchivalInfo from '../common/archivalInfo/ArchivalInfo';
import moment from 'moment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const ArchivedMealsPage = () => {
  const archivedMeals = useSelector((state) => state.archivedMeals);
  const [searchText, setSearchText] = useState('');

  const filteredMeals = useMemo(() => {
    if (!searchText) {
      return archivedMeals;
    }
    return archivedMeals.filter((meal) => meal.name.toLowerCase().includes(searchText.toLowerCase()));
  }, [archivedMeals, searchText]);

  const redirectToMealsPage = () => {
    browserHistory.push(routePaths.meals);
  };

  return (
    <LoadingRenderWrapper>
      <>
        <PageHeaderText title="Zarchiwizowane posiłki" titleIcon={<DinnerDiningOutlinedIcon />} />
        <ContentWrapper>
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} md={5} sx={{ dispaly: 'flex', justifyContent: 'center' }}>
              <SearchBar
                label="Wyszukaj"
                SearchOnClick={(value) => {
                  setSearchText(value.target.value);
                }}
                onKeyPress={(value) => {
                  setSearchText(value.target.value);
                }}
                value={searchText}
                onChange={(value) => {
                  setSearchText(value.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={7} sx={{ display: 'flex', justifyContent: 'flex-end', mt: { xs: 2, sm: 0 } }}>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIosIcon />}
                aria-label="wróć do listy posiłków"
                sx={{ my: 1 }}
                onClick={redirectToMealsPage}>
                Wróć do listy poisłków
              </Button>
            </Grid>
          </Grid>
          {archivedMeals.length !== 0 && filteredMeals.length === 0 && (
            <EmptyState isPaper contrast message="Brak wyników wyszukiwania" />
          )}
          {archivedMeals.length === 0 && filteredMeals.length === 0 && (
            <EmptyState isPaper contrast message="Nie zarchiwizowano jeszcze żadnych posiłków" />
          )}

          {filteredMeals.length !== 0 && (
            <Paper>
              <List sx={{ p: 2 }}>
                {filteredMeals
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((meal) => {
                    return (
                      <ListItem
                        key={meal.id}
                        sx={{
                          '&:hover': {
                            background: (theme) => theme.palette.background.hover
                          }
                        }}>
                        <ListItemAvatar>
                          <MealAvatar meal={meal} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={meal.name}
                          primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
                          secondary={
                            <ArchivalInfo
                              archivalInfo={{
                                archivedOn: moment(meal.archivalInfo.archivedOn).format('YYYY-MM-DD'),
                                archivedBy: meal.archivalInfo.archivedBy
                              }}
                            />
                          }
                          secondaryTypographyProps={{ color: (theme) => theme.palette.color.contrast }}
                        />
                      </ListItem>
                    );
                  })}
              </List>
            </Paper>
          )}
        </ContentWrapper>
      </>
    </LoadingRenderWrapper>
  );
};

ArchivedMealsPage.propTypes = {};

export default ArchivedMealsPage;
