/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { SettlementModel } from '../models/settlements/SettlementModels';

export default function childDuesReducer(state = initialState.childDues, action) {
  switch (action.type) {
    case types.LOAD_CHILD_DUES:
      return {
        ...initialState.childDues,
        isLoading: true
      };
    case types.LOAD_CHILD_DUES_SUCCESS:
      return {
        balance: action.childDues.balance,
        dues: action.childDues.dues.map((due) => new SettlementModel().assign(due)),
        isLoading: false
      };
    default:
      return state;
  }
}
