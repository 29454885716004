export const avatarColorPalette = Object.freeze({
  lavenderGradient: {
    color: 'linear-gradient(#BA68C8, #A15AAD)',
    className: 'lavenderGradient',
    name: 'Lawendowy'
  },
  darkPurpleColor: {
    color: 'linear-gradient(#FBAD67,#FA8D26)',
    className: 'orangeColor',
    name: 'Pomarańczowy'
  },
  veryDarkPurpleGradient: {
    color: 'linear-gradient(#4352A4, #903E97)',
    className: 'veryDarkPurpleGradient',
    name: 'Ciemny fioletowy'
  },
  oragnePinkGradient: {
    color: 'linear-gradient(#F77062, #FE5196)',
    className: 'oragnePinkGradient',
    name: 'Pomarańczowo różowy'
  },
  lightPurpleGradient: {
    color: 'linear-gradient(#9575CD, #8266B3)',
    className: 'lightPurpleGradient',
    name: 'Jasny fioletowy'
  },
  yellowGradient: {
    color: 'linear-gradient(#F29F63, #F1B624)',
    className: 'yellowGradient',
    name: 'Żółty'
  },
  purpleYellowGradient: {
    color: 'linear-gradient(#4158D0, #C850C0, #FFCC70)',
    className: 'purpleYellowGradient',
    name: 'Fioletowo żółty'
  },
  pinkGradient: {
    color: 'linear-gradient(#C471F5, #FA71CD)',
    className: 'pinkGradient',
    name: 'Różowy'
  },
  lightBlueGradient: {
    color: 'linear-gradient(#66A6FF, #04E1EF)',
    className: 'lightBlueGradient',
    name: 'Błękitny'
  },
  darkBlueGradient: {
    color: 'linear-gradient(#00C6FB, #005BEA)',
    className: 'darkBlueGradient',
    name: 'Ciemny błękitny'
  },
  blueGreenGradient: {
    color: 'linear-gradient(#009EFD, #00E57A)',
    className: 'blueGreenGradient',
    name: 'Błękitno zielony'
  },
  mintColor: {
    color: 'linear-gradient(#20DFB2, #15846E)',
    className: 'mintColor',
    name: 'Miętowy'
  },
  pinkColor: {
    color: 'linear-gradient(#DE8BFC, #A58BFC)',
    className: 'pinkVioletGradient',
    name: 'Różowo fioletowy'
  },
  coralPinkColor: {
    color: 'linear-gradient(#FC8BAA, #FC5A86)',
    className: 'coralPinkColor',
    name: 'Koralowy'
  }
});

export const avatarColorPaletteArray = Object.values(avatarColorPalette);
