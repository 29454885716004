import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import { Button } from '@mui/material';
import Resizer from 'react-image-file-resizer';
import axios from 'axios';
import UploadProgress from '../../messageBoard/dialog/UploadProgress';
import localStorageProvider from '../../../utils/localStorageProvider';
import { ROOT_URL } from '../../../api/ApiBase';
import FilePickerWithRef from '../FilePickerWithRef/FilePickerWithRef';
import { Box, Button } from '@mui/material';
import ErrorAlertBox from '../ErrorAlertBox';
import PhotoGallery from '../../messageBoard/dialog/PhotoGallery';
import Files from '../../messageBoard/dialog/Files';

const FileUploader = (props) => {
  const { onFileUploaded, onFileRemoved, uploadURL = '/files/upload', files } = props;

  const [uploadErrors, setUploadErrors] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const filePickerRef = useRef(null);

  const resizeFile = (file) => {
    return new Promise((resolve) => {
      const isImage = (mimeType) => /.*?(gif|jpg|jpeg|tiff|png)$/.test(mimeType);
      if (isImage(file.type)) {
        Resizer.imageFileResizer(file, 1920, 1080, 'JPEG', 80, 0, (uri) => resolve(uri), 'blob');
      } else {
        resolve(file);
      }
    });
  };

  const postFileHandler = (file, onUploadProgress) => {
    return resizeFile(file).then((compressedFile) => {
      const data = new FormData();
      data.append('file', compressedFile, file.name);

      const config = {
        onUploadProgress: onUploadProgress(file.name),
        headers: {
          Authorization: `Bearer ${localStorageProvider.getItem('authToken')}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      return axios.post(`${ROOT_URL}${uploadURL}`, data, config).then((res) => {
        const uploadedFile = {
          id: res.data.id,
          fileUrl: res.data.fileUrl,
          thumbnailUrl: res.data.thumbnailUrl,
          name: res.data.name,
          type: res.data.type,
          mimeType: res.data.mimeType
        };
        setUploadProgress((prevState) => prevState.filter((f) => f.name !== file.name));

        // handle file after upload outside component
        onFileUploaded(uploadedFile);
      });
    });
  };

  const handleFileRemoved = (file) => {
    onFileRemoved(file);
  };

  const handleFileAdded = (addedFiles) => {
    const onUploadProgress = (fileName) => (progress) => {
      const percentage = Math.floor((progress.loaded * 100) / progress.total);
      const fileIndex = uploadProgress.findIndex((s) => s.name === fileName);
      const newUploadProgress = [
        ...uploadProgress.slice(0, fileIndex),
        { name: fileName, progress: percentage },
        ...uploadProgress.slice(fileIndex + 1, uploadProgress.length)
      ];

      setUploadProgress(newUploadProgress);
    };

    const maxFileSize = 2 * 1024 * 1024 * 1024;
    // const maxFileSize = 1;
    setUploadProgress(addedFiles.filter((f) => f.size < maxFileSize).map((f) => ({ name: f.name, progress: 0 })));
    let errors = [];

    addedFiles.reduce((prevPromise, file) => {
      if (file.size < maxFileSize) {
        return prevPromise.then(() => {
          return postFileHandler(file, onUploadProgress);
        });
      }
      errors = [...errors, file.name];
      return new Promise((resolve) => {
        setUploadErrors(errors);
        resolve();
      });
    }, Promise.resolve());
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          aria-label="Dodaj obraz lub załącznik"
          onClick={() => filePickerRef.current.open()}
          sx={{ mb: 2 }}>
          Dodaj obraz lub załącznik
        </Button>
      </Box>
      {uploadErrors.length ? (
        <Box sx={{ py: 2, px: 0 }}>
          <ErrorAlertBox state={!!uploadErrors.length} errors={uploadErrors} onClose={() => setUploadErrors([])} />
        </Box>
      ) : null}
      {uploadProgress && uploadProgress.length ? <UploadProgress files={uploadProgress} /> : null}
      <Box sx={{ display: 'none' }}>
        <FilePickerWithRef onDrop={handleFileAdded} ref={filePickerRef} multiple={true} />
      </Box>
      <PhotoGallery
        showAllPhotos={true}
        photos={(files || []).filter((f) => f.type === 'image')}
        onRemoveFile={handleFileRemoved}
      />
      <Files files={(files || []).filter((f) => f.type !== 'image')} onRemoveFile={handleFileRemoved} />
    </Box>
  );
};

FileUploader.propTypes = {
  files: PropTypes.array,
  onFileUploaded: PropTypes.func.isRequired,
  onFileRemoved: PropTypes.func.isRequired,
  uploadURL: PropTypes.string
};

export default FileUploader;
