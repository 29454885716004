import React from 'react';
import { render } from 'react-dom';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { Router, browserHistory, Redirect } from 'react-router';
import routes from './routes';
import { preloadData, loadOnNavigateTo } from './actions/initActions';
import { wrapRoutesActions } from './middleware/routing/routerActionsWrapper';
import ApiBase from './api/ApiBase';
import { verifyAuth } from './middleware/auth/authentication';
import { redirect } from './middleware/redirecting/redirect';

const store = configureStore();
ApiBase.dispatch = store.dispatch;
store.dispatch(preloadData());

render(
  <Provider store={store}>
    <Router
      history={browserHistory}
      routes={[
        wrapRoutesActions(
          routes,
          (location, params, matchedRoute, replace) =>
            verifyAuth(store.getState(), matchedRoute)
              ? loadOnNavigateTo(store.dispatch, store.getState, location, params, matchedRoute)
              : redirect(store.getState(), matchedRoute, replace, location),
          () => {}
        ),
        <Redirect from="*" to="/" />
      ]}
      onUpdate={() => window.scrollTo(0, 0)}
    />
  </Provider>,
  document.getElementById('app')
);
