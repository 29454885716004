import PropTypes from 'prop-types';
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

class NotificationDialog extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.renderActionButtons = this.renderActionButtons.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleConfirm() {
    this.props.onConfirm();
  }

  renderActionButtons() {
    return [
      <Button variant="contained" aria-label={this.props.notificationLabel} key="1" onClick={this.handleConfirm}>
        {this.props.notificationLabel}
      </Button>
    ];
  }

  render() {
    return (
      <Dialog open={this.props.isOpen}>
        <DialogTitle>{this.props.notificationTitle}</DialogTitle>
        <DialogContent>{this.props.notificationText}</DialogContent>
        <DialogActions>{this.renderActionButtons()}</DialogActions>
      </Dialog>
    );
  }
}

NotificationDialog.propTypes = {
  notificationLabel: PropTypes.string,
  notificationText: PropTypes.string.isRequired,
  notificationTitle: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired
};

NotificationDialog.defaultProps = {
  notificationLabel: 'Ok',
  isConfirmPrimary: false,
  isConfirmSecondary: false
};

export default NotificationDialog;
