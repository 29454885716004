import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import { useTheme } from '@mui/material';

const PdfFileIcon = (props) => {
  const theme = useTheme();
  const pdfFileIconColor = theme.palette.components.fileIcons.pdfFile;

  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M14.2929 2.29289C14.1054 2.10536 13.851 2 13.5858 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8.41421C20 8.149 19.8946 7.89464 19.7071 7.70711L14.2929 2.29289ZM18 19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V5C6 4.44772 6.44772 4 7 4H12C12.5523 4 13 4.44772 13 5V8C13 8.55228 13.4477 9 14 9H17C17.5523 9 18 9.44772 18 10V19Z"
        fill={pdfFileIconColor}
      />
      <path
        d="M15.2 10H10.4C9.96 10 9.6 10.36 9.6 10.8V15.6C9.6 16.04 9.96 16.4 10.4 16.4H15.2C15.64 16.4 16 16.04 16 15.6V10.8C16 10.36 15.64 10 15.2 10ZM11.8 13C11.8 13.332 11.532 13.6 11.2 13.6H10.8V14.4H10.2V12H11.2C11.532 12 11.8 12.268 11.8 12.6V13ZM13.8 13.8C13.8 14.132 13.532 14.4 13.2 14.4H12.2V12H13.2C13.532 12 13.8 12.268 13.8 12.6V13.8ZM15.4 12.6H14.8V13H15.4V13.6H14.8V14.4H14.2V12H15.4V12.6ZM10.8 13H11.2V12.6H10.8V13ZM8.8 11.6H8V17.2C8 17.64 8.36 18 8.8 18H14.4V17.2H8.8V11.6ZM12.8 13.8H13.2V12.6H12.8V13.8Z"
        fill={pdfFileIconColor}
      />
    </SvgIcon>
  );
};

export default PdfFileIcon;
