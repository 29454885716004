export const eventTypes = {
  daySchedule: {
    type: 'daySchedule',
    path: 'ramowy-rozklad-dnia',
    name: 'Ramowy rozkład dnia'
  },
  saveWork: {
    type: 'saveWork',
    path: 'zapis-pracy',
    name: 'Zapis pracy wychowawczo-dydaktycznej'
  },
  trip: {
    type: 'trip',
    path: 'wycieczki',
    name: 'Wycieczki'
  },
  additionalActivities: {
    type: 'additionalActivities',
    path: 'zajecia-dodatkowe',
    name: 'Zajęcia dodatkowe'
  },
  workWithParents: {
    type: 'workWithParents',
    path: 'wspolpraca-z-rodzicami',
    name: 'Roczny plan współpracy z rodzicami'
  },
  workPlan: {
    type: 'workPlan',
    path: 'plan-pracy',
    name: 'Plan pracy'
  }
};

export function getNameByType(type) {
  const searchedKey = Object.keys(eventTypes).find((key) => {
    return eventTypes[key].type === type;
  });
  return eventTypes[searchedKey].name || '';
}

export function getPathByType(type) {
  const searchedKey = Object.keys(eventTypes).find((key) => {
    return eventTypes[key].type === type;
  });
  return eventTypes[searchedKey].path || '';
}
