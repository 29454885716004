import ApiBase, { ROOT_URL } from './ApiBase';

class MealsApi extends ApiBase {
  static getMeals() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/meals`).type('application/json'));
  }

  static getMealDetails(id) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/meals/${id}`).type('application/json'));
  }

  static getMealCategories() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/mealCategories`).type('application/json'));
  }

  static createMeal(group) {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/meals`).send(group).type('application/json'));
  }

  static updateMeal(meal) {
    return super.toPromise((agent) => agent.put(`${ROOT_URL}/meals/${meal.id}`).send(meal).type('application/json'));
  }

  static createMealCategory(group) {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/mealCategories`).send(group).type('application/json'));
  }

  static deleteMealCategory(categoryId) {
    console.log(categoryId);
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/mealCategories/${categoryId}`).type('application/json')
    );
  }

  static addMealToCategory(categoryId, meal) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/mealCategories/addMeal`)
        .send({ categoryId: categoryId, mealId: meal.id })
        .type('application/json')
    );
  }

  static removeMealFromCategory(categoryId, mealId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/mealCategories/removeMeal`)
        .send({ categoryId: categoryId, mealId: mealId })
        .type('application/json')
    );
  }

  static getChildMealsByMonth(childId, year, month) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/meals/mealsCalendar/${childId}?year=${year}&month=${month}`).type('application/json')
    );
  }

  static updateMealByDay(childId, date, meals) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/meals/editChildMealsSingle`)
        .send({ childId: childId, startDate: date, mealIds: meals })
        .type('application/json')
    );
  }

  static getArchivedMeals() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/meals/archived`).type('application/json'));
  }

  static archiveMeal(mealId, name, color) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/meals/${mealId}/archive`).send({ name: name, color: color }).type('application/json')
    );
  }
}

export default MealsApi;
