/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ChargingSchemeDetailsModel } from '../models/configuration/chargingSchemes/ChargingSchemeModels';

export default function chargingSchemeDetailsReducer(state = initialState.configuration.chargingSchemeDetails, action) {
  switch (action.type) {
    case types.LOAD_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME_SUCCESS: {
      return new ChargingSchemeDetailsModel().assign({ assignedChildrenSetup: action.children, ...action });
    }
    case types.ADD_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME_SUCCESS: {
      return Object.assign({}, state, {
        assignedChildrenSetup: state.assignedChildrenSetup.map((x) =>
          action.childrenIds.some((id) => id === x.id) ? { ...x, alreadyLinked: true, singleUse: action.singleUse } : x
        )
      });
    }
    case types.REMOVE_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME_SUCCESS: {
      return Object.assign({}, state, {
        assignedChildrenSetup: state.assignedChildrenSetup.map((x) =>
          action.childrenIds.some((id) => id === x.id) ? { ...x, alreadyLinked: false } : x
        )
      });
    }
    default:
      return state;
  }
}
