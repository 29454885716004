import MealsApi from '../api/MealsApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';

export function loadArchivedMealsAsync() {
  return async (dispatch) => {
    try {
      const archivedMeals = await MealsApi.getArchivedMeals();
      dispatch({ type: types.LOAD_ARCHIVED_MEALS_SUCCESS, archivedMeals });
    } catch (e) {
      dispatch({ type: types.LOAD_ARCHIVED_MEALS_FAILURE });
      dispatch(notificationActions.showError('Nie udało się pobrać zarchiwizowanych posiłków'));
    }
  };
}
