import PropTypes from 'prop-types';
import React from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import _ from 'lodash';
import DotsMenu from '../../common/menu/DotsMenu';

const ChildVacationPeriodsList = ({ vacationPeriods, onRemove }) => {
  const renderListItem = (item) => {
    return (
      <ListItem button key={item.id} component="li" aria-label={`Edytuj okres urlopowania ${item.id}`}>
        <ListItemText primary={`Okres urlopowania od ${item.startMonth} do ${item.endMonth}`} />
        <ListItemSecondaryAction>
          <DotsMenu>
            {(onClose) => [
              <ActionConfirmationMenuItem
                key="1"
                onItemClick={onClose}
                actionLabel="Usuń"
                confirmationText="Czy na pewno chcesz usunąć wybrany okres urlopowania?"
                confirmationTitle="Usuń okres urlopowania"
                confirmLabel="Usuń"
                onConfirm={() => onRemove(item)}
                onCancel={() => {}}
              />
            ]}
          </DotsMenu>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderList = (array) => {
    return array.map((item) => renderListItem(item));
  };

  return <List>{renderList(vacationPeriods)}</List>;
};

ChildVacationPeriodsList.propTypes = {
  vacationPeriods: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default ChildVacationPeriodsList;
