'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../forms/DialogForm';
import EmptyState from '../../common/EmptyState';
import * as settlementsActions from '../../../actions/settlementsActions';
import { Link, browserHistory } from 'react-router';
import { SettlementModel } from '../../../models/settlements/SettlementModels';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import { CalculationStatus } from '../../../constants/calculationStatus';
import { PersonTypes } from '../../../constants/personTypes';
import { SettlementModelValidator } from '../../../models/settlements/SettlementModelsValidators';
import SettlementDetails from './SettlementDetails';
import Payments from '../payments/RegisteredPaymentsList';
import PaymentRebate from '../payments/PaymentRebate';
import WarningSummary, { validationTypes } from '../../common/validation/ValidationSummary';
import { tryConvertToNumber } from '../../../utils/numberConverters';
import { routePaths, fromTemplate } from '../../../routePaths';
import moment from 'moment';
import _ from 'lodash';
import withTheme from '@mui/styles/withTheme';
import { Box, Button, Typography } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class SettlementDetailsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settlement: new SettlementModel().assign(this.props.settlement),
      errors: {}
    };

    this.handleAdjustmentChange = this.handleAdjustmentChange.bind(this);
    this.handleCancelDelayInterest = this.handleCancelDelayInterest.bind(this);
    this.handleReserveBalanceFunds = this.handleReserveBalanceFunds.bind(this);
    this.handleRemoveReservedFunds = this.handleRemoveReservedFunds.bind(this);
    this.handleSaveCustomInterest = this.handleSaveCustomInterest.bind(this);
  }

  componentDidMount() {
    this.setState({
      settlement: new SettlementModel().assign(this.props.settlement)
    });
    this.props.actions.getChildBalanceSummary(this.props.settlement.person.id);
  }

  generateStatusBackground(status) {
    switch (status) {
      case 'failed':
        return (theme) => theme.palette.color.error;
      case 'draft':
        return (theme) => theme.palette.color.color18;
      case 'approved':
        return (theme) => theme.palette.color.success;
      case 'canceled':
        return (theme) => theme.palette.color.cancel;
      case 'partlyPaid':
        return (theme) => theme.palette.color.info;
      default:
        return (theme) => theme.palette.color.disabled;
    }
  }

  handleCancelDelayInterest() {
    return this.props.cancelDelayInterest(this.state.settlement).then((r) => {
      if (!r) return;
      this.setState((state) => ({
        settlement: Object.assign(new SettlementModel(), state.settlement, {
          totalDelayInterests: null,
          calculatedTotalWithDelayInterests: null,
          delayInterestCalculationDate: null
        })
      }));
    });
  }

  async handleSaveCustomInterest(value) {
    const { totalDelayInterests, calculatedTotalWithDelayInterests, delayInterestCalculationDate } =
      await this.props.saveCustomInterest(this.state.settlement, value);
    this.setState((state) => ({
      settlement: Object.assign(new SettlementModel(), state.settlement, {
        totalDelayInterests,
        calculatedTotalWithDelayInterests,
        delayInterestCalculationDate
      })
    }));
  }

  handleAdjustmentChange(event) {
    this.setState((state) => {
      const fieldName = event.target.name;
      const { schemeSnapshotId } = event.target;
      const { value } = event.target;
      const settlement = new SettlementModel().assign(state.settlement);
      const scheme = _.find(settlement.schemeResultItems, (s) => s.schemeSnaphot.id === schemeSnapshotId);
      scheme[fieldName] = value;
      if (fieldName === 'adjustmentAmount') {
        settlement.calculatedTotal =
          settlement.calculatedTotalUnadjusted +
          _.sum(settlement.schemeResultItems.map((s) => tryConvertToNumber(s.adjustmentAmount) || 0)) -
          settlement.reservedBalanceFunds;
      }
      return { settlement };
    });
  }

  handleReserveBalanceFunds() {
    return this.props.reserveBalanceFunds(new SettlementModel().assign(this.props.settlement)).then((result) => {
      if (!result) return;
      this.setState((state) => ({
        settlement: Object.assign(new SettlementModel(), state.settlement, {
          reservedBalanceFunds: result.reservedBalanceFunds,
          calculatedTotal:
            state.settlement.calculatedTotalUnadjusted +
            _.sum(state.settlement.schemeResultItems.map((s) => tryConvertToNumber(s.adjustmentAmount) || 0)) -
            result.reservedBalanceFunds
        })
      }));
    });
  }

  handleRemoveReservedFunds() {
    return this.props.removeReservedFunds(new SettlementModel().assign(this.props.settlement)).then((result) => {
      if (!result) return;
      this.setState((state) => ({
        settlement: Object.assign(new SettlementModel(), state.settlement, {
          reservedBalanceFunds: null,
          calculatedTotal:
            state.settlement.calculatedTotalUnadjusted +
            _.sum(state.settlement.schemeResultItems.map((s) => tryConvertToNumber(s.adjustmentAmount) || 0))
        })
      }));
    });
  }

  renderPersonLink(person) {
    const routePath = person.type === PersonTypes.child ? routePaths.childDetails : routePaths.staffMemberDetails;
    return (
      <Link to={fromTemplate(routePath, person.id)} style={{ textDecoration: 'none' }}>
        <Typography
          variant="h6"
          sx={{
            color: (theme) => theme.palette.color.color22,
            display: 'flex',
            flexDirection: 'row',
            textDecoration: 'underline'
          }}>
          {person.firstName} {person.lastName}
        </Typography>
      </Link>
    );
  }

  renderDialogTitle(settlement) {
    const reportTypeName = ChargingReportTypes[settlement.dueType].name.toLowerCase();
    const { theme } = this.props;

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          '@media(max-width: 767px)': {
            flexWrap: 'wrap'
          },
          width: '100%'
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%'
          }}>
          {this.renderPersonLink(settlement.person)}
          <Box sx={{ mx: 1 }}>-</Box>
          <Box
            sx={{
              fontWeight: theme.typography.fontWeightMedium,
              color: theme.palette.color.color7
            }}>
            {settlement.isCorrective() ? `${reportTypeName} (korekta)  ` : reportTypeName}
          </Box>
        </Box>
        <Typography
          sx={{
            background: this.generateStatusBackground(settlement.calculationStatus),
            color: (theme) => theme.palette.color.primary,
            p: '5px',
            fontSize: '9.75px',
            fontWeight: 500,
            letterSpacing: '0.6px',
            lineHeight: 1,
            borderRadius: '0.25em',
            textTransform: 'uppercase',
            whiteSpace: 'nowrap'
          }}>
          {CalculationStatus[settlement.calculationStatus].name}
        </Typography>
      </Box>
    );
  }

  renderFinancialDetailsLinkButton(settlement) {
    if (settlement.person.type !== PersonTypes.child) return null;
    if (settlement.hasFundsAvailable === false) return null;
    return (
      <Button
        variant="outlinedContrast"
        aria-label="rozlicz nadpłaty"
        disabled={this.props.isProcessing}
        onClick={() => browserHistory.push(fromTemplate(routePaths.childFinancialDetails, [settlement.person.id]))}>
        Rozlicz nadpłaty
      </Button>
    );
  }

  renderFailureMessage(message, code, details) {
    return (
      <Box>
        <WarningSummary
          messages={[message]}
          title="Wystąpił błąd podczas generowania rozliczenia"
          type={validationTypes.warning}
        />
        {code === 'unapprovedDrafts' && (
          <Link
            sx={{ textDecoration: 'none' }}
            to={fromTemplate(routePaths.settlementsGroupDetails, [
              details.dueMonth.substring(0, 4),
              details.dueMonth.substring(5, 7),
              details.dueGroupId,
              details.dueId
            ])}>
            <Typography sx={{ color: (theme) => theme.palette.color.contrast, textDecoration: 'underline' }}>
              Przejdź do niezatwierdzonego rozliczenia
            </Typography>
          </Link>
        )}
      </Box>
    );
  }

  renderPayments(settlement) {
    return (
      <Box>
        <Typography variant="h6">WPŁATY</Typography>
        {settlement.payments && settlement.payments.length > 0 ? (
          <Payments
            payments={settlement.payments}
            undoPayment={(paymentId) => this.props.undoPayment(settlement, paymentId)}
            cancelOnlinePayment={(onlinePaymentId) => this.props.cancelOnlinePayment(settlement, onlinePaymentId)}
            personType={settlement.person.type}
          />
        ) : (
          <EmptyState contrast message="Nie zarejetrowano jeszcze żadnych wpłat" />
        )}
      </Box>
    );
  }

  renderSummary() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Box sx={{ marginRight: 2 }}>
          {' '}
          <Typography variant="h6">Saldo portfeli</Typography>
          <Typography>{this.props.balanceAmount} zł</Typography>
        </Box>
        <Box sx={{ marginRight: 2 }}>
          {' '}
          <Typography variant="h6">Nieopłacone rozliczenia</Typography>
          <Typography>{this.props.overdueAmount} zł</Typography>
        </Box>
        <Box>
          <Typography variant="h6">Odsetki</Typography>
          <Typography>{this.props.interestAmount} zł</Typography>
        </Box>
      </Box>
    );
  }

  renderRebates(settlement) {
    return (
      <Box sx={{ p: 0 }}>
        <Typography variant="h6">ZWROT</Typography>
        {settlement.paymentRebate ? (
          <PaymentRebate paymentRebate={settlement.paymentRebate} />
        ) : (
          <EmptyState contrast message="Nie zarejetrowano żadnego zwrotu" />
        )}
      </Box>
    );
  }

  renderCalculations(settlement) {
    return (
      <Box>
        <SettlementDetails
          settlement={settlement}
          onAdjustmentChange={this.handleAdjustmentChange}
          onCancelDelayInterest={this.handleCancelDelayInterest}
          onReserveBalanceFunds={this.handleReserveBalanceFunds}
          onRemoveReservedFunds={this.handleRemoveReservedFunds}
          onSaveCustomInterest={this.handleSaveCustomInterest}
        />
        {settlement.calculationStatus === CalculationStatus.approved.value ||
        settlement.calculationStatus === CalculationStatus.paid.value ||
        settlement.calculationStatus === CalculationStatus.partlyPaid.value
          ? this.renderPayments(settlement)
          : null}
        {settlement.calculationStatus === CalculationStatus.paid.value ? this.renderRebates(settlement) : null}
      </Box>
    );
  }
  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    const { settlement } = this.state;
    const saveDisabled = settlement.calculationStatus !== CalculationStatus.draft.value;
    return (
      <DialogForm
        header={this.renderDialogTitle(settlement)}
        onSave={() => onSave(settlement)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        statePathToUi="settlementsUi"
        onValidate={() => new SettlementModelValidator().validate(settlement)}
        onValidationDone={(errors) => this.setState({ errors })}
        saveLabel="Zapisz"
        cancelLabel={saveDisabled ? 'Zamknij' : 'Anuluj'}
        saveDisabled={saveDisabled}
        extraActionContent={this.renderFinancialDetailsLinkButton(settlement)}>
        <Box
          sx={{
            fontSize: 12,
            width: '100%',
            mb: 2
          }}>
          <Typography>Numer {settlement.number || 'zostanie wygenerowany po zatwierdzeniu'}</Typography>
          <Typography>Wygenerowano {moment(settlement.calculationDate).format('YYYY-MM-DD HH:mm')}</Typography>
          {settlement.approvalDate ? (
            <Typography>
              Zatwierdzono {moment(settlement.approvalDate).format('YYYY-MM-DD HH:mm')}
              {settlement.approvedBy ? ` przez ${settlement.approvedBy}` : ''}
            </Typography>
          ) : null}
          {settlement.delayInterestCalculationDate != null ? (
            <Typography>
              Odsetki naliczono {moment(settlement.delayInterestCalculationDate).format('YYYY-MM-DD HH:mm')}
            </Typography>
          ) : null}
          {settlement.export && (
            <Typography>Wyeksportowano {moment(settlement.export.exportedAt).format('YYYY-MM-DD HH:mm')}</Typography>
          )}
        </Box>
        {settlement.calculationStatus === CalculationStatus.failed.value
          ? this.renderFailureMessage(settlement.failureReason, settlement.failureReasonCode, settlement.failureDetails)
          : this.renderCalculations(settlement)}
          {!this.props.isLoading && this.renderSummary(settlement)}
      </DialogForm>
    );
  }
}

SettlementDetailsDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  settlement: PropTypes.object.isRequired,
  undoPayment: PropTypes.func.isRequired,
  cancelOnlinePayment: PropTypes.func.isRequired,
  cancelDelayInterest: PropTypes.func.isRequired,
  saveCustomInterest: PropTypes.func,
  reserveBalanceFunds: PropTypes.func.isRequired,
  removeReservedFunds: PropTypes.func.isRequired,
  theme: PropTypes.object,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    balanceAmount: state.childBalanceSummary.balanceAmount,
    overdueAmount: state.childBalanceSummary.overdueAmount,
    interestAmount: state.childBalanceSummary.interestAmount,
    isLoading: state.childBalanceSummary.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settlementsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(SettlementDetailsDialog));
