/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function registerPaymentUiReducer(state = initialState.registerPaymentUi, action) {
  switch (action.type) {
    case types.REGISTER_PAYMENT: {
      return UiModel.start();
    }
    case types.REGISTER_PAYMENT_CANCEL: {
      return UiModel.cancel();
    }
    case types.REGISTER_PAYMENT_SUBMIT: {
      return UiModel.submit();
    }
    case types.REGISTER_PAYMENT_SUCCESS: {
      return UiModel.success();
    }
    case types.REGISTER_PAYMENT_FAILURE: {
      return UiModel.failure(action.errors);
    }
    //
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.registerPaymentUi);
    }
    default:
      return state;
  }
}
