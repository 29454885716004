import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import userRoles from '../../constants/userRoles';
import { loadUnreadedPosts } from '../../actions/staffMessageBoardActions';
import BoardPostCard from './post/BoardPostCard';
import EmptyState from '../common/EmptyState';
import { Box, Button, Paper } from '@mui/material';
import Masonry from '@mui/lab/Masonry';

const BoardPostsList = (props) => {
  const {
    posts,
    pageCount,
    hasNextPage,
    onEdit,
    onDelete,
    onLoadMoreClick,
    dontDisplayHistory = false,
    isStaffMemberBoard
  } = props;
  const auth = useSelector((state) => state.auth);
  const editConfig = useSelector((state) => state.configuration.unit.postsModifyingEnabled);
  const dispatch = useDispatch();

  const readingAll = useSelector((state) => state.staffMessageBoard.readingAll);
  useEffect(() => {
    const loadUnreadedPostsAsync = async () => {
      await dispatch(loadUnreadedPosts());
    };
    if (isStaffMemberBoard) loadUnreadedPostsAsync();
  }, [dispatch, isStaffMemberBoard, readingAll]);

  const canEdit = (post) => {
    if (editConfig && auth.userRole !== userRoles.legalGuardian) {
      return editConfig;
    }
    return post.author.id === auth.userId || auth.userRole === userRoles.staffMemberPrincipal;
  };

  if (posts.length === 0) {
    return (
      <Paper sx={{ py: 5 }}>
        <EmptyState contrast message="Brak wpisów" />
      </Paper>
    );
  }

  return (
    <>
      <Masonry
        columns={{ xs: 1, md: posts.length > 1 ? 2 : 1 }}
        spacing={2}
        sx={{ mx: -2, mt: 0, p: 0, width: 'auto' }}>
        {posts.map((post, index) => {
          return (
            <Box sx={{ borderRadius: 50 }} key={post.id}>
              <BoardPostCard
                key={post.id}
                post={post}
                canEdit={canEdit(post)}
                onEdit={() => onEdit(post)}
                onDelete={() => onDelete(post)}
                dontDisplayHistory={dontDisplayHistory}
                isStaffMemberBoard={isStaffMemberBoard}
              />
            </Box>
          );
        })}
      </Masonry>

      {pageCount > 0 && hasNextPage && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center'
          }}>
          <Button
            variant="contained"
            aria-label="Załaduj kolejne wpisy"
            sx={{ mr: 3 }}
            onClick={onLoadMoreClick}
            fullwidth="true">
            Załaduj kolejne wpisy
          </Button>
        </Box>
      )}
    </>
  );
};

BoardPostsList.propTypes = {
  posts: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  onLoadMoreClick: PropTypes.func.isRequired,
  customPermissions: PropTypes.bool,
  loadingMore: PropTypes.bool,
  dontDisplayHistory: PropTypes.bool,
  isStaffMemberBoard: PropTypes.bool
};

export default BoardPostsList;
