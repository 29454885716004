import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import api from '../api/DigitalDiaryApi';

export function loadDiagnosticSchemesSetAsync() {
  return (dispatch) => {
    api
      .loadDiagnosticSchemas()
      .then((schema) => {
        dispatch({
          type: types.LOAD_DIAGNOSTICSCHEMES_SETS_SUCCESS,
          schema
        });
      })
      .catch(() => {
        // console.log(error);
      });
  };
}

export function addDiagnosticSchemesSetStart() {
  return { type: types.CREATE_DIAGNOSTICSCHEMES_SET };
}

export function addDiagnosticSchemesSetCancel() {
  return { type: types.CREATE_DIAGNOSTICSCHEMES_SET_CANCEL };
}

export function addDiagnosticSchemesSetAsync(newSchema) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_DIAGNOSTICSCHEMES_SET_SUBMIT });
    //Strzelanie async do api
    api.createDiagnosticSchemas(newSchema).then((schema) => {
      dispatch({
        type: types.CREATE_DIAGNOSTICSCHEMES_SET_SUCCESS,
        schema
      });
    });
    dispatch(notificationActions.showSuccess(`Dodano schemat diagnozy o nazwie '${newSchema.title}'`));
  };
}

export function updateDiagnosticSchemesSetStart() {
  return { type: types.UPDATE_DIAGNOSTICSCHEMES_SET };
}

export function updateDiagnosticSchemesSetCancel() {
  return { type: types.UPDATE_DIAGNOSTICSCHEMES_SET_CANCEL };
}

export function updateDiagnosticSchemesSetAsync(schema) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_DIAGNOSTICSCHEMES_SET_SUBMIT });
    //Strzelanie do api
    api.updateDiagnosticSchemas(schema).then(() => {
      dispatch({
        type: types.UPDATE_DIAGNOSTICSCHEMES_SET_SUCCESS,
        schema
      });
      dispatch(notificationActions.showSuccess(`Zaktualizowano schemat diagramów o nowej nazwie '${schema.title}'`));
    });
  };
}

export function removeDiagnosticSchemesSetAsync(schema) {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_DIAGNOSTICSCHEMES_SET });
    //Strzal do api
    api.removeDiagnosticSchemas(schema.id).then(() => {
      dispatch(notificationActions.showSuccess(`Usunięto schemat diagramów '${schema.title}'`));
      dispatch({ type: types.REMOVE_DIAGNOSTICSCHEMES_SET_SUCCESS, schema });
    });
  };
}
