import PropTypes from 'prop-types';
import React from 'react';
import { calendarDaysByWeeksProvider, calendarWeeksProvider } from './CalendarDataProvider';
import classNames from 'classnames';
import CalendarDayContent from './Grid/CalendarDayContent';
import CalendarDay from './Grid/CalendarDay';
import dimensions from 'react-dimensions';
import { isCalendarExtraSize, isSmall } from './Grid/sizeHelper';
import { compose } from 'redux';
import { Box, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

const useStyles = (theme) => ({
  calendarHeaderSmall: {
    display: 'none'
  },
  calendarHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  calendarHeaderItem: {
    flex: '0 calc(100% / 7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.components.calendar.headerIconColor,
    textTransform: 'uppercase',
    height: '36px',
    padding: '0.5px',
    boxSizing: 'border-box'
  },
  calendarGrid: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: '0.5px',
    background: theme.palette.components.calendar.calendarBackground
  },
  calendarGridWeekItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('1333')]: {
      flexDirection: 'column'
    }
  },
  calendarGridItem: {
    flex: '0 0 calc(100% / 7)',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    padding: '0.5px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('1333')]: {
      flex: '0 1 100%',
      width: '100%',
      height: '50px'
    }
  },
  defaultDayButton: {
    minWidth: '65px',
    width: '100%',
    padding: 1
  }
});

class Calendar extends React.Component {
  render() {
    const { classes } = this.props;
    const { date, weekOffset, onDaySelected, markedDays, onDayRender, onDayContentRender, containerWidth } = this.props;
    return (
      <Box>
        <Box
          className={
            isSmall(containerWidth) || !isCalendarExtraSize() ? classes.calendarHeaderSmall : classes.calendarHeader
          }>
          {calendarWeeksProvider(weekOffset).map((week) => (
            <Box key={week} className={classes.calendarHeaderItem}>
              <Typography sx={{ color: (theme) => theme.palette.components.calendar.weekColor }}>{week}</Typography>
            </Box>
          ))}
        </Box>
        <Box className={classes.calendarGrid}>
          {calendarDaysByWeeksProvider(date, weekOffset, markedDays).map((week, weekIndex) => (
            <Box key={`week-${weekIndex}`} className={classes.calendarGridWeekItem}>
              {week.map((day, dayIndex) => {
                return (
                  <Box key={`day-${dayIndex}`} className={classNames(classes.calendarGridItem, day.classNames)}>
                    {onDayRender(
                      day,
                      onDayContentRender,
                      onDaySelected,
                      containerWidth,
                      day.isWeekend || !day.isCurrentMonth
                    )}
                  </Box>
                );
              })}
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
}

Calendar.propTypes = {
  classes: PropTypes.object,
  weekOffset: PropTypes.number.isRequired,
  date: PropTypes.object.isRequired,
  markedDays: PropTypes.array.isRequired,
  onDaySelected: PropTypes.func,
  onDayRender: PropTypes.func,
  onDayContentRender: PropTypes.func,
  containerWidth: PropTypes.number.isRequired
};

Calendar.defaultProps = {
  weekOffset: 0,
  markedDays: [],
  onDayRender: (day, contentRender, onDaySelected, containerWidth, isDisabled) => (
    <CalendarDay
      day={day}
      onDaySelected={onDaySelected}
      contentRender={contentRender}
      isDisabled={isDisabled}
      containerWidth={containerWidth}
    />
  ),
  onDayContentRender: (day, containerWidth) => <CalendarDayContent day={day} containerWidth={containerWidth} />
};

export default compose(dimensions())(withStyles(useStyles)(Calendar));
