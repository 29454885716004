'use strict';

// import '../../../node_modules/react-virtualized/styles.css';
import PropTypes from 'prop-types';
import React from 'react';
import numberFormatter from '../../utils/numberFormatter';
import _ from 'lodash';
import { Box, Typography } from '@mui/material';

const DuesToPaySummary = ({ duesToPay }) => {
  return (
    <Box sx={{ display: 'flex', color: (theme) => theme.palette.color.primary }}>
      <Typography sx={{ mr: 1 }}>kwota razem</Typography>
      <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
        {numberFormatter.format(_.sum(duesToPay.map((d) => d.getTotalAmount())))} zł
      </Typography>
    </Box>
  );
};

DuesToPaySummary.propTypes = {
  duesToPay: PropTypes.array.isRequired
};

export default DuesToPaySummary;
