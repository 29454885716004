import {
  Box,
  Checkbox,
  FormHelperText,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { useCallback, useMemo } from 'react';
import { MealModel } from '../../models/meals/MealModels';

export const MealMultiPicker = ({ meals, categories, handleSelect, selected, error }) => {
  const handleSelectMeal = useCallback(
    (meal) => {
      console.log(selected, meal);
      if (selected.some(({ id }) => id === meal.id)) {
        handleSelect(selected.filter(({ id }) => id !== meal.id));
      } else {
        handleSelect([...selected, new MealModel().assign(meal)]);
      }
    },
    [handleSelect, selected]
  );

  const sortedMeals = useMemo(() => {
    return meals.sort((a, b) => a.name.localeCompare(b.name));
  }, [meals]);

  return (
    <Box>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {sortedMeals
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((meal) => {
            const labelId = `meals-list-label-${meal.name}`;

            return (
              <ListItem key={meal.id} disablePadding>
                <ListItemButton
                  role={undefined}
                  dense
                  onClick={() => {
                    handleSelectMeal(meal);
                  }}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      checked={selected.some(({ id }) => id === meal.id)}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={meal.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
      </List>
      {!!error ? (
        <FormHelperText sx={{ fontSize: 16 }} error>
          {error}
        </FormHelperText>
      ) : null}
    </Box>
  );
};
