import PropTypes from 'prop-types';
import React from 'react';
import GroupAvatar from '../common/groups/GroupAvatar';
import LinkAdapter from '../common/LinkAdapter/LinkAdapter';
import { routePaths, fromTemplate } from '../../routePaths';
import { List, ListItemButton, Divider, ListItemAvatar, ListItemText, ListItem, Typography } from '@mui/material';

const GroupsList = ({ groups, to }) => {
  return (
    <List>
      {groups.map((group) => {
        return (
          <ListItem
            key={group.id}
            sx={{
              p: 0,
              '&:hover': {
                background: (theme) => theme.palette.background.hover
              }
            }}>
            <ListItemButton
              component={LinkAdapter}
              to={to ? fromTemplate(to, [group.id]) : fromTemplate(routePaths.groupDetails, [group.id])}
              sx={{ textDecoration: 'none', color: (theme) => theme.palette.text.primary }}>
              <ListItemAvatar>
                <GroupAvatar group={group} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                    {group.name}
                  </Typography>
                }
                secondary={group.isTemporary ? 'Tymczasowa' : `Rok ${group.yearbook}/${group.yearbook + 1}`}
                secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.text.primary } }}
              />
            </ListItemButton>
            <Divider />
          </ListItem>
        );
      })}
    </List>
  );
};

GroupsList.propTypes = {
  groups: PropTypes.array.isRequired
};

export default GroupsList;
