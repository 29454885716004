'use strict';

import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import AvatarDropzone from '../../../../common/avatar/AvatarDropzone';

const ChangeProfilePhotoForm = ({ photoUrl, onPhotoDrop, onPhotoRemove }) => {
  return (
    <Box>
      <Typography variant="h6" sx={{ my: 1 }}>
        Twoje zdjęcie
      </Typography>
      <AvatarDropzone onPhotoDrop={onPhotoDrop} onPhotoRemove={onPhotoRemove} photoUrl={photoUrl} sx={{ my: 1 }} />
      <Box sx={{ my: 1 }}>Tutaj możesz zmienić swoje zdjęcie. Maksymalny rozmiar pliku to 750KB</Box>
    </Box>
  );
};

ChangeProfilePhotoForm.propTypes = {
  photoUrl: PropTypes.string,
  onPhotoDrop: PropTypes.func.isRequired,
  onPhotoRemove: PropTypes.func.isRequired
};

export default ChangeProfilePhotoForm;
