import PropTypes from 'prop-types';
import React from 'react';
import TimePicker from '../../../common/TimePicker';
import { TimeModel } from '../../../../models/TimeModel';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import { Grid } from '@mui/material';

const UnitWorkingHoursForm = ({ workingHours, onChange, errors }) => {
  const { from, to } = workingHours;

  return (
    <FieldsetWrapper legend="Dane godzinowe rozpoczęcia oraz zakończenia pracy">
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
          <TimePicker
            hintText="Godzina rozpoczęcia pracy"
            floatingLabelText="Godzina rozpoczęcia pracy"
            floatingLabelFixed={false}
            name="from"
            defaultTime={from.toDate()}
            value={from.toDate() || null}
            onChange={(e, value) => onChange({ target: { name: 'from', value: new TimeModel().parse(value) } })}
            errorText={errors.from}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, mt: { xs: 1, sm: 0 } }}>
          <TimePicker
            hintText="Godzina zakończenia pracy"
            floatingLabelText="Godzina zakończenia pracy"
            floatingLabelFixed={false}
            name="to"
            defaultTime={to.toDate()}
            value={to.toDate() || null}
            onChange={(e, value) => onChange({ target: { name: 'to', value: new TimeModel().parse(value) } })}
            errorText={errors.to}
          />
        </Grid>
      </Grid>
    </FieldsetWrapper>
  );
};

UnitWorkingHoursForm.propTypes = {
  workingHours: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default UnitWorkingHoursForm;
