import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';
import initialState from './initial';

export default function exportChildrenUiReducer(state = initialState.configuration.importChildrenSummaryUi, action) {
  switch (action.type) {
    case types.IMPORT_CHILDREN_SUMMARY_START: {
      return UiModel.start();
    }
    case types.IMPORT_CHILDREN_SUMMARY_CANCEL: {
      return UiModel.cancel();
    }
    default:
      return state;
  }
}
