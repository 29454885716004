import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EventCalendarIcon from '@mui/icons-material/Event';
import * as digitalDiaryActions from '../../../actions/digitalDiaryActions';
import CustomCalendar from './calendar/CustomCalendar';
import PostEditor from './BoardPostEditDialog';
import SaveWorkDialog from './SaveWorkDialog';
import { DigitalDiaryEventModel } from '../../../models/digitalDiary/digitalDiaryEvent/DigitalDiaryEventModel';
import ActivitiesDialog from './ActivitiesDialog';
import moment from 'moment';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../../../routePaths';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import EventDetailsDialog from '../../eventsCalendar/dialogs/EventDetailsDialog';
import TimeTableEventDialog from './TimeTableEventDialog';
import { eventTypes, getNameByType, getPathByType } from '../../../constants/eventTypes';
import ReportsIcon from '@mui/icons-material/InsertChart';
import DatePicker from '../../common/DatePicker';
import GroupSwitch from '../../common/groups/GroupSwitch';
import { Box, Button, FormControl, Grid, Input, InputAdornment, InputLabel, Paper } from '@mui/material';
import BoardPostsList from './BoardPostsList';
import ChildrenApi from '../../../api/ChildrenApi';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import { SearchBar } from '../../common/SearchBar';
import ContentWrapper from '../../common/contentWrapper';

function storeBlobAs(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.download = filename;
  link.href = url;
  link.click();
  document.body.removeChild(link);
}

class CustomCalendarPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleCalendarDateChange = this.handleCalendarDateChange.bind(this);
    this.handlePostSave = this.handlePostSave.bind(this);
    this.handlePostCancel = this.handlePostCancel.bind(this);
    this.handlePostEdit = this.handlePostEdit.bind(this);
    this.renderCreateEventDialog = this.renderCreateEventDialog.bind(this);
    this.handleDetailsOpen = this.handleDetailsOpen.bind(this);
    this.handleDetailsClose = this.handleDetailsClose.bind(this);
    this.handleShowEventFullDetails = this.handleShowEventFullDetails.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleGenerateReport = this.handleGenerateReport.bind(this);
    this.getEventsAtDay = this.getEventsAtDay.bind(this);
    this.handleTextSearchChange = this.handleTextSearchChange.bind(this);
    this.handleSearchByEnter = this.handleSearchByEnter.bind(this);
    this.handleTextSearch = this.handleTextSearch.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);
    this.handlePostDelete = this.handlePostDelete.bind(this);
    this.handleChangeDateFrom = this.handleChangeDateFrom.bind(this);
    this.handleChangeDateTo = this.handleChangeDateTo.bind(this);
    this.handleDownloadXMLExport = this.handleDownloadXMLExport.bind(this);
    this.state = {
      currentPost: null,
      isEventDetailsDialogOpen: false,
      eventDetailsDate: null,
      textFilter: '',
      eventDateFrom: '',
      eventDateTo: '',
      filteredEvents: {},
      downloadingXML: false
    };
  }

  getEventsAtDay(date) {
    return this.props.posts.filter((x) => x.eventDate === date.format('YYYY-MM-DD'));
  }

  handleGroupChange(group) {
    this.props.digitalDiaryEvents.loadEventsAsync({
      eventType: this.props.eventType,
      year: this.props.year,
      month: this.props.month,
      groupId: group.id
    });
    this.props.digitalDiaryEvents.changeChoosenGroup(group);
  }

  handleCalendarDateChange(date) {
    const d = moment(date);
    browserHistory.push(
      fromTemplate(
        `/dziennik-elektroniczny/kalendarz/${getPathByType(this.props.params.eventType)}/:eventType/:year/:month`,
        [this.props.params.eventType, d.format('YYYY'), d.format('MM')]
      )
    );
  }

  handleShowEventFullDetails(date) {
    browserHistory.push(
      fromTemplate(routePaths.electronicDiaryMessageBoard, [
        this.props.eventType,
        date.format('YYYY'),
        date.format('MM'),
        date.format('DD'),
        this.props.choosenGroup.id
      ])
    );
  }

  handleFilterDayToChange() {}

  handleGenerateReport() {
    browserHistory.push(fromTemplate(routePaths.reports));
  }

  handlePostEdit(date) {
    this.setState({
      currentPost: new DigitalDiaryEventModel().assign({
        eventDate: date || moment().format(),
        group: this.props.choosenGroup.id
      })
    });
    this.props.digitalDiaryEvents.createEventStart();
  }

  async handlePostDelete(post) {
    this.setState({
      currentPost: null
    });
    await this.props.digitalDiaryEvents.deleteEventAsync(post.id);
    if (this.state.textFilter.length > 0) {
      this.handleTextSearch();
    }
  }

  async handlePostSave(post) {
    await this.props.digitalDiaryEvents.addNewEventAsync({ eventData: post, eventType: this.props.params.eventType });
    if (this.state.textFilter.length > 0) {
      this.handleTextSearch();
    }
  }

  handleChangeDateFrom(date) {
    this.setState({ eventDateFrom: moment(date).format('YYYY-MM-DD') });
  }

  handleChangeDateTo(date) {
    this.setState({ eventDateTo: moment(date).format('YYYY-MM-DD') });
  }

  handlePostCancel() {
    this.setState({ currentPost: null });
    this.props.digitalDiaryEvents.createEventCancel();
  }

  handleDetailsOpen(date) {
    const events = this.getEventsAtDay(date.date);
    this.openDetailsForStaff(date, events);
  }

  openDetailsForLegalGuardian(date, events) {
    if (events.length) {
      this.handleShowEventFullDetails(date.date);
    }
  }

  openDetailsForStaff(date, events) {
    if (events.length) {
      this.setState({ isEventDetailsDialogOpen: true, eventDetailsDate: date });
    } else {
      this.handlePostEdit(date.date);
    }
  }

  handleDetailsClose() {
    this.setState({ isEventDetailsDialogOpen: false, eventDetailsDate: null });
  }

  handleSearchByEnter(event) {
    if (event.charCode === 13) {
      this.handleTextSearch();
    }
  }

  handleTextSearchChange(e) {
    e.persist();
    this.setState({ textFilter: e.target.value });
  }

  async handleTextSearch() {
    this.setState({ filteredEvents: {} });
    const date = `${this.props.year}-${this.props.month}`;
    const data = await this.props.digitalDiaryEvents.eventsFilter(
      date,
      this.props.params.eventType,
      this.state.textFilter,
      this.props.choosenGroup.id,
      this.state.eventDateFrom,
      this.state.eventDateTo
    );
    this.setState({
      filteredEvents: data
    });
  }

  async handleDownloadXMLExport() {
    this.setState({
      downloadingXML: true
    });
    const month = `${this.props.year}-${this.props.month}`;
    const groupId = this.props.choosenGroup.id;
    const { response, headers } = await ChildrenApi.getJournalExport(month, groupId);
    const filename = headers['content-disposition'].split('filename=')[1] || 'dziennik_przedszkola.zip';
    storeBlobAs(response, filename);
    this.setState({
      downloadingXML: false
    });
  }

  renderCreateEventDialog() {
    if (this.props.ui.isEditing) {
      switch (this.props.params.eventType) {
        case eventTypes.additionalActivities.type:
          return (
            <ActivitiesDialog
              post={this.state.currentPost}
              groups={this.props.groups}
              activities={this.props.activities}
              onSave={(post) => this.handlePostSave(post)}
              onCancel={(post) => this.handlePostCancel(post)}
              isEditing={this.props.ui.isEditing}
              isProcessing={this.props.ui.isProcessing}
              canClearDate={false}
              isGroupArchived={this.props.archivedGroups.some((group) => group.id === this.props.choosenGroup.id)}
            />
          );
        case eventTypes.saveWork.type:
          return (
            <SaveWorkDialog
              defaultPublishingHouseId={this.props.defaultPublishingHouseId}
              post={this.state.currentPost}
              groups={this.props.groups}
              activities={this.props.activities}
              onSave={(post) => this.handlePostSave(post)}
              onCancel={(post) => this.handlePostCancel(post)}
              isEditing={this.props.ui.isEditing}
              isProcessing={this.props.ui.isProcessing}
              canClearDate={false}
              user={this.props.auth.userFullName}
              auth={this.props.auth}
              staffMembers={this.props.staffMembers}
              isGroupArchived={this.props.archivedGroups.some((group) => group.id === this.props.choosenGroup.id)}
            />
          );
        default:
          return (
            <PostEditor
              post={this.state.currentPost}
              groups={this.props.groups}
              activities={this.props.activities}
              onSave={(post) => this.handlePostSave(post)}
              onCancel={(post) => this.handlePostCancel(post)}
              isEditing={this.props.ui.isEditing}
              isProcessing={this.props.ui.isProcessing}
              canClearDate={false}
              isGroupArchived={this.props.archivedGroups.some((group) => group.id === this.props.choosenGroup.id)}
            />
          );
      }
    }
    return null;
  }

  renderEventDetailsDialog() {
    if (this.state.isEventDetailsDialogOpen) {
      return this.props.params.eventType !== eventTypes.daySchedule.type ? (
        <EventDetailsDialog
          isDialogOpen={this.state.isEventDetailsDialogOpen}
          isGroupArchived={this.props.archivedGroups.some((group) => group.id === this.props.choosenGroup.id)}
          onRequestClose={this.handleDetailsClose}
          header="Wydarzenia"
          events={this.getEventsAtDay(this.state.eventDetailsDate.date)}
          onShowEventFullDetails={() => this.handleShowEventFullDetails(this.state.eventDetailsDate.date)}
          onCreateNewEvent={() => this.handlePostEdit(this.state.eventDetailsDate.date.format())}
        />
      ) : (
        <TimeTableEventDialog
          date={this.state.eventDetailsDate.date}
          isDialogOpen={this.state.isEventDetailsDialogOpen}
          onRequestClose={this.handleDetailsClose}
          daysOff={this.props.daysOff}
          isGroupArchived={this.props.archivedGroups.some((group) => group.id === this.props.choosenGroup.id)}
          header="Wydarzenia"
          events={this.props.posts}
          key={this.props.posts.length}
          onShowEventFullDetails={() => this.handleShowEventFullDetails(this.state.eventDetailsDate.date)}
          onCreateNewEvent={() => this.handlePostEdit(this.state.eventDetailsDate.date.format())}
          onCloneEvents={(informations) =>
            this.props.digitalDiaryEvents.cloneEventsAsync(informations, {
              eventType: this.props.eventType,
              year: this.props.year,
              month: this.props.month,
              groupId: this.props.choosenGroup.id
            })
          }
        />
      );
    }
    return null;
  }

  handleClearFilters() {
    this.setState({
      filteredEvents: {},
      textFilter: '',
      eventDateFrom: '',
      eventDateTo: ''
    });
  }

  renderCreateEventButton() {
    return (
      <Button
        variant="contained"
        arial-label="Nowy wpis"
        disabled={
          this.props.archivedGroups.some((group) => group.id === this.props.choosenGroup.id) ||
          this.props.choosenGroup.id === ''
        }
        onClick={() => this.handlePostEdit()}>
        Nowy wpis
      </Button>
    );
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText title={getNameByType(this.props.params.eventType)} titleIcon={<EventCalendarIcon />} />
          <ContentWrapper>
            <Grid container sx={{ mb: 2 }}>
              <Grid
                item
                xs={12}
                md={6}
                xl={4}
                sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flexWrap: 'wrap', px: 1 }}>
                <SearchBar
                  label="Szukaj"
                  SearchOnClick={this.handleTextSearch}
                  handleTextSearch={this.handleTextSearch}
                  onKeyDown={this.handleSearchByEnter}
                  value={this.state.textFilter}
                  onChange={this.handleTextSearchChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                xl={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  pt: 2
                }}>
                <Box sx={{ mx: { xs: 1, md: 2 } }}>
                  <DatePicker
                    contrast
                    floatingLabelText="Data od"
                    onChange={(e, date) => this.handleChangeDateFrom(date)}
                    value={this.state.eventDateFrom ? new Date(this.state.eventDateFrom) : null}
                    name="eventDateFrom"
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <FormControl
                        sx={{
                          display: 'flex',
                          p: 3,
                          alignItems: 'center',
                          color: (theme) => theme.palette.color.primary
                        }}>
                        <InputLabel sx={{ color: (theme) => theme.palette.color.hidden }}>Data od</InputLabel>
                        <Input
                          disableUnderline
                          ref={inputRef}
                          {...inputProps}
                          sx={{
                            color: (theme) => theme.palette.color.hidden,

                            svg: { color: (theme) => theme.palette.color.hidden },
                            input: { color: (theme) => theme.palette.color.hidden, width: 90 },
                            label: { color: (theme) => theme.palette.color.hidden },
                            borderBottom: (theme) => `1px solid ${theme.palette.color.primary}`
                          }}
                          endAdornment={<InputAdornment position="end">{InputProps.endAdornment}</InputAdornment>}
                        />
                      </FormControl>
                    )}
                  />
                </Box>
                <Box sx={{ mx: { xs: 1, md: 2 } }}>
                  <DatePicker
                    contrast
                    floatingLabelText={'Data do'}
                    onChange={(e, date) => this.handleChangeDateTo(date)}
                    value={this.state.eventDateTo ? new Date(this.state.eventDateTo) : null}
                    name="eventDateTo"
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <FormControl
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: (theme) => theme.palette.color.primary
                        }}>
                        <InputLabel sx={{ color: (theme) => theme.palette.color.hidden }}>Data do</InputLabel>
                        <Input
                          disableUnderline
                          ref={inputRef}
                          {...inputProps}
                          sx={{
                            color: (theme) => theme.palette.color.transparent,

                            svg: { color: (theme) => theme.palette.color.hidden },
                            input: { color: (theme) => theme.palette.color.hidden, width: 90 },
                            label: { color: (theme) => theme.palette.color.hidden },
                            borderBottom: (theme) => `1px solid ${theme.palette.color.primary}`
                          }}
                          endAdornment={<InputAdornment position="end">{InputProps.endAdornment}</InputAdornment>}
                        />
                      </FormControl>
                    )}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                xl={4}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                {Object.keys(this.state.filteredEvents).length > 0 ? (
                  <Button variant="outlined" aria-label="Wyczyść filtry" onClick={() => this.handleClearFilters()}>
                    Wyczyść filtry
                  </Button>
                ) : null}
                <GroupSwitch onGroupChange={this.handleGroupChange} showArchivedGroups={true} />
                {this.renderCreateEventButton()}
              </Grid>
            </Grid>

            {this.renderCreateEventDialog()}
            {this.renderEventDetailsDialog()}
            {Object.keys(this.state.filteredEvents).length > 0 ? (
              <BoardPostsList
                posts={this.state.filteredEvents.data}
                eventType={this.props.params.eventType}
                onSave={this.handlePostSave}
                onClearFilters={this.handleClearFilters}
                onDelete={this.handlePostDelete}
                onEdit={this.handleEditPost}
              />
            ) : (
              <>
                <Paper className="card panel">
                  <CustomCalendar
                    eventDays={this.props.posts}
                    daysOff={this.props.daysOff}
                    onDateChange={this.handleCalendarDateChange}
                    onDaySelected={(date) => this.handleDetailsOpen(date)}
                    date={moment(new Date(this.props.year, this.props.month - 1, 1))}
                    year={this.props.year}
                    month={this.props.month}
                  />
                </Paper>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', my: 2 }}>
                  <Box>
                    {this.props.eventType === eventTypes.saveWork.type && (
                      <Button
                        variant="outlined"
                        aria-label="Export XML (.zip)"
                        style={{ m: '4px 0' }}
                        startIcon={<ReportsIcon />}
                        disabled={this.state.downloadingXML}
                        onClick={this.handleDownloadXMLExport}>
                        Export XML (.zip)
                      </Button>
                    )}
                  </Box>
                  <Button
                    variant="outlined"
                    aria-label="Generuj raport PDF"
                    startIcon={<ReportsIcon />}
                    style={{ m: '4px 0' }}
                    onClick={this.handleGenerateReport}>
                    Generuj raport PDF
                  </Button>
                </Box>
              </>
            )}
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

CustomCalendarPage.propTypes = {
  posts: PropTypes.array.isRequired,
  daysOff: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  actions: PropTypes.object,
  digitalDiaryEvents: PropTypes.object.isRequired,
  isAllowed: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  choosenGroup: PropTypes.object.isRequired,
  eventType: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  staffMembers: PropTypes.array.isRequired,
  archivedGroups: PropTypes.array.isRequired,
  defaultPublishingHouseId: PropTypes.string
};

function mapStateToProps(state, ownProps) {
  return {
    posts: state.digitalDiary.events.events,
    daysOff: state.configuration.daysOff,
    ui: state.digitalDiary.eventsUi,
    groups: state.groups,
    activities: state.activities,
    isAllowed: state.auth.isAllowed.bind(state.auth),
    year: ownProps.params.year,
    month: ownProps.params.month,
    eventType: ownProps.params.eventType,
    choosenGroup: state.digitalDiary.choosenGroup,
    auth: state.auth,
    staffMembers: state.staffMembers,
    archivedGroups: state.archives.groups,
    defaultPublishingHouseId: state.configuration.unit.defaultPublishingHouseId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    digitalDiaryEvents: bindActionCreators(digitalDiaryActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomCalendarPage);
