import PropTypes from 'prop-types';
import React from 'react';
import { routePaths, fromTemplate } from '../../../routePaths';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import moment from 'moment';
import ReliefIcon from '@mui/icons-material/MoneyOff';
import { connect } from 'react-redux';
import EmptyState from '../../common/EmptyState';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router';
import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import CircleIcon from '@mui/icons-material/Circle';
import AuthorizationChipElement from '../../common/children/guardians/AuthorizationChipElement';

const ExpiringReliefsList = ({ reliefs }) => {
  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title="Kończące się ulgi" titleIcon={<ReliefIcon />} />
        <ContentWrapper>
          <TableContainer component={Paper} sx={{ px: 4, py: 2 }}>
            <Stack direction={{ xs: 'column', md: 'row' }}>
              <Box sx={{ mr: { xs: 1, md: 3 }, display: 'flex', color: (theme) => theme.palette.color.contrast }}>
                <CircleIcon fontSize="1fr" sx={{ mr: 1, color: (theme) => theme.palette.color.success }} />
                <Typography>
                  Zakończą się w przeciągu 14 dni (
                  {reliefs.filter((item) => !moment(item.reliefExpiryDate).isBefore(new Date())).length})
                </Typography>
              </Box>
              <Box sx={{ mr: 1, display: 'flex', color: (theme) => theme.palette.color.contrast }}>
                <CircleIcon fontSize="1fr" sx={{ mr: 1, color: (theme) => theme.palette.color.error }} />
                <Typography>
                  Zakończone ({reliefs.filter((item) => moment(item.reliefExpiryDate).isBefore(new Date())).length})
                </Typography>
              </Box>
            </Stack>
            {reliefs.length ? (
              <Table aria-label="Kończące się ulgi">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">Dziecko</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">Ulga</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">Data ważności</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reliefs.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:hover': {
                          background: (theme) => theme.palette.background.hover
                        }
                      }}>
                      <TableCell>
                        <Link
                          onlyActiveOnIndex={false}
                          to={fromTemplate(routePaths.childDetails, item.child.id)}
                          style={{ textDecoration: 'none' }}>
                          <Typography
                            sx={{
                              fontWeight: (theme) => theme.typography.fontWeightBold,
                              cursor: 'pointer',
                              color: (theme) => theme.palette.color.contrast,
                              '&:hover': {
                                textDecoration: 'underline'
                              }
                            }}>{`${item.child.lastName} ${item.child.firstName}`}</Typography>
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: 16, fontWeight: (theme) => theme.typography.fontWeightBold }}>
                        {ChargingReportTypes[item.schemeReportType].name}
                      </TableCell>
                      <TableCell>
                        <AuthorizationChipElement
                          sx={{
                            width: 124,
                            color: (theme) => theme.palette.color.primary,
                            background: (theme) =>
                              moment(item.reliefExpiryDate).isBefore(new Date())
                                ? theme.palette.color.error
                                : theme.palette.color.success
                          }}>
                          {moment(item.reliefExpiryDate).format('YYYY-MM-DD')}
                        </AuthorizationChipElement>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <EmptyState message="Żadna z ulg nie wygasa w ciągu najbliższych 14 dni" noIcon={true} />
            )}
          </TableContainer>
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};

ExpiringReliefsList.propTypes = {
  reliefs: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    isContrastColor: state.contrastColor,
    reliefs: state.staffDashboard.expiringReliefs
  };
}

export default connect(mapStateToProps)(ExpiringReliefsList);
