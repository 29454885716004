import config from '../config/config';
import localStorageProvider from '../utils/localStorageProvider';
import * as authActions from '../actions/authActions';
import agentWithDefaults from 'superagent-defaults';

const agent = agentWithDefaults();

export const ROOT_URL = config.apiUrl;

agent.on('request', (req) => {
  const token = localStorageProvider.getItem('authToken');
  if (token) {
    req.set('Authorization', `Bearer ${token}`);
  }
});
function handleUnauthorizedResponse(dispatch) {
  return authActions.signOut()(dispatch);
}

function isUnauthorized(response) {
  return (
    response &&
    response.status === 401 &&
    !response.error.url.includes('users/authenticate') &&
    !response.error.url.includes('users/password/reset') &&
    !response.error.url.includes('users/logingov')
  );
}

class ApiBase {
  constructor() {
    this.dispatch = null;
  }

  static toPromise(agentSetupFunction, responseFilter = (r) => r.body) {
    return new Promise((resolve, reject) => {
      agentSetupFunction(agent).end((error, response) => {
        const newError = error || response.error;
        if (newError) {
          if (isUnauthorized(response)) {
            handleUnauthorizedResponse(this.dispatch);
          } else {
            newError.response = response;
            reject(newError);
          }
        } else {
          resolve(responseFilter(response));
        }
      });
    });
  }

  static filterLocationHeader(response) {
    return response.header.location.split('/').pop();
  }
}

export default ApiBase;
