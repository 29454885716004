import BaseModel from '../../BaseModel';
import { AuthorModel } from '../../digitalDiary/digitalDiaryEvent/DigitalDiaryEventModel';
/*eslint no-use-before-define: 0*/
// export class DiagnosticSchemeSetModel extends BaseModel {
//   constructor(id = '', name = '', superText = '') {
//     super();
//     this.id = id;
//     this.name = name;
//     this.superText = superText;
//   }
// }

// export class DiagnosticSchemaSetModel extends BaseModel {
//   constructor() {
//     super();
//     this.id = '';
//     this.title = '';
//     this.superText = '';
//   }
// }

export class DiagnosticSchemaSetModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.title = '';
    this.content = '';
    this.group = '';
    this.author = new AuthorModel();
    this.availableToAll = false;
    this.eventDate = '';
    this.eventStartTime = '';
    this.eventEndTime = '';
    this.eventType = '';
  }

  onAssignment(assigned) {
    this.author = new AuthorModel().assign(assigned.author);
  }
}
