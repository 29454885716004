import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormikTextField } from '../../../formik/FormikTextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import FormikDatePicker from '../../../formik/FormikDatePicker';
import userRoles from '../../../../constants/userRoles';
import FormikRadioSelect from '../../../formik/FormikRadioSelect';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import { FormLabel, Radio, Grid, Typography, ListItem, Box } from '@mui/material';
import Pesel from '../../../../utils/Pesel';

function ChildDetailsFormPersonalData(props) {
  const { individualBankAccounts, symphony } = props;

  const { values, setFieldValue, status } = useFormikContext();
  const auth = useSelector((state) => state.auth);
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);
  const uiErrors = useSelector((state) => state.childDetailsUi.details.asyncErrors);

  const handleChange = (value) => {
    setFieldValue('personalId', value);
    if (values.personalIdType === 'pesel') {
      const pesel = new Pesel(value);
      if (pesel.isValid()) {
        const decodedPesel = pesel.decode();
        const birthDate = new Date(Date.UTC(decodedPesel.year, decodedPesel.month - 1, decodedPesel.day));
        setFieldValue('birthDate', birthDate);
      }
    }
  };
  useEffect(() => {
    if (values.personalIdType === 'pesel') {
      const pesel = new Pesel(values.personalId);
      if (pesel.isValid()) {
        const decodedPesel = pesel.decode();
        const birthDate = new Date(Date.UTC(decodedPesel.year, decodedPesel.month - 1, decodedPesel.day));
        setFieldValue('birthDate', birthDate);
      }
    }
  }, [values.personalId, values.personalIdType]);

  return (
    <FieldsetWrapper
      legend={
        <Typography
          component="span"
          sx={{
            color: (theme) => theme.palette.color.contrast,
            fontWeight: (theme) => theme.typography.fontWeightBold,
            pl: 2
          }}>
          Dane personalne
        </Typography>
      }
      invisible={false}
      sx={{ pb: 1 }}>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} md={4}>
          <ListItem>
            <FormikTextField label="Imię*" name="firstName" fullWidth />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={4}>
          <ListItem>
            <FormikTextField label="Drugie imię" name="secondName" fullWidth />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={4}>
          <ListItem>
            <FormikTextField label="Nazwisko*" name="lastName" fullWidth />
          </ListItem>
        </Grid>
        {nadarzyn && (
          <Grid item xs={12} md={4}>
            <ListItem>
              <FormikTextField errorText={uiErrors && uiErrors.email} label="Email*" name="email" fullWidth />
            </ListItem>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <ListItem>
            <FormLabel
              component="legend"
              sx={{
                '&.Mui-focused': {
                  color: (theme) => theme.palette.color.contrast
                },
                color: (theme) => theme.palette.color.contrast
              }}>
              Rodzaj numeru ewidencyjnego
            </FormLabel>
          </ListItem>
          <ListItem sx={{ pl: 2, '& .MuiFormGroup-root': { width: '100%' } }}>
            <FormikRadioSelect name="personalIdType">
              <Box width="80%" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormControlLabel value="pesel" control={<Radio disabled={status?.disabled} />} label="Pesel" />
                <FormControlLabel value="other" control={<Radio disabled={status?.disabled} />} label="Inny" />
              </Box>
            </FormikRadioSelect>
          </ListItem>
        </Grid>
        <Grid item xs={12} md={4}>
          <ListItem>
            <FormikTextField
              label={values.personalIdType === 'pesel' ? 'Pesel*' : 'Inny*'}
              name="personalId"
              onChange={handleChange}
              errorText={uiErrors && uiErrors.personalId}
              fullWidth
            />
          </ListItem>
        </Grid>
        {!nadarzyn && symphony ? (
          <Grid item xs={12} md={4}>
            <>
              <ListItem>
                <FormikTextField label="Identyfikator dziecka Symfonia" name="childIntegrationId" fullWidth />
              </ListItem>
              <ListItem>
                <FormikTextField
                  label="Dodatkowy identyfikator dziecka Symfonia"
                  name="additionalChildIntegrationId"
                  fullWidth
                />
              </ListItem>
            </>
          </Grid>
        ) : null}
      </Grid>
      <Grid container columnSpacing={1}>
        {individualBankAccounts && auth.userRole === userRoles.staffMemberPrincipal ? (
          <>
            <Grid item xs={12} md={4}>
              <ListItem>
                <FormikTextField label="Indywidualny numer konta - Pobyt" name="stayBankAccount" fullWidth />
              </ListItem>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListItem>
                <FormikTextField label="Indywidualny numer konta - Wyżywienie" name="cateringBankAccount" fullWidth />
              </ListItem>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListItem>
                <FormikTextField label="Indywidualny numer konta - Inne" name="otherBankAccount" fullWidth />
              </ListItem>
            </Grid>
          </>
        ) : null}
      </Grid>
      <Grid container columnSpacing={1} sx={{ pb: 2 }}>
        <Grid item xs={12} md={4} sx={{ mt: '6px' }}>
          <ListItem>
            <FormikDatePicker
              name="birthDate"
              label="Data urodzenia"
              disabled={values.personalIdType === 'pesel'}
              fullWidth
            />
          </ListItem>
        </Grid>
        {!nadarzyn && (
          <>
            <Grid item xs={12} md={4}>
              <ListItem>
                <FormikTextField name="contractNumber" label="Numer umowy" fullWidth />
              </ListItem>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListItem>
                <FormikTextField name="caseNumber" label="Numer sprawy" value={values.caseNumber || ''} fullWidth />
              </ListItem>
            </Grid>
          </>
        )}
      </Grid>
    </FieldsetWrapper>
  );
}

ChildDetailsFormPersonalData.propTypes = {
  symphony: PropTypes.bool,
  individualBankAccounts: PropTypes.bool
};

export default ChildDetailsFormPersonalData;
