import PropTypes from 'prop-types';
import React from 'react';
import SelectField from '../../common/SelectField';
import Checkbox from '../../common/Checkbox';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { Box, Typography, MenuItem } from '@mui/material';
import { ReportTypes } from '../../../constants/reportTypes';
import ActivityPickerTemplate from './common/ActivityPickerTemplate';

const emptyColumnsCount = [
  { value: '0', name: 'bez pustych kolumn' },
  { value: 1, name: '1 pusta kolumna' },
  { value: 2, name: '2 puste kolumny' },
  { value: 3, name: '3 puste kolumny' },
  { value: 4, name: '4 puste kolumny' },
  { value: 5, name: '5 pustych kolumn' }
];

const ChildrenActivitiesReport = ({
  activityId,
  activities,
  printGroupName,
  printEmptyColumns,
  printLandscape,
  onChange
}) => {
  return (
    <Box>
      <ActivityPickerTemplate
        activityId={activityId}
        activities={activities}
        onChange={onChange}
        legend={` ${ReportTypes.childrenActivities.name}`}
      />
      <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.childrenActivities.name}`}>
        <Typography variant="h6" sx={{ pt: 2 }}>
          Dodatkowe kolumny
        </Typography>
        <Checkbox
          label="Nazwa grupy"
          defaultChecked={printGroupName}
          onCheck={(event, isChecked) => onChange({ target: { name: 'printGroupName', value: isChecked } })}
        />
        <SelectField
          contrast
          floatingLabelText="Liczba dodatkowych pustych kolumn"
          hintText="Puste kolumny"
          displayEmpty={true}
          name="selectedEmptyColumnsCount"
          value={printEmptyColumns}
          onChange={(event) => {
            onChange({ target: { name: 'printEmptyColumns', value: event.target.value } });
          }}
          inputProps={{ 'aria-label': 'Ilość dodatkowych pustych kolumn' }}>
          {emptyColumnsCount.map((col) => {
            return (
              <MenuItem key={col.value} value={col.value}>
                {col.name}
              </MenuItem>
            );
          })}
        </SelectField>
        <Typography variant="h6" sx={{ pt: 2 }}>
          Opcja wydruku
        </Typography>
        <Checkbox
          label="Drukuj horyzontalnie"
          defaultChecked={printLandscape}
          onCheck={(event, isChecked) => onChange({ target: { name: 'printLandscape', value: isChecked } })}
        />
      </FieldsetWrapper>
    </Box>
  );
};

ChildrenActivitiesReport.propTypes = {
  activityId: PropTypes.string,
  activities: PropTypes.array.isRequired,
  printEmptyColumns: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  printGroupName: PropTypes.bool.isRequired,
  printLandscape: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ChildrenActivitiesReport;
