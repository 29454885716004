import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Menu } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

const DotsMenu = React.forwardRef(({ buttonStyle, children, disabled, sx, ...rest }, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ ...sx }}>
      <IconButton
        ref={ref}
        aria-label="więcej"
        aria-controls="simple-menu"
        aria-haspopup="true"
        disabled={disabled}
        onClick={handleClick}
        sx={{ ...buttonStyle }}
        size="large">
        <MoreVert sx={{ '&:hover': { background: 'transparent !important' } }} />
      </IconButton>
      <EnhancedMenu
        id="simple-menu"
        anchorEl={anchorEl}
        aria-label="więcej"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        {...rest}>
        {children(handleClose)}
      </EnhancedMenu>
    </Box>
  );
});

const EnhancedMenu = React.forwardRef((props, ref) => <Menu {...props} ref={ref} />);

DotsMenu.propTypes = {
  children: PropTypes.any,
  buttonStyle: PropTypes.object,
  disabled: PropTypes.bool,
  sx: PropTypes.object
};

export default DotsMenu;
