import PropTypes from 'prop-types';
import React from 'react';
import { routePaths } from '../../../routePaths';
import MenuRoute from './MenuRoute';
import { Divider, List, useTheme } from '@mui/material';
import UnreadThreadsBadgeWrapper from '../../common/privateMessages/UnreadThreadsBadgeWrapper';
import moment from 'moment';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import MessageBoardIcon from '@mui/icons-material/SmsFailedOutlined';
import EventsCalendarIcon from '@mui/icons-material/Event';
import PrivateMessagesIcon from '@mui/icons-material/EmailOutlined';
import PhotoLibraryIcon from '@mui/icons-material/CollectionsOutlined';
import StorageIcon from '@mui/icons-material/SaveOutlined';
import { useSelector } from 'react-redux';

const ReducedStudentMenu = ({ currentRoute, onRouteSelect }) => {
  let constKey = 0;
  const getNextKey = () => constKey++;
  const year = moment().format('YYYY');
  const month = moment().format('MM');
  const rest = { selectedRoutePath: currentRoute, onClick: onRouteSelect };
  const theme = useTheme();
  const isContrastColor = useSelector((state) => state.theme);

  return (
    <List>
      <MenuRoute
        text="Pulpit"
        icon={
          <HomeIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
          />
        }
        routePath={routePaths.home}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Wiadomości"
        icon={
          <PrivateMessagesIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color3 }}
          />
        }
        routePath={routePaths.guardianPrivateMessages}
        key={getNextKey()}
        badge={<UnreadThreadsBadgeWrapper />}
        {...rest}
      />
      <MenuRoute
        text="Dysk wirtualny"
        icon={
          <StorageIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color21 }}
          />
        }
        routePath={routePaths.virtualDisc}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Ogłoszenia"
        icon={
          <MessageBoardIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color4 }}
          />
        }
        routePath={routePaths.messageBoard}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Kalendarz wydarzeń"
        icon={
          <EventsCalendarIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color7 }}
          />
        }
        routePath={routePaths.eventsCalendar}
        routeParams={[year, month]}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Galeria"
        icon={
          <PhotoLibraryIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color6 }}
          />
        }
        routePath={routePaths.gallery}
        key={getNextKey()}
        {...rest}
      />
      <Divider />
    </List>
  );
};

ReducedStudentMenu.propTypes = {
  currentRoute: PropTypes.string.isRequired,
  onRouteSelect: PropTypes.func.isRequired
};

export default ReducedStudentMenu;
