import PropTypes from 'prop-types';
import React from 'react';
import ReportAttendanceDialog from '../reportDialogs/ReportAttendanceDialog';
import ReportAbsenceDialog from '../reportDialogs/ReportAbsenceDialog';
import moment from 'moment';
import { connect } from 'react-redux';

class PreviousAttendanceEditDialogs extends React.Component {
  renderAttendanceDialog(isProcessing, isDialogOpen, onCancel, onSave, statePathToUi) {
    return (
      <ReportAttendanceDialog
        pupilFirstName={this.props.childFirstName}
        pupilLastName={this.props.childLastName}
        pupilPhotoUrl={this.props.childPhotoUrl}
        isFirstAttendanceAttempt={!this.props.droppedOffAt || !this.props.pickedUpAt}
        droppedOffAt={
          this.props.droppedOffAt ||
          (this.props.workingHours.from.hourLocal !== null
            ? moment(this.props.journalDate)
                .set('hour', this.props.workingHours.from.hourLocal)
                .set('minute', this.props.workingHours.from.minuteLocal)
            : moment(this.props.journalDate).set('hour', 10))
        }
        pickedUpAt={
          this.props.pickedUpAt ||
          (this.props.workingHours.to.hourLocal !== null
            ? moment(this.props.journalDate)
                .set('hour', this.props.workingHours.to.hourLocal)
                .set('minute', this.props.workingHours.to.minuteLocal)
            : moment(this.props.journalDate).set('hour', 20))
        }
        pickedUpBy={this.props.pickedUpBy ? this.props.pickedUpBy.id : null}
        guardians={this.props.childAllGuardians}
        isProcessing={isProcessing}
        isDialogOpen={isDialogOpen}
        onCancel={onCancel}
        onSave={onSave}
        statePathToUi={statePathToUi}
      />
    );
  }

  renderAbsenceDialog(isProcessing, isDialogOpen, onCancel, onSave, statePathToUi) {
    return (
      <ReportAbsenceDialog
        pupilFirstName={this.props.childFirstName}
        pupilLastName={this.props.childLastName}
        pupilPhotoUrl={this.props.childPhotoUrl}
        pupilContract={this.props.childContract}
        absenceFrom={this.props.journalDate}
        absenceTo={this.props.journalDate}
        absenceNotes=""
        isAbsenceFromBlocked={this.props.isAbsenceFromBlocked}
        isAbsenceToBlocked={this.props.isAbsenceToBlocked}
        isProcessing={isProcessing}
        isDialogOpen={isDialogOpen}
        onCancel={onCancel}
        onSave={onSave}
        statePathToUi={statePathToUi}
      />
    );
  }

  render() {
    const {
      reportAttendanceUi,
      onReportAttendanceCancel,
      onReportAttendance,
      revertAttendanceUi,
      onRevertAttendanceCancel,
      onRevertAttendance
    } = this.props;
    if (reportAttendanceUi.isEditing) {
      return this.renderAttendanceDialog(
        reportAttendanceUi.isProcessing,
        reportAttendanceUi.isEditing,
        onReportAttendanceCancel,
        ({ droppedOffAt, pickedUpAt, pickedUpBy }) =>
          onReportAttendance(
            this.props.childId,
            droppedOffAt,
            pickedUpAt,
            pickedUpBy,
            this.props.absenceFrom,
            this.props.absenceTo
          ),
        'attendanceUi.reportAttendance'
      );
    }
    if (revertAttendanceUi.isEditing) {
      return this.renderAbsenceDialog(
        revertAttendanceUi.isProcessing,
        revertAttendanceUi.isEditing,
        onRevertAttendanceCancel,
        ({ absenceFrom, absenceTo, absenceNotes }) =>
          onRevertAttendance(
            this.props.childId,
            this.props.droppedOffAt,
            this.props.pickedUpAt,
            absenceFrom,
            absenceTo,
            absenceNotes
          ),
        'attendanceUi.revertAttendance'
      );
    }
    return null;
  }
}

PreviousAttendanceEditDialogs.propTypes = {
  reportAttendanceUi: PropTypes.object.isRequired,
  onReportAttendanceCancel: PropTypes.func.isRequired,
  onReportAttendance: PropTypes.func.isRequired,

  revertAttendanceUi: PropTypes.object.isRequired,
  onRevertAttendanceCancel: PropTypes.func.isRequired,
  onRevertAttendance: PropTypes.func.isRequired,

  childId: PropTypes.string,
  absenceFrom: PropTypes.object,
  absenceTo: PropTypes.object,
  droppedOffAt: PropTypes.object,
  pickedUpAt: PropTypes.object,
  pickedUpBy: PropTypes.object,
  isAbsenceFromBlocked: PropTypes.bool,
  isAbsenceToBlocked: PropTypes.bool,

  childFirstName: PropTypes.string,
  childLastName: PropTypes.string,
  childPhotoUrl: PropTypes.string,
  childContract: PropTypes.object,
  childAllGuardians: PropTypes.array,

  journalDate: PropTypes.object,

  workingHours: PropTypes.object
};

function mapStateToProps(state) {
  return {
    workingHours: state.configuration.unit.workingHours
  };
}

export default connect(mapStateToProps)(PreviousAttendanceEditDialogs);
