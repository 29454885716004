/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { MessageBoardModel, BoardPostModel } from '../models/messageBoard/MessageBoardModels';

export default function messageBoardPostsReducer(state = initialState.messageBoardPosts, action) {
  switch (action.type) {
    case types.LOAD_MESSAGE_BOARD_POSTS_SUCCESS:
    case types.LOAD_MESSAGE_BOARD_POSTS_TO_ME_SUCCESS: {
      return new MessageBoardModel([...action.posts.data], action.posts.totalCount);
    }
    case types.LOAD_NEXT_MESSAGE_BOARD_POSTS_SUCCESS:
    case types.LOAD_NEXT_MESSAGE_BOARD_POSTS_TO_ME_SUCCESS: {
      return new MessageBoardModel([...state.posts, ...action.posts.data], action.posts.totalCount);
    }
    case types.CREATE_MESSAGE_BOARD_POST_SUCCESS: {
      if (!action.post.eventDate) {
        return new MessageBoardModel([action.post, ...state.posts], state.totalCount + 1);
      }
      return state;
    }
    case types.UPDATE_MESSAGE_BOARD_POST_SUCCESS: {
      const updatedPosts = state.posts.map((post) => {
        return action.post.id === post.id ? new BoardPostModel().assign(action.post) : post;
      });
      return new MessageBoardModel(updatedPosts, state.pageCount);
    }
    case types.DELETE_MESSAGE_BOARD_POST_SUCCESS: {
      return new MessageBoardModel([...state.posts.filter((p) => p.id !== action.postId)], state.pageCount - 1);
    }
    default:
      return state;
  }
}
