import { Fullscreen } from '@mui/icons-material';
import { IconButton, ImageList, ImageListItem, ImageListItemBar, useMediaQuery } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/system';

const ImageListFuncional = ({
  photos,
  slicedPhotos,
  styles,
  restPictureNumbers,
  showAllPhotos,
  openLightbox,
  onRemove
}) => {
  const screenWidth = useMediaQuery('(min-width:900px)');

  return (
    <>
      {photos.length ? (
        <ImageList rowHeight={130} cols={screenWidth ? 2 : 1}>
          {slicedPhotos.map((photo, index) => (
            <ImageListItem key={index} sx={styles.emptyImagesBox}>
              <img
                src={photo.thumbnailUrl.startsWith('blob') ? photo.thumbnailUrl : photo.thumbnailUrl}
                alt={photo.name.split('.')[0]}
              />
              <ImageListItemBar
                actionIcon={
                  <>
                    {onRemove && (
                      <IconButton
                        sx={{ color: (theme) => theme.palette.primary.contrastText }}
                        aria-label="Usuń"
                        onClick={() => {
                          onRemove(photos[index]);
                        }}
                        size="large">
                        <DeleteIcon />
                      </IconButton>
                    )}
                    <IconButton
                      sx={{ color: (theme) => theme.palette.primary.contrastText }}
                      aria-label="Powiększ"
                      onClick={() => {
                        openLightbox(index);
                      }}
                      size="large">
                      <Fullscreen />
                    </IconButton>
                  </>
                }
              />
            </ImageListItem>
          ))}
          {restPictureNumbers > 0 && !showAllPhotos ? (
            <ImageListItem>
              <Box sx={styles.additionalImagesBox}>
                <span>+{restPictureNumbers} </span>
              </Box>
            </ImageListItem>
          ) : null}
        </ImageList>
      ) : null}
    </>
  );
};

export default ImageListFuncional;
