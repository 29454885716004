import api from '../api/ChargingSchemesApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import { browserHistory } from 'react-router';
import { routePaths } from '../routePaths';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';

export function loadChargingSchemesAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_CHARGING_SCHEMES });
    return api
      .getChargingSchemes()
      .then((schemes) => dispatch({ type: types.LOAD_CHARGING_SCHEMES_SUCCESS, schemes }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać schematów płatności'));
        return logger.error(error);
      });
  };
}

export function loadChargingSchemeDetailsAsync(id) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_CHARGING_SCHEME_DETAILS });
    return api
      .getChargingScheme(id)
      .then((scheme) => dispatch({ type: types.LOAD_CHARGING_SCHEME_DETAILS_SUCCESS, scheme }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać szczegółów schematu płatności '${get.schemeName(getState, id)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function createChargingSchemeDetailsStart() {
  return { type: types.CREATE_CHARGING_SCHEME_DETAILS };
}

export function createChargingSchemeDetailsCancel() {
  return { type: types.CREATE_CHARGING_SCHEME_DETAILS_CANCEL };
}

export function createChargingSchemeDetailsAsync(nextScheme) {
  const scheme = Object.assign({}, nextScheme);
  return (dispatch) => {
    dispatch({ type: types.CREATE_CHARGING_SCHEME_DETAILS_SUBMIT });
    return api
      .createChargingScheme(scheme)
      .then((id) => {
        dispatch(notificationActions.showSuccess(`Dodano schemat płatności '${scheme.name}'`));
        dispatch({ type: types.CREATE_CHARGING_SCHEME_DETAILS_SUCCESS, scheme: Object.assign(scheme, { id }) });
      })
      .catch((error) => {
        logger.error(error);
        dispatch(
          notificationActions.showError(
            `Nie udało się dodać schematu płatności '${scheme.name}, ${error.response.body.generalError.toLowerCase()}'`
          )
        );
        return dispatch({
          type: types.CREATE_CHARGING_SCHEME_DETAILS_FAILURE,
          errors: error.response?.body
        });
      });
  };
}

export function updateChargingSchemeDetailsStart() {
  return { type: types.UPDATE_CHARGING_SCHEME_DETAILS };
}

export function updateChargingSchemeDetailsCancel() {
  return { type: types.UPDATE_CHARGING_SCHEME_DETAILS_CANCEL };
}

export function updateChargingSchemeDetailsAsync(nextScheme) {
  const scheme = Object.assign({}, nextScheme);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_CHARGING_SCHEME_DETAILS_SUBMIT });
    return api
      .updateChargingScheme(scheme.id, scheme)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zaktualizowano schemat płatności '${scheme.name}'`));
        dispatch({ type: types.UPDATE_CHARGING_SCHEME_DETAILS_SUCCESS, scheme });
      })
      .catch((error) => {
        if (error.status === 412) {
          return dispatch({
            type: types.UPDATE_CHARGING_SCHEME_DETAILS_FAILURE,
            errors: error.response.body
          });
        }
        dispatch(notificationActions.showError(`Nie udało się zaktualizować schematu płatności '${scheme.name}'`));
        return logger.error(error);
      });
  };
}

export function archiveChargingSchemeDetailsAsync(id) {
  return (dispatch, getState) => {
    dispatch({ type: types.ARCHIVE_CHARGING_SCHEME_DETAILS });
    return api
      .archiveChargingScheme(id)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Usunięto schemat płatności '${get.schemeName(getState, id)}'`));
        dispatch({ type: types.ARCHIVE_CHARGING_SCHEME_DETAILS_SUCCESS, schemeId: id });
        browserHistory.push(routePaths.configurationChargingSchemes);
      })
      .catch((error) => {
        const errorText =
          error.response && error.response.body && error.response.body.generalError
            ? error.response.body.generalError
            : '';
        if (error.status === 412)
          return dispatch(notificationActions.showError(`Nie udało się usunąć schematu płatności. ${errorText}`));
        dispatch(
          notificationActions.showError(`Nie udało się usunąć schematu płatności '${get.schemeName(getState, id)}'`)
        );
        return logger.error(error);
      });
  };
}

export function loadAssignedChildrenAsync(id) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME });
    return api
      .getAssignedChildren(id)
      .then((children) =>
        dispatch({
          type: types.LOAD_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME_SUCCESS,
          selectedSchemeId: id,
          children
        })
      )
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać listy dzieci przypisanych do schematu płatności '${get.schemeName(getState, id)}`
          )
        );
        return logger.error(error);
      });
  };
}

export function AssignChildrenAsync(id, childrenIds, singleUse, reliefId, reliefExpirationDate) {
  return (dispatch, getState) => {
    dispatch({ type: types.ADD_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME });
    return api
      .assignChildren(id, childrenIds, singleUse, reliefId, reliefExpirationDate)
      .then(() =>
        dispatch({
          type: types.ADD_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME_SUCCESS,
          childrenIds,
          singleUse,
          reliefId,
          reliefExpirationDate
        })
      )
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się przypisać dzieci do schematu płatności '${get.schemeName(getState, id)}`
          )
        );
        return logger.error(error);
      });
  };
}

export function UnassignChildrenAsync(id, childrenIds) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME });
    return api
      .unassignChildren(id, childrenIds)
      .then(() => dispatch({ type: types.REMOVE_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME_SUCCESS, childrenIds }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się odpiąć dzieci do schematu płatności '${get.schemeName(getState, id)}`
          )
        );
        return logger.error(error);
      });
  };
}

export function loadCateringChargingSchemesAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_CATERING_CHARGING_SCHEMES });
    return api
      .getCateringChargingSchemes()
      .then((schemes) => dispatch({ type: types.LOAD_CATERING_CHARGING_SCHEMES_SUCCESS, schemes }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać schematów wyżywienia'));
        return logger.error(error);
      });
  };
}
