/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { NumberingSchemeModel } from '../models/configuration/numberingSchemes/NumberingSchemeModels';

export default function numberingSchemesReducer(state = initialState.configuration.numberingSchemes, action) {
  switch (action.type) {
    case types.LOAD_NUMBERING_SCHEMES_SUCCESS: {
      return action.schemes.map((scheme) => new NumberingSchemeModel().assign(scheme));
    }
    case types.CREATE_NUMBERING_SCHEME_DETAILS_SUCCESS: {
      const scheme = new NumberingSchemeModel().assign(action.scheme);
      return [...state, scheme];
    }
    case types.UPDATE_NUMBERING_SCHEME_DETAILS_SUCCESS: {
      const scheme = new NumberingSchemeModel().assign(action.scheme);
      return [...state.filter((g) => g.id !== scheme.id), scheme];
    }
    case types.DELETE_NUMBERING_SCHEME_DETAILS_SUCCESS: {
      return [...state.filter((g) => g.id !== action.schemeId)];
    }
    default:
      return state;
  }
}
