/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function legalGuardianDashboardUiReducer(state = initialState.legalGuardianDashboardUi, action) {
  switch (action.type) {
    case types.REPORT_CHILD_ABSENCE: {
      return Object.assign({}, state, { childrenAbsence: UiModel.start() });
    }
    case types.REPORT_CHILD_ABSENCE_CANCEL: {
      return Object.assign({}, state, { childrenAbsence: UiModel.cancel() });
    }
    case types.REPORT_CHILD_ABSENCE_SUBMIT: {
      return Object.assign({}, state, { childrenAbsence: UiModel.submit() });
    }
    case types.REPORT_CHILD_ABSENCE_SUCCESS: {
      return Object.assign({}, state, { childrenAbsence: UiModel.success() });
    }
    case types.REPORT_CHILD_ABSENCE_FAILURE: {
      return Object.assign({}, state, { childrenAbsence: UiModel.failure(action.errors) });
    }
    //
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.legalGuardianDashboardUi);
    }
    default:
      return state;
  }
}
