import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as notificationActions from '../../actions/notificationActions';
import { Alert, Snackbar } from '@mui/material';
import * as notificationTypes from '../../constants/notificationTypes';
import _ from 'lodash';

class NotificationHandlerContainer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isOpen: false
    };
    this.onRequestClose = this.onRequestClose.bind(this);
    this.handleChangeOpenState = this.handleChangeOpenState.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notifications.length !== this.props.notifications.length) {
      if (this.props.notifications.length) {
        this.handleChangeOpenState();
      }
    }
  }

  handleChangeOpenState() {
    this.setState({ isOpen: true });
  }

  onRequestClose() {
    this.setState({ isOpen: false });
    this.props.actions.clearMessages();
  }

  renderMessage(notification, index) {
    if (notification.type === notificationTypes.unhandledError)
      // return <UnhandledErrorNotification key={index} message={notification.message} />;
      return (
        <Alert severity="error" variant="filled" sx={{ wordBreak: 'break-word' }}>
          {notification.message}
        </Alert>
      );
    if (notification.type === notificationTypes.warning)
      return (
        <Alert severity="warning" variant="filled" sx={{ wordBreak: 'break-word' }}>
          {notification.message}
        </Alert>
      );
    if (notification.type === notificationTypes.success)
      return (
        <Alert severity="success" variant="filled" sx={{ wordBreak: 'break-word' }}>
          {notification.message}
        </Alert>
      );
    return null;
  }

  render() {
    return _.takeRight(this.props.notifications, 3).map((e, index) => (
      <Snackbar
        key={index}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          height: 'auto',
          lineHeight: 3,
          p: 0
        }}
        open={this.state.isOpen}
        autoHideDuration={30000}
        onClose={this.onRequestClose}>
        {this.renderMessage(e, index)}
      </Snackbar>
    ));
  }
}

NotificationHandlerContainer.propTypes = {
  notifications: PropTypes.array,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { notifications } = state;
  return { notifications };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(notificationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationHandlerContainer);
