/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import moment from 'moment';

export default function specialistNotesReducer(state = initialState.specialistNotes, action) {
  switch (action.type) {
    case types.LOAD_SPECIALIST_NOTES: {
      const newState = Object.assign({}, state);
      newState.specialistWorksList = action.response;
      return newState;
    }
    case types.LOAD_SPECIALIST_CLASSES_SCHEDULE: {
      const newState = Object.assign({}, state);
      newState.classSchedule = action.response;
      return newState;
    }
    case types.LOAD_SPECIALIST_WORK_PLAN_PROGRESS: {
      const newState = Object.assign({}, state);
      newState.workPlanProgress = action.response;
      return newState;
    }
    case types.LOAD_SPECIALIST_GROUP_ACTIVITIES: {
      const newState = Object.assign({}, state);
      newState.groupActivities = action.response;
      return newState;
    }
    case types.LOAD_SPECIALIST_DETAILS: {
      const newState = Object.assign({}, state);
      newState.id = action.response.id;
      // newState.classSchedule = [];
      newState.workPlan = action.response.records?.workPlan;
      // newState.workPlanProgress = [];
      newState.contacts = action.response.records?.contacts;
      newState.observations = action.response.records?.observations;
      // newState.groupActivities = [];
      newState.notes = action.response.records?.notes;
      newState.specialistsIds = action.response?.specialistIds;
      return newState;
    }

    case types.CREATE_SPECIALIST_NOTES_SUCCESS: {
      const newState = Object.assign({}, state);
      newState.specialistWorksList = [{ id: action.id, ...action.data }, ...newState.specialistWorksList];
      return newState;
    }
    case types.MODIFY_SPECIALIST_NOTES_SUCCESS: {
      let newState = Object.assign({}, state);
      let newSpecialistWork = { id: action.id, ...action.data };
      newState.specialistWorksList = [
        newSpecialistWork,
        ...newState.specialistWorksList.filter((w) => w.id !== action.id)
      ];
      return newState;
    }
    case types.DELETE_SPECIALIST_NOTES_SUCCESS: {
      let newState = Object.assign({}, state);
      newState.specialistWorksList = [...newState.specialistWorksList.filter((work) => work.id !== action.id)];
      return newState;
    }

    //                                                      Contacts
    case types.ADD_CONTACT_TO_SPECIALIST_NOTES_SUCCESS: {
      const newState = Object.assign({}, state);
      newState.contacts = [...newState.contacts, action.newContact];
      return newState;
    }
    case types.EDIT_SPECIALIST_CONTACT: {
      let newState = Object.assign({}, state);
      let newContact = action.data;
      newContact.creationDate = moment(action.data).format('YYYY-MM-DD');
      newState.contacts = [...newState.contacts.filter((w) => w.id !== action.data.id), newContact];

      return newState;
    }
    case types.ARCHIVE_SPECIALIST_CONTACT: {
      let newState = Object.assign({}, state);
      newState.contacts = [...newState.contacts.filter((contact) => contact.id !== action.id)];
      return newState;
    }

    //                                                      classSchedule
    case types.ADD_SPECIALIST_CLASS_SCHEDULE_ELEMENT: {
      const newState = Object.assign({}, state);
      newState.classSchedule = [...newState.classSchedule, { ...action.data }];
      return newState;
    }
    case types.EDIT_SPECIALIST_CLASS_SCHEDULE_ELEMENT: {
      let newState = Object.assign({}, state);
      let { data } = action;
      const elementIdx = newState.classSchedule.findIndex((el) => el.id === data.id);
      newState.classSchedule[elementIdx] = data;
      return newState;
    }
    case types.DELETE_SPECIALIST_CLASS_SCHEDULE_ELEMENT: {
      const newState = Object.assign({}, state);
      newState.classSchedule = newState.classSchedule.filter((post) => post.id !== action.id);
      return newState;
    }
    //                                                      workPlanProgress
    case types.ADD_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT: {
      const newState = Object.assign({}, state);
      newState.workPlanProgress = [...newState.workPlanProgress, { ...action.data }];
      return newState;
    }
    case types.EDIT_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT: {
      let newState = Object.assign({}, state);
      let { data } = action;
      const elementIdx = newState.workPlanProgress.findIndex((el) => el.id === data.id);
      newState.workPlanProgress[elementIdx] = data;
      return newState;
    }
    case types.DELETE_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT: {
      const newState = Object.assign({}, state);
      newState.workPlanProgress = newState.workPlanProgress.filter((post) => post.id !== action.id);
      return newState;
    }
    //                                                      workPlan
    case types.ADD_SPECIALIST_WORK_PLAN_ELEMENT: {
      const newState = Object.assign({}, state);
      newState.workPlan = [...newState.workPlan, { ...action.data }];
      return newState;
    }

    case types.DELETE_SPECIALIST_WORK_PLAN_ELEMENT: {
      let newState = Object.assign({}, state);
      newState.workPlan = [...newState.workPlan.filter((contact) => contact.id !== action.id)];
      return newState;
    }

    case types.EDIT_SPECIALIST_WORK_PLAN_ELEMENT: {
      let newState = Object.assign({}, state);
      let newWorkPlan = action.data;
      newWorkPlan.creationDate = moment(action.data).format('YYYY-MM-DD');
      newState.workPlan = [...newState.workPlan.filter((w) => w.id !== action.data.id), newWorkPlan];

      return newState;
    }
    //                                                      groupActivities
    case types.ADD_SPECIALIST_GROUP_ACTIVITIES_ELEMENT: {
      const newState = Object.assign({}, state);
      newState.groupActivities = [...newState.groupActivities, { ...action.data }];
      return newState;
    }

    case types.DELETE_SPECIALIST_GROUP_ACTIVITIES_ELEMENT: {
      const newState = Object.assign({}, state);
      newState.groupActivities = newState.groupActivities.filter((post) => post.id !== action.id);
      return newState;
    }

    case types.EDIT_SPECIALIST_GROUP_ACTIVITIES_ELEMENT: {
      let newState = Object.assign({}, state);
      let { data } = action;
      const elementIdx = newState.groupActivities.findIndex((el) => el.id === data.id);
      newState.groupActivities[elementIdx] = data;
      return newState;
    }
    //                                                      notes
    case types.ADD_SPECIALIST_NOTE: {
      const newState = Object.assign({}, state);
      const { data } = action;
      newState.notes = [...newState.notes, { ...data }];
      return newState;
    }
    case types.EDIT_SPECIALIST_NOTE: {
      let { data } = action;
      const nextItems = state.notes.map((e) => {
        return e.id === data.id ? data : e;
      });

      return Object.assign({}, state, { notes: [...nextItems] });
    }
    case types.DELETE_SPECIALIST_NOTE: {
      const newState = Object.assign({}, state);
      newState.notes = newState.notes.filter((post) => post.id !== action.id);
      return newState;
    }

    //                                                      observations
    case types.ADD_SPECIALIST_OBSERVATION: {
      const newState = Object.assign({}, state);
      const { data } = action;
      newState.observations = [...newState.observations, { ...data }];
      return newState;
    }
    case types.EDIT_SPECIALIST_OBSERVATION: {
      let { data } = action;
      const nextItems = state.observations.map((e) => {
        return e.id === data.id ? data : e;
      });

      return Object.assign({}, state, { observations: [...nextItems] });
    }
    case types.DELETE_SPECIALIST_OBSERVATION: {
      const newState = Object.assign({}, state);
      newState.observations = newState.observations.filter((post) => post.id !== action.id);
      return newState;
    }

    default:
      return state;
  }
}
