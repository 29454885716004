import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';

import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import React, { useState } from 'react';
import ActivityAvatar from '../activities/ActivityAvatar';

const PsycholologicalGroupSwitch = ({ handleChange, value, groups }) => {
  const [selectedGroup, setSelectedGroup] = useState(groups.find((group) => group.id === value));
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleGroupChange = (value) => {
    handleChange(value.id);
    setSelectedGroup(value);
    setDialogOpen(false);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ActivityAvatar activityName={selectedGroup.name} />
        <Typography sx={{ mx: 2 }}>{selectedGroup.name}</Typography>
        <Button
          variant="textContrast"
          onClick={() => {
            setDialogOpen(true);
          }}>
          <ChangeCircleOutlinedIcon sx={{ mr: 1 }} />
          zmień grupę
        </Button>
      </Box>

      <Dialog open={isDialogOpen} maxWidth="sm" fullWidth={true} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Wybierz grupę</DialogTitle>
        <DialogContent>
          <List>
            {groups?.map((element) => (
              <ListItem key={element.id} sx={{ p: 0, m: 0 }}>
                <ListItemButton onClick={() => handleGroupChange(element)}>
                  <ListItemAvatar>
                    <ActivityAvatar activityName={element.name} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={element.name}
                    primaryTypographyProps={{
                      fontWeight: (theme) => theme.typography.fontWeightBold
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            aria-label="Anuluj"
            onClick={() => {
              setDialogOpen(false);
            }}>
            Anuluj
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PsycholologicalGroupSwitch;
