import api from '../api/DaysOffApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';

export function loadDaysOffAsync(year) {
  return (dispatch, getState) => {
    if (getState().configuration) {
      if (
        getState().configuration.daysOff.length === 0 ||
        getState().configuration.daysOff[0].date.substring(0, 4) !== year
      ) {
        dispatch({ type: types.LOAD_DAYS_OFF });
        return api
          .getDaysOff(year)
          .then((daysOff) => dispatch({ type: types.LOAD_DAYS_OFF_SUCCESS, daysOff }))
          .catch((error) => {
            dispatch(notificationActions.showError(`Nie udało się pobrać listy dni wolnych na rok ${year}`));
            return logger.error(error);
          });
      }
    }
    return Promise.resolve();
  };
}

export function addDayOffStart() {
  return { type: types.ADD_DAY_OFF };
}

export function addDayOffCancel() {
  return { type: types.ADD_DAY_OFF_CANCEL };
}

export function addDayOffAsync(nextDayOff) {
  const dayOff = Object.assign({}, nextDayOff);
  return (dispatch) => {
    dispatch({ type: types.ADD_DAY_OFF_SUBMIT });
    return api
      .addDayOff(dayOff.date, dayOff.name)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(`${get.displayDate(dayOff.date)} dodano dzień wolny '${dayOff.name}'`)
        );
        dispatch({ type: types.ADD_DAY_OFF_SUCCESS, dayOff });
      })
      .catch((error) => {
        if (error.status === 412) return dispatch({ type: types.ADD_DAY_OFF_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `${get.displayDate(dayOff.date)} nie udało się dodać dnia wolnego '${dayOff.name}'`
          )
        );
        dispatch({ type: types.ADD_DAY_OFF_FAILURE });
        return logger.error(error);
      });
  };
}

export function removeDayOffAsync(nextDayOff) {
  const dayOff = Object.assign({}, nextDayOff);
  return (dispatch) => {
    dispatch({ type: types.REMOVE_DAY_OFF });
    return api
      .removeDayOff(nextDayOff.date)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Usunięto dzień wolny '${dayOff.name}' z dnia ${get.displayDate(dayOff.date)}`
          )
        );
        dispatch({ type: types.REMOVE_DAY_OFF_SUCCESS, dayOff });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się usunąć dnia wolnego '${dayOff.name}' z dnia ${get.displayDate(dayOff.date)}`
          )
        );
        return logger.error(error);
      });
  };
}
