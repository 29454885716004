/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function psychologicalIndividualJournalWorkProgramReducer(
  state = initialState.psychologicalIndividualJournalWorkProgram,
  action
) {
  switch (action.type) {
    case types.LOAD_INDIVIDUAL_JOURNAL_CLASSES: {
      const newState = Object.assign({}, state);
      newState.id = action.payload.id;
      newState.basicGoals = action.payload.elements.basicGoals;
      newState.specificGoals = action.payload.elements.specificGoals;
      newState.methods = action.payload.elements.methods;
      newState.conclusions = action.payload.elements.conclusions;
      newState.teachingResources = action.payload.elements.teachingResources;
      newState.notes = action.payload.elements.notes;
      newState.observations = action.payload.elements.observations;
      newState.childId = action.id;
      return newState;
    }
    case types.LOAD_INDIVIDUAL_JOURNAL_CLASSES_CALENDAR: {
      const newState = Object.assign({}, state);
      newState.posts = action.response;
      return newState;
    }
    case types.ADD_INDIVIDUAL_JOURNAL_CLASSES: {
      const newState = Object.assign({}, state);
      newState.posts = [...newState.posts, { ...action.response }];
      return newState;
    }
    case types.EDIT_INDIVIDUAL_JOURNAL_CLASSES: {
      const newState = Object.assign({}, state);
      const postIdx = newState.posts.findIndex((post) => post.id === action.data.id);
      newState.posts[postIdx] = { ...action.data };
      return newState;
    }
    case types.DELETE_INDIVIDUAL_JOURNAL_CLASSES: {
      const newState = Object.assign({}, state);
      newState.posts = newState.posts.filter((post) => post.id !== action.postId);
      return newState;
    }
    case types.ADD_INDIVIDUAL_JOURNAL_PROGRAM: {
      let newState = Object.assign({}, state);
      let { data, response } = action;
      newState[data.type] = [...newState[data.type], { ...data, id: response.elementId }];
      return newState;
    }
    case types.ADD_INDIVIDUAL_JOURNAL_PROGRAM_NOTE: {
      let newState = Object.assign({}, state);
      let { data, response } = action;
      newState.notes = [...newState.notes, { ...data, id: response.elementId }];
      return newState;
    }
    case types.ADD_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION: {
      let newState = Object.assign({}, state);
      let { data, response } = action;
      newState.observations = [...newState.observations, { ...data, id: response.elementId }];
      return newState;
    }
    case types.EDIT_INDIVIDUAL_JOURNAL_PROGRAM: {
      let newState = Object.assign({}, state);
      let { data } = action;
      const elementIdx = newState[data.type].findIndex((el) => el.id === data.id);
      newState[data.type][elementIdx] = data;
      return newState;
    }
    case types.EDIT_INDIVIDUAL_JOURNAL_PROGRAM_NOTE: {
      let newState = Object.assign({}, state);
      let { data } = action;
      const elementIdx = newState.notes.findIndex((el) => el.id === data.id);
      newState.notes[elementIdx] = data;
      return newState;
    }
    case types.EDIT_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION: {
      let newState = Object.assign({}, state);
      let { data } = action;
      const elementIdx = newState.observations.findIndex((el) => el.id === data.id);
      newState.observations[elementIdx] = data;
      return newState;
    }
    case types.DELETE_INDIVIDUAL_JOURNAL_PROGRAM:
    case types.DELETE_INDIVIDUAL_JOURNAL_PROGRAM_NOTE:
    case types.DELETE_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION: {
      let newState = Object.assign({}, state);
      let { data } = action;
      newState[data.type] = [...newState[data.type].filter((el) => el.id !== data.id)];
      return newState;
    }
    default:
      return state;
  }
}
