import BaseModel from '../../BaseModel';
import { ReliefType } from '../../../constants/reliefType';

export class ReliefModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.rate = 0;
    this.type = ReliefType.percentage.value;
    this.isSubsidy = false;
  }
}
