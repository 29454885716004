import PropTypes from 'prop-types';
import React from 'react';
import CalculationMethodOptions from './CalculationMethodOptions';
import AbsenceReportingDeadline from './AbsenceReportingDeadline';
import ReportTypesOptions from './ReportTypesOptions';
import PaymentsOptions from './PaymentsOptions';
import { tryConvertToNumber } from '../../../../utils/numberConverters';
import AttendanceOptions from './AttendanceOptions';
import TextField from '../../../common/TextField';
import AvailableForLegalGuardians from './AvailableForLegalGuardians';
import { ChargingReportTypes } from '../../../../constants/chargingReportTypes';
import ChargingSchemeActivitySwitch from './ChargingSchemeActivitySwitch';
import { Box } from '@mui/material';
import ChargingSchemeMealSwitch from './ChargingSchemeMealSwitch';

const DailyChargingScheme = ({ scheme, onChange, onDeadlineClear, errors }) => {
  return (
    <Box>
      <TextField
        hintText="Nazwa"
        floatingLabelText="Nazwa*"
        floatingLabelFixed={false}
        autoFocus={true}
        name="name"
        value={scheme.name}
        onChange={onChange}
        fullWidth={true}
        errorText={errors.name}
      />
      <CalculationMethodOptions
        defaultSelected={scheme.calculationMethod}
        onSelected={(value) => onChange({ target: { value, name: 'calculationMethod' } })}
      />
      <ReportTypesOptions
        defaultSelected={scheme.reportType}
        onSelected={(value) => onChange({ target: { value, name: 'reportType' } })}
        isDisabled={!!scheme.id}
      />
      <TextField
        hintText="Stawka (zł/dzień)"
        floatingLabelText="Stawka (zł/dzień)*"
        floatingLabelFixed={false}
        name="rate"
        value={scheme.rate}
        onChange={onChange}
        onBlur={(e) => {
          onChange({ target: { name: 'rate', value: tryConvertToNumber(e.target.value) } });
        }}
        fullWidth={true}
        errorText={errors.rate}
      />
      {scheme.reportType === 'activities' ? (
        <ChargingSchemeActivitySwitch
          activityId={scheme.activityId ? scheme.activityId : null}
          onChange={onChange}
          error={errors.activityId}
        />
      ) : null}
      {scheme.reportType === ChargingReportTypes.meals.value ? (
        <ChargingSchemeMealSwitch
          mealId={scheme.mealId ? scheme.mealId : null}
          onChange={onChange}
          error={errors.mealId}
        />
      ) : null}
      <AbsenceReportingDeadline scheme={scheme} onChange={onChange} errors={errors} onClear={onDeadlineClear} />
      <PaymentsOptions scheme={scheme} onChange={onChange} errors={errors} />
      <AttendanceOptions scheme={scheme} onChange={onChange} />
      {scheme.reportType === ChargingReportTypes.catering.value && (
        <AvailableForLegalGuardians scheme={scheme} onChange={onChange} />
      )}
    </Box>
  );
};

DailyChargingScheme.propTypes = {
  scheme: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDeadlineClear: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default DailyChargingScheme;
