export const routePathActions = {
  children: {
    registerChild: 'registerChild'
  },
  student: {
    registerStudent: 'registerStudent'
  },
  childDetails: {
    viewChargingSchemesSection: 'viewChargingSchemesSection'
  },
  legalGuardians: {
    restartActivation: 'restartActivationAction',
    deleteLegalGuardianAccount: 'deleteLegalGuardianAccountAction',
    resetPassword: 'resetPasswordAction'
  },
  eventsCalendar: {
    createEvent: 'createEventAction',
    editEvent: 'editEventAction'
  },
  messageBoard: {
    createPost: 'createPostAction',
    editPost: 'editPostAction',
    viewAllPosts: 'viewAllPostsActions'
  },
  privateMessages: {
    sendToLegalGuardians: 'sendToLegalGuardians',
    sendToStaffMembers: 'sendToStaffMembers'
  }
};
