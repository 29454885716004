import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import * as AlbumTypes from '../../../../../constants/groupsAndActivitiesTypes';
import IconButtonPipe from '../../../../common/IconButtonPipe';
import { Button, Typography, useMediaQuery, useTheme, Box } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import { useDispatch, useSelector } from 'react-redux';
import * as notificationsActions from '../../../../../actions/notificationActions';

import Public from '@mui/icons-material/Public';
import { uniqueId } from 'lodash';

const Options = ({ isEditAvailable, openDialog, ...rest }) => {
  const dispatch = useDispatch();
  const { clipboard } = navigator;
  return (
    <Grid
      item
      style={{
        display: 'flex',
        padding: 10,
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      {...rest}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButtonPipe
          tooltip="Skopiuj link albumu do schowka"
          onClick={async () => {
            try {
              await clipboard.writeText(window.location.href);
              dispatch(notificationsActions.showSuccess('Skopiowano link albumu do schowka'));
            } catch (error) {
              dispatch(notificationsActions.showError('Nie udało się skopiować linku albumu do schowka'));
            }
          }}>
          <FileCopyOutlinedIcon />
        </IconButtonPipe>
        {isEditAvailable && (
          <IconButtonPipe tooltip="Ustawienia" onClick={openDialog}>
            <SettingsOutlinedIcon />
          </IconButtonPipe>
        )}
      </Box>
    </Grid>
  );
};

const Header = ({ album, data, type, onBack, openDialog, isEditAvailable }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);

  const memoCatalogue = useMemo(() => {
    return (
      <>
        {data &&
          data.map((item) => (
            <Typography key={uniqueId()} component="span" sx={{ fontWeight: theme.typography.fontWeightMedium, p: 1 }}>
              {item.name}
            </Typography>
          ))}
      </>
    );
  }, [data]);

  return (
    <Grid
      container
      alignItems={isMobile ? 'center' : 'stretch'}
      wrap={isMobile ? 'wrap' : 'nowrap'}
      sx={{ width: 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          width: isMobile ? '100%' : undefined
        }}>
        <Button
          onClick={onBack}
          variant="text"
          sx={{ px: { sm: 2, md: 5 } }}
          startIcon={
            <>
              <ArrowBackIosIcon sx={{ fontSize: 32 }} />
            </>
          }>
          {!isMobile ? (
            <Typography variant="h6" sx={{ fontWeight: theme.typography.fontWeightMedium }}>
              Albumy
            </Typography>
          ) : null}
        </Button>
        {isMobile && <Options isEditAvailable={isEditAvailable} openDialog={openDialog} sx={{ ml: 'auto' }} />}
      </Box>

      <Grid container direction="column" sx={{ p: { xs: 3, sm: 1, md: 3 } }}>
        <Grid container justifyContent="center">
          <Typography variant="h5" sx={{ fontWeight: theme.typography.fontWeightBold, wordBreak: 'break-word' }}>
            {album.name}
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          {album.description && (
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: theme.typography.fontWeightRegular, wordBreak: 'break-word' }}>
              {album.description}
            </Typography>
          )}
        </Grid>

        {type !== AlbumTypes.PUBLIC ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
            <ChildCareIcon />
            {memoCatalogue}
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
            <Public />
            <Typography component="span" sx={{ fontWeight: theme.typography.fontWeightMedium, p: 1 }}>
              Katalog ogólny {`${nadarzyn ? 'szkoły' : 'przedszkola'}`}
            </Typography>
          </Box>
        )}
      </Grid>
      {!isMobile && <Options isEditAvailable={isEditAvailable} openDialog={openDialog} />}
    </Grid>
  );
};

Options.propTypes = {
  openDialog: PropTypes.func.isRequired,
  isEditAvailable: PropTypes.bool.isRequired
};

Header.propTypes = {
  album: PropTypes.object.isRequired,
  data: PropTypes.array,
  type: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  isEditAvailable: PropTypes.bool.isRequired
};

export default Header;
