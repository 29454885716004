import PropTypes from 'prop-types';
import React from 'react';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import EmptyState from '../../common/EmptyState';
import DotsMenu from '../../common/menu/DotsMenu';
import { ListItem as ListItem2, List as List2, ListItemText, ListItemSecondaryAction, Typography } from '@mui/material';

const WorkPlanListv2 = ({ sets, onSelected, onRemove, disabled }) => {
  const renderListItem = (set, i) => {
    return (
      <ListItem2
        key={set.goalName + i}
        dense
        button={!disabled}
        component="li"
        onClick={() => (disabled ? {} : onSelected(set, i))}
        aria-label={`Edytuj zamierzenie wychowawczo-dydaktyczne ${set.goalName}`}>
        <ListItemText>
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 18 }}>
            {set.goalName}
          </Typography>
        </ListItemText>
        {!disabled && (
          <ListItemSecondaryAction>
            <DotsMenu>
              {(onClose) => [
                <ActionConfirmationMenuItem
                  actionLabel="Usuń"
                  confirmationText="Czy na pewno chcesz usunąć zamierzenie wychowawczo-dydaktyczne?"
                  confirmationTitle="Usuń zamierzenie wychowawczo-dydaktyczne"
                  confirmLabel="Usuń"
                  onConfirm={() => onRemove(i)}
                  onItemClick={onClose}
                  onCancel={() => {}}
                  key="usuń"
                />
              ]}
            </DotsMenu>
          </ListItemSecondaryAction>
        )}
      </ListItem2>
    );
  };

  const renderList = (array) => {
    return <List2 sx={{ p: 3 }}>{array.map((item, i) => renderListItem(item, i))}</List2>;
  };

  return !sets.length ? (
    <EmptyState message="Nie zdefiowano jeszcze żadnego zamierzenia wychowawczo-dydaktycznego" />
  ) : (
    renderList(sets)
  );
};

WorkPlanListv2.propTypes = {
  sets: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default WorkPlanListv2;
