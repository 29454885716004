import { Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import PropTypes from 'prop-types';

export const UnitListElement = ({ label, desc, checked, handleEnable, handleDisable, disabled }) => {
  UnitListElement.propTypes = {
    label: PropTypes.string,
    desc: PropTypes.string,
    checked: PropTypes.bool,
    handleEnable: PropTypes.func,
    handleDisable: PropTypes.func,
    disabled: PropTypes.bool
  };
  UnitListElement.defaultProps = {
    disabled: false
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Switch
        disabled={disabled}
        sx={{ transform: 'scale(1.5)', mx: 3 }}
        checked={checked}
        onClick={checked ? handleEnable : handleDisable}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }} variant="h6">
          {label}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          <Typography variant="subtitle1">
            {desc}&nbsp;
            <strong>{checked ? 'włączone' : 'wyłączone'}</strong>.{' '}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
