import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemAvatar,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MealPicker } from '../../../meals/MealPicker';
import MealAvatar from '../../../meals/MealAvatar';

const ChargingSchemeMealSwitch = ({ onChange, mealId, error }) => {
  const meals = useSelector((state) => state.meals);
  console.log('mealId', mealId);

  const [showMealPickerDialog, setShowMealPickerDialog] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState(meals.find((meal) => meal.id === mealId) || null);

  useEffect(() => {
    if ((mealId, selectedMeal)) {
      console.log(meals);
      const foundMeal = meals.find((meal) => meal.id === mealId);
      if (foundMeal) {
        console.log(foundMeal);
        setSelectedMeal(foundMeal);
        onChange({ target: { value: selectedMeal.id, name: 'mealId' } });
      }
    }
  }, [meals, mealId, onChange, selectedMeal]);

  // useEffect(() => {
  //   if (mealId && selectedMeal) {
  //     meals.forEach((meal) => (meal.id === meals.id ? setSelectedMeal(meal) : null));
  //     onChange({ target: { value: selectedMeal.id, name: 'mealId' } });
  //   }
  //   // setSelectedActivity(activityId ? activities.find((activity) => activity.id === activityId) : activities[0]);
  // }, [mealId, meals, onChange, selectedMeal]);

  const handleOpenCloseDialog = () => {
    setShowMealPickerDialog((prev) => !prev);
  };

  const handleChangeMeal = (meal) => {
    setSelectedMeal(meal);
    setShowMealPickerDialog((prev) => !prev);
    onChange({ target: { value: meal.id, name: 'mealId' } });
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', m: '8px 0' }}>
        {!!selectedMeal ? (
          <>
            <ListItemAvatar>
              <MealAvatar meal={selectedMeal} />
            </ListItemAvatar>

            <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, pr: 3 }}>
              {selectedMeal.name}
            </Typography>
          </>
        ) : (
          <Typography sx={{ color: (theme) => (error ? theme.palette.color.error : theme.palette.color.contrast) }}>
            Wybierz posiłek
          </Typography>
        )}

        <Button variant="contained" aria-label="Wybierz posiłek" sx={{ mr: 1 }} onClick={handleOpenCloseDialog}>
          <SwapHorizIcon /> Wybierz posiłek
        </Button>
      </Box>
      <Dialog open={showMealPickerDialog}>
        <DialogTitle>Wybierz posiłek</DialogTitle>
      </Dialog>
      <Dialog open={showMealPickerDialog} maxWidth="sm" fullWidth={true} onClose={() => handleOpenCloseDialog}>
        <DialogTitle>Wybierz aktywność</DialogTitle>
        <DialogContent>
          <MealPicker meals={meals} handleSelect={handleChangeMeal} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" aria-label="Anuluj" onClick={handleOpenCloseDialog}>
            Anuluj
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ChargingSchemeMealSwitch.propTypes = {
  onChange: PropTypes.func,
  activityId: PropTypes.string
};

export default ChargingSchemeMealSwitch;
