/*eslint indent:0*/
import initialState from './initial';
import { ActivityDetailsModel, EnrolledChildModel } from '../models/activities/ActivityModels';
import { compareNames } from '../utils/personNamesComparator';
import * as types from '../actions/actionTypes';

export default function activityDetailsReducer(state = initialState.activityDetails, action) {
  switch (action.type) {
    case types.LOAD_ACTIVITY_DETAILS_SUCCESS: {
      return new ActivityDetailsModel().assign(action.activityDetails);
    }
    case types.CREATE_ACTIVITY_DETAILS_SUCCESS: {
      return new ActivityDetailsModel().assign(action.activityDetails);
    }
    case types.UPDATE_ACTIVITY_DETAILS_SUCCESS: {
      return new ActivityDetailsModel().assign(action.activityDetails);
    }
    case types.ENROLL_CHILDREN_TO_ACTIVITY_SUCCESS: {
      const activityDetails = new ActivityDetailsModel().assign(state);
      activityDetails.enrolledChildren = action.children
        .map((child) => new EnrolledChildModel().assign(child))
        .sort(compareNames);
      return activityDetails;
    }
    case types.REMOVE_CHILD_FROM_ACTIVITY_SUCCESS: {
      const activityDetails = new ActivityDetailsModel().assign(state);
      activityDetails.enrolledChildren = [
        ...activityDetails.enrolledChildren.filter((child) => child.id !== action.childId)
      ];
      return activityDetails;
    }
    default:
      return state;
  }
}
