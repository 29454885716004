import { List, ListItem, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import EmptyState from '../common/EmptyState';
import PersonAvatar from '../forms/PersonAvatar';

const GroupArchiveTeachersList = ({ teachers }) => {
  return (
    <>
      {teachers.length > 0 ? (
        <List>
          {teachers.map((teacher) => {
            return (
              <ListItem
                key={teacher.id}
                sx={{
                  '&:hover': {
                    background: (theme) => theme.palette.background.hover
                  }
                }}>
                <PersonAvatar
                  sx={{ mr: 2 }}
                  initials
                  alt={teacher.firstName}
                  url={teacher.photoUrl}
                  firstName={teacher.firstName}
                  lastName={teacher.lastName}
                />
                <ListItemText
                  primary={`${teacher.lastName} ${teacher.firstName}`}
                  primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
                />
              </ListItem>
            );
          })}
        </List>
      ) : (
        <EmptyState message="Brak nauczycieli przypisanych do grupy" contrast />
      )}
    </>
  );
};

GroupArchiveTeachersList.propTypes = {
  teachers: PropTypes.array.isRequired
};

export default GroupArchiveTeachersList;
