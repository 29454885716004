import React from 'react';
import PropTypes from 'prop-types';
import ImageList from '@mui/material/ImageList';
import SingleMultimedia from '../List/SingleMultimedia';
import makeStyles from '@mui/styles/makeStyles';
import { useMediaQuery, useTheme } from '@mui/material';

const useStyles = makeStyles(() => ({
  list: {
    overflowY: 'visible',
    justifyContent: 'center'
  }
}));

const DialogContent = ({ selectedItems }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const cl = useStyles();

  const colNumber = () => {
    const count = selectedItems.length;
    if (isMobile) return 2;
    if (count < 3) return 3;
    if (count >= 3 && count < 5) return count;
    return 5;
  };
  return (
    <ImageList
      cellheight={125}
      cols={colNumber()}
      spacing={isMobile ? 20 : 5}
      sx={{
        m: 0,
        display: selectedItems.length < 3 && 'flex',
        alignItems: selectedItems.length < 3 && 'center'
      }}
      classes={{ root: cl.list }}>
      {selectedItems.map((tile) => {
        return <SingleMultimedia key={tile.id} hideFullscreenButton={true} tile={tile} />;
      })}
    </ImageList>
  );
};

DialogContent.propTypes = {
  selectedItems: PropTypes.array
};

export default DialogContent;
