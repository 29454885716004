import PropTypes from 'prop-types';
import React from 'react';
import ActivityChildrenList from './ActivityChildrenList';
import ActivityChildrenEditList from './ActivityChildrenEditList';
import CardDialogEditForm from '../../forms/CardDialogEditForm';
import EmptyState from '../../common/EmptyState';

class ActivityChildrenCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      errors: null,
      selectedChildrenIds: []
    };
    this.getEnrolledChildrenWithDetails = this.getEnrolledChildrenWithDetails.bind(this);
    this.getNotEnrolledChildren = this.getNotEnrolledChildren.bind(this);
    this.handleEnrolledChildrenChange = this.handleEnrolledChildrenChange.bind(this);
  }

  getEnrolledChildrenWithDetails() {
    const tempPupils = this.props.activity.enrolledChildren
      .map((enrollmentInfo) => ({ enrollmentInfo, pupil: this.props.pupils.find((c) => c.id === enrollmentInfo.id) }))
      .filter((info) => !!info.pupil);
    return tempPupils;
  }

  getNotEnrolledChildren() {
    return this.props.pupils.filter((child) => !this.props.activity.enrolledChildren.find((c) => c.id === child.id));
  }

  handleEnrolledChildrenChange(selectedChildrenIds) {
    this.setState({ selectedChildrenIds });
  }

  renderEditForm() {
    return (
      <ActivityChildrenEditList
        allPupils={this.getNotEnrolledChildren()}
        enrolledPupilsIds={this.props.activity.enrolledChildren.map((e) => e.id)}
        onChange={this.handleEnrolledChildrenChange}
      />
    );
  }

  renderReadonlyForm() {
    return <ActivityChildrenList pupils={this.getEnrolledChildrenWithDetails()} onRemoveChild={this.props.onRemove} />;
  }

  render() {
    return (
      <CardDialogEditForm
        maxWidth="md"
        title="Dzieci"
        subtitle={`Liczba: ${this.getEnrolledChildrenWithDetails().length || 0}`}
        isInitiallyExpanded={true}
        onSave={() =>
          this.props.onSave([
            ...this.props.activity.enrolledChildren.map((c) => c.id),
            ...this.state.selectedChildrenIds
          ])
        }
        onCancel={this.props.onCancel}
        onAction={this.props.onEdit}
        onValidationDone={(errors) => this.setState({ errors })}
        isDialogOpen={this.props.isEditing}
        isProcessing={this.props.isProcessing}
        statePathToUi="activityDetailsUi.enrolledChildren"
        readonlyForm={this.renderReadonlyForm()}
        editForm={this.renderEditForm()}
        isEmpty={this.props.activity.enrolledChildren.length === 0}
        emptyForm={<EmptyState message="Brak przypisanych dzieci" contrast />}
        saveDisabled={this.getNotEnrolledChildren().length === 0}
        actionTitle="Przypisz dzieci do zajęć dodatkowych"
        actionLabel="Przypisz dzieci"
      />
    );
  }
}

ActivityChildrenCard.propTypes = {
  activity: PropTypes.object.isRequired,
  pupils: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired
};

export default ActivityChildrenCard;
