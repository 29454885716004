/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { GroupArchiveDetailsModel } from '../models/groups/GroupArchiveModels';

export default function groupArchiveDetailsReducer(state = initialState.archives.groupDetails, action) {
  switch (action.type) {
    case types.LOAD_GROUP_ARCHIVE_DETAILS_SUCCESS: {
      return new GroupArchiveDetailsModel().assign(action.groupDetails);
    }
    default:
      return state;
  }
}
