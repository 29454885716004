import { List, ListItem, ListItemText, ListSubheader, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../forms/DialogForm';

class CalculateChildrenCorrectiveDuesDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calculationParameters: {
        inAdvanceAbsencesCalculation: false,
        withoutPreviousMonthCorrectionCalculation: false,
        calculateCorrectionsWithCurrentChargingScheme: false,
        inArrearsUseDeclaredHoursOnMissingEntries: false
      },
      errors: {}
    };

    this.handleParamsChange = this.handleParamsChange.bind(this);
  }

  handleParamsChange(event) {
    const field = event.target.name;
    const { calculationParameters } = this.state;
    calculationParameters[field] = !calculationParameters[field];
    if (field === 'withoutPreviousMonthCorrectionCalculation' && calculationParameters[field]) {
      calculationParameters.calculateCorrectionsWithCurrentChargingScheme = false;
    }
    return this.setState({ calculationParameters });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    const { calculationParameters } = this.state;
    return (
      <DialogForm
        maxWidth="md"
        header="Generowanie korekt"
        onSave={() => onSave(this.state.calculationParameters)}
        onCancel={onCancel}
        saveLabel="Przelicz"
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="settlementsCalculationUi">
        <List>
          <ListSubheader sx={{ fontSize: 16 }}>Sposób naliczania odpisów</ListSubheader>
          <ListItem>
            <ListItemText
              key="Uwzględniaj zaplanowane obecności"
              primary="Uwzględniaj zaplanowane obecności"
              secondary="Od opłat naliczanych z góry zostaną odpisane zaplanowane w tym miesiącu nieobecności"
            />
            <Switch
              name="inAdvanceAbsencesCalculation"
              checked={calculationParameters.inAdvanceAbsencesCalculation}
              onChange={this.handleParamsChange}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              key="Używaj deklarowanych godzin obecności w przypadku braku faktycznych wpisów"
              primary="Używaj deklarowanych godzin obecności w przypadku braku faktycznych wpisów"
              secondary='Wpływa tylko na opłaty określone schematem o sposobie naliczania "z dołu".'
            />
            <Switch
              name="inArrearsUseDeclaredHoursOnMissingEntries"
              checked={calculationParameters.inArrearsUseDeclaredHoursOnMissingEntries}
              onChange={this.handleParamsChange}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              key="Nie wyrównuj opłat z poprzedniego miesiąca"
              primary="Nie wyrównuj opłat z poprzedniego miesiąca"
              secondary="Nie zostaną wyliczone odpisy korygujące rozliczenia z poprzedniego miesiąca."
            />
            <Switch
              name="withoutPreviousMonthCorrectionCalculation"
              checked={calculationParameters.withoutPreviousMonthCorrectionCalculation}
              onChange={this.handleParamsChange}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              key="Wyrównaj opłaty z poprzedniego miesiąca korzystając z obecnego schematu płatności"
              primary="Wyrównaj opłaty z poprzedniego miesiąca korzystając z obecnego schematu płatności"
              secondary="Odpisy korygujące rozliczenia z poprzedniego miesiąca zostaną obliczone za pomocą aktualnych schematów płatności."
              secondaryLines={2}
            />

            <Switch
              disabled={calculationParameters.withoutPreviousMonthCorrectionCalculation}
              name="calculateCorrectionsWithCurrentChargingScheme"
              checked={calculationParameters.calculateCorrectionsWithCurrentChargingScheme}
              onChange={this.handleParamsChange}
            />
          </ListItem>
        </List>
      </DialogForm>
    );
  }
}

CalculateChildrenCorrectiveDuesDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired
};

export default CalculateChildrenCorrectiveDuesDialog;
