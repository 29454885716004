import api from '../api/AttributesApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import { attributeObjectTypes } from '../constants/attributeObjectTypes';
import * as logger from '../utils/logger';

export function loadAttributesSetsAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_ATTRIBUTES_SETS });
    return api
      .getAttributesSets()
      .then((sets) => dispatch({ type: types.LOAD_ATTRIBUTES_SETS_SUCCESS, sets }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać zestawów atrybutów'));
        return logger.error(error);
      });
  };
}

export function addAttributesSetStart() {
  return { type: types.CREATE_ATTRIBUTES_SET };
}

export function addAttributesSetCancel() {
  return { type: types.CREATE_ATTRIBUTES_SET_CANCEL };
}

export function addAttributesSetAsync(nextSet) {
  const set = Object.assign({}, nextSet);
  return (dispatch) => {
    dispatch({ type: types.CREATE_ATTRIBUTES_SET_SUBMIT });
    return api
      .addAttributeSet(set)
      .then((id) => {
        dispatch(
          notificationActions.showSuccess(
            `Dodano zestaw atrybutów '${set.name}' przypisany do obiektów typu '${
              attributeObjectTypes[set.objectType].name
            }'`
          )
        );
        dispatch({ type: types.CREATE_ATTRIBUTES_SET_SUCCESS, set: Object.assign(set, { id }) });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.CREATE_ATTRIBUTES_SET_CANCEL, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się dodać zestawu atrybutów '${set.name}' przypisanego do obiektów typu '${
              attributeObjectTypes[set.objectType].name
            }'`
          )
        );
        return logger.error(error);
      });
  };
}

export function updateAttributesSetStart() {
  return { type: types.UPDATE_ATTRIBUTES_SET };
}

export function updateAttributesSetCancel() {
  return { type: types.UPDATE_ATTRIBUTES_SET_CANCEL };
}

export function updateAttributesSetAsync(nextSet) {
  const set = Object.assign({}, nextSet);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_ATTRIBUTES_SET_SUBMIT });
    return api
      .updateAttributeSet(set)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Zaktualizowano zestaw atrybutów '${set.name}' przypisany do obiektów typu '${
              attributeObjectTypes[set.objectType].name
            }'`
          )
        );
        dispatch({ type: types.UPDATE_ATTRIBUTES_SET_SUCCESS, set });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.UPDATE_ATTRIBUTES_SET_CANCEL, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się zaktualizować zestawu atrybutów '${set.name}' przypisanego do obiektów typu '${
              attributeObjectTypes[set.objectType].name
            }'`
          )
        );
        return logger.error(error);
      });
  };
}

export function removeAttributesSetAsync(nextSet) {
  const set = Object.assign({}, nextSet);
  return (dispatch) => {
    dispatch({ type: types.REMOVE_ATTRIBUTES_SET });
    return api
      .removeAttributeSet(set.id)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Usunięto zestaw atrybutów '${set.name}' przypisany do obiektów typu '${
              attributeObjectTypes[set.objectType].name
            }'`
          )
        );
        dispatch({ type: types.REMOVE_ATTRIBUTES_SET_SUCCESS, set });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się usunąć zestawu atrybutów '${set.name}' przypisanego do obiektów typu '${
              attributeObjectTypes[set.objectType].name
            }'`
          )
        );
        return logger.error(error);
      });
  };
}
