import PropTypes from 'prop-types';
import React from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import UnitAttendanceSummaryItem from './UnitAttendanceSummaryItem';
import { browserHistory } from 'react-router';
import { routePaths } from '../../../routePaths';
import { useDispatch, useSelector } from 'react-redux';
import userRoles from '../../../constants/userRoles';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import ReplayIcon from '@mui/icons-material/Replay';
import { loadTodayAttendanceSummaryAsync } from '../../../actions/attendanceActions';

export const canShowAbsence = (user) => {
  return (
    user.userRole === userRoles.staffMemberTeacher ||
    user.userRole === userRoles.staffMemberPrincipal ||
    user.userRole === userRoles.staffMemberAccountant ||
    user.userRole === userRoles.staffMemberSecretary
  );
};
const UnitAttendanceSummary = ({ attendanceSummary }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);

  const handleRefresh = () => {
    dispatch(loadTodayAttendanceSummaryAsync());
  };

  const showAbsenceTomorrow = () => {
    if (canShowAbsence(user)) {
      browserHistory.push({ pathname: routePaths.absentTomorrow });
    }
  };
  const showAttendance = () => {
    if (canShowAbsence(user)) browserHistory.push({ pathname: routePaths.attendance });
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 2
        }}>
        <Typography
          sx={{
            color: (theme) => theme.palette.color.primary,
            fontWeight: (theme) => theme.typography.fontWeightBold,
            opacity: 0.9,
            display: 'flex',
            alignItems: 'flex-end'
          }}
          component="h3"
          variant="h6">
          <ChildCareIcon fontSize="large" sx={{ mr: 2 }} />
          Dzisiaj w placówce
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{
            ml: 'auto',
            textTransform: 'none'
          }}
          onClick={() => handleRefresh()}
          startIcon={<ReplayIcon sx={{ fontSize: '3rem' }} />}>
          Odśwież
        </Button>
      </Box>
      <Paper
        sx={{
          background: (theme) => theme.palette.color.color26,
          m: 0,
          p: 4,
          color: (theme) => theme.palette.color.color2
        }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={5}
            md={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', sm: 'flex-start' }
            }}>
            <Typography
              sx={{
                pl: { xs: 0, sm: 3 },
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: (theme) => theme.typography.fontWeightRegular,
                fontSize: '28px'
              }}>
              Cała placówka
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7} md={9}>
            <Grid container sx={{ display: 'flex' }}>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  onClick={showAttendance}
                  sx={
                    canShowAbsence(user) && {
                      borderRadius: 4,
                      cursor: 'pointer',
                      '&:hover': { background: (theme) => theme.palette.background.hover }
                    }
                  }>
                  <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <UnitAttendanceSummaryItem name="Obecne" value={attendanceSummary.present} />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <UnitAttendanceSummaryItem
                      name="Nieobecne"
                      value={attendanceSummary.enrolled - attendanceSummary.present}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                onClick={showAbsenceTomorrow}
                item
                xs={6}
                md={3}
                sx={
                  canShowAbsence(user) && {
                    borderRadius: 4,
                    cursor: 'pointer',
                    '&:hover': { background: (theme) => theme.palette.background.hover }
                  }
                }>
                <UnitAttendanceSummaryItem name="Nieobecne jutro" value={attendanceSummary.absentTomorrow} />
              </Grid>
              <Grid item xs={6} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                <UnitAttendanceSummaryItem name="Liczba dzieci" value={attendanceSummary.enrolled} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

UnitAttendanceSummary.propTypes = {
  attendanceSummary: PropTypes.object.isRequired
};

export default UnitAttendanceSummary;
