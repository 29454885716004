import { DialogContent } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPsychologicalSchemeAsync, editPsychologicalSchemeAsync } from '../../../../actions/psychologicalActions';
import UnitSchemeElementForm from './UnitSchemeElementForm';
import DialogForm from '../../../forms/DialogForm';
import { UnitSchemeValidator } from './UnitSchemeValidator';

const UnitSchemeElementDialog = ({ selectedDialog, selectedField, selectedScheme, onCancel, isEditing }) => {
  const [errors, setErrors] = useState({});
  const [data, setData] = useState(selectedScheme || {});
  const UI = useSelector((state) => state.psychologicalSchemesUI);

  const dispatch = useDispatch();
  const handleCancel = () => {
    setErrors({});
    onCancel();
  };

  const validateData = (data) => {
    if (!data.templateName || !data.content || !data.fieldName || data.templateName.length > 100) {
      return false;
    }
    return true;
  };

  const handleSave = () => {
    const preparedData = {
      fieldName: selectedField,
      templateName: data.templateName.trim(),
      content: data.content
    };

    if (!!isEditing && validateData(preparedData)) {
      preparedData.id = selectedScheme.id;
      //TODO
      dispatch(editPsychologicalSchemeAsync(preparedData, selectedField));
      setErrors({});
    } else if (validateData(preparedData)) {
      dispatch(addPsychologicalSchemeAsync(preparedData, selectedField));
      setErrors({});
    }
  };

  return (
    <DialogForm
      header={isEditing ? 'Edycja schematu' : !!selectedScheme ? 'Podgląd schematu' : 'Nowy schemat'}
      fullWidth
      hideSubmit={!isEditing && !!selectedScheme}
      maxWidth="md"
      isDialogOpen={UI.isEditing}
      isProcessing={UI.isProcessing}
      onSave={handleSave}
      onCancel={handleCancel}
      statePathToUi="psychologicalSchemesUI"
      saveDisabled={!isEditing && !!selectedScheme}
      onClose={handleCancel}
      onValidate={() => {
        return new UnitSchemeValidator().validate(data);
      }}
      onValidationDone={(errors) => {
        setErrors({ errors });
      }}
      aria-labelledby="Nowy schemat"
      aria-describedby="Nowy schemat">
      <DialogContent>
        <UnitSchemeElementForm
          errors={errors}
          selectedDialog={selectedDialog || []}
          setData={setData}
          isEditing={isEditing}
          selectedScheme={selectedScheme}
        />
      </DialogContent>
    </DialogForm>
  );
};

export default UnitSchemeElementDialog;
