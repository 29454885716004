import PropTypes from 'prop-types';
import React from 'react';
import MenuRoute from './MenuRoute';
import { routePaths } from '../../../routePaths';
import { List, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import AssignmentSharpIcon from '@mui/icons-material/AssignmentSharp';
import GroupIcon from '@mui/icons-material/Group';
import BookIcon from '@mui/icons-material/Book';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import GroupsSharpIcon from '@mui/icons-material/GroupsSharp';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ChildrenIcon from '@mui/icons-material/FormatListNumberedOutlined';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';

const SpecialistMenu = ({ currentRoute, onRouteSelect, insurance }) => {
  let constKey = 0;
  const getNextKey = () => constKey++;
  const rest = { selectedRoutePath: currentRoute, onClick: onRouteSelect };
  const theme = useTheme();
  const isContrastColor = useSelector((state) => state.theme);
  return (
    <List>
      <MenuRoute
        text="Pulpit"
        icon={
          <HomeIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
          />
        }
        routePath={routePaths.home}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Rejestr dzieci"
        icon={
          <ChildrenIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color7 }}
          />
        }
        routePath={routePaths.children}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        primaryText="Dziennik specjalistyczny"
        icon={
          <AssignmentSharpIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color3 }}
          />
        }
        primaryTogglesNestedList={true}
        nestedItems={[
          <MenuRoute
            text="Grupy"
            icon={
              <GroupIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color8 }}
              />
            }
            routePath={routePaths.psychologicalGroups}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Pomoc psychologiczno-pedagogiczna"
            icon={
              <AssignmentTurnedInIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color7 }}
              />
            }
            routePath={routePaths.psychologicalHelp}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Dziennik zajęć indywidualnych"
            icon={
              <PermContactCalendarIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.individualClasses}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Dziennik zajęć grupowych"
            icon={
              <GroupsSharpIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color9 }}
              />
            }
            routePath={routePaths.groupClasses}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Indywidualne dzienniki zajęć specjalistycznych"
            icon={
              <SwitchAccountIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color15 }}
              />
            }
            routePath={routePaths.individualClassesJournal}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Zapis pracy specjalisty"
            icon={
              <NoteAltOutlinedIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color12 }}
              />
            }
            routePath={routePaths.specialistNotes}
            key={getNextKey()}
            {...rest}
          />
        ]}
      />
      <MenuRoute
        text="Grupy"
        icon={
          <GroupIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color25 }}
          />
        }
        routePath={routePaths.groups}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Blog 4Parents"
        icon={
          <BookIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color6 }}
          />
        }
        key={getNextKey()}
        component="a"
        href="https://4parents.education/blog/"
        target="_blank"
      />
    </List>
  );
};

SpecialistMenu.propTypes = {
  firstGroupId: PropTypes.string,
  currentRoute: PropTypes.string.isRequired,
  onRouteSelect: PropTypes.func.isRequired,
  insurance: PropTypes.object
};

export default SpecialistMenu;
