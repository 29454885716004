import { check } from '../../validation/validateHelper';
import { BaseModelValidator } from '../../BaseModelValidator';
import { AttributeDefinitionModel } from './AttributeDefinitionModel';

export class AttributeDefinitionModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = AttributeDefinitionModel;

    this.constraints = {
      name: (value) => check.isEmpty(value) && 'Nie podano nazwy',
      type: (value) => check.isEmpty(value) && 'Nie podano typu'
    };
  }
}
