import PropTypes from 'prop-types';
import React from 'react';

import _ from 'lodash';
import SingleListItem from './SingleListItem';
import { List } from '@mui/material';

const ChildrenList = ({ pupils }) => {
  const preparedPupils = _.chain(pupils)
    .groupBy((pupil) => (pupil.lastName.length ? pupil.lastName[0].toUpperCase() : ''))
    .toPairs()
    .value();
  let listItems = [];

  preparedPupils.forEach((group) => {
    const firstPupilInGroup = _.head(group[1]);
    const restOfPupils = _.tail(group[1]);

    listItems.push(
      <SingleListItem key={firstPupilInGroup.id} pupil={firstPupilInGroup} groupIdentificationText={group[0]} />
    );

    listItems = [...listItems, restOfPupils.map((pupil) => <SingleListItem key={pupil.id} pupil={pupil} inset />)];
  });
  return <List sx={{ p: 0 }}>{listItems}</List>;
};

ChildrenList.propTypes = {
  pupils: PropTypes.array.isRequired
};

export default ChildrenList;
