import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/AddBox';

export const extendAuthorizationDateMenuItem = (isAuthorized, authorizationExpirationDate, onTouch, key) => {
  return isAuthorized && authorizationExpirationDate && moment().isAfter(authorizationExpirationDate) ? (
    <MuiMenuItem key={key} onClick={() => onTouch()}>
      <ListItemIcon>
        <AddIcon />
      </ListItemIcon>
      <ListItemText primary="Przedłuż upoważnienie" />
    </MuiMenuItem>
  ) : null;
};

const MuiMenuItem = React.forwardRef((props, ref) => {
  return <MenuItem ref={ref} {...props} />;
});

extendAuthorizationDateMenuItem.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  onTouch: PropTypes.func,
  key: PropTypes.any
};
