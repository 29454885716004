import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from '../../common/Checkbox';
import { Box, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import staffMemberRoles from '../../../constants/staffMemberRoles';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { useTheme } from '@emotion/react';

const ReportsList = ({ reports, disabled, onChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const rolesVisibility = (report) => (
    <Box
      sx={{
        px: 2,
        mx: 2,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        flexWrap: 'wrap'
      }}>
      {Object.keys(staffMemberRoles).map((role) => (
        <Checkbox
          key={role}
          checkedIcon={<VisibilityIcon sx={{ color: (theme) => theme.palette.color.color7 }} />}
          uncheckedIcon={<VisibilityOffIcon color="error" />}
          label={staffMemberRoles[role].name}
          defaultChecked={report.visibleTo.some((r) => r === role)}
          onCheck={(e, isInputChecked) =>
            onChange && onChange({ reportType: report.type, role, visible: isInputChecked })
          }
          sx={{
            mr: 2
          }}
          labelStyle={{ color: (theme) => theme.palette.color.contrast }}
          disabled={disabled}
        />
      ))}
    </Box>
  );

  return (
    <List>
      {reports.map((report) => {
        return (
          <Box key={report.type}>
            <ListItem
              sx={{
                width: '100%',
                '&:hover': {
                  background: (theme) => theme.palette.background.hover
                }
              }}>
              <ListItemText
                primary={report.name}
                primaryTypographyProps={{
                  variant: 'h6',
                  fontWeight: (theme) => theme.typography.fontWeightRegular
                }}
                secondaryTypographyProps={{ component: 'div' }}
                secondary={
                  <FieldsetWrapper
                    legend={report.name}
                    sx={{
                      color: (theme) => theme.palette.color.contrast
                    }}>
                    {rolesVisibility(report)}
                  </FieldsetWrapper>
                }
                disabled={true}
              />
            </ListItem>
          </Box>
        );
      })}
    </List>
  );
};

ReportsList.propTypes = {
  reports: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default ReportsList;
