import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import staffMemberRoles from '../../constants/staffMemberRoles';
import Paper from '@mui/material/Paper';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FilterBar from '../common/FilterBar';
import StaffMembersArchiveList from './StaffMembersArchiveList';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import { Box } from '@mui/material';
import ContentWrapper from '../common/contentWrapper';
import EmptyState from '../common/EmptyState';

class StaffMembersPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      filteredStaffMembers: []
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleLoaded = this.handleLoaded.bind(this);
  }

  handleLoaded() {
    this.setState({
      filteredStaffMembers: this.props.staffMembers
    });
  }

  handleFilterChange(filteredList) {
    this.setState({
      filteredStaffMembers: filteredList
    });
  }

  render() {
    return (
      <LoadingRenderWrapper onLoaded={this.handleLoaded}>
        <Box>
          <PageHeaderText title="Zarchiwizowani pracownicy" titleIcon={<AssignmentIndIcon />} />
          <ContentWrapper>
            <Box sx={{ my: 2 }}>
              <FilterBar
                itemsToFilter={this.props.staffMembers}
                onFilter={this.handleFilterChange}
                filter={(staff, keyword) =>
                  staff.lastName.toLowerCase().includes(keyword) || staff.firstName.toLowerCase().includes(keyword)
                }
              />
            </Box>

            <Paper>
              <StaffMembersArchiveList staffMembers={this.state.filteredStaffMembers} roles={staffMemberRoles} />
              {this.state.filteredStaffMembers.length === 0 && (
                <EmptyState contrast message="Brak wyników wyszukiwania" />
              )}
            </Paper>
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

StaffMembersPage.propTypes = {
  staffMembers: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    staffMembers: state.archives.staffMembers
  };
}

export default connect(mapStateToProps)(StaffMembersPage);
