import { Box, Button, Grid, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import GroupIcon from '@mui/icons-material/Group';
import ContentWrapper from '../common/contentWrapper';
import FilterBar from '../common/FilterBar';
import GroupsList from '../groups/GroupsList';
import GroupDetailsCard from '../groupDetails/details/GroupDetailsCard';
import { GroupDetailsModelValidator } from '../../models/groups/GroupModelsValidator';
import { GroupDetailsModel } from '../../models/groups/GroupModels';
import { create } from '../../utils/AvatarContentGenerator';
import { createPsychologicalGroupAsync } from '../../actions/psychologicalGroupsActions';
import EmptyState from '../common/EmptyState';
import { routePaths } from '../../routePaths';

const PsychologicalGroupsContainer = () => {
  const dispatch = useDispatch();
  const [isAdding, setAdding] = useState(false);
  const [group, setGroup] = useState(Object.assign({}, new GroupDetailsModel()));
  const groups = useSelector((state) => state.psychologicalGroups);
  const [filteredGroups, setFilteredGroups] = useState([]);

  useEffect(() => {
    setFilteredGroups(groups.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)));
  }, [groups]);

  const handleGroupCreate = () => {
    let newGroup = group;
    if (!newGroup.color) {
      newGroup = Object.assign({}, newGroup, { color: create().generateColorFromName(group.name) });
    }
    dispatch(createPsychologicalGroupAsync(newGroup));
    setAdding(false);
  };

  const handleGroupChange = (event) => {
    setGroup((prevState) => {
      const field = event.target.name;
      const updatedGroup = Object.assign({}, prevState);
      updatedGroup[field] = event.target.value;
      return updatedGroup;
    });
  };

  return (
    <Box>
      <PageHeaderText title="Grupy psychologiczno-pedagogiczne" titleIcon={<GroupIcon />} />
      <ContentWrapper>
        {!isAdding ? (
          <>
            <Grid container>
              <Grid item xs={12} md={6}>
                <FilterBar
                  sx={{ py: 3 }}
                  itemsToFilter={groups}
                  onFilter={(groups) => {
                    setFilteredGroups(groups);
                  }}
                  filter={(group, keyword) => group.name.toLowerCase().includes(keyword)}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  aria-label="Dodaj grupę"
                  onClick={() => {
                    setAdding(true);
                  }}>
                  Dodaj grupę
                </Button>
              </Grid>
            </Grid>

            <Paper sx={{ my: 2, px: 2, py: 1 }}>
              {filteredGroups.length > 0 ? (
                <GroupsList groups={filteredGroups} to={routePaths.psychologicalGroupDetails} />
              ) : (
                <EmptyState contrast message="Brak grup psychologiczno-pedagogicznych" />
              )}
            </Paper>
          </>
        ) : (
          <GroupDetailsCard
            group={group}
            onChange={handleGroupChange}
            onSave={handleGroupCreate}
            onCancel={() => setAdding(false)}
            onEdit={() => {}}
            onValidate={() => new GroupDetailsModelValidator().validate(group)}
            isEditing={false}
            isProcessing={false}
            title="Nowa grupa"
          />
        )}
      </ContentWrapper>
    </Box>
  );
};

export default PsychologicalGroupsContainer;
