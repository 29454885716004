import BaseModel from '../BaseModel';

class AuthorModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.fullName = '';
    this.role = '';
    this.photoUrl = '';
  }
}

export class PrivateMessagePagingModel extends BaseModel {
  constructor() {
    super();
    this.threadId = null;
    this.canLoadMore = false;
    this.isLoading = false;
    this.isInitial = false;
    this.messages = [];
    this.activeView = null;
    this.possibleRecipients = [];
  }
}

export class PrivateThreadPagingModel extends BaseModel {
  constructor() {
    super();
    this.unreadThreadsIds = [];
    this.canLoadMore = false;
    this.isLoading = false;
    this.threads = [];
    this.activeView = null;
  }
}

export class PrivateMessageModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.threadId = '';
    this.content = '';
    this.sentAt = '';
    this.updatedAt = '';
    this.deletedAt = '';
    this.senderId = '';
    this.readByParticipants = false;
    this.readByMe = false;
  }
}

export class PrivateThreadModel extends BaseModel {
  constructor() {
    super();
    this.lastMessageContent = '';
    this.lastMessage = '';
    this.threadId = '';
    this.participants = [];
    this.lastRead = {};
    this.readByParticipants = false;
    this.readByMe = true;
    this.typingParticipant = null;
  }

  fromMessage(message) {
    this.lastMessage = new PrivateMessageModel().assign(message);
    this.threadId = message.threadId;
    this.lastRead[message.senderId] = message.sentAt;
    return this;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.lastRead = Object.assign({}, assigned.lastRead);
    assigned.participants = assigned.participants.map((p) => new AuthorModel().assign(p));
    assigned.lastMessage = new PrivateMessageModel().assign(assigned.lastMessage);
  }
}
