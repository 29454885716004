import React from 'react';
import PropTypes from 'prop-types';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import IndividualClassesDetailsContainer from './IndividualClassesDetailsContainer';

const IndividualClassesDetailsPage = ({ location, params }) => {
  return (
    <LoadingRenderWrapper>
      <IndividualClassesDetailsContainer location={location} childId={params.id} />
    </LoadingRenderWrapper>
  );
};

IndividualClassesDetailsPage.alerpropTypes = {
  location: PropTypes.object.isRequired
};

export default IndividualClassesDetailsPage;
