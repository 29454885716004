import DialogForm from '../forms/DialogForm';
import ChildrenPicker from './ChildrenPicker';

export const ChildrenListDialog = ({ open, onClose, onSelect, allPupils, exclude, onChange, isLoading }) => {
  return (
    <DialogForm
      isDialogOpen={open}
      onCancel={onClose}
      onSave={onSelect}
      isProcessing={isLoading}
      noValidate
      fullWidth
      maxWidth="md"
      header="Odpisz dzieci z diety">
      <ChildrenPicker name="childIds" allPupils={allPupils} exclude={exclude} onChange={onChange} />
    </DialogForm>
  );
};
