import BaseModel from '../BaseModel';
import { CalculationStatus } from '../../constants/calculationStatus';
import _ from 'lodash';

/*eslint no-use-before-define: 0*/
export class SettlementModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.number = '';
    this.person = {};
    this.dueType = '';
    this.calculationStatus = '';
    this.calculationDate = '';
    this.approvalDate = '';
    this.approvedBy = '';
    this.maturityDate = '';
    this.cancellationDate = '';
    this.calculatedTotal = undefined;
    this.calculatedTotalUnadjusted = undefined;
    this.calculatedTotalWithDelayInterests = undefined;
    this.totalDelayInterests = undefined;
    this.delayInterestCalculationDate = undefined;
    this.month = '';
    this.failureReason = '';
    this.failureReasonCode = null;
    this.failureDetails = null;
    this.schemeResultItems = [];
    this.payments = [];
    this.latestPayment = {};
    this.paymentRebate = {};
    this.correctedDue = null;
    this.paidAmount = undefined;
    this.reservedBalanceFunds = null;
    this.usedBalanceFunds = null;
    this.hasFundsAvailable = false;
    this.personAvailableFunds = null;
    this.export = null;
    this.name = '';
    this.paymentAmount = null;
    this.isInterestCalculation = false;
  }

  isCorrective() {
    return this.correctedDue != null;
  }

  canBeCorrected() {
    return this.calculationStatus === CalculationStatus.paid.value && !this.isCorrective();
  }

  canBeApproved() {
    return this.calculationStatus === CalculationStatus.draft.value;
  }

  isApproved() {
    return this.calculationStatus === CalculationStatus.approved.value;
  }

  canBeCanceled() {
    return (
      this.calculationStatus === CalculationStatus.approved.value ||
      (this.calculationStatus === CalculationStatus.paid.value && this.getTotalAmount() <= 0)
    );
  }

  canBeDeleted() {
    return (
      this.calculationStatus === CalculationStatus.draft.value ||
      this.calculationStatus === CalculationStatus.failed.value
    );
  }

  getTotalAmount() {
    return this.calculatedTotalWithDelayInterests != null
      ? this.calculatedTotalWithDelayInterests
      : this.calculatedTotal;
  }

  getAmountToPaid() {
    const amountToPaid = this.getTotalAmount();
    const paidAmount =
      this.paidAmount || (this.calculationStatus === CalculationStatus.paid.value ? this.getTotalAmount() : 0);
    return amountToPaid !== undefined && amountToPaid !== null ? amountToPaid - paidAmount : null;
  }

  canBePaidWithBalanceFunds() {
    return (
      this.hasFundsAvailable &&
      (this.calculationStatus === CalculationStatus.approved.value ||
        this.calculationStatus === CalculationStatus.partlyPaid.value ||
        this.calculationStatus === CalculationStatus.draft.value)
    );
  }

  getOutstandingAmount() {
    return _.round(this.getTotalAmount() - (this.paidAmount || 0), 2);
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.schemeResultItems = assigned.schemeResultItems.map((s) => new SchemeResultItem().assign(s));
  }
}

export class SchemeResultItem extends BaseModel {
  constructor() {
    super();
    this.attendanceSummarySnapshot = {};
    this.baseAfterRelief = undefined;
    this.baseAfterReliefAndPenalty = undefined;
    this.baseDue = undefined;
    this.calculatedForMonth = '';
    this.calculationDate = '';
    this.corrections = {};
    this.correctionScheme = null;
    this.correctionsTotal = undefined;
    this.finalDue = undefined;
    this.penaltyAmount = undefined;
    this.grantAmount = undefined;
    this.grantSnapshot = undefined;
    this.reliefSnapshot = {};
    this.schemeSnaphot = {};
    this.adjustmentReason = '';
    this.adjustmentAmount = undefined;
    this.appliedDeduction = undefined;
    this.previousMonthAppliedDeduction = undefined;
    this.baseAfterDeduction = undefined;
  }
}

export class OverdueModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.number = '';
    this.person = {};
    this.dueType = '';
    this.maturityDate = '';
    this.calculatedTotal = undefined;
    this.paidAmount = undefined;
  }
}

export class OverpaymentModel extends BaseModel {
  constructor() {
    super();
    this.personId = '';
    this.firstName = '';
    this.lastName = '';
    this.number = '';
    this.walletType = '';
    this.amount = undefined;
    this.date = '';
  }
}

export class DueListModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.number = '';
    this.person = {};
    this.dueType = '';
    this.calculationStatus = '';
    this.calculationDate = '';
    this.approvalDate = '';
    this.maturityDate = '';
    this.cancellationDate = '';
    this.calculatedTotal = undefined;
    this.calculatedTotalWithDelayInterests = undefined;
    this.month = '';
    this.latestPayment = null;
    this.onlinePaymentSupported = false;
    this.correctedDue = null;
    this.hasFundsAvailable = false;
    this.paidAmount = undefined;
  }

  isCorrective() {
    return this.correctedDue != null;
  }

  canBeCorrected() {
    return this.calculationStatus === CalculationStatus.paid.value && !this.isCorrective();
  }

  canBeApproved() {
    return this.calculationStatus === CalculationStatus.draft.value;
  }

  canBeCanceled() {
    return (
      this.calculationStatus === CalculationStatus.approved.value ||
      (this.calculationStatus === CalculationStatus.paid.value && this.getTotalAmount() <= 0)
    );
  }

  canBeDeleted() {
    return (
      this.calculationStatus === CalculationStatus.draft.value ||
      this.calculationStatus === CalculationStatus.failed.value
    );
  }

  getTotalAmount() {
    return this.calculatedTotalWithDelayInterests != null
      ? this.calculatedTotalWithDelayInterests
      : this.calculatedTotal;
  }

  getAmountToPaid() {
    const amountToPaid = this.getTotalAmount();
    const paidAmount =
      this.paidAmount || (this.calculationStatus === CalculationStatus.paid.value ? this.getTotalAmount() : 0);
    return amountToPaid !== undefined && amountToPaid !== null ? amountToPaid - paidAmount : null;
  }
}

export class ChildDuesModel extends BaseModel {
  constructor() {
    super();
    this.balance = {};
    this.dues = [];
    this.isLoading = false;
  }
}

export class ChildBalanceSummaryModel extends BaseModel {
  constructor() {
    super();
    this.balanceAmount = null;
    this.overdueAmount = null;
    this.interestAmount = null;
    this.isLoading = false;
  }
}

export class StaffDuesModel extends BaseModel {
  constructor() {
    super();
    this.balance = {};
    this.dues = [];
    this.isLoading = false;
  }
}
