import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const MoreGroupsIcon = (props) => (
  <SvgIcon viewBox="0.375 -2.584 24 24" {...props}>
    <path fill="none" d="M.375-2.584h24v24h-24v-24z" />
    <path d="M17.939 9.098c1.141 0 2.055-.921 2.055-2.063s-.914-2.063-2.055-2.063c-1.143 0-2.063.921-2.063 2.063s.92 2.063 2.063 2.063zM12.387 8.675a2.211 2.211 0 002.214-2.221 2.219 2.219 0 10-4.436 0 2.219 2.219 0 002.222 2.221zM12.387 10.155c-1.726 0-5.183.867-5.183 2.592v1.852h10.364v-1.852c.001-1.725-3.456-2.592-5.181-2.592zM17.939 10.473c-.201 0-.427.014-.667.035.798.577 1.355 1.354 1.355 2.371v1.72h4.124v-1.72c0-1.601-3.21-2.406-4.812-2.406zM8.874 7.036c0-1.142-.92-2.063-2.062-2.063-1.143 0-2.057.921-2.057 2.063s.914 2.063 2.057 2.063a2.06 2.06 0 002.062-2.063zM2 12.879v1.72h4.124v-1.72c0-1.018.558-1.794 1.354-2.371a7.885 7.885 0 00-.666-.035C5.21 10.473 2 11.278 2 12.879z" />
  </SvgIcon>
);

export default MoreGroupsIcon;
