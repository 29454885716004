import EmptyState from '../../../common/EmptyState';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Chip,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  Checkbox
} from '@mui/material';
import PersonAvatar from '../../../forms/PersonAvatar';
import { getConstantByValue } from '../../../../utils/getConstantByValue';
import staffMemberRoles from '../../../../constants/staffMemberRoles';
import React from 'react';
import PropTypes from 'prop-types';

const TabContent = ({
  value,
  index,
  filteredRecipients,
  label,
  isSelectedAll,
  onSelect,
  isCheckbox,
  selectedRecipents,
  canSelectAll,
  onSelectAll,
  onDeleteAll
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      role="tabpanel"
      sx={{ overflow: 'hidden' }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}>
      {value === index && (
        <>
          {filteredRecipients.length === 0 ? (
            <EmptyState contrast message={`${label} nie są dostępni na liście`} />
          ) : null}

          <List sx={{ height: '55vh', overflowY: 'scroll' }}>
            {!!canSelectAll && (
              <Box>
                <ListItem
                  button
                  component="li"
                  onClick={isSelectedAll ? onDeleteAll : onSelectAll}
                  sx={{ display: 'flex', alignItems: 'center', cursor: 'default', p: { xs: 0, sm: 1 } }}>
                  <Checkbox checked={isSelectedAll} />

                  <ListItemText primary="Zaznacz wszystkie" />
                </ListItem>
              </Box>
            )}
            {filteredRecipients.map((recipient) => {
              return (
                <Box key={recipient.id}>
                  {isCheckbox ? (
                    <ListItem
                      button
                      component="li"
                      onClick={() => {
                        onSelect(recipient);
                      }}
                      sx={{ display: 'flex', alignItems: 'start', cursor: 'default', p: { xs: 0, sm: 1 } }}>
                      <Checkbox sx={{ mt: 1 }} checked={!!selectedRecipents.includes(recipient.id)} />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box
                          aria-label={`Wybierz rozmowę do ${recipient.firstName} ${recipient.lastName}`}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%'
                          }}>
                          <ListItemAvatar>
                            <PersonAvatar
                              initials
                              alt={recipient.firstName}
                              url={recipient.photoUrl}
                              firstName={recipient.firstName}
                              lastName={recipient.lastName}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${recipient.firstName} ${recipient.lastName}`}
                            secondary={
                              recipient.role
                                ? getConstantByValue(staffMemberRoles, recipient.role).name
                                : 'Opiekun prawny'
                            }
                            secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.color.contrast } }}
                          />
                        </Box>
                        {recipient.role ? null : (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: isMobile ? 'column' : 'row',
                              justifyContent: 'flex-start',
                              flexWrap: 'wrap',
                              width: '100%'
                            }}>
                            {recipient.children.map((c, index) => (
                              <Chip
                                key={index}
                                avatar={
                                  <PersonAvatar
                                    initials
                                    sx={{ m: 1 }}
                                    alt={c.firstName}
                                    url={c.photoUrl}
                                    firstName={c.firstName}
                                    lastName={c.lastName}
                                  />
                                }
                                sx={{
                                  '& .MuiChip-avatar': {
                                    color: (theme) => theme.palette.color.primary
                                  },
                                  height: 'auto',
                                  fontSize: 12,
                                  overflow: 'hidden',
                                  borderRadius: 8,
                                  mb: 0.25
                                }}
                                label={
                                  <Box>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                      <Box sx={{ mr: 1 }}>{c.firstName}</Box> <Box>{c.lastName}</Box>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap'
                                      }}>
                                      <Box>{c.groupName}</Box>
                                    </Box>
                                  </Box>
                                }
                              />
                            ))}
                          </Box>
                        )}
                      </Box>
                    </ListItem>
                  ) : (
                    <ListItem
                      button
                      component="li"
                      onClick={() => onSelect(recipient)}
                      sx={{ display: 'flex', flexDirection: 'column' }}>
                      <>
                        <Box
                          aria-label={`Wybierz rozmowę do ${recipient.firstName} ${recipient.lastName}`}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%'
                          }}>
                          <ListItemAvatar>
                            <PersonAvatar
                              initials
                              alt={recipient.firstName}
                              url={recipient.photoUrl}
                              firstName={recipient.firstName}
                              lastName={recipient.lastName}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${recipient.firstName} ${recipient.lastName}`}
                            secondary={
                              recipient.role
                                ? getConstantByValue(staffMemberRoles, recipient.role).name
                                : 'Opiekun prawny'
                            }
                            secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.color.contrast } }}
                          />
                        </Box>
                        {recipient.role ? null : (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: isMobile ? 'column' : 'row',
                              justifyContent: 'flex-start',
                              flexWrap: 'wrap',
                              width: '100%'
                            }}>
                            {recipient.children.map((c) => (
                              <Typography sx={{ pl: isMobile ? 0 : 1, pb: 1 }} key={c.id}>
                                <Chip
                                  avatar={
                                    <PersonAvatar
                                      initials
                                      sx={{ m: 1 }}
                                      alt={c.firstName}
                                      url={c.photoUrl}
                                      firstName={c.firstName}
                                      lastName={c.lastName}
                                    />
                                  }
                                  sx={{
                                    '& .MuiChip-label': {
                                      color: (theme) => theme.palette.color.contrast
                                    },
                                    '& .MuiChip-avatar': {
                                      color: (theme) => theme.palette.color.primary
                                    },
                                    height: 'auto',
                                    fontSize: 12,
                                    overflow: 'hidden',
                                    borderRadius: 8
                                  }}
                                  label={
                                    <Box>
                                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <Box sx={{ mr: 1 }}>{c.firstName}</Box> <Box>{c.lastName}</Box>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexWrap: 'wrap'
                                        }}>
                                        <Box>{c.groupName}</Box>
                                      </Box>
                                    </Box>
                                  }
                                />
                              </Typography>
                            ))}
                          </Box>
                        )}
                      </>
                    </ListItem>
                  )}
                </Box>
              );
            })}
          </List>
        </>
      )}
    </Box>
  );
};

TabContent.propTypes = {
  filteredRecipients: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  isCheckbox: PropTypes.bool
};

export default TabContent;
