import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import * as guardianDuesActions from '../../actions/guardianDuesActions';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import DoneIcon from '@mui/icons-material/Done';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GuardianDuesToPay from './GuardianDuesToPay';
import GuardianPaidDuesList from './GuardianPaidDuesList';
import OnlinePaymentsSummary from './OnlinePaymentsSummary';
import { CalculationStatus } from '../../constants/calculationStatus';
import { PaymentStatus } from '../../constants/paymentStatus';
import { PaymentMethods } from '../../constants/paymentMethods';
import { routePaths, fromTemplate } from '../../routePaths';
import moment from 'moment';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import _ from 'lodash';
import SelectField from '../common/SelectField';
import { Box, MenuItem, Paper, Typography } from '@mui/material';
import ManualLink from '../ManualLink';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import EmptyState from '../common/EmptyState';

class GuardianDuesPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedItemsIds: []
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handlePay = this.handlePay.bind(this);
    this.handleOpenDetails = this.handleOpenDetails.bind(this);
    this.onDateChanged = this.onDateChanged.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
  }

  onDateChanged(year) {
    browserHistory.push(fromTemplate(routePaths.guardianDues, [year]));
  }

  handleSelect(selection) {
    const { selectedItemsIds } = this.state;
    if (selection) {
      if (selectedItemsIds.some((i) => i === selection.id)) {
        _.remove(selectedItemsIds, (i) => i === selection.id);
      } else {
        selectedItemsIds.push(selection.id);
      }
    }

    this.setState({ selectedItemsIds });
  }

  handlePay() {
    this.props.actions.pay(this.state.selectedItemsIds);
  }

  handleOpenDetails(due) {
    browserHistory.push(fromTemplate(routePaths.guardianDueDetails, due.id));
  }

  handlePrint(due) {
    this.props.actions.print(due.id, due.number);
  }

  renderDuesToPay(duesToPay) {
    return (
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PriorityHighIcon sx={{ mr: 1, color: (theme) => theme.palette.color.primary }} />
          <Typography variant="h6" sx={{ my: 2, color: (theme) => theme.palette.color.primary }}>
            Nieopłacone
          </Typography>
        </Box>
        <Paper sx={{ p: 2, my: 2 }}>
          <GuardianDuesToPay
            duesToPay={duesToPay}
            selectedDuesIds={this.state.selectedItemsIds}
            onSelect={this.handleSelect}
            onChoose={this.handleOpenDetails}
            onlinePaymentEnabled={this.props.onlinePaymentEnabled}
          />
        </Paper>
        {!this.props.onlinePaymentEnabled ? null : (
          <OnlinePaymentsSummary
            duesToPay={duesToPay}
            selectedDuesIds={this.state.selectedItemsIds}
            onPay={this.handlePay}
            includesCommission={this.props.onlinePaymentsIncludeCommission}
          />
        )}
      </Box>
    );
  }

  renderPendingDues(pendingDues) {
    return (
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AccessTimeIcon sx={{ mr: 1, color: (theme) => theme.palette.color.primary }} />
          <Typography variant="h6" sx={{ my: 2, color: (theme) => theme.palette.color.primary }}>
            Przetwarzane
          </Typography>
        </Box>
        <Paper sx={{ p: 2, my: 2 }}>
          <GuardianPaidDuesList dues={pendingDues} onChoose={this.handleOpenDetails} onPrint={this.handlePrint} />
        </Paper>
      </Box>
    );
  }

  renderPaidDues(paidDues) {
    return (
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DoneIcon sx={{ mr: 1, color: (theme) => theme.palette.color.primary }} />
          <Typography variant="h6" sx={{ my: 2, color: (theme) => theme.palette.color.primary }}>
            Uregulowane
          </Typography>
        </Box>
        <Paper sx={{ p: 2, my: 2 }}>
          <GuardianPaidDuesList dues={paidDues} onChoose={this.handleOpenDetails} onPrint={this.handlePrint} />
        </Paper>
      </Box>
    );
  }

  render() {
    const dues = _.orderBy(this.props.guardianDues, ['approvalDate'], ['desc']);

    const duesToPay = dues.filter(
      (d) =>
        (d.calculationStatus === CalculationStatus.approved.value ||
          d.calculationStatus === CalculationStatus.partlyPaid.value) &&
        d.calculatedTotal > 0 &&
        (!d.latestPayment ||
          d.latestPayment.method !== PaymentMethods.online.value ||
          d.latestPayment.status === PaymentStatus.failed.value ||
          d.latestPayment.status === PaymentStatus.canceled.value)
    );

    const pendingDues = dues.filter(
      (d) =>
        d.calculationStatus === CalculationStatus.approved.value &&
        d.latestPayment &&
        d.latestPayment.method === PaymentMethods.online.value &&
        (d.latestPayment.status === PaymentStatus.defined.value ||
          d.latestPayment.status === PaymentStatus.pending.value)
    );

    const paidDues = dues.filter((d) => d.calculationStatus === CalculationStatus.paid.value);
    return (
      <LoadingRenderWrapper>
        <Box>
          <Box flexDirection="row" display="flex" alignItems="center">
            <PageHeaderText title="Opłaty" titleIcon={<AttachMoneyOutlinedIcon />} />
            <ManualLink index="5" />
          </Box>
          <ContentWrapper>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SelectField
                id="year"
                value={this.props.year}
                onChange={(e) => this.onDateChanged(e.target.value)}
                sx={{ width: 100 }}>
                {this.props.years.map((year) => {
                  return (
                    <MenuItem key={year} value={year.toString()}>
                      {year}
                    </MenuItem>
                  );
                })}
              </SelectField>
            </Box>
            {duesToPay.length == 0 && pendingDues.length == 0 && paidDues.length == 0 ? (
              <Box sx={{ py: 10 }}>
                <EmptyState message="Dla wybranego okresu nie wygenerowano żadnych opłat" />
              </Box>
            ) : null}
            {duesToPay.length > 0 ? this.renderDuesToPay(duesToPay) : null}
            {pendingDues.length > 0 ? this.renderPendingDues(pendingDues) : null}
            {paidDues.length > 0 ? this.renderPaidDues(paidDues) : null}
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

GuardianDuesPage.propTypes = {
  guardianDues: PropTypes.array,
  actions: PropTypes.object.isRequired,
  years: PropTypes.array.isRequired,
  year: PropTypes.string.isRequired,
  onlinePaymentEnabled: PropTypes.bool.isRequired,
  onlinePaymentsIncludeCommission: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    guardianDues: state.guardianDues,
    years: _.range(2015, moment().year() + 2),
    year: ownProps.params.year,
    onlinePaymentEnabled: state.configuration.unit.onlinePaymentsEnabled,
    onlinePaymentsIncludeCommission: state.configuration.unit.onlinePaymentsIncludeCommission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(guardianDuesActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuardianDuesPage);
