import BaseModel from '../../BaseModel';
import moment from 'moment';
import { TimeModel } from '../../TimeModel';

export class AuthorModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.userFullName = '';
    this.userName = '';
  }
}

export class DigitalDiaryEventModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.title = '';
    this.content = '';
    this.group = '';
    this.author = new AuthorModel();
    this.availableToAll = false;
    this.eventDate = '';
    this.programPoints = '';
    this.learningMaterials = '';
    this.eventStartTime = new TimeModel().parse(new Date());
    this.eventEndTime = new TimeModel().parse(new Date());
    this.eventType = '';
    this.publishedAt = Date.now();
    this.replacement = false;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.author = new AuthorModel().assign(assigned.author);
    assigned.eventDate = assigned.eventDate ? moment(assigned.eventDate).format('YYYY-MM-DD') : '';
    assigned.eventStartTime = new TimeModel().assign(assigned.eventStartTime);
    assigned.eventEndTime = new TimeModel().assign(assigned.eventEndTime);
  }
}

export class DigitalDiaryEventsModel extends BaseModel {
  constructor(events, totalCount) {
    super();
    this.events = events.map((event) => new DigitalDiaryEventModel().assign(event));
    this.limit = 10;
    this.totalCount = totalCount;
    this.pageCount = Math.abs(totalCount / this.limit);
    this.hasNextPage = this.events.length < totalCount;
    this.offset = this.events.length;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.events = assigned.events.map((event) => new DigitalDiaryEventModel().assign(event));
  }
}
