import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { MenuItem, ListItem, ListItemText, Typography, Box, Button, Grid } from '@mui/material';
import DotsMenu from '../../common/menu/DotsMenu';
import PersonAvatar from '../../forms/PersonAvatar';
import { avatarColorPaletteArray } from '../../../constants/avatarColorPalette';
import { browserHistory } from 'react-router';
import { fromTemplate, routePaths } from '../../../routePaths';
import { canShowAbsence } from '../../dashboard/staffMember/UnitAttendanceSummary';

const AttendanceListItem = ({
  journalEntry,
  onReportDropOff,
  onReportPickUp,
  onReportAbsence,
  onRevertDropOff,
  onRevertPickUp,
  onRevertAbsence,
  noHoursAttendancesEnabled,
  user
}) => {
  const getUnregisteredActions = (entry, onDropOff, onAbsence) => {
    if (!entry.droppedOffAt && !entry.absenceFrom && !entry.absenceTo) {
      return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} key="niezarejestrowane-akcje">
          <Button
            variant="text"
            aria-label={noHoursAttendancesEnabled ? 'obecność' : 'wejście'}
            key="1"
            onClick={() => onDropOff(entry)}
            sx={{
              textTransform: 'none',
              width: { xs: 100, md: 140 },
              color: (theme) => theme.palette.color.color7,
              fontWeight: (theme) => theme.typography.fontWeightBold,
              fontSize: 16
            }}>
            {noHoursAttendancesEnabled ? 'obecność' : 'wejście'}
          </Button>
          <Button
            variant="text"
            aria-label="nieobecność"
            key="2"
            onClick={() => onAbsence(entry)}
            sx={{
              textTransform: 'none',
              width: { xs: 100, md: 140 },
              color: (theme) => theme.palette.color.color25,
              fontWeight: (theme) => theme.typography.fontWeightBold,
              fontSize: 16
            }}>
            nieobecność
          </Button>
        </Box>
      );
    }
    return null;
  };

  const getAttendanceText = (entry, onPickUp) => {
    if (entry.droppedOffAt && !entry.pickedUpAt) {
      return [
        <Box
          key={entry.child.id}
          sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
          <Typography
            sx={{
              textTransform: 'none',
              textAlign: 'center',
              width: { xs: 100, md: 140 },
              display: 'inline-block',
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}>
            {moment(entry.droppedOffAt).format('HH:mm')}
          </Typography>
          <Button
            variant="text"
            aria-label="odbiór"
            onClick={() => onPickUp(entry)}
            sx={{
              textTransform: 'none',
              width: { xs: 100, md: 140 },
              color: (theme) => theme.palette.color.color7,
              fontWeight: (theme) => theme.typography.fontWeightBold,
              fontSize: 16
            }}>
            odbiór
          </Button>
        </Box>
      ];
    }
    return null;
  };

  const getAttendanceActions = (entry, onPickUp, onRevertDropped) => {
    if (entry.droppedOffAt && !entry.pickedUpAt) {
      return (
        <DotsMenu key="dots1">
          {() => <MenuItem onClick={() => onRevertDropped(entry)}>Wycofaj wejście</MenuItem>}
        </DotsMenu>
      );
    }
    return null;
  };

  const getAbsenceText = (entry) => {
    if (entry.absenceFrom && entry.absenceTo) {
      return (
        <Box key="nieobecności">
          <Typography
            component="span"
            sx={{
              textAlign: 'center',
              width: { xs: 100, md: 140 },
              display: 'inline-block',
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}>
            nieobecność
          </Typography>
          <Typography
            component="span"
            sx={{
              textAlign: 'center',
              width: { xs: 100, md: 140 },
              display: 'inline-block',
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}>{`${moment(entry.absenceFrom).format('DD.MM')} - ${moment(entry.absenceTo).format(
            'DD.MM'
          )}`}</Typography>
        </Box>
      );
    }
    return null;
  };

  const getPickedText = (entry) => {
    if (entry.droppedOffAt && entry.pickedUpAt) {
      return (
        <Box key="godziny-odbioru">
          <Typography
            component="span"
            sx={{
              width: { xs: 100, md: 140 },
              textAlign: 'center',
              display: 'inline-block',
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}>
            obecność
          </Typography>
          <Typography
            component="span"
            sx={{
              width: { xs: 100, md: 140 },
              textAlign: 'center',
              display: 'inline-block',
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}>{`${moment(entry.droppedOffAt).format('HH:mm')} - ${moment(entry.pickedUpAt).format(
            'HH:mm'
          )}`}</Typography>
        </Box>
      );
    }
    return null;
  };

  const getAbsenceActions = (entry, onRevertPicked, onRevertAbsent, onRevertAttendance) => {
    if (entry.droppedOffAt && entry.pickedUpAt) {
      return (
        <DotsMenu key="dots2">
          {() => (
            <MenuItem onClick={() => (noHoursAttendancesEnabled ? onRevertAttendance(entry) : onRevertPicked(entry))}>
              {noHoursAttendancesEnabled ? 'Wycofaj obecność' : 'Wycofaj wyjście'}
            </MenuItem>
          )}
        </DotsMenu>
      );
    }
    if (entry.absenceFrom && entry.absenceTo) {
      return (
        <DotsMenu key="dots3">
          {() => <MenuItem onClick={() => onRevertAbsent(entry)}>Wycofaj nieobecność</MenuItem>}
        </DotsMenu>
      );
    }
    return null;
  };

  const renderText = () => [
    getUnregisteredActions(journalEntry, onReportDropOff, onReportAbsence),
    getAbsenceText(journalEntry),
    getAttendanceText(journalEntry, onReportPickUp),
    getPickedText(journalEntry)
  ];

  const renderActions = () => [
    getAttendanceActions(journalEntry, onReportPickUp, onRevertDropOff),
    getAbsenceActions(journalEntry, onRevertPickUp, onRevertAbsence, onRevertDropOff)
  ];

  return (
    <ListItem
      key={journalEntry.childId || journalEntry.photoUrl}
      sx={{
        px: { md: 2, xs: 0.5 },
        minHeight: 80,
        '&:hover': {
          background: (theme) => theme.palette.background.hover
        }
      }}>
      <Grid container>
        <Grid item xs={11}>
          <ListItemText
            primary={
              <Grid container>
                <Grid
                  item
                  onClick={() => {
                    if (canShowAbsence(user))
                      browserHistory.push(fromTemplate(`${routePaths.children}` + `/${journalEntry.childId}`));
                  }}
                  xs={8}
                  md={6}
                  sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    pl: { xs: 1, md: 2 }
                  }}>
                  <PersonAvatar
                    initials
                    palette={avatarColorPaletteArray}
                    alt={journalEntry.firstName}
                    url={journalEntry.photoUrl}
                    firstName={journalEntry.firstName}
                    lastName={journalEntry.lastName}
                  />
                  <Typography
                    sx={{
                      cursor: canShowAbsence(user) && 'pointer',
                      '&:hover': {
                        textDecoration: canShowAbsence(user) && 'underline'
                      },
                      fontWeight: (theme) => theme.typography.fontWeightBold,
                      pl: { xs: 1, md: 2 },
                      wordBreak: 'break-word'
                    }}>
                    {journalEntry.lastName} {journalEntry.firstName}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={6}
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'center', md: 'flex-end' },
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}>
                  {renderText()}
                </Grid>
              </Grid>
            }
          />
        </Grid>
        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {renderActions()}
        </Grid>
      </Grid>
    </ListItem>
  );
};

AttendanceListItem.propTypes = {
  journalEntry: PropTypes.object.isRequired,
  onReportDropOff: PropTypes.func.isRequired,
  onReportPickUp: PropTypes.func.isRequired,
  onReportAbsence: PropTypes.func.isRequired,
  onRevertDropOff: PropTypes.func.isRequired,
  onRevertPickUp: PropTypes.func.isRequired,
  onRevertAbsence: PropTypes.func.isRequired,
  noHoursAttendancesEnabled: PropTypes.bool
};

export default AttendanceListItem;
