import pinApi from '../api/GuardianPinServiceApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';

export function loadGuardianPinServiceConfiguration() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_GUARDIAN_PIN_SERVICE });
    return pinApi
      .getPinServiceConfiguration()
      .then((configuration) => dispatch({ type: types.LOAD_GUARDIAN_PIN_SERVICE_SUCCESS, configuration }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać konfiguracji dla usługi logowania pinem.'));
        return logger.error(error);
      });
  };
}

export function loadGuardiansWithoutPins() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_GUARDIANS_WITHOUT_PINS });
    return pinApi
      .getGuardiansWithoutPin()
      .then((guardians) => dispatch({ type: types.LOAD_GUARDIANS_WITHOUT_PINS_SUCCESS, guardians }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać listy opiekunów bez przypisanych pinów.'));
        return logger.error(error);
      });
  };
}

export function generatePinsToGuardiansWithout() {
  return (dispatch) => {
    dispatch({ type: types.GENERATE_PINS_TO_GUARDIANS_WITHOUT });
    return pinApi
      .generateInitialPins()
      .then(() => dispatch({ type: types.GENERATE_PINS_TO_GUARDIANS_WITHOUT_SUCCESS }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            'Nie udało się wygenerować pinów dla opiekunów którzy jeszcze ich nie posiadają.'
          )
        );
        return logger.error(error);
      });
  };
}

export function updateGuardianPinServiceConfiguration(enabled) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_GUARDIAN_PIN_SERVICE });
    return pinApi
      .updatePinServiceConfiguration(enabled)
      .then(() => dispatch({ type: types.UPDATE_GUARDIAN_PIN_SERVICE_SUCCESS, enabled }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się zaktualizować konfiguracji dla usługi logowania pinem.'));
        return logger.error(error);
      });
  };
}

export function cancelAddPinDevice() {
  return (dispatch) => dispatch({ type: types.ADD_PIN_DEVICE_CANCEL });
}

export function addPinDevice(login, password) {
  return (dispatch) => {
    dispatch({ type: types.ADD_PIN_DEVICE });
    return pinApi
      .addPinDevice(login, password)
      .then((id) => {
        dispatch(notificationActions.showSuccess(`Udało się dodać urządzenia '${login}'`));
        dispatch({ type: types.ADD_PIN_DEVICE_SUCCESS, id, login });
      })
      .catch((error) => {
        if (error.status === 412) {
          dispatch({ type: types.ADD_PIN_DEVICE_FAILURE, errors: error.response.body });
          return error.response.body;
        }
        dispatch(notificationActions.showError(`Nie udało się dodać urządzenia '${login}'.`));
        return logger.error(error);
      });
  };
}

export function removePinDevice(id) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_PIN_DEVICE });
    return pinApi
      .removePinDevice(id)
      .then(() => dispatch({ type: types.REMOVE_PIN_DEVICE_SUCCESS, id }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało usunąć urządzenia '${get.pinDeviceLogin(getState, id)}'.`));
        return logger.error(error);
      });
  };
}

export function cancelResetPinDevicePassword() {
  return (dispatch) => dispatch({ type: types.RESET_PIN_DEVICE_PASSWORD_CANCEL });
}

export function resetPinDevicePassword(id, password) {
  return (dispatch, getState) => {
    dispatch({ type: types.RESET_PIN_DEVICE_PASSWORD });
    return pinApi
      .resetPinDevicePassword(id, password)
      .then(() => dispatch({ type: types.RESET_PIN_DEVICE_PASSWORD_SUCCESS }))
      .catch((error) => {
        if (error.status === 412) {
          dispatch({ type: types.RESET_PIN_DEVICE_PASSWORD_FAILURE, errors: error.response.body });
          return error.response.body;
        }
        dispatch(
          notificationActions.showError(
            `Nie udało zmienić hasła logowania dla urządzenia '${get.pinDeviceLogin(getState, id)}'.`
          )
        );
        return logger.error(error);
      });
  };
}
