import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function contrastColorReducer(state = initialState.theme, action) {
  switch (action.type) {
    case types.CHANGE_THEME: {
      return action.payload;
    }
    default:
      return state;
  }
}
