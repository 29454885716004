import api from '../api/psychologicalApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function loadPsychologicalHelpChildrenList(id) {
  return (dispatch) => {
    return api
      .loadPsychologicalHelpList(id)
      .then((payload) => dispatch({ type: types.LOAD_PSYCHOLOGICAL_HELP_LIST, payload }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać listy dzieci programu pomocy psychologiczno-pedagogicznej`
          )
        );
        return logger.error(error);
      });
  };
}

export function loadPsychologicalHelpDetails(helpId) {
  return (dispatch) => {
    return api
      .loadPsychologiclDetails(helpId)
      .then((payload) => dispatch({ type: types.LOAD_PSYCHOLOGICAL_DETAILS, payload }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(`Nie udało się pobrać szczegółów programu pomocy psychologiczno-pedagogicznej`)
        );
        return logger.error(error);
      });
  };
}

export function startAddingPsychologicalHelp() {
  return (dispatch) => {
    dispatch({ type: types.ADD_PSYCHOLOGICAL_HELP_START });
  };
}

export function cancelAddingPsychologicalHelp() {
  return (dispatch) => {
    dispatch({ type: types.ADD_PSYCHOLOGICAL_HELP_CANCEL });
  };
}

export function addPscyhologicalHelp(child) {
  return (dispatch) => {
    dispatch({ type: types.ADD_PSYCHOLOGICAL_HELP_SUBMIT });
    return api
      .addPsychologicalHelp(child)
      .then((id) => {
        dispatch(notificationActions.showSuccess(`Dodano dziecko do programu pomocy psychologiczno-pedagogicznej`));
        dispatch({ type: types.ADD_PSYCHOLOGICAL_HELP, child, id });
      })
      .catch((error) => {
        dispatch({ type: types.ADD_PSYCHOLOGICAL_HELP_FAILURE });
        dispatch(
          notificationActions.showError(`Nie udało się dodać dziecka do programu pomocy psychologiczno-pedagogicznej`)
        );
        return logger.error(error);
      });
  };
}

export function editPscyhologicalHelp(data) {
  return (dispatch) => {
    dispatch({ type: types.ADD_PSYCHOLOGICAL_HELP_SUBMIT });
    return api
      .editPsychologicalHelp(data)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Edytowano program pomocy psychologiczno-pedagogicznej`));
        dispatch({ type: types.EDIT_PSYCHOLOGICAL_HELP, data });
      })
      .catch((error) => {
        dispatch({ type: types.ADD_PSYCHOLOGICAL_HELP_FAILURE });
        dispatch(notificationActions.showError(`Nie udało się edytować programu pomocy psychologiczno-pedagogicznej`));
        return logger.error(error);
      });
  };
}

export function archivePsychologicalHelp(helpId) {
  return (dispatch) => {
    return api
      .archivePsychologicalHelp(helpId)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zarchiwizowano pomoc psychologiczno-pedagogiczną`));
        dispatch({ type: types.ARCHIVE_PSYCHOLOGICAL_HELP, helpId });
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zarchiwizować pomocy psychologiczno-pedagogicznej`));
        return logger.error(error);
      });
  };
}

export function newNotificationStart() {
  return { type: types.CREATE_NOTIFY_UI_START };
}

export function newNotificationCancel() {
  return { type: types.CREATE_NOTIFY_UI_CANCEL };
}

export function newNotification(data, id) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_NOTIFY_UI_SUBMIT });

    return api
      .addNotification(data, id)
      .then((res) => {
        dispatch({ type: types.ADD_PSYCHOLOGICAL_NOTIFICATION, data, res });
        dispatch(notificationActions.showSuccess(`Dodano zawiadomienie`));
      })
      .catch((error) => {
        dispatch({ type: types.CREATE_NOTIFY_UI_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError(`Nie udało się dodać zawiadomienia`));
        return logger.error(error);
      });
  };
}

export function editNotification(data, id, helpId) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_NOTIFY_UI_SUBMIT });

    return api
      .editNotification(data, id, helpId)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Edytowano pismo zawiadamiające`));
        dispatch({ type: types.EDIT_PSYCHOLOGICAL_NOTIFICATION, data, id });
      })
      .catch((error) => {
        dispatch({ type: types.CREATE_NOTIFY_UI_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError(`Nie udało się zapisać edytowanego pisma zawiadamiającego`));
        return logger.error(error);
      });
  };
}

export function archiveNotification(helpId, id) {
  return (dispatch) => {
    return api
      .archiveNotification(helpId, id)
      .then(() => {
        dispatch({ type: types.ARCHIVE_PSYCHOLOGICAL_NOFITICATION, id });
        dispatch(notificationActions.showSuccess(`Zarchiwizowano powiadomienie`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zarchiwizować powiadomienia`));
        return logger.error(error);
      });
  };
}

export function newWopfuStart() {
  return { type: types.CREATE_WOPFU_UI_START };
}

export function newWopfuCancel() {
  return { type: types.CREATE_WOPFU_UI_CANCEL };
}

export function addWopfu(data, helpId) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_WOPFU_UI_SUBMIT });
    return api
      .addWopfu(data, helpId)
      .then((res) => {
        dispatch({ type: types.ADD_WOPFU, data, res });
        dispatch(
          notificationActions.showSuccess(`Dodano wpis wielospecjalistycznej oceny poziomu funkcjonowania ucznia.`)
        );
      })
      .catch((error) => {
        dispatch({ type: types.CREATE_WOPFU_UI_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się dodać wpisu wielospecjalistycznej oceny poziomu funkcjonowania ucznia`
          )
        );
        return logger.error(error);
      });
  };
}

export function editWopfu(data, wopfuId, helpId) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_WOPFU_UI_SUBMIT });
    return api
      .editWopfu(data, wopfuId, helpId)
      .then(() => {
        dispatch({ type: types.EDIT_WOPFU, data, wopfuId });
        dispatch(
          notificationActions.showSuccess(`Edytowano wpis wielospecjalistycznej oceny poziomu funkcjonowania ucznia.`)
        );
      })
      .catch((error) => {
        dispatch({ type: types.CREATE_WOPFU_UI_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się zapisać edytowanego wpisu wielospecjalistycznej oceny poziomu funkcjonowania ucznia`
          )
        );
        return logger.error(error);
      });
  };
}

export function archiveWopfu(wopfuId, helpId) {
  return (dispatch) => {
    return api
      .archiveWopfu(wopfuId, helpId)
      .then(() => {
        dispatch({ type: types.ARCHIVE_WOPFU, wopfuId });
        dispatch(notificationActions.showSuccess(`Zarchiwizowano wpis WOPFU`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zarchiwizować wpisu WOPFU`));
        return logger.error(error);
      });
  };
}

export function newIpetStart() {
  return { type: types.CREATE_IPET_UI_START };
}

export function newIpetCancel() {
  return { type: types.CREATE_IPET_UI_CANCEL };
}

export function addIpet(data, helpId) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_IPET_UI_SUBMIT });
    return api
      .addIpet(data, helpId)
      .then((res) => {
        dispatch({ type: types.ADD_IPET, data, res });
        dispatch(notificationActions.showSuccess(`Dodano nowy wpis IPET`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się dodać nowego wpisu IPET`));
        return logger.error(error);
      });
  };
}

export function editIpet(data, ipetId, helpId) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_IPET_UI_SUBMIT });
    return api
      .editIpet(data, ipetId, helpId)
      .then(() => {
        dispatch({ type: types.EDIT_IPET, data, ipetId });
        dispatch(notificationActions.showSuccess(`Edytowano wpis IPET`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się edytować wpisu IPET`));
        return logger.error(error);
      });
  };
}

export function archiveIpet(ipetId, helpId) {
  return (dispatch) => {
    return api
      .archiveIpet(ipetId, helpId)
      .then(() => {
        dispatch({ type: types.ARCHIVE_IPET, ipetId });
        dispatch(notificationActions.showSuccess(`Zarchiwizowano wpis IPET`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zarchiwizować wpisu IPET`));
        return logger.error(error);
      });
  };
}

export function loadPsychologicalSchemesAsync() {
  return (dispatch) => {
    return api
      .loadSchemes()
      .then((data) => {
        dispatch({ type: types.LOAD_PSYCHOLOGICAL_SCHEMES_LIST, data });
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać schematów`));
        return logger.error(error);
      });
  };
}

export function openPsychologicalSchemesDialog() {
  return (dispatch) => {
    dispatch({ type: types.PSYCHOLOGICAL_SCHEMES_START });
  };
}

export function cancelPsychologicalSchemesDialog() {
  return (dispatch) => {
    dispatch({ type: types.PSYCHOLOGICAL_SCHEMES_CANCEL });
  };
}

export function addPsychologicalSchemeAsync(data) {
  return (dispatch) => {
    dispatch({ type: types.ADD_PSYCHOLOGICAL_SCHEMES_SUBMIT });
    return api
      .addScheme(data)
      .then(() => {
        dispatch({ type: types.ADD_PSYCHOLOGICAL_SCHEMES, data });
        dispatch(notificationActions.showSuccess(`Dodano schemat`));
      })
      .catch((error) => {
        dispatch({ type: types.ADD_PSYCHOLOGICAL_SCHEMES_FAILURE });
        dispatch(notificationActions.showError(`Nie udało się dodać schematu`));
        return logger.error(error);
      });
  };
}

//TODO
export function editPsychologicalSchemeAsync(data) {
  return (dispatch) => {
    dispatch({ type: types.EDIT_PSYCHOLOGICAL_SCHEMES_SUBMIT });
    dispatch(notificationActions.showSuccess(`Edytowano schemat`));
    dispatch({ type: types.EDIT_PSYCHOLOGICAL_SCHEMES, data });
  };
}

//TODO
export function deletePsychologicalSchemeAsync(data) {
  return (dispatch) => {
    dispatch(notificationActions.showSuccess(`Usunięto schemat`));
    dispatch({ type: types.DELETE_PSYCHOLOGICAL_SCHEMES, data });
  };
}
