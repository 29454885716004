import { FormControl, Input, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

export const SelectWrapper = ({ label, options, variant = 'standard', ...rest }) => {
  SelectWrapper.propTypes = {
    label: PropTypes.string.isRequired,
    variant: PropTypes.string,
    options: PropTypes.array
  };
  return (
    <FormControl variant="standard">
      <InputLabel
        sx={{
          color: (theme) => theme.palette.color.primary,
          '&.Mui-focused': {
            color: (theme) => theme.palette.color.primary
          }
        }}
        htmlFor={label}>
        {label}
      </InputLabel>
      <Select disableUnderline variant={variant} {...rest} inputProps={{ id: label }}>
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={typeof option.value === 'undefined' ? option : option.value}>
              {option.label || option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const SearchBarWrapper = ({ sx, searchText, placeholder = 'plaheholder', onChange, onClear, ...rest }) => {
  SearchBarWrapper.propTypes = {
    placeholder: PropTypes.string,
    onClear: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
    sx: PropTypes.object,
    searchText: PropTypes.string
  };
  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel
        htmlFor="searchbar"
        sx={{
          border: 0,
          clip: 'rect(0 0 0 0)',
          height: '1px',
          margin: '-1px',
          overflow: 'hidden',
          p: 0,
          position: 'absolute',
          width: '1px'
        }}>
        Wyszukaj
      </InputLabel>
      <Input
        inputProps={{ id: 'searchbar' }}
        onChange={onChange}
        value={searchText}
        sx={{
          color: (theme) => theme.palette.color.primary,
          borderBottom: (theme) => `1px solid ${theme.palette.color.primary}`,
          ...sx
        }}
        disableUnderline
        fullWidth
        placeholder={placeholder}
        id="input-with-icon-adornment"
        startAdornment={
          <InputAdornment sx={{ color: 'white', opacity: 0.5 }} position="start">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment sx={{ color: 'white', opacity: 0.5 }} position="end">
            <CloseIcon sx={{ cursor: 'pointer' }} />
          </InputAdornment>
        }
        {...rest}
      />
    </FormControl>
  );
};
