import React from 'react';
import UserPasswordTemplate, {
  validate as validateTemplate,
  InitialValues as InitialValuesTemplate,
  VALIDATION_SCHEMA as VALIDATION_SCHEMA_TEMPLATE
} from '../templates/UserPasswordTemplate';
import * as Yup from 'yup';

const ResetPasswordForm = () => {
  return <UserPasswordTemplate />;
};

ResetPasswordForm.propTypes = {};

export class InitialValues extends InitialValuesTemplate {}

export const VALIDATION_SCHEMA = Yup.object({
  ...VALIDATION_SCHEMA_TEMPLATE
});

export const validate = ({ password }) => {
  return validateTemplate({ password });
};

export default ResetPasswordForm;
