import React, { useState } from 'react';
import { Checkbox, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import FilterBar from '../../common/FilterBar';
import _ from 'lodash';
import EmptyState from '../../common/EmptyState';
import PropTypes from 'prop-types';

const styles = {
  useTableStyles: {
    minWidth: 650
  },
  cellHeaderStyles: {
    px: 2,
    width: 100,
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: 13
  },
  useCellBodyStyles: {
    width: 100,
    fontSize: 13,
    padding: '0px 12px'
  }
};

const GroupTeachersEditListv2 = ({ allTeachers, associatedTeachersIds, onChange }) => {
  const [selectableTeachers] = useState(allTeachers.filter((x) => !associatedTeachersIds.includes(x.id)));
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [filteredSelectableTeachers, setFilteredSelectableTeachers] = useState(_.cloneDeep(selectableTeachers));

  const handleCheck = (teacher) => {
    setSelectedTeacher(teacher);
    onChange({ target: { name: 'selectedTeacher', value: teacher } });
  };

  return (
    <>
      <FilterBar
        contrast
        itemsToFilter={selectableTeachers}
        onFilter={(filteredList) => setFilteredSelectableTeachers(_.cloneDeep(filteredList))}
        filter={(teacher, keyword) =>
          teacher.lastName.toLowerCase().includes(keyword) || teacher.firstName.toLowerCase().includes(keyword)
        }
      />
      {selectableTeachers.length === 0 && filteredSelectableTeachers.length === 0 ? (
        <EmptyState contrast message="Brak nauczycieli do przypisania" />
      ) : (
        <>
          {selectableTeachers.length !== 0 && filteredSelectableTeachers.length === 0 ? (
            <EmptyState contrast message="Brak wyników wyszukiwania" />
          ) : (
            <Table
              size="small"
              sx={
                (styles.useTableStyles,
                {
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: 'none'
                  }
                })
              }
              aria-label="Lista nieprzypisanych nauczycieli">
              <caption>Lista nieprzypisanych nauczycieli</caption>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.cellHeaderStyles} align="left">
                    Nazwisko
                  </TableCell>
                  <TableCell sx={styles.cellHeaderStyles} align="left">
                    Imię
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSelectableTeachers.map((teacher, rowIndex) => {
                  const selected = selectedTeacher && teacher.id === selectedTeacher.id;
                  return (
                    <TableRow
                      key={teacher.id}
                      onClick={() => handleCheck(teacher)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') handleCheck(teacher);
                      }}
                      tabIndex={0}>
                      {[teacher.lastName, teacher.firstName].map((item, index) => (
                        <TableCell
                          key={index}
                          sx={(styles.useCellBodyStyles, { pl: index === 0 ? 0 : undefined })}
                          align="left">
                          {index === 0 && (
                            <Checkbox
                              color="primary"
                              inputProps={{ 'aria-label': `wybierz wiersz ${rowIndex}` }}
                              checked={selected || false}
                              tabIndex={-1}
                            />
                          )}
                          <Typography
                            component="span"
                            varaint="h6"
                            sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
                            {item}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </>
      )}
    </>
  );
};

GroupTeachersEditListv2.propTypes = {
  allTeachers: PropTypes.array.isRequired,
  associatedTeachersIds: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default GroupTeachersEditListv2;
