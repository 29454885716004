import PropTypes from 'prop-types';
import React from 'react';
import BoardPostCard from '../../messageBoard/post/BoardPostCard';

import Masonry from '@mui/lab/Masonry';
import { Box } from '@mui/material';

const EventsList = ({ events, dontDisplayHistory }) => {
  return (
    <Box>
      <Masonry columns={{ xs: 1, md: 2, lg: 3 }} spacing={4} sx={{ mx: -2, mt: 0, p: 0, width: 'auto' }}>
        {events.map((post) => {
          return (
            <BoardPostCard key={post.id} post={post} canEdit={false} dontDisplayHistory={dontDisplayHistory || true} />
          );
        })}
      </Masonry>
    </Box>
  );
};

EventsList.propTypes = {
  events: PropTypes.array.isRequired,
  dontDisplayHistory: PropTypes.bool
};

export default EventsList;
