export const tableConfig = [
  {
    id: 0,
    name: 'imie',
    description: 'Imię dziecka',
    required: true
  },
  {
    id: 1,
    name: 'drugie_imie',
    description: 'Drugie imię dziecka',
    required: true
  },
  {
    id: 2,
    name: 'nazwisko',
    description: 'Nazwisko dziecka',
    required: true
  },
  {
    id: 3,
    name: 'pesel',
    description: 'Numer pesel dziecka',
    required: true
  },
  {
    id: 4,
    name: 'data_urodzenia',
    description: 'Data urodzenia dziecka',
    required: true
  },
  {
    id: 5,
    name: 'adres',
    description: 'Adres zamieszkania dziecka',
    required: true
  },
  {
    id: 6,
    name: 'kod_pocztowy',
    description: 'Kod pocztowy adresu zamieszkania dziecka',
    required: true
  },
  {
    id: 7,
    name: 'miejscowosc',
    description: 'Miejscowość zamieszkania dziecka',
    required: true
  },
  {
    id: 8,
    name: 'zalecania_lekarskie',
    description: 'Informacja odnośnie zaleceń wydanych przez lekarzy',
    required: true
  },
  {
    id: 9,
    name: 'uczulenia_na_pokarmy',
    description: 'Informacja odnośnie uczulenia dziecka na pokarmy',
    required: true
  },
  {
    id: 10,
    name: 'imie_opiekuna',
    description: 'Imię opiekuna',
    required: true
  },
  {
    id: 11,
    name: 'nazwisko_opiekuna',
    description: 'Nazwisko opiekuna',
    required: true
  },
  {
    id: 12,
    name: 'email_opiekuna',
    description: 'Adres e-mail opiekuna',
    required: true
  },
  {
    id: 13,
    name: 'numer_ewidencji',
    description: 'Pesel lub inny identyfikator rodzica',
    required: true
  },
  {
    id: 14,
    name: 'telefon_opiekuna',
    description: 'Numer kontaktowy opiekuna',
    required: true
  },
  {
    id: 15,
    name: 'adres_zamieszkania_opiekuna',
    description: 'Adres zamieszkania opiekuna',
    required: true
  },
  {
    id: 16,
    name: 'adres_zameldowania_opiekuna',
    description: 'Adres zameldowania opiekuna',
    required: false
  },
  {
    id: 17,
    name: 'pokrewienstwo',
    description: 'Stopień pokrewieństwa opiekuna oraz dziecka',
    required: false
  },
  {
    id: 18,
    name: 'rozpoczecie_pracy',
    description: 'Godzina rozpoczynania pracy przez opiekuna',
    required: false
  },
  {
    id: 19,
    name: 'zakonczenie_pracy',
    description: 'Godzina zakończenia pracy przez opiekuna',
    required: false
  },
  {
    id: 20,
    name: 'adres_miejsca_pracy',
    description: 'Adres miejsca pracy opiekuna',
    required: false
  },
  {
    id: 21,
    name: 'numer_konta',
    description: 'Numer konta opiekuna',
    required: false
  }
];
