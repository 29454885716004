import React from 'react';
import { connect } from 'react-redux';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { bindActionCreators } from 'redux';
import * as applicationsActions from '../../actions/applicationsActions';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../forms/ConfirmationDialog';
import PrincipalApplicationsTable from './PrincipalApplicationsTable';
import * as activityActions from '../../actions/activitiesActions';
import * as childrenActions from '../../actions/childrenActions';
import * as chargingSchemesActions from '../../actions/chargingSchemesActions';
import { ChildChargingScheme } from '../../models/children/ChildModels';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import FeedIcon from '@mui/icons-material/Feed';

const actionType = {
  additionalActivities: 'additionalActivities',
  catering: 'catering'
};

class PrincipalApplicationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenConfirmRejectApplicationDialog: false,
      isOpenConfirmApproveApplicationDialog: false,
      selectedApplication: {},
      type: ''
    };

    this.rejectApplication = this.rejectApplication.bind(this);
    this.approveApplication = this.approveApplication.bind(this);
    this.refreshApplications = this.refreshApplications.bind(this);
  }

  componentDidMount() {
    this.refreshApplications();
  }

  onRejectApplication(application) {
    this.setState(
      {
        selectedApplication: application
      },
      () => {
        this.setState({
          isOpenConfirmRejectApplicationDialog: true
        });
      }
    );
  }

  onApproveApplication(application, type) {
    this.setState(
      {
        selectedApplication: application,
        type
      },
      () => {
        this.setState({
          isOpenConfirmApproveApplicationDialog: true
        });
      }
    );
  }

  rejectApplication() {
    this.setState({
      isInProgress: true
    });
    this.props.applicationsActions.rejectApplication(this.state.selectedApplication.id).then(() => {
      this.setState({
        isOpenConfirmRejectApplicationDialog: false,
        isInProgress: false
      });
      this.refreshApplications();
    });
  }

  approveApplication() {
    const { selectedApplication, type } = this.state;
    const {
      activityActions: actAction,
      chargingSchemes,
      applicationsActions: appActions,
      childrenAction,
      activity
    } = this.props;

    this.setState({
      isInProgress: true
    });

    const afterAll = () => {
      this.setState({
        isOpenConfirmApproveApplicationDialog: false,
        isInProgress: false
      });
      this.refreshApplications();
    };

    appActions.approveApplication(selectedApplication.id).then(() => {
      switch (type) {
        case actionType.additionalActivities:
          actAction.loadActivityDetailsAsync(selectedApplication.activityId).then(() => {
            const newChildList = [...activity.enrolledChildren.map((c) => c.id), selectedApplication.childId];
            actAction.updateEnrolledChildrenAsync(selectedApplication.activityId, newChildList).then(() => {
              afterAll();
            });
          });
          break;
        case actionType.catering:
          {
            const scheme = chargingSchemes && chargingSchemes.find((item) => item.id === selectedApplication.schemeId);
            if (!!scheme) {
              const { id, absenceReportingDeadlineTime, absenceReportingDeadlineDays } = scheme;
              childrenAction
                .updateChargingScheme(
                  selectedApplication.childId,
                  new ChildChargingScheme().assign({
                    schemeId: id,
                    absenceReportingDeadlineTime,
                    absenceReportingDeadlineDays
                  })
                )
                .then(() => {
                  afterAll();
                });
            }
          }
          break;
        default:
          return null;
      }

      return null;
    });
  }

  refreshApplications() {
    this.props.applicationsActions.loadAllApplications();
  }

  render() {
    const { applications } = this.props;
    const { isOpenConfirmRejectApplicationDialog, isOpenConfirmApproveApplicationDialog } = this.state;
    return (
      <LoadingRenderWrapper>
        <>
          <PageHeaderText title="Wnioski" titleIcon={<FeedIcon />} />
          <ContentWrapper>
            <PrincipalApplicationsTable
              activitiesHeader={false}
              title="Wnioski dotyczące wyżywienia"
              captionText="Tabela wniosków o schematy płatności za wyżywienie"
              applications={applications.filter((a) => a.schemeId !== null)}
              approve={(app) => this.onApproveApplication(app, actionType.catering)}
              reject={(app) => this.onRejectApplication(app)}
            />
            <PrincipalApplicationsTable
              activitiesHeader={true}
              title="Wnioski dotyczące zajęć dodatkowych"
              captionText="Tabela wniosków o zajęcia dodatkowe"
              applications={applications.filter((a) => a.activityId !== null)}
              approve={(app) => this.onApproveApplication(app, actionType.additionalActivities)}
              reject={(app) => this.onRejectApplication(app)}
            />
            <ConfirmationDialog
              confirmationText="Czy na pewno chcesz odrzucić wniosek?"
              confirmationTitle="Odrzucenie wniosku"
              onConfirm={this.rejectApplication}
              inProgress={this.state.isInProgress}
              onCancel={() => this.setState({ isOpenConfirmRejectApplicationDialog: false })}
              isOpen={isOpenConfirmRejectApplicationDialog}
            />
            <ConfirmationDialog
              confirmationText="Czy na pewno chcesz zaakceptować wniosek?"
              confirmationTitle="Akceptacja wniosku"
              onConfirm={this.approveApplication}
              inProgress={this.state.isInProgress}
              onCancel={() => this.setState({ isOpenConfirmApproveApplicationDialog: false })}
              isOpen={isOpenConfirmApproveApplicationDialog}
            />
          </ContentWrapper>
        </>
      </LoadingRenderWrapper>
    );
  }
}

PrincipalApplicationsPage.propTypes = {
  applications: PropTypes.array.isRequired,
  applicationsActions: PropTypes.object.isRequired,
  activityActions: PropTypes.object.isRequired,
  activity: PropTypes.any,
  childrenAction: PropTypes.object.isRequired,
  chargingSchemesActions: PropTypes.object.isRequired,
  chargingSchemes: PropTypes.any
};

function mapStateToProps(state) {
  return {
    applications: state.applications,
    activity: state.activityDetails,
    chargingSchemes: state.configuration.chargingSchemes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    applicationsActions: bindActionCreators(applicationsActions, dispatch),
    activityActions: bindActionCreators(activityActions, dispatch),
    childrenAction: bindActionCreators(childrenActions, dispatch),
    chargingSchemesActions: bindActionCreators(chargingSchemesActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrincipalApplicationsPage);
