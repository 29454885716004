import React from 'react';
import PropTypes from 'prop-types';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PsychologicalSchemesContainer from './PsychologicalSchemesContainer';

const PsychologicalSchemesPage = ({ location }) => {
  return (
    <LoadingRenderWrapper>
      <PsychologicalSchemesContainer location={location} />
    </LoadingRenderWrapper>
  );
};

PsychologicalSchemesPage.alerpropTypes = {
  location: PropTypes.object.isRequired
};

export default PsychologicalSchemesPage;
