import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import moment from 'moment';
import FormDialog from '../../../formik/formDialog/FormDialog';
import { FormikTextField } from '../../../formik/FormikTextField';
import FormikDatePicker from '../../../formik/FormikDatePicker';
import * as Yup from 'yup';

const InitialValues = {
  rate: '',
  startDate: moment().format('yyyy-MM-DD')
};

const VALIDATION_SCHEMA = Yup.object().shape({
  rate: Yup.number().required('Pole wymagane'),
  startDate: Yup.date().required('Pole wymagane')
});

const AdvancedInterestDialog = ({ open, onSubmit, onClose, initialData, edit }) => {
  const handleSubmit = ({ rate, startDate }, formikHelpers) => {
    onSubmit(
      {
        rate: parseFloat(rate),
        startDate: moment(startDate).format('yyyy-MM-DD')
      },
      formikHelpers
    );
  };

  return (
    <FormDialog
      open={open}
      title="Reguła zaawansowanego naliczania odsetek"
      initialValues={initialData || InitialValues}
      onSubmit={handleSubmit}
      validationSchema={VALIDATION_SCHEMA}
      maxWidth="sm"
      onClose={onClose}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
        <FormikTextField name="rate" type="number" label="Stopa odsetek" />
        <FormikDatePicker disabled={edit} name="startDate" />
      </Box>
    </FormDialog>
  );
};

AdvancedInterestDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  edit: PropTypes.bool.isRequired
};

export default AdvancedInterestDialog;
