import io from 'socket.io-client';
import config from '../../config/config';
import * as types from '../../actions/actionTypes';
import localStorageProvider from '../../utils/localStorageProvider';
import * as logger from '../../utils/logger';

class SocketService {
  constructor() {
    this.socket = null;
  }

  sendMessage(threadId, messageId, content) {
    this.socket.emit('pm:sendMessage', { threadId, messageId, content });
  }

  updateMessage(threadId, messageId, content) {
    this.socket.emit('pm:updateMessage', { threadId, messageId, content });
  }

  deleteMessage(threadId, messageId) {
    this.socket.emit('pm:deleteMessage', { threadId, messageId });
  }

  startThread(threadId, messageId, content, recipients) {
    this.socket.emit('pm:startThread', { threadId, messageId, recipients, content });
  }

  markRead(threadId, messageTimestamp) {
    this.socket.emit('pm:markRead', { threadId, messageTimestamp });
  }

  notifyTyping(threadId) {
    this.socket.emit('pm:triggerTyping', { threadId });
  }

  notifyNotTyping(threadId) {
    this.socket.emit('pm:triggerNotTyping', { threadId });
  }

  getMyList(callback) {
    if (this.socket) {
      this.socket.emit('u:getUsersList', callback);
    }
  }

  connect(store) {
    try {
      const token = localStorageProvider.getItem('authToken');
      if ((this.socket && this.socket.connected) || !token) {
        return;
      }

      this.socket = io.connect(config.apiUrl, {
        query: { token },
        transports: ['websocket']
      });

      ////here register all actions
      this.socket.on('pm:message', (message) =>
        store.dispatch({ type: types.PRIVATE_MESSAGE_SENT_NOTIFICATION, message })
      );
      this.socket.on('pm:messageDeleted', (message) =>
        store.dispatch({ type: types.PRIVATE_MESSAGE_DELETED_NOTIFICATION, message })
      );
      this.socket.on('pm:messageUpdated', (message) =>
        store.dispatch({ type: types.PRIVATE_MESSAGE_UPDATED_NOTIFICATION, message })
      );
      this.socket.on('pm:newThread', (message) => store.dispatch({ type: types.NEW_THREAD_NOTIFICATION, message }));
      this.socket.on('pm:read', (message) => store.dispatch({ type: types.MESSAGE_READ_NOTIFICATION, message }));
      this.socket.on('pm:typing', (message) =>
        store.dispatch({ type: types.PM_TYPING_NOTIFICATION_RECEIVED, message })
      );
      this.socket.on('pm:notTyping', (message) =>
        store.dispatch({ type: types.PM_NOT_TYPING_NOTIFICATION_RECEIVED, message })
      );
    } catch (error) {
      logger.error(`SocketListener.connect error: ${error}`);
    }
  }

  disconnect() {
    try {
      if (this.socket) this.socket.disconnect();
    } catch (error) {
      logger.error(`SocketListener.disconnect error: ${error}`);
    }
  }
}

const createSocketListenerRegisterMiddleware = (listener) => (store) => (next) => (action) => {
  if (action.type === types.PRELOAD_DATA_SUCCESS) {
    listener.connect(store);
  }
  if (action.type === types.CLEANUP_DATA) {
    listener.disconnect();
  }
  next(action);
};
const socketService = new SocketService();

export default createSocketListenerRegisterMiddleware(socketService);

export function getSocketService() {
  return socketService;
}
