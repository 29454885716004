import React from 'react';
import PropTypes from 'prop-types';
import ResetPasswordFormWrap from '../Components/Forms/ResetPasswordFormWrap';
import * as authActions from '../../../../actions/userAccountActions';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';

const ResetPasswordContainer = ({ location }) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line react/prop-types
  const onSubmit = ({ password }) =>
    dispatch(authActions.resetPassword({ token: location.query.token, newPassword: password, isRegister: false }));

  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
        <strong>Witaj!</strong> Poproszono o zmianę hasła
      </Typography>
      <ResetPasswordFormWrap onSubmit={onSubmit} />
    </Box>
  );
};

ResetPasswordContainer.propTypes = {
  location: PropTypes.object.isRequired
};

export default ResetPasswordContainer;
