import PropTypes from 'prop-types';
import React from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import PersonAvatar from '../forms/PersonAvatar';
import EmptyState from '../common/EmptyState';

const ChildArchiveGuardiansList = ({ guardians }) => {
  return (
    <>
      {guardians.length > 0 ? (
        <List>
          {guardians.map((guardian) => {
            return (
              <ListItem key={guardian.id}>
                <PersonAvatar
                  initials
                  alt={guardian.firstName}
                  firstName={guardian.firstName}
                  lastName={guardian.lastName}
                  sx={{ mr: 2 }}
                />
                <ListItemText
                  primary={`${guardian.firstName} ${guardian.lastName}`}
                  secondary={[
                    <Box key="1">
                      <Typography
                        sx={{
                          fontWeight: (theme) => theme.typography.fontWeightBold
                        }}>
                        {guardian.email}
                      </Typography>
                    </Box>,
                    <Box sx={{ display: 'flex' }} key="2">
                      <Typography sx={{ mr: 1 }}>Numer dokumentu</Typography>
                      <Typography
                        sx={{
                          mr: 2,
                          fontWeight: (theme) => theme.typography.fontWeightBold
                        }}>
                        {guardian.documentNumber}
                      </Typography>
                    </Box>
                  ]}
                  primaryTypographyProps={{
                    color: (theme) => theme.palette.text.primary,
                    fontWeight: (theme) => theme.typography.fontWeightBold
                  }}
                  secondaryTypographyProps={{
                    color: (theme) => theme.palette.text.primary
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      ) : (
        <EmptyState message="Dla dziecka nie zostały przypisane osoby upoważnione do odbioru" contrast />
      )}
    </>
  );
};

ChildArchiveGuardiansList.propTypes = {
  guardians: PropTypes.array.isRequired
};

export default ChildArchiveGuardiansList;
