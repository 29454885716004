import React from 'react';
import PropTypes from 'prop-types';
import { Fade, Grid, useTheme, Box, Typography, Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ShareToRoleCard from '../ShareToRoleCard';
import SaveAndCancel from '../../SaveAndCancel/SaveAndCancel';
import IconButtonPipe from '../../../../../components/common/IconButtonPipe';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import MoreGroupsIcon from '../../../../../assets/icons/MoreGroupsIcon';
import EmptyState from '../../../../../components/common/EmptyState';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import PrivateFileBackdrop from '../../PrivateFileBackdrop/PrivateFileBackdrop';
import { useShareFileContext } from '../../../context/ShareFileContext';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  contentWrapper: {
    position: 'relative',
    height: '100%',
    overflowY: ({ isPrivate }) => (isPrivate ? 'hidden' : 'auto')
  },
  gridWrapper: {
    gap: 20,
    display: 'grid',
    gridTemplateColumns: ({ isMobile }) => `repeat( auto-fill, minmax(${isMobile ? '100px' : '200px'}, 1fr) )`,
    justifyItems: 'center',
    alignItems: 'center',
    padding: 20,
    width: '100%'
  }
}));

const SelectedRolesContent = ({ selectedList, onSingleAction, onCloseDialog, onResetSelectedRoles }) => {
  const { isMobile, isDisabled, isPrivate, isForbidden, isSingleShare, isFolder } = useShareFileContext();
  const cl = useStyles({ isMobile, isPrivate });
  const theme = useTheme();
  const isListEmpty = !(selectedList.length > 0);
  return (
    <>
      <Grid item xs={12} md={6} className={cl.wrapper} sx={{ overflow: 'scroll' }}>
        <Box className={cl.contentWrapper}>
          {isFolder && (
            <Alert severity="warning" sx={{ m: 2 }}>
              Wszystkie pliki oraz podfoldery znajdujące się w udostępnianym folderze zostaną również udostępnione.
            </Alert>
          )}
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}>
            <MoreGroupsIcon
              sx={{
                fontSize: 40,
                mr: 2,
                color: isDisabled ? theme.palette.color.contrast : theme.palette.color.color25
              }}
            />
            <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightLarge }}>
              Wybrane role, dla których udostępnisz {isFolder ? 'folder' : isSingleShare ? 'plik' : 'pliki'}
            </Typography>
            <Fade in={true} timeout={500}>
              <Box>
                <IconButtonPipe
                  onClick={(e) => {
                    e.preventDefault();
                    onResetSelectedRoles();
                  }}
                  tooltip="Usuń wszystkie role z listy"
                  disabled={isDisabled || isListEmpty}>
                  <RotateLeftIcon sx={{ color: isDisabled || isListEmpty || theme.palette.color.color25 }} />
                </IconButtonPipe>
              </Box>
            </Fade>
          </Box>
          {isListEmpty && (
            <Fade in={true}>
              <Box>
                <EmptyState contrast icon={<SentimentSatisfiedIcon />} message="Nie wybrano żadnej roli" />
              </Box>
            </Fade>
          )}
          <Box className={cl.gridWrapper}>
            {selectedList.map((item, index) => (
              <ShareToRoleCard
                key={index}
                {...item}
                onSingleAction={onSingleAction}
                isSelected={true}
                isDisabled={isDisabled}
                isPrivate={isPrivate}
              />
            ))}
          </Box>
          <PrivateFileBackdrop
            isOpen={isPrivate}
            text={`${
              isFolder
                ? 'Wybrany folder stanie się prywatny'
                : isSingleShare
                ? 'Wybrany plik stanie się prywatny'
                : 'Wszystkie wybrane pliki staną się prywatne'
            }. Zapisane udostępnienia dla roli oraz dla pojedynczych użytkowników zostaną usunięte.`}
          />
        </Box>
        {!isMobile && <SaveAndCancel onCancel={onCloseDialog} isDisabled={isDisabled} isForbidden={isForbidden} />}
      </Grid>
    </>
  );
};

SelectedRolesContent.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  onResetSelectedRoles: PropTypes.func.isRequired,
  onSingleAction: PropTypes.func.isRequired,
  selectedList: PropTypes.array.isRequired
};

export default SelectedRolesContent;
