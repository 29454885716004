import api from '../api/DigitalDiaryApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function initLoadEventsAsync({ eventType, year, month }) {
  return (dispatch, getState) => {
    const { choosenGroup } = getState().digitalDiary;
    if (choosenGroup.id) {
      return api
        .getSomeData({ eventType, year, month, groupId: choosenGroup.id })
        .then((data) => {
          dispatch({ type: types.LOAD_DIGITAL_DIARY_EVENT_SUCCESS, data });
        })
        .catch((error) => {
          dispatch(notificationActions.showError('Nie udało się pobrać danych'));
          return logger.error(error);
        });
    }
    return null;
  };
}

export function loadEventsAsync({ eventType, year, month, groupId }) {
  return (dispatch) => {
    dispatch({ type: types.PRELOAD_ON_NAVIGATION });
    return api
      .getSomeData({ eventType, year, month, groupId })
      .then((data) => {
        dispatch({ type: types.PRELOAD_ON_NAVIGATION_SUCCESS });
        dispatch({ type: types.LOAD_DIGITAL_DIARY_EVENT_SUCCESS, data });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać danych'));
        dispatch({ type: types.PRELOAD_ON_NAVIGATION_SUCCESS });
        return logger.error(error);
      });
  };
}

export function changeChoosenGroup(group) {
  return (dispatch) => {
    dispatch({ type: types.CHANGE_DIGITAL_DIARY_CHOOSEN_GROUP, group });
  };
}

export function createEventStart() {
  return { type: types.CREATE_DIGITAL_DIARY_EVENT_START };
}

export function createEventCancel() {
  return { type: types.CREATE_DIGITAL_DIARY_EVENT_CANCEL };
}

export function addNewEventAsync(event) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_DIGITAL_DIARY_EVENT_SUBMIT });
    return api
      .addNewEvent(event)
      .then((data) => {
        dispatch({
          type: types.CREATE_DIGITAL_DIARY_EVENT_SUCCESS,
          eventData: data,
          sendedData: event
        });
        dispatch(notificationActions.showSuccess('Dodano pomyślnie'));
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Wystąpił błąd'));
        return logger.error(error);
      });
  };
}

export function deleteEventAsync(eventId) {
  return (dispatch) => {
    dispatch({ type: '' });
    return api
      .deleteEvent(eventId)
      .then(() => {
        dispatch({
          type: '',
          eventId
        });
        dispatch(notificationActions.showSuccess('Pomyślnie usunięto'));
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się usunąć wydarzenia'));
        return logger.error(error);
      });
  };
}

export function updateEventStart() {
  return (dispatch) => {
    dispatch({ type: '' });
  };
}

export function updateEventStop() {
  return (dispatch) => {
    dispatch({ type: '' });
  };
}

export function updateEventAsync() {
  return (dispatch) => {
    dispatch({ type: '' });
  };
}

export function cloneEventsAsync(set, objectToLoadData) {
  return (dispatch) => {
    return api
      .cloneEvents(set)
      .then(() => {
        dispatch(notificationActions.showSuccess('Klonowanie zakończone sukcesem'));
        dispatch(loadEventsAsync(objectToLoadData));
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać danych'));
        return logger.error(error);
      });
  };
}

// export function eventsFilter(date, eventType, filterText, groupId) {
//   return async (dispatch) => {
//     try {
//       const response = await api.eventsFilter(date, eventType, filterText, groupId);
//       dispatch({ type: types.DIGITAL_DIARY_POSTS_FILTER_SUCCESS, result: response });
//       return response;
//     } catch (error) {
//       dispatch({ type: types.DIGITAL_DIARY_POSTS_FILTER_FAILURE });
//       dispatch(notificationActions.showError('Nie udało się przefiltrować wydarzeń'));
//       return {};
//     }
//   };

export function eventsFilter(date, eventType, filterText, groupId, eventDateFrom, eventDateTo) {
  const query = { group: groupId };
  if (filterText.length > 0) {
    query.textFilter = filterText;
  }
  if (eventDateFrom.length > 0) {
    query.eventDateFrom = eventDateFrom;
  }
  if (eventDateTo.length > 0) {
    query.eventDateTo = eventDateTo;
  }
  return async (dispatch) => {
    try {
      // const response = await api.eventsFilter(date, eventType, filterText, groupId, eventDateFrom, eventDateTo);
      const response = await api.eventsFilter(query, date, eventType);
      dispatch({ type: types.DIGITAL_DIARY_POSTS_FILTER_SUCCESS, result: response });
      return response;
    } catch (error) {
      dispatch({ type: types.DIGITAL_DIARY_POSTS_FILTER_FAILURE });
      dispatch(notificationActions.showError('Nie udało się przefiltrować wydarzeń'));
      return {};
    }
  };
}
