import React from 'react';
import PropTypes from 'prop-types';
import UserRoleIcon from '../../../../components/userRoles/UserRoleIcon';
import CancelIcon from '@mui/icons-material/Cancel';
import RedoIcon from '@mui/icons-material/Redo';
import { Box, Card, CardActionArea, CardContent, Grid, Grow, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useShareFileContext } from '../../context/ShareFileContext';

const ShareToRoleCard = ({ role, name, onSingleAction, isSelected }) => {
  const { isMobile, isDisabled, isPrivate } = useShareFileContext();
  const theme = useTheme();
  const isContrastColor = useSelector((state) => state.theme);
  const color = isDisabled
    ? theme.palette.color.contrast
    : isContrastColor === 'contrast'
    ? theme.palette.color.wcag
    : theme.palette.components.role[role];

  return (
    <Grow in={true}>
      <Card
        sx={{
          width: isMobile ? 120 : 180,
          height: isMobile ? 100 : 140,
          m: 2,
          border: `2px solid ${color} `,
          background: `${color}30`,
          borderRadius: '30px',
          position: 'relative',
          opacity: isPrivate && isSelected ? 0.1 : undefined
        }}>
        <CardActionArea
          sx={{ height: '100%' }}
          disabled={isDisabled}
          onClick={(e) => {
            e.preventDefault();
            onSingleAction(role, isSelected);
          }}>
          <CardContent>
            <Grid container sx={{ justifyContent: 'center', alignItems: 'center', direction: 'column' }}>
              <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
                <UserRoleIcon role={role} sx={{ fontSize: isMobile ? 36 : 65, color }} />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: isMobile ? 14 : 16,
                    fontWeight: theme.typography.fontWeightMedium,
                    textAlign: 'center'
                  }}>
                  {name}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>

          <Box sx={{ position: 'absolute', right: 15, top: isMobile ? 10 : 15 }}>
            {!isSelected ? (
              <RedoIcon
                sx={{
                  color: isDisabled ? theme.palette.color.contrast : theme.palette.color.success,
                  fontSize: isMobile ? 18 : undefined
                }}
              />
            ) : (
              <CancelIcon
                sx={{
                  color: isDisabled ? theme.palette.color.contrast : theme.palette.color.error,
                  fontSize: isMobile ? 18 : undefined
                }}
              />
            )}
          </Box>
        </CardActionArea>
      </Card>
    </Grow>
  );
};

ShareToRoleCard.propTypes = {
  isSelected: PropTypes.bool,
  name: PropTypes.string,
  onSingleAction: PropTypes.func.isRequired,
  role: PropTypes.string,
  tooltip: PropTypes.string
};

export default ShareToRoleCard;
