import ApiBase, { ROOT_URL } from './ApiBase';

class ScheduledTransferApi extends ApiBase {
  static getScheduledTransfers() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/enrollments`).type('application/json'));
  }

  static createScheduledTransfer(data) {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/enrollments`).send(data).type('application/json'));
  }

  static editScheduledTransfer(id, data) {
    return super.toPromise((agent) => agent.put(`${ROOT_URL}/enrollments/${id}`).send(data).type('application/json'));
  }

  static deleteScheduledTransfer(id) {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/enrollments/${id}/archive`).type('application/json'));
  }

  static deleteManyScheduledTransfer(data) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/enrollments/archiveMany`).send(data).type('application/json')
    );
  }
}

export default ScheduledTransferApi;
