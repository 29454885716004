import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../../forms/DialogForm';
import UnitAbsenceRemovalDeadlineForm from '../forms/UnitAbsenceRemovalDeadlineForm';
import { UnitAbsenceRemovalDeadlineValidator } from '../../../../models/configuration/unit/UnitModelsValidators';

class UnitAbsenceRemovalDeadlineDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      legalGuardiansAbsenceRemovalDeadlineHours: this.props.legalGuardiansAbsenceRemovalDeadlineHours,
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    return this.setState({ legalGuardiansAbsenceRemovalDeadlineHours: event.target.value });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    return (
      <DialogForm
        maxWidth="sm"
        header="Zmiana czasu na wycofanie nieobecności"
        onSave={() => onSave(this.state.legalGuardiansAbsenceRemovalDeadlineHours)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidate={() => new UnitAbsenceRemovalDeadlineValidator().validate(this.state)}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="configuration.unitAbsenceRemovalDeadlineUi"
        requiredFieldText>
        <UnitAbsenceRemovalDeadlineForm
          legalGuardiansAbsenceRemovalDeadlineHours={this.state.legalGuardiansAbsenceRemovalDeadlineHours}
          onChange={this.handleChange}
          errors={this.state.errors}
        />
      </DialogForm>
    );
  }
}

UnitAbsenceRemovalDeadlineDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  legalGuardiansAbsenceRemovalDeadlineHours: PropTypes.number.isRequired
};

export default UnitAbsenceRemovalDeadlineDialog;
