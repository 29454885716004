import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function legalGuardianApplicationsReducer(state = initialState.legalGuardianApplications, action) {
  switch (action.type) {
    case types.LOAD_CATERING_CHARGING_SCHEMES_SUCCESS: {
      return { ...state, cateringChargingSchemes: action.schemes };
    }
    case types.LOAD_LEGAL_GUARDIAN_APPLICATIONS_SUCCESS: {
      return { ...state, childApplications: action.applications };
    }
    default:
      return state;
  }
}
