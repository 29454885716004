import api from '../api/DaycareApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function loadDaycareConfiguration() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_DAYCARE_SETTINGS });
    return api
      .getConfiguration()
      .then((configuration) => dispatch({ type: types.LOAD_DAYCARE_SETTINGS_SUCCESS, configuration }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać konfiguracji aktywności dzieci.'));
        return logger.error(error);
      });
  };
}

export function updateConfiguration(configuration) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_DAYCARE_SETTINGS });
    return api
      .updateConfiguration(configuration)
      .then(() => dispatch({ type: types.UPDATE_DAYCARE_SETTINGS_SUCCESS, configuration }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się zaktualizować konfiguracji aktywności dzieci.'));
        return logger.error(error);
      });
  };
}
