import PropTypes from 'prop-types';
import React from 'react';
import Toggle from '../../../common/Toggle';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';

const AttendanceOptions = ({ scheme, onChange }) => {
  return (
    <FieldsetWrapper legend="Obecności" invisible={false}>
      <Toggle
        label="Traktuj nieuzupełnione wpisy obecności jako obecność w placówce"
        labelPosition="right"
        defaultToggled={scheme.unknownStateAsPresence}
        onToggle={(event, value) => onChange({ target: { name: 'unknownStateAsPresence', value } })}
        sx={{ mt: 2 }}
      />
    </FieldsetWrapper>
  );
};

AttendanceOptions.propTypes = {
  scheme: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default AttendanceOptions;
