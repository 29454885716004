import React, { useState } from 'react';
import ImageList from '@mui/material/ImageList';
import { useMediaQuery, useTheme } from '@mui/material';
import SingleMultimedia from './SingleMultimedia';
import PropTypes from 'prop-types';
import EmptyState from '../../../../common/EmptyState';
import Lightbox from 'react-image-lightbox';
import { GetApp } from '@mui/icons-material';
import IconButtonPipe from '../../../../common/IconButtonPipe';
import 'react-image-lightbox/style.css'; // !!!!! Potrzebne do działania Lightboxa

const MultimediaList = ({ multimediaList, isFiltered, selectedItems, deleteOne, isEditAvailable, ...rest }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);

  const filesLength = multimediaList.length;

  const downloadPhoto = (url) => {
    if (url.fileUrl.startsWith('blob')) {
      const a = document.createElement('a');
      a.href = url.fileUrl;
      a.download = url.caption;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      // download img from server logic
      const path = `${url.fileUrl}?name=${encodeURIComponent(url.name)}&mimeType=${encodeURIComponent(url.mimeType)}`;
      window.open(path, '_blank');
    }
  };

  const handleLightboxOpen = (index) => {
    setIsLightboxOpen(true);
    setCurrentItem(index);
  };

  return (
    <>
      {multimediaList && multimediaList.length > 0 ? (
        <ImageList rowHeight={200} gap={20} cols={isMobile ? 1 : 4} sx={{ m: 0, overflowY: 'visible' }}>
          {multimediaList.map((tile, index) => {
            const isSelected = selectedItems.some((item) => item.id === tile.id);
            return (
              <SingleMultimedia
                key={tile.id}
                isSelected={isSelected}
                tile={tile}
                isEditAvailable={isEditAvailable}
                deleteOne={deleteOne}
                handleLightboxOpen={() => handleLightboxOpen(index)}
                {...rest}
              />
            );
          })}
        </ImageList>
      ) : (
        <EmptyState
          contrast
          message={
            isFiltered !== null
              ? 'Brak wyników wyszukiwania dla zastosowanych filtrów'
              : 'Album nie posiada multimediów!'
          }
        />
      )}
      {isLightboxOpen && (
        <Lightbox
          mainSrc={multimediaList[currentItem].fileUrl}
          onCloseRequest={() => setIsLightboxOpen(false)}
          nextSrc={
            multimediaList[(currentItem + 1) % filesLength] && multimediaList[(currentItem + 1) % filesLength].fileUrl
          }
          prevSrc={
            multimediaList[(currentItem + filesLength - 1) % filesLength] &&
            multimediaList[(currentItem + filesLength - 1) % filesLength].fileUrl
          }
          onMoveNextRequest={() => setCurrentItem((currentItem + 1) % filesLength)}
          nextLabel="Następne"
          prevLabel="Poprzednie"
          zoomInLabel="Powiększ"
          zoomOutLabel="Pomniejsz"
          imageCaption={
            <p>
              {currentItem + 1} z {filesLength}
            </p>
          }
          onMovePrevRequest={() => setCurrentItem((currentItem + filesLength - 1) % filesLength)}
          reactModalStyle={{ overlay: { zIndex: theme.zIndex.modal } }}
          reactModalProps={{ autoFocus: true }}
          toolbarButtons={[
            <IconButtonPipe
              sx={{ color: (theme) => theme.palette.color.primary }}
              tooltip="Pobierz"
              onClick={() => downloadPhoto(multimediaList[currentItem])}>
              <GetApp />
            </IconButtonPipe>
          ]}
        />
      )}
    </>
  );
};

MultimediaList.propTypes = {
  multimediaList: PropTypes.array.isRequired,
  isFiltered: PropTypes.object,
  selectedItems: PropTypes.array.isRequired,
  deleteOne: PropTypes.func.isRequired,
  isEditAvailable: PropTypes.bool.isRequired
};

export default MultimediaList;
