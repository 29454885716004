/*eslint indent:0*/
import initialState from './initial';
import { ChildArchiveDetailsModel } from '../models/children/ChildArchiveModels';
import * as types from '../actions/actionTypes';

export default function childArchiveDetailsReducer(state = initialState.childDetails, action) {
  switch (action.type) {
    case types.LOAD_CHILD_ARCHIVE_SUCCESS: {
      return new ChildArchiveDetailsModel().assign(action.childDetails);
    }
    default:
      return state;
  }
}
