import classNamesProvider from 'classnames';

export class CalendarDayModel {
  constructor(date, isCurrentMonth = true, classNames = null) {
    this.date = date;
    this.isMarked = false;
    this.isCurrentMonth = isCurrentMonth;
    this.isWeekend = date.day() === 6 || date.day() === 0;
    this.data = '';

    const currentMonthClass = this.isCurrentMonth ? 'current-month' : 'not-current-month';
    const weekendClass = this.isWeekend ? 'weekend' : '';
    this.classNames = classNamesProvider(classNames, currentMonthClass, weekendClass);
  }

  mark(data) {
    this.data = data;
    this.isMarked = true;
    this.classNames = classNamesProvider(this.classNames, 'marked');
  }

  static newDay(date) {
    return new CalendarDayModel(date, true, 'current-month');
  }

  static previousMonthDay(date) {
    return new CalendarDayModel(date, false, 'prev-month');
  }

  static nextMonthDay(date) {
    return new CalendarDayModel(date, false, 'prev-month');
  }
}
