import PropTypes from 'prop-types';
import React from 'react';
import { Box, Typography } from '@mui/material';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import YearAndMonthPickerTemplate from './YearAndMonthPickerTemplate';
import ManyGroupsSelector from './ManyGroupsPickerTemplate';
import GroupPickerTemplate from './GroupPickerTemplate';

const YearMonthAndGroupPickerTemplate = ({
  groupId,
  groups,
  year,
  month,
  onChange,
  errors,
  info,
  legend,
  manyGroupsSelector
}) => {
  return (
    <Box>
      <FieldsetWrapper legend={`Pola dla generowania raportu${legend}`}>
        {!manyGroupsSelector ? (
          <GroupPickerTemplate
            groupId={groupId}
            groups={groups}
            onChange={onChange}
            legend={legend}
            errorText={errors.groupId}
          />
        ) : (
          <ManyGroupsSelector groups={groups} onChange={onChange} errorText={errors.groupId} />
        )}

        <YearAndMonthPickerTemplate year={year} month={month} onChange={onChange} sx={{ mt: 1 }} />
      </FieldsetWrapper>
      {info ? (
        <Typography sx={{ mt: 1 }} variant="subtitle2">
          {info}
        </Typography>
      ) : null}
    </Box>
  );
};

YearMonthAndGroupPickerTemplate.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  groupId: PropTypes.string,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  info: PropTypes.string,
  legend: PropTypes.string.isRequired
};

export default YearMonthAndGroupPickerTemplate;
