/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { StaffMemberArchiveModel } from '../models/staffMembers/StaffMemberArchiveModels';

export default function staffMembersArchiveReducer(state = initialState.archives.staffMembers, action) {
  switch (action.type) {
    case types.LOAD_STAFF_MEMBERS_ARCHIVE_SUCCESS: {
      return action.members.map((m) => new StaffMemberArchiveModel().assign(m));
    }
    default:
      return state;
  }
}
