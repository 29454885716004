import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const ExpandLessAll = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="m8 18 1 1 3-3 3 3 .991-1L12 14l-4 4Zm7.991-12L15 5l-3 3-3-3-1 1 4 4 3.991-4ZM20 2H4v2h16V2ZM20 20H4v2h16v-2Z" />
  </SvgIcon>
);

export default ExpandLessAll;
