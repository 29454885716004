import PropTypes from 'prop-types';
import React from 'react';
import Toggle from '../../../common/Toggle';
import TextField from '../../../common/TextField';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';

const PaymentsOptions = ({ scheme, onChange, errors }) => {
  return (
    <FieldsetWrapper legend="Płatności" invisible={false}>
      <Toggle
        label="Płatności elektroniczne włączone"
        labelPosition="right"
        defaultToggled={scheme.onlinePaymentsEnabled}
        onToggle={(event, value) => onChange({ target: { name: 'onlinePaymentsEnabled', value } })}
      />
      <TextField
        hintText="Numer rachunku bankowego"
        floatingLabelText="Numer rachunku bankowego"
        floatingLabelFixed={false}
        name="accountNumber"
        value={scheme.accountNumber}
        onChange={onChange}
        fullWidth={true}
        errorText={errors.accountNumber}
      />
    </FieldsetWrapper>
  );
};

PaymentsOptions.propTypes = {
  scheme: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default PaymentsOptions;
