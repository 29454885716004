/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function diagnosticSchemesUiReducer(state = initialState.configuration.diagnosticSchemesSetsUi, action) {
  switch (action.type) {
    //create
    case types.CREATE_DIAGNOSTICSCHEMES_SET: {
      return Object.assign({}, state, { details: UiModel.start() });
    }
    case types.CREATE_DIAGNOSTICSCHEMES_SET_CANCEL: {
      return Object.assign({}, state, { details: UiModel.cancel() });
    }
    case types.CREATE_DIAGNOSTICSCHEMES_SET_SUBMIT: {
      return Object.assign({}, state, { details: UiModel.submit() });
    }
    case types.CREATE_DIAGNOSTICSCHEMES_SET_SUCCESS: {
      return Object.assign({}, state, { details: UiModel.success() });
    }
    case types.CREATE_DIAGNOSTICSCHEMES_SET_FAILURE: {
      return Object.assign({}, state, { details: UiModel.failure(action.errors) });
    }
    //update
    case types.UPDATE_DIAGNOSTICSCHEMES_SET: {
      return Object.assign({}, state, { details: UiModel.start() });
    }
    case types.UPDATE_DIAGNOSTICSCHEMES_SET_CANCEL: {
      return Object.assign({}, state, { details: UiModel.cancel() });
    }
    case types.UPDATE_DIAGNOSTICSCHEMES_SET_SUBMIT: {
      return Object.assign({}, state, { details: UiModel.submit() });
    }
    case types.UPDATE_DIAGNOSTICSCHEMES_SET_SUCCESS: {
      return Object.assign({}, state, { details: UiModel.success() });
    }
    case types.UPDATE_DIAGNOSTICSCHEMES_SET_FAILURE: {
      return Object.assign({}, state, { details: UiModel.failure(action.errors) });
    }
    default:
      return state;
  }
}
