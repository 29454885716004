import * as types from './actionTypes';

export function changePsychologicalGroup(group) {
  return (dispatch) => {
    dispatch({ type: types.CHANGE_PSYCHOLOGICAL_GROUP, group });
  };
}

export function loadPsychologicalGroupWorkPlan(group) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_GROUP_CLASSES, group });
  };
}
