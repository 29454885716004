// components/OverpaymentsListHeader.jsx
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SortedTableCell from '../../table/SortedTableCell';
import PropTypes from 'prop-types';
import React from 'react';
import * as definedSortType from '../../../constants/definedSortTypes';
import { Checkbox, TableCell } from '@mui/material';

const sortedCells = [
  {
    id: 'firstName',
    label: 'Imię',
    type: definedSortType.STRING_SORT
  },
  {
    id: 'lastName',
    label: 'Nazwisko',
    type: definedSortType.STRING_SORT
  },
  {
    id: 'number',
    label: 'Numer',
    type: definedSortType.STRING_SORT
  },
  {
    id: 'amount',
    label: 'Kwota Nadpłaty',
    type: definedSortType.NUMBER_SORT
  },
  {
    id: 'walletType',
    label: 'Typ Portfela',
    type: definedSortType.STRING_SORT
  },
  {
    id: 'date',
    label: 'Data Nadpłaty',
    type: definedSortType.DATE_SORT
  }
];

const OverpaymentsListHeader = ({ orderBy, onRequestSort, order, setNotificationList, filteredIds, checked }) => {
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newNotificationList = filteredIds.map((payment) => ({
        personId: payment.personId,
        walletType: payment.walletType
      }));
      setNotificationList(newNotificationList);
      return;
    }
    setNotificationList([]);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Checkbox checked={checked} onChange={handleSelectAllClick} />
        </TableCell>
        {sortedCells.map((cell) => (
          <SortedTableCell
            key={cell.id}
            order={order}
            orderBy={orderBy.property}
            onRequestSort={onRequestSort}
            cell={cell}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};

OverpaymentsListHeader.propTypes = {
  orderBy: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  setNotificationList: PropTypes.func.isRequired,
  filteredIds: PropTypes.array.isRequired,
  checked: PropTypes.bool.isRequired
};

export default OverpaymentsListHeader;
