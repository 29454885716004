import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ActivitiesList from './ActivitiesArchiveList';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import FilterBar from '../common/FilterBar';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { Box, Button, Grid, Paper } from '@mui/material';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import { fromTemplate, routePaths } from '../../routePaths';
import { browserHistory } from 'react-router';
import EmptyState from '../common/EmptyState';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

class ActivitiesArchivePage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      filteredActivities: []
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleLoaded = this.handleLoaded.bind(this);
  }

  handleLoaded() {
    this.setState({
      filteredActivities: this.props.activities
    });
  }

  handleFilterChange(filteredList) {
    this.setState({
      filteredActivities: filteredList
    });
  }

  render() {
    return (
      <LoadingRenderWrapper onLoaded={this.handleLoaded}>
        <Box>
          <PageHeaderText title="Zarchiwizowane zajęcia dodatkowe" titleIcon={<GroupAddOutlinedIcon />} />
          <ContentWrapper>
            <Grid container>
              <Grid item xs={12} md={6}>
                <FilterBar
                  itemsToFilter={this.props.activities}
                  onFilter={this.handleFilterChange}
                  filter={(activity, keyword) => activity.name.toLowerCase().includes(keyword)}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  sx={{ mb: { xs: 0, md: 4 }, mt: { xs: 2, md: 0 } }}
                  variant="outlined"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => browserHistory.replace(fromTemplate(routePaths.activities))}>
                  Wróć do listy zajęć dodatkowych
                </Button>
              </Grid>
            </Grid>
            {this.state.filteredActivities.length > 0 && (
              <Paper sx={{ my: 2, p: 2 }}>
                <ActivitiesList activities={this.state.filteredActivities} />
              </Paper>
            )}
            {this.state.filteredActivities.length === 0 && (
              <EmptyState message="Brak wyników wyszukiwania" isPaper contrast />
            )}
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

ActivitiesArchivePage.propTypes = {
  activities: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    activities: state.archives.activities
  };
}

export default connect(mapStateToProps)(ActivitiesArchivePage);
