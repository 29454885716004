import React from 'react';
import PropTypes from 'prop-types';
import PersonAvatar from '../../forms/PersonAvatar';
import { Box, Typography } from '@mui/material';

const ChildInfo = (props) => {
  const { child, isOnList } = props;

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <PersonAvatar initials url={child.photoUrl} firstName={child.firstName} lastName={child.lastName} size={60} />
        <Box
          sx={
            ({ ml: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
            isOnList ? { mt: 1 } : { mt: 0 })
          }>
          <Typography variant="h6" sx={{ ml: 2, color: (theme) => theme.palette.color.primary }}>
            {child.firstName} {child.lastName}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

ChildInfo.propTypes = {
  child: PropTypes.object.isRequired,
  isOnList: PropTypes.bool
};

export default ChildInfo;
