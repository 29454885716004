import { Box, CircularProgress, IconButton, List, ListItem, Paper, TextField, Typography } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import FileSpecificIcon from '../../common/FileSpecificIcon/FileSpecificIcon';
import EmptyState from '../../common/EmptyState';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import { getFolderContent } from '../../../actions/virtualDiscActions';
import FolderBreadcrumbs from '../../virtualDisc/components/Breadcrumbs/FolderBreadcrumbs';

const SendFileFromDisc = ({ user, onFileSelect, sx, onClose }) => {
  const dispatch = useDispatch();
  const filesFromFolder = useSelector((state) => state.virtualDiscFolder.disc);
  const { isProcessing } = useSelector((state) => state.virtualDiscFolderUi.fetch);
  const allFiles = useSelector((state) => state.virtualDisc);
  const [search, setSearch] = useState('');
  const [filteredFiles, setFilteredFiles] = useState(filesFromFolder);
  const searchRef = useRef();
  useEffect(() => {
    if (searchRef) searchRef.current.focus();
  }, []);
  useEffect(() => {
    setFilteredFiles(filesFromFolder);
  }, [filesFromFolder]);

  const preparedFiles = useMemo(() => {
    if (user.userRole === 'staffMemberPrincipal') return filteredFiles;
    if (!user.userRole) return filteredFiles.filter((file) => !!file.shareForUserIds.find((id) => id === user.userId));
    return filteredFiles.filter(
      (file) =>
        !!file.shareForUserIds.find((id) => id === user.userId) ||
        !!file.shareForUserRoles.find((role) => role === user.userRole) ||
        file.isOwnByUser
    );
  }, [filteredFiles, user]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    const files = e.target.value ? allFiles : filesFromFolder;
    setFilteredFiles(files.filter((file) => file.name.toLowerCase().includes(e.target.value.toLowerCase())));
  };
  const handleFileSelect = (file) => {
    onFileSelect(file);
  };
  const handleOpenFolder = (id) => {
    handleSearchChange({ target: { value: '' } });
    dispatch(getFolderContent(id));
  };
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Paper sx={{ p: 2, zIndex: 99999, width: 332, ...sx }} elevation={3}>
        <TextField
          inputRef={searchRef}
          value={search}
          onChange={handleSearchChange}
          variant="standard"
          placeholder="Szukaj"
          sx={{ width: '100%' }}
        />
        {search ? (
          <Typography sx={{ mx: 1, color: (theme) => theme.palette.text.secondary }}>
            Przeszukiwanie całego dysku
          </Typography>
        ) : (
          <FolderBreadcrumbs />
        )}
        {isProcessing ? (
          <Box sx={{ height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {!!preparedFiles?.length && (
              <List sx={{ overflowY: 'scroll', width: 300, maxHeight: 300, pb: 4 }}>
                {preparedFiles?.map((file, index) =>
                  file.type === 'folder' ? (
                    <ListItem
                      onDoubleClick={() => handleOpenFolder(file.id)}
                      secondaryAction={
                        <IconButton edge="end" aria-label="otwórz folder" onClick={() => handleOpenFolder(file.id)}>
                          <KeyboardArrowRightIcon />
                        </IconButton>
                      }
                      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.text.color1}`, cursor: 'pointer' }}
                      key={index}>
                      <FileSpecificIcon style={{ fontSize: 40, marginRight: 5 }} type={file.type} item={file} />

                      <Typography sx={{ wordBreak: 'break-word' }}>{file.name}</Typography>
                    </ListItem>
                  ) : (
                    <ListItem
                      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.text.color1}` }}
                      key={index}
                      button
                      onClick={() => {
                        handleFileSelect(file);
                      }}>
                      <FileSpecificIcon
                        style={{ fontSize: 40, marginRight: 5 }}
                        type={file.type === 'folder' ? 'folder' : file.mimeType}
                      />
                      <Typography sx={{ wordBreak: 'break-word' }}>{file.name}</Typography>
                    </ListItem>
                  )
                )}
              </List>
            )}
            {!preparedFiles?.length && <EmptyState contrast noIcon message="Brak plików do udostępnienia" />}
          </>
        )}
      </Paper>
    </ClickAwayListener>
  );
};
export default SendFileFromDisc;
