import PropTypes from 'prop-types';
import React from 'react';
import { Box, MenuItem } from '@mui/material';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import SelectField from '../../../common/SelectField';

const ActivityPickerTemplate = ({ activityId, activities, onChange, legend, sx }) => {
  return (
    <Box sx={{ ...sx }}>
      <FieldsetWrapper legend={`Pola dla generowania raportu${legend}`}>
        <SelectField
          contrast
          sx={{ width: 'auto', overflow: 'hidden' }}
          floatingLabelText="Zajęcia dodatkowe"
          id="activityId"
          value={activityId}
          onChange={(event) => {
            onChange({ target: { name: 'activityId', value: event.target.value } });
          }}>
          {activities.map((activity) => {
            return (
              <MenuItem key={activity.id} value={activity.id}>
                {activity.name}
              </MenuItem>
            );
          })}
        </SelectField>
      </FieldsetWrapper>
    </Box>
  );
};

ActivityPickerTemplate.propTypes = {
  activityId: PropTypes.string.isRequired,
  activities: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  legend: PropTypes.string.isRequired,
  sx: PropTypes.object
};

export default ActivityPickerTemplate;
