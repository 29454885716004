import { Box, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import SelectField from '../../../common/SelectField';
import SingleDatePickerTemplate from './SingleDatePickerTemplate';

const DayAndGroupPickerTemplate = ({ legend, date, groupId, groups, onChange, name, errors }) => {
  return (
    <Box>
      <FieldsetWrapper legend={`Pola dla generowania raportu${legend}`}>
        <SelectField
          errorText={errors.groupId}
          contrast
          sx={{ width: 'auto', overflow: 'hidden', pb: 2 }}
          floatingLabelText="Grupa"
          id="groupId"
          value={groupId}
          onChange={(event) => {
            onChange({ target: { name: 'groupId', value: event.target.value } });
          }}>
          {groups.map((group) => {
            return (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            );
          })}
        </SelectField>
        <SingleDatePickerTemplate
          date={date}
          errors={errors}
          legend={legend}
          name={name}
          onChange={(event) => {
            onChange({ target: { name: 'startDate', value: event.target.value } });
          }}
        />
      </FieldsetWrapper>
    </Box>
  );
};

DayAndGroupPickerTemplate.propTypes = {
  legend: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object
};

export default DayAndGroupPickerTemplate;
