import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WelcomeScreen from '../common/welcomeScreen/WelcomeScreen';
import { Box, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserAccountApi from '../../api/UserAccountApi';
import { withRouter } from 'react-router';
import { routePaths } from '../../routePaths';
import { signOut } from '../../actions/authActions';
import { ROOT_URL } from '../../api/ApiBase';
import { loadUserState } from '../../actions/userAccountActions';
import { getTenantName } from '../../utils/getTenantName';

const useStyle = makeStyles({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  agreement: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0'
  }
});

const StatuteChangedPage = (props) => {
  const [loading, setLoading] = useState(false);

  const acceptAgreement = () => {
    setLoading(true);
    UserAccountApi.acceptAgreement().then(() => {
      props.loadUserState().then(() => {
        props.router.push(routePaths.home);
      });
    });
  };

  const handleAgreementOpen = (event) => {
    const path = `${ROOT_URL}/users/agreement?insurance=${!!props.unit.insurance.isActive}&tenant=${getTenantName()}`;
    window.open(path, '_blank');
    event.preventDefault();
  };

  const classes = useStyle();

  return (
    <WelcomeScreen>
      <Box sx={{ p: 2 }}>
        <Typography paragraph variant="h5" align="center">
          Zmiany w regulaminie
        </Typography>
        <Typography paragraph variant="subtitle1" component="p" align="center">
          Nastąpiła zmiana w regulaminie korzystania z aplikacji dla Państwa placówki. Do dalszego korzystania z
          aplikacji, wymagane jest zapoznanie się z nowym regulaminem oraz jego zaakceptowanie.
        </Typography>
        <Box className={classes.agreement}>
          <Button variant="outlinedContrast" aria-label="Regulamin" onClick={handleAgreementOpen}>
            Regulamin
          </Button>
        </Box>
        <Box className={classes.buttons}>
          <Button variant="outlinedContrast" aria-label="Odmawiam" disabled={loading} onClick={props.signOut}>
            Odmawiam
          </Button>
          <Button variant="contained" aria-label="Akceptuje" disabled={loading} onClick={acceptAgreement}>
            Akceptuje
          </Button>
        </Box>
      </Box>
    </WelcomeScreen>
  );
};

StatuteChangedPage.propTypes = {
  router: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
  loadUserState: PropTypes.func.isRequired,
  unit: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  unit: state.configuration.unit
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signOut,
      loadUserState
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StatuteChangedPage));
