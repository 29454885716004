import React from 'react';
import PropTypes from 'prop-types';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PsychologicalGroupsContainer from './PsychologicalGroupsContainer';

const PsychologicalGroupsPage = ({ location }) => {
  return (
    <LoadingRenderWrapper>
      <PsychologicalGroupsContainer location={location} />
    </LoadingRenderWrapper>
  );
};

PsychologicalGroupsPage.alerpropTypes = {
  location: PropTypes.object.isRequired
};

export default PsychologicalGroupsPage;
