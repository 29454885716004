import BaseModel from './BaseModel';
import moment from 'moment';

export class ArchivedByModel extends BaseModel {
  constructor() {
    super();
    this.login = '';
    this.userId = '';
    this.userRole = '';
    this.userFullName = '';
  }
}

export class ArchivalInfoModel extends BaseModel {
  constructor() {
    super();
    this.archivedBy = new ArchivedByModel();
    this.archivedOn = undefined;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.archivedBy = new ArchivedByModel().assign(assigned.archivedBy);
    assigned.archivedOn = moment(assigned.archivedOn).format('DD.MM.YYYY');
  }
}
