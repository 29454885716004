import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../../forms/DialogForm';
import SettlementsSettingsForm from '../forms/SettlementsSettingsForm';
import { SettlementsSettingsModel } from '../../../../models/configuration/settlementsSettings/SettlementsSettingsModel';

class SettlementsSettingsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settlementsSettings: new SettlementsSettingsModel().assign(this.props.settlementsSettings),
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);

    this.handleIntegrationInputChange = this.handleIntegrationInputChange.bind(this);
    this.handleIntegrationAccountsInputChange = this.handleIntegrationAccountsInputChange.bind(this);
  }

  handleChange(event) {
    const field = event.target.name;
    const settlementsSettings = Object.assign({}, this.state.settlementsSettings);
    settlementsSettings[field] = event.target.value;
    return this.setState({ settlementsSettings });
  }

  handleIntegrationInputChange(event) {
    const field = event.target.name;
    const settlementsSettings = new SettlementsSettingsModel().assign(this.state.settlementsSettings);
    settlementsSettings.integration[field] = event.target.value;
    return this.setState({ settlementsSettings });
  }

  handleIntegrationAccountsInputChange(event) {
    const field = event.target.name;
    const settlementsSettings = new SettlementsSettingsModel().assign(this.state.settlementsSettings);
    settlementsSettings.integration.accounts[field] = event.target.value;
    return this.setState({ settlementsSettings });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    return (
      <DialogForm
        header="Zmiana ustawień rozliczeń"
        onSave={() => onSave(this.state.settlementsSettings)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="configuration.settlementsSettingsUi">
        <SettlementsSettingsForm
          settlementsSettings={this.state.settlementsSettings}
          numberingSchemes={this.props.numberingSchemes}
          onChange={this.handleChange}
          onIntegrationInputChange={this.handleIntegrationInputChange}
          onIntegrationAccountsInputChange={this.handleIntegrationAccountsInputChange}
          errors={this.state.errors}
        />
      </DialogForm>
    );
  }
}

SettlementsSettingsDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  settlementsSettings: PropTypes.object.isRequired,
  numberingSchemes: PropTypes.array.isRequired
};

export default SettlementsSettingsDialog;
