// import * as types from '../actions/actionTypes';
// import {UiModel} from '../models/UiModel';
import initialState from './initial';

export default function exportChildrenReducer(state = initialState.configuration.exportChildren, action) {
  switch (action.type) {
    default:
      return state;
  }
}
