import PropTypes from 'prop-types';
import React from 'react';
import MenuRoute from './MenuRoute';
import moment from 'moment';
import { routePaths } from '../../../routePaths';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import UnreadThreadsBadgeWrapper from '../../common/privateMessages/UnreadThreadsBadgeWrapper';
import { useSelector } from 'react-redux';
import ImportChildrenDialog from '../../configuration/importChildren/ImportChildrenDialog';
import ExportChildrenDialog from '../../configuration/exportChildren/ExportChildrenDialog';
import config from '../../../config/config';

import ManualIcon from '@mui/icons-material/DescriptionOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import GroupIcon from '@mui/icons-material/GroupsOutlined';
import ChildrenIcon from '@mui/icons-material/FormatListNumberedOutlined';
import AssignmentIndIcon from '@mui/icons-material/PermContactCalendarOutlined';
import MessageBoardIcon from '@mui/icons-material/SmsFailedOutlined';
import EventsCalendarIcon from '@mui/icons-material/Event';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import UnitIcon from '@mui/icons-material/Business';
import ReportsIcon from '@mui/icons-material/InsertChartOutlined';
import PrivateMessagesIcon from '@mui/icons-material/EmailOutlined';
import PrivateMessagesSentIcon from '@mui/icons-material/SendOutlined';
import ImportIcon from '@mui/icons-material/CloudUpload';
import ExportIcon from '@mui/icons-material/CloudDownload';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import LoginIcon from '@mui/icons-material/VpnKey';
import CurrentUsersIcon from '@mui/icons-material/People';
import PhotoLibraryIcon from '@mui/icons-material/CollectionsOutlined';
import StorageIcon from '@mui/icons-material/SaveOutlined';

const ReducedPrincipalMenu = ({ currentRoute, onRouteSelect, importChildren, exportChildren, exportUI }) => {
  const unit = useSelector((state) => state.configuration.unit);
  const year = moment().format('YYYY');
  const month = moment().format('MM');
  let constKey = 0;
  const getNextKey = () => constKey++;
  const rest = { selectedRoutePath: currentRoute, onClick: onRouteSelect };
  const unreadPostsCount = useSelector((state) => state.staffMessageBoard.unreadedPosts).length;
  const theme = useTheme();
  const isContrastColor = useSelector((state) => state.theme);

  return (
    <List>
      <MenuRoute
        text="Pulpit"
        icon={
          <HomeIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
          />
        }
        routePath={routePaths.home}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        primaryText={<UnreadThreadsBadgeWrapper text="Wiadomości" sx={{ mr: 4 }} />}
        icon={
          <PrivateMessagesIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color3 }}
          />
        }
        primaryTogglesNestedList={true}
        nestedItems={[
          <MenuRoute
            text="Wszystkie"
            icon={
              <PrivateMessagesIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color3 }}
              />
            }
            routePath={routePaths.privateMessages}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Moje rozmowy"
            icon={
              <PrivateMessagesSentIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color3 }}
              />
            }
            routePath={routePaths.staffMemberPrivateMessages}
            key={getNextKey()}
            {...rest}
          />
        ]}
      />
      <MenuRoute
        text="Galeria"
        icon={
          <PhotoLibraryIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color6 }}
          />
        }
        routePath={routePaths.gallery}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Ogłoszenia"
        icon={
          <MessageBoardIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color4 }}
          />
        }
        routePath={routePaths.messageBoard}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Kalendarz wydarzeń"
        icon={
          <EventsCalendarIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color5 }}
          />
        }
        routePath={routePaths.eventsCalendar}
        routeParams={[year, month]}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Dysk wirtualny"
        icon={
          <StorageIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color21 }}
          />
        }
        routePath={routePaths.virtualDisc}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Rejestr uczniów"
        icon={
          <ChildrenIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color7 }}
          />
        }
        routePath={routePaths.student}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        primaryText="Wewnętrzne"
        icon={
          <AssignmentIndIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
          />
        }
        primaryTogglesNestedList={true}
        badgeValue={unreadPostsCount}
        nestedItems={[
          <MenuRoute
            text="Ogłoszenia"
            icon={
              <MessageBoardIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
              />
            }
            badgeValue={unreadPostsCount}
            routePath={routePaths.staffMessageBoard}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Kalendarz"
            icon={
              <EventsCalendarIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
              />
            }
            routePath={routePaths.staffCalendar}
            routeParams={[year, month]}
            key={getNextKey()}
            {...rest}
          />
        ]}
      />
      <MenuRoute
        text="Grupy"
        icon={
          <GroupIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color8 }}
          />
        }
        routePath={routePaths.groups}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Raporty"
        icon={
          <ReportsIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color5 }}
          />
        }
        routePath={routePaths.reports}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        primaryText="Konfiguracja"
        icon={
          <SettingsIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
          />
        }
        primaryTogglesNestedList={true}
        nestedItems={[
          <MenuRoute
            text="Placówka"
            icon={
              <UnitIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
              />
            }
            routePath={routePaths.configurationUnit}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Pracownicy"
            icon={
              <AssignmentIndIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
              />
            }
            routePath={routePaths.staffMembers}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Import uczniów"
            icon={
              <ImportIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color5 }}
              />
            }
            button
            onMouseDown={importChildren}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Export uczniów"
            icon={
              <ExportIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color5 }}
              />
            }
            button
            onMouseDown={exportChildren}
            key={getNextKey()}
            {...rest}
          />,
          unit.features && unit.features.usersSignInRegister && (
            <MenuRoute
              text="Historia logowań"
              icon={
                <LoginIcon
                  fontSize="medium"
                  sx={{
                    color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color25
                  }}
                />
              }
              routePath={routePaths.loginHistory}
              key={getNextKey()}
              {...rest}
            />
          ),
          <MenuRoute
            text="Rejestr zdarzeń"
            icon={
              <EventOutlinedIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color25 }}
              />
            }
            routePath={routePaths.events}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Zalogowani użytkownicy"
            icon={
              <CurrentUsersIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color25 }}
              />
            }
            routePath={routePaths.currentUsers}
            key={getNextKey()}
            {...rest}
          />
        ]}
      />
      <Divider />
      <ListItem key={getNextKey()}>
        <ListItemButton onClick={() => window.open(config.staffManualReducedUrl, '_blank')}>
          <ListItemIcon>
            <ManualIcon
              fontSize="medium"
              sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
            />
          </ListItemIcon>
          <ListItemText
            sx={{ '& .MuiListItemText-primary': { fontWeight: (theme) => theme.typography.fontWeightBold } }}
            primary="Instrukcja obsługi"
          />
        </ListItemButton>
      </ListItem>
      <ImportChildrenDialog />
      {exportUI.isEditing && <ExportChildrenDialog />}
    </List>
  );
};

ReducedPrincipalMenu.propTypes = {
  currentRoute: PropTypes.string.isRequired,
  onRouteSelect: PropTypes.func.isRequired,
  importChildren: PropTypes.func.isRequired,
  exportChildren: PropTypes.func.isRequired,
  exportUI: PropTypes.object.isRequired
};

export default ReducedPrincipalMenu;
