import PropTypes from 'prop-types';
import { Grid, MenuItem, Button } from '@mui/material';
import React from 'react';
import FilterBar from '../../../common/FilterBar';
import SelectField from '../../../common/SelectField';
import { useSelector } from 'react-redux';

const Header = ({ itemsToFilter, onFilter, rowsPerPage, onRowsPerPageChange, isAllowed, redirectToCreatePage }) => {
  const numberArrayOfOptions = [5, 10, 25, 50];
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);

  return (
    <Grid container sx={{ pb: 2 }}>
      <Grid item xs={12} md={6}>
        <FilterBar
          sx={{ py: 3 }}
          itemsToFilter={itemsToFilter}
          onFilter={onFilter}
          filter={(child, keyword) =>
            child.lastName.toLowerCase().includes(keyword) ||
            child.firstName.toLowerCase().includes(keyword) ||
            child.personalId.toLowerCase().includes(keyword) ||
            (child.firstName.toLowerCase() + ' ' + child.lastName.toLowerCase()).includes(keyword) ||
            (child.lastName.toLowerCase() + ' ' + child.firstName.toLowerCase()).includes(keyword)
          }
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', py: '7px' }}>
        <SelectField
          sx={{
            minWidth: 88
          }}
          id="rowsPerPage"
          value={rowsPerPage}
          onChange={onRowsPerPageChange}
          floatingLabelText="Pozycji na stronę">
          {numberArrayOfOptions.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </SelectField>

        {isAllowed && (
          <Button
            sx={{ ml: 2, py: 'auto' }}
            label={nadarzyn ? 'Dodaj ucznia' : 'Dodaj dziecko'}
            variant="contained"
            aria-label={nadarzyn ? 'Dodaj ucznia' : 'Dodaj dziecko'}
            onClick={redirectToCreatePage}>
            {nadarzyn ? 'Dodaj ucznia' : 'Dodaj dziecko'}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  itemsToFilter: PropTypes.array,
  onFilter: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  isAllowed: PropTypes.bool,
  redirectToCreatePage: PropTypes.func
};

export default Header;
