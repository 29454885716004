import { ValidationModel } from './validation/ValidationModel';
import { validate } from './validation/validateHelper';

export class BaseModelValidator {
  constructor() {
    this.constraints = {};
    this.model = null;
  }

  validate(model) {
    this.verifyModel(model, this.model);
    return new ValidationModel(validate(model, this.constraints));
  }

  verifyModel(model, type) {
    if (type && !(model instanceof type)) {
      /* eslint no-console: 'off' */
      console.warn(`${this.constructor.name} - injected model is not instance of: ${type}`);
    }
  }
}
