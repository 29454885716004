import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const FormikDisabler = ({ disabled }) => {
  const { setStatus } = useFormikContext();
  useEffect(() => {
    if (disabled) {
      setStatus({ disabled: true });
    } else {
      setStatus({ disabled: false });
    }
  }, [disabled, setStatus]);
  return null;
};

export default FormikDisabler;
