import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../../../common/TextField';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import { Stack } from '@mui/material';

const UnitAddressForm = ({ address, onChange, errors }) => {
  return (
    <Stack>
      <FieldsetWrapper legend="Dane placówki">
        <TextField
          hintText="Nazwa placówki"
          floatingLabelText="Nazwa placówki*"
          floatingLabelFixed={false}
          autoFocus={true}
          name="name"
          value={address.name}
          onChange={onChange}
          errorText={errors.name}
          fullWidth={true}
        />
        <TextField
          hintText="Ulica oraz numer"
          floatingLabelText="Adres*"
          floatingLabelFixed={false}
          autocomplete="street-address"
          name="address"
          value={address.address}
          onChange={onChange}
          errorText={errors.address}
          fullWidth={true}
        />
        <TextField
          hintText="Kod pocztowy w formacie 00-000"
          floatingLabelText="Kod pocztowy*"
          floatingLabelFixed={false}
          name="zipCode"
          value={address.zipCode}
          onChange={onChange}
          errorText={errors.zipCode}
          autocomplete="postal-code"
        />
        <TextField
          hintText="Miejscowość"
          floatingLabelText="Miejscowość*"
          floatingLabelFixed={false}
          autocomplete="address-level2"
          name="city"
          value={address.city}
          onChange={onChange}
          errorText={errors.city}
          fullWidth={true}
        />
        <TextField
          hintText="Województwo"
          floatingLabelText="Województwo"
          floatingLabelFixed={false}
          name="voivodeship"
          value={address.voivodeship}
          onChange={onChange}
          errorText={errors.voivodeship}
          fullWidth={true}
        />
        <TextField
          hintText="Gmina"
          floatingLabelText="Gmina"
          floatingLabelFixed={false}
          name="community"
          value={address.community}
          onChange={onChange}
          errorText={errors.community}
          fullWidth={true}
        />
        <TextField
          hintText="Numer NIP (opcjonalne)"
          floatingLabelText="Numer NIP"
          floatingLabelFixed={false}
          name="nip"
          value={address.nip}
          onChange={onChange}
          errorText={errors.nip}
        />
        <TextField
          hintText="Numer telefonu (opcjonalne)"
          floatingLabelText="Numer telefonu"
          floatingLabelFixed={false}
          autocomplete="tel"
          name="phoneNumber"
          value={address.phoneNumber}
          onChange={onChange}
          errorText={errors.phoneNumber}
        />
        <TextField
          hintText="Numer identyfikacyjny placówki (opcjonalne)"
          floatingLabelText="Numer identyfikacyjny placówki"
          floatingLabelFixed={false}
          autocomplete="facility-number"
          name="facilityNumber"
          value={address.facilityNumber}
          onChange={onChange}
          errorText={errors.facilityNumber}
        />
      </FieldsetWrapper>
    </Stack>
  );
};

UnitAddressForm.propTypes = {
  address: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default UnitAddressForm;
