import PropTypes from 'prop-types';
import React from 'react';
import GroupTeachersList from './GroupTeachersList';
import CardDialogEditForm from '../../forms/CardDialogEditForm';
import EmptyState from '../../common/EmptyState';
import GroupTeachersEditListv2 from './GroupTeachersEditListv2';

class GroupTeacherCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      errors: null
    };
  }

  getAvailableTeachers() {
    return this.props.teachers.filter(
      (teacher) => !this.props.group.associatedTeachers.some((at) => at.id === teacher.id)
    );
  }

  renderEditForm() {
    return (
      <GroupTeachersEditListv2
        allTeachers={this.getAvailableTeachers()}
        associatedTeachersIds={this.props.group.associatedTeachers.map((e) => e.id)}
        onChange={this.props.onChange}
      />
    );
  }

  renderReadonlyForm() {
    return <GroupTeachersList teachers={this.props.group.associatedTeachers} onRemoveTeacher={this.props.onRemove} />;
  }

  render() {
    return (
      <CardDialogEditForm
        title="Nauczyciele"
        subtitle={`${this.props.group.associatedTeachers ? this.props.group.associatedTeachers.length : 0}`}
        isInitiallyExpanded={true}
        onSave={() => this.props.onSave(this.props.group.selectedTeacher.id)}
        onCancel={this.props.onCancel}
        onAction={this.props.onEdit}
        onValidationDone={(errors) => this.setState({ errors })}
        isDialogOpen={this.props.isEditing}
        isProcessing={this.props.isProcessing}
        statePathToUi="groupDetailsUi.associatedTeachers" //that's the tricky thing and a thing to check ;)
        readonlyForm={this.renderReadonlyForm()}
        editForm={this.renderEditForm()}
        isEmpty={this.props.group.associatedTeachers.length < 1}
        emptyForm={<EmptyState contrast message="Brak nauczycieli" />}
        saveDisabled={this.getAvailableTeachers() === 0 || !this.props.group.selectedTeacher}
        actionTitle="Przypisz nauczyciela"
        actionLabel="Przypisz nauczyciela"
      />
    );
  }
}

GroupTeacherCard.propTypes = {
  group: PropTypes.object.isRequired,
  teachers: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isProcessing: PropTypes.bool
};

export default GroupTeacherCard;
