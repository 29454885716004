import PropTypes from 'prop-types';
import React from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import PersonAvatar from '../forms/PersonAvatar';
import EmptyState from '../common/EmptyState';

const ChildArchiveLegalGuardiansList = ({ guardians }) => {
  return (
    <>
      {guardians.length > 0 ? (
        <List>
          {guardians.map((guardian) => {
            return (
              <ListItem key={guardian.id}>
                <PersonAvatar
                  initials
                  alt={guardian.firstName}
                  url={guardian.photoUrl}
                  firstName={guardian.firstName}
                  lastName={guardian.lastName}
                  sx={{ mr: 2 }}
                />
                <ListItemText
                  disableTypography
                  primary={
                    <Box sx={{ display: 'flex' }}>
                      <Typography
                        sx={{
                          mr: 1,
                          fontWeight: (theme) => theme.typography.fontWeightBold
                        }}>
                        {guardian.firstName} {guardian.lastName}
                      </Typography>
                      {guardian.relationship && <Typography>({guardian.relationship})</Typography>}
                    </Box>
                  }
                  secondary={[
                    <Box key="1">
                      <Typography
                        sx={{
                          fontWeight: (theme) => theme.typography.fontWeightBold
                        }}>
                        {guardian.email}
                      </Typography>
                    </Box>,
                    <Box sx={{ display: 'flex' }} key="2">
                      <Typography sx={{ mr: 1 }}>Numer dokumentu</Typography>
                      <Typography
                        sx={{
                          mr: 2,
                          fontWeight: (theme) => theme.typography.fontWeightBold
                        }}>
                        {guardian.documentNumber}
                      </Typography>
                      <Typography sx={{ mx: 1 }}> Numer telefonu </Typography>
                      <Typography
                        sx={{
                          mr: 2,
                          fontWeight: (theme) => theme.typography.fontWeightBold
                        }}>
                        {guardian.phoneNumber || 'brak'}
                      </Typography>
                    </Box>
                  ]}
                  disabled={true}
                />
              </ListItem>
            );
          })}
        </List>
      ) : (
        <EmptyState message="Dla dziecka nie zostali przypisani opierunowie prawni" contrast />
      )}
    </>
  );
};

ChildArchiveLegalGuardiansList.propTypes = {
  guardians: PropTypes.array.isRequired
};

export default ChildArchiveLegalGuardiansList;
