import React from 'react';
import PropTypes from 'prop-types';
import GetAppIcon from '@mui/icons-material/GetApp';
import * as Status from '../../../../../../constants/status';
import { removeRightBottomNotification } from '../../../../../../actions/notificationActions';
import { useDispatch } from 'react-redux';
import { AlertMessage } from '../AlertMessage';
import { CircularProgress, Grid, ListItem, Typography } from '@mui/material';

const Download = ({ uuid, data }) => {
  const { status } = data;
  const dispatch = useDispatch();

  const handleRemove = () => {
    dispatch(removeRightBottomNotification(uuid));
  };

  const statusInfo = () => {
    switch (status) {
      case Status.SUCCESS:
        return {
          messageShort: 'Pomyślnie pobrano multimedia!',
          severity: 'success'
        };
      case Status.ERROR:
        return {
          messageShort: 'Bład przy pobieraniu multimediów!',
          severity: 'error'
        };
      default:
        return null;
    }
  };

  return (
    <ListItem>
      {status === Status.PENDING && (
        <Grid container sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>
          <GetAppIcon sx={{ mr: 1 }} />
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>Pobieranie plików</Typography>
          <CircularProgress size={20} sx={{ ml: 1 }} />
        </Grid>
      )}
      {(status === Status.SUCCESS || status === Status.ERROR) && (
        <AlertMessage data={statusInfo()} isInfo={false} handleRemove={handleRemove} style={{ width: '100%' }} />
      )}
    </ListItem>
  );
};

Download.propTypes = {
  uuid: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
};

export default Download;
