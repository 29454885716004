import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SaveCancelButtons from './buttons/SaveCancelButtons';
import RichTextEditor from './richTextEditor/RichTextEditor';
import ActionButton from './buttons/ActionButton';
import { CoursebookBoardPostModelValidator } from '../../models/messageBoard/MessageBoardModelsValidator';

import { useSelector } from 'react-redux';
import { ProgramPoints as allProgramPoints } from '../../utils/ProgramPointsList';
import { uniqueId } from 'lodash';
import PublisherApi from '../../api/PublisherApi';
import SelectField from '../common/SelectField';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  TextField
} from '@mui/material';

const PublisherDialogForm = ({ open, onCancel, coursebookSetMethods }) => {
  const [coursebooks, setCoursebooks] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [book, setBook] = useState({});
  const [coursebook, setCoursebook] = useState('');
  const [thematicCicle, setThematicCicle] = useState('');
  const [title, setTitle] = useState('');
  const [note, setNote] = useState('');
  const [learningMaterials, setLearningMaterials] = useState('');
  const [programPoints, setProgramPoints] = useState([]);

  const [errors, setErrors] = useState({});
  const [showProgramPoints, setShowProgramPoints] = useState(false);
  const [wasSubmitActivated, setWasSubmitActivated] = useState(false);
  const defaultPublisherId = useSelector((state) => state.configuration.unit.defaultPublishingHouseId);
  const [refresh, setRefresh] = useState(uniqueId());
  const [publishers, setPublishers] = useState([]);
  const [anotherPublisher, setAnotherPublisher] = useState(false);
  const [anotherPublisherId, setAnotherPublisherId] = useState('');
  const [anotherPublisherName, setAnotherPublisherName] = useState('');

  const options = allProgramPoints
    .map((pp) => pp.values)
    .flat()
    .map((programPoint) => {
      const group = programPoint.split('.')[0];
      const programPointValue = programPoint.split(' - ')[0];
      return {
        group,
        programPointValue,
        programPoint
      };
    });

  const styles = {
    width: '100%',
    marginBottom: '1.5rem'
  };

  const removeNextValues = (value) => {
    switch (value) {
      case 'courseBook':
        setThematicCicle('');
      // eslint-disable-next-line no-fallthrough
      case 'thematicCicle':
        setTitle('');
        setSubjects([]);
        setNote('');
        setRefresh(uniqueId());
        setLearningMaterials('');
        setProgramPoints([]);
      // eslint-disable-next-line no-fallthrough
      default:
    }
  };

  const onValidate = () =>
    new CoursebookBoardPostModelValidator().validate({
      coursebook,
      thematicCicle,
      title,
      note,
      learningMaterials,
      programPoints
    });

  useEffect(() => {
    if (wasSubmitActivated) setErrors(onValidate().errors);
    else setErrors({});
    setWasSubmitActivated(false);
  }, [coursebook, thematicCicle, title, note, learningMaterials, programPoints]);

  const changeVisibilityOfProgramPoints = () => {
    setShowProgramPoints((prevState) => !prevState);
  };

  useEffect(() => {
    PublisherApi.getPublishers().then((response) => setPublishers(response));
  }, [anotherPublisher]);

  useEffect(() => {
    if (!anotherPublisher) {
      PublisherApi.getCourseBookByPublisherId(defaultPublisherId).then((response) => setCoursebooks(response.books));
    } else {
      PublisherApi.getCourseBookByPublisherId(anotherPublisherId).then((response) => setCoursebooks(response.books));
    }
  }, [defaultPublisherId, anotherPublisherId]);

  useEffect(() => {
    removeNextValues('courseBook');
    if (!coursebook) return;
    PublisherApi.getBookdetailsById(coursebooks.filter((x) => x.title === coursebook)[0].id).then((response) =>
      setBook(response)
    );
  }, [coursebook]);

  const handleChangethematicCicle = (event) => {
    removeNextValues('thematicCicle');
    setThematicCicle(event.target.value);
    const tc = book.subjects.filter((s) => s.name === event.target.value);
    setSubjects(tc ? tc[0].topics : []);
  };
  const handleProgramPointsChange = (event, value) => {
    setProgramPoints(value);
  };

  const handleTitleChange = async (event) => {
    setTitle(event.target.value);
    const thematicDescription = subjects.filter((subject) => subject.topicOfTheDay === event.target.value)[0];
    setNote(`<p>${thematicDescription.description}</p>`);
    setRefresh(uniqueId());
    setLearningMaterials(thematicDescription.teachingMaterials);
    const coreCurriculumRequirementsTable = thematicDescription.coreCurriculumRequirements
      .replaceAll(';', ',')
      .split(', ');
    setProgramPoints(options.filter((op) => coreCurriculumRequirementsTable.includes(op.programPointValue)));
  };
  const handleSave = () => {
    if (onValidate().isValid) {
      coursebookSetMethods(title, learningMaterials, programPoints, note);
      onCancel();
    } else {
      setErrors(onValidate().errors);
      setWasSubmitActivated(true);
    }
  };

  const handleAnotherPublisher = () => {
    setAnotherPublisher(true);
  };

  const handleAnotherPublisherConfirmation = (name) => {
    setAnotherPublisherName(name);
    const publisher = publishers.filter((pub) => pub.name === name);
    setAnotherPublisherId(publisher[0].id);
  };

  return (
    <Dialog open={open} fullWidth={true} style={{ fontFamily: "Roboto, 'sans-serif'" }} maxWidth="md">
      <DialogTitle>
        <Box
          sx={{ display: 'flex', justifyContent: { sm: 'space-between' }, flexDirection: { xs: 'column', sm: 'row' } }}>
          Wybierz temat z podręcznika
          <ActionButton actionLabel="Zmień wydawnictwo" onAction={handleAnotherPublisher} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {anotherPublisher ? (
          <>
            <SelectField
              contrast
              sx={{ mt: 2 }}
              floatingLabelText="Wydawnictwo*"
              onChange={(event) => handleAnotherPublisherConfirmation(event.target.value)}
              value={anotherPublisherName}
              style={styles}>
              {publishers.map((publisher) => (
                <MenuItem key={uniqueId()} value={publisher.name}>
                  {publisher.name}
                </MenuItem>
              ))}
            </SelectField>
          </>
        ) : null}
        <SelectField
          contrast
          value={coursebook}
          floatingLabelText="Podręcznik*"
          onChange={(event) => setCoursebook(event.target.value)}
          errorText={errors.coursebook}
          style={styles}>
          {coursebooks.map((c) => (
            <MenuItem key={uniqueId()} value={c.title}>
              {c.title}
            </MenuItem>
          ))}
        </SelectField>
        <SelectField
          contrast
          value={thematicCicle}
          floatingLabelText="Krąg tematyczny*"
          onChange={handleChangethematicCicle}
          style={styles}
          errorText={coursebook !== '' && errors.thematicCicle ? errors.thematicCicle : ''}
          disabled={coursebook === ''}>
          {book.subjects
            ? book.subjects.map((s) => (
                <MenuItem key={uniqueId()} value={s.name}>
                  {s.name}
                </MenuItem>
              ))
            : []}
          {/* {renderSubjects(book)} */}
        </SelectField>
        <SelectField
          contrast
          value={title}
          floatingLabelText="Temat zajęć*"
          onChange={handleTitleChange}
          style={styles}
          errorText={thematicCicle !== '' && errors.title ? errors.title : ''}
          disabled={thematicCicle === ''}>
          {subjects.length !== 0
            ? subjects.map((subject) => (
                <MenuItem value={subject.topicOfTheDay} key={uniqueId()}>
                  {subject.topicOfTheDay}
                </MenuItem>
              ))
            : []}
        </SelectField>
        <FormControl
          error={title !== '' && !!errors && !!errors.note}
          style={{
            width: '100%',
            border: title !== '' && !!errors && !!errors.note ? '1px solid red' : null
          }}>
          <RichTextEditor key={refresh} initialText={note} onChange={(htmlContent) => setNote(htmlContent)} />
          <FormHelperText error>{title !== '' ? errors.note : ''}</FormHelperText>
        </FormControl>
        <TextField
          variant="standard"
          label="Materiały dydaktyczne"
          value={learningMaterials}
          onChange={(e) => setLearningMaterials(e.target.value)}
          error={title !== '' && errors.learningMaterials ? !!errors.learningMaterials : false}
          helperText={title !== '' && errors.learningMaterials ? errors.learningMaterials : ''}
          sx={(styles, { color: (theme) => theme.palette.color.contrast, width: '100%' })}
          disabled={title === ''}
        />
        <Autocomplete
          id="program-points-autocomplete"
          sx={{ width: '100%', my: 1 }}
          multiple
          disabled={title === ''}
          disableCloseOnSelect
          value={programPoints}
          options={options.filter(
            (option) => !programPoints.map((v) => v.programPointValue).some((v) => v === option.programPointValue)
          )}
          getOptionLabel={(option) => option.programPoint}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option.programPointValue} {...getTagProps({ index })} />
            ))
          }
          groupBy={(option) =>
            `${option.group} - ${allProgramPoints.filter((pp) => pp.key === option.group)[0]?.title}`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              error={title !== '' && !!errors.programPoints}
              helperText={errors.programPoints}
              label="Podstawa programowa"
              variant="standard"
            />
          )}
          onChange={handleProgramPointsChange}
        />
        <Button
          aria-label={`${showProgramPoints ? 'Ukryj' : 'Pokaż'} opis wybranych punktów podstawy programowej`}
          variant="clearText"
          sx={{ p: '.25rem 0' }}
          onClick={changeVisibilityOfProgramPoints}>{`${
          showProgramPoints ? 'Ukryj' : 'Pokaż'
        } opis wybranych punktów podstawy programowej`}</Button>
        {showProgramPoints && programPoints.map((pp) => <p key={uniqueId()}>{pp.programPoint}</p>)}
      </DialogContent>
      <DialogActions>
        <SaveCancelButtons onCancel={() => onCancel()} onSave={() => handleSave()} />
      </DialogActions>
    </Dialog>
  );
};

PublisherDialogForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  coursebookSetMethods: PropTypes.func.isRequired,
  noValidate: PropTypes.bool
};

PublisherDialogForm.defaultProps = {
  noValidate: false
};

export default PublisherDialogForm;
