import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Select } from '@mui/material';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import SingleMemberChip from '../../gallery/Components/Albums/SingleMemberChip';
import SpecialButton from '../../groupsAndActivities/SpecialButton';
import FormHelperText from '@mui/material/FormHelperText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';

const SingleSelectWithButton = ({
  children,
  fullWidth,
  selectStyle,
  selectClassName,
  type,
  options,
  buttonStyles,
  specialMenuOptions,
  specialOptions,
  defaultLabel,
  multiple = false,
  onChange,
  value,
  error,
  errorText,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  let isAllSelected = null;
  let selectedData = null;
  let filteredData = null;
  if (multiple) {
    selectedData = options.filter((item) => value.includes(item.id));
    filteredData = options.filter((x) => !selectedData.some((y) => x.id === y.id));
    isAllSelected = filteredData.length === 0;
  }

  const handleChange = (e) => {
    const values = e.target.value;
    if (multiple) {
      if (values.includes('all')) {
        onChange(options.map((item) => item.id));
      } else if (values.includes('deleteAll')) {
        onChange([]);
      } else if (specialOptions && specialOptions.length) {
        const tempValues = [...values];
        const lastValue = tempValues.pop();
        if (specialOptions.some((option) => option.id === lastValue.id)) {
          onChange([lastValue]);
        } else {
          onChange(values.filter((insideValue) => !specialOptions.some((option) => option.id === insideValue)));
        }
      } else {
        onChange(values);
      }
    } else {
      onChange(values);
    }
  };

  const selectConfig = {
    value,
    onChange: handleChange,
    onClose: () => setOpen(false),
    open,
    style: { display: 'none', ...selectStyle },
    className: selectClassName,
    input: <Input id="select-multiple-checkbox" />,
    MenuProps: {
      anchorEl: document.getElementById('openMenu')
    },
    multiple,
    ...rest
  };

  const formControlConfig = {
    style: { minWidth: `${fullWidth ? '100%' : '200px'}`, marginRight: '5px' },
    error
  };

  return (
    <FormControl {...formControlConfig}>
      <SpecialButton
        id="openMenu"
        data={
          multiple
            ? [...options, ...(specialOptions || [])].filter((item) => value.includes(item.id))
            : [...options, ...(specialOptions || [])].find((item) => item.id === value)
        }
        type={type}
        onClick={() => {
          setOpen(!open);
        }}
        multiple={multiple}
        style={buttonStyles}
        isError={error}
        defaultLabel={defaultLabel}
      />
      <Select {...selectConfig}>
        {[
          ...(specialMenuOptions || []),
          multiple && (
            <MenuItem key={0} value={isAllSelected ? 'deleteAll' : 'all'}>
              <ListItemIcon>
                <Checkbox checked={isAllSelected} indeterminate={selectedData.length > 0 && !isAllSelected} />
              </ListItemIcon>
              <ListItemText primary={isAllSelected ? 'Odznacz wszystkie' : 'Wybierz wszystkie'} />
            </MenuItem>
          ),
          options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {multiple && (
                <ListItemIcon>
                  <Checkbox checked={selectedData.some((item) => item.id === option.id)} />
                </ListItemIcon>
              )}
              <ListItemText
                disableTypography
                primary={
                  <SingleMemberChip
                    type={type}
                    name={option.name}
                    color={option.color}
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => e.stopPropagation()}
                  />
                }
              />
            </MenuItem>
          ))
        ]}
      </Select>
      {error && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};

SingleSelectWithButton.propTypes = {
  buttonStyles: PropTypes.any,
  children: PropTypes.any,
  defaultLabel: PropTypes.any,
  fullWidth: PropTypes.bool,
  multiple: PropTypes.bool,
  options: PropTypes.array,
  selectClassName: PropTypes.string,
  selectStyle: PropTypes.object,
  specialMenuOptions: PropTypes.array,
  specialOptions: PropTypes.array,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string
};

export default SingleSelectWithButton;
