import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import { Box, List, ListItem, Paper, Typography } from '@mui/material';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import userRoles from '../../constants/userRoles';
import { bindActionCreators } from 'redux';
import * as importChildrenActions from '../../actions/importChildrenActions';
import * as exportChildrenActions from '../../actions/exportChildrenActions';
import InProgressIcon from '@mui/icons-material/EngineeringOutlined';
import Strong from '../common/Strong';
import ContentWrapper from '../common/contentWrapper';
import Masonry from '@mui/lab/Masonry';

const releases = [
  {
    date: moment('2024-10-18').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Nowy moduł: Indywidualne dzienniki zajęć specjalistycznych a w nim:',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Moduł obecności indywidualnych w każdej grupie/zajęciach specjalistycznych',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Moduł obecności indywidualnych w rejestrze dzieci jako kalendarz "Obecności na zajęciach specjalistycznych"',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Możliwość kliknięcia w dziecko w indywidualnym dzienniku zajęć specjalistycznych co przekieruje Państwa na dodatkowe widoki jak Indywidualny program pracu z uczniem per zajęcia, Notatki oraz Obserwacje',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Możliwość edycji pomocy psychologiczno-pedagogicznej w dzienniku specjalistycznym',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Możliwość edycji i archiwizacji grup w dzienniku specjalistycznym',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Poprawki i usprawnienia działania systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodanie odnośnika w menu do naszego bloga na którego serdecznie Państwa zapraszamy',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodanie opcji "Brak" w podstawie programowej w zapisie pracy w dzienniku elektronicznym',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodanie nowej opcji generowania raportu Zestawienie zaległości za miesiąc -> "Pokaż kwoty poszczególnych typów zaległości, zawiera dzieci zarchiwizowane"',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Umożliwienie uzupełniania obecności/nieobecności z dziennika specjalistycznego przez rejestr dzieci przez specjalistów',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ],
    works: []
  },
  {
    date: moment('2024-09-09').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Nowy moduł: Roczny ramowy plan dnia w dzienniku elektronicznym',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Moduł umożliwia Państwu wprowadzanie wpisów rocznego planu dnia dla każdej z grup wraz z tytułem, opisem oraz obowiązującymi godzinami',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Możliwość pobrania całego folderu w dysku wirtualnym',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Poprawki w działaniu dysku wirtualnego w aplikacji mobilnej i systemie WEB',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Możliwość odświeżenia pulpitu przyciskiem odśwież, który zaktualizuje dane obecności na pulpicie bez potrzeby odświeżania całego systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowa funkcja: Możliwość dodania domyślnego terminu płatności w ustawieniach rozliczeń na ustalony przez Państwa dzień każdego miesiąca',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Rozszerzenie widoku Osób upoważnionych do odbioru o numer identyfikacyjny',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Rozszerzenie funkcjonalności widoku Zaległych opłat o pogrupowanie zaległych opłat per dzieci',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Poprawki w działaniu raportów i systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodatkowe zabezpieczenia systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ],
    works: []
  },
  {
    date: moment('2024-08-11').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Aktualizacja silnika systemu, która powinna przełożyć się na zauważalnie większą wydajność i szybkość działania 🎉',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Możliwość wysyłania ogłoszeń zbiorczo z systemu 4pmanagement do wielu placówek (przypominamy, jeżeli mają Państwo wiele placówek, prosimy o kontakt z działem sprzedaży poprzez biuro@softwarehub.pl w celu prezentacji możliwości systemu 4pmanagement)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowa funkcjonalność w systemie: Foldery w dysku wirtualnym 🎉   Moduł umożliwia:',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodawanie folderów/podfolderów w dysku wirtualnym',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Przenoszenie plików oraz folderów do innych folderów za pomocą drag&drop, dowolnie je zagnieżdżając',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Udostępnianie folderów dla całych grup / ról użytkowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Obsługę przechodzenia po folderach w module wiadomości aby udostępnić wybrany plik',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Obsługę przechodzenia po folderach w aplikacji mobilnej',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ],
    works: []
  },
  {
    date: moment('2024-06-28').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Nowy moduł - rozliczenie końcowe dziecka. W zakładce rozliczenia pojawiła się nowa zakładka "Nadpłaty", gdzie można zbiorczo zwrócić nadpłaty.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Powiadomienia w aplikacji mobilnej dla pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Pracownik zalogowany w aplikacji mobilnej dostanie powiadomienie o utworzonym ogłoszeniu bądź napisanej wiadomości',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Nowa podkategoria w szczegółowym podsumowaniu płatności dziecka "Posiłki"',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ],
    works: []
  },
  {
    date: moment('2024-06-05').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Usprawnienie działania modułu wiadomości - w przypadku dużej ilości wiadomości powinna być zauważalna zmiana w szybkości działania systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawnienie działania linków w wiadomościach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawnienia w dzienniku specjalistycznym',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawnienia działania raportów',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ],
    works: []
  },
  {
    date: moment('2024-05-14').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Aktualizacje i zmiany w aplikacji mobilnej:',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'poprawienie brakujących osób upoważnionych do odbioru dziecka',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Dodano nowy komponent do przeglądania zdjęć (zastosowano we wszystkich miejscach w aplikacji) co powinno znacznie usprawnić przeglądanie zdjęć w aplikacji',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Poprawiono wyświetlanie linków w wiadomościach w aplikacji',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ],
    works: []
  },
  {
    date: moment('2024-05-08').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Możliwość dodania zdjęcia bezpośrednio w treści ogłoszenia',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Zwiększone limity wysyłania plików w dysku wirtualnym',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Dodanie możliwości importu MT940 dla księgowych',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Poprawki i zmiany w raportach PDF i XLSX',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Dodano wyświetlanie nazw świąt i dni wolnych na kalendarzu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ],
    works: []
  },
  {
    date: moment('2024-04-10').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Aktualizacje i zmiany w aplikacji mobilnej:',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodanie sortowania w rejestrze dzieci (po nazwisku)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Dodanie sortowania w dzienniku obecności (po nazwisku)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Dodanie informacji o nieobecności w dzienniku obecności',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Dodanie opcji sprawdzenia kto wyświetlił ogłoszenie',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Dodanie opcji sprawdzenia kto wyświetlił ogłoszenie wewnętrzne',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Dodano wyświetlanie nazw świąt i dni wolnych na kalendarzu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Dodano wyświetlanie nazw świąt i dni wolnych na kalendarzu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
    ],
    works: []
  },
  {
    date: moment('2024-04-09').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Aktualizacje i zmiany w aplikacji mobilnej',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowy moduł: Adaptacja',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Jeżeli podczas zgłaszania obecności oznaczymy je jako adaptację, dziecko nie będzie miało naliczonej opłaty za wyżywienie tego dnia',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Nowy moduł: Windykacja',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'W rejestrze dzieci pracownik może oznaczyć dziecko jako objęte windykacją (przycisk windykacji). Od tego momentu, dziecko nie pojawi się w menu zaległości',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Możliwość wygenerowania raportów wraz z dziećmi zarchiwizowanymi w raportach:',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Zestawienie zaległości za miesiąc, Zestawienie rozliczeń za miesiąc, Zestawienie osobodni(obecność dzieci), Zestawienie opłat wnoszonych, Zestawienie odsetek, Zestawienie naliczeń za miesiąc, Zestawienie nadpłat, Zestawienie przelewów, Lista obecności oddzielna dla każdej grupy',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
    ],
    works: []
  },
  {
    date: moment('2024-03-11').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Wydanie pierwszej produkcyjnej wersji aplikacji mobilnej dla pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Możliwość wycofywania przez rodzica nieobecności spoza początkowego zakresu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Jeżeli rodzic wprowadził nieobecność od 11.03 do 15.03 i nieobecność rozpoczęła swój bieg, rodzic od teraz może wycofać nieobecności na dni, które się jeszcze nie zaczęły, np. od 13.03 do 15.03',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Obsługa MT940 dla rozliczeń "Inne"',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ],
    works: []
  },
  {
    date: moment('2024-03-08').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Aktualizacja i zmiany w aplikacji mobilnej',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Poprawki u usprawnienia działania systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ],
    works: [
      {
        title: 'Komentowanie ogłoszeń',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Wycofywanie nieobecności spoza początkowego zakresu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2024-02-12').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Nowa funkcjonalność: zbiorcze przypisywanie ulg do dzieci (podczas przypinania dzieci do schematów płatności)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Poprawa zaokrąglania w podsumowaniu zaległości',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawniona logika raportów oraz inne poprawki stabilizujące pracę systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ],
    works: [
      {
        title: 'Aplikacja mobilna dla pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Możliwość komentowania ogłoszeń',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2024-01-29').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Nowa funkcjonalność: obsługa pliku MT940 banku ING',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowa funkcjonalnośc: Eksport danych dzieci w formacie csv z poziomu Konfiguracja -> "Eksport dzieci SIO" zgodnie z wytycznymi Systemu Informacji Oświatowej (SIO). ',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawnienia systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ],
    works: [
      {
        title: 'Aplikacja mobilna dla pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Możliwość komentowania ogłoszeń',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2024-01-24').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Nowy moduł umożliwiający odseparowanie w należnościach kwot bazowych i odsetek. Aby uruchomić funkcję, należy ją uruchomić w Konfiguracja -> Ustawienia rozliczeń -> Ustawienia odsetek -> Rozdzielaj opłaty podstawowe od odsetkowych',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodanie nowych kategorii w rodzajach opłat w Rejestr Dzieci -> Dziecko -> Ewidencja opłat',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Modyfikacja raportu "Lista obecności dzieci (dzienna) oddzielna dla każdej grupy", która umożliwia wyświetlenie nieobecności zgłoszonych po terminie',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowy raport: "Dziennik specjalistyczny - zajęcia indywidualne"',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawnienia działania galerii',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Usprawnienia modułu dziennika specjalistycznego',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Usprawnienia modułu dziennika elektrycznego',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Usprawnienia systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ],
    works: [
      {
        title: 'Aplikacja mobilna dla pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Możliwość komentowania ogłoszeń',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2023-12-18').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Dodanie nowego rodzaju portfela dotyczącego posiłków',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Udostępnienie dziennika specjalistycznego dla roli nauczyciela',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Poprawki i usprawnienia w raportach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Usprawnienia modułu dziennika specjalistycznego',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ],
    works: [
      {
        title: 'Wersja aplikacji mobilnej dla pracowników!',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Możliwość komentowania ogłoszeń przez rodziców i pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2023-11-28').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Rozszerzenie nowego modułu systemu "Dzienniki specjalistyczne", które zawiera:',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Tworzenie grup pomocy psychologicznej wraz z przypisywaniem dzieci oraz nauczycieli do danej grupy',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dziennik zajęć indywidualnych dla każdego dziecka',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Lista obecności dzieci na zajęciach indywidualnych w tym zgłaszanie obecności/nieobecności',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Pogląd kalendarza w rejestrze dzieci, kalendarz z rozkładem zajęć',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Dodawanie, edycja oraz usuwanie wpisów w kalendarzu zajęć indywidualnych',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Indywidualny program pracy z uczniem: dodawanie/edycja/usuwanie celów ogólnych, celów szczegółowych, metod, wniosków',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Dziennik zajęć grupowych',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Lista obecności na zajęciach grupowych, zgłaszanie obecności/nieobecności',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Kalendarz z rozkładem zajęć z możliwością dodania, podglądu, edycją i usuwania wpisu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Program pracy grupy: dodawanie/edycja/usuwanie celów ogólnych, celów szczegółowych, metod, wniosków',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Rozszerzenie szablonów pomocy psychologiczno-pedagogicznej - dodawanie i podgląd nowych szablonów dotyczących dziennika indywidualnego i grupowego',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowa sekcja w Rejestrze dzieci',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: '2 nowe sekcje: Obecność na zajęciach indywidualnych oraz Obecność na zajęciach grupowych',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Obecność na zajęciach indywidualnych wyświetli się, jeśli dziecko jest przypisane do pomocy psychologiczno-pedagogicznej',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Obecność na zajęciach grupowych wyświetli się, jeśli dziecko jest przypisane do grupy psychologiczno-pedagogicznej',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Inne aktualizacje:',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowy raport: Podsumowanie zamówienia posiłków przedstawiający ilość zamówionych i anulowanych posiłków na wybrany dzień',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawnienie wyszukiwania w całym systemie',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawnienia wydajnościowe systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
    ],
    works: [
      {
        title: 'Wersja aplikacji mobilnej dla pracowników!',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Możliwość komentowania ogłoszeń przez rodziców i pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2023-11-14').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Nowy moduł systemu: Posiłki',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Za pomocą nowego modułu posiłków mogą Państwo tworzyć posiłki oraz komponować diety (np. dieta standardowa, dieta bezmleczna) wraz z wybranymi posiłkami',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Do diet można przypisać dzieci zbiorczo jak i pojedynczo',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Wraz z nowym modułem istnieje teraz możliwość rozliczania wyżywienia przez przypisaną dietę do dziecka',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Aby skorzystać z tej opcji, należy stworzyć schemat płatności "dzienny" i po wypełnieniu formularza według preferencji przypisać odpowiedni posiłek do schematu płatności',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Rodzic może odwołać dany posiłek na dany dzień z poziomu aplikacji mobilnej (przykładowo: Dziecko będzie nieobecne na śniadaniu ponieważ pojawi się tego dnia później w placówce)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Moduł z góry zakłada, że dzieci będą obecne w danym miesiącu, więc nie muszą Państwo wprowadzać obecności na danym posiłku',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Aby rodzic mógł odwołać posiłek z poziomu aplikacji mobilnej, należy włączyć w konfiguracji placówki opcję "Korzystaj z modułu posiłków',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Pracownik ma podgląd i możliwość odwołania danego posiłku z poziomu Rejestr Dzieci -> Wybrane dziecko -> Obecności na posiłkach / Posiłki',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Uwzględniliśmy takie scenariusze jak odwołanie wszystkich posiłków po zgłoszeniu nieobecności w placówce dziecka bądź naliczanie za posiłki, jeżeli rodzic odwoła posiłek za późno',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'UWAGA: Nie przypisujemy schematów płatności dotyczących posiłków do dzieci - po przypisaniu dziecka do diety system automatycznie powiąże dziecko ze schematami płatności',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Moduł posiłków jest zaawansowany i może współistnieć z istniejącym sposobem rozliczania wyżywienia, więc w przypadku pytań bądź chęci otrzymania dodatkowej pomocy/wyjaśnień przed miesięcznymi rozliczeniami, bardzo prosimy o kontakt z biurem obsługi klienta',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowy moduł: Dziennik Specjalistyczny',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Moduł umożliwia zdefiniowanie Pomocy Psychologiczno-Pedagogicznej dziecku',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Pracownik może dodać Wielospecjalistyczną Ocenę Poziomu Funkcjonowania Ucznia (WOPFU) oraz Indywidualny program Edukacyjno-Terapeutyczny (IPET) dla danego dziecka',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'W zakładce konfiguracja -> Placówka jako element modułu zaimplementowaliśmy "Szablony pomocy psychologiczno-pedagogicznej" jako wzorce, które można następnie wykorzystać przy wypełnianiu IPET oraz WOPFU',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'W niedalekiej przyszłości moduł będzie umożliwiał tworzenie grup specjalistycznych, tworzenie zajęć grupowych i indywidualnych wraz z dziennikiem obecności (wyszarzone pola)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Inne aktualizacje:',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawnienia i poprawa działania modułu wiadomości m.in powiadomienia o nowej wiadomości itd.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Poprawki i usprawnienia działania systemu m.in raporty, sortowanie na widokach i inne',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
    ],
    works: [
      {
        title:
          'Dzienniki Specjalistyczne',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Wersja aplikacji mobilnej dla pracowników!',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Możliwość komentowania ogłoszeń przez rodziców i pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2023-09-29').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Dodanie mozliwości wyboru wszystkich grup',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Mogą Państwo wybrać opcję "Wszystkie grupy" w module wyboru grupy w całym systemie',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Usprawnienia działania modułu Rozliczenia',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowa funkcjonalność - widok "Nieobecne jutro". Mogą Państwo sprawdzić listę nieobecnych dzieci w kolejnym dniu roboczym. Funkcja działa z poziomu pulpitu (podsumowanie u góry na białym tle) oraz z menu po lewej stronie',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawnienia i poprawa wyglądu logowania PINem i kodem QR',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Zmiany wyglądu modułu Ogłoszeń',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Wiele usprawnień wydajnościowych i poprawek wizualnych systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },

    ],
    works: [
      {
        title:
          'Dzienniki Specjalistyczne',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Wersja aplikacji mobilnej dla pracowników!',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Moduł jadłospisów i posiłków wraz z  możliwością odmawiania/odznaczania posiłków przez rodziców na dany dzień',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Możliwość komentowania ogłoszeń',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2023-09-14').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Obsługa czatów grupowych w aplikacji mobilnej 4Parents dla rodziców',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowy wygląd czatów grupowych w aplikacji mobilnej',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawnienia dotyczące funkcjonalności powiadomień w aplikacji mobilnej',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawnienia dotyczące skanowania kodu QR w aplikacji mobilnej',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Jeżeli spotkają się Państwo z problemami dotyczącymi powiadomień bądź skanowania kodu QR, prosimy o poinformowanie rodziców, żeby zainstalować najnowszą wersję aplikacji',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ],
    works: []
  },
  {
    date: moment('2023-09-04').format('YYYY-MM-DD'),
    changes: [
      {
        title: 'Obsługa pliku MT940 banku Santander',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Modyfikacja raportu “Podsumowanie dla pracowników stołówki”. Mogą Państwo wybrać dzień na który ma być wygenerowany raport. W raporcie pojawi się zaplanowana ilość dzieci na wybrany dzień biorąc pod uwagę zaplanowane nieobecności',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Poprawki w wiadomościach grupowych',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ],
    works: []
  },
  {
    date: moment('2023-08-28').format('YYYY-MM-DD'),
    changes: [
      {
        title: 'Poprawki graficzne i usprawnienia działania wersji beta systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ],
    works: []
  },
  {
    date: moment('2023-08-23').format('YYYY-MM-DD'),
    changes: [
      {
        title: 'Nowa funkcjonalność: czaty grupowe w Wiadomościach. Mogą Państwo tworzyć konwersacje z wieloma pracownikami oraz rodzicami',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowa funkcjonalność: możliwość wysłania pojedynczej wiadomości zbiorczo, uprzednio wybierając odbiorców. Wysłanie takiej wiadomości utworzy osobne konwersację pomiędzy twórcą wiadomości a każdym z wybranych odbiorców wiadomości',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Obsługa GIFów w Wiadomościach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowa funkcjonalność: udostępnianie plików z dysku wirtualnego bezpośrednio w Wiadomościach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Mogą Państwo udostępnić plik dla odbiorcy wiadomości bezpośrednio w konwersacji oraz nadać ewentualne uprawnienia, jeżeli ich nie posiada',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'W przypadku udostępnienia pliku w konwersacji grupowej, odbiorcy będą mogli pobrać ten plik, jeżeli będą mieli do niego uprawnienia. W przeciwnym razie zobaczą komunikat “Nie masz uprawnień do tego pliku”. Zobaczą również Państwo stosowny komunikat z możliwością udostępnienia pliku dla nieuprawnionych',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Nowy raport: “Dziennik zajęć przedszkola” prezentujący dane dziennika elektronicznego z całego roku.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Obsługa pliku MT940 banku ING',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Poprawa filtrowania wydarzeń i ogłoszeń',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Poprawki i usprawnienia działania wersji beta systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ],
    works: []
  },
  {
    date: moment('2023-07-25').format('YYYY-MM-DD'),
    changes: [
      {
        title: 'Dodanie opcji łączenia transakcji dziecka z tymi samymi numerami konta wokół jednego rozliczenia w przypadku płatności online. Zamiast kilku przelewów, dotrze na Państwa konto jeden zbiorczy.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodanie opcji włączenia lub wyłączenia korzystania z portfeli dodatkowych oraz powiązania operacji (w Ustawieniach rozliczeń należy włączyć funkcję “Korzystanie z portfeli dodatkowych"',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodanie w zestawieniu zaległości możliwości podziału ze względu na typ zadłużenia',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodanie w zestawieniu przelewów możliwości wyświetlenia w liście kwot przypisanych do konkretnych rozliczeń',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Poprawki i usprawnienia działania wersji beta systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ],
    works: []
  },
  {
    date: moment('2023-06-28').format('YYYY-MM-DD'),
    changes: [
      {
        title: 'Dodanie nowego rodzaju portfela "Inne". Dotyczy to ewidencji napdłat, rozliczeń itd',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Poprawka raportu nieobecności i błędów w podsumowaniu nieobecnych na pulpicie',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Poprawki i usprawnienia działania wersji beta systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ],
    works: []
  },
  {
    date: moment('2023-06-13').format('YYYY-MM-DD'),
    changes: [
      {
        title: 'Poprawki graficzne i usprawnienia działania wersji beta systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ],
    works: []
  },
  {
    date: moment('2023-06-07').format('YYYY-MM-DD'),
    changes: [
      {
        title: 'Obsługa plikow MT940 banków PKO BP i Peako SA',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawniona logika ładowania plików MT940',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowa opcja w konfiguracji placówki: Możliwość WYŁĄCZENIA edycji danych dziecka przez nauczyciela',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: true
      },
      {
        title: 'Po włączeniu tej flagi, nauczyciel może tylko wyświetlić te dane, bez możliwości edycji',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: false
      },
      {
        title: 'Nowa opcja w konfiguracji placówki: Możliwość WYŁĄCZENIA wysyłania powiadomień o odbiorze dziecka na telefon rodzica',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Po włączeniu tej funkcji, rodzice nie będą dostawać powiadomień o odbiorze dziecka przez opiekuna',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Poprawka w raporcie  "Lista zgłoszonych nieobecności do godziny X”',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Dodatkowe usprawnienia działania systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
    ],
    works: []
  },
  {
    date: moment('2023-06-06').format('YYYY-MM-DD'),
    changes: [
      {
        title: 'Poprawki graficzne i usprawnienia działania wersji beta systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ],
    works: []
  },
  {
    date: moment('2023-05-11').format('YYYY-MM-DD'),
    changes: [
      {
        title: 'Raport zestawienie przelewów ma teraz wybór zakresu dat OD-DO',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodanie funkcji "Nie uwzględniaj weekendów jako możliwych dni do zgłoszenia nieobecności" w schematach płatności',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: ' jeżeli chcą Państwo, żeby rodzice mogli zgłosić nieobecność maksymalnie do wybranej godziny w dni robocze bez naliczenia opłaty',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Poprawki w raportach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
    ],
    works: []
  },
  {
    date: moment('2023-04-19').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Zupełnie nowy wygląd systemu 4Parents!',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Miło nam zaprezentować Państwu zupełnie nowy wygląd całego systemu!',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title:
          'Dostępne są 3 motywy: jasny, ciemny oraz kontrast dla osób z dysfunkcją/wadą wzroku. Motyw wybierany jest w prawym górnym rogu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'System z nowym wyglądem dostępny jest pod adresem "twojadomena".4parentsbeta.pl',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title:
          'Nowe funkcjonalności będą oferowane i prezentowane na nowym systemie. Dotychczasowy system w przyszłości przestanie być wspierany',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title:
          'Jest to wersja beta, mogą występować niewielkie błędy. Jeżeli je Państwo zauważą - prosimy o kontakt poprzez biuro@4parents.pl. Prosimy również o przesłanie opinii na temat nowego wyglądu :)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Przypominamy o dostępności nowego systemu: 4pmanagement!',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'W tym systemie, mogą Państwo obserwować zbiorcze dane obecności, rozliczeń oraz generować raporty ze wszystkich placówek z jednego miejsca. System będzie regularnie rozwijany i aktualizowany o nowe funkcjonalności.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Jeżeli są Państwo zainteresowani prezentacją, prosimy o kontakt z działem sprzedaży (+48 535 668 076) lub poprzez biuro@softwarehub.pl',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },

      {
        title: 'Nowa funkcjonalność odsetek - możliwość naliczania odsetek od ustalonej kwoty',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'System naliczy odsetki od danego rozliczenia dopiero po przekroczeniu ustalonej przez Państwa kwoty',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Aby uruchomić tą funkcję, należy włączyć "Stosuj system naliczania odsetek podatkowych" w Konfiguracja -> Ustawienia rozliczeń',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Nowa funkcjonalność: zaokrąglanie odsetek do pełnych dziesiątek groszy w Konfiguracja -> Ustawienia rozliczeń',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowa funkcjonalność: zaokrąglanie odsetek do pełnych złotówek w Konfiguracja -> Ustawienia rozliczeń',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowy raport: Zestawienie przewidywanych kwot odsetek na wybrany dzień',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Nowa funkcjonalność: skala posiłków. W zakładce "Aktywność dzieci" przy zaznaczeniu posiłku mogą Państwo opcjonalnie dodać skalę zjedzonego posiłku (dostępne tylko na 4parents BETA)',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Dodano dodatkową informację w "Konfiguracja -> Import dzieci (csv)" opisującą format poszczególnych kolumn, aby ułatwić Państwu import danych',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Dodano możliwość wydruku kodu QR do rejestracji wejść/wyjść dzieci w formie plakatu 4Parents (w formatach A3 i A4)',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Nowa funkcjonalność: Możliwość ręcznego wysyłania powiadomień ponaglających do rodziców, którzy mają zaległe opłaty z poziomu "Rozliczenia -> Zaległe opłaty" (dostępne tylko na 4parents BETA)',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Poprawki i usprawnienia w raportach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: false
      },
      {
        title: 'Usprawnienia działania całego systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: false
      }
    ],
    works: [
      {
        title: 'Grafik pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Jadłospisy',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'I wiele więcej :)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2023-02-15').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Zupełnie nowy wygląd systemu 4Parents!',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Miło nam zaprezentować Państwu zupełnie nowy wygląd całego systemu!',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title:
          'Dostępne są 3 motywy: jasny, ciemny oraz kontrast dla osób z dysfunkcją/wadą wzroku. Motyw wybierany jest w prawym górnym rogu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'System z nowym wyglądem dostępny jest pod adresem "twojadomena".4parentsbeta.pl',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title:
          'Nowe funkcjonalności będą oferowane i prezentowane na nowym systemie. Dotychczasowy system w przyszłości przestanie być wspierany',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title:
          'Jest to wersja beta, mogą występować niewielkie błędy. Jeżeli je Państwo zauważą - prosimy o kontakt poprzez biuro@4parents.pl. Prosimy również o przesłanie opinii na temat nowego wyglądu :)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Przypominamy o dostępności nowego systemu: 4pmanagement!',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'W tym systemie, mogą Państwo obserwować zbiorcze dane obecności, rozliczeń oraz generować raporty ze wszystkich placówek z jednego miejsca. System będzie regularnie rozwijany i aktualizowany o nowe funkcjonalności.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Jeżeli są Państwo zainteresowani prezentacją, prosimy o kontakt z działem sprzedaży (+48 535 668 076) lub poprzez biuro@softwarehub.pl',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },

      {
        title: 'Nowa funkcjonalność odsetek - możliwość naliczania odsetek od ustalonej kwoty',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'System naliczy odsetki od danego rozliczenia dopiero po przekroczeniu ustalonej przez Państwa kwoty',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Aby uruchomić tą funkcję, należy włączyć "Stosuj system naliczania odsetek podatkowych" w Konfiguracja -> Ustawienia rozliczeń',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Nowa funkcjonalność: zaokrąglanie odsetek do pełnych dziesiątek groszy w Konfiguracja -> Ustawienia rozliczeń',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowa funkcjonalność: zaokrąglanie odsetek do pełnych złotówek w Konfiguracja -> Ustawienia rozliczeń',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowy raport: Zestawienie przewidywanych kwot odsetek na wybrany dzień',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Nowa funkcjonalność: skala posiłków. W zakładce "Aktywność dzieci" przy zaznaczeniu posiłku mogą Państwo opcjonalnie dodać skalę zjedzonego posiłku (dostępne tylko na 4parents BETA)',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Dodano dodatkową informację w "Konfiguracja -> Import dzieci (csv)" opisującą format poszczególnych kolumn, aby ułatwić Państwu import danych',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Dodano możliwość wydruku kodu QR do rejestracji wejść/wyjść dzieci w formie plakatu 4Parents (w formatach A3 i A4)',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Nowa funkcjonalność: Możliwość ręcznego wysyłania powiadomień ponaglających do rodziców, którzy mają zaległe opłaty z poziomu "Rozliczenia -> Zaległe opłaty" (dostępne tylko na 4parents BETA)',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Poprawki i usprawnienia w raportach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: false
      },
      {
        title: 'Usprawnienia działania całego systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: false
      }
    ],
    works: [
      {
        title: 'Grafik pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Jadłospisy',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'I wiele więcej :)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2023-02-15').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Zupełnie nowy system: 4pmanagement!',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'W tym systemie, mogą Państwo obserwować zbiorcze dane obecności, rozliczeń ze wszystkich placówek oraz generować raporty ze wszystkich placówek z jednego miejsca. System będzie regularnie rozwijany i aktualizowany o nowe funkcjonalności.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Jeżeli są Państwo zainteresowani prezentacją, prosimy o kontakt z działem sprzedaży (+48 535 668 076) lub poprzez biuro@softwarehub.pl',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowa kategoria w Konfiguracji Placówki - Urządzenia do rejestracji obecności a w niej:',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowa funkcjonalność: logowanie wejść/wyjść dzieci kodem QR!',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Rodzice mogą teraz wprowadzić wejście/wyjście dziecka z poziomu aplikacji mobilnej, skanując kod QR, który jest udostępniony do wydruku bądź wyświetlony na tablecie',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Są dwa rodzaje kodu QR: statyczny oraz dynamiczny:',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Statyczny kod QR mogą Państwo wydrukować i udostępnić w wygodnym dla rodziców miejscu',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Dynamiczny kod QR jest zmienny codziennie i będzie wyświetlał się na tabletach w tym samym miejscu co wprowadzanie PINów. W przypadku korzystania z tabletów prosimy o korzystanie z dynamicznych kodów QR',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'W przypadku włączenia funkcji rejestracji wejść/wyjść kodem QR - w aplikacji mobilnej rodziców pojawi się nowy przycisk "Rejestracja obecności dziecka". Po wejściu do placówki, rodzic skanuje kod QR i naciska wejście (bądź wyjście), które będzie zarejestrowane w systemie analogicznie jak w przypadku kodów PIN bądź wprowadzania godzin obecności przez pracowników placówki',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Nowa funkcjonalność: wysyłanie powiadomień o zaległych zapłatach do rodziców',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Ta funkcjonalność już była zawarta w systemie wcześniej, jednak została poddana modyfikacjom.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Aby włączyć powiadomienia o zaległych opłatach do rodziców, należy włączyć tą opcję w zakładce Konfiguracja -> Placówka o nazwie "Wysyłanie powiadomień do rodziców o zaległych opłatach"',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Po uruchomieniu tej opcji, rodzice będą dostawać powiadomienia na telefon codziennie o godzinie 17:00 dopóki nie uregulują zaległości',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Domyślnie ta funkcja jest wyłączona, więc jeżeli chcecie Państwo aby rodzice otrzymywali powiadomienia, należy tą funkcję włączyć',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Modyfikacje raportów o dane historyczne (np. odczyt stanu wpłat w danych rozliczeniach w przeszłości)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: false
      },
      {
        title: 'Poprawki i usprawnienia w raportach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: false
      },
      {
        title: 'Ograniczenie możliwości wprowadzenai nieobecności na maksymalnie 3 miesiące do przodu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: false
      },
      {
        title: 'Usprawnienie odczytu stanu portfeli dziecka w raportach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: false
      },
      {
        title: 'Usprawnienie działania całego systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: false
      }
    ],
    works: [
      {
        title:
          'Wielkimi krokami zbliżamy się do wdrożenia na produkcję zupełnie nowego wyglądu systemu 4Parents, który będą mogli Państwo przetestować osobiście',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Grafik pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title:
          'I wiele więcej :) Jeżeli mają Państwo sugestie dot. funkcjonalności, prosimy o kontakt poprzez biuro@4parents.pl',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2022-11-14').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title: 'Nowa funkcjonalność: rozbudowanie mechanizmu odsetek.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Dostępne w zakładce "Konfiguracja -> Ustawienia rozliczeń',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      // {
      //   title: 'Funkcjonalność dot. odsetek została gruntownie zmieniona. Od teraz mogą Państwo wprowadzić stopę odsetek oraz datę rozpoczęcia obowiązywania',
      //   visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
      //   strong: false
      // },
      // {
      //   title: 'W zakładce Konfiguracja -> Ustawienia rozliczeń jest możliwość wprowadzenia zaawansowanych odsetek dla każdego rodzaju opłaty z osobna',
      //   visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
      //   strong: false
      // },
      // {
      //   title: 'System przeliczając/naliczając odsetki przeliczy je odpowiednio wg. podanych stóp od/do okresu obowiązywania',
      //   visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
      //   strong: false
      // },
      {
        title: 'Nowy raport: Zestawienie naliczeń za miesiąc - początek miesiąca',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowy raport: Zestawienie rozliczeń za miesiąc',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Zmiany w raporcie "Kartoteka opłat za pobyt dziecka" w Rejestrze dzieci -> Dziecko -> zakładka "Dokumenty"',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowa funkcjonalność: Możliwość ograniczenia widoczności ogłoszeń dla rodziców:',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: true
      },
      {
        title:
          'W Konfiguracja -> Placówka została dodana opcja "Widoczność wszystkich ogłoszeń przez rodziców". Gdy wyłączymy tą opcję, rodzic zobaczy tylko ogłoszenia, które są nowsze od daty utworzenia rodzica.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: false
      },
      {
        title: 'Poprawki w istniejących raportach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: true
      },
      {
        title: 'Nowa funkcjonalność: Obsługa wielu wydawnictw:',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: true
      },
      {
        title:
          'Jeżeli istnieje potrzeba skorzystania z innego wydaniwctwa przy tworzeniu wpisu w zapisie pracy wychowawczo-dydaktycznej, został dodany przycisk umożliwiający wybór innego wydawnictwa.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: false
      },
      {
        title: 'Funkcjonalność domyślnego wydawnictwa (Konfiguracja -> Placówka) pozostaje bez zmian',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: false
      },
      {
        title:
          'Nowa funkcjonalność: Wprowadzając ulgę w Konfiguracja -> Ulgi, możemy ją oznaczyć i traktować jako dofinansowanie.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: true
      }
    ],
    works: [
      {
        title: 'Grafik pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Arkusz organizacyjny',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Zupełnie odświeżony i nowy wygląd systemu - do wyboru będą 3 motywy',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title:
          'I wiele więcej :) Jeżeli mają Państwo sugestie dot. funkcjonalności, prosimy o kontakt poprzez biuro@4parents.pl',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2022-09-05').format('YYYY-MM-DD'),
    title: 'W przypadku problemów prosimy o kontakt przez biuro@4parents.pl lub +48 22 398 74 85',
    changes: [
      {
        title:
          'Od ostatniej aktualizacji skupiliśmy się mocno na nowych funkcjonalnościach, prosimy o opinie i ewentualne uwagi!',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowe raporty (gorąco zachęcamy do zapoznania się z nimi):',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Lista obecności (dzienna) oddzielna dla każdej grupy',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Kartoteka opłat za pobyt dziecka (Rejestr dzieci -> Dziecko -> Zakładka "Dokumenty)',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Ewidencja dziennego pobytu dziecka (Rejestr dzieci -> Dziecko -> Zakładka "Dokumenty)',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Zestawienie osobodni (obecności dzieci)',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Karta informacyjna dziecka (Rejestr dzieci -> Dziecko -> Zakładka "Dokumenty)',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Zestawienie zaległości za miesiąc',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Zestawienie przelewów',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Zestawienie odsetek',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Lista wydarzeń z kalendarza',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Modyfikacja raportu "Lista zgłoszonych nieobecności". Raport umożliwia podział na osoby zgłaszające nieobecność (rodzic/pracownik placówki). W przypadku zaznaczenia podziału, raport generuje dwie osobne strony.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Nowa funkcjonalność - integracja rozliczeń z wyciągami bankowymi w formacie mt940. ',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'W przypadku niekorzystania z płatności online wbudowanych w 4p, istnieje możliwość importu przelewów bankowych do rozliczeń',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'W przypadku zainteresowania po więcej szczegółów prosimy o kontakt poprzez biuro@4parents.pl',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Nowa funkcjonalność - integracja z wydawnictwami!',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title:
          'W zakładce Konfiguracja -> Placówka. Dyrektor ma możliwość wyboru domyślnego wydawnictwa, z którym Państwo współpracują',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title:
          'Po wybraniu domyślnego wydawnictwa, automatycznie otrzymują Państwo dostęp do tematów dnia, opisów, materiałów dydaktycznych itd.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title:
          'Skorzystanie z integracji jest możliwe z poziomu Dziennik elektroniczny -> Zapis pracy wychowawczo-dydaktycznej',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title:
          'Tworząc nowy wpis, z prawej strony pojawiła się nowa opcja "Wybierz temat z podręcznika". Do wyboru mają Państwo podręcznik, krąg tematyczny, temat zajęć. Automatycznie zostaną dodane opis, materiały dydaktyczne oraz podstawa programowa.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Lista wydawnictw oraz książek z którymi system 4parents został zintegrowany:',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Wydawnictwo Szkolne i Pedagogiczne',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Drużyna Marzeń',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Planeta Dzieci',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Nowi Tropiciele',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Lions Team',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Wydawnictwo MAC Edukacja',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Kolorowy Start',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Mały Elementarz',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Nowe Przygody Olka i Ady',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Odkrywam Siebie',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Strefa Przedszkolaka',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Supersmyki',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Wydawnictwo Didasko',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: '5-latki',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Poznaję świat, bo mam 6 lat',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Wydawnictwo Podręcznikarnia',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Zabawy z porami roku',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Seria Razem (uczymy się, bawimy się, itp.)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Trefliki w przedszkolu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Wydawnictwo Klett',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowa Trampolina',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Świat w kolorach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Żyrafa Ola i Przyjaciele',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Wydawnictwo Nowa Era',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Ciekawa Zabawa',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Dzieciaki w akcji',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Jeżeli nie ma Twojego wydawnictwa skontaktuj się z biuro@4parents.pl',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Nowe funkcjonalności: dodane opcjonalne pola w formularzu dziecka jak numer umowy i adres zameldowania',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Zmiany w podsumowaniach kwitariuszy. Można uwzględnić ulgi w podsumowaniu oraz zmodyfikowaliśmy samo podsumowanie',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Nowa funkcjonalność: Wyświetlenia ogłoszeń wewnętrznych przez pracowników',
        visibleFor: [userRoles.staffMemberPrincipal],
        strong: true
      },
      {
        title: 'Poprawki napotkanych oraz zgłaszanych błędów i stabilizacja systemu.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary
        ],
        strong: false
      }
    ],
    works: [
      {
        title: 'Grafik pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Arkusz organizacyjny',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Odświeżony dziennik elektroniczny',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Zupełnie odświeżony i nowy wygląd systemu - do wyboru będą 3 motywy',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title:
          'I wiele więcej :) Jeżeli mają Państwo sugestie dot. funkcjonalności, prosimy o kontakt poprzez biuro@4parents.pl',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2022-07-21').format('YYYY-MM-DD'),
    title: '',
    changes: [
      {
        title:
          'Nowa funkcjonalność: portfele/salda dzieci w zależności od rodzaju (Ogólny, Pobyt, Wyżwienie, Zajęcia dodatkowe)',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Każdy portfel jest od siebie odseparowany wraz z historią operacji. Można wpłacać/wypłacać bezpośrednio na każdy z portfeli oraz między nimi',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'W przypadki płatności z aktualnych środków za rozliczenie również można wybrać portfel danego rodzaju bądź ogólny. ',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Portfel/saldo danego typu rozliczenia jest automatycznie wybierany w rozliczeniu',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'W przypadku przelewu bankowego lub wpłaty w kasie z nadwyżką, mamy do wyboru "Aktualny portfel na który zostaną przelane pozostałe środki". Kwota, ktora pozostanie z rozliczenia zostanie przelana na wybrany portfel ',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Nowa funkcjonalność: Przeliczanie odsetek - na widoku rozliczeń -> "Zarejestruj wpłatę" można przeliczyć odsetki do wybranej daty wpłaty przez rodzica',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowy raport: Rozliczenie - pobyt (szczegóły) (.xls)',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowy raport: Rozliczenie - wyżywienie (szczegóły) (.xls)',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Poprawki w module "Aktywność dzieci" - gorąco zachęcamy do włączenia tego modułu w zakładce "Konfiguracja"',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Zmiany w module Ogłoszenia - dodając ogłoszenie i ograniczając widoczność ogłoszenia do konkretnych grup bądź zajęć dodatkowych',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberSecretary
        ],
        strong: true
      },
      {
        title: 'Schematy płatności przy przypisywaniu schematu do dzieci są teraz pokategoryzowane',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Poprawki kosmetyczne i opisowe',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Stabilizacja systemu',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      }
    ],
    works: [
      {
        title: 'Grafik pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowy dziennik elektroniczny wraz z integracją z wydawnictwami',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Zupełnie nowy wygląd systemu - do wyboru będą 4 motywy',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Rozszerzenie funkcjonalności Ulg',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Odświeżony moduł Aktywności dziecka',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'I wiele więcej :)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2022-06-24').format('YYYY-MM-DD'),
    title: '',
    changes: [
      {
        title: 'Wyszukiwanie w dzienniku elektronicznym we wszystkich podkategoriach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberSecretary
        ],
        strong: true
      },
      {
        title: 'Poprawki istniejących funkcjonalności i stabilności systemu',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      }
    ],
    works: []
  },
  {
    date: moment('2022-06-15').format('YYYY-MM-DD'),
    title: '',
    changes: [
      {
        title:
          'Nowy moduł systemu: rozliczenia pracowników. Moduł teraz jest analogicznie rozbudowany jak w przypadku rozliczeń dzieci',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowy moduł: nadpłaty i saldo/portfel na kontach pracowników',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowa funkcjonalność: Import dzieci z pliku CSV do systemu wraz z instrukcją i widokiem podsumowującym',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Zmiana/dodanie uprawnień do kalendarza obecności zajęć dodatkowych, dodanie daty w nagłówku i informacji o braku przypisanych dzieci do zajęć dodatkowych',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Rozliczenia, które można rozliczyć nadpłatą są również podświetlone w statusie Robocze',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Poprawki i modyfikacje w module wiadomości',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Rozszerzenie funkcji raportu "Obecność dzieci w interwałach godzinowych" o dowolnej ilości interwałów',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Raporty są teraz pogrupowane na kategorie wraz z wyszukiwarką w każdej z kategorii.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Usprawnienie generowania raportów. Generowanie raportów w formacie PDF jest szybsze i stabilniejsze',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Liczby porządkowe dodane we wszystkich raportach',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowa rola użytkownika: Pomoc nauczyciela',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Nowy raport: Podsumowanie dla pracowników stołówki opisujący schemat wyżywienia i ilość przypisanych dzieci',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Poprawki w ewidencji dziecka',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Dodany adres zameldowania do rejestru dzieci',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Usprawnienia działania systemu',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      }
    ],
    works: [
      {
        title: 'Bardziej zaawansowany moduł rozliczania pracowników, wraz z nadpłatami, portfelem itd.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Grafik pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowy dziennik elektroniczny',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Arkusz organizacyjny',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2022-04-26').format('YYYY-MM-DD'),
    title: '',
    changes: [
      {
        title: 'Nowa funkcjonalność: Moduł obecności za zajęcia dodatkowe a w tym:',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dziennik obecności na zajęciach dodatkowych',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Kalendarz obecności na zajęciach dodatkowych w Rejestrze dzieci, indywidualnie dla zajęć',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Ważne: Nieobecność w placówce nie wyklucza obecności na zajęciach dodatkowych. Dziecko może być obecne na zajęciach dodatkowych mimo nieobecności w placówce i na odwrót. Obecności i nieobecności w placówce i zajęciach dodatkowych nie łączą się.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Aby móc wprowadzać obecności/nieobecności należy zapisać dziecko na utworzone wcześniej zajęcia dodatkowe',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Nowa funkcjonalność: Moduł rozliczeń za zajęcia dodatkowe a w tym:',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Możliwość utworzenia nowego schematu płatności (dzienny lub miesięczny) za wybrane zajęcia dodatkowe',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Nowa kategoria rozliczeń za zajęcia dodatkowe',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Możliwość wygenerowania rozliczenia dla dzieci za zajęcia dodatkowe',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Aby skorzystać z nowych funkcjonalności należy utworzyć schemat płatności za wybrane zajęcia dodatkowe, zapisać dziecko na zajęcia dodatkowe oraz przypisać schemat płatności dla tego dziecka',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowy raport: Wydruk kwitariuszy zajęć dodatkowych',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowy raport: lista obecności na zajęciach dodatkowych',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Zmiany w raporcie "Wydruk kwitariuszy ogólny (xls)"',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Aktualizacja istniejących raportów związanych z płatnościami o zajęcia dodatkowe',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowa funkcjonalność: Możliwość wprowadzenia i edycji opisu albumu w galerii zdjęć',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Poprawki i usprawnienia działania systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ],
    works: [
      {
        title: 'Bardziej zaawansowany moduł rozliczania pracowników, wraz z nadpłatami, portfelem itd.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Grafik pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowy dziennik elektroniczny',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Arkusz organizacyjny',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2022-04-06').format('YYYY-MM-DD'),
    title: '',
    changes: [
      {
        title:
          'Nowa funkcjonalność: Import dzieci z pliku CSV. System umoliwia zaimportowanie dzieci wraz z rodzicami z automatycznym utworzeniem kont dla rodziców',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Na widoku importu dzieci są opisane kolumny wraz z adnotacją, czy dana kolumna jest wymagana czy nie',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'W przypadku błędnych danych, zostanie wyświetlona informacja',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title:
          'Nowa funkcjonalność: ustawianie rabatów w przypadku nieobecności ciągłej, gdy placówka chce naliczać rabat po ustalonych dniach ciągłej nieobecności dziecka',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Stawkę rabatu za każdy kolejny dzień nieobecności można ustalić kwotowo jak i procentowo',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Rabaty za nieobecność ciągłą definiuje się w schematach płatności',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Nowa funkcjonalność: Widok osób upoważnionych do odbioru dziecka, w którym w wygodny sposób przedstawione są osoby wraz z dodatkowymi danymi, możliwością wyszukiwania. Zachęcamy do wypróbowania!',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Możliwość włączenia powiadomienia o ukończeniu przez dziecko 2.5 roku życia. Powiadomienie pojawi się w rejestrze dzieci. Opcję tą można włączyć w konfiguracji placówki',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Widok na panelu rodzica zachęcający do zainstalowania nowej aplikacji 4Parents',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Zmiany i poprawki w istniejących raportach',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Poprawki i usprawnienia działania systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ],
    works: [
      {
        title:
          'Nowy moduł obecności na zajęciach dodatkowych oraz możliwość prowadzenia rozliczeń za zajęcia dodatkowe, który pojawi się w kwietniu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Grafik pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowy dziennik elektroniczny',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'I wiele więcej :)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2022-02-28').format('YYYY-MM-DD'),
    title: '',
    changes: [
      {
        title: 'Nowe funkcjonalności w dysku wirtualnym, m.in udostępnianie:',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Udostępnianie plików dla wybranych osób / pracowników oraz grup do których są przypisani rodzice',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title:
          'Zmiany na ekranie logowania poprzez PIN - potwierdzenia i powiadomienia są bardziej przejrzyste dla użytkownika',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dostosowanie widoku panelu udostępniania w dysku wirtualnym na smartfonach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title:
          'Nowy raport: Ilość dzieci, podsumowujący ilość dzieci w danych grupach w dniu wygenerowania. Dostępny w zakładce "Raporty"',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Nowy raport: Zestawienie płatności dziecka, znajdujący się w Rejestr Dzieci -> Dziecko -> Zakładka Dokumenty -> Zestawienie płatności dziecka',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowy raport: Wydruk kwitariuszy (ogólny) (.xls) przedstawiający opłaty per dziecko',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowe filtry na koncie opiekunów - możliwość wyfiltrowania konta na aktywne i nieaktywne',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'W "Historia Obecności" będąc w danym dniu można wybrać grupe bez potrzeby powrotu do kalendarza i wyboru grupy',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Przy wprowadzaniu obecności dziecka, godziny obecności są proponowane wg. godzin otwarcia placówki',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Możliwość przejścia do dziennika obecności klikając w kafelek grupy na pulpicie',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Poprawki i usprawnienia działania systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ],
    works: [
      {
        title:
          'Nowy moduł obecności na zajęciach dodatkowych oraz możliwość prowadzenia rozliczeń za zajęcia dodatkowe',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Grafik pracowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowy dziennik elektroniczny',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Arkusz organizacyjny',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'I wiele więcej :)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2022-01-08').format('YYYY-MM-DD'),
    title: '',
    changes: [
      {
        title: 'Zupełnie nowa aplikacja mobilna, która pojawi się w ciągu najbliższych dni!',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher,
          userRoles.legalGuardian
        ],
        strong: true
      },
      {
        title:
          'Zmiany w funkcjonalności "Aktywność dzieci". Aby uruchomić tą funkcję, należy przejść do Konfiguracja -> Aktywność dzieci. Pracownik placówki może regularnie wprowadzać wybrane informacje na temat dziecka, które zobaczy również rodzic w swojej aplikacji. Gorąco zachęcamy do korzystania!',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title:
          'Nowy raport: Obecność dzieci w interwałach godzinowych (miesięczna, dla całej placówki lub poszczególnych grup). Raport przedstawia ilość obecnych dzieci w interwałach godzinowych',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title:
          'Dodana obsługa nowych języków podczas tworzenia kont opiekunów, wraz z nowymi mailami aktywacyjnymi i resetu hasła dla obcojęzycznych rodziców. Wspierane języki: polski, angielski, ukraiński, niemiecki.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Wyszukiwarka w schematach płatności',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Zmiany i poprawki w rejestrze dzieci i panelu szczegółów dziecka, co eliminuje problem z niepoprawnym wyświetlaniem indywidualnych kont bankowych',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Dodano w całym systemie obsługę polskich znaków w przypadku sortowania, jak lista kont opiekunów, dziennik obecności itd.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Poprawki w funkcji "Wyświetlenia" w ogłoszeniach',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Nowe funkcjonalności w wiadomościach. Na liście opiekunów prawnych są przedstawione dzieci rodziców wraz z wyszukiwarką i filtrami.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Na liście "Nowa wiadomość" można wybrać rodzica, z którym konwersacja została rozpoczęta wcześniej, w celu ułatwienia komunikacji',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Poprawki podsumowania obecności na pulpicie',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      }
    ]
  },
  {
    date: moment('2021-12-13').format('YYYY-MM-DD'),
    title: '',
    changes: [
      {
        title: 'Nowa funkcjonalność: indywidualne numery kont bankowych dla dzieci.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Aby włączyć tą funkcję, należy przejść do zakładki Konfiguracja -> Placówka. Po włączeniu, w rejestrze dzieci pojawią się trzy dostępne numery kont',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Indywidualne numery konta są opcjonalne np. w przypadku, gdy dziecko ma mieć zdefiniowany indywidualny numer konta tylko za Pobyt',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'W przypadku braku indywidualnego numeru konta, przelew zostanie wykonany na numer konta bankowego zdefiniowany w schemacie płatności',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title:
          'Nowa funkcjonalność: możliwość sprawdzenia kto odczytał/nie odczytał ogłoszenia wraz z dodatkowymi filtrami ułatwiającymi pracę',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Nowa funkcjonalność: Generowanie haseł dla rodziców (wraz z aktywacją konta) oraz pracowników.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Nowa funkcjonalność: Resetowanie haseł dla rodziców oraz pracowników.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Pracownik placówki może wygenerować hasło dla rodzica z poziomu "Konta opiekunów -> Generuj/Zresetuj hasło dostępne w menu (3 kropki).',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Nowy raport: Lista Ogłoszeń, który przedstawia ogłoszenia z dodatkowymi filtrami w formacie PDF.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Zmiany i poprawki w menu "Zaległe opłaty" - dodana możliwość sortowania danych po kolumnach oraz dodana paginacja',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Poprawki wyświetlania kalendarza obecności',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      },
      {
        title: 'Usprawnienia działania systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ]
  },
  {
    date: moment('2021-11-12').format('YYYY-MM-DD'),
    title: '',
    changes: [
      {
        title:
          'Nowa opcja w konfiguracji placówki: Możliwość edytowania postów przez każdego pracownika. Włączając tą opcję, wpisy w ogłoszeniach, kalendarzu wydarzeń oraz dzienniku elektronicznym prócz autora i dyrektora może modyfikować inny pracownik',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Zmiany w raporcie "Lista zaplanowanych obecności". Do raportu można dołączyć dodatkową kolumnę zawierającą infromację o uczuleniach dzieci,',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Poprawki w raportach i usprawnienia działania systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ]
  },
  {
    date: moment('2021-11-08').format('YYYY-MM-DD'),
    title: '',
    changes: [
      {
        title: 'Nowy moduł systemu: Dysk wirtualny, który umożliwia:',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberDigitalDiary,
          userRoles.legalGuardian
        ],
        strong: true
      },
      {
        title: 'Załadowanie plików do systemu, w tym wielu plików na raz',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberDigitalDiary
        ],
        strong: false
      },
      {
        title: 'Udostępnianie plików dla wybranych ról',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberDigitalDiary
        ],
        strong: false
      },
      {
        title: 'Sortowanie i filtrowanie plików według ich rodzaju, daty dodania oraz komu zostały udostępnione',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberDigitalDiary
        ],
        strong: false
      },
      {
        title: 'Sortowanie i filtrowanie plików według ich rodzaju',
        visibleFor: [userRoles.legalGuardian],
        strong: false
      },
      {
        title: 'Dwa rodzaje widoków: kafelkowy oraz tabelowy z dodatkowymi informacjami',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberDigitalDiary,
          userRoles.legalGuardian
        ],
        strong: false
      },
      {
        title: 'Możliwość skopiowania linku do danego pliku oraz umożliwienie osadzenia go, np. w ogłoszeniach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberDigitalDiary
        ],
        strong: false
      },
      {
        title:
          'Możliwość zaznaczania wielu plików na raz (klawisze CTRL zaznaczając pojedynczo lub klawisz SHIFT zaznaczający pliki OD-DO) w celu pobrania',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberDigitalDiary,
          userRoles.legalGuardian
        ],
        strong: false
      },
      {
        title:
          'Dysk wirtualny jest dostępny również dla rodziców z poziomu aplikacji WEB. W przyszłości funkcja ta zostanie również dodana do aplikacji mobilnej',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher,
          userRoles.staffMemberDigitalDiary
        ],
        strong: false
      },
      {
        title:
          'Dodana paginacja w rejestrze dzieci oraz liście opiekunów, ułatwiająca pracę w przypadku dużej ilości dzieci',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title:
          'Nowy raport: Lista zaplanowanych obecności. Raport generuje listę dzieci, które nie mają zaplanowanej obecności w momencie wygenerowania na wybrany dzień, np. na potrzeby zamówień cateringu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title:
          'Zmiany w raportach obecności oraz nieobecności. Raporty umożliwiają teraz dodatkowo zaznaczenie dzieci zarchiwizowanych, w razie generowania raportu np. za wcześniejszy okres',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Poprawki w raportach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Usprawnienia działania systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Czekamy na Państwa opinie :)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2021-11-03').format('YYYY-MM-DD'),
    title: '',
    changes: [
      {
        title:
          'Nowy raport: "Lista zaplanowanych obecności". Zawiera listę dzieci, które nie mają zgłoszonej nieobecności w momencie wygenerowania raportu na wybrany dzień.',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title: 'Usprawnienia działania systemu',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: false
      }
    ]
  },
  {
    date: moment('2021-10-18').format('YYYY-MM-DD'),
    title: '',
    changes: [
      {
        title: 'Poprawki w ewidencji dziecka',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Nowa funkcjonalność: możliwość zgłaszania obecności całodniowych, bez potrzeby wprowadzania wejścia i wyjścia dziecka. Obecność jest wprowadzana na podstawie zadeklarowanych godzin obecności dziecka. Aby uruchomić funkcjonalność, należy przejść do Konfiguracja -> Placówka',
        visibleFor: [userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal, userRoles.staffMemberSecretary],
        strong: true
      },
      {
        title:
          'Nowa funkcjonalność: możliwość zgłaszania nieobecności całodniowych, bez potrzeby wprowadzania wejścia i wyjścia dziecka',
        visibleFor: [userRoles.staffMemberTeacher],
        strong: true
      }
    ]
  },
  {
    date: moment('2021-10-08').format('YYYY-MM-DD'),
    title: '',
    changes: [
      {
        title:
          'Nowy moduł systemu w kategorii Wewnętrzne: “Planowane przeniesienia”. Moduł umożliwia zaplanowanie przypisania/wypisania dzieci do/z grup i zajęć dodatkowych na konkretny wybrany dzień',
        visibleFor: [userRoles.staffMemberPrincipal],
        strong: true
      },
      {
        title: `W module “Wiadomości” został dodany przycisk filtracji dla listy korespondentów.
           Umożliwia on filtrację użytkowników po roli oraz grupie z którą docelowa osoba jest związana.
            W korespondencji z opiekunem prawnym została dodana informacja o przypisanych mu dzieciach (prawy górny róg)`,
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title:
          'Zmiany oraz poprawki w raportach oraz dzienniku elektronicznym (dla planu pracy, wycieczek oraz zajęć dodatkowych)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodatkowe informacje w raporcie zapisu pracy wychowawczo-dydaktycznej',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Poprawki w ogłoszeniach',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Wydłużono czas na kliknięcie w link aktywacyjny',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Wydłużona długość czasu ważności hasła',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Wydłużony czas sesji zalogowanego użytkownika',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title: 'Usprawnienia działania systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ]
  },
  {
    date: moment('2021-07-21').format('YYYY-MM-DD'),
    title: '',
    changes: [
      {
        title:
          'Dodano nowy moduł systemu zawierający wewnętrzne ogłoszenia i kalendarz wydarzeń, w kategorii "Wewnętrzne", widoczny tylko dla pracowników placówki.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title:
          'Dodano nową rolę w systemie: Nauczyciel - zajęcia dodatkowe. Rola ta ma uprawnienia do dziennika elektronicznego, zajęć dodatkowych i wewnętrznych ogłoszeń.',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawnienia działania systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ]
  },
  {
    date: moment('2021-07-16').format('YYYY-MM-DD'),
    title: null,
    changes: [
      {
        title: 'Dodano nowy moduł systemu: Galeria',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodano możliwość zablokowania zgłaszania nieobecności w dni wolne ( Konfiguracja -> Placówka)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodano nowy moduł systemu: Galeria (wersja przeglądarkowa)',
        visibleFor: [userRoles.legalGuardian],
        strong: true
      },
      {
        title: 'Zaktualizowano politykę haseł w celu zwiększenia bezpieczeństwa',
        visibleFor: ['all'],
        strong: true
      },
      {
        title: 'Dodano zmiany/poprawki w raportach (m.in lista nieobecności, raport zadłużonych rodziców)',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawnienia działania systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ]
  },
  {
    date: moment('2021-05-06').format('YYYY-MM-DD'),
    title: null,
    changes: [
      {
        title:
          'Dodano ewidencję opłat za pobyt oraz wyżywienie w szczegółach dziecka (Rejestr dzieci -> Dziecko -> Zakładka opłaty) ',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodano opcję wygenerowania raportu xls na podstawie ewidencji dziecka ',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Usprawnienia działania systemu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ]
  },
  {
    date: moment('2021-04-30').format('YYYY-MM-DD'),
    title: null,
    changes: [
      {
        title: 'Dodano raport lista obecności w formacie xls',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodano raport lista nieobecności w formacie xls',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodano raport lista obecności godzinowa w formacie xls',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodano raport lista obecnosci ( godziny płatne ) w formacie xls',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      }
    ]
  },
  {
    date: moment('2021-03-31').format('YYYY-MM-DD'),
    title: null,
    changes: [
      {
        title:
          'Nowa opcja w menu: Konfiguracja -> Rejestr zdarzeń, która wyświetla zmiany i operacje jakie zachodziły w systemie (np. odbiór dziecka przez rodzica, zmiana danych użytkownika, dokonana płatność itd.) oraz możliwość pobrania raportu w formacie XML',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      // {
      //   title: 'Nowa funkcja: rodzic może upoważnić osobę do odbioru dziecka profilem zaufanym bez potrzeby akceptacji przez dyrektora. Upoważniona osoba automatycznie zostanie przypisana do danego dziecka.',
      //   visibleFor: ['all'],
      //   strong: true,
      // },
      {
        title: 'Dostosowano aplikację dla osób z dysfunkcjami',
        visibleFor: ['all'],
        strong: true
      },
      {
        title: 'Zmieniono wygląd aplikacji',
        visibleFor: ['all'],
        strong: true
      },
      {
        title: 'Ulepszono wygląd aplikacji na urządzeniach mobilnych i tabletach',
        visibleFor: ['all'],
        strong: true
      },
      {
        title: 'Usprawniono działanie całego systemu',
        visibleFor: ['all'],
        strong: true
      },
      {
        title: 'Zmieniono i usprawniono podgląd zdjęć. Teraz możliwe jest przybliżanie oraz pobieranie zdjęcia',
        visibleFor: ['all'],
        strong: true
      },
      {
        title:
          'Nowa opcja w menu: Wnioski, dzięki której rodzic może złożyć wniosek o przypisanie dziecka do zajęć dodatkowych oraz do złożenia wniosku o przypisanie dziecka na obiady (schemat płatności o kategorii wyżywienia)',
        visibleFor: ['all'],
        strong: true
      },
      {
        title: 'Dodano możliwość wstawiania emotikon w wiadomościach',
        visibleFor: ['all'],
        strong: true
      },
      {
        title:
          'Nowa opcja w menu: Konfiguracja -> Zalogowani użytkownicy, która umożliwia wyświetlenie aktualnie zalogowanych użytkowników w systemie',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title:
          'Nowa opcja w menu: Wnioski, dzięki której dyrektor może zaakceptować bądź odrzucić wniosek złożony o przypisanie dziecka do zajęć dodatkowych lub na schemat płatności wyżywienia. Po akceptacji dziecko automatycznie zostanie dodane do zajęć dodatkowych lub zostanie mu przypisany schemat płatności',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodano widok z historią logowań użytkowników',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodano widok z listą zmian w profilach pracowników oraz opiekunów',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title:
          'Zmieniono edytor tekstu w ogłoszeniach, kalendarzu wydarzeń i kilku innych miejscach, który zawiera więcej funkcjonalności: np. kolorowanie tekstu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodano emotikony do edytora tekstu',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: true
      },
      {
        title: 'Dodano tryb wysokiego konstrastu (z prawej strony u góry)',
        visibleFor: ['all'],
        strong: false
      },
      {
        title: 'Dodano opis rozszerzeń pobieranych plików',
        visibleFor: ['all'],
        strong: false
      },
      {
        title: 'Usprawniono pobieranie załączników',
        visibleFor: ['all'],
        strong: false
      },
      {
        title: 'Usprawniono działanie wyszukiwania odbiorcy podczas tworzenia nowej wiadomości',
        visibleFor: ['all'],
        strong: false
      },
      {
        title: 'Przeniesiono tabele zaległych opłat oraz kończących się ulg do zakładki Rozliczenia',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      },
      {
        title:
          'Dodano możliwość ustawienia w schematach płatności wyżywienia czy rodzic ma możliwość złożyć wniosek o przypisanie dziecka na dany schemat płatności',
        visibleFor: [
          userRoles.staffMemberAccountant,
          userRoles.staffMemberCanteenWorker,
          userRoles.staffMemberPrincipal,
          userRoles.staffMemberSecretary,
          userRoles.staffMemberTeacher
        ],
        strong: false
      }
    ]
  }
];

const styles = {
  listItem: {
    margin: '10px 0',
    fontSize: '16px'
  }
};

const compare = (a, b) => {
  if (a.strong && !b.strong) return -1;
  if (a.strong && b.strong) return 0;
  if (!a.strong && b.strong) return 1;
  return 0;
};

const ChangeLogPage = (props) => {
  return (
    <Box>
      <PageHeaderText title=" Co nowego?" titleIcon={<VerifiedOutlinedIcon />} />
      <ContentWrapper>
        {/* <Masonry columns={{ xs: 1, lg: 2 }} spacing={2} sx={{ mx: -2, mt: 0, p: 0, width: 'auto' }}> */}
          {releases.map((release, index) => {
            if (
              release.changes.some(
                (change) => change.visibleFor.includes('all') || props.auth.isStaffMember(change.visibleFor)
              ) ||
              (release.works &&
                release.works.some(
                  (work) => work.visibleFor.includes('all') || props.auth.isStaffMember(work.visibleFor)
                ))
            )
              return (
                <Box key={index}>
                  <Paper sx={{ p: 4, mb: 4 }}>
                    {release.changes.some(
                      (change) => change.visibleFor.includes('all') || props.auth.isStaffMember(change.visibleFor)
                    ) && (
                      <>
                        <Typography
                          variant="h5"
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            fontWeight: (theme) => theme.typography.fontWeightBold
                          }}>
                          Aktualizacja z dnia: {moment(release.date).format('DD.MM.YYYY')}
                        </Typography>
                        <br />
                        {release.title && <Typography>{release.title}</Typography>}
                        <List
                          sx={{
                            // maxWidth: 700,
                            pl: 3,
                            listStyleType: 'disc',
                            '& .MuiListItem-root': {
                              display: 'list-item'
                            },
                            '& .MuiListItem-gutters': {
                              color: (theme) => theme.palette.color.color25
                            }
                          }}>
                          {release.changes.map((change, id) => {
                            return change.visibleFor.includes('all') || props.auth.isStaffMember(change.visibleFor) ? (
                              <ListItem style={styles.listItem} key={id}>
                                <Typography sx={{ color: (theme) => theme.palette.color.color2 }}>
                                  {change.strong ? <Strong>{change.title}</Strong> : change.title}
                                </Typography>
                              </ListItem>
                            ) : null;
                          })}
                        </List>
                      </>
                    )}
                    {release.works &&
                      release.works.some(
                        (work) => work.visibleFor.includes('all') || props.auth.isStaffMember(work.visibleFor)
                      ) && (
                        <>
                          <Typography
                            variant="h5"
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              mt: 4,
                              color: (theme) => theme.palette.color.color25
                            }}>
                            <InProgressIcon
                              fontSize="large"
                              sx={{ mr: 1, color: (theme) => theme.palette.color.color25 }}
                            />
                            Nad czym pracujemy?
                          </Typography>
                          <List
                            sx={{
                              maxWidth: 700,
                              listStyleType: 'disc',
                              pl: 3,
                              '& .MuiListItem-root': {
                                display: 'list-item'
                              }
                            }}>
                            {release.works.sort(compare).map((change, id) => {
                              return change.visibleFor.includes('all') ||
                                props.auth.isStaffMember(change.visibleFor) ? (
                                <ListItem style={styles.listItem} key={id}>
                                  <Typography variant="subtitle2">
                                    {change.strong ? <Strong>{change.title}</Strong> : change.title}
                                  </Typography>
                                </ListItem>
                              ) : null;
                            })}
                          </List>
                        </>
                      )}
                  </Paper>
                </Box>
              );
          })}
        {/* </Masonry> */}
      </ContentWrapper>
    </Box>
  );
};

ChangeLogPage.propTypes = {
  auth: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    currentRoute: state.routing.current.matchedRoute,
    isDaycareEnabled: state.configuration.daycare.enabled,
    features: state.configuration.unit.features,
    fkIntegration: state.configuration.settlementsSettings.integrationEnabled,
    symphonyIntegration: state.configuration.settlementsSettings.symphonyIntegrationEnabled
  };
}

function mapDispatchToProps(dispatch) {
  return {
    importActions: bindActionCreators(importChildrenActions, dispatch),
    exportActions: bindActionCreators(exportChildrenActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLogPage);
