import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as staffMembersActions from '../../actions/staffMembersActions';
import StaffMembersList from './StaffMembersList';
import normalStaffMemberRoles from '../../constants/staffMemberRoles';
import reducedStaffMemberRoles from '../../constants/reducedStaffMemberRoles';
import { Paper, Button, Grid, Box } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { browserHistory } from 'react-router';
import FilterBar from '../common/FilterBar';
import { routePaths } from '../../routePaths';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import EmptyState from '../common/EmptyState';

class StaffMembersPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      filteredStaffMembers: [],
      staffMemberRoles: props.nadarzyn ? reducedStaffMemberRoles : normalStaffMemberRoles
    };
    this.redirectToAddStaffMemberPage = this.redirectToAddStaffMemberPage.bind(this);
    this.redirectToStaffMembersArchivePage = this.redirectToStaffMembersArchivePage.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleLoaded = this.handleLoaded.bind(this);
  }

  redirectToStaffMembersArchivePage() {
    browserHistory.push(routePaths.staffMembersArchive);
  }

  redirectToAddStaffMemberPage() {
    browserHistory.push(routePaths.staffMemberRegister);
  }

  handleFilterChange(filteredList) {
    this.setState({
      filteredStaffMembers: filteredList
    });
  }

  handleLoaded() {
    this.setState({
      filteredStaffMembers: this.props.staffMembers
    });
  }

  render() {
    return (
      <LoadingRenderWrapper onLoaded={this.handleLoaded}>
        <Box>
          <PageHeaderText title="Pracownicy" titleIcon={<AssignmentIndIcon sx={{ mr: 1 }} />} />
          <ContentWrapper>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box>
                  <FilterBar
                    itemsToFilter={this.props.staffMembers}
                    onFilter={this.handleFilterChange}
                    filter={(staff, keyword) =>
                      staff.lastName.toLowerCase().includes(keyword) || staff.firstName.toLowerCase().includes(keyword)
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  aria-label="Dodaj pracownika"
                  onClick={this.redirectToAddStaffMemberPage}
                  sx={{ my: 2 }}>
                  Dodaj pracownika
                </Button>
              </Grid>
            </Grid>
            {this.state.filteredStaffMembers.length > 0 && (
              <Paper sx={{ mb: 2, p: 2 }}>
                <StaffMembersList
                  router={this.props.router}
                  staffMembers={this.state.filteredStaffMembers}
                  roles={this.state.staffMemberRoles}
                />
              </Paper>
            )}
            {this.state.filteredStaffMembers.length === 0 && (
              <EmptyState isPaper contrast message="Brak wyników wyszukiwania" />
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              <Button variant="outlined" aria-label="Pokaż archiwalne" onClick={this.redirectToStaffMembersArchivePage}>
                Pokaż archiwalne
              </Button>
            </Box>
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

StaffMembersPage.propTypes = {
  staffMembers: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  nadarzyn: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    staffMembers: state.staffMembers,
    nadarzyn: state.configuration.unit.features.nadarzyn
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(staffMembersActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffMembersPage);
