import PropTypes from 'prop-types';
import React from 'react';
import {
  InputLabel,
  MenuItem,
  ListItemText,
  FormControl,
  Select,
  FormHelperText,
  ListItemIcon,
  Checkbox
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FieldsetWrapper from '../../forms/FieldsetWrapper';

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  shrink: {
    color: (contrast) => `${contrast ? theme.palette.color.primary : theme.palette.color.contrast} !important`
  }
}));

const RichMultiSelectField = ({
  options,
  sx,
  label,
  type,
  selectElement,
  chipElement,
  onChange,
  value,
  error,
  errorText,
  onDelete,
  isSelectAll = false,
  specialOptions,
  specialMenuOptions,
  SelectDisplayStylesProps,
  contrast
}) => {
  const cl = useStyles(contrast);
  const selectedData = [...options, ...(specialOptions || [])].filter((item) => value.some((x) => x.id === item.id));
  const filteredData = options.filter((x) => !selectedData.some((y) => x.id === y.id));
  const isAllSelected = filteredData.length === 0;
  const isSpecialOptionsSelected =
    specialOptions &&
    specialOptions.length &&
    selectedData.some((item) => specialOptions.some((special) => special.id === item.id));

  const handleChange = (e) => {
    const values = e.target.value;
    if (values.includes('all')) {
      onChange([...options]);
    } else if (values.includes('deleteAll')) {
      onChange([]);
    } else if (specialOptions && specialOptions.length) {
      const tempValues = [...values];
      const lastValue = tempValues.pop();
      if (specialOptions.some((option) => option.id === lastValue.id)) {
        onChange([lastValue]);
      } else {
        onChange(values.filter((insideValue) => !specialOptions.some((option) => option.id === insideValue.id)));
      }
    } else {
      onChange(values);
    }
  };

  const handleOnDelete = (id) => {
    onDelete(selectedData.filter((item) => item.id !== id));
  };

  const selectConfig = {
    value: selectedData,
    onChange: handleChange,
    renderValue: (x) => {
      if (chipElement) {
        return x.map((element) =>
          React.cloneElement(chipElement, {
            type,
            sx: {
              color: (theme) => (contrast ? theme.palette.color.primary : theme.palette.color.contrast),
              '& .MuiSvgIcon-root': {
                fill: (theme) => (contrast ? theme.palette.color.primary : theme.palette.color.contrast)
              }
            },
            contrast,
            ...element,
            key: element.id,
            onDelete: () => handleOnDelete(element.id)
          })
        );
      }
      return isAllSelected ? 'Wszystkie' : x.map((a) => a.name).join(', ');
    },
    SelectDisplayProps: {
      sx: {
        display: 'grid',
        gridTemplateColumns: isSpecialOptionsSelected ? '1fr' : 'repeat(auto-fit, 150px)',
        p: isSpecialOptionsSelected ? '5px 80px' : undefined,
        gridGap: 10,
        ...SelectDisplayStylesProps
      }
    },
    labelId: 'mutiple-select-label',
    multiple: true,
    autoWidth: true
  };

  return (
    <>
      <FieldsetWrapper sx={{ mt: 2, ...sx }} legend={label}>
        <FormControl variant="standard" className={cl.formControl} error={error}>
          <InputLabel
            sx={{
              ml: 0,
              color: (theme) => (contrast ? theme.palette.color.primary : theme.palette.color.contrast),
              '&.Mui-focused': {
                color: (theme) => (contrast ? theme.palette.color.primary : theme.palette.color.contrast)
              }
            }}
            htmlFor="mutiple-select-label">
            {label}
          </InputLabel>
          <Select
            disableUnderline
            {...selectConfig}
            inputProps={{ id: 'mutiple-select-label' }}
            sx={{
              borderBottom: (theme) =>
                `1px solid ${contrast ? theme.palette.color.primary : theme.palette.color.contrast}`,
              '& .MuiSelect-icon': {
                fill: (theme) => (contrast ? theme.palette.color.primary : theme.palette.color.contrast)
              },
              color: (theme) => (contrast ? theme.palette.color.primary : theme.palette.color.contrast),
              ':before': {
                borderBottomColor: (theme) => (contrast ? theme.palette.color.primary : theme.palette.color.contrast)
              },
              ':after': {
                borderBottomColor: (theme) => (contrast ? theme.palette.color.primary : theme.palette.color.contrast)
              }
            }}>
            {[
              ...(specialMenuOptions || []),
              isSelectAll && (
                <MenuItem key={0} value={isAllSelected ? 'deleteAll' : 'all'}>
                  <ListItemIcon>
                    <Checkbox checked={isAllSelected} indeterminate={selectedData.length > 0 && !isAllSelected} />
                  </ListItemIcon>
                  <ListItemText primary={isAllSelected ? 'Odznacz wszystkie' : 'Wybierz wszystkie'} />
                </MenuItem>
              ),
              options.map((option) => (
                <MenuItem key={option.id} value={option}>
                  <ListItemIcon>
                    <Checkbox checked={selectedData.some((item) => item.id === option.id)} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography={!!selectElement}
                    primary={selectElement ? React.cloneElement(selectElement, { type, ...option }) : option.name}
                  />
                </MenuItem>
              ))
            ]}
          </Select>
          {error && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
      </FieldsetWrapper>
    </>
  );
};

RichMultiSelectField.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  selectElement: PropTypes.node,
  chipElement: PropTypes.node,
  specialOptions: PropTypes.array,
  isSelectAll: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  specialMenuOptions: PropTypes.node,
  SelectDisplayStylesProps: PropTypes.object
};

export default RichMultiSelectField;
