import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MobileToolkit from '../components/Toolkit/MobileToolkit';
import Toolkit from '../components/Toolkit/Toolkit';
import ShareAndDeleteDialogContent from '../components/Dialogs/ShareAndDeleteDialogContent';
import ConfirmationDialog from '../../forms/ConfirmationDialog';
import { useDispatch } from 'react-redux';
import { deleteFiles } from '../../../actions/virtualDiscActions';
import uuid from 'uuid/v4';
import { downloadAndZip } from '../../../utils/prepareZip';
import * as notificationActions from '../../../actions/notificationActions';
import * as rightBottomNotificationTypes from '../../../constants/rightBottomNotificationTypes';
import * as Status from '../../../constants/status';
import { Alert, Typography } from '@mui/material';

const VirtualDiscToolkitContainer = (props) => {
  const [open, setOpen] = React.useState(false);
  const [isDeleteManyDialogOpen, setIsDeleteManyDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    isMobile,
    selectedFiles,
    files,
    cancelSelectedFiles,
    onShareFiles,
    hasSuperPermission,
    onSelectedView,
    selectedView,
    rowsPerPage,
    setRowsPerPage,
    rowsPerPageVisible,
    handleOpenMoveToFolderDialog
  } = props;

  const preparedFiles = files.filter((item) => selectedFiles.includes(item.id));

  const missingPermissionsItemsArray = hasSuperPermission ? [] : preparedFiles.filter((item) => !item.isOwnByUser);
  const isInvalid = missingPermissionsItemsArray.length > 0;

  const isSelected = selectedFiles.length > 0;
  const isSingleDelete = selectedFiles.length === 1;
  const dispatch = useDispatch();

  const handleDownloadFiles = () => {
    const notifyUuid = uuid();
    downloadAndZip(
      preparedFiles,
      () =>
        dispatch(
          notificationActions.startRightBottomNotification(notifyUuid, rightBottomNotificationTypes.DOWNLOAD, {
            status: Status.PENDING
          })
        ),
      () =>
        dispatch(
          notificationActions.updateRightBottomNotification(notifyUuid, {
            status: Status.SUCCESS
          })
        ),
      () =>
        dispatch(
          notificationActions.updateRightBottomNotification(notifyUuid, {
            status: Status.ERROR
          })
        ),
      'Wybrane-pliki'
    );
  };

  const handleDeleteFiles = async () => {
    setIsLoading(true);
    await dispatch(deleteFiles(selectedFiles.map((item) => item.id)));
    setIsLoading(false);
    setIsDeleteManyDialogOpen(false);
    cancelSelectedFiles();
  };
  const onOpenMoveToFolder = () => {
    handleOpenMoveToFolderDialog(selectedFiles);
  };
  return (
    <>
      {isMobile ? (
        <MobileToolkit
          isOpen={open}
          handleOpen={() => {
            setOpen(true);
          }}
          isMobile={isMobile}
          handleClose={() => setOpen(false)}
          isSelected={isSelected}
          downloadFiles={handleDownloadFiles}
          shareFiles={onShareFiles}
          onOpenMoveToFolder={onOpenMoveToFolder}
          deleteFiles={() => setIsDeleteManyDialogOpen(true)}
          {...props}
        />
      ) : (
        <Toolkit
          {...props}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          onOpenMoveToFolder={onOpenMoveToFolder}
          isMobile={isMobile}
          downloadFiles={handleDownloadFiles}
          shareFiles={onShareFiles}
          deleteFiles={() => setIsDeleteManyDialogOpen(true)}
          isSelected={isSelected}
          onSelectedView={onSelectedView}
          selectedView={selectedView}
          rowsPerPageVisible={rowsPerPageVisible}
        />
      )}
      <ConfirmationDialog
        confirmationTitle={`Czy na pewno chcesz usunąć ${isSingleDelete ? 'plik' : 'pliki'}?`}
        onConfirm={handleDeleteFiles}
        inProgress={isLoading}
        onCancel={() => {
          setIsDeleteManyDialogOpen(false);
        }}
        confirmLabel="Usuń"
        isOpen={isDeleteManyDialogOpen}
        isSecondary
        contentStyle={{ padding: isMobile ? 0 : '8px 24px' }}
        isInvalid={isInvalid}>
        <>
          {isInvalid && (
            <Alert severity="error">
              <Typography>
                Nie posiadasz uprawnień do usunięcia
                {isSingleDelete ? ' wybranego pliku' : ' co najmniej jednego z wybranych plików'}
              </Typography>
            </Alert>
          )}
          <ShareAndDeleteDialogContent
            isMobile={isMobile}
            selectedItems={preparedFiles}
            missingPermissionsItemsArray={missingPermissionsItemsArray}
          />
        </>
      </ConfirmationDialog>
    </>
  );
};

VirtualDiscToolkitContainer.propTypes = {
  isMobile: PropTypes.bool,
  searchText: PropTypes.string.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelectedView: PropTypes.func.isRequired,
  selectedFiles: PropTypes.array.isRequired,
  selectedView: PropTypes.number.isRequired,
  cancelSelectedFiles: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  onShareFiles: PropTypes.func.isRequired,
  isManagement: PropTypes.bool.isRequired,
  hasSuperPermission: PropTypes.bool.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  rowsPerPageVisible: PropTypes.bool.isRequired
};

export default VirtualDiscToolkitContainer;
