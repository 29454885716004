import { Box, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { useState } from 'react';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RichTooltip from '../../richTooltip/richTooltip';
import PersonAvatar from '../../forms/PersonAvatar';
import FaceIcon from '@mui/icons-material/Face';
import KidsList from './KidsList';
import { useSelector } from 'react-redux';

const ParticipantList = ({ participants, getUserRole }) => {
  const [open, setOpen] = useState(false);
  const children = useSelector((state) => state.children);

  const getChildren = (participant) => {
    return children.filter((child) => child.legalGuardians.filter((lg) => lg.id === participant.id).length > 0);
  };
  return (
    <Box onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)} sx={{ cursor: 'help' }}>
      <RichTooltip
        content={
          <List>
            {participants.map((participant, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <PersonAvatar url={participant.photoUrl} fullName={participant.fullName} initials />
                </ListItemAvatar>
                <ListItemText primary={participant.fullName} secondary={getUserRole(participant).name} />
                {getChildren(participant).length > 0 && (
                  <ListItemAvatar sx={{ ml: 1 }}>
                    <KidsList childrenArray={getChildren(participant)} placement="bottom" />
                  </ListItemAvatar>
                )}
              </ListItem>
            ))}
          </List>
        }
        open={open}
        placement="top-end"
        onClose={() => setOpen(false)}>
        <Diversity3Icon
          aria-label="Pokaż uczestników"
          sx={{ color: (theme) => theme.palette.color.contrast, mr: 2 }}
          onClick={() => setOpen((prev) => !prev)}
        />
      </RichTooltip>
    </Box>
  );
};
export default ParticipantList;
