/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function psychologicalHelpUiReducer(state = initialState.psychologicalHelpUi, action) {
  switch (action.type) {
    //Notify
    case types.CREATE_NOTIFY_UI_START: {
      let newState = Object.assign({}, state);
      newState.notify = UiModel.start();
      return newState;
    }
    case types.CREATE_NOTIFY_UI_CANCEL: {
      let newState = Object.assign({}, state);
      newState.notify = UiModel.cancel();
      return newState;
    }
    case types.CREATE_NOTIFY_UI_SUBMIT: {
      let newState = Object.assign({}, state);
      newState.notify = UiModel.submit();
      return newState;
    }
    case types.EDIT_PSYCHOLOGICAL_NOTIFICATION: {
      let newState = Object.assign({}, state);
      newState.notify = UiModel.success();
      return newState;
    }
    case types.ADD_PSYCHOLOGICAL_NOTIFICATION: {
      let newState = Object.assign({}, state);
      newState.notify = UiModel.success();
      return newState;
    }
    case types.CREATE_NOTIFY_UI_FAILURE: {
      let newState = Object.assign({}, state);
      newState.notify = UiModel.failure(action.errors);
      return newState;
    }

    //IPET
    case types.CREATE_IPET_UI_START: {
      let newState = Object.assign({}, state);
      newState.ipet = UiModel.start();
      return newState;
    }
    case types.CREATE_IPET_UI_CANCEL: {
      let newState = Object.assign({}, state);
      newState.ipet = UiModel.cancel();
      return newState;
    }
    case types.CREATE_IPET_UI_SUBMIT: {
      let newState = Object.assign({}, state);
      newState.ipet = UiModel.submit();
      return newState;
    }
    case types.EDIT_IPET: {
      let newState = Object.assign({}, state);
      newState.ipet = UiModel.success();
      return newState;
    }
    case types.ADD_IPET: {
      let newState = Object.assign({}, state);
      newState.ipet = UiModel.success();
      return newState;
    }
    case types.CREATE_IPET_UI_FAILURE: {
      let newState = Object.assign({}, state);
      newState.ipet = UiModel.failure(action.errors);
      return newState;
    }

    //WOPFU
    case types.CREATE_WOPFU_UI_START: {
      let newState = Object.assign({}, state);
      newState.wopfu = UiModel.start();
      return newState;
    }
    case types.CREATE_WOPFU_UI_CANCEL: {
      let newState = Object.assign({}, state);
      newState.wopfu = UiModel.cancel();
      return newState;
    }
    case types.CREATE_WOPFU_UI_SUBMIT: {
      let newState = Object.assign({}, state);
      newState.wopfu = UiModel.submit();
      return newState;
    }
    case types.EDIT_WOPFU: {
      let newState = Object.assign({}, state);
      newState.wopfu = UiModel.success();
      return newState;
    }
    case types.ADD_WOPFU: {
      let newState = Object.assign({}, state);
      newState.wopfu = UiModel.success();
      return newState;
    }
    case types.CREATE_WOPFU_UI_FAILURE: {
      let newState = Object.assign({}, state);
      newState.wopfu = UiModel.failure(action.errors);
      return newState;
    }

    default:
      return state;
  }
}
