import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Typography, Stack } from '@mui/material';
import PersonAvatar from '../../forms/PersonAvatar';

const PostUpdateInfo = ({ post, isParagraph, noAvatar }) => {
  const { author, publishedAt, lastEditedAt, lastEditedBy } = post;
  if (lastEditedAt) {
    return (
      <Stack direction="row" spacing={1} sx={{ mb: 2 }} alignItems="center">
        {!noAvatar && lastEditedBy.fullName && <PersonAvatar fullName={lastEditedBy.fullName} initials />}
        <Stack direction={noAvatar ? 'row-reverse' : 'column'} spacing={noAvatar && 1}>
          {lastEditedBy.fullName && (
            <Typography
              component={isParagraph ? 'p' : 'span'}
              sx={{
                color: (theme) => theme.palette.color.color22,
                fontWeight: (theme) => theme.typography.fontWeightMedium
              }}>
              {lastEditedBy.fullName}
            </Typography>
          )}
          {noAvatar && <Typography> przez: </Typography>}
          <Typography
            component={isParagraph ? 'p' : 'span'}
            sx={{
              fontSize: noAvatar ? 16 : 14,
              color: (theme) => theme.palette.color.color22
            }}>
            Zaktualizowano {moment(lastEditedAt).calendar().toLowerCase()}
          </Typography>
        </Stack>
      </Stack>
    );
  }
  return author?.fullName || author?.userFullName ? (
    <Stack direction="row" spacing={1} sx={{ mb: 2 }} alignItems="center">
      {!noAvatar && (author.fullName || author.userFullName) && (
        <PersonAvatar fullName={author.fullName || author.userFullName} initials />
      )}
      <Stack direction={noAvatar ? 'row-reverse' : 'column'} spacing={noAvatar && 1}>
        {(author.fullName || author.userFullName) && (
          <Typography
            component={isParagraph ? 'p' : 'span'}
            sx={{
              color: (theme) => theme.palette.color.color22,
              fontWeight: (theme) => theme.typography.fontWeightMedium
            }}>
            {author.fullName || author.userFullName}
          </Typography>
        )}
        {noAvatar && <Typography> przez: </Typography>}
        {publishedAt && (
          <Typography
            component={isParagraph ? 'p' : 'span'}
            sx={{
              fontSize: noAvatar ? 16 : 14,
              color: (theme) => theme.palette.color.color22
            }}>
            Opublikowano {moment(publishedAt).calendar().toLowerCase()}
          </Typography>
        )}
      </Stack>
    </Stack>
  ) : (
    <Typography component={isParagraph ? 'p' : 'span'} sx={{ mb: 1 }}>
      Opublikowano {moment(publishedAt).calendar().toLowerCase()}
    </Typography>
  );
};

PostUpdateInfo.propTypes = {
  post: PropTypes.object.isRequired,
  noAvatar: PropTypes.bool
};

export default PostUpdateInfo;
