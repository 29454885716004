import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as activityActions from '../../actions/activitiesActions';
import * as childrenActions from '../../actions/childrenActions';
import ActivityDetailsCard from './details/ActivityDetailsCard';
import ActivityChildrenCard from './children/ActivityChildrenCard';
import ActionConfirmationButton from '../forms/buttons/ActionConfirmationButton';
import { ActivityDetailsModelValidator } from '../../models/activities/ActivityModelsValidator';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import ContentWrapper from '../common/contentWrapper';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import { Box } from '@mui/material';

class ActivityDetailsPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activity: Object.assign({}, this.props.activity)
    };
    this.onUpdateActivityState = this.onUpdateActivityState.bind(this);
    this.onSaveActivity = this.onSaveActivity.bind(this);
    this.onCancelActivity = this.onCancelActivity.bind(this);
    this.onEditActivity = this.onEditActivity.bind(this);
    this.onArchiveActivity = this.onArchiveActivity.bind(this);

    this.onSaveEnrolledChildren = this.onSaveEnrolledChildren.bind(this);
    this.onCancelEnrolledChildren = this.onCancelEnrolledChildren.bind(this);
    this.onEditEnrolledChildren = this.onEditEnrolledChildren.bind(this);
    this.onRemoveEnrolledChild = this.onRemoveEnrolledChild.bind(this);
    this.handleChangeActivityState = this.handleChangeActivityState.bind(this);

    this.handleLoaded = this.handleLoaded.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.activity !== prevProps.activity) {
      this.handleChangeActivityState(this.props.activity);
    }
  }

  handleChangeActivityState(value) {
    this.setState({ activity: Object.assign({}, value) });
  }

  onUpdateActivityState(event) {
    const field = event.target.name;
    const { activity } = this.state;
    activity[field] = event.target.value;
    return this.setState({ activity });
  }

  onSaveActivity() {
    this.props.actions.updateActivityDetailsAsync(this.state.activity);
  }

  onCancelActivity() {
    this.props.actions.updateActivityDetailsCancel();
    this.setState({ activity: Object.assign({}, this.props.activity) });
  }

  onEditActivity() {
    this.props.actions.updateActivityDetailsStart();
  }

  onEditEnrolledChildren() {
    this.props.actions.updateEnrolledChildrenStart();
  }

  async onSaveEnrolledChildren(childrenIds) {
    await this.props.actions.updateEnrolledChildrenAsync(this.props.activityId, [...childrenIds]);
    await this.props.actions.loadActivityDetailsAsync(this.props.activityId);
  }

  onCancelEnrolledChildren() {
    this.props.actions.updateEnrolledChildrenCancel();
    this.setState({ activity: Object.assign({}, this.props.activity) });
  }

  onRemoveEnrolledChild(childId) {
    this.props.actions.removeChildAsync(this.props.activityId, childId);
  }

  onArchiveActivity() {
    this.props.actions.archiveActivityDetailsAsync(this.props.activityId);
  }

  handleLoaded() {
    this.setState({ activity: Object.assign({}, this.props.activity) });
  }

  render() {
    return (
      <LoadingRenderWrapper onLoaded={this.handleLoaded}>
        <Box>
          <PageHeaderText title="Zajęcia dodatkowe" titleIcon={<GroupAddOutlinedIcon />} />
          <ContentWrapper>
            <ActivityDetailsCard
              activity={this.state.activity}
              onChange={this.onUpdateActivityState}
              onSave={this.onSaveActivity}
              onCancel={this.onCancelActivity}
              onEdit={this.onEditActivity}
              onValidate={() => new ActivityDetailsModelValidator().validate(this.state.activity)}
              isEditing={this.props.detailsUi.isEditing}
              isProcessing={this.props.detailsUi.isProcessing}
            />
            <ActivityChildrenCard
              activity={this.state.activity}
              pupils={this.props.pupils}
              onChange={this.onUpdateActivityState}
              onSave={this.onSaveEnrolledChildren}
              onCancel={this.onCancelEnrolledChildren}
              onEdit={this.onEditEnrolledChildren}
              onRemove={this.onRemoveEnrolledChild}
              isEditing={this.props.childrenUi.isEditing}
              isProcessing={this.props.childrenUi.isProcessing}
            />
            <ActionConfirmationButton
              actionLabel="archiwizuj"
              confirmationText="Czy na pewno chcesz zarchiwizować grupę zajęć dodatkowych?"
              confirmationTitle="Archiwizuj grupę"
              // isSecondary={true}
              confirmLabel="archiwizuj"
              variant="outlined"
              onConfirm={this.onArchiveActivity}
              onCancel={() => {}}
            />
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

ActivityDetailsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  childrenActions: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,
  activityId: PropTypes.string,
  pupils: PropTypes.array.isRequired,
  detailsUi: PropTypes.object.isRequired,
  childrenUi: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  const activityId = ownProps.params.id;
  return {
    activityId,
    detailsUi: state.activityDetailsUi.details,
    childrenUi: state.activityDetailsUi.enrolledChildren,
    activity: state.activityDetails,
    pupils: state.children
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch),
    childrenActions: bindActionCreators(childrenActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDetailsPage);
