'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import { CalculationStatus } from '../../constants/calculationStatus';
import { Box, Typography, useTheme } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

const SettlementsStatistics = ({ settlements, sx }) => {
  const approvedSettlements = settlements.filter((s) => s.calculationStatus === CalculationStatus.approved.value);
  const draftSettlements = settlements.filter((s) => s.calculationStatus === CalculationStatus.draft.value);
  const failedSettlements = settlements.filter((s) => s.calculationStatus === CalculationStatus.failed.value);
  const canceledSettlements = settlements.filter((s) => s.calculationStatus === CalculationStatus.canceled.value);
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'start',
        mt: { xs: 3, md: 2 },
        ...sx
      }}>
      <Box sx={{ mr: 1, display: 'flex', color: theme.palette.color.primary }}>
        <CircleIcon fontSize="1fr" sx={{ color: theme.palette.grey[500] }} />
        <Typography sx={{ mx: 1, fontWeight: theme.typography.fontWeightBold }}>{draftSettlements.length}</Typography>
        <Typography> oczekujących</Typography>
      </Box>
      <Box sx={{ mr: 1, display: 'flex', color: theme.palette.color.primary }}>
        <CircleIcon fontSize="1fr" sx={{ color: theme.palette.color.success }} />
        <Typography sx={{ mx: 1, fontWeight: theme.typography.fontWeightBold }}>
          {approvedSettlements.length}
        </Typography>
        <Typography> zatwierdzonych</Typography>
      </Box>
      <Box sx={{ mr: 1, display: 'flex', color: theme.palette.color.primary }}>
        <CircleIcon fontSize="1fr" sx={{ color: theme.palette.color.error }} />
        <Typography sx={{ mx: 1, fontWeight: theme.typography.fontWeightBold }}>{failedSettlements.length}</Typography>
        <Typography> błędnych</Typography>
      </Box>
      <Box sx={{ mr: 1, display: 'flex', color: theme.palette.color.primary }}>
        <CircleIcon fontSize="1fr" sx={{ color: theme.palette.color.cancel }} />
        <Typography sx={{ mx: 1, fontWeight: theme.typography.fontWeightBold }}>
          {canceledSettlements.length}
        </Typography>
        <Typography> anulowanych</Typography>
      </Box>
    </Box>
  );
};

SettlementsStatistics.propTypes = {
  settlements: PropTypes.array.isRequired,
  sx: PropTypes.object
};

export default SettlementsStatistics;
