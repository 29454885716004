import { Box, Divider } from '@mui/material';
import FieldsetWrapper from '../forms/FieldsetWrapper';
import { MealMultiPicker } from './MealMultiPicker';
import { useSelector } from 'react-redux';
import CustomTextField from '../common/TextField';

export const DietDetailsForm = ({ diet, onChange, errors }) => {
  const meals = useSelector((state) => state.meals);
  const categories = useSelector((state) => state.mealsCategories);

  return (
    <Box>
      <FieldsetWrapper legend="Pola dodania nowej diety">
        <CustomTextField
          variant="standard"
          hintText="Nazwa diety"
          floatingLabelText="Nazwa diety*"
          floatingLabelFixed={false}
          onChange={onChange}
          autoFocus={true}
          name="name"
          value={diet.name}
          fullWidth={true}
          errorText={errors.name}
        />
        <Divider sx={{ my: 2 }} />
        <MealMultiPicker
          meals={meals}
          categories={categories}
          selected={diet.meals}
          error={errors.meals}
          handleSelect={(meals) => onChange({ target: { name: 'meals', value: meals } })}
        />
      </FieldsetWrapper>
    </Box>
  );
};
