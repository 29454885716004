import { Box, Card, CardContent, CardHeader, MenuItem, Typography } from '@mui/material';
import React from 'react';
import Strong from '../../../common/Strong';
import DotsMenu from '../../../common/menu/DotsMenu';
import ActionConfirmationMenuItem from '../../../forms/ActionConfirmationMenuItem';
import PostHeader from '../../../digitalDiary/digitalDiaryMessageBoardPage/post/PostHeader';
import SpecialistCardPostHeader from './SpecialistCardPostHeader';

const SpecialistPostCard = ({ id, post, canEdit, onEdit, onDelete }) => {
  return (
    <Card>
      <CardHeader
        sx={{ pb: 1 }}
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SpecialistCardPostHeader post={post} />

            {canEdit && (
              <DotsMenu key={`dostMenu-${id}`}>
                {(onClose) => [
                  <MenuItem key={`edit-${id}`} onClick={() => onEdit()}>
                    Edytuj
                  </MenuItem>,
                  <ActionConfirmationMenuItem
                    id={id}
                    key={`action-${id}`}
                    actionLabel="Usuń"
                    confirmationText="Czy na pewno chcesz usunąć wpis?"
                    confirmationTitle="Usuń wpis"
                    confirmLabel="Usuń"
                    onItemClick={() => onClose()}
                    onConfirm={() => onDelete()}
                    onCancel={() => {}}
                  />
                ]}
              </DotsMenu>
            )}
          </Box>
        }
      />
      <CardContent
        sx={{
          py: 0,
          '& img': {
            maxWidth: '100%'
          }
        }}
        dangerouslySetInnerHTML={{ __html: post.content }}
      />
      {post.learningMaterials && (
        <CardContent>
          <Strong>materiały dydaktyczne</Strong>
          <Typography>{post.learningMaterials}</Typography>
        </CardContent>
      )}
      {post.programPoints && (
        <CardContent>
          <Strong>Podstawa programowa</Strong>
          <Box>{post.programPoints}</Box>
        </CardContent>
      )}
    </Card>
  );
};

export default SpecialistPostCard;
