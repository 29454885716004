import ApiBase, { ROOT_URL } from './ApiBase';
import moment from 'moment';

class DaysOffApi extends ApiBase {
  static getDaysOff(year) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/configuration/daysOff/${year}`).type('application/json'));
  }

  static addDayOff(date, name) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/daysOff/${moment(date).format('YYYY-MM-DD')}`)
        .send({ name })
        .type('application/json')
    );
  }

  static removeDayOff(date) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/configuration/daysOff/${moment(date).format('YYYY-MM-DD')}`).type('application/json')
    );
  }
}

export default DaysOffApi;
