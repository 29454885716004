import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getTheme } from '../styles/appTheme';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import NotificationHandlerContainer from './notificationsHandler/NotificationHandlerContainer';
import LoadingWrapper from './common/loading/LoadingIndicatorWrapper';
import NotFoundPage from './notFound/NotFoundPage';
import dimensions from 'react-dimensions';
import { plPL } from '@mui/material/locale';
import { Box, CssBaseline } from '@mui/material';

class App extends React.Component {
  renderAppContent() {
    return this.props.children;
  }

  renderUnauthorizedContent() {
    return (
      <Box>
        <Box>
          <LoadingWrapper isProcessing={this.props.isLoading} shouldUnmountChildrenWhenProcessing={false}>
            {this.props.unitActive ? this.props.children : <NotFoundPage />}
          </LoadingWrapper>
          <NotificationHandlerContainer />
        </Box>
      </Box>
    );
  }

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createTheme(getTheme(this.props.selectedTheme), plPL)}>
          <CssBaseline />
          {this.props.signedIn ? this.renderAppContent() : this.renderUnauthorizedContent()}
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

App.propTypes = {
  unitActive: PropTypes.bool.isRequired,
  signedIn: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired,
  unitName: PropTypes.string.isRequired,
  contrastColor: PropTypes.string.isRequired,
  selectedTheme: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    signedIn: state.auth.signedIn,
    isLoading: state.isLoading,
    unitActive: state.configuration.unit != null,
    unitName: state.configuration.unit && state.configuration.unit.address ? state.configuration.unit.address.name : '',
    contrastColor: state.contrastColor,
    selectedTheme: state.theme
  };
}

export default connect(mapStateToProps)(dimensions()(App));
