import PropTypes from 'prop-types';
import React from 'react';
import { ChargingCalculationMethod } from '../../../../constants/chargingCalculationMethods';
import { ChargingSchemeTypes } from '../../../../constants/chargingSchemeTypes';
import { getConstantByValue } from '../../../../utils/getConstantByValue';
import { DisplayTimeDescription } from './SchemeDescription';
import { Box, Typography } from '@mui/material';

const MonthlySchemeDescription = ({ scheme }) => {
  return (
    <Box
      sx={{
        color: (theme) => theme.palette.color.contrast
      }}>
      <Typography sx={{ fontSize: 14 }}>
        Stawka:
        <Typography
          component="span"
          sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 14, ml: 1 }}>
          {scheme.rate} {ChargingSchemeTypes[scheme.schemeType].unit}
        </Typography>
      </Typography>
      <Typography sx={{ fontSize: 14 }}>
        Opłata naliczana:
        <Typography
          component="span"
          sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 14, mx: 1 }}>
          {getConstantByValue(ChargingCalculationMethod, scheme.calculationMethod).name}
        </Typography>
      </Typography>
      <DisplayTimeDescription scheme={scheme} />
      {!!scheme.dailyBonusRate ? (
        <Typography sx={{ fontSize: 14 }}>
          Rabat przyznawany za każdy dzień nieobecności:{' '}
          <Typography
            component="span"
            sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 14, ml: 1 }}>
            {scheme.dailyBonusRate} zł
          </Typography>
        </Typography>
      ) : null}
      {!!scheme.extraBonusValue && !!scheme.extraBonusThreshold ? (
        <Typography sx={{ fontSize: 14 }}>
          Dodatkowy rabat (jednorazowy):
          <Typography
            component="span"
            sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 14, mx: 1 }}>
            {scheme.extraBonusValue} zł
          </Typography>
          przyznawany po przekroczeniu
          <Typography
            component="span"
            sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 14, mx: 1 }}>
            {scheme.extraBonusThreshold} dni/a
          </Typography>
        </Typography>
      ) : null}
      {!!scheme.wholeMonthOverride ? (
        <Typography sx={{ fontSize: 14 }}>
          Rabat przyznawany za cały miesiąc nieobecności:
          <Typography
            component="span"
            sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 14, mx: 1 }}>
            {scheme.wholeMonthOverride} zł
          </Typography>
        </Typography>
      ) : null}
    </Box>
  );
};

MonthlySchemeDescription.propTypes = {
  scheme: PropTypes.object.isRequired
};

export default MonthlySchemeDescription;
