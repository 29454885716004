import PropTypes from 'prop-types';
import React from 'react';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import EmptyState from '../../common/EmptyState';
import DeviceIcon from '@mui/icons-material/ScreenLockLandscape';
import PinDeviceDialog from './PinDeviceDialog';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  MenuItem,
  Box,
  Typography,
  Button
} from '@mui/material';
import DotsMenu from '../../common/menu/DotsMenu';
import Strong from '../../common/Strong';

class PinDevicesList extends React.Component {
  constructor() {
    super();
    this.state = {
      showAddDeviceDialog: false,
      isProcessing: false,
      showResetPasswordDialog: false,
      device: {}
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAddDevice = this.handleAddDevice.bind(this);
    this.handleAddDeviceSave = this.handleAddDeviceSave.bind(this);
    this.handleResetDevicePassword = this.handleResetDevicePassword.bind(this);
    this.handleResetDevicePasswordSave = this.handleResetDevicePasswordSave.bind(this);
  }

  handleCancel() {
    if (this.state.showAddDeviceDialog) this.props.onCancelAdd();
    if (this.state.showResetPasswordDialog) this.props.onCancelPasswordRestart();
    this.setState({
      showAddDeviceDialog: false,
      isProcessing: false,
      showResetPasswordDialog: false,
      device: {}
    });
  }

  handleAddDevice() {
    const device = { login: `pin${this.props.devices.length + 1}` };
    this.setState({ showAddDeviceDialog: true, showResetPasswordDialog: false, device });
  }

  handleAddDeviceSave(device) {
    this.setState({ isProcessing: true }, () =>
      this.props
        .onAdd(device.login, device.password)
        .then((error) => this.setState({ showAddDeviceDialog: !!error, isProcessing: false }))
    );
  }

  handleResetDevicePassword(device) {
    this.setState({ showResetPasswordDialog: true, showAddDeviceDialog: false, device });
  }

  handleResetDevicePasswordSave(device) {
    this.setState({ isProcessing: true }, () =>
      this.props
        .onPasswordRestart(device.id, device.password)
        .then((error) => this.setState({ showResetPasswordDialog: !!error, isProcessing: false }))
    );
  }

  renderAddDeviceDialog() {
    return (
      this.state.showAddDeviceDialog && (
        <PinDeviceDialog
          onSave={this.handleAddDeviceSave}
          onCancel={this.handleCancel}
          isDialogOpen={this.state.showAddDeviceDialog}
          isProcessing={this.state.isProcessing}
          device={this.state.device}
          title="Dodaj nowe urządzenie"
          canEditLogin={true}
        />
      )
    );
  }

  renderResetDevicePasswordDialog() {
    return (
      this.state.showResetPasswordDialog && (
        <PinDeviceDialog
          onSave={this.handleResetDevicePasswordSave}
          onCancel={this.handleCancel}
          isDialogOpen={this.state.showResetPasswordDialog}
          isProcessing={this.state.isProcessing}
          device={this.state.device}
          title="Ustaw nowe hasło"
          canEditLogin={false}
        />
      )
    );
  }

  render() {
    const { devices, onRemove, enabled } = this.props;
    return (
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
            Zintegrowane urządzenia
          </Typography>
          <Button variant="contained" aria-label="Dodaj" onClick={this.handleAddDevice} disabled={!enabled}>
            Dodaj
          </Button>
        </Box>
        <List>
          {devices.length === 0 ? <EmptyState contrast message="Nie zdefiowano jeszcze urządzeń" /> : null}
          {devices.map((device, index) => {
            return (
              <React.Fragment key={device.id}>
                <ListItem
                  sx={{
                    '&:hover': {
                      background: (theme) => theme.palette.background.hover
                    }
                  }}>
                  <ListItemIcon>
                    <DeviceIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Urządzenie nr ${index + 1}`}
                    secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.text.primary } }}
                    secondary={
                      <Typography>
                        login:
                        <Typography
                          component="span"
                          sx={{ mx: 1, fontWeight: (theme) => theme.typography.fontWeightBold }}>
                          {device.login}
                        </Typography>
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <DotsMenu>
                      {(onClose) => [
                        <MenuItem
                          key="1"
                          onClick={() => {
                            onClose();
                            this.handleResetDevicePassword(device);
                          }}
                          disabled={!enabled}>
                          Zmień hasło
                        </MenuItem>,
                        <ActionConfirmationMenuItem
                          key="2"
                          onItemClick={onClose}
                          actionLabel="Usuń"
                          confirmationText={
                            <Box>
                              Dalsze logowanie za pomocą <Strong>{device.login}</Strong> nie będzie możliwe. Czy na
                              pewno chcesz usunąć urządzenie?
                            </Box>
                          }
                          confirmationTitle={`Usuń urządzenie nr ${index + 1}`}
                          confirmLabel="Usuń"
                          onConfirm={() => onRemove(device.id)}
                          onCancel={() => {}}
                        />
                      ]}
                    </DotsMenu>
                  </ListItemSecondaryAction>
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
        {this.renderAddDeviceDialog()}
        {this.renderResetDevicePasswordDialog()}
      </Box>
    );
  }
}

PinDevicesList.propTypes = {
  devices: PropTypes.array.isRequired,
  onPasswordRestart: PropTypes.func.isRequired,
  onCancelPasswordRestart: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onCancelAdd: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired
};

export default PinDevicesList;
