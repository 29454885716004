/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import {
  ChildModel,
  EnrolledToGroupModel,
  GuardianModel,
  LegalGuardianModel,
  StudentModel
} from '../models/children/ChildModels';

export default function childrenReducer(state = initialState.children, action) {
  switch (action.type) {
    case types.LOAD_CHILDREN_SUCCESS: {
      return action.children.map((child) => new ChildModel().assign(child));
    }
    case types.CREATE_CHILD_SUCCESS: {
      return [...state, new ChildModel().assignFromDetailsModel(action.childDetails)];
    }
    case types.CREATE_STUDENT_SUCCESS: {
      return [...state, new StudentModel().assignFromDetailsModel(action.childDetails)];
    }
    case types.UPDATE_CHILD_SUCCESS: {
      return [
        ...state.filter((c) => c.id !== action.childDetails.id),
        new ChildModel().assignFromDetailsModel(action.childDetails)
      ];
    }
    case types.UPDATE_STUDENT_SUCCESS: {
      return [
        ...state.filter((c) => c.id !== action.childDetails.id),
        new StudentModel().assignFromDetailsModel(action.childDetails)
      ];
    }
    case types.ARCHIVE_CHILD_SUCCESS: {
      return [...state.filter((c) => c.id !== action.childId)];
    }
    case types.ENROLL_CHILDREN_TO_GROUP_SUCCESS: {
      const updatedState = state.map((child) => {
        if (action.children.some((c) => c.id === child.id)) {
          const newChild = new ChildModel().assign(child);
          newChild.enrolledToGroup = new EnrolledToGroupModel().assign({
            id: action.groupId,
            enrolledAt: new Date(),
            name: ''
          });
          return newChild;
        }
        return child;
      });
      return [...updatedState];
    }
    case types.REMOVE_CHILD_FROM_GROUP_SUCCESS: {
      const updatedState = state.map((child) => {
        if (child.id === action.childId) {
          const newChild = new ChildModel().assign(child);
          newChild.enrolledToGroup = null;
          return newChild;
        }
        return child;
      });
      return [...updatedState];
    }
    case types.ENROLL_CHILDREN_TO_ACTIVITY_SUCCESS: {
      const updatedState = state.map((child) => {
        if (action.children.some((c) => c?.id === child.id)) {
          const newChild = new ChildModel().assign(child);
          newChild.enrolledToActivities.push(
            new EnrolledToGroupModel().assign({ id: action.groupId, enrolledAt: new Date(), name: '' })
          );
          return newChild;
        }
        return child;
      });
      return [...updatedState];
    }
    case types.REMOVE_CHILD_FROM_ACTIVITY_SUCCESS: {
      const updatedState = state.map((child) => {
        if (child.id === action.childId) {
          const newChild = new ChildModel().assign(child);
          newChild.enrolledToActivities = newChild.enrolledToActivities.filter((a) => a.id !== action.activityId);
          return newChild;
        }
        return child;
      });
      return [...updatedState];
    }
    case types.ADD_GUARDIAN_TO_CHILD_SUCCESS: {
      const updatedState = state.map((child) => {
        if (child.id === action.childId) {
          const newChild = new ChildModel().assign(child);
          newChild.guardians.push(new GuardianModel().assign(action.guardian));
          return newChild;
        }
        return child;
      });
      return [...updatedState];
    }
    case types.UPDATE_GUARDIAN_IN_CHILD_SUCCESS: {
      const updatedState = state.map((child) => {
        if (child.id === action.childId) {
          const newChild = new ChildModel().assign(child);
          newChild.guardians = newChild.guardians.map((g) => {
            return g.id === action.guardian.id ? new GuardianModel().assign(action.guardian) : g;
          });
          return newChild;
        }
        return child;
      });
      return [...updatedState];
    }
    case types.REMOVE_GUARDIAN_FROM_CHILD_SUCCESS: {
      const updatedState = state.map((child) => {
        if (child.id === action.childId) {
          const newChild = new ChildModel().assign(child);
          newChild.guardians = [...newChild.guardians.filter((g) => g.id !== action.guardianId)];
          return newChild;
        }
        return child;
      });
      return [...updatedState];
    }
    case types.ADD_LEGAL_GUARDIAN_TO_CHILD_SUCCESS: {
      const updatedState = state.map((child) => {
        if (child.id === action.childId) {
          const newChild = new ChildModel().assign(child);
          newChild.guardians.push(new LegalGuardianModel().assign(action.legalGuardian));
          return newChild;
        }
        return child;
      });
      return [...updatedState];
    }
    case types.UPDATE_LEGAL_GUARDIAN_IN_CHILD_SUCCESS: {
      const updatedState = state.map((child) => {
        if (child.id === action.childId) {
          const newChild = new ChildModel().assign(child);
          newChild.guardians = newChild.legalGuardians.map((g) => {
            return g.id === action.legalGuardian.id ? new LegalGuardianModel().assign(action.legalGuardian) : g;
          });
          return newChild;
        }
        return child;
      });
      return [...updatedState];
    }
    case types.REMOVE_LEGAL_GUARDIAN_FROM_CHILD_SUCCESS: {
      const updatedState = state.map((child) => {
        if (child.id === action.childId) {
          const newChild = new ChildModel().assign(child);
          newChild.guardians = [...newChild.legalGuardians.filter((g) => g.id !== action.guardianId)];
          return newChild;
        }
        return child;
      });
      return [...updatedState];
    }
    default:
      return state;
  }
}
