import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { Box, Grid } from '@mui/material';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import FilterBar from '../common/FilterBar';
import GroupSwitch from '../common/groups/GroupSwitch';
import React, { useEffect, useMemo, useState } from 'react';
import AttendanceList from './currentJournal/AttendanceList';
import { useDispatch, useSelector } from 'react-redux';
import { loadAbsentTomorrow } from '../../actions/attendanceActions';
import * as digitalDiaryEvents from '../../actions/digitalDiaryActions';
import EmptyState from '../common/EmptyState';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';

const AbsenceTomorrowPage = () => {
  const absenceTomorrow = useSelector((state) => state.absenceTomorrow);
  const user = useSelector((state) => state.auth);
  const choosenGroup = useSelector((state) => state.digitalDiary.choosenGroup);
  const [pupils, setPupils] = useState(absenceTomorrow.entries);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(digitalDiaryEvents.changeChoosenGroup('all'));
    const fetchDate = async () => {
      await dispatch(loadAbsentTomorrow());
    };
    fetchDate();
  }, []);

  useEffect(() => {
    setPupils(absenceTomorrow.entries);
  }, [absenceTomorrow]);

  const filteredPupils = useMemo(() => {
    if (choosenGroup.id === 'all') return pupils;
    return pupils.filter((pupil) => pupil.groupId === choosenGroup.id);
  }, [choosenGroup, pupils]);

  const groupSelect = (groupSummary) => {
    dispatch(digitalDiaryEvents.changeChoosenGroup(groupSummary));
  };

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText
          title="Zaplanowane nieobecności"
          titleIcon={<EventBusyIcon />}
          additionalInfo={
            absenceTomorrow.entries.length > 0 ? moment(absenceTomorrow.entries[0].date).format('DD.MM.YYYY') : null
          }
        />
        {absenceTomorrow.isLoading ? (
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 300 }}>
            <CircularProgress size={80} />
          </Grid>
        ) : (
          <ContentWrapper>
            <Grid container sx={{ mb: 2 }}>
              <Grid item xs={12} md={6}>
                <FilterBar
                  itemsToFilter={absenceTomorrow.entries}
                  sx={{ my: 3 }}
                  onFilter={(value) => setPupils(value)}
                  filter={(child, keyword) =>
                    (child.lastName.toLowerCase() + ' ' + child.firstName.toLowerCase()).includes(
                      keyword.toLowerCase()
                    ) ||
                    (child.firstName.toLowerCase() + ' ' + child.lastName.toLowerCase()).includes(keyword.toLowerCase())
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <GroupSwitch onGroupChange={groupSelect} showArchivedGroups={false} showAllGroups={true} />
              </Grid>
            </Grid>
            <AttendanceList absentTomorrow title="Liczba dzieci" journalEntries={filteredPupils} user={user} />
            {filteredPupils.length === 0 && <EmptyState message="Brak dzieci" contrast isPaper />}
          </ContentWrapper>
        )}
      </Box>
    </LoadingRenderWrapper>
  );
};
export default AbsenceTomorrowPage;
