import PropTypes from 'prop-types';
import React, { useState } from 'react';
import EmptyState from '../../common/EmptyState';
import CardDialogEditForm from '../../forms/CardDialogEditForm';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ChildEvaluationSurveyDialog from './ChildEvaluationSurveyDialog';
import { FileModel, SurveyModel } from '../../../models/children/ChildModels';
import { FileModelValidator } from '../../../models/children/ChildModelsValidator';
import ChildFileAttachmentsList from '../fileAttachments/ChildFileAttachmentsList';
import { ROOT_URL } from '../../../api/ApiBase';
import FilePickerWithRef from '../../common/FilePickerWithRef/FilePickerWithRef';
import { Box } from '@mui/material';

const ChildEvaluationSurveyCard = (props) => {
  const filePickerRef = React.createRef();
  const [activeSurvey, setActiveSurvey] = useState(new FileModel().assign({ fileInfo: new SurveyModel() }));
  const [errors, setErrors] = useState({});

  const handleSurveyTitleChange = (name) => {
    setActiveSurvey(Object.assign({}, activeSurvey, { name }, { fileInfo: { ...activeSurvey.fileInfo, title: name } }));
  };

  const handleDownloadFile = (fileId) => {
    const path = `${ROOT_URL}/children/${props.pupilId}/files/${fileId}`;
    window.open(path, '_blank');
  };

  const handleSaveSurvey = () => {
    props.onAdd(activeSurvey);
  };

  const handleFilePicked = (file) => {
    setActiveSurvey(Object.assign({}, new FileModel().assign({ fileInfo: new SurveyModel() }), { file }));
    props.onCreate();
  };

  const renderActionTitle = (filename) => {
    return (
      <Box>
        <AttachmentIcon />
        {filename}
      </Box>
    );
  };

  const renderReadonlyForm = () => {
    return (
      <ChildFileAttachmentsList
        attachments={props.attachments}
        onRemove={props.onRemove}
        onDownloadFile={handleDownloadFile}
      />
    );
  };

  const renderEditDialog = () => {
    return (
      <ChildEvaluationSurveyDialog
        fileAttachment={activeSurvey}
        onNameChange={handleSurveyTitleChange}
        onFileDrop={handleFilePicked}
        errors={errors}
      />
    );
  };

  return (
    <Box>
      <CardDialogEditForm
        title="Ankieta wejściowa i ewaluacyjna"
        actionTitle={activeSurvey.file ? renderActionTitle(activeSurvey.file.name) : ''}
        isInitiallyExpanded={props.isInitiallyExpanded}
        isDialogOpen={props.isEditing}
        isProcessing={props.isProcessing}
        onAction={() => filePickerRef.current.open()}
        onCancel={() => props.onCancel()}
        onValidate={() => new FileModelValidator().validate(activeSurvey)}
        onValidationDone={(err) => setErrors(err)}
        onSave={handleSaveSurvey}
        actionLabel="Dodaj ankietę"
        statePathToUi="childDetailsUi.attachments"
        readonlyForm={renderReadonlyForm()}
        emptyForm={<EmptyState message="Brak ankiet" contrast />}
        editForm={renderEditDialog()}
        isEmpty={props.attachments.length < 1}
      />
      <Box sx={{ display: 'none' }}>
        <FilePickerWithRef ref={filePickerRef} onDrop={handleFilePicked} />
      </Box>
    </Box>
  );
};

ChildEvaluationSurveyCard.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  canClearDate: PropTypes.bool,
  canSetDate: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  attachments: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  pupilId: PropTypes.string.isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired
};

ChildEvaluationSurveyCard.defaultProps = {
  canClearDate: true,
  canSetDate: true
};

export default ChildEvaluationSurveyCard;
