import React from 'react';
import PropTypes from 'prop-types';
import IconButtonPipe from '../../../common/IconButtonPipe';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import AddAPhotoIcon from '@mui/icons-material/AddAPhotoOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GetAppIcon from '@mui/icons-material/GetApp';
import ExportFilesIcon from '../../../../assets/icons/ExportFilesIcon';
import Filters from './Filters';
import Sorts from './Sorts';
import { Alert, Box, useMediaQuery, useTheme, Fade, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useStateFromProp } from '../../../../utils/useStateFromProps';
import RichTooltip from '../../../richTooltip/richTooltip';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

const useStyles = makeStyles({
  wrapper: ({ isMobile }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: isMobile ? 'wrap' : 'nowrap',
    alignItems: 'center',
    padding: isMobile ? 0 : 10,
    boxSizing: 'border-box',
    width: '100%',
    marginBottom: isMobile ? 20 : null
  }),
  mediaManagementWrapper: {
    width: '50%',
    justifyContent: 'flex-end'
  },
  actionsWrapper: ({ isMobile }) => ({
    display: 'flex',
    width: '50%',
    boxSizing: 'border-box',
    justifyContent: 'flex-start'
  }),
  specificAction: ({ isMobile }) => ({
    display: 'flex',
    justifyContent: isMobile ? 'center' : 'flex-start',
    width: isMobile ? '100%' : 'auto'
  }),
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: ({ isMobile }) => (isMobile ? null : 10)
  },
  header: ({ isMobile }) => ({
    width: isMobile ? '100%' : 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
    padding: isMobile ? 20 : 10
  }),
  button: {
    margin: 4
  }
});

const MediaManagementWrapper = ({ cl, sortType, handleSort, setDateFrom, dateFrom, dateTo, setDateTo, ...rest }) => (
  <Box {...rest} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
    <Sorts contrast cl={cl} sortType={sortType} handleSort={handleSort} title="Multimedia" />
    <Filters cl={cl} setDateFrom={setDateFrom} dateFrom={dateFrom} dateTo={dateTo} setDateTo={setDateTo} />
  </Box>
);

const Header = ({
  onSelectAll,
  openAddDialog,
  isMultipleEdit,
  setMultipleEdit,
  isEditAvailable,
  isListNotEmpty,
  openDeleteDialog,
  areEditButtonsDisabled,
  openMoveDialog,
  setDateFrom,
  setDateTo,
  dateFrom,
  dateTo,
  sortType,
  handleSort,
  handleDownloadMore,
  isFiltered,
  downloadingInProgress = false
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const cl = useStyles({ isMobile });

  const localStorageGalleryUploadTooltip = JSON.parse(localStorage.getItem('galleryUploadTooltip'));
  const [isTooltipOpen, setIsTooltipOpen] = useStateFromProp(!localStorageGalleryUploadTooltip);

  const handleTooltip = () => {
    localStorage.setItem('galleryUploadTooltip', JSON.stringify(true));
    setIsTooltipOpen(false);
  };

  return (
    <>
      {isTooltipOpen && (
        <Alert
          severity="info"
          onClose={handleTooltip}
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            width: 300,
            transform: 'none !important',
            zIndex: 900
          }}>
          Możesz dodawać multimedia przeciągając je na pole multimediów albumu!
        </Alert>
      )}

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ flexWrap: isMobile ? 'wrap' : 'nowrap', py: 1, pr: isMobile ? 1 : 4, pl: isMobile ? 1 : 4 }}>
        <Box className={cl.wrapper}>
          {(isEditAvailable || isListNotEmpty) && (
            <Box className={cl.actionsWrapper} sx={{ display: 'flex', alignItems: 'center' }}>
              {isEditAvailable && (
                <RichTooltip arrow={false} open={isTooltipOpen} placement="bottom" ContentStyles={{ p: 0 }}>
                  <Box>
                    <IconButtonPipe onClick={openAddDialog} tooltip="Dodaj multimedia">
                      <AddAPhotoIcon />
                    </IconButtonPipe>
                  </Box>
                </RichTooltip>
              )}
              {isListNotEmpty && (
                <>
                  <IconButtonPipe onClick={setMultipleEdit} tooltip="Zaznacz kilka" className={cl.button}>
                    <LibraryAddCheckIcon />
                  </IconButtonPipe>
                  {isMultipleEdit && (
                    <IconButtonPipe tooltip="Zaznacz wszystkie" className={cl.button} onClick={onSelectAll}>
                      <PlaylistAddCheckIcon />
                    </IconButtonPipe>
                  )}
                </>
              )}
            </Box>
          )}

          {(isListNotEmpty || isFiltered) && (
            <>
              <MediaManagementWrapper
                cl={cl}
                sortType={sortType}
                handleSort={handleSort}
                setDateFrom={setDateFrom}
                dateFrom={dateFrom}
                dateTo={dateTo}
                setDateTo={setDateTo}
                className={`${cl.mediaManagementWrapper} ${cl.flex}`}
              />
              {isMultipleEdit && (
                <Fade in={isMultipleEdit}>
                  <Box className={cl.specificAction}>
                    {isEditAvailable && (
                      <>
                        <IconButtonPipe
                          onClick={openMoveDialog}
                          tooltip="Przenieś wszystkie zaznaczone"
                          className={cl.button}
                          disabled={areEditButtonsDisabled}>
                          <ExportFilesIcon
                            className={cl.icon}
                            color={areEditButtonsDisabled ? 'disabled' : 'primary'}
                          />
                        </IconButtonPipe>
                        <IconButtonPipe
                          onClick={openDeleteDialog}
                          tooltip="Usuń wszystkie zaznaczone"
                          className={cl.button}
                          disabled={areEditButtonsDisabled}>
                          <DeleteForeverIcon
                            className={cl.icon}
                            color={areEditButtonsDisabled ? 'disabled' : 'primary'}
                          />
                        </IconButtonPipe>
                      </>
                    )}
                    <IconButtonPipe
                      onClick={handleDownloadMore}
                      tooltip="Pobierz wszystkie zaznaczone"
                      className={cl.button}
                      disabled={downloadingInProgress || areEditButtonsDisabled}>
                      <GetAppIcon
                        className={cl.icon}
                        color={downloadingInProgress || areEditButtonsDisabled ? 'disabled' : 'primary'}
                      />
                    </IconButtonPipe>
                  </Box>
                </Fade>
              )}
            </>
          )}
        </Box>
      </Grid>
    </>
  );
};

Header.propTypes = {
  openAddDialog: PropTypes.func.isRequired,
  isMultipleEdit: PropTypes.bool.isRequired,
  setMultipleEdit: PropTypes.func.isRequired,
  isEditAvailable: PropTypes.bool.isRequired,
  isListNotEmpty: PropTypes.bool.isRequired,
  openDeleteDialog: PropTypes.func.isRequired,
  areEditButtonsDisabled: PropTypes.bool.isRequired,
  openMoveDialog: PropTypes.func.isRequired,
  setDateFrom: PropTypes.func,
  setDateTo: PropTypes.func,
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
  sortType: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired,
  handleDownloadMore: PropTypes.func.isRequired,
  downloadingInProgress: PropTypes.bool
};

MediaManagementWrapper.propTypes = {
  cl: PropTypes.object.isRequired,
  setDateFrom: PropTypes.func,
  setDateTo: PropTypes.func,
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
  sortType: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired
};

export default Header;
