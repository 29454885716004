import React from 'react';
import PropTypes from 'prop-types';
import DoneIcon from '@mui/icons-material/Done';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import ErrorIcon from '@mui/icons-material/Error';

const useStatusStyles = makeStyles((theme) => ({
  statusSuccessButton: {
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.color.success}`
  },
  statusErrorButton: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.color.error}`
  },
  statusSuccessIcon: {
    color: theme.palette.color.success
  },
  statusErrorIcon: {
    color: theme.palette.color.error
  }
}));

const Status = ({ type, text }) => {
  const cl = useStatusStyles();
  if (type === 'success') {
    return (
      <Chip
        icon={<DoneIcon className={cl.statusSuccessIcon} />}
        variant="outlined"
        label={text || 'Sukces'}
        className={cl.statusSuccessButton}
      />
    );
  }
  if (type === 'error') {
    return (
      <Chip
        icon={<ErrorIcon className={cl.statusErrorIcon} />}
        variant="outlined"
        label={text || 'Błąd'}
        className={cl.statusErrorButton}
      />
    );
  }

  return null;
};

Status.propTypes = { type: PropTypes.string.isRequired, text: PropTypes.string };

export default Status;
