import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import iban from 'iban';
import Pesel from '../../../utils/Pesel';
import { StudentInitialValues } from '../../../models/children/ChildDetailsFormInitialValues';
import ChildDetailsFormPersonalData from '../../childDetails/details/components/ChildDetailsFormPersonalData';
import ChildDetailsFormAddress from '../../childDetails/details/components/ChildDetailsFormAddress';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SaveCancelButtons from '../../forms/buttons/SaveCancelButtons';
import { createChildStart } from '../../../actions/childrenActions';
import StudentGuardianFormAddress from '../../childDetails/details/components/StudentGuardianFormAddress';
import { Collapse, DialogActions, List, ListItem, ListItemText, Typography } from '@mui/material';

const StudentDetailsForm = ({ pupil, symphony, isInitiallyExpanded = false, onSubmit }) => {
  const [inProgress, setInProgress] = useState(false);
  const [isOpen, setIsOpen] = useState(isInitiallyExpanded);
  const individualBankAccounts = useSelector((state) => state.configuration.unit.individualBankAccounts);
  const AccountCountryCode = 'PL';
  const dispatch = useDispatch();

  const handleOpen = () => setIsOpen((prevState) => !prevState);
  const onClose = () => setIsOpen(false);

  const handleSubmit = async (values, actions) => {
    setInProgress(true);
    try {
      await onSubmit(values, actions);
    } catch (error) {
      setInProgress(false);
    }
    setInProgress(false);
  };

  const initValues = Object.assign(new StudentInitialValues().assign(pupil));
  const NADARZYN_VALIDATION_SCHEMA = Yup.object().shape({
    firstName: Yup.string().trim().required('Pole wymagane'),
    secondName: Yup.string().trim(),
    lastName: Yup.string().trim().required('Pole wymagane'),
    address: Yup.object().shape({
      address: Yup.string().trim().required('Pole wymagane'),
      birthPlace: Yup.string().trim().required('Pole wymagane'),
      zipCode: Yup.string()
        .matches('^[0-9]{2}-[0-9]{3}$', 'Podano nieprawidłowy kod pocztowy')
        .required('Pole wymagane'),
      city: Yup.string().trim().required('Pole wymagane')
    }),
    email: Yup.string().email('Podano nieprawidłowy email').required('Pole wymagane'),
    registeredAddress: Yup.object().shape(
      {
        address: Yup.string().trim(),
        zipCode: Yup.string().when(['zipCode'], {
          is: (code) => code && code !== undefined && code.length > 0,
          then: Yup.string().matches('^[0-9]{2}-[0-9]{3}$', 'Podano nieprawidłowy kod pocztowy')
        }),
        city: Yup.string().trim()
      },
      ['zipCode', 'zipCode']
    ),
    guardianFirstName: Yup.string().trim().required('Pole wymagane'),
    guardianLastName: Yup.string().trim().required('Pole wymagane'),
    guardianContactNumber: Yup.string().matches('^[0-9]{9}$', 'Podano nieprawidłowy numer telefonu'),
    guardiansAddress: Yup.object().shape({
      address: Yup.string().trim().required('Pole wymagane'),
      zipCode: Yup.string()
        .matches('^[0-9]{2}-[0-9]{3}$', 'Podano nieprawidłowy kod pocztowy')
        .required('Pole wymagane'),
      city: Yup.string().trim().required('Pole wymagane')
    }),
    personalId: Yup.string().trim().required('Pole wymagane')
  });

  const validate = (values) => {
    const errors = {};
    dispatch(createChildStart());
    const { stayBankAccount, cateringBankAccount, otherBankAccount, personalId, personalIdType } = values;
    if (stayBankAccount && !iban.isValid(`${AccountCountryCode}${stayBankAccount}`))
      errors.stayBankAccount = 'Podano nieprawidłowy numer konta';
    if (cateringBankAccount && !iban.isValid(`${AccountCountryCode}${cateringBankAccount}`))
      errors.cateringBankAccount = 'Podano nieprawidłowy numer konta';
    if (otherBankAccount && !iban.isValid(`${AccountCountryCode}${otherBankAccount}`))
      errors.otherBankAccount = 'Podano nieprawidłowy numer konta';
    if (personalIdType === 'pesel') {
      const pesel = new Pesel(personalId);
      if (!pesel.isValid()) errors.personalId = 'Podano nieprawidłowy numer ewidencyjny';
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={NADARZYN_VALIDATION_SCHEMA}
      validate={validate}
      onSubmit={handleSubmit}>
      {({ isValid }) => (
        <Form sx={{ mb: 2 }}>
          <Paper sx={{ my: 2, p: 1 }} elevation={1}>
            <ListItem button onClick={() => handleOpen()} sx={{ py: 1 }}>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                    Formularz informacyjny
                  </Typography>
                }
              />
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={isOpen}>
              <List>
                <ChildDetailsFormPersonalData individualBankAccounts={individualBankAccounts} symphony={symphony} />
                <ChildDetailsFormAddress />
                <StudentGuardianFormAddress />
                <DialogActions>
                  <Typography sx={{ mr: 'auto', pl: 2, fontSize: 14 }}>* - Pola wymagane</Typography>
                  <SaveCancelButtons
                    saveLabel="Zapisz"
                    cancelLabel="Anuluj"
                    onCancel={onClose}
                    inProgress={inProgress}
                    type="submit"
                    saveDisabled={inProgress || !isValid}
                  />
                </DialogActions>
              </List>
            </Collapse>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

StudentDetailsForm.propTypes = {
  pupil: PropTypes.object.isRequired,
  symphony: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isInitiallyExpanded: PropTypes.bool
};

export default StudentDetailsForm;
