import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const ApplicationStatusChip = ({ application }) => {
  const statusStyle = {
    fontWeight: (theme) => theme.typography.fontWeightBold,
    fontSize: 16
  };

  if (application.acceptedBy !== null) {
    statusStyle.color = (theme) => theme.palette.color.color7;
    return <Typography sx={statusStyle}>Zaakceptowany</Typography>;
  }
  if (application.rejectedBy !== null) {
    statusStyle.color = (theme) => theme.palette.color.color25;
    return <Typography sx={statusStyle}>Odrzucony</Typography>;
  }
  statusStyle.color = (theme) => theme.palette.color.color1;
  return <Typography sx={statusStyle}>W trakcie rozpatrywania</Typography>;
};

ApplicationStatusChip.propTypes = {
  application: PropTypes.object.isRequired
};

export default ApplicationStatusChip;
