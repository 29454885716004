import { BaseModelValidator } from '../../models/BaseModelValidator';
import { MealCategory } from '../../models/meals/MealModels';
import { check } from '../../models/validation/validateHelper';

export class MealCategoryModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = MealCategory;

    this.constraints = {
      name: (value) => {
        if (check.isEmpty(value)) {
          return 'Kategoria musi mieć nazwę.';
        } else if (value.length > 75) {
          return 'Nazwa kategorii nie może przekraczać 75 znaków.';
        }
      }
    };
  }
}
