import ApiBase, { ROOT_URL } from './ApiBase';
// import moment from 'moment';

class QrCodeApi extends ApiBase {
  static updateQrCodeSetting(enabled = false, dynamic = false) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/configuration/guardiansQRService`).send({ enabled, dynamic }).type('application/json')
    );
  }
}

export default QrCodeApi;
