export const featuresFromPrefixValidator = () => {
  const currentDomainPrefix = window.location.origin.split('.')[0].slice(8);

  switch (currentDomainPrefix) {
    case 'eszkola':
    case 'eszkola-test':
      return { nadarzyn: true };

    default:
      return null;
  }
};
