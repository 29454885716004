'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import YearAndMonthOptionsSlider from '../../common/calendar/YearAndMonthOptionsSlider';
import Dialog from '../../common/Dialog';
import { Box, Button, Typography } from '@mui/material';

class DuesExportDialog extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: moment().startOf('month'),
      overrideDate: new Date()
    };

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateChange(date) {
    this.setState({ date });
  }

  render() {
    const actions = [
      <Button
        variant="contained"
        aria-label="Wyślij"
        key={1}
        onClick={() => this.props.onSubmit(this.state.date.format('YYYY-MM'))}
        disabled={this.props.inProgress}>
        Wyślij
      </Button>,
      <Button variant="contained" aria-label="Anuluj" key={2} onClick={this.props.onClose}>
        Anuluj
      </Button>
    ];

    return (
      <Dialog
        title="Eksport danych"
        modal={false}
        open={this.props.open}
        actions={actions}
        onClose={this.props.onClose}
        overlayClassName="dialog-overlay">
        <Box fullWidth>
          <Typography>Wybierz okres rozliczeń</Typography>
        </Box>
        <YearAndMonthOptionsSlider
          contrast
          onSelected={(year, month) => {
            const date = moment(`${year}-${month}`, 'YYYY-MM');
            this.handleDateChange(date.startOf('month'));
          }}
          defaultMonth={this.state.date.format('MM')}
          defaultYear={this.state.date.format('YYYY')}
        />
      </Dialog>
    );
  }
}

DuesExportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired
};

export default DuesExportDialog;
