import BaseModel from '../../BaseModel';
import { TimeModel } from '../../TimeModel';
import moment from 'moment';
import { AuthorModel } from '../digitalDiaryEvent/DigitalDiaryEventModel';

export class GoalModel extends BaseModel {
  constructor() {
    super();
    this.goalName = '';
  }
}

export class TopicModel extends BaseModel {
  constructor() {
    super();
    this.topicName = '';
    this.todo = '';
    this.books = '';
    this.comments = '';
  }
}

export class WorkPlanContentModel extends BaseModel {
  constructor() {
    super();
    this.goals = [];
    this.topics = [];
    this.approvedBy = '';
    this.approved = false;
  }

  onAssignment(assigned) {
    //  Map GoalModel to goals array
    //  Map TopicModel to topics array
    this.goals = assigned.goals.map((goal) => new GoalModel().assign(goal));
    this.topics = assigned.topics.map((topic) => new TopicModel().assign(topic));
  }
}

export class WorkPlanModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.title = '';
    this.content = new WorkPlanContentModel();
    this.group = '';
    this.author = new AuthorModel();
    this.eventDate = moment().format('YYYY-MM-DD');
    this.eventStartTime = new TimeModel();
    this.eventEndTime = new TimeModel();
  }

  onAssignment(assinged) {
    this.content = new WorkPlanContentModel().assign(assinged.content);
  }
}
