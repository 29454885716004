import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import UserRoleIcon from '../../../userRoles/UserRoleIcon';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: ({ color }) => color || theme.palette.color.color4,
    marginLeft: 10
  }
}));

const SingleUserRole = ({ name, role }) => {
  const isContrastColor = useSelector((state) => state.contrastColor);
  const theme = useTheme();
  const color = isContrastColor ? theme.palette.color.color4 : theme.palette.components.role[role];
  const cl = useStyles({ color });
  return (
    <Grid alignItems="center" container>
      <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>{name}</Typography>
      <UserRoleIcon role={role} fontSize="large" className={cl.icon} />
    </Grid>
  );
};

SingleUserRole.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  color: PropTypes.string
};

export default SingleUserRole;
