import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import ActivityAvatar from '../common/activities/ActivityAvatar';
import { routePaths, fromTemplate } from '../../routePaths';
import { List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';

const ActivitiesList = ({ activities }) => {
  return (
    <List sx={{ p: 2 }}>
      {activities.map((activity) => {
        return (
          <ListItem key={activity.id} sx={{ p: 0, m: 0 }}>
            <ListItemButton
              sx={{ p: 2 }}
              onClick={() => {
                browserHistory.push(fromTemplate(routePaths.activityDetails, [activity.id]));
              }}>
              <ListItemAvatar>
                <ActivityAvatar activity={activity} />
              </ListItemAvatar>
              <ListItemText
                primary={activity.name}
                primaryTypographyProps={{
                  fontWeight: (theme) => theme.typography.fontWeightBold
                }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

ActivitiesList.propTypes = {
  activities: PropTypes.array.isRequired
};

export default ActivitiesList;
