import Promise from 'bluebird';
import JsZip from 'jszip';
import FileSaver from 'file-saver';
import moment from 'moment';
// import filesApi from '../api/FilesApi';
import localStorageProvider from './localStorageProvider';
import mime from 'mime';

const download = async (url, mimeType, name) => {
  const token = localStorageProvider.getItem('authToken');
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return { blob: response.blob(), mimeType, name };
};

const downloadByGroup = (items, filesPerGroup = 5) => {
  return Promise.map(
    items,
    ({ fileUrl, mimeType, name }) => {
      return download(fileUrl, mimeType, name);
    },
    { concurrency: filesPerGroup }
  );
};

const exportZip = (array, endAction = () => {}, fileName) => {
  const zip = new JsZip();
  array.forEach(({ blob, mimeType, name }, i) => {
    const ext = mime.getExtension(mimeType) || mime.getExtension(blob.type);
    zip.file(name || `${fileName}-${i}.${ext}`, blob);
  });
  zip.generateAsync({ type: 'blob' }).then((zipFile) => {
    const currentDate = moment().format('YYYY-MM-DD');
    const extendedFileName = `${fileName}-${currentDate}.zip`;
    endAction();
    return FileSaver.saveAs(zipFile, extendedFileName);
  });
};

export const downloadAndZip = (items, startAction, endAction, errorAction, fileName) => {
  startAction();
  return downloadByGroup(items, 5)
    .then((blobs) => exportZip(blobs, endAction, fileName))
    .catch(() => {
      errorAction();
    });
};
