import PropTypes from 'prop-types';
import React from 'react';
import CardEditForm from '../../forms/CardEditForm';
import GroupDetailsForm from './GroupDetailsForm';
import moment from 'moment';

class GroupDetailsCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      errors: {}
    };
    this.handleYearbookChange = this.handleYearbookChange.bind(this);
    this.generateYearbookValues = this.generateYearbookValues.bind(this);
  }

  getTemporaryGroupValue() {
    return this.generateYearbookValues().pop().value;
  }

  generateYearbookValues() {
    const year = moment().year();
    const prevYear = year - 1;
    const nextYear = year + 1;
    return [
      { name: `${prevYear}/${year}`, value: prevYear.toString() },
      { name: `${year}/${nextYear}`, value: year.toString() },
      { name: 'Tymczasowa', value: 'temporary' }
    ];
  }

  handleYearbookChange(value) {
    const isTemporary = this.getTemporaryGroupValue() === value;
    this.props.onChange({ target: { name: 'yearbook', value: isTemporary ? null : parseInt(value, 10) } });
    this.props.onChange({ target: { name: 'isTemporary', value: isTemporary } });
  }

  render() {
    return (
      <CardEditForm
        onSave={this.props.onSave}
        onValidate={this.props.onValidate}
        onValidationDone={(errors) => this.setState({ errors })}
        onCancel={this.props.onCancel}
        isProcessing={this.props.isProcessing}
        isExpanded={this.props.isEditing}
        statePathToUi="groupDetailsUi.details" //that's the tricky thing
        onCollapse={this.props.onCancel}
        onExpand={this.props.onEdit}
        title={this.props.title || this.props.group.name}
        requiredFieldText
        editForm={
          <GroupDetailsForm
            group={this.props.group}
            onChange={this.props.onChange}
            errors={this.state.errors}
            onYearbookChange={this.handleYearbookChange}
            yearbooks={this.generateYearbookValues()}
          />
        }
      />
    );
  }
}

GroupDetailsCard.propTypes = {
  group: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isProcessing: PropTypes.bool,
  title: PropTypes.string
};

export default GroupDetailsCard;
