'use strict';

import PropTypes from 'prop-types';

import React from 'react';
import TextField from '../../../common/TextField';
import { RadioGroup, FormControlLabel, Radio, ListSubheader, Box } from '@mui/material';

import { tryConvertToNumber } from '../../../../utils/numberConverters';
import { ReliefType } from '../../../../constants/reliefType';
import InputAdornment from '@mui/material/InputAdornment';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import Checkbox from '../../../common/Checkbox';

const ReliefForm = ({ relief, onChange, errors }) => {
  return (
    <Box>
      <FieldsetWrapper legend="Informacje o uldze">
        <TextField
          hintText="Nazwa ulgi"
          floatingLabelText="Nazwa ulgi*"
          floatingLabelFixed={false}
          autoFocus={true}
          name="name"
          value={relief.name}
          onChange={onChange}
          fullWidth={true}
          errorText={errors.name}
        />
        <FieldsetWrapper
          legend={
            <ListSubheader component="span" sx={{ pl: 0, color: (theme) => theme.palette.color.color2 }}>
              Rodzaj ulgi
            </ListSubheader>
          }
          invisible={false}>
          <RadioGroup
            aria-label="typ ulgi"
            name="type"
            value={relief.type || ''}
            onClick={(e) => {
              onChange({ target: { name: 'type', value: e.target.value } });
            }}>
            <FormControlLabel
              value={ReliefType.percentage.value}
              control={<Radio color="primary" />}
              label={ReliefType.percentage.name}
            />
            <FormControlLabel
              value={ReliefType.fixedAmount.value}
              control={<Radio color="primary" />}
              label={ReliefType.fixedAmount.name}
            />
          </RadioGroup>
        </FieldsetWrapper>
        <TextField
          hintText="Wartość ulgi"
          floatingLabelText="Wartość ulgi*"
          floatingLabelFixed={false}
          name="rate"
          value={relief.rate}
          errorText={errors.rate}
          onChange={onChange}
          id="standard-adornment-weight"
          onBlur={(e) => {
            onChange({ target: { name: 'rate', value: tryConvertToNumber(e.target.value) } });
          }}
          endAdornment={
            <InputAdornment position="end">{relief.type === ReliefType.percentage.value ? '%' : 'zł'}</InputAdornment>
          }
        />
        <Checkbox
          name="isSubsidy"
          label="Traktuj jako dofinansowanie"
          defaultChecked={relief.isSubsidy}
          onCheck={(event, isChecked) => onChange({ target: { name: 'isSubsidy', value: isChecked } })}
        />
      </FieldsetWrapper>
    </Box>
  );
};

ReliefForm.propTypes = {
  relief: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ReliefForm;
