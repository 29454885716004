import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { Typography } from '@mui/material';
import { Warning } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import AuthorizationChipElement from './AuthorizationChipElement';

const authorizationIcon = (isAuthorized) =>
  isAuthorized ? (
    <CheckIcon sx={{ color: (theme) => theme.palette.color.primary, mb: 0.5, ml: 1 }} />
  ) : (
    <CloseIcon sx={{ color: (theme) => theme.palette.color.primary, mb: 0.5, ml: 1 }} />
  );

const AuthorizationDetails = ({ isAuthorized, authorizationExpirationDate, authorizedBy, isAuthorizedBySign }) => {
  const { guardianCrossValidation } = useSelector((state) => state.configuration.unit.features);

  const statusText = useMemo(() => {
    if (!isAuthorized && !authorizedBy) {
      return (
        <AuthorizationChipElement
          sx={{
            color: (theme) => theme.palette.color.primary,
            background: (theme) => theme.palette.color.warning
          }}>
          Oczekuje na upoważnienie
          {authorizationIcon(false)}
        </AuthorizationChipElement>
      );
    }
    if (!isAuthorized && authorizedBy && guardianCrossValidation) {
      return (
        <AuthorizationChipElement
          sx={{
            color: (theme) => theme.palette.color.primary,
            background: (theme) => theme.palette.color.warning
          }}>
          Oczekuje na podpis drugiego opiekuna
          <Warning sx={{ color: (theme) => theme.palette.color.primary, mb: 0.5, ml: 1 }} />
        </AuthorizationChipElement>
      );
    }

    if (!isAuthorized && authorizedBy && !guardianCrossValidation) {
      return (
        <AuthorizationChipElement
          sx={{
            color: (theme) => theme.palette.color.primary,
            background: (theme) => theme.palette.color.warning
          }}>
          Niepoprawne upoważnienie
          <Warning sx={{ color: (theme) => theme.palette.color.primary, mt: 0.5, ml: 1 }} />
        </AuthorizationChipElement>
      );
    }

    if (isAuthorized && !authorizationExpirationDate) {
      return (
        <AuthorizationChipElement
          sx={{
            color: (theme) => theme.palette.color.primary,
            background: (theme) => theme.palette.color.success
          }}>
          Upoważniony{authorizationIcon(true)}
        </AuthorizationChipElement>
      );
    }

    if (moment().isSameOrBefore(authorizationExpirationDate, 'day')) {
      const date = moment(authorizationExpirationDate).format('YYYY-MM-DD');
      return (
        <AuthorizationChipElement
          sx={{
            color: (theme) => theme.palette.color.primary,
            background: (theme) => theme.palette.color.success
          }}>
          Upoważniony do
          <Typography component="span" sx={{ ml: 1, fontWeight: (theme) => theme.typography.fontWeightBold }}>
            {date}
          </Typography>
          {authorizationIcon(true)}
        </AuthorizationChipElement>
      );
    }

    if (isAuthorized && authorizationExpirationDate && moment().isAfter(authorizationExpirationDate, 'day')) {
      const date = moment(authorizationExpirationDate).format('YYYY-MM-DD');
      return (
        <AuthorizationChipElement
          sx={{
            color: (theme) => theme.palette.color.primary,
            background: (theme) => theme.palette.color.error
          }}>
          Nieupoważniony od
          <Typography
            component="span"
            sx={{
              ml: 1,
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}>
            {date}
          </Typography>
          {authorizationIcon(false)}
        </AuthorizationChipElement>
      );
    }
    return null;
  }, [isAuthorized, authorizationExpirationDate]);

  const signText = useMemo(() => {
    if (isAuthorizedBySign && !authorizedBy) {
      return (
        <AuthorizationChipElement
          sx={{
            color: (theme) => theme.palette.color.primary,
            background: (theme) => theme.palette.color.color24
          }}>
          Podpisano przez opiekuna prawnego
          {authorizationIcon(true)}
        </AuthorizationChipElement>
      );
    }

    if (authorizedBy && authorizedBy.length > 1) {
      return (
        <AuthorizationChipElement
          sx={{
            color: (theme) => theme.palette.color.primary,
            background: (theme) => theme.palette.color.color5
          }}>
          Podpisano przez opiekunów prawnych
          {authorizedBy.map(({ userFullName, id }) => (
            <React.Fragment key={id}>{` ${userFullName}`}</React.Fragment>
          ))}
          {authorizationIcon(true)}
        </AuthorizationChipElement>
      );
    }

    if (authorizedBy && authorizedBy.length === 1) {
      return (
        <AuthorizationChipElement
          sx={{
            color: (theme) => theme.palette.color.primary,
            background: (theme) => theme.palette.color.color4
          }}>
          Podpisano przez opiekuna prawnego
          {authorizedBy.map(({ id, userFullName }) => (
            <React.Fragment key={id}>{` ${userFullName}`}</React.Fragment>
          ))}
          {authorizationIcon(true)}
        </AuthorizationChipElement>
      );
    }

    return null;
  }, []);

  return (
    <>
      {statusText}
      {signText}
    </>
  );
};

AuthorizationDetails.propTypes = {
  isAuthorized: PropTypes.bool,
  isAuthorizedBySign: PropTypes.bool,
  isAuthorizedByAll: PropTypes.bool,
  authorizationExpirationDate: PropTypes.object,
  authorizedBy: PropTypes.array
};

export default AuthorizationDetails;
