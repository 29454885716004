import { useCallback, useState } from 'react';
import { DietDetailsModel } from '../../models/meals/DietModels';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { DietDetailsCard } from './DietDetailsCard';
import { browserHistory } from 'react-router';
import { routePaths } from '../../routePaths';
import { useDispatch } from 'react-redux';
import { createDietAsync } from '../../actions/dietActions';

const emptyMeal = new DietDetailsModel();

export const CreateDietPage = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = useCallback(
    async (newDiet) => {
      console.log(newDiet);
      const parsedDiet = {
        name: newDiet.name,
        mealIds: newDiet.meals.map((meal) => meal.id)
      };
      try {
        setIsLoading(true);
        console.log('before create');
        await dispatch(createDietAsync(parsedDiet));
        console.log('after create');
        browserHistory.push(routePaths.diets);
      } catch {
        console.log('error');
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const handleCancel = useCallback(() => {
    browserHistory.push(routePaths.diets);
  }, []);

  return (
    <LoadingRenderWrapper>
      <DietDetailsCard
        diet={emptyMeal}
        title="Nowa dieta"
        handleSave={handleSave}
        onCancel={handleCancel}
        isLoading={isLoading}
      />
    </LoadingRenderWrapper>
  );
};
