import ApiBase, { ROOT_URL } from './ApiBase';

class DuesExportApi extends ApiBase {
  static getConfiguration() {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/configuration/duesExport/duesExportSettings`).type('application/json')
    );
  }

  static getSymphonyConfiguration() {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/configuration/duesExport/duesExportSettingsSymphony`).type('application/json')
    );
  }

  static updateConfiguration(settings) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/duesExport`)
        .send({ ...settings, type: 'duesExportSettings' })
        .type('application/json')
    );
  }

  static getExportsHistory(type) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/settlements/exports?exportType=${type}`).type('application/json')
    );
  }

  static runExport(exportType, month, overrideDate) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/settlements/exports/export-by-email`)
        // eslint-disable-next-line no-undef
        .send({ exportType, month, overrideDate })
        .type('application/json')
    );
  }
}

export default DuesExportApi;
