import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Box, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import YearAndMonthOptions from './YearAndMonthOptions';

class YearAndMonthOptionsSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.nextState(this.props.defaultYear, this.props.defaultMonth);
    this.handleYearMonthSelected = this.handleYearMonthSelected.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultYear !== prevProps.defaultYear || this.props.defaultMonth !== prevProps.defaultMonth) {
      this.handleDateChange(this.props.defaultYear, this.props.defaultMonth);
    }
  }

  handleDateChange(year, month) {
    this.setState(this.nextState(year, month));
  }

  getYears() {
    const { maxYear, minYear } = this.props;
    return _.range(minYear.format('YYYY'), moment(maxYear).add(1, 'years').format('YYYY'), 1).map((r) => r.toString());
  }

  getYearMonthDate(year, month) {
    return moment({ y: year, M: month - 1, d: 1 });
  }

  nextState(year, month) {
    const nextYearMonth = this.getYearMonthDate(year, month);
    const { maxYear, minYear } = this.props;
    return {
      canGetPrevious: moment({ y: minYear.format('YYYY'), M: 0, d: 1 }).isBefore(nextYearMonth),
      canGetNext: moment({ y: maxYear.format('YYYY'), M: 11, d: 1 }).isAfter(nextYearMonth),
      yearMonth: nextYearMonth
    };
  }

  handleYearMonthSelected(year, month) {
    this.setState(this.nextState(year, month));
    this.props.onSelected(year, month);
  }

  handleNext() {
    const nextYearMonth = moment(this.state.yearMonth.add(1, 'months'));
    this.handleYearMonthSelected(nextYearMonth.format('YYYY'), nextYearMonth.format('MM'));
  }

  handlePrevious() {
    const nextYearMonth = moment(this.state.yearMonth.subtract(1, 'months'));
    this.handleYearMonthSelected(nextYearMonth.format('YYYY'), nextYearMonth.format('MM'));
  }

  render() {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          ...this.props.sx,
          width: !this.props.small ? 280 : 200,
          ml: !this.props.isMarginLeft ? -1.5 : null
        }}>
        {!this.props.small && (
          <IconButton
            onClick={this.handlePrevious}
            disabled={!this.state.canGetPrevious || this.props.disabled}
            aria-label="Poprzedni miesiąc"
            tooltip="Poprzedni miesiąc">
            <ChevronLeftIcon
              sx={{
                p: 0,
                fontSize: 30,
                color: (theme) => (this.props.contrast ? theme.palette.color.contrast : theme.palette.color.primary)
              }}
            />
          </IconButton>
        )}
        <YearAndMonthOptions
          contrast={this.props.contrast}
          onSelected={this.handleYearMonthSelected}
          years={this.getYears()}
          disabled={this.props.disabled}
          defaultYear={this.state.yearMonth.format('YYYY')}
          defaultMonth={this.state.yearMonth.format('MM')}
        />
        {!this.props.small && (
          <IconButton
            onClick={this.handleNext}
            disabled={!this.state.canGetNext || this.props.disabled}
            aria-label="Następny miesiąc"
            tooltip="Następny miesiąc">
            <ChevronRightIcon
              sx={{
                p: 0,
                fontSize: 30,
                color: (theme) => (this.props.contrast ? theme.palette.color.contrast : theme.palette.color.primary)
              }}
            />
          </IconButton>
        )}
      </Box>
    );
  }
}

YearAndMonthOptionsSlider.propTypes = {
  onSelected: PropTypes.func.isRequired,
  defaultYear: PropTypes.string,
  defaultMonth: PropTypes.string,
  maxYear: PropTypes.object,
  minYear: PropTypes.object,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
  contrast: PropTypes.bool,
  small: PropTypes.bool,
  isMarginLeft: PropTypes.bool
};

YearAndMonthOptionsSlider.defaultProps = {
  maxYear: moment().add(1, 'years'),
  minYear: moment().set('year', 2015)
};

export default YearAndMonthOptionsSlider;
