import { Grid, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { browserHistory } from 'react-router';
import { fromTemplate, routePaths } from '../../../routePaths';
import PersonAvatar from '../../forms/PersonAvatar';
import { avatarColorPaletteArray } from '../../../constants/avatarColorPalette';
import React from 'react';
import moment from 'moment/moment';

const AbsenceListItem = ({ journalEntry }) => {
  return (
    <ListItem
      key={journalEntry.childId || journalEntry.photoUrl}
      onClick={() => {
        browserHistory.push(fromTemplate(`${routePaths.children}` + `/${journalEntry.childId}`));
      }}
      sx={{
        cursor: 'pointer',
        px: { md: 2, xs: 0.5 },
        minHeight: 80,
        '&:hover': {
          background: (theme) => theme.palette.background.hover
        }
      }}>
      <ListItemText
        primary={
          <Grid container sx={{ px: { xs: 1, md: 2 } }}>
            <Grid
              item
              xs={8}
              sx={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}>
              <PersonAvatar
                initials
                palette={avatarColorPaletteArray}
                alt={journalEntry.firstName}
                url={journalEntry.photoUrl}
                firstName={journalEntry.firstName}
                lastName={journalEntry.lastName}
              />
              <Typography
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline'
                  },
                  fontWeight: (theme) => theme.typography.fontWeightBold,
                  pl: { xs: 1, md: 2 },
                  wordBreak: 'break-word'
                }}>
                {journalEntry.lastName} {journalEntry.firstName}
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              <Typography>Zgłoszono: {moment(journalEntry.absence_reported_at).format('DD.MM.YYYY')}</Typography>
            </Grid>
          </Grid>
        }
      />
    </ListItem>
  );
};
export default AbsenceListItem;
