import PropTypes from 'prop-types';
import React from 'react';
import CheckboxV4 from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, Typography } from '@mui/material';

import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

import BreakfastDiningOutlinedIcon from '@mui/icons-material/BreakfastDiningOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import RamenDiningOutlinedIcon from '@mui/icons-material/RamenDiningOutlined';
import SoupKitchenOutlinedIcon from '@mui/icons-material/SoupKitchenOutlined';
import BakeryDiningOutlinedIcon from '@mui/icons-material/BakeryDiningOutlined';
import _ from 'lodash';

const generateIcon = (type) => {
  switch (type) {
    case 'calm':
      return <SentimentSatisfiedAltOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'annoyed':
      return <SentimentDissatisfiedOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'sad':
      return <SentimentDissatisfiedIcon sx={{ fontSize: 30 }} />;

    case 'happy':
      return <SentimentVerySatisfiedOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'tearful':
      return <SentimentDissatisfiedOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'breakfast':
      return <BreakfastDiningOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'secondBreakfast':
      return <RestaurantOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'lunch':
      return <RamenDiningOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'soup':
      return <SoupKitchenOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'afternoonMeal':
      return <BakeryDiningOutlinedIcon sx={{ fontSize: 30 }} />;

    default:
      break;
  }
  return null;
};

class EmojiCheckboxList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: this.props.values || []
    };

    this.handleCheck = this.handleCheck.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    this.props.onChange({ target: { name: this.props.name, value: this.state.values } });
  }

  handleCheck(event, checked) {
    const value = event.target.name;
    if (checked) {
      this.setState({ values: [...this.state.values, value] }, this.onChange);
    } else {
      this.setState({ values: this.state.values.filter((v) => v !== value) }, this.onChange);
      //czyszczenie mealsScale przy wyłączaniu posiłku

      this.props.secondaryname &&
        this.props.onChange({
          target: {
            name: this.props.secondaryname,
            value: [...this.props.mealsScale.filter((meal) => meal.name !== event.target.name)]
          }
        });
    }
  }

  render() {
    return (
      <Box
        sx={{
          m: '14px 0 0 0'
        }}>
        <Typography>{this.props.labelText}</Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}>
          {Object.keys(this.props.emoji).map((value) => (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }} key={_.uniqueId()}>
              <FormControlLabel
                key={value}
                control={
                  <CheckboxV4
                    sx={{ fontSize: 18, width: 'inherits', mr: 1, display: 'flex', alignItems: 'center' }}
                    name={value}
                    checkedIcon={
                      <Box
                        sx={{
                          p: 0,
                          m: 0,
                          color: (theme) => theme.palette.color.wcag || theme.palette.color.color2
                        }}>
                        {generateIcon(this.props.emoji[value].value)}
                      </Box>
                    }
                    icon={
                      <>
                        <Box
                          sx={{
                            opacity: 0.5
                          }}>
                          {generateIcon(this.props.emoji[value].value)}
                        </Box>
                      </>
                    }
                    checked={this.state.values.includes(value)}
                    onChange={this.handleCheck}
                  />
                }
                label={this.props.emoji[value].name}
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
}

EmojiCheckboxList.propTypes = {
  name: PropTypes.string.isRequired,
  secondaryname: PropTypes.string,
  values: PropTypes.array,
  labelText: PropTypes.string.isRequired,
  emoji: PropTypes.object.isRequired,
  checked: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  percentages: PropTypes.bool
};

export default EmojiCheckboxList;
