import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Paper, List, Box, Typography } from '@mui/material';
import AttendanceListItem from './AttendanceListItem';
import ReportAbsenceDialog from '../reportDialogs/ReportAbsenceDialog';
import ReportDropOffDialog from '../reportDialogs/ReportDropOffDialog';
import ReportPickUpDialog from '../reportDialogs/ReportPickUpDialog';
import AbsenceListItem from './AbsenceListItem';
import { connect } from 'react-redux';

class AttendanceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEntry: null
    };
    this.renderListItem = this.renderListItem.bind(this);
    this.renderReportDialog = this.renderReportDialog.bind(this);
  }

  renderReportDialog() {
    if (!this.state.currentEntry) {
      return null;
    }
    if (this.props.ui.dropOff.isEditing && this.props.onReportDropOffStart) {
      return this.renderDropOffDialog();
    }
    if (this.props.ui.pickUp.isEditing && this.props.onReportPickUpStart) {
      return this.renderPickUpDialog();
    }
    if (this.props.ui.absence.isEditing && this.props.onReportAbsenceStart) {
      return this.renderAbsenceDialog();
    }
    return null;
  }

  renderDropOffDialog() {
    return (
      <ReportDropOffDialog
        pupilFirstName={this.state.currentEntry.firstName}
        pupilLastName={this.state.currentEntry.lastName}
        pupilPhotoUrl={this.state.currentEntry.photoUrl}
        droppedOffAt={moment()}
        onCancel={() => {
          this.props.onReportDropOffCancel();
          this.setState({ currentEntry: null });
        }}
        onSave={(droppedOffAt, isAdaptation) =>
          this.props.onReportDropOff(this.state.currentEntry.childId, droppedOffAt, isAdaptation)
        }
        isProcessing={this.props.ui.dropOff.isProcessing}
        isDialogOpen={this.props.ui.dropOff.isEditing}
      />
    );
  }

  renderPickUpDialog() {
    return (
      <ReportPickUpDialog
        pupilFirstName={this.state.currentEntry.firstName}
        pupilLastName={this.state.currentEntry.lastName}
        pupilPhotoUrl={this.state.currentEntry.photoUrl}
        guardians={this.state.currentEntry.allGuardians}
        pickedUpAt={moment()}
        droppedOffAt={this.state.currentEntry.droppedOffAt}
        onCancel={() => {
          this.props.onReportPickUpCancel();
          this.setState({ currentEntry: null });
        }}
        onSave={({ pickedUpAt, pickedUpBy }) =>
          this.props.onReportPickUp(this.state.currentEntry.childId, pickedUpBy, pickedUpAt)
        }
        isProcessing={this.props.ui.pickUp.isProcessing}
        isDialogOpen={this.props.ui.pickUp.isEditing}
      />
    );
  }

  renderAbsenceDialog() {
    return (
      <ReportAbsenceDialog
        pupilFirstName={this.state.currentEntry.firstName}
        pupilLastName={this.state.currentEntry.lastName}
        pupilPhotoUrl={this.state.currentEntry.photoUrl}
        pupilContract={this.state.currentEntry.contract}
        absenceFrom={moment()}
        absenceTo={moment()}
        absenceNotes=""
        onCancel={() => {
          this.props.onReportAbsenceCancel();
          this.setState({ currentEntry: null });
        }}
        onSave={({ absenceFrom, absenceTo, absenceNotes }) =>
          this.props.onReportAbsence(this.state.currentEntry.childId, absenceFrom, absenceTo, absenceNotes)
        }
        isProcessing={this.props.ui.absence.isProcessing}
        isDialogOpen={this.props.ui.absence.isEditing}
      />
    );
  }

  renderListItem(entry) {
    if (this.props.absentTomorrow) return <AbsenceListItem journalEntry={entry} key={entry.childId} />;
    return (
      <AttendanceListItem
        user={this.props.user}
        key={entry.child.id}
        journalEntry={entry}
        onReportDropOff={() => {
          this.setState({ currentEntry: entry }, () => this.props.onReportDropOffStart(entry.childId));
        }}
        onReportPickUp={() => {
          this.setState({ currentEntry: entry }, this.props.onReportPickUpStart);
        }}
        onReportAbsence={() => {
          this.setState({ currentEntry: entry }, this.props.onReportAbsenceStart);
        }}
        onRevertDropOff={() => this.props.onRevertDropOff(entry.childId)}
        onRevertPickUp={() => this.props.onRevertPickUp(entry.childId)}
        onRevertAbsence={() => this.props.onRevertAbsence(entry.childId)}
        noHoursAttendancesEnabled={this.props.noHoursAttendancesEnabled}
      />
    );
  }

  render() {
    if (!this.props.journalEntries.length || !this.props.choosenGroup.id) {
      return null;
    }
    return (
      <Box>
        {this.props.title ? (
          <Box sx={{ display: 'flex', alignContent: 'center', ml: 2, mb: 1 }}>
            <Typography
              variant="h5"
              sx={{
                mr: 2,
                display: 'flex',
                fontWeight: (theme) => theme.typography.fontWeightBold,
                color: (theme) => theme.palette.color.primary
              }}>
              {this.props.title}
            </Typography>

            <Typography
              variant="h5"
              sx={{
                m: 0,
                p: 0,
                fontWeight: (theme) => theme.typography.fontWeightBold,
                color: (theme) => theme.palette.color.primary
              }}>
              {this.props.journalEntries.length}
            </Typography>
          </Box>
        ) : null}
        <Paper
          sx={{
            mb: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            py: 1,
            px: 2
          }}>
          <List>{this.props.journalEntries.map((entry) => this.renderListItem(entry))}</List>
        </Paper>
        {this.renderReportDialog()}
      </Box>
    );
  }
}

AttendanceList.propTypes = {
  title: PropTypes.string.isRequired,
  journalEntries: PropTypes.array.isRequired,

  onReportDropOffStart: PropTypes.func,
  onReportDropOffCancel: PropTypes.func,
  onReportDropOff: PropTypes.func,

  onReportPickUpStart: PropTypes.func,
  onReportPickUpCancel: PropTypes.func,
  onReportPickUp: PropTypes.func,

  onReportAbsenceStart: PropTypes.func,
  onReportAbsenceCancel: PropTypes.func,
  onReportAbsence: PropTypes.func,

  onRevertDropOff: PropTypes.func,
  onRevertPickUp: PropTypes.func,
  onRevertAbsence: PropTypes.func,

  ui: PropTypes.object,

  noHoursAttendancesEnabled: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    choosenGroup: state.digitalDiary.choosenGroup
  };
}

export default connect(mapStateToProps)(AttendanceList);
