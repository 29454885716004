import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as duesFkExportSettingsActions from '../../../actions/duesFkExportSettingsActions';
import moment from 'moment';
import ArchiveIcon from '@mui/icons-material/Archive';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import PageHeader from '../../common/PageHeader';
import DuesExportSettingsDialog from './DuesExportSettingsDialog';
import DuesExportDialog from './DuesExportDialog';
import config from '../../../config/config';
import {
  Box,
  Button,
  TableContainer,
  Typography,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Table
} from '@mui/material';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';

class DuesExportPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      settingsDialogVisible: false,
      exportDialogVisible: false,
      exportInProgress: false
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleRunExport = this.handleRunExport.bind(this);
    this.handleOpenExportDialog = this.handleOpenExportDialog.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadConfiguration();
    this.props.actions.loadExportsHistory();
  }

  handleEdit() {
    this.setState({ settingsDialogVisible: true });
  }

  handleSave(settings) {
    this.props.actions.updateConfiguration(settings);
    this.setState({ settingsDialogVisible: false });
  }

  handleOpenExportDialog() {
    this.setState({ exportDialogVisible: true });
  }

  handleRunExport(data) {
    this.setState({ exportInProgress: true });
    this.props.actions
      .runExport(data)
      .then(() => this.props.actions.loadExportsHistory())
      .finally(() => this.setState({ exportInProgress: false, exportDialogVisible: false }));
  }

  renderDialog() {
    if (this.state.settingsDialogVisible) {
      return (
        <DuesExportSettingsDialog
          onSave={this.handleSave}
          onCancel={() => {
            this.setState({ settingsDialogVisible: false });
          }}
          duesExportSettings={this.props.configuration}
          isDialogOpen={true}
          isProcessing={this.props.ui.isProcessing}
        />
      );
    }
    return null;
  }

  render() {
    const { configuration, duesFkExports } = this.props;
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeader>
            <PageHeaderText title="Eksport Sputnik" titleIcon={<ArchiveIcon />} />
            <Button variant="contained" aria-label="Zmień ustawienia" onClick={this.handleEdit} sx={{ mb: 2 }}>
              Zmień ustawienia
            </Button>
          </PageHeader>
          <Paper>
            <Typography variant="h6">Ustawienia eksportu</Typography>
            <Box>
              Adres email: <strong>{configuration.email || 'nie podano'}</strong>
            </Box>
            <Box>
              Symbol: <strong>{configuration.unitSymbol || 'nie podano'}</strong>
            </Box>
            <Button
              sx={{ color: (theme) => theme.palette.color.wcag }}
              aria-label="Uruchom eksport pliku"
              variant="outlined"
              onClick={this.handleOpenExportDialog}
              disabled={this.state.exportInProgress || !configuration.email}>
              Uruchom eksport pliku
            </Button>
            <Typography variant="h6">Historia</Typography>
            <TableContainer>
              <Table aria-label="Tabela exportu">
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Liczba rozliczeń</TableCell>
                    <TableCell>Rodzaj rozliczeń</TableCell>
                    <TableCell>Rozliczenie za miesiąc</TableCell>
                    <TableCell
                      sx={{
                        border: 0,
                        clip: 'rect(0 0 0 0)',
                        height: 1,
                        margin: -1,
                        overflow: 'hidden',
                        p: 0,
                        position: 'absolute',
                        top: 20,
                        width: 1
                      }}>
                      Koniec nagłówka
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {duesFkExports.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{moment(item.exportedAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.duesCount}</TableCell>
                      <TableCell>{item.type === 'payments' ? 'Wpłaty' : 'Należności'}</TableCell>
                      <TableCell>{item.month}</TableCell>
                      <TableCell>
                        <a href={`${config.apiUrl}/settlements/exports/${item.id}/download`}>Pobierz(.xlsx)</a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {this.renderDialog()}
          <DuesExportDialog
            open={this.state.exportDialogVisible}
            groups={this.props.groups.map((group) => group.id)}
            onSubmit={this.handleRunExport}
            onClose={() => {
              this.setState({ exportDialogVisible: false });
            }}
          />
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

DuesExportPage.propTypes = {
  configuration: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  duesFkExports: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    configuration: state.configuration.duesFkExportSettings,
    ui: state.configuration.duesFkExportSettingsUi,
    duesFkExports: state.duesFkExports,
    groups: state.groups
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(duesFkExportSettingsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DuesExportPage);
