import PropTypes from 'prop-types';
import FullCalendar from '../../calendar/FullCalendar';
import LoadingIndicatorWrapper from '../../loading/LoadingIndicatorWrapper';
import moment from 'moment';
import CalendarDay from '../../calendar/Grid/CalendarDay';
import CalendarDayContent from '../../calendar/Grid/CalendarDayContent';
import DayOffDayContent from '../daycare/DayOffDayContent';
import MealContent from './MealContent';

const ChildMealsCalendar = ({
  markedDays = [],
  daysOff = [],
  onDateChange,
  onDetailsOpen,
  defaultYear,
  defaultMonth,
  isLoading = false
}) => {
  const includeMarkedDays = (day) => {
    const dayOff = daysOff.find((d) => moment(d.date).format('YYYY-MM-DD') === moment(day.date).format('YYYY-MM-DD'));
    const isDayOff = !!dayOff;
    return {
      date: moment(day.date).format('YYYY-MM-DD'),
      data: {
        ...day,
        isDayOff,
        dayOffName: isDayOff ? dayOff.name : ''
      }
    };
  };

  const renderDayContent = (day, containerWidth, data) => {
    if (data && !day.isWeekend) {
      return <MealContent day={day} containerWidth={containerWidth} daycareEntry={data} />;
    }
    if (day.isWeekend && day.isCurrentMonth) {
      return <CalendarDayContent day={day} containerWidth={containerWidth} />;
    }
    if (data.isDayOff) {
      return <DayOffDayContent day={day} containerWidth={containerWidth} dayOffName={data.dayOffName} />;
    }
    return <CalendarDayContent day={day} containerWidth={containerWidth} />;
  };

  return (
    <LoadingIndicatorWrapper
      isProcessing={isLoading}
      shouldUnmountChildrenWhenProcessing={false}
      sx={{ display: 'flex', flexDirection: 'column' }}>
      <FullCalendar
        markedDays={markedDays.map((a) => includeMarkedDays(a))}
        onYearOrMonthSelected={(year, month) => onDateChange(moment(new Date(year, month - 1, 1)))}
        onDayRender={(day, contentRender, onDaySelected, containerWidth, isDisabled) => (
          <CalendarDay
            isDisabled={!day.data && (day.data.isDayOff || isDisabled)}
            day={day}
            onDaySelected={onDaySelected}
            contentRender={contentRender}
            containerWidth={containerWidth}
          />
        )}
        onDayContentRender={(day, containerWidth) => renderDayContent(day, containerWidth, day.data)}
        defaultMonth={defaultMonth}
        defaultYear={defaultYear}
        onDaySelected={(day) => {
          if (day.isWeekend === false && day.data.isDayOff === false) {
            onDetailsOpen(day);
          } else {
            return null;
          }
        }}
      />
    </LoadingIndicatorWrapper>
  );
};

ChildMealsCalendar.propTypes = {
  markedDays: PropTypes.array,
  daysOff: PropTypes.array.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onDaySelected: PropTypes.func.isRequired,
  onDetailsOpen: PropTypes.func.isRequired,
  defaultYear: PropTypes.string,
  defaultMonth: PropTypes.string,
  isLoading: PropTypes.bool
};

export default ChildMealsCalendar;
