import { Box, FormControl, FormHelperText, TextField } from '@mui/material';
import React, { useState } from 'react';
import moment from 'moment/moment';
import { TimeModel } from '../../../../models/TimeModel';
import DialogForm from '../../../forms/DialogForm';
import RichTextEditor from '../../../forms/richTextEditor/RichTextEditor';
import ClearButtonWrapper from '../../../common/ClearButtonWrapper';
import DatePicker from '../../../common/DatePicker';
import TimePicker from '../../../common/TimePicker';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import { SpecialistGroupActivitiesValidator } from '../../../../models/specialist/SpecialistGroupActivitiesModel';

const GroupActivitiesDialog = (props) => {
  const [post, setPost] = useState(Object.assign({}, props.post));

  const [errors, setErrors] = useState({});

  const { isMobile } = props;

  const handleTitleChange = (e) => {
    setPost((prevPost) => Object.assign(prevPost, { title: e.target.value }));
  };

  const handleContentChange = (htmlContent) => {
    setPost((prevPost) => Object.assign(prevPost, { content: htmlContent }));
  };

  const handleEventDateChange = (date) => {
    setPost((prevPost) =>
      Object.assign(prevPost, {
        eventDate: date ? moment(date).format('YYYY-MM-DD') : null
      })
    );
  };

  const handleEventStartTimeChange = (time) => {
    setPost((prevPost) =>
      Object.assign(prevPost, { eventStartTime: !!time ? new TimeModel().parse(time) : new TimeModel() })
    );
  };

  const handleEventEndTimeChange = (time) => {
    setPost((prevPost) =>
      Object.assign(prevPost, { eventEndTime: !!time ? new TimeModel().parse(time) : new TimeModel() })
    );
  };

  const handleOnSave = () => {
    props.onSave(post);
  };

  return (
    <DialogForm
      errors={errors}
      onSave={handleOnSave}
      onCancel={() => props.onCancel(post)}
      onValidate={() => new SpecialistGroupActivitiesValidator().validate(post)}
      onValidationDone={(errors) => {
        setErrors(errors);
      }}
      statePathToUi="specialistNotesUi.groupActivities"
      isDialogOpen={props.isEditing}
      isProcessing={props.isProcessing}
      header="Utwórz nowy wpis"
      saveDisabled={Object.values(errors).filter((e) => e !== '').length > 0}
      requiredFieldText>
      <FieldsetWrapper legend="Pola na temat wydarzenia dotyczącego zajęcia dodatkowego">
        <TextField
          sx={{
            fontSize: 24,
            lineHeight: 36
          }}
          label="Tytuł*"
          name="title"
          id="title"
          variant="standard"
          defaultValue={post.title}
          onChange={handleTitleChange}
          fullWidth={true}
          error={!!errors.title}
          helperText={errors.title}
        />
        <FormControl
          error={errors.content}
          sx={{
            width: '100%',
            mb: 3,
            borderBottom: !!errors && !!errors.content ? (theme) => `1px solid ${theme.palette.color.error}` : null
          }}>
          <RichTextEditor onChange={handleContentChange} initialText={post.content} />
          <FormHelperText>{errors.content}</FormHelperText>
        </FormControl>

        {props.canSetDate && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'start', justifyContent: 'space-between' }}>
            <ClearButtonWrapper contrast onClear={() => handleEventDateChange()} disabled={!props.canClearDate}>
              <DatePicker
                sx={{ width: 200, m: 0 }}
                floatingLabelText="Data wydarzenia*"
                textFieldStyle={{ width: 128 }}
                onChange={(e, date) => handleEventDateChange(date)}
                value={post.eventDate}
                name="eventDate"
                errorText={errors.eventDate}
                id="dataWydarzeniaPicker"
              />
            </ClearButtonWrapper>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems="flex-start"
              justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
              <ClearButtonWrapper
                sx={{ mr: props.canClearDate ? 0 : 2 }}
                contrast
                onClear={() => handleEventStartTimeChange()}
                disabled={!props.canClearDate}>
                <TimePicker
                  floatingLabelText="Rozpoczęcie"
                  textFieldStyle={{ width: 128 }}
                  onChange={(e, time) => handleEventStartTimeChange(time)}
                  value={post?.eventStartTime.toDate()}
                  name="eventStartTime"
                  errorText={errors.eventStartTime}
                />
              </ClearButtonWrapper>
              <ClearButtonWrapper contrast onClear={() => handleEventEndTimeChange()} disabled={!props.canClearDate}>
                <TimePicker
                  floatingLabelText="Zakończenie"
                  textFieldStyle={{ width: 128 }}
                  onChange={(e, time) => handleEventEndTimeChange(time)}
                  value={post?.eventEndTime.toDate()}
                  name="eventEndTime"
                  errorText={errors.eventEndTime}
                />
              </ClearButtonWrapper>
            </Box>
          </Box>
        )}
      </FieldsetWrapper>
    </DialogForm>
  );
};
export default GroupActivitiesDialog;
