import { ChargingReportTypes } from '../../../../constants/chargingReportTypes';
import { getComparator, stableSort } from '../../../../utils/tableSorting';
import numberFormatter from '../../../../utils/numberFormatter';
import moment from 'moment';
import React from 'react';
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableContainer,
  TableHead,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import SortedTableCell from '../../../table/SortedTableCell';
import * as definedSortTypes from '../../../../constants/definedSortTypes';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettlementStatus from '../../SettlementStatus';

const useCellBodyStyles = makeStyles({
  root: {
    fontSize: 16,
    py: 0,
    px: 2,
    align: 'left'
  }
});

const useCellHeaderStyles = makeStyles({
  root: {
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: 14
  }
});

const CorrectPaymentsTable = ({ data, order, orderBy, handleRequestSort }) => {
  const sortedCells = [
    {
      id: 'number',
      label: 'Numer',
      type: definedSortTypes.STRING_SORT
    },
    {
      id: 'name',
      label: 'Dziecko',
      type: definedSortTypes.STRING_SORT
    },
    {
      id: 'dueType',
      label: 'Opłata',
      type: definedSortTypes.CHARGING_REPORT_TYPE
    },
    {
      id: 'calculatedTotal',
      label: 'Kwota',
      type: definedSortTypes.NUMBER_SORT
    },
    {
      id: 'calculatedTotalUnadjusted',
      label: 'Do zapłaty',
      type: definedSortTypes.NUMBER_SORT
    },
    {
      id: 'calculationStatus',
      label: 'Status',
      type: definedSortTypes.STRING_SORT
    },
    {
      id: 'calculationDate',
      label: 'Wygenerowano',
      type: definedSortTypes.DATE_SORT
    },
    {
      id: 'paymentAmount',
      label: 'Wpłacono',
      type: definedSortTypes.NUMBER_SORT
    }
  ];

  const cellHeaderStyles = useCellHeaderStyles();
  const cellBodyStyles = useCellBodyStyles();

  return (
    <Box>
      <Accordion sx={{ '&:hover': { background: (theme) => theme.palette.background.hover } }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
            Poprawne płatności:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow sx={{ fontSize: 15, mt: '5', mb: '5' }}>
                  {sortedCells.map((cell) => (
                    <SortedTableCell
                      cell={cell}
                      key={cell.id}
                      order={order}
                      orderBy={orderBy.property}
                      classes={{ root: cellHeaderStyles.root }}
                      align="left"
                      onRequestSort={handleRequestSort}
                    />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(data, getComparator(order, orderBy)).map((row, rowIndex) => {
                  return (
                    <TableRow
                      sx={{
                        '&:hover': {
                          background: (theme) => theme.palette.background.hover
                        }
                      }}
                      key={`${row.id}-${rowIndex}`}
                      tabIndex={0}>
                      <TableCell classes={{ root: cellBodyStyles.root }} align="left">
                        {row.number}
                      </TableCell>
                      <TableCell classes={{ root: cellBodyStyles.root }} align="left">
                        {`${row.name}`}
                      </TableCell>
                      <TableCell classes={{ root: cellBodyStyles.root }} align="left">
                        {ChargingReportTypes[row.dueType].name}
                      </TableCell>
                      <TableCell classes={{ root: cellBodyStyles.root }} align="left">
                        <strong>
                          {row.getTotalAmount() !== undefined && row.getTotalAmount() !== null
                            ? numberFormatter.format(row.getTotalAmount())
                            : '-'}
                        </strong>
                      </TableCell>
                      <TableCell classes={{ root: cellBodyStyles.root }} align="left">
                        <strong>
                          {row.getAmountToPaid() !== undefined && row.getAmountToPaid() !== null
                            ? numberFormatter.format(row.getAmountToPaid())
                            : '-'}
                        </strong>
                      </TableCell>
                      <TableCell classes={{ root: cellBodyStyles.root }} align="left">
                        <SettlementStatus calculationStatus={row.calculationStatus} />
                      </TableCell>
                      <TableCell classes={{ root: cellBodyStyles.root }} sx={{ maxWidth: 110 }} align="left">
                        {moment(row.calculationDate).format('YYYY-MM-DD')}
                      </TableCell>
                      <TableCell classes={{ root: cellBodyStyles.root }} sx={{ maxWidth: 110 }} align="left">
                        <strong>{numberFormatter.format(row.paymentAmount)}</strong>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

CorrectPaymentsTable.propTypes = {
  data: PropTypes.array,
  order: PropTypes.string,
  orderBy: PropTypes.object,
  handleRequestSort: PropTypes.func
};

export default CorrectPaymentsTable;
