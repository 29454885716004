/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function unitUiReducer(state = initialState.configuration.unitAbsenceRemovalDeadlineUi, action) {
  switch (action.type) {
    case types.UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE: {
      return UiModel.start();
    }
    case types.UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_CANCEL: {
      return UiModel.cancel();
    }
    case types.UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_SUBMIT: {
      return UiModel.submit();
    }
    case types.UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_SUCCESS: {
      return UiModel.success();
    }
    case types.UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_FAILURE: {
      return UiModel.failure(action.errors);
    }
    //
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.configuration.unitAbsenceRemovalDeadlineUi);
    }
    default:
      return state;
  }
}
