export const CalculationStatus = Object.freeze({
  draft: { value: 'draft', name: 'Robocze' },
  approved: { value: 'approved', name: 'Zatwierdzone' },
  failed: { value: 'failed', name: 'Błąd obliczeń' },
  canceled: { value: 'canceled', name: 'Anulowane' },
  paid: { value: 'paid', name: 'Opłacone' },
  partlyPaid: { value: 'partlyPaid', name: 'Częściowo opłacone' }
});

export const calculationStatusComparator = (a, b, orderBy, order) => {
  const mappedA = a[orderBy] && CalculationStatus[a[orderBy]].name;
  const mappedB = b[orderBy] && CalculationStatus[b[orderBy]].name;
  const tempA = mappedA && mappedA.toLowerCase();
  const tempB = mappedB && mappedB.toLowerCase();
  if (tempA !== null) {
    return tempA.localeCompare(tempB, 'pl');
  }
  return order === 'asc' ? 1 : -1;
};
