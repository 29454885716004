import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FilterBar from '../../common/FilterBar';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import FaceIcon from '@mui/icons-material/Face';
import { caseInsensitiveComparator } from '../../../utils/polishSort';
import ChildrenArchiveList from '../../childrenArchvie/ChildrenArchiveList';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import { Box, Paper } from '@mui/material';
import ContentWrapper from '../../common/contentWrapper';

const StudentArchivePage = () => {
  const [filteredPupils, setFilteredPupils] = useState([]);
  const pupils = useSelector((state) => state.archives.children);

  const groupChildrenByLastName = (children) => {
    return _.chain(children)
      .sort((a, b) => caseInsensitiveComparator(a.lastName, b.lastName))
      .groupBy((pupil) => (pupil.lastName.length ? pupil.lastName[0].toUpperCase() : ''))
      .toPairs()
      .value();
  };

  const handleLoaded = () => setFilteredPupils(groupChildrenByLastName(pupils));
  const handleFilterChange = (filteredList) => setFilteredPupils(groupChildrenByLastName(filteredList));

  return (
    <LoadingRenderWrapper onLoaded={handleLoaded}>
      <Box>
        <PageHeaderText title="Rejestr zarchiwizowanych uczniów" titleIcon={<FaceIcon />} />
        <ContentWrapper>
          <Paper>
            <FilterBar
              itemsToFilter={pupils}
              onFilter={handleFilterChange}
              filter={(child, keyword) =>
                child.lastName.toLowerCase().includes(keyword) || child.firstName.toLowerCase().includes(keyword)
              }
            />
            <ChildrenArchiveList pupils={filteredPupils} />
          </Paper>
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};

export default StudentArchivePage;
