import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../forms/DialogForm';
import { ReportTypes } from '../../constants/reportTypes';
import { ChargingReportTypes } from '../../constants/chargingReportTypes';
import { PaymentMethods } from '../../constants/paymentMethods';
import ChildrenAttendanceInDaysReport from './Configurations/ChildrenAttendanceInDaysReport';
import ChildrenAbsenceInDaysReport from './Configurations/ChildrenAbsenceInDaysReport';
import GroupAttendanceDailySummaryReport from './Configurations/GroupAttendanceDailySummaryReport';
import ReliefsByChildrenReport from './Configurations/ReliefsByChildrenReport';
import ReliefsSummaryReport from './Configurations/ReliefsSummaryReport';
import ChildrenListReport from './Configurations/ChildrenListReport';
import ChildrenActivitiesReport from './Configurations/ChildrenActivitiesReport';
import PaymentsSummaryPerChildReport from './Configurations/PaymentsSummaryPerChildReport';
import DetailedPaymentsSummaryPerChildReport from './Configurations/DetailedPaymentsSummaryPerChildReport';
import SimplifiedPaymentsSummaryPerChildReport from './Configurations/SimplifiedPaymentsSummaryPerChild';
import ChildrenAverageAttendanceInHourIntervalsReport from './Configurations/ChildrenAverageAttendanceInHourIntervalsReport';
import DuesPaymentsReport from './Configurations/DuesPaymentsReport';
import DuesStayDetailsOverallReport from './Configurations/DuesStayOverallReport';
import DuesActivitiesOverallReport from './Configurations/DuesActivitiesOverallReport';
import DuesCateringDetailsOverallReport from './Configurations/DuesCateringOverallReport';
import DuesOtherDetailsOverallReport from './Configurations/DuesOtherOverallReport';
import DuesDetailsOverallReport from './Configurations/DuesDetailsOverallReport';
import ChildrenOpinionReport from './Configurations/ChildrenOpinionReport';
import ChildrenAttributeSetValuesReport from './Configurations/ChildrenAttributeSetValuesReport';
import ChildrenAttributeSetValuesSummaryReport from './Configurations/ChildrenAttributeSetValuesSummaryReport';
import SchoolYearInaugurationReport from './Configurations/SchoolYearInaugurationReport';
import LinearProgress from '@mui/material/LinearProgress';
import AttributeSetAttendanceReport from './Configurations/AttributeSetAttendanceReport';
import ChildrenPlannedAttendancesReport from './Configurations/ChildrenPlannedAttendancesReport';
import ChildrenAttendanceInHourIntervalsMonthReport from './Configurations/ChildrenAttendanceInHourIntervalsMonthReport';
import DuesDetailsOverallReportXLS from './Configurations/DuesDetailsOverallReportXLS';
import DatePickerAndGroupTemplate from './Configurations/common/DateAndGroupPickerTemplate';
import ReportedAbsencesByTheHoursOfPreviousDayReport from './Configurations/ReportedAbsencesByTheHourOfPreviousDayReport';
import ListOfTransfersReport from './Configurations/ListOfTransfersReport';
import StatementOfExpectedInterestAmountForDayReport from './Configurations/StatementOfExpectedInterestAmountForDayReport';
import StatementOfSettlementsForMonthReport from './Configurations/StatementOfSettlementsForMonthReport';
import ListOfArrearsForMonthReport from './Configurations/ListOfArrearsForMonthReport';
import KindergartenActivityLog from './Configurations/KindergartenActivityLog';

import moment from 'moment';
import _ from 'lodash';
import { Box } from '@mui/material';
import GroupPickerTemplate from './Configurations/common/GroupPickerTemplate';
import GroupAndMonthTemplate from './Configurations/common/YearMonthAndGroupPickerTemplate';
import YearAndMonthTemplate from './Configurations/common/YearAndMonthPickerTemplate';
import SingleDateAndGroupPickerTemplate from './Configurations/common/SingeDateAndGroupPickerTemplate';
import DayAndGroupPickerTemplate from './Configurations/common/DayAndGroupPickerTemplate';
import ChildrenAttendanceInHoursDuesReport from './Configurations/ChildrenAttendanceInHoursDuesReport';
import ChildrenAbsenceReportedAtDetailsReport from './Configurations/ChildrenAbsenceReportedAtDetailsReport';
import ListOfFeesPaidReport from './Configurations/ListOfFeesPaidReport ';
import { connect } from 'react-redux';
import userRoles from '../../constants/userRoles';
import ChildrenDiagnosesReport from './Configurations/ChildrenDiagnosesReport';
import SingleDatePickerTemplate from './Configurations/common/SingleDatePickerTemplate';
import ChildrenAttendanceInDaysByGroupReport from './Configurations/ChildrenAttendanceInDaysByGroupReport';

class GenerateReportDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportConfiguration: null,
      errors: {},
      reportPayload: ''
    };
    this.onUpdateReportConfiguration = this.onUpdateReportConfiguration.bind(this);
    this.setReportPayload = this.setReportPayload.bind(this);
    this.filterTeacherGroups = this.filterTeacherGroups.bind(this);
  }

  componentDidMount() {
    this.setDefaultReportConfiguration(this.props.reportType.type, this.props);
  }

  onUpdateReportConfiguration(event) {
    const field = event.target.name;
    const { reportConfiguration } = this.state;
    reportConfiguration[field] = event.target.value;
    if (
      this.props.auth.userRole === userRoles.staffMemberTeacher &&
      !this.props.unit.teacherAllGroupVisibility &&
      field === 'groupId' &&
      event.target.value === 'all'
    ) {
      this.setState({ errors: { groupId: 'Wybierz grupę.' } });
    } else if (!!this.state.errors.groupId && field === 'groupId') {
      this.setState({ errors: { groupId: undefined } });
    }
    return this.setState({ reportConfiguration });
  }

  filterTeacherGroups(groups) {
    const user = this.props.staffMembers.find((obj) => obj.id === this.props.auth.userId);
    return !this.props.unit.teacherAllGroupVisibility && this.props.auth.userRole === userRoles.staffMemberTeacher
      ? this.props.groups.filter((group) => user.groups.find((userGroup) => userGroup.id === group.id))
      : this.props.groups;
  }

  getMonthDateRange() {
    return {
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD')
    };
  }

  getWeek() {
    return {
      startDate: moment().isoWeekday(1).format('YYYY-MM-DD'),
      endDate: moment().isoWeekday(7).format('YYYY-MM-DD')
    };
  }

  getDay() {
    return {
      date: moment().startOf('month').format('YYYY-MM-DD')
    };
  }

  getLastDay() {
    return {
      date: moment().endOf('month').format('YYYY-MM-DD')
    };
  }

  setReportPayload(payload) {
    this.setState({ reportPayload: payload });
  }

  setDefaultReportConfiguration(reportType, nextProps) {
    const defaultGroupIds =
      this.props.auth.userRole !== userRoles.staffMemberTeacher || this.props.unit.teacherAllGroupVisibility
        ? undefined
        : this.filterTeacherGroups(nextProps.groups).map((g) => g.id);

    if (reportType === ReportTypes.childrenAttendanceInHours.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id, ...this.getMonthDateRange() });
    if (reportType === ReportTypes.childrenAttendanceInHoursXls.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id, ...this.getMonthDateRange() });
    if (reportType === ReportTypes.childrenAttendanceInDays.type)
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        ...this.getMonthDateRange(),
        includeArchivedChildren: false
      });
    if (reportType === ReportTypes.childrenAttendanceInDaysXls.type)
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        ...this.getMonthDateRange(),
        includeArchivedChildren: false
      });
    if (reportType === ReportTypes.childrenAbsenceInDays.type)
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        ...this.getMonthDateRange(),
        includeArchivedChildren: false
      });
    if (reportType === ReportTypes.childrenAbsenceInDaysXls.type)
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        ...this.getMonthDateRange(),
        includeArchivedChildren: false
      });
    if (reportType === ReportTypes.childrenAbsenceReportedAtDetails.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id, ...this.getMonthDateRange(), division: false });
    if (reportType === ReportTypes.childrenOpinionsSummary.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id });
    if (reportType === ReportTypes.childrenSummaryPerGroup.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id });
    if (reportType === ReportTypes.childrenAttendanceJournal.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id, ...this.getMonthDateRange() });
    if (reportType === ReportTypes.digitalDiaryAdditionalActivities.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id, ...this.getMonthDateRange() });
    if (reportType === ReportTypes.psychologicalPedagogicalIndividualClassesTimetable.type)
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        ...this.getMonthDateRange(),
        disabledGroupPicker: true
      });
    if (reportType === ReportTypes.digitalDiaryDaySchedule.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id, startDate: moment().format('YYYY-MM-DD') });
    if (reportType === ReportTypes.digitalDiarySaveWork.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id, ...this.getMonthDateRange() });
    if (reportType === ReportTypes.boardPostsListReport.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id, ...this.getMonthDateRange() });
    if (reportType === ReportTypes.digitalDiaryWorkPlan.type)
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        month: moment().format('MM'),
        year: moment().format('YYYY')
      });
    if (reportType === ReportTypes.digitalDiaryWorkWithParents.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id, ...this.getMonthDateRange() });
    if (reportType === ReportTypes.groupAttendanceDailySummary.type)
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        ...this.getMonthDateRange(),
        showNotReportedAsAttendant: false
      });
    if (reportType === ReportTypes.groupActivitiesAttendanceDailySummary.type)
      this.setConfiguration({
        activityId: _.isEmpty(nextProps.activities) ? null : nextProps.activities[0].id,
        ...this.getMonthDateRange()
      });
    if (reportType === ReportTypes.reliefsByChildren.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id, reliefId: nextProps.reliefs[0].id });
    if (reportType === ReportTypes.paymentsSummary.type)
      this.setConfiguration({ month: moment().format('MM'), year: moment().format('YYYY') });
    if (reportType === ReportTypes.childrenAverageAttendanceInHourIntervals.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id, ...this.getMonthDateRange() });
    if (reportType === ReportTypes.childrenAttendanceInHourIntervalsMonth.type && this.state.reportPayload)
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        ...this.getMonthDateRange(),
        intervals: this.state.reportPayload
      });
    if (reportType === ReportTypes.childrenAttendanceInHourIntervalsMonth.type && !this.state.reportPayload)
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        ...this.getMonthDateRange()
      });

    if (reportType === ReportTypes.stayDuesPayments.type)
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        showOverdue: true,
        showPaymentDates: false,
        showStaff: false,
        groupIds: defaultGroupIds
      });
    if (reportType === ReportTypes.cateringDuesPayments.type)
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        showStaff: false,
        showOverdue: true,
        showPaymentDates: false,
        groupIds: defaultGroupIds
      });
    if (reportType === ReportTypes.childrenPlannedAttendances.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id, ...this.getDay(), includeAllergies: false });
    if (reportType === ReportTypes.childrenOpinionReport.type) this.setConfiguration({});
    if (reportType === ReportTypes.reliefsSummary.type) {
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        schemeReportType: ChargingReportTypes.stay.value,
        reliefIds: nextProps.reliefs.filter((r) => r.id !== 'all').map((r) => r.id),
        reliefsDate: moment().format('YYYY-MM-DD')
      });
    }
    if (reportType === ReportTypes.childrenList.type) {
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        printEmptyColumns: 0,
        printGroupName: false,
        printLandscape: false
      });
    }
    if (reportType === ReportTypes.canteenSummaryReport.type) {
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        date: moment().format('YYYY-MM-DD')
      });
    }
    if (reportType === ReportTypes.canteenSummaryByMealsAtDayReport.type) {
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        date: moment().format('YYYY-MM-DD')
      });
    }
    if (reportType === ReportTypes.childrenAttendanceInDaysByGroup.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupId: nextProps.groups[0].id,
        addIncorrectAbsencesData: false
      });
    }
    if (reportType === ReportTypes.listOfManDaysReport.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupId: nextProps.groups[0].id
      });
    }
    if (reportType === ReportTypes.staySettlementsDetailsFromReaderReport.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupId: nextProps.groups[0].id
      });
    }
    if (reportType === ReportTypes.dailyAbsenceOfChildrenReport.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupId: nextProps.groups[0].id
      });
    }
    if (reportType === ReportTypes.cateringSettlementsDetailsFromReaderReport.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupId: nextProps.groups[0].id
      });
    }
    if (reportType === ReportTypes.listOfArrearsForMonthReport.type) {
      this.setConfiguration({
        selectedDate: moment().format('YYYY-MM-DD'),
        groupId: nextProps.groups[0].id,
        type: 'getDataFromStatementOfSettlements'
      });
    }
    if (reportType === ReportTypes.listOfTransfersReport.type) {
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        ...this.getMonthDateRange(),
        splitPayments: false
      });
    }
    if (reportType === ReportTypes.statementOfInterestReport.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupId: nextProps.groups[0].id
      });
    }
    if (reportType === ReportTypes.statementOfOverpaymentsReport.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupId: nextProps.groups[0].id
      });
    }

    if (reportType === ReportTypes.reportedAbsencesByTheHourOfPreviousDayReport.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupId: nextProps.groups[0].id,
        hour: '15:00'
      });
    }

    if (reportType === ReportTypes.deductionAndSettlementOfPaymentForStayAndCatering.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupId: nextProps.groups[0].id
      });
    }

    if (reportType === ReportTypes.summaryOfAttendanceAtMealsReport.type) {
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        date: moment().format('YYYY-MM-DD')
      });
    }

    if (reportType === ReportTypes.statementOfAccrualsForMonthReport.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupId: nextProps.groups[0].id
      });
    }

    if (reportType === ReportTypes.statementOfSettlementsForMonthReport.type) {
      this.setConfiguration({
        selectedDate: moment().format('YYYY-MM-DD'),
        groupId: nextProps.groups[0].id
      });
    }

    if (reportType === ReportTypes.settlementForTheMonth.type) {
      this.setConfiguration({
        selectedDate: moment().format('YYYY-MM-DD'),
        groupId: nextProps.groups[0].id
      });
    }

    if (reportType === ReportTypes.paymentAssignment.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupId: nextProps.groups[0].id
      });
    }

    if (reportType === ReportTypes.childrenActivities.type) {
      this.setConfiguration({
        activityId: _.isEmpty(nextProps.activities) ? null : nextProps.activities[0].id,
        printEmptyColumns: 0,
        printGroupName: false,
        printLandscape: false
      });
    }
    if (reportType === ReportTypes.paymentsSummaryPerChild.type) {
      this.setConfiguration({
        ...this.getMonthDateRange(),
        groupIds: defaultGroupIds,
        dueTypes: [ChargingReportTypes.stay.value, ChargingReportTypes.catering.value, ChargingReportTypes.other.value],
        paymentMethods: [
          PaymentMethods.cash.value,
          PaymentMethods.online.value,
          PaymentMethods.transfer.value,
          PaymentMethods.fromBalance.value
        ]
      });
    }
    if (reportType === ReportTypes.detailedPaymentsSummaryPerChild.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupIds: defaultGroupIds,
        dueType: ChargingReportTypes.stay.value,
        dontShowNegativeValues: false
      });
    }
    if (reportType === ReportTypes.simplifiedPaymentsSummaryPerChild.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupIds: defaultGroupIds,
        dueType: ChargingReportTypes.stay.value,
        showInterest: false
      });
    }
    if (reportType === ReportTypes.childrenAttendanceInHoursDuesXls.type) {
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        ...this.getMonthDateRange(),
        advancedHourlyCalculation: false
      });
    }
    if (reportType === ReportTypes.duesStayDetailsOverall.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupIds: defaultGroupIds,
        dueTypes: [ChargingReportTypes.stay.value],
        summaryRelief: false
      });
    }
    if (reportType === ReportTypes.duesActivitiesOverallReport.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        activityId: _.isEmpty(nextProps.activities) ? null : nextProps.activities[0].id,
        dueTypes: [ChargingReportTypes.activities.value]
      });
    }
    if (reportType === ReportTypes.duesCateringDetailsOverall.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupIds: defaultGroupIds,
        dueTypes: [ChargingReportTypes.catering.value, ChargingReportTypes.staffCatering.value],
        showOverdue: true
      });
    }
    if (reportType === ReportTypes.duesOtherDetailsOverall.type) {
      this.setConfiguration({
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupIds: defaultGroupIds,
        dueTypes: [ChargingReportTypes.other.value]
      });
    }
    if (reportType === ReportTypes.duesDetailsOverall.type) {
      this.setConfiguration({
        sortBy: 'name',
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupIds: defaultGroupIds,
        dueTypes: [
          ChargingReportTypes.stay.value,
          ChargingReportTypes.catering.value,
          ChargingReportTypes.other.value,
          ChargingReportTypes.staffCatering.value
        ],
        summaryRelief: false
      });
    }
    if (reportType === ReportTypes.duesDetailsXls.type) {
      this.setConfiguration({
        sortBy: 'name',
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        groupIds: defaultGroupIds,
        dueTypes: [ChargingReportTypes.stay.value, ChargingReportTypes.catering.value, ChargingReportTypes.other.value]
      });
    }
    if (reportType === ReportTypes.childrenAttributeSetValues.type) {
      this.setConfiguration({
        groupId: nextProps.groups.length > 0 ? nextProps.groups[0].id : null,
        attributeSetId: nextProps.attributeSets.length > 0 ? nextProps.attributeSets[0].id : null,
        includePresentChildren: false,
        includeNotReportedChildren: false
      });
    }
    if (reportType === ReportTypes.childrenAttributeSetValuesSummary.type) {
      this.setConfiguration({
        groupId: nextProps.groups.length > 0 ? nextProps.groups[0].id : null,
        attributeSetId: nextProps.attributeSets.length > 0 ? nextProps.attributeSets[0].id : null,
        includePresentChildren: false,
        includeNotReportedChildren: false
      });
    }
    if (reportType === ReportTypes.schoolYearInauguration.type) {
      this.setConfiguration({
        startDate: `${moment().format('YYYY')}-09-01`
      });
    }
    if (reportType === ReportTypes.legalGuardianDebts.type) {
      this.setConfiguration({
        maturityDate: moment().format('YYYY-MM-DD')
      });
    }
    if (reportType === ReportTypes.attributeSetAttendanceReport.type) {
      this.setConfiguration({
        groupIds: defaultGroupIds,
        attributeSetId: nextProps.attributeSets.length > 0 ? nextProps.attributeSets[0].id : null
      });
    }
    if (reportType === ReportTypes.listOfFeesPaidReport.type) {
      this.setConfiguration({
        ...this.getMonthDateRange(),
        selectOther: false
      });
    }
    if (reportType === ReportTypes.statementOfExpectedInterestAmountForDayReport.type) {
      this.setConfiguration({
        groupId: nextProps.groups[0].id,
        ...this.getLastDay()
      });
    }
    if (reportType === ReportTypes.calendarEventsListReport.type)
      this.setConfiguration({ groupId: nextProps.groups[0].id, ...this.getMonthDateRange() });
    if (reportType === ReportTypes.childrenDiagnosesReport.type) {
      this.setConfiguration({
        groupIds: 'none'
      });
    }
    if (reportType === ReportTypes.kindergartenActivityLog.type) {
      this.setConfiguration({
        groupId: nextProps.groups[1].id,
        year: moment().format('YYYY')
      });
    }
  }

  setConfiguration(config) {
    this.setState({ reportConfiguration: config });
  }

  renderReportConfiguration(reportType, props) {
    if (!this.state.reportConfiguration) return null;

    if (reportType === ReportTypes.childrenDiagnosesReport.type) return <ChildrenDiagnosesReport />;
    if (reportType === ReportTypes.childrenAttendanceInHours.type)
      return <DatePickerAndGroupTemplate legend={ReportTypes.childrenAttendanceInHours.name} {...props} />;
    if (reportType === ReportTypes.childrenAttendanceInHoursXls.type)
      return <DatePickerAndGroupTemplate legend={ReportTypes.childrenAttendanceInHoursXls.name} {...props} />;
    if (reportType === ReportTypes.childrenAttendanceInDays.type) return <ChildrenAttendanceInDaysReport {...props} />;
    if (reportType === ReportTypes.childrenAttendanceInDaysXls.type)
      return <ChildrenAttendanceInDaysReport {...props} />;
    if (reportType === ReportTypes.childrenAbsenceInDays.type) return <ChildrenAbsenceInDaysReport {...props} />;
    if (reportType === ReportTypes.childrenAbsenceInDaysXls.type) return <ChildrenAbsenceInDaysReport {...props} />;
    if (reportType === ReportTypes.childrenAttendanceInHoursDuesXls.type)
      return <ChildrenAttendanceInHoursDuesReport {...props} />;
    if (reportType === ReportTypes.childrenPlannedAttendances.type)
      return <ChildrenPlannedAttendancesReport {...props} />;
    if (reportType === ReportTypes.childrenAbsenceReportedAtDetails.type)
      return <ChildrenAbsenceReportedAtDetailsReport {...props} />;
    if (reportType === ReportTypes.childrenOpinionsSummary.type)
      return <GroupPickerTemplate legend={ReportTypes.childrenOpinionsSummary.name} {...props} />;
    if (reportType === ReportTypes.childrenSummaryPerGroup.type)
      return <GroupPickerTemplate legend={ReportTypes.childrenSummaryPerGroup.name} {...props} />;
    if (reportType === ReportTypes.childrenAttendanceJournal.type)
      return <DatePickerAndGroupTemplate legend={ReportTypes.childrenAttendanceJournal.name} {...props} />;
    if (reportType === ReportTypes.digitalDiaryAdditionalActivities.type)
      return <DatePickerAndGroupTemplate legend={ReportTypes.digitalDiaryAdditionalActivities.name} {...props} />;
    if (reportType === ReportTypes.psychologicalPedagogicalIndividualClassesTimetable.type)
      return (
        <DatePickerAndGroupTemplate
          legend={ReportTypes.psychologicalPedagogicalIndividualClassesTimetable.name}
          {...props}
        />
      );
    if (reportType === ReportTypes.digitalDiaryDaySchedule.type)
      return (
        <DayAndGroupPickerTemplate
          legend={ReportTypes.digitalDiaryDaySchedule.name}
          date={props.startDate}
          name="startDate"
          {...props}
        />
      );
    if (reportType === ReportTypes.digitalDiarySaveWork.type)
      return <DatePickerAndGroupTemplate {...props} legend={ReportTypes.digitalDiarySaveWork.name} />;
    if (reportType === ReportTypes.digitalDiaryWorkPlan.type)
      return <GroupAndMonthTemplate {...props} legend={ReportTypes.digitalDiaryWorkPlan.name} />;
    if (reportType === ReportTypes.digitalDiaryWorkWithParents.type)
      return <DatePickerAndGroupTemplate legend={ReportTypes.digitalDiaryWorkWithParents.name} {...props} />;
    if (reportType === ReportTypes.groupAttendanceDailySummary.type)
      return <GroupAttendanceDailySummaryReport {...props} />;
    if (reportType === ReportTypes.groupActivitiesAttendanceDailySummary.type)
      return <DatePickerAndGroupTemplate legend={ReportTypes.groupActivitiesAttendanceDailySummary.name} {...props} />;
    if (reportType === ReportTypes.reliefsByChildren.type) return <ReliefsByChildrenReport {...props} />;
    if (reportType === ReportTypes.reliefsSummary.type) return <ReliefsSummaryReport {...props} />;
    if (reportType === ReportTypes.childrenList.type) return <ChildrenListReport {...props} />;
    if (reportType === ReportTypes.canteenSummaryReport.type)
      return <SingleDateAndGroupPickerTemplate legend={ReportTypes.canteenSummaryReport.name} {...props} />;
    if (reportType === ReportTypes.canteenSummaryByMealsAtDayReport.type)
      return <SingleDateAndGroupPickerTemplate legend={ReportTypes.canteenSummaryByMealsAtDayReport.name} {...props} />;
    if (reportType === ReportTypes.summaryOfAttendanceAtMealsReport.type)
      return <SingleDateAndGroupPickerTemplate legend={ReportTypes.summaryOfAttendanceAtMealsReport.name} {...props} />;
    if (reportType === ReportTypes.childrenAttendanceInDaysByGroup.type)
      return (
        <ChildrenAttendanceInDaysByGroupReport
          legend={ReportTypes.childrenAttendanceInDaysByGroup.name}
          info={ReportTypes.childrenAttendanceInDaysByGroup.info}
          {...props}
        />
      );
    if (reportType === ReportTypes.childrenActivities.type) return <ChildrenActivitiesReport {...props} />;
    if (reportType === ReportTypes.paymentsSummaryPerChild.type) return <PaymentsSummaryPerChildReport {...props} />;
    if (reportType === ReportTypes.detailedPaymentsSummaryPerChild.type)
      return <DetailedPaymentsSummaryPerChildReport {...props} />;
    if (reportType === ReportTypes.simplifiedPaymentsSummaryPerChild.type)
      return <SimplifiedPaymentsSummaryPerChildReport {...props} />;
    if (reportType === ReportTypes.paymentsSummary.type)
      return <YearAndMonthTemplate {...props} legend={ReportTypes.paymentsSummary.name} />;
    if (reportType === ReportTypes.childrenAverageAttendanceInHourIntervals.type)
      return <ChildrenAverageAttendanceInHourIntervalsReport {...props} />;
    if (reportType === ReportTypes.stayDuesPayments.type || reportType === ReportTypes.cateringDuesPayments.type)
      return <DuesPaymentsReport {...props} />;
    if (reportType === ReportTypes.duesStayDetailsOverall.type) return <DuesStayDetailsOverallReport {...props} />;
    if (reportType === ReportTypes.duesActivitiesOverallReport.type) return <DuesActivitiesOverallReport {...props} />;
    if (reportType === ReportTypes.duesCateringDetailsOverall.type)
      return <DuesCateringDetailsOverallReport {...props} />;
    if (reportType === ReportTypes.duesOtherDetailsOverall.type) return <DuesOtherDetailsOverallReport {...props} />;
    if (reportType === ReportTypes.duesDetailsOverall.type) return <DuesDetailsOverallReport {...props} />;
    if (reportType === ReportTypes.duesDetailsXls.type) return <DuesDetailsOverallReportXLS {...props} />;
    if (reportType === ReportTypes.childrenAttributeSetValues.type)
      return <ChildrenAttributeSetValuesReport {...props} />;
    if (reportType === ReportTypes.childrenAttributeSetValuesSummary.type)
      return <ChildrenAttributeSetValuesSummaryReport {...props} />;
    if (reportType === ReportTypes.schoolYearInauguration.type) return <SchoolYearInaugurationReport {...props} />;
    if (reportType === ReportTypes.legalGuardianDebts.type)
      return (
        <SingleDatePickerTemplate
          legend={ReportTypes.legalGuardianDebts.name}
          date={props.maturityDate}
          name="maturityDate"
          {...props}
        />
      );
    if (reportType === ReportTypes.attributeSetAttendanceReport.type)
      return <AttributeSetAttendanceReport {...props} />;
    if (reportType === ReportTypes.boardPostsListReport.type)
      return <DatePickerAndGroupTemplate legend={ReportTypes.boardPostsListReport.name} {...props} />;
    if (reportType === ReportTypes.childrenOpinionReport.type) return <ChildrenOpinionReport />;
    if (reportType === ReportTypes.childrenAttendanceInHourIntervalsMonth.type)
      return (
        <ChildrenAttendanceInHourIntervalsMonthReport
          {...props}
          disableSaveButton={this.props.disableSaveButton}
          setReportPayload={this.setReportPayload}
        />
      );
    if (reportType === ReportTypes.listOfManDaysReport.type)
      return <GroupAndMonthTemplate {...props} legend={ReportTypes.listOfManDaysReport.name} />;
    if (reportType === ReportTypes.staySettlementsDetailsFromReaderReport.type)
      return <GroupAndMonthTemplate legend={ReportTypes.staySettlementsDetailsFromReaderReport.name} {...props} />;
    if (reportType === ReportTypes.cateringSettlementsDetailsFromReaderReport.type)
      return <GroupAndMonthTemplate {...props} />;
    if (reportType === ReportTypes.listOfManDaysReport.type)
      return <GroupAndMonthTemplate legend={ReportTypes.listOfManDaysReport.name} {...props} />;
    if (reportType === ReportTypes.listOfArrearsForMonthReport.type) return <ListOfArrearsForMonthReport {...props} />;
    if (reportType === ReportTypes.listOfTransfersReport.type)
      return <ListOfTransfersReport legend={ReportTypes.listOfTransfersReport.name} {...props} />;
    if (reportType === ReportTypes.statementOfInterestReport.type)
      return <GroupAndMonthTemplate legend={ReportTypes.statementOfInterestReport.name} {...props} />;
    if (reportType === ReportTypes.statementOfOverpaymentsReport.type)
      return <GroupAndMonthTemplate legend={ReportTypes.statementOfOverpaymentsReport.name} {...props} />;
    if (reportType === ReportTypes.listOfFeesPaidReport.type)
      return (
        <ListOfFeesPaidReport
          legend={ReportTypes.listOfFeesPaidReport.name}
          startDate={props.startDate}
          name="startDate"
          {...props}
        />
      );
    if (reportType === ReportTypes.calendarEventsListReport.type)
      return <DatePickerAndGroupTemplate legend={ReportTypes.calendarEventsListReport.name} {...props} />;
    if (reportType === ReportTypes.dailyAbsenceOfChildrenReport.type)
      return <GroupAndMonthTemplate legend={ReportTypes.dailyAbsenceOfChildrenReport.name} {...props} />;
    if (reportType === ReportTypes.reportedAbsencesByTheHourOfPreviousDayReport.type)
      return <ReportedAbsencesByTheHoursOfPreviousDayReport {...props} />;
    if (reportType === ReportTypes.statementOfExpectedInterestAmountForDayReport.type)
      return (
        <StatementOfExpectedInterestAmountForDayReport
          legend={ReportTypes.statementOfExpectedInterestAmountForDayReport.name}
          {...props}
        />
      );

    if (reportType === ReportTypes.statementOfAccrualsForMonthReport.type)
      return <GroupAndMonthTemplate legend={ReportTypes.statementOfAccrualsForMonthReport.name} {...props} />;
    if (reportType === ReportTypes.statementOfSettlementsForMonthReport.type)
      return <StatementOfSettlementsForMonthReport {...props} />;
    if (reportType === ReportTypes.settlementForTheMonth.type)
      return <StatementOfSettlementsForMonthReport {...props} />;
    if (reportType === ReportTypes.kindergartenActivityLog.type) return <KindergartenActivityLog {...props} />;

    if (reportType === ReportTypes.deductionAndSettlementOfPaymentForStayAndCatering.type)
      return (
        <GroupAndMonthTemplate legend={ReportTypes.deductionAndSettlementOfPaymentForStayAndCatering.name} {...props} />
      );

    if (reportType === ReportTypes.paymentAssignment.type) return <GroupAndMonthTemplate {...props} />;

    throw new `report type ${reportType} is not supported`();
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing, reportType } = this.props;
    return (
      <DialogForm
        buttonsHidden={reportType.type === ReportTypes.childrenDiagnosesReport.type}
        scrollHidden={reportType.type === ReportTypes.childrenDiagnosesReport.type}
        maxWidth={reportType.type === ReportTypes.childrenDiagnosesReport.type ? 'xs' : 'sm'}
        header={`Generowanie raportu: ${reportType.name}`}
        onSave={() => {
          if (
            this.props.auth.userRole === userRoles.staffMemberTeacher &&
            !this.props.unit.teacherAllGroupVisibility &&
            this.state.reportConfiguration.groupId === 'all'
          ) {
            this.setState({ errors: { groupId: 'Wybierz grupę.' } });
            return;
          } else if (!!this.state.errors.groupId) {
            this.setState({ errors: { groupId: undefined } });
          }

          onSave(this.state.reportConfiguration);
        }}
        onCancel={onCancel}
        saveLabel={reportType.formats[0] === 'xls' ? 'Wygeneruj raport XLSX' : 'Wygeneruj raport PDF '}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        saveDisabled={this.props.reportWithErrors}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="generateReportUi">
        <Box>
          {isProcessing && <LinearProgress sx={{ width: '100%', mb: 2 }} />}
          {(isDialogOpen &&
            this.renderReportConfiguration(reportType.type, {
              onChange: this.onUpdateReportConfiguration,
              errors: this.state.errors,
              groups: this.filterTeacherGroups(this.props.groups),
              attributeSets: this.props.attributeSets,
              reliefs: this.props.reliefs,
              activities: this.props.activities,
              ...this.state.reportConfiguration
            })) || <Box />}
        </Box>
      </DialogForm>
    );
  }
}

GenerateReportDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  reportType: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  attributeSets: PropTypes.array.isRequired,
  reliefs: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  reportWithErrors: PropTypes.bool,
  disableSaveButton: PropTypes.func
};

function mapStateToProps(state) {
  return {
    unit: state.configuration.unit,
    staffMembers: state.staffMembers,
    auth: state.auth
  };
}

export default connect(mapStateToProps)(GenerateReportDialog);
