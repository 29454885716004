import React from 'react';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import ChildrenContainer from './containers/ChildrenContainer';

const ChildrenPage = () => {
  return (
    <LoadingRenderWrapper>
      <ChildrenContainer />
    </LoadingRenderWrapper>
  );
};

export default ChildrenPage;
