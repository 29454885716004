import BaseModel from '../BaseModel';

class LinkedChild extends BaseModel {
  constructor() {
    super();
    this.id = null;
    this.groupId = null;
    this.firstName = '';
    this.lastName = '';
    this.photoUrl = '';
    this.groupName = '';
    this.pinOwnerId = null;
    this.hasPlannedAbsence = false;
    this.wasDroppedOff = false;
    this.wasPickedUp = false;
  }
}

export class PinEntriesModel extends BaseModel {
  constructor() {
    super();
    this.pin = '';
    this.firstName = '';
    this.lastName = '';
    this.photoUrl = null;
    this.guardianId = null;
    this.guardianType = null;
    this.documentNumber = null;
    this.linkedChildren = [];
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.linkedChildren = assigned.linkedChildren.map((e) => new LinkedChild().assign(e));
  }
}
