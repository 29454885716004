import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import Dialog from '../../../common/Dialog';

class DeleteConfirmationButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isOpen: false
    };
    this.handleAction = this.handleAction.bind(this);
    this.renderActionButtons = this.renderActionButtons.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleAction(event) {
    event.preventDefault();
    this.setState({ isOpen: true });
  }

  handleConfirm() {
    this.setState({ isOpen: false });
    this.props.onConfirm();
  }

  handleCancel() {
    this.setState({ isOpen: false });
    this.props.onCancel();
  }

  renderActionButtons() {
    return [
      <Button onClick={this.handleCancel} aria-label={this.props.cancelLabel} variant="outlinedContrast" key="cancel">
        {this.props.cancelLabel}
      </Button>,
      <Button variant="contained" onClick={this.handleConfirm} aria-label={this.props.confirmLabel} key="confirm">
        {this.props.confirmLabel}
      </Button>
    ];
  }

  render() {
    return (
      <Box>
        <Button
          disabled={this.props.isDisabled}
          variant="outlinedContrast"
          aria-label={this.props.actionLabel}
          onClick={this.handleAction}
          sx={{ float: 'right' }}>
          {this.props.actionLabel}
        </Button>
        <Dialog
          sx={{ zIndex: (theme) => theme.zIndex.modal }}
          title={this.props.confirmationTitle}
          actions={this.renderActionButtons()}
          modal={true}
          open={this.state.isOpen}>
          {this.props.confirmationText}
        </Dialog>
      </Box>
    );
  }
}

DeleteConfirmationButton.propTypes = {
  actionLabel: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmationText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  confirmationTitle: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isDisabled: PropTypes.bool,
  leftIcon: PropTypes.object,
  rightIcon: PropTypes.object,
  style: PropTypes.object
};

DeleteConfirmationButton.defaultProps = {
  confirmLabel: 'Ok',
  cancelLabel: 'Anuluj',
  isPrimary: false,
  isSecondary: false,
  isDisabled: false
};

export default DeleteConfirmationButton;
