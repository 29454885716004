import { Accordion, AccordionDetails, AccordionSummary, Button, Paper, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChildDetailsHeader from '../childDetails/header/ChildDetailsHeader';
import ContentWrapper from '../common/contentWrapper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import NotifyDialog from './dialog/NotifyDialog/NotifyDialog';
import WopfuTable from './wopfuTable';
import WopfuDialog from './dialog/WopfuDialog/WopfuDialog';
import IpetDialog from './dialog/IpetDialog/IpetDialog';
import IpetTable from './IpetTable';
import NotifyTable from './notifyTable';
import {
  archiveIpet,
  archiveNotification,
  archiveWopfu,
  newIpetCancel,
  newIpetStart,
  newNotificationCancel,
  newNotificationStart,
  newWopfuCancel,
  newWopfuStart
} from '../../actions/psychologicalActions';
import { loadChildAsync } from '../../actions/childrenActions';

const PsychologicalHelpChildPage = () => {
  const dispatch = useDispatch();
  const psychologicalData = useSelector((state) => state.psychologicalHelpDetails);
  const pupil = useSelector((state) => state.childDetails);
  const [selectedNotify, setSelectedNotify] = useState();
  const [selectedWOPFU, setWOPFU] = useState();
  const [selectedIPET, setIPET] = useState();
  const [dialogIsReadOnly, setDialogIsReadOnly] = useState(false);

  const isIpetDialogOpen = useSelector((state) => state.psychologicalHelpUi.ipet);
  const isWopfuDialogOpen = useSelector((state) => state.psychologicalHelpUi.wopfu);
  const isNotifyDialogOpen = useSelector((state) => state.psychologicalHelpUi.notify);

  const lettersOfNotification = useMemo(() => {
    if (!!psychologicalData && psychologicalData) return psychologicalData.lettersOfNotification;
    return [];
  }, [psychologicalData]);

  const renderNotifyDialog = () => {
    return (
      <NotifyDialog
        isReadonly={dialogIsReadOnly}
        handleCancel={() => {
          dispatch(newNotificationCancel());
        }}
        dialogTitle={
          dialogIsReadOnly
            ? 'Pismo zawiadamiające'
            : !!selectedNotify
            ? 'Edycja pisma zawiadamiającego'
            : 'Nowe pismo zawiadamiające'
        }
        child={pupil}
        id={psychologicalData && psychologicalData?.id}
        selectedNotify={selectedNotify}
      />
    );
  };
  const renderWopfuDialog = () => {
    return (
      <WopfuDialog
        isReadonly={dialogIsReadOnly}
        handleCancel={() => {
          dispatch(newWopfuCancel());
        }}
        title={dialogIsReadOnly ? 'Wpis WOPFU' : selectedWOPFU ? 'Edycja wpisu WOPFU' : 'Nowy wpis WOPFU'}
        child={pupil}
        id={psychologicalData && psychologicalData?.id}
        selected={selectedWOPFU}
      />
    );
  };
  const renderIpetDialog = () => {
    return (
      <IpetDialog
        isReadonly={dialogIsReadOnly}
        handleCancel={() => {
          dispatch(newIpetCancel());
        }}
        title={dialogIsReadOnly ? 'Wpis IPET' : selectedIPET ? 'Edycja wpisu IPET' : 'Nowy wpis IPET'}
        child={pupil}
        id={psychologicalData && psychologicalData?.id}
        selectedIPET={selectedIPET}
      />
    );
  };

  const handleNotifyEdit = (data, readonly) => {
    setDialogIsReadOnly(readonly);
    dispatch(newNotificationStart());
    setSelectedNotify(data);
  };

  const handleWopfuEdit = (x, isReadonly) => {
    setDialogIsReadOnly(isReadonly);
    dispatch(newWopfuStart());
    setWOPFU(x);
  };

  const handleIpetEdit = (data, isReadonly) => {
    dispatch(newIpetStart());
    setDialogIsReadOnly(isReadonly);
    setIPET(data);
  };

  const handleWopfuArchive = (id) => {
    dispatch(archiveWopfu(id, psychologicalData.id));
  };
  const handleIpetArchive = (id) => {
    dispatch(archiveIpet(id, psychologicalData.id));
  };
  const handleNotifyArchive = (id) => {
    dispatch(archiveNotification(psychologicalData.id, id));
  };

  return (
    <ContentWrapper>
      <ChildDetailsHeader
        photoChangeDisabled
        onPhotoDrop={() => {}}
        onPhotoRemove={() => {}}
        name={`${psychologicalData.childFirstName} ${psychologicalData.childLastName}`}
        group={pupil.enrolledToGroup}
        photoUrl={pupil.photoUrl}
      />

      <Paper sx={{ p: 1, my: 2 }}>
        <Accordion sx={{ boxShadow: 0 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                mr: 2
              }}>
              <Typography variant="h6">Pismo zawiadamiające</Typography>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedNotify();
                  dispatch(newNotificationStart());
                  setDialogIsReadOnly(false);
                }}>
                Dodaj
              </Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ overflowX: 'auto' }}>
            <NotifyTable
              childId={psychologicalData?.childId}
              id={psychologicalData?.id}
              handleArchive={handleNotifyArchive}
              rows={lettersOfNotification}
              psychologicalData={psychologicalData}
              handleEdit={(e, readonly = false) => handleNotifyEdit(e, readonly)}
            />
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper sx={{ p: 1, my: 2 }}>
        <Accordion sx={{ boxShadow: 0 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                mr: 2
              }}>
              <Typography variant="h6">
                Wielospecjalistyczna ocena poziomu funkcjonowania ucznia (RWD: WOPFU)
              </Typography>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  setWOPFU();
                  setDialogIsReadOnly(false);
                  dispatch(newWopfuStart());
                }}>
                Dodaj
              </Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ overflowX: 'auto' }}>
            <WopfuTable
              rows={!!psychologicalData && psychologicalData?.WOPFUs}
              psychologicalData={psychologicalData}
              handleEdit={(e, isReadonly) => handleWopfuEdit(e, isReadonly)}
              handleArchive={handleWopfuArchive}
            />
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper sx={{ p: 1, my: 2 }}>
        <Accordion sx={{ boxShadow: 0 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                mr: 2
              }}>
              <Typography variant="h6">Indywidualny program Edukacyjno-Terapeutyczny (RWD:IPET)</Typography>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  setIPET();
                  setDialogIsReadOnly(false);
                  dispatch(newIpetStart());
                }}>
                Dodaj
              </Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ overflowX: 'auto' }}>
            <IpetTable
              rows={!!psychologicalData && psychologicalData?.IPETs}
              psychologicalData={psychologicalData}
              handleEdit={(row, isReadonly) => {
                handleIpetEdit(row, isReadonly);
              }}
              handleArchive={handleIpetArchive}
            />
          </AccordionDetails>
        </Accordion>
      </Paper>

      {isNotifyDialogOpen.isEditing && renderNotifyDialog()}
      {isWopfuDialogOpen.isEditing && renderWopfuDialog()}
      {isIpetDialogOpen.isEditing && renderIpetDialog()}
    </ContentWrapper>
  );
};

export default PsychologicalHelpChildPage;
