import api from '../api/TestApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function getDataFromTestApiAsync() {
  return (dispatch) => {
    dispatch({ type: types.REQUEST_SENT });
    return api
      .getSomeData()
      .then((data) => dispatch({ type: types.TEST_GET_SUCCESS, data }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać danych z testowego api'));
        return logger.error(error);
      });
  };
}

export function sendDataToTestApiAsync() {
  return (dispatch) => {
    dispatch({ type: types.REQUEST_SENT });
    return api
      .postSomeData()
      .then((data) => dispatch({ type: types.TEST_GET_SUCCESS, data }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się wysłać danych do testowego api'));
        return logger.error(error);
      });
  };
}

export function modifyDataTestApiAsync() {
  return (dispatch) => {
    dispatch({ type: types.REQUEST_SENT });
    return api
      .modify()
      .then((data) => dispatch({ type: types.TEST_GET_SUCCESS, data }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się wysłać danych do testowego api'));
        return logger.error(error);
      });
  };
}
