import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { Box, Button, Grid, Typography } from '@mui/material';

const FormikContentWrapper = ({
  children,
  initialValues,
  validationSchema,
  validate,
  onSubmit,
  isButton = false,
  buttonLabel = '',
  isRequiredText,
  isLoading,
  ...rest
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validate={validate}
      onSubmit={onSubmit}
      {...rest}>
      {({ isValid }) => (
        <Form>
          {children(isValid)}
          {isButton && (
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              {isRequiredText && <Typography sx={{ display: 'inline-block' }}>* - Pola wymagane</Typography>}

              <Button variant="contained" aria-label={buttonLabel} type="submit" disabled={!isValid || isLoading}>
                {buttonLabel}
              </Button>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

FormikContentWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object,
  validate: PropTypes.func,
  children: PropTypes.func.isRequired,
  isButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  isRequiredText: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default FormikContentWrapper;
