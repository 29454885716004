import * as types from './actionTypes';
import api from '../api/ChildrenApi';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function importChildrenStart() {
  return { type: types.IMPORT_CHILDREN };
}

export function importChildrenCancel() {
  return { type: types.IMPORT_CHILDREN_CANCEL };
}

export function importChildrenClose() {
  return { type: types.IMPORT_CHILDREN_CANCEL };
}

export function importStudentAsync(file) {
  return (dispatch) => {
    dispatch({ type: types.IMPORT_CHILDREN_SUBMIT });
    return api
      .importStudent(file)
      .then(() => {
        dispatch(notificationActions.showSuccess('Pomyślnie zaimportowano uczniów'));
        dispatch({ type: types.IMPORT_CHILDREN_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: types.IMPORT_CHILDREN_FAILURE });
        dispatch(notificationActions.showError('Wystąpił błąd podaczas importowania uczniów'));
        return logger.error(error);
      });
  };
}

export function importChildrenAsync(file) {
  return (dispatch) => {
    dispatch({ type: types.IMPORT_CHILDREN_SUBMIT });
    return api
      .importChildren(file)
      .then((res) => {
        dispatch({ type: types.IMPORT_CHILDREN_SUMMARY_START, result: res });
        if (res.errorList.length) {
          dispatch(notificationActions.showError('Nie udało się zaimportować wszystkich dzieci'));
          dispatch({ type: types.IMPORT_CHILDREN_FAILURE });
        } else {
          dispatch(notificationActions.showSuccess('Pomyślnie zaimportowano dzieci'));
          dispatch({ type: types.IMPORT_CHILDREN_SUCCESS });
        }
      })
      .catch((error) => {
        dispatch({ type: types.IMPORT_CHILDREN_FAILURE });
        dispatch(notificationActions.showError('Wystąpił błąd podaczas importowania dzieci'));
        return logger.error(error);
      });
  };
}

export function importChildrenSummaryCancel() {
  return (dispatch) => {
    dispatch({ type: types.IMPORT_CHILDREN_SUMMARY_CANCEL });
  };
}
