import React, { useState } from 'react';
import { Grid, List, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import ManageAcitivityApplicationDialog from './ManageAcitivityApplicationDialog';
import ConfirmationDialog from '../forms/ConfirmationDialog';
import { AdditionalActivitiesApplicationsHeader } from '../common/applications/ApplicationHeaders';
import { ApplicationItemsWrapper } from '../common/applications/ApplicationWrappers';
import GuardianApplicationItem from './GuardianApplicationItem';

const ActivitiesApplications = (props) => {
  const [isOpenManageActivityApplicationDialog, setIsOpenManageActivityApplicationDialog] = useState(false);
  const [isOpenArchiveApplicationConfirmDialog, setIsOpenArchiveApplicationConfirmDialog] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const { selectedChild, applicationsActions, refreshChildApplications, activities, childApplications } = props;

  const onAdd = () => {
    setSelectedApplication(null);
    setIsOpenManageActivityApplicationDialog(true);
  };

  const onEdit = (app) => {
    setSelectedApplication(app);
    setIsOpenManageActivityApplicationDialog(true);
  };

  const onArchiveApplication = (app) => {
    setSelectedApplication(app);
    setIsOpenArchiveApplicationConfirmDialog(true);
  };

  const archiveApplication = () => {
    applicationsActions.archiveApplication(selectedApplication.id).then(() => {
      setIsOpenArchiveApplicationConfirmDialog(false);
      refreshChildApplications();
    });
  };

  const saveActivityApplication = (activityId) => {
    const req = {
      activityId,
      childId: selectedChild.id
    };

    if (selectedApplication === null) {
      applicationsActions.createApplication(req).then(() => {
        setIsOpenManageActivityApplicationDialog(false);
        refreshChildApplications();
      });
    } else {
      applicationsActions.updateApplication(selectedApplication.id, req).then(() => {
        setIsOpenManageActivityApplicationDialog(false);
        refreshChildApplications();
      });
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <AdditionalActivitiesApplicationsHeader onAdd={onAdd} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <List>
            <ApplicationItemsWrapper items={childApplications}>
              {childApplications.map((application) => {
                return (
                  <GuardianApplicationItem
                    key={application.id}
                    edit={(app) => onEdit(app)}
                    archive={(app) => onArchiveApplication(app)}
                    application={application}
                  />
                );
              })}
            </ApplicationItemsWrapper>
          </List>
        </Paper>
      </Grid>
      <ManageAcitivityApplicationDialog
        open={isOpenManageActivityApplicationDialog}
        close={() => setIsOpenManageActivityApplicationDialog(false)}
        activities={activities}
        childApplications={childApplications}
        selectedApplication={selectedApplication}
        save={saveActivityApplication}
      />
      <ConfirmationDialog
        confirmationText="Czy na pewno chcesz usunąć wniosek?"
        confirmationTitle="Usunięcie wniosku"
        confirmLabel="tak"
        onConfirm={archiveApplication}
        onCancel={() => setIsOpenArchiveApplicationConfirmDialog(false)}
        isOpen={isOpenArchiveApplicationConfirmDialog}
      />
    </>
  );
};

ActivitiesApplications.propTypes = {
  childApplications: PropTypes.array.isRequired,
  selectedChild: PropTypes.object.isRequired,
  applicationsActions: PropTypes.object.isRequired,
  refreshChildApplications: PropTypes.func.isRequired,
  activities: PropTypes.array.isRequired
};

export default ActivitiesApplications;
