import BaseModel from '../BaseModel';

export class AbsenceTomorrow extends BaseModel {
  constructor() {
    super();
    this.childId = null;
    this.groupId = null;
    this.firstName = '';
    this.lastName = '';
    this.photoUrl = null;
    this.date = null;
    this.absence_reported_at = null;
  }
}
