import ApiBase, { ROOT_URL } from './ApiBase';
// import moment from 'moment';

class UnitApi extends ApiBase {
  static getUnitData() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/configuration/unit`).type('application/json'));
  }

  static updateUnitAddress(address) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/configuration/unit/address`).send(address).type('application/json')
    );
  }

  static updatePenaltiesSettings(penaltiesSettings) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/configuration/unit/penalties`).send(penaltiesSettings).type('application/json')
    );
  }

  static updateTwoWayMessagingSettings(enabled) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/configuration/unit/twoWayMessaging`).send({ enabled }).type('application/json')
    );
  }

  static updateReportAbsenceInDaysOff(enabled) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/configuration/unit/absenceReportDaysOff`).send({ enabled }).type('application/json')
    );
  }

  static updateNoHoursAttendance(enabled) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/attendancesType`)
        .send({ noHoursAttendancesEnabled: enabled })
        .type('application/json')
    );
  }

  static updateUnitWorkingHours(workingHours) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/configuration/unit/workingHours`).send(workingHours).type('application/json')
    );
  }

  static updateUnitAbsenceRemovalDeadline(hours) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/configuration/unit/absenceRemovalDeadline`).send({ hours }).type('application/json')
    );
  }

  static getEntrances() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/files/getList/cardReaderData`).type('application/json'));
  }

  static importEntrance(fileAttachment) {
    const data = new FormData();
    data.append('file', fileAttachment, fileAttachment.name);
    data.append('name', fileAttachment.name);
    data.append('dataType', 'cardReaderData');

    return super.toPromise((agent) => agent.post(`${ROOT_URL}/files/upload`).send(data));
  }

  static updatePostsModifyingEnabled(enabled) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/postsModifying`)
        .send({ postsModifyingEnabled: enabled })
        .type('application/json')
    );
  }

  static updateIndividualBankAccounts(enabled) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/individualBankAccounts`)
        .send({ individualBankAccounts: enabled })
        .type('application/json')
    );
  }

  static updateAddContractNumberToSettlementNumber(enabled) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/addContractNumberToSettlementNumber`)
        .send({ addContractNumberToSettlementNumber: enabled })
        .type('application/json')
    );
  }

  static updateChildrenAgeInfo(enabled) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/childrenAgeInfoEnabled`)
        .send({ childrenAgeInfoEnabled: enabled })
        .type('application/json')
    );
  }

  static updateSendingChildPickedUpNotificationBlocked(enabled) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/sendingChildPickedUpNotificationBlocked`)
        .send({ sendingChildPickedUpNotificationBlocked: enabled })
        .type('application/json')
    );
  }

  static updateEditingChildDataByTeacherBlocked(enabled) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/editingChildDataByTeacherBlocked`)
        .send({ editingChildDataByTeacherBlocked: enabled })
        .type('application/json')
    );
  }

  static updateMealsCateringDues(enabled) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/mealsCateringDues`)
        .send({ mealsCateringDues: enabled })
        .type('application/json')
    );
  }

  static updateAllowDebtCollection(enabled) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/allowDebtCollection`)
        .send({ allowDebtCollection: enabled })
        .type('application/json')
    );
  }

  static updateAllowAdaptation(enabled) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/allowAdaptation`)
        .send({ allowAdaptation: enabled })
        .type('application/json')
    );
  }

  static updateTeacherGroupAccess(enabled) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/teacherGroupsVisibility`)
        .send({ teacherAllGroupVisibility: enabled })
        .type('application/json')
    );
  }

  static updateTeacherPostsAccess(enabled) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/teacherBoardPostVisibility`)
        .send({ teacherAllBoardPostsVisibility: enabled })
        .type('application/json')
    );
  }

  static updateLegalGuardianPostsAccess(enabled) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/legalGuardianBoardPostVisibility`)
        .send({ legalGuardianPartOfBoardPostsVisibility: enabled })
        .type('application/json')
    );
  }

  static sendingOverduesNotification(enabled) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/configuration/unit/sendingOverduesNotification`)
        .send({ sendingOverduesNotification: enabled })
        .type('application/json')
    );
  }

  // static updateNotificationSendingEnabled(enabled) {
  //   return super.toPromise((agent) =>
  //     agent.put(`${ROOT_URL}/configuration/unit/Test`).send({ notificationSendingEnabled: enabled }).type('application/json')
  //   );
  // }
}

export default UnitApi;
