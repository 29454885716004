import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Checkbox from '../../../common/Checkbox';
import TextField from '../../../common/TextField';
import SelectField from '../../../common/SelectField';
import { Box, MenuItem, Stack } from '@mui/material';
import { tryConvertToNumber } from '../../../../utils/numberConverters';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import AdvancedInterests from '../components/AdvancedInterests';

const SettlementsSettingsForm = ({
  settlementsSettings,
  numberingSchemes,
  onChange,
  errors,
  onIntegrationInputChange,
  onIntegrationAccountsInputChange
}) => {
  const showAdvancedInterestEditor = useMemo(() => {
    return (
      settlementsSettings.useAdvancedInterestSystemStay ||
      settlementsSettings.useAdvancedInterestSystemCatering ||
      settlementsSettings.useAdvancedInterestSystemOther
    );
  }, [
    settlementsSettings.useAdvancedInterestSystemStay,
    settlementsSettings.useAdvancedInterestSystemCatering,
    settlementsSettings.useAdvancedInterestSystemOther
  ]);

  return (
    <Box sx={{ overflow: 'auto', p: '0 10px' }}>
      <FieldsetWrapper legend="Ustawienia rozliczeń">
        <Stack>
          <TextField
            hintText="Termin płatności na dzień każdego miesiąca"
            floatingLabelText="Termin płatności na dzień każdego miesiąca"
            floatingLabelFixed={false}
            name="paymentDueDay"
            value={settlementsSettings.paymentDueDay}
            onChange={onChange}
            type='number'
            onBlur={(e) => {
              onChange({ target: { name: 'paymentDueDay', value: tryConvertToNumber(e.target.value) } });
            }}
            errorText={errors.paymentDueDay}
          />
          <Checkbox
            name="symphonyIntegrationEnabled"
            label="Włącz integrację z systemem Symfonia"
            defaultChecked={settlementsSettings.symphonyIntegrationEnabled}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'symphonyIntegrationEnabled', value: isChecked } })
            }
          />
          <Checkbox
            name="integrationEnabled"
            label="Włącz integrację FK"
            defaultChecked={settlementsSettings.integrationEnabled}
            onCheck={(event, isChecked) => onChange({ target: { name: 'integrationEnabled', value: isChecked } })}
          />
          {settlementsSettings.integrationEnabled && (
            <Box>
              <TextField
                floatingLabelText="ID przedszkola w systemie FK"
                floatingLabelFixed={false}
                fullWidth
                name="integrationID"
                value={settlementsSettings.integration.integrationID}
                onChange={onIntegrationInputChange}
                onBlur={(e) => {
                  onIntegrationInputChange({ target: { name: 'integrationID', value: e.target.value } });
                }}
              />
              <TextField
                floatingLabelText="URL systemu FK"
                floatingLabelFixed={false}
                fullWidth
                errorText="Zmiana może doprowadzić do awarii usługi. Modyfikuj tylko jeśli wiesz co robisz."
                name="integrationURL"
                value={settlementsSettings.integration.integrationURL}
                onChange={onIntegrationInputChange}
                onBlur={(e) => {
                  onIntegrationInputChange({ target: { name: 'integrationURL', value: e.target.value } });
                }}
              />

              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Wyżywienie - Konto Ma"
                floatingLabelFixed={false}
                name="cateringHas"
                value={settlementsSettings.integration.accounts.cateringHas}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'cateringHas', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Wyżywienie - Konto Winien"
                floatingLabelFixed={false}
                name="cateringOwed"
                value={settlementsSettings.integration.accounts.cateringOwed}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'cateringOwed', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Wyżywienie odsetki - Konto Ma"
                floatingLabelFixed={false}
                name="cateringPenaltyHas"
                value={settlementsSettings.integration.accounts.cateringPenaltyHas}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'cateringPenaltyHas', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Wyżywienie odsetki - Konto Winien"
                floatingLabelFixed={false}
                name="cateringPenaltyOwed"
                value={settlementsSettings.integration.accounts.cateringPenaltyOwed}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'cateringPenaltyOwed', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Wyżywienie wpłata razem - Konto Ma"
                floatingLabelFixed={false}
                name="cateringPaidHas"
                value={settlementsSettings.integration.accounts.cateringPaidHas}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'cateringPaidHas', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Wyżywienie wpłata razem - Konto Winien"
                floatingLabelFixed={false}
                name="cateringPaidOwed"
                value={settlementsSettings.integration.accounts.cateringPaidOwed}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'cateringPaidOwed', value: e.target.value } });
                }}
              />

              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Pobyt - Konto Ma"
                floatingLabelFixed={false}
                name="stayHas"
                value={settlementsSettings.integration.accounts.stayHas}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'stayHas', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Pobyt - Konto Winien"
                floatingLabelFixed={false}
                name="stayOwed"
                value={settlementsSettings.integration.accounts.stayOwed}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'stayOwed', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Pobyt odsetki - Konto Ma"
                floatingLabelFixed={false}
                name="stayPenaltyHas"
                value={settlementsSettings.integration.accounts.stayPenaltyHas}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'stayPenaltyHas', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Pobyt odsetki - Konto Winien"
                floatingLabelFixed={false}
                name="stayPenaltyOwed"
                value={settlementsSettings.integration.accounts.stayPenaltyOwed}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'stayPenaltyOwed', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Pobyt wpłata razem - Konto Ma"
                floatingLabelFixed={false}
                name="stayPaidHas"
                value={settlementsSettings.integration.accounts.stayPaidHas}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'stayPaidHas', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Pobyt wpłata razem - Konto Winien"
                floatingLabelFixed={false}
                name="stayPaidOwed"
                value={settlementsSettings.integration.accounts.stayPaidOwed}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'stayPaidOwed', value: e.target.value } });
                }}
              />

              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Pozostałe - Konto Ma"
                floatingLabelFixed={false}
                name="otherHas"
                value={settlementsSettings.integration.accounts.otherHas}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'otherHas', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Pozostałe - Konto Winien"
                floatingLabelFixed={false}
                name="otherOwed"
                value={settlementsSettings.integration.accounts.otherOwed}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'otherOwed', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Dodatkowe odsetki - Konto Ma"
                floatingLabelFixed={false}
                name="otherPenaltyHas"
                value={settlementsSettings.integration.accounts.otherPenaltyHas}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'otherPenaltyHas', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Dodatkowe odsetki - Konto Winien"
                floatingLabelFixed={false}
                name="otherPenaltyOwed"
                value={settlementsSettings.integration.accounts.otherPenaltyOwed}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'otherPenaltyOwed', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Dodatkowe wpłata razem - Konto Ma"
                floatingLabelFixed={false}
                name="otherPaidHas"
                value={settlementsSettings.integration.accounts.otherPaidHas}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'otherPaidHas', value: e.target.value } });
                }}
              />
              <TextField
                fullWidth
                hintText="Numer konta"
                floatingLabelText="Dodatkowe wpłata razem - Konto Winien"
                floatingLabelFixed={false}
                name="otherPaidOwed"
                value={settlementsSettings.integration.accounts.otherPaidOwed}
                onChange={onIntegrationAccountsInputChange}
                onBlur={(e) => {
                  onIntegrationAccountsInputChange({ target: { name: 'otherPaidOwed', value: e.target.value } });
                }}
              />
            </Box>
          )}
          <Checkbox
            name="useAdditionalWallets"
            label="Korzystanie z portfeli dodatkowych"
            defaultChecked={settlementsSettings.useAdditionalWallets}
            onCheck={(event, isChecked) => onChange({ target: { name: 'useAdditionalWallets', value: isChecked } })}
          />
          <Checkbox
            name="staySixYearOldExemption"
            label="Zwolnienie sześciolatków z opłat za pobyt"
            defaultChecked={settlementsSettings.staySixYearOldExemption}
            onCheck={(event, isChecked) => onChange({ target: { name: 'staySixYearOldExemption', value: isChecked } })}
          />
          <Checkbox
            name="mergeBMProductsWithSameAccountNumber"
            label="Grupowanie płatności online według rodzaju rozliczenia"
            defaultChecked={settlementsSettings.mergeBMProductsWithSameAccountNumber}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'mergeBMProductsWithSameAccountNumber', value: isChecked } })
            }
          />
          <Checkbox
            name="separateBasicDuesFromInterestDues"
            label="Rozdzielaj opłaty podstawowe od odsetkowych"
            defaultChecked={settlementsSettings.separateBasicDuesFromInterestDues}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'separateBasicDuesFromInterestDues', value: isChecked } })
            }
          />
          <Checkbox
            name="taxInterestCalculationSystem"
            label="Stosuj system naliczania odsetek podatkowych"
            defaultChecked={settlementsSettings.taxInterestCalculationSystem}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'taxInterestCalculationSystem', value: isChecked } })
            }
          />
          {settlementsSettings.taxInterestCalculationSystem && (
            <TextField
              hintText="Minimalna kwota odsetek podatkowych"
              floatingLabelText="Minimalna kwota odsetek podatkowych"
              floatingLabelFixed={false}
              name="taxInterestMinAmount"
              value={settlementsSettings.taxInterestMinAmount}
              onChange={onChange}
              onBlur={(e) => {
                onChange({ target: { name: 'taxInterestMinAmount', value: tryConvertToNumber(e.target.value) } });
              }}
              errorText={errors.taxInterestMinAmount}
            />
          )}
          <Checkbox
            name="stayDelayInterestCharging"
            label="Naliczaj odsetki przy opłatach za pobyt"
            defaultChecked={settlementsSettings.stayDelayInterestCharging}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'stayDelayInterestCharging', value: isChecked } })
            }
          />
          {settlementsSettings.stayDelayInterestCharging && (
            <Box style={{ marginLeft: '16px', display: 'flex', flexDirection: 'column' }}>
              <Checkbox
                name="useAdvancedInterestSystemStay"
                label="Zastosuj zaawansowane naliczanie odsetek za pobyt"
                defaultChecked={settlementsSettings.useAdvancedInterestSystemStay}
                onCheck={(event, isChecked) =>
                  onChange({ target: { name: 'useAdvancedInterestSystemStay', value: isChecked } })
                }
              />
              <TextField
                hintText="Stopa odsetek w procentach"
                floatingLabelText="Stopa odsetek"
                floatingLabelFixed={false}
                name="stayDelayInterestRate"
                value={settlementsSettings.stayDelayInterestRate}
                onChange={onChange}
                onBlur={(e) => {
                  onChange({ target: { name: 'stayDelayInterestRate', value: tryConvertToNumber(e.target.value) } });
                }}
                errorText={errors.stayDelayInterestRate}
              />
            </Box>
          )}
          <Checkbox
            name="cateringDelayInterestCharging"
            label="Naliczaj odsetki przy opłatach za wyżywiewnie"
            defaultChecked={settlementsSettings.cateringDelayInterestCharging}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'cateringDelayInterestCharging', value: isChecked } })
            }
          />
          {settlementsSettings.cateringDelayInterestCharging && (
            <Box style={{ marginLeft: '16px', display: 'flex', flexDirection: 'column' }}>
              <Checkbox
                name="useAdvancedInterestSystemCatering"
                label="Zastosuj zaawansowane naliczanie odsetek za wyżywienie"
                defaultChecked={settlementsSettings.useAdvancedInterestSystemCatering}
                onCheck={(event, isChecked) =>
                  onChange({ target: { name: 'useAdvancedInterestSystemCatering', value: isChecked } })
                }
              />
              <TextField
                hintText="Stopa odsetek w procentach"
                floatingLabelText="Stopa odsetek"
                floatingLabelFixed={false}
                name="cateringDelayInterestRate"
                value={settlementsSettings.cateringDelayInterestRate}
                onChange={onChange}
                onBlur={(e) => {
                  onChange({
                    target: { name: 'cateringDelayInterestRate', value: tryConvertToNumber(e.target.value) }
                  });
                }}
                errorText={errors.cateringDelayInterestRate}
              />
            </Box>
          )}
          <Checkbox
            name="otherDelayInterestCharging"
            label="Naliczaj odsetki przy innych opłatach"
            defaultChecked={settlementsSettings.otherDelayInterestCharging}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'otherDelayInterestCharging', value: isChecked } })
            }
          />
          {settlementsSettings.otherDelayInterestCharging && (
            <Box style={{ marginLeft: '16px', display: 'flex', flexDirection: 'column' }}>
              <Checkbox
                name="useAdvancedInterestSystemOther"
                label="Zastosuj zaawansowane naliczanie odsetek za inne opłaty"
                defaultChecked={settlementsSettings.useAdvancedInterestSystemOther}
                onCheck={(event, isChecked) =>
                  onChange({ target: { name: 'useAdvancedInterestSystemOther', value: isChecked } })
                }
              />
              <TextField
                hintText="Stopa odsetek w procentach"
                floatingLabelText="Stopa odsetek"
                floatingLabelFixed={false}
                name="otherDelayInterestRate"
                value={settlementsSettings.otherDelayInterestRate}
                onChange={onChange}
                onBlur={(e) => {
                  onChange({ target: { name: 'otherDelayInterestRate', value: tryConvertToNumber(e.target.value) } });
                }}
                errorText={errors.otherDelayInterestRate}
              />
            </Box>
          )}
          <Checkbox
            name="staffCateringDelayInterestCharging"
            label="Naliczaj odsetki przy opłatach za wyżywienie pracowników"
            defaultChecked={settlementsSettings.staffCateringDelayInterestCharging}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'staffCateringDelayInterestCharging', value: isChecked } })
            }
          />
          {settlementsSettings.staffCateringDelayInterestCharging && (
            <TextField
              hintText="Stopa odsetek w procentach"
              floatingLabelText="Stopa odsetek"
              floatingLabelFixed={false}
              name="staffCateringDelayInterestRate"
              value={settlementsSettings.staffCateringDelayInterestRate}
              onChange={onChange}
              onBlur={(e) => {
                onChange({
                  target: { name: 'staffCateringDelayInterestRate', value: tryConvertToNumber(e.target.value) }
                });
              }}
              errorText={errors.staffCateringDelayInterestRate}
            />
          )}
          {showAdvancedInterestEditor ? (
            <AdvancedInterests
              data={settlementsSettings.advancedInterests}
              onChange={(data) => {
                onChange({
                  target: { name: 'advancedInterests', value: data }
                });
              }}
            />
          ) : null}
        </Stack>
        <Stack>
          <Checkbox
            name="interestToNearestTenRounding"
            label="Zaokrąglaj naliczoną kwotę odsetek do pełnych dziesiątek groszy"
            defaultChecked={settlementsSettings.interestToNearestTenRounding}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'interestToNearestTenRounding', value: isChecked } })
            }
          />
          <Checkbox
            name="interestToNearestFullRounding"
            label="Zaokrąglaj naliczoną kwotę odsetek do pełnych złotówek"
            defaultChecked={settlementsSettings.interestToNearestFullRounding}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'interestToNearestFullRounding', value: isChecked } })
            }
          />
          <Checkbox
            name="stayDuesRounding"
            label="Zaokrąglaj opłaty za pobyt do pełnych złotówek"
            defaultChecked={settlementsSettings.stayDuesRounding}
            onCheck={(event, isChecked) => onChange({ target: { name: 'stayDuesRounding', value: isChecked } })}
          />
          <Checkbox
            name="cateringDuesRounding"
            label="Zaokrąglaj opłaty za wyżywienie do pełnych złotówek"
            defaultChecked={settlementsSettings.cateringDuesRounding}
            onCheck={(event, isChecked) => onChange({ target: { name: 'cateringDuesRounding', value: isChecked } })}
          />
          <Checkbox
            name="otherDuesRounding"
            label="Zaokrąglaj inne opłaty do pełnych złotówek"
            defaultChecked={settlementsSettings.otherDuesRounding}
            onCheck={(event, isChecked) => onChange({ target: { name: 'otherDuesRounding', value: isChecked } })}
          />
          <Checkbox
            name="staffCateringDuesRounding"
            label="Zaokrąglaj opłaty za wyżywienie pracowników do pełnych złotówek"
            defaultChecked={settlementsSettings.staffCateringDuesRounding}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'staffCateringDuesRounding', value: isChecked } })
            }
          />
        </Stack>
        <Stack>
          <Checkbox
            name="stayCreditDueOverpaymentsToBalance"
            label="Księguj nadpłaty z opłat za pobyt na saldo dziecka"
            defaultChecked={settlementsSettings.stayCreditDueOverpaymentsToBalance}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'stayCreditDueOverpaymentsToBalance', value: isChecked } })
            }
          />
          <Checkbox
            name="cateringCreditDueOverpaymentsToBalance"
            label="Księguj nadpłaty z opłat za wyżywienie na saldo dziecka"
            defaultChecked={settlementsSettings.cateringCreditDueOverpaymentsToBalance}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'cateringCreditDueOverpaymentsToBalance', value: isChecked } })
            }
          />
          <Checkbox
            name="otherCreditDueOverpaymentsToBalance"
            label="Księguj nadpłaty z innych opłat na saldo dziecka"
            defaultChecked={settlementsSettings.otherCreditDueOverpaymentsToBalance}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'otherCreditDueOverpaymentsToBalance', value: isChecked } })
            }
          />
        </Stack>
        <Box>
          <SelectField
            contrast
            sx={{
              my: 2
            }}
            floatingLabelText="Numeracja dla opłat za pobyt"
            floatingLabelFixed={false}
            id="stayNumberingSchemeId"
            value={settlementsSettings.stayNumberingSchemeId}
            onChange={(e) => {
              onChange({ target: { name: 'stayNumberingSchemeId', value: e.target.value } });
            }}
            errorText={errors.stayNumberingSchemeId}
            fullWidth={true}>
            {numberingSchemes.map((numberingScheme) => {
              return (
                <MenuItem key={numberingScheme.id} value={numberingScheme.id}>
                  {numberingScheme.name}
                </MenuItem>
              );
            })}
          </SelectField>
          <SelectField
            contrast
            sx={{
              my: 2
            }}
            floatingLabelText="Numeracja dla opłat za wyżywienie"
            floatingLabelFixed={false}
            id="cateringNumberingSchemeId"
            value={settlementsSettings.cateringNumberingSchemeId}
            onChange={(e) => {
              onChange({ target: { name: 'cateringNumberingSchemeId', value: e.target.value } });
            }}
            errorText={errors.cateringNumberingSchemeId}
            fullWidth={true}>
            {numberingSchemes.map((numberingScheme) => {
              return (
                <MenuItem key={numberingScheme.id} value={numberingScheme.id}>
                  {numberingScheme.name}
                </MenuItem>
              );
            })}
          </SelectField>
          <SelectField
            contrast
            sx={{
              my: 2
            }}
            floatingLabelText="Numeracja dla opłat za inne opłaty"
            floatingLabelFixed={false}
            id="otherNumberingSchemeId"
            value={settlementsSettings.otherNumberingSchemeId}
            onChange={(e) => {
              onChange({ target: { name: 'otherNumberingSchemeId', value: e.target.value } });
            }}
            errorText={errors.otherNumberingSchemeId}
            fullWidth={true}>
            {numberingSchemes.map((numberingScheme) => {
              return (
                <MenuItem key={numberingScheme.id} value={numberingScheme.id}>
                  {numberingScheme.name}
                </MenuItem>
              );
            })}
          </SelectField>
          <SelectField
            contrast
            sx={{
              my: 2
            }}
            floatingLabelText="Numeracja dla opłat za wyżywienie pracowników"
            floatingLabelFixed={false}
            id="staffCateringNumberingSchemeId"
            value={settlementsSettings.staffCateringNumberingSchemeId}
            onChange={(e) => {
              onChange({ target: { name: 'staffCateringNumberingSchemeId', value: e.target.value } });
            }}
            errorText={errors.staffCateringNumberingSchemeId}
            fullWidth={true}>
            {numberingSchemes.map((numberingScheme) => {
              return (
                <MenuItem key={numberingScheme.id} value={numberingScheme.id}>
                  {numberingScheme.name}
                </MenuItem>
              );
            })}
          </SelectField>
          <SelectField
            contrast
            sx={{
              my: 2
            }}
            floatingLabelText="Numeracja dla opłat za zajęcia dodatkowe"
            floatingLabelFixed={false}
            id="activitiesNumberingSchemeId"
            value={settlementsSettings.activitiesNumberingSchemeId}
            onChange={(e) => {
              onChange({ target: { name: 'activitiesNumberingSchemeId', value: e.target.value } });
            }}
            errorText={errors.activitiesNumberingSchemeId}
            fullWidth={true}>
            {numberingSchemes.map((numberingScheme) => {
              return (
                <MenuItem key={numberingScheme.id} value={numberingScheme.id}>
                  {numberingScheme.name}
                </MenuItem>
              );
            })}
          </SelectField>
          <SelectField
            contrast
            sx={{
              my: 2
            }}
            floatingLabelText="Numeracja dla opłat za posiłki"
            floatingLabelFixed={false}
            id="mealsNumberingSchemeId"
            value={settlementsSettings.mealsNumberingSchemeId}
            onChange={(e) => {
              onChange({ target: { name: 'mealsNumberingSchemeId', value: e.target.value } });
            }}
            errorText={errors.mealsNumberingSchemeId}
            fullWidth={true}>
            {numberingSchemes.map((numberingScheme) => {
              return (
                <MenuItem key={numberingScheme.id} value={numberingScheme.id}>
                  {numberingScheme.name}
                </MenuItem>
              );
            })}
          </SelectField>
        </Box>
      </FieldsetWrapper>
    </Box>
  );
};

SettlementsSettingsForm.propTypes = {
  settlementsSettings: PropTypes.object.isRequired,
  numberingSchemes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onIntegrationInputChange: PropTypes.func.isRequired,
  onIntegrationAccountsInputChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default SettlementsSettingsForm;
