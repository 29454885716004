import React from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import IndividualProgram from './IndividualProgram';
import ClassesCalendar from './ClassesCalendar';
import { useDispatch, useSelector } from 'react-redux';
import {
  addIndividualClasses,
  loadIndividualClassesWorkPlan,
  setIndividualClassesDialogClosed,
  setIndividualClassesDialogOpen
} from '../../actions/psychologicalIndividualClassesActions';
import { routePaths } from '../../routePaths';
import moment from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';
import ChildDetailsHeader from '../childDetails/header/ChildDetailsHeader';
import IndividualNotesList from './notes/IndividualNotesList';
import ObservationsList from './observations/ObservationsList';
import { Box } from '@mui/material';
import { useIsSpecialist } from '../../hooks/useIsSpecialist';

const IndividualClassesDetailsContainer = (params) => {
  const dispatch = useDispatch();
  const lessons = useSelector((state) => state.psychologicalIndividualWorkProgram.posts);
  const schemesArray = useSelector((state) => state.psychologicalSchemes);
  const ui = useSelector((state) => state.psychologicalIndividualProgramUi.individualClasses);
  const childData = useSelector((state) => state.childDetails);
  const [date, setDate] = useState(moment());
  const { isSpecialistOrPrincipal } = useIsSpecialist();

  const handleDateChange = (value) => {
    setDate(value);
  };

  useEffect(() => {
    dispatch(loadIndividualClassesWorkPlan(moment(date).format('YYYY-MM'), params.childId));
  }, [date, dispatch, params.childId]);

  const handleOpen = () => {
    dispatch(setIndividualClassesDialogOpen());
  };
  const handleCancel = () => {
    dispatch(setIndividualClassesDialogClosed());
  };
  const handleSave = (event) => {
    let data = event;
    data.group = params.childId;
    data.eventType = 'psychologicalPedagogicalIndividualClassesTimetable';
    dispatch(addIndividualClasses(data));
  };

  return (
    <>
      <PageHeaderText title="Rozkład zajęć" titleIcon={<CalendarMonthIcon />} />
      <ContentWrapper>
        <ChildDetailsHeader
          photoChangeDisabled
          onPhotoDrop={() => {}}
          onPhotoRemove={() => {}}
          name={`${childData.firstName} ${childData.lastName}`}
          group={childData.enrolledToGroup}
          photoUrl={childData.photoUrl}
        />
        <ClassesCalendar
          handleDateChange={handleDateChange}
          helpId={params.childId}
          lessons={lessons}
          onSave={(e) => handleSave(e)}
          routePath={routePaths.individualClassesEventsDetails}
          schemesArray={schemesArray.individualSchedules}
          ui={ui}
          onOpen={handleOpen}
          onCancel={handleCancel}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <IndividualProgram />
          {isSpecialistOrPrincipal && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <IndividualNotesList />
              <ObservationsList />
            </Box>
          )}
        </Box>
      </ContentWrapper>
    </>
  );
};

export default IndividualClassesDetailsContainer;
