import CalendarDayContent from '../../calendar/Grid/CalendarDayContent';
import PropTypes from 'prop-types';
import { Restaurant, NoMeals, DinnerDining } from '@mui/icons-material';
import { useTheme } from '@mui/material';

const MealContent = ({ day, containerWidth }) => {
  const theme = useTheme();

  const renderMealIcon = (day) => {
    const isMealArrayEmpty = day.data.meals.length === 0;
    const { isModified } = day.data;
    const { isDayOff } = day.data;

    if (isDayOff === true) {
      return null;
    } else if (isMealArrayEmpty === false && isModified === true) {
      return <Restaurant sx={{ color: theme.palette.color.warning }} />;
    } else if (isMealArrayEmpty === false && isModified === false) {
      return <DinnerDining sx={{ color: theme.palette.color.color24 }} />;
    } else if (isMealArrayEmpty === true && isModified === true) {
      return <NoMeals sx={{ color: theme.palette.color.color14 }} />;
    } else {
      return null;
    }
  };

  return <CalendarDayContent day={day} containerWidth={containerWidth} meal={true} primaryIcon={renderMealIcon(day)} />;
};

MealContent.propTypes = {
  day: PropTypes.object.isRequired,
  containerWidth: PropTypes.number.isRequired
};

export default MealContent;
