import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ChildModel } from '../models/children/ChildModels';

export default function legalGuardiansReducer(state = initialState.legalGuardiansChildren, action) {
  switch (action.type) {
    case types.LOAD_LEGAL_GUARDIANS_CHILDREN_SUCCESS: {
      return action.children.map((child) => new ChildModel().assign(child));
    }
    default:
      return state;
  }
}
