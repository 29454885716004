import PropTypes from 'prop-types';
import React from 'react';

class UploadProgress extends React.Component {
  render() {
    const { files } = this.props;

    return (
      <div style={{ fontSize: 11, marginLeft: 5, marginBottom: 16 }}>
        <div style={{ fontWeight: 500 }}>Trwa wysyłanie plików</div>
        {files.map((file) => {
          return (
            <div key={file.name}>
              {file.name} - {file.progress}%
            </div>
          );
        })}
      </div>
    );
  }
}

UploadProgress.propTypes = {
  files: PropTypes.array.isRequired
};

export default UploadProgress;
