export const ProgramPoints = [
  {
    key: 'Brak',
    title: 'Brak podstawy programowej',
    values: ['Brak']
  },
  {
    key: 'I',
    title: 'Fizyczny obszar rozwoju dziecka. Dziecko przygotowane do podjęcia nauki w szkole',
    values: [
      'I.1 - zgłasza potrzeby fizjologiczne, samodzielnie wykonuje podstawowe czynności higieniczne',
      'I.2 - wykonuje czynności samoobsługowe: ubieranie się i rozbieranie, w tym czynności precyzyjne, np. zapinanie guzików, wiązanie sznurowadeł',
      'I.3 - spożywa posiłki z użyciem sztućców, nakrywa do stołu i sprząta po posiłku',
      'I.4 - komunikuje potrzebę ruchu, odpoczynku itp.',
      'I.5 - uczestniczy w zabawach ruchowych, w tym rytmicznych, muzycznych, naśladowczych, z przyborami lub bez nich  wykonuje różne formy ruchu: bieżne, skoczne, z czworakowaniem, rzutne',
      'I.6 - inicjuje zabawy konstrukcyjne, majsterkuje, buduje, wykorzystując zabawki, materiały użytkowe, w tym materiał naturalny',
      'I.7 - wykonuje czynności, takie jak: sprzątanie, pakowanie, trzymanie przedmiotów jedną ręką i oburącz, małych przedmiotów z wykorzystaniem odpowiednio ukształtowanych chwytów dłoni, używa chwytu pisarskiego podczas rysowania, kreślenia i pierwszych prób pisania',
      'I.8 - wykonuje podstawowe ćwiczenia kształtujące nawyk utrzymania prawidłowej postawy ciała',
      'I.9 - wykazuje sprawność ciała i koordynację w stopniu pozwalającym na rozpoczęcie systematycznej nauki czynności złożonych, takich jak czytanie i pisanie.'
    ]
  },
  {
    key: 'II',
    title: 'Emocjonalny obszar rozwoju dziecka. Dziecko przygotowane do podjęcia nauki w szkole',
    values: [
      'II.1 - rozpoznaje i nazywa podstawowe emocje, próbuje radzić sobie z ich przeżywaniem',
      'II.2 - szanuje emocje swoje i innych osób',
      'II.3 - przeżywa emocje w sposób umożliwiający mu adaptację w nowym otoczeniu, np. w nowej grupie dzieci, nowej grupie starszych dzieci, a także w nowej grupie dzieci i osób dorosłych',
      'II.4 - przedstawia swoje emocje i uczucia, używając charakterystycznych dla dziecka form wyrazu',
      'II.5 - rozstaje się z rodzicami bez lęku, ma świadomość, że rozstanie takie bywa dłuższe lub krótsze',
      'II.6 - rozróżnia emocje i uczucia przyjemne i nieprzyjemne, ma świadomość, że odczuwają i przeżywają je wszyscy ludzie',
      'II.7 - szuka wsparcia w sytuacjach trudnych dla niego emocjonalnie  wdraża swoje własne strategie, wspierane przez osoby dorosłe lub rówieśników',
      'II.8 - zauważa, że nie wszystkie przeżywane emocje i uczucia mogą być podstawą do podejmowania natychmiastowego działania, panuje nad nieprzyjemną emocją, np. podczas czekania na własną kolej w zabawie lub innej sytuacji',
      'II.9 - wczuwa się w emocje i uczucia osób z najbliższego otoczenia',
      'II.10 - dostrzega, że zwierzęta posiadają zdolność odczuwania, przejawia w stosunku do nich życzliwość i troskę',
      'II.11 - dostrzega emocjonalną wartość otoczenia przyrodniczego jako źródła satysfakcji estetycznej.'
    ]
  },
  {
    key: 'III',
    title: 'Społeczny obszar rozwoju dziecka. Dziecko przygotowane do podjęcia nauki w szkole',
    values: [
      'III.1 - przejawia poczucie własnej wartości jako osoby, wyraża szacunek wobec innych osób i przestrzegając tych wartości, nawiązuje relacje rówieśnicze',
      'III.2 - odczuwa i wyjaśnia swoją przynależność do rodziny, narodu, grupy przedszkolnej, grupy chłopców, grupy dziewczynek oraz innych grup, np. grupy teatralnej, grupy sportowej',
      'III.3 - posługuje się swoim imieniem, nazwiskiem, adresem',
      'III.4 - używa zwrotów grzecznościowych podczas powitania, pożegnania, sytuacji wymagającej przeproszenia i przyjęcia konsekwencji swojego zachowania',
      'III.5 - ocenia swoje zachowanie w kontekście podjętych czynności i zadań oraz przyjętych norm grupowych  przyjmuje, respektuje i tworzy zasady zabawy w grupie, współdziała z dziećmi w zabawie, pracach użytecznych, podczas odpoczynku',
      'III.6 - nazywa i rozpoznaje wartości związane z umiejętnościami i zachowaniami społecznymi, np. szacunek do dzieci i dorosłych, szacunek do ojczyzny, życzliwość okazywana dzieciom i dorosłym – obowiązkowość, przyjaźń, radość',
      'III.7 - respektuje prawa i obowiązki swoje oraz innych osób, zwracając uwagę na ich indywidualne potrzeby',
      'III.8 - obdarza uwagą inne dzieci i osoby dorosłe',
      'III.9 - komunikuje się z dziećmi i osobami dorosłymi, wykorzystując komunikaty werbalne i pozawerbalne  wyraża swoje oczekiwania społeczne wobec innego dziecka, grupy'
    ]
  },
  {
    key: 'IV',
    title: 'Poznawczy obszar rozwoju dziecka. Dziecko przygotowane do podjęcia nauki w szkole',
    values: [
      'IV.1 - wyraża swoje rozumienie świata, zjawisk i rzeczy znajdujących się w bliskim otoczeniu za pomocą komunikatów pozawerbalnych: tańca, intencjonalnego ruchu, gestów, impresji plastycznych, technicznych, teatralnych, mimicznych, konstrukcji i modeli z tworzyw i materiału naturalnego',
      'IV.2 - wyraża swoje rozumienie świata, zjawisk i rzeczy znajdujących się w bliskim otoczeniu za pomocą języka mówionego, posługuje się językiem polskim w mowie zrozumiałej dla dzieci i osób dorosłych, mówi płynnie, wyraźnie, rytmicznie, poprawnie wypowiada ciche i głośne dźwięki mowy, rozróżnia głoski na początku i końcu w wybranych prostych fonetycznie słowach',
      'IV.3 - odróżnia elementy świata fikcji od realnej rzeczywistości  byty rzeczywiste od medialnych, byty realistyczne od fikcyjnych',
      'IV.4 - rozpoznaje litery, którymi jest zainteresowane na skutek zabawy i spontanicznych odkryć, odczytuje krótkie wyrazy utworzone z poznanych liter w formie napisów drukowanych dotyczące treści znajdujących zastosowanie w codziennej aktywności',
      'IV.5 - odpowiada na pytania, opowiada o zdarzeniach z przedszkola, objaśnia kolejność zdarzeń w prostych historyjkach obrazkowych, układa historyjki obrazkowe, recytuje wierszyki, układa i rozwiązuje zagadki',
      'IV.6 - wykonuje własne eksperymenty językowe, nadaje znaczenie czynnościom, nazywa je, tworzy żarty językowe i sytuacyjne, uważnie słucha i nadaje znaczenie swym doświadczeniom',
      'IV.7 - eksperymentuje rytmem, głosem, dźwiękami i ruchem, rozwijając swoją wyobraźnię muzyczną  słucha, odtwarza i tworzy muzykę, śpiewa piosenki, porusza się przy muzyce i do muzyki, dostrzega zmiany charakteru muzyki, np. dynamiki, tempa i wysokości dźwięku oraz wyraża ją ruchem, reaguje na sygnały, muzykuje z użyciem instrumentów oraz innych źródeł dźwięku  śpiewa piosenki z dziecięcego repertuaru oraz łatwe piosenki ludowe  chętnie uczestniczy w zbiorowym muzykowaniu  wyraża emocje i zjawiska pozamuzyczne różnymi środkami aktywności muzycznej  aktywnie słucha muzyki  wykonuje lub rozpoznaje melodie, piosenki i pieśni, np. ważne dla wszystkich dzieci w przedszkolu, np. hymn przedszkola, charakterystyczne dla uroczystości narodowych (hymn narodowy), potrzebne do organizacji uroczystości np. Dnia Babci i Dziadka, święta przedszkolaka (piosenki okazjonalne) i inne  w skupieniu słucha muzyki',
      'IV.8 - wykonuje własne eksperymenty graficzne farbą, kredką, ołówkiem, mazakiem itp., tworzy proste i złożone znaki, nadając im znaczenie, odkrywa w nich fragmenty wybranych liter, cyfr, kreśli wybrane litery i cyfry na gładkiej kartce papieru, wyjaśnia sposób powstania wykreślonych, narysowanych lub zapisanych kształtów, przetwarza obraz ruchowy na graficzny i odwrotnie, samodzielnie planuje ruch przed zapisaniem, np. znaku graficznego, litery i innych w przestrzeni sieci kwadratowej lub liniatury, określa kierunki i miejsca na kartce papieru',
      'IV.9 - czyta obrazy, wyodrębnia i nazywa ich elementy, nazywa symbole i znaki znajdujące się w otoczeniu, wyjaśnia ich znaczenie',
      'IV.10 - wymienia nazwę swojego kraju i jego stolicy, rozpoznaje symbole narodowe (godło, flaga, hymn), nazywa wybrane symbole związane z regionami Polski ukryte w podaniach, przysłowiach, legendach, bajkach, np. o smoku wawelskim, orientuje się, że Polska jest jednym z krajów Unii Europejskiej',
      'IV.11 - wyraża ekspresję twórczą podczas czynności konstrukcyjnych i zabawy, zagospodarowuje przestrzeń, nadając znaczenie umieszczonym w niej przedmiotom, określa ich położenie, liczbę, kształt, wielkość, ciężar, porównuje przedmioty w swoim otoczeniu z uwagi na wybraną cechę',
      'IV.12 - klasyfikuje przedmioty według: wielkości, kształtu, koloru, przeznaczenia, układa przedmioty w grupy, szeregi, rytmy, odtwarza układy przedmiotów i tworzy własne, nadając im znaczenie, rozróżnia podstawowe figury geometryczne (koło, kwadrat, trójkąt, prostokąt)',
      'IV.13 - eksperymentuje, szacuje, przewiduje, dokonuje pomiaru długości przedmiotów, wykorzystując np. dłoń, stopę, but',
      'IV.14 - określa kierunki i ustala położenie przedmiotów w stosunku do własnej osoby, a także w stosunku do innych przedmiotów, rozróżnia stronę lewą i prawą',
      'IV.15 - przelicza elementy zbiorów w czasie zabawy, prac porządkowych, ćwiczeń i wykonywania innych czynności, posługuje się liczebnikami głównymi i porządkowymi, rozpoznaje cyfry oznaczające liczby od 0 do 10, eksperymentuje z tworzeniem kolejnych liczb, wykonuje dodawanie i odejmowanie w sytuacji użytkowej, liczy obiekty, odróżnia liczenie błędne od poprawnego',
      'IV.16 - posługuje się w zabawie i w trakcie wykonywania innych czynności pojęciami dotyczącymi następstwa czasu np. wczoraj, dzisiaj, jutro, rano, wieczorem, w tym nazwami pór roku, nazwami dni tygodnia i miesięcy',
      'IV.17 - rozpoznaje modele monet i banknotów o niskich nominałach, porządkuje je, rozumie, do czego służą pieniądze w gospodarstwie domowym',
      'IV.18 - posługuje się pojęciami dotyczącymi zjawisk przyrodniczych, np. tęcza, deszcz, burza, opadanie liści z drzew, sezonowa wędrówka ptaków, kwitnienie drzew, zamarzanie wody, dotyczącymi życia zwierząt, roślin, ludzi w środowisku przyrodniczym, korzystania z dóbr przyrody, np. grzybów, owoców, ziół',
      'IV.19 - podejmuje samodzielną aktywność poznawczą np. oglądanie książek, zagospodarowywanie przestrzeni własnymi pomysłami konstrukcyjnymi, korzystanie z nowoczesnej technologii itd.',
      'IV.20 - wskazuje zawody wykonywane przez rodziców i osoby z najbliższego otoczenia, wyjaśnia, czym zajmuje się osoba wykonująca dany zawód',
      'IV.21 - rozumie bardzo proste polecenia w języku obcym nowożytnym i reaguje na nie  uczestniczy w zabawach, np. muzycznych, ruchowych, plastycznych, konstrukcyjnych, teatralnych  używa wyrazów i zwrotów mających znaczenie dla danej zabawy lub innych podejmowanych czynności  powtarza rymowanki i proste wierszyki, śpiewa piosenki w grupie  rozumie ogólny sens krótkich historyjek opowiadanych lub czytanych, gdy są wspierane np. obrazkami, rekwizytami, ruchem, mimiką, gestami',
      'IV.22 - reaguje na proste polecenie w języku mniejszości narodowej lub etnicznej, używa wyrazów i zwrotów mających znaczenie w zabawie i innych podejmowanych czynnościach: powtarza rymowanki i proste wierszyki, śpiewa piosenki  rozumie ogólny sens krótkich historyjek opowiadanych lub czytanych wspieranych np. obrazkiem, rekwizytem, gestem  zna godło (symbol) swojej wspólnoty narodowej lub etnicznej',
      'IV.23 - reaguje na proste polecenie w języku regionalnym – kaszubskim, używa wyrazów i zwrotów mających znaczenie w zabawie i innych podejmowanych czynnościach: powtarza rymowanki i proste wierszyki, śpiewa piosenki  rozumie ogólny sens krótkich historyjek opowiadanych lub czytanych wspieranych np. obrazkiem, rekwizytem, gestem, zna godło (symbol) swojej wspólnoty regionalnej – kaszubskiej.'
    ]
  }
];
