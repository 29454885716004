import PropTypes from 'prop-types';
import React from 'react';
import CardEditForm from '../../forms/CardEditForm';
import ActivityDetailsForm from './ActivityDetailsForm';

class ActivityDetailsCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      errors: {}
    };
  }

  render() {
    return (
      <CardEditForm
        onSave={this.props.onSave}
        onValidate={this.props.onValidate}
        onValidationDone={(errors) => this.setState({ errors })}
        onCancel={this.props.onCancel}
        isProcessing={this.props.isProcessing}
        isExpanded={this.props.isEditing}
        statePathToUi="activityDetailsUi.details"
        onCollapse={this.props.onCancel}
        onExpand={this.props.onEdit}
        title={this.props.title || this.props.activity.name}
        editForm={
          <ActivityDetailsForm
            activity={this.props.activity}
            onChange={this.props.onChange}
            errors={this.state.errors}
          />
        }
        groupColor={this.props.activity.color}
        requiredFieldText
      />
    );
  }
}

ActivityDetailsCard.propTypes = {
  activity: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isProcessing: PropTypes.bool,
  title: PropTypes.string
};

export default ActivityDetailsCard;
