import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function legalGuardianApplicationsReducer(state = initialState.applications, action) {
  switch (action.type) {
    case types.LOAD_ALL_APPLICATIONS_SUCCESS: {
      return action.applications;
    }
    default:
      return state;
  }
}
