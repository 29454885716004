import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Checkbox, Box, ListItemButton } from '@mui/material';
import EmptyState from '../../../common/EmptyState';
import _ from 'lodash';
import { SearchBar } from '../../../common/SearchBar';
import DatePicker from '../../../common/DatePicker';
import SelectField from '../../../common/SelectField';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';

const AssignedChildrenList = ({
  groupsWithPupils,
  emptyMessage,
  onSelect,
  onDeselect,
  isReadonly,
  searchText,
  setSearchText,
  reliefId,
  reliefExpirationDate,
  onReliefIdChange,
  onReliefExpirationDateChange,
  isAssigning
}) => {
  const reliefs = useSelector((state) => state.configuration.reliefs);

  const allPupils = useMemo(() => {
    return () => _.flatten(Object.values(groupsWithPupils));
  }, [groupsWithPupils]);

  if (Object.keys(groupsWithPupils).length === 0) {
    return <EmptyState contrast message={emptyMessage} />;
  }

  const handleCheck = (v, ids) => (v ? onSelect(ids) : onDeselect(ids));

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const renderPupil = (pupil) => (
    <ListItem
      sx={{ pl: 2 }}
      component="li"
      aria-label={`Zaznacz ${pupil.lastName} ${pupil.firstName}`}
      dense
      key={pupil.id}>
      <ListItemButton disabled={isReadonly} onClick={() => handleCheck(!pupil.isChecked, [pupil.id])}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={pupil.isChecked}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': pupil.id }}
          />
        </ListItemIcon>
        <ListItemText
          id={pupil.id}
          primary={`${pupil.lastName} ${pupil.firstName}`}
          secondary={pupil.singleUse ? '(jednorazowy)' : null}
          secondaryTypographyProps={{ color: (theme) => theme.palette.color.contrast }}
        />
      </ListItemButton>
    </ListItem>
  );

  const renderHeader = (group) =>
    isReadonly ? (
      <ListItem key={group}>
        <ListItemText
          primary={`Grupa ${group}`}
          primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightLarge }}
        />
      </ListItem>
    ) : (
      <ListItem dense key={group} sx={{ pl: 0 }} component="li" aria-label={`Wybierz grupę ${group}`}>
        <ListItemButton
          disabled={isReadonly}
          onClick={() =>
            handleCheck(
              !groupsWithPupils[group].every((p) => p.isChecked),
              groupsWithPupils[group].map((pupil) => pupil.id)
            )
          }>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={groupsWithPupils[group].every((p) => p.isChecked)}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': group }}
            />
          </ListItemIcon>
          <ListItemText
            id={group}
            primary={`Grupa ${group}`}
            primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightLarge, fontSize: 16 }}
          />
        </ListItemButton>
      </ListItem>
    );

  const renderCheckAll = () => (
    <ListItem sx={{ pl: 0 }} dense key="all" component="li" aria-label="Zaznacz wszystkie">
      <ListItemButton
        onClick={() =>
          handleCheck(
            !allPupils().every((p) => p.isChecked),
            allPupils().map((pupil) => pupil.id)
          )
        }>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={allPupils().every((p) => p.isChecked)}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': 'all' }}
          />
        </ListItemIcon>
        <ListItemText id="all" primary="Wszystkie" />
      </ListItemButton>
    </ListItem>
  );

  const renderReliefChange = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: { xs: '', md: 'space-between' },
        alignItems: 'start',
        flexDirection: { xs: 'column', sm: 'row' },
        columnGap: 2
      }}>
      <SelectField
        contrast
        sx={{ width: '50%', my: 1, mx: 0 }}
        id="reliefId"
        value={reliefId || ''}
        onChange={(e) => onReliefIdChange(e.target.value)}
        floatingLabelText="Ulga">
        <MenuItem key={null} value="" onClick={() => onReliefExpirationDateChange(null)}>
          Brak ulgi
        </MenuItem>
        {reliefs.map((relief) => {
          return (
            <MenuItem key={relief.id} value={relief.id}>
              {relief.name}
            </MenuItem>
          );
        })}
      </SelectField>

      <DatePicker
        floatingLabelText={<Box sx={{ color: (theme) => theme.palette.color.contrast }}>Data ważności ulgi</Box>}
        onChange={(e, date) => onReliefExpirationDateChange(date)}
        value={reliefExpirationDate ? new Date(reliefExpirationDate) : undefined}
        name="reliefExpirationDate"
        disabled={!reliefId}
        sx={{ my: 1 }}
        unlimitedMaxDate={true}
      />
    </Box>
  );

  return (
    <Box>
      <SearchBar contrast value={searchText} onChange={handleSearchTextChange} label="Wyszukaj ucznia" />
      {!isReadonly && renderCheckAll()}
      {isAssigning && renderReliefChange()}
      <List sx={{ pt: 2, height: 300, overflowY: 'auto', overflowX: 'hidden' }}>
        {Object.entries(groupsWithPupils).map(([group, pupils]) => {
          if (Object.entries(pupils).length === 0) return null;
          return (
            <Box key={group}>
              {renderHeader(group)}
              {pupils.map((pupil) => renderPupil(pupil))}
            </Box>
          );
        })}
      </List>
    </Box>
  );
};

AssignedChildrenList.propTypes = {
  groupsWithPupils: PropTypes.object.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func.isRequired
};

export default AssignedChildrenList;
