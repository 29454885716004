import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CardActionArea,
  CardContent,
  Collapse,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery
} from '@mui/material';
import moment from 'moment';
import ClearButtonWrapper from '../../common/ClearButtonWrapper';
import DatePicker from '../../common/DatePicker';
import eventTranslations from '../../../constants/eventTranslations';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import SelectField from '../../common/SelectField';

const StaffMemberProfileHistoryCard = (props) => {
  const [isOpen, setIsOpen] = useState(props.isInitiallyExpanded);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const numberArrayOfOptions = [5, 10, 25, 50];
  const countOfPagination = Math.ceil(props.profileHistory.rowNumber / rowsPerPage);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const isDateInvalid = useMemo(() => {
    if (dateFrom !== null && dateTo !== null) {
      return dateFrom > dateTo;
    }
    return false;
  }, [dateFrom, dateTo]);

  const loadEvents = (newRow, newPage) => {
    const params = {
      fetchCount: newRow,
      page: newPage
    };
    if (!!dateFrom) params.dateFrom = moment(dateFrom).format('YYYY-MM-DD');
    if (!!dateTo) params.dateTo = moment(dateTo).format('YYYY-MM-DD');
    props.onFilter(props.userId, params);
  };

  const handleChangePage = (event, newValue) => {
    setPage(newValue);
    loadEvents(rowsPerPage, newValue - 1);
  };

  const handleRowsChange = (e) => {
    setRowsPerPage(e.target.value);
    setPage(1);
    loadEvents(e.target.value, 0);
  };

  const handleFilter = () => {
    setPage(1);
    loadEvents(rowsPerPage, 0);
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Paper sx={{ my: 2, p: 1 }} elevation={1}>
      <CardActionArea onClick={handleOpen}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', px: 2, py: 1, alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, my: '4px' }}>
              Historia zmian profilu
            </Typography>
          </Box>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </CardContent>
      </CardActionArea>
      <Collapse in={isOpen}>
        <CardContent>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
              <Box>
                <ClearButtonWrapper contrast sx={{ mr: 1 }} onClear={() => setDateFrom(null)}>
                  <DatePicker
                    onChange={(val) => setDateFrom(val)}
                    value={dateFrom}
                    floatingLabelText="Zmiany od"
                    name="timeFrom"
                  />
                </ClearButtonWrapper>
                {isDateInvalid === true && (
                  <Typography sx={{ fontSize: 12, color: (theme) => theme.palette.color.hidden }}>
                    Data końcowa musi być późniejsza od daty początkowej
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
              <Box>
                <ClearButtonWrapper contrast sx={{ mr: 1 }} onClear={() => setDateTo(null)}>
                  <DatePicker
                    onChange={(val) => setDateTo(val)}
                    value={dateTo}
                    floatingLabelText="Zmiany do"
                    name="timeTo"
                  />
                </ClearButtonWrapper>
                {isDateInvalid === true && (
                  <Typography sx={{ fontSize: 12, color: (theme) => theme.palette.background.error }}>
                    Data końcowa musi być późniejsza od daty początkowej
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', sm: 'flex-end' },
                mt: { xs: 2, md: 0 }
              }}>
              <SelectField
                contrast
                sx={{
                  minWidth: 88
                }}
                id="rowsPerPage"
                value={rowsPerPage}
                onChange={handleRowsChange}
                floatingLabelText="Wierszy na stronę">
                {numberArrayOfOptions.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </SelectField>
              <Button variant="contained" sx={{ ml: 2 }} aria-label="Filtruj" onClick={handleFilter}>
                Filtruj
              </Button>
            </Grid>
          </Grid>
          <TableContainer>
            <Table aria-label="Historia zmian w profilu">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Zmiana</TableCell>
                  <TableCell align="center">Wykonawca</TableCell>
                  <TableCell align="right">Data zmiany</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.profileHistory.profileHistoryList.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{eventTranslations[row.event]}</TableCell>
                    <TableCell align="center">
                      {!row.userData.login ? <span>Brak</span> : null}
                      {row.userData.fullName && <Typography>{row.userData.fullName}</Typography>}
                      {row.userData.login && <Typography>{row.userData.login}</Typography>}
                    </TableCell>
                    <TableCell align="right">{moment(row.created_at).format('YYYY-MM-DD HH:mm')}</TableCell>
                  </TableRow>
                ))}
                {props.profileHistory.profileHistoryList.length === 0 && (
                  <TableRow>
                    <TableCell>Brak wyników</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Typography variant="caption" sx={{ color: (theme) => theme.palette.color.contrast }}>
              Historia zmian w profilu
            </Typography>
          </TableContainer>
          <Pagination
            siblingCount={isMobile ? 0 : 1}
            count={countOfPagination}
            page={page}
            onChange={handleChangePage}
            sx={{ display: 'flex', justifyContent: 'center', mt: 4, pb: 4 }}
          />
        </CardContent>
      </Collapse>
    </Paper>
  );
};

StaffMemberProfileHistoryCard.propTypes = {
  isInitiallyExpanded: PropTypes.bool.isRequired,
  profileHistory: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired
};

export default StaffMemberProfileHistoryCard;
