import Resizer from 'react-image-file-resizer';
import axios from 'axios';
import { ROOT_URL } from '../api/ApiBase';
import uuid from 'uuid/v4';
import * as Status from '../constants/status';

const resizeFile = (file) => {
  return new Promise((resolve) => {
    const isImage = (mimeType) => /.*?(gif|jpg|jpeg|png)$/.test(mimeType);
    if (isImage(file.type)) {
      const type = file.type === 'image/png' ? 'PNG' : 'JPEG';
      Resizer.imageFileResizer(file, 1920, 1080, type, 80, 0, (uri) => resolve(uri), 'blob');
    } else {
      resolve(file);
    }
  });
};
const postFileHandler = (file, notifyUuid, token, actualCount, description, uploadAction) => {
  return resizeFile(file).then((compressedFile) => {
    const newMultimediaData = new FormData();
    newMultimediaData.append('file', compressedFile, file.name);
    newMultimediaData.append('isGallery', true);
    newMultimediaData.append('description', description);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    };
    return axios.post(`${ROOT_URL}/files/upload`, newMultimediaData, config).then(({ data }) => {
      uploadAction(actualCount);
      return data;
    });
  });
};

export const uploadFilesWithNotify = async (
  files,
  errorMultimedia,
  totalCount,
  token,
  startAction,
  uploadAction,
  endingAction,
  successAction,
  descriptionArray
) => {
  const notifyUuid = uuid();
  startAction();

  const newMultimedia = [];
  let descriptionIndex = 0;
  await files.reduce((prevPromise, file) => {
    return prevPromise.then(() => {
      return postFileHandler(
        file,
        notifyUuid,
        token,
        newMultimedia.length,
        descriptionArray[descriptionIndex],
        uploadAction
      )
        .then((x) => {
          newMultimedia.push(x);
          descriptionIndex += 1;
          return x;
        })
        .catch(() => {
          errorMultimedia.push({
            file: { name: file.name },
            errorMessage: 'Błąd w trakcie przesyłania danych'
          });
        });
    });
  }, Promise.resolve());
  let status;
  if (newMultimedia.length === totalCount && errorMultimedia.length === 0) {
    status = Status.SUCCESS;
  } else if (newMultimedia.length > 0 && errorMultimedia.length > 0) {
    status = Status.PARTLY;
  } else {
    status = Status.ERROR;
  }
  endingAction(status === Status.ERROR ? Status.ERROR : Status.UPLOADED, newMultimedia, errorMultimedia);
  if (newMultimedia.length > 0) successAction(newMultimedia, status);
};
