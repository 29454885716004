/* eslint-disable global-require */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { withRouter } from 'react-router';
import CommonWrapper from '../common/CommonWrapper';
import { Backdrop, Box, LinearProgress, Typography } from '@mui/material';
import image_1 from '../../img/manual/image-1.jpg';
import image_2 from '../../img/manual/image-2.jpg';
import image_3 from '../../img/manual/image-3.jpg';
import image_4 from '../../img/manual/image-4.jpg';
import image_5 from '../../img/manual/image-5.jpg';
import image_6 from '../../img/manual/image-6.jpg';
import image_7 from '../../img/manual/image-7.jpg';
import image_8 from '../../img/manual/image-8.jpg';
import image_9 from '../../img/manual/image-9.jpg';
import image_10 from '../../img/manual/image-10.jpg';

const IMAGE_COUNT = 10;

const useStyles = makeStyles((theme) => {
  return {
    header: {
      background: theme.palette.background.default,
      color: 'white',
      fontSize: '1em',
      padding: '16px'
    },
    pictureWrapper: {
      margin: 'auto',
      textAlign: 'center'
    },
    caption: {
      color: theme.palette.color.color4,
      padding: '3px',
      fontSize: '.8em'
    },
    image: {
      width: '80%'
    },
    important: {
      color: 'red',
      textAlign: 'center'
    },
    sectionWrapper: {
      paddingTop: '80px'
    },
    backdrop: {
      zIndex: theme.zIndex.snackbar,
      color: theme.palette.color.color4
    },
    sectionContent: {
      padding: '0 32px'
    }
  };
});

const ManualHeader = ({ title, index }) => {
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.header} sx={{ mb: 1 }}>
      {index + '. ' + title}
    </Typography>
  );
};

ManualHeader.propTypes = {
  title: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired
};

const ManualSection = ({ title, index, children, anchorId, count }) => {
  const section = useRef(null);
  useEffect(() => {
    if (index === anchorId && count === IMAGE_COUNT) {
      window.scrollTo({
        top: section.current.getBoundingClientRect().top + window.pageYOffset,
        behavior: 'smooth'
      });
    }
  }, [anchorId, count, index]);

  const classes = useStyles();

  return (
    <Box id={index} ref={section}>
      <Box className={classes.sectionContent}>
        <ManualHeader title={title} index={index} />
        {children}
      </Box>
    </Box>
  );
};

ManualSection.propTypes = {
  title: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  anchorId: PropTypes.string,
  count: PropTypes.number.isRequired
};

const ManualPicture = ({ name, caption, index, onImageLoad, image }) => {
  const classes = useStyles();
  return (
    <Box className={classes.pictureWrapper}>
      <img className={classes.image} src={image} alt={caption} onLoad={() => onImageLoad(name || index)} />
      <Box className={classes.caption}>
        Rysunek {index} - {caption}
      </Box>
    </Box>
  );
};

ManualPicture.propTypes = {
  name: PropTypes.string,
  index: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  onImageLoad: PropTypes.func.isRequired
};

const ContextManualPage = (props) => {
  const classes = useStyles();
  const [loadedImageCount, setLoadedImageCount] = useState(0);

  const handleImageLoaded = () => {
    setLoadedImageCount((count) => count + 1);
  };

  let anchorId = null;
  if (!!props.location.hash) {
    anchorId = props.location.hash.slice(1);
  }
  return (
    <CommonWrapper>
      <Box>
        <Backdrop className={classes.backdrop} open={loadedImageCount < IMAGE_COUNT}>
          <Box sx={{ height: '1px', width: '30%' }}>
            <LinearProgress value={(loadedImageCount / IMAGE_COUNT) * 100} />
          </Box>
        </Backdrop>
        <ManualSection count={loadedImageCount} anchorId={anchorId} title="Pulpit" index="1">
          <ManualPicture onImageLoad={handleImageLoaded} caption="Widok pulpitu" index="1" image={image_1} />
          <Typography sx={{ mx: 2, textAlign: 'justify' }}>
            Po zalogowaniu do systemu 4Parents, użytkownik witany jest przez
            <Typography component="span" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
              Pulpit
            </Typography>
            . W tej sekcji opiekun posiada szybki dostęp do profilu dziecka w 4Parents, najbliższych zaplanowanych
            wydarzeń w placówce oraz najnowszych ogłoszeń.
          </Typography>
        </ManualSection>
        <ManualSection count={loadedImageCount} anchorId={anchorId} title="Wiadomości" index="2">
          <ManualPicture onImageLoad={handleImageLoaded} caption="Widok wiadomości" index="2" image={image_2} />
          <Typography sx={{ mx: 2, textAlign: 'justify' }}>
            Sekcja
            <Typography component="span" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
              Wiadomości
            </Typography>
            służy do komunikacji pomiędzy placówką a opiekunami. W zależności od preferencji placówki możliwa jest
            komunikacja jednostronna bądź dwustronna.
          </Typography>
        </ManualSection>

        <ManualSection count={loadedImageCount} anchorId={anchorId} title="Ogłoszenia" index="3">
          <ManualPicture onImageLoad={handleImageLoaded} caption="Widok ogłoszeń" index="3" image={image_3} />
          <Typography sx={{ mx: 2, textAlign: 'justify' }}>
            W
            <Typography component="span" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
              Ogłoszeniach
            </Typography>
            wyświetlanie są wszystkie ogłoszenia wystosowane przez placówkę. Wyszukiwanie pojedynczych ogłoszeń ułatwia
            opcja filtrowania, znajdująca się pod przyciskiem FILTRUJ. Dzięki niemu możliwe jest odnalezienie
            interesującego użytkownika zgłoszenia według: daty, grupy, zajęć oraz autorów ogłoszeń.
          </Typography>
        </ManualSection>

        <ManualSection count={loadedImageCount} anchorId={anchorId} title="Kalendarz wydarzeń" index="4">
          <ManualPicture
            onImageLoad={handleImageLoaded}
            caption="Widok kalendarza wydarzeń"
            index="4"
            image={image_4}
          />
          <Typography sx={{ mx: 2, textAlign: 'justify' }}>
            <Typography component="span" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
              Kalendarz wydarzeń
            </Typography>
            przedstawiony jest w formie widoku dni aktualnego miesiąca i roku. Opiekun może w nim podejrzeć wszystkie
            zaplanowane przez placówkę wydarzenia we wskazanym miesiącu. Dodatkowy filtr umieszczony nad kalendarzem
            pomaga również odnaleźć wydarzenia według grup oraz zajęć.
          </Typography>
        </ManualSection>

        <ManualSection count={loadedImageCount} anchorId={anchorId} title="Opłaty" index="5">
          <ManualPicture onImageLoad={handleImageLoaded} caption="Widok opłat" index="5" image={image_5} />
          <Typography sx={{ mx: 2, textAlign: 'justify' }}>
            Sekcja
            <Typography component="span" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
              Opłaty
            </Typography>
            przedstawia listę opłat wygenerowanych przez placówkę. Możliwy jest w niej podgląd oraz realizacja opłat za
            pobyt, wyżywienie bądź inne zobowiązania opiekuna wobec placówki.
          </Typography>
        </ManualSection>

        <ManualSection count={loadedImageCount} anchorId={anchorId} title="Wnioski" index="6">
          <ManualPicture onImageLoad={handleImageLoaded} caption="Widok wniosków" index="6" image={image_6} />
          <Typography sx={{ mx: 2, textAlign: 'justify' }}>
            We
            <Typography component="span" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
              Wnioskach
            </Typography>
            opiekun może złożyć wniosek dotyczący np. zmiany sposobu rozliczeń za wyżywienie dziecka bądź zapisania go
            na zajęcia dodatkowe. Złożenie wniosku należy rozpocząć poprzez wybranie symbolu „+” przy odpowiednim wątku.
          </Typography>
        </ManualSection>

        <ManualSection count={loadedImageCount} anchorId={anchorId} title="Dodawanie wniosków" index="6.1">
          <ManualPicture onImageLoad={handleImageLoaded} caption="Widok dodawania wniosku" index="7" image={image_7} />
          <ManualPicture onImageLoad={handleImageLoaded} caption="Widok listy wniosków" index="8" image={image_8} />
          <Typography sx={{ mx: 2, textAlign: 'justify' }}>
            Po rozpoczęciu składania wniosku, wyświetlane jest okno wyboru zagadnienia, którego ma on dotyczyć.
            Użytkownik powinien wybrać z listy pozycję, która go interesuje i wysłać wniosek wybierając przycisk ZAPISZ.
            Tak złożony dokument pojawi się na liście wniosków. Wyświetlany będzie również jego status, odpowiedni do
            postępu procesu jego rozpatrzenia.
          </Typography>
        </ManualSection>

        <ManualSection count={loadedImageCount} anchorId={anchorId} title="Profil" index="7">
          <ManualPicture onImageLoad={handleImageLoaded} caption="Dodatkowe menu" index="9" image={image_9} />
          <ManualPicture
            onImageLoad={handleImageLoaded}
            caption="Widok profilu użytkownika"
            index="10"
            image={image_10}
          />
          <Typography sx={{ mx: 2, textAlign: 'justify' }}>
            W prawym, górnym rogu okna portalu umieszczone jest dodatkowe menu, symbolizowane trzema kropkami. Zawiera
            ono przejście do podglądu Profilu użytkownika poprzez opcję Profil oraz opcję wylogowania się z portalu.
            Profil użytkownika zawiera informację o kodzie PIN do profilu, możliwość wgrania zdjęcia profilowego oraz
            funkcję zmiany hasła.
          </Typography>
        </ManualSection>
      </Box>
    </CommonWrapper>
  );
};

ContextManualPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(ContextManualPage);
