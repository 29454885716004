import React from 'react';

const FooterText = () => {
  return (
    <a
      href="https://4parents.education"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'inherit', textDecoration: 'none' }}>
      © 4Parents {new Date().getFullYear()} | sukces zaczyna się w Hubie
    </a>
  );
};
export default FooterText;
