import { Button, Grid, Paper } from '@mui/material';
import ContentWrapper from '../common/contentWrapper';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import DinnerDiningOutlinedIcon from '@mui/icons-material/DinnerDiningOutlined';
import FilterBar from '../common/FilterBar';
import EmptyState from '../common/EmptyState';
import { DietsList } from './DietsList';
import { browserHistory } from 'react-router';
import { routePaths } from '../../routePaths';
import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';

export const DietsPage = () => {
  const diets = useSelector((state) => state.diets);
  const [filteredDiets, setFilteredDiets] = useState(diets);

  const handleFilterChange = useCallback((filteredValues) => {
    setFilteredDiets(filteredValues);
  }, []);

  const handleFetchedDiets = useCallback(() => {
    setFilteredDiets(diets);
  }, [diets]);

  const redirectToCreateDietPage = () => {
    browserHistory.push(routePaths.createDiet);
  };

  return (
    <LoadingRenderWrapper onLoaded={handleFetchedDiets}>
      <>
        <PageHeaderText title="Diety" titleIcon={<DinnerDiningOutlinedIcon />} />
        <ContentWrapper>
          <Grid container>
            <Grid item xs={12} sm={7} md={5} sx={{ dispaly: 'flex', justifyContent: 'center' }}>
              <FilterBar
                sx={{ py: 1 }}
                itemsToFilter={diets}
                onFilter={handleFilterChange}
                filter={(diet, keyword) => diet.name.toLowerCase().includes(keyword)}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" aria-label="Dodaj dietę" onClick={redirectToCreateDietPage} sx={{ my: 1 }}>
                Dodaj dietę
              </Button>
            </Grid>
          </Grid>
          {diets.length === 0 && filteredDiets.length === 0 ? (
            <EmptyState contrast isPaper message="Nie utworzono żadnych diet" />
          ) : (
            <>
              {diets.length !== 0 && filteredDiets.length === 0 ? (
                <EmptyState contrast isPaper message="Brak wyników wyszukiwania" />
              ) : (
                <Paper sx={{ mb: 2 }}>
                  <DietsList diets={filteredDiets} />
                </Paper>
              )}
            </>
          )}
        </ContentWrapper>
      </>
    </LoadingRenderWrapper>
  );
};
