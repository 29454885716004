import React from 'react';
import PropTypes from 'prop-types';
import ShareFileMobileToolkit from './ShareFileMobileToolkit';
import ShareFileMobileManage from './ShareFileMobileManage';
import ShareFileMobileAlert from './ShareFileMobileAlert';
import { Box } from '@mui/material';

const ShareFileMobileHeader = ({
  selectedDetailsSharingOption,
  setSelectedDetailsSharingOption,
  selectedSharingOption,
  ...rest
}) => {
  return (
    <Box sx={{ background: (theme) => theme.palette.background.color3 }}>
      <ShareFileMobileToolkit selectedSharingOption={selectedSharingOption} {...rest} />
      <ShareFileMobileManage
        selectedSharingOption={selectedSharingOption}
        selectedDetailsSharingOption={selectedDetailsSharingOption}
        setSelectedDetailsSharingOption={setSelectedDetailsSharingOption}
      />
      <ShareFileMobileAlert />
    </Box>
  );
};

ShareFileMobileHeader.propTypes = {
  selectedDetailsSharingOption: PropTypes.string,
  selectedSharingOption: PropTypes.number,
  setSelectedDetailsSharingOption: PropTypes.func
};

export default ShareFileMobileHeader;
