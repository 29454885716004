import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CardActionArea, CardContent, Collapse, Grow, List, Paper, Typography, useTheme } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButtonPipe from '../../../../../components/common/IconButtonPipe';
import UserRoleIcon from '../../../../../components/userRoles/UserRoleIcon';
import { useSelector } from 'react-redux';
import SpecificIcon from '../../../../../components/groupsAndActivities/SpecificIcon';
import * as GlobalTypes from '../../../../../constants/groupsAndActivitiesTypes';
import SingleUser from '../SingleUser';
import { useSharePersonListComponentStyles } from './SharePersonListComponentStyles';
import DeleteAllIcon from '../../../../../assets/icons/DeleteAllIcon';
import { useShareFileContext } from '../../../context/ShareFileContext';

const ReducedSharePersonListComponent = ({
  title,
  type,
  groupId,
  color: propsColor,
  users,
  teachers,
  isSelected = false,
  onSingleAction,
  onMultiAction,
  forceOpenCollapse = false
}) => {
  const isTeachers = teachers !== undefined && teachers.length !== 0;
  const isUsers = users !== undefined && users.length !== 0;
  const { isPrivate } = useShareFileContext();
  const [isOpen, setIsOpen] = useState(false);
  const isContrastColor = useSelector((state) => state.contrastColor);
  const theme = useTheme();

  useEffect(() => {
    setIsOpen(forceOpenCollapse);
  }, [forceOpenCollapse]);

  // eslint-disable-next-line no-nested-ternary
  let color = isContrastColor
    ? theme.palette.color.color25
    : type === GlobalTypes.GROUP
    ? propsColor
    : theme.palette.role[type];

  if (isPrivate) color = theme.palette.color.disabled;

  const cl = useSharePersonListComponentStyles({ color, isOpen });

  if (!isTeachers && !isUsers) return null;

  return (
    <Grow in={true} timeout={500}>
      <Paper elevation={1} className={cl.wrapper}>
        <CardActionArea
          component="div"
          disabled={isPrivate}
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
          className={cl.actionArea}>
          <CardContent className={cl.cardContent}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isOpen ? <ExpandLess /> : <ExpandMore />}
              {type === GlobalTypes.GROUP ? (
                <SpecificIcon type={type} sx={{ mr: 2, fontSize: 32, color }} />
              ) : (
                <UserRoleIcon role={type} sx={{ mr: 2, fontSize: 34, color }} />
              )}
              <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightLarge }}>{title} </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        <Collapse in={isOpen}>
          <CardContent sx={{ borderTop: `1px solid ${color}` }}>
            {isUsers && (
              <>
                <Box sx={{ ml: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightLarge }}>Uczniowie</Typography>
                  <IconButtonPipe
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      e.persist();
                      onMultiAction(
                        users.map((item) => item.id),
                        isSelected,
                        groupId
                      );
                    }}
                    tooltip={isSelected ? 'Usuń' : 'Udostępnij'}
                    style={{ color: isSelected ? theme.palette.color.color10 : theme.palette.color.color25 }}>
                    {isSelected ? (
                      <DeleteAllIcon sx={{ color: isPrivate ? color : undefined }} />
                    ) : (
                      <ArrowForwardIcon sx={{ color: isPrivate ? color : undefined }} />
                    )}
                  </IconButtonPipe>
                </Box>
                <List dense sx={{ p: 0, m: 0 }}>
                  {users.map((item) => (
                    <SingleUser
                      {...item}
                      key={item.id}
                      onSingleAction={(id) => {
                        onSingleAction(id, isSelected, groupId);
                      }}
                      isSelected={isSelected}
                      isDisabled={isPrivate}
                    />
                  ))}
                </List>
              </>
            )}
            {isTeachers && (
              <Box>
                <Box sx={{ ml: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightLarge }}>Nauczyciele</Typography>
                  <IconButtonPipe
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      e.persist();
                      onMultiAction(
                        teachers.map((item) => item.id),
                        isSelected,
                        groupId
                      );
                    }}
                    tooltip={isSelected ? 'Usuń' : 'Udostępnij'}
                    style={{ color: isSelected ? theme.palette.color.color10 : theme.palette.color.color25 }}>
                    {isSelected ? (
                      <DeleteAllIcon sx={{ color: isPrivate ? color : undefined }} />
                    ) : (
                      <ArrowForwardIcon sx={{ color: isPrivate ? color : undefined }} />
                    )}
                  </IconButtonPipe>
                </Box>
                <List dense sx={{ p: 0, m: 0 }}>
                  {teachers.map((item) => (
                    <SingleUser
                      {...item}
                      key={item.id}
                      onSingleAction={(id) => {
                        onSingleAction(id, isSelected, groupId, true);
                      }}
                      isSelected={isSelected}
                      isDisabled={isPrivate}
                    />
                  ))}
                </List>
              </Box>
            )}
          </CardContent>
        </Collapse>
      </Paper>
    </Grow>
  );
};

ReducedSharePersonListComponent.propTypes = {
  color: PropTypes.string,
  isSelected: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  users: PropTypes.array,
  teachers: PropTypes.array,
  onSingleAction: PropTypes.func.isRequired,
  onMultiAction: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  forceOpenCollapse: PropTypes.bool
};

export default ReducedSharePersonListComponent;
