import PropTypes from 'prop-types';
import React from 'react';
import DeductionItem from './DeductionItem';
import ClearButtonWrapper from '../../../common/ClearButtonWrapper';
import _ from 'lodash';
import { DeductionModel } from '../../../../models/configuration/chargingSchemes/ChargingSchemeModels';
import { ChargingDeductionBases } from '../../../../constants/chargingDeductionBases';
import { validationTypes } from '../../../common/validation/ValidationSummary';
import ValidationLabel from '../../../common/validation/ValidationLabel';
import { Box, Button } from '@mui/material';

class DeductionList extends React.Component {
  constructor(props) {
    super(props);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getAvailableBaseWithThresholds = this.getAvailableBaseWithThresholds.bind(this);

    this.baseWithThresholdsConfig = [
      {
        threshold: '',
        name: 'Nieobecność co najmniej ',
        value: ChargingDeductionBases.days.value
      },
      {
        threshold: '',
        name: 'Nieobecność w ciągu powyżej ',
        value: ChargingDeductionBases.daysInRow.value
      },
      {
        threshold: '',
        name: 'Nieobecność w ciągu powyżej (wszystkie dni w zakresie)',
        value: ChargingDeductionBases.daysInRowFromStart.value
      },
      {
        threshold: null,
        name: 'Nieobecność przez cały miesiąc',
        value: ChargingDeductionBases.month.value
      }
    ];

    this.toBase = (baseWithThresholds, value) => _.find(baseWithThresholds, (x) => x.value === value).value;
    this.toThreshold = (baseWithThresholds, value) => _.find(baseWithThresholds, (x) => x.value === value).threshold;
  }

  getBaseWithThresholdValue(threshold) {
    return `value-${threshold}`;
  }

  getAvailableBaseWithThresholds() {
    return this.baseWithThresholdsConfig;
  }

  handleAdd() {
    const { deductions } = this.props;
    const newDeduction = new DeductionModel(deductions.length + 1);
    const baseWithThreshold = _.head(this.getAvailableBaseWithThresholds(deductions));
    if (!baseWithThreshold) {
      return;
    }
    newDeduction.base = baseWithThreshold.value;
    newDeduction.threshold = baseWithThreshold.threshold;

    this.props.onChange({ target: { name: 'deductions', value: [...deductions, newDeduction] } });
  }

  handleRemove(id) {
    const { deductions } = this.props;
    this.props.onChange({ target: { name: 'deductions', value: [...deductions.filter((d) => d.id !== id)] } });
  }

  handleChange(deduction, event) {
    const field = event.target.name;
    const { deductions } = this.props;
    const nextDeduction = Object.assign({}, deduction);
    if (field === 'thresholdInput') {
      nextDeduction.threshold = parseInt(event.target.value, 10);
    }

    if (field === 'baseThreshold') {
      const baseWithThresholds = this.getAvailableBaseWithThresholds(deductions);
      nextDeduction.base = this.toBase(baseWithThresholds, event.target.value);
      if (event.target.value === ChargingDeductionBases.month.value) {
        nextDeduction.threshold = null;
      }
    } else {
      nextDeduction[field] = event.target.value;
    }

    this.props.onChange({
      target: { name: 'deductions', value: _.sortBy(_.unionBy([nextDeduction], deductions, 'id'), (x) => x.id) }
    });
  }

  render() {
    const { deductions, errors } = this.props;
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <ValidationLabel messages={errors.deductions || ''} type={validationTypes.error} />
        {deductions.map((deduction) => (
          <ClearButtonWrapper
            key={deduction.id}
            onClear={() => this.handleRemove(deduction.id)}
            tooltip="usuń"
            contrast>
            <DeductionItem
              baseWithThresholds={this.getAvailableBaseWithThresholds(deductions, deduction)}
              deduction={deduction}
              onChange={(event) => this.handleChange(deduction, event)}
            />
          </ClearButtonWrapper>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" aria-label="Dodaj rabat" onClick={this.handleAdd} sx={{ mt: 1 }}>
            Dodaj rabat
          </Button>
        </Box>
      </Box>
    );
  }
}

DeductionList.propTypes = {
  deductions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default DeductionList;
