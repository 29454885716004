const staffMemberRoles = Object.freeze({
  teacher: { value: 'teacher', name: 'Nauczyciel' },
  principal: { value: 'principal', name: 'Dyrektor' },
  canteenWorker: { value: 'canteenWorker', name: 'Pracownik Stołówki' },
  accountant: { value: 'accountant', name: 'Księgowy' },
  secretary: { value: 'secretary', name: 'Sekretariat' },
  digitalDiary: { value: 'digitalDiary', name: 'Nauczyciel - zajęcia dodatkowe' },
  teacherSupport: { value: 'teacherSupport', name: 'Pomoc nauczyciela' },
  specialistTeacher: { value: 'specialistTeacher', name: 'Specjalista' }
});

export default staffMemberRoles;
