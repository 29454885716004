export function isCalendarExtraSize() {
  return document.body.clientWidth >= 1333;
}

export function isTablet(size) {
  return size <= 768;
}

export function isSmall(size) {
  return size <= 640;
}

export function isVerySmall(size) {
  return size <= 256;
}

export function isKindaSmall(size) {
  return size <= 0;
}
