import React from 'react';
import UserPasswordTemplate, {
  validate as validateTemplate,
  InitialValues as InitialValuesTemplate,
  VALIDATION_SCHEMA as VALIDATION_SCHEMA_TEMPLATE
} from '../templates/UserPasswordTemplate';
import * as Yup from 'yup';
import FieldsetWrapper from '../../components/forms/FieldsetWrapper';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import { Link } from 'react-router';
import { ROOT_URL } from '../../api/ApiBase';
import * as PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';

const AccountActivationForm = ({ handleAgreementOpen, isCheckbox }) => {
  return (
    <>
      <UserPasswordTemplate />
      {isCheckbox && (
        <FieldsetWrapper legend="Potwierdzenie regulaminu">
          <FormikCheckbox
            name="checkbox"
            label={
              <Typography>
                Zapoznałem się z{' '}
                <Typography
                  sx={{ display: 'block', textDecoration: 'underline', color: (theme) => theme.palette.color.color5 }}
                  onClick={handleAgreementOpen}>
                  {'regulaminem'}
                </Typography>
              </Typography>
            }
          />
        </FieldsetWrapper>
      )}
    </>
  );
};

AccountActivationForm.propTypes = {
  handleAgreementOpen: PropTypes.func,
  isCheckbox: PropTypes.bool
};

export class InitialValues extends InitialValuesTemplate {
  constructor() {
    super();
    this.checkbox = false;
    this.showCheckbox = true;
  }

  deleteCheckbox() {
    delete this.checkbox;
    this.showCheckbox = false;
  }
}

export const VALIDATION_SCHEMA = Yup.object({
  ...VALIDATION_SCHEMA_TEMPLATE,
  showCheckbox: Yup.boolean(),
  checkbox: Yup.boolean().when('showCheckbox', {
    is: true,
    then: Yup.boolean().oneOf([true], 'Pole musi być zaznaczone')
  })
});

export const validate = ({ password }) => {
  return validateTemplate({ password });
};

export default AccountActivationForm;
