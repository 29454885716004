import PropTypes from 'prop-types';
import React from 'react';
import PersonAvatar from '../forms/PersonAvatar';
import DaycareReadonlyDetails from './DaycareReadonlyDetails';
import { Paper, Button, Box, Typography, useMediaQuery } from '@mui/material';

const ChildDaycareCard = ({ daycareEntry, onEdit }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Paper
      onClick={() => onEdit()}
      sx={{
        pr: 3,
        pl: 3,
        pt: 4,
        pb: 2,
        cursor: 'pointer',
        '&:hover': {
          background: (theme) => theme.palette.background.hover
        }
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        {!isMobile && (
          <PersonAvatar
            initials
            alt={daycareEntry.firstName}
            url={daycareEntry.photoUrl}
            firstName={daycareEntry.firstName}
            lastName={daycareEntry.lastName}
            size={50}
            sx={{ flexShrink: 0, mr: 1, mb: 0.5 }}
          />
        )}
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
            fontWeight: (theme) => theme.typography.fontWeightBold
          }}>
          {daycareEntry.firstName} {daycareEntry.lastName}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 1
        }}>
        <DaycareReadonlyDetails daycareEntry={daycareEntry} />
        <Button
          fullWidth
          sx={{
            fontSize: 16,
            fontWeight: (theme) => theme.typography.fontWeightBold,
            textTransform: 'none',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
          aria-label="Kliknij, aby dodać więcej">
          Kliknij, aby dodać więcej
        </Button>
      </Box>
    </Paper>
  );
};

ChildDaycareCard.propTypes = {
  daycareEntry: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default ChildDaycareCard;
