import React, { useEffect, useMemo, useState } from 'react';

import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import { SearchBar } from '../common/SearchBar';
import { useDispatch, useSelector } from 'react-redux';

import {
  reportIndividualAbsence,
  reportIndividualAttendance
} from '../../actions/psychologicalIndividualAttendanceActions';
import { browserHistory } from 'react-router';
import { fromTemplate, routePaths } from '../../routePaths';
import IndividualAttendanceSheetList, { actionTypes } from './IndividualAtendanceSheetList';
import moment from 'moment/moment';

const IndividualClassesContainer = () => {
  const pupils = useSelector((state) => state.children);
  const children = useSelector((state) => state.psychologicalChildrenList);
  // const Ui = useSelector((state) => state.childDetailsUi.individualAttendanceUi);
  const attendances = useSelector((state) => state.psychologicalIndividualAttendance.childrenAttendance);

  const [searchValue, setSearchValue] = useState('');
  const [attendantChildren, setAttendantChildren] = useState([]);
  const [absenceChildren, setAbsenceChildren] = useState([]);
  const [unregisteredChildren, setUnregisteredChildren] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const setVariable = async () => {
      setUnregisteredChildren([]);
      setAbsenceChildren([]);
      setAttendantChildren([]);
      if (children.length > 0 && Object.keys(attendances).length > 0) {
        attendances.map((childAttendance) => {
          return !childAttendance.droppedOffAt && !childAttendance.absenceFrom
            ? setUnregisteredChildren((prev) => [
                ...prev,
                pupils.find((child) => child.id === childAttendance.assistance.childId)
              ])
            : null;
        });
      }
      if (children.length > 0 && Object.keys(attendances).length > 0) {
        attendances.map((childAttendance) =>
          childAttendance.droppedOffAt
            ? setAttendantChildren((prev) => [
                ...prev,
                pupils.find((child) => child.id === childAttendance.assistance.childId)
              ])
            : null
        );
      }
      if (children.length > 0 && Object.keys(attendances).length > 0) {
        attendances.map((childAttendance) =>
          childAttendance.absenceFrom
            ? setAbsenceChildren((prev) => [
                ...prev,
                pupils.find((child) => child.id === childAttendance.assistance.childId)
              ])
            : null
        );
      }
    };
    setVariable();
  }, [children, attendances, pupils]);

  const handleCustomRedirect = (entry) => {
    const helpId = children.find((help) => help.childId === entry.id).id;
    browserHistory.push(
      fromTemplate(routePaths.individualClassesDetails, [helpId, entry.id, moment().year(), moment().month() + 1])
    );
  };

  const handleReportAttendance = (child) => {
    dispatch(reportIndividualAttendance(child.id));
  };
  const handleReportAbsence = (child) => {
    dispatch(reportIndividualAbsence(child.id));
  };
  return (
    <>
      <PageHeaderText
        title="Dziennik zajęć indywidualnych"
        titleIcon={<PermContactCalendarIcon />}
        additionalInfo={moment().format('DD.MM.YYYY')}
      />
      <ContentWrapper>
        <SearchBar label="Wyszukaj" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
        <IndividualAttendanceSheetList
          searchText={searchValue}
          entries={unregisteredChildren}
          onReportAttendance={handleReportAttendance}
          onReportAbsence={handleReportAbsence}
          onCustomRedirect={handleCustomRedirect}
        />
        <IndividualAttendanceSheetList
          searchText={searchValue}
          title="Obecne"
          entries={attendantChildren}
          onReportAttendance={handleReportAttendance}
          onReportAbsence={handleReportAbsence}
          onCustomRedirect={handleCustomRedirect}
          actionTypes={actionTypes}
          actionType={actionTypes.attendant}
        />
        <IndividualAttendanceSheetList
          searchText={searchValue}
          title="Nieobecne"
          entries={absenceChildren}
          onReportAttendance={handleReportAttendance}
          onReportAbsence={handleReportAbsence}
          onCustomRedirect={handleCustomRedirect}
          actionTypes={actionTypes}
          actionType={actionTypes.absence}
        />
        {/*{!!childDetails && (*/}
        {/*  <PsychologicalAttendanceDialog*/}
        {/*    Ui={Ui}*/}
        {/*    childDetails={childDetails}*/}
        {/*    pupils={pupils}*/}
        {/*    journalDate={moment()}*/}
        {/*    attendanceDetails={attendances.entries.find(*/}
        {/*      (day) => new Date(day.date).toDateString() === new Date().toDateString()*/}
        {/*    )}*/}
        {/*  />*/}
        {/*)}*/}
      </ContentWrapper>
    </>
  );
};

export default IndividualClassesContainer;
