/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function dailySchedulesUiReducer(state = initialState.dailySchedulesUi, action) {
  switch (action.type) {
    case types.CREATE_DAILY_SCHEDULE: {
      return Object.assign({}, state, { schedule: UiModel.start() });
    }
    case types.CREATE_DAILY_SCHEDULE_SUCCESS: {
      return Object.assign({}, state, { schedule: UiModel.success() });
    }
    case types.CREATE_DAILY_SCHEDULE_FAILURE: {
      return Object.assign({}, state, { schedule: UiModel.success() });
    }
    case types.ADD_DAILY_SCHEDULE_ITEM: {
      return Object.assign({}, state, { items: UiModel.start() });
    }
    case types.ADD_DAILY_SCHEDULE_ITEM_SUCCESS: {
      return Object.assign({}, state, { items: UiModel.success() });
    }
    case types.ADD_DAILY_SCHEDULE_ITEM_FAILURE: {
      return Object.assign({}, state, { items: UiModel.failure(action.errors) });
    }
    case types.UPDATE_DAILY_SCHEDULE_ITEM: {
      return Object.assign({}, state, { items: UiModel.start() });
    }
    case types.UPDATE_DAILY_SCHEDULE_ITEM_SUCCESS: {
      return Object.assign({}, state, { items: UiModel.success() });
    }
    case types.UPDATE_DAILY_SCHEDULE_ITEM_FAILURE: {
      return Object.assign({}, state, { items: UiModel.failure(action.errors) });
    }
    default:
      return state;
  }
}
