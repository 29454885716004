import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  postsModifyingEnabledAsync,
  updateTwoWayMessagingSettingAsync,
  teacherGropuAccessAsync
} from '../../../actions/unitActions';
import BusinessIcon from '@mui/icons-material/Business';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import Strong from '../../common/Strong';
import UnitListItem from './UnitListItem';
import ContentWrapper from '../../common/contentWrapper';

const ReducedUnitPage = () => {
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.configuration.unit);
  const [twoWayMessagingUpdateInProgress, setTwoWayMessagingUpdateInProgress] = useState(false);
  const [postsModifyingEnabledInProgress, setPostsModifyingEnabledInProgress] = useState(false);
  const [teacherAllGroupVisibilityInProgress, setTeacherAllGroupVisibilityInProgress] = useState(false);

  const handlerEnableTwoWayMessaging = () => {
    setTwoWayMessagingUpdateInProgress(true);
    dispatch(updateTwoWayMessagingSettingAsync(true)).finally(() => setTwoWayMessagingUpdateInProgress(false));
  };

  const handlerDisableTwoWayMessaging = () => {
    setTwoWayMessagingUpdateInProgress(true);
    dispatch(updateTwoWayMessagingSettingAsync(false)).finally(() => setTwoWayMessagingUpdateInProgress(false));
  };

  const handleEnablePostsModifying = () => {
    setPostsModifyingEnabledInProgress(true);
    dispatch(postsModifyingEnabledAsync(false)).finally(() => setPostsModifyingEnabledInProgress(false));
  };

  const handleDisablePostsModifying = () => {
    setPostsModifyingEnabledInProgress(true);
    dispatch(postsModifyingEnabledAsync(true)).finally(() => setPostsModifyingEnabledInProgress(false));
  };

  const handleEnableAllGroupsAccess = () => {
    setTeacherAllGroupVisibilityInProgress(true);
    dispatch(teacherGropuAccessAsync(true)).finally(() => setTeacherAllGroupVisibilityInProgress(false));
  };

  const handleDisableAllGroupsAccess = () => {
    setTeacherAllGroupVisibilityInProgress(true);
    dispatch(teacherGropuAccessAsync(false)).finally(() => setTeacherAllGroupVisibilityInProgress(false));
  };

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title="Ustawienia placówki" titleIcon={<BusinessIcon />} />
        <ContentWrapper>
          <Paper sx={{ p: 2 }}>
            <UnitListItem>
              <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Komunikacja dwukierunkowa
              </Typography>
              {unit.twoWayMessagingEnabled ? (
                <Typography component="div">
                  Możliwość wysyłania wiadomości bezpośrednich przez opiekunów prawnych jest <Strong>włączona</Strong>.
                </Typography>
              ) : (
                <Typography component="div">
                  Możliwość wysyłania wiadomości bezpośrednich przez opiekunów prawnych jest <Strong>wyłączona</Strong>.
                </Typography>
              )}
              {unit.twoWayMessagingEnabled ? (
                <Button
                  variant="contained"
                  aria-label="Wyłącz"
                  sx={{ mt: 1 }}
                  disabled={twoWayMessagingUpdateInProgress}
                  onClick={handlerDisableTwoWayMessaging}>
                  wyłącz
                </Button>
              ) : (
                <Button
                  variant="contained"
                  aria-label="Włącz"
                  sx={{ mt: 1 }}
                  disabled={twoWayMessagingUpdateInProgress}
                  onClick={handlerEnableTwoWayMessaging}>
                  włącz
                </Button>
              )}
            </UnitListItem>
            <Divider sx={{ my: 2 }} />
            <UnitListItem>
              <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Możliwość edytowania postów przez każdego pracownika
              </Typography>
              <Typography component="div">
                Możliwość edytowania postów innych autorów przez każdego pracownika placówki jest&nbsp;
                <Strong>{unit.postsModifyingEnabled ? 'włączona' : 'wyłączona'}</Strong>.
              </Typography>
              <Button
                variant="contained"
                aria-label={unit.postsModifyingEnabled ? 'Wyłącz' : 'Włącz'}
                sx={{ mt: 1 }}
                disabled={postsModifyingEnabledInProgress}
                onClick={unit.postsModifyingEnabled ? handleEnablePostsModifying : handleDisablePostsModifying}>
                {unit.postsModifyingEnabled ? 'Wyłącz' : 'Włącz'}
              </Button>
            </UnitListItem>
            <Divider sx={{ my: 2 }} />
            <UnitListItem>
              <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                Pozwól nauczycielom na dostęp do wszystkich grup
              </Typography>
              <Typography component="div">
                Dostępy do wszystkich grup są &nbsp;
                <Strong>{unit.teacherAllGroupVisibility ? 'włączone' : 'wyłączone'}</Strong>.
              </Typography>
              <Button
                variant="contained"
                aria-label={unit.teacherAllGroupVisibility ? 'Wyłącz' : 'Włącz'}
                sx={{ mt: 1 }}
                disabled={teacherAllGroupVisibilityInProgress}
                onClick={unit.teacherAllGroupVisibility ? handleDisableAllGroupsAccess : handleEnableAllGroupsAccess}>
                {unit.teacherAllGroupVisibility ? 'Wyłącz' : 'Włącz'}
              </Button>
            </UnitListItem>
          </Paper>
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};

export default ReducedUnitPage;
