// @flow
import { Grid, MenuItem, Typography } from '@mui/material';
import React from 'react';
import * as Yup from 'yup';
import BaseModel from '../../models/BaseModel';
import PropTypes from 'prop-types';
import { prepareData } from '../../components/gallery/Containers/Albums/Albums';
import SingleAlbum from '../../components/gallery/Components/Albums/SingleAlbum/SingleAlbum';
import FormikSelectField from '../../components/formik/FormikSelectField';
import { useFormikContext } from 'formik';

const GalleryMoveFilesForm = ({ albums }) => {
  const name = 'album';

  const { setFieldValue } = useFormikContext();

  const handleOnChange = (e) => {
    setFieldValue(name, e.target.value);
  };

  return (
    <>
      <Grid container sx={{ p: 2 }} justifyContent="center">
        <FormikSelectField contrast name={name} label="Wybierz album*" onChange={handleOnChange} displayEmpty>
          {albums.map((item) => (
            <MenuItem value={item} key={item.id}>
              {/* <SingleAlbum contrast {...item} {...prepareData(item)} isOnList isTiny /> */}
              <Typography>{item.name}</Typography>
            </MenuItem>
          ))}
        </FormikSelectField>
      </Grid>
    </>
  );
};

GalleryMoveFilesForm.propTypes = {
  albums: PropTypes.array.isRequired
};

export default GalleryMoveFilesForm;

export class InitialValues extends BaseModel {
  constructor() {
    super();
    this.album = '';
  }
}

export const VALIDATION_SCHEMA = Yup.object().shape({});

export const validate = ({ album }) => {
  const errors = {};
  if (Object.keys(album).length === 0 && album.constructor !== Object) {
    errors.album = 'Musisz wybrać conajmniej jeden album';
  }
  return errors;
};
