import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addIpet, editIpet } from '../../../../actions/psychologicalActions';
import DialogForm from '../../../forms/DialogForm';
import IpetForm from './IpetForm';
import { IPETValidator } from './IPETValidator';

const IpetDialog = ({ handleCancel, title, id, selectedIPET, isReadonly }) => {
  const [data, setData] = useState();
  const [errors, setErrors] = useState();
  const dispatch = useDispatch();
  const Ui = useSelector((state) => state.psychologicalHelpUi.ipet);

  const save = () => {
    if (!!errors || (errors?.errors && Object.values(errors?.errors).length)) {
      selectedIPET ? dispatch(editIpet(data, selectedIPET?.id, id)) : dispatch(addIpet(data, id));
    }
  };
  return (
    <DialogForm
      isEditing={Ui.isEditing}
      isProcessing={Ui.isProcessing}
      hideSubmit={isReadonly}
      header={title}
      maxWidth="md"
      onSave={save}
      onCancel={handleCancel}
      isDialogOpen={Ui.isEditing}
      onValidate={() => new IPETValidator().validate(data)}
      onValidationDone={(errors) => setErrors({ errors })}
      statePathToUi=""
      requiredFieldText>
      <IpetForm setData={setData} selectedIPET={selectedIPET} isReadonly={isReadonly} errors={errors} />
    </DialogForm>
  );
};

export default IpetDialog;
