import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MessageBoardFilterModel } from '../../models/messageBoardFilter/MessageBoardFilterModels';
import { BoardPostModel } from '../../models/messageBoard/MessageBoardModels';
import * as messageBoardActions from '../../actions/messageBoardActions';
import * as messageBoardFilterActions from '../../actions/messageBoardFilterActions';
import { routePathActions } from '../../routePathActions';
import moment from 'moment';
import { InputFieldStyles } from '../../constants/ComponentStyles/inputFieldStyles';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import userRoles from '../../constants/userRoles';
import FilterDialog from './dialog/FilterDialog';
import { Button, Box, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import EventNote from '@mui/icons-material/FeedbackOutlined';
import BoardPostsList from './BoardPostsList';
import ManualLink from '../ManualLink';
import PostEditor from './dialog/BoardPostEditDialog';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import { SearchBar } from '../common/SearchBar';
import ContentWrapper from '../common/contentWrapper';
import * as childrenActions from '../../actions/childrenActions';

class MessageBoardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPost: new BoardPostModel(),
      filters: new MessageBoardFilterModel(),
      filtered: false
    };
    this.handlePostSave = this.handlePostSave.bind(this);
    this.handlePostEdit = this.handlePostEdit.bind(this);
    this.handlePostCancel = this.handlePostCancel.bind(this);
    this.renderEditDialog = this.renderEditDialog.bind(this);
    this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this);
    this.handlePostDelete = this.handlePostDelete.bind(this);
    this.handlePostFilterSave = this.handlePostFilterSave.bind(this);
    // this.handleSaveFilter = this.handleSaveFilter.bind(this);
    this.handleFilterPostCancel = this.handleFilterPostCancel.bind(this);
    this.handlePostFilterStart = this.handlePostFilterStart.bind(this);
    this.getGroups = this.getGroups.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);
    this.handleTextSearch = this.handleTextSearch.bind(this);
    this.renderAddPostButton = this.renderAddPostButton.bind(this);
    this.handleTextSearchChange = this.handleTextSearchChange.bind(this);
    this.sortPosts = this.sortPosts.bind(this);
    this.handleSearchByEnter = this.handleSearchByEnter.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.userRole === userRoles.legalGuardian)
      this.props.childrenActions.loadLegalGuardiansChildrenAsync();
  }

  handleLoadMoreClick() {
    this.loadPosts(this.props.board.limit, this.props.board.offset);
  }

  handlePostFilterStart() {
    this.props.filterActions.filterPostStart();
  }

  handlePostFilterSave(filters) {
    this.setState({
      filtered: true,
      filters: new MessageBoardFilterModel().assign(filters)
    });
    const data = {
      filters: {
        ...filters,
        authors: filters.authors.length === this.props.staffMembers.length ? [] : filters.authors,
        groups: filters.groups.length === this.getGroups().length ? [] : filters.groups,
        activities: filters.activities.length === this.props.activities.length ? [] : filters.activities
      },
      authors: this.props.staffMembers.map((member) => member.id),
      groups: this.getGroups().map((group) => group.id),
      activities: this.props.activities.filter((activ) => activ.id)
    };
    this.props.actions.loadFilteredPostsByRoleAsync(this.props.board.limit, 0, data);
  }

  handleClearFilters() {
    this.setState({
      filtered: false,
      filters: new MessageBoardFilterModel()
    });
    this.props.actions.loadPostsByRoleAsync(10, 0);
  }

  handleTextSearchChange(e) {
    e.persist();
    this.setState((prevState) => {
      return {
        filters: new MessageBoardFilterModel().assign({ ...prevState.filters, textFilter: e.target.value })
      };
    });
  }

  handleTextSearch() {
    this.handlePostFilterSave(this.state.filters);
  }

  handleSearchByEnter(event) {
    if (event.keyCode === 13) {
      this.handleTextSearch();
    }
  }

  handlePostDelete(post) {
    this.setState({
      currentPost: null
    });
    this.props.actions.deletePostAsync(post.id);
  }

  handlePostEdit(post) {
    this.setState({ currentPost: new BoardPostModel().assign(post) });
    if (post) {
      this.props.actions.updatePostStart();
    } else {
      this.props.actions.createPostStart();
    }
  }

  handlePostSave(post) {
    if (post.id) {
      this.props.actions.updatePostAsync(post);
    } else {
      this.props.actions.createPostAsync(
        new BoardPostModel().assign({
          ...post,
          author: { id: this.props.auth.userId, fullName: this.props.auth.userFullName }
        })
      );
    }
  }

  handlePostCancel(post) {
    this.setState({ currentPost: new BoardPostModel() });
    if (post.id) {
      this.props.actions.updatePostCancel();
    } else {
      this.props.actions.createPostCancel();
    }
  }

  handleFilterPostCancel() {
    this.props.filterActions.filterPostCancel();
  }

  getGroups() {
    const user = this.props.staffMembers.find((obj) => obj.id === this.props.auth.userId);
    if (this.props.auth.userRole === userRoles.legalGuardian) {
      return this.props.children
        ? this.props.groups.filter(({ id }) =>
            this.props.children.some(({ enrolledToGroup }) => enrolledToGroup && enrolledToGroup.id === id)
          )
        : [];
    }
    if (user) {
      const groups =
        user.role === 'teacher'
          ? this.props.groups.filter((group) => {
              return user.groups.find((userGroup) => userGroup.id === group.id);
            })
          : this.props.groups;
      return groups;
    }
    return [];
  }

  sortPosts() {
    return [...this.props.board.posts].sort((a, b) => {
      const a1 = moment({ hour: a.eventStartTime.hourLocal, minute: a.eventStartTime.minuteLocal });
      const a2 = moment({ hour: b.eventStartTime.hourLocal, minute: b.eventStartTime.minuteLocal });
      return a1.isBefore(a2) ? -1 : 1;
    });
  }

  loadPosts(limit, offset) {
    if (this.state.filtered) {
      if (this.props.isAllowed(routePathActions.messageBoard.viewAllPosts)) {
        this.props.actions.loadNextFilteredPostsAsync(limit, offset, this.state.filters);
      } else {
        this.props.actions.loadNextFilteredPostsToMeAsync(limit, offset, this.state.filters);
      }
    } else if (this.props.isAllowed(routePathActions.messageBoard.viewAllPosts)) {
      this.props.actions.loadNextPostsAsync(limit, offset);
    } else {
      this.props.actions.loadNextPostsToMeAsync(limit, offset);
    }
  }

  renderEditDialog() {
    return this.props.ui.isEditing ? (
      <PostEditor
        post={this.state.currentPost}
        type={this.props.isEventBoard ? 'event' : 'post'}
        groups={this.props.groups}
        activities={this.props.activities}
        onSave={(post) => this.handlePostSave(post)}
        onCancel={(post) => this.handlePostCancel(post)}
        isEditing={this.props.ui.isEditing}
        isProcessing={this.props.ui.isProcessing}
        canSetDate={this.props.isEventBoard}
        canClearDate={false}
      />
    ) : null;
  }

  renderFilterDialog() {
    const allowedRoles = [
      userRoles.staffMemberAccountant,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSecretary
    ];
    return this.props.filterUi.isEditing ? (
      <FilterDialog
        auth={this.props.auth}
        filters={this.state.filters}
        groups={this.getGroups()}
        activities={this.props.activities}
        staffMembers={this.props.staffMembers}
        onSave={(filters) => this.handlePostFilterSave(filters)}
        onCancel={(post) => this.handleFilterPostCancel(post)}
        isEditing={this.props.filterUi.isEditing}
        isProcessing={this.props.filterUi.isProcessing}
        canSetDate
        canChooseActivities={allowedRoles.includes(this.props.auth.userRole)}
        canChooseAuthors={allowedRoles.includes(this.props.auth.userRole)}
      />
    ) : null;
  }

  renderAddPostButton() {
    if (this.props.isAllowed(routePathActions.messageBoard.createPost)) {
      return (
        <Grid container>
          {!this.props.isEventBoard ? (
            <Grid
              item
              xs={12}
              sm={12}
              lg={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                pb: 2
              }}>
              <SearchBar
                label="Znajdź ogłoszenie"
                SearchOnClick={this.handleTextSearch}
                value={this.state.filters.textFilter}
                onChange={this.handleTextSearchChange}
                handleTextSearch={this.handleTextSearch}
                onKeyDown={this.handleSearchByEnter}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={12} lg={6} sx={{ textAlign: 'right' }}>
            {this.state.filtered ? (
              <Button
                label="Wyczyść filtry"
                variant="outlined"
                onClick={this.handleClearFilters}
                aria-label="Wyczyść filtry">
                Wyczyść filtry
              </Button>
            ) : null}
            {!this.props.isEventBoard ? (
              <Button
                label="filtruj"
                variant="outlined"
                onClick={() => this.handlePostFilterStart(null)}
                aria-label="filtruj">
                filtruj
              </Button>
            ) : null}
            <Button
              label="Nowy wpis"
              variant="contained"
              aria-label="Nowy wpis"
              onClick={() => this.handlePostEdit(null)}>
              Nowy wpis
            </Button>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            pb: 2
          }}>
          <SearchBar
            label="Znajdź ogłoszenie"
            SearchOnClick={this.handleTextSearch}
            value={this.state.filters.textFilter}
            onChange={this.handleTextSearchChange}
            handleTextSearch={this.handleTextSearch}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} sx={{ textAlign: 'right' }}>
          {this.state.filtered && !this.props.isEventBoard ? (
            <Button
              label="Wyczyść filtry"
              variant="outlined"
              onClick={this.handleClearFilters}
              aria-label="Wyczyść filtry">
              Wyczyść filtry
            </Button>
          ) : null}
          {!this.props.isEventBoard ? (
            <Button
              label="filtruj"
              variant="contained"
              onClick={() => this.handlePostFilterStart(null)}
              aria-label="filtruj">
              filtruj
            </Button>
          ) : null}
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <Box flexDirection="row" display="flex" alignItems="center">
            <PageHeaderText title={this.props.isEventBoard ? ' Wydarzenia' : ' Ogłoszenia'} titleIcon={<EventNote />} />
            {!this.props.nadarzyn && this.props.auth.userRole === userRoles.legalGuardian && <ManualLink index="3" />}
          </Box>

          <ContentWrapper>
            <Box flexDirection="column" sx={{ pb: 3 }}>
              {this.renderAddPostButton()}
            </Box>
            {this.renderEditDialog()}
            {this.renderFilterDialog()}
            <BoardPostsList
              posts={this.props.isEventBoard ? this.sortPosts() : this.props.board.posts}
              pageCount={this.props.board.pageCount}
              hasNextPage={this.props.board.hasNextPage}
              canEdit={this.props.isAllowed(routePathActions.messageBoard.editPost)}
              onEdit={(post) => this.handlePostEdit(post)}
              onDelete={(post) => this.handlePostDelete(post)}
              onLoadMoreClick={this.handleLoadMoreClick}
              dontDisplayHistory={this.props.isEventBoard}
              isStaffMemberBoard={false}
            />
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

MessageBoardPage.propTypes = {
  board: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  filterUi: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  isAllowed: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  isEventBoard: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  staffMembers: PropTypes.array.isRequired,
  classes: PropTypes.object,
  childrenActions: PropTypes.object.isRequired,
  children: PropTypes.array,
  nadarzyn: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
  const isEventBoard = !!ownProps.params.year;
  return {
    board: isEventBoard ? state.messageBoardEvents : state.messageBoardPosts,
    ui: state.messageBoardUi,
    filterUi: state.messageBoardFilterUi,
    groups: state.groups,
    activities: state.activities,
    isAllowed: state.auth.isAllowed.bind(state.auth),
    isEventBoard,
    filters: state.messageBoardFilter,
    auth: state.auth,
    staffMembers: state.staffMembers,
    children: state.legalGuardiansChildren,
    nadarzyn: state.configuration.unit.features.nadarzyn
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(messageBoardActions, dispatch),
    filterActions: bindActionCreators(messageBoardFilterActions, dispatch),
    childrenActions: bindActionCreators(childrenActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(InputFieldStyles)(MessageBoardPage));
