import BaseModel from '../BaseModel';

export class ChildMealsCalendarModel extends BaseModel {
  constructor() {
    super();
    this.childId = '';
    this.month = '';
    this.calendar = [];
  }
}
