import PropTypes from 'prop-types';
import React from 'react';
import ValidationSummary, { validationTypes } from '../../common/validation/ValidationSummary';
import exceededAbsenceReportDeadline from '../../../utils/exceededAbsenceReportDeadline';

const ExceededAbsenceReportDeadlineWarning = ({ schemes, absenceFrom, absenceReportedAt, force }) => {
  const violationDetected = exceededAbsenceReportDeadline(schemes, absenceFrom, absenceReportedAt, force);

  if (violationDetected) {
    const message =
      'Zgłoszenie nieobecności nastąpiło po godzinie określonej w regulaminie. Opłata zostanie naliczona.';
    return <ValidationSummary title="Uwaga" messages={[message]} type={validationTypes.warning} />;
  }

  return null;
};

ExceededAbsenceReportDeadlineWarning.propTypes = {
  schemes: PropTypes.array.isRequired,
  absenceFrom: PropTypes.object,
  absenceReportedAt: PropTypes.object,
  force: PropTypes.bool
};

export default ExceededAbsenceReportDeadlineWarning;
