import PropTypes from 'prop-types';
import React from 'react';
import SelectField from '../../common/SelectField';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { Box, MenuItem } from '@mui/material';
import { ReportTypes } from '../../../constants/reportTypes';
import GroupPickerTemplate from './common/GroupPickerTemplate';

const ReliefsByChildrenReport = ({ groupId, groups, reliefId, reliefs, onChange, errors }) => {
  return (
    <Box>
      <GroupPickerTemplate
        errorText={errors.groupId}
        groupId={groupId}
        groups={groups}
        onChange={onChange}
        legend={ReportTypes.reliefsByChildren.name}
        sx={{ pb: 2 }}
      />
      <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.reliefsByChildren.name}`}>
        <SelectField
          contrast
          sx={{ width: 'auto', overflow: 'hidden' }}
          floatingLabelText="Ulga"
          id="reliefId"
          value={reliefId}
          onChange={(event) => {
            onChange({ target: { name: 'reliefId', value: event.target.value } });
          }}>
          {reliefs.map((relief) => {
            return (
              <MenuItem key={relief.id} value={relief.id}>
                {relief.name}
              </MenuItem>
            );
          })}
        </SelectField>
      </FieldsetWrapper>
    </Box>
  );
};

ReliefsByChildrenReport.propTypes = {
  groupId: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  reliefId: PropTypes.string.isRequired,
  reliefs: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ReliefsByChildrenReport;
