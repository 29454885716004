import React from 'react';
import PropTypes from 'prop-types';
import ResetPasswordForm, {
  InitialValues,
  validate,
  VALIDATION_SCHEMA
} from '../../../../../forms/userAccount/ResetPasswordForm';
import FormikContentWrapper from '../../../../formik/FormikContentWrapper';
import { Box } from '@mui/material';

const ResetPasswordFormWrap = ({ onSubmit }) => {
  return (
    <FormikContentWrapper
      initialValues={new InitialValues()}
      validationSchema={VALIDATION_SCHEMA}
      validate={validate}
      onSubmit={onSubmit}
      isButton
      buttonLabel="Zapisz nowe hasło"
      isRequiredText>
      {() => (
        <Box sx={{ py: 1, px: 0 }}>
          <ResetPasswordForm />
        </Box>
      )}
    </FormikContentWrapper>
  );
};

ResetPasswordFormWrap.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default ResetPasswordFormWrap;
