import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as staffMembersActions from '../../actions/staffMembersActions';
import StaffMemberDetailsCard from './details/StaffMemberDetailsCard';
import { browserHistory } from 'react-router';
import { routePaths } from '../../routePaths';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { StaffMemberDetailsModel } from '../../models/staffMembers/StaffMemberModels';
import {
  StaffMemberModelValidator,
  StaffMemberModelWithPersonalIdValidator
} from '../../models/staffMembers/StaffMemberModelsValidator';

class StaffMemberRegisterPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      member: new StaffMemberDetailsModel()
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  componentDidMount() {
    this.handleEdit();
  }

  handleChange(event) {
    const field = event.target.name;
    const member = Object.assign({}, this.state.member);
    _.set(member, field, event.target.value);
    return this.setState({ member });
  }

  handleSave() {
    this.props.actions.createStaffMemberAsync(this.state.member);
  }

  handleCancel() {
    this.props.actions.createStaffMemberCancel();
    browserHistory.push(routePaths.staffMembers);
  }

  handleEdit() {
    this.props.actions.createStaffMemberStart();
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <StaffMemberDetailsCard
          title="Rejestracja pracownika"
          member={this.state.member}
          onChange={this.handleChange}
          onSave={this.handleSave}
          onCancel={this.handleCancel}
          onEdit={this.handleEdit}
          onValidate={
            this.props.nadarzyn
              ? () => new StaffMemberModelWithPersonalIdValidator().validate(this.state.member)
              : () => new StaffMemberModelValidator().validate(this.state.member)
          }
          isEditing={this.props.ui.isEditing}
          isProcessing={this.props.ui.isProcessing}
        />
      </LoadingRenderWrapper>
    );
  }
}

StaffMemberRegisterPage.propTypes = {
  actions: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  nadarzyn: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    ui: state.staffMemberDetailsUi.details,
    nadarzyn: state.configuration.unit.features.nadarzyn
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(staffMembersActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffMemberRegisterPage);
