import ApiBase, { ROOT_URL } from './ApiBase';

class ReliefsApi extends ApiBase {
  static getReliefs() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/configuration/reliefs`).type('application/json'));
  }

  static createRelief(relief) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/configuration/reliefs`).send(relief).type('application/json')
    );
  }

  static archiveRelief(reliefId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/configuration/reliefs/${reliefId}`).type('application/json')
    );
  }

  static getExpiringReliefs() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/reliefs/expiring`).type('application/json'));
  }
}

export default ReliefsApi;
