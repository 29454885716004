import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  List,
  ListItemAvatar,
  Dialog,
  DialogActions,
  DialogTitle,
  ListItem,
  Button,
  ListItemButton,
  ListItemText,
  DialogContent
} from '@mui/material';
import ActivityAvatar from './ActivityAvatar';

const ActivityModal = (props) => {
  const { onSelectActivity, isDialogOpen, changeDialogState } = props;
  const activities = useSelector((state) => state.activities);

  return (
    <Dialog open={isDialogOpen} maxWidth="sm" fullWidth={true} onClose={() => changeDialogState()}>
      <DialogTitle>Wybierz aktywność</DialogTitle>
      <DialogContent>
        <List>
          {activities.map((activity) => (
            <ListItem key={activity.id} sx={{ p: 0, m: 0 }}>
              <ListItemButton onClick={() => onSelectActivity(activity)}>
                <ListItemAvatar>
                  <ActivityAvatar activity={activity} />
                </ListItemAvatar>
                <ListItemText
                  primary={activity.name}
                  primaryTypographyProps={{
                    fontWeight: (theme) => theme.typography.fontWeightBold
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" aria-label="Anuluj" onClick={changeDialogState}>
          Anuluj
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ActivityModal.propTypes = {
  onSelectActivity: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  changeDialogState: PropTypes.func
};

export default ActivityModal;
