/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function eventsReducer(state = initialState.events, action) {
  switch (action.type) {
    case types.LOAD_EVENTS_SUCCESS: {
      return { eventsList: [...action.events.data], rowNumber: parseInt(action.events.rowNumber, 10) };
    }
    default:
      return state;
  }
}
