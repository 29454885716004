'use strict';

import localStorageProvider from '../utils/localStorageProvider';
import { AuthModel } from '../models/auth/AuthModel';
import { getAllowedActions } from '../middleware/auth/authentication';
import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_PASSWORD_CHANGE_REQUIRED,
  SIGN_IN_FAILURE_INVALID_CREDENTIALS,
  SIGNED_OUT,
  CHANGE_PASSWORD_SUCCESS,
  LOAD_USER_STATE_SUCCESS,
  UPDATE_USER_PROFILE_PHOTO_SUCCESS,
  NAVIGATE_REQUEST,
  REMOVE_USER_PROFILE_PHOTO_SUCCESS,
  SIGN_IN_FAILURE_LOGIN_GOV,
  SIGN_IN_LOGIN_GOV_FAILURE_INVALID_CREDENTIALS,
  SIGN_IN_GOV_SUCCESS
} from '../actions/actionTypes';

export default function authReducer(state = new AuthModel(localStorageProvider.getItem('authToken')), action) {
  switch (action.type) {
    case SIGN_IN:
      return Object.assign({}, new AuthModel(), { isProcessing: true });
    case CHANGE_PASSWORD_SUCCESS:
    case SIGN_IN_SUCCESS:
      return new AuthModel(action.token);
    case SIGN_IN_PASSWORD_CHANGE_REQUIRED:
    case SIGN_IN_FAILURE:
      return new AuthModel(null);
    case SIGN_IN_FAILURE_INVALID_CREDENTIALS: {
      return Object.assign({}, state, {
        lastAuthErrorMessage: 'Podano nieprawidłowy adres email lub hasło',
        isProcessing: false
      });
    }
    case SIGN_IN_LOGIN_GOV_FAILURE_INVALID_CREDENTIALS: {
      return Object.assign({}, state, {
        lastAuthErrorMessage: 'Twoje konto w serwisie nie jest powiązane z węzłem krajowym',
        isProcessing: false
      });
    }
    case SIGN_IN_FAILURE_LOGIN_GOV: {
      return Object.assign({}, state, {
        lastAuthErrorMessage: 'Logowanie za pomocą węzła krajowego jest dostępne tylko dla opiekunów',
        isProcessing: false
      });
    }
    case SIGNED_OUT:
      return new AuthModel(null);
    case UPDATE_USER_PROFILE_PHOTO_SUCCESS: {
      const authModel = new AuthModel().assign(state);
      authModel.profilePhoto = action.photoUrl;
      return authModel;
    }
    case REMOVE_USER_PROFILE_PHOTO_SUCCESS: {
      const authModel = new AuthModel().assign(state);
      authModel.profilePhoto = null;
      return authModel;
    }
    case LOAD_USER_STATE_SUCCESS: {
      const authModel = new AuthModel().assign(state);
      authModel.profilePhoto = action.userState.photoUrl;
      authModel.pin = action.userState.pin;
      authModel.acceptanceRequired = action.userState.acceptanceRequired;
      return authModel;
    }
    case NAVIGATE_REQUEST: {
      const authModel = new AuthModel().assign(state);
      authModel.routeAllowedActions = getAllowedActions(action.matchedRoute, state.userRole);
      return authModel;
    }
    case SIGN_IN_GOV_SUCCESS: {
      const authModel = new AuthModel().assign(state);
      authModel.trustedProfileUserId = action.userId;
      return authModel;
    }
    default:
      return state;
  }
}
