import initialState from './initial';
import * as types from '../actions/actionTypes';
import { MealModel } from '../models/meals/MealModels';

export default function mealsReducer(state = initialState.meals, action) {
  switch (action.type) {
    case types.LOAD_MEALS_SUCCESS: {
      return action.meals.map((meal) => new MealModel().assign(meal));
    }
    // case types.CREATE_MEAL_DETAILS: {
    //   return [...state, new MealModel().assign({ ...action.nextMealDetails, id: action.response.mealId })];
    // }
    default:
      return state;
  }
}
