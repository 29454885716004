import initialState from './initial';
import * as types from '../actions/actionTypes';
import moment from 'moment';

export default function PsychologicalGroupClassesAttendanceReducer(
  state = initialState.PsychologicalGroupClassesAttendance,
  action
) {
  switch (action.type) {
    case types.LOAD_PSYCHOLOGICAL_GROUP_ATTENDANCE: {
      return Object.assign({}, action.attendance);
    }
    case types.REPORT_PSYCHOLOGICAL_GROUP_ATTENDANCE: {
      let newState = Object.assign({}, state);
      let idx = newState.childrenAttendance.findIndex((a) => a.child.id === action.child.id);
      newState.childrenAttendance[idx].droppedOffAt = moment().format('YYYY-MM-DD');
      newState.childrenAttendance[idx].pickedUpAt = moment().format('YYYY-MM-DD');
      newState.childrenAttendance[idx].absenceFrom = null;
      newState.childrenAttendance[idx].absenceTo = null;
      return newState;
    }
    case types.REPORT_PSYCHOLOGICAL_GROUP_ABSENCE: {
      let newState = Object.assign({}, state);
      let idx = newState.childrenAttendance.findIndex((a) => a.child.id === action.child.id);
      newState.childrenAttendance[idx].droppedOffAt = null;
      newState.childrenAttendance[idx].pickedUpAt = null;
      newState.childrenAttendance[idx].absenceFrom = moment().format('YYYY-MM-DD');
      newState.childrenAttendance[idx].absenceTo = moment().format('YYYY-MM-DD');
      return newState;
    }
    default:
      return state;
  }
}
