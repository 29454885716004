import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const ActionButton = ({ inProgress, onClick, onAction, actionLabel, disabled, ...rest }) => {
  return (
    <Button
      sx={{ whiteSpace: 'nowrap' }}
      variant="contained"
      aria-label={actionLabel}
      onClick={onClick || onAction}
      disabled={inProgress || disabled}
      {...rest}>
      {actionLabel}
    </Button>
  );
};

ActionButton.propTypes = {
  onAction: PropTypes.func,
  onClick: PropTypes.func,
  actionLabel: PropTypes.string,
  inProgress: PropTypes.bool,
  disabled: PropTypes.bool
};

ActionButton.defaultProps = {
  actionLabel: 'Edytuj'
};

export default ActionButton;
