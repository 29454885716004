import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as activitiesActions from '../../actions/activitiesActions';
import ActivitiesList from './ActivitiesList';
import { browserHistory } from 'react-router';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import FilterBar from '../common/FilterBar';
import { routePaths } from '../../routePaths';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import userRoles from '../../constants/userRoles';
import { Box, Grid, Button, Paper } from '@mui/material';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import EmptyState from '../common/EmptyState';

class ActivitiesPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      filteredActivities: props.activities
    };
    this.redirectToRegisterActivitiesPage = this.redirectToRegisterActivitiesPage.bind(this);
    this.redirectToActivitiesArchivePage = this.redirectToActivitiesArchivePage.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);

    this.handleLoaded = this.handleLoaded.bind(this);
  }

  redirectToRegisterActivitiesPage() {
    browserHistory.push(routePaths.activityRegister);
  }

  redirectToActivitiesArchivePage() {
    browserHistory.push(routePaths.activitiesArchive);
  }

  handleLoaded() {
    this.setState({
      filteredActivities: this.props.activities
    });
  }

  handleFilterChange(filteredList) {
    this.setState({
      filteredActivities: filteredList
    });
  }

  render() {
    return (
      <LoadingRenderWrapper onLoaded={this.handleLoaded}>
        <>
          <PageHeaderText title="Zajęcia dodatkowe" titleIcon={<GroupAddOutlinedIcon />} />
          <ContentWrapper>
            <Grid container sx={{ mb: 1 }}>
              <Grid item xs={12} md={6}>
                <FilterBar
                  sx={{ py: 3 }}
                  itemsToFilter={this.props.activities}
                  onFilter={this.handleFilterChange}
                  filter={(activity, keyword) => activity.name.toLowerCase().includes(keyword)}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {!this.props.auth.isStaffMember(userRoles.staffMemberDigitalDiary) && (
                  <Button
                    variant="contained"
                    aria-label="Dodaj zajęcia"
                    onClick={this.redirectToRegisterActivitiesPage}
                    sx={{ my: 1 }}>
                    Dodaj zajęcia
                  </Button>
                )}
              </Grid>
            </Grid>

            <Paper sx={{ mb: 2 }}>
              {this.state.filteredActivities.length === 0 ? (
                <EmptyState contrast message="Brak wyników wyszukiwania" />
              ) : (
                <ActivitiesList activities={this.state.filteredActivities} />
              )}
            </Paper>
            {!this.props.auth.isStaffMember(userRoles.staffMemberDigitalDiary) && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="outlined" aria-label="Pokaż archiwalne" onClick={this.redirectToActivitiesArchivePage}>
                  Pokaż archiwalne
                </Button>
              </Box>
            )}
          </ContentWrapper>
        </>
      </LoadingRenderWrapper>
    );
  }
}

ActivitiesPage.propTypes = {
  activities: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    activities: state.activities,
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activitiesActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesPage);
