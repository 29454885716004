import React, { useEffect, useState } from 'react';
import { Box, Card } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import makeStyles from '@mui/styles/makeStyles';
import CardHeader from '@mui/material/CardHeader';
import ActionButton from '../../forms/buttons/ActionButton';
import CardActions from '@mui/material/CardActions';
import SummaryTable from './SummaryTable';
import SummaryFilters from './SummaryFilters';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { bindActionCreators } from 'redux';
import * as allReportsActions from '../../../actions/reportsActions';
import * as allChildrenActions from '../../../actions/childDetailsSummaryActions';
import moment from 'moment';
import { fromTemplate, routePaths } from '../../../routePaths';
import { browserHistory } from 'react-router';

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

const options = [
  { value: 'stay', name: 'Pobyt' },
  { value: 'catering', name: 'Wyżywienie' },
  { value: 'other', name: 'Dodatkowe' },
  { value: 'activities', name: 'Zajęcia dodatkowe' },
  { value: 'meals', name: 'Posiłki' }
];
const SummaryCard = ({ childId, isLoading, childDetailsSummary, childrenActions, reportsActions, groupId }) => {
  const classes = useStyles();

  const [fromMonth, setFromMonth] = useState(moment().format('MM'));
  const [fromYear, setFromYear] = useState(moment().format('YYYY'));
  const [toMonth, setToMonth] = useState(moment().format('MM'));
  const [toYear, setToYear] = useState(moment().format('YYYY'));
  const [dueType, setDueType] = useState('stay');
  const [dontShowNegativeValues, setDontShowNegativeValues] = useState(false);
  const [totalPaymentsAmount, setTotalPaymentsAmount] = useState(false);
  const isError = !moment()
    .set({ year: fromYear, month: fromMonth })
    .isSameOrBefore(moment().set({ year: toYear, month: toMonth }));

  useEffect(() => {
    if (!isError) {
      childrenActions.loadChildDetailsSummary(childId, {
        fromMonth,
        fromYear,
        toMonth,
        toYear,
        dueType,
        dontShowNegativeValues,
        totalPaymentsAmount
      });
    }
  }, [fromMonth, fromYear, toMonth, toYear, dueType, dontShowNegativeValues, totalPaymentsAmount]);
  return (
    <Card className={classes.root} sx={{ my: 2, p: 3 }}>
      <CardHeader
        title={
          <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
            Ewidencja płatności za {options.find((x) => x.value === dueType).name}
          </Typography>
        }
      />
      <CardContent>
        <SummaryFilters
          fromMonth={fromMonth}
          fromYear={fromYear}
          toMonth={toMonth}
          toYear={toYear}
          dueType={dueType}
          dontShowNegativeValues={dontShowNegativeValues}
          totalPaymentsAmount={totalPaymentsAmount}
          setFromMonth={setFromMonth}
          setFromYear={setFromYear}
          setToMonth={setToMonth}
          setToYear={setToYear}
          options={options}
          setDueType={setDueType}
          setDontShowNegativeValues={setDontShowNegativeValues}
          setTotalPaymentsAmount={setTotalPaymentsAmount}
          isLoading={isLoading}
          childId={childId}
          isError={isError}
        />
        {isLoading ? (
          <Box sx={{ m: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <SummaryTable
            childDetailsSummary={childDetailsSummary}
            type={options.find((x) => x.value === dueType).name}
            isError={isError}
          />
        )}
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: { xs: 'column', sm: 'row' } }}>
        {groupId && (
          <ActionButton
            actionLabel="Przejdź do rozliczeń"
            onAction={() => {
              browserHistory.push(fromTemplate(routePaths.settlementsGroup, [fromYear, fromMonth, groupId]));
            }}
            disabled={isLoading}
          />
        )}
        <ActionButton
          actionLabel="Pobierz raport XLS"
          onAction={() =>
            reportsActions.generateReportAsync('detailedPaymentsSummaryReport', 'xls', {
              childId,
              fromMonth,
              fromYear,
              toMonth,
              toYear,
              dueType,
              dontShowNegativeValues,
              totalPaymentsAmount
            })
          }
          disabled={isLoading || isError}
        />
      </CardActions>
    </Card>
  );
};

SummaryCard.propTypes = {
  childId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  childDetailsSummary: PropTypes.array.isRequired,
  childrenActions: PropTypes.object,
  reportsActions: PropTypes.object,
  groupId: PropTypes.string
};

function mapStateToProps(state) {
  return {
    childDetailsSummary: state.childDetailsSummary,
    isLoading: state.childDetailsUi.detailsSummary.isProcessing
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportsActions: bindActionCreators(allReportsActions, dispatch),
    childrenActions: bindActionCreators(allChildrenActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryCard);
