import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ValidationSummary, { validationTypes } from '../common/validation/ValidationSummary';
import { Stack } from '@mui/material';

class ValidationWrapper extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ignoreTouchedItems: false,
      syncErrors: {},
      asyncErrors: {},
      touchedItems: []
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.asyncErrors !== this.props.asyncErrors && !!this.props.asyncErrors) {
      this.validateAsync(this.props.asyncErrors);
    }
    if (this.props.showAllErrors && !prevProps.showAllErrors) {
      this.validateSync();
    }
  }

  getValidationErrors(errors) {
    if (this.state.ignoreTouchedItems || this.props.showAllErrors) {
      return errors;
    }

    /*eslint no-param-reassign: off*/
    return this.state.touchedItems.reduce((o, v) => {
      _.setWith(o, v, _.get(errors, v, ''), {});
      return o;
    }, {});
  }

  getMergedGeneralError() {
    const wrapInArray = (errors) => {
      if (!errors || !errors.generalError) return [];
      return typeof errors.generalError === 'string' ? [errors.generalError] : errors.generalError;
    };

    const syncGeneralError = wrapInArray(this.state.syncErrors);
    const asyncGeneralError = wrapInArray(this.state.asyncErrors);

    if (this.state.ignoreTouchedItems || this.props.showAllErrors) return [...syncGeneralError, ...asyncGeneralError];
    return [];
  }

  handleBlur(event) {
    if (this.isTargetValidInput(event)) {
      const elementName = event.target.name;
      if (!this.state.touchedItems.length || this.state.touchedItems.every((i) => i !== elementName)) {
        this.state.touchedItems.push(elementName);
      }
    }
    this.validateSync(event);
  }

  handleFocus(e) {
    this.validateSync(e);
  }

  isTargetValidInput(event) {
    return event.target.nodeName === 'INPUT' && !!event.target.name;
  }

  validateSync() {
    if (this.props.validate == null) {
      return;
    }
    this.setState(
      {
        syncErrors: this.props.validate().errors
      },
      () => this.validationDone()
    );
  }

  validateAsync(asyncErrors) {
    this.setState(
      {
        asyncErrors,
        ignoreTouchedItems: true
      },
      () => this.validationDone()
    );
  }

  validationDone() {
    const errors = this.getValidationErrors({ ...this.state.syncErrors, ...this.state.asyncErrors });
    this.props.onValidationDone(errors);
  }

  renderValidationSummary() {
    return (
      <ValidationSummary messages={this.getMergedGeneralError()} title="Wystąpił błąd" type={validationTypes.error} />
    );
  }

  render() {
    return (
      <Stack onBlur={this.handleBlur} onFocus={this.handleFocus}>
        {this.renderValidationSummary()}
        {this.props.children}
      </Stack>
    );
  }
}

ValidationWrapper.propTypes = {
  asyncErrors: PropTypes.object,
  statePathToUi: PropTypes.string.isRequired,
  validate: PropTypes.func,
  onValidationDone: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  showAllErrors: PropTypes.bool
};

ValidationWrapper.defaultProps = {
  validate: null
};

function mapStateToProps(state, ownProps) {
  return {
    asyncErrors: ownProps.statePathToUi ? _.get(state, ownProps.statePathToUi).asyncErrors : null
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidationWrapper);
