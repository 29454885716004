import BaseModel from '../BaseModel';
import { compareNames } from '../../utils/personNamesComparator';

export class EnrolledChildModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.lastName = '';
    this.firstName = '';
    this.enrolledAt = '';
    this.photoUrl = '';
  }
}

export class AssociatedTeacherModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.lastName = '';
    this.firstName = '';
    this.associatedAt = '';
    this.photoUrl = '';
  }
}

export class GroupModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.yearbook = null;
    this.isTemporary = true;
    this.color = '';
  }
}

export class GroupDetailsModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.yearbook = null;
    this.isTemporary = true;
    this.color = '';
    this.enrolledChildren = [];
    this.associatedTeachers = [];
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.enrolledChildren = assigned.enrolledChildren
      .map((e) => new EnrolledChildModel().assign(e))
      .sort(compareNames);
    /* eslint no-param-reassign: 'off' */
    assigned.associatedTeachers = assigned.associatedTeachers
      .map((a) => new AssociatedTeacherModel().assign(a))
      .sort(compareNames);
  }
}
