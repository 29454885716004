import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../forms/DialogForm'; // ✅
import DatePicker from '../../common/DatePicker'; // ✅
import ClearButtonWrapper from '../../common/ClearButtonWrapper'; // ✅
import { MessageBoardFilterModel } from '../../../models/messageBoardFilter/MessageBoardFilterModels';
import moment from 'moment';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import SingleMemberChip from '../../gallery/Components/Albums/SingleMemberChip';
import RichMultiSelectField from '../../formik/RichMultiSelectField/RichMultiSelectField';
import * as AlbumTypes from '../../../constants/groupsAndActivitiesTypes';
import SingleAuthorChip from '../../formik/RichMultiSelectField/richElements/SingleAuthorChip';
import { connect } from 'react-redux';

class FilterDialog extends React.Component {
  constructor(props) {
    super(props);
    this.limitedToChips = [];
    this.state = {
      filters: new MessageBoardFilterModel().assign(props.filters),
      edited: false,
      groups: this.props.groups,
      activities: this.props.activities,
      staffMembers: this.props.staffMembers,

      showGroupsDialog: false,
      showActivitiesDialog: false,
      showAuthorsDialog: false,

      canChooseActivities: this.props.canChooseActivities !== undefined ? this.props.canChooseActivities : true,
      canChooseAuthors: this.props.canChooseAuthors !== undefined ? this.props.canChooseAuthors : true,

      selectedGroups: this.props.groups.filter((g) => props.filters.groups.includes(g.id)),
      selectedActivities: this.props.activities.filter((g) => props.filters.activities.includes(g.id)),
      selectedAuthors: this.props.staffMembers.filter((g) => props.filters.authors.includes(g.id))
    };

    this.handleFilterDayFromChange = this.handleFilterDayFromChange.bind(this);
    this.handleFilterDayToChange = this.handleFilterDayToChange.bind(this);
    this.handleShowGroupsDialog = this.handleShowGroupsDialog.bind(this);
    this.handleCloseGroupsDialog = this.handleCloseGroupsDialog.bind(this);
    this.handleSelectGroups = this.handleSelectGroups.bind(this);
    this.handleSaveFilters = this.handleSaveFilters.bind(this);
    this.handleShowActivitiesDialog = this.handleShowActivitiesDialog.bind(this);
    this.handleSelectActivities = this.handleSelectActivities.bind(this);
    this.handleCloseActivitiesDialog = this.handleCloseActivitiesDialog.bind(this);
    this.handleShowAuthorsDialog = this.handleShowAuthorsDialog.bind(this);
    this.handleSelectAuthors = this.handleSelectAuthors.bind(this);
    this.handleCloseAuthorsDialog = this.handleCloseAuthorsDialog.bind(this);
    this.handlePinnedCheckbox = this.handlePinnedCheckbox.bind(this);
  }

  handleShowGroupsDialog() {
    this.setState({
      showGroupsDialog: true
    });
  }

  handleShowActivitiesDialog() {
    this.setState({
      showActivitiesDialog: true
    });
  }

  handleShowAuthorsDialog() {
    this.setState({
      showAuthorsDialog: true
    });
  }

  handleSelectGroups(groups) {
    this.setState({
      selectedGroups: groups,
      showGroupsDialog: false
    });
  }

  handleSelectActivities(activities) {
    this.setState({
      selectedActivities: activities,
      showActivitiesDialog: false
    });
  }

  handleSelectAuthors(authors) {
    this.setState({
      selectedAuthors: authors,
      showAuthorsDialog: false
    });
  }

  handleCloseGroupsDialog() {
    this.setState({
      showGroupsDialog: false
    });
  }

  handleCloseActivitiesDialog() {
    this.setState({
      showActivitiesDialog: false
    });
  }

  handleCloseAuthorsDialog() {
    this.setState({
      showAuthorsDialog: false
    });
  }

  handleFilterDayFromChange(date) {
    this.setState((prevState) => {
      return {
        filters: new MessageBoardFilterModel().assign({ ...prevState.filters, dateFrom: date })
      };
    });
  }

  handleFilterDayToChange(date) {
    this.setState((prevState) => {
      return {
        filters: new MessageBoardFilterModel().assign({ ...prevState.filters, dateTo: date })
      };
    });
  }

  handlePinnedCheckbox(e) {
    e.persist();
    this.setState((prevState) => {
      return {
        filters: new MessageBoardFilterModel().assign({ ...prevState.filters, pinned: e.target.checked })
      };
    });
  }

  handleSaveFilters() {
    const filters = new MessageBoardFilterModel().assign({
      dateFrom: !!this.state.filters.dateFrom ? moment(this.state.filters.dateFrom).format('YYYY-MM-DD') : '',
      dateTo: !!this.state.filters.dateTo ? moment(this.state.filters.dateTo).format('YYYY-MM-DD') : '',
      groups: this.state.selectedGroups.map((group) => group.id),
      authors: this.state.selectedAuthors.map((author) => author.id),
      activities: this.state.selectedActivities.map((activ) => activ.id),
      pinned: this.state.filters.pinned
    });
    this.props.onSave(filters);
  }

  render() {
    return (
      <>
        <DialogForm
          onSave={this.handleSaveFilters}
          onCancel={() => this.props.onCancel(this.state.post)}
          onValidationDone={() => {}}
          statePathToUi="messageBoardFilterUi"
          isDialogOpen={this.props.isEditing}
          isProcessing={this.props.isProcessing}
          saveLabel="Filtruj"
          header="Filtrowanie"
          maxWidth="sm">
          <Grid container>
            {this.props.canSetDate ? (
              <Grid item md={6} xs={12} sx={{ mb: 0.5, alignItems: 'start', flexDirection: 'column' }}>
                <FieldsetWrapper legend="Wybór daty" sx={{ mt: 2 }}>
                  <ClearButtonWrapper onClear={() => this.handleFilterDayFromChange('')} contrast>
                    <DatePicker
                      floatingLabelText="Data od"
                      textFieldStyle={{ width: 128 }}
                      onChange={(e, date) => this.handleFilterDayFromChange(date)}
                      value={this.state.filters.dateFrom ? new Date(this.state.filters.dateFrom) : null}
                      name="dateFrom"
                      maxDate={this.state.filters.dateTo || null}
                    />
                  </ClearButtonWrapper>

                  <ClearButtonWrapper onClear={() => this.handleFilterDayToChange('')} contrast>
                    <DatePicker
                      floatingLabelText="Data do"
                      textFieldStyle={{ width: 128 }}
                      onChange={(e, date) => this.handleFilterDayToChange(date)}
                      value={this.state.filters.dateTo ? new Date(this.state.filters.dateTo) : null}
                      name="dateFrom"
                      minDate={this.state.filters.dateFrom || null}
                    />
                  </ClearButtonWrapper>
                  <FormControlLabel
                    sx={{ mt: 2 }}
                    control={
                      <Checkbox
                        checked={this.state.filters.pinned}
                        onChange={this.handlePinnedCheckbox}
                        name="pinned"
                      />
                    }
                    label="Tylko przypięte do tablicy"
                  />
                </FieldsetWrapper>
              </Grid>
            ) : null}

            <Grid
              item
              md={this.props.canSetDate ? 6 : 12}
              xs={12}
              sx={{ mb: 0.5, alignItems: 'start', flexDirection: 'column' }}>
              <Box
                sx={{
                  display: 'flex',
                  mb: 0.6,
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                <RichMultiSelectField
                  options={this.state.groups}
                  name="limitedToGroups"
                  label="Wybierz grupy*"
                  type={AlbumTypes.GROUP}
                  selectElement={<SingleMemberChip />}
                  chipElement={<SingleMemberChip />}
                  isSelectAll={true}
                  value={this.state.selectedGroups}
                  onChange={this.handleSelectGroups}
                  onDelete={this.handleSelectGroups}
                />
              </Box>

              {!this.props.nadarzyn && this.state.canChooseActivities && (
                <Box sx={{ mb: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <RichMultiSelectField
                    options={this.state.activities}
                    name="limitedToActivitiess"
                    label="Wybierz zajęcia*"
                    type={AlbumTypes.ACTIVITY}
                    selectElement={<SingleMemberChip />}
                    chipElement={<SingleMemberChip />}
                    isSelectAll={true}
                    value={this.state.selectedActivities}
                    onChange={this.handleSelectActivities}
                    onDelete={this.handleSelectActivities}
                  />
                </Box>
              )}
              {this.state.canChooseAuthors && (
                <Box sx={{ mb: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <RichMultiSelectField
                    options={this.state.staffMembers}
                    name="limitedToStaffMembers"
                    label="Wybierz pracownika*"
                    type={AlbumTypes.NONE}
                    selectElement={<SingleAuthorChip />}
                    chipElement={<SingleAuthorChip />}
                    isSelectAll={true}
                    value={this.state.selectedAuthors}
                    onChange={this.handleSelectAuthors}
                    onDelete={this.handleSelectAuthors}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogForm>
      </>
    );
  }
}

FilterDialog.propTypes = {
  filters: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  staffMembers: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  canSetDate: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  nadarzyn: PropTypes.bool
};

const mapStateToProps = (state) => ({
  nadarzyn: state.configuration.unit.features.nadarzyn
});

export default connect(mapStateToProps)(FilterDialog);
