import _ from 'lodash';
import moment from 'moment';
import { CalendarDayModel } from './CalendarDayModel';

function includeMarkedInfo(days, markedDays) {
  return days.map((day) => {
    const marked = markedDays.find((md) => day.date.isSame(md.date));
    if (marked) {
      day.mark(marked.data);
    }
    return day;
  });
}

export function calendarWeeksProvider(weekOffset = 0) {
  return _.range(1, 8).map((i) =>
    moment()
      .isoWeekday(i + weekOffset)
      .format('ddd')
  );
}

export function calendarDaysProvider(date, weekOffset = 0, markedDays = []) {
  const startOfMonth = date.startOf('month');
  let diff = startOfMonth.weekday() - weekOffset;
  if (diff < 0) diff += 7;

  const prevMonthDays = _.range(0, diff).map((n) =>
    CalendarDayModel.previousMonthDay(startOfMonth.clone().subtract(diff - n, 'days'))
  );
  const currentMonthDays = _.range(1, date.daysInMonth() + 1).map((index) =>
    CalendarDayModel.newDay(moment([date.year(), date.month(), index]))
  );
  const daysAdded = prevMonthDays.length + currentMonthDays.length - 1;
  const nextMonthDays = _.takeWhile(_.range(1, 7), (n) => (daysAdded + n) % 7 !== 0).map((n) =>
    CalendarDayModel.nextMonthDay(_.last(currentMonthDays).date.clone().add(n, 'days'))
  );

  return includeMarkedInfo([...prevMonthDays, ...currentMonthDays, ...nextMonthDays], markedDays);
}

export function calendarDaysByWeeksProvider(date, weekOffset = 0, markedDays = []) {
  const days = calendarDaysProvider(date, weekOffset, markedDays);
  const weeks = _.chunk(days, 7);
  return weeks;
}
