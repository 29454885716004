import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ScheduledTransferModel } from '../models/scheduledTransfer/ScheduledTransferModel';

export default function scheduledTransferReducer(state = initialState.scheduledTransfers, action) {
  switch (action.type) {
    case types.SCHEDULED_TRANSFER_FETCH: {
      const { data } = action;
      return data.map((item) => new ScheduledTransferModel().assign(item));
    }
    case types.SCHEDULED_TRANSFER_CREATE: {
      const { data } = action;
      return [...state, new ScheduledTransferModel().assign(data)];
    }
    case types.SCHEDULED_TRANSFER_EDIT: {
      let newState = [...state];
      const { data } = action;
      const elementIdx = state.findIndex((item) => item.id === data.id);
      newState[elementIdx] = new ScheduledTransferModel().assign(data);
      return newState;
    }
    case types.SCHEDULED_TRANSFER_DELETE: {
      const { deletedId } = action;
      return [...state.filter((item) => item.id !== deletedId)];
    }
    case types.SCHEDULED_TRANSFER_DELETE_MANY: {
      const { deletedIds } = action;
      return [...state.filter((item) => !deletedIds.includes(item.id))];
    }
    case types.SCHEDULED_TRANSFER_ERROR: {
      return state;
    }
    default:
      return state;
  }
}
