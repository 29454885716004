import { FilterByRoleType } from '../../../components/common/filters/filterByRole/FilterByRole';

export const shareAvailableListFilter = (keyword, filterBy, selectedGroup, array) => {
  let filterArray = array;

  if (keyword.length) {
    filterArray = filterArray.map((item) => ({
      ...item,
      users:
        item.users &&
        item.users.filter(
          (user) =>
            `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.includes(keyword.toLowerCase()) ||
            `${user.lastName.toLowerCase()} ${user.firstName.toLowerCase()}`.includes(keyword.toLowerCase())
        ),
      teachers:
        item.teachers &&
        item.teachers.filter(
          (teacher) =>
            `${teacher.firstName.toLowerCase()} ${teacher.lastName.toLowerCase()}`.includes(keyword.toLowerCase()) ||
            `${teacher.lastName.toLowerCase()} ${teacher.firstName.toLowerCase()}`.includes(keyword.toLowerCase())
        )
    }));
  }

  if (filterBy === FilterByRoleType.TEACHER) {
    filterArray = filterArray.filter((item) => item.teachers !== undefined && item.teachers.length !== 0);
    filterArray = filterArray.map((item) => ({
      ...item,
      users: []
    }));
  }

  if (filterBy === FilterByRoleType.LEGAL_GUARDIAN) {
    filterArray = filterArray.filter((item) => item.users !== undefined && item.users.length !== 0);
    filterArray = filterArray.map((item) => ({
      ...item,
      teachers: []
    }));
  }

  if (!!selectedGroup) filterArray = filterArray.filter((item) => item.id === selectedGroup);

  return filterArray;
};
