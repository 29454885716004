import ScheduledTransfersApi from '../api/ScheduledTransferApi';
import { showError } from './notificationActions';
import {
  SCHEDULED_TRANSFER_CREATE,
  SCHEDULED_TRANSFER_DELETE,
  SCHEDULED_TRANSFER_DELETE_MANY,
  SCHEDULED_TRANSFER_EDIT,
  SCHEDULED_TRANSFER_FETCH
} from './actionTypes';

export function fetchScheduledTransfers() {
  return async (dispatch) => {
    try {
      const response = await ScheduledTransfersApi.getScheduledTransfers();
      dispatch({ type: SCHEDULED_TRANSFER_FETCH, data: response });
    } catch (e) {
      dispatch(showError('Nie udało się pobrać listy przenosin dzieci'));
    }
  };
}

export function createScheduledTransfer(inData, storeData) {
  return async (dispatch) => {
    try {
      const { id } = await ScheduledTransfersApi.createScheduledTransfer(inData);
      dispatch({ type: SCHEDULED_TRANSFER_CREATE, data: { id, ...storeData } });
      return undefined;
    } catch (e) {
      return JSON.parse(e.response.text);
    }
  };
}

export function editScheduledTransfer(id, inData, storeData) {
  return async (dispatch) => {
    try {
      await ScheduledTransfersApi.editScheduledTransfer(id, inData);
      dispatch({ type: SCHEDULED_TRANSFER_EDIT, data: storeData });
      return undefined;
    } catch (e) {
      return JSON.parse(e.response.text);
    }
  };
}

export function deleteScheduledTransfer(id) {
  return async (dispatch) => {
    try {
      await ScheduledTransfersApi.deleteScheduledTransfer(id);
      dispatch({ type: SCHEDULED_TRANSFER_DELETE, deletedId: id });
    } catch (e) {
      dispatch(showError('Nie udało się usunąć przenosin dziecka'));
    }
  };
}

export function deleteManyScheduledTransfer(data) {
  return async (dispatch) => {
    try {
      await ScheduledTransfersApi.deleteManyScheduledTransfer(data);
      dispatch({ type: SCHEDULED_TRANSFER_DELETE_MANY, deletedIds: data.enrollmentIds });
    } catch (e) {
      dispatch(showError('Nie udało się usunąć zaznaczonych przenosin dzieci'));
    }
  };
}
