import PropTypes from 'prop-types';
import React from 'react';
import TimePicker from '../../common/TimePicker';
import { TimeModel } from '../../../models/TimeModel';
import { Languages } from '../../../constants/languages';
import TextField from '../../common/TextField';
import SelectField from '../../common/SelectField';
import MenuItem from '@mui/material/MenuItem';
import { Box, Button, Typography, Grid, Stack } from '@mui/material';

const ChildLegalGuardianForm = ({ guardian, onChange, errors, copyChildAddress }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        hintText="Imię"
        floatingLabelText="Imię*"
        floatingLabelFixed={false}
        autocomplete="given-name"
        name="firstName"
        value={guardian.firstName}
        onChange={onChange}
        errorText={errors.firstName}
        autoFocus={true}
      />
      <TextField
        hintText="Nazwisko"
        floatingLabelText="Nazwisko*"
        floatingLabelFixed={false}
        autocomplete="family-name"
        name="lastName"
        value={guardian.lastName}
        onChange={onChange}
        errorText={errors.lastName}
        fullWidth={true}
      />
      <TextField
        hintText="Stopień pokrewieństwa"
        floatingLabelText="Stopień pokrewieństwa"
        floatingLabelFixed={false}
        name="relationship"
        value={guardian.relationship}
        onChange={onChange}
        errorText={errors.relationship}
        fullWidth={true}
      />
      <TextField
        hintText="E-mail posłuży jako login do systemu"
        floatingLabelText="E-mail*"
        floatingLabelFixed={false}
        autocomplete="email"
        name="email"
        value={guardian.email}
        onChange={onChange}
        errorText={errors.email}
        fullWidth={true}
      />
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, mr: 1 }}>Uwaga</Typography>-
        <Typography sx={{ ml: 1 }}>na podany adres email zostanie wysłany link aktywacyjny</Typography>
      </Box>
      <TextField
        hintText="Numer dowodu osobistego lub paszportu"
        floatingLabelText="Numer dokumentu tożsamości*"
        floatingLabelFixed={false}
        name="documentNumber"
        value={guardian.documentNumber}
        onChange={onChange}
        errorText={errors.documentNumber}
        fullWidth={true}
      />
      <TextField
        hintText="Numer PESEL"
        floatingLabelText="Numer PESEL"
        floatingLabelFixed={false}
        name="personalId"
        value={guardian.personalId}
        onChange={onChange}
        errorText={errors.personalId}
        fullWidth={true}
      />
      <TextField
        hintText="Numer telefonu kontaktowego"
        floatingLabelText="Numer telefonu kontaktowego"
        floatingLabelFixed={false}
        autocomplete="tel"
        name="phoneNumber"
        value={guardian.phoneNumber}
        onChange={onChange}
        errorText={errors.phoneNumber}
      />
      <Stack sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: { sm: 'center' }, flexWrap: 'nowrap' }}>
        <TextField
          hintText="Adres zamieszkania"
          floatingLabelText="Adres zamieszkania*"
          floatingLabelFixed={false}
          autocomplete="street-address"
          name="address"
          value={guardian.address}
          onChange={onChange}
          errorText={errors.address}
          fullWidth={true}
        />
        <Button
          variant="contained"
          aria-label="Skopiuj adres dziecka"
          sx={{ mr: 1 }}
          onClick={() => copyChildAddress()}>
          Skopiuj adres dziecka
        </Button>
      </Stack>
      <TextField
        hintText="Numer konta bankowego"
        floatingLabelText="Numer konta bankowego"
        floatingLabelFixed={false}
        name="bankAccountNumber"
        value={guardian.bankAccountNumber}
        onChange={onChange}
        errorText={errors.bankAccountNumber}
      />
      <TextField
        hintText="Adres miejsca pracy"
        floatingLabelText="Adres miejsca pracy"
        floatingLabelFixed={false}
        autocomplete="street-address"
        name="workplaceAddress"
        value={guardian.workplaceAddress}
        onChange={onChange}
        errorText={errors.workplaceAddress}
        fullWidth={true}
      />
      <SelectField
        contrast={true}
        sx={{ mt: 3 }}
        floatingLabelText="Domyślny język aplikacji mobilnej"
        floatingLabelFixed={true}
        id="defaultLanguageCode"
        value={guardian.defaultLanguageCode || Languages.pl.value}
        onChange={(event) => {
          onChange({ target: { name: 'defaultLanguageCode', value: event.target.value } });
        }}
        display={guardian.id ? 'none' : 'flex'}>
        {Object.values(Languages).map((language) => {
          return (
            <MenuItem key={language.value} value={language.value}>
              {language.name}
            </MenuItem>
          );
        })}
      </SelectField>
      <Typography variant="h6" sx={{ pt: 1 }}>
        Godziny pracy
      </Typography>
      <Grid container sx={{ display: 'flex', justifyContent: { sm: 'space-between', md: 'flex-start' } }}>
        <Grid item>
          <TimePicker
            hintText="Od"
            floatingLabelText="Od"
            floatingLabelFixed={false}
            name="worksFrom"
            value={guardian.worksFrom ? guardian.worksFrom.toDate() : undefined}
            onChange={(e, value) => onChange({ target: { name: 'worksFrom', value: new TimeModel().parse(value) } })}
            errorText={errors.worksFrom}
          />
        </Grid>
        <Grid item sx={{ ml: { md: 2 } }}>
          <TimePicker
            hintText="Do"
            floatingLabelText="Do"
            floatingLabelFixed={false}
            name="worksTo"
            value={guardian.worksTo ? guardian.worksTo.toDate() : undefined}
            onChange={(e, value) => onChange({ target: { name: 'worksTo', value: new TimeModel().parse(value) } })}
            errorText={errors.worksTo}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

ChildLegalGuardianForm.propTypes = {
  guardian: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  copyChildAddress: PropTypes.func
};

export default ChildLegalGuardianForm;
