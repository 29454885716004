import React from 'react';
import ListItem from '@mui/material/ListItem';
import { FormikTextField } from '../../../formik/FormikTextField';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import { Typography, Grid } from '@mui/material';

const ChildDetailsFormAddress = () => {
  return (
    <>
      <FieldsetWrapper
        legend={
          <Typography component="span" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, pl: 2 }}>
            Adres
          </Typography>
        }
        invisible={false}
        labelStyle={{ color: (theme) => theme.palette.color.contrast }}>
        <Grid container columnSpacing={1} sx={{ mb: 4 }}>
          <Grid item xs={12} md={6}>
            <ListItem>
              <FormikTextField label="Adres*" name="address.address" fullWidth />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={6}>
            <ListItem>
              <FormikTextField label="Miejsce urodzenia" name="address.birthPlace" fullWidth />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={6}>
            <ListItem>
              <FormikTextField label="Kod pocztowy*" name="address.zipCode" zip fullWidth />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={6}>
            <ListItem>
              <FormikTextField label="Miejscowość*" name="address.city" fullWidth />
            </ListItem>
          </Grid>
        </Grid>
      </FieldsetWrapper>
      <FieldsetWrapper
        legend={
          <Typography component="span" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, pl: 2 }}>
            Adres zameldowania
          </Typography>
        }
        invisible={false}
        labelStyle={{ color: (theme) => theme.palette.color.contrast }}>
        <Grid container columnSpacing={1}>
          <Grid item xs={12} md={4}>
            <ListItem>
              <FormikTextField label="Adres" name="registeredAddress.address" fullWidth />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItem>
              <FormikTextField label="Kod pocztowy" name="registeredAddress.zipCode" fullWidth />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItem sx={{ pb: 5 }}>
              <FormikTextField label="Miejscowość" name="registeredAddress.city" fullWidth />
            </ListItem>
          </Grid>
        </Grid>
      </FieldsetWrapper>
    </>
  );
};

export default ChildDetailsFormAddress;
