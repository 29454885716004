//Error handling actions
export const SHOW_ERROR_NOTIFICATION = 'SHOW_ERROR_NOTIFICATION';
export const SHOW_SUCCESS_NOTIFICATION = 'SHOW_SUCCESS_NOTIFICATION';
export const SHOW_WARNING_NOTIFICATION = 'SHOW_WARNING_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_ALL_ERRORS';

//Groups actions
//load all
export const LOAD_GROUPS = 'LOAD_GROUPS';
export const LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS';
//archived
export const LOAD_GROUPS_ARCHIVE = 'LOAD_GROUPS_ARCHIVE';
export const LOAD_GROUPS_ARCHIVE_SUCCESS = 'LOAD_GROUPS_ARCHIVE_SUCCESS';

//create
export const CREATE_GROUP_DETAILS = 'CREATE_GROUP_DETAILS';
export const CREATE_GROUP_DETAILS_CANCEL = 'CREATE_GROUP_DETAILS_CANCEL';
export const CREATE_GROUP_DETAILS_SUBMIT = 'CREATE_GROUP_DETAILS_SUBMIT';
export const CREATE_GROUP_DETAILS_SUCCESS = 'CREATE_GROUP_DETAILS_SUCCESS';
export const CREATE_GROUP_DETAILS_FAILURE = 'CREATE_GROUP_DETAILS_FAILURE';
//update
export const UPDATE_GROUP_DETAILS = 'UPDATE_GROUP_DETAILS';
export const UPDATE_GROUP_DETAILS_CANCEL = 'UPDATE_GROUP_DETAILS_CANCEL';
export const UPDATE_GROUP_DETAILS_SUBMIT = 'UPDATE_GROUP_DETAILS_SUBMIT';
export const UPDATE_GROUP_DETAILS_SUCCESS = 'UPDATE_GROUP_DETAILS_SUCCESS';
export const UPDATE_GROUP_DETAILS_FAILURE = 'UPDATE_GROUP_DETAILS_FAILURE';

//load
export const LOAD_GROUP_DETAILS = 'LOAD_GROUP_DETAILS';
export const LOAD_GROUP_DETAILS_SUCCESS = 'LOAD_GROUP_DETAILS_SUCCESS';

export const LOAD_GROUP_ARCHIVE_DETAILS = 'LOAD_GROUP_ARCHIVE_DETAILS';
export const LOAD_GROUP_ARCHIVE_DETAILS_SUCCESS = 'LOAD_GROUP_ARCHIVE_DETAILS_SUCCESS';
//archive
export const ARCHIVE_GROUP = 'ARCHIVE_GROUP';
export const ARCHIVE_GROUP_SUBMIT = 'ARCHIVE_GROUP_SUBMIT';
export const ARCHIVE_GROUP_CANCEL = 'ARCHIVE_GROUP_CANCEL';
export const ARCHIVE_GROUP_SUCCESS = 'ARCHIVE_GROUP_SUCCESS';

export const ENROLL_CHILDREN_TO_GROUP = 'ENROLL_CHILDREN_TO_GROUP';
export const ENROLL_CHILDREN_TO_GROUP_CANCEL = 'ENROLL_CHILDREN_TO_GROUP_CANCEL';
export const ENROLL_CHILDREN_TO_GROUP_SUBMIT = 'ENROLL_CHILDREN_TO_GROUP_SUBMIT';
export const ENROLL_CHILDREN_TO_GROUP_SUCCESS = 'ENROLL_CHILDREN_TO_GROUP_SUCCESS';
export const ENROLL_CHILDREN_TO_GROUP_FAILURE = 'ENROLL_CHILDREN_TO_GROUP_FAILURE';

export const REMOVE_CHILD_FROM_GROUP = 'REMOVE_CHILD_FROM_GROUP';
export const REMOVE_CHILD_FROM_GROUP_SUCCESS = 'REMOVE_CHILD_FROM_GROUP_SUCCESS';

export const ASSOCIATE_TEACHER_TO_GROUP = 'ASSOCIATE_TEACHER_TO_GROUP';
export const ASSOCIATE_TEACHER_TO_GROUP_CANCEL = 'ASSOCIATE_TEACHER_TO_GROUP_CANCEL';
export const ASSOCIATE_TEACHER_TO_GROUP_SUBMIT = 'ASSOCIATE_TEACHER_TO_GROUP';
export const ASSOCIATE_TEACHER_TO_GROUP_SUCCESS = 'ASSOCIATE_TEACHER_TO_GROUP_SUCCESS';
export const ASSOCIATE_TEACHER_TO_GROUP_FAILURE = 'ASSOCIATE_TEACHER_TO_GROUP_FAILURE';

export const REMOVE_TEACHER_FROM_GROUP = 'REMOVE_TEACHER_FROM_GROUP';
export const REMOVE_TEACHER_FROM_GROUP_SUCCESS = 'REMOVE_TEACHER_FROM_GROUP_SUCCESS';

//Children actions
//load all
export const LOAD_CHILDREN = 'LOAD_CHILDREN';
export const LOAD_CHILDREN_SUCCESS = 'LOAD_CHILDREN_SUCCESS';
export const LOAD_STUDENT_SUCCESS = 'LOAD_STUDENT_SUCCESS';

export const LOAD_CHILDREN_ARCHIVE = 'LOAD_CHILDREN_ARCHIVE';
export const LOAD_CHILDREN_ARCHIVE_SUCCESS = 'LOAD_CHILDREN_ARCHIVE_SUCCESS';

//load children of currently logged legal guardian
export const LOAD_LEGAL_GUARDIANS_CHILDREN = 'LOAD_LEGAL_GUARDIANS_CHILDREN';
export const LOAD_LEGAL_GUARDIANS_CHILDREN_SUCCESS = 'LOAD_LEGAL_GUARDIANS_CHILDREN_SUCCESS';
export const LOAD_LEGAL_GUARDIANS_CHILDREN_FAILURE = 'LOAD_LEGAL_GUARDIANS_CHILDREN_FAILURE';
//create
export const CREATE_CHILD = 'CREATE_CHILD';
export const CREATE_CHILD_CANCEL = 'CREATE_CHILD_CANCEL';
export const CREATE_CHILD_SUBMIT = 'CREATE_CHILD_SUBMIT';
export const CREATE_CHILD_SUCCESS = 'CREATE_CHILD_SUCCESS';
export const CREATE_STUDENT_SUCCESS = 'CREATE_STUDENT_SUCCESS';
export const CREATE_CHILD_FAILURE = 'CREATE_CHILD_FAILURE';
//update
export const UPDATE_CHILD = 'UPDATE_CHILD';
export const UPDATE_CHILD_CANCEL = 'UPDATE_CHILD_CANCEL';
export const UPDATE_CHILD_SUBMIT = 'UPDATE_CHILD_SUBMIT';
export const UPDATE_CHILD_SUCCESS = 'UPDATE_CHILD_SUCCESS';
export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS';
export const UPDATE_CHILD_FAILURE = 'UPDATE_CHILD_FAILURE';
//load
export const LOAD_CHILD = 'LOAD_CHILD';
export const LOAD_CHILD_SUCCESS = 'LOAD_CHILD_SUCCESS';

//load personal balance
export const LOAD_CHILD_PERSONAL_BALANCE = 'LOAD_CHILD_PERSONAL_BALANCE';
export const LOAD_CHILD_PERSONAL_BALANCE_SUCCESS = 'LOAD_CHILD_PERSONAL_BALANCE_SUCCESS';

//load personal balance
export const CASH_CHILD_BALANCE = 'CASH_CHILD_BALANCE';
export const CASH_CHILD_BALANCE_SUCCESS = 'CASH_CHILD_BALANCE_SUCCESS';

export const LOAD_CHILD_ARCHIVE = 'LOAD_CHILD_ARCHIVE';
export const LOAD_CHILD_ARCHIVE_SUCCESS = 'LOAD_CHILD_ARCHIVE_SUCCESS';
//archive
export const ARCHIVE_CHILD = 'ARCHIVE_CHILD';
export const ARCHIVE_CHILD_SUCCESS = 'ARCHIVE_CHILD_SUCCESS';
export const ARCHIVE_CHILD_SUBMIT = 'ARCHIVE_CHILD_SUBMIT';
export const ARCHIVE_CHILD_CANCEL = 'ARCHIVE_CHILD_CANCEL';
//photo
export const UPDATE_CHILD_PROFILE_PHOTO = 'UPDATE_CHILD_PROFILE_PHOTO';
export const UPDATE_CHILD_PROFILE_PHOTO_SUCCESS = 'UPDATE_CHILD_PROFILE_PHOTO_SUCCESS';

export const REMOVE_CHILD_PROFILE_PHOTO = 'REMOVE_CHILD_PROFILE_PHOTO';
export const REMOVE_CHILD_PROFILE_PHOTO_SUCCESS = 'REMOVE_CHILD_PROFILE_PHOTO_SUCCESS';

//load child attributes
export const LOAD_CHILD_ATTRIBUTES = 'LOAD_CHILD_ATTRIBUTES';
export const LOAD_CHILD_ATTRIBUTES_SUCCESS = 'LOAD_CHILD_ATTRIBUTES_SUCCESS';
//update child attributes
export const UPDATE_CHILD_ATTRIBUTES = 'UPDATE_CHILD_ATTRIBUTES';
export const UPDATE_CHILD_ATTRIBUTES_SUCCESS = 'UPDATE_CHILD_ATTRIBUTES_SUCCESS';
export const UPDATE_CHILD_ATTRIBUTES_FAILURE = 'UPDATE_CHILD_ATTRIBUTES_FAILURE';

//attachments
export const ATTACH_FILE_TO_CHILD = 'ATTACH_FILE_TO_CHILD';
export const ATTACH_FILE_TO_CHILD_CANCEL = 'ATTACH_FILE_TO_CHILD_CANCEL';
export const ATTACH_FILE_TO_CHILD_SUBMIT = 'ATTACH_FILE_TO_CHILD_SUBMIT';
export const ATTACH_FILE_TO_CHILD_SUCCESS = 'ATTACH_FILE_TO_CHILD_SUCCESS';
export const ATTACH_FILE_TO_CHILD_FAILURE = 'ATTACH_FILE_TO_CHILD_FAILURE';

export const REMOVE_FILE_FROM_CHILD = 'REMOVE_FILE_FROM_CHILD';
export const REMOVE_FILE_FROM_CHILD_SUCCESS = 'REMOVE_FILE_FROM_CHILD_SUCCESS';

export const ADD_LEGAL_GUARDIAN_TO_CHILD = 'ADD_LEGAL_GUARDIAN_TO_CHILD';
export const ADD_LEGAL_GUARDIAN_TO_CHILD_CANCEL = 'ADD_LEGAL_GUARDIAN_TO_CHILD_CANCEL';
export const ADD_LEGAL_GUARDIAN_TO_CHILD_SUBMIT = 'ADD_LEGAL_GUARDIAN_TO_CHILD_SUBMIT';
export const ADD_LEGAL_GUARDIAN_TO_CHILD_SUCCESS = 'ADD_LEGAL_GUARDIAN_TO_CHILD_SUCCESS';
export const ADD_LEGAL_GUARDIAN_TO_CHILD_FAILURE = 'ADD_LEGAL_GUARDIAN_TO_CHILD_FAILURE';

export const REMOVE_LEGAL_GUARDIAN_FROM_CHILD = 'REMOVE_LEGAL_GUARDIAN_FROM_CHILD';
export const REMOVE_LEGAL_GUARDIAN_FROM_CHILD_SUCCESS = 'REMOVE_LEGAL_GUARDIAN_FROM_CHILD_SUCCESS';

export const UPDATE_LEGAL_GUARDIAN_IN_CHILD = 'UPDATE_LEGAL_GUARDIAN_IN_CHILD';
export const UPDATE_LEGAL_GUARDIAN_IN_CHILD_CANCEL = 'UPDATE_LEGAL_GUARDIAN_IN_CHILD_CANCEL';
export const UPDATE_LEGAL_GUARDIAN_IN_CHILD_SUBMIT = 'UPDATE_LEGAL_GUARDIAN_IN_CHILD_SUBMIT';
export const UPDATE_LEGAL_GUARDIAN_IN_CHILD_SUCCESS = 'UPDATE_LEGAL_GUARDIAN_IN_CHILD_SUCCESS';
export const UPDATE_LEGAL_GUARDIAN_IN_CHILD_FAILURE = 'UPDATE_LEGAL_GUARDIAN_IN_CHILD_FAILURE';

export const ADD_GUARDIAN_TO_CHILD = 'ADD_GUARDIAN_TO_CHILD';
export const ADD_GUARDIAN_TO_CHILD_CANCEL = 'ADD_GUARDIAN_TO_CHILD_CANCEL';
export const ADD_GUARDIAN_TO_CHILD_SUBMIT = 'ADD_GUARDIAN_TO_CHILD_SUBMIT';
export const ADD_GUARDIAN_TO_CHILD_SUCCESS = 'ADD_GUARDIAN_TO_CHILD_SUCCESS';
export const ADD_GUARDIAN_TO_CHILD_FAILURE = 'ADD_GUARDIAN_TO_CHILD_FAILURE';

export const REMOVE_GUARDIAN_FROM_CHILD = 'REMOVE_GUARDIAN_FROM_CHILD';
export const REMOVE_GUARDIAN_FROM_CHILD_SUCCESS = 'REMOVE_GUARDIAN_FROM_CHILD_SUCCESS';

export const AUTHORIZE_GUARDIAN_ON_CHILD = 'AUTHORIZE_GUARDIAN_ON_CHILD';
export const AUTHORIZE_GUARDIAN_ON_CHILD_SUCCESS = 'AUTHORIZE_GUARDIAN_ON_CHILD_SUCCESS';
export const UNAUTHORIZE_GUARDIAN_ON_CHILD = 'UNAUTHORIZE_GUARDIAN_ON_CHILD';
export const UNAUTHORIZE_GUARDIAN_ON_CHILD_SUCCESS = 'UNAUTHORIZE_GUARDIAN_ON_CHILD_SUCCESS';

export const UPDATE_GUARDIAN_IN_CHILD = 'UPDATE_GUARDIAN_IN_CHILD';
export const UPDATE_GUARDIAN_IN_CHILD_CANCEL = 'UPDATE_GUARDIAN_IN_CHILD_CANCEL';
export const UPDATE_GUARDIAN_IN_CHILD_SUBMIT = 'UPDATE_GUARDIAN_IN_CHILD_SUBMIT';
export const UPDATE_GUARDIAN_IN_CHILD_SUCCESS = 'UPDATE_GUARDIAN_IN_CHILD_SUCCESS';
export const UPDATE_GUARDIAN_IN_CHILD_FAILURE = 'UPDATE_GUARDIAN_IN_CHILD_FAILURE';

export const LOAD_LEGAL_GUARDIANS = 'LOAD_LEGAL_GUARDIANS';
export const LOAD_LEGAL_GUARDIANS_SUCCESS = 'LOAD_LEGAL_GUARDIANS_SUCCESS';
export const LOAD_LEGAL_GUARDIANS_FAILURE = 'LOAD_LEGAL_GUARDIANS_FAILURE';

export const REMOVE_LEGAL_GUARDIAN = 'REMOVE_LEGAL_GUARDIAN';
export const REMOVE_LEGAL_GUARDIAN_SUCCESS = 'REMOVE_LEGAL_GUARDIAN_SUCCESS';

export const ADD_OPINION_TO_CHILD = 'ADD_OPINION_TO_CHILD';
export const ADD_OPINION_TO_CHILD_CANCEL = 'ADD_OPINION_TO_CHILD_CANCEL';
export const ADD_OPINION_TO_CHILD_SUBMIT = 'ADD_OPINION_TO_CHILD_SUBMIT';
export const ADD_OPINION_TO_CHILD_SUCCESS = 'ADD_OPINION_TO_CHILD_SUCCESS';
export const ADD_OPINION_TO_CHILD_FAILURE = 'ADD_OPINION_TO_CHILD_FAILURE';

export const ADD_VACATION_PERIOD_TO_CHILD = 'ADD_VACATION_PERIOD_TO_CHILD';
export const ADD_VACATION_PERIOD_TO_CHILD_CANCEL = 'ADD_VACATION_PERIOD_TO_CHILD_CANCEL';
export const ADD_VACATION_PERIOD_TO_CHILD_SUBMIT = 'ADD_VACATION_PERIOD_TO_CHILD_SUBMIT';
export const ADD_VACATION_PERIOD_TO_CHILD_SUCCESS = 'ADD_VACATION_PERIOD_TO_CHILD_SUCCESS';
export const ADD_VACATION_PERIOD_TO_CHILD_FAILURE = 'ADD_VACATION_PERIOD_TO_CHILD_FAILURE';

export const REMOVE_VACATION_PERIOD_FROM_CHILD = 'REMOVE_VACATION_PERIOD_FROM_CHILD';
export const REMOVE_VACATION_PERIOD_FROM_CHILD_CANCEL = 'REMOVE_VACATION_PERIOD_FROM_CHILD_CANCEL';
export const REMOVE_VACATION_PERIOD_FROM_CHILD_SUBMIT = 'REMOVE_VACATION_PERIOD_FROM_CHILD_SUBMIT';
export const REMOVE_VACATION_PERIOD_FROM_CHILD_SUCCESS = 'REMOVE_VACATION_PERIOD_FROM_CHILD_SUCCESS';
export const REMOVE_VACATION_PERIOD_FROM_CHILD_FAILURE = 'REMOVE_VACATION_PERIOD_FROM_CHILD_FAILURE';

export const LOAD_CHILDREN_ON_VACATION_PERIOD = 'LOAD_CHILDREN_ON_VACATION_PERIOD';
export const LOAD_CHILDREN_ON_VACATION_PERIOD_SUCCESS = 'LOAD_CHILDREN_ON_VACATION_PERIOD_SUCCESS';
export const LOAD_CHILDREN_ON_VACATION_PERIOD_FAILURE = 'LOAD_CHILDREN_ON_VACATION_PERIOD_FAILURE';

export const ADD_SURVEY_TO_CHILD = 'ADD_SURVEY_TO_CHILD';
export const ADD_SURVEY_TO_CHILD_CANCEL = 'ADD_SURVEY_TO_CHILD_CANCEL';
export const ADD_SURVEY_TO_CHILD_SUBMIT = 'ADD_SURVEY_TO_CHILD_SUBMIT';
export const ADD_SURVEY_TO_CHILD_SUCCESS = 'ADD_SURVEY_TO_CHILD_SUCCESS';
export const ADD_SURVEY_TO_CHILD_FAILURE = 'ADD_SURVEY_TO_CHILD_FAILURE';

export const GET_CHILDREN_TAKEN_OVER_BY_DEBT_COLLECTION_SUBMIT = 'GET_CHILDREN_TAKEN_OVER_BY_DEBT_COLLECTION_SUBMIT';
export const GET_CHILDREN_TAKEN_OVER_BY_DEBT_COLLECTION_SUCCESS = 'GET_CHILDREN_TAKEN_OVER_BY_DEBT_COLLECTION_SUCCESS';
export const GET_CHILDREN_TAKEN_OVER_BY_DEBT_COLLECTION_FAILURE = 'GET_CHILDREN_TAKEN_OVER_BY_DEBT_COLLECTION_FAILURE';

export const TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION = 'TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION';
export const TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_CANCEL = 'TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_CANCEL';
export const TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_SUBMIT = 'TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_SUBMIT';
export const TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_SUCCESS = 'TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_SUCCESS';
export const TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_FAILURE = 'TAKE_OVER_BY_DEBT_COLLECTION_CONFIRMATION_FAILURE';

export const REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN = 'REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN';
export const REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_CANCEL =
  'REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_CANCEL';
export const REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_SUBMIT =
  'REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_SUBMIT';
export const REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_SUCCESS =
  'REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_SUCCESS';
export const REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_FAILURE =
  'REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_FAILURE';

//child diagnostic scheme

export const ASSIGN_CHARGING_SCHEME_TO_CHILD = 'ASSIGN_CHARGING_SCHEME_TO_CHILD';
export const ASSIGN_CHARGING_SCHEME_TO_CHILD_CANCEL = 'ASSIGN_CHARGING_SCHEME_TO_CHILD_CANCEL';
export const ASSIGN_CHARGING_SCHEME_TO_CHILD_SUBMIT = 'ASSIGN_CHARGING_SCHEME_TO_CHILD_SUBMIT';
export const ASSIGN_CHARGING_SCHEME_TO_CHILD_SUCCESS = 'ASSIGN_CHARGING_SCHEME_TO_CHILD_SUCCESS';
export const ASSIGN_CHARGING_SCHEME_TO_CHILD_FAILURE = 'ASSIGN_CHARGING_SCHEME_TO_CHILD_FAILURE';
export const REMOVE_CHARGING_SCHEME_FROM_CHILD = 'REMOVE_CHARGING_SCHEME_FROM_CHILD';
export const REMOVE_CHARGING_SCHEME_FROM_CHILD_SUCCESS = 'REMOVE_CHARGING_SCHEME_FROM_CHILD_SUCCESS';

export const UPDATE_CHILD_OPENING_BALANCE = 'UPDATE_CHILD_OPENING_BALANCE';
export const UPDATE_CHILD_OPENING_BALANCE_CANCEL = 'UPDATE_CHILD_OPENING_BALANCE_CANCEL';
export const UPDATE_CHILD_OPENING_BALANCE_SUBMIT = 'UPDATE_CHILD_OPENING_BALANCE_SUBMIT';
export const UPDATE_CHILD_OPENING_BALANCE_SUCCESS = 'UPDATE_CHILD_OPENING_BALANCE_SUCCESS';
export const UPDATE_CHILD_OPENING_BALANCE_FAILURE = 'UPDATE_CHILD_OPENING_BALANCE_FAILURE';

//Meals actions
//load all
export const LOAD_MEALS_SUCCESS = 'LOAD_MEALS_SUCCESS';
export const LOAD_MEALS_FAILURE = 'LOAD_MEALS_FAILURE';

export const LOAD_MEAL_DETAILS_SUCCESS = 'LOAD_MEAL_DETAILS_SUCCESS';
export const LOAD_MEAL_DETAILS_FAILURE = 'LOAD_MEAL_DETAILS_FAILURE';

//create
export const CREATE_MEAL_DETAILS = 'CREATE_MEAL_DETAILS';
export const CREATE_MEAL_DETAILS_CANCEL = 'CREATE_MEAL_DETAILS_CANCEL';
export const CREATE_MEAL_DETAILS_SUBMIT = 'CREATE_MEAL_DETAILS_SUBMIT';
export const CREATE_MEAL_DETAILS_SUCCESS = 'CREATE_MEAL_DETAILS_SUCCESS';
export const CREATE_MEAL_DETAILS_FAILURE = 'CREATE_MEAL_DETAILS_FAILURE';

//update
export const UPDATE_MEAL_DETAILS = 'UPDATE_MEAL_DETAILS';
export const UPDATE_MEAL_DETAILS_CANCEL = 'UPDATE_MEAL_DETAILS_CANCEL';
export const UPDATE_MEAL_DETAILS_SUBMIT = 'UPDATE_MEAL_DETAILS_SUBMIT';
export const UPDATE_MEAL_DETAILS_SUCCESS = 'UPDATE_MEAL_DETAILS_SUCCESS';
export const UPDATE_MEAL_DETAILS_FAILURE = 'UPDATE_MEAL_DETAILS_FAILURE';

//Meal categories actions
//load all
export const LOAD_MEALS_CATEGORIES_SUCCESS = 'LOAD_MEALS_CATEGORIES_SUCCESS';
export const LOAD_MEALS_CATEGORIES_FAILURE = 'LOAD_MEALS_CATEGORIES_FAILURE';

//archive
export const ARCHIVE_MEAL_SUCCESS = 'ARCHIVE_MEAL_SUCCESS';
export const ARCHIVE_MEAL_FAILURE = 'ARCHIVE_MEAL_FAILURE';

export const LOAD_ARCHIVED_MEALS_SUCCESS = 'LOAD_ARCHIVED_MEAL_SUCCESS';
export const LOAD_ARCHIVED_MEALS_FAILURE = 'LOAD_ARCHIVED_MEAL_FAILURE';

//create
export const CREATE_MEAL_CATEGORY = 'CREATE_MEAL_CATEGORY';
export const CREATE_MEAL_CATEGORY_CANCEL = 'CREATE_MEAL_CATEGORY_CANCEL';
export const CREATE_MEAL_CATEGORY_SUBMIT = 'CREATE_MEAL_CATEGORY_SUBMIT';
export const CREATE_MEAL_CATEGORY_SUCCESS = 'CREATE_MEAL_CATEGORY_SUCCESS';
export const CREATE_MEAL_CATEGORY_FAILURE = 'CREATE_MEAL_CATEGORY_FAILURE';

export const REMOVE_MEAL_FROM_CATEGORY_SUCCESS = 'REMOVE_MEAL_FROM_CATEGORY_SUCCESS';
export const REMOVE_MEAL_FROM_CATEGORY_FAILURE = 'REMOVE_MEAL_FROM_CATEGORY_FAILURE';

export const ADD_MEAL_TO_CATEGORY_SUCCESS = 'ADD_MEAL_TO_CATEGORY_SUCCESS';
export const ADD_MEAL_TO_CATEGORY_FAILURE = 'ADD_MEAL_TO_CATEGORY_FAILURE';

//delete
export const DELETE_MEAL_CATEGORY_SUCCESS = 'DELETE_MEAL_CATEGORY_SUCCESS';
export const DELETE_MEAL_CATEGORY_FAILURE = 'DELETE_MEAL_CATEGORY_FAILURE';

//Meal calendar actions

export const LOAD_CHILDREN_CALENDAR_MEALS_SUCCESS = 'LOAD_CHILDREN_CALENDAR_MEALS_SUCCESS';
export const LOAD_CHILDREN_CALENDAR_MEALS_FAILURE = 'LOAD_CHILDREN_CALENDAR_MEALS_FAILURE';

export const UPDATE_CHILDREN_CALENDAR_MEALS_SUCCESS = 'UPDATE_CHILDREN_CALENDAR_MEALS_SUCCESS';
export const UPDATE_CHILDREN_CALENDAR_MEALS_FAILURE = 'UPDATE_CHILDREN_CALENDAR_MEALS_FAILURE';

// Diet actions
export const LOAD_DIETS_SUCCESS = 'LOAD_DIETS_SUCCESS';
export const LOAD_DIETS_FAILURE = 'LOAD_DIETS_FAILURE';

export const LOAD_DIET_DETAILS_SUCCESS = 'LOAD_DIET_DETAILS_SUCCESS';
export const LOAD_DIET_DETAILS_FAILURE = 'LOAD_DIET_DETAILS_FAILURE';

export const CREATE_DIET = 'CREATE_DIET';
export const CREATE_DIET_CANCEL = 'CREATE_DIET_CANCEL';
export const CREATE_DIET_SUBMIT = 'CREATE_DIET_SUBMIT';
export const CREATE_DIET_SUCCESS = 'CREATE_DIET_SUCCESS';
export const CREATE_DIET_FAILURE = 'CREATE_DIET_FAILURE';

export const UPDATE_DIET = 'UPDATE_DIET';
export const UPDATE_DIET_CANCEL = 'UPDATE_DIET_CANCEL';
export const UPDATE_DIET_SUBMIT = 'UPDATE_DIET_SUBMIT';
export const UPDATE_DIET_SUCCESS = 'UPDATE_DIET_SUCCESS';
export const UPDATE_DIET_FAILURE = 'UPDATE_DIET_FAILURE';

export const ENROLL_CHILDREN_TO_DIET_SUCCESS = 'ENROLL_CHILDREN_TO_DIET_SUCCESS';
export const ENROLL_CHILDREN_TO_DIET_FAILURE = 'ENROLL_CHILDREN_TO_DIET_FAILURE';

export const UNROLL_CHILDREN_TO_DIET_SUCCESS = 'UNROLL_CHILDREN_TO_DIET_SUCCESS';
export const UNROLL_CHILDREN_TO_DIET_FAILURE = 'UNROLL_CHILDREN_TO_DIET_FAILURE';

export const CHANGE_CHILD_ENROLLMENT_TO_DIET_SUCCESS = 'CHANGE_CHILD_ENROLLMENT_TO_DIET_SUCCESS';
export const CHANGE_CHILD_ENROLLMENT_TO_DIET_FAILURE = 'CHANGE_CHILD_ENROLLMENT_TO_DIET_FAILURE';

//Staff Members actions
//load all
export const LOAD_STAFF_MEMBERS = 'LOAD_STAFF_MEMBERS';
export const LOAD_STAFF_MEMBERS_SUCCESS = 'LOAD_STAFF_MEMBERS_SUCCESS';

export const LOAD_STAFF_MEMBERS_ARCHIVE = 'LOAD_STAFF_MEMBERS_ARCHIVE';
export const LOAD_STAFF_MEMBERS_ARCHIVE_SUCCESS = 'LOAD_STAFF_MEMBERS_ARCHIVE_SUCCESS';

//details
export const LOAD_STAFF_MEMBER_DETAILS = 'LOAD_STAFF_MEMBER_DETAILS';
export const LOAD_STAFF_MEMBER_DETAILS_SUCCESS = 'LOAD_STAFF_MEMBER_DETAILS_SUCCESS';

export const LOAD_STAFF_MEMBER_DETAILS_ARCHIVE = 'LOAD_STAFF_MEMBER_DETAILS_ARCHIVE';
export const LOAD_STAFF_MEMBER_DETAILS_ARCHIVE_SUCCESS = 'LOAD_STAFF_MEMBER_DETAILS_ARCHIVE_SUCCESS';
// create
export const CREATE_STAFF_MEMBER = 'CREATE_STAFF_MEMBER';
export const CREATE_STAFF_MEMBER_CANCEL = 'CREATE_STAFF_MEMBER_CANCEL';
export const CREATE_STAFF_MEMBER_SUBMIT = 'CREATE_STAFF_MEMBER_SUBMIT';
export const CREATE_STAFF_MEMBER_SUCCESS = 'CREATE_STAFF_MEMBER_SUCCESS';
export const CREATE_STAFF_MEMBER_FAILURE = 'CREATE_STAFF_MEMBER_FAILURE';
// update
export const UPDATE_STAFF_MEMBER = 'UPDATE_STAFF_MEMBER';
export const UPDATE_STAFF_MEMBER_CANCEL = 'UPDATE_STAFF_MEMBER_CANCEL';
export const UPDATE_STAFF_MEMBER_SUBMIT = 'UPDATE_STAFF_MEMBER_SUBMIT';
export const UPDATE_STAFF_MEMBER_SUCCESS = 'UPDATE_STAFF_MEMBER_SUCCESS';
export const UPDATE_STAFF_MEMBER_FAILURE = 'UPDATE_STAFF_MEMBER_FAILURE';
//unit
export const ENABLE_TEACHER_GROUP_ACCESS = 'ENABLE_TEACHER_GROUP_ACCESS';
export const DISABLE_TEACHER_GROUP_ACCESS = 'DISABLE_TEACHER_GROUP_ACCESS';
export const ENABLE_ALLOW_DEBT_COLLECTION = 'ENABLE_ALLOW_DEBT_COLLECTION';
export const DISABLE_ALLOW_DEBT_COLLECTION = 'DISABLE_ALLOW_DEBT_COLLECTION';
export const ENABLE_ALLOW_ADAPTATION = 'ENABLE_ALLOW_ADAPTATION';
export const DISABLE_ALLOW_ADAPTATION = 'DISABLE_ALLOW_ADAPTATION';
export const ENABLE_TEACHER_POSTS_ACCESS = 'ENABLE_TEACHER_POSTS_ACCESS';
export const DISABLE_TEACHER_POSTS_ACCESS = 'DISABLE_TEACHER_POSTS_ACCESS';
export const ENABLE_LEGAL_GUARDIAN_BOARDPOST_ACCESS = 'ENABLE_LEGAL_GUARDIAN_BOARDPOST_ACCESS';
export const DISABLE_LEGAL_GUARDIAN_BOARDPOST_ACCESS = 'DISABLE_LEGAL_GUARDIAN_BOARDPOST_ACCESS';
export const ENABLE_SENDING_OVERDUES_NOTIFICATIONS = 'ENABLE_SENDING_OVERDUES_NOTIFICATIONS';
export const DISABLE_SENDING_OVERDUES_NOTIFICATIONS = 'DISABLE_SENDING_OVERDUES_NOTIFICATIONS';
export const ENABLE_SENDING_CHILD_PICKED_UP_NOTIFICATION_BLOCKED =
  'ENABLE_SENDING_CHILD_PICKED_UP_NOTIFICATION_BLOCKED';
export const DISABLE_SENDING_CHILD_PICKED_UP_NOTIFICATION_BLOCKED =
  'DISABLE_SENDING_CHILD_PICKED_UP_NOTIFICATION_BLOCKED';
export const ENABLE_EDITING_CHILD_DATA_BY_TEACHER_BLOCKED = 'ENABLE_EDITING_CHILD_DATA_BY_TEACHER_BLOCKED';
export const DISABLE_EDITING_CHILD_DATA_BY_TEACHER_BLOCKED = 'DISABLE_EDITING_CHILD_DATA_BY_TEACHER_BLOCKED';
export const ENABLE_MEALS_CATERING_DUES = 'ENABLE_MEALS_CATERING_DUES';
export const DISABLE_MEALS_CATERING_DUES = 'DISABLE_MEALS_CATERING_DUES';

// charging schemes
export const ASSIGN_CHARGING_SCHEME_TO_STAFF = 'ASSIGN_CHARGING_SCHEME_TO_STAFF';
export const ASSIGN_CHARGING_SCHEME_TO_STAFF_CANCEL = 'ASSIGN_CHARGING_SCHEME_TO_STAFF_CANCEL';
export const ASSIGN_CHARGING_SCHEME_TO_STAFF_SUBMIT = 'ASSIGN_CHARGING_SCHEME_TO_STAFF_SUBMIT';
export const ASSIGN_CHARGING_SCHEME_TO_STAFF_SUCCESS = 'ASSIGN_CHARGING_SCHEME_TO_STAFF_SUCCESS';
export const ASSIGN_CHARGING_SCHEME_TO_STAFF_FAILURE = 'ASSIGN_CHARGING_SCHEME_TO_STAFF_FAILURE';
export const REMOVE_CHARGING_SCHEME_FROM_STAFF = 'REMOVE_CHARGING_SCHEME_FROM_STAFF';
export const REMOVE_CHARGING_SCHEME_FROM_STAFF_SUCCESS = 'REMOVE_CHARGING_SCHEME_FROM_STAFF_SUCCESS ';
// archive
export const ARCHIVE_STAFF_MEMBER = 'ARCHIVE_STAFF_MEMBER';
export const ARCHIVE_STAFF_MEMBER_SUCCESS = 'ARCHIVE_STAFF_MEMBER_SUCCESS';

// Authentication
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE_INVALID_CREDENTIALS = 'SIGN_IN_FAILURE_INVALID_CREDENTIALS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_IN_PASSWORD_CHANGE_REQUIRED = 'SIGN_IN_PASSWORD_CHANGE_REQUIRED';
export const SIGNED_OUT = 'SIGNED_OUT';
export const SIGN_IN_FAILURE_LOGIN_GOV = 'SIGN_IN_FAILURE_LOGIN_GOV';
export const SIGN_IN_LOGIN_GOV_FAILURE_INVALID_CREDENTIALS = 'SIGN_IN_LOGIN_GOV_FAILURE_INVALID_CREDENTIALS';
export const SIGN_IN_GOV_SUCCESS = 'SIGN_IN_GOV_SUCCESS';

// User account
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

export const UPDATE_USER_PROFILE_PHOTO = 'UPDATE_USER_PROFILE_PHOTO';
export const UPDATE_USER_PROFILE_PHOTO_SUCCESS = 'UPDATE_USER_PROFILE_PHOTO_SUCCESS';

export const REMOVE_USER_PROFILE_PHOTO = 'REMOVE_USER_PROFILE_PHOTO';
export const REMOVE_USER_PROFILE_PHOTO_SUCCESS = 'REMOVE_USER_PROFILE_PHOTO_SUCCESS';

export const LOAD_USER_STATE = 'LOAD_USER_STATE';
export const LOAD_USER_STATE_SUCCESS = 'LOAD_USER_STATE_SUCCESS';

export const RESTART_ACCOUNT_ACTIVATION = 'RESTART_ACCOUNT_ACTIVATION';
export const RESTART_ACCOUNT_ACTIVATION_SUCCESS = 'RESTART_ACCOUNT_ACTIVATION_SUCCESS';
export const RESTART_ACCOUNT_ACTIVATION_FAILURE = 'RESTART_ACCOUNT_ACTIVATION_FAILURE';

export const RESET_ACCOUNT_PASSWORD = 'RESET_ACCOUNT_PASSWORD';
export const RESET_ACCOUNT_PASSWORD_SUCCESS = 'RESET_ACCOUNT_PASSWORD_SUCCESS';
export const RESET_ACCOUNT_PASSWORD_FAILURE = 'RESET_ACCOUNT_PASSWORD_FAILURE';

// Initialization
export const PRELOAD_DATA = 'PRELOAD_DATA';
export const PRELOAD_DATA_SUCCESS = 'PRELOAD_DATA_SUCCESS';
export const CLEANUP_DATA = 'CLEANUP_DATA';
export const PRELOAD_ON_NAVIGATION = 'PRELOAD_ON_NAVIGATION';
export const PRELOAD_ON_NAVIGATION_SUCCESS = 'PRELOAD_ON_NAVIGATION_SUCCESS';

// Navigation
export const NAVIGATE_REQUEST = 'NAVIGATE_REQUEST';

//Message Board
export const LOAD_MESSAGE_BOARD_POSTS = 'LOAD_MESSAGE_BOARD_POSTS';
export const LOAD_MESSAGE_BOARD_POSTS_SUCCESS = 'LOAD_MESSAGE_BOARD_POSTS_SUCCESS';
export const LOAD_NEXT_MESSAGE_BOARD_POSTS = 'LOAD_NEXT_MESSAGE_BOARD_POSTS';
export const LOAD_NEXT_MESSAGE_BOARD_POSTS_SUCCESS = 'LOAD_NEXT_MESSAGE_BOARD_POSTS_SUCCESS';

export const LOAD_MESSAGE_BOARD_POSTS_TO_ME = 'LOAD_MESSAGE_BOARD_POSTS_TO_ME';
export const LOAD_MESSAGE_BOARD_POSTS_TO_ME_SUCCESS = 'LOAD_MESSAGE_BOARD_POSTS_TO_ME_SUCCESS';
export const LOAD_NEXT_MESSAGE_BOARD_POSTS_TO_ME = 'LOAD_NEXT_MESSAGE_BOARD_POSTS_TO_ME';
export const LOAD_NEXT_MESSAGE_BOARD_POSTS_TO_ME_SUCCESS = 'LOAD_NEXT_MESSAGE_BOARD_POSTS_TO_ME_SUCCESS';

export const LOAD_LATEST_MESSAGE_BOARD_EVENTS = 'LOAD_LATEST_MESSAGE_BOARD_EVENTS';
export const LOAD_LATEST_MESSAGE_BOARD_EVENTS_SUCCESS = 'LOAD_LATEST_MESSAGE_BOARD_EVENTS_SUCCESS';
export const LOAD_LATEST_MESSAGE_BOARD_EVENTS_TO_ME = 'LOAD_LATEST_MESSAGE_BOARD_EVENTS_TO_ME';
export const LOAD_LATEST_MESSAGE_BOARD_EVENTS_TO_ME_SUCCESS = 'LOAD_LATEST_MESSAGE_BOARD_EVENTS_TO_ME_SUCCESS';

export const LOAD_MESSAGE_BOARD_EVENTS_AT_MONTH = 'LOAD_MESSAGE_BOARD_EVENTS_AT_MONTH';
export const LOAD_MESSAGE_BOARD_EVENTS_AT_MONTH_SUCCESS = 'LOAD_MESSAGE_BOARD_EVENTS_AT_MONTH_SUCCESS';

export const LOAD_MESSAGE_BOARD_EVENTS_AT_DAY = 'LOAD_MESSAGE_BOARD_EVENTS_AT_DAY';
export const LOAD_MESSAGE_BOARD_EVENTS_AT_DAY_SUCCESS = 'LOAD_MESSAGE_BOARD_EVENTS_AT_DAY_SUCCESS';

export const LOAD_MESSAGE_BOARD_EVENTS_TO_ME_AT_MONTH = 'LOAD_MESSAGE_BOARD_EVENTS_TO_ME_AT_MONTH';
export const LOAD_MESSAGE_BOARD_EVENTS_TO_ME_AT_MONTH_SUCCESS = 'LOAD_MESSAGE_BOARD_EVENTS_TO_ME_AT_MONTH_SUCCESS';

export const LOAD_MESSAGE_BOARD_EVENTS_TO_ME_AT_DAY = 'LOAD_MESSAGE_BOARD_EVENTS_TO_ME_AT_DAY';
export const LOAD_MESSAGE_BOARD_EVENTS_TO_ME_AT_DAY_SUCCESS = 'LOAD_MESSAGE_BOARD_EVENTS_TO_ME_AT_DAY_SUCCESS';

export const CREATE_MESSAGE_BOARD_POST = 'CREATE_MESSAGE_BOARD_POST';
export const CREATE_MESSAGE_BOARD_POST_CANCEL = 'CREATE_MESSAGE_BOARD_POST_CANCEL';
export const CREATE_MESSAGE_BOARD_POST_SUBMIT = 'CREATE_MESSAGE_BOARD_POST_SUBMIT';
export const CREATE_MESSAGE_BOARD_POST_SUCCESS = 'CREATE_MESSAGE_BOARD_POST_SUCCESS';
export const CREATE_MESSAGE_BOARD_POST_FAILURE = 'CREATE_MESSAGE_BOARD_POST_FAILURE';

export const UPDATE_MESSAGE_BOARD_POST = 'UPDATE_MESSAGE_BOARD_POST';
export const UPDATE_MESSAGE_BOARD_POST_CANCEL = 'UPDATE_MESSAGE_BOARD_POST_CANCEL';
export const UPDATE_MESSAGE_BOARD_POST_SUBMIT = 'UPDATE_MESSAGE_BOARD_POST_SUBMIT';
export const UPDATE_MESSAGE_BOARD_POST_SUCCESS = 'UPDATE_MESSAGE_BOARD_POST_SUCCESS';
export const UPDATE_MESSAGE_BOARD_POST_FAILURE = 'UPDATE_MESSAGE_BOARD_POST_FAILURE';

export const DELETE_MESSAGE_BOARD_POST = 'DELETE_MESSAGE_BOARD_POST';
export const DELETE_MESSAGE_BOARD_POST_SUCCESS = ' DELETE_MESSAGE_BOARD_POST_SUCCESS';

export const FILTER_MESSAGE_BOARD_START = 'FILTER_MESSAGE_BOARD_START';
export const FILTER_MESSAGE_BOARD_CANCEL = 'FILTER_MESSAGE_BOARD_CANCEL';
export const FILTER_MESSAGE_BOARD_SUCCESS = 'FILTER_MESSAGE_BOARD_SUCCESS';
export const FILTER_MESSAGE_BOARD_FAILURE = 'FILTER_MESSAGE_BOARD_FAILURE';

export const POST_SEEN_BY = 'POST_SEEN_BY';
export const POST_SEEN_BY_SUCCESS = 'POST_SEEN_BY_SUCCESS';
export const POST_SEEN_BY_FAILURE = 'POST_SEEN_BY_FAILURE';

export const READALL_STAFF_MESSAGE_BOARD_START = 'READALL_MESSAGE_BOARD_START';
export const READALL_STAFF_MESSAGE_BOARD_SUCCESS = 'READALL_STAFF_MESSAGE_BOARD_SUCCESS';
export const READALL_STAFF_MESSAGE_BOARD_FAILURE = 'READALL_STAFF_MESSAGE_BOARD_FAILURE';

export const LOAD_UNREADED_POSTS_START = 'LOAD_UNREADED_POSTS_START';
export const LOAD_UNREADED_POSTS_SUCCESS = 'LOAD_UNREADED_POSTS_SUCCESS';
export const LOAD_UNREADED_POSTS_FAILURE = 'LOAD_UNREADED_POSTS_FAILURE';

//Message Board Digital Diary

export const LOAD_LATEST_MESSAGE_BOARD_DIGITALDIARY = 'LOAD_LATEST_MESSAGE_BOARD_DIGITALDIARY';
export const LOAD_LATEST_MESSAGE_BOARD_DIGITALDIARY_SUCCESS = 'LOAD_LATEST_MESSAGE_BOARD_DIGITALDIARY_SUCCESS';
export const LOAD_LATEST_MESSAGE_BOARD_DIGITALDIARY_TO_ME = 'LOAD_LATEST_MESSAGE_BOARD_DIGITALDIARY_TO_ME';
export const LOAD_LATEST_MESSAGE_BOARD_DIGITALDIARY_TO_ME_SUCCESS =
  'LOAD_LATEST_MESSAGE_BOARD_DIGITALDIARY_TO_ME_SUCCESS';

export const LOAD_MESSAGE_BOARD_DIGITALDIARY_AT_MONTH = 'LOAD_MESSAGE_BOARD_DIGITALDIARY_AT_MONTH';
export const LOAD_MESSAGE_BOARD_DIGITALDIARY_AT_MONTH_SUCCESS = 'LOAD_MESSAGE_BOARD_DIGITALDIARY_AT_MONTH_SUCCESS';

export const LOAD_MESSAGE_BOARD_DIGITALDIARY_AT_DAY = 'LOAD_MESSAGE_BOARD_DIGITALDIARY_AT_DAY';
export const LOAD_MESSAGE_BOARD_DIGITALDIARY_AT_DAY_SUCCESS = 'LOAD_MESSAGE_BOARD_DIGITALDIARY_AT_DAY_SUCCESS';

export const LOAD_MESSAGE_BOARD_DIGITALDIARY_TO_ME_AT_MONTH = 'LOAD_MESSAGE_BOARD_DIGITALDIARY_TO_ME_AT_MONTH';
export const LOAD_MESSAGE_BOARD_DIGITALDIARY_TO_ME_AT_MONTH_SUCCESS =
  'LOAD_MESSAGE_BOARD_DIGITALDIARY_TO_ME_AT_MONTH_SUCCESS';

export const LOAD_MESSAGE_BOARD_DIGITALDIARY_TO_ME_AT_DAY = 'LOAD_MESSAGE_BOARD_DIGITALDIARY_TO_ME_AT_DAY';
export const LOAD_MESSAGE_BOARD_DIGITALDIARY_TO_ME_AT_DAY_SUCCESS =
  'LOAD_MESSAGE_BOARD_DIGITALDIARY_TO_ME_AT_DAY_SUCCESS';

export const CREATE_MESSAGE_BOARD_DIGITALDIARY = 'CREATE_MESSAGE_BOARD_DIGITALDIARY';
export const CREATE_MESSAGE_BOARD_DIGITALDIARY_CANCEL = 'CREATE_MESSAGE_BOARD_DIGITALDIARY_CANCEL';
export const CREATE_MESSAGE_BOARD_DIGITALDIARY_SUBMIT = 'CREATE_MESSAGE_BOARD_DIGITALDIARY_SUBMIT';
export const CREATE_MESSAGE_BOARD_DIGITALDIARY_SUCCESS = 'CREATE_MESSAGE_BOARD_DIGITALDIARY_SUCCESS';
export const CREATE_MESSAGE_BOARD_DIGITALDIARY_FAILURE = 'CREATE_MESSAGE_BOARD_DIGITALDIARY_FAILURE';
export const UPDATE_MESSAGE_BOARD_DIGITALDIARY = 'UPDATE_MESSAGE_BOARD_DIGITALDIARY';
export const UPDATE_MESSAGE_BOARD_DIGITALDIARY_CANCEL = 'UPDATE_MESSAGE_BOARD_DIGITALDIARY_CANCEL';
export const UPDATE_MESSAGE_BOARD_DIGITALDIARY_SUBMIT = 'UPDATE_MESSAGE_BOARD_DIGITALDIARY_SUBMIT';
export const UPDATE_MESSAGE_BOARD_DIGITALDIARY_SUCCESS = 'UPDATE_MESSAGE_BOARD_DIGITALDIARY_SUCCESS';
export const UPDATE_MESSAGE_BOARD_DIGITALDIARY_FAILURE = 'UPDATE_MESSAGE_BOARD_DIGITALDIARY_FAILURE';
export const DELETE_MESSAGE_BOARD_DIGITALDIARY = 'DELETE_MESSAGE_BOARD_DIGITALDIARY';
export const DELETE_MESSAGE_BOARD_DIGITALDIARY_SUCCESS = ' DELETE_MESSAGE_BOARD_DIGITALDIARY_SUCCESS';

export const DIGITAL_DIARY_POSTS_FILTER_SUCCESS = 'DIGITAL_DIARY_POSTS_FILTER_SUCCESS';
export const DIGITAL_DIARY_POSTS_FILTER_FAILURE = 'DIGITAL_DIARY_POSTS_FILTER_FAILURE';

//Attendance

export const REPORT_CHILD_DROPPED_OFF = 'REPORT_CHILD_DROPPED_OFF';
export const REPORT_CHILD_DROPPED_OFF_CANCEL = 'REPORT_CHILD_DROPPED_OFF_CANCEL';
export const REPORT_CHILD_DROPPED_OFF_SUBMIT = 'REPORT_CHILD_DROPPED_OFF_SUBMIT';
export const REPORT_CHILD_DROPPED_OFF_SUCCESS = 'REPORT_CHILD_DROPPED_OFF_SUCCESS';
export const REPORT_CHILD_DROPPED_OFF_FAILURE = 'REPORT_CHILD_DROPPED_OFF_FAILURE';

export const REPORT_CHILD_ATTENDANCE_NO_HOURS_SUCCESS = 'REPORT_CHILD_ATTENDANCE_NO_HOURS_SUCCESS';
export const REPORT_CHILD_ATTENDANCE_NO_HOURS_FAILURE = 'REPORT_CHILD_ATTENDANCE_NO_HOURS_FAILURE';

export const REPORT_CHILD_PICKED_UP = 'REPORT_CHILD_PICKED_UP';
export const REPORT_CHILD_PICKED_UP_CANCEL = 'REPORT_CHILD_PICKED_UP_CANCEL';
export const REPORT_CHILD_PICKED_UP_SUBMIT = 'REPORT_CHILD_PICKED_UP_SUBMIT';
export const REPORT_CHILD_PICKED_UP_SUCCESS = 'REPORT_CHILD_PICKED_UP_SUCCESS';
export const REPORT_CHILD_PICKED_UP_FAILURE = 'REPORT_CHILD_PICKED_UP_FAILURE';

export const REPORT_CHILD_ABSENCE = 'REPORT_CHILD_ABSENCE';
export const REPORT_CHILD_ABSENCE_CANCEL = 'REPORT_CHILD_ABSENCE_CANCEL';
export const REPORT_CHILD_ABSENCE_SUBMIT = 'REPORT_CHILD_ABSENCE_SUBMIT';
export const REPORT_CHILD_ABSENCE_SUCCESS = 'REPORT_CHILD_ABSENCE_SUCCESS';
export const REPORT_CHILD_ABSENCE_FAILURE = 'REPORT_CHILD_ABSENCE_FAILURE';

export const REPORT_CHILD_ACTIVITY_ATTENDANCE_SUBMIT = 'REPORT_CHILD_ACTIVITY_ATTENDANCE_SUBMIT';
export const REPORT_CHILD_ACTIVITY_ATTENDANCE_SUCCESS = 'REPORT_CHILD_ACTIVITY_ATTENDANCE_SUCCESS';
export const REPORT_CHILD_ACTIVITY_ATTENDANCE_FAILURE = 'REPORT_CHILD_ACTIVITY_ATTENDANCE_FAILURE';

export const REPORT_CHILD_ACTIVITY_ABSENCE_SUBMIT = 'REPORT_CHILD_ACTIVITY_ABSENCE_SUBMIT';
export const REPORT_CHILD_ACTIVITY_ABSENCE_SUCCESS = 'REPORT_CHILD_ACTIVITY_ABSENCE_SUCCESS';
export const REPORT_CHILD_ACTIVITY_ABSENCE_FAILURE = 'REPORT_CHILD_ACTIVITY_ABSENCE_FAILURE';

export const REVERT_CHILD_DROPPED_OFF_REPORT = 'REVERT_CHILD_DROPPED_OFF_REPORT';
export const REVERT_CHILD_DROPPED_OFF_REPORT_SUCCESS = 'REVERT_CHILD_DROPPED_OFF_REPORT_SUCCESS';

export const REVERT_CHILD_PICKED_UP_REPORT = 'REVERT_CHILD_PICKED_UP_REPORT';
export const REVERT_CHILD_PICKED_UP_REPORT_SUCCESS = 'REVERT_CHILD_PICKED_UP_REPORT_SUCCESS';

export const REVERT_CHILD_ABSENCE = 'REVERT_CHILD_ABSENCE';
export const REVERT_CHILD_ABSENCE_SUCCESS = 'REVERT_CHILD_ABSENCE_SUCCESS';

export const REVERT_CHILD_ATTENDANCE_SUCCESS = 'REVERT_CHILD_ATTENDANCE_SUCCESS';
export const REVERT_CHILD_ATTENDANCE_FAILURE = 'REVERT_CHILD_ATTENDANCE_FAILURE';

export const LOAD_CURRENT_ATTENDANCE = 'LOAD_CURRENT_ATTENDANCE';
export const LOAD_CURRENT_ATTENDANCE_SUCCESS = 'LOAD_CURRENT_ATTENDANCE_SUCCESS';

export const LOAD_PREVIOUS_ATTENDANCE = 'LOAD_PREVIOUS_ATTENDANCE';
export const LOAD_PREVIOUS_ATTENDANCE_SUCCESS = 'LOAD_PREVIOUS_ATTENDANCE_SUCCESS';

export const LOAD_ALL_CURRENT_ATTENDANCE = 'LOAD_ALL_CURRENT_ATTENDANCE';
export const LOAD_ALL_CURRENT_ATTENDANCE_SUCCESS = 'LOAD_ALL_CURRENT_ATTENDANCE_SUCCESS';

export const LOAD_ALL_PREVIOUS_ATTENDANCE = 'LOAD_ALL_PREVIOUS_ATTENDANCE';
export const LOAD_ALL_PREVIOUS_ATTENDANCE_SUCCESS = 'LOAD_ALL_PREVIOUS_ATTENDANCE_SUCCESS';

//Absence Tomorrow

export const LOAD_ABSENCE_TOMORROW = 'LOAD_ABSENCE_TOMORROW';

export const LOAD_ABSENCE_TOMORROW_SUCCESS = 'LOAD_ABSENCE_TOMORROW_SUCCESS';

//Attendance History

export const LOAD_ATTENDANCE_MONTHS = 'LOAD_ATTENDANCE_MONTHS';
export const LOAD_ATTENDANCE_MONTHS_SUCCESS = 'LOAD_ATTENDANCE_MONTHS_SUCCESS';

export const LOAD_ATTENDANCE_DAYS_IN_MONTH = 'LOAD_ATTENDANCE_DAYS_IN_MONTH';
export const LOAD_ATTENDANCE_DAYS_IN_MONTH_SUCCESS = 'LOAD_ATTENDANCE_DAYS_IN_MONTH_SUCCESS';

export const REVERT_PREVIOUS_CHILD_ATTENDANCE = 'REVERT_PREVIOUS_CHILD_ATTENDANCE';
export const REVERT_PREVIOUS_CHILD_ATTENDANCE_CANCEL = 'REVERT_PREVIOUS_CHILD_ATTENDANCE_CANCEL';
export const REVERT_PREVIOUS_CHILD_ATTENDANCE_SUBMIT = 'REVERT_PREVIOUS_CHILD_ATTENDANCE_SUBMIT';
export const REVERT_PREVIOUS_CHILD_ATTENDANCE_SUCCESS = 'REVERT_PREVIOUS_CHILD_ATTENDANCE_SUCCESS';
export const REVERT_PREVIOUS_CHILD_ATTENDANCE_FAILURE = 'REVERT_PREVIOUS_CHILD_ATTENDANCE_FAILURE';

export const REPORT_PREVIOUS_CHILD_ATTENDANCE = 'REPORT_PREVIOUS_CHILD_ATTENDANCE';
export const REPORT_PREVIOUS_CHILD_ATTENDANCE_CANCEL = 'REPORT_PREVIOUS_CHILD_ATTENDANCE_CANCEL';
export const REPORT_PREVIOUS_CHILD_ATTENDANCE_SUBMIT = 'REPORT_PREVIOUS_CHILD_ATTENDANCE_SUBMIT';
export const REPORT_PREVIOUS_CHILD_ATTENDANCE_SUCCESS = 'REPORT_PREVIOUS_CHILD_ATTENDANCE_SUCCESS';
export const REPORT_PREVIOUS_CHILD_ATTENDANCE_FAILURE = 'REPORT_PREVIOUS_CHILD_ATTENDANCE_FAILURE';

//Child Attendance
export const LOAD_CHILD_ATTENDANCES = 'LOAD_CHILD_ATTENDANCES';
export const LOAD_CHILD_ATTENDANCES_SUCCESS = 'LOAD_CHILD_ATTENDANCES_SUCCESS';

//Charging Schemes
//load all
export const LOAD_CHARGING_SCHEMES = 'LOAD_CHARGING_SCHEMES';
export const LOAD_CHARGING_SCHEMES_SUCCESS = 'LOAD_CHARGING_SCHEMES_SUCCESS';

//load only catering
export const LOAD_CATERING_CHARGING_SCHEMES = 'LOAD_CATERING_CHARGING_SCHEMES';
export const LOAD_CATERING_CHARGING_SCHEMES_SUCCESS = 'LOAD_CATERING_CHARGING_SCHEMES_SUCCESS';

//create
export const CREATE_CHARGING_SCHEME_DETAILS = 'CREATE_CHARGING_SCHEME_DETAILS';
export const CREATE_CHARGING_SCHEME_DETAILS_CANCEL = 'CREATE_CHARGING_SCHEME_DETAILS_CANCEL';
export const CREATE_CHARGING_SCHEME_DETAILS_SUBMIT = 'CREATE_CHARGING_SCHEME_DETAILS_SUBMIT';
export const CREATE_CHARGING_SCHEME_DETAILS_SUCCESS = 'CREATE_CHARGING_SCHEME_DETAILS_SUCCESS';
export const CREATE_CHARGING_SCHEME_DETAILS_FAILURE = 'CREATE_CHARGING_SCHEME_DETAILS_FAILURE';
//update
export const UPDATE_CHARGING_SCHEME_DETAILS = 'UPDATE_CHARGING_SCHEME_DETAILS';
export const UPDATE_CHARGING_SCHEME_DETAILS_CANCEL = 'UPDATE_CHARGING_SCHEME_DETAILS_CANCEL';
export const UPDATE_CHARGING_SCHEME_DETAILS_SUBMIT = 'UPDATE_CHARGING_SCHEME_DETAILS_SUBMIT';
export const UPDATE_CHARGING_SCHEME_DETAILS_SUCCESS = 'UPDATE_CHARGING_SCHEME_DETAILS_SUCCESS';
export const UPDATE_CHARGING_SCHEME_DETAILS_FAILURE = 'UPDATE_CHARGING_SCHEME_DETAILS_FAILURE';

//load
export const LOAD_CHARGING_SCHEME_DETAILS = 'LOAD_CHARGING_SCHEME_DETAILS';
export const LOAD_CHARGING_SCHEME_DETAILS_SUCCESS = 'LOAD_CHARGING_SCHEME_DETAILS_SUCCESS';
//archive
export const ARCHIVE_CHARGING_SCHEME_DETAILS = 'ARCHIVE_CHARGING_SCHEME_DETAILS';
export const ARCHIVE_CHARGING_SCHEME_DETAILS_SUCCESS = 'ARCHIVE_CHARGING_SCHEME_DETAILS_SUCCESS';
//assigned children
export const LOAD_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME = 'LOAD_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME';
export const LOAD_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME_SUCCESS = 'LOAD_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME_SUCCESS';

export const ADD_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME = 'ADD_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME';
export const ADD_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME_SUCCESS = 'ADD_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME_SUCCESS';

export const REMOVE_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME = 'REMOVE_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME';
export const REMOVE_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME_SUCCESS =
  'REMOVE_CHILDREN_ASSIGNED_TO_CHARGING_SCHEME_SUCCESS';

//Reliefs
//load all
export const LOAD_RELIEFS = 'LOAD_RELIEFS';
export const LOAD_RELIEFS_SUCCESS = 'LOAD_RELIEFS_SUCCESS';
//create
export const CREATE_RELIEF_DETAILS = 'CREATE_RELIEF_DETAILS';
export const CREATE_RELIEF_DETAILS_CANCEL = 'CREATE_RELIEF_DETAILS_CANCEL';
export const CREATE_RELIEF_DETAILS_SUBMIT = 'CREATE_RELIEF_DETAILS_SUBMIT';
export const CREATE_RELIEF_DETAILS_SUCCESS = 'CREATE_RELIEF_DETAILS_SUCCESS';
export const CREATE_RELIEF_DETAILS_FAILURE = 'CREATE_RELIEF_DETAILS_FAILURE';

//archive
export const ARCHIVE_RELIEF_DETAILS = 'ARCHIVE_RELIEF_DETAILS';
export const ARCHIVE_RELIEF_DETAILS_SUCCESS = 'ARCHIVE_RELIEF_DETAILS_SUCCESS';

//Days Off
export const LOAD_DAYS_OFF = 'LOAD_DAYS_OFF';
export const LOAD_DAYS_OFF_SUCCESS = 'LOAD_DAYS_OFF_SUCCESS';

export const ADD_DAY_OFF = 'ADD_DAY_OFF';
export const ADD_DAY_OFF_CANCEL = 'ADD_DAY_OFF_CANCEL';
export const ADD_DAY_OFF_SUBMIT = 'ADD_DAY_OFF_SUBMIT';
export const ADD_DAY_OFF_SUCCESS = 'ADD_DAY_OFF_SUCCESS';
export const ADD_DAY_OFF_FAILURE = 'ADD_DAY_OFF_FAILURE';

export const REMOVE_DAY_OFF = 'REMOVE_DAY_OFF';
export const REMOVE_DAY_OFF_SUCCESS = 'REMOVE_DAY_OFF_SUCCESS';

// Settlements settings
export const LOAD_SETTLEMENTS_SETTINGS = 'LOAD_SETTLEMENTS_SETTINGS';
export const LOAD_SETTLEMENTS_SETTINGS_SUCCESS = 'LOAD_SETTLEMENTS_SETTINGS_SUCCESS';
export const UPDATE_SETTLEMENTS_SETTINGS = 'UPDATE_SETTLEMENTS_SETTINGS';
export const UPDATE_SETTLEMENTS_SETTINGS_CANCEL = 'UPDATE_SETTLEMENTS_SETTINGS_CANCEL';
export const UPDATE_SETTLEMENTS_SETTINGS_SUBMIT = 'UPDATE_SETTLEMENTS_SETTINGS_SUBMIT';
export const UPDATE_SETTLEMENTS_SETTINGS_SUCCESS = 'UPDATE_SETTLEMENTS_SETTINGS_SUCCESS';
export const UPDATE_SETTLEMENTS_SETTINGS_FAILURE = 'UPDATE_SETTLEMENTS_SETTINGS_FAILURE';
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';

export const IMPORT_MT940_FILE_SUBMIT = 'IMPORT_MT940_FILE_SUBMIT';
export const IMPORT_MT940_FILE_SUCCESS = 'IMPORT_MT940_FILE_SUCCESS';
export const IMPORT_MT940_FILE_FAILURE = 'IMPORT_MT940_FILE_FAILURE';

export const CALCULATE_INTERESTS_SUBMIT = 'CALCULATE_INTERESTS_SUBMIT';
export const CALCULATE_INTERESTS_SUCCESS = 'CALCULATE_INTERESTS_SUCCESS';
export const CALCULATE_INTERESTS_FAILURE = 'CALCULATE_INTERESTS_FAILURE';

// Reports
export const LOAD_REPORTS_CONFIGURATION = 'LOAD_REPORTS_CONFIGURATION';
export const LOAD_REPORTS_CONFIGURATION_SUCCESS = 'LOAD_REPORTS_CONFIGURATION_SUCCESS';
export const UPDATE_REPORTS_CONFIGURATION = 'UPDATE_REPORTS_CONFIGURATION';
export const UPDATE_REPORTS_CONFIGURATION_CANCEL = 'UPDATE_REPORTS_CONFIGURATION_CANCEL';
export const UPDATE_REPORTS_CONFIGURATION_SUBMIT = 'UPDATE_REPORTS_CONFIGURATION_SUBMIT';
export const UPDATE_REPORTS_CONFIGURATION_SUCCESS = 'UPDATE_REPORTS_CONFIGURATION_SUCCESS';
export const UPDATE_REPORTS_CONFIGURATION_FAILURE = 'UPDATE_REPORTS_CONFIGURATION_FAILURE';

//Attributes Sets
export const LOAD_ATTRIBUTES_SETS = 'LOAD_ATTRIBUTES_SETS';
export const LOAD_ATTRIBUTES_SETS_SUCCESS = 'LOAD_ATTRIBUTES_SETS_SUCCESS';

export const CREATE_ATTRIBUTES_SET = 'CREATE_ATTRIBUTES_SET';
export const CREATE_ATTRIBUTES_SET_CANCEL = 'CREATE_ATTRIBUTES_SET_CANCEL';
export const CREATE_ATTRIBUTES_SET_SUBMIT = 'CREATE_ATTRIBUTES_SET_SUBMIT';
export const CREATE_ATTRIBUTES_SET_SUCCESS = 'CREATE_ATTRIBUTES_SET_SUCCESS';
export const CREATE_ATTRIBUTES_SET_FAILURE = 'CREATE_ATTRIBUTES_SET_FAILURE';

export const UPDATE_ATTRIBUTES_SET = 'UPDATE_ATTRIBUTE_SET';
export const UPDATE_ATTRIBUTES_SET_CANCEL = 'UPDATE_ATTRIBUTES_SET_CANCEL';
export const UPDATE_ATTRIBUTES_SET_SUBMIT = 'UPDATE_ATTRIBUTES_SET_SUBMIT';
export const UPDATE_ATTRIBUTES_SET_SUCCESS = 'UPDATE_ATTRIBUTES_SET_SUCCESS';
export const UPDATE_ATTRIBUTES_SET_FAILURE = 'UPDATE_ATTRIBUTES_SET_FAILURE';

export const REMOVE_ATTRIBUTES_SET = 'REMOVE_ATTRIBUTES_SET';
export const REMOVE_ATTRIBUTES_SET_SUCCESS = 'REMOVE_ATTRIBUTES_SET_SUCCESS';

//DiagnosticSchemes Sets
export const LOAD_DIAGNOSTICSCHEMES_SETS = 'LOAD_DIAGNOSTICSCHEMES_SETS';
export const LOAD_DIAGNOSTICSCHEMES_SETS_SUCCESS = 'LOAD_DIAGNOSTICSCHEMES_SETS_SUCCESS';

export const CREATE_DIAGNOSTICSCHEMES_SET = 'CREATE_DIAGNOSTICSCHEMES_SET';
export const CREATE_DIAGNOSTICSCHEMES_SET_CANCEL = 'CREATE_DIAGNOSTICSCHEMES_SET_CANCEL';
export const CREATE_DIAGNOSTICSCHEMES_SET_SUBMIT = 'CREATE_DIAGNOSTICSCHEMES_SET_SUBMIT';
export const CREATE_DIAGNOSTICSCHEMES_SET_SUCCESS = 'CREATE_DIAGNOSTICSCHEMES_SET_SUCCESS';
export const CREATE_DIAGNOSTICSCHEMES_SET_FAILURE = 'CREATE_DIAGNOSTICSCHEMES_SET_FAILURE';

export const UPDATE_DIAGNOSTICSCHEMES_SET = 'UPDATE_DIAGNOSTICSCHEMES_SET';
export const UPDATE_DIAGNOSTICSCHEMES_SET_CANCEL = 'UPDATE_DIAGNOSTICSCHEMES_SET_CANCEL';
export const UPDATE_DIAGNOSTICSCHEMES_SET_SUBMIT = 'UPDATE_DIAGNOSTICSCHEMES_SET_SUBMIT';
export const UPDATE_DIAGNOSTICSCHEMES_SET_SUCCESS = 'UPDATE_DIAGNOSTICSCHEMES_SET_SUCCESS';
export const UPDATE_DIAGNOSTICSCHEMES_SET_FAILURE = 'UPDATE_DIAGNOSTICSCHEMES_SET_FAILURE';

export const REMOVE_DIAGNOSTICSCHEMES_SET = 'REMOVE_DIAGNOSTICSCHEMES_SET';
export const REMOVE_DIAGNOSTICSCHEMES_SET_SUCCESS = 'REMOVE_DIAGNOSTICSCHEMES_SET_SUCCESS';

//childSection
export const LOAD_DIAGNOSTICSCHEMES_TO_CHILD_SETS = 'LOAD_DIAGNOSTICSCHEMES_TO_CHILD_SETS';
export const LOAD_DIAGNOSTICSCHEMES_TO_CHILD_SETS_SUCCESS = 'LOAD_DIAGNOSTICSCHEMES_TO_CHILD_SETS_SUCCESS';

export const CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET = 'CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET';
export const CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_CANCEL = 'CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_CANCEL';
export const CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUBMIT = 'CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUBMIT';
export const CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUCCESS = 'CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUCCESS';
export const CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_FAILURE = 'CREATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_FAILURE';

export const UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET = 'UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET';
export const UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_CANCEL = 'UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_CANCEL';
export const UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUBMIT = 'UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUBMIT';
export const UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUCCESS = 'UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUCCESS';
export const UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_FAILURE = 'UPDATE_DIAGNOSTICSCHEMES_TO_CHILD_SET_FAILURE';

export const REMOVE_DIAGNOSTICSCHEMES_TO_CHILD_SET = 'REMOVE_DIAGNOSTICSCHEMES_TO_CHILD_SET';
export const REMOVE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUCCESS = 'REMOVE_DIAGNOSTICSCHEMES_TO_CHILD_SET_SUCCESS';

// Unit
export const LOAD_UNIT_DATA = 'LOAD_UNIT_DATA';
export const LOAD_UNIT_DATA_SUCCESS = 'LOAD_UNIT_DATA_SUCCESS';
export const LOAD_UNIT_DATA_NOT_FOUND = 'LOAD_UNIT_DATA_NOT_FOUND';
export const UPDATE_UNIT_ADDRESS = 'UPDATE_UNIT_ADDRESS';
export const UPDATE_UNIT_ADDRESS_CANCEL = 'UPDATE_UNIT_ADDRESS_CANCEL';
export const UPDATE_UNIT_ADDRESS_SUBMIT = 'UPDATE_UNIT_ADDRESS_SUBMIT';
export const UPDATE_UNIT_ADDRESS_SUCCESS = 'UPDATE_UNIT_ADDRESS_SUCCESS';
export const UPDATE_UNIT_ADDRESS_FAILURE = 'UPDATE_UNIT_ADDRESS_FAILURE';
export const UPDATE_UNIT_WORKING_HOURS = 'UPDATE_UNIT_WORKING_HOURS';
export const UPDATE_UNIT_WORKING_HOURS_CANCEL = 'UPDATE_UNIT_WORKING_HOURS_CANCEL';
export const UPDATE_UNIT_WORKING_HOURS_SUBMIT = 'UPDATE_UNIT_WORKING_HOURS_SUBMIT';
export const UPDATE_UNIT_WORKING_HOURS_SUCCESS = 'UPDATE_UNIT_WORKING_HOURS_SUCCESS';
export const UPDATE_UNIT_WORKING_HOURS_FAILURE = 'UPDATE_UNIT_WORKING_HOURS_FAILURE';
export const UPDATE_UNIT_PENALTIES = 'UPDATE_UNIT_PENALTIES';
export const UPDATE_UNIT_PENALTIES_CANCEL = 'UPDATE_UNIT_PENALTIES_CANCEL';
export const UPDATE_UNIT_PENALTIES_SUBMIT = 'UPDATE_UNIT_PENALTIES_SUBMIT';
export const UPDATE_UNIT_PENALTIES_SUCCESS = 'UPDATE_UNIT_PENALTIES_SUCCESS';
export const UPDATE_UNIT_PENALTIES_FAILURE = 'UPDATE_UNIT_PENALTIES_FAILURE';
export const ENABLE_TWO_WAY_MESSAGING_SUCCESS = 'ENABLE_TWO_WAY_MESSAGING_SUCCESS';
export const DISABLE_TWO_WAY_MESSAGING_SUCCESS = 'DISABLE_TWO_WAY_MESSAGING_SUCCESS';
export const UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE = 'UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE';
export const UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_CANCEL = 'UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_CANCEL';
export const UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_SUBMIT = 'UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_SUBMIT';
export const UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_SUCCESS = 'UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_SUCCESS';
export const UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_FAILURE = 'UPDATE_UNIT_ABSENCE_REMOVAL_DEADLINE_FAILURE';
export const ENABLE_REPORT_ABSENCE_IN_DAYS_OFF_SUCCESS = 'ENABLE_REPORT_ABSENCE_IN_DAYS_OFF_SUCCESS';
export const DISABLE_REPORT_ABSENCE_IN_DAYS_OFF_SUCCESS = 'DISABLE_REPORT_ABSENCE_IN_DAYS_OFF_SUCCESS';

// guardian qr code service
export const ENABLE_QR_CODE_SERVICE_SUCCESS = 'ENABLE_QR_CODE_SERVICE_SUCCESS';
export const DISABLE_QR_CODE_SERVICE_SUCCESS = 'DISABLE_QR_CODE_SERVICE_SUCCESS';
export const ENABLE_DYNAMIC_QR_CODE_SERVICE_SUCCESS = 'ENABLE_DYNAMIC_QR_CODE_SERVICE_SUCCESS';
export const DISABLE_DYNAMIC_QR_CODE_SERVICE_SUCCESS = 'DISABLE_DYNAMIC_QR_CODE_SERVICE_SUCCESS';

export const UPDATE_UNIT_DEFAULT_PUBLISHER_SUCCESS = 'UPDATE_UNIT_DEFAULT_PUBLISHER_SUCCESS';
export const UPDATE_UNIT_DEFAULT_PUBLISHER_FAIL = 'UPDATE_UNIT_DEFAULT_PUBLISHER_FAIL';
export const REMOVE_UNIT_DEFAULT_PUBLISHER = 'REMOVE_UNIT_DEFAULT_PUBLISHER';

export const ENABLE_NO_HOURS_ATTENDANCE_SUCCESS = 'ENABLE_NO_HOURS_ATTENDANCE_SUCCESS';
export const DISABLE_NO_HOURS_ATTENDANCE_SUCCESS = 'DISABLE_NO_HOURS_ATTENDANCE_SUCCESS';

export const ENABLE_POSTS_MODIFYING_SUCCESS = 'ENABLE_POSTS_MODIFYING_SUCCESS';
export const DISABLE_POSTS_MODIFYING_SUCCESS = 'DISABLE_POSTS_MODIFYING_SUCCESS';

export const ENABLE_INDIVIDUAL_BANK_ACCOUNTS_SUCCESS = 'ENABLE_INDIVIDUAL_BANK_ACCOUNTS_SUCCESS';
export const DISABLE_INDIVIDUAL_BANK_ACCOUNTS_SUCCESS = 'DISABLE_INDIVIDUAL_BANK_ACCOUNTS_SUCCESS';

export const ENABLE_ADD_CONTRACT_NUMBER_TO_SETTLEMENT_NUMBER_SUCCESS =
  'ENABLE_ADD_CONTRACT_NUMBER_TO_SETTLEMENT_NUMBER_SUCCESS';
export const DISABLE_ADD_CONTRACT_NUMBER_TO_SETTLEMENT_NUMBER_SUCCESS =
  'DISABLE_ADD_CONTRACT_NUMBER_TO_SETTLEMENT_NUMBER_SUCCESS';

export const ENABLE_CHILDREN_AGE_INFO = 'ENABLE_CHILDREN_AGE_INFO';
export const DISABLE_CHILDREN_AGE_INFO = 'DISABLE_CHILDREN_AGE_INFO';

// export const ENABLE_NOTIFICATION_SENDING_SUCCESS = 'ENABLE_POSTS_MODIFYING_SUCCESS';
// export const DISABLE_NOTIFICATION_SENDING_SUCCESS = 'DISABLE_POSTS_MODIFYING_SUCCESS';

// guardian pin service
export const LOAD_GUARDIAN_PIN_SERVICE = 'LOAD_GUARDIAN_PIN_SERVICE';
export const LOAD_GUARDIAN_PIN_SERVICE_SUCCESS = 'LOAD_GUARDIAN_PIN_SERVICE_SUCCESS';

export const UPDATE_GUARDIAN_PIN_SERVICE = 'UPDATE_GUARDIAN_PIN_SERVICE';
export const UPDATE_GUARDIAN_PIN_SERVICE_SUCCESS = 'UPDATE_GUARDIAN_PIN_SERVICE_SUCCESS';

export const LOAD_GUARDIANS_WITHOUT_PINS = 'LOAD_GUARDIANS_WITHOUT_PINS';
export const LOAD_GUARDIANS_WITHOUT_PINS_SUCCESS = 'LOAD_GUARDIANS_WITHOUT_PINS_SUCCESS';
export const GENERATE_PINS_TO_GUARDIANS_WITHOUT = 'GENERATE_PINS_TO_PEOPLE_WITHOUT';
export const GENERATE_PINS_TO_GUARDIANS_WITHOUT_SUCCESS = 'GENERATE_PINS_TO_PEOPLE_WITHOUT_SUCCESS';

export const ADD_PIN_DEVICE = 'ADD_PIN_DEVICE';
export const ADD_PIN_DEVICE_SUCCESS = 'ADD_PIN_DEVICE_SUCCESS';
export const ADD_PIN_DEVICE_CANCEL = 'ADD_PIN_DEVICE_CANCEL';
export const ADD_PIN_DEVICE_FAILURE = 'ADD_PIN_DEVICE_FAILURE';

export const REMOVE_PIN_DEVICE = 'REMOVE_PIN_DEVICE';
export const REMOVE_PIN_DEVICE_SUCCESS = 'REMOVE_PIN_DEVICE_SUCCESS';

export const RESET_PIN_DEVICE_PASSWORD = 'RESET_PIN_DEVICE_PASSWORD';
export const RESET_PIN_DEVICE_PASSWORD_SUCCESS = 'RESET_PIN_DEVICE_PASSWORD_SUCCESS';
export const RESET_PIN_DEVICE_PASSWORD_CANCEL = 'RESET_PIN_DEVICE_PASSWORD_CANCEL';
export const RESET_PIN_DEVICE_PASSWORD_FAILURE = 'RESET_PIN_DEVICE_PASSWORD_FAILURE';

// Settlements Filters
export const SAVE_SETTLEMENTS_FILTERS = 'SAVE_SETTLEMENTS_FILTERS';
export const RESET_SETTLEMENTS_FILTERS = 'RESET_SETTLEMENTS_FILTERS';

// Settlements
export const LOAD_SETTLEMENTS = 'LOAD_SETTLEMENTS';
export const LOAD_SETTLEMENTS_SUCCESS = 'LOAD_SETTLEMENTS_SUCCESS';

export const LOAD_CHILD_DUES = 'LOAD_CHILD_DUES';
export const LOAD_CHILD_DUES_SUCCESS = 'LOAD_CHILD_DUES_SUCCESS';

export const LOAD_CHILD_BALANCE_SUMMARY = 'LOAD_CHILD_BALANCE_SUMMARY';
export const LOAD_CHILD_BALANCE_SUMMARY_SUCCESS = 'LOAD_CHILD_BALANCE_SUMMARY_SUCCESS';

export const LOAD_STAFF_DUES = 'LOAD_STAFF_DUES';
export const LOAD_STAFF_DUES_SUCCESS = 'LOAD_STAFF_DUES_SUCCESS';

export const UPDATE_SETTLEMENT = 'UPDATE_SETTLEMENT';
export const UPDATE_SETTLEMENT_CANCEL = 'UPDATE_SETTLEMENT_CANCEL';
export const UPDATE_SETTLEMENT_SUBMIT = 'UPDATE_SETTLEMENT_SUBMIT';
export const UPDATE_SETTLEMENT_SUCCESS = 'UPDATE_SETTLEMENT_SUCCESS';
export const UPDATE_SETTLEMENT_FAILURE = 'UPDATE_SETTLEMENT_FAILURE';

export const APPROVE_DUE = 'APPROVE_DUE';
export const APPROVE_DUE_CANCEL = 'APPROVE_DUE_CANCEL';
export const APPROVE_DUE_SUBMIT = 'APPROVE_DUE_SUBMIT';
export const APPROVE_DUE_SUCCESS = 'APPROVE_DUE_SUCCESS';
export const APPROVE_DUE_FAILURE = 'APPROVE_DUE_FAILURE';

export const CANCEL_SETTLEMENT_SUBMIT = 'CANCEL_SETTLEMENT_SUBMIT';
export const CANCEL_SETTLEMENT_SUCCESS = 'CANCEL_SETTLEMENT_SUCCESS';
export const CANCEL_SETTLEMENTS_SUCCESS = 'CANCEL_SETTLEMENTS_SUCCESS';
export const CANCEL_SETTLEMENT_FAILURE = 'CANCEL_SETTLEMENT_FAILURE';

export const CANCEL_SETTLEMENT_DELAY_INTERESTS_SUBMIT = 'CANCEL_SETTLEMENT_DELAY_INTERESTS_SUBMIT';
export const CANCEL_SETTLEMENT_DELAY_INTERESTS_SUCCESS = 'CANCEL_SETTLEMENT_DELAY_INTERESTS_SUCCESS';
export const CANCEL_SETTLEMENT_DELAY_INTERESTS_FAILURE = 'CANCEL_SETTLEMENT_DELAY_INTERESTS_FAILURE';

export const EDIT_SETTLEMENT_DELAY_INTERESTS_SUBMIT = 'EDIT_SETTLEMENT_DELAY_INTERESTS_SUBMIT';
export const EDIT_SETTLEMENT_DELAY_INTERESTS_SUCCESS = 'EDIT_SETTLEMENT_DELAY_INTERESTS_SUCCESS';
export const EDIT_SETTLEMENT_DELAY_INTERESTS_FAILURE = 'EDIT_SETTLEMENT_DELAY_INTERESTS_FAILURE';

export const DELETE_SETTLEMENTS_SUBMIT = 'DELETE_SETTLEMENTS_SUBMIT';
export const DELETE_SETTLEMENTS_SUCCESS = 'DELETE_SETTLEMENTS_SUCCESS';
export const DELETE_SETTLEMENTS_FAILURE = 'DELETE_SETTLEMENTS_FAILURE';

export const CALCULATE_SETTLEMENTS = 'CALCULATE_SETTLEMENTS';
export const CALCULATE_SETTLEMENTS_CANCEL = 'CALCULATE_SETTLEMENTS_CANCEL';
export const CALCULATE_SETTLEMENTS_SUBMIT = 'CALCULATE_SETTLEMENTS_SUBMIT';
export const CALCULATE_SETTLEMENTS_SUCCESS = 'CALCULATE_SETTLEMENTS_SUCCESS';
export const CALCULATE_SETTLEMENTS_FAILURE = 'CALCULATE_SETTLEMENTS_FAILURE';

export const CALCULATE_CORRECTIVE_DUES = 'CALCULATE_CORRECTIVE_DUES';
export const CALCULATE_CORRECTIVE_DUES_CANCEL = 'CALCULATE_CORRECTIVE_DUES_CANCEL';
export const CALCULATE_CORRECTIVE_DUES_SUBMIT = 'CALCULATE_CORRECTIVE_DUES_SUBMIT';
export const CALCULATE_CORRECTIVE_DUES_SUCCESS = 'CALCULATE_CORRECTIVE_DUES_SUCCESS';
export const CALCULATE_CORRECTIVE_DUES_FAILURE = 'CALCULATE_CORRECTIVE_DUES_FAILURE';

export const LOAD_REPORTS = 'LOAD_REPORTS';
export const LOAD_REPORTS_SUCCESS = 'LOAD_REPORTS_SUCCESS';
export const GENERATE_REPORT = 'GENERATE_REPORT';
export const GENERATE_REPORT_CANCEL = 'GENERATE_REPORT_CANCEL';
export const GENERATE_REPORT_SUBMIT = 'GENERATE_REPORT_SUBMIT';
export const GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS';
export const GENERATE_REPORT_FAILURE = 'GENERATE_REPORT_FAILURE';

export const REGISTER_PAYMENT = 'REGISTER_PAYMENT';
export const REGISTER_PAYMENT_CANCEL = 'REGISTER_PAYMENT_CANCEL';
export const REGISTER_PAYMENT_SUBMIT = 'REGISTER_PAYMENT_SUBMIT';
export const REGISTER_PAYMENT_SUCCESS = 'REGISTER_PAYMENT_SUCCESS';
export const REGISTER_PAYMENT_FAILURE = 'REGISTER_PAYMENT_FAILURE';

export const REGISTER_PAYMENTS = 'REGISTER_PAYMENTS';
export const REGISTER_PAYMENTS_CANCEL = 'REGISTER_PAYMENTS_CANCEL';
export const REGISTER_PAYMENTS_SUBMIT = 'REGISTER_PAYMENTS_SUBMIT';
export const REGISTER_PAYMENTS_SUCCESS = 'REGISTER_PAYMENTS_SUCCESS';
export const REGISTER_PAYMENTS_FAILURE = 'REGISTER_PAYMENTS_FAILURE';

export const UNDO_PAYMENT_SUBMIT = 'UNDO_PAYMENT_SUBMIT';
export const UNDO_PAYMENT_SUCCESS = 'UNDO_PAYMENT_SUCCESS';
export const UNDO_PAYMENT_FAILURE = 'UNDO_PAYMENT_FAILURE';

export const CANCEL_ONLINE_PAYMENT_SUBMIT = 'CANCEL_ONLINE_PAYMENT_SUBMIT';
export const CANCEL_ONLINE_PAYMENT_SUCCESS = 'CANCEL_ONLINE_PAYMENT_SUCCESS';
export const CANCEL_ONLINE_PAYMENT_FAILURE = 'CANCEL_ONLINE_PAYMENT_FAILURE';

export const REGISTER_REBATE = 'REGISTER_REBATE';
export const REGISTER_REBATE_CANCEL = 'REGISTER_REBATE_CANCEL';
export const REGISTER_REBATE_SUBMIT = 'REGISTER_REBATE_SUBMIT';
export const REGISTER_REBATE_SUCCESS = 'REGISTER_REBATE_SUCCESS';
export const REGISTER_REBATE_FAILURE = 'REGISTER_REBATE_FAILURE';

export const REMOVE_REBATE_SUBMIT = 'REMOVE_REBATE_SUBMIT';
export const REMOVE_REBATE_SUCCESS = 'REMOVE_REBATE_SUCCESS';
export const REMOVE_REBATE_FAILURE = 'REMOVE_REBATE_FAILURE';

export const RESERVE_BALANCE_FUNDS_SUBMIT = 'RESERVE_BALANCE_FUNDS_SUBMIT';
export const RESERVE_BALANCE_FUNDS_SUCCESS = 'RESERVE_BALANCE_FUNDS_SUCCESS';
export const RESERVE_BALANCE_FUNDS_FAILURE = 'RESERVE_BALANCE_FUNDS_FAILURE';

export const REMOVE_RESERVED_BALANCE_FUNDS_SUBMIT = 'REMOVE_RESERVED_BALANCE_FUNDS_SUBMIT';
export const REMOVE_RESERVED_BALANCE_FUNDS_SUCCESS = 'REMOVE_RESERVED_BALANCE_FUNDS_SUCCESS';
export const REMOVE_RESERVED_BALANCE_FUNDS_FAILURE = 'REMOVE_RESERVED_BALANCE_FUNDS_FAILURE';
export const AVAILABLE_FUNDS_UPDATED = 'AVAILABLE_FUNDS_UPDATED';

export const WALLET_TRANSFER_SUBMIT = 'WALLET_TRANSFER_SUBMIT';
export const WALLET_TRANSFER_SUCCESS = 'WALLET_TRANSFER_SUCCESS';
export const WALLET_TRANSFER_FAILURE = 'WALLET_TRANSFER_FAILURE';

// Guardian dues
export const LOAD_DUES = 'LOAD_DUES';
export const LOAD_DUES_SUCCESS = 'LOAD_DUES_SUCCESS';

export const LOAD_DUE_DETAILS = 'LOAD_DUE_DETAILS';
export const LOAD_DUE_DETAILS_SUCCESS = 'LOAD_DUE_DETAILS_SUCCESS';

export const PAY_FOR = 'PAY_FOR';
export const PAY_FOR_SUCCESS = 'PAY_FOR_SUCCESS';

//activities
export const LOAD_ACTIVITIES = 'LOAD_ACTIVITIES';
export const LOAD_ACTIVITIES_SUCCESS = 'LOAD_ACTIVITIES_SUCCESS';
//archived
export const LOAD_ACTIVITIES_ARCHIVE = 'LOAD_ACTIVITIES_ARCHIVE';
export const LOAD_ACTIVITIES_ARCHIVE_SUCCESS = 'LOAD_ACTIVITIES_ARCHIVE_SUCCESS';

//create
export const CREATE_ACTIVITY_DETAILS = 'CREATE_ACTIVITY_DETAILS';
export const CREATE_ACTIVITY_DETAILS_CANCEL = 'CREATE_ACTIVITY_DETAILS_CANCEL';
export const CREATE_ACTIVITY_DETAILS_SUBMIT = 'CREATE_ACTIVITY_DETAILS_SUBMIT';
export const CREATE_ACTIVITY_DETAILS_SUCCESS = 'CREATE_ACTIVITY_DETAILS_SUCCESS';
export const CREATE_ACTIVITY_DETAILS_FAILURE = 'CREATE_ACTIVITY_DETAILS_FAILURE';
//update
export const UPDATE_ACTIVITY_DETAILS = 'UPDATE_ACTIVITY_DETAILS';
export const UPDATE_ACTIVITY_DETAILS_CANCEL = 'UPDATE_ACTIVITY_DETAILS_CANCEL';
export const UPDATE_ACTIVITY_DETAILS_SUBMIT = 'UPDATE_ACTIVITY_DETAILS_SUBMIT';
export const UPDATE_ACTIVITY_DETAILS_SUCCESS = 'UPDATE_ACTIVITY_DETAILS_SUCCESS';
export const UPDATE_ACTIVITY_DETAILS_FAILURE = 'UPDATE_ACTIVITY_DETAILS_FAILURE';

//load
export const LOAD_ACTIVITY_DETAILS = 'LOAD_ACTIVITY_DETAILS';
export const LOAD_ACTIVITY_DETAILS_SUCCESS = 'LOAD_ACTIVITY_DETAILS_SUCCESS';

export const LOAD_ACTIVITY_ARCHIVE_DETAILS = 'LOAD_ACTIVITY_ARCHIVE_DETAILS';
export const LOAD_ACTIVITY_ARCHIVE_DETAILS_SUCCESS = 'LOAD_ACTIVITY_ARCHIVE_DETAILS_SUCCESS';
//archive
export const ARCHIVE_ACTIVITY_DETAILS = 'ARCHIVE_ACTIVITY_DETAILS';
export const ARCHIVE_ACTIVITY_DETAILS_SUCCESS = 'ARCHIVE_ACTIVITY_DETAILS_SUCCESS';

export const ENROLL_CHILDREN_TO_ACTIVITY = 'ENROLL_CHILDREN_TO_ACTIVITY';
export const ENROLL_CHILDREN_TO_ACTIVITY_CANCEL = 'ENROLL_CHILDREN_TO_ACTIVITY_CANCEL';
export const ENROLL_CHILDREN_TO_ACTIVITY_SUBMIT = 'ENROLL_CHILDREN_TO_ACTIVITY_SUBMIT';
export const ENROLL_CHILDREN_TO_ACTIVITY_SUCCESS = 'ENROLL_CHILDREN_TO_ACTIVITY_SUCCESS';
export const ENROLL_CHILDREN_TO_ACTIVITY_FAILURE = 'ENROLL_CHILDREN_TO_ACTIVITY_FAILURE';

export const REMOVE_CHILD_FROM_ACTIVITY = 'REMOVE_CHILD_FROM_ACTIVITY';
export const REMOVE_CHILD_FROM_ACTIVITY_SUCCESS = 'REMOVE_CHILD_FROM_ACTIVITY_SUCCESS';

// Numbering schemes
export const LOAD_NUMBERING_SCHEMES = 'LOAD_NUMBERING_SCHEMES';
export const LOAD_NUMBERING_SCHEMES_SUCCESS = 'LOAD_NUMBERING_SCHEMES_SUCCESS';
export const LOAD_NUMBERING_SCHEME_DETAILS = 'LOAD_NUMBERING_SCHEME_DETAILS';
export const LOAD_NUMBERING_SCHEME_DETAILS_SUCCESS = 'LOAD_NUMBERING_SCHEME_DETAILS_SUCCESS';

export const CREATE_NUMBERING_SCHEME_DETAILS = 'CREATE_NUMBERING_SCHEME_DETAILS';
export const CREATE_NUMBERING_SCHEME_DETAILS_CANCEL = 'CREATE_NUMBERING_SCHEME_DETAILS_CANCEL';
export const CREATE_NUMBERING_SCHEME_DETAILS_SUBMIT = 'CREATE_NUMBERING_SCHEME_DETAILS_SUBMIT';
export const CREATE_NUMBERING_SCHEME_DETAILS_SUCCESS = 'CREATE_NUMBERING_SCHEME_DETAILS_SUCCESS';
export const CREATE_NUMBERING_SCHEME_DETAILS_FAILURE = 'CREATE_NUMBERING_SCHEME_DETAILS_FAILURE';

export const UPDATE_NUMBERING_SCHEME_DETAILS = 'UPDATE_NUMBERING_SCHEME_DETAILS';
export const UPDATE_NUMBERING_SCHEME_DETAILS_CANCEL = 'UPDATE_NUMBERING_SCHEME_DETAILS_CANCEL';
export const UPDATE_NUMBERING_SCHEME_DETAILS_SUBMIT = 'UPDATE_NUMBERING_SCHEME_DETAILS_SUBMIT';
export const UPDATE_NUMBERING_SCHEME_DETAILS_SUCCESS = 'UPDATE_NUMBERING_SCHEME_DETAILS_SUCCESS';
export const UPDATE_NUMBERING_SCHEME_DETAILS_FAILURE = 'UPDATE_NUMBERING_SCHEME_DETAILS_FAILURE';

export const DELETE_NUMBERING_SCHEME_DETAILS = 'DELETE_NUMBERING_SCHEME_DETAILS';
export const DELETE_NUMBERING_SCHEME_DETAILS_SUCCESS = 'DELETE_NUMBERING_SCHEME_DETAILS_SUCCESS';
export const LOAD_OVERDUE_PAYMENTS = 'LOAD_OVERDUE_PAYMENTS';
export const LOAD_OVERDUE_PAYMENTS_SUCCESS = 'LOAD_OVERDUE_PAYMENTS_SUCCESS';

export const LOAD_OVERPAYMENTS = 'LOAD_OVERPAYMENTS';
export const LOAD_OVERPAYMENTS_SUCCESS = 'LOAD_OVERPAYMENTS_SUCCESS';
export const LOAD_OVERPAYMENTS_FAILURE = 'LOAD_OVERPAYMENTS_FAILURE';

export const PROCESS_REFUNDS_SUCCESS = 'PROCESS_REFUNDS_SUCCESS';

export const LOAD_EXPIRING_RELIEFS = 'LOAD_EXPIRING_RELIEFS';
export const LOAD_EXPIRING_RELIEFS_SUCCESS = 'LOAD_EXPIRING_RELIEFS_SUCCESS';

export const LOAD_ATTENDANCE_SUMMARY = 'LOAD_ATTENDANCE_SUMMARY';
export const LOAD_ATTENDANCE_SUMMARY_SUCCESS = 'LOAD_ATTENDANCE_SUMMARY_SUCCESS';

export const RESET_PASSWORD_SUBMIT = 'RESET_PASSWORD_SUBMIT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

//Private messages
export const PRIVATE_MESSAGES_CLEARED = 'PRIVATE_MESSAGES_CLEARED';
export const LOAD_PRIVATE_THREADS = 'LOAD_PRIVATE_THREADS';
export const LOAD_PRIVATE_THREADS_SUCCESS = 'LOAD_PRIVATE_THREADS_SUCCESS';
export const LOAD_NEXT_PRIVATE_THREADS = 'LOAD_NEXT_PRIVATE_THREADS';
export const LOAD_NEXT_PRIVATE_THREADS_SUCCESS = 'LOAD_NEXT_PRIVATE_THREADS_SUCCESS';

export const LOAD_MY_PRIVATE_THREADS = 'LOAD_MY_PRIVATE_THREADS';
export const LOAD_MY_PRIVATE_THREADS_SUCCESS = 'LOAD_MY_PRIVATE_THREADS_SUCCESS';

export const LOAD_PRIVATE_MESSAGES = 'LOAD_PRIVATE_MESSAGES';
export const LOAD_PRIVATE_MESSAGES_SUCCESS = 'LOAD_PRIVATE_MESSAGES_SUCCESS';
export const LOAD_NEXT_PRIVATE_MESSAGES = 'LOAD_NEXT_PRIVATE_MESSAGES';
export const LOAD_NEXT_PRIVATE_MESSAGES_SUCCESS = 'LOAD_NEXT_PRIVATE_MESSAGES_SUCCESS';

export const LOAD_UNREAD_THREADS = 'LOAD_UNREAD_THREADS';
export const LOAD_UNREAD_THREADS_SUCCESS = 'LOAD_UNREAD_THREADS_SUCCESS';

export const SEND_PRIVATE_MESSAGE = 'SEND_PRIVATE_MESSAGE';
export const SEND_PRIVATE_MESSAGE_SUCCESS = 'SEND_PRIVATE_MESSAGE_SUCCESS';
export const SEND_PRIVATE_MULTI_MESSAGE_SUCCESS = 'SEND_PRIVATE_MULTI_MESSAGE_SUCCESS';
export const NEW_THREAD_NOTIFICATION = 'NEW_THREAD_NOTIFICATION';
export const UPDATE_PRIVATE_MESSAGE = 'UPDATE_PRIVATE_MESSAGE';
export const UPDATE_PRIVATE_MESSAGE_SUCCESS = 'UPDATE_PRIVATE_MESSAGE_SUCCESS';
export const DELETE_PRIVATE_MESSAGE = 'DELETE_PRIVATE_MESSAGE';
export const DELETE_PRIVATE_MESSAGE_SUCCESS = 'DELETE_PRIVATE_MESSAGE_SUCCESS';

export const MARK_MESSAGE_READ_SUCCESS = 'MARK_MESSAGE_READ_SUCCESS';
export const MESSAGE_READ_NOTIFICATION = 'MESSAGE_READ_NOTIFICATION';
export const NOTIFY_PM_TYPING_SUCCESS = 'NOTIFY_PM_TYPING_SUCCESS';
export const NOTIFY_PM_NOT_TYPING_SUCCESS = 'NOTIFY_PM_NOT_TYPING_SUCCESS';
export const PM_TYPING_NOTIFICATION_RECEIVED = 'PM_TYPING_NOTIFICATION_RECEIVED';
export const PM_NOT_TYPING_NOTIFICATION_RECEIVED = 'PM_NOT_TYPING_NOTIFICATION_RECEIVED';

export const LOAD_POSSIBLE_PM_RECIPIENTS_SUCCESS = 'LOAD_POSSIBLE_PM_RECIPIENTS_SUCCESS';

export const PRIVATE_MESSAGE_SENT_NOTIFICATION = 'PRIVATE_MESSAGE_SENT_NOTIFICATION';
export const PRIVATE_MESSAGE_UPDATED_NOTIFICATION = 'PRIVATE_MESSAGE_UPDATED_NOTIFICATION';
export const PRIVATE_MESSAGE_DELETED_NOTIFICATION = 'PRIVATE_MESSAGE_DELETED_NOTIFICATION';
export const PRIVATE_MESSAGE_READ_NOTIFICATION = 'PRIVATE_MESSAGE_READ_NOTIFICATION';

// Authentication
export const AUTHENTICATE_PIN_SUCCESS = 'AUTHENTICATE_PIN_SUCCESS';
export const AUTHENTICATE_PIN_FAILURE = 'AUTHENTICATE_PIN_FAILURE';
export const CLEAR_AUTHENTICATED_PIN_STATE = 'CLEAR_AUTHENTICATED_PIN_STATE';

// daycare
export const LOAD_DAYCARE_SETTINGS = 'LOAD_DAYCARE_SETTINGS';
export const LOAD_DAYCARE_SETTINGS_SUCCESS = 'LOAD_DAYCARE_SETTINGS_SUCCESS';

export const UPDATE_DAYCARE_SETTINGS = 'UPDATE_DAYCARE_SETTINGS';
export const UPDATE_DAYCARE_SETTINGS_SUCCESS = 'UPDATE_DAYCARE_SETTINGS_SUCCESS';

export const LOAD_DAYCARE_GROUP_ENTRIES = 'LOAD_DAYCARE_GROUP_ENTRIES';
export const LOAD_DAYCARE_GROUP_ENTRIES_SUCCESS = 'LOAD_DAYCARE_GROUP_ENTRIES_SUCCESS';

export const LOAD_CHILD_DAYCARE = 'LOAD_CHILD_DAYCARE';
export const LOAD_CHILD_DAYCARE_SUCCESS = 'LOAD_CHILD_DAYCARE_SUCCESS';

export const UPDATE_CHILD_DAYCARE = 'UPDATE_CHILD_DAYCARE';
export const UPDATE_CHILD_DAYCARE_SUCCESS = 'UPDATE_CHILD_DAYCARE_SUCCESS';

export const LOAD_DUES_EXPORT_SETTINGS_SUCCESS = 'LOAD_DUES_EXPORT_SETTINGS_SUCCESS';
export const LOAD_DUES_EXPORTS_HISTORY_SUCCESS = 'LOAD_DUES_EXPORTS_HISTORY_SUCCESS';
export const UPDATE_DUES_EXPORT_SETTINGS = 'UPDATE_DUES_EXPORT_SETTINGS';
export const UPDATE_DUES_EXPORT_SETTINGS_SUCCESS = 'UPDATE_DUES_EXPORT_SETTINGS_SUCCESS';
export const RUN_DUES_EXPORT = 'RUN_DUES_EXPORT';
export const RUN_DUES_EXPORT_SUCCESS = 'RUN_DUES_EXPORT_SUCCESS';

export const LOAD_DUES_FK_EXPORT_SETTINGS_SUCCESS = 'LOAD_DUES_FK_EXPORT_SETTINGS_SUCCESS';
export const LOAD_DUES_FK_EXPORTS_HISTORY_SUCCESS = 'LOAD_DUES_FK_EXPORTS_HISTORY_SUCCESS';
export const UPDATE_DUES_FK_EXPORT_SETTINGS = 'UPDATE_DUES_FK_EXPORT_SETTINGS';
export const UPDATE_DUES_FK_EXPORT_SETTINGS_SUCCESS = 'UPDATE_DUES_FK_EXPORT_SETTINGS_SUCCESS';
export const RUN_DUES_FK_EXPORT = 'RUN_DUES_FK_EXPORT';
export const RUN_DUES_FK_EXPORT_SUCCESS = 'RUN_DUES_FK_EXPORT_SUCCESS';

// TEST API
export const TEST_GET_SUCCESS = 'TEST_GET_SUCCESS';
export const REQUEST_SENT = 'REQUEST_SENT';

// DIGITAL DIARY

export const CHANGE_DIGITAL_DIARY_CHOOSEN_GROUP = 'CHANGE_DIGITAL_DIARY_CHOOSEN_GROUP';

// digital diary events
export const LOAD_DIGITAL_DIARY_EVENT_START = 'LOAD_DIGITAL_DIARY_EVENT_START';
export const LOAD_DIGITAL_DIARY_EVENT_SUCCESS = 'LOAD_DIGITAL_DIARY_EVENT_SUCCESS';

export const CREATE_DIGITAL_DIARY_EVENT = 'CREATE_DIGITAL_DIARY_EVENT';
export const CREATE_DIGITAL_DIARY_EVENT_START = 'CREATE_DIGITAL_DIARY_EVENT_START';
export const CREATE_DIGITAL_DIARY_EVENT_CANCEL = 'CREATE_DIGITAL_DIARY_EVENT_CANCEL';
export const CREATE_DIGITAL_DIARY_EVENT_SUBMIT = 'CREATE_DIGITAL_DIARY_EVENT_SUBMIT';
export const CREATE_DIGITAL_DIARY_EVENT_FAILURE = 'CREATE_DIGITAL_DIARY_EVENT_FAILURE';
export const CREATE_DIGITAL_DIARY_EVENT_SUCCESS = 'CREATE_DIGITAL_DIARY_EVENT_SUCCESS';

export const UPDATE_DIGITAL_DIARY_EVENT = 'UPDATE_DIGITAL_DIARY_EVENT';
export const UPDATE_DIGITAL_DIARY_EVENT_START = 'UPDATE_DIGITAL_DIARY_EVENT_START';
export const UPDATE_DIGITAL_DIARY_EVENT_CANCEL = 'UPDATE_DIGITAL_DIARY_EVENT_CANCEL';
export const UPDATE_DIGITAL_DIARY_EVENT_SUBMIT = 'UPDATE_DIGITAL_DIARY_EVENT_SUBMIT';
export const UPDATE_DIGITAL_DIARY_EVENT_FAILURE = 'UPDATE_DIGITAL_DIARY_EVENT_FAILURE';
export const UPDATE_DIGITAL_DIARY_EVENT_SUCCESS = 'UPDATE_DIGITAL_DIARY_EVENT_SUCCESS';

// export const CLONE_DIGITAL_DIARY_EVENTS_SUBMIT = 'CLONE_DIGITAL_DIARY_EVENTS_SUCCESS';
// export const CLONE_DIGITAL_DIARY_EVENTS_SUCCESS = 'CLONE_DIGITAL_DIARY_EVENTS_SUCCESS';
// export const CLONE_DIGITAL_DIARY_EVENTS_FAILURE = 'CLONE_DIGITAL_DIARY_EVENTS_FAILURE';

// daily schedules
export const LOAD_DAILY_SCHEDULE_START = 'LOAD_DAILY_SCHEDULE_START';
export const LOAD_DAILY_SCHEDULE_SUCCESS = 'LOAD_DAILY_SCHEDULE_SUCCESS';
export const LOAD_DAILY_SCHEDULE_FAILURE = 'LOAD_DAILY_SCHEDULE_FAILURE';

export const CREATE_DAILY_SCHEDULE = 'CREATE_DAILY_SCHEDULE';
export const CREATE_DAILY_SCHEDULE_SUCCESS = 'CREATE_DAILY_SCHEDULE_SUCCESS';
export const CREATE_DAILY_SCHEDULE_FAILURE = 'CREATE_DAILY_SCHEDULE_FAILURE';

export const ADD_DAILY_SCHEDULE_ITEM = 'ADD_DAILY_SCHEDULE_ITEM';
export const ADD_DAILY_SCHEDULE_ITEM_SUCCESS = 'ADD_DAILY_SCHEDULE_SUCCESS';
export const ADD_DAILY_SCHEDULE_ITEM_FAILURE = 'ADD_DAILY_SCHEDULE_FAILURE';

export const UPDATE_DAILY_SCHEDULE_ITEM = 'UPDATE_DAILY_SCHEDULE_ITEM';
export const UPDATE_DAILY_SCHEDULE_ITEM_SUCCESS = 'UPDATE_DAILY_SCHEDULE_ITEM_SUCCESS';
export const UPDATE_DAILY_SCHEDULE_ITEM_FAILURE = 'UPDATE_DAILY_SCHEDULE_ITEM_SUCCESS';

export const REMOVE_DAILY_SCHEDULE_ITEM = 'REMOVE_DAILY_SCHEDULE_ITEM';
export const REMOVE_DAILY_SCHEDULE_ITEM_SUCCESS = 'REMOVE_DAILY_SCHEDULE_ITEM_SUCCESS';
export const REMOVE_DAILY_SCHEDULE_ITEM_FAILURE = 'REMOVE_DAILY_SCHEDULE_ITEM_FAILURE';

// work plan

export const LOAD_DIGITAL_DIARY_WORKPLAN_SETS = 'LOAD_DIGITALDIARY_WORKPLAN_SETS';
export const LOAD_DIGITAL_DIARY_WORKPLAN_SETS_SUCCESS = 'LOAD_DIGITALDIARY_WORKPLAN_SETS_SUCCESS';

export const CREATE_DIGITAL_DIARY_WORKPLAN_SET = 'CREATE_DIGITALDIARY_WORKPLAN_SET';
export const CREATE_DIGITAL_DIARY_WORKPLAN_SET_CANCEL = 'CREATE_DIGITALDIARY_WORKPLAN_SET_CANCEL';
export const CREATE_DIGITAL_DIARY_WORKPLAN_SET_SUBMIT = 'CREATE_DIGITALDIARY_WORKPLAN_SET_SUBMIT';
export const CREATE_DIGITAL_DIARY_WORKPLAN_SET_SUCCESS = 'CREATE_DIGITALDIARY_WORKPLAN_SET_SUCCESS';
export const CREATE_DIGITAL_DIARY_WORKPLAN_TOPIC_SET_SUCCESS = 'CREATE_DIGITALDIARY_WORKPLAN_TOPIC_SET_SUCCESS';
export const CREATE_DIGITAL_DIARY_WORKPLAN_SET_FAILURE = 'CREATE_DIGITALDIARY_WORKPLAN_SET_FAILURE';

export const UPDATE_DIGITAL_DIARY_WORKPLAN_SET = 'UPDATE_DIGITALDIARY_WORKPLAN_SET';
export const UPDATE_DIGITAL_DIARY_WORKPLAN_SET_CANCEL = 'UPDATE_DIGITALDIARY_WORKPLAN_SET_CANCEL';
export const UPDATE_DIGITAL_DIARY_WORKPLAN_SET_SUBMIT = 'UPDATE_DIGITALDIARY_WORKPLAN_SET_SUBMIT';
export const UPDATE_DIGITAL_DIARY_WORKPLAN_SET_SUCCESS = 'UPDATE_DIGITALDIARY_WORKPLAN_SET_SUCCESS';
export const UPDATE_DIGITAL_DIARY_WORKPLAN_TOPIC_SET_SUCCESS = 'UPDATE_DIGITALDIARY_WORKPLAN_TOPIC_SET_SUCCESS';
export const UPDATE_DIGITAL_DIARY_WORKPLAN_SET_FAILURE = 'UPDATE_DIGITALDIARY_WORKPLAN_SET_FAILURE';

export const REMOVE_DIGITAL_DIARY_WORKPLAN_SET = 'REMOVE_DIGITALDIARY_WORKPLAN_SET';
export const REMOVE_DIGITAL_DIARY_WORKPLAN_SET_SUCCESS = 'REMOVE_DIGITALDIARY_WORKPLAN_SET_SUCCESS';
export const REMOVE_DIGITAL_DIARY_WORKPLAN_TOPIC_SET_SUCCESS = 'REMOVE_DIGITALDIARY_WORKPLAN_TOPIC_SET_SUCCESS';

export const SAVEDATA_DIGITAL_DIARY_WORKPLAN = 'SAVEDATA_DIGITALDIARY_WORKPLAN';
export const SAVEDATA_DIGITAL_DIARY_WORKPLAN_SUCCESS = 'SAVEDATA_DIGITALDIARY_WORKPLAN_SUCCESS';

export const EXPORT_CHILDREN = 'EXPORT_CHILDREN';
export const EXPORT_CHILDREN_CANCEL = 'EXPORT_CHILDREN_CANCEL';
export const EXPORT_CHILDREN_SUBMIT = 'EXPORT_CHILDREN_SUBMIT';
export const EXPORT_CHILDREN_SUCCESS = 'EXPORT_CHILDREN_SUCCESS';
export const EXPORT_CHILDREN_FAILURE = 'EXPORT_CHILDREN_FAILURE';

export const IMPORT_CHILDREN = 'IMPORT_CHILDREN';
export const IMPORT_CHILDREN_CANCEL = 'IMPORT_CHILDREN_CANCEL';
export const IMPORT_CHILDREN_SUBMIT = 'IMPORT_CHILDREN_SUBMIT';
export const IMPORT_CHILDREN_SUCCESS = 'IMPORT_CHILDREN_SUCCESS';
export const IMPORT_CHILDREN_FAILURE = 'IMPORT_CHILDREN_FAILURE';

export const IMPORT_CHILDREN_CSV_SUBMIT = 'IMPORT_CHILDREN_CSV_SUBMIT';
export const IMPORT_CHILDREN_CSV_SUCCESS = 'IMPORT_CHILDREN_CSV_SUCCESS';
export const IMPORT_CHILDREN_CSV_FAILURE = 'IMPORT_CHILDREN_CSV_FAILURE';

export const IMPORT_CHILDREN_SUMMARY_START = 'IMPORT_CHILDREN_SUMMARY_START';
export const IMPORT_CHILDREN_SUMMARY_CANCEL = 'IMPORT_CHILDREN_SUMMARY_CANCEL';

export const LOAD_ENTRANCES_START = 'LOAD_ENTRANCES';
export const LOAD_ENTRANCES_SUCCESS = 'LOAD_ENTRANCES_SUCCESS';

export const IMPORT_ENTRANCE_START = 'IMPORT_ENTRANCE';
export const IMPORT_ENTRANCE_CANCEL = 'IMPORT_ENTRANCE_CANCEL';
export const IMPORT_ENTRANCE_SUBMIT = 'IMPORT_ENTRANCE_SUBMIT';
export const IMPORT_ENTRANCE_SUCCESS = 'IMPORT_ENTRANCE_SUCCESS';
export const IMPORT_ENTRANCE_FAILURE = 'IMPORT_ENTRANCE_FAILURE';

export const LOAD_LEGAL_GUARDIAN_APPLICATIONS = 'LOAD_LEGAL_GUARDIAN_APPLICATIONS';
export const LOAD_LEGAL_GUARDIAN_APPLICATIONS_SUCCESS = 'LOAD_LEGAL_GUARDIAN_APPLICATIONS_SUCCESS';

export const LEGAL_GUARDIAN_CREATE_APPLICATION = 'LEGAL_GUARDIAN_CREATE_APPLICATION';
export const LEGAL_GUARDIAN_CREATE_APPLICATION_SUCCESS = 'LEGAL_GUARDIAN_CREATE_APPLICATION_SUCCESS';
export const LEGAL_GUARDIAN_UPDATE_APPLICATION = 'LEGAL_GUARDIAN_UPDATE_APPLICATION';
export const LEGAL_GUARDIAN_UPDATE_APPLICATION_SUCCESS = 'LEGAL_GUARDIAN_UPDATE_APPLICATION_SUCCESS';

export const LEGAL_GUARDIAN_ARCHIVE_APPLICATION = 'LEGAL_GUARDIAN_ARCHIVE_APPLICATION';
export const LEGAL_GUARDIAN_ARCHIVE_APPLICATION_SUCCESS = 'LEGAL_GUARDIAN_ARCHIVE_APPLICATION_SUCCESS';

export const LOAD_ALL_APPLICATIONS = 'LOAD_ALL_APPLICATIONS';
export const LOAD_ALL_APPLICATIONS_SUCCESS = 'LOAD_ALL_APPLICATIONS_SUCCESS';

export const APPROVE_APPLICATION = 'APPROVE_APPLICATION';
export const APPROVE_APPLICATION_SUCCESS = 'APPROVE_APPLICATION_SUCCESS';

export const REJECT_APPLICATION = 'REJECT_APPLICATION';
export const REJECT_APPLICATION_SUCCESS = 'REJECT_APPLICATION_SUCCESS';

export const CHANGE_CONTRAST_COLOR = 'CHANGE_CONTRAST_COLOR';
export const CHANGE_THEME = 'CHANGE_THEME';

export const LOAD_EVENTS = 'LOAD_EVENTS';
export const LOAD_EVENTS_SUCCESS = 'LOAD_EVENTS_SUCCESS';
export const LOAD_EVENTS_FAILURE = 'LOAD_EVENTS_FAILURE';

export const FETCH_SIGN_URL = 'FETCH_SIGN_URL';
export const FETCH_SIGN_URL_SUCCESS = 'FETCH_SIGN_URL_SUCCESS';
export const FETCH_SIGN_URL_FAILURE = 'FETCH_SIGN_URL_FAILURE';

export const LOAD_LOGIN_HISTORY = 'LOAD_LOGIN_HISTORY';
export const LOAD_LOGIN_HISTORY_SUCCESS = 'LOAD_LOGIN_HISTORY_SUCCESS';
export const LOAD_LOGIN_HISTORY_FAILURE = 'LOAD_LOGIN_HISTORY_FAILURE';

export const LOAD_PROFILE_HISTORY = 'LOAD_PROFILE_HISTORY';
export const LOAD_PROFILE_HISTORY_SUCCESS = 'LOAD_PROFILE_HISTORY_SUCCESS';
export const LOAD_PROFILE_HISTORY_FAILURE = 'LOAD_PROFILE_HISTORY_FAILURE';

export const COMPLETE_AUTHORIZATION_BY_SIGN = 'COMPLETE_AUTHORIZATION_BY_SIGN';
export const COMPLETE_AUTHORIZATION_BY_SIGN_SUCCESS = 'COMPLETE_AUTHORIZATION_BY_SIGN_SUCCESS';
export const COMPLETE_AUTHORIZATION_BY_SIGN_FAILURE = 'COMPLETE_AUTHORIZATION_BY_SIGN_FAILURE';

export const LOAD_CURRENT_USERS = 'LOAD_CURRENT_USERS';
export const LOAD_CURRENT_USERS_SUCCESS = 'LOAD_CURRENT_USERS_SUCCESS';
export const LOAD_CURRENT_USERS_FAILURE = 'LOAD_CURRENT_USERS_FAILURE';

export const LOAD_CHILD_DETAILS_SUMMARY = 'LOAD_CHILD_DETAILS_SUMMARY';
export const LOAD_CHILD_DETAILS_SUMMARY_START = 'LOAD_CHILD_DETAILS_SUMMARY_START';
export const LOAD_CHILD_DETAILS_SUMMARY_SUCCESS = 'LOAD_CHILD_DETAILS_SUMMARY_SUCCESS';
export const LOAD_CHILD_DETAILS_SUMMARY_FAILURE = 'LOAD_CHILD_DETAILS_SUMMARY_FAILURE';

export const GALLERY_LOADING = 'GALLERY_LOADING';
export const GALLERY_LOADING_RESET = 'GALLERY_LOADING_RESET';
export const GALLERY_LOADING_SUCCESS = 'GALLERY_LOADING_SUCCESS';
export const GALLERY_CREATED_ALBUM = 'GALLERY_CREATED_ALBUM';
export const GALLERY_FETCH_ALBUMS = 'GALLERY_FETCH_ALBUMS';
export const GALLERY_UPDATED_ALBUM = 'GALLERY_UPDATED_ALBUM';
export const GALLERY_DELETED_ALBUM = 'GALLERY_DELETED_ALBUM';

export const GALLERY_MULTIMEDIA_LOADING = 'GALLERY_MULTIMEDIA_LOADING';
export const GALLERY_MULTIMEDIA_LOADING_RESET = 'GALLERY_MULTIMEDIA_LOADING_RESET';
export const GALLERY_MULTIMEDIA_LOADING_SUCCESS = 'GALLERY_MULTIMEDIA_LOADING_SUCCESS';
export const GALLERY_ADD_MULTIMEDIA = 'GALLERY_ADD_MULTIMEDIA';
export const GALLERY_MOVED_MULTIMEDIA = 'GALLERY_MOVED_MULTIMEDIA';
export const GALLERY_FETCH_MULTIMEDIA = 'GALLERY_FETCH_MULTIMEDIA';
export const GALLERY_UPDATED_MULTIMEDIA = 'GALLERY_UPDATED_MULTIMEDIA';
export const GALLERY_DELETED_MULTIMEDIA = 'GALLERY_DELETED_MULTIMEDIA';

export const RIGHT_BOTTOM_NOTIFICATION_START = 'RIGHT_BOTTOM_NOTIFICATION_START';
export const RIGHT_BOTTOM_NOTIFICATION_RESET = 'RIGHT_BOTTOM_NOTIFICATION_RESET';
export const RIGHT_BOTTOM_NOTIFICATION_UPDATE = 'RIGHT_BOTTOM_NOTIFICATION_UPDATE';
export const RIGHT_BOTTOM_NOTIFICATION_REMOVE = 'RIGHT_BOTTOM_NOTIFICATION_REMOVE';

export const LOAD_STAFF_EVENTS_BEGIN = 'LOAD_STAFF_EVENTS_BEGIN';
export const LOAD_STAFF_EVENTS_SUCCESS = 'LOAD_STAFF_EVENTS_SUCCESS';
export const LOAD_STAFF_EVENTS_FAILURE = 'LOAD_STAFF_EVENTS_FAILURE';

export const CREATE_STAFF_EVENT_BEGIN = 'CREATE_STAFF_EVENT_BEGIN';
export const CREATE_STAFF_EVENT_SUCCESS = 'CREATE_STAFF_EVENT_SUCCESS';
export const CREATE_STAFF_EVENT_FAILURE = 'CREATE_STAFF_EVENT_FAILURE';

export const UPDATE_STAFF_EVENT_BEGIN = 'UPDATE_STAFF_EVENT_BEGIN';
export const UPDATE_STAFF_EVENT_SUCCESS = 'UPDATE_STAFF_EVENT_SUCCESS';
export const UPDATE_STAFF_EVENT_FAILURE = 'UPDATE_STAFF_EVENT_FAILURE';

export const DELETE_STAFF_EVENT_BEGIN = 'DELETE_STAFF_EVENT_BEGIN';
export const DELETE_STAFF_EVENT_SUCCESS = 'DELETE_STAFF_EVENT_SUCCESS';
export const DELETE_STAFF_EVENT_FAILURE = 'DELETE_STAFF_EVENT_FAILURE';

export const LOAD_STAFF_EVENT_DETAILS_BEGIN = 'LOAD_STAFF_EVENT_DETAILS_BEGIN';
export const LOAD_STAFF_EVENT_DETAILS_SUCCESS = 'LOAD_STAFF_EVENT_DETAILS_SUCCESS';
export const LOAD_STAFF_EVENT_DETAILS_FAILURE = 'LOAD_STAFF_EVENT_DETAILS_FAILURE';

export const LOAD_STAFF_MESSAGE_BOARD_POSTS_BEGIN = 'LOAD_STAFF_MESSAGE_BOARD_POSTS_BEGIN';
export const LOAD_STAFF_MESSAGE_BOARD_POSTS_SUCCESS = 'LOAD_STAFF_MESSAGE_BOARD_POSTS_SUCCESS';
export const LOAD_STAFF_MESSAGE_BOARD_POSTS_FAILURE = 'LOAD_STAFF_MESSAGE_BOARD_POSTS_FAILURE';

export const LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_BEGIN = 'LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_BEGIN';
export const LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_SUCCESS = 'LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_SUCCESS';
export const LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_FAILURE = 'LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_FAILURE';

export const CREATE_STAFF_MESSAGE_BOARD_POST_BEGIN = 'CREATE_STAFF_MESSAGE_BOARD_POST_BEGIN';
export const CREATE_STAFF_MESSAGE_BOARD_POST_SUCCESS = 'CREATE_STAFF_MESSAGE_BOARD_POST_SUCCESS';
export const CREATE_STAFF_MESSAGE_BOARD_POST_FAILURE = 'CREATE_STAFF_MESSAGE_BOARD_POST_FAILURE';

export const UPDATE_STAFF_MESSAGE_BOARD_POST_BEGIN = 'UPDATE_STAFF_MESSAGE_BOARD_POST_BEGIN';
export const UPDATE_STAFF_MESSAGE_BOARD_POST_SUCCESS = 'UPDATE_STAFF_MESSAGE_BOARD_POST_SUCCESS';
export const UPDATE_STAFF_MESSAGE_BOARD_POST_FAILURE = 'UPDATE_STAFF_MESSAGE_BOARD_POST_FAILURE';

export const DELETE_STAFF_MESSAGE_BOARD_POST_BEGIN = 'DELETE_STAFF_MESSAGE_BOARD_POST_BEGIN';
export const DELETE_STAFF_MESSAGE_BOARD_POST_SUCCESS = 'DELETE_STAFF_MESSAGE_BOARD_POST_SUCCESS';
export const DELETE_STAFF_MESSAGE_BOARD_POST_FAILURE = 'DELETE_STAFF_MESSAGE_BOARD_POST_FAILURE';

export const SCHEDULED_TRANSFER_FETCH = 'SCHEDULED_TRANSFER_FETCH';
export const SCHEDULED_TRANSFER_CREATE = 'SCHEDULED_TRANSFER_CREATE';
export const SCHEDULED_TRANSFER_EDIT = 'SCHEDULED_TRANSFER_EDIT';
export const SCHEDULED_TRANSFER_DELETE = 'SCHEDULED_TRANSFER_DELETE';
export const SCHEDULED_TRANSFER_DELETE_MANY = 'SCHEDULED_TRANSFER_DELETE_MANY';
export const SCHEDULED_TRANSFER_ERROR = 'SCHEDULED_TRANSFER_FETCH_ERROR';

export const VIRTUAL_DISC_FETCH_FILES = 'VIRTUAL_DISC_FETCH_FILES';
export const VIRTUAL_DISC_SHARE_FILES = 'VIRTUAL_DISC_SHARE_FILES';
export const VIRTUAL_DISC_ADD_FILE = 'VIRTUAL_DISC_ADD_FILE';
export const VIRTUAL_DISC_DELETE_FILE = 'VIRTUAL_DISC_DELETE_FILE';
export const VIRTUAL_DISC_DELETE_MANY_FILES = 'VIRTUAL_DISC_DELETE_MANY_FILES';

//virtual disc folders
export const VIRTUAL_DISC_CREATE_FOLDER = 'VIRTUAL_DISC_CREATE_FOLDER';
export const VIRTUAL_DISC_DELETE_FOLDER = 'VIRTUAL_DISC_DELETE_FOLDER';
export const VIRTUAL_DISC_EDIT_FOLDER = 'VIRTUAL_DISC_EDIT_FOLDER';
export const VIRTUAL_DISC_MOVE_TO_FOLDER = 'VIRTUAL_DISC_MOVE_TO_FOLDER';
export const VIRTUAL_DISC_MOVE_FOLDER = 'VIRTUAL_DISC_MOVE_FOLDER';
export const VIRTUAL_DISC_GET_FOLDER_CONTENT = 'VIRTUAL_DISC_GET_FOLDER_CONTENT';
export const VIRTUAL_DISC_GET_FOLDER_METADATA = 'VIRTUAL_DISC_GET_FOLDER_METADATA';
export const VIRTUAL_DISC_SHARE_FOLDER = 'VIRTUAL_DISC_SHARE_FOLDER';

//creating, editing, deleting, moving, downloading itp
export const VIRTUAL_DISC_ACTION_FOLDER_SUBMIT = 'VIRTUAL_DISC_ACTION_FOLDER_SUBMIT';
export const VIRTUAL_DISC_ACTION_FOLDER_SUCCESS = 'VIRTUAL_DISC_ACTION_FOLDER_SUCCESS';
export const VIRTUAL_DISC_ACTION_FOLDER_FAILURE = 'VIRTUAL_DISC_ACTION_FOLDER_FAILURE';
//fetch list or details
export const VIRTUAL_DISC_FETCH_FOLDER_SUBMIT = 'VIRTUAL_DISC_FETCH_FOLDER_SUBMIT';
export const VIRTUAL_DISC_FETCH_FOLDER_SUCCESS = 'VIRTUAL_DISC_FETCH_FOLDER_SUCCESS';
export const VIRTUAL_DISC_FETCH_FOLDER_FAILURE = 'VIRTUAL_DISC_FETCH_FOLDER_FAILURE';

//Individual classes

export const LOAD_INDIVIDUAL_CLASSES = 'LOAD_INDIVIDUAL_CLASSES';

export const ADD_INDIVIDUAL_CLASSES = 'ADD_INDIVIDUAL_CLASSES';
export const EDIT_INDIVIDUAL_CLASSES = 'EDIT_INDIVIDUAL_CLASSES';
export const DELETE_INDIVIDUAL_CLASSES = 'DELETE_INDIVIDUAL_CLASSES';
export const INDIVIDUAL_CLASSES_START = 'INDIVIDUAL_CLASSES_START';
export const INDIVIDUAL_CLASSES_CANCEL = 'INDIVIDUAL_CLASSES_CANCEL';
export const INDIVIDUAL_CLASSES_SUBMIT = 'INDIVIDUAL_CLASSES_SUBMIT';
export const INDIVIDUAL_CLASSES_FAILURE = 'INDIVIDUAL_CLASSES_FAILURE';

export const ADD_INDIVIDUAL_PROGRAM = 'ADD_INDIVIDUAL_PROGRAM';
export const EDIT_INDIVIDUAL_PROGRAM = 'EDIT_INDIVIDUAL_PROGRAM';
export const DELETE_INDIVIDUAL_PROGRAM = 'DELETE_INDIVIDUAL_PROGRAM';

export const INDIVIDUAL_PROGRAM_START = 'INDIVIDUAL_PROGRAM_START';
export const INDIVIDUAL_PROGRAM_CANCEL = 'INDIVIDUAL_PROGRAM_CANCEL';
export const INDIVIDUAL_PROGRAM_SUBMIT = 'INDIVIDUAL_PROGRAM_SUBMIT';
export const INDIVIDUAL_PROGRAM_FAILURE = 'INDIVIDUAL_PROGRAM_FAILURE';

export const INDIVIDUAL_PROGRAM_NOTE_START = 'INDIVIDUAL_PROGRAM_NOTE_START';
export const INDIVIDUAL_PROGRAM_NOTE_CANCEL = 'INDIVIDUAL_PROGRAM_NOTE_CANCEL';
export const INDIVIDUAL_PROGRAM_NOTE_SUBMIT = 'INDIVIDUAL_PROGRAM_NOTE_SUBMIT';
export const INDIVIDUAL_PROGRAM_NOTE_FAILURE = 'INDIVIDUAL_PROGRAM_NOTE_FAILURE';

//individual classes journal

export const LOAD_INDIVIDUAL_JOURNAL_CLASSES = 'LOAD_INDIVIDUAL_JOURNAL_CLASSES';

export const REPORT_INDIVIDUAL_ATTENDANCE = 'REPORT_INDIVIDUAL_ATTENDANCE';
export const REPORT_INDIVIDUAL_ABSENCE = 'REPORT_INDIVIDUAL_ABSENCE';

export const REPORT_INDIVIDUAL_JOURNAL_ATTENDANCE = 'REPORT_INDIVIDUAL_JOURNAL_ATTENDANCE';
export const REPORT_INDIVIDUAL_JOURNAL_ABSENCE = 'REPORT_INDIVIDUAL_JOURNAL_ABSENCE';

export const ADD_INDIVIDUAL_JOURNAL_CLASSES = 'ADD_INDIVIDUAL_JOURNAL_CLASSES';
export const EDIT_INDIVIDUAL_JOURNAL_CLASSES = 'EDIT_INDIVIDUAL_JOURNAL_CLASSES';
export const DELETE_INDIVIDUAL_JOURNAL_CLASSES = 'DELETE_INDIVIDUAL_JOURNAL_CLASSES';
export const INDIVIDUAL_JOURNAL_CLASSES_START = 'INDIVIDUAL_JOURNAL_CLASSES_START';
export const INDIVIDUAL_JOURNAL_CLASSES_CANCEL = 'INDIVIDUAL_JOURNAL_CLASSES_CANCEL';
export const INDIVIDUAL_JOURNAL_CLASSES_SUBMIT = 'INDIVIDUAL_JOURNAL_CLASSES_SUBMIT';
export const INDIVIDUAL_JOURNAL_CLASSES_FAILURE = 'INDIVIDUAL_JOURNAL_CLASSES_FAILURE';

export const ADD_INDIVIDUAL_JOURNAL_PROGRAM = 'ADD_INDIVIDUAL_JOURNAL_PROGRAM';
export const EDIT_INDIVIDUAL_JOURNAL_PROGRAM = 'EDIT_INDIVIDUAL_JOURNAL_PROGRAM';
export const DELETE_INDIVIDUAL_JOURNAL_PROGRAM = 'DELETE_INDIVIDUAL_JOURNAL_PROGRAM';

export const INDIVIDUAL_JOURNAL_PROGRAM_START = 'INDIVIDUAL_JOURNAL_PROGRAM_START';
export const INDIVIDUAL_JOURNAL_PROGRAM_CANCEL = 'INDIVIDUAL_JOURNAL_PROGRAM_CANCEL';
export const INDIVIDUAL_JOURNAL_PROGRAM_SUBMIT = 'INDIVIDUAL_JOURNAL_PROGRAM_SUBMIT';
export const INDIVIDUAL_JOURNAL_PROGRAM_FAILURE = 'INDIVIDUAL_JOURNAL_PROGRAM_FAILURE';

export const INDIVIDUAL_JOURNAL_PROGRAM_NOTE_START = 'INDIVIDUAL_JOURNAL_PROGRAM_NOTE_START';
export const INDIVIDUAL_JOURNAL_PROGRAM_NOTE_CANCEL = 'INDIVIDUAL_JOURNAL_PROGRAM_NOTE_CANCEL';
export const INDIVIDUAL_JOURNAL_PROGRAM_NOTE_SUBMIT = 'INDIVIDUAL_JOURNAL_PROGRAM_NOTE_SUBMIT';
export const INDIVIDUAL_JOURNAL_PROGRAM_NOTE_FAILURE = 'INDIVIDUAL_JOURNAL_PROGRAM_NOTE_FAILURE';

//Journal Program notes

export const ADD_INDIVIDUAL_JOURNAL_PROGRAM_NOTE = 'ADD_INDIVIDUAL_JOURNAL_PROGRAM_NOTE';
export const EDIT_INDIVIDUAL_JOURNAL_PROGRAM_NOTE = 'EDIT_INDIVIDUAL_JOURNAL_PROGRAM_NOTE';
export const DELETE_INDIVIDUAL_JOURNAL_PROGRAM_NOTE = 'DELETE_INDIVIDUAL_JOURNAL_PROGRAM_NOTE';

export const INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION_START = 'INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION_START';
export const INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION_CANCEL = 'INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION_CANCEL';
export const INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION_SUBMIT = 'INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION_SUBMIT';
export const INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION_FAILURE = 'INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION_FAILURE';

export const ADD_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION = 'ADD_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION';
export const EDIT_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION = 'EDIT_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION';
export const DELETE_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION = 'DELETE_INDIVIDUAL_JOURNAL_PROGRAM_OBSERVATION';

//Program notes

export const ADD_INDIVIDUAL_PROGRAM_NOTE = 'ADD_INDIVIDUAL_PROGRAM_NOTE';
export const EDIT_INDIVIDUAL_PROGRAM_NOTE = 'EDIT_INDIVIDUAL_PROGRAM_NOTE';
export const DELETE_INDIVIDUAL_PROGRAM_NOTE = 'DELETE_INDIVIDUAL_PROGRAM_NOTE';

export const INDIVIDUAL_PROGRAM_OBSERVATION_START = 'INDIVIDUAL_PROGRAM_OBSERVATION_START';
export const INDIVIDUAL_PROGRAM_OBSERVATION_CANCEL = 'INDIVIDUAL_PROGRAM_OBSERVATION_CANCEL';
export const INDIVIDUAL_PROGRAM_OBSERVATION_SUBMIT = 'INDIVIDUAL_PROGRAM_OBSERVATION_SUBMIT';
export const INDIVIDUAL_PROGRAM_OBSERVATION_FAILURE = 'INDIVIDUAL_PROGRAM_OBSERVATION_FAILURE';

export const ADD_INDIVIDUAL_PROGRAM_OBSERVATION = 'ADD_INDIVIDUAL_PROGRAM_OBSERVATION';
export const EDIT_INDIVIDUAL_PROGRAM_OBSERVATION = 'EDIT_INDIVIDUAL_PROGRAM_OBSERVATION';
export const DELETE_INDIVIDUAL_PROGRAM_OBSERVATION = 'DELETE_INDIVIDUAL_PROGRAM_OBSERVATION';

//Group classes

export const LOAD_GROUP_CLASSES = 'LOAD_GROUP_CLASSES';
export const ADD_GROUP_CLASSES = 'ADD_GROUP_CLASSES';
export const EDIT_GROUP_CLASSES = 'EDIT_GROUP_CLASSES';
export const DELETE_GROUP_CLASSES = 'DELETE_GROUP_CLASSES';
export const GROUP_CLASSES_START = 'GROUP_CLASSES_START';
export const GROUP_CLASSES_CANCEL = 'GROUP_CLASSES_CANCEL';
export const GROUP_CLASSES_SUBMIT = 'GROUP_CLASSES_SUBMIT';
export const GROUP_CLASSES_FAILURE = 'GROUP_CLASSES_FAILURE';

export const ADD_GROUP_DIARY = 'ADD_GROUP_DIARY';
export const EDIT_GROUP_DIARY = 'EDIT_GROUP_DIARY';
export const DELETE_GROUP_DIARY = 'DELETE_GROUP_DIARY';
export const GROUP_DIARY_START = 'GROUP_DIARY_START';
export const GROUP_DIARY_CANCEL = 'GROUP_DIARY_CANCEL';
export const GROUP_DIARY_SUBMIT = 'GROUP_DIARY_SUBMIT';
export const GROUP_DIARY_FAILURE = 'GROUP_DIARY_FAILURE';

export const ADD_GROUP_PROGRAM = 'ADD_GROUP_PROGRAM';
export const EDIT_GROUP_PROGRAM = 'EDIT_GROUP_PROGRAM';
export const DELETE_GROUP_PROGRAM = 'DELETE_GROUP_PROGRAM';

export const GROUP_PROGRAM_START = 'GROUP_PROGRAM_START';
export const GROUP_PROGRAM_CANCEL = 'GROUP_PROGRAM_CANCEL';
export const GROUP_PROGRAM_SUBMIT = 'GROUP_PROGRAM_SUBMIT';
export const GROUP_PROGRAM_FAILURE = 'GROUP_PROGRAM_FAILURE';

//PSYCHO

export const LOAD_PSYCHOLOGICAL_HELP_LIST = 'LOAD_PSYCHOLOGICAL_HELP_LIST';
export const ARCHIVE_PSYCHOLOGICAL_HELP = 'ARCHIVE_PSYCHOLOGICAL_HELP';
export const ADD_PSYCHOLOGICAL_NOTIFICATION = 'ADD_PSYCHOLOGICAL_NOTIFICATION';
export const EDIT_PSYCHOLOGICAL_NOTIFICATION = 'EDIT_PSYCHOLOGICAL_NOTIFICATION';
export const ARCHIVE_PSYCHOLOGICAL_SCHEME = 'ARCHIVE_PSYCHOLOGICAL_SCHEME';

export const ADD_PSYCHOLOGICAL_HELP_START = 'ADD_PSYCHOLOGICAL_HELP_START';
export const ADD_PSYCHOLOGICAL_HELP_CANCEL = 'ADD_PSYCHOLOGICAL_HELP_CANCEL';
export const ADD_PSYCHOLOGICAL_HELP_SUBMIT = 'ADD_PSYCHOLOGICAL_HELP_SUBMIT';
export const ADD_PSYCHOLOGICAL_HELP = 'ADD_PSYCHOLOGICAL_HELP';
export const EDIT_PSYCHOLOGICAL_HELP = 'EDIT_PSYCHOLOGICAL_HELP';
export const ADD_PSYCHOLOGICAL_HELP_FAILURE = 'ADD_PSYCHOLOGICAL_HELP_FAILURE';

export const LOAD_PSYCHOLOGICAL_DETAILS = 'LOAD_PSYCHOLOGICAL_DETAILS';

export const CHANGE_PSYCHOLOGICAL_GROUP = 'CHANGE_PSYCHOLOGICAL_GROUP';
export const LOAD_PSYCHOLOGICAL_GROUPS = 'LOAD_PSYCHOLOGICAL_GROUPS';
export const LOAD_PSYCHOLOGICAL_GROUP_ATTENDANCE = 'LOAD_PSYCHOLOGICAL_GROUP_ATTENDANCE';
export const REPORT_PSYCHOLOGICAL_GROUP_ATTENDANCE = 'REPORT_PSYCHOLOGICAL_GROUP_ATTENDANCE';
export const REPORT_PSYCHOLOGICAL_GROUP_ABSENCE = 'REPORT_PSYCHOLOGICAL_GROUP_ABSENCE';

export const ADD_PSYCHOLOGICAL_GROUP = 'ADD_PSYCHOLOGICAL_GROUP';
export const EDIT_PSYCHOLOGICAL_GROUP = 'EDIT_PSYCHOLOGICAL_GROUP';

export const ARCHIVE_PSYCHOLOGICAL_GROUP = 'ARCHIVE_PSYCHOLOGICAL_GROUP';
export const ARCHIVE_PSYCHOLOGICAL_GROUP_CANCEL = 'ARCHIVE_PSYCHOLOGICAL_GROUP_CANCEL';
export const ARCHIVE_PSYCHOLOGICAL_GROUP_SUCCESS = 'ARCHIVE_PSYCHOLOGICAL_GROUP_SUCCESS';
export const ARCHIVE_PSYCHOLOGICAL_GROUP_SUBMIT = 'ARCHIVE_PSYCHOLOGICAL_GROUP_SUBMIT';
export const ARCHIVE_PSYCHOLOGICAL_GROUP_FAILURE = 'ARCHIVE_PSYCHOLOGICAL_GROUP_FAILURE';

export const UPDATE_PSYCHOLOGICAL_GROUP = 'UPDATE_PSYCHOLOGICAL_GROUP';
export const UPDATE_PSYCHOLOGICAL_GROUP_CANCEL = 'UPDATE_PSYCHOLOGICAL_GROUP_CANCEL';
export const UPDATE_PSYCHOLOGICAL_GROUP_SUBMIT = 'UPDATE_PSYCHOLOGICAL_GROUP_SUBMIT';
export const UPDATE_PSYCHOLOGICAL_GROUP_SUCCESS = 'UPDATE_PSYCHOLOGICAL_GROUP_SUCCESS';
export const UPDATE_PSYCHOLOGICAL_GROUP_FAILURE = 'UPDATE_PSYCHOLOGICAL_GROUP_FAILURE';

export const ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP = 'ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP';
export const ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_CANCEL = 'ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_CANCEL';
export const ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_SUBMIT = 'ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_SUBMIT';
export const ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_SUCCESS = 'ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_SUCCESS';
export const ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_FAILURE = 'ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_FAILURE';

export const LOAD_PSYCHOLOGICAL_GROUP_DETAILS_SUCCESS = 'LOAD_PSYCHOLOGICAL_GROUP_DETAILS_SUCCESS';

export const REMOVE_CHILD_FROM_PSYCHOLOGICAL_GROUP_SUCCESS = 'REMOVE_CHILD_FROM_PSYCHOLOGICAL_GROUP_SUCCESS';

export const ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP = 'ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP';
export const ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_CANCEL = 'ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_CANCEL';
export const ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_SUBMIT = 'ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_SUBMIT';
export const ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_SUCCESS = 'ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_SUCCESS';
export const ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_FAILURE = 'ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_FAILURE';

export const REMOVE_TEACHER_FROM_PSYCHOLOGICAL_GROUP_SUCCESS = 'REMOVE_TEACHER_FROM_PSYCHOLOGICAL_GROUP_SUCCESS';

export const LOAD_PSYCHOLOGICAL_SCHEMES_LIST = 'LOAD_PSYCHOLOGICAL_SCHEMES_LIST';
export const DELETE_PSYCHOLOGICAL_SCHEMES = 'DELETE_PSYCHOLOGICAL_SCHEMES';

export const PSYCHOLOGICAL_SCHEMES_START = 'PSYCHOLOGICAL_SCHEMES_START';
export const ADD_PSYCHOLOGICAL_SCHEMES_SUBMIT = 'ADD_PSYCHOLOGICAL_SCHEMES_SUBMIT';
export const ADD_PSYCHOLOGICAL_SCHEMES = 'ADD_PSYCHOLOGICAL_SCHEMES';
export const ADD_PSYCHOLOGICAL_SCHEMES_FAILURE = 'ADD_PSYCHOLOGICAL_SCHEMES_FAILURE';
export const PSYCHOLOGICAL_SCHEMES_CANCEL = 'PSYCHOLOGICAL_SCHEMES_CANCEL';
export const EDIT_PSYCHOLOGICAL_SCHEMES = 'EDIT_PSYCHOLOGICAL_SCHEMES';
export const EDIT_PSYCHOLOGICAL_SCHEMES_SUBMIT = 'EDIT_PSYCHOLOGICAL_SCHEMES_SUBMIT';
export const EDIT_PSYCHOLOGICAL_SCHEMES_FAILURE = 'EDIT_PSYCHOLOGICAL_SCHEMES_FAILURE';

export const ADD_WOPFU = 'ADD_WOPFU';
export const ADD_IPET = 'ADD_IPET';
export const EDIT_WOPFU = 'EDIT_WOPFU';
export const EDIT_IPET = 'EDIT_IPET';

export const ARCHIVE_PSYCHOLOGICAL_NOFITICATION = 'ARCHIVE_PSYCHOLOGICAL_NOFITICATION';

export const ARCHIVE_WOPFU = 'ARCHIVE_WOPFU';
export const ARCHIVE_IPET = 'ARCHIVE_IPET';

export const CREATE_NOTIFY_UI_START = 'CREATE_NOTIFY_UI_START';
export const CREATE_NOTIFY_UI_CANCEL = 'CREATE_NOTIFY_UI_CANCEL';
export const CREATE_NOTIFY_UI_SUBMIT = 'CREATE_NOTIFY_UI_SUBMIT';
export const CREATE_NOTIFY_UI_FAILURE = 'CREATE_NOTIFY_UI_FAILURE';

export const CREATE_IPET_UI_START = 'CREATE_IPET_UI_START';
export const CREATE_IPET_UI_CANCEL = 'CREATE_IPET_UI_CANCEL';
export const CREATE_IPET_UI_SUBMIT = 'CREATE_IPET_UI_SUBMIT';
export const CREATE_IPET_UI_FAILURE = 'CREATE_IPET_UI_FAILURE';

export const CREATE_WOPFU_UI_START = 'CREATE_WOPFU_UI_START';
export const CREATE_WOPFU_UI_CANCEL = 'CREATE_WOPFU_UI_CANCEL';
export const CREATE_WOPFU_UI_SUBMIT = 'CREATE_WOPFU_UI_SUBMIT';
export const CREATE_WOPFU_UI_FAILURE = 'CREATE_WOPFU_UI_FAILURE';

//Child Individual Classes Attendance

export const LOAD_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE = 'LOAD_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE';

export const REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_REVERT_ABSENCE_SUCCESS =
  'REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_REVERT_ABSENCE_SUCCESS';

export const REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_START =
  'REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_START';
export const REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_SUBMIT =
  'REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_SUBMIT';
export const REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_SUCCESS =
  'REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_SUCCESS';
export const REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_FAILURE =
  'REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_FAILURE';
export const REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_CANCEL =
  'REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_CANCEL';

export const REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ABSENCE_START =
  'REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ABSENCE_START';
export const REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ABSENCE_CANCEL =
  'REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ABSENCE_CANCEL';

export const REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_REVERT_SUBMIT =
  'REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_REVERT_SUBMIT';
export const REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_REVERT_SUCCESS =
  'REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_REVERT_SUCCESS';
//Child Group Classes Attendance
export const LOAD_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE = 'LOAD_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE';

export const REPORT_CHILD_PSYCHOLOGICAL_GROUP_REVERT_ABSENCE_SUCCESS =
  'REPORT_CHILD_PSYCHOLOGICAL_GROUP_REVERT_ABSENCE_SUCCESS';

export const REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_START = 'REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_START';
export const REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_SUBMIT = 'REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_SUBMIT';
export const REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_SUCCESS =
  'REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_SUCCESS';
export const REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_FAILURE =
  'REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_FAILURE';
export const REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_CANCEL = 'REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_CANCEL';
export const REPORT_CHILD_PSYCHOLOGICAL_GROUP_REVERT_SUCCESS = 'REPORT_CHILD_PSYCHOLOGICAL_GROUP_REVERT_SUCCESS';
export const LOAD_PSYCHOLOGICAL_CHILDREN_SUCCESS = 'LOAD_PSYCHOLOGICAL_CHILDREN_SUCCESS';

export const LOAD_INDIVIDUAL_CLASSES_ATTENDANCE = 'LOAD_INDIVIDUAL_CLASSES_ATTENDANCE';
export const LOAD_INDIVIDUAL_CLASSES_CALENDAR = 'LOAD_INDIVIDUAL_CLASSES_CALENDAR';
export const LOAD_INDIVIDUAL_JOURNAL_CLASSES_ATTENDANCE = 'LOAD_INDIVIDUAL_JOURNAL_CLASSES_ATTENDANCE';
export const LOAD_INDIVIDUAL_JOURNAL_CLASSES_CALENDAR = 'LOAD_INDIVIDUAL_JOURNAL_CLASSES_CALENDAR';
export const LOAD_GROUP_CLASSES_CALENDAR = 'LOAD_GROUP_CLASSES_CALENDAR';
export const LOAD_GROUP_DIARY_CALENDAR = 'LOAD_GROUP_DIARY_CALENDAR';

//Specialists
export const LOAD_SPECIALISTS = 'LOAD_SPECIALISTS';
export const LOAD_SPECIALIST_DETAILS = 'LOAD_SPECIALIST_DETAILS';
export const LOAD_SPECIALIST_CLASSES_SCHEDULE = 'LOAD_SPECIALIST_CLASSES_SCHEDULE';
export const LOAD_SPECIALIST_GROUP_ACTIVITIES = 'LOAD_SPECIALIST_GROUP_ACTIVITIES';
export const LOAD_SPECIALIST_WORK_PLAN_PROGRESS = 'LOAD_SPECIALIST_WORK_PLAN_PROGRESS';
export const CREATE_SPECIALIST_WORK_PLAN_SUCCESS = 'CREATE_SPECIALIST_WORK_PLAN_SUCCESS';

//Specialist Notes
export const LOAD_SPECIALIST_NOTES = 'LOAD_SPECIALIST_NOTES';
export const CREATE_SPECIALIST_NOTES_START = 'CREATE_SPECIALIST_START';
export const CREATE_SPECIALIST_NOTES_SUBMIT = 'CREATE_SPECIALIST_NOTES_SUBMIT';
export const CREATE_SPECIALIST_NOTES_CANCEL = 'CREATE_SPECIALIST_NOTES_CANCEL';
export const CREATE_SPECIALIST_NOTES_FAILURE = 'CREATE_SPECIALIST_NOTES_FAILURE';
export const CREATE_SPECIALIST_NOTES_SUCCESS = 'CREATE_SPECIALIST_NOTES_SUCCESS';

export const MODIFY_SPECIALIST_NOTES_START = 'MODIFY_SPECIALIST_NOTES_START';
export const MODIFY_SPECIALIST_NOTES_SUBMIT = 'MODIFY_SPECIALIST_NOTES_SUBMIT';
export const MODIFY_SPECIALIST_NOTES_CANCEL = 'MODIFY_SPECIALIST_NOTES_CANCEL';
export const MODIFY_SPECIALIST_NOTES_FAILURE = 'MODIFY_SPECIALIST_NOTES_FAILURE';
export const MODIFY_SPECIALIST_NOTES_SUCCESS = 'MODIFY_SPECIALIST_NOTES_SUCCESS';
export const DELETE_SPECIALIST_NOTES_SUCCESS = 'DELETE_SPECIALIST_NOTES_SUCCESS';

//contacts
export const ADD_CONTACT_TO_SPECIALIST_NOTES_SUCCESS = 'ADD_CONTACT_TO_SPECIALIST_NOTES_SUCCESS';
export const EDIT_SPECIALIST_CONTACT = 'EDIT_SPECIALIST_CONTACT';
export const ARCHIVE_SPECIALIST_CONTACT = 'ARCHIVE_SPECIALIST_CONTACT';
export const ADD_CONTACT_START = 'ADD_CONTACT_START';
export const ADD_CONTACT_CANCEL = 'ADD_CONTACT_CANCEL';
export const ADD_CONTACT_SUBMIT = 'ADD_CONTACT_SUBMIT';
export const ADD_CONTACT_FAILURE = 'ADD_CONTACT_FAILURE';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';

//classSchedule
export const ADD_SPECIALIST_CLASS_SCHEDULE_ELEMENT = 'ADD_SPECIALIST_CLASS_SCHEDULE_ELEMENT';
export const EDIT_SPECIALIST_CLASS_SCHEDULE_ELEMENT = 'EDIT_SPECIALIST_CLASS_SCHEDULE_ELEMENT';
export const DELETE_SPECIALIST_CLASS_SCHEDULE_ELEMENT = 'DELETE_SPECIALIST_CLASS_SCHEDULE_ELEMENT';

export const SPECIALIST_CLASS_SCHEDULE_START = 'SPECIALIST_CLASS_SCHEDULE_START';
export const SPECIALIST_CLASS_SCHEDULE_CANCEL = 'SPECIALIST_CLASS_SCHEDULE_CANCEL';
export const SPECIALIST_CLASS_SCHEDULE_SUBMIT = 'SPECIALIST_CLASS_SCHEDULE_SUBMIT';
export const SPECIALIST_CLASS_SCHEDULE_FAILURE = 'SPECIALIST_CLASS_SCHEDULE_FAILURE';
//workProgram
export const ADD_SPECIALIST_WORK_PLAN_ELEMENT = 'ADD_SPECIALIST_WORK_PLAN_ELEMENT';
export const EDIT_SPECIALIST_WORK_PLAN_ELEMENT = 'EDIT_SPECIALIST_WORK_PLAN_ELEMENT';
export const DELETE_SPECIALIST_WORK_PLAN_ELEMENT = 'DELETE_SPECIALIST_WORK_PLAN_ELEMENT';

export const SPECIALIST_WORK_PLAN_START = 'SPECIALIST_WORK_PLAN_START';
export const SPECIALIST_WORK_PLAN_CANCEL = 'SPECIALIST_WORK_PLAN_CANCEL';
export const SPECIALIST_WORK_PLAN_SUBMIT = 'SPECIALIST_WORK_PLAN_SUBMIT';
export const SPECIALIST_WORK_PLAN_FAILURE = 'SPECIALIST_WORK_PLAN_FAILURE';
//workProgramProgress
export const ADD_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT = 'ADD_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT';
export const EDIT_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT = 'EDIT_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT';
export const DELETE_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT = 'DELETE_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT';

export const SPECIALIST_WORK_PLAN_PROGRESS_START = 'SPECIALIST_WORK_PLAN_PROGRESS_START';
export const SPECIALIST_WORK_PLAN_PROGRESS_CANCEL = 'SPECIALIST_WORK_PLAN_PROGRESS_CANCEL';
export const SPECIALIST_WORK_PLAN_PROGRESS_SUBMIT = 'SPECIALIST_WORK_PLAN_PROGRESS_SUBMIT';
export const SPECIALIST_WORK_PLAN_PROGRESS_FAILURE = 'SPECIALIST_WORK_PLAN_PROGRESS_FAILURE';
//GroupActivities
export const ADD_SPECIALIST_GROUP_ACTIVITIES_ELEMENT = 'ADD_SPECIALIST_GROUP_ACTIVITIES_ELEMENT';
export const EDIT_SPECIALIST_GROUP_ACTIVITIES_ELEMENT = 'EDIT_SPECIALIST_GROUP_ACTIVITIES_ELEMENT';
export const DELETE_SPECIALIST_GROUP_ACTIVITIES_ELEMENT = 'DELETE_SPECIALIST_GROUP_ACTIVITIES_ELEMENT';

export const SPECIALIST_GROUP_ACTIVITIES_START = 'SPECIALIST_GROUP_ACTIVITIES_START';
export const SPECIALIST_GROUP_ACTIVITIES_CANCEL = 'SPECIALIST_GROUP_ACTIVITIES_CANCEL';
export const SPECIALIST_GROUP_ACTIVITIES_SUBMIT = 'SPECIALIST_GROUP_ACTIVITIES_SUBMIT';
export const SPECIALIST_GROUP_ACTIVITIES_FAILURE = 'SPECIALIST_GROUP_ACTIVITIES_FAILURE';
//notes
export const SPECIALIST_NOTE_START = 'SPECIALIST_NOTE_START';
export const SPECIALIST_NOTE_CANCEL = 'SPECIALIST_NOTE_CANCEL';
export const SPECIALIST_NOTE_SUBMIT = 'SPECIALIST_NOTE_SUBMIT';
export const ADD_SPECIALIST_NOTE = 'ADD_SPECIALIST_NOTE';
export const EDIT_SPECIALIST_NOTE = 'EDIT_SPECIALIST_NOTE';
export const DELETE_SPECIALIST_NOTE = 'DELETE_SPECIALIST_NOTE';
export const SPECIALIST_NOTE_FAILURE = 'SPECIALIST_NOTE_FAILURE';
//observations
export const SPECIALIST_OBSERVATION_START = 'SPECIALIST_OBSERVATION_START';
export const SPECIALIST_OBSERVATION_CANCEL = 'SPECIALIST_OBSERVATION_CANCEL';
export const SPECIALIST_OBSERVATION_SUBMIT = 'SPECIALIST_OBSERVATION_SUBMIT';
export const SPECIALIST_OBSERVATION_FAILURE = 'SPECIALIST_OBSERVATION_FAILURE';
export const ADD_SPECIALIST_OBSERVATION = 'ADD_SPECIALIST_OBSERVATION';
export const EDIT_SPECIALIST_OBSERVATION = 'EDIT_SPECIALIST_OBSERVATION';
export const DELETE_SPECIALIST_OBSERVATION = 'DELETE_SPECIALIST_OBSERVATION';

//individual journal attendance ui
export const REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_START = 'REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_START';
export const REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_SUBMIT = 'REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_SUBMIT';
export const REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_SUCCESS = 'REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_SUCCESS';
export const REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_FAILURE = 'REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_FAILURE';
export const REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_CANCEL = 'REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_CANCEL';
export const LOAD_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE = 'LOAD_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE';
export const REPORT_CHILD_INDIVIDUAL_JOURNAL_ABSENCE_SUCCESS = 'REPORT_CHILD_INDIVIDUAL_JOURNAL_ABSENCE_SUCCESS';
export const REPORT_CHILD_INDIVIDUAL_JOURNAL_ABSENCE_SUBMIT = 'REPORT_CHILD_INDIVIDUAL_JOURNAL_ABSENCE_SUBMIT';
export const REPORT_INDIVIDUAL_JOURNAL_GROUP_ATTENDANCE_SUBMIT = 'REPORT_INDIVIDUAL_JOURNAL_GROUP_ATTENDANCE_SUBMIT';
export const REPORT_CHILD_INDIVIDUAL_JOURNAL_REVERT_ABSENCE_SUCCESS =
  'REPORT_CHILD_INDIVIDUAL_JOURNAL_REVERT_ABSENCE_SUCCESS';
