import PropTypes from 'prop-types';
import React from 'react';
import PostHeader from '../../messageBoard/post/PostHeader';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import moment from 'moment';
import momentBusiness from 'moment-business-days';
import CloneEventsDialog from './CloneEventsDialog';
import {
  Stack,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Divider,
  Box,
  Typography
} from '@mui/material';

class TimeTableEventDialog extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      events: this.initFilterDay(),
      selected: 'day',
      showCloneEventsDialog: false,
      isCloneEventsProcessing: false,
      startDate: moment().startOf('day'),
      endDate: moment().startOf('day').add(1, 'day')
    };
    this.filterDay = this.filterDay.bind(this);
    this.filterWeek = this.filterWeek.bind(this);
    this.filterMonth = this.filterMonth.bind(this);
  }

  initFilterDay() {
    const filteredEvents = this.props.events.filter((x) => x.eventDate === this.props.date.format('YYYY-MM-DD'));
    this.filterByHours(filteredEvents);
    return filteredEvents;
  }

  filterByHours(array) {
    array.sort((a, b) => {
      const minutesA = a.eventStartTime.hourLocal * 60 + a.eventStartTime.minuteLocal;
      const minutesB = b.eventStartTime.hourLocal * 60 + b.eventStartTime.minuteLocal;
      return minutesA - minutesB;
    });
  }

  filterDay() {
    const filteredEvents = this.props.events.filter((x) => x.eventDate === this.props.date.format('YYYY-MM-DD'));
    this.setState({ events: this.sortDaybyDay(filteredEvents), selected: 'day' });
  }

  filterWeek() {
    const filteredEvents = this.props.events.filter(
      (event) => moment(event.eventDate).week() === this.props.date.week()
    );
    this.setState({ events: this.sortDaybyDay([...filteredEvents]), selected: 'week' });
  }

  filterMonth() {
    this.setState({ events: this.sortDaybyDay([...this.props.events]), selected: 'month' });
  }

  sortDaybyDay(array) {
    let temp = [];
    let tempDay = moment(array[0].eventDate);
    let sortedList = [];
    array.forEach((event, i) => {
      if (tempDay.format('YYYY-MM-DD') !== moment(event.eventDate).format('YYYY-MM-DD')) {
        this.filterByHours(temp);
        sortedList = [...sortedList, ...temp];
        tempDay = moment(event.eventDate);
        temp = [];
      }
      temp.push(event);
      if (array.length - 1 === i) {
        this.filterByHours(temp);
        sortedList = [...sortedList, ...temp];
      }
    });
    return sortedList;
  }

  showEvents() {
    let tempDay = null;
    let show = null;
    return this.state.events.map((e) => {
      if (tempDay !== moment(e.eventDate).day()) {
        tempDay = moment(e.eventDate).day();
        show = (
          <Typography variant="h6" sx={{ ml: 5, py: 1, fontWeight: (theme) => theme.typography.fontWeightBold }}>
            {e.eventDate}
          </Typography>
        );
      } else {
        show = null;
      }
      return (
        <Box key={e.id}>
          {show}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <EventAvailableIcon sx={{ fontSize: 30, mr: 1 }} />
            <PostHeader post={e} noAvatar />
          </Box>
          <Divider />
        </Box>
      );
    });
  }

  handleCloneEvents() {
    const ids = this.state.events.map((event) => event.id);
    const dates = this.filterDates(
      this.state.startDate,
      this.state.endDate,
      this.props.daysOff,
      this.state.events[0].eventDate
    );
    this.props.onCloneEvents({ IDs: ids, eventDates: dates });
    this.setState({ isCloneEventsProcessing: true });
  }

  filterDates(startDate, endDate, daysOff, eventDate) {
    const days = [];
    let day = startDate;
    const dayOffNormalized = daysOff.map((dayOff) => moment(dayOff.date).format('YYYY-MM-DD'));
    let flag = true;
    while (day <= endDate) {
      const dayNormalized = day.format('YYYY-MM-DD');
      if (momentBusiness(day).isBusinessDay() && dayNormalized !== eventDate) {
        flag = dayOffNormalized.includes(dayNormalized);
        if (!flag) {
          days.push(dayNormalized);
        }
      }
      day = day.clone().add(1, 'd');
    }
    return days;
  }

  render() {
    const { header, events, isDialogOpen, className, onRequestClose, onShowEventFullDetails, onCreateNewEvent } =
      this.props;

    return (
      <Dialog open={isDialogOpen} onClose={onRequestClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightLarge, pt: 1 }}>
              {header}
            </Typography>
            <Box>
              <Button onClick={this.filterDay} variant="clearText" aria-label="Dzień">
                Dzień
              </Button>
              <Button onClick={this.filterWeek} variant="clearText" aria-label="Tydzień">
                Tydzień
              </Button>
              <Button onClick={this.filterMonth} variant="clearText" aria-label="Miesiąc">
                Miesiąc
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className={className}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }} tabIndex={0}>
            {this.showEvents()}
          </Box>

          {this.state.showCloneEventsDialog ? (
            <CloneEventsDialog
              isOpen={this.state.showCloneEventsDialog}
              isProcessing={this.state.isCloneEventsProcessing}
              onCancel={() => this.setState({ showCloneEventsDialog: false })}
              onSave={() => this.handleCloneEvents()}
              startDate={this.state.startDate}
              onChangeStartDate={(value) => this.setState({ startDate: moment(value) })}
              endDate={this.state.endDate}
              onChangeEndDate={(value) => this.setState({ endDate: moment(value) })}
            />
          ) : null}
        </DialogContent>
        <DialogActions sx={{ py: 2, px: 1 }}>
          <Stack direction="row" justifyContent="center" sx={{ flexWrap: 'wrap' }}>
            <Button
              variant="outlinedContrast"
              aria-label="Klonuj wydarzenia"
              onClick={() => this.setState({ showCloneEventsDialog: true })}
              disabled={this.state.selected !== 'day' || this.props.isGroupArchived}>
              klonuj wydarzenia
            </Button>
            <Button
              variant="outlinedContrast"
              aria-label="Pokaż szczegóły"
              onClick={onShowEventFullDetails}
              disabled={!events.length || this.state.selected !== 'day'}>
              pokaż szczegóły
            </Button>
            <Button
              aria-label="Nowe wydarzenie"
              variant="contained"
              onClick={onCreateNewEvent}
              disabled={this.state.selected !== 'day' || this.props.isGroupArchived}>
              Nowe Wydarzenie
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    );
  }
}

TimeTableEventDialog.propTypes = {
  date: PropTypes.object.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isDialogOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  className: PropTypes.string,
  onShowEventFullDetails: PropTypes.func.isRequired,
  onCreateNewEvent: PropTypes.func.isRequired,
  onCloneEvents: PropTypes.func.isRequired,
  daysOff: PropTypes.array.isRequired,
  classes: PropTypes.any,
  isGroupArchived: PropTypes.bool
};

export default TimeTableEventDialog;
