import { check } from '../../validation/validateHelper';
import { ReliefModel } from './ReliefModels';
import { BaseModelValidator } from '../../BaseModelValidator';
import { isNumericBetween, isNumeric } from '../../../utils/numberValidators';
import { ReliefType } from '../../../constants/reliefType';

export class ReliefModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = ReliefModel;

    this.constraints = {
      name: (value) => check.isEmpty(value) && 'Nie podano nazwy',
      rate: (value, model) => {
        if (check.isEmpty(value)) return 'Nie podano wartości';
        if (model.type === ReliefType.percentage.value && !isNumericBetween(value, 0.1, 100))
          return 'Wartość musi być liczbą dodatnią z zakresu pomiędzy 0.1 - 100';
        if (model.type === ReliefType.fixedAmount.value && !isNumeric(value)) return 'Wartość musi być liczbą dodatnią';
        return null;
      }
    };
  }
}
