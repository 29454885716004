import eventsApi from '../api/EventsApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function loadEventsAsync(params) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_EVENTS });
    return eventsApi
      .getEvents(params)
      .then((events) => dispatch({ type: types.LOAD_EVENTS_SUCCESS, events }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać listy zdarzeń'));
        logger.error(error);
      });
  };
}
