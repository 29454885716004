/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { PrivateMessageModel } from '../models/privateMessages/PrivateMessagesModels';
import moment from 'moment';

export default function privateMessageReducer(state = initialState.privateMessages, action) {
  switch (action.type) {
    case types.LOAD_PRIVATE_THREADS:
    case types.LOAD_MY_PRIVATE_THREADS: {
      return Object.assign({}, state, { activeView: action.view, isInitial: true, threadId: null, messages: [] });
    }
    case types.LOAD_PRIVATE_MESSAGES: {
      return Object.assign({}, state, { isLoading: true, isInitial: true, threadId: null, messages: [] });
    }
    case types.LOAD_NEXT_PRIVATE_MESSAGES: {
      return Object.assign({}, state, { isLoading: true });
    }
    case types.PRIVATE_MESSAGES_CLEARED: {
      return Object.assign({}, state, { messages: [] });
    }
    case types.LOAD_PRIVATE_MESSAGES_SUCCESS: {
      if (state.activeView !== action.view) return state;
      const { thread, currentUserId } = action;
      const otherParticipant = thread.participants.find((x) => x.id !== currentUserId);
      const lastReadByParticipant = otherParticipant ? thread.lastRead[otherParticipant.id] : null;
      const lastReadByMe = thread.lastRead[currentUserId];
      const messages = action.messages.data.map((message) => {
        const msg = new PrivateMessageModel().assign(message);
        msg.readByParticipants = lastReadByParticipant && message.sentAt <= lastReadByParticipant;
        msg.readByMe = lastReadByMe && message.sentAt <= lastReadByMe;
        return msg;
      });
      return Object.assign({}, state, {
        threadId: action.threadId,
        messages,
        canLoadMore: action.messages.data.length < action.messages.totalCount,
        isLoading: false,
        isInitial: false
      });
    }
    case types.LOAD_NEXT_PRIVATE_MESSAGES_SUCCESS: {
      if (state.activeView !== action.view) return state;
      const { thread, currentUserId } = action;
      const otherParticipant = thread.participants.find((x) => x.id !== currentUserId);
      const lastRead = otherParticipant ? thread.lastRead[otherParticipant.id] : null;
      const lastReadByMe = thread.lastRead[currentUserId];
      const messages = [
        ...state.messages,
        ...action.messages.data.map((message) => {
          const msg = new PrivateMessageModel().assign(message);
          msg.readByParticipants = lastRead && message.sentAt <= lastRead;
          msg.readByMe = lastReadByMe && message.sentAt <= lastReadByMe;
          return msg;
        })
      ];
      return Object.assign({}, state, {
        messages,
        canLoadMore: messages.length < action.messages.totalCount,
        isLoading: false
      });
    }
    case types.SEND_PRIVATE_MESSAGE_SUCCESS: {
      const nextMessage = Object.assign({}, action.message, { readByMe: true });
      const messages = [new PrivateMessageModel().assign(nextMessage), ...state.messages];
      return Object.assign({}, state, { messages, threadId: action.thread.threadId });
    }
    case types.UPDATE_PRIVATE_MESSAGE_SUCCESS: {
      const nextMessage = Object.assign({}, action.message, {
        updatedAt: moment().toJSON()
      });
      const messages = state.messages.map((x) =>
        x.id === nextMessage.id ? new PrivateMessageModel().assign(nextMessage) : x
      );
      return Object.assign({}, state, { messages });
    }
    case types.DELETE_PRIVATE_MESSAGE_SUCCESS: {
      const nextMessage = Object.assign({}, action.message, {
        deletedAt: moment().toJSON()
      });
      const messages = state.messages.map((x) =>
        x.id === nextMessage.id ? new PrivateMessageModel().assign(nextMessage) : x
      );
      return Object.assign({}, state, { messages });
    }
    case types.PRIVATE_MESSAGE_SENT_NOTIFICATION: {
      if (state.threadId !== action.message.threadId) {
        return state;
      }
      const newMessage = new PrivateMessageModel().assign(action.message);
      newMessage.readByMe = false;
      newMessage.readByParticipants = true;
      const messages = [newMessage, ...state.messages];
      return Object.assign({}, state, { messages });
    }
    case types.PRIVATE_MESSAGE_UPDATED_NOTIFICATION: {
      if (state.threadId !== action.message.threadId) {
        return state;
      }

      const messages = state.messages.map((x) =>
        x.id === action.message.id ? new PrivateMessageModel().assign(action.message) : x
      );
      return Object.assign({}, state, { messages });
    }
    case types.PRIVATE_MESSAGE_DELETED_NOTIFICATION: {
      if (state.threadId !== action.message.threadId) {
        return state;
      }

      const messages = state.messages.map((x) =>
        x.id === action.message.id ? new PrivateMessageModel().assign(Object.assign({}, x, action.message)) : x
      );
      return Object.assign({}, state, { messages });
    }
    case types.MESSAGE_READ_NOTIFICATION: {
      const { threadId, messageTimestamp } = action.message;
      if (state.threadId !== threadId) {
        return state;
      }
      const messages = state.messages.map((msg) => {
        return msg.sentAt <= messageTimestamp
          ? new PrivateMessageModel().assign(Object.assign({}, msg, { readByParticipants: true }))
          : msg;
      });
      return Object.assign({}, state, { messages });
    }
    case types.MARK_MESSAGE_READ_SUCCESS: {
      const { messageTimestamp } = action;
      const messages = state.messages.map((message) => {
        if (message.sentAt <= messageTimestamp) {
          return new PrivateMessageModel().assign(message).assign({ readByMe: true });
        }
        return message;
      });
      return Object.assign({}, state, { messages });
    }
    case types.LOAD_POSSIBLE_PM_RECIPIENTS_SUCCESS: {
      const { recipients } = action;
      return Object.assign({}, state, { possibleRecipients: recipients });
    }
    default:
      return state;
  }
}
