import { BaseModelValidator } from '../../../../models/BaseModelValidator';
import { check } from '../../../../models/validation/validateHelper';
import moment from 'moment';  

export class NoteValidator extends BaseModelValidator {
  constructor() {
    super();

    this.constraints = {
      title: (value) => {
        return check.isEmpty(value) && 'Nie podano tytułu';
      },
      content: (value) => check.isEmpty(value) && 'Nie podano treści notatki',
      date: (value) => {
        if (!!value && (!moment(value).isValid() || moment(value, "DD-MM-YYYY").isBefore('1900', 'year'))) return 'Podana data ma niepoprawny format';
        return null;
      },
      time: (value) => {
        if (!!value && !(moment(value).isValid())) return 'Podany czas ma niepoprawny format';
        return null;
      }
    };
  }
}
