import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../forms/DialogForm';
import { BoardPostModelValidator } from '../../../models/messageBoard/MessageBoardModelsValidator';
import DatePicker from '../../common/DatePicker';
import TimePicker from '../../common/TimePicker';
import ClearButtonWrapper from '../../common/ClearButtonWrapper';
import { TimeModel } from '../../../models/TimeModel';
import localStorageProvider from '../../../utils/localStorageProvider';
import moment from 'moment';
import {
  Box,
  FormControl,
  Chip,
  FormHelperText,
  MenuItem,
  Autocomplete,
  TextField,
  Button,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import withMediaQuery from '../../../hoc/withMediaQuery';
import SelectField from '../../common/SelectField';
import staffMemberRoles from '../../../constants/staffMemberRoles';
import { ProgramPoints as allProgramPoints } from '../../../utils/ProgramPointsList';
import { uniqueId } from 'lodash';
import RichTextEditor from '../../forms/richTextEditor/RichTextEditor';

class PostEditor extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.options = allProgramPoints
      .map((pp) => pp.values)
      .flat()
      .map((programPoint) => {
        const group = programPoint.split('.')[0];
        const programPointValue = programPoint.split(' - ')[0];
        return {
          group,
          programPointValue,
          programPoint
        };
      });
    this.state = {
      post: Object.assign(
        {},
        {
          ...this.props.post,
          programPoints: this.options.filter(
            (pp) =>
              this.props.post.programPoints &&
              pp &&
              this.props.post.programPoints?.split(', ').includes(pp.programPointValue)
          )
        }
      ),
      errors: {},
      refresh: uniqueId(),
      isProgramPointsListVisible: false,
      disabled: false
    };
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleReplacementChange = this.handleReplacementChange.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
    this.handlePinnedPostStateChange = this.handlePinnedPostStateChange.bind(this);
    this.handleProgramPointsChange = this.handleProgramPointsChange.bind(this);
    this.handleSelectCoursebook = this.handleSelectCoursebook.bind(this);
    this.changeVisibilityOfProgramPoints = this.changeVisibilityOfProgramPoints.bind(this);
    this.handleLearningMaterialChange = this.handleLearningMaterialChange.bind(this);
    this.token = localStorageProvider.getItem('authToken');
  }

  handleTitleChange(event) {
    this.setState({ post: Object.assign(this.state.post, { title: event.target.value }) });
  }

  handleReplacementChange() {
    this.setState((prevState) => ({
      post: Object.assign(prevState.post, {
        replacement: !prevState.post.replacement
      })
    }));
  }

  handleContentChange(htmlContent) {
    this.setState({ post: Object.assign(this.state.post, { content: htmlContent }) });
  }

  handleEventDateChange(date) {
    this.setState({
      post: Object.assign(this.state.post, {
        eventDate: date ? moment(date).format('YYYY-MM-DD') : null
      })
    });
    if (!date) {
      this.handleEventStartTimeChange(undefined);
      this.handleEventEndTimeChange(undefined);
    }
  }

  handleEventStartTimeChange(time) {
    this.setState({
      post: Object.assign(this.state.post, { eventStartTime: time ? new TimeModel().parse(time) : new TimeModel() })
    });
    if (!time) {
      this.handleEventEndTimeChange(undefined);
    }
  }

  handleEventEndTimeChange(time) {
    this.setState({
      post: Object.assign(this.state.post, { eventEndTime: time ? new TimeModel().parse(time) : new TimeModel() })
    });
  }

  handlePinnedPostStateChange(event, value) {
    this.setState({ post: Object.assign(this.state.post, { pinned: value }) });
  }

  handleSelectCoursebook(title, learningMaterials, programPoints, note) {
    this.setState((prevState) => {
      return {
        ...prevState,
        refresh: uniqueId(),
        post: Object.assign(prevState.post, {
          title,
          learningMaterials,
          programPoints,
          content: note
        })
      };
    });
  }

  handleProgramPointsChange(event, value) {
    this.setState((prevState) => ({
      ...prevState,
      post: Object.assign(prevState.post, {
        programPoints: value
      })
    }));
  }

  handleLearningMaterialChange(event) {
    this.setState((prevState) => ({
      ...prevState,
      post: Object.assign(prevState.post, {
        learningMaterials: event.target.value
      })
    }));
  }

  changeVisibilityOfProgramPoints() {
    this.setState((prevState) => ({ ...prevState, isProgramPointsListVisible: !prevState.isProgramPointsListVisible }));
  }

  render() {
    const { isMobile, eventType, staffMembers } = this.props;
    const isSelect = eventType === 'additionalActivities';
    return (
      <DialogForm
        onSave={() => {
          this.props.onSave({
            ...this.state.post,
            programPoints: this.state.post.programPoints?.map((pp) => pp.programPointValue).join(', ')
          });
          this.setState({
            disabled: true
          });
        }}
        coursebook={this.props.coursebook}
        coursebookSetMethods={this.handleSelectCoursebook}
        defaultPublishingHouseId={this.props.defaultPublishingHouseId}
        onCancel={() => this.props.onCancel(this.state.post)}
        onValidate={() => new BoardPostModelValidator().validate(this.state.post)}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="messageBoardUi"
        isDialogOpen={this.props.isEditing}
        isProcessing={this.props.isProcessing}
        header={this.props.title}
        saveDisabled={this.state.disabled || this.props.isGroupArchived}
        requiredFieldText>
        {isSelect ? (
          <SelectField
            contrast
            onChange={this.handleTitleChange}
            value={this.state.post.title}
            fullWidth={true}
            sx={{ mb: 3 }}
            floatingLabelText="Tytuł*"
            errorText={this.state.errors.title}>
            {eventType === 'additionalActivities'
              ? this.props.activities.map((activity) => (
                  <MenuItem key={activity.name} value={activity.name}>
                    {activity.name}
                  </MenuItem>
                ))
              : staffMembers
                  .filter((member) => member.role === staffMemberRoles.teacher.value)
                  .map((member) => (
                    <MenuItem key={member.id} value={`${member.firstName} ${member.lastName}`}>
                      {`${member.firstName} ${member.lastName}`}
                    </MenuItem>
                  ))}
          </SelectField>
        ) : (
          <TextField
            variant="standard"
            label="Tytuł*"
            autoFocus={true}
            name="title"
            value={this.state.post.title}
            onChange={this.handleTitleChange}
            fullWidth
            error={!!this.state.errors.title}
            helperText={this.state.errors.title}></TextField>
        )}
        {this.props.showReplacement ? (
          <FormControlLabel
            sx={{ display: 'flex', alignItems: 'center' }}
            control={<Checkbox checked={this.state.post.replacement} onChange={this.handleReplacementChange} />}
            label="Zastępstwo"
          />
        ) : null}
        <FormControl
          error={!!this.state.errors && !!this.state.errors.content}
          sx={{
            width: '100%',
            mb: 3,
            borderBottom:
              !!this.state.errors && !!this.state.errors.content
                ? (theme) => `1px solid ${theme.palette.color.error}`
                : null
          }}>
          <RichTextEditor
            key={this.state.refresh}
            value={this.state.post.note}
            onChange={this.handleContentChange}
            initialText={this.state.post.content}
          />
          <FormHelperText>{this.state.errors.content}</FormHelperText>
        </FormControl>
        {this.props.coursebook && (
          <>
            <TextField
              id="learningMaterials"
              label="Materiały dydaktyczne"
              aria-label="Materiały dydaktyczne"
              variant="standard"
              sx={{ width: '100%', mb: 2 }}
              value={this.state.post.learningMaterials}
              onChange={this.handleLearningMaterialChange}
            />

            <Autocomplete
              id="program-points-autocomplete"
              sx={{
                color: (theme) => theme.palette.color.contrast,
                width: '100%',
                mb: 2,
                '& .MuiInput-underline:before': {
                  borderBottom: (theme) => `1px solid ${theme.palette.color.contrast} !important`
                },
                '& .MuiInput-underline:after': {
                  borderBottom: (theme) => `1px solid ${theme.palette.color.contrast} !important`
                },
                '& .MuiInput-underline:hover': {
                  borderBottom: (theme) => `1px solid ${theme.palette.color.contrast} !important`
                },
                svg: { color: (theme) => theme.palette.color.contrast },
                label: {
                  color: (theme) => theme.palette.color.contrast,
                  '&.Mui-focused': {
                    color: (theme) => theme.palette.color.contrast
                  }
                }
              }}
              multiple
              disableCloseOnSelect
              value={this.state.post.programPoints}
              options={this.options.filter(
                (option) =>
                  !this.state.post.programPoints
                    .map((v) => v.programPointValue)
                    .some((v) => v === option.programPointValue)
              )}
              getOptionLabel={(option) => option.programPoint}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option.programPointValue} {...getTagProps({ index })} />
                ))
              }
              groupBy={(option) =>
                `${option.group} - ${allProgramPoints.filter((pp) => pp.key === option.group)[0]?.title}`
              }
              renderInput={(params) => <TextField {...params} label="Podstawa programowa" variant="standard" />}
              onChange={this.handleProgramPointsChange}
            />
            <Button
              variant="clearText"
              sx={{ py: 1, px: 0, width: 400 }}
              onClick={() => this.changeVisibilityOfProgramPoints()}>{`${
              this.state.isProgramPointsListVisible ? 'Ukryj' : 'Pokaż'
            } opis wybranych punktów podstawy programowej`}</Button>
            {this.state.isProgramPointsListVisible &&
              this.state.post.programPoints &&
              this.state.post.programPoints.map((pp) => <p key={uniqueId()}>{pp.programPoint}</p>)}
          </>
        )}
        {this.props.canSetDate && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'start', justifyContent: 'space-between' }}>
            <ClearButtonWrapper
              contrast
              onClear={() => this.handleEventDateChange(undefined)}
              disabled={!this.props.canClearDate}
              sx={{ mr: 1 }}>
              <DatePicker
                floatingLabelText={<Box sx={{ color: (theme) => theme.palette.color.contrast }}>Data wydarzenia</Box>}
                textFieldStyle={{ width: 128 }}
                onChange={(e, date) => this.handleEventDateChange(date)}
                value={this.state.post.eventDate ? new Date(this.state.post.eventDate) : undefined}
                name="eventDate"
                errorText={this.state.errors.eventDate}
                sx={{ m: 0 }}
                id="data-wydarzenia-picker"
              />
            </ClearButtonWrapper>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems="flex-start"
              justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
              <ClearButtonWrapper contrast onClear={() => this.handleEventStartTimeChange(undefined)}>
                <TimePicker
                  floatingLabelText="Rozpoczęcie"
                  disabled={!this.state.post.eventDate}
                  textFieldStyle={{ width: 128 }}
                  onChange={(e, time) => this.handleEventStartTimeChange(time)}
                  value={
                    !this.state.post.eventStartTime.isEmpty() ? this.state.post.eventStartTime.toDate() : undefined
                  }
                  name="eventStartTime"
                  errorText={this.state.errors.eventStartTime}
                />
              </ClearButtonWrapper>
              <ClearButtonWrapper contrast onClear={() => this.handleEventEndTimeChange(undefined)}>
                <TimePicker
                  floatingLabelText="Zakończenie"
                  disabled={this.state.post.eventStartTime.isEmpty()}
                  textFieldStyle={{ width: 128 }}
                  onChange={(e, time) => this.handleEventEndTimeChange(time)}
                  value={!this.state.post.eventEndTime.isEmpty() ? this.state.post.eventEndTime.toDate() : undefined}
                  name="eventEndTime"
                  errorText={this.state.errors.eventEndTime}
                />
              </ClearButtonWrapper>
            </Box>
          </Box>
        )}
      </DialogForm>
    );
  }
}

PostEditor.propTypes = {
  post: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  canClearDate: PropTypes.bool,
  canSetDate: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  eventType: PropTypes.string,
  staffMembers: PropTypes.array,
  isGroupArchived: PropTypes.bool,
  coursebook: PropTypes.bool,
  defaultPublishingHouseId: PropTypes.string,
  title: PropTypes.string,
  showReplacement: PropTypes.bool
};

PostEditor.defaultProps = {
  canClearDate: true,
  canSetDate: true,
  coursebook: false,
  defaultPublishingHouseId: '',
  title: 'Utwórz nowe wydarzenie',
  showReplacement: false
};

export default withMediaQuery()(PostEditor);
