import PropTypes from 'prop-types';
import React from 'react';
import SchemeDescription from './schemeDescriptions/SchemeDescription';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import EmptyState from '../../common/EmptyState';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemButton,
  Paper
} from '@mui/material';
import DotsMenu from '../../common/menu/DotsMenu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMemo } from 'react';

const ChargingSchemesList = ({ schemes, onSelected, onRemove, onShowAssignedChildren, filterText }) => {
  const renderListItem = (scheme, canShowAssignedChildren) => {
    const generateMenuItems = (onClose) => {
      const dotsMenuItems = [
        <MenuItem
          key="1"
          onClick={() => {
            onClose();
            onShowAssignedChildren(scheme);
          }}>
          Pokaż przypisane dzieci
        </MenuItem>,
        <ActionConfirmationMenuItem
          key="2"
          onItemClick={onClose}
          actionLabel="Usuń"
          confirmationText="Czy na pewno chcesz usunąć schemat płatności? Schemat zostanie odłączony od wszystkich dzieci do których jest aktualnie przypisany. Odpięcie schematu jest możliwe tylko jeśli nie istnieją zatwierdzone bądź robocze rozliczenia."
          confirmationTitle="Usuń schemat płatności"
          confirmLabel="Usuń"
          onConfirm={() => onRemove(scheme.id)}
          onCancel={() => {}}
        />
      ];
      if (canShowAssignedChildren) return dotsMenuItems;
      else return dotsMenuItems.slice(1);
    };
    return (
      <ListItem
        key={scheme.id}
        component="li"
        sx={{ p: 0, m: 0 }}
        aria-label={`Edytuj schemat płatności ${scheme.name}`}>
        <ListItemButton onClick={() => onSelected(scheme)}>
          <ListItemText
            primary={scheme.name}
            primaryTypographyProps={{
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}
            secondary={
              <Typography component="span">
                <SchemeDescription scheme={scheme} />
              </Typography>
            }
          />
        </ListItemButton>
        <ListItemSecondaryAction>
          <DotsMenu>{(onClose) => generateMenuItems(onClose)}</DotsMenu>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const filteredSchemes = useMemo(() => {
    return schemes.filter((scheme) => scheme.name.toLowerCase().includes(filterText?.toLowerCase() ?? ''));
  }, [schemes, filterText]);

  if (filterText && !filteredSchemes.length) {
    return (
      <Paper sx={{ boxShadow: 'none', py: 5 }}>
        <EmptyState contrast message="Nie znaleziono żadnego schematu płatności" />
      </Paper>
    );
  }

  if (filterText) {
    return (
      <List component="nav" aria-label="Schematy płatności">
        {filteredSchemes
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((scheme) => renderListItem(scheme, scheme.reportType !== 'staffCatering'))}
      </List>
    );
  }

  return (
    <>
      {Object.values(ChargingReportTypes).map((item) => (
        <Accordion
          key={item.value}
          sx={{
            my: 2
          }}>
          <AccordionSummary
            sx={{
              px: 4
            }}
            expandIcon={<ExpandMoreIcon />}>
            <Typography
              sx={{
                fontWeight: (theme) => theme.typography.fontWeightBold,
                fontSize: 18
              }}>
              {item.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ py: 0 }}>
            <List sx={{ pt: 0 }} component="nav" aria-label={`Schematy płatności w kategorii ${item.name}`}>
              {schemes
                .filter((scheme) => scheme.reportType === item.value)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((scheme) => renderListItem(scheme, item.value !== 'staffCatering'))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

ChargingSchemesList.propTypes = {
  schemes: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onShowAssignedChildren: PropTypes.func.isRequired,
  filterText: PropTypes.string
};

export default ChargingSchemesList;
