import PropTypes from 'prop-types';
import React from 'react';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  MenuItem,
  Box,
  Button,
  Typography
} from '@mui/material';
import { PaymentMethods } from '../../../constants/paymentMethods';
import { tryConvertToNumber } from '../../../utils/numberConverters';
import moment from 'moment';
import TextField from '../../common/TextField';
import DatePicker from '../../common/DatePicker';
import SelectField from '../../common/SelectField';

const defaultState = {
  amountToCash: '',
  paymentMethod: PaymentMethods.cash.value,
  amountToDepositIsValid: false
};

class DepositSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      paymentDate: moment().format('YYYY-MM-DD'),
      walletToTransfer: this.props.walletConfig[0].name
    };
    this.handleAmountToDepositUpdate = this.handleAmountToDepositUpdate.bind(this);
    this.handleDeposit = this.handleDeposit.bind(this);
    this.handleChangeWalletToTransfer = this.handleChangeWalletToTransfer.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleAmountToDepositUpdate(e) {
    const amountToCash = e.target.value;
    this.setState({
      amountToCash,
      amountToDepositErrorText:
        (!(Number.isFinite(parseFloat(amountToCash)) && amountToCash > 0) && 'Wprowadz poprawną wartość') ||
        (this.props.availableMoney < parseFloat(amountToCash) &&
        this.state.paymentMethod === PaymentMethods.walletTransfer.value
          ? 'Przenoszona kwota przekracza wartość salda portfela bazowego.'
          : ''),
      amountToDepositIsValid: Number.isFinite(parseFloat(amountToCash)) && amountToCash > 0
    });
  }

  handleChangeWalletToTransfer(event) {
    this.setState({ walletToTransfer: event.target.value });
  }

  handleDateChange(value) {
    this.setState({
      paymentDate: moment(value).format('YYYY-MM-DD'),
      paymentDateErrorText: !moment(value).isValid() && 'Wprowadz poprawną datę.'
    });
  }

  handleDeposit() {
    if (this.state.paymentMethod === PaymentMethods.walletTransfer.value) {
      this.props.onWalletTransfer(this.props.walletType, this.state.walletToTransfer, this.state.amountToCash);
    } else {
      const payload = {
        paymentMethod: this.state.paymentMethod,
        paymentDate: this.state.paymentDate,
        paymentAmount: this.state.amountToCash,
        payments: [],
        balance: {
          method: this.state.paymentMethod,
          date: this.state.paymentDate,
          amount: this.state.amountToCash,
          walletType: this.props.walletType
        }
      };
      this.props.onDeposit(payload);
      this.setState(defaultState);
    }
  }

  render() {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>Wpłata środków</Typography>
        <Box>
          <Box>
            <Box sx={{ p: 0 }}>
              <TextField
                sx={{ width: '200px', maxWidth: '100%' }}
                hintText="Kwota wpłaty"
                floatingLabelText="Kwota wpłaty"
                floatingLabelFixed={false}
                errorText={this.state.amountToDepositErrorText}
                name="amountToCash"
                value={this.state.amountToCash}
                disabled={this.props.inProgress}
                onChange={this.handleAmountToDepositUpdate}
                onBlur={(e) => {
                  this.handleAmountToDepositUpdate({
                    target: { name: 'amountToCash', value: tryConvertToNumber(e.target.value) }
                  });
                }}
              />
            </Box>
            <Box sx={{ p: 0 }}>
              <FormControl
                component="fieldset"
                sx={{
                  mt: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  '& div': {
                    width: 'auto',
                    mr: 3,
                    '& div div': {
                      mr: 1
                    },
                    '& label': {
                      width: 'auto'
                    }
                  }
                }}>
                <FormLabel
                  component="legend"
                  sx={{
                    '&.Mui-focused': {
                      color: (theme) => theme.palette.color.contrast
                    },
                    color: (theme) => theme.palette.color.contrast
                  }}>
                  Sposób płatności
                </FormLabel>
                <RadioGroup
                  sx={{ display: 'flex', flexDirection: 'row' }}
                  aria-label="sposób płatności"
                  name="paymentMethod"
                  value={this.state.paymentMethod}
                  onChange={(e) =>
                    this.setState({
                      paymentMethod: e.target.value,
                      amountToDepositErrorText:
                        this.props.availableMoney < parseFloat(this.state.amountToCash) &&
                        e.target.value === PaymentMethods.walletTransfer.value &&
                        'Przenoszona kwota przekracza wartość salda portfela bazowego.'
                    })
                  }>
                  <FormControlLabel
                    key={PaymentMethods.cash.value}
                    label="Wpłata w kasie"
                    value={PaymentMethods.cash.value}
                    disabled={this.props.inProgress}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    key={PaymentMethods.transfer.value}
                    label={PaymentMethods.transfer.name}
                    value={PaymentMethods.transfer.value}
                    disabled={this.props.inProgress}
                    control={<Radio />}
                  />
                  {this.props.isStaffMember ? null : (
                    <FormControlLabel
                      key={PaymentMethods.walletTransfer.value}
                      label={PaymentMethods.walletTransfer.name}
                      value={PaymentMethods.walletTransfer.value}
                      disabled={this.props.inProgress}
                      control={<Radio />}
                    />
                  )}
                </RadioGroup>
                {this.props.isStaffMember ? null : (
                  <SelectField
                    contrast
                    disabled={this.state.paymentMethod !== PaymentMethods.walletTransfer.value}
                    value={this.state.walletToTransfer}
                    onChange={(event) => this.handleChangeWalletToTransfer(event)}>
                    {this.props.walletConfig.map((wallet) => (
                      <MenuItem key={wallet.id} value={wallet.name}>
                        {wallet.label}
                      </MenuItem>
                    ))}
                  </SelectField>
                )}
              </FormControl>
              <Box>
                <DatePicker
                  hintText="Data wpłaty"
                  floatingLabelText="Data wpłaty"
                  floatingLabelFixed={false}
                  name="paymentDate"
                  errorText={this.state.paymentDateErrorText}
                  value={this.state.paymentDate}
                  onChange={(e, value) => this.handleDateChange(value)}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                aria-label="Wpłać"
                onClick={this.handleDeposit}
                disabled={
                  !this.state.amountToDepositIsValid || this.props.inProgress || !!this.state.amountToDepositErrorText
                }>
                Wpłać
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

DepositSection.propTypes = {
  personalBalance: PropTypes.object.isRequired,
  inProgress: PropTypes.bool.isRequired,
  onDeposit: PropTypes.func.isRequired,
  walletType: PropTypes.string.isRequired,
  walletConfig: PropTypes.array.isRequired,
  onWalletTransfer: PropTypes.func,
  isStaffMember: PropTypes.bool.isRequired
};

export default DepositSection;
