import PropTypes from 'prop-types';
import React from 'react';
import CalendarDayContent from '../../common/calendar/Grid/CalendarDayContent';
import PrimaryIcon from '@mui/icons-material/BeachAccess';

const DayOffDayContent = ({ day, containerWidth, dayOffName }) => {
  return (
    <CalendarDayContent
      day={day}
      containerWidth={containerWidth}
      primaryText={dayOffName}
      primaryIcon={<PrimaryIcon />}
    />
  );
};

DayOffDayContent.propTypes = {
  day: PropTypes.object.isRequired,
  containerWidth: PropTypes.number.isRequired,
  dayOffName: PropTypes.string.isRequired
};

export default DayOffDayContent;
