import api from '../api/DaycareApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';

export function loadGroupDaycareSheet(groupId, date) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_DAYCARE_GROUP_ENTRIES });
    return api
      .getDaycareEntries(groupId, date)
      .then((daycareSheet) => dispatch({ type: types.LOAD_DAYCARE_GROUP_ENTRIES_SUCCESS, daycareSheet }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać aktywności dla grupy '${get.groupName(getState, groupId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function loadChildDaycare(childId, date) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_CHILD_DAYCARE });
    return api
      .getChildDaycareEntries(childId, date)
      .then((daycareEntries) => {
        dispatch({ type: types.LOAD_CHILD_DAYCARE_SUCCESS, daycareEntries });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać aktywności dla dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function updateDaycareEntry(entry) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_CHILD_DAYCARE });
    const action = entry.id ? api.updateDaycareEntry : api.createDaycareEntry;

    return action(entry)
      .then((id) =>
        dispatch({ type: types.UPDATE_CHILD_DAYCARE_SUCCESS, entry: entry.id ? entry : Object.assign(entry, { id }) })
      )
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się zaktualizować aktywności dziecka.'));
        return logger.error(error);
      });
  };
}
