import PropTypes from 'prop-types';
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

class ActionConfirmationButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isOpen: false
    };
    this.handleAction = this.handleAction.bind(this);
    this.renderActionButtons = this.renderActionButtons.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleAction() {
    this.setState({ isOpen: true });
  }

  handleConfirm() {
    this.setState({ isOpen: false });
    this.props.onConfirm();
  }

  handleCancel() {
    this.setState({ isOpen: false });
    this.props.onCancel();
  }

  renderActionButtons() {
    return [
      <Button variant="outlinedContrast" key="1" onClick={this.handleCancel}>
        {this.props.cancelLabel}
      </Button>,
      <Button variant="contained" key="2" onClick={this.handleConfirm}>
        {this.props.confirmLabel}
      </Button>
    ];
  }

  renderMainButton() {
    return (
      <LoadingButton
        onClick={this.handleAction}
        variant={this.props.variant || 'textContrast'}
        color={this.props.isPrimary ? 'primary' : 'error'}
        disabled={this.props.isDisabled}
        loading={this.props.isLoading}
        startIcon={this.props.actionIcon}
        sx={{ textTransform: 'none', ...this.props.sx }}>
        {this.props.actionLabel}
      </LoadingButton>
    );
  }

  render() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          ...this.props.wrapperSx
        }}>
        {this.renderMainButton()}
        <Dialog open={this.state.isOpen}>
          <DialogTitle>{this.props.confirmationTitle}</DialogTitle>
          <DialogContent sx={{ wordBreak: 'break-word' }}>{this.props.confirmationText}</DialogContent>
          <DialogActions>{this.renderActionButtons()}</DialogActions>
        </Dialog>
      </Box>
    );
  }
}

ActionConfirmationButton.propTypes = {
  actionLabel: PropTypes.string,
  actionIcon: PropTypes.node,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmationText: PropTypes.string.isRequired,
  confirmationTitle: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  useRaisedButton: PropTypes.bool,
  className: PropTypes.string,
  wrapperSx: PropTypes.object,
  variant: PropTypes.string,
  sx: PropTypes.object
};

ActionConfirmationButton.defaultProps = {
  confirmLabel: 'Ok',
  cancelLabel: 'Anuluj',
  isConfirmPrimary: false,
  isConfirmSecondary: false,
  isConfirmDisabled: false,
  actionIcon: null,
  variant: 'contained'
};

export default ActionConfirmationButton;
