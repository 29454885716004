import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const MoreActivitiesIcon = (props) => (
  <SvgIcon viewBox="4.209 0 24 24" {...props}>
    <path fill="none" d="M8.439 4.231h15.539V19.77H8.439V4.231z" />
    <path fill="none" d="M8.439 4.231h15.539V19.77H8.439V4.231z" />
    <path d="M9.726 15.804l2.173 1.312-.577-2.472 1.92-1.664-2.528-.214-.988-2.331-.988 2.331-2.529.214 1.921 1.664-.578 2.472 2.174-1.312zM22.691 15.804l2.175 1.312-.578-2.472 1.921-1.664-2.528-.214-.989-2.331-.987 2.331-2.529.214 1.921 1.664-.578 2.472 2.172-1.312z" />
    <path d="M16.12 14.765l3.189 1.925-.847-3.628 2.817-2.44-3.711-.315-1.448-3.422-1.45 3.421-3.71.315 2.817 2.44-.847 3.628 3.19-1.924z" />
    <path fill="none" d="M4.209 0h24v24h-24V0z" />
  </SvgIcon>
);

export default MoreActivitiesIcon;
