/*eslint indent:0*/
import initialState from './initial';
import { LOAD_REPORTS_SUCCESS } from '../actions/actionTypes';
import { ReportModel } from '../models/configuration/reports/ReportModel';

export default function reportsReducer(state = initialState.reports, action) {
  switch (action.type) {
    case LOAD_REPORTS_SUCCESS: {
      return action.reports.map((report) => new ReportModel().assign(report));
    }
    default:
      return state;
  }
}
