/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { StaffMemberDetailsModel, StaffChargingScheme } from '../models/staffMembers/StaffMemberModels';

export default function staffMemberDetailsReducer(state = initialState.staffMemberDetails, action) {
  switch (action.type) {
    case types.LOAD_STAFF_MEMBER_DETAILS_SUCCESS: {
      return new StaffMemberDetailsModel().assign(action.member);
    }
    case types.CREATE_STAFF_MEMBER_SUCCESS: {
      return new StaffMemberDetailsModel().assign(action.member);
    }
    case types.UPDATE_STAFF_MEMBER_SUCCESS: {
      return new StaffMemberDetailsModel().assign(action.member);
    }
    case types.ASSIGN_CHARGING_SCHEME_TO_STAFF_SUCCESS: {
      const staffDetails = new StaffMemberDetailsModel().assign(state);
      staffDetails.contract.schemes = [
        ...staffDetails.contract.schemes.filter((scheme) => scheme.schemeId !== action.scheme.schemeId),
        new StaffChargingScheme().assign(action.scheme)
      ];
      return staffDetails;
    }
    case types.REMOVE_CHARGING_SCHEME_FROM_STAFF_SUCCESS: {
      const staffDetails = new StaffMemberDetailsModel().assign(state);
      staffDetails.contract.schemes = [
        ...staffDetails.contract.schemes.filter((scheme) => scheme.schemeId !== action.schemeId)
      ];
      return staffDetails;
    }
    default:
      return state;
  }
}
