export const handleColorSelector = (status) => {
  switch (status) {
    case 'draft':
      return (theme) => theme.palette.color.warning;
    case 'approved':
      return (theme) => theme.palette.color.success;
    case 'failed':
      return (theme) => theme.palette.color.error;
    case 'canceled':
      return (theme) => theme.palette.color.cancel;
    case 'paid':
      return (theme) => theme.palette.color.color5;
    case 'partlyPaid':
      return (theme) => theme.palette.color.color4;
    default:
      return (theme) => theme.palette.color.hidden;
  }
};
