import initialState from './initial';
import * as types from '../actions/actionTypes';
import { MealCategory } from '../models/meals/MealModels';

export default function mealsCategoriesReducer(state = initialState.mealsCategories, action) {
  switch (action.type) {
    case types.LOAD_MEALS_CATEGORIES_SUCCESS: {
      return action.mealsCategories.map((mealCategory) => new MealCategory().assign(mealCategory));
    }
    // case types.CREATE_MEAL_CATEGORY: {
    //   let newState = [...state];
    //   const newCategory = new MealCategory().assign({ ...action.nextMealCategory, id: action.response.mealCategoryId });
    //   newState.push(newCategory);

    //   return newState;
    // }
    // case types.ADD_MEAL_TO_CATEGORY_SUCCESS: {
    //   const { categoryId, meal } = action.payload;
    //   let newState = state;
    //   let category = state.find((mealCategory) => mealCategory.id === categoryId);
    //   category.meals.push(meal);

    //   newState = [...newState.filter((element) => element.id !== categoryId), category];
    //   return newState;
    // }
    // case types.REMOVE_MEAL_FROM_CATEGORY_SUCCESS: {
    //   const { categoryId, mealId } = action.payload;
    //   let newState = [...state];
    //   let category = state.find((mealCategory) => mealCategory.id === categoryId);
    //   category.meals = category.meals.filter((element) => element.id !== mealId);

    //   newState = [...newState.filter((element) => element.id !== categoryId), category];
    //   return newState;
    // }
    default:
      return state;
  }
}
