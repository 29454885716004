import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ActionButton from './buttons/ActionButton';
import DialogForm from './DialogForm';
import withMediaQuery from '../../hoc/withMediaQuery';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Box, CardActionArea, Typography } from '@mui/material';

const CardDialogEditForm = (props) => {
  const [isOpen, setIsOpen] = useState(props.isInitiallyExpanded);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const renderCardContent = () => {
    if (props.isEmpty) {
      return props.emptyForm;
    }
    return props.readonlyForm;
  };

  const renderDialog = (content) => {
    return (
      <DialogForm
        maxWidth={props.maxWidth || 'sm'}
        header={props.actionTitle || props.title}
        icon={props.actionTitleIcon}
        onSave={props.onSave}
        onCancel={props.onCancel}
        onValidate={props.onValidate}
        onValidationDone={props.onValidationDone}
        isDialogOpen={props.isDialogOpen}
        isProcessing={props.isProcessing}
        statePathToUi={props.statePathToUi}
        saveDisabled={props.saveDisabled}
        requiredFieldText={props.requiredFieldText}
        noValidate={props.noValidate}
        hasConfirmationDialog={props.hasConfirmationDialog}
        confirmationTitle={props.confirmationTitle}
        confirmationText={props.confirmationText}
        attachRODO={props.attachRODO}>
        {content}
      </DialogForm>
    );
  };

  return (
    <Paper sx={{ my: 2, p: 1, overflow: 'auto' }} elevation={1}>
      <CardActionArea onClick={handleOpen}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', px: 2, py: 1, alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, my: 0.5 }}>
              {props.title}
            </Typography>
            {props.subtitle && <Typography sx={{ mx: 3, opacity: 0.7 }}>{props.subtitle}</Typography>}
          </Box>

          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </CardContent>
      </CardActionArea>
      <Collapse in={isOpen}>
        <CardContent>
          {props.helperText ? <>{props.helperText}</> : null}
          {renderCardContent(props.isDialogOpen)}
          {renderDialog(props.editForm)}
        </CardContent>
        <CardActions
          sx={{
            flexDirection: props.isMobile ? 'column' : null,
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap'
          }}>
          {props.customActions ? props.customActions : null}
          <ActionButton onAction={props.onAction} actionLabel={props.actionLabel} inProgress={props.isProcessing} />
        </CardActions>
      </Collapse>
    </Paper>
  );
};

CardDialogEditForm.defaultProps = {
  actionLabel: 'ZMIEŃ'
};

CardDialogEditForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  onValidationDone: PropTypes.func,
  isProcessing: PropTypes.bool.isRequired,
  noValidate: PropTypes.bool,
  isDialogOpen: PropTypes.bool.isRequired,
  statePathToUi: PropTypes.string,
  //
  title: PropTypes.string,
  subtitle: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  actionLabel: PropTypes.string,
  actionTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isInitiallyExpanded: PropTypes.bool,
  actionTitleIcon: PropTypes.object,
  //
  readonlyForm: PropTypes.node.isRequired,
  editForm: PropTypes.node.isRequired,
  emptyForm: PropTypes.node,
  isEmpty: PropTypes.bool,
  saveDisabled: PropTypes.bool,
  customActions: PropTypes.node,
  requiredFieldText: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  maxWidth: PropTypes.string
};

export default withMediaQuery()(CardDialogEditForm);
