import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import StackRow from '../StackRow';

const PageHeaderText = ({ title, titleIcon, subtitle, subtitleIcon, additionalInfo, sx }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <Stack spacing={1} sx={{ my: 3, flexWrap: 'wrap', color: (theme) => theme.palette.color.primary, ...sx }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
        <StackRow sx={{ alignItems: 'start', display: 'flex', flexWrap: 'nowrap', justifyContent: 'start' }}>
          <Box
            sx={{
              transform: 'scale(1.8)',
              mr: isMobile ? 3 : 5,
              ml: 1,
              mt: 1.5,
              width: 25,
              height: 25,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start'
            }}>
            {titleIcon}
          </Box>

          <Typography
            align="left"
            variant="h4"
            component="h2"
            sx={{
              fontWeight: (theme) => theme.typography.fontWeightBold,
              display: 'inline-block',
              mt: 1
            }}>
            {title}
          </Typography>
        </StackRow>
        {additionalInfo && (
          <Typography
            variant="h4"
            sx={{
              fontWeight: (theme) => theme.typography.fontWeightBold,
              opacity: 0.5,
              pl: 9,
              mt: 1
            }}>
            {additionalInfo}
          </Typography>
        )}
      </Box>

      {subtitleIcon && (
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          {subtitleIcon}
          {subtitle && (
            <Typography variant="h6" sx={{ ml: 1 }}>
              {subtitle}
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};

PageHeaderText.propTypes = {
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.node,
  subtitle: PropTypes.string,
  subtitleIcon: PropTypes.node,
  additionalInfo: PropTypes.any,
  sx: PropTypes.object
};

export default PageHeaderText;
