import PropTypes from 'prop-types';
import React from 'react';
import { chargingAbsenceReportingDeadlineDays as DeadlineDays } from '../../../../constants/chargingAbsenceReportingDeadlineDays';
import SelectField from '../../../common/SelectField';
import { Box, ListSubheader, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import TimePicker from '../../../common/TimePicker';
import { TimeModel } from '../../../../models/TimeModel';
import ClearButtonWrapper from '../../../common/ClearButtonWrapper';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import Toggle from '../../../common/Toggle';

const AbsenceReportingDeadline = ({ scheme, onChange, onClear, errors }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <FieldsetWrapper
      legend={
        <ListSubheader component="span" sx={{ pl: 0, fontSize: 12, color: (theme) => theme.palette.color.contrast }}>
          Uwzględniaj nieobecności zgłoszone do
        </ListSubheader>
      }
      invisible={false}
      sx={{ width: '100%' }}>
      <ClearButtonWrapper
        contrast
        onClear={() => onClear()}
        disabled={scheme.absenceReportingDeadlineDays === undefined && scheme.absenceReportingDeadlineTime.isEmpty()}>
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
          <Box>
            <TimePicker
              textFieldStyle={{ maxWidth: 64, marginRight: 16 }}
              name="absenceReportingDeadlineTime"
              value={scheme.absenceReportingDeadlineTime.toDate() || undefined}
              onChange={(e, value) =>
                onChange({ target: { name: 'absenceReportingDeadlineTime', value: new TimeModel().parse(value) } })
              }
              errorText={errors.absenceReportingDeadlineTime}
            />
          </Box>
          <SelectField
            contrast
            floatingLabelText="Dnia"
            sx={{ m: isMobile ? '5px 0 0 0' : '0 0 0 10px', minWidth: 100 }}
            id="chargingPrecision"
            value={scheme.absenceReportingDeadlineDays === 0 ? '0' : scheme.absenceReportingDeadlineDays}
            onChange={(e) => {
              onChange({
                target: { name: 'absenceReportingDeadlineDays', value: e.target.value === '0' ? 0 : e.target.value }
              });
            }}
            errorText={errors.absenceReportingDeadlineDays}>
            {Object.values(DeadlineDays).map((deadline) => {
              return (
                <MenuItem key={deadline.name} value={deadline.value === 0 ? '0' : deadline.value}>
                  {deadline.name}
                </MenuItem>
              );
            })}
          </SelectField>
        </Box>
      </ClearButtonWrapper>
      <Toggle
        label="Nie uwzględniaj weekendów jako możliwych dni do zgłoszenia nieobecności"
        labelPosition="right"
        defaultToggled={scheme.absenceReportingDeadlineDisableWeekends}
        onToggle={(event, value) => onChange({ target: { name: 'absenceReportingDeadlineDisableWeekends', value } })}
      />
    </FieldsetWrapper>
  );
};

AbsenceReportingDeadline.propTypes = {
  scheme: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default AbsenceReportingDeadline;
