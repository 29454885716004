import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import React from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PropTypes from 'prop-types';

export const mainApplicationsHeader = (props) => {
  return (
    <Typography
      variant="h5"
      sx={{
        display: 'flex',
        fontWeight: (theme) => theme.typography.fontWeightBold,
        color: (theme) => (props.contrast ? theme.palette.color.contrast : theme.palette.color.primary)
      }}>
      <AssignmentIcon fontSize="large" />
      Wnioski
    </Typography>
  );
};

export const CateringApplicationsHeader = (props) => {
  const { onAdd } = props;
  return (
    <Box>
      {onAdd && (
        <Typography
          variant="h6"
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightBold,
            my: 1,
            color: (theme) => (props.contrast ? theme.palette.color.contrast : theme.palette.color.primary)
          }}>
          Wyżywienie
          <Tooltip title="Utwórz wniosek">
            <IconButton size="medium" onClick={onAdd}>
              <AddCircleOutlineOutlinedIcon
                sx={{ color: (theme) => (props.contrast ? theme.palette.color.contrast : theme.palette.color.primary) }}
                fontSize="large"
              />
            </IconButton>
          </Tooltip>
        </Typography>
      )}
    </Box>
  );
};

CateringApplicationsHeader.propTypes = {
  onAdd: PropTypes.func
};

export const PsychologicalHelpApplicationsHeader = (props) => {
  const { onAdd } = props;
  return (
    <Box>
      {onAdd && (
        <Typography
          variant="h6"
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightBold,
            my: 1,
            color: (theme) => (props.contrast ? theme.palette.color.contrast : theme.palette.color.primary)
          }}>
          Pomoc psychologiczno-pedagogiczna
          <Tooltip title="Utwórz wniosek">
            <IconButton size="medium" onClick={onAdd}>
              <AddCircleOutlineOutlinedIcon
                sx={{ color: (theme) => (props.contrast ? theme.palette.color.contrast : theme.palette.color.primary) }}
                fontSize="large"
              />
            </IconButton>
          </Tooltip>
        </Typography>
      )}
    </Box>
  );
};

PsychologicalHelpApplicationsHeader.propTypes = {
  onAdd: PropTypes.func
};
export const AdditionalActivitiesApplicationsHeader = (props) => {
  const { onAdd } = props;
  return (
    <Box>
      {onAdd && (
        <Typography
          variant="h6"
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightBold,
            my: 1,
            color: (theme) => (props.contrast ? theme.palette.color.contrast : theme.palette.color.primary)
          }}>
          Zajęcia dodatkowe
          <Tooltip title="Utwórz wniosek">
            <IconButton size="medium" onClick={onAdd}>
              <AddCircleOutlineOutlinedIcon
                sx={{ color: (theme) => (props.contrast ? theme.palette.color.contrast : theme.palette.color.primary) }}
                fontSize="large"
              />
            </IconButton>
          </Tooltip>
        </Typography>
      )}
    </Box>
  );
};

AdditionalActivitiesApplicationsHeader.propTypes = {
  onAdd: PropTypes.func
};
