import initialState from './initial';
import * as types from '../actions/actionTypes';

import { compareNames } from '../utils/personNamesComparator';
import {
  AssociatedTeacherModel,
  EnrolledChildModel,
  PsychologicalGroupModel
} from '../models/psychological/PsychologicalGroupModel';
import moment from 'moment';

export default function psychologicalGroupDetailsReducer(state = initialState.psychologicalGroupDetails, action) {
  switch (action.type) {
    case types.LOAD_PSYCHOLOGICAL_GROUP_DETAILS_SUCCESS: {
      return new PsychologicalGroupModel().assign(action.groupDetails);
    }
    case types.UPDATE_PSYCHOLOGICAL_GROUP_SUCCESS: {
      return new PsychologicalGroupModel().assign(action.groupDetails);
    }
    case types.ENROLL_CHILDREN_TO_PSYCHOLOGICAL_GROUP_SUCCESS: {
      const groupDetails = new PsychologicalGroupModel().assign(state);
      groupDetails.enrolledChildren = action.children
        .map((child) => new EnrolledChildModel().assign({ ...child, enrolledAt: moment() }))
        .sort(compareNames);
      return groupDetails;
    }
    case types.REMOVE_CHILD_FROM_PSYCHOLOGICAL_GROUP_SUCCESS: {
      const groupDetails = new PsychologicalGroupModel().assign(state);
      groupDetails.enrolledChildren = [...groupDetails.enrolledChildren.filter((child) => child.id !== action.childId)];
      return groupDetails;
    }
    case types.ASSOCIATE_TEACHER_TO_PSYCHOLOGICAL_GROUP_SUCCESS: {
      const groupDetails = new PsychologicalGroupModel().assign(state);
      const newTeacher = new AssociatedTeacherModel().assign(action.teacher);
      newTeacher.associatedAt = new Date();
      groupDetails.associatedTeachers = [...groupDetails.associatedTeachers, newTeacher].sort(compareNames);
      return groupDetails;
    }
    case types.REMOVE_TEACHER_FROM_PSYCHOLOGICAL_GROUP_SUCCESS: {
      const groupDetails = new PsychologicalGroupModel().assign(state);
      groupDetails.associatedTeachers = [
        ...groupDetails.associatedTeachers.filter((teacher) => teacher.id !== action.teacherId)
      ];
      return groupDetails;
    }
    default:
      return state;
  }
}
