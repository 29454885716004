import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ExceededWorkingHoursWarningWrapper from './ExceededWorkingHoursWarningWrapper';
import { TimeModel } from '../../../models/TimeModel';
import PersonAvatar from '../../forms/PersonAvatar';
import DialogForm from '../../forms/DialogForm';
import TimePicker from '../../common/TimePicker';
import { Box, Typography } from '@mui/material';
import Checkbox from '../../common/Checkbox';
import { useSelector } from 'react-redux';

const ReportDropOffDialog = ({
  pupilFirstName,
  pupilLastName,
  pupilPhotoUrl,
  droppedOffAt,
  onCancel,
  onSave,
  isProcessing,
  isDialogOpen
}) => {
  const isAdaptationAvailable = useSelector((state) => state.configuration.unit.allowAdaptation);
  const [dropOffTime, setDropOffTime] = useState(droppedOffAt);
  const [isAdaptation, setIsAdaptation] = useState(false);
  const [errors, setErrors] = useState({});

  const handleTimeChange = (newTime) => {
    setDropOffTime(newTime);
  };

  const handleIsAdaptationChange = (e, isChecked) => {
    setIsAdaptation(isChecked);
  };

  const handleSave = () => {
    onSave(dropOffTime, isAdaptation);
  };

  const handleValidationDone = (newErrors) => {
    setErrors(newErrors);
  };

  return (
    <DialogForm
      maxWidth="xs"
      header="Rejestracja wejścia"
      onSave={handleSave}
      onCancel={onCancel}
      isDialogOpen={isDialogOpen}
      isProcessing={isProcessing}
      onValidationDone={handleValidationDone}
      statePathToUi="attendanceUi.dropOff">
      <ExceededWorkingHoursWarningWrapper droppedOffAt={new TimeModel().parse(dropOffTime)} />
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 18, my: 3, mx: 0 }}>
          <PersonAvatar
            initials
            alt={pupilFirstName}
            url={pupilPhotoUrl}
            firstName={pupilFirstName}
            lastName={pupilLastName}
          />
          <Typography sx={{ ml: 1, fontWeight: (theme) => theme.typography.fontWeightBold }}>
            {pupilFirstName + ' ' + pupilLastName}
          </Typography>
        </Box>
        <TimePicker
          sx={{ width: 128, '&input': { textAlign: 'center', fontSize: 32 } }}
          floatingLabelText="Godzina wejścia"
          value={dropOffTime}
          onChange={handleTimeChange}
          name="dropped"
        />
        {isAdaptationAvailable && (
          <Checkbox label="Oznacz jako adaptacja" defaultChecked={isAdaptation} onCheck={handleIsAdaptationChange} />
        )}
      </Box>
    </DialogForm>
  );
};

ReportDropOffDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  droppedOffAt: PropTypes.object.isRequired,
  pupilFirstName: PropTypes.string.isRequired,
  pupilLastName: PropTypes.string.isRequired,
  pupilPhotoUrl: PropTypes.string
};

export default ReportDropOffDialog;
