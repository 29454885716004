import IconButtonPipe from '../../../../common/IconButtonPipe';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, Typography } from '@mui/material';

const ActionsButtons = ({ setIsDialogOpen, handleRemove, isInfo }) => (
  <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
    {isInfo && (
      <IconButtonPipe
        sx={{ p: 0, px: 1 }}
        size="small"
        onClick={() => {
          setIsDialogOpen(true);
        }}
        tooltip="Szczegóły">
        <InfoIcon sx={{ color: (theme) => theme.palette.color.contrast }} />
      </IconButtonPipe>
    )}
    <IconButtonPipe sx={{ p: 0, px: 1 }} size="small" onClick={handleRemove} tooltip="Zamknij">
      <CancelIcon sx={{ color: (theme) => theme.palette.color.contrast }} />
    </IconButtonPipe>
  </Box>
);

export const AlertMessage = ({
  setIsDialogOpen = () => {},
  isInDialog = false,
  data,
  albumName = null,
  style,
  handleRemove,
  isInfo = true
}) => (
  <Alert
    sx={{ border: isInDialog ? null : 0, ...style }}
    variant={isInDialog ? 'standard' : 'outlined'}
    severity={data.severity}>
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      {isInDialog ? (
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>{data.messageLong}</Typography>
          <PhotoLibraryIcon sx={{ ml: 1 }} />
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, ml: 1 }}>{albumName}</Typography>
        </Box>
      ) : (
        <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }} alignItems="center">
          {data.messageShort}
        </Typography>
      )}
      {!isInDialog && <ActionsButtons isInfo={isInfo} setIsDialogOpen={setIsDialogOpen} handleRemove={handleRemove} />}
    </Box>
  </Alert>
);

ActionsButtons.propTypes = {
  setIsDialogOpen: PropTypes.func.isRequired,
  handleRemove: PropTypes.func,
  isInfo: PropTypes.bool
};

AlertMessage.propTypes = {
  setIsDialogOpen: PropTypes.func,
  isInDialog: PropTypes.bool,
  data: PropTypes.object,
  albumName: PropTypes.string,
  style: PropTypes.object,
  handleRemove: PropTypes.func,
  isInfo: PropTypes.bool
};
