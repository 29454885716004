import PropTypes from 'prop-types';
import React from 'react';
import ChildDaycareCalendar from '../../common/children/daycare/ChildDaycareCalendar';
import ReportDaycareDialog from '../../daycare/dialogs/ReportDaycareDialog';
import NotificationDialog from '../../forms/NotificationDialog';
import moment from 'moment';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { CardActionArea, Typography, CardContent, Paper, Collapse } from '@mui/material';

class ChildDaycareCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      currentDay: null,
      isDetailsDialogOpen: false,
      isReportingDisabledDialogOpen: false,
      isProcessing: false,
      isOpen: this.props.isInitiallyExpanded
    };
    this.getDetailsByDay = this.getDetailsByDay.bind(this);
    this.handleSaveReport = this.handleSaveReport.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  getDetailsByDay(date) {
    return date
      ? this.props.daycare.entries.find(
          (a) => moment(a.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
        ) || {}
      : {};
  }

  handleDetailsOpen(day) {
    this.setState({
      currentDay: day,
      isDetailsDialogOpen: true
    });
  }

  handleOpen() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  }

  handleSaveReport(entry) {
    this.setState({ isProcessing: true }, () =>
      this.props.daycareActions
        .updateDaycareEntry(entry)
        .then((error) => this.setState({ isDetailsDialogOpen: !!error, isProcessing: false }))
    );
  }

  whenChildIsEnrolledToGroup(day, actionWhenEnrolled) {
    if (this.props.childDetails.enrolledToGroup) {
      this.setState({ currentDay: day }, actionWhenEnrolled);
    } else {
      this.setState({ isReportingDisabledDialogOpen: true });
    }
  }

  render() {
    return (
      <Paper elevation={1} sx={{ my: 2, p: 1 }}>
        <CardActionArea onClick={this.handleOpen}>
          <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 1, px: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, my: '4px' }}>
              Aktywność
            </Typography>
            {this.isOpen ? <ExpandLess /> : <ExpandMore />}
          </CardContent>
        </CardActionArea>
        <Collapse in={this.state.isOpen}>
          <ChildDaycareCalendar
            markedDays={this.props.daycare.entries}
            daysOff={this.props.daysOff}
            onDateChange={this.props.onDateChange}
            onDaySelected={() => {}}
            onDetailsOpen={(day) => this.handleDetailsOpen(day)}
            isLoading={this.props.isCalendarLoading}
          />
          {this.state.isDetailsDialogOpen && (
            <ReportDaycareDialog
              title="Aktywność dziecka"
              pupilId={this.props.childDetails.id}
              pupilFirstName={this.props.childDetails.firstName}
              pupilLastName={this.props.childDetails.lastName}
              pupilPhotoUrl={this.props.childDetails.photoUrl}
              isProcessing={this.state.isProcessing}
              isDialogOpen={this.state.isDetailsDialogOpen}
              date={moment(this.state.currentDay.date).format('YYYY-MM-DD')}
              entry={this.getDetailsByDay(this.state.currentDay ? this.state.currentDay.date : null)}
              onCancel={() => this.setState({ isDetailsDialogOpen: false })}
              onSave={this.handleSaveReport}
              propertiesDefinition={this.props.propertiesDefinition}
            />
          )}
          <NotificationDialog
            notificationText="Nie można zarejestrować aktywności. Dziecko nie jest przypisane do grupy."
            notificationTitle="Uwaga"
            onConfirm={() => this.setState({ isReportingDisabledDialogOpen: false })}
            isOpen={this.state.isReportingDisabledDialogOpen}
          />
        </Collapse>
      </Paper>
    );
  }
}

ChildDaycareCard.propTypes = {
  daycare: PropTypes.object.isRequired,
  onDateChange: PropTypes.func.isRequired,
  daysOff: PropTypes.array.isRequired,
  isCalendarLoading: PropTypes.bool.isRequired,
  daycareActions: PropTypes.object.isRequired,
  childDetails: PropTypes.object.isRequired,
  propertiesDefinition: PropTypes.object.isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired
};

export default ChildDaycareCard;
