/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { EntranceFileModel } from '../models/entracnes/EntranceModels';

export default function messageBoardUiReducer(state = initialState.configuration.entrances, action) {
  switch (action.type) {
    case types.LOAD_ENTRANCES_SUCCESS:
      return action.entrances.map((entrance) => Object.assign({}, new EntranceFileModel(), entrance));
    case types.IMPORT_ENTRANCE_SUCCESS:
      return [...state, Object.assign({}, new EntranceFileModel(), action.entrance)];
    default:
      return state;
  }
}
