import PropTypes from 'prop-types';
import React from 'react';
import SelectField from '../../common/SelectField';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import DatePicker from '../../common/DatePicker';
import moment from 'moment';
import { Box, MenuItem, Typography } from '@mui/material';

const StatementOfSettlementsForMonthReport = ({ groupId, groups, selectedDate, onChange, info, errors }) => {
  return (
    <Box>
      <FieldsetWrapper legend="Pola dla generowania raportu rozliczeń">
        <SelectField
          errorText={errors.groupId}
          contrast
          sx={{ width: 'auto', overflow: 'hidden', my: 2 }}
          floatingLabelText="Grupa"
          id="groupId"
          value={groupId}
          onChange={(event) => {
            onChange({ target: { name: 'groupId', value: event.target.value } });
          }}>
          {groups.map((group) => {
            return (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            );
          })}
        </SelectField>
        <DatePicker
          floatingLabelText="Data generacji raportu"
          name="date"
          value={selectedDate ? new Date(selectedDate) : new Date()}
          onChange={(e, value) =>
            onChange({ target: { name: 'selectedDate', value: moment(value).format('YYYY-MM-DD') } })
          }
        />
        {info ? (
          <Typography sx={{ p: 2, mt: 2 }} variant="subtitle2">
            {info}
          </Typography>
        ) : null}
      </FieldsetWrapper>
    </Box>
  );
};

StatementOfSettlementsForMonthReport.propTypes = {
  selectedDate: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  info: PropTypes.string
};

export default StatementOfSettlementsForMonthReport;
