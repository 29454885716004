import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as childrenActions from '../../actions/childrenActions';
import * as reportsActions from '../../actions/reportsActions';
import * as settlementsActions from '../../actions/settlementsActions';
import ChildDetailsHeader from './header/ChildDetailsHeader';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { Box } from '@mui/material';
import FinancialDetailsCard from './financialDetails/FinancialDetailsCard';

class ChildFinancialDetailsPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handlerLoadPersonalBalance = this.handlerLoadPersonalBalance.bind(this);
    this.handlerPayoutFromBalance = this.handlerPayoutFromBalance.bind(this);
    this.handlerDeposit = this.handlerDeposit.bind(this);
    this.handleLoaded = this.handleLoaded.bind(this);
    this.handleGeneratePayoutConfirmation = this.handleGeneratePayoutConfirmation.bind(this);
    this.handleWalletTransfer = this.handleWalletTransfer.bind(this);
    this.handlePupilChange = this.handlePupilChange.bind(this);
  }

  componentDidMount() {
    this.handlerLoadPersonalBalance();
  }

  componentDidUpdate(prevProps) {
    if (this.props.pupil !== prevProps.pupil) {
      this.handlePupilChange(this.props.pupil);
    }
  }

  handlePupilChange(value) {
    this.setState({ pupil: Object.assign({}, value) });
  }

  handleLoaded() {
    this.setState({ pupil: Object.assign({}, this.props.pupil) });
  }

  handleGeneratePayoutConfirmation(operationDate) {
    return this.props.reportsActions.generateBalanceFundsPayoutConfirmationAsync(this.props.pupilId, operationDate);
  }

  handlerLoadPersonalBalance() {
    return this.props.actions.loadChildPersonalBalanceAsync(this.props.pupilId);
  }

  handlerPayoutFromBalance(amount, paymentMethod, walletType) {
    return this.props.actions.cashOutMoneyFromPersonalBalanceAsync(
      this.props.pupilId,
      amount,
      paymentMethod,
      walletType
    );
  }

  handlerDeposit(paymentDetails, child) {
    return this.props.settlementsActions.registerDepositAsync(paymentDetails, child);
  }

  handleWalletTransfer(from, target, amount) {
    return this.props.actions.walletTransferAsync(from, target, amount, this.props.pupil.id);
  }

  render() {
    const { pupil } = this.props;
    return (
      <LoadingRenderWrapper onLoaded={this.handleLoaded}>
        <Box>
          <ChildDetailsHeader
            name={pupil.firstName + ' ' + pupil.lastName}
            group={pupil.enrolledToGroup}
            photoUrl={pupil.photoUrl}
            photoChangeDisabled={true}
            onPhotoDrop={() => {}}
            onPhotoRemove={() => {}}
          />
          <FinancialDetailsCard
            child={pupil}
            personalBalance={this.props.childPersonalBalance}
            onPayoutFromBalance={this.handlerPayoutFromBalance}
            onDeposit={this.handlerDeposit}
            onWalletTransfer={this.handleWalletTransfer}
            onLoadBalance={this.handlerLoadPersonalBalance}
            onGeneratePayoutConfirmation={this.handleGeneratePayoutConfirmation}
          />
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

ChildFinancialDetailsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  pupil: PropTypes.object.isRequired,
  pupilId: PropTypes.string.isRequired,
  childPersonalBalance: PropTypes.object.isRequired,
  reportsActions: PropTypes.object.isRequired,
  settlementsActions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  const pupilId = ownProps.params.id;
  return {
    pupilId,
    pupil: state.childDetails,
    childPersonalBalance: state.childPersonalBalance
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(childrenActions, dispatch),
    reportsActions: bindActionCreators(reportsActions, dispatch),
    settlementsActions: bindActionCreators(settlementsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildFinancialDetailsPage);
