import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  CardActionArea,
  CardContent,
  Collapse,
  Grow,
  List,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import IconButtonPipe from '../../../../../components/common/IconButtonPipe';
import UserRoleIcon from '../../../../../components/userRoles/UserRoleIcon';
import { useSelector } from 'react-redux';
import SpecificIcon from '../../../../../components/groupsAndActivities/SpecificIcon';
import * as GlobalTypes from '../../../../../constants/groupsAndActivitiesTypes';
import SingleUser from '../SingleUser';
import { useSharePersonListComponentStyles } from './SharePersonListComponentStyles';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useShareFileContext } from '../../../context/ShareFileContext';

const SharePersonListComponent = (props) => {
  const {
    title,
    type,
    groupId,
    color: propsColor,
    users,
    isSelected = false,
    onSingleAction,
    onMultiAction,
    forceOpenCollapse = false
  } = props;

  const { isPrivate } = useShareFileContext();
  const [isOpen, setIsOpen] = useState(false);
  const isContrastColor = useSelector((state) => state.theme);
  const theme = useTheme();

  useEffect(() => {
    setIsOpen(forceOpenCollapse);
  }, [forceOpenCollapse]);

  // eslint-disable-next-line no-nested-ternary
  let color = isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color25;

  const cl = useSharePersonListComponentStyles({ color, isOpen });

  if (isPrivate) color = theme.palette.color.disabled;
  if (users.length === 0) return null;

  return (
    <Grow in={true} timeout={500}>
      <Paper
        elevation={1}
        className={cl.wrapper}
        sx={{
          boxShadow: 'none'
        }}>
        <CardActionArea
          component="div"
          disabled={isPrivate}
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
          className={cl.actionArea}>
          <CardContent className={cl.cardContent}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isOpen ? <ExpandLess /> : <ExpandMore />}
              {type === GlobalTypes.GROUP ? (
                <SpecificIcon type={type} style={{ mr: 1, fontSize: 32, color }} />
              ) : (
                <UserRoleIcon role={type} style={{ mr: 1, fontSize: 34, color }} />
              )}
              <Typography
                sx={{
                  fontWeight: (theme) => theme.typography.fontWeightBold
                }}
                className={cl.titleWrapper}>
                {title}
              </Typography>
              <Tooltip title="Ilość osób na liście">
                <Avatar className={cl.numberWrapper} style={{ background: ` ${color}` }}>
                  {users.length}
                </Avatar>
              </Tooltip>
            </Box>
            <IconButtonPipe
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                e.persist();
                onMultiAction(
                  users.map((item) => item.id),
                  isSelected,
                  groupId
                );
              }}
              tooltip={isSelected ? 'Usuń' : 'Udostępnij'}
              style={{ color: isSelected ? theme.palette.color.color10 : theme.palette.color.secondary }}>
              {isSelected ? (
                <DeleteForeverOutlinedIcon sx={{ fontSize: 30, color: isPrivate ? color : undefined }} />
              ) : (
                <NavigateNextIcon sx={{ color: isPrivate ? color : undefined }} />
              )}
            </IconButtonPipe>
          </CardContent>
        </CardActionArea>
        <Collapse in={isOpen}>
          <CardContent style={{ borderTop: `1px solid ${color}` }}>
            <List dense sx={{ p: 0, m: 0 }}>
              {users.map((item) => (
                <SingleUser
                  {...item}
                  key={item.id}
                  onSingleAction={(id) => {
                    onSingleAction(id, isSelected, groupId);
                  }}
                  isSelected={isSelected}
                  isDisabled={isPrivate}
                />
              ))}
            </List>
          </CardContent>
        </Collapse>
      </Paper>
    </Grow>
  );
};

SharePersonListComponent.propTypes = {
  color: PropTypes.string,
  isSelected: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  users: PropTypes.array,
  onSingleAction: PropTypes.func.isRequired,
  onMultiAction: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  forceOpenCollapse: PropTypes.bool
};

export default SharePersonListComponent;
