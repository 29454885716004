import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import {
  setGeneralDialogClosed,
  setGeneralDialogOpen,
  deleteProgramElement
} from '../../actions/psychologicalGroupClassesActions';
import ProgramContainer from '../common/psychologicalWorkProgram/ProgramContainer';
import GroupProgramDialog from './GroupProgramDialog';

const GroupProgram = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.psychologicalGroupWorkProgram);
  const basicGroupGoals = useSelector((state) => state.psychologicalGroupWorkProgram.basicGroupGoals);
  const specificGroupGoals = useSelector((state) => state.psychologicalGroupWorkProgram.specificGroupGoals);
  const groupMethods = useSelector((state) => state.psychologicalGroupWorkProgram.groupMethods);
  const groupConclusions = useSelector((state) => state.psychologicalGroupWorkProgram.groupConclusions);
  const Ui = useSelector((state) => state.psychologicalGroupProgramUi.groupProgram);
  const psychologicalChoosenGroup = useSelector((state) => state.psychologicalChoosenGroup);

  const [currentData, setCurrentData] = useState(null);

  const [dialogType, setDialogType] = useState('');

  const handleSave = () => {
    setCurrentData(null);
  };

  const handleDelete = (data) => {
    dispatch(deleteProgramElement(data, psychologicalChoosenGroup.id));
  };

  const handleGeneralDialogOpen = (post) => {
    if (!!post) {
      setCurrentData(post);
    }
    setDialogType('basicGroupGoals');
    dispatch(setGeneralDialogOpen());
  };
  const handleSpecificDialogOpen = (post) => {
    if (!!post) {
      setCurrentData(post);
    }
    setDialogType('specificGroupGoals');
    dispatch(setGeneralDialogOpen());
  };
  const handleGeneralMethods = (post) => {
    if (!!post) {
      setCurrentData(post);
    }
    setDialogType('groupMethods');
    dispatch(setGeneralDialogOpen());
  };
  const handleGeneralConclusions = (post) => {
    if (!!post) {
      setCurrentData(post);
    }
    setDialogType('groupConclusions');
    dispatch(setGeneralDialogOpen());
  };

  const handleCancel = () => {
    setDialogType('');
    setCurrentData(null);
    dispatch(setGeneralDialogClosed());
  };

  return (
    <Box>
      <ProgramContainer
        title="Cele ogólne"
        data={basicGroupGoals}
        onClick={() => handleGeneralDialogOpen(null)}
        onEdit={handleGeneralDialogOpen}
        onDelete={handleDelete}
      />
      <ProgramContainer
        title="Cele szczegółowe"
        data={specificGroupGoals}
        onClick={() => {
          handleSpecificDialogOpen(null);
        }}
        onEdit={handleSpecificDialogOpen}
        onDelete={handleDelete}
      />
      <ProgramContainer
        title="Metody"
        data={groupMethods}
        onClick={() => {
          handleGeneralMethods(null);
        }}
        onEdit={handleGeneralMethods}
        onDelete={handleDelete}
      />
      <ProgramContainer
        title="Wnioski"
        data={groupConclusions}
        onClick={() => {
          handleGeneralConclusions(null);
        }}
        onEdit={handleGeneralConclusions}
        onDelete={handleDelete}
      />
      {Ui.isEditing && (
        <>
          <GroupProgramDialog
            dialogType={dialogType}
            handleCancel={handleCancel}
            selectedElement={currentData}
            onSave={handleSave}
          />
        </>
      )}
    </Box>
  );
};

export default GroupProgram;
