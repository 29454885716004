import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../../common/TextField';
import FieldsetWrapper from '../../forms/FieldsetWrapper';

const PinDeviceForm = ({ device, isLoginDisabled, onChange, errors }) => {
  return (
    <div>
      <FieldsetWrapper legend="Informacje o nowym urządzeniu">
        <TextField
          disabled={isLoginDisabled}
          hintText="Login"
          floatingLabelText="Login*"
          floatingLabelFixed={false}
          name="login"
          value={device.login}
          onChange={onChange}
          fullWidth={true}
          errorText={errors.login}
        />
        <TextField
          hintText="Hasło"
          floatingLabelText="Hasło*"
          floatingLabelFixed={false}
          autoFocus={true}
          name="password"
          value={device.password}
          onChange={onChange}
          fullWidth={true}
          errorText={errors.password}
        />
      </FieldsetWrapper>
    </div>
  );
};

PinDeviceForm.propTypes = {
  device: PropTypes.object.isRequired,
  isLoginDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default PinDeviceForm;
