import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { Paper } from '@mui/material';
import FilterBar from '../common/FilterBar';
import _ from 'lodash';
import ChildrenList from './ChildrenArchiveList';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { caseInsensitiveComparator } from '../../utils/polishSort';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';

class ChildrenArchivePage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      filteredPupils: []
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.groupChildrenByLastName = this.groupChildrenByLastName.bind(this);
    this.handleLoaded = this.handleLoaded.bind(this);
  }

  handleLoaded() {
    this.setState({
      filteredPupils: this.groupChildrenByLastName(this.props.pupils)
    });
  }

  handleFilterChange(filteredList) {
    this.setState({
      filteredPupils: this.groupChildrenByLastName(filteredList)
    });
  }

  groupChildrenByLastName(children) {
    return _.chain(children)
      .sort((a, b) => caseInsensitiveComparator(a.lastName, b.lastName))
      .groupBy((pupil) => (pupil.lastName.length ? pupil.lastName[0].toUpperCase() : ''))
      .toPairs()
      .value();
  }

  render() {
    return (
      <LoadingRenderWrapper onLoaded={this.handleLoaded}>
        <>
          <PageHeaderText titleIcon={<FormatListNumberedIcon />} title=" Rejestr zarchiwizowanych dzieci" />
          <ContentWrapper>
            <FilterBar
              sx={{ mb: 2 }}
              itemsToFilter={this.props.pupils}
              onFilter={this.handleFilterChange}
              filter={(child, keyword) =>
                (child.lastName.toLowerCase() + ' ' + child.firstName.toLowerCase()).includes(keyword) ||
                (child.firstName.toLowerCase() + ' ' + child.lastName.toLowerCase()).includes(keyword)
              }
            />
            <Paper sx={{ p: 2, mb: 5 }}>
              <ChildrenList pupils={this.state.filteredPupils} />
            </Paper>
          </ContentWrapper>
        </>
      </LoadingRenderWrapper>
    );
  }
}

ChildrenArchivePage.propTypes = {
  pupils: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    pupils: state.archives.children
  };
}

export default connect(mapStateToProps)(ChildrenArchivePage);
