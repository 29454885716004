/*eslint indent:0*/
import initialState from './initial';
import { AlbumModel } from '../models/album/AlbumModel';
import * as types from '../actions/actionTypes';
import * as SortTypes from '../constants/sortTypes';

export function albumsReducer(state = initialState.gallery.albums, action) {
  switch (action.type) {
    case types.GALLERY_FETCH_ALBUMS: {
      return action.albums.map((item) => new AlbumModel().assign(item));
    }
    case types.GALLERY_CREATED_ALBUM: {
      return [...state, new AlbumModel().assign(action.newAlbum)];
    }
    case types.GALLERY_UPDATED_ALBUM: {
      const { updatedAlbum } = action;
      return [...state.filter((item) => item.id !== updatedAlbum.id), new AlbumModel().assign(updatedAlbum)];
    }
    case types.GALLERY_DELETED_ALBUM: {
      return [...state.filter((item) => item.id !== action.albumId)];
    }
    default:
      return state;
  }
}

export function uiReducer(state = initialState.gallery.ui, action) {
  switch (action.type) {
    case types.GALLERY_LOADING: {
      return { isLoading: true, target: action.target, isSuccess: null };
    }
    case types.GALLERY_LOADING_SUCCESS: {
      return { ...state, isLoading: false, isSuccess: true };
    }
    case types.GALLERY_LOADING_RESET: {
      return { isLoading: false, isSuccess: null };
    }
    default:
      return state;
  }
}

export function multimediaReducer(state = initialState.gallery.multimedia, action) {
  switch (action.type) {
    case types.GALLERY_ADD_MULTIMEDIA: {
      const { newMultimedia, dateFrom, dateTo, sortType, fetchCount } = action.payload;
      const newMultimediaTemp =
        dateFrom || dateTo
          ? newMultimedia.filter(({ uploadedAt }) => {
              return !!(
                (dateFrom && !dateTo && new Date(dateFrom) > new Date(uploadedAt)) ||
                (dateTo && !dateFrom && new Date(dateTo) < new Date(uploadedAt)) ||
                (dateTo &&
                  dateFrom &&
                  new Date(dateFrom) > new Date(uploadedAt) &&
                  new Date(dateTo) < new Date(uploadedAt))
              );
            })
          : newMultimedia;

      const preparedArray = [...state.list, ...newMultimediaTemp]
        .sort(({ uploadedAt: a }, { uploadedAt: b }) => {
          if (sortType === SortTypes.NEWEST) return new Date(b) - new Date(a);
          return new Date(a) - new Date(b);
        })
        .slice(0, fetchCount);
      return {
        ...state,
        list: preparedArray,
        totalFetchCount: state.totalFetchCount + action.payload.newMultimedia.length
      };
    }
    case types.GALLERY_FETCH_MULTIMEDIA: {
      return {
        ...state,
        list: [...action.multimedia.list],
        totalFetchCount: action.multimedia.totalFetchCount,
        albumId: action.albumId
      };
    }
    case types.GALLERY_UPDATED_MULTIMEDIA: {
      return {
        ...state,
        list: [...state.list.filter((item) => !action.multimediaList.includes(item.id))],
        totalFetchCount: state.totalFetchCount + action.multimediaList.length
      };
    }
    case types.GALLERY_MOVED_MULTIMEDIA: {
      return {
        ...state,
        list: [...state.list.filter((item) => !action.multimediaList.includes(item.id))],
        totalFetchCount: state.totalFetchCount - action.multimediaList.length
      };
    }
    case types.GALLERY_DELETED_MULTIMEDIA: {
      return {
        ...state,
        list: [...state.list.filter((item) => !action.multimediaList.includes(item.id))],
        totalFetchCount: state.totalFetchCount - action.multimediaList.length
      };
    }
    default:
      return state;
  }
}

export function multimediaUiReducer(state = initialState.gallery.ui, action) {
  switch (action.type) {
    case types.GALLERY_MULTIMEDIA_LOADING: {
      return { isLoading: true, target: action.target, isSuccess: null };
    }
    case types.GALLERY_MULTIMEDIA_LOADING_SUCCESS: {
      return { ...state, isLoading: false, isSuccess: true };
    }
    case types.GALLERY_MULTIMEDIA_LOADING_RESET: {
      return { isLoading: false, isSuccess: null };
    }
    default:
      return state;
  }
}
