import PropTypes from 'prop-types';
import React from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { List, ListItemIcon, ListItemText, Collapse, ListItemButton, ListItem, Box } from '@mui/material';

import { fromTemplate } from '../../../routePaths';
import LinkAdapter from '../../common/LinkAdapter/LinkAdapter';
import Badge from './Bagde';

class MenuRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    this.setState((prevState) => {
      return {
        expanded: !prevState.expanded
      };
    });
  }

  renderText(text, badge) {
    if (!badge) return text;
    return (
      <Box sx={{ display: 'flex' }}>
        {text}
        {badge}
      </Box>
    );
  }

  render() {
    const {
      text,
      icon,
      leftIcon,
      routePath,
      routeParams,
      selectedRoutePath,
      badge,
      nestedItems,
      primaryText,
      badgeValue,
      ...rest
    } = this.props;
    const isRouteSelected = () => routePath && selectedRoutePath && routePath === selectedRoutePath;
    return nestedItems ? (
      [
        <ListItem key="1" disablePadding>
          <ListItemButton
            onClick={this.handleToggle}
            aria-label={`Rozwiń ${text || primaryText} `}
            sx={{
              pl: 4,
              background: (theme) => theme.palette.background.default,
              color: (theme) => theme.palette.text.primary,
              display: 'flex',
              alignItems: 'center'
            }}>
            <ListItemIcon
              sx={{
                color: (theme) => theme.palette.text.primary
              }}>
              {icon || leftIcon}
            </ListItemIcon>
            <ListItemText
              sx={{
                color: (theme) => theme.palette.text.primary,
                fontWeight: (theme) => theme.typography.fontWeightBulky,
                fontFamily: 'League Spartan'
              }}
              disableTypography
              primary={text || primaryText}
            />
            {badgeValue > 0 ? <Badge badgeValue={badgeValue} /> : null}
            {this.state.expanded ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>,
        <Collapse key="2" in={this.state.expanded} timeout="auto" unmountOnExit>
          <List sx={{ ml: 2 }} disablePadding>
            {nestedItems}
          </List>
        </Collapse>
      ]
    ) : (
      <ListItem key={text} disablePadding>
        <ListItemButton
          selected={isRouteSelected()}
          sx={{
            pl: 4,
            background: (theme) => theme.palette.background.default,
            color: (theme) => (isRouteSelected() ? theme.palette.text.primary : theme.palette.text.primary),
            display: 'flex',
            justifyContent: 'flex-start'
          }}
          component={LinkAdapter}
          to={!this.props.disabled && !routeParams ? routePath : fromTemplate(routePath, routeParams)}
          {...rest}>
          <ListItemIcon
            sx={{
              color: (theme) => (isRouteSelected() ? theme.palette.text.primary : theme.palette.text.primary)
            }}>
            {icon || leftIcon || null}
          </ListItemIcon>
          <ListItemText
            sx={{
              color: (theme) => theme.palette.text.primary,
              fontWeight: (theme) => theme.typography.fontWeightBulky,
              fontFamily: 'League Spartan'
            }}
            disableTypography
            primary={this.renderText(text || primaryText, badge)}
          />
        </ListItemButton>
      </ListItem>
    );
  }
}

MenuRoute.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.element,
  leftIcon: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  routePath: PropTypes.string,
  routeParams: PropTypes.array,
  selectedRoutePath: PropTypes.string,
  primaryText: PropTypes.any,
  badge: PropTypes.object,
  nestedItems: PropTypes.any,
  badgeValue: PropTypes.number
};

export default MenuRoute;
