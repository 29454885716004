import { routePaths } from '../../routePaths';

/* eslint no-console:0 */
const tryToRedirect = (matchedRoute, redirectionRoute, replace) => {
  console.log(matchedRoute, redirectionRoute);
  if (matchedRoute !== redirectionRoute) {
    console.log(`Redirecting url: ${redirectionRoute}`);
    replace(redirectionRoute);
  }
};

export const redirect = (state, matchedRoute, replace, location) => {
  const { auth } = state;
  if (
    matchedRoute !== routePaths.passwordRecovery &&
    matchedRoute !== routePaths.passwordReset &&
    matchedRoute !== routePaths.accountActivation
  ) {
    localStorage.setItem('expectedRedirect', location ? location.pathname + location.search : matchedRoute);
    tryToRedirect(matchedRoute, auth.signedIn ? routePaths.home : routePaths.signIn, replace);
  }
};
