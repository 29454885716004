import { Box, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import React from 'react';
import DotsMenu from '../common/menu/DotsMenu';
import ActionConfirmationMenuItem from '../forms/ActionConfirmationMenuItem';
import EmptyState from '../common/EmptyState';

const WopfuTable = ({ rows = [], psychologicalData, handleEdit, handleArchive }) => {
  return rows.length > 0 ? (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontSize: 18 }}>Nazwa pliku</TableCell>
          <TableCell sx={{ fontSize: 18 }}>Data utworzenia</TableCell>
          <TableCell sx={{ fontSize: 18 }}>Status</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow
            onClick={() => handleEdit(row, true)}
            sx={{ '&:hover': { background: (theme) => theme.palette.background.hover, cursor: 'pointer' } }}
            key={row?.id || index}>
            <TableCell>
              <Box
                sx={{
                  fontSize: 18,
                  maxWidth: 300,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}>
                {row.name}
              </Box>
            </TableCell>
            <TableCell sx={{ fontSize: 18 }}>{moment(row?.creationDate).format('DD.MM.YYYY')}</TableCell>
            <TableCell sx={{ fontSize: 18 }}>Oczekuje</TableCell>
            <TableCell
              align="right"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}>
              <DotsMenu>
                {() => [
                  <MenuItem key="2" onClick={() => handleEdit(row, false)}>
                    Edytuj
                  </MenuItem>,
                  <ActionConfirmationMenuItem
                    key="1"
                    onItemClick={() => {}}
                    actionLabel="Archiwizuj"
                    confirmationText="Czy na pewno chcesz zarchiwizować wpis WOPFU?"
                    confirmationTitle="Archiwizuj wpis WOPFU"
                    confirmLabel="Archiwizuj"
                    onConfirm={() => {
                      handleArchive(row.id);
                    }}
                    onCancel={() => {}}
                  />
                  // <ActionConfirmationMenuItem
                  //   key="1"
                  //   onItemClick={() => {}}
                  //   actionLabel="Udostępnij rodzicowi"
                  //   confirmationText="Czy na pewno chcesz udostępnić plik?"
                  //   confirmationTitle="Udostępnij"
                  //   confirmLabel="Udostepnij"
                  //   onConfirm={() => {
                  //     console.log('udostępniono');
                  //   }}
                  //   onCancel={() => {}}
                  // />
                ]}
              </DotsMenu>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <EmptyState contrast message="Brak wielospecjalistycznych ocen poziomu funkcjonowania ucznia" />
  );
};

export default WopfuTable;
