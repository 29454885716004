/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { OverdueModel } from '../models/settlements/SettlementModels';

export default function overdueReducer(state = initialState.staffDashboard.overdue, action) {
  switch (action.type) {
    case types.LOAD_OVERDUE_PAYMENTS_SUCCESS: {
      return action.overdue.map((overdue) => new OverdueModel().assign(overdue));
    }
    default:
      return state;
  }
}
