import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import GroupDetailsSummary from '../common/groups/GroupDetailsSummary';
import GroupArchiveChildrenList from './GroupArchiveChildrenList';
import GroupArchiveTeachersList from './GroupArchiveTeachersList';
import ArchivalInfo from '../common/archivalInfo/ArchivalInfo';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { Box, Paper, Typography } from '@mui/material';

class GroupArchiveDetailsPage extends React.Component {
  render() {
    const { group, pupils } = this.props;
    return (
      <LoadingRenderWrapper>
        <>
          <ArchivalInfo
            archivalInfo={group.archivalInfo}
            sx={{ py: 1, display: 'flex', justifyContent: 'flex-end' }}
            contrast
          />
          <Paper sx={{ my: 2, p: 2 }}>
            <GroupDetailsSummary group={group} />
          </Paper>
          <Box sx={{ display: 'flex', alignContent: 'center', ml: 2 }}>
            <Typography
              variant="h6"
              sx={{
                mr: 1,
                display: 'flex',
                fontWeight: (theme) => theme.typography.fontWeightBold,
                color: (theme) => theme.palette.color.primary
              }}>
              Dzieci
            </Typography>
            <Typography
              variant="h6"
              sx={{
                display: 'flex',
                fontWeight: (theme) => theme.typography.fontWeightBold,
                color: (theme) => theme.palette.color.primary,
                opacity: 0.5
              }}>
              {group.enrolledChildren.length}
            </Typography>
          </Box>
          <Paper sx={{ mb: 4, p: 2 }}>
            <GroupArchiveChildrenList
              pupils={group.enrolledChildren}
              isPupilsArchived={(pupil) => !pupils.find((p) => p.id === pupil.id)}
            />
          </Paper>
          <Box sx={{ display: 'flex', alignContent: 'center', ml: 2 }}>
            <Typography
              variant="h6"
              sx={{
                mr: 1,
                display: 'flex',
                fontWeight: (theme) => theme.typography.fontWeightBold,
                color: (theme) => theme.palette.color.primary
              }}>
              Nauczyciele
            </Typography>
            <Typography
              variant="h6"
              sx={{
                display: 'flex',
                fontWeight: (theme) => theme.typography.fontWeightBold,
                color: (theme) => theme.palette.color.primary,
                opacity: 0.5
              }}>
              {group.associatedTeachers.length}
            </Typography>
          </Box>
          <Paper sx={{ p: 2 }}>
            <GroupArchiveTeachersList teachers={group.associatedTeachers} />
          </Paper>
        </>
      </LoadingRenderWrapper>
    );
  }
}

GroupArchiveDetailsPage.propTypes = {
  group: PropTypes.object.isRequired,
  pupils: PropTypes.array.isRequired,
  groupId: PropTypes.string
};

function mapStateToProps(state, ownProps) {
  const groupId = ownProps.params.id;
  return {
    groupId,
    group: state.archives.groupDetails,
    pupils: state.children
  };
}

export default connect(mapStateToProps)(GroupArchiveDetailsPage);
