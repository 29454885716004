import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import Grow from '@mui/material/Grow';

const SquareRadioButton = ({ value, label, icon, checked, disabled, ...rest }) => {
  return (
    <Grow in={true} sx={{ transformOrigin: '0 0 0', width: 100 }} {...(checked ? { timeout: 1000 } : {})}>
      <FormControlLabel
        control={
          <Radio
            disabled={disabled}
            disableRipple
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 45
              },
              color: (theme) => theme.palette.color.color25,
              '&.Mui-checked': { color: `${(theme) => theme.palette.color.color25} !important` }
            }}
          />
        }
        labelPlacement="bottom"
        label={label}
        disabled={disabled}
        value={value}
        {...rest}
        disableTypography
        sx={
          (checked && { animation: '$checked 0.3s' },
          {
            fontSize: 28
          })
        }
      />
    </Grow>
  );
};

SquareRadioButton.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SquareRadioButton;
