import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function loadingReducer(state = initialState.isLoading, action) {
  switch (action.type) {
    case types.PRELOAD_ON_NAVIGATION: {
      return true;
    }
    case types.PRELOAD_ON_NAVIGATION_SUCCESS: {
      return false;
    }
    case types.CLEANUP_DATA: {
      return false;
    }
    default:
      return state;
  }
}
