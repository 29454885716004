import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { browserHistory } from 'react-router';
import ReceiptIcon from '@mui/icons-material/Receipt';
import YearAndMonthOptionsSlider from '../common/calendar/YearAndMonthOptionsSlider';
import { routePaths, fromTemplate } from '../../routePaths';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import ChildrenSettlementsPage from './ChildrenSettlementsPage';
import * as settlementsActions from '../../actions/settlementsActions';
import { Box, Stack } from '@mui/material';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import GroupSwitch from '../common/groups/GroupSwitch';
import * as digitalDiaryEvents from '../../actions/digitalDiaryActions';

const ChildrenSettlementsNavigationPage = (props) => {
  const { isLoading } = props;
  const dispatch = useDispatch();

  const handleDateChanged = useCallback((year, month) => {
    // if (props.groupId) {
    //   browserHistory.push(fromTemplate(routePaths.settlementsGroup, [year, month, props.groupId]));
    // } else {
    browserHistory.push(fromTemplate(routePaths.settlements, [year, month]));
    // }
  }, []);

  const handleGroupChange = useCallback(
    (group) => {
      dispatch(digitalDiaryEvents.changeChoosenGroup(group));
      // browserHistory.push(fromTemplate(routePaths.settlementsGroup, [props.year, props.month, group.id]));
    },
    [dispatch]
  );

  // const selectedGroup2 = useMemo(() => {
  //   console.log('useMemo selected group', groupId, groups, archivedGroups);
  //   // if (groupId === 'all')
  //   //   return new GroupModel().assign({
  //   //     id: 'all',
  //   //     name: 'Wszystkie grupy',
  //   //     yearbook: 2023,
  //   //     isTemporary: false,
  //   //     //Nie podpinamy tutaj theme !!!!
  //   //     color: 'linear-gradient(#66A6FF, #89F7FE)',
  //   //     enrolledChildren: [],
  //   //     associatedTeachers: []
  //   //   });
  //   if (groupId && (groups.length > 0 || archivedGroups.length > 0)) {
  //     const foundGroups = groups.find((g) => g.id === groupId) || archivedGroups.find((g) => g.id === groupId) || null;
  //     console.log(foundGroups);
  //     return foundGroups ? foundGroups : null;
  //   }
  //   return null;
  // }, [archivedGroups, groupId, groups]);

  const settlementsForSelectedGroup = useMemo(() => {
    if (!props.chosenGroup) return [];
    if (props.chosenGroup.id === 'all') {
      return props.settlements;
    } else if (props.chosenGroup) return props.settlements.filter((s) => s.person.groupId === props.chosenGroup.id);
  }, [props.chosenGroup, props.settlements]);

  useEffect(() => {
    if (props.chosenGroup) {
      props.actions.resetSettlementsFilters();
    }
  }, [props.actions, props.chosenGroup]);

  // useEffect(() => {
  //   if (props.chosenGroup) {
  //     console.log(props.chosenGroup);
  //     dispatch(digitalDiaryEvents.changeChoosenGroup(props.chosenGroup));
  //   }
  // }, [dispatch, props.chosenGroup]);

  useEffect(() => {
  }, [isLoading]);

  useEffect(() => {
  }, [props.chosenGroup]);

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title="Rozliczenia dzieci" titleIcon={<ReceiptIcon />} />
        <ContentWrapper>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { xs: 'center', sm: 'end', md: 'center' },
              justifyContent: { xs: 'center', sm: 'end' }
            }}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <GroupSwitch showAllGroups onGroupChange={handleGroupChange} showArchivedGroups={true} />
            </Stack>
            <YearAndMonthOptionsSlider
              onSelected={handleDateChanged}
              defaultMonth={props.month}
              defaultYear={props.year}
            />
          </Box>
          {!!props.chosenGroup && (
            <ChildrenSettlementsPage
              // key={selectedGroup2.id}
              settlementsForSelectedGroup={settlementsForSelectedGroup}
              group={props.chosenGroup}
              year={props.year}
              month={props.month}
              settlementId={props.settlementId}
            />
          )}
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};

ChildrenSettlementsNavigationPage.propTypes = {
  groups: PropTypes.array.isRequired,
  settlements: PropTypes.array.isRequired,
  archivedGroups: PropTypes.array.isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  groupId: PropTypes.string,
  settlementId: PropTypes.string,
  actions: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  chosenGroup: PropTypes.any
};

function mapStateToProps(state, ownProps) {
  return {
    groups: state.groups,
    archivedGroups: state.archives.groups,
    year: ownProps.params.year,
    month: ownProps.params.month,
    groupId: ownProps.params.groupId,
    settlementId: ownProps.params.settlementId,
    isLoading: state.isLoading,
    settlements: state.settlements,
    chosenGroup: state.digitalDiary.choosenGroup
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settlementsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildrenSettlementsNavigationPage);
