import BaseModel from '../BaseModel';

export class ExpiringReliefModel extends BaseModel {
  constructor() {
    super();

    this.reliefExpiryDate = '';
    this.schemeReportType = '';
    this.child = {
      id: '',
      firstName: '',
      lastName: ''
    };
  }
}

export class GroupAttendanceSummaryModel extends BaseModel {
  constructor() {
    super();

    this.enrolled = 0;
    this.present = 0;
    this.group = {
      id: '',
      name: '',
      color: null
    };
  }
}
