import { BaseModelValidator } from '../BaseModelValidator';
import { check } from '../validation/validateHelper';
import { DietDetailsModel } from './DietModels';

export class DietModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = DietDetailsModel;

    this.constraints = {
      name: (value) => check.isEmpty(value) && 'Dieta musi mieć nazwę',
      meals: (value) => value.length === 0 && 'Dieta musi zawierać posiłki'
    };
  }
}
