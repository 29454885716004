import api from '../api/SpecialistNotesApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function loadAllSpecialists() {
  return (dispatch) => {
    return api
      .loadAllSpecialists()
      .then((response) => {
        dispatch({ type: types.LOAD_SPECIALISTS, response });
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać listy specjalistów`));
        return logger.error(error);
      });
  };
}

export function loadSpecialistsDetails(specialistId) {
  return (dispatch) => {
    return api
      .loadSpecialistDetails(specialistId)
      .then((response) => {
        dispatch({ type: types.LOAD_SPECIALIST_DETAILS, response });
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać listy specjalistów`));
        return logger.error(error);
      });
  };
}

export function loadAllSpecialistNotes() {
  return (dispatch) => {
    return api
      .loadAllSpecialistWorkNotes()
      .then((response) => {
        dispatch({ type: types.LOAD_SPECIALIST_NOTES, response });
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać zapisów pracy specjalisty`));
        return logger.error(error);
      });
  };
}

export function createSpecialistWorkPlan(data) {
  return (dispatch) => {
    return api
      .createSpecialistWorkPlan(data)
      .then((response) => {
        dispatch({ type: types.CREATE_SPECIALIST_NOTES_SUCCESS, id: response.id, data });
        dispatch(notificationActions.showSuccess(`Dodano zapis pracy specjalisty`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się dodać zapisu pracy specjalisty`));
        return logger.error(error);
      });
  };
}

export function deleteSpecialistWorkPlan(workId) {
  return (dispatch) => {
    return api
      .deleteSpecialistWorkPlan(workId)
      .then(() => {
        dispatch({ type: types.DELETE_SPECIALIST_NOTES_SUCCESS, id: workId });
        dispatch(notificationActions.showSuccess(`Usunięto zapis pracy specjalisty`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć zapisu pracy specjalisty`));
        return logger.error(error);
      });
  };
}

export function updateSpecialistWorkPlan(data, specialistWorkId) {
  return (dispatch) => {
    return api
      .updateSpecialistWorkPlan(data, specialistWorkId)
      .then(() => {
        dispatch({ type: types.MODIFY_SPECIALIST_NOTES_SUCCESS, id: specialistWorkId, data });
        dispatch(notificationActions.showSuccess(`Zmodyfikowano zapis pracy specjalisty`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zaktualizować zapisu pracy specjalisty`));
        return logger.error(error);
      });
  };
}

export function setSpecialistWorkDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.CREATE_SPECIALIST_NOTES_START });
  };
}

export function setSpecialistWorkDialogClosed() {
  return (dispatch) => {
    dispatch({ type: types.CREATE_SPECIALIST_NOTES_CANCEL });
  };
}

//                                                            Contact
export function newContactStart() {
  return { type: types.ADD_CONTACT_START };
}

export function newContactCancel() {
  return { type: types.ADD_CONTACT_CANCEL };
}

export function addContactToSpecialitNotes(contact, workId) {
  return (dispatch) => {
    return api
      .addContact(contact, workId)
      .then((response) => {
        dispatch({
          type: types.ADD_CONTACT_TO_SPECIALIST_NOTES_SUCCESS,
          newContact: { ...contact, id: response.contactId }
        });
        dispatch(notificationActions.showSuccess(`Dodano kontakt`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się dodać kontaktu`));
        return logger.error(error);
      });
  };
}

export function editSpecialistContact(data, workId) {
  return (dispatch) => {
    return api
      .editContact(data, workId)
      .then(() => {
        dispatch({ type: types.EDIT_SPECIALIST_CONTACT, data });
        dispatch(notificationActions.showSuccess(`Edytowano kontakt`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zapisać zmian kontaktu`));
        return logger.error(error);
      });
  };
}

export function deleteContact(contactId, workId) {
  return (dispatch) => {
    return api
      .deleteContact(contactId, workId)
      .then((response) => {
        dispatch({ type: types.ARCHIVE_SPECIALIST_CONTACT, id: contactId });
        dispatch(notificationActions.showSuccess(`Usunięto kontakt`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć kontaktu`));
        return logger.error(error);
      });
  };
}

//                                                  Notes

export function setNotesDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.SPECIALIST_NOTE_START });
  };
}

export function setNotesDialogClose() {
  return (dispatch) => {
    dispatch({ type: types.SPECIALIST_NOTE_CANCEL });
  };
}

export function createSpecialistNoteElement(data, specialistWorkRecordId) {
  return (dispatch) => {
    return api
      .createSpecialistWorkRecordElement(data, specialistWorkRecordId)
      .then((res) => {
        dispatch({ type: types.ADD_SPECIALIST_NOTE, data: { ...data, id: res.recordId } });
        dispatch(notificationActions.showSuccess(`Dodano element ${data.title}`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się dodać elementu`));
        return logger.error(error);
      });
  };
}

export function editSpecialistNoteElement(data, specialistWorkRecordId) {
  return (dispatch) => {
    return api
      .editSpecialistWorkRecordElement(data, specialistWorkRecordId)
      .then(() => {
        dispatch({ type: types.EDIT_SPECIALIST_NOTE, data });
        dispatch(notificationActions.showSuccess(`Edytowano element ${data.title}`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zaktualizować elementu`));
        return logger.error(error);
      });
  };
}

export function deleteSpecialistNoteElement(id, elementType, specialistWorkRecordId) {
  return (dispatch) => {
    return api
      .deleteSpecialistWorkRecordElement(id, elementType, specialistWorkRecordId)
      .then(() => {
        dispatch({ type: types.DELETE_SPECIALIST_NOTE, id });
        dispatch(notificationActions.showSuccess(`Usunięto element wybrany element`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zaktualizować elementu`));
        return logger.error(error);
      });
  };
}

//                                              Observations
export function setObservationsDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.SPECIALIST_OBSERVATION_START });
  };
}

export function setObservationsDialogClose() {
  return (dispatch) => {
    dispatch({ type: types.SPECIALIST_OBSERVATION_CANCEL });
  };
}

export function createSpecialistObservation(data, specialistWorkRecordId) {
  return (dispatch) => {
    return api
      .createSpecialistWorkRecordElement(data, specialistWorkRecordId)
      .then((res) => {
        dispatch({ type: types.ADD_SPECIALIST_OBSERVATION, data: { ...data, id: res.recordId } });
        dispatch(notificationActions.showSuccess(`Dodano element ${data.title}`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się dodać elementu`));
        return logger.error(error);
      });
  };
}

export function editSpecialistObservation(data, specialistWorkRecordId) {
  return (dispatch) => {
    return api
      .editSpecialistWorkRecordElement(data, specialistWorkRecordId)
      .then(() => {
        dispatch({ type: types.EDIT_SPECIALIST_OBSERVATION, data });
        dispatch(notificationActions.showSuccess(`Edytowano element ${data.title}`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zaktualizować elementu`));
        return logger.error(error);
      });
  };
}

export function deleteSpecialistObservation(id, elementType, specialistWorkRecordId) {
  return (dispatch) => {
    return api
      .deleteSpecialistWorkRecordElement(id, elementType, specialistWorkRecordId)
      .then(() => {
        dispatch({ type: types.DELETE_SPECIALIST_OBSERVATION, id });
        dispatch(notificationActions.showSuccess(`Usunięto element wybrany element`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się zaktualizować elementu`));
        return logger.error(error);
      });
  };
}
