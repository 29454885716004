const userRoles = Object.freeze({
  legalGuardian: 'legalGuardian',
  staffMemberTeacher: 'staffMemberTeacher',
  staffMemberPrincipal: 'staffMemberPrincipal',
  staffMemberAccountant: 'staffMemberAccountant',
  staffMemberCanteenWorker: 'staffMemberCanteenWorker',
  staffMemberSecretary: 'staffMemberSecretary',
  staffMemberDigitalDiary: 'staffMemberDigitalDiary',
  staffMemberTeacherSupport: 'staffMemberTeacherSupport',
  staffMemberSpecialist: 'staffMemberSpecialistTeacher',
  pinDevice: 'pinDevice'
});

module.exports = userRoles;
