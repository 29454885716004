import { MenuItem, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { fromTemplate, routePaths } from '../../routePaths';
import DotsMenu from '../common/menu/DotsMenu';
import { browserHistory } from 'react-router';
import moment from 'moment';
import styled from '@emotion/styled';
import { archivePsychologicalHelp } from '../../actions/psychologicalActions';
import { useDispatch } from 'react-redux';
import ActionConfirmationMenuItem from '../forms/ActionConfirmationMenuItem';

const StyledCell = styled(TableCell)({
  fontSize: 16,
  py: 0,
  px: 2,
  align: 'left'
});

const ChildToHelpRow = ({ child, openEditDialog }) => {
  const dispatch = useDispatch();
  const handleRecordDelete = () => {
    dispatch(archivePsychologicalHelp(child.id));
  };
  return (
    <TableRow
      sx={{ cursor: 'pointer', '&:hover': { background: (theme) => theme.palette.background.hover }, p: 0, m: 0 }}
      onClick={(e) => {
        e.preventDefault();
        browserHistory.push(fromTemplate(routePaths.psychologicalHelpChild, [child.childId, child.id]));
      }}>
      <StyledCell align="left">{child.childFirstName + ' ' + child.childLastName}</StyledCell>
      <StyledCell align="left">{moment(child.from).format('DD/MM/YYYY')}</StyledCell>
      <StyledCell align="left">{moment(child.to).format('DD/MM/YYYY')}</StyledCell>
      <StyledCell align="left">{child.reason}</StyledCell>
      <StyledCell
        align="left"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
        <DotsMenu>
          {() => [
            <MenuItem
              key={1}
              onClick={() => {
                openEditDialog(child);
              }}>
              Edytuj
            </MenuItem>,

            <ActionConfirmationMenuItem
              key={2}
              onItemClick={() => {}}
              actionLabel="Archiwizuj"
              confirmationText="Czy na pewno chcesz zarchiwizować schemat pomocy psychologiczno-pedagogicznej?"
              confirmationTitle="Archiwizuj schemat diagnoz"
              confirmLabel="Archiwizuj"
              onConfirm={() => handleRecordDelete()}
              onCancel={() => {}}
            />
          ]}
        </DotsMenu>
      </StyledCell>
    </TableRow>
  );
};
export default ChildToHelpRow;
