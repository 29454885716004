import PropTypes from 'prop-types';
import React from 'react';
import { Box, MenuItem } from '@mui/material';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import SelectField from '../../../common/SelectField';

const GroupPickerTemplate = ({ groupId, groups, onChange, legend, sx, errorText, errors }) => {
  return (
    <Box sx={{ ...sx }}>
      <FieldsetWrapper legend={`Pola dla generowania raportu${legend}`}>
        <SelectField
          errorText={errors ? errors.groupId : errorText}
          contrast
          sx={{ width: 'auto', overflow: 'hidden' }}
          floatingLabelText="Grupa"
          id="groupId"
          value={groupId}
          onChange={(event) => {
            onChange({ target: { name: 'groupId', value: event.target.value } });
          }}>
          {groups.map((group) => {
            return (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            );
          })}
        </SelectField>
      </FieldsetWrapper>
    </Box>
  );
};

GroupPickerTemplate.propTypes = {
  groupId: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  legend: PropTypes.string.isRequired,
  sx: PropTypes.object
};

export default GroupPickerTemplate;
