'use strict';

import PropTypes from 'prop-types';

import React from 'react';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import { CalculationStatus } from '../../../constants/calculationStatus';
import FilterSelectField from './FilterSelectField';
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';

const SettlementsFilters = ({
  selectedChargingType,
  handleChargingTypeChange,
  selectedStatus,
  handleStatusChange,
  sx
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent={isMobile ? 'center' : 'flex-end'}
      flexGrow={1}
      alignItems="center"
      sx={{ mt: isMobile ? 3 : 2, ...sx }}>
      <FilterSelectField
        sx={{ width: { xs: '100%', sm: 200 } }}
        label="Typ opłaty"
        id="paidType"
        onChange={handleChargingTypeChange}
        items={Object.values(ChargingReportTypes).filter((value) => value !== ChargingReportTypes.staffCatering)}
        selectedItem={selectedChargingType}
      />
      <FilterSelectField
        sx={{ my: 1, width: { xs: '100%', sm: 200 } }}
        label="Status"
        id="status"
        onChange={handleStatusChange}
        items={Object.values(CalculationStatus)}
        selectedItem={selectedStatus}
      />
    </Box>
  );
};

SettlementsFilters.propTypes = {
  selectedChargingType: PropTypes.string,
  selectedStatus: PropTypes.string,
  handleChargingTypeChange: PropTypes.func.isRequired,
  handleStatusChange: PropTypes.func.isRequired
};

export default SettlementsFilters;
