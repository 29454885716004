import PropTypes from 'prop-types';
import React from 'react';
import ChildDaycareCard from './ChildDaycareCard';
import ReportDaycareDialog from './dialogs/ReportDaycareDialog';
import { Box } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import _ from 'lodash';
import EmptyState from '../common/EmptyState';

class ChildrenList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showReportDialog: false,
      currentEntry: null,
      isProcessing: false
    };
    this.renderChildCard = this.renderChildCard.bind(this);
    this.renderReportDialog = this.renderReportDialog.bind(this);
    this.handleSaveReport = this.handleSaveReport.bind(this);
  }

  handleSaveReport(entry) {
    this.setState({ isProcessing: true }, () =>
      this.props.onSave(entry).then((error) => this.setState({ showReportDialog: !!error, isProcessing: false }))
    );
  }

  renderReportDialog() {
    if (!this.state.showReportDialog) return null;

    return (
      <ReportDaycareDialog
        title="Aktywność dziecka"
        date={this.state.currentEntry.date}
        pupilId={this.state.currentEntry.childId}
        pupilFirstName={this.state.currentEntry.firstName}
        pupilLastName={this.state.currentEntry.lastName}
        pupilPhotoUrl={this.state.currentEntry.photoUrl}
        isDialogOpen={this.state.showReportDialog}
        isProcessing={this.state.isProcessing}
        entry={this.state.currentEntry}
        onSave={this.handleSaveReport}
        onCancel={() => {
          this.setState({ showReportDialog: false, currentEntry: null });
        }}
        propertiesDefinition={this.props.propertiesDefinition}
      />
    );
  }

  renderChildCard(entry) {
    return (
      <ChildDaycareCard
        key={_.uniqueId()}
        daycareEntry={entry}
        onEdit={() => this.setState({ showReportDialog: true, currentEntry: entry })}
      />
    );
  }

  render() {
    return (
      <>
        {this.props.daycareEntries.length > 0 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Masonry columns={{ xs: 1, md: 2, xl: 3 }} spacing={4}>
              {this.props.daycareEntries.map((entry) => this.renderChildCard(entry))}
            </Masonry>
            {this.renderReportDialog()}
          </Box>
        ) : (
          <EmptyState isPaper contrast message="Brak wyników wyszukiwania" />
        )}
      </>
    );
  }
}

ChildrenList.propTypes = {
  daycareEntries: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  propertiesDefinition: PropTypes.object.isRequired
};

export default ChildrenList;
