import React from 'react';
import PostUpdateInfo from '../../../messageBoard/post/PostUpdateInfo';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { TimeModel } from '../../../../models/TimeModel';

const SpecialistCardPostHeader = ({ post, sx, noAvatar }) => {
  const { title, eventDate, eventStartTime, eventEndTime, author } = post;

  const startTime = new TimeModel().assign(eventStartTime);
  const endTime = new TimeModel().assign(eventEndTime);

  const hasStartTime = (time) => {
    return !!time && !time.isEmpty();
  };

  const generateTimeLabel = () => {
    return (
      <>
        {hasStartTime(startTime) &&
          `, godzina ${startTime?.hourLocal}:${moment(startTime?.minuteLocal, 'minutes').format('mm')}`}
        {hasStartTime(endTime) && ` do ${endTime?.hourLocal}:${moment(endTime?.minuteLocal, 'minutes').format('mm')}`}
      </>
    );
  };

  const generateDateLabel = () => {
    return eventDate && `${moment(eventDate).format('DD MMMM')}`;
  };

  return (
    <Box sx={{ py: 1, ...sx }}>
      {(author?.userFullName || author?.fullName) && <PostUpdateInfo post={post} noAvatar={noAvatar} />}
      {eventDate && (
        <Typography
          sx={{
            mb: 1,
            wordBreak: 'break-word',
            color: (theme) => theme.palette.text.primary,
            fontWeight: (theme) => theme.typography.fontWeightMedium
          }}>
          {generateDateLabel()}
          {generateTimeLabel()}
        </Typography>
      )}
      <Box>{title}</Box>
    </Box>
  );
};

export default SpecialistCardPostHeader;
