import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';

const SchoolYearInaugurationReport = ({ startDate }) => {
  return (
    <Box>
      <Typography variant="h6">Raport zostanie wygenerowany dla:</Typography>
      <Typography>{`Wrzesień ${moment(startDate).format('YYYY')}`}</Typography>
    </Box>
  );
};

SchoolYearInaugurationReport.propTypes = {
  startDate: PropTypes.string.isRequired
};

export default SchoolYearInaugurationReport;
