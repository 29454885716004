import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as groupActions from '../../actions/groupsActions';
import GroupsList from './GroupsList';
import { browserHistory } from 'react-router';
import { Paper, Grid, Button, Box } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import FilterBar from '../common/FilterBar';
import { routePaths } from '../../routePaths';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import EmptyState from '../common/EmptyState';

class GroupsPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      filteredGroups: []
    };
    this.redirectToAddGroupPage = this.redirectToAddGroupPage.bind(this);
    this.redirectToGroupArchivePage = this.redirectToGroupArchivePage.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);

    this.handleLoaded = this.handleLoaded.bind(this);
  }

  redirectToAddGroupPage() {
    browserHistory.push(routePaths.groupRegister);
  }

  redirectToGroupArchivePage() {
    browserHistory.push(routePaths.groupsArchive);
  }

  handleFilterChange(filteredList) {
    this.setState({
      filteredGroups: filteredList
    });
  }

  handleLoaded() {
    this.setState({
      filteredGroups: this.props.groups
    });
  }

  render() {
    return (
      <LoadingRenderWrapper onLoaded={this.handleLoaded}>
        <Box>
          <PageHeaderText title="Grupy" titleIcon={<GroupIcon />} />
          <ContentWrapper>
            <Grid container>
              <Grid item xs={12} md={6}>
                <FilterBar
                  sx={{ py: 3 }}
                  itemsToFilter={this.props.groups}
                  onFilter={this.handleFilterChange}
                  filter={(group, keyword) => group.name.toLowerCase().includes(keyword)}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" aria-label="Dodaj grupę" onClick={this.redirectToAddGroupPage}>
                  Dodaj grupę
                </Button>
              </Grid>
            </Grid>
            {this.state.filteredGroups.length === 0 ? (
              <EmptyState isPaper contrast message="Nie znaleziono grupy" />
            ) : (
              <Paper sx={{ my: 2, px: 2, py: 1 }}>
                <GroupsList groups={this.state.filteredGroups} />
              </Paper>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="outlined" aria-label="Pokaż zarchiwizowane" onClick={this.redirectToGroupArchivePage}>
                Pokaż zarchiwizowane
              </Button>
            </Box>
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

GroupsPage.propTypes = {
  groups: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    groups: state.groups
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(groupActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsPage);
