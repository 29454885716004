import makeStyles from '@mui/styles/makeStyles';

export const useSearchInputV2Styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: ({ isSelected, isMobile, isBorderBottom }) =>
      isBorderBottom && !isSelected && isMobile ? `2px solid ${theme.palette.color.color4} ` : undefined
  },
  input: {
    fontWeight: 500,
    fontSize: 14
  },
  divider: {}
}));
