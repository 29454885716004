import uuid from 'uuid';
import * as types from './actionTypes';
import api from '../api/PrivateMessages';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';
import { getSocketService } from '../middleware/socket/socketListenerMiddleware';
import moment from 'moment';

export function loadThreadsAsync(limit, offset) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_PRIVATE_THREADS, view: 'all' });
    return api
      .getThreads(limit, offset)
      .then((threads) => dispatch({ type: types.LOAD_PRIVATE_THREADS_SUCCESS, threads }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać prywatnych wiadomości'));
        return logger.error(error);
      });
  };
}

export function loadNextThreadsAsync(limit, offset) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_NEXT_PRIVATE_THREADS });
    return api
      .getThreads(limit, offset)
      .then((threads) => dispatch({ type: types.LOAD_NEXT_PRIVATE_THREADS_SUCCESS, threads }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać prywatnych wiadomości'));
        return logger.error(error);
      });
  };
}

export function loadMyThreads(limit = 999, offset = 0) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_MY_PRIVATE_THREADS, view: 'my' });
    return api
      .getMyThreads(limit, offset)
      .then((threads) => dispatch({ type: types.LOAD_MY_PRIVATE_THREADS_SUCCESS, threads }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać otrzymanych wiadomości'));
        return logger.error(error);
      });
  };
}

export function clearMessages() {
  return (dispatch) => dispatch({ type: types.PRIVATE_MESSAGES_CLEARED });
}

export function loadMessagesAsync(threadId, limit, offset, view) {
  return (dispatch, getState) => {
    const state = getState();
    const currentUserId = state.auth.userId;
    const thread = state.privateThreads.threads.find((t) => t.threadId === threadId);
    dispatch({ type: types.LOAD_PRIVATE_MESSAGES });
    return api
      .getMessages(threadId, limit, offset)
      .then((messages) =>
        dispatch({
          type: types.LOAD_PRIVATE_MESSAGES_SUCCESS,
          messages,
          threadId,
          view,
          thread,
          currentUserId
        })
      )
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać wiadomości'));
        return logger.error(error);
      });
  };
}

export function loadNextMessagesAsync(threadId, limit, offset, view) {
  return (dispatch, getState) => {
    const state = getState();
    const currentUserId = state.auth.userId;
    const thread = state.privateThreads.threads.find((t) => t.threadId === threadId);
    dispatch({ type: types.LOAD_NEXT_PRIVATE_MESSAGES });
    return api
      .getMessages(threadId, limit, offset)
      .then((messages) =>
        dispatch({ type: types.LOAD_NEXT_PRIVATE_MESSAGES_SUCCESS, messages, threadId, view, thread, currentUserId })
      )
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać wiadomości'));
        return logger.error(error);
      });
  };
}

export function loadUnreadMessagesCountAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_UNREAD_THREADS });
    return api
      .getUnreadThreadsByRecipient(10, 0)
      .then((result) => dispatch({ type: types.LOAD_UNREAD_THREADS_SUCCESS, ids: result }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać nieodczytanych wiadomości'));
        return logger.error(error);
      });
  };
}

export function loadMessagesCountAsync() {
  return (dispatch) => {
    return api
      .getUnreadThreadsByRecipient(10, 0)
      .then((result) => result)
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać nieodczytanych wiadomości'));
        return logger.error(error);
      });
  };
}

export function sendMessageAsync(message, thread, senderId) {
  const threadCopy = Object.assign({}, thread);
  return (dispatch) => {
    dispatch({ type: types.SEND_PRIVATE_MESSAGE });
    const id = uuid.v4();
    const sentAt = moment().toJSON();
    if (thread.threadId) {
      getSocketService().sendMessage(thread.threadId, id, message.content);
    } else {
      const threadId = uuid.v4();
      threadCopy.threadId = threadId;
      getSocketService().startThread(
        threadId,
        id,
        message.content,
        thread.participants.map((x) => x.id)
      );
    }

    dispatch({
      type: types.SEND_PRIVATE_MESSAGE_SUCCESS,
      thread: threadCopy,
      message: Object.assign({}, message, { id, senderId, sentAt })
    });
    return { threadId: threadCopy.threadId, messageId: id };
  };
}

export function sendMultiMessageAsync(message, threads, senderId) {
  const threadsCopy = JSON.parse(JSON.stringify(threads));
  return (dispatch) => {
    let id;
    const sentAt = moment().toJSON();
    threads.map((thread, index) => {
      dispatch({ type: types.SEND_PRIVATE_MESSAGE });
      id = uuid.v4();
      if (thread.threadId) {
        getSocketService().sendMessage(thread.threadId, id, message.content);
      } else {
        const threadId = uuid.v4();
        threadsCopy[index].threadId = threadId;
        getSocketService().startThread(
          threadId,
          id,
          message.content,
          thread.participants.map((x) => x.id)
        );
      }
    });
    dispatch({
      type: types.SEND_PRIVATE_MESSAGE_SUCCESS,
      thread: threadsCopy[0],
      message: Object.assign({}, message, { id, senderId, sentAt })
    });
    dispatch({
      type: types.SEND_PRIVATE_MULTI_MESSAGE_SUCCESS,
      threads: threadsCopy,
      message: Object.assign({}, message, { id, senderId, sentAt })
    });

    return { threadId: threadsCopy[0].threadId, messageId: id };
  };
}

export function updateMessageAsync(message, thread, senderId) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_PRIVATE_MESSAGE });

    getSocketService().updateMessage(thread.threadId, message.id, message.content);

    dispatch({
      type: types.UPDATE_PRIVATE_MESSAGE_SUCCESS,
      thread,
      message: Object.assign({}, message, {
        senderId,
        updatedAt: moment().toJSON(),
        threadId: thread.id,
        readByMe: true
      })
    });
    return { threadId: thread.threadId, messageId: message.id };
  };
}

export function deleteMessageAsync(message, thread) {
  const threadCopy = Object.assign({}, thread);
  return (dispatch) => {
    dispatch({ type: types.DELETE_PRIVATE_MESSAGE });

    getSocketService().deleteMessage(thread.threadId, message.id);

    dispatch({
      type: types.DELETE_PRIVATE_MESSAGE_SUCCESS,
      thread: threadCopy,
      message: Object.assign({}, message, { content: { text: 'Wiadomość została usunięta' } })
    });
    return { threadId: threadCopy.threadId, messageId: message.id };
  };
}

export function markMessageRead(threadId, messageTimestamp) {
  return (dispatch, getState) => {
    getSocketService().markRead(threadId, messageTimestamp);
    const currentUserId = getState().auth.userId;
    dispatch({ type: types.MARK_MESSAGE_READ_SUCCESS, threadId, messageTimestamp, currentUserId });
  };
}

export function notifyTyping(threadId) {
  return (dispatch) => {
    getSocketService().notifyTyping(threadId);
    dispatch({ type: types.NOTIFY_PM_TYPING_SUCCESS, threadId });
  };
}

export function notifyNotTyping(threadId) {
  return (dispatch) => {
    getSocketService().notifyNotTyping(threadId);
    dispatch({ type: types.NOTIFY_PM_NOT_TYPING_SUCCESS, threadId });
  };
}

export function loadPossibleRecipients() {
  return (dispatch) => {
    return api
      .getPossibleRecipients()
      .then((recipients) => dispatch({ type: types.LOAD_POSSIBLE_PM_RECIPIENTS_SUCCESS, recipients }));
  };
}
