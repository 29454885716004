export const InputFieldStyles = (theme) => ({
  textField: {
    width: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    color: `${theme.palette.color.contrast} !important`
  },
  input: {
    color: `${theme.palette.color.contrast} !important`,
    width: 130
  },
  root: {
    color: `${theme.palette.color.contrast} !important`,
    opacity: 1
  },
  underline: {
    borderBottom: 'none',
    '&:hover': {
      borderBottom: 'none'
    },
    '&::before': {
      borderBottom: `1px solid ${theme.palette.color.contrast} !important`
    },
    '&:hover::before': {
      borderBottom: `2px solid ${theme.palette.color.contrast} !important`
    }
  },

  underlineContrast: {
    borderBottom: 'none',
    '&:hover': {
      borderBottom: 'none'
    },
    '&::before': {
      borderBottom: `1px solid ${theme.palette.color.primary} !important`
    },
    '&:hover::before': {
      borderBottom: `2px solid ${theme.palette.color.primary} !important`
    }
  }
});
