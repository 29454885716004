import moment from 'moment';
import mime from 'mime';

export const userOwnerFilter = (isOwnerFile, array) => {
  return isOwnerFile ? array.filter((item) => item.isOwnByUser) : array;
};

export const dateFilter = (dateFrom, dateTo, array) => {
  if (
    (dateFrom && !dateTo && dateFrom.isValid()) ||
    (dateTo && !dateFrom && dateTo.isValid()) ||
    (dateTo && dateFrom && dateTo.isValid() && dateFrom.isValid())
  ) {
    return array.filter((item) => {
      const date = moment(item.uploadedAt);

      if (dateFrom && !dateTo) {
        return date.isAfter(dateFrom);
      }
      if (dateTo && !dateFrom) {
        return date.isBefore(dateTo);
      }
      return date.isBetween(dateFrom, dateTo);
    });
  }
  return array;
};

export const userRoleFilter = (selectedUserRoles, array) => {
  if (selectedUserRoles.length === 0) return array;
  const isShareToMoreThenOneUser = selectedUserRoles.some((item) => item.role === 'shareToMoreThenOneUser');
  return array.filter((item) => {
    const isRole = item.shareForUserRoles.some((assignedRole) =>
      selectedUserRoles.some((x) => x.role === assignedRole)
    );
    const isMoreThenOne = isShareToMoreThenOneUser ? item.shareForUserIds.length > 0 : true;
    if (isShareToMoreThenOneUser) return isMoreThenOne || isRole;
    return isRole;
  });
};

export const textFilter = (keyword, array) =>
  keyword.length ? array.filter((item) => item.name.toLowerCase().includes(keyword.toLowerCase())) : array;

export const extensionsFilter = (filesExtensions, array) => {
  const filesExtensionsArray = filesExtensions.reduce((acc, item) => {
    return item.others === true
      ? acc
      : [...acc, ...item.value.map((type) => (type === 'folder' ? type : mime.getType(type)))];
  }, []);

  const otherExtension = filesExtensions.find((item) => item.others === true);
  let excludedExtensionsArray = [];
  if (otherExtension) {
    excludedExtensionsArray = otherExtension.value.reduce((acc, item) => [...acc, mime.getType(item)], []);
  }

  return filesExtensionsArray.length > 0 || otherExtension
    ? array.filter(
        (item) =>
          filesExtensionsArray.includes(item.mimeType || item.type) ||
          (otherExtension && !excludedExtensionsArray.includes(item.mimeType))
      )
    : array;
};
