/*eslint indent:0*/
import initialState from './initial';
import { LOAD_SETTLEMENTS_SETTINGS_SUCCESS, UPDATE_SETTLEMENTS_SETTINGS_SUCCESS } from '../actions/actionTypes';
import { SettlementsSettingsModel } from '../models/configuration/settlementsSettings/SettlementsSettingsModel';

export default function settlementsSettings(state = initialState.configuration.settlementsSettings, action) {
  switch (action.type) {
    case LOAD_SETTLEMENTS_SETTINGS_SUCCESS: {
      return new SettlementsSettingsModel().assign(action.settings);
    }
    case UPDATE_SETTLEMENTS_SETTINGS_SUCCESS: {
      return new SettlementsSettingsModel().assign(action.settings);
    }
    default:
      return state;
  }
}
