import ApiBase, { ROOT_URL } from './ApiBase';
import { eventTypes } from '../constants/eventTypes';

class DigitalDiaryApi extends ApiBase {
  // EVENTS
  static getSomeData({ eventType, year, month, groupId }) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/digitalDiary/${eventType}/${year}-${month}/${groupId}`).type('application/json')
    );
  }

  static getSomeDataByDay({ eventType, eventDate, groupId }) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/digitalDiary/${eventType}/${eventDate}/${groupId}`).type('application/json')
    );
  }

  static addNewEvent({ eventData, eventType }) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/digitalDiary/${eventType}`).send(eventData).type('application/json')
    );
  }

  static updateEvent({ eventData }) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/digitalDiary/${eventData.id}`).send(eventData).type('application/json')
    );
  }

  static deleteEvent(eventId) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/digitalDiary/${eventId}/archive`).type('application/json')
    );
  }

  //INDIVIDUAL CLASSES

  static addCustomNewEvent(data, eventType) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/digitalDiary/${eventType}`).send(data).type('application/json')
    );
  }

  static editCustomEvent(data) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/digitalDiary/${data.id}`).send(data).type('application/json')
    );
  }

  static deleteCustomEvent(eventId) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/digitalDiary/${eventId}/archive`).type('application/json')
    );
  }

  // WORKPLANS
  static addNewWorkPlan(eventData) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/digitalDiary/${eventTypes.workPlan.type}`).send(eventData).type('application/json')
    );
  }

  static updateWorkPlan(eventData) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/digitalDiary/${eventData.id}`).send(eventData).type('application/json')
    );
  }

  static loadWorksPlans({ eventDate, group }) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/digitalDiary/${eventTypes.workPlan.type}/${eventDate}/${group}`).type('application/json')
    );
  }

  // DIAGNOSTIC SCHEMAS
  static loadDiagnosticSchemas() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/digitalDiary/schemas`).type('application/json'));
  }

  static createDiagnosticSchemas(schema) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/digitalDiary/schema`).send(schema).type('application/json')
    );
  }

  static updateDiagnosticSchemas(schema) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/digitalDiary/${schema.id}`).send(schema).type('application/json')
    );
  }

  static removeDiagnosticSchemas(schemaId) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/digitalDiary/${schemaId}/archive`).type('application/json')
    );
  }

  static cloneEvents(someData) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/digitalDiary/clone`).send(someData).type('application/json')
    );
  }

  // static eventsFilter(date, eventType, filterText, groupId) {
  //   return super.toPromise((agent) =>
  //     agent
  //       .get(`${ROOT_URL}/digitalDiary/byFilter/${eventType}/${date}`)
  //       .query({ textFilter: filterText, group: groupId })
  //       .type('application/json')
  //   );
  // }

  static eventsFilter(query, date, eventType) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/digitalDiary/byFilter/${eventType}/${date}`).query(query).type('application/json')
    );
  }
}

export default DigitalDiaryApi;
