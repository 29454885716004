/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function psychologicalChildrenListUIReducer(state = initialState.psychologicalChildrenListUI, action) {
  switch (action.type) {
    case types.ADD_PSYCHOLOGICAL_HELP_START: {
      return UiModel.start();
    }
    case types.ADD_PSYCHOLOGICAL_HELP_CANCEL: {
      return UiModel.cancel();
    }
    case types.ADD_PSYCHOLOGICAL_HELP_SUBMIT: {
      return UiModel.submit();
    }
    case types.EDIT_PSYCHOLOGICAL_HELP:
    case types.ADD_PSYCHOLOGICAL_HELP: {
      return UiModel.success();
    }
    case types.ADD_PSYCHOLOGICAL_HELP_FAILURE: {
      return UiModel.failure();
    }
    default:
      return state;
  }
}
