import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const SummaryCheckbox = ({ label, variant, disabled, value, sx, onClick, checkboxStyles }) => {
  return (
    <FormControlLabel
      sx={{ ...sx }}
      control={
        <Checkbox onClick={onClick || undefined} disabled={disabled} checked={value} sx={{ ...checkboxStyles }} />
      }
      label={label}
    />
  );
};

SummaryCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default SummaryCheckbox;
