import PropTypes from 'prop-types';
import React from 'react';
import PersonAvatar from '../../forms/PersonAvatar';

import { Badge, Box, ListItem, ListItemAvatar, ListItemText, Tooltip } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { convertThreadLastMessage } from '../../common/messageFunctions';

const ThreadHeader = ({ thread, user, onSelect }) => {
  const theme = useTheme();

  const useStyles = makeStyles(() => ({
    unread: {
      fontWeight: 900,
      color: theme.palette.color.color22
    },
    secondaryText: {
      fontSize: 14,
      lineHeight: '18px',
      height: '18px',
      margin: '4px 0px 0px',
      color: theme.palette.color.color22,

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  }));
  const classes = useStyles();
  return (
    <ListItem
      sx={{ pl: 1 }}
      button
      component="li"
      key={user.id}
      onClick={() => onSelect(thread)}
      aria-label={`Wybierz rozmowę do ${user.fullName}`}>
      <Tooltip title={user.fullName}>
        <ListItemAvatar sx={{ mr: 0.5 }}>
          <Badge badgeContent={!thread.readByMe ? '!' : null} color={'primary'}>
            <PersonAvatar
              sx={{ width: 50, height: 50 }}
              initials
              url={user.photoUrl}
              fullName={user.fullName}
              alt={user.fullName}
            />
          </Badge>
        </ListItemAvatar>
      </Tooltip>
      <ListItemText
        disableTypography
        primary={<Box className={`name${!thread.readByMe ? ` ` + classes.unread : ''}`}>{user.fullName}</Box>}
        secondary={
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box className={`last-message${!thread.readByMe ? ` ` + classes.unread : ''} ` + classes.secondaryText}>
              {thread.lastMessage && thread.lastMessage.content
                ? convertThreadLastMessage(thread.lastMessage.content.text)
                : convertThreadLastMessage(thread.lastMessageContent.text)}
            </Box>
          </Box>
        }
      />
    </ListItem>
  );
};

ThreadHeader.propTypes = {
  thread: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default ThreadHeader;
