import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { TimeModel } from '../../../models/TimeModel';
import ValidationSummary, { validationTypes } from '../../common/validation/ValidationSummary';

const ExceededWorkingHoursWarningWrapper = ({ workingHours, droppedOffAt, pickedUpAt, unitConfigFlag }) => {
  if (!droppedOffAt && !pickedUpAt) {
    return null;
  }
  if (workingHours.from.isEmpty() && workingHours.to.isEmpty()) {
    return null;
  }

  const generateExceededOpenMessage = (time, timeDescription) => {
    if (!time || time.isEmpty()) return null;
    if (time.toDate() < workingHours.from.toDate()) {
      return `Wprowadzona godzina ${timeDescription} jest wcześniejsza niż godzina otwarcia placówki (${workingHours.from.toString()})`;
    }
    return null;
  };

  const generateExceededCloseMessage = (time, timeDescription) => {
    if (!time || time.isEmpty()) return null;
    if (time.toDate() > workingHours.to.toDate()) {
      return `Wprowadzona godzina ${timeDescription} jest późniejsza niż godzina zamknięcia placówki (${workingHours.to.toString()})`;
    }

    return null;
  };
  const generatePickupDropoutError = () => {
    if (pickedUpAt.toDate() <= droppedOffAt.toDate()) {
      return `Wprowadzona godzina odbioru dziecka jest wcześniejsza niż godzina pozostawienia go w placówce!`;
    }
    return null;
  };
  const messages = [
    unitConfigFlag && generatePickupDropoutError(),
    generateExceededOpenMessage(droppedOffAt, 'przyjęcia'),
    generateExceededCloseMessage(droppedOffAt, 'przyjęcia'),
    generateExceededOpenMessage(pickedUpAt, 'odbioru'),
    generateExceededCloseMessage(pickedUpAt, 'odbioru')
  ].filter((x) => x);

  if (messages.length) {
    return <ValidationSummary title="Uwaga" messages={messages} type={validationTypes.warning} />;
  }

  return null;
};

ExceededWorkingHoursWarningWrapper.propTypes = {
  workingHours: PropTypes.object.isRequired,
  droppedOffAt: PropTypes.instanceOf(TimeModel),
  pickedUpAt: PropTypes.instanceOf(TimeModel),
  unitConfigFlag: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    unitConfigFlag: state.configuration.unit.noHoursAttendancesEnabled,
    workingHours: state.configuration.unit.workingHours
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ExceededWorkingHoursWarningWrapper);
