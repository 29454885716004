import makeStyles from '@mui/styles/makeStyles';

export const useSharePersonListComponentStyles = makeStyles((theme) => ({
  wrapper: ({ color }) => ({
    marginBottom: 15,
    width: '100%',
    borderRadius: 20,
    border: `2px solid ${color}`,
    overflow: 'hidden'
  }),
  actionArea: ({ isOpen }) => ({
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: isOpen ? 0 : 20,
    borderBottomRightRadius: isOpen ? 0 : 20
  }),
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8
  },
  titleWrapper: { marginLeft: 10, fontSize: 14 },
  numberWrapper: {
    marginLeft: 15,
    width: 24,
    height: 24,
    fontSize: 12
  },
  cardContentInside: {
    borderTop: ({ color }) => `1px solid ${color}`
  },
  divider: ({ color }) => ({
    borderTop: `2px solid ${color}`,
    marginTop: '0.90rem'
  })
}));
