import React from 'react';
import { Box, Button, IconButton, Paper, Typography } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import GooglePlayBadge from '../../img/mobileImages/google-play-badge.svg';
import AppStoreBadge from '../../img/mobileImages/ios-badge.svg';
import ContentWrapper from '../common/contentWrapper';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import PropTypes from 'prop-types';
import PageHeaderText from '../common/pageHeader/PageHeaderText';

import img1 from '../../img/mobileImages/1.jpg';
import img2 from '../../img/mobileImages/2.jpg';
import img3 from '../../img/mobileImages/3.jpg';
import img4 from '../../img/mobileImages/4.jpg';
import img5 from '../../img/mobileImages/5.jpg';

const MobileDownloadPage = () => {
  const images = [
    {
      id: 0,
      src: img1
    },
    {
      id: 1,
      src: img2
    },
    {
      id: 2,
      src: img3
    },
    {
      id: 3,
      src: img4
    },
    {
      id: 4,
      src: img5
    }
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const CustomRight = ({ onClick }) => (
    <IconButton
      disableTouchRipple
      onClick={onClick}
      sx={{
        m: 4,
        border: 0,
        color: (theme) => theme.palette.color.contrast,
        right: -50,
        position: 'absolute',
        top: '25%',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }}>
      <ArrowForwardIosOutlinedIcon sx={{ fontSize: 40 }} />
    </IconButton>
  );
  const CustomLeft = ({ onClick }) => (
    <IconButton
      disableTouchRipple
      onClick={onClick}
      sx={{
        m: 4,
        border: 0,
        color: (theme) => theme.palette.color.contrast,
        left: -50,
        position: 'absolute',
        top: '25%',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }}>
      <ArrowBackIosNewOutlinedIcon sx={{ fontSize: 40 }} />
    </IconButton>
  );

  const handleOpenStore = (store) => {
    if (store === 'android') {
      window.open(
        'https://play.google.com/store/apps/details?id=com.softwarehub.for_parents&hl=pl&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
        '_blank'
      );
    }
    if (store === 'ios') {
      window.open('https://apps.apple.com/pl/app/4parents/id1191079838?itsct=apps_box_badge&amp;itscg=30200', '_blank');
    }
  };

  return (
    <Box>
      <PageHeaderText title="Pobierz nową aplikację mobilną!" titleIcon={<SmartphoneIcon />} />
      <ContentWrapper>
        <Paper sx={{ p: 3, mb: 5 }}>
          <Box sx={{ width: '90%', my: 0, mx: 'auto' }}>
            <Typography
              variant="h5"
              sx={{ textAlign: 'center', lineHeight: '1.4', fontWeight: (theme) => theme.typography.fontWeightLarge }}>
              Nowa aplikacja mobilna, dzięki której sprawdzanie informacji odnośnie pobytu dziecka w placówce stało się
              jeszcze prostsze, jest już dostępna na smartfony zarówno z systemem Android jak i iOS!
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 2
            }}>
            <Button onClick={() => handleOpenStore('android')} sx={{ width: '200px', p: 3 }}>
              <img style={{ width: '100%' }} alt="Get it on Google Play" src={GooglePlayBadge} />
            </Button>
            <Button onClick={() => handleOpenStore('ios')} sx={{ width: '192px', p: 3 }}>
              <img style={{ width: '100%' }} alt="Download on the App Store" src={AppStoreBadge} />
            </Button>
          </Box>

          <Box
            sx={{
              width: { xs: 260, sm: 360, md: 540, xl: 860 },
              my: 0,
              mx: 'auto'
            }}>
            <Carousel
              autoPlay={true}
              arrows
              responsive={responsive}
              customLeftArrow={<CustomLeft />}
              customRightArrow={<CustomRight />}
              removeArrowOnDeviceType={['tablet', 'mobile']}
              showDots={true}>
              {images.map((image) => (
                <Box key={image.id} sx={{ width: '100%', textAlign: 'center' }}>
                  <img
                    src={image.src}
                    alt={image.src}
                    style={{ width: '70%', borderRadius: '20px', marginBottom: '40px' }}
                  />
                </Box>
              ))}
            </Carousel>
          </Box>

          <Typography variant="h6" sx={{ p: 4, lineHeight: '1.4', textAlign: 'justify' }}>
            Aplikacja nie tylko umożliwia zobaczenie informacji takich jak historię obecności czy aktywność dziecka,
            lecz również sprawdzanie opłat, czy też ogłoszeń udostępnionych przez przedszkole. Kolejną funkcjonalnością
            jest możliwość zobaczenia galerii, gdzie będą trafiały wszystkie zdjęcia udostępnione dla użytkownika oraz
            kalendarz, w którym widoczne będą wszystkie nadchodzące wydarzenia. Sprawdź już teraz!
          </Typography>
        </Paper>
      </ContentWrapper>
    </Box>
  );
};

MobileDownloadPage.propTypes = {
  onClick: PropTypes.func
};

export default MobileDownloadPage;
