import PropTypes from 'prop-types';
import React from 'react';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Box } from '@mui/material';

const large = { height: 12, width: 12, marginLeft: 4 };
const small = { height: 10, width: 10, marginLeft: 4 };

const ReadIndicatorIcon = ({ isRead, isLarge, isContrastColor = false }) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    {isRead && (
      <FiberManualRecordIcon
        style={
          isLarge
            ? {
                ...large,
                color: (theme) => (!isContrastColor ? theme.palette.color.color7 : theme.palette.color.color8)
              }
            : {
                ...small,
                color: (theme) => (!isContrastColor ? theme.palette.color.color7 : theme.palette.color.color8)
              }
        }
      />
    )}
    {!isRead && (
      <PanoramaFishEyeIcon
        style={
          isLarge
            ? {
                ...large,
                color: !isContrastColor ? (theme) => theme.palette.grey[400] : (theme) => theme.palette.grey[900]
              }
            : {
                ...small,
                color: !isContrastColor ? (theme) => theme.palette.grey[400] : (theme) => theme.palette.grey[900]
              }
        }
      />
    )}
  </Box>
);

ReadIndicatorIcon.propTypes = {
  isRead: PropTypes.bool,
  isLarge: PropTypes.bool,
  isContrastColor: PropTypes.bool
};

export default ReadIndicatorIcon;
