import { Box, ClickAwayListener, Fade, Paper, Popper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => {
  const color = theme.palette.background.default;
  return {
    popoverRoot: {
      background: color,
      maxWidth: 1000,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    popper: {
      zIndex: theme.zIndex.tooltip,
      marginBottom: '0.51em',
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.71em',
        marginLeft: 0,
        marginRight: 0,
        '&::before': {
          transformOrigin: '0 100%'
        }
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.71em',
        marginLeft: 2,
        marginRight: 4,
        '&::before': {
          transformOrigin: '100% 0'
        }
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.71em',
        height: '1em',
        width: '0.71em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '100% 100%'
        }
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.71em',
        height: '1em',
        width: '0.71em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '0 0'
        }
      }
    },
    arrow: {
      overflow: 'hidden',
      position: 'absolute',
      width: '1em',
      height: '0.71em',
      boxSizing: 'border-box',
      color,
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        boxShadow: theme.shadows[1],
        background: 'currentColor',
        transform: 'rotate(45deg)'
      }
    }
  };
});

const RichTooltip = ({
  placement = 'top',
  arrow = true,
  open,
  onClose = () => {},
  content,
  children,
  PopperStyles,
  ArrowStyles
}) => {
  const classes = useStyles();
  const arrowRef = useRef(null);
  const [childNode, setChildNode] = useState(null);

  return (
    <>
      {React.cloneElement(children, { ...children.props, ref: setChildNode })}
      <Popper
        open={open && Boolean(childNode)}
        anchorEl={childNode}
        placement={placement}
        sx={PopperStyles}
        transition
        className={classes.popper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box>
              <Paper>
                <ClickAwayListener onClickAway={onClose}>
                  <Paper className={classes.popoverRoot}>
                    {/* {arrow ? <span className={classes.arrow} style={ArrowStyles} ref={arrowRef} /> : null} */}
                    {content}
                  </Paper>
                </ClickAwayListener>
              </Paper>
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  );
};

RichTooltip.propTypes = {
  placement: PropTypes.string,
  arrow: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  content: PropTypes.node,
  children: PropTypes.node,
  PopperStyles: PropTypes.object,
  ArrowStyles: PropTypes.object
};

export default RichTooltip;
