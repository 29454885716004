import SmartphoneIcon from '@mui/icons-material/Smartphone';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import MessageBoardIcon from '@mui/icons-material/SmsFailedOutlined';
import EventsCalendarIcon from '@mui/icons-material/Event';
import BookIcon from '@mui/icons-material/Book';
import ChargingSchemeIcon from '@mui/icons-material/AttachMoney';
import ManualIcon from '@mui/icons-material/DescriptionOutlined';
import PrivateMessagesIcon from '@mui/icons-material/EmailOutlined';
import ChangeLogIcon from '@mui/icons-material/VerifiedOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import PhotoLibraryIcon from '@mui/icons-material/CollectionsOutlined';
import StorageIcon from '@mui/icons-material/SaveOutlined';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import config from '../../../config/config';
import { routePaths } from '../../../routePaths';
import MenuRoute from './MenuRoute';
import moment from 'moment';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import UnreadThreadsBadgeWrapper from '../../common/privateMessages/UnreadThreadsBadgeWrapper';
import insuranceLogoUrl from '../../../img/logo_b_1280.svg';
import insuranceLogoWhiteUrl from '../../../img/logo_b_1280_white.svg';
import InsuranceInfoDialog from '../../insuranceInfo/InsuranceInfoDialog';
import { useThemeMode } from '../../../hooks/useThemeMode';
import { themeTypes } from '../../../styles/appTheme';
import { useSelector } from 'react-redux';

const LegalGuardianMenu = ({ currentRoute, onRouteSelect, insurance }) => {
  const themeMode = useThemeMode();
  const [insuranceDialog, setInsuranceDialog] = useState(false);
  const year = moment().format('YYYY');
  const month = moment().format('MM');
  let constKey = 0;
  const getNextKey = () => constKey++;
  const rest = { selectedRoutePath: currentRoute, onClick: onRouteSelect };
  const theme = useTheme();
  const isContrastColor = useSelector((state) => state.theme);
  return (
    <List>
      <MenuRoute
        text="Pulpit"
        icon={
          <HomeIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
          />
        }
        routePath={routePaths.home}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Wiadomości"
        icon={
          <PrivateMessagesIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color3 }}
          />
        }
        routePath={routePaths.guardianPrivateMessages}
        key={getNextKey()}
        badge={<UnreadThreadsBadgeWrapper />}
        {...rest}
      />
      <MenuRoute
        text="Ogłoszenia"
        icon={
          <MessageBoardIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color4 }}
          />
        }
        routePath={routePaths.messageBoard}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Kalendarz wydarzeń"
        icon={
          <EventsCalendarIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
          />
        }
        routePath={routePaths.eventsCalendar}
        routeParams={[year, month]}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Dysk wirtualny"
        icon={
          <StorageIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color21 }}
          />
        }
        routePath={routePaths.virtualDisc}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Galeria"
        icon={
          <PhotoLibraryIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color25 }}
          />
        }
        routePath={routePaths.gallery}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Opłaty"
        icon={
          <ChargingSchemeIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.warning }}
          />
        }
        routePath={routePaths.guardianDues}
        routeParams={[moment().format('YYYY')]}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Wnioski"
        icon={
          <ContentPasteOutlinedIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color4 }}
          />
        }
        routePath={routePaths.guardianApplications}
        key={getNextKey()}
        {...rest}
      />
      {insurance && insurance.isActive && (
        <ListItem key={getNextKey()} component="li">
          <ListItemButton onClick={() => setInsuranceDialog(true)}>
            <img
              src={themeMode !== themeTypes.dark.value ? insuranceLogoUrl : insuranceLogoWhiteUrl}
              width="70%"
              alt="Ubezpieczenia"
            />
          </ListItemButton>
        </ListItem>
      )}
      <Divider />
      <MenuRoute
        text="Co nowego?"
        icon={
          <ChangeLogIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color6 }}
          />
        }
        routePath={routePaths.changeLog}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Sprawdź aplikację mobilną!"
        icon={
          <SmartphoneIcon
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color7 }}
          />
        }
        routePath={routePaths.mobileDownload}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Blog 4Parents"
        icon={
          <BookIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color6 }}
          />
        }
        key={getNextKey()}
        component="a"
        href="https://4parents.education/blog/"
        target="_blank"
      />
      <ListItem
        button
        key={getNextKey()}
        onClick={() => window.open(config.guardianManualUrl, '_blank')}
        aria-label="Otwórz instrukcję obsługi">
        <ListItemIcon>
          <ManualIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
          />
        </ListItemIcon>
        <ListItemText
          sx={{ '& .MuiListItemText-primary': { fontWeight: (theme) => theme.typography.fontWeightBold } }}
          primary="Instrukcja obsługi"
        />{' '}
      </ListItem>
      {insuranceDialog && (
        <InsuranceInfoDialog insurance={insurance} onClose={() => setInsuranceDialog(false)} open={insuranceDialog} />
      )}
    </List>
  );
};

LegalGuardianMenu.propTypes = {
  currentRoute: PropTypes.string.isRequired,
  onRouteSelect: PropTypes.func.isRequired,
  insurance: PropTypes.object
};

export default LegalGuardianMenu;
