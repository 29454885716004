import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import { routePaths } from '../../../routePaths';
import { caseInsensitiveComparator } from '../../../utils/polishSort';
import FaceIcon from '@mui/icons-material/Face';
import EmptyState from '../../common/EmptyState';
import Header from '../../children/components/Header/Header';
import StudentList from '../list/StudentList';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import { Box, Button, Pagination, Paper } from '@mui/material';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import ContentWrapper from '../../common/contentWrapper';

const groupChildrenByLastName = (children) => {
  return _.chain(children)
    .sort((a, b) => caseInsensitiveComparator(a.lastName, b.lastName))
    .value();
};

const StudentContainer = () => {
  const pupils = useSelector((state) => state.children);
  const auth = useSelector((state) => state.auth);
  const isAllowed = useSelector((state) => state.auth.isAllowed.bind(auth));
  const [filteredPupils, setFilteredPupils] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const countOfPagination = Math.ceil(filteredPupils.length / rowsPerPage);

  useEffect(() => {
    setFilteredPupils(groupChildrenByLastName(pupils));
  }, []);

  const redirectToCreatePage = () => {
    browserHistory.push(routePaths.studentRegister);
  };

  const redirectToChildrenArchivePage = () => {
    browserHistory.push(routePaths.studentArchive);
  };

  const handleFilterChange = (filteredList) => {
    setPage(0);
    setFilteredPupils(groupChildrenByLastName(filteredList));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);

    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title="Rejestr uczniów" titleIcon={<FaceIcon />} />
        <ContentWrapper>
          <Header
            redirectToCreatePage={redirectToCreatePage}
            itemsToFilter={pupils}
            onFilter={handleFilterChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            isAllowed={isAllowed}
          />
          <Paper>
            {filteredPupils.length > 0 && (
              <StudentList students={filteredPupils.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} />
            )}
            {filteredPupils.length === 0 && (
              <EmptyState
                contrast
                message={pupils.length === 0 ? 'Nie zapisano żadnych uczniów' : 'Brak wyników wyszukiwania'}
              />
            )}
            {filteredPupils.length > 0 && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Pagination sx={{ p: 1 }} count={countOfPagination} page={page + 1} onChange={handleChangePage} />
              </Box>
            )}
          </Paper>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" aria-label="Pokaż archiwalne" onClick={redirectToChildrenArchivePage}>
              Pokaż archiwalne
            </Button>
          </Box>
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};

export default StudentContainer;
