import React from 'react';
import PropTypes from 'prop-types';
import IconButtonPipe from '../../../common/IconButtonPipe';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileSpecificIcon from '../../../common/FileSpecificIcon/FileSpecificIcon';
import DoneIcon from '@mui/icons-material/Done';
import LinkIcon from '@mui/icons-material/Link';
import { Box, Chip, CircularProgress, Grid, ListItem, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import userRoles from '../../../../constants/userRoles';

const SingleFile = ({
  item,
  isSelected,
  onSelectFile,
  onSingleDownload,
  isNew,
  onCopyLink,
  handleOnDrag,
  draggedItem
}) => {
  const { isProcessing } = useSelector((state) => state.virtualDiscFolderUi.action);
  const userRole = useSelector((state) => state.auth.userRole);
  const isManagement = userRole !== userRoles.legalGuardian && userRole !== userRoles.staffMemberCanteenWorker;

  const { mimeType, fileUrl, name } = item;

  const onDrgStart = (e) => {
    handleOnDrag(e, item);
  };
  const keyDownFunction = (e) => {
    if (e.keyCode === 32) onSelectFile(e);
  };
  return (
    <ListItem sx={{ p: '1px' }}>
      <Box
        tabIndex={0}
        aria-label={`Zaznaczalny plik ${item.name}`}
        role="checkbox"
        aria-checked={isSelected}
        sx={{
          minHeight: 184,
          height: '100%',
          px: '15px',
          py: '7px',
          cursor: isManagement ? 'grab' : 'default',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          border: (theme) => `2px solid ${isSelected ? theme.palette.color.color19 : theme.palette.background.color3}`,
          width: '100%',
          borderRadius: '5px',
          overflow: 'hidden',
          background: (theme) => theme.palette.background.color3,
          '&:hover': {
            background: (theme) => theme.palette.background.hover
          }
        }}
        onClick={onSelectFile}
        onKeyDown={keyDownFunction}
        onDoubleClick={() => onSingleDownload(fileUrl, name, mimeType)}
        onDragStart={isManagement ? onDrgStart : () => {}}
        draggable={isManagement}>
        <Grid container justifyContent="space-between" alignItems="center">
          <IconButtonPipe
            tooltip={`Skopiuj link do pliku ${name}`}
            onClick={(e) => {
              onCopyLink(fileUrl, name, mimeType);
              e.stopPropagation();
            }}>
            <LinkIcon sx={{ color: (theme) => theme.palette.color.color22 }} />
          </IconButtonPipe>
          <IconButtonPipe
            onClick={(e) => {
              e.stopPropagation();
              onSingleDownload(fileUrl, name, mimeType);
            }}
            tooltip={`Pobierz plik ${name}`}>
            <FileDownloadOutlinedIcon sx={{ color: (theme) => theme.palette.color.color22 }} />
          </IconButtonPipe>
        </Grid>

        {isProcessing && draggedItem?.id === item.id ? (
          <CircularProgress />
        ) : (
          <FileSpecificIcon type={mimeType} style={{ fontSize: 60, marginBottom: isNew ? 0 : 10 }} />
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 'auto' }}>
          {isNew && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Chip
                sx={{
                  '& .MuiChip-label': {
                    pt: 0.5
                  },
                  width: 80,
                  my: 1,
                  color: (theme) => theme.palette.color.color25,
                  border: (theme) => `2px solid ${theme.palette.color.color25}`
                }}
                icon={<DoneIcon color="inherit" />}
                label={<Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>NOWY</Typography>}
                size="small"
                variant="outlined"
              />
            </Box>
          )}
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: (theme) => theme.typography.fontWeightMedium,
              mx: 'auto',
              textAlign: 'center',
              display: '-webkit-box',
              webkitLineClamp: '2',
              webkitBoxOrient: 'vertical',
              textOverflow: 'ellipsis',
              wordBreak: 'break-all'
            }}>
            {name}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
};

SingleFile.propTypes = {
  item: PropTypes.object.isRequired,
  fileUrl: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  mimeType: PropTypes.string,
  name: PropTypes.string.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  onSingleDownload: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
  onCopyLink: PropTypes.func.isRequired
};
export default React.memo(SingleFile);
