import PropTypes from 'prop-types';
import React from 'react';
import PersonAvatar from '../../forms/PersonAvatar';
import DialogForm from '../../forms/DialogForm';
import ReportDaycareForm from './ReportDaycareForm';
import { Box, Typography } from '@mui/material';
import ChildCareIcon from '@mui/icons-material/ChildCare';

class ReportDaycareDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entry: {
        id: this.props.entry.id,
        childId: this.props.pupilId,
        date: this.props.date,
        mood: this.props.entry.mood,
        note: this.props.entry.note,
        poos: this.props.entry.poos,
        meals: this.props.entry.meals,
        mealsScale: this.props.entry.mealsScale,
        mealsNote: this.props.entry.mealsNote || '',

        nappies: this.props.entry.nappies,
        sleepTimeInMinutes: this.props.entry.sleepTimeInMinutes
      },
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    // this.handleStringValidate = this.handleStringValidate.bind(this);
  }

  // handleStringValidate = (entry) => {
  //   if (!!entry.mealsNote && entry.mealsNote.length > 150 && !!entry.note && entry.note.length > 150) {
  //     this.setState({
  //       errors: {
  //         mealsNote: 'Przekroczono maksymalną długość (150 znaków).',
  //         note: 'Przekroczono maksymalną długość (150 znaków).'
  //       }
  //     });
  //   } else if (!!entry.mealsNote && entry.mealsNote.length > 150) {
  //     this.setState({ errors: { mealsNote: 'Przekroczono maksymalną długość (150 znaków).' } });
  //   } else if (!!entry.note && entry.note.length > 150) {
  //     this.setState({ errors: { note: 'Przekroczono maksymalną długość (150 znaków).' } });
  //   } else {
  //     this.setState({ errors: {} });
  //   }
  // };

  handleChange(event) {
    const field = event.target.name;
    const entry = Object.assign({}, this.state.entry);
    entry[field] = event.target.value;
    // if (field === 'note' || field === 'mealsNote') {
    //   this.handleStringValidate(entry);
    // }
    return this.setState({ entry });
  }

  render() {
    const {
      onSave,
      onCancel,
      title,
      isDialogOpen,
      isProcessing,
      pupilFirstName,
      pupilLastName,
      pupilPhotoUrl,
      propertiesDefinition
    } = this.props;
    return (
      <DialogForm
        maxWidth="md"
        header={title}
        onSave={() => onSave(this.state.entry)}
        // saveDisabled={Object.entries(this.state.errors).length > 0}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        icon={<ChildCareIcon sx={{ fontSize: 35, mr: 3, ml: 1 }} />}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="daycare">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <PersonAvatar
            initials
            alt={pupilFirstName}
            url={pupilPhotoUrl}
            firstName={pupilFirstName}
            lastName={pupilLastName}
          />
          <Typography
            sx={{
              textAlign: 'center',
              fontWeight: (theme) => theme.typography.fontWeightBold,
              my: 2,
              mx: 0
            }}>{`${pupilFirstName} ${pupilLastName}`}</Typography>
        </Box>
        <ReportDaycareForm
          entry={this.state.entry}
          errors={this.state.errors}
          onChange={this.handleChange}
          propertiesDefinition={propertiesDefinition}
        />
      </DialogForm>
    );
  }
}

ReportDaycareDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  entry: PropTypes.object,
  date: PropTypes.string.isRequired,
  pupilId: PropTypes.string.isRequired,
  pupilFirstName: PropTypes.string.isRequired,
  pupilLastName: PropTypes.string.isRequired,
  pupilPhotoUrl: PropTypes.string,
  propertiesDefinition: PropTypes.object.isRequired
};

export default ReportDaycareDialog;
