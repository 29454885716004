import PropTypes from 'prop-types';
import React from 'react';
import Paper from '@mui/material/Paper';
import { Dialog, Button, Box, Typography } from '@mui/material';
import Check from '@mui/icons-material/Check';
import Avatar from '@mui/material/Avatar';

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPickerOpen: false
    };
    this.handlePickerOpen = this.handlePickerOpen.bind(this);
    this.handlePickerClose = this.handlePickerClose.bind(this);
    this.handleColorPick = this.handleColorPick.bind(this);
  }

  handlePickerOpen() {
    this.setState({ isPickerOpen: true });
  }

  handlePickerClose() {
    this.setState({ isPickerOpen: false });
  }

  handleColorPick(color) {
    this.props.onChange(color);
    this.handlePickerClose();
  }

  renderDialogColorElement(color, selectedColor, name = '') {
    return color === selectedColor ? (
      <Button
        aria-label="wybierz kolor"
        key={color}
        onClick={() => this.handleColorPick(color)}
        sx={{
          width: 140,
          height: 100,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          py: 1,
          px: 2,
          textAlign: 'center'
        }}>
        <Avatar
          aria-label={name}
          variant="square"
          sx={{
            background: color,

            color: 'transparent',
            boxShadow: (theme) => theme.palette.background.shadow + '0px 3px 10px',
            mx: 'auto'
          }}>
          <Check />
        </Avatar>
        <Typography sx={{ mt: 2 }}>{name}</Typography>
      </Button>
    ) : (
      <Button
        sx={{
          width: 140,
          height: 100,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          py: 1,
          px: 2,
          textAlign: 'center'
        }}
        aria-label="wybierz kolor"
        key={color}
        onClick={() => this.handleColorPick(color)}>
        <Avatar
          aria-label={name}
          variant="square"
          sx={{ background: color, color: 'transparent', mx: ' auto' }}></Avatar>
        <Typography sx={{ mt: 2 }}>{name}</Typography>
      </Button>
    );
  }

  render() {
    return (
      <Box>
        <Button sx={{ px: 0 }} aria-label="wybierz grupe" onClick={this.handlePickerOpen}>
          <Avatar variant="circular" sx={{ background: this.props.defaultColor }}>
            <Box />
          </Avatar>
        </Button>
        <Dialog open={this.state.isPickerOpen} onClose={this.handlePickerClose}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              p: 2
            }}>
            {this.props.palette.map((item) =>
              this.renderDialogColorElement(item.color, this.props.defaultColor, item.name)
            )}
          </Box>
        </Dialog>
      </Box>
    );
  }
}

ColorPicker.propTypes = {
  palette: PropTypes.array.isRequired,
  defaultColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  colorElement: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
};

ColorPicker.defaultProps = {
  colorElement: (props) => {
    /*eslint react/prop-types:0*/
    const { style, ...other } = props;
    return (
      <Paper
        zDepth={0}
        sx={{
          height: 40,
          width: 40,
          borderRadius: '10%',
          cursor: 'pointer',
          ...style
        }}
        {...other}
      />
    );
  }
};

export default ColorPicker;
