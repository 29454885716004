export const isInitiallyExpanded = {
  ChildDetailsForm: false,
  ChildChargingSchemesCard: true,
  ChildAttendanceCard: false,
  ChildAttendanceActivitiesCard: false,
  ChildDaycareCard: false,
  ChildMealsCard: true,
  ChildMealsCalendarCard: false,
  ChildLegalGuardiansCard: true,
  ChildGuardiansCard: true,
  ChildFileAttachmentsCard: true,
  ChildEvaluationSurveyCard: true,
  ChildDiagnosticSchemesCard: true,
  ChildOpinionCard: true,
  ChildDocumentsCard: false,
  GroupEnrollmentHistoryCard: false,
  ChildVacationPeriodsCard: false,
};
