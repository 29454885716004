// Check at least 1 digit, 1 special symbol, atleast 8 characters, 1 uppercase letter, 1 lowercaseletter
export const PASSWORD_REGEXP = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*([_\\W]))(?=.{12,})', 'g');
// Simply email check
export const EMAIL_REGEXP = new RegExp('^\\S+@\\S+$', 'g');
// Check if contain at least 1 lower character
export const ONE_LOWER_CHARACTER_REGEXP = new RegExp('(?=.*[a-z])', 'g');
// Check if contain at least 1 upper character
export const ONE_UPPER_CHARACTER_REGEXP = new RegExp('(?=.*[A-Z])', 'g');
// Check if contain at least 1 digit
export const ONE_DIGIT_REGEXP = new RegExp('(?=.*\\d)', 'g');
// Check if contain at least 1 special character
export const ONE_SPECIAL_CHARACTER_REGEXP = new RegExp('(?=.*([_\\W]))', 'g');
