import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

const FormikTimePicker = ({ name, helperText, onChangeValue, label, disabled, fullWidth, ...other }) => {
  const { setFieldValue, setFieldError, status } = useFormikContext();
  const [field, meta] = useField(name);

  const showError = !!meta.error;
  const errorText = meta.error || helperText;

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        clearable
        ampm={false}
        name={field.name}
        label={label}
        maxDateMessage="Zbyt odległa godzina"
        minDateMessage="Zbyt odległa godzina"
        okLabel="Zatwierdź"
        cancelLabel="Anuluj"
        clearLabel="Wyczyść"
        todayLabel="Dziś"
        disabled={status?.disabled || disabled}
        autoComplete={other.autoComplete || 'off'}
        value={field.value}
        format="HH:mm"
        helperText={showError ? meta.error : helperText}
        error={showError}
        onChange={(date) => {
          if (date && !isValidDate(date)) {
            setFieldError(field.name, `Podaj godzinę w formacie hh:mm`);
          } else if (date && isValidDate(date)) {
            setFieldError(field.name, undefined);
          }
          setFieldValue(field.name, date, false);
        }}
        renderInput={(params) => (
          <TextField
            sx={{
              mt: 1,
              color: (theme) => theme.palette.color.error,
              '& .Mui-error': {
                color: (theme) => theme.palette.color.error
              },
              '& .MuiFormHelperText-root': {
                color: (theme) => theme.palette.color.error
              }
            }}
            helperText={errorText}
            error={showError}
            variant="standard"
            {...params}
            fullWidth={fullWidth}
          />
        )}
        {...other}
      />
    </LocalizationProvider>
  );
};

FormikTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onChangeValue: PropTypes.func,
  label: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default FormikTimePicker;
