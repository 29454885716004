import BaseModel from '../../BaseModel';
import uuid from 'uuid';

/*eslint no-use-before-define: 0*/
export class AttributeDefinitionModel extends BaseModel {
  constructor() {
    super();
    this.id = uuid.v4();
    this.name = '';
    this.type = '';
    this.value = undefined;
    this.defaultValue = undefined;
    this.isInitialValue = true;
  }

  onAssignment(assigned) {
    super.onAssignment(assigned);
    /* eslint no-param-reassign: 'off' */
    assigned.value = assigned.value === null ? undefined : assigned.value;
    assigned.defaultValue = assigned.defaultValue === null ? undefined : assigned.defaultValue;
  }
}
