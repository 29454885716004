import _ from 'lodash';
import moment from 'moment';
import BaseModel from '../BaseModel';
import { PersonalIdTypes } from '../../constants/personalIdTypes';
import { TimeModel } from '../TimeModel';
import { ChildDietModel } from '../meals/DietModels';

export class EnrolledToGroupModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.enrolledAt = '';
  }
}

export class UserModel extends BaseModel {
  constructor() {
    super();
    this.userName = '';
    this.password = '';
  }
}

export class EnrolledToGroupModelHistoryItemModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.enrolledAt = '';
    this.disenrolledAt = '';
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.enrolledAt = assigned.enrolledAt ? moment(assigned.enrolledAt).local().format('YYYY-MM-DD HH:mm') : '';
    assigned.disenrolledAt = assigned.disenrolledAt
      ? moment(assigned.disenrolledAt).local().format('YYYY-MM-DD HH:mm')
      : '';
  }
}

export class LegalGuardianModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.firstName = '';
    this.lastName = '';
    this.lastName = '';
    this.relationship = '';
    this.email = '';
    this.documentNumber = '';
    this.phoneNumber = '';
    this.address = '';
    this.children = [];
    this.workplaceAddress = '';
    this.bankAccountNumber = '';
    this.userAccountActivated = false;
    this.worksFrom = new TimeModel();
    this.worksTo = new TimeModel();
    this.photoUrl = '';
    this.pin = '';
    this.personalId = '';
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.worksFrom = assigned.worksFrom ? new TimeModel().assign(assigned.worksFrom) : new TimeModel();
    assigned.worksTo = assigned.worksTo ? new TimeModel().assign(assigned.worksTo) : new TimeModel();
  }
}

class TimeSpan extends BaseModel {
  constructor() {
    super();
    this.hours = null;
    this.minutes = null;
  }
}

export class GuardianModel extends BaseModel {
  constructor(isAuthorized = false) {
    super();
    this.id = '';
    this.firstName = '';
    this.lastName = '';
    this.documentNumber = '';
    this.relationship = '';
    this.phoneNumber = '';
    this.authorizationExpirationDate = null;
    this.isAuthorized = isAuthorized;
    this.isAuthorizedBySign = false;
    this.isAuthorizedByAll = false;
    this.pin = '';
    this.signedFileInfo = {};
    this.authorizedBy = null;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.authorizationExpirationDate = assigned.authorizationExpirationDate
      ? new Date(assigned.authorizationExpirationDate)
      : null;
  }
}

export class Address {
  constructor() {
    this.city = '';
    this.address = '';
    this.zipCode = '';
    this.birthPlace = '';
  }
}

export class ChildChargingScheme extends BaseModel {
  constructor() {
    super();
    this.schemeId = null;
    this.reliefId = null;
    this.singleUse = false;
    this.reliefExpiryDate = null;
    this.schemeReportType = null;
    this.absenceReportingDeadlineTime = new TimeModel();
    this.absenceReportingDeadlineDays = undefined;
    this.grantHoursAmount = null;
    this.grantHourlyRate = null;
    this.calculationStartDate = null;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.absenceReportingDeadlineTime = assigned.absenceReportingDeadlineTime
      ? new TimeModel().assign(assigned.absenceReportingDeadlineTime)
      : new TimeModel();
  }
}

export class OpeningBalance extends BaseModel {
  constructor() {
    super();
    this.totalDays = '';
    this.totalTime = new TimeSpan();
    this.totalChargeableTime = new TimeSpan();
    this.stayDueCorrection = '';
    this.cateringDueCorrection = '';
    this.otherDueCorrection = '';
    this.isLocked = false;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.totalTime = new TimeSpan().assign(assigned.totalTime);
    assigned.totalChargeableTime = new TimeSpan().assign(assigned.totalChargeableTime);
  }
}

export class Contract extends BaseModel {
  constructor() {
    super();
    this.schemes = [];
    this.openingBalance = new OpeningBalance();
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.schemes = assigned.schemes.map((s) => new ChildChargingScheme().assign(s));
    assigned.openingBalance = assigned.openingBalance ? new OpeningBalance().assign(assigned.openingBalance) : null;
  }
}

export class FileModel extends BaseModel {
  constructor() {
    super();
    this.fileId = '';
    this.name = '';
    this.uploadDate = '';
    this.file = null;
    this.fileInfo = null;
  }
}

export class OpinionFileModel extends BaseModel {
  constructor() {
    super();
    this.fileId = '';
    this.name = '';
    this.uploadDate = '';
    this.file = null;
    this.fileInfo = null;
    this.opinionType = '';
    this.opinionTypeOtherValue = '';
    this.reason = '';
    this.opinionNumber = '';
    this.needHelp = false;
    this.helpDateStart = '';
    this.helpDateEnd = '';
  }
}

export class VacationPeriodModel extends BaseModel {
  constructor() {
    super();
    this.id = null;
    this.startMonth = null;
    this.endMonth = null;
    this.createdAt = null;
    this.createdBy = null;
  }
}

export class BalanceOperationModel extends BaseModel {
  constructor() {
    super();
    this.date = null;
    this.amount = null;
    this.type = null;
    this.dueId = null;
    this.dueNumber = null;
    this.method = null;
    this.fundsBefore = null;
    this.fundsAfter = null;
    this.walletType = null;
    this.fromFundsAfter = null;
    this.fromFundsBefore = null;
    this.from = null;
    this.target = null;
  }

  onAssignment(assigned) {
    assigned.fundsBefore = assigned.fundsBefore || 0;
    assigned.fundsAfter = assigned.fundsAfter || 0;
  }
}

export class ChildPersonalBalanceModel extends BaseModel {
  constructor() {
    super();
    this.availableFunds = null;
    this.availableFundsStay = null;
    this.availableFundsCatering = null;
    this.availableFundsOther = null;
    this.availableFundsActivities = null;
    this.availableFundsMeals = null;
    this.operations = [];
  }

  onAssignment(assigned) {
    assigned.operations = _.reverse(assigned.operations).map((o) => new BalanceOperationModel().assign(o));
  }
}

export class ChildModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.firstName = '';
    this.secondName = '';
    this.lastName = '';
    this.birthDate = new Date();
    this.nameDayDate = '';
    this.personalId = '';
    this.childIntegrationId = '';
    this.additionalChildIntegrationId = '';
    this.personalIdType = PersonalIdTypes.pesel.value;
    this.address = new Address();
    this.guardians = [];
    this.legalGuardians = [];
    this.enrolledToGroup = {};
    this.enrolledToActivities = [];
    this.enrolledToMenu = [];
    this.contract = new Contract();
    this.photoUrl = '';
    this.opinions = [];
    this.declaredDropOffAt = new TimeModel();
    this.declaredPickUpAt = new TimeModel();
    this.educationEndDate = new Date();
  }

  assignFromDetailsModel(childDetails) {
    return this.assign({
      id: childDetails.id,
      firstName: childDetails.firstName,
      secondName: childDetails.secondName,
      lastName: childDetails.lastName,
      birthDate: childDetails.birthDate,
      nameDayDate: childDetails.nameDayDate,
      personalId: childDetails.personalId,
      childIntegrationId: childDetails.childIntegrationId,
      additionalChildIntegrationId: childDetails.additionalChildIntegrationId,
      personalIdType: childDetails.personalIdType,
      address: childDetails.address,
      guardians: childDetails.guardians,
      legalGuardians: childDetails.legalGuardians,
      enrolledToGroup: childDetails.enrolledToGroup,
      enrolledToActivities: childDetails.enrolledToActivities,
      enrolledToMenu: childDetails.enrolledToMenu,
      contract: childDetails.contract,
      photoUrl: childDetails.photoUrl,
      educationEndDate: childDetails.educationEndDate
    });
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.enrolledToActivities = assigned.enrolledToActivities.map((a) => new EnrolledToGroupModel().assign(a));
    assigned.enrolledToGroup = assigned.enrolledToGroup
      ? new EnrolledToGroupModel().assign(assigned.enrolledToGroup)
      : null;
    assigned.enrolledToMenu = assigned.enrolledToMenu.map((a) => new ChildDietModel().assign(a));
    assigned.legalGuardians = assigned.legalGuardians.map((e) => new LegalGuardianModel().assign(e));
    assigned.guardians = assigned.guardians.map((e) => new GuardianModel().assign(e));
    assigned.contract = assigned.contract ? new Contract().assign(assigned.contract) : null;
    assigned.declaredPickUpAt = assigned.declaredPickUpAt
      ? new TimeModel().assign(assigned.declaredPickUpAt)
      : new TimeModel();
    assigned.declaredDropOffAt = assigned.declaredDropOffAt
      ? new TimeModel().assign(assigned.declaredDropOffAt)
      : new TimeModel();
  }
}

export class StudentModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.firstName = '';
    this.secondName = '';
    this.lastName = '';
    this.birthDate = new Date();
    this.personalId = '';
    this.personalIdType = PersonalIdTypes.pesel.value;
    this.address = new Address();
    this.legalGuardians = [];
    this.enrolledToGroup = {};
    this.enrolledToActivities = [];
    this.photoUrl = '';
    this.declaredDropOffAt = {
      hourLocal: null,
      minuteLocal: null
    };
    this.declaredPickUpAt = {
      hourLocal: null,
      minuteLocal: null
    };
    this.email = '';
    this.guardianFirstName = '';
    this.guardianLastName = '';
    this.guardianContactNumber = '';
    this.guardiansAddress = new Address();
  }

  assignFromDetailsModel(childDetails) {
    return this.assign({
      id: childDetails.id,
      firstName: childDetails.firstName,
      secondName: childDetails.secondName,
      lastName: childDetails.lastName,
      birthDate: childDetails.birthDate,
      personalId: childDetails.personalId,
      personalIdType: childDetails.personalIdType,
      address: childDetails.address,
      legalGuardians: childDetails.legalGuardians,
      enrolledToGroup: childDetails.enrolledToGroup,
      enrolledToActivities: childDetails.enrolledToActivities,
      photoUrl: childDetails.photoUrl
    });
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.enrolledToActivities = assigned.enrolledToActivities.map((a) => new EnrolledToGroupModel().assign(a));
    assigned.enrolledToGroup = assigned.enrolledToGroup
      ? new EnrolledToGroupModel().assign(assigned.enrolledToGroup)
      : null;
    assigned.legalGuardians = assigned.legalGuardians.map((e) => new LegalGuardianModel().assign(e));
  }
}

export class OpinionModel extends BaseModel {
  constructor() {
    super();
    this.title = '';
    this.releasedBy = '';
    this.date = null;
    this.fileType = 'opinion';
    this.comments = '';
  }
}

export class AttributeModel extends BaseModel {
  constructor() {
    super();
    this.title = '';
    this.releasedBy = '';
    this.date = null;
    this.fileType = 'opinion';
    this.comments = '';
  }
}

export class DiagnoseModel extends BaseModel {
  constructor() {
    super();
    this.diagnoseId = '';
    this.title = '';
    this.content = '';
    this.date = moment().format('YYYY-MM-DD');
    // todo createdBy:
    this.createdBy = '';
  }
}

export class SurveyModel extends BaseModel {
  constructor() {
    super();
    this.title = '';
    this.fileType = 'survey';
  }
}

export class AttributeValueModel extends BaseModel {
  constructor() {
    super();
    this.defaultValue = '';
    this.id = '';
    this.isInitialValue = '';
    this.name = '';
    this.type = '';
    this.value = '';
  }
}

export class AttributesModel extends BaseModel {
  constructor() {
    super();
    this.attributeSetId = '';
    this.attributeSetName = '';
    this.attributeValues = [];
    this.objectId = '';
    this.objectType = '';
  }

  onAssignment(assigned) {
    assigned.attributeValues = assigned.attributeValues.map((o, obj) => {
      obj = o;
      if (obj.type === 'text' && obj.value === null) {
        obj.value = '';
      }
      if (obj.type === 'flag' && obj.value === null) {
        obj.value = false;
      }
      return new AttributeValueModel().assign(obj);
    });
  }
}

export class ChildDetailsModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.firstName = '';
    this.secondName = '';
    this.lastName = '';
    this.birthDate = new Date();
    this.educationStartDate = new Date();
    this.educationEndDate = new Date();
    this.personalId = '';
    this.childIntegrationId = '';
    this.additionalChildIntegrationId = '';
    this.personalIdType = PersonalIdTypes.pesel.value;
    this.address = new Address();
    this.nameDayDate = null;
    this.declaredDropOffAt = new TimeModel();
    this.declaredPickUpAt = new TimeModel();
    this.stayBankAccount = '';
    this.cateringBankAccount = '';
    this.otherBankAccount = '';
    this.birthPlace = '';
    this.registeredAddress = new Address();
    this.contractNumber = '';
    this.caseNumber = '';

    // this.psychologicalHelp={
    //   null
    // }

    this.notes = [
      { key: 'Zalecenia lekarskie', value: '' },
      { key: 'Uwagi', value: '' },
      { key: 'Uczulenia na pokarmy', value: '' },
      { key: 'Uczulenia na ukąszenia owadów', value: '' }
    ];

    this.opinions = [
      { key: 'Dziecko posiada orzeczenie o niepełnosprawności', value: false },
      { key: 'Dziecko posiada orzeczenie o kształceniu specjalnym', value: false },
      { key: 'Dziecko posiada opinię o wczesnym wspomaganiu', value: false }
    ];

    this.agreements = [
      { key: 'Wyrażam zgodę na badanie logopedyczne i ewentualna terapie logopedyczną', value: false },
      {
        key: 'Wyrażam zgodę na umieszczenie zdjęć mojego dziecka oraz jego prac plastycznych na stronie internetowej naszej placówki i lokalnej prasie',
        value: false
      },
      {
        key: 'Wyrażam zgodę na uczestniczenie mojego dziecka w spacerach i wycieczkach organizowanych poza teren placówki',
        value: false
      },
      {
        key: 'W razie wypadku zezwalam na decyzje konieczne (udzielenie pomocy medycznej, przewiezienie dziecka do szpitala)',
        value: false
      }
    ];

    this.guardians = [];
    this.assistanceId = '';
    this.legalGuardians = [];
    this.enrolledToGroup = {};
    this.enrolledToGroupHistory = [];
    this.enrolledToActivities = [];
    this.enrolledToMenu = [];
    this.enrolledToPsychologicalPedagogicalGroup = [];
    this.enrolledToPsychologicalPedagogicalGroupHistory = [];
    this.attachments = [];
    this.photoUrl = '';
    this.contract = new Contract();
    this.attributes = [];
    this.diagnoses = [];
    this.takenOverByDebtCollection = false;
    this.vacationPeriods = [];
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.declaredPickUpAt = assigned.declaredPickUpAt
      ? new TimeModel().assign(assigned.declaredPickUpAt)
      : new TimeModel();
    assigned.declaredDropOffAt = assigned.declaredDropOffAt
      ? new TimeModel().assign(assigned.declaredDropOffAt)
      : new TimeModel();
    assigned.enrolledToGroup = assigned.enrolledToGroup
      ? new EnrolledToGroupModel().assign(assigned.enrolledToGroup)
      : null;
    assigned.enrolledToGroupHistory = assigned.enrolledToGroupHistory.map((e) =>
      new EnrolledToGroupModelHistoryItemModel().assign(e)
    );
    assigned.enrolledToActivities = assigned.enrolledToActivities.map((a) => new EnrolledToGroupModel().assign(a));
    assigned.enrolledToMenu = assigned.enrolledToMenu.map((a) => new ChildDietModel().assign(a));
    assigned.legalGuardians = assigned.legalGuardians.map((e) => new LegalGuardianModel().assign(e));
    assigned.guardians = assigned.guardians.map((e) => new GuardianModel().assign(e));
    assigned.contract = assigned.contract ? new Contract().assign(assigned.contract) : null;
    assigned.attachments = assigned.attachments.map((e) => {
      if (e.fileInfo && e.fileInfo.fileType === 'opinion') return new OpinionFileModel().assign(e);
      else return new FileModel().assign(e);
    });
    assigned.diagnoses = assigned.diagnoses
      ? assigned.diagnoses.map((diagnose) => new DiagnoseModel().assign(diagnose))
      : [];
    assigned.opinions = assigned.opinions.map((o) => ({ key: o.key, value: o.value }));
    assigned.agreements = assigned.agreements.map((o) => ({ key: o.key, value: o.value }));
    assigned.attributes = assigned.attributes.map((o) => new AttributesModel().assign(o));
  }
}

export class StudentDetailsModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.firstName = '';
    this.secondName = '';
    this.lastName = '';
    this.birthDate = new Date();
    this.personalId = '';
    this.personalIdType = PersonalIdTypes.pesel.value;
    this.address = new Address();
    this.nameDayDate = null;
    this.declaredDropOffAt = {
      hourLocal: null,
      minuteLocal: null
    };
    this.declaredPickUpAt = {
      hourLocal: null,
      minuteLocal: null
    };
    this.stayBankAccount = '';
    this.cateringBankAccount = '';
    this.otherBankAccount = '';
    this.registeredAddress = new Address();
    this.contractNumber = '';
    this.email = '';

    this.legalGuardians = [];
    this.enrolledToGroup = {};
    this.enrolledToGroupHistory = [];
    this.enrolledToActivities = [];
    this.attachments = [];
    this.photoUrl = '';
    this.guardianFirstName = '';
    this.guardianLastName = '';
    this.guardianContactNumber = '';
    this.guardiansAddress = new Address();
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.declaredPickUpAt = assigned.declaredPickUpAt
      ? new TimeModel().assign(assigned.declaredPickUpAt)
      : new TimeModel();
    assigned.declaredDropOffAt = assigned.declaredDropOffAt
      ? new TimeModel().assign(assigned.declaredDropOffAt)
      : new TimeModel();
    assigned.enrolledToGroup = assigned.enrolledToGroup
      ? new EnrolledToGroupModel().assign(assigned.enrolledToGroup)
      : null;
    assigned.enrolledToGroupHistory = assigned.enrolledToGroupHistory.map((e) =>
      new EnrolledToGroupModelHistoryItemModel().assign(e)
    );
    assigned.enrolledToActivities = assigned.enrolledToActivities.map((a) => new EnrolledToGroupModel().assign(a));
    assigned.legalGuardians = assigned.legalGuardians.map((e) => new LegalGuardianModel().assign(e));
    assigned.attachments = assigned.attachments.map((e) => new FileModel().assign(e));
  }
}

export class ImportChildrenSummaryModel extends BaseModel {
  constructor() {
    super();
    this.errorList = [];
    this.addedChildrenCount = 0;
    this.addedGuardiansCount = 0;
  }
}
