import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CurrentUserIcon from '@mui/icons-material/People';
import { loadCurrentUsersAsync } from '../../../actions/currentUsersActions';
import _ from 'lodash';
import {
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Pagination,
  Typography,
  useMediaQuery
} from '@mui/material';
import normalUserRoleNames from '../../../constants/userRoleNames';
import reducedUserRoleNames from '../../../constants/reducedUserRoleNames';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';
import SelectField from '../../common/SelectField';

const CurrentUsersPage = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const numberArrayOfOptions = [5, 10, 25, 50];
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);
  const currentUsers = useSelector((state) => state.currentUsers);

  const userRoleNames = nadarzyn ? reducedUserRoleNames : normalUserRoleNames;

  const handleChangePage = (event, newValue) => {
    setPage(newValue);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(e.target.value);
    setPage(1);
  };

  const handleRefresh = () => {
    dispatch(loadCurrentUsersAsync());
  };

  return (
    <Box>
      <PageHeaderText title="Zalogowani użytkownicy" titleIcon={<CurrentUserIcon />} />
      <ContentWrapper>
        <Box sx={{ mb: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <SelectField
            sx={{
              width: 88
            }}
            id="rowsPerPage"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            floatingLabelText="Wierszy na stronę">
            {numberArrayOfOptions.map((item) => (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            ))}
          </SelectField>
          <Button aria-label="Odśwież listę" onClick={handleRefresh} variant="contained" sx={{ my: 1, ml: 1 }}>
            Odśwież listę
          </Button>
        </Box>
        <Paper sx={{ p: 3 }}>
          <TableContainer>
            <Table aria-label="Tabela zalogowanych użytkowników">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="tableHeader">Użytkownik</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="tableHeader">Rola</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentUsers.currentUsersList.map((row) => (
                  <TableRow key={row.userId}>
                    <TableCell align="left">
                      <Typography variant="tableBodyCell">{row.userFullName}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="tableBodyCell">{userRoleNames[row.userRole].name}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
                {currentUsers.currentUsersList.length === 0 && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="tableBodyCell">Brak wyników</Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            siblingCount={isMobile ? 0 : 1}
            count={_.ceil(currentUsers.currentUsersList.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              my: 2
            }}
          />
        </Paper>
      </ContentWrapper>
    </Box>
  );
};

export default CurrentUsersPage;
