import moment from 'moment';

export function childName(getState, id) {
  const child = getState().children.find((x) => x.id === id);
  if (!child) return '';
  return `${child.firstName} ${child.lastName}`;
}

export function childArchiveName(getState, id) {
  const child = getState().archives.children.find((x) => x.id === id);
  if (!child) return '';
  return `${child.firstName} ${child.lastName}`;
}

export function groupName(getState, id) {
  const group = getState().groups.find((x) => x.id === id);
  if (!group) return '';
  return group.name;
}

export function groupArchiveName(getState, id) {
  const group = getState().archives.groups.find((x) => x.id === id);
  if (!group) return '';
  return group.name;
}

export function activityName(getState, id) {
  const activity = getState().activities.find((x) => x.id === id);
  if (!activity) return '';
  return activity.name;
}

export function activityArchiveName(getState, id) {
  const activity = getState().archives.activities.find((x) => x.id === id);
  if (!activity) return '';
  return activity.name;
}

export function staffName(getState, id) {
  const staff = getState().staffMembers.find((x) => x.id === id);
  if (!staff) return '';
  return `${staff.firstName} ${staff.lastName}`;
}

export function staffArchiveName(getState, id) {
  const staff = getState().archives.staffMembers.find((x) => x.id === id);
  if (!staff) return '';
  return `${staff.firstName} ${staff.lastName}`;
}

export function legalGuardianName(getState, id) {
  const guardian = getState().legalGuardians.find((x) => x.id === id);
  if (!guardian) return '';
  return `${guardian.firstName} ${guardian.lastName}`;
}

export function guardianName(getState, childId, id) {
  const child = getState().children.find((x) => x.id === childId);
  if (!child) return '';
  const guardian = child.guardians.find((x) => x.id === id);
  if (!guardian) return '';
  return `${guardian.firstName} ${guardian.lastName}`;
}

export function schemeName(getState, id) {
  const scheme = getState().configuration.chargingSchemes.find((x) => x.id === id);
  if (!scheme) return '';
  return scheme.name;
}

export function numberingSchemeName(getState, id) {
  const scheme = getState().configuration.numberingSchemes.find((x) => x.id === id);
  if (!scheme) return '';
  return scheme.name;
}

export function displayDate(date) {
  return moment(date).format('DD.MM.YYYY');
}

export function displayMonth(date) {
  return moment(date).format('MMMM YYYY');
}

export function displayDateRange(from, to) {
  const fromFormated = displayDate(from);
  const toFormated = displayDate(to);
  if (fromFormated === toFormated) return `dnia ${fromFormated}`;
  return `od ${fromFormated} do ${toFormated}`;
}

export function displayTime(time) {
  return moment(time).format('HH:mm');
}

export function displayTimeRange(from, to) {
  return `od ${displayTime(from)} do ${displayTime(to)}`;
}

export function postTitle(getState, id) {
  const post = getState().messageBoardPosts.posts.find((x) => x.id === id);
  if (!post) return '';
  return post.title;
}

export function reliefName(getState, id) {
  const relief = getState().configuration.reliefs.find((x) => x.id === id);
  if (!relief) return '';
  return relief.name;
}

export function pinDeviceLogin(getState, id) {
  const device = getState().configuration.guardiansPinService.devices.find((x) => x.id === id);
  if (!device) return '';
  return device.login;
}

export function displaySettlements(getState, params) {
  const options = [
    params.calculateStayDues ? 'pobyt' : null,
    params.calculateCateringDues ? 'wyżywienie' : null,
    params.calculationActivitiesDue ? 'zajęcia dodatkowe' : null,
    params.calculateMealsDues ? 'posiłki' : null,
    params.calculateOtherDues ? 'inne' : null
  ];

  return `(${options.filter((x) => x).join(', ')}) dla grupy '${groupName(getState, params.groupIds[0])}'`;
}
