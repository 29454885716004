import {
  Box,
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import EmptyState from '../../common/EmptyState';
// import _ from 'lodash';
import styled from '@emotion/styled';
import DotsMenu from '../../common/menu/DotsMenu';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import ContactDialog from './dialogs/ContactDialog';
import { deleteContact, newContactStart } from '../../../actions/specialistNotesActions';
import { useIsSpecialist } from '../../../hooks/useIsSpecialist';

const StyledCell = styled(TableCell)({
  fontSize: 16,
  py: 0,
  px: 2,
  align: 'left'
});

const ContactComponent = ({ workId }) => {
  const [dialogReadonly, setDialogReadonly] = useState(false);
  const [selectedContact, setSelectedContact] = useState();
  const contacts = useSelector((state) => state.specialistNotes.contacts);
  // const numberArrayOfOptions = [5, 10, 25, 50];
  // const [rowsPerPage, setRowsPerPage] = useState(numberArrayOfOptions[0]);
  // const [page, setPage] = useState(0);
  // const handleChangePage = (event, newValue) => {
  //   setPage(newValue - 1);
  // };
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);

  const { isSpecialist } = useIsSpecialist();

  const handleNewContactDialogOpen = () => {
    setSelectedContact();
    dispatch(newContactStart());
    setDialogReadonly(false);
    setDialogOpen(true);
  };
  const handleNewContactDialogClose = () => {
    setDialogOpen(false);
  };

  const handleEdit = (data, readonly) => {
    setDialogOpen(true);
    setDialogReadonly(readonly);
    dispatch(newContactStart());
    setSelectedContact(data);
  };

  const handleArchiveContact = (id) => {
    dispatch(deleteContact(id, workId));
  };

  const generateLabel = (value) => {
    switch (value) {
      case 'institution':
        return 'Instutycja';
      case 'parent':
        return 'Rodzic';
      default:
        return '';
    }
  };

  return (
    <Box>
      {isSpecialist && (
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              handleNewContactDialogOpen();
            }}>
            Dodaj
          </Button>
        </Box>
      )}
      {contacts?.length ? (
        <TableContainer component={Box}>
          <Table aria-label="psychological-table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ fontSize: 18, minWidth: 200 }}>
                  Wpis
                </TableCell>
                <TableCell align="left" sx={{ fontSize: 18, minWidth: 230 }}>
                  Data wydarzenia
                </TableCell>
                <TableCell align="left" sx={{ fontSize: 18, minWidth: 230 }}>
                  Typ odbiorcy
                </TableCell>
                <TableCell align="left">
                  <Typography
                    sx={{
                      border: 0,
                      clip: 'rect(0 0 0 0)',
                      height: '1px',
                      ml: '-1px',
                      overflow: 'hidden',
                      p: 0,
                      top: 0,
                      width: '1px'
                    }}>
                    Menu
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contacts.map((contact, index) => {
                return (
                  <TableRow
                    onClick={() => handleEdit({ row: contact, data: {} }, true)}
                    key={contact?.id || index}
                    sx={{ '&:hover': { background: (theme) => theme.palette.background.hover }, cursor: 'pointer' }}>
                    <StyledCell align="left">{contact.note}</StyledCell>
                    <StyledCell align="left">{moment(contact.eventDate).format('DD.MM.YYYY')}</StyledCell>
                    <StyledCell align="left">{generateLabel(contact.contactType)}</StyledCell>
                    <StyledCell
                      align="left"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}>
                      {isSpecialist && (
                        <DotsMenu>
                          {() => [
                            <MenuItem onClick={() => handleEdit({ row: contact, data: {} }, false)} key="1">
                              Edytuj
                            </MenuItem>,
                            <ActionConfirmationMenuItem
                              key="2"
                              onItemClick={() => {}}
                              actionLabel="Usuń"
                              confirmationText="Czy na pewno chcesz usunąć ten kontakt?"
                              confirmationTitle="Usuń kontakt"
                              confirmLabel="Usuń"
                              onConfirm={() => {
                                handleArchiveContact(contact.id);
                              }}
                              onCancel={() => {}}
                            />
                          ]}
                        </DotsMenu>
                      )}
                    </StyledCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {/* <Pagination
            siblingCount={1}
            count={_.ceil(contacts.length / rowsPerPage)}
            page={page + 1}
            onChange={handleChangePage}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 1,
              mt: 2
            }}
          /> */}
        </TableContainer>
      ) : (
        <EmptyState message="Brak kontaktów" contrast />
      )}
      {dialogOpen && (
        <ContactDialog
          workId={workId}
          dialogTitle={
            dialogReadonly
              ? 'Kontakt do rodzica/instytucji'
              : !!selectedContact
              ? 'Edycja kontaktu'
              : 'Nowy kontakt do rodzica/instytucji'
          }
          open={dialogOpen}
          handleCancel={handleNewContactDialogClose}
          isReadonly={dialogReadonly}
          selectedContact={selectedContact}
        />
      )}
    </Box>
  );
};

export default ContactComponent;
