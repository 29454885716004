import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButtonPipe from '../../../../common/IconButtonPipe';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import makeStyles from '@mui/styles/makeStyles';
import FileSpecificIcon from '../../../../common/FileSpecificIcon/FileSpecificIcon';
import * as Status from '../../../../../constants/status';
import ClearIcon from '@mui/icons-material/Clear';
import { removeRightBottomNotification } from '../../../../../actions/notificationActions';
import { useDispatch } from 'react-redux';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import RichTooltip from '../../../../richTooltip/richTooltip';
import {
  Alert,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Box,
  Typography
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme) => ({
  avatar: {
    minWidth: 'auto',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center'
  },
  list: {},
  listItem: {
    width: '100%'
  }
}));

const Uploaded = ({ actualCount }) => <Typography>Przesłano {actualCount} plików. Trwa zapisywanie</Typography>;

const UploadMultimedia = ({ data, uuid }) => {
  const cl = useStyles();
  const { cancelRequest, name, progress, status, mimeType, errorMessage } = data;
  const [isCancelShow, setIsCancelShow] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const dispatch = useDispatch();

  const handleRemove = () => {
    dispatch(removeRightBottomNotification(uuid));
  };

  return (
    <ListItem
      key={uuid}
      classes={{ container: cl.listItem }}
      onMouseEnter={() => setIsCancelShow(true)}
      onMouseLeave={() => setIsCancelShow(false)}>
      <ListItemAvatar className={cl.avatar}>
        <FileSpecificIcon type={mimeType} />
        {status === Status.ERROR && errorMessage && (
          <Box
            sx={{ display: 'flex', alignItems: 'center', ml: 2 }}
            onMouseEnter={() => {
              setIsTooltipOpen((prev) => !prev);
            }}
            onMouseLeave={() => {
              setIsTooltipOpen((prev) => !prev);
            }}>
            <RichTooltip
              content={<Alert severity="error">{errorMessage}</Alert>}
              open={isTooltipOpen}
              placement="top"
              onClose={() => {
                setIsTooltipOpen(false);
              }}>
              <InfoIcon sx={{ cursor: 'help' }} />
            </RichTooltip>
          </Box>
        )}
      </ListItemAvatar>
      <ListItemText sx={{ mr: 2 }} primary={name} />
      <ListItemSecondaryAction
        onMouseEnter={() => setIsCancelShow(true)}
        onMouseLeave={() => setIsCancelShow(false)}
        onFocus={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            // Not triggered when swapping focus between children
            setIsCancelShow(true);
          }
        }}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setIsCancelShow(false);
          }
        }}>
        <Box position="relative" display="inline-flex">
          {status !== Status.PENDING && (
            <IconButtonPipe
              size="small"
              tooltip="Usuń plik z listy"
              style={{ marginLeft: 5, padding: 8 }}
              onClick={() => {
                handleRemove();
              }}>
              {isCancelShow ? (
                <CancelScheduleSendIcon sx={{ color: (theme) => theme.palette.text.primary, fontSize: 25 }} />
              ) : (
                <>
                  {status === Status.SUCCESS ? (
                    <CheckCircleIcon sx={{ fontSize: 25, color: (theme) => theme.palette.background.success }} />
                  ) : (
                    <HighlightOffIcon sx={{ fontSize: 25, color: (theme) => theme.palette.background.error }} />
                  )}
                </>
              )}
            </IconButtonPipe>
          )}
        </Box>
        {status === Status.PENDING && (
          <IconButtonPipe
            size="small"
            tooltip="Analuj przesyłanie pliku"
            style={{ marginLeft: 5 }}
            onClick={() => {
              cancelRequest();
            }}>
            <>
              <CircularProgress size={35} variant="determinate" value={progress} />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center">
                {!isCancelShow ? (
                  <Typography
                    variant="caption"
                    component="div"
                    sx={{ fontSize: '0.7rem' }}>{`${progress}%`}</Typography>
                ) : (
                  <ClearIcon sx={{ color: (theme) => theme.palette.color.color22, fontSize: 20 }} />
                )}
              </Box>
            </>
          </IconButtonPipe>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

UploadMultimedia.propTypes = {
  data: PropTypes.object.isRequired,
  uuid: PropTypes.string.isRequired
};
Uploaded.propTypes = {
  actualCount: PropTypes.number.isRequired
};

export default UploadMultimedia;
