import moment from 'moment';

function exceededAbsenceReportDeadline(schemes, absenceFrom, absenceReportedAt, force) {
  return (
    force ||
    !!schemes
      .filter((x) => !x.absenceReportingDeadlineTime.isEmpty())
      .find((x) => {
        const next = moment(absenceFrom)
          .startOf('day')
          .subtract(x.absenceReportingDeadlineDays, 'days')
          .add(x.absenceReportingDeadlineTime.hourLocal, 'hours')
          .add(x.absenceReportingDeadlineTime.minuteLocal, 'minutes');
        return next.isBefore(absenceReportedAt);
      })
  );
}

export default exceededAbsenceReportDeadline;
