import React from 'react';
import PropTypes from 'prop-types';
import IconButtonPipe from '../../../common/IconButtonPipe';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useToolkitStyles } from './ToolkitStyles';
import ShareIcon from '@mui/icons-material/Share';
import SearchInputV2 from '../../../searchInputV2/SearchInputV2';
import { Box, Typography, Fade, Grid, MenuItem } from '@mui/material';
import SelectField from '../../../common/SelectField';

const Toolkit = ({
  downloadFiles,
  deleteFiles,
  shareFiles,
  selectedFiles,
  searchText,
  onSearch,
  onClearSearch,
  isMobile,
  onOpenMoveToFolder,
  isSelected,
  isManagement,
  rowsPerPage,
  setRowsPerPage,
  rowsPerPageVisible
}) => {
  const cl = useToolkitStyles({ isMobile, isSelected });
  const numberArrayOfOptions = [5, 10, 25, 50];

  return (
    <>
      <Grid
        container
        sx={{
          mb: 2
        }}>
        <Grid item xs={12} lg={4} xl={6}>
          <SearchInputV2
            sx={{ my: '20px' }}
            isDivider={false}
            isMobile={isMobile}
            searchText={searchText}
            onSearch={onSearch}
            onClear={onClearSearch}
            InputProps={{
              style: { fontSize: '18px' },
              placeholder: 'Wyszukaj',
              inputProps: { 'aria-label': 'Wyszukaj na dysku wirtualnym' }
            }}
          />
        </Grid>
        <Grid item xs={12} lg={8} xl={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {rowsPerPageVisible && (
            <SelectField
              sx={{
                minWidth: 88
              }}
              id="rowsPerPage"
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(e);
              }}
              floatingLabelText="Wierszy na stronę">
              {numberArrayOfOptions.map((item) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </SelectField>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isSelected && (
              <Fade in={isSelected}>
                <Box>
                  <Box className={`${cl.flexContainer} ${cl.mobileBorders}`} sx={{ textAlign: 'center' }}>
                    <>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography
                          sx={{
                            color: (theme) => theme.palette.color.primary,
                            fontWeight: (theme) => theme.typography.fontWeightBulky,
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                            ml: 3
                          }}>
                          Zaznaczono {selectedFiles.length ? selectedFiles.length : undefined}
                          <DescriptionOutlinedIcon sx={{ ml: 1, mr: 4 }} />
                        </Typography>
                      </Box>

                      <Box>
                        <IconButtonPipe onClick={downloadFiles} tooltip="Pobierz zaznaczone pliki">
                          <FileDownloadOutlinedIcon
                            sx={{
                              color: (theme) => theme.palette.color.primary
                            }}
                          />
                        </IconButtonPipe>
                        {isManagement && (
                          <>
                            <IconButtonPipe onClick={shareFiles} tooltip="Upublicznij zaznaczone pliki">
                              <ShareIcon
                                sx={{
                                  color: (theme) => theme.palette.color.primary
                                }}
                              />
                            </IconButtonPipe>
                            <IconButtonPipe onClick={onOpenMoveToFolder} tooltip="Przenieś zaznaczone pliki do folderu">
                              <DriveFileMoveIcon
                                sx={{
                                  color: (theme) => theme.palette.color.primary
                                }}
                              />
                            </IconButtonPipe>
                            <IconButtonPipe onClick={deleteFiles} tooltip="Usuń zaznaczone pliki">
                              <DeleteForeverIcon
                                sx={{
                                  color: (theme) => theme.palette.color.primary
                                }}
                              />
                            </IconButtonPipe>
                          </>
                        )}
                      </Box>
                    </>
                  </Box>
                </Box>
              </Fade>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

Toolkit.propTypes = {
  deleteFiles: PropTypes.func.isRequired,
  downloadFiles: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  searchText: PropTypes.string.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  shareFiles: PropTypes.func.isRequired,
  selectedFiles: PropTypes.array.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isManagement: PropTypes.bool.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  rowsPerPageVisible: PropTypes.bool.isRequired
};

export default Toolkit;
