import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GetAppIcon from '@mui/icons-material/GetApp';
import Dialog from '../../../../../common/Dialog';
import * as Status from '../../../../../../constants/status';
import UploadedMultimediaDetailsContent from './UploadedMultimediaDetailsContent';
import { useDispatch } from 'react-redux';
import { removeRightBottomNotification } from '../../../../../../actions/notificationActions';
import { AlertMessage } from '../AlertMessage';
import { Button, useMediaQuery, useTheme, ListItem, Grid, CircularProgress, Typography } from '@mui/material';

const Pending = ({ actualCount, totalCount }) => (
  <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
    Przesyłanie plików {actualCount} / {totalCount}
  </Typography>
);

const Uploaded = ({ actualCount }) => (
  <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
    Przesłano {actualCount} plików. Trwa zapisywanie
  </Typography>
);

const UploadMultimedia = ({ data, uuid }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { actualCount, totalCount, status, newMultimedia, errorMultimedia, albumName } = data;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();

  const handleRemove = () => {
    dispatch(removeRightBottomNotification(uuid));
  };

  const statusInfo = () => {
    switch (status) {
      case Status.SUCCESS:
        return {
          messageShort: 'Pomyślnie dodano multimedia!',
          messageLong: 'Pomyslnie dodano multimedia do albumu:',
          severity: 'success'
        };
      case Status.PARTLY:
        return {
          messageShort: 'Nie dodano wszystkich multimediów!',
          messageLong: 'Nie dodano wszystkich multimediów do albumu:',
          severity: 'warning'
        };
      case Status.ERROR:
        return {
          messageShort: 'Bład przy dodawaniu multimediów!',
          messageLong: 'Bład przy dodawaniu multimediów do albumu:',
          severity: 'error'
        };
      default:
        return null;
    }
  };

  return (
    <ListItem>
      <Grid container alignItems="center" justifyContent="space-between">
        {(status === Status.PENDING || status === Status.UPLOADED) && (
          <Grid container justifyContent="center">
            <GetAppIcon sx={{ mr: 1 }} />
            {status === Status.PENDING && <Pending actualCount={actualCount} totalCount={totalCount} />}
            {status === Status.UPLOADED && <Uploaded actualCount={actualCount} />}
            <CircularProgress size={20} sx={{ ml: 1 }} />
          </Grid>
        )}
        {(status === Status.SUCCESS || status === Status.PARTLY || status === Status.ERROR) && (
          <AlertMessage data={statusInfo()} setIsDialogOpen={setIsDialogOpen} handleRemove={handleRemove} fullWidth />
        )}
      </Grid>
      <Dialog
        title="Szczegóły przesyłanych multimediów"
        actions={[
          <Button variant="contained" aria-label="Zamknij" onClick={() => setIsDialogOpen(false)} key={1}>
            Zamknij
          </Button>
        ]}
        open={isDialogOpen}
        onRequestClose={() => setIsDialogOpen(false)}
        contentStyle={{ ...(isMobile && { padding: 0 }) }}>
        <UploadedMultimediaDetailsContent
          errorMultimedia={errorMultimedia}
          newMultimedia={newMultimedia}
          message={<AlertMessage data={statusInfo()} isInDialog={true} albumName={albumName} />}
        />
      </Dialog>
    </ListItem>
  );
};

UploadMultimedia.propTypes = {
  data: PropTypes.object.isRequired,
  uuid: PropTypes.string.isRequired
};
Uploaded.propTypes = {
  actualCount: PropTypes.number.isRequired
};
Pending.propTypes = {
  actualCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired
};

export default UploadMultimedia;
