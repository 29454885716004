import React from 'react';
import { useSelector } from 'react-redux';
import BoardPostCard from '../../messageBoard/post/BoardPostCard';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import _ from 'lodash';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import { Box, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EventsCalendarIcon from '@mui/icons-material/Event';

const ReducedGuardianDashboardPage = () => {
  const loggedUserName = useSelector((state) => state.auth.userFullName);
  const messageBoardLatestEvents = useSelector((state) => _.take(state.messageBoardEvents.posts, 3));
  const messageBoardLatestPosts = useSelector((state) => _.take(state.messageBoardPosts.posts, 3));

  const renderMessageBoard = (messageBoard) => {
    return (
      <Box>
        {messageBoard.map((post) => (
          <BoardPostCard key={post.id} post={post} canEdit={false} />
        ))}
      </Box>
    );
  };

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title={`Witaj ${loggedUserName}`} titleIcon={<HomeOutlinedIcon />} />

        {messageBoardLatestEvents.length > 0 && (
          <Typography
            sx={{
              color: (theme) => theme.palette.color.primary,
              fontWeight: (theme) => theme.typography.fontWeightBold,
              opacity: 0.9,
              display: 'flex',
              alignItems: 'flex-end',
              my: 2
            }}
            component="h3"
            variant="h6">
            <EventsCalendarIcon fontSize="large" sx={{ mr: 2 }} /> Najbliższe wydarzenia
          </Typography>
        )}
        {renderMessageBoard(messageBoardLatestEvents)}
        {messageBoardLatestPosts.length > 0 && (
          <Typography
            sx={{
              color: (theme) => theme.palette.color.primary,
              fontWeight: (theme) => theme.typography.fontWeightBold,
              opacity: 0.9,
              display: 'flex',
              alignItems: 'flex-end',
              my: '16px !important'
            }}
            component="h3"
            variant="h6">
            <EventsCalendarIcon fontSize="large" sx={{ mr: 2 }} /> Najnowsze ogłoszenia
          </Typography>
        )}
        {renderMessageBoard(messageBoardLatestPosts)}
      </Box>
    </LoadingRenderWrapper>
  );
};

export default ReducedGuardianDashboardPage;
