import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AdvancedFormField from '../../../common/AdvancedFormField';
import DatePicker from '../../../common/DatePicker';

const IpetForm = ({ setData, selectedIPET, isReadonly, errors }) => {
  const [number, setNumber] = useState(selectedIPET?.opinionNumber);
  const [name, setName] = useState(selectedIPET?.name);
  const [fileDate, setFileDate] = useState(selectedIPET ? selectedIPET.issueDate : null);
  const [programDuration, setProgramDuration] = useState(selectedIPET?.programDuration);
  const [guidelines, setGuidelines] = useState(selectedIPET?.psychologicalCounselingRecommendations);
  const [scope, setScope] = useState(selectedIPET?.programAdaptationScopeAndMethod);
  const [assistance, setAssistance] = useState(selectedIPET?.parentSupportActions);
  const [help, setHelp] = useState(selectedIPET?.psychologicalSupport);
  const [lessons, setLessons] = useState(selectedIPET?.rehabilitationSessions);
  const [enviroment, setEnviroment] = useState(selectedIPET?.educationConditionAdaptationTypeAndMethod);
  const [cooperation, setCooperation] = useState(selectedIPET?.teacherSpecialistCooperationScope);
  const [integrated, setintegrated] = useState(selectedIPET?.integratedActions);

  useEffect(() => {
    setData({
      opinionNumber: number,
      name: name,
      issueDate: fileDate,
      programDuration: programDuration,
      psychologicalCounselingRecommendations: guidelines,
      programAdaptationScopeAndMethod: scope,
      parentSupportActions: assistance,
      psychologicalSupport: help,
      rehabilitationSessions: lessons,
      educationConditionAdaptationTypeAndMethod: enviroment,
      teacherSpecialistCooperationScope: cooperation,
      integratedActions: integrated
    });
  }, [
    assistance,
    cooperation,
    enviroment,
    fileDate,
    guidelines,
    help,
    integrated,
    lessons,
    name,
    number,
    programDuration,
    scope,
    setData
  ]);
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          error={errors?.errors?.name}
          helperText={errors?.errors?.name}
          disabled={isReadonly}
          sx={{ my: 1 }}
          fullWidth
          multiline
          label="Nazwa IPET*"
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="standard"
        />
        <TextField
          error={errors?.errors?.opinionNumber}
          helperText={errors?.errors?.opinionNumber}
          disabled={isReadonly}
          fullWidth
          multiline
          value={number}
          label="Numer orzeczenia*"
          onChange={(e) => setNumber(e.target.value)}
          variant="standard"
          sx={{ mr: 2 }}
        />

        <TextField
          error={errors?.errors?.programDuration}
          helperText={errors?.errors?.programDuration}
          disabled={isReadonly}
          sx={{ my: 1 }}
          fullWidth
          label="Okres realizacji*"
          value={programDuration}
          onChange={(e) => setProgramDuration(e.target.value)}
          variant="standard"
        />
      </Box>
      <DatePicker
        errorText={errors?.errors?.issueDate}
        disabled={isReadonly}
        unlimitedMaxDate
        sx={{ width: 200, mr: 2, my: 1 }}
        floatingLabelText="Data wydania orzeczenia*"
        hintText="Data wydania orzeczenia*"
        name="date_from"
        onChange={(empty, date) => {
          setFileDate(date);
        }}
        value={fileDate}
      />
      <AdvancedFormField
        error={errors?.errors?.teacherSpecialistCooperationScope}
        isReadonly={isReadonly}
        value={cooperation}
        setData={setCooperation}
        label="Zakres współpracy nauczycieli z rodzicami*"
        schemeType="teacherSpecialistCooperationScope"
      />

      <AdvancedFormField
        error={errors?.errors?.psychologicalCounselingRecommendations}
        isReadonly={isReadonly}
        value={guidelines}
        setData={setGuidelines}
        label="Zalecenia poradni psychologicznej*"
        schemeType="psychologicalCounselingRecommendations"
      />
      <AdvancedFormField
        error={errors?.errors?.programAdaptationScopeAndMethod}
        isReadonly={isReadonly}
        value={scope}
        schemeType="programAdaptationScopeAndMethod"
        setData={setScope}
        label="Zakres i sposób dostosowania programu wychowania przedszkolnego oraz wymagań edukacyjnych do indywidualnych potrzeb rozwojowych i edukacyjnych  oraz możliwości psychofizycznych ucznia*"
      />
      <AdvancedFormField
        error={errors?.errors?.parentSupportActions}
        isReadonly={isReadonly}
        value={assistance}
        schemeType="parentSupportActions"
        setData={setAssistance}
        label="Działania wspierające rodziców dziecka*"
        additionalInfo
      />
      <AdvancedFormField
        error={errors?.errors?.psychologicalSupport}
        isReadonly={isReadonly}
        value={help}
        schemeType="psychologicalSupport"
        setData={setHelp}
        label="Udzielana pomoc psychologiczno-pedagogiczna*"
        additionalInfo
      />
      <AdvancedFormField
        error={errors?.errors?.rehabilitationSessions}
        isReadonly={isReadonly}
        value={lessons}
        schemeType="rehabilitationSessions"
        setData={setLessons}
        label="Zajęcia rewalidacyjne*"
        additionalInfo
      />
      <AdvancedFormField
        error={errors?.errors?.integratedActions}
        isReadonly={isReadonly}
        value={integrated}
        schemeType="integratedActions"
        setData={setintegrated}
        label="Zintegrowane działania*"
        additionalInfo
      />
      <AdvancedFormField
        error={errors?.errors?.educationConditionAdaptationTypeAndMethod}
        isReadonly={isReadonly}
        value={enviroment}
        schemeType="educationConditionAdaptationTypeAndMethod"
        setData={setEnviroment}
        label="Rodzaj i sposób dostosowania warunków organizacji kształcenia do rodzaju niepełnosprawności ucznia w tym w zakresie wykorzystywania technologii wspomagających to kształcenie - w zależności od potrzeb*"
      />
    </Box>
  );
};

export default IpetForm;
