import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getComparator, stableSort } from '../../../utils/tableSorting';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  List,
  Typography,
  useMediaQuery,
  Box
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import BoardPostCardDialogFilter from './BoardPostCardFilter';
import BoardPostCardDialogItem from './BoardPostCardDialogItem';

const BoardPostCardDialog = (props) => {
  const { seenBy, setDialogState, dialogState, legalGuardians, isLoading } = props;
  const [userFilter, setUserFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState('5');
  const filters = { all: '', seen: 'seen', unseen: 'unseen' };
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const handleSetFilter = (value) => {
    setUserFilter(value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRows = (value) => {
    setRowsPerPage(value);
    setPage(1);
  };

  const noUsersFound = () => {
    if (isLoading) {
      return <CircularProgress />;
    }
    return <Typography>Brak danych do wyświetlenia</Typography>;
  };

  const postAvailableTo = useMemo(() => {
    if (seenBy) {
      return JSON.parse(JSON.stringify(seenBy.availableTo));
    }
    return [];
  }, [seenBy]);

  const postUnseenBy = useMemo(() => {
    if (seenBy) {
      return JSON.parse(JSON.stringify(seenBy.unread));
    }
    return [];
  }, [seenBy]);

  const showUsers = useMemo(() => {
    const legalGuardiansList = [];
    legalGuardians.map((legalGuardian) =>
      postAvailableTo.includes(legalGuardian.id) ? legalGuardiansList.push(legalGuardian) : null
    );
    const seenOrUnseenList = legalGuardiansList.map((element) => ({
      firstName: element.firstName,
      lastName: element.lastName,
      seen: !postUnseenBy.includes(element.id),
      id: element.id,
      photoUrl: element.photoUrl,
      groups: element.children.map((child) => {
        return child.groupName;
      })
    }));
    if (userFilter === filters.seen) return seenOrUnseenList.filter((item) => item.seen);
    if (userFilter === filters.unseen) {
      return seenOrUnseenList.filter((item) => !item.seen);
    }
    return seenOrUnseenList;
  });

  return (
    <Dialog
      key="view-dialog"
      id="view-dialog"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setDialogState(false);
        }
        if (reason === 'escapeKeyDown') {
          setDialogState(false);
        }
      }}
      maxWidth="sm"
      fullWidth
      aria-labelledby="view-dialog-title"
      open={dialogState}>
      <DialogTitle>Historia wyświetlania przez opiekunów prawnych</DialogTitle>
      <DialogContentText>
        <Box
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightBold,
            px: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: (theme) => theme.palette.color.contrast
          }}>
          <Typography variant="subtitle1">Lista opiekunów prawnych</Typography>
          <BoardPostCardDialogFilter
            setFilter={handleSetFilter}
            filter={userFilter}
            rows={rowsPerPage}
            setRows={handleChangeRows}
            setPage={handleChangePage}
          />
        </Box>
      </DialogContentText>
      <DialogContent sx={{ py: 0 }}>
        <List sx={(isLoading ? { display: 'flex', justifyContent: 'center' } : {}, { overflow: 'auto' })}>
          {dialogState && showUsers.length > 0
            ? stableSort(showUsers, getComparator('asc', { property: 'lastName', type: 'STRING_SORT' }))
                .slice(page * rowsPerPage, page * rowsPerPage + parseInt(rowsPerPage, 10))
                .map((element) => <BoardPostCardDialogItem key={element.id} element={element} />)
            : noUsersFound()}
        </List>
      </DialogContent>
      {dialogState ? (
        <Pagination
          siblingCount={isMobile ? 0 : 1}
          sx={{ alignSelf: 'center', mt: 2 }}
          count={Math.ceil(showUsers.length / rowsPerPage)}
          page={page + 1}
          onChange={handleChangePage}
        />
      ) : null}
      {dialogState ? (
        <DialogActions>
          <Button variant="outlinedContrast" onClick={() => setDialogState(false)}>
            Zamknij
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

BoardPostCardDialog.propTypes = {
  seenBy: PropTypes.object,
  setDialogState: PropTypes.func,
  dialogState: PropTypes.bool,
  legalGuardians: PropTypes.array,
  isLoading: PropTypes.bool
};

export default BoardPostCardDialog;
