import BaseModel from '../BaseModel';

export class RightBottomNotification extends BaseModel {
  constructor(uuid, target, data) {
    super();
    this.uuid = uuid;
    this.target = target;
    this.data = data;
  }
}
