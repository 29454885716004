import BaseModel from '../BaseModel';
import { Address } from './ChildModels';

export class InitialValues extends BaseModel {
  constructor() {
    super();
    this.address = '';
    this.registeredAddress = '';
    this.agreements = '';
    this.attachments = '';
    this.attributes = '';
    this.birthDate = new Date();
    this.cateringBankAccount = '';
    this.childIntegrationId = '';
    this.additionalChildIntegrationId = '';
    this.contract = '';
    this.declaredDropOffAt = new Date();
    this.declaredPickUpAt = new Date();
    this.diagnoses = '';
    this.educationEndDate = new Date();
    this.educationStartDate = new Date();
    this.enrolledToActivities = '';
    this.enrolledToGroup = '';
    this.firstName = '';
    this.guardians = '';
    this.id = '';
    this.lastName = '';
    this.legalGuardians = '';
    this.nameDayDate = '';
    this.notes = '';
    this.birthPlace = '';
    this.opinions = '';
    this.otherBankAccount = '';
    this.personalId = '';
    this.personalIdType = '';
    this.photoUrl = '';
    this.secondName = '';
    this.stayBankAccount = '';
    this.contractNumber = '';
    this.caseNumber = '';
  }
}

export class StudentInitialValues extends BaseModel {
  constructor() {
    super();
    this.address = '';
    this.registeredAddress = '';
    this.attachments = '';
    this.birthDate = new Date();
    this.declaredDropOffAt = {
      hourLocal: null,
      minuteLocal: null
    };
    this.declaredPickUpAt = {
      hourLocal: null,
      minuteLocal: null
    };
    this.enrolledToActivities = '';
    this.enrolledToGroup = '';
    this.firstName = '';
    this.id = '';
    this.lastName = '';
    this.legalGuardians = '';
    this.personalId = '';
    this.personalIdType = '';
    this.photoUrl = '';
    this.secondName = '';
    this.email = '';
    this.guardiansAddress = new Address();
    this.guardianContactNumber = '';
    this.guardianLastName = '';
    this.guardianFirstName = '';
  }
}
