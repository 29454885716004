import PropTypes from 'prop-types';
import React from 'react';
import * as reportsActions from '../../actions/reportsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReportsList from './ReportsList';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import FilterBar from '../common/FilterBar';
import GenerateReportDialog from './GenerateReportDialog';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { ReportsWithCategories } from './Configurations/ReportsWithCategories';
import { Box, Button, Grid, Typography, Paper } from '@mui/material';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import ReportsCategoriesIcons from './ReportsCategoriesIcons';
import withMediaQuery from '../../hoc/withMediaQuery';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import EmptyState from '../common/EmptyState';

class ReportsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReport: null,
      filteredReports: [],
      reportCategory: {},
      reportWithErrors: false
    };

    this.handleReportSelected = this.handleReportSelected.bind(this);
    this.handleGenerateReport = this.handleGenerateReport.bind(this);
    this.handleCancelReportGeneration = this.handleCancelReportGeneration.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleLoaded = this.handleLoaded.bind(this);
    this.renderCategoryChip = this.renderCategoryChip.bind(this);
    this.handleDisableSaveButton = this.handleDisableSaveButton.bind(this);
  }

  handleReportSelected(reportDefinition) {
    this.setState({ selectedReport: reportDefinition });
    this.props.actions.generateReportStart();
  }

  handleDisableSaveButton(disabled) {
    this.setState({ reportWithErrors: disabled });
  }

  handleGenerateReport(parameters) {
    this.setState({ reportWithErrors: false });
    let params = Object.assign({}, parameters);
    if (parameters.groupId === 'all') {
      params = Object.assign({}, parameters, { groupId: null });
    }
    if (parameters.activityId === 'all') {
      params = Object.assign({}, parameters, { activityId: null });
    }
    if (params.reliefId === 'all') {
      params = Object.assign({}, params, { reliefId: null });
    }
    // WARNING: we always take first report format, that means we not fully support more report formats on single report definition.
    // not needed at this moment so i'm not going to deep.
    // console.log(this.state.selectedReport.type, this.state.selectedReport.formats[0], params);
    this.props.actions.generateReportAsync(
      this.state.selectedReport.type,
      this.state.selectedReport.formats[0],
      params
    );
  }

  handleCancelReportGeneration() {
    this.props.actions.generateReportCancel();
    this.setState({ reportWithErrors: false });
  }

  handleFilterChange(filteredList) {
    this.setState({
      filteredReports: filteredList
    });
  }

  handleClearErrors() {
    this.setState({ reportWithErrors: false });
  }

  handleLoaded() {
    this.setState({
      filteredReports: this.props.reports
    });
  }

  handleCategoryChange(category) {
    this.setState({
      reportCategory: category
    });
  }

  renderCategoryChip() {
    if (this.state.filteredReports.length === this.props.reports.length) {
      return (
        <>
          <Grid container>
            {ReportsWithCategories.map(
              (reportCategory) =>
                this.state.filteredReports.filter((report) => reportCategory.reports.includes(report.type)).length >
                  0 && (
                  <Grid
                    xs={12}
                    sm={6}
                    lg={4}
                    xl={3}
                    item
                    sx={{ overflow: 'hidden', p: 1 }}
                    onClick={() => this.handleCategoryChange(reportCategory)}
                    key={reportCategory.id}>
                    <Box
                      sx={{
                        width: '100%',
                        height: 130,
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyCOntent: 'center',
                        borderRadius: '20px',
                        background: (theme) => theme.palette.color.color26,
                        '&:hover': {
                          cursor: 'pointer'
                        }
                      }}>
                      <ReportsCategoriesIcons category={reportCategory.category} />
                      <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, mt: 2 }}>
                        {reportCategory.title}
                      </Typography>
                    </Box>
                  </Grid>
                )
            )}
          </Grid>
        </>
      );
    }
    return null;
  }

  renderGenerateReportDialog() {
    if (this.props.generateReportUi.isEditing) {
      return (
        <GenerateReportDialog
          onSave={this.handleGenerateReport}
          onCancel={this.handleCancelReportGeneration}
          isDialogOpen={this.props.generateReportUi.isEditing}
          isProcessing={this.props.generateReportUi.isProcessing}
          reportType={this.state.selectedReport}
          attributeSets={this.props.attributeSets}
          reportWithErrors={this.state.reportWithErrors}
          disableSaveButton={this.handleDisableSaveButton}
          groups={[{ name: 'Wszystkie', id: 'all' }, ...this.props.groups]}
          reliefs={[{ name: 'Wszystkie', id: 'all' }, ...this.props.reliefs]}
          activities={[{ name: 'Wszystkie', id: 'all' }, ...this.props.activities]}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <LoadingRenderWrapper onLoaded={this.handleLoaded}>
        <Box>
          <PageHeaderText title="Raporty" titleIcon={<InsertChartIcon />} />

          <ContentWrapper>
            <Grid container>
              <Grid item xs={12} md={6} sx={{ py: 2 }}>
                <FilterBar
                  itemsToFilter={this.props.reports}
                  onFilter={this.handleFilterChange}
                  filter={(report, keyword) => report.name.toLowerCase().includes(keyword)}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                {Object.keys(this.state.reportCategory).length !== 0 && (
                  <Button variant="outlined" onClick={() => this.handleCategoryChange({})}>
                    <NavigateBeforeOutlinedIcon />
                    Powrót do wyboru kategorii
                  </Button>
                )}
              </Grid>
            </Grid>
            {Object.keys(this.state.reportCategory).length === 0 && this.renderCategoryChip()}
            {Object.keys(this.state.reportCategory).length === 0 ? null : (
              <ReportsList
                reports={this.state.filteredReports}
                onReportSelected={this.handleReportSelected}
                reportCategory={this.state.reportCategory}
              />
            )}
            {Object.keys(this.state.reportCategory).length === 0 &&
            this.state.filteredReports.length > 0 &&
            this.props.reports.length > 0 &&
            this.state.filteredReports.length < this.props.reports.length ? (
              <ReportsList
                reports={this.state.filteredReports}
                onReportSelected={this.handleReportSelected}
                reportCategory={ReportsWithCategories[0]}
              />
            ) : null}
            {Object.keys(this.state.reportCategory).length === 0 && this.state.filteredReports.length === 0 && (
              <EmptyState isPaper contrast message="Brak wyników wyszukiwania raportów" />
            )}
          </ContentWrapper>
          {this.renderGenerateReportDialog()}
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

ReportsPage.propTypes = {
  generateReportUi: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  attributeSets: PropTypes.array.isRequired,
  reliefs: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  reports: PropTypes.array.isRequired,
  isMobile: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    reports: state.reports,
    generateReportUi: state.generateReportUi,
    groups: state.groups,
    reliefs: state.configuration.reliefs,
    activities: state.activities,
    attributeSets: state.configuration.attributesSets
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(reportsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withMediaQuery()(ReportsPage));
