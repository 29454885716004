import PropTypes from 'prop-types';
import React from 'react';
import NotificationHandlerContainer from './notificationsHandler/NotificationHandlerContainer';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.color.primary,
    color: '#fff',
    minHeight: '100vh'
  }
}));

const PinApp = ({ children }) => {
  const cl = useStyles();
  return (
    <Box className={cl.root}>
      {children}
      <NotificationHandlerContainer />
    </Box>
  );
};

PinApp.propTypes = {
  children: PropTypes.object.isRequired
};
export default PinApp;
