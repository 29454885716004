import PropTypes from 'prop-types';
import React from 'react';
import CalendarDayContent from '../../../calendar/Grid/CalendarDayContent';
import ErrorIcon from '@mui/icons-material/Error';
import { MenuItem, Typography } from '@mui/material';
import IconMenu from '../../../menu/IconMenu';
import { useTheme } from '@emotion/react';

const InvalidDayContent = ({ day, containerWidth, onReportAttendance, onReportAbsence, isReadOnly }) => {
  const theme = useTheme();
  return (
    <CalendarDayContent
      day={day}
      containerWidth={containerWidth}
      primaryText={
        <Typography component="span" sx={{ color: theme.palette.color.color14 }}>
          Nie zapisano wyjścia
        </Typography>
      }
      primaryIcon={
        <IconMenu icon={ErrorIcon} color={theme.palette.color.color14} isReadOnly={isReadOnly} dense>
          {() => [
            <MenuItem key="1" onClick={() => onReportAttendance(day)}>
              Zmień godziny obecności
            </MenuItem>,
            <MenuItem key="2" onClick={() => onReportAbsence(day)}>
              Wycofaj obecność
            </MenuItem>
          ]}
        </IconMenu>
      }
    />
  );
};

InvalidDayContent.propTypes = {
  day: PropTypes.object.isRequired,
  containerWidth: PropTypes.number.isRequired,
  onReportAttendance: PropTypes.func.isRequired,
  onReportAbsence: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool
};

export default InvalidDayContent;
