import PropTypes from 'prop-types';
import React from 'react';
import { List, ListItemIcon, ListItemText, ListItemSecondaryAction, ListItem } from '@mui/material';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import EmptyState from '../../common/EmptyState';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import _ from 'lodash';
import DotsMenu from '../../common/menu/DotsMenu';

const DiagnosticSchemesSetsList = ({ sets, onSelected, onRemove }) => {
  const renderListItem = (set) => {
    return (
      <React.Fragment key={set.id}>
        <ListItem
          button
          onClick={() => onSelected(set)}
          component="li"
          aria-label={`Edytuj szablon diagnoz ${set.title}`}
          sx={{
            '&:hover': {
              background: (theme) => theme.palette.background.hover
            }
          }}>
          <ListItemIcon>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText primary={set.title} />
          <ListItemSecondaryAction>
            <DotsMenu>
              {(onClose) => [
                <ActionConfirmationMenuItem
                  key="1"
                  onItemClick={onClose}
                  actionLabel="Usuń"
                  confirmationText="Czy na pewno chcesz usunąć szablon diagnozy?"
                  confirmationTitle="Usuń szablon diagnoz"
                  confirmLabel="Usuń"
                  onConfirm={() => onRemove(set)}
                  onCancel={() => {}}
                />
              ]}
            </DotsMenu>
          </ListItemSecondaryAction>
        </ListItem>
      </React.Fragment>
    );
  };

  const renderList = (array) => {
    const newSortedArray = _.sortBy(array, (s) => s.name);
    return <List>{newSortedArray.map((item) => renderListItem(item))}</List>;
  };

  return !sets.length ? <EmptyState contrast message="Nie zdefiowano jeszcze żadnych szablonów" /> : renderList(sets);
};

DiagnosticSchemesSetsList.propTypes = {
  sets: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default DiagnosticSchemesSetsList;
