import { ChildModel, ChildDetailsModel } from './ChildModels';
import { ArchivalInfoModel } from '../ArchivalInfoModel';

export class ChildArchiveModel extends ChildModel {
  constructor() {
    super();
    this.archivalInfo = new ArchivalInfoModel();
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.archivalInfo = new ArchivalInfoModel().assign(assigned.archivalInfo);
  }
}

export class ChildArchiveDetailsModel extends ChildDetailsModel {
  constructor() {
    super();
    this.archivalInfo = new ArchivalInfoModel();
  }

  onAssignment(assigned) {
    super.onAssignment(assigned);
    /* eslint no-param-reassign: 'off' */
    assigned.archivalInfo = new ArchivalInfoModel().assign(assigned.archivalInfo);
  }
}
