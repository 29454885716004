/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ChildArchiveModel } from '../models/children/ChildArchiveModels';

export default function childrenArchiveReducer(state = initialState.archives.children, action) {
  switch (action.type) {
    case types.LOAD_CHILDREN_ARCHIVE_SUCCESS: {
      return action.children.map((child) => new ChildArchiveModel().assign(child));
    }
    default:
      return state;
  }
}
