import { Stack } from '@mui/material';
import React from 'react';
import { Box, MenuItem, TextField } from '@mui/material';
import SelectField from '../../../common/SelectField';
import TimePicker from '../../../common/TimePicker';
import DatePicker from '../../../common/DatePicker';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import DialogForm from '../../../forms/DialogForm';
import moment from 'moment';
import { TimeModel } from '../../../../models/TimeModel';
import { ContactValidator } from './ContactValidator';
import { addContactToSpecialitNotes, editSpecialistContact } from '../../../../actions/specialistNotesActions';

const ContactDialog = ({ open = false, handleCancel, id, selectedContact, isReadonly, dialogTitle, workId }) => {
  const [contactType, setcontactType] = useState(selectedContact?.row.contactType || 'institution');

  const [note, setNote] = useState(selectedContact?.row.note);
  const [date, setDate] = useState(selectedContact?.row.eventDate || null);
  const [time, setTime] = useState(
    selectedContact ? new TimeModel().parse(selectedContact?.row.eventTime)?.toDate() : null
  );
  const [place, setPlace] = useState(selectedContact?.row.location);
  const [purpose, setPuproseOfMeeting] = useState(selectedContact?.row.purpose);
  const dispatch = useDispatch();
  const address = useSelector((state) => state.configuration.unit.address);

  const Ui = useSelector((state) => state.specialistNotesUi.contacts);
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    const data = {
      id: selectedContact?.row.id,
      note: note,
      contactType: contactType,
      eventDate: moment(date).format('YYYY-MM-DD'),
      eventTime: moment(time).format('HH:mm'),
      location: place,
      purpose: purpose
    };
    selectedContact
      ? dispatch(editSpecialistContact(data, workId))
      : dispatch(addContactToSpecialitNotes(data, workId));
    handleCancel();
  };

  useEffect(() => {
    setPlace(`${address.city} ${address.address}`);
  }, [address.address, address.city]);

  return (
    <DialogForm
      errors={errors}
      header={dialogTitle}
      maxWidth="md"
      hideSubmit={isReadonly}
      onSave={handleSave}
      isEditing={Ui.isEditing}
      isProcessing={Ui.isProcessing}
      onCancel={handleCancel}
      isDialogOpen={open}
      onValidate={() =>
        new ContactValidator().validate({
          id: id,
          note: note,
          contactType: contactType,
          eventDate: date,
          eventTime: time,
          location: place,
          purpose: purpose
        })
      }
      onValidationDone={(errors) => {
        setErrors(errors);
      }}
      statePathToUi="specialistNotesUi.contacts"
      requiredFieldText>
      <Box sx={{ display: 'flex' }}>
        <SelectField
          errorText={errors?.contactType}
          defaultValue="parent"
          contrast
          disabled={isReadonly}
          fullWidth
          floatingLabelText="Kontakt*"
          floatingLabelFixed={false}
          id="contactType"
          value={contactType}
          onChange={(e) => {
            setcontactType(e.target.value);
          }}>
          <MenuItem key="Rodzic" value="parent">
            Rodzic
          </MenuItem>
          <MenuItem key="Instytucja" value="institution">
            Instytucja
          </MenuItem>
        </SelectField>
      </Box>
      <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ my: 1 }}>
        <DatePicker
          disabled={isReadonly}
          unlimitedMaxDate
          errorText={errors?.eventDate}
          hintText="Data wydarzenia*"
          floatingLabelText="Data wydarzenia*"
          name="date"
          value={date}
          onChange={(empty, date) => {
            setDate(date);
          }}
        />
        <Box sx={{ mt: { xs: 1, sm: 0 } }}>
          <TimePicker
            disabled={isReadonly}
            errorText={errors?.eventTime}
            floatingLabelText="Godzina spotkania*"
            value={time}
            onChange={(e) => setTime(e)}
            name="time"
          />
        </Box>
      </Stack>
      <TextField
        disabled={isReadonly}
        error={errors?.location}
        helperText={errors?.location}
        multiline
        fullWidth
        variant="standard"
        sx={{ my: 1 }}
        id="cause"
        label="Miejsce spotkania*"
        value={place}
        onChange={(e) => setPlace(e.target.value)}
      />
      <TextField
        disabled={isReadonly}
        error={errors?.purpose}
        helperText={errors?.purpose}
        multiline
        fullWidth
        variant="standard"
        sx={{ my: 1 }}
        id="purpose"
        label="Cel spotkania*"
        value={purpose}
        onChange={(e) => setPuproseOfMeeting(e.target.value)}
      />
      <TextField
        disabled={isReadonly}
        multiline
        error={errors?.note}
        helperText={errors?.note}
        fullWidth
        variant="standard"
        sx={{ my: 1 }}
        id="note"
        label="Notatka*"
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
    </DialogForm>
  );
};

export default ContactDialog;
