import ApiBase, { ROOT_URL } from './ApiBase';

const dummyDataPOST = {
  title: 'Test12',
  content: 'Test15',
  date: '2020-08-19'
};

const dummyDataPUT = {
  title: 'NewTitle',
  content: 'newContent22',
  eventDate: '2020-08-19'
};

class TestApi extends ApiBase {
  static getSomeData() {
    return super.toPromise((agent) =>
      agent
        .get(`${ROOT_URL}/fkIntegration/export-by-email`)
        // .get(`${ROOT_URL}/digitalDiary/workPlan/2020-08-17/f522b34c-b24c-4c7e-97f7-48b74d9d343c`)
        .type('application/json')
    );
  }

  static postSomeData(someData = dummyDataPOST) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/jobs/monthlyDues`).send(someData).type('application/json')
    );
  }

  static modify(someData = dummyDataPUT) {
    return super.toPromise((agent) => agent.put(`${ROOT_URL}/endpoint/here`).send(someData).type('application/json'));
  }
}
export default TestApi;
