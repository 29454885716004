import React from 'react';
import PropTypes from 'prop-types';
import SearchInputV2 from '../../../../components/searchInputV2/SearchInputV2';
import FilterByRole from '../../../../components/common/filters/filterByRole/FilterByRole';
import { useTheme, Box } from '@mui/material';
import Expand from './Expand/Expand';
import { useShareFileContext } from '../../context/ShareFileContext';

const ShareSearchBar = ({
  searchText,
  onSearch,
  onClearSearch,
  groups,
  selectedGroup,
  onSelectGroup,
  onResetFilters,
  onResetGroups,
  filterBy,
  onFilterBy,
  onExpandAction,
  isAvailableListEmpty,
  forceOpenCollapse
}) => {
  const { isMobile, isDisabled } = useShareFileContext();

  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', p: 'auto' }}>
      <SearchInputV2
        contrast
        isMobile={isMobile}
        searchText={searchText}
        onSearch={onSearch}
        onClear={onClearSearch}
        isDivider={false}
        style={{ flex: 1 }}
        sx={{ p: 1 }}
        InputProps={{
          style: { fontSize: '18px', width: '100%' },
          placeholder: 'Wyszukaj pojedyncze osoby',
          inputProps: { 'aria-label': 'Wyszukaj pojedyncze osoby z listy' }
        }}
        disabled={isDisabled}
      />
      {!isAvailableListEmpty && (
        <Expand
          onClick={onExpandAction}
          isForceOpenCollapse={forceOpenCollapse}
          isDisabled={isDisabled}
          isVisible={!isAvailableListEmpty}
        />
      )}
      <FilterByRole
        groups={groups}
        filterBy={filterBy}
        onFilterBy={onFilterBy}
        selectedGroup={selectedGroup}
        onSelectGroup={onSelectGroup}
        resetFilters={onResetFilters}
        onResetGroups={onResetGroups}
        iconProps={{ style: { fontSize: 32, color: isDisabled ? theme.palette.color.disabled : undefined } }}
        disabled={isDisabled}
      />
    </Box>
  );
};

ShareSearchBar.propTypes = {
  filterBy: PropTypes.string.isRequired,
  groups: PropTypes.array,
  onClearSearch: PropTypes.func.isRequired,
  onFilterBy: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  onResetGroups: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelectGroup: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  selectedGroup: PropTypes.string,
  onExpandAction: PropTypes.func,
  forceOpenCollapse: PropTypes.bool,
  isAvailableListEmpty: PropTypes.bool
};

export default ShareSearchBar;
