import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import SchemeDescription from '../configuration/chargingSchemes/schemeDescriptions/SchemeDescription';
import SelectField from '../common/SelectField';

const ManageCateringApplicationDialog = (props) => {
  const { open, close, cateringChargingSchemes, save, selectedApplication, childApplications } = props;

  const [selectedCateringSchemeId, setSelectedCateringSchemeId] = useState('');

  const filteredCateringChargingSchemes = cateringChargingSchemes.filter((item) => {
    const isA = item.availableForLegalGuardians === true;
    const isB = childApplications.some((app) => app.schemeId === item.id && !app.rejectedBy && !app.acceptedBy);
    return isA && !isB;
  });

  useEffect(() => {
    if (selectedApplication === null) {
      setSelectedCateringSchemeId('');
    } else {
      setSelectedCateringSchemeId(selectedApplication.schemeId);
    }
  }, [selectedApplication]);

  const onSave = () => {
    if (selectedCateringSchemeId === '') {
      return;
    }

    save(selectedCateringSchemeId);
    setSelectedCateringSchemeId('');
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm">
      <DialogTitle>{selectedApplication == null ? 'Składanie wniosku' : 'Edycja wniosku'}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {filteredCateringChargingSchemes.length ? (
              <SelectField
                contrast
                fullWidth={true}
                displayEmpty={true}
                value={selectedCateringSchemeId}
                onChange={(e) => setSelectedCateringSchemeId(e.target.value)}
                inputProps={{ 'aria-label': 'Wybierz wyżywienie' }}>
                <MenuItem key="wybierz wyżywienie" value="">
                  Wybierz wyżywienie
                </MenuItem>
                {filteredCateringChargingSchemes.map((c) => {
                  return (
                    <MenuItem key={c.id} value={c.id}>
                      <Box sx={{ width: '100%' }}>
                        <Typography sx={{ pr: 2, fontWeight: (theme) => theme.typography.fontWeightBold }}>
                          {c.name}
                        </Typography>
                        <SchemeDescription scheme={c} />
                      </Box>
                    </MenuItem>
                  );
                })}
              </SelectField>
            ) : (
              <Typography variant="body2">Brak wniosków do złożenia </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mt: 3 }}>
        <Button variant="outlinedContrast" onClick={close} sx={{ ml: 2 }}>
          Anuluj
        </Button>
        {filteredCateringChargingSchemes.length ? (
          <Button variant="contained" onClick={onSave}>
            Zapisz
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

ManageCateringApplicationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  cateringChargingSchemes: PropTypes.array.isRequired,
  save: PropTypes.func.isRequired,
  selectedApplication: PropTypes.object,
  childApplications: PropTypes.array
};

export default ManageCateringApplicationDialog;
