import ApiBase, { ROOT_URL } from './ApiBase';

class GalleryApi extends ApiBase {
  static createAlbum(data) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/gallery/createAlbum`).send(data).type('application/json')
    );
  }

  static getAlbums() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/gallery`).type('application/json'));
  }

  static updateAlbum(albumData) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/gallery/updateAlbum`).send(albumData).type('application/json')
    );
  }

  static deleteAlbum(id) {
    return super.toPromise((agent) => agent.delete(`${ROOT_URL}/gallery/deleteAlbum/${id}`).type('application/json'));
  }

  static addMultimedia(data) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/gallery/addMultimedia`).send(data).type('application/json')
    );
  }

  static getMultimedia(albumId, params) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/gallery/getMultimedia/${albumId}`).query(params).type('application/json')
    );
  }

  static updateMultimedia(data) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/gallery/updateMultimedia`).send(data).type('application/json')
    );
  }

  static deleteMultimedia(albumId, ids) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/gallery/deleteMultimedia`).query({ ids }).query({ albumId }).type('application/json')
    );
  }
}

export default GalleryApi;
