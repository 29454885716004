import PropTypes from 'prop-types';
import React from 'react';
import DeleteConfirmationButton from './dialogs/DeleteConfirmationButton';
import makeStyles from '@mui/styles/makeStyles';
import EmptyState from '../../common/EmptyState';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  textfield: {
    '& 	.MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.color.contrast
    },
    borderBottom: `1px solid ${theme.palette.color.contrast}`,

    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: theme.palette.color.contrast
    }
  }
}));

const WorkPlanTopicList = ({ sets, onSelected, onRemove, isApproved }) => {
  const cl = useStyles();

  const workPlanListItem = (item, i) => {
    const { topicName, todo, books, comments } = item;
    return (
      <Paper key={topicName + i} sx={{ p: 4 }} elevation={0}>
        <Typography variant="h6">{topicName}</Typography>
        <TextField
          className={cl.textfield}
          sx={{
            mt: 2
          }}
          multiline
          InputProps={{ disableUnderline: true }}
          variant="standard"
          label="Działania edukacyjne"
          autoFocus={false}
          value={todo}
          fullWidth={true}
          disabled={true}
        />
        <TextField
          className={cl.textfield}
          sx={{
            mt: 2
          }}
          multiline
          InputProps={{ disableUnderline: true }}
          variant="standard"
          label="Środki dydaktyczne"
          autoFocus={false}
          value={books}
          fullWidth={true}
          disabled={true}
        />
        <TextField
          className={cl.textfield}
          sx={{
            mt: 2
          }}
          multiline
          InputProps={{ disableUnderline: true }}
          variant="standard"
          label="Uwagi nauczyciela"
          autoFocus={false}
          value={comments}
          fullWidth={true}
          disabled={true}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2 }}>
          <DeleteConfirmationButton
            isDisabled={isApproved}
            onCancel={() => {}}
            onConfirm={() => onRemove(i)}
            actionLabel="Usuń"
            confirmLabel="Usuń"
            confirmationText="Czy na pewno chcesz usunąć temat?"
            confirmationTitle="Usuń temat"
          />
          <Button
            disabled={isApproved}
            variant="contained"
            aria-label="Edytuj"
            onClick={() => onSelected(item, i)}
            sx={{ float: 'right' }}>
            Edytuj
          </Button>
        </Box>
      </Paper>
    );
  };

  const renderList = (array) => {
    return <Box>{array.map((item, i) => workPlanListItem(item, i))}</Box>;
  };

  return !sets.length ? <EmptyState message="Nie zdefiowano jeszcze żadnego tematu" /> : renderList(sets);
};

WorkPlanTopicList.propTypes = {
  sets: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isApproved: PropTypes.bool.isRequired
};

export default WorkPlanTopicList;
