import PropTypes from 'prop-types';
import React from 'react';
import CalendarDayContent from '../../../calendar/Grid/CalendarDayContent';
import { MenuItem } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import moment from 'moment';
import IconMenu from '../../../menu/IconMenu';

const UndeclaredDayContent = ({
  day,
  containerWidth,
  absenceNotes,
  onReportAttendance,
  onReportAbsence,
  isReadOnly,
  isActivityCalendar
}) => {
  return (
    <CalendarDayContent
      day={day}
      containerWidth={containerWidth}
      primaryText={absenceNotes || ''}
      primaryIcon={
        isReadOnly ? null : (
          <IconMenu sx={{ ml: 1 }} icon={AddCircleOutlineIcon} dense>
            {(onClose) => [
              moment(day.date).isSameOrBefore(moment()) ? (
                <MenuItem
                  key="1"
                  onClick={() => {
                    onClose();
                    onReportAttendance(day);
                  }}>
                  {!isActivityCalendar ? 'Wprowadź godziny obecności' : 'Wprowadź obecność'}
                </MenuItem>
              ) : null,
              <MenuItem
                key="2"
                onClick={() => {
                  onClose();
                  onReportAbsence(day);
                }}>
                Wprowadź nieobecność
              </MenuItem>
            ]}
          </IconMenu>
        )
      }
    />
  );
};

UndeclaredDayContent.propTypes = {
  day: PropTypes.object.isRequired,
  containerWidth: PropTypes.number.isRequired,
  absenceNotes: PropTypes.string,
  onReportAttendance: PropTypes.func.isRequired,
  onReportAbsence: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  isActivityCalendar: PropTypes.bool
};

export default UndeclaredDayContent;
