import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Header from '../../Components/Albums/Managment/Header';
import * as AlbumTypes from '../../../../constants/groupsAndActivitiesTypes';
import { Box, Paper, Stack, Typography } from '@mui/material';
import GalleryAlbumForm, {
  InitialValues,
  validate,
  VALIDATION_SCHEMA
} from '../../../../forms/gallery/GalleryAlbumForm';
import FormDialog from '../../../formik/formDialog/FormDialog';
import { browserHistory } from 'react-router';
import { routePaths } from '../../../../routePaths';
import { useDispatch, useSelector } from 'react-redux';
import * as galleryActions from '../../../../actions/galleryActions';
import ConfirmationDialog from '../../../forms/ConfirmationDialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined';
import IconButtonPipe from '../../../common/IconButtonPipe';
import SingleAlbum from '../../Components/Albums/SingleAlbum/SingleAlbum';
import { prepareData as prepareSingleData } from './Albums';
import { useIsMount } from '../../../../utils/useIsMount';

const prepareData = ({ limitedToGroups, limitedToActivities }) => {
  if (!Array.isArray(limitedToGroups) && !Array.isArray(limitedToGroups)) return null;

  if (!limitedToGroups.length && !limitedToActivities.length) return { data: null, type: AlbumTypes.PUBLIC };
  if (limitedToGroups.length >= 1 && !limitedToActivities.length) {
    return {
      data: limitedToGroups,
      type: AlbumTypes.GROUP
    };
  }
  if (!limitedToGroups.length && limitedToActivities.length >= 1) {
    return {
      data: limitedToActivities,
      type: AlbumTypes.ACTIVITY
    };
  }
  return null;
};

const AlbumManagement = ({ album, onBack, groups, activities, isEditAvailable }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.gallery.ui.isLoading);
  const isSuccess = useSelector((state) => state.gallery.ui.isSuccess);
  const target = useSelector((state) => state.gallery.ui.target);
  const isFirstRender = useIsMount();

  const handleOnBack = () => {
    browserHistory.push({
      pathname: routePaths.gallery,
      query: {}
    });
    onBack();
  };

  useEffect(() => {
    if (!isFirstRender) {
      if (isLoading === false && isSuccess && target === galleryActions.loadingTypes.UPDATE) {
        setIsDialogOpen(false);
      } else if (isLoading === false && isSuccess && target === galleryActions.loadingTypes.DELETE) {
        setIsDeleteDialogOpen(false);
        handleOnBack();
      }
    }
  }, [isLoading]);

  const handleAlbumUpdate = (values) => dispatch(galleryActions.updateAlbum({ ...album, ...values }, album));
  const handleDeleteAlbum = () => dispatch(galleryActions.deleteAlbum(album));

  const initialValues = new InitialValues().assign(album);
  initialValues.setType(prepareData(initialValues).type);

  return (
    <Box>
      <Paper sx={{ p: 2 }}>
        <Header
          onBack={handleOnBack}
          album={album}
          openDialog={() => setIsDialogOpen(!isDialogOpen)}
          isEditAvailable={isEditAvailable}
          {...prepareData(album)}
        />
        <FormDialog
          titleStyles={{ flexDirection: 'row-reverse', justifyContent: 'space-between' }}
          maxWidth="lg"
          title="Edytuj album"
          titleIcon={
            <IconButtonPipe onClick={() => setIsDeleteDialogOpen(true)} tooltip="Usuń album">
              <DeleteForeverIcon sx={{ fontSize: 32 }} />
            </IconButtonPipe>
          }
          open={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
          }}
          initialValues={initialValues}
          validationSchema={VALIDATION_SCHEMA}
          validate={validate}
          onSubmit={handleAlbumUpdate}
          requiredFieldText
          inProgress={isLoading && target === galleryActions.loadingTypes.UPDATE}>
          <GalleryAlbumForm groups={groups} activities={activities} isEdit />
        </FormDialog>
      </Paper>

      <ConfirmationDialog
        confirmationTitle={
          <Stack alignItems="start">
            <Typography variant="h5" sx={{ ml: 2, fontWeight: (theme) => theme.typography.fontWeightMedium }}>
              Czy na pewno chcesz usunąć album:
            </Typography>
            <SingleAlbum
              {...album}
              {...prepareSingleData(album)}
              isOnList
              isTiny
              sx={{ background: (theme) => theme.palette.background.hover }}
            />
          </Stack>
        }
        confirmationText="Potwierdzając wszystkie multimedia znajdujące się w albumie zostaną usunięte."
        onConfirm={handleDeleteAlbum}
        onCancel={() => {
          setIsDeleteDialogOpen(false);
        }}
        confirmLabel="Usuń"
        isSecondary
        inProgress={isLoading && target === galleryActions.loadingTypes.DELETE}
        isOpen={isDeleteDialogOpen}
      />
    </Box>
  );
};

AlbumManagement.propTypes = {
  album: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  isEditAvailable: PropTypes.bool.isRequired
};

export default AlbumManagement;
