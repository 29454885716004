'use strict';

import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import ConfirmationDialog from '../../forms/ConfirmationDialog';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const useStyles = makeStyles((theme) => ({
  noPicIcon: {
    width: '48px',
    height: '48px',
    color: theme.palette.color.color2
  },
  otherPicIcon: {
    width: '35px',
    height: '35px',
    color: theme.palette.color.color2
  },
  boxMenu: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 50,
    opacity: 0.7,
    background: theme.palette.background.color7
  },
  dotsButton: {
    width: 50,
    height: 50,
    p: 0,
    color: theme.palette.color.primary
  },
  avatarDropZoneContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    visibility: 'visible',
    flexDirection: 'column',
    borderRadius: '5px',
    opacity: 0,
    '&:hover ': {
      '&': {
        opacity: 0.7,
        background: theme.palette.background.color7
      }
    }
  },
  avatarDropZoneContentMobile: {
    display: 'none'
  },
  avatarDropZone: {
    display: 'flex',
    width: '150px',
    height: '150px',
    borderRadius: '5px',
    justifyContent: 'center',
    textAlign: 'center',
    alignAtems: 'center',
    fontWeight: 500,
    backgroundSize: 'cover',
    backgroundPositionX: 'center'
  }
}));

const AvatarDropzone = ({ onPhotoDrop, photoUrl, readonly, onPhotoRemove, style, sx }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    onPhotoDrop(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: 'image/*',
    inputProps: { 'aria-label': 'Strefa umieszczania plików' },
    disabled: readonly,
    multiple: false
  });

  const renderEmptyPhoto = () => {
    return (
      <Stack sx={{ width: '100%', height: '100%' }} alignItems="center" justifyContent={'center'}>
        <PhotoCameraIcon className={classes.noPicIcon} />
      </Stack>
    );
  };

  const renderAddPhotoZone = () => {
    return (
      <Button
        aria-label="Zaktualizuj"
        sx={{
          width: '100%',
          height: '50%',
          py: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 'grow'
        }}
        onClick={() => open()}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 'grow'
          }}>
          <AddAPhotoIcon className={classes.otherPicIcon} />
          <Typography>Zaktualizuj</Typography>
        </Box>
      </Button>
    );
  };

  const renderRemovePhotoZone = () => {
    return (
      <Button
        aria-label="Usuń"
        sx={{
          py: 0,
          width: '100%',
          height: '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 'grow'
        }}
        onClick={() => {
          setIsRemoveDialogOpen(true);
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <DeleteIcon className={classes.otherPicIcon} />
          <Typography>Usuń</Typography>
        </Box>
      </Button>
    );
  };

  const renderRemoveConfirmationDialog = () => {
    if (!isRemoveDialogOpen) return null;
    return (
      <ConfirmationDialog
        confirmationText="Czy na pewno chcesz usunąć zdjęcie?"
        confirmationTitle="Usuń zdjęcie"
        confirmLabel="Usuń"
        onConfirm={() => {
          setIsRemoveDialogOpen(false);
          onPhotoRemove();
        }}
        onCancel={() => {
          setIsRemoveDialogOpen(false);
        }}
        isOpen={isRemoveDialogOpen}
      />
    );
  };
  const [anchorEl, setAnchorEl] = useState();
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };
  const backgroundImage = photoUrl ? `url(${photoUrl})` : null;

  return (
    <Box
      className={classes.avatarDropZone}
      sx={{ position: 'relative', backgroundImage, ...style, ...sx, cursor: readonly ? 'default' : 'pointer' }}
      border={photoUrl ? 'none' : '2px dashed gray'}
      {...getRootProps()}>
      <FormControl>
        <InputLabel>Wybierz plik</InputLabel>
        <input {...getInputProps()} aria-label="upload" />
      </FormControl>
      {isMobile && !readonly && (
        <Stack direction="row" justifyContent="flex-end" className={classes.boxMenu}>
          <IconButton id="demo-positioned-button" className={classes.dotsButton} onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}>
            {photoUrl && !readonly && <MenuItem onClick={handleClose}>{renderRemovePhotoZone()}</MenuItem>}
            {!readonly && <MenuItem onClick={handleClose}>{renderAddPhotoZone()}</MenuItem>}
          </Menu>
        </Stack>
      )}
      <Box
        className={
          readonly ? undefined : isMobile ? classes.avatarDropZoneContentMobile : classes.avatarDropZoneContent
        }
        sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {!photoUrl && readonly && renderEmptyPhoto()}
        {!readonly && renderAddPhotoZone()}
        {photoUrl && !readonly && <Typography component="hr" sx={{ width: '100%' }} />}
        {photoUrl && !readonly && renderRemovePhotoZone()}
        {photoUrl && !readonly && renderRemoveConfirmationDialog()}
      </Box>
    </Box>
  );
};

AvatarDropzone.propTypes = {
  photoUrl: PropTypes.string,
  onPhotoDrop: PropTypes.func.isRequired,
  onPhotoRemove: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  style: PropTypes.object,
  sx: PropTypes.object
};

export default AvatarDropzone;
