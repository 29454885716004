import api from '../api/ReliefsApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import { browserHistory } from 'react-router';
import { routePaths } from '../routePaths';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';

export function loadReliefsAsync() {
  return (dispatch, getState) => {
    if (getState().configuration.reliefs.length === 0) {
      dispatch({ type: types.LOAD_RELIEFS });
      return api
        .getReliefs()
        .then((reliefs) => dispatch({ type: types.LOAD_RELIEFS_SUCCESS, reliefs }))
        .catch((error) => {
          dispatch(notificationActions.showError('Nie udało się pobrać listy ulg'));
          return logger.error(error);
        });
    }
    return Promise.resolve();
  };
}

export function createReliefDetailsStart() {
  return { type: types.CREATE_RELIEF_DETAILS };
}

export function createReliefDetailsCancel() {
  return { type: types.CREATE_RELIEF_DETAILS_CANCEL };
}

export function createReliefDetailsAsync(nextRelief) {
  const relief = Object.assign({}, nextRelief);
  return (dispatch) => {
    dispatch({ type: types.CREATE_RELIEF_DETAILS_SUBMIT });
    return api
      .createRelief(relief)
      .then((id) => {
        dispatch(notificationActions.showSuccess(`Dodano ulgę '${relief.name}'`));
        dispatch({ type: types.CREATE_RELIEF_DETAILS_SUCCESS, relief: Object.assign(relief, id) });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.CREATE_RELIEF_DETAILS_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError(`Nie udało się dodać ulgi '${relief.name}'`));
        return logger.error(error);
      });
  };
}

export function archiveReliefDetailsAsync(id) {
  return (dispatch, getState) => {
    dispatch({ type: types.ARCHIVE_RELIEF_DETAILS });
    return api
      .archiveRelief(id)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Usunięto ulgę '${get.reliefName(getState, id)}'`));
        dispatch({ type: types.ARCHIVE_RELIEF_DETAILS_SUCCESS, reliefId: id });
        browserHistory.push(routePaths.configurationReliefs);
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć ulgi '${get.reliefName(getState, id)}'`));
        return logger.error(error);
      });
  };
}

export function loadExpiringReliefsAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_EXPIRING_RELIEFS });
    return api
      .getExpiringReliefs()
      .then((expiringReliefs) => dispatch({ type: types.LOAD_EXPIRING_RELIEFS_SUCCESS, expiringReliefs }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać listy kończących się ulg'));
        return logger.error(error);
      });
  };
}
