import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import DatePicker from '../../DatePicker';
import TextField from '../../TextField';
import { Box } from '@mui/material';

const ReadonlyGuardianExtendAuthorizationDateForm = ({ guardian, onChange, errors }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        onChange={onChange}
        disabled={true}
        hintText="Imię"
        floatingLabelText="Imię"
        floatingLabelFixed={false}
        name="firstName"
        value={guardian.firstName}
        autocomplete="given-name"
      />
      <TextField
        onChange={onChange}
        disabled={true}
        hintText="Nazwisko"
        floatingLabelText="Nazwisko"
        floatingLabelFixed={false}
        name="lastName"
        value={guardian.lastName}
        fullWidth={true}
        autocomplete="family-name"
      />
      <TextField
        onChange={onChange}
        disabled={true}
        hintText="Stopień pokrewieństwa"
        floatingLabelText="Stopień pokrewieństwa"
        floatingLabelFixed={false}
        name="relationship"
        value={guardian.relationship}
        fullWidth={true}
      />
      <TextField
        onChange={onChange}
        disabled={true}
        hintText="Numer dowodu osobistego lub paszportu"
        floatingLabelText="Numer dokumentu tożsamości"
        floatingLabelFixed={false}
        name="documentNumber"
        value={guardian.documentNumber}
        fullWidth={true}
      />
      <DatePicker
        hintText="Data ważności upoważnienia (włącznie)"
        floatingLabelText="Data obowiązywania upoważnienia"
        floatingLabelFixed={false}
        name="authorizationExpirationDate"
        value={guardian.authorizationExpirationDate}
        errorText={errors.authorizationExpirationDate}
        minDate={moment.utc().toDate()}
        maxDate={moment.utc().add(1, 'year').toDate()}
        onChange={(empty, date) => {
          onChange({ target: { name: 'authorizationExpirationDate', value: date } });
        }}
      />
      <TextField
        onChange={onChange}
        disabled={true}
        hintText="Numer telefonu kontaktowego"
        floatingLabelText="Numer telefonu kontaktowego"
        floatingLabelFixed={false}
        name="phoneNumber"
        value={guardian.phoneNumber}
        autocomplete="tel"
      />
    </Box>
  );
};

ReadonlyGuardianExtendAuthorizationDateForm.propTypes = {
  guardian: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ReadonlyGuardianExtendAuthorizationDateForm;
