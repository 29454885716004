/*eslint indent:0*/
import initialState from './initial';
import { ChildDetailsSummaryModel } from '../models/children/ChildDetailsSummary';
import * as types from '../actions/actionTypes';

export default function childDetailsReducer(state = initialState.childDetailsSummary, action) {
  switch (action.type) {
    case types.LOAD_CHILD_DETAILS_SUMMARY: {
      return action.childDetails.map((detail) => new ChildDetailsSummaryModel().assign(detail));
    }
    default:
      return state;
  }
}
