export function error(err) {
  /* eslint-disable no-console */

  // i want to ignore termination errors related to redirection
  if (err && !err.status && err.message && err.message.indexOf('Request has been terminated') > -1) return;

  console.error(err);

  if (window.Rollbar) {
    window.Rollbar.error(err, (rollbarError, rollbarData) => {
      if (rollbarError) {
        return console.log(`Error while reporting error to Rollbar: ${rollbarError}`);
      }

      return console.log(`Error successfully reported to Rollbar. UUID: ${rollbarData.result.uuid}`);
    });
  }
  /* eslint-enable no-console */
}
