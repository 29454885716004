'use strict';

import { Typography } from '@mui/material';
import React from 'react';
import WelcomeScreen from '../common/welcomeScreen/WelcomeScreen';

const NotFoundPage = () => {
  return (
    <WelcomeScreen>
      <Typography>
        Nie znaleziono placówki pod wskazanym adresem. Zweryfikuj wprowadzony adres i spróbuj ponownie.
      </Typography>
    </WelcomeScreen>
  );
};

export default NotFoundPage;
