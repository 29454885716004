import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CardEditForm from '../../forms/CardEditForm';
import StaffMemberForm from './StaffMemberForm';
import staffMemberRoles from '../../../constants/staffMemberRoles';

const StaffMemberDetailsCard = (props) => {
  const [errors, setErrors] = useState({});

  return (
    <CardEditForm
      hideCancel
      onSave={props.onSave}
      onValidate={props.onValidate}
      onValidationDone={(error) => setErrors({ error })}
      onCancel={props.onCancel}
      isProcessing={props.isProcessing}
      isExpanded={props.isEditing}
      statePathToUi="staffMemberDetailsUi.details"
      onCollapse={props.onCancel}
      onExpand={props.onEdit}
      title={props.title || `${props.member.firstName} ${props.member.lastName}`}
      requiredFieldText
      editForm={
        <StaffMemberForm
          staffMember={props.member}
          roles={staffMemberRoles}
          errors={errors}
          onChange={props.onChange}
        />
      }
    />
  );
};

StaffMemberDetailsCard.propTypes = {
  member: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isProcessing: PropTypes.bool,
  title: PropTypes.string
};

export default StaffMemberDetailsCard;
