import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Typography } from '@mui/material';
import Strong from './Strong';

const ErrorAlertBox = ({ onClose, message, state, errors, severity = 'error' }) => {
  if (errors) {
    return state ? (
      <Alert severity="error" onClose={onClose}>
        <Typography>
          {errors.length === 1 ? 'Plik ' : 'Każdy plik: '}
          <Strong>{errors.join(', ')}</Strong>
          {' przekracza maksymalny rozmiar (50MB) i nie może zostać dodany.'}
        </Typography>
      </Alert>
    ) : null;
  }

  return state ? (
    <Alert severity={severity} onClose={onClose}>
      {message}
    </Alert>
  ) : null;
};

ErrorAlertBox.propTypes = {
  onClose: PropTypes.func,
  message: PropTypes.string,
  state: PropTypes.bool.isRequired,
  errors: PropTypes.array,
  severity: PropTypes.string
};

export default ErrorAlertBox;
