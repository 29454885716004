import api from '../api/DuesExportApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function loadConfiguration() {
  return (dispatch) => {
    return api
      .getConfiguration()
      .then((configuration) => dispatch({ type: types.LOAD_DUES_EXPORT_SETTINGS_SUCCESS, configuration }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać konfiguracji eksportu'));
        return logger.error(error);
      });
  };
}

export function loadSymphonyConfiguration() {
  return (dispatch) => {
    return api
      .getSymphonyConfiguration()
      .then((configuration) => dispatch({ type: types.LOAD_DUES_EXPORT_SETTINGS_SUCCESS, configuration }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać konfiguracji eksportu'));
        return logger.error(error);
      });
  };
}

export function updateConfiguration(configuration) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_DUES_EXPORT_SETTINGS });
    return api
      .updateConfiguration(configuration)
      .then(() => dispatch({ type: types.UPDATE_DUES_EXPORT_SETTINGS_SUCCESS, configuration }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się zaktualizować ustawień eksportu'));
        return logger.error(error);
      });
  };
}

export function runExport(type, date, overrideDate) {
  return (dispatch) => {
    dispatch({ type: types.RUN_DUES_EXPORT });
    return api
      .runExport(type, date, overrideDate)
      .then(() => dispatch({ type: types.RUN_DUES_EXPORT_SUCCESS, date }))
      .catch((error) => {
        dispatch(notificationActions.showError('Wystąpił błąd podczas eksportu'));
        return logger.error(error);
      });
  };
}

export function loadExportsHistory(type) {
  return (dispatch) => {
    return api
      .getExportsHistory(type)
      .then((history) => dispatch({ type: types.LOAD_DUES_EXPORTS_HISTORY_SUCCESS, history }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać historii eksportu'));
        return logger.error(error);
      });
  };
}
