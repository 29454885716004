import PropTypes from 'prop-types';
import React from 'react';
import InactiveIcon from '@mui/icons-material/Close';
import ActiveIcon from '@mui/icons-material/Check';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';

const AuthorizeMenuItem = React.forwardRef(({ isAuthorized, onAuthorize, onUnAuthorize, autoFocus }, ref) => (
  <MenuItem
    autoFocus={autoFocus}
    key="authorize"
    ref={ref}
    onClick={isAuthorized ? () => onUnAuthorize() : () => onAuthorize()}>
    <ListItemIcon>{isAuthorized ? <InactiveIcon /> : <ActiveIcon />}</ListItemIcon>
    <ListItemText primary={isAuthorized ? 'Anuluj upoważnienie' : 'Potwierdź upoważnienie'} />
  </MenuItem>
));

AuthorizeMenuItem.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  autoFocus: PropTypes.bool,
  onAuthorize: PropTypes.func,
  onUnAuthorize: PropTypes.func
};

export default AuthorizeMenuItem;
