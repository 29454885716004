import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import MenuRoute from './MenuRoute';

import HomeIcon from '@mui/icons-material/HomeOutlined';
import ChildrenIcon from '@mui/icons-material/FormatListNumberedOutlined';
import BookIcon from '@mui/icons-material/Book';
import MessageBoardIcon from '@mui/icons-material/SmsFailedOutlined';
import ReportsIcon from '@mui/icons-material/InsertChartOutlined';
import ChangeLogIcon from '@mui/icons-material/VerifiedOutlined';
import ElectronicDiaryIcon from '@mui/icons-material/AssignmentOutlined';
import TimeTable from '@mui/icons-material/DateRange';
import SaveDataIcon from '@mui/icons-material/Save';
import TravelIcon from '@mui/icons-material/CardTravel';
import ActivitiesIcon from '@mui/icons-material/GroupAddOutlined';
import WorkWithParentsIcon from '@mui/icons-material/GroupWork';
import WorkSchedule from '@mui/icons-material/Schedule';
import AttendanceIcon from '@mui/icons-material/HistoryOutlined';
import ImportantEventInGroupIcon from '@mui/icons-material/AssignmentLate';

import { eventTypes } from '../../../constants/eventTypes';
import moment from 'moment';
import { routePaths } from '../../../routePaths';
import { List, ListItem, ListItemButton, useTheme } from '@mui/material';
import insuranceLogoUrl from '../../../img/logo_b_1280.svg';
import insuranceLogoWhiteUrl from '../../../img/logo_b_1280_white.svg';
import InsuranceInfoDialog from '../../insuranceInfo/InsuranceInfoDialog';
import { connect, useSelector } from 'react-redux';
import { useThemeMode } from '../../../hooks/useThemeMode';
import { themeTypes } from '../../../styles/appTheme';

const TeacherSupportMenu = ({ firstGroupId, currentRoute, onRouteSelect, insurance, choosenGroup }) => {
  const themeMode = useThemeMode();
  const [insuranceDialog, setInsuranceDialog] = useState(false);
  const year = moment().format('YYYY');
  const month = moment().format('MM');
  let constKey = 0;
  const getNextKey = () => constKey++;
  const rest = { selectedRoutePath: currentRoute, onClick: onRouteSelect };
  const theme = useTheme();
  const isContrastColor = useSelector((state) => state.theme);
  return (
    <List>
      <MenuRoute
        text="Pulpit"
        icon={
          <HomeIcon
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
          />
        }
        routePath={routePaths.home}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Ogłoszenia"
        icon={
          <MessageBoardIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color25 }}
          />
        }
        routePath={routePaths.messageBoard}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        primaryText="Obecności"
        icon={
          <ElectronicDiaryIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
          />
        }
        primaryTogglesNestedList={true}
        nestedItems={[
          <MenuRoute
            text="Dziennik obecności"
            icon={
              <ElectronicDiaryIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
              />
            }
            routePath={routePaths.attendance}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Dziennik obecności - zajęcia dodatkowe"
            icon={
              <ElectronicDiaryIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
              />
            }
            routePath={routePaths.activitiesAttendanceSheetPage}
            key={getNextKey()}
            {...rest}
          />
        ]}
      />
      <MenuRoute
        primaryText="Dziennik elektroniczny"
        icon={
          <ElectronicDiaryIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
          />
        }
        primaryTogglesNestedList={true}
        nestedItems={[
          <MenuRoute
            text="Ramowy rozkład dnia"
            icon={
              <TimeTable
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryTimeTable}
            routeParams={[eventTypes.daySchedule.type, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Roczny ramowy plan dnia"
            icon={
              <TimeTable
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryFrameworkDaySchedule}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Zapis pracy wychowawczo-dydaktycznej"
            icon={
              <SaveDataIcon
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiarySaveWork}
            routeParams={[eventTypes.saveWork.type, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Wycieczki"
            icon={
              <TravelIcon
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryTrip}
            routeParams={[eventTypes.trip.type, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Zajęcia dodatkowe"
            icon={
              <ActivitiesIcon
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryAdditionalActivities}
            routeParams={[eventTypes.additionalActivities.type, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Roczny plan współpracy z rodzicami"
            icon={
              <WorkWithParentsIcon
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryWorkWithParents}
            routeParams={[eventTypes.workWithParents.type, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Plan pracy"
            icon={
              <WorkSchedule
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryWorkPlan}
            routeParams={[year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Dziennik obecności"
            icon={
              <AttendanceIcon
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.attendanceMonthlyHistory}
            routeParams={[!choosenGroup.isTemporary ? choosenGroup.id : firstGroupId, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Ważne wydarzenia z życia grupy"
            icon={
              <ImportantEventInGroupIcon
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.messageBoard}
            key={getNextKey()}
            {...rest}
          />
        ]}
      />
      <MenuRoute
        text="Rejestr dzieci"
        icon={
          <ChildrenIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color7 }}
          />
        }
        routePath={routePaths.children}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Raporty"
        icon={
          <ReportsIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color5 }}
          />
        }
        routePath={routePaths.reports}
        key={getNextKey()}
        {...rest}
      />
      <Divider />
      {
      // insurance && insurance.isActive 
       (
        <ListItem key={getNextKey()} component="li">
          <ListItemButton onClick={() => setInsuranceDialog(true)}>
            <img
              src={themeMode !== themeTypes.dark.value ? insuranceLogoUrl : insuranceLogoWhiteUrl}
              width="70%"
              alt="Ubezpieczenia"
            />
          </ListItemButton>
        </ListItem>
      )}
      <MenuRoute
        text="Blog 4Parents"
        icon={
          <BookIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color6 }}
          />
        }
        key={getNextKey()}
        component="a"
        href="https://4parents.education/blog/"
        target="_blank"
      />
      <MenuRoute
        text="Co nowego?"
        icon={
          <ChangeLogIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color6 }}
          />
        }
        routePath={routePaths.changeLog}
        key={getNextKey()}
        {...rest}
      />
      {insuranceDialog && (
        <InsuranceInfoDialog insurance={insurance} onClose={() => setInsuranceDialog(false)} open={insuranceDialog} />
      )}
    </List>
  );
};

function mapStateToProps(state) {
  return {
    choosenGroup: state.digitalDiary.choosenGroup
  };
}

TeacherSupportMenu.propTypes = {
  firstGroupId: PropTypes.string,
  currentRoute: PropTypes.string.isRequired,
  onRouteSelect: PropTypes.func.isRequired,
  insurance: PropTypes.object,
  choosenGroup: PropTypes.object
};

export default connect(mapStateToProps)(TeacherSupportMenu);
