/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import {
  GuardiansPinServiceSettingsModel,
  PinDevice
} from '../models/configuration/guardiansPinService/GuardiansPinServiceModels';

export default function guardiansPinServiceReducer(state = initialState.configuration.guardiansPinService, action) {
  switch (action.type) {
    case types.LOAD_GUARDIAN_PIN_SERVICE_SUCCESS: {
      return new GuardiansPinServiceSettingsModel().assign({
        ...action.configuration,
        guardiansWithoutPin: state.guardiansWithoutPin
      });
    }
    case types.LOAD_GUARDIANS_WITHOUT_PINS_SUCCESS: {
      return { ...state, guardiansWithoutPin: action.guardians };
    }
    case types.GENERATE_PINS_TO_GUARDIANS_WITHOUT_SUCCESS: {
      return { ...state, guardiansWithoutPin: [] };
    }
    case types.UPDATE_GUARDIAN_PIN_SERVICE_SUCCESS: {
      const { enabled } = action;
      return { ...state, enabled };
    }
    case types.ADD_PIN_DEVICE:
    case types.ADD_PIN_DEVICE_CANCEL:
    case types.RESET_PIN_DEVICE_PASSWORD:
    case types.RESET_PIN_DEVICE_PASSWORD_CANCEL: {
      const asyncErrors = undefined;
      return { ...state, asyncErrors };
    }
    case types.ADD_PIN_DEVICE_FAILURE:
    case types.RESET_PIN_DEVICE_PASSWORD_FAILURE: {
      const asyncErrors = action.errors;
      return { ...state, asyncErrors };
    }
    case types.ADD_PIN_DEVICE_SUCCESS: {
      return { ...state, devices: [...state.devices, new PinDevice().assign(action)] };
    }
    case types.REMOVE_PIN_DEVICE_SUCCESS: {
      return { ...state, devices: [...state.devices.filter((g) => g.id !== action.id)] };
    }
    default:
      return state;
  }
}
