import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../../forms/DialogForm';
import UnitWorkingHoursForm from '../forms/UnitWorkingHoursForm';
import { UnitWorkingHoursValidator } from '../../../../models/configuration/unit/UnitModelsValidators';

class UnitWorkingHoursDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workingHours: this.props.workingHours,
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const workingHours = Object.assign({}, this.state.workingHours);
    workingHours[event.target.name] = event.target.value;
    return this.setState({ workingHours });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    return (
      <DialogForm
        header="Zmiana godzin pracy"
        maxWidth="sm"
        onSave={() => onSave(this.state.workingHours)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidate={() => new UnitWorkingHoursValidator().validate(this.state.workingHours)}
        onValidationDone={(errors) => this.setState({ errors })}
        saveDisabled={Object.keys(this.state.errors).length > 0}
        statePathToUi="configuration.unitWorkingHoursUi">
        <UnitWorkingHoursForm
          workingHours={this.state.workingHours}
          onChange={this.handleChange}
          errors={this.state.errors}
        />
      </DialogForm>
    );
  }
}

UnitWorkingHoursDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  workingHours: PropTypes.object.isRequired
};

export default UnitWorkingHoursDialog;
