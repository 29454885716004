import React from 'react';
import PropTypes from 'prop-types';
import ChangePasswordForm, {
  InitialValues,
  validate,
  VALIDATION_SCHEMA
} from '../../../../../forms/userAccount/ChangePasswordForm';
import Grid from '@mui/material/Grid';
import FormikContentWrapper from '../../../../formik/FormikContentWrapper';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/material';

const ChangePasswordFormWrap = ({ onSubmit, error }) => (
  <Grid container>
    <Grid item xs={12}>
      {error && (
        <Alert severity="error" sx={{ mt: 1 }}>
          {error}
        </Alert>
      )}
      <FormikContentWrapper
        initialValues={new InitialValues()}
        validationSchema={VALIDATION_SCHEMA}
        validate={validate}
        onSubmit={onSubmit}
        isButton
        buttonLabel="Zmień hasło"
        isRequiredText>
        {() => (
          <Box sx={{ py: 3, px: 0 }}>
            <ChangePasswordForm />
          </Box>
        )}
      </FormikContentWrapper>
    </Grid>
  </Grid>
);

ChangePasswordFormWrap.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default ChangePasswordFormWrap;
