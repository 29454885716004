/*eslint indent:0*/
import initialState from './initial';
import { AttendancesHistoryModel } from '../models/attendance/AttendanceModels';
import * as types from '../actions/actionTypes';

export default function attendanceHistoryReducer(state = initialState.attendanceHistory, action) {
  switch (action.type) {
    case types.LOAD_ATTENDANCE_DAYS_IN_MONTH_SUCCESS: {
      return new AttendancesHistoryModel(state.groupId, state.months, action.days);
    }
    case types.LOAD_ATTENDANCE_MONTHS_SUCCESS: {
      return new AttendancesHistoryModel(action.groupId, action.months, []);
    }
    default:
      return state;
  }
}
