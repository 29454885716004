import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Box, MenuItem, Typography } from '@mui/material';
import SelectField from '../../common/SelectField';
import YearAndMonthOptionsSlider from '../../common/calendar/YearAndMonthOptionsSlider';
import FieldsetWrapper from '../../forms/FieldsetWrapper';

const ChildrenAverageAttendanceInHourIntervalsReport = ({ groupId, startDate, groups, onChange, errors }) => {
  return (
    <FieldsetWrapper legend="Pola dla generowania raportu średniej liczby dzieci przebywających w przedszkolu">
      <SelectField
        contrast
        errorText={errors.groupId}
        sx={{ width: 'auto', overflow: 'hidden' }}
        floatingLabelText="Grupa"
        id="groupId"
        value={groupId}
        onChange={(event) => {
          onChange({ target: { name: 'groupId', value: event.target.value } });
        }}>
        {groups.map((group) => {
          return (
            <MenuItem key={group.id} value={group.id} className="menu-item">
              {group.name}
            </MenuItem>
          );
        })}
      </SelectField>
      <Typography variant="h6" sx={{ mt: 1, display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
        Wybierz miesiąc
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
        <YearAndMonthOptionsSlider
          contrast
          onSelected={(year, month) => {
            const date = moment(`${year}-${month}`, 'YYYY-MM');
            onChange({ target: { name: 'startDate', value: date.startOf('month').format('YYYY-MM-DD') } });
            onChange({ target: { name: 'endDate', value: date.endOf('month').format('YYYY-MM-DD') } });
          }}
          defaultMonth={moment(startDate).format('MM')}
          defaultYear={moment(startDate).format('YYYY')}
        />
      </Box>
    </FieldsetWrapper>
  );
};

ChildrenAverageAttendanceInHourIntervalsReport.propTypes = {
  groupId: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ChildrenAverageAttendanceInHourIntervalsReport;
