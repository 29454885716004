import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItem, Paper, Typography, useTheme, useMediaQuery } from '@mui/material';
import * as AlbumTypes from '../../../../../constants/groupsAndActivitiesTypes';
import SpecificIcon from '../../../../groupsAndActivities/SpecificIcon';
import SingleMemberChip from '../SingleMemberChip';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ChildCareOutlinedIcon from '@mui/icons-material/ChildCareOutlined';
import PublicIcon from '@mui/icons-material/Public';
import RichTooltip from '../../../../richTooltip/richTooltip';

const SingleAlbum = ({ name, isPublic, type, data, onClick, id, isOnList = false, isTiny = false, sx }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);

  const tinyText = () => {
    switch (type) {
      case AlbumTypes.GROUP:
        return 'Przypisana grupa:';
      case AlbumTypes.ACTIVITY:
        return 'Przypisane zajęcie dodatkowe';
      case AlbumTypes.MORE_GROUPS:
        return 'Przypisane grupy';
      case AlbumTypes.MORE_ACTIVITIES:
        return 'Przypisane zajęcia dodatkowe';
      default:
        return 'Album przedszkolny';
    }
  };

  const displayMembership = () => {
    let tempType = type;
    let tempData = !!data ? data : [];
    if (isTiny) {
      if (tempType === AlbumTypes.GROUP) {
        tempType = AlbumTypes.MORE_GROUPS;
        tempData = [data];
      }
      if (tempType === AlbumTypes.ACTIVITY) {
        tempType = AlbumTypes.MORE_ACTIVITIES;
        tempData = [data];
      }
    }

    switch (tempType) {
      case AlbumTypes.GROUP:
      case AlbumTypes.ACTIVITY:
        return tempData.name;
      case AlbumTypes.MORE_GROUPS:
      case AlbumTypes.MORE_ACTIVITIES:
        return (
          <Box
            onMouseEnter={() => setOpen(!open)}
            onMouseLeave={() => setOpen(!open)}
            aria-labelledby={open ? id : null}>
            <RichTooltip
              open={open}
              placement="bottom"
              onClose={() => setOpen(false)}
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    background: 'transparent'
                  }
                }
              }}
              content={
                <Paper>
                  <List
                    id={open ? id : null}
                    subheader={
                      isTiny && (
                        <ListSubheader component="div" id="nested-list-subheader">
                          {tinyText()}
                        </ListSubheader>
                      )
                    }>
                    {Array.isArray(tempData) &&
                      tempData
                        .sort((a, b) => a?.name?.length - b?.name?.length)
                        .map((item, index) => {
                          const currentType =
                            type === AlbumTypes.MORE_ACTIVITIES ? AlbumTypes.ACTIVITY : AlbumTypes.GROUP;
                          return (
                            <ListItem component="li" key={item.id}>
                              <SingleMemberChip color={item.color} type={currentType} name={item.name} />
                            </ListItem>
                          );
                        })}
                  </List>
                </Paper>
              }>
              <Box sx={{ display: 'flex' }}>
                {!isTiny &&
                  // eslint-disable-next-line no-nested-ternary
                  (tempData?.length > 4
                    ? type === AlbumTypes.MORE_GROUPS
                      ? 'Grup'
                      : 'Zajęć dodatkowych'
                    : type === AlbumTypes.MORE_ACTIVITIES
                    ? 'Zajęcia dodatkowe'
                    : 'Grupy')}
                <Box
                  sx={{
                    border: (theme) => `1px solid ${theme.palette.color.color2}`,
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    fontSize: 10,
                    mx: 1,
                    pt: 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                  }}>
                  {tempData?.length}
                </Box>
              </Box>
            </RichTooltip>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Paper
      sx={{
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        overflow: 'hidden',
        m: 1,
        p: 2,
        height: 80,
        width: isMobile ? '100%' : undefined,
        '&:hover': {
          background: (theme) => theme.palette.background.hover
        },
        ...sx
      }}
      tabIndex={0}
      onKeyPress={isOnList ? null : onClick}
      onClick={isOnList ? null : onClick}
      onFocus={() => setOpen(!open)}
      onBlur={() => setOpen(!open)}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: isMobile ? 'space-between' : undefined
        }}>
        {isPublic ? (
          <PublicIcon sx={{ mr: 2, fontSize: 25 }} />
        ) : (
          <ChildCareOutlinedIcon sx={{ mr: 2, fontSize: 25 }} />
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}>
          <Typography
            fontWeight="bold"
            sx={{
              fontSize: isTiny ? 14 : null,
              lineHeight: !isTiny && type === AlbumTypes.GLOBAL ? 2.3 : null,
              whiteSpace: 'normal',
              wordBreak: 'break-all'
            }}>
            {name}
          </Typography>
          {isTiny && displayMembership()}
          {!isTiny && (
            <Typography
              sx={{ fontWeight: (theme) => theme.typography.fontWeightLight }}
              component="span"
              aria-hidden={true}>
              {displayMembership()}
            </Typography>
          )}
          <Typography
            sx={{
              border: 0,
              clip: 'rect(0 0 0 0)',
              height: '1px',
              margin: '-1px',
              overflow: 'hidden',
              p: 0,
              width: '1px'
            }}>
            {!!data && `Dostępny dla: ${data.length}`}
            {Array.isArray(!!data ? data : []) &&
              !!data &&
              data.sort((a, b) => a?.name?.length - b?.name?.length).map((item) => ` ${item.name}`)}
          </Typography>
        </Box>
        <SpecificIcon type={type} sx={{ fontSize: 25, ml: 2 }} />
      </Box>
    </Paper>
  );
};

SingleAlbum.propTypes = {
  name: PropTypes.string,
  isPublic: PropTypes.bool,
  type: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onClick: PropTypes.func,
  id: PropTypes.string,
  isOnList: PropTypes.bool,
  isTiny: PropTypes.bool,
  sx: PropTypes.object
};

export default SingleAlbum;
