import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import AccountActivationFormWrap from '../Components/Form/AccountActivationFormWrap';
import { ROOT_URL } from '../../../../api/ApiBase';
import { AuthModel } from '../../../../models/auth/AuthModel';
import * as authActions from '../../../../actions/userAccountActions';
import { Typography, Box } from '@mui/material';
import { getTenantName } from '../../../../utils/getTenantName';

const AccountActivationContainer = ({ location }) => {
  const dispatch = useDispatch();
  const { isActive } = useSelector((state) => state.configuration.unit.insurance);

  const isLegalGuardian = new AuthModel(location.query.token).isLegalGuardian() || true;

  const onSubmit = ({ password }) => {
    dispatch(authActions.resetPassword({ token: location.query.token, newPassword: password, isRegister: true }));
  };

  const handleAgreementOpen = (event) => {
    const path = `${ROOT_URL}/users/agreement?insurance=${!!isActive}&tenant=${getTenantName()}`;
    window.open(path, '_blank');
    event.preventDefault();
  };

  return (
    <Box className="reset-password-panel" sx={{ p: 2 }}>
      <Typography component="p" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
        Witaj!{' '}
      </Typography>
      <Typography component="p" sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
        Zostało przygotowane dla Ciebie konto użytkownika, aby dokończyć rejestrację zdefiniuj swoje hasło.
      </Typography>
      <AccountActivationFormWrap
        onSubmit={onSubmit}
        handleAgreementOpen={handleAgreementOpen}
        isCheckbox={isLegalGuardian}
      />
    </Box>
  );
};

AccountActivationContainer.propTypes = {
  location: PropTypes.object.isRequired
};

export default AccountActivationContainer;
