import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { List, Paper, ListItemAvatar, ListItem, Avatar, useTheme, Box, Typography, Button, Grid } from '@mui/material';
import PersonAvatar from '../forms/PersonAvatar';
import EmptyState from '../common/EmptyState';
import { browserHistory } from 'react-router';
import { fromTemplate, routePaths } from '../../routePaths';
import { useSelector } from 'react-redux';
import { canShowAbsence } from '../dashboard/staffMember/UnitAttendanceSummary';

const ActivitiesAttendanceSheetPageList = (props) => {
  const {
    title,
    entries,
    onReportAttendance,
    onReportAbsence,
    actionTypes,
    actionType,
    filterBy,
    selectedActivity,
    searchText,
    onCustomRedirect
  } = props;

  const user = useSelector((state) => state.auth);
  const filteredEntities = useMemo(() => {
    return entries
      .filter(
        (entry) =>
          entry &&
          ((entry.firstName.toLowerCase() + ' ' + entry.lastName.toLowerCase()).includes(filterBy.toLowerCase()) ||
            (entry.lastName.toLowerCase() + ' ' + entry.firstName.toLowerCase()).includes(filterBy.toLowerCase()))
      )
      .sort((a, b) => a.lastName.localeCompare(b.lastName));
  }, [entries, filterBy]);
  const theme = useTheme();

  return (
    <>
      {title ? (
        <Box sx={{ display: 'flex', alignItems: 'center', mx: 2 }}>
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: (theme) => theme.palette.color.primary,
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}>
            {title}
          </Typography>
          <Avatar
            sx={{
              width: '25px',
              height: '25px',
              background: (theme) => theme.palette.background.transparent,
              color: (theme) => theme.palette.color.primary,
              opacity: 0.7,
              mx: 1,
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}>
            {entries.length}
          </Avatar>
        </Box>
      ) : null}
      {filteredEntities.length > 0 ? (
        <Box>
          <Paper
            sx={{
              my: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              px: 2,
              py: 1
            }}>
            <List>
              {filteredEntities.map((entry) =>
                entry ? (
                  <ListItem
                    key={entry.id}
                    sx={{
                      display: 'flex',
                      minHeight: 80,
                      '&:hover': {
                        background: (theme) => theme.palette.background.hover
                      }
                    }}>
                    <Grid container>
                      <Grid
                        item
                        onClick={() => {
                          if (canShowAbsence(user) && !onCustomRedirect)
                            browserHistory.push(fromTemplate(`${routePaths.children}` + `/${entry.id}`));
                          if (!!onCustomRedirect) onCustomRedirect(entry);
                        }}
                        xs={6}
                        sx={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemAvatar>
                          <PersonAvatar
                            initials
                            alt={entry.firstName}
                            url={entry.photoUrl}
                            firstName={entry.firstName}
                            lastName={entry.lastName}
                          />
                        </ListItemAvatar>
                        <Typography
                          sx={{
                            cursor: 'pointer',
                            '&:hover': {
                              textDecoration: 'underline'
                            },
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            fontWeight: theme.typography.fontWeightBold
                          }}>{`${entry.lastName} ${entry.firstName}`}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row' },
                          justifyContent: { xs: 'flex-end' }
                        }}>
                        {!actionType || actionType === actionTypes.absence ? (
                          <Button
                            variant="text"
                            aria-label="obecność"
                            key="1"
                            onClick={() => onReportAttendance(entry.id)}
                            sx={{
                              color: theme.palette.color.color7,
                              fontWeight: theme.typography.fontWeightBold,
                              textTransform: 'none',
                              fontSize: 16
                            }}>
                            obecność
                          </Button>
                        ) : null}
                        {!actionType || actionType === actionTypes.attendant ? (
                          <Button
                            variant="text"
                            aria-label="nieobecność"
                            key="2"
                            onClick={() => onReportAbsence(entry.id)}
                            sx={{
                              textTransform: 'none',
                              color: theme.palette.color.color25,
                              fontWeight: theme.typography.fontWeightBold,
                              fontSize: 16
                            }}>
                            nieobecność
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                  </ListItem>
                ) : null
              )}
            </List>
          </Paper>
        </Box>
      ) : (
        <EmptyState
          isPaper
          contrast
          message={`Brak ${searchText ? 'wyników' : 'dzieci'} w  ${
            title ? `grupie ${title.toLowerCase()}` : selectedActivity ? `zajęciach ${selectedActivity.name}` : null
          }`}
        />
      )}
    </>
  );
};

ActivitiesAttendanceSheetPageList.propTypes = {
  title: PropTypes.string,
  entries: PropTypes.array,
  onReportAttendance: PropTypes.func,
  onReportAbsence: PropTypes.func,
  actionTypes: PropTypes.object,
  actionType: PropTypes.string,
  filterBy: PropTypes.string,
  selectedActivity: PropTypes.object,
  searchText: PropTypes.string
};

export default ActivitiesAttendanceSheetPageList;
