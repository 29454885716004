/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { SettlementModel } from '../models/settlements/SettlementModels';

export default function guardianDueDetailsReducer(state = initialState.guardianDueDetails, action) {
  switch (action.type) {
    case types.LOAD_DUE_DETAILS_SUCCESS: {
      return new SettlementModel().assign(action.dueDetails);
    }
    default:
      return state;
  }
}
