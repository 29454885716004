import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormDialog from '../../formik/formDialog/FormDialog';
import { FormikTextField } from '../../formik/FormikTextField';
import { Grid } from '@mui/material';
import EventNote from '@mui/icons-material/FeedbackOutlined';
import makeStyles from '@mui/styles/makeStyles';
import WYSIWYG from '../../formik/FormikWYSIWYG';
import FormikDatePicker from '../../formik/FormikDatePicker';
import FormikTimePicker from '../../formik/FormikTimePicker';
import FileUploader from '../../common/fileUploader/FileUploader';
import { useUpload } from '../../../hooks/useUpload';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import { TimeModel } from '../../../models/TimeModel';
import moment from 'moment';

const useStyles = makeStyles({
  editor: {
    margin: '16px 0'
  }
});

const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Podaj tytuł';
  }
  if (values.eventEndTime < values.eventStartTime) {
    errors.eventStartTime =
      'Zadeklarowana godzina musi być wcześniejsza niż ' + new TimeModel().parse(values.eventEndTime);
  }

  const blocksFromHTML = convertFromHTML(values.content);
  const content = convertToRaw(
    ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
  );
  if (content.blocks.length === 0) {
    errors.content = 'Podaj treść';
  }

  if (!values.eventDate) {
    errors.eventDate = 'Podaj datę';
  }
  if (!moment(values.eventDate).isValid()) errors.eventDate = 'Nieprawidłowa data';
  if (!moment(values.eventDate).isSameOrBefore(moment(new Date()).add(2, 'year')))
    errors.eventDate = `Wybierz datę wcześniejszą niż ${moment(new Date()).add(2, 'year').format('DD/MM/YYYY')}`;
  return errors;
};

const EventDialog = (props) => {
  const { onClose, open, onSubmit, initialValues, title, inProgress } = props;

  const classes = useStyles();
  const [files, handleFileAdded, handleFileDeleted, setFiles] = useUpload();

  useEffect(() => {
    setFiles(initialValues.files);
  }, []);

  const handleSubmit = (values) => {
    onSubmit({
      ...values,
      files
    });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <FormDialog
      onClose={handleClose}
      open={open}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      title={title}
      titleIcon={<EventNote fontSize="large" />}
      requiredFieldText
      inProgress={inProgress}
      validate={validate}>
      <Grid container direction="column" gap={1}>
        <Grid item xs={12}>
          <FormikTextField name="title" label="Nazwa wydarzenia*" fullWidth />
        </Grid>
        <Grid item className={classes.editor}>
          <WYSIWYG name="content" />
        </Grid>
        <Grid item>
          <FileUploader files={files} onFileUploaded={handleFileAdded} onFileRemoved={handleFileDeleted} />
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Grid item xs={12} md={4}>
            <FormikDatePicker fullWidth name="eventDate" label="Data wydarzenia*" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} pr={2}>
            <FormikTimePicker fullWidth name="eventStartTime" label="Wydarzenie od" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} pr={2}>
            <FormikTimePicker fullWidth name="eventEndTime" label="Wydarzenie do" />
          </Grid>
        </Grid>
      </Grid>
    </FormDialog>
  );
};

EventDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  inProgress: PropTypes.bool.isRequired
};

export default EventDialog;
