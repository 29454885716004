import { TextField, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogForm from '../../../forms/DialogForm';
import { WorkPlanValidator } from './WorkPlanValidator';
import { addSpecialistWorkPlan, editSpecialistWorkPlanElement } from '../../../../actions/specialistWorkPlanActions';

const NewWorkPlanDialog = ({ handleCancel, selectedElement, onSave, workId }) => {
  const ui = useSelector((state) => state.specialistNotesUi.workPlan);
  const [errors, setErrors] = useState({});
  const [title, setTitle] = useState(selectedElement?.title);
  const [content, setContent] = useState(selectedElement?.content);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedElement) {
      setTitle(selectedElement.title);
      setContent(selectedElement.content);
    }
  }, [selectedElement]);

  const handleSave = () => {
    let data = {
      title: title,
      content: content,
      id: selectedElement ? selectedElement.id : undefined
    };
    selectedElement
      ? dispatch(editSpecialistWorkPlanElement(data, workId))
      : dispatch(addSpecialistWorkPlan(data, workId));
    onSave();
  };

  return (
    <>
      {ui.isEditing && (
        <DialogForm
          errors={errors}
          header={selectedElement ? 'Edycja wpisu' : 'Nowy wpis'}
          maxWidth="md"
          onSave={handleSave}
          onCancel={() => handleCancel()}
          isDialogOpen={ui.isEditing}
          isProcessing={ui.isProcessing}
          onValidate={() => new WorkPlanValidator().validate({ title: title, content: content })}
          onValidationDone={(errors) => setErrors(errors)}
          statePathToUi="specialistNotesUi.workPlan"
          requiredFieldText>
          <Box sx={{ my: 1 }}>
            <TextField
              sx={{ mb: 2 }}
              label="Podaj tytuł*"
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              defaultValue={!!selectedElement ? selectedElement.title : undefined}
              error={errors?.title}
              helperText={errors?.title}
            />
            <TextField
              multiline
              minRows={5}
              label="Podaj treść wpisu*"
              onChange={(e) => setContent(e.target.value)}
              fullWidth
              defaultValue={!!selectedElement ? selectedElement.content : undefined}
              error={errors?.content}
              helperText={errors?.content}
            />
          </Box>
        </DialogForm>
      )}
    </>
  );
};

export default NewWorkPlanDialog;
