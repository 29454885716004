import ApiBase, { ROOT_URL } from './ApiBase';

class AttributesApi extends ApiBase {
  static getAttributesSets() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/attributes/sets`).type('application/json'));
  }

  static addAttributeSet(attributeSet) {
    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/attributes/sets`).send(attributeSet).type('application/json'),
      super.filterLocationHeader
    );
  }

  static updateAttributeSet(attributeSet) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/attributes/sets/${attributeSet.id}`).send(attributeSet).type('application/json')
    );
  }

  static removeAttributeSet(setId) {
    return super.toPromise((agent) => agent.delete(`${ROOT_URL}/attributes/sets/${setId}`).type('application/json'));
  }

  static getAttributesValues(objectId) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/attributes/values/${objectId}`).type('application/json'));
  }
}

export default AttributesApi;
