import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const CalendarDay = ({ day, contentRender, onDaySelected, containerWidth, isDisabled, clickable = true }) => {
  const useStyle = makeStyles((theme) => ({
    calendarDay: {
      marginTop: '0.5px',
      borderRadius: '0',
      padding: '12px',
      minWidth: '65px',
      width: '100%',
      color: theme.palette.color.primary,
      cursor: 'pointer',
      border: 'solid 1px',
      background: theme.palette.components.calendar.calendarDay,
      borderColor: theme.palette.components.calendar.calendarDay,
      overflow: 'hidden',
      '&:hover': {
        background: theme.palette.components.calendar.calendarDayBgColorHover,
        color: theme.palette.components.calendar.calendarDayColorHover
      }
    },
    weekend: {
      background: theme.palette.components.calendar.calendarWeekend,
      borderColor: theme.palette.components.calendar.calendarWeekend,
      boxShadow: 'none'
    },
    disabled: {
      background: theme.palette.components.calendar.calendarWeekend,
      borderColor: theme.palette.components.calendar.calendarWeekend,
      boxShadow: 'none',
      cursor: 'default'
    },
    notCurrentMonth: {
      cursor: 'default',
      boxShadow: 'none',
      color: theme.palette.components.calendar.notCurrentMonth
    }
  }));
  const classes = useStyle();
  if (!clickable) {
    return (
      <Box
        className={classNames(
          day.isWeekend || day.data.isDayOff || !day.isCurrentMonth ? classes.disabled : '',
          classes.calendarDay
        )}
        onClick={() => {
          if (!isDisabled) onDaySelected(day);
        }}>
        {contentRender(day, containerWidth)}
      </Box>
    );
  }
  return (
    <Button
      disabled={isDisabled}
      className={classNames(
        classes.calendarDay,
        day.isWeekend || day.data.isDayOff || isDisabled || !day.isCurrentMonth ? classes.disabled : ''
      )}
      onClick={() => {
        if (!isDisabled) onDaySelected(day);
      }}
      aria-label={day.date ? ` Dzień kalendarza ${day.date.format('YYYY-MM-DD')}` : 'Dzień kalendarza'}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        textTransform: 'none',
        width: '100%',
        height: '100%',
        m: 0,
        p: 0.5
      }}>
      {contentRender(day, containerWidth)}
    </Button>
  );
};

CalendarDay.propTypes = {
  day: PropTypes.object.isRequired,
  onDaySelected: PropTypes.func.isRequired,
  contentRender: PropTypes.func.isRequired,
  containerWidth: PropTypes.number.isRequired,
  extraClassName: PropTypes.string,
  isDisabled: PropTypes.bool,
  clickable: PropTypes.bool
};

export default CalendarDay;
