import { Box, Rating, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Meal as MealTypes } from '../../constants/mealTypes';
import BreakfastDiningOutlinedIcon from '@mui/icons-material/BreakfastDiningOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import RamenDiningOutlinedIcon from '@mui/icons-material/RamenDiningOutlined';
import SoupKitchenOutlinedIcon from '@mui/icons-material/SoupKitchenOutlined';
import BakeryDiningOutlinedIcon from '@mui/icons-material/BakeryDiningOutlined';
import styled from '@emotion/styled';
import EggAltIcon from '@mui/icons-material/EggAlt';
import EggAltOutlinedIcon from '@mui/icons-material/EggAltOutlined';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75'
  },
  '& .MuiRating-iconHover': {
    color: '#FB5B85'
  }
});

const generateIcon = (type) => {
  switch (type) {
    case 'breakfast':
      return <BreakfastDiningOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'secondBreakfast':
      return <RestaurantOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'lunch':
      return <RamenDiningOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'soup':
      return <SoupKitchenOutlinedIcon sx={{ fontSize: 30 }} />;

    case 'afternoonMeal':
      return <BakeryDiningOutlinedIcon sx={{ fontSize: 30 }} />;

    default:
      break;
  }
  return null;
};

const Meal = ({ meal, noDescription, mealsScale = [] }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      ml: 1,
      my: 1,
      width: '100%'
    }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {generateIcon(MealTypes[meal].value)}
      {noDescription ? null : (
        <Typography
          variant="MealsAndMoods"
          sx={{
            color: (theme) => theme.palette.color.color2,
            ml: 1
          }}>
          {MealTypes[meal].name}
        </Typography>
      )}
    </Box>
    <StyledRating
      max={4}
      readOnly
      value={mealsScale?.value || 0}
      icon={<EggAltIcon />}
      emptyIcon={<EggAltOutlinedIcon />}
    />
  </Box>
);

Meal.propTypes = {
  meal: PropTypes.string.isRequired,
  noDescription: PropTypes.bool
};

export default Meal;
