import staffMembersApi from '../api/StaffMembersApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../routePaths';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';

export function loadStaffMembersAsync() {
  return (dispatch, getSate) => {
    if (getSate().staffMembers.length === 0) {
      dispatch({ type: types.LOAD_STAFF_MEMBERS });
      return staffMembersApi
        .getStaffMembers()
        .then((members) => dispatch({ type: types.LOAD_STAFF_MEMBERS_SUCCESS, members }))
        .catch((error) => {
          dispatch(notificationActions.showError('Nie udało się pobrać zarejestrowanych pracowników'));
          return logger.error(error);
        });
    }
    return Promise.resolve();
  };
}

export function loadStaffMemberDetailsAsync(id) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_STAFF_MEMBER_DETAILS });
    return staffMembersApi
      .getStaffMemberDetails(id)
      .then((member) => dispatch({ type: types.LOAD_STAFF_MEMBER_DETAILS_SUCCESS, member }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać zarejestrowanego pracownika ${get.staffName(getState, id)}`
          )
        );
        return logger.error(error);
      });
  };
}

export function loadStaffMembersArchiveAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_STAFF_MEMBERS_ARCHIVE });
    return staffMembersApi
      .getStaffMembersArchive()
      .then((members) => dispatch({ type: types.LOAD_STAFF_MEMBERS_ARCHIVE_SUCCESS, members }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać zarchiwizowanych pracowników'));
        return logger.error(error);
      });
  };
}

export function loadStaffMemberArchiveAsync(id) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_STAFF_MEMBER_DETAILS_ARCHIVE });
    return staffMembersApi
      .getStaffMemberDetailsArchive(id)
      .then((member) => dispatch({ type: types.LOAD_STAFF_MEMBER_DETAILS_ARCHIVE_SUCCESS, member }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać zarchiwizowanego pracownika ${get.staffArchiveName(getState, id)}`
          )
        );
        return logger.error(error);
      });
  };
}

export function createStaffMemberStart() {
  return { type: types.CREATE_STAFF_MEMBER };
}

export function createStaffMemberCancel() {
  return { type: types.CREATE_STAFF_MEMBER_CANCEL };
}

export function createStaffMemberAsync(nextMember) {
  const member = Object.assign({}, nextMember);
  return (dispatch) => {
    dispatch({ type: types.CREATE_STAFF_MEMBER_SUBMIT });
    return staffMembersApi
      .createStaffMember(member)
      .then((id) => {
        dispatch(notificationActions.showSuccess(`Dodano pracownika '${member.firstName} ${member.lastName}'`));
        dispatch({ type: types.CREATE_STAFF_MEMBER_SUCCESS, member: Object.assign(member, { id }) });
        browserHistory.push(fromTemplate(routePaths.staffMemberDetails, [id]));
      })
      .catch((error) => {
        if (error.status === 412) {
          dispatch(notificationActions.showError(`Email już istnieje`));
          return dispatch({ type: types.CREATE_STAFF_MEMBER_FAILURE, errors: error.response.body });
        }
        dispatch(
          notificationActions.showError(`Nie udało się dodać pracownika '${member.firstName} ${member.lastName}'`)
        );
        return logger.error(error);
      });
  };
}

export function updateStaffMemberStart() {
  return { type: types.UPDATE_STAFF_MEMBER };
}

export function updateStaffMemberCancel() {
  return { type: types.UPDATE_STAFF_MEMBER_CANCEL };
}

export function updateStaffMemberAsync(nextMember) {
  const member = Object.assign({}, nextMember);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_STAFF_MEMBER_SUBMIT });
    return staffMembersApi
      .updateStaffMember(member.id, member)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zaktualizowano pracownika '${member.firstName} ${member.lastName}'`));
        dispatch({ type: types.UPDATE_STAFF_MEMBER_SUCCESS, member });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.UPDATE_STAFF_MEMBER_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się zaktualizować pracownika '${member.firstName} ${member.lastName}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function cashOutMoneyFromPersonalBalanceAsync(childId, amount, method) {
  return (dispatch) => {
    dispatch({ type: types.CASH_CHILD_BALANCE });
    return staffMembersApi
      .cashOutMoneyFromPersonalBalance(childId, amount, method)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zarejestrowano wypłatę środków - ${amount}zł`));
        dispatch({ type: types.CASH_CHILD_BALANCE_SUCCESS });
      })
      .catch((error) => {
        if (error.status === 412) {
          const errorText =
            error.response && error.response.body && error.response.body.generalError
              ? error.response.body.generalError
              : '';
          return dispatch(notificationActions.showError(`Nie udało się zarejestrować wypłaty środków - ${errorText}`));
        }
        dispatch(notificationActions.showError('Nie udało się zarejestrować wypłaty środków.'));
        return logger.error(error);
      });
  };
}

export function archiveStaffMemberAsync(memberId) {
  return (dispatch, getState) => {
    dispatch({ type: types.ARCHIVE_STAFF_MEMBER });
    return staffMembersApi
      .archiveStaffMember(memberId)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zarchiwizowano pracownika '${get.staffName(getState, memberId)}'`));
        dispatch({ type: types.ARCHIVE_STAFF_MEMBER_SUCCESS, memberId });
        browserHistory.push(routePaths.staffMembers);
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(`Nie udało się zarchiwizować pracownika '${get.staffName(getState, memberId)}'`)
        );
        return logger.error(error);
      });
  };
}

export function saveStaffMemberAsync(nextMember) {
  const member = Object.assign({}, nextMember);
  return (dispatch) => {
    return member.id ? dispatch(updateStaffMemberAsync(member)) : dispatch(createStaffMemberAsync(member));
  };
}

export function updateChargingSchemeStart() {
  return { type: types.ASSIGN_CHARGING_SCHEME_TO_STAFF };
}

export function updateChargingSchemeCancel() {
  return { type: types.ASSIGN_CHARGING_SCHEME_TO_STAFF_CANCEL };
}

export function updateChargingScheme(staffId, nextStaffChargingScheme) {
  const chargingScheme = Object.assign({}, nextStaffChargingScheme);

  return (dispatch, getState) => {
    dispatch({ type: types.ASSIGN_CHARGING_SCHEME_TO_STAFF_SUBMIT });
    return staffMembersApi
      .updateChargingScheme(staffId, chargingScheme)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Przypisano schemat płatności '${get.schemeName(
              getState,
              chargingScheme.schemeId
            )}' do pracownika '${get.staffName(getState, staffId)}'`
          )
        );
        dispatch({ type: types.ASSIGN_CHARGING_SCHEME_TO_STAFF_SUCCESS, scheme: chargingScheme });
      })
      .catch((error) => {
        if (error.status === 412) {
          return dispatch({
            type: types.ASSIGN_CHARGING_SCHEME_TO_STAFF_FAILURE,
            errors: error.response.body
          });
        }
        dispatch(
          notificationActions.showError(
            `Nie udało się przypisać schematu płatności '${get.schemeName(
              getState,
              chargingScheme.schemeId
            )}' do pracownika '${get.staffName(getState, staffId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function removeChargingScheme(staffId, schemeId) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_CHARGING_SCHEME_FROM_STAFF });
    return staffMembersApi
      .removeChargingScheme(staffId, schemeId)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Odpięto schemat płatności '${get.schemeName(getState, schemeId)}' od pracownika '${get.staffName(
              getState,
              staffId
            )}'`
          )
        );
        dispatch({ type: types.REMOVE_CHARGING_SCHEME_FROM_STAFF_SUCCESS, schemeId });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się odpiąć schematu płatności '${get.schemeName(
              getState,
              schemeId
            )}' od pracownika '${get.staffName(getState, staffId)}'`
          )
        );
        return logger.error(error);
      });
  };
}
