import { SettlementModel } from './SettlementModels';
import { BaseModelValidator } from '../BaseModelValidator';
import { isNumeric } from '../../utils/numberValidators';

export class SettlementModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = SettlementModel;

    this.constraints = {
      adjustmentAmount: (value) => value && !isNumeric(value) && 'Wartość musi być liczbą'
    };
  }
}
