import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import SingleFile from '../SingleFile/SingleFile';
import List from '@mui/material/List';
import Grow from '@mui/material/Grow';
import { Pagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { moveToFolder } from '../../../../actions/virtualDiscActions';
import Folder from '../Folder/Folder';
import LoadingIndicatorWrapper from '../../../common/loading/LoadingIndicatorWrapper';

const TilesView = ({
  itemList,
  onSelectFile,
  selectedFiles,
  isMobile,
  onSingleDownload,
  onCopyLink,
  cancelSelectedFiles,
  handleOpenMoveToFolderDialog,
  handleClearSearch
}) => {
  const dispatch = useDispatch();
  const { isProcessing } = useSelector((state) => state.virtualDiscFolderUi.fetch);
  const [draggedItem, setDraggedItem] = useState(null);
  const visibleItemsAmount = 16;
  const [page, setPage] = useState(1);

  const handleOnDrag = (e, itemId) => {
    setDraggedItem(itemId);
  };
  const handleOnDrop = async (preparedData) => {
    if (draggedItem) {
      await dispatch(moveToFolder(preparedData));
      cancelSelectedFiles();
    }
    setDraggedItem(null);
  };
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleOpenFolder = () => {
    cancelSelectedFiles();
    handleClearSearch();
  };
  const preparedItems = useMemo(() => {
    return itemList.slice(visibleItemsAmount * (page - 1), visibleItemsAmount * page);
  }, [page, itemList]);

  return (
    <LoadingIndicatorWrapper isProcessing={isProcessing} shouldUnmountChildrenWhenProcessing>
      <>
        <Grow in={true}>
          <List
            sx={{
              display: 'grid',
              gridTemplateColumns: `repeat(auto-fit, minmax(${isMobile ? '125px' : '200px'}, ${
                isMobile ? '1fr' : '200px'
              }))`,
              gridGap: isMobile ? 10 : 15,
              justifyContent: 'center'
            }}>
            {preparedItems.map((item) =>
              item.type === 'folder' ? (
                <Folder
                  draggedItem={draggedItem}
                  handleOnDrag={handleOnDrag}
                  handleOnDrop={handleOnDrop}
                  handleOpenMoveToFolderDialog={handleOpenMoveToFolderDialog}
                  item={item}
                  itemId={item.id}
                  key={item.id}
                  onOpenFolder={handleOpenFolder}
                />
              ) : (
                <SingleFile
                  draggedItem={draggedItem}
                  handleOnDrag={handleOnDrag}
                  item={item}
                  itemId={item.id}
                  key={item.id}
                  name={item.name}
                  fileUrl={item.fileUrl}
                  mimeType={item.mimeType}
                  isNew={item.isNew}
                  onSelectFile={(e) => onSelectFile(e, item)}
                  isSelected={selectedFiles.map((item) => item.id).includes(item.id)}
                  onSingleDownload={onSingleDownload}
                  onCopyLink={onCopyLink}
                />
              )
            )}
          </List>
        </Grow>
        <Pagination
          siblingCount={isMobile ? 0 : 1}
          count={Math.ceil(itemList.length / visibleItemsAmount)}
          page={page}
          onChange={handleChangePage}
          sx={{ mt: 2, mx: 'auto', display: 'flex', justifyContent: 'center' }}
        />
      </>
    </LoadingIndicatorWrapper>
  );
};

TilesView.propTypes = {
  isMobile: PropTypes.bool,
  itemList: PropTypes.array.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  handleClearSearch: PropTypes.func.isRequired,
  selectedFiles: PropTypes.array.isRequired,
  onSingleDownload: PropTypes.func.isRequired,
  onCopyLink: PropTypes.func.isRequired,
  cancelSelectedFiles: PropTypes.func.isRequired
};

export default TilesView;
