/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';

import { RightBottomNotification } from '../models/rightBottomNotification/RightBottomNotification';

export default function rightBottomNotificationReducer(state = initialState.rightBottomNotification, action) {
  switch (action.type) {
    case types.RIGHT_BOTTOM_NOTIFICATION_START: {
      const { uuid, target, data } = action.payload;
      return [new RightBottomNotification(uuid, target, data), ...state];
    }
    case types.RIGHT_BOTTOM_NOTIFICATION_UPDATE: {
      const { uuid, data } = action.payload;
      const currentItem = state.find((item) => item.uuid === uuid);
      const updatedItem = { ...currentItem, data: { ...currentItem.data, ...data } };
      return state.map((item) => (uuid === item.uuid ? updatedItem : item));
    }
    case types.RIGHT_BOTTOM_NOTIFICATION_REMOVE: {
      const { uuid } = action.payload;
      return state.filter((item) => item.uuid !== uuid);
    }
    case types.RIGHT_BOTTOM_NOTIFICATION_RESET: {
      return [];
    }
    default:
      return state;
  }
}
