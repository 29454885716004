import React from 'react';
import PropTypes from 'prop-types';
import ImageListItem from '@mui/material/ImageListItem';
import Fade from '@mui/material/Fade';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButtonPipe from '../../../../common/IconButtonPipe';
import ExportFilesIcon from '@mui/icons-material/DriveFileMoveOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Fullscreen } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  tile: {
    border: ({ isSelected, isMultipleEdit }) =>
      `4px solid ${isMultipleEdit && isSelected ? theme.palette.color.color4 : theme.palette.background.default}`,
    cursor: ({ isMultipleEdit }) => (isMultipleEdit ? 'pointer' : 'default')
  },
  titleBar: {
    background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  button: {
    padding: '4px 6px'
  },
  titleWrap: {
    marginLeft: 4
  },
  titleBarTop: {
    width: 40,
    right: 0,
    borderBottomLeftRadius: 40,
    borderTopLeftRadius: 15,
    marginLeft: 'auto'
  }
}));

const SingleMultimedia = ({
  isSelected = false,
  handleOnSelect = () => {},
  tile,
  isMultipleEdit = false,
  isEditAvailable = false,
  hideFullscreenButton = false,
  deleteOne = () => {},
  moveOne = () => {},
  handleLightboxOpen,
  style
}) => {
  const cl = useStyles({ isSelected, isMultipleEdit });
  const { name, thumbnailUrl, description } = tile;
  const renderContent = () => {
    return (
      <>
        <img src={thumbnailUrl} alt={description ? 'Zdjęcie z opisem: ' + description : name.split('.').shift()} />
        <Fade in={!isMultipleEdit}>
          <Box>
            <ImageListItemBar
              title={
                isEditAvailable && (
                  <>
                    <IconButtonPipe
                      aria-label={'Przenieś grafikę ' + (description !== null ? description : '')}
                      onClick={() => moveOne(tile)}
                      tooltip="Przenieś"
                      className={cl.button}
                      color="inherit">
                      <ExportFilesIcon className={cl.icon} />
                    </IconButtonPipe>
                    <IconButtonPipe
                      aria-label={'Usuń grafikę ' + (description !== null ? description : '')}
                      onClick={() => {
                        deleteOne(tile);
                      }}
                      tooltip="Usuń"
                      className={cl.button}
                      color="inherit">
                      <DeleteForeverIcon className={cl.icon} />
                    </IconButtonPipe>
                  </>
                )
              }
              classes={{
                root: cl.titleBar,
                titleWrap: cl.titleWrap
              }}
              actionIcon={
                !hideFullscreenButton && (
                  <IconButtonPipe
                    sx={{ color: 'white' }}
                    onClick={handleLightboxOpen}
                    tooltip="Powiększ"
                    aria-label={'Powiększ grafikę ' + (description !== null ? description : '')}>
                    <Fullscreen />
                  </IconButtonPipe>
                )
              }
            />
          </Box>
        </Fade>

        <Fade in={isMultipleEdit}>
          <Box>
            <ImageListItemBar
              titleposition="top"
              classes={{
                root: cl.titleBarTop
              }}
              actionIcon={
                <Checkbox
                  checked={isSelected}
                  sx={{ padding: 0, color: '#fff', '&.Mui-checked': { color: (theme) => theme.palette.color.color8 } }}
                  onChange={() => handleOnSelect(tile)}
                  inputProps={{ 'aria-label': `Zaznacz obrazek o nazwie ${name}` }}
                />
              }
            />
          </Box>
        </Fade>
      </>
    );
  };
  const renderClickable = () => {
    return (
      <ImageListItem
        sx={{
          overflow: 'hidden',
          outline: (theme) =>
            `4px solid ${isMultipleEdit && isSelected ? theme.palette.color.color4 : theme.palette.background.default}`,
          cursor: isMultipleEdit ? 'pointer' : undefined
        }}
        classes={{ tile: cl.tile }}
        onClick={() => (isMultipleEdit ? handleOnSelect(tile) : null)}>
        {renderContent()}
      </ImageListItem>
    );
  };
  const renderUnclickable = () => {
    return (
      <ImageListItem
        sx={{
          overflow: 'hidden',
          outline: (theme) =>
            `4px solid ${isMultipleEdit && isSelected ? theme.palette.color.color4 : theme.palette.background.default}`,
          cursor: isMultipleEdit ? 'pointer' : undefined
        }}
        classes={{ tile: cl.tile }}>
        {renderContent()}
      </ImageListItem>
    );
  };

  return isMultipleEdit ? renderClickable() : renderUnclickable();
};

SingleMultimedia.propTypes = {
  isSelected: PropTypes.bool,
  handleOnSelect: PropTypes.func,
  tile: PropTypes.object.isRequired,
  isMultipleEdit: PropTypes.bool,
  isEditAvailable: PropTypes.bool,
  hideFullscreenButton: PropTypes.bool,
  style: PropTypes.object,
  deleteOne: PropTypes.func,
  moveOne: PropTypes.func,
  handleLightboxOpen: PropTypes.func
};

export default SingleMultimedia;
