import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLegalGuardianAsync } from '../../../actions/childrenActions';
import { restartUserAccountActivation } from '../../../actions/userAccountActions';
import LegalGuardiansList from '../components/LegalGuardiansList';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import { Box, Grid, MenuItem, Pagination, Paper, useMediaQuery } from '@mui/material';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import Filter from '../components/Header/Filter';
import ContentWrapper from '../../common/contentWrapper';
import SelectField from '../../common/SelectField';
import { SearchBar } from '../../common/SearchBar';
import _ from 'lodash';

const LegalGuardiansContainer = () => {
  const legalGuardians = useSelector((state) => state.legalGuardians);
  const auth = useSelector((state) => state.auth);
  const isPinEnabled = useSelector((state) => state.configuration.guardiansPinService.enabled);
  const isAllowed = useSelector((state) => state.auth.isAllowed.bind(auth));
  const isStaffMember = useSelector((state) => state.auth.isStaffMember.bind(auth));
  const [legalGuardiansFromFilter, setLegalGuardiansFromFilter] = useState(legalGuardians);
  const [showOnlyLegalGuardiansWithoutChildren, setShowOnlyLegalGuardiansWithoutChildren] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const numberArrayOfOptions = [5, 10, 25, 50];
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  useEffect(() => {
    setLegalGuardiansFromFilter(legalGuardians);
  }, [legalGuardians]);
  const handleAssignedChildrenFilterChange = (value) => {
    setPage(0);
    setShowOnlyLegalGuardiansWithoutChildren(value);
  };

  const handleDelete = (legalGuardianId) => {
    dispatch(deleteLegalGuardianAsync(legalGuardianId));
  };

  const handleRestartActivation = (legalGuardianEmail) => {
    dispatch(restartUserAccountActivation(legalGuardianEmail));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterChange = (filteredList) => {
    setLegalGuardiansFromFilter(filteredList);
    setPage(0);
  };

  const preparedLegalGuardians = useMemo(() => {
    let legalGuardians = showOnlyLegalGuardiansWithoutChildren
      ? legalGuardiansFromFilter.filter((lg) => !showOnlyLegalGuardiansWithoutChildren || lg.children.length === 0)
      : legalGuardiansFromFilter;

    legalGuardians = legalGuardians.filter(
      (lg) =>
        (lg.firstName + ' ' + lg.lastName).toLowerCase().includes(searchText.toLowerCase()) ||
        (lg.lastName + ' ' + lg.firstName).toLowerCase().includes(searchText.toLowerCase()) ||
        (!!lg.email && lg.email.toLowerCase().includes(searchText.toLowerCase()))
    );

    return legalGuardians;
  }, [searchText, legalGuardiansFromFilter, showOnlyLegalGuardiansWithoutChildren]);

  const handleFilter = (keyword) => {
    setPage(0);
    setSearchText(keyword || '');
  };

  const countOfPagination = _.ceil(preparedLegalGuardians.length / rowsPerPage);
  return (
    <Box>
      <PageHeaderText title="Konta opiekunów" titleIcon={<PeopleOutlineOutlinedIcon />} />
      <ContentWrapper>
        <Grid container sx={{ pb: 1 }}>
          <Grid item xs={12} md={6}>
            <SearchBar
              sx={{ my: 1.5 }}
              onChange={(e) => handleFilter(e.target.value)}
              handleTextSearch={(value) => handleFilter(value)}
              value={searchText}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ py: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <SelectField
              sx={{
                width: 88
              }}
              id="rowsPerPage"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              floatingLabelText="Wierszy na stronę">
              {numberArrayOfOptions.map((item) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </SelectField>

            <Filter
              sx={{ height: '100px' }}
              rowsPerPage={rowsPerPage}
              itemsToFilter={legalGuardians}
              onFilter={handleFilterChange}
              showOnlyLegalGuardiansWithoutChildren={showOnlyLegalGuardiansWithoutChildren}
              onAssignedChildrenFilterChange={handleAssignedChildrenFilterChange}
              filter={(legal, keyword) =>
                legal.lastName.toLowerCase().includes(keyword) || legal.firstName.toLowerCase().includes(keyword)
              }
            />
          </Grid>
        </Grid>

        <Paper>
          <LegalGuardiansList
            legalGuardians={preparedLegalGuardians.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
            isPinEnabled={isPinEnabled}
            isAllowed={isAllowed}
            isStaffMember={isStaffMember}
            onRemove={handleDelete}
            onRestartActivation={handleRestartActivation}
          />
          {preparedLegalGuardians.length > 0 && (
            <Pagination
              siblingCount={isMobile ? 0 : 1}
              count={countOfPagination}
              page={page + 1}
              onChange={handleChangePage}
              sx={{ display: 'flex', justifyContent: 'center', mt: 4, pb: 4 }}
            />
          )}
        </Paper>
      </ContentWrapper>
    </Box>
  );
};

export default LegalGuardiansContainer;
