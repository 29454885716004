import { Box, Divider, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DatePicker from '../../../common/DatePicker';
import UserDefinedFields from '../../../common/UserDefinedFields';

const WopfuForm = ({ setData, selected, isReadonly, errors }) => {
  const [number, setNumber] = useState(selected && selected.opinionNumber);
  const [name, setName] = useState(selected && selected.name);
  const [fileDate, setFileDate] = useState(selected && selected.issueDate ? selected.issueDate : null);
  const [individualNeeds, setIndividualNeeds] = useState(selected && selected.individualDevelopmentAndEducationalNeeds);
  const [help, setHelp] = useState(selected && selected.parentSupportScope);
  const [programGrade, setProgramGrade] = useState(selected && selected.programEffectivenessAssessment);
  const [failures, setFailures] = useState(selected && selected.reasonsForEducationalFailures);
  const [strongSides, setStrongSides] = useState(selected && selected.studentStrengthsAndPreferences);

  useEffect(() => {
    setData({
      number: number,
      date: fileDate,
      name: name,
      needs: individualNeeds,
      help: help,
      strongSides: strongSides,
      programGrade: programGrade,
      failures: failures
    });
  }, [failures, fileDate, help, individualNeeds, name, number, programGrade, setData, strongSides]);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          error={!!errors?.errors?.name}
          helperText={errors?.errors?.name}
          disabled={isReadonly}
          sx={{ mt: 1 }}
          fullWidth
          multiline
          label="Nazwa WOPFU*"
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="standard"
        />
        <TextField
          error={!!errors?.errors?.number}
          helperText={errors?.errors?.number}
          disabled={isReadonly}
          sx={{ mt: 1 }}
          fullWidth
          multiline
          label="Numer orzeczenia*"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          variant="standard"
        />
        <DatePicker
          errorText={errors?.errors?.date}
          disabled={isReadonly}
          unlimitedMaxDate
          sx={{ width: 190, mr: 2, mt: 1 }}
          hintText="Data wydania orzeczenia*"
          name="date_from"
          floatingLabelText="Data wydania orzeczenia*"
          onChange={(empty, date) => {
            setFileDate(date);
          }}
          value={fileDate}
        />
      </Box>

      <Divider sx={{ mt: 2 }} />
      <UserDefinedFields
        errors={errors?.errors?.needs}
        isReadonly={isReadonly}
        fields={individualNeeds}
        setFields={setIndividualNeeds}
        fieldLabel="Indywidualne potrzeby rozwojowe i edukacyjne"
        isEditing={selected}
      />
      <UserDefinedFields
        errors={errors?.errors?.strongSides}
        isReadonly={isReadonly}
        fields={strongSides}
        setFields={setStrongSides}
        fieldName={strongSides}
        fieldLabel="Mocne strony, predyspozycje i zainteresowania ucznia"
        isEditing={selected}
      />

      <UserDefinedFields
        errors={errors?.errors?.help}
        isReadonly={isReadonly}
        fields={help}
        setFields={setHelp}
        fieldLabel="Zakres i charakter wsparcia ze strony nauczycieli, specjalistów lub pomocy nauczyciela"
        isEditing={selected}
      />

      <UserDefinedFields
        errors={errors?.errors?.programGrade}
        isReadonly={isReadonly}
        fields={programGrade}
        setFields={setProgramGrade}
        fieldName={programGrade}
        fieldLabel="Ocena efektywności programu"
        isEditing={selected}
      />
      <UserDefinedFields
        errors={errors?.errors?.failures}
        isReadonly={isReadonly}
        fields={failures}
        setFields={setFailures}
        fieldName={failures}
        fieldLabel="Przyczyny niepowodzeń edukacyjnych i trudności w funkcjonowaniu dziecka"
        isEditing={selected}
      />
    </Box>
  );
};

export default WopfuForm;
