import ApiBase, { ROOT_URL } from './ApiBase';

class DietsApi extends ApiBase {
  static loadDiets() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/meals/menu`).type('application/json'));
  }

  static loadDietDetails(dietId) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/meals/menu/${dietId}`).type('application/json'));
  }

  static createDiet(diet) {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/meals/menu`).send(diet).type('application/json'));
  }

  static updateDiet(diet) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/meals/menu/${diet.id}`).send(diet).type('application/json')
    );
  }

  static deleteDiet(dietId) {
    return super.toPromise((agent) => agent.delete(`${ROOT_URL}/meals/menu/${dietId}`).type('application/json'));
  }

  static enrollChildren(body) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/meals/menu/enrollChildren`).send(body).type('application/json')
    );
  }

  static unrollChildren(body) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/meals/menu/disenrollChildren`).send(body).type('application/json')
    );
  }

  static changeChildEnrollment(body) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/meals/menu/changeChildEnrollment`).send(body).type('application/json')
    );
  }
}

export default DietsApi;
