import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../../forms/DialogForm';
import { WorkPlanValidationTopicModel } from '../../../../models/digitalDiary/workPlan/WorkPlanValidationModels';
import TextField from '../../../common/TextField';
import { Box } from '@mui/material';

class WorkPlanTopicDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workPlanSetTopic: this.props.workPlanSetTopic,
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const field = event.target.name;
    const workPlanSetTopic = { ...this.state.workPlanSetTopic };
    workPlanSetTopic[field] = event.target.value;
    return this.setState({ workPlanSetTopic });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing, isEditing } = this.props;
    return (
      <Box>
        <DialogForm
          header={isEditing ? 'Edytuj temat' : 'Dodaj temat'}
          onSave={() => onSave(this.state.workPlanSetTopic)}
          onCancel={onCancel}
          isDialogOpen={isDialogOpen}
          isProcessing={isProcessing}
          onValidate={() => new WorkPlanValidationTopicModel().validate(this.state.workPlanSetTopic)}
          onValidationDone={(errors) => this.setState({ errors })}
          statePathToUi="configuration.attributesSetsUi"
          requiredFieldText>
          <TextField
            hintText="Podaj nazwę tematu"
            floatingLabelText="Podaj nazwę tematu*"
            floatingLabelFixed={false}
            floatingLabelStyle={{ fontSize: '20px' }}
            inputStyle={{ fontSize: '20px' }}
            autoFocus={true}
            multiLine={true}
            name="topicName"
            value={this.state.workPlanSetTopic.topicName}
            onChange={this.handleChange}
            fullWidth={true}
            errorText={this.state.errors.topicName}
          />
          <TextField
            hintText="Podaj działania edukacyjne"
            floatingLabelText="Podaj działania edukacyjne*"
            floatingLabelFixed={false}
            autoFocus={false}
            multiLine={true}
            name="todo"
            value={this.state.workPlanSetTopic.todo}
            onChange={this.handleChange}
            fullWidth={true}
            errorText={this.state.errors.todo}
          />
          <TextField
            hintText="Podaj środki dydaktyczne"
            floatingLabelText="Podaj środki dydaktyczne*"
            floatingLabelFixed={false}
            autoFocus={false}
            multiLine={true}
            name="books"
            value={this.state.workPlanSetTopic.books}
            onChange={this.handleChange}
            fullWidth={true}
            errorText={this.state.errors.books}
          />
          <TextField
            hintText="Podaj uwagi nauczyciela"
            floatingLabelText="Podaj uwagi nauczyciela*"
            floatingLabelFixed={false}
            autoFocus={false}
            multiLine={true}
            name="comments"
            value={this.state.workPlanSetTopic.comments}
            onChange={this.handleChange}
            fullWidth={true}
            errorText={this.state.errors.comments}
          />
        </DialogForm>
      </Box>
    );
  }
}

WorkPlanTopicDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool,
  workPlanSetTopic: PropTypes.object
};

export default WorkPlanTopicDialog;
