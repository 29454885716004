import ApiBase, { ROOT_URL } from './ApiBase';

class GuardianPinServiceApi extends ApiBase {
  static getPinServiceConfiguration() {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/configuration/guardiansPinService`).type('application/json')
    );
  }

  static getGuardiansWithoutPin() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/pins/peopleWithoutPin`).type('application/json'));
  }

  static generateInitialPins() {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/pins/generateInitialPins`).send().type('application/json')
    );
  }

  static updatePinServiceConfiguration(enabled) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/configuration/guardiansPinService`).send({ enabled }).type('application/json')
    );
  }

  static addPinDevice(login, password) {
    return super.toPromise(
      (agent) =>
        agent
          .post(`${ROOT_URL}/configuration/guardiansPinService/devices`)
          .send({ login, password })
          .type('application/json'),
      super.filterLocationHeader
    );
  }

  static removePinDevice(id) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/configuration/guardiansPinService/devices/${id}`).type('application/json')
    );
  }

  static resetPinDevicePassword(id, newPassword) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/configuration/guardiansPinService/devices/${id}/resetPassword`)
        .send({ newPassword })
        .type('application/json')
    );
  }
}

export default GuardianPinServiceApi;
