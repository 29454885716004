/*eslint indent:0*/
import initialState from "./initial";
import * as types from "../actions/actionTypes";
import moment from "moment";

export default function psychologicalHelpDetailsReducer(state = initialState.psychologicalHelpDetails, action) {
  switch (action.type) {
    case types.LOAD_PSYCHOLOGICAL_DETAILS: {
      return action.payload;
    }
    case types.ADD_PSYCHOLOGICAL_NOTIFICATION: {
      let newState = Object.assign({}, state);
      let newNotify = action.data;
      newNotify.id = action.res.id;
      newNotify.creationDate = moment(action.data).format("YYYY-MM-DD");
      newState.lettersOfNotification.push(newNotify);
      return newState;
    }

    case types.EDIT_PSYCHOLOGICAL_NOTIFICATION: {
      let newState = Object.assign({}, state);
      let notify = newState.lettersOfNotification;
      let newNotify = action.data;
      newNotify.id = action.id;
      newNotify.creationDate = moment(action.data).format("YYYY-MM-DD");
      notify = [...notify.filter((w) => w.id !== action.id), action.data];
      newState.lettersOfNotification = notify;
      return newState;
    }
    case types.ARCHIVE_PSYCHOLOGICAL_NOFITICATION: {
      let newState = Object.assign({}, state);
      newState.lettersOfNotification = newState.lettersOfNotification.filter((notify) => notify.id !== action.id);
      return newState;
    }
    case types.ADD_WOPFU: {
      let newState = Object.assign({}, state);
      let wopfu = state.WOPFUs;
      let newWopfu = action.data;
      newWopfu.id = action.res.id;
      newWopfu.creationDate = action.data.creationDate;
      wopfu.push(newWopfu);
      newState.WOPFUs = wopfu;
      return newState;
    }
    case types.EDIT_WOPFU: {
      let newState = Object.assign({}, state);
      let wopfu = state.WOPFUs;
      let newWopfu = action.data;
      newWopfu.id = action.wopfuId;
      wopfu = [...wopfu.filter((w) => w.id !== action.wopfuId), newWopfu];
      newState.WOPFUs = wopfu;
      return newState;
    }
    case types.ARCHIVE_WOPFU: {
      let newState = Object.assign({}, state);
      newState.WOPFUs = newState.WOPFUs.filter((wopfu) => wopfu.id !== action.wopfuId);
      return newState;
    }
    case types.ADD_IPET: {
      let newState = Object.assign({}, state);
      let ipet = state.IPETs;
      let newIpet = action.data;
      newIpet.id = action.res.id;
      newIpet.creationDate = action.data.creationDate;
      ipet.push(newIpet);
      newState.IPETs = ipet;
      return newState;
    }
    case types.EDIT_IPET: {
      let newState = Object.assign({}, state);
      let ipet = state.IPETs;
      let newIpet = action.data;
      newIpet.id = action.ipetId;
      ipet = [...ipet.filter((w) => w.id !== action.ipetId), newIpet];
      newState.IPETs = ipet;
      return newState;
    }
    case types.ARCHIVE_IPET: {
      let newState = Object.assign({}, state);
      newState.IPETs = newState.IPETs.filter((ipet) => ipet.id !== action.ipetId);
      return newState;
    }

    default:
      return state;
  }
}
