import React from 'react';
import PropTypes from 'prop-types';
import { Fade, useTheme, Box } from '@mui/material';
import IconButtonPipe from '../../../../../components/common/IconButtonPipe';
import ExpandLessAll from '../../../../../assets/icons/ExpandLessAll';
import ExpandMoreAll from '../../../../../assets/icons/ExpandMoreAll';
import { useShareFileContext } from '../../../context/ShareFileContext';

const Expand = ({ onClick, isForceOpenCollapse, isVisible }) => {
  const { isDisabled } = useShareFileContext();

  const theme = useTheme();
  return (
    <Fade in={isVisible} timeout={500}>
      <Box>
        <IconButtonPipe
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
          tooltip={isForceOpenCollapse ? 'Zwiń wszystkie' : 'Rozwiń wszystkie'}
          disabled={isDisabled}>
          {isForceOpenCollapse ? (
            <ExpandLessAll style={{ color: isDisabled ? theme.palette.color.disabled : undefined }} />
          ) : (
            <ExpandMoreAll style={{ color: isDisabled ? theme.palette.color.disabled : undefined }} />
          )}
        </IconButtonPipe>
      </Box>
    </Fade>
  );
};

Expand.propTypes = {
  onClick: PropTypes.func.isRequired,
  isForceOpenCollapse: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired
};

export default Expand;
