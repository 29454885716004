/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { DayOffModel } from '../models/configuration/daysOff/DaysOffModels';

export default function daysOffReducer(state = initialState.configuration.daysOff, action) {
  switch (action.type) {
    case types.LOAD_DAYS_OFF_SUCCESS: {
      return action.daysOff.map((dayOff) => new DayOffModel().assign(dayOff));
    }
    case types.ADD_DAY_OFF_SUCCESS: {
      return [...state, new DayOffModel().assign(action.dayOff)];
    }
    case types.REMOVE_DAY_OFF_SUCCESS: {
      return [...state.filter((d) => d.date !== action.dayOff.date)];
    }
    default:
      return state;
  }
}
