import PropTypes from 'prop-types';
import React from 'react';
import TextAttributeTemplate from './TextAttributeTemplate';
import FlagAttributeTemplate from './FlagAttributeTemplate';
import { attributeTypes } from '../../../../constants/attributeTypes';

const TemplatesProvider = ({ attributeDefinition, onChange, className }) => {
  const { type, ...other } = attributeDefinition;
  const props = {
    id: other.id,
    value: other.isInitialValue ? other.defaultValue : other.value,
    name: other.name,
    onChange,
    className
  };
  if (type === attributeTypes.text.value) return <TextAttributeTemplate {...props} />;
  if (type === attributeTypes.flag.value) return <FlagAttributeTemplate {...props} />;

  return null;
};

TemplatesProvider.propTypes = {
  attributeDefinition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default TemplatesProvider;
