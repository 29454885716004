import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { tryConvertToNumber } from '../../utils/numberConverters';
import TextField from '../common/TextField';

const CalculateSettlementsChildrenList = ({ children, onChange }) => (
  <Box>
    {children.map((child) => {
      return (
        <Grid container key={child.id}>
          <Grid xs={9}>
            {child.lastName} {child.firstName}
          </Grid>
          <Grid xs={2}>
            <TextField
              fullWidth={true}
              onChange={onChange}
              name={child.id}
              onBlur={(e) => {
                onChange({ target: { name: child.id, value: tryConvertToNumber(e.target.value) } });
              }}
            />
          </Grid>
          <Grid xs={1}>min.</Grid>
        </Grid>
      );
    })}
  </Box>
);

CalculateSettlementsChildrenList.propTypes = {
  children: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default CalculateSettlementsChildrenList;
