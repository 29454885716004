import initialState from './initial';
import * as types from '../actions/actionTypes';
import { AbsenceTomorrow } from '../models/attendance/AbsenceTomorrow';
import { LOAD_ABSENCE_TOMORROW } from '../actions/actionTypes';

export default function tomorrowAbsenceReducer(state = initialState.absenceTomorrow, action) {
  switch (action.type) {
    case types.LOAD_ABSENCE_TOMORROW_SUCCESS: {
      return { entries: action.response.map((entry) => new AbsenceTomorrow().assign(entry)), isLoading: false };
    }
    case types.LOAD_ABSENCE_TOMORROW: {
      return { ...state, isLoading: true };
    }
    default:
      return state;
  }
}
