import React from 'react';
import PropTypes from 'prop-types';
import AccountActivationForm, {
  InitialValues,
  validate,
  VALIDATION_SCHEMA
} from '../../../../../forms/userAccount/AccountActivationForm';
import FormikContentWrapper from '../../../../formik/FormikContentWrapper';
import { Box } from '@mui/material';

const AccountActivationFormWrap = ({ onSubmit, handleAgreementOpen, isCheckbox }) => {
  const initialValues = new InitialValues();
  if (!isCheckbox) initialValues.deleteCheckbox();
  return (
    <FormikContentWrapper
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      validate={validate}
      onSubmit={onSubmit}
      isButton
      buttonLabel="Potwierdź rejestrację"
      isRequiredText>
      {() => (
        <Box sx={{ py: 3, px: 0 }}>
          <AccountActivationForm handleAgreementOpen={handleAgreementOpen} isCheckbox={isCheckbox} />
        </Box>
      )}
    </FormikContentWrapper>
  );
};

AccountActivationFormWrap.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleAgreementOpen: PropTypes.func.isRequired,
  isCheckbox: PropTypes.bool.isRequired
};

export default AccountActivationFormWrap;
