import { Breadcrumbs, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getFolderContent } from '../../../../actions/virtualDiscActions';

const FolderBreadcrumbs = ({ actionAfterFetch }) => {
  const { path, folderId } = useSelector((state) => state.virtualDiscFolder);
  const dispatch = useDispatch();

  const handleFethFolder = (folderId, index) => {
    if (index + 1 !== path.length) {
      dispatch(getFolderContent(folderId));
      if (!!actionAfterFetch) actionAfterFetch();
    }
  };
  const handleKeyPress = (e, folderId, index) => {
    if (e.key === 'Enter' || e.charCode === 32) {
      e.preventDefault();
      handleFethFolder(folderId, index);
    }
  };

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      mx={1}
      maxItems={3}
      itemsAfterCollapse={2}
      sx={{
        flexWrap: 'wrap'
      }}>
      {path.map((folder, index) => (
        <Link
          key={folder.id}
          tabIndex={0}
          underline="hover"
          sx={{
            '&:hover': {
              cursor: 'pointer'
            },
            fontWeight: folderId === folder.id ? 'bold' : 'regular',
            color: (theme) => theme.palette.text.primary
          }}
          onClick={() => handleFethFolder(folder.id, index)}
          onKeyPress={(e) => handleKeyPress(e, folder.id, index)}>
          {folder.name}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default FolderBreadcrumbs;
