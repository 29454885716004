import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as settlementsActions from '../../../actions/settlementsActions';
import DatePicker from '../../common/DatePicker';
import DialogForm from '../../forms/DialogForm';
import { PaymentMethods } from '../../../constants/paymentMethods';
import PaymentMethodOptions from './PaymentMethodOptions';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import numberFormatter from '../../../utils/numberFormatter';
import { tryConvertToNumber } from '../../../utils/numberConverters';
import moment from 'moment';
import _ from 'lodash';
import TextField from '../../common/TextField';
import {
  Box,
  Checkbox,
  Select,
  MenuItem,
  Tooltip,
  CircularProgress,
  Button,
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  Typography
} from '@mui/material';
import WalletConfig from './WalletConfig.json';
import EmptyState from '../../common/EmptyState';
import InfoIcon from '@mui/icons-material/Info';
import Strong from '../../common/Strong';
import LoadingIndicator from '../../common/loading/LoadingIndicator';

class RegisterPaymentsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentDetails: {
        paymentMethod: PaymentMethods.transfer.value,
        paymentDate: moment().format('YYYY-MM-DD'),
        paymentAmount: '',
        payments: [],
        balance: {},
        errorMessageList: []
      },
      errors: {},
      walletType: WalletConfig.walletConfig.find((wallet) => wallet.name === this.props.dueType)?.name,
      availableFunds: 0,
      open: false,
      isCalculating: false
    };

    this.handleParamsChange = this.handleParamsChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.dueAmountDisabled = this.dueAmountDisabled.bind(this);
    this.getDueAmount = this.getDueAmount.bind(this);
    this.handleWalletChange = this.handleWalletChange.bind(this);
    this.handleChangeWalletToTransfer = this.handleChangeWalletToTransfer.bind(this);
    this.handleCalculateDelayInterests = this.handleCalculateDelayInterests.bind(this);
    this.handleCloseTooltip = this.handleCloseTooltip.bind(this);
    this.handleOpenTooltip = this.handleOpenTooltip.bind(this);
  }

  componentDidMount() {
    this.props.actions.getChildUnpaidDues(this.props.person.id);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.availableFunds) !== JSON.stringify(this.props.availableFunds)) {
      this.handleWalletChange(this.props.dueType);
    }
  }

  async handleCalculateDelayInterests() {
    this.setState({ isCalculating: true });
    const duesToCalculate = {
      maturityDate: this.state.paymentDetails.paymentDate,
      duesId: this.state.paymentDetails.payments.map((payment) => payment.dueId)
    };
    await this.props.actions.calculateDelayInterests(this.props.year, this.props.month, duesToCalculate);
    await this.props.actions.getChildUnpaidDues(this.props.person.id);
    this.setState({ isCalculating: false });
  }

  async handleWalletChange(wType) {
    await this.setState({ walletType: wType });
    switch (wType) {
      case 'general':
        this.setState({ availableFunds: this.props.availableFunds.availableFunds || 0 });
        break;
      case 'stay':
        this.setState({ availableFunds: this.props.availableFunds.availableFundsStay || 0 });
        break;
      case 'catering':
        this.setState({ availableFunds: this.props.availableFunds.availableFundsCatering || 0 });
        break;
      case 'activities':
        this.setState({ availableFunds: this.props.availableFunds.availableFundsActivities || 0 });
        break;
      case 'other':
        this.setState({ availableFunds: this.props.availableFunds.availableFundsOther || 0 });
        break;
      case 'meals':
        this.setState({ availableFunds: this.props.availableFunds.availableFundsMeals || 0 });
      default:
        break;
    }
    this.temp = this.state.paymentDetails;
    this.temp.payments = [];
    if (this.state.paymentDetails.paymentMethod === PaymentMethods.fromBalance.value) {
      this.temp.paymentAmount = this.state.availableFunds || 0;
    }
    this.setState({ paymentDetails: this.temp });
  }

  handleChangeWalletToTransfer(event) {
    this.handleWalletChange(event.target.value);
  }

  handleCloseTooltip() {
    this.setState({ open: false });
  }

  handleOpenTooltip() {
    this.setState({ open: true });
  }

  handleParamsChange(event) {
    /*eslint no-param-reassign: 0*/
    const field = event.target.name;
    const { paymentDetails } = this.state;
    paymentDetails[field] = event.target.value;

    /*eslint default-case: 0*/
    switch (field) {
      case 'paymentAmount':
        paymentDetails.payments.forEach((payment) => {
          payment.amount = 0;
        });
        break;
      case 'paymentDate':
        paymentDetails.payments.forEach((payment) => {
          payment.date = paymentDetails[field];
        });
        break;
      case 'paymentMethod':
        paymentDetails.payments.forEach((payment) => {
          payment.method = paymentDetails[field];
        });
        paymentDetails.payments = [];
        if (paymentDetails.paymentMethod === PaymentMethods.fromBalance.value) {
          paymentDetails.paymentAmount = this.state.availableFunds;
        }
        break;
    }
    paymentDetails.balance = this.getBalance(paymentDetails);

    return this.setState({ paymentDetails });
  }

  handleCheck(dueId, isChecked, paidAmount, isInterestCalculation, dueNumber) {
    const { paymentDetails } = this.state;
    paymentDetails.payments = [...paymentDetails.payments];

    const diff = _.round(this.calculateBalanceAmount(paymentDetails) - paidAmount, 2);
    let initialAmount = diff >= 0 ? paidAmount : _.round(paidAmount + diff, 2);
    if (this.calculateBalanceAmount(paymentDetails) < 0) initialAmount = 0;

    if (isChecked) {
      const newPayment = {
        dueId,
        dueNumber,
        method: paymentDetails.paymentMethod,
        date: paymentDetails.paymentDate,
        amount: initialAmount,
        walletType: this.state.walletType,
        isInterestPayment: !!isInterestCalculation
      };
      paymentDetails.payments.push(newPayment);
    } else {
      _.remove(
        paymentDetails.payments,
        (payment) => `${payment.dueId}-${payment.dueNumber}` === `${dueId}-${dueNumber}`
      );
    }
    paymentDetails.balance = this.getBalance(paymentDetails);
    this.setState({ paymentDetails });
  }

  handleAmountChange(event) {
    const dueNumber = event.target.name;
    const amount = typeof event.target.value === 'string' ? event.target.value.replace(',', '.') : event.target.value;
    const amountNumber = tryConvertToNumber(amount);
    const { paymentDetails } = this.state;
    const updatedPayments = [...paymentDetails.payments];
    const otherPaymentsSum = _.round(
      _.sumBy(
        updatedPayments.filter((payment) => payment.dueNumber !== dueNumber),
        (payment) => payment.amount
      ),
      2
    );
    const paymentToUpdate = _.find(updatedPayments, (p) => p.dueNumber === dueNumber);

    if (paymentToUpdate) {
      paymentToUpdate.amount = amountNumber;
      if (!paymentDetails.paymentAmount || amountNumber > _.round(paymentDetails.paymentAmount - otherPaymentsSum, 2)) {
        paymentDetails.errorMessageList = [...paymentDetails.errorMessageList, dueNumber];
      } else {
        paymentDetails.errorMessageList = paymentDetails.errorMessageList.filter((error) => error !== dueNumber);
      }
    }
    const updatedPaymentDetails = {
      ...paymentDetails,
      payments: updatedPayments,
      balance: this.getBalance(paymentDetails)
    };
    return this.setState(this.checkSummaryAmount(updatedPaymentDetails));
  }

  getDueAmount(dueNumber) {
    const payment = _.find(this.state.paymentDetails.payments, (p) => p.dueNumber === dueNumber);
    return payment ? payment.amount : '';
  }

  getBalance(paymentDetails) {
    const balanceAmount = this.calculateBalanceAmount(paymentDetails);
    if (!balanceAmount || paymentDetails.paymentMethod === PaymentMethods.fromBalance.value) return null;
    return {
      method: paymentDetails.paymentMethod,
      date: paymentDetails.paymentDate,
      amount: this.calculateBalanceAmount(paymentDetails),
      walletType: this.state.walletType
    };
  }

  dueAmountDisabled(dueNumber) {
    return !this.state.paymentDetails.payments.some((payment) => payment.dueNumber === dueNumber);
  }

  checkSummaryAmount(paymentDetails) {
    const summaryAmount = paymentDetails.payments.reduce(
      (total, payment) => total + parseInt(payment.amount ? payment.amount : 0, 10),
      0
    );

    if (paymentDetails.paymentAmount >= summaryAmount) paymentDetails.errorMessageList = [];

    return { paymentDetails };
  }

  calculateBalanceAmount(paymentDetails) {
    const paymentsSum = _.sumBy(paymentDetails.payments, (payment) => _.round(payment.amount || 0, 2));
    const amount = _.round(paymentDetails.paymentAmount || 0, 2) - _.round(paymentsSum, 2);
    return _.round(amount || 0, 2);
  }

  renderSummary() {
    return (
      <Box sx={{ mt: 1 }}>
        Stan środków w portfelu po rozliczeniu{' '}
        <Strong>
          {numberFormatter.format(
            this.calculateBalanceAmount(this.state.paymentDetails) + this.state.availableFunds || 0
          )}{' '}
          zł
        </Strong>
      </Box>
    );
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing, person, dues, isLoading } = this.props;
    const { paymentDetails } = this.state;
    return (
      <DialogForm
        header={`Rozliczanie opłat dla dziecka ${person.firstName} ${person.lastName}`}
        onSave={() => onSave(this.state.paymentDetails)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing || isLoading}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="registerPaymentsUi"
        hasConfirmationDialog={this.calculateBalanceAmount(this.state.paymentDetails) > 0}
        confirmationTitle="Potwierdzenie wpłaty"
        confirmationText={`Czy na pewno chcesz dokonać wpłaty? Na portfelu dziecka zostanie zaksięgowana nadpłata w wysokości ${this.calculateBalanceAmount(
          this.state.paymentDetails
        )} zł.`}
        saveDisabled={
          parseFloat(this.state.paymentDetails.paymentAmount || '0') <= 0 ||
          this.state.paymentDetails.payments.length === 0 ||
          this.state.isCalculating
        }>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <PaymentMethodOptions
              value={paymentDetails.paymentMethod}
              onSelected={(value) => this.handleParamsChange({ target: { name: 'paymentMethod', value } })}
            />
            {this.state.paymentDetails.paymentMethod === PaymentMethods.fromBalance.value ? (
              <Box>
                Aktualny stan środków <Strong>{numberFormatter.format(this.state.availableFunds) || 0} zł</Strong>
              </Box>
            ) : null}
            <Box
              sx={{
                py: 2,
                display: 'flex',
                alignItems: { xs: 'start', md: 'center' },
                flexDirection: { xs: 'column', md: 'row' }
              }}>
              <Typography sx={{ mr: 2, mt: 1 }}>
                Aktualny portfel{' '}
                {this.state.paymentDetails.paymentMethod !== PaymentMethods.fromBalance.value
                  ? 'na który zostaną przelane pozostałe środki'
                  : null}
                :
              </Typography>

              <Select
                sx={{
                  color: (theme) => theme.palette.color.color2,
                  borderBottom: (theme) => `1px solid ${theme.palette.color.color2}`,
                  '&:hover': {
                    borderBottom: (theme) => `1px solid ${theme.palette.color.color2}`
                  },
                  '& .MuiSvgIcon-root': {
                    color: (theme) => theme.palette.color.color2
                  }
                }}
                variant="standard"
                value={this.state.walletType}
                onChange={(event) => this.handleChangeWalletToTransfer(event)}>
                {WalletConfig.walletConfig.map((wallet) => (
                  <MenuItem key={wallet.id} value={wallet.name}>
                    {wallet.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              display="flex"
              sx={{
                py: 1,
                px: 0,
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: { xs: 'start', md: 'center' }
              }}>
              <TextField
                name="paymentAmount"
                value={
                  paymentDetails.paymentMethod === PaymentMethods.fromBalance.value
                    ? numberFormatter.format(this.state.availableFunds) || 0
                    : paymentDetails.paymentAmount
                }
                hintText="Wprowadź kwotę"
                floatingLabelText="Kwota wpłaty"
                floatingLabelFixed={true}
                onChange={this.handleParamsChange}
                sx={{ m: 0, pb: 1 }}
                onBlur={(e) => {
                  this.handleParamsChange({
                    target: { name: 'paymentAmount', value: tryConvertToNumber(e.target.value) }
                  });
                }}
                disabled={this.state.paymentDetails.paymentMethod === PaymentMethods.fromBalance.value}
              />
              <DatePicker
                hintText="Data wpłaty"
                floatingLabelText="Data wpłaty"
                floatingLabelFixed={false}
                name="paymentDate"
                value={new Date(paymentDetails.paymentDate)}
                errorText={this.state.errors.paymentDate}
                minDate={new Date('2000/01/01')}
                maxDate={new Date()}
                onChange={(empty, date) => {
                  this.handleParamsChange({
                    target: { name: 'paymentDate', value: moment(date).format('YYYY-MM-DD') }
                  });
                }}
                sx={{ width: 200, mb: 1, mt: 0, ml: { xs: 0, md: 1 }, mr: 0 }}
              />
              {this.state.isCalculating ? (
                <CircularProgress sx={{ mt: 2, mr: 0, mb: 0, ml: 2 }} size={20} />
              ) : (
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    variant="contained"
                    aria-label="Przelicz odsetki"
                    onClick={() => this.handleCalculateDelayInterests()}
                    sx={{ ml: 3, mt: 0, mr: 0, mb: 0 }}
                    disabled={this.state.paymentDetails.payments.length <= 0}>
                    Przelicz odsetki
                  </Button>
                  <Tooltip
                    open={this.state.open}
                    onClose={this.handleCloseTooltip}
                    onOpen={this.handleOpenTooltip}
                    title="Aby przeliczyć odsetki dla opłat należy wybrać datę dokonania wpłaty oraz zaznaczyć płatności, dla których czynność będzie zastosowana">
                    <InfoIcon sx={{ ml: 1, mt: 0, mr: 0, mb: 0 }} color="primary" />
                  </Tooltip>
                </Box>
              )}
            </Box>
            <TableContainer>
              <Table size="small" aria-label="Tabela rejestracji opłat">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 30 }} />
                    <TableCell sx={{ minWidth: 50 }} align="left">
                      Miesiąc
                    </TableCell>
                    <TableCell sx={{ minWidth: 50 }} align="left">
                      Numer
                    </TableCell>
                    <TableCell sx={{ minWidth: 50 }} align="left">
                      Opłata
                    </TableCell>
                    <TableCell sx={{ minWidth: 50 }} align="left">
                      Kwota
                    </TableCell>
                    <TableCell sx={{ minWidth: 50 }} align="left">
                      Do zapłaty
                    </TableCell>
                    <TableCell sx={{ minWidth: 50 }} align="left">
                      Wpłata
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.paymentDetails.paymentMethod !== PaymentMethods.fromBalance.value ||
                  this.state.walletType === 'general'
                    ? dues.map((due, index) => (
                        <TableRow key={due.number}>
                          <TableCell sx={{ minWidth: 30 }} align="center">
                            <Checkbox
                              key={this.state.paymentDetails.paymentMethod + this.state.walletType}
                              color="primary"
                              inputProps={{ 'aria-label': `Wybierz wiersz ${index}` }}
                              onChange={(e) =>
                                this.handleCheck(
                                  due.id,
                                  e.target.checked,
                                  due.getOutstandingAmount(),
                                  due.isInterestCalculation,
                                  due.number
                                )
                              }
                            />
                          </TableCell>
                          <TableCell sx={{ minWidth: 50 }} align="left">
                            {due.month}
                          </TableCell>
                          <TableCell sx={{ minWidth: 50 }} align="left">
                            {due.number}
                          </TableCell>
                          <TableCell sx={{ minWidth: 50 }} align="left">
                            {ChargingReportTypes[due.dueType].name}
                          </TableCell>
                          <TableCell sx={{ minWidth: 50 }} align="left">
                            <Strong>{numberFormatter.format(due.getTotalAmount())}</Strong>
                          </TableCell>
                          <TableCell sx={{ minWidth: 50 }} align="left">
                            <Strong>{numberFormatter.format(due.getOutstandingAmount())}</Strong>
                          </TableCell>
                          <TableCell sx={{ minWidth: 50, maxWidth: 100 }} align="right">
                            <TextField
                              fullWidth={true}
                              floatingLabelText="Podaj kwote"
                              name={due.number}
                              disabled={this.dueAmountDisabled(due.number)}
                              value={this.getDueAmount(due.number)}
                              onChange={this.handleAmountChange}
                              errorText={
                                this.state.paymentDetails.errorMessageList.some((value) => value === due.number)
                                  ? 'Kwota jest zbyt duża'
                                  : ''
                              }
                              endAdornment={<InputAdornment position="end">zł</InputAdornment>}
                              onBlur={(e) => {
                                this.handleAmountChange({
                                  target: { name: due.id, value: tryConvertToNumber(e.target.value) }
                                });
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                  {this.state.paymentDetails.paymentMethod === PaymentMethods.fromBalance.value &&
                  this.state.walletType !== 'general'
                    ? dues
                        .filter((due) => due.dueType === this.state.walletType)
                        .map((due, index) => (
                          <TableRow key={due.number}>
                            <TableCell sx={{ minWidth: 30 }} align="center">
                              <Checkbox
                                key={this.state.paymentDetails.paymentMethod + this.state.walletType}
                                color="primary"
                                inputProps={{ 'aria-label': `Wybierz wiersz ${index}` }}
                                onChange={(e) =>
                                  this.handleCheck(
                                    due.id,
                                    e.target.checked,
                                    due.getOutstandingAmount(),
                                    due.isInterestCalculation,
                                    due.number
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell sx={{ minWidth: 50 }} align="left">
                              {due.month}
                            </TableCell>
                            <TableCell sx={{ minWidth: 50 }} align="left">
                              {due.number}
                            </TableCell>
                            <TableCell sx={{ minWidth: 50 }} align="left">
                              {ChargingReportTypes[due.dueType].name}
                            </TableCell>
                            <TableCell sx={{ minWidth: 50 }} align="left">
                              <Strong>{numberFormatter.format(due.getTotalAmount())}</Strong>
                            </TableCell>
                            <TableCell sx={{ minWidth: 50 }} align="left">
                              <Strong>{numberFormatter.format(due.getOutstandingAmount())}</Strong>
                            </TableCell>
                            <TableCell sx={{ minWidth: 50, maxWidth: 100 }} align="right">
                              <TextField
                                fullWidth={true}
                                floatingLabelText="Podaj kwote"
                                name={due.id}
                                disabled={this.dueAmountDisabled(due.number)}
                                value={this.getDueAmount(due.number)}
                                onChange={this.handleAmountChange}
                                endAdornment={<InputAdornment position="end">zł</InputAdornment>}
                                onBlur={(e) => {
                                  this.handleAmountChange({
                                    target: { name: due.id, value: tryConvertToNumber(e.target.value) }
                                  });
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                    : null}
                </TableBody>
              </Table>
              <Typography variant="caption" sx={{ color: (theme) => theme.palette.color.contrast }}>
                Tabela rejestracji opłat
              </Typography>
              {dues.filter(
                (due) =>
                  due.dueType === this.state.walletType ||
                  this.state.paymentDetails.paymentMethod !== PaymentMethods.fromBalance.value ||
                  this.state.walletType !== 'general'
              ).length > 0 ? null : (
                <EmptyState message="Nie znaleziono żadnych płatności możliwych do zapłacenia przy użyciu danego portfela" />
              )}
            </TableContainer>
            {this.state.paymentDetails.paymentMethod !== PaymentMethods.fromBalance.value ? this.renderSummary() : null}
          </>
        )}
      </DialogForm>
    );
  }
}

RegisterPaymentsDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  dues: PropTypes.array,
  availableFunds: PropTypes.object,
  dueType: PropTypes.string,
  year: PropTypes.string,
  month: PropTypes.string
};

function mapStateToProps(state) {
  return {
    dues: state.childDues.dues,
    availableFunds: state.childDues.balance,
    isLoading: state.childDues.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settlementsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPaymentsDialog);
