import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const Badge = ({ badgeValue, sx }) => {
  return (
    <Box
      sx={{
        borderRadius: '50%',
        background: 'red',
        color: 'white',
        width: 18,
        height: 18,
        p: '1px',
        textAlign: 'center',
        ...sx
      }}>
      <Typography sx={{ fontSize: 12, fontWeight: (theme) => theme.typography.fontWeightLarge }}>
        {badgeValue}
      </Typography>
    </Box>
  );
};

Badge.propTypes = {
  badgeValue: PropTypes.number,
  sx: PropTypes.object
};

export default Badge;
