import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import OverpaymentsListHeader from './OverpaymentsListHeader';
import OverpaymentsListBody from './OverpaymentsListBody';
import * as definedSortTypes from '../../../constants/definedSortTypes';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import {
  Box,
  Paper,
  Table,
  TableContainer,
  Typography,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Pagination
} from '@mui/material';
import ConfirmationDialog from '../../forms/ConfirmationDialog';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import EmptyState from '../../common/EmptyState';
import ClearButtonWrapper from '../../common/ClearButtonWrapper';
import DatePicker from '../../common/DatePicker';
import _ from 'lodash';
import { processRefunds } from '../../../actions/settlementsActions';

const OverpaymentsList = ({ overpayments }) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const numberArrayOfOptions = [5, 10, 25, 50];
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState({ property: 'number', type: 'string' });
  const [filteredOverpayments, setFilteredOverpayments] = useState(overpayments);
  const [overdueType, setOverdueType] = useState('');
  const [notificationList, setNotificationList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const dispatch = useDispatch();
  const allChecked = notificationList.length === filteredOverpayments.length;

  useEffect(() => {
    setFilteredOverpayments(overpayments);
  }, [overpayments]);

  useEffect(() => {
    setFilteredOverpayments(overpayments.filter((payment) => payment.walletType === overdueType || overdueType === ''));
  }, [overdueType, notificationList, overpayments]);

  const handleChangePage = (event, newValue) => {
    setPage(newValue - 1);
  };

  const handleRowsChange = (e) => {
    setRows(e.target.value);
    setPage(0);
  };

  const getSortValueType = (value) => {
    switch (value) {
      case 'amount':
        return definedSortTypes.NUMBER_SORT;
      case 'date':
        return definedSortTypes.DATE_SORT;
      default:
        return definedSortTypes.STRING_SORT;
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy.property === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy({ property, type: getSortValueType(property) });
    setPage(0);
  };

  const handleTypeChange = (type) => {
    setOverdueType(type);
  };

  const handleNotificationSend = () => {
    if (!selectedDate) {
      setShowDatePicker(true);
    } else {
      dispatch(processRefunds(notificationList.map((item) => ({ ...item, date: selectedDate }))));
      setNotificationList([]);
      setSelectedDate(null);
      setShowDatePicker(false);
      setOpenDialog(false);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Box>
      <ConfirmationDialog
        cancelLabel="Anuluj"
        confirmLabel="Zapisz"
        onConfirm={handleNotificationSend}
        onCancel={() => setOpenDialog(false)}
        isOpen={openDialog}
        confirmationTitle="Uwaga, czy na pewno chcesz zwrócić nadpłaty zaznaczonym dzieciom?">
        {showDatePicker && (
          <>
            <Typography sx={{ mb: 2 }}>Wybierz datę zwrotu nadpłat dla zaznaczonych dzieci</Typography>
            <DatePicker
              value={selectedDate}
              name="Data zwrotu nadpłat"
              onChange={(e, date) => handleDateChange(date)}
              minDate={new Date()}
            />
          </>
        )}
      </ConfirmationDialog>
      <PageHeaderText title="Nadpłaty" titleIcon={<CurrencyExchangeIcon />} />

      <Box sx={{ mt: 2, mb: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        <Box>
          <FormControl variant="standard">
            <ClearButtonWrapper onClear={() => handleTypeChange('')}>
              <>
                <InputLabel
                  id="overdue-type"
                  sx={{
                    color: (theme) => theme.palette.color.primary,
                    '&.Mui-focused': {
                      color: (theme) => theme.palette.color.primary
                    }
                  }}>
                  Typ opłaty
                </InputLabel>
                <Select
                  sx={{ width: 200 }}
                  disableUnderline
                  labelId="overdue-type"
                  id="overdue-type"
                  value={overdueType}
                  label="Age"
                  onChange={(e) => handleTypeChange(e.target.value)}>
                  {[
                    { value: 'general', name: 'Portfel bazowy' },
                    { value: 'stay', name: 'Pobyt' },
                    { value: 'catering', name: 'Wyżywienie' },
                    { value: 'staffCatering', name: 'Wyżywienie pracowników' },
                    { value: 'activities', name: 'Zajęcia dodatkowe' },
                    { value: 'meals', name: 'Posiłki' },
                    { value: 'other', name: 'Inne' }
                  ].map((type) => (
                    <MenuItem value={type.value} key={type.value}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            </ClearButtonWrapper>
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <FormControl variant="standard">
            <InputLabel
              htmlFor="rows-per-page"
              sx={{
                color: (theme) => theme.palette.color.primary,
                '&.Mui-focused': {
                  color: (theme) => theme.palette.color.primary
                }
              }}>
              Wierszy na stronę:
            </InputLabel>
            <Select
              disableUnderline
              variant="standard"
              sx={{ minWidth: 104 }}
              inputProps={{
                id: 'rows-per-page'
              }}
              value={rows}
              onChange={handleRowsChange}>
              {numberArrayOfOptions.map((item) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {notificationList.length !== 0 && (
        <Box sx={{ display: 'flex', my: 2, justifyContent: 'end' }}>
          <Button variant="contained" onClick={() => setOpenDialog(true)}>
            Zwróć nadpłaty
            <Chip label={notificationList.length} sx={{ ml: 2, color: (theme) => theme.palette.color.primary }} />
          </Button>
        </Box>
      )}
      <TableContainer component={Paper} sx={{ p: 3, mb: 5 }}>
        {filteredOverpayments.length ? (
          <>
            <Table aria-label="Nadpłaty">
              <OverpaymentsListHeader
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                order={order}
                setNotificationList={setNotificationList}
                filteredIds={filteredOverpayments.map((payment) => ({
                  personId: payment.personId,
                  walletType: payment.walletType
                }))}
                checked={allChecked}
              />
              <OverpaymentsListBody
                overpayments={filteredOverpayments}
                page={page}
                rowsPerPage={rows}
                order={order}
                setNotificationList={setNotificationList}
                notificationList={notificationList}
                orderBy={orderBy}
              />
            </Table>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Pagination
                siblingCount={0}
                count={_.ceil(filteredOverpayments.length / rows)}
                page={page + 1}
                onChange={handleChangePage}
              />
            </Box>
          </>
        ) : (
          <EmptyState contrast message="Brak nadpłat" noIcon={true} />
        )}
      </TableContainer>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  overpayments: state.staffDashboard.overpayments
});

export default connect(mapStateToProps)(OverpaymentsList);
