import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';
import api from '../api/ChildrenApi';

export function loadChildDetailsSummary(childId, params) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_CHILD_DETAILS_SUMMARY_START });
    return api
      .getChildDetailsSummary(childId, params)
      .then((childDetails) => {
        dispatch({ type: types.LOAD_CHILD_DETAILS_SUMMARY_SUCCESS });
        dispatch({ type: types.LOAD_CHILD_DETAILS_SUMMARY, childDetails });
      })
      .catch((error) => {
        dispatch({ type: types.LOAD_CHILD_DETAILS_SUMMARY_FAILURE });
        dispatch(notificationActions.showError('Nie udało się pobrać ewidencji płatności'));
        return logger.error(error);
      });
  };
}
