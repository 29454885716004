import React, { useCallback, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, FormControl, InputLabel, Typography, useMediaQuery } from '@mui/material';
import Header from '../components/Header/Header';
import * as SortTypes from '../../../constants/sortTypes';
import TilesView from '../components/TilesView/TilesView';
import TableView from '../components/TableView/TableView';
import EmptyState from '../../common/EmptyState';
import * as definedSortTypes from '../../../constants/definedSortTypes';
import { useDropzone } from 'react-dropzone';
import UploadBackdrop from '../../common/UploadBackdrop/UploadBackdrop';
import VirtualDiscToolkitContainer from './VirtualDiscToolkitContainer';
import { uploadFiles } from '../../../actions/uploadFilesActions';
import { useDispatch, useSelector } from 'react-redux';
import { createFolder, deleteFile } from '../../../actions/virtualDiscActions';
import { virtualDiscSort } from '../utils/virtualDiscSort';
import { dateFilter, extensionsFilter, textFilter, userOwnerFilter, userRoleFilter } from '../utils/virtualDiscFilter';
import ShareFileDialog from './Dialogs/ShareFileDialog';
import userRoles from '../../../constants/userRoles';
import * as notificationsActions from '../../../actions/notificationActions';
import moment from 'moment';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';
import CreateOrEditFolderDialog from '../components/Dialogs/CreateOrEditFolderDialog';
import FolderBreadcrumbs from '../components/Breadcrumbs/FolderBreadcrumbs';
import MoveToFolder from '../components/Dialogs/MoveToFolder';

const VirtualDiscContainer = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const virtualDiscFolder = useSelector((state) => state.virtualDiscFolder);
  const allFiles = useSelector((state) => state.virtualDisc);

  const filesFromFolder = virtualDiscFolder.disc;
  const userRole = useSelector((state) => state.auth.userRole);

  const [selectedView, setSelectedView] = useState(0);
  const [rowsPerPageVisible, setRowsPerPageVisible] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [lastSelectedFile, setLastSelectedFile] = useState(null);
  const [filesExtensions, setFilesExtensions] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [sortType, setSortType] = useState(SortTypes.NEWEST);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState({ property: 'uploadedAt', type: definedSortTypes.DATE_SORT });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchText, setSearchText] = useState('');
  const [preparedFiles, setPreparedFiles] = useState([]);
  const [areDisabledActions, setAreDisabledActions] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [selectedShareFile, setSelectedShareFile] = useState(null);
  const [isSingleShare, setIsSingleShare] = useState(false);
  const [selectedUserRoles, setSelectedUserRoles] = useState([]);
  const [isOwnerFile, setIsOwnerFile] = useState(false);
  const [openFolderDialog, setOpenFolderDialog] = useState(false);
  const [isMoveToFolderOpen, setIsMoveToFolderOpen] = useState(false);

  const isManagement = userRole !== userRoles.legalGuardian && userRole !== userRoles.staffMemberCanteenWorker;
  const hasSuperPermission = userRole === userRoles.staffMemberPrincipal;
  const dispatch = useDispatch();
  const { clipboard } = navigator;

  const virtualDiscFilter = (
    currentIsOwnerFile,
    currentFiles,
    currentSelectedUserRoles,
    currentText,
    currentFilesExtensions,
    currentDateFrom,
    currentDateTo
  ) => {
    let filterArray = currentFiles;
    filterArray = userOwnerFilter(currentIsOwnerFile, filterArray);
    filterArray = userRoleFilter(currentSelectedUserRoles, filterArray);
    filterArray = textFilter(currentText, filterArray);
    filterArray = extensionsFilter(currentFilesExtensions, filterArray);
    filterArray = dateFilter(currentDateFrom, currentDateTo, filterArray);
    setPage(0);
    return filterArray;
  };

  useEffect(() => {
    setPreparedFiles(
      virtualDiscFilter(isOwnerFile, filesFromFolder, selectedUserRoles, searchText, filesExtensions, dateFrom, dateTo)
    );
  }, [filesFromFolder]);

  const handleCopyLink = async (fileUrl, name, mimeType) => {
    try {
      await clipboard.writeText(`${fileUrl}?name=${encodeURIComponent(name)}&mimeType=${encodeURIComponent(mimeType)}`);
      dispatch(notificationsActions.showSuccess('Link do pliku został skopiowany'));
    } catch (error) {
      dispatch(notificationsActions.showError('Nie udało się skopiować linku do pliku'));
    }
  };

  const handleSingleDownload = (fileUrl, name, mimeType) => {
    const path = `${fileUrl}?name=${encodeURIComponent(name)}&mimeType=${encodeURIComponent(mimeType)}`;
    window.open(path, '_blank');
  };

  const handleSingleShare = async (id) => {
    setSelectedShareFile(filesFromFolder.find((item) => item.id === id));
    setIsShareDialogOpen(true);
    setIsSingleShare(true);
  };

  const handleDeleteSingleFile = async (id) => {
    setAreDisabledActions(true);
    await dispatch(deleteFile(id));
    setAreDisabledActions(false);
    setSelectedFiles([]);
  };

  const handleSort = (value) => {
    if (value === SortTypes.A_Z) {
      setOrder('asc');
      setOrderBy({ property: 'name', type: definedSortTypes.STRING_SORT });
    }
    if (value === SortTypes.Z_A) {
      setOrder('desc');
      setOrderBy({ property: 'name', type: definedSortTypes.STRING_SORT });
    }
    if (value === SortTypes.NEWEST) {
      setOrder('asc');
      setOrderBy({ property: 'uploadedAt', type: definedSortTypes.DATE_SORT });
    }
    if (value === SortTypes.LATEST) {
      setOrder('desc');
      setOrderBy({ property: 'uploadedAt', type: definedSortTypes.DATE_SORT });
    }
    setSortType(value);
  };

  const handleHeaderSelect = (type) => {
    if (type === 'none') setSelectedFiles([]);
    if (type === 'all') setSelectedFiles(preparedFiles.filter((item) => item.type !== 'folder'));
  };

  const handleTableSelectFile = (file) => {
    if (!selectedFiles.map((item) => item.id).includes(file.id)) {
      setSelectedFiles((prev) => [...prev, file]);
    } else {
      setSelectedFiles((prev) => prev.filter((item) => file.id !== item.id));
    }
    setLastSelectedFile(file.id);
  };

  const handleSelectFile = (e, item) => {
    const sortedFiles = virtualDiscSort(sortType, preparedFiles);
    if (!selectedFiles.length) {
      setSelectedFiles([item]);
      setLastSelectedFile(item.id);
      return null;
    }
    if (e.ctrlKey) {
      if (!selectedFiles.map((file) => file.id).includes(item.id)) {
        setSelectedFiles((prev) => [...prev, item]);
      } else {
        setSelectedFiles((prev) => prev.filter((file) => file.id !== item.id));
      }
    } else if (e.shiftKey) {
      const index = sortedFiles.findIndex((files) => files.id === item.id);
      const selectedLastIndex = sortedFiles.findIndex((item) => lastSelectedFile === item.id);
      const tempA = index < selectedLastIndex ? index : selectedLastIndex;
      const tempB = index < selectedLastIndex ? selectedLastIndex : index;
      setSelectedFiles(sortedFiles.slice(tempA, tempB + 1));
    } else if (selectedFiles.filter((file) => file.id === item.id).length && selectedFiles.length === 1) {
      setSelectedFiles([]);
      setLastSelectedFile(item.id);
    } else {
      setSelectedFiles([item]);
      setLastSelectedFile(item.id);
    }
    return null;
  };

  const handleDataFrom = (value) => {
    const startOfDateFrom = value && moment(value).startOf('day');
    setDateFrom(startOfDateFrom);
    const files = searchText ? allFiles : filesFromFolder;
    setPreparedFiles(
      virtualDiscFilter(isOwnerFile, files, selectedUserRoles, searchText, filesExtensions, startOfDateFrom, dateTo)
    );
  };

  const handleDataTo = (value) => {
    const endOfDateTo = value && moment(value).endOf('day');
    const files = searchText ? allFiles : filesFromFolder;
    setDateTo(endOfDateTo);
    setPreparedFiles(
      virtualDiscFilter(isOwnerFile, files, selectedUserRoles, searchText, filesExtensions, dateFrom, endOfDateTo)
    );
  };

  const handleOwnerFileChange = () => {
    setIsOwnerFile((prev) => !prev);
    const files = searchText ? allFiles : filesFromFolder;
    setPreparedFiles(
      virtualDiscFilter(!isOwnerFile, files, selectedUserRoles, searchText, filesExtensions, dateFrom, dateTo)
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property, type) => {
    const isAsc = orderBy.property === property && order === 'asc';
    if (property === 'uploadedAt') setSortType(isAsc ? SortTypes.LATEST : SortTypes.NEWEST);
    if (property === 'name') setSortType(isAsc ? SortTypes.Z_A : SortTypes.A_Z);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy({ property, type });
    setPage(0);
  };

  const handleSearch = (text) => {
    setSearchText(text);
    const files = text.trim() ? allFiles : filesFromFolder;
    setPreparedFiles(virtualDiscFilter(isOwnerFile, files, selectedUserRoles, text, filesExtensions, dateFrom, dateTo));
  };

  const handleClearSearch = () => {
    setSearchText('');
    setPreparedFiles(
      virtualDiscFilter(isOwnerFile, filesFromFolder, selectedUserRoles, '', filesExtensions, dateFrom, dateTo)
    );
  };

  const handleFileExtensionsChange = (value) => {
    setFilesExtensions(value);
    const files = searchText ? allFiles : filesFromFolder;
    setPreparedFiles(virtualDiscFilter(isOwnerFile, files, selectedUserRoles, searchText, value, dateFrom, dateTo));
  };

  const handleUploadFiles = useCallback(
    async (acceptedFiles) => {
      const forbiddenArray = [];
      const forbiddenFileExtensions = ['cmd', 'html', 'htm', 'js', 'py', 'bat', 'exe', 'deb', 'php'];
      const fileMaxSize = 1000 * 1000 * 1000 * 2;
      const uploadArray = acceptedFiles.filter((item) => {
        if (forbiddenFileExtensions.includes(/[^.]+$/.exec(item.name)[0])) {
          forbiddenArray.push(item);
          return false;
        } else if (item.size >= fileMaxSize) {
          forbiddenArray.push(item);
          return false;
        }
        return true;
      });
      await dispatch(uploadFiles(uploadArray, forbiddenArray, virtualDiscFolder.folderId));
    },
    [dispatch, virtualDiscFolder.folderId]
  );

  const handleSelectedUserRolesChange = (values) => {
    setSelectedUserRoles(values);
    const files = searchText ? allFiles : filesFromFolder;
    setPreparedFiles(virtualDiscFilter(isOwnerFile, files, values, searchText, filesExtensions, dateFrom, dateTo));
  };

  const handleResetFilters = () => {
    setFilesExtensions([]);
    setDateFrom(null);
    setDateTo(null);
    setSelectedUserRoles([]);
    setIsOwnerFile(false);
    setPreparedFiles(virtualDiscFilter(false, filesFromFolder, [], searchText, [], null, null));
  };

  const allItemsSelected =
    preparedFiles.length > 0 &&
    selectedFiles.length > 0 &&
    selectedFiles.length === preparedFiles.filter((item) => item.type !== 'folder').length;
  const itemSelected = (rowId) => selectedFiles.some((item) => item.id === rowId);

  const canUpload = userRole !== userRoles.legalGuardian ? handleUploadFiles : false;

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: canUpload,
    noClick: true,
    noKeyboard: true
  });
  const { ref, ...rootProps } = getRootProps();

  const handleCloseFolderDialog = () => {
    setOpenFolderDialog(false);
  };
  const handleSubmitCreateFolder = async (values) => {
    await dispatch(createFolder({ ...values, parentId: virtualDiscFolder.folderId }));
    handleCloseFolderDialog();
  };
  const handleOpenFolderDialog = () => {
    setOpenFolderDialog(true);
  };
  const handleOpenMoveToFolderDialog = (items) => {
    setSelectedFiles(items);
    setIsMoveToFolderOpen(true);
  };
  const handleCloseMoveToFolderDialog = () => {
    setIsMoveToFolderOpen(false);
    setSelectedFiles([]);
  };

  return (
    <>
      <PageHeaderText title="Dysk wirtualny" titleIcon={<SaveOutlinedIcon sx={{ mr: 1 }} />} />
      {openFolderDialog && (
        <CreateOrEditFolderDialog
          onSubmit={handleSubmitCreateFolder}
          open={openFolderDialog}
          onClose={handleCloseFolderDialog}
        />
      )}
      {isMoveToFolderOpen ? (
        <ContentWrapper>
          <MoveToFolder
            selectedFiles={selectedFiles}
            cancelSelectedFiles={() => {}}
            onClose={handleCloseMoveToFolderDialog}
          />
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          <VirtualDiscToolkitContainer
            addFile={() => {
              open();
            }}
            rowsPerPage={rowsPerPage}
            handleOpenMoveToFolderDialog={handleOpenMoveToFolderDialog}
            setRowsPerPage={handleChangeRowsPerPage}
            searchText={searchText}
            onSearch={handleSearch}
            onClearSearch={handleClearSearch}
            selectedFiles={selectedFiles}
            isMobile={isMobile}
            onSelectedView={(event, newValue) => setSelectedView(newValue)}
            selectedView={selectedView}
            cancelSelectedFiles={() => setSelectedFiles([])}
            files={filesFromFolder}
            onShareFiles={() => {
              setIsShareDialogOpen(true);
              setIsSingleShare(false);
            }}
            isManagement={isManagement}
            hasSuperPermission={hasSuperPermission}
            rowsPerPageVisible={rowsPerPageVisible}
          />
          <>
            <Paper
              sx={{
                p: isMobile ? 1 : 2,
                pt: isMobile ? 1 : 0,
                position: 'relative'
              }}
              {...rootProps}>
              <FormControl>
                <InputLabel
                  htmlFor="fileUpload"
                  sx={{
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    top: 20,
                    width: 1
                  }}>
                  Upload plików
                </InputLabel>
                <input {...getInputProps()} id="fileUpload" />
              </FormControl>
              <UploadBackdrop
                isOpen={userRole !== userRoles.legalGuardian ? isDragActive : false}
                isMobile={isMobile}
                text={
                  <>
                    Upuść pliki, <br /> aby dodać do dysku
                  </>
                }
              />
              <Header
                addFile={() => {
                  open();
                }}
                handleOpenFolderDialog={handleOpenFolderDialog}
                setDateFrom={handleDataFrom}
                setDateTo={handleDataTo}
                dateFrom={dateFrom}
                dateTo={dateTo}
                sortType={sortType}
                handleSort={handleSort}
                isTable={selectedView === 1}
                filesExtensions={filesExtensions}
                onFileExtensionsChange={handleFileExtensionsChange}
                onResetFilters={handleResetFilters}
                selectedUserRoles={selectedUserRoles}
                onSelectedUserRolesChange={handleSelectedUserRolesChange}
                isOwnerFile={isOwnerFile}
                onOwnerFileChange={handleOwnerFileChange}
                isManagement={isManagement}
                onSelectedView={(event, newValue) => {
                  setSelectedView(newValue);
                  setRowsPerPageVisible(!rowsPerPageVisible);
                }}
                selectedView={selectedView}
                rowsPerPageVisible={rowsPerPageVisible}
              />
              {searchText ? (
                <Typography sx={{ mx: 1, color: (theme) => theme.palette.text.secondary }}>
                  Przeszukiwanie całego dysku
                </Typography>
              ) : (
                <FolderBreadcrumbs actionAfterFetch={() => setSelectedFiles([])} />
              )}

              {preparedFiles && preparedFiles.length > 0 ? (
                <Box>
                  {selectedView === 0 ? (
                    <TilesView
                      itemList={virtualDiscSort(sortType, preparedFiles)}
                      onSelectFile={handleSelectFile}
                      cancelSelectedFiles={() => setSelectedFiles([])}
                      selectedFiles={selectedFiles}
                      isMobile={isMobile}
                      handleClearSearch={handleClearSearch}
                      onSingleDownload={handleSingleDownload}
                      onCopyLink={handleCopyLink}
                      handleOpenMoveToFolderDialog={handleOpenMoveToFolderDialog}
                    />
                  ) : (
                    <TableView
                      user={userRole}
                      orderBy={orderBy}
                      order={order}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      handleClearSearch={handleClearSearch}
                      onCopyLink={handleCopyLink}
                      cancelSelectedFiles={() => setSelectedFiles([])}
                      onDelete={handleDeleteSingleFile}
                      onRequestSort={handleRequestSort}
                      onChangePage={handleChangePage}
                      onSelect={handleTableSelectFile}
                      onHeaderSelect={handleHeaderSelect}
                      onDownload={handleSingleDownload}
                      onShare={handleSingleShare}
                      handleOpenMoveToFolderDialog={handleOpenMoveToFolderDialog}
                      allItemsSelected={allItemsSelected}
                      itemSelected={itemSelected}
                      preparedFiles={preparedFiles}
                      areActionsDisabled={selectedFiles.length > 1 || areDisabledActions}
                      isManagement={isManagement}
                      hasSuperPermission={hasSuperPermission}
                    />
                  )}
                </Box>
              ) : undefined}
              {filesFromFolder.length === 0 ? <EmptyState contrast message="Brak plików" /> : undefined}
              {preparedFiles.length === 0 && filesFromFolder.length !== 0 ? (
                <EmptyState contrast message="Brak wyników wyszukiwania dla zastosowanych kryteriów" />
              ) : undefined}
            </Paper>
          </>

          <ShareFileDialog
            isDialogOpen={isShareDialogOpen}
            onClose={() => {
              setIsShareDialogOpen(false);
            }}
            onSubmit={() => {
              setIsShareDialogOpen(false);
            }}
            selectedFiles={isSingleShare ? [selectedShareFile] : selectedFiles}
            isSingleShare={isSingleShare || selectedFiles.length === 1}
            isMobile={isMobile}
            hasSuperPermission={hasSuperPermission}
          />
        </ContentWrapper>
      )}
    </>
  );
};

VirtualDiscContainer.propTypes = {};

export default VirtualDiscContainer;
