import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ToggleButton, Typography } from '@mui/material';
import { SHARE_DETAILS_OPTIONS, SHARE_OPTIONS } from '../../containers/ShareFileFormContainer';
// import { ToggleButton } from '@mui/material';
import ListIcon from '../../../../assets/icons/ListIcon';
import DoneListIcon from '../../../../assets/icons/DoneListIcon';
import { StyledToggleButtonGroup } from './ShareFileMobileToolkit';
import { useShareFileContext } from '../../context/ShareFileContext';

const screenName = (option) => {
  switch (option) {
    case SHARE_OPTIONS.roleShare:
      return 'Udostępnianie dla roli';
    case SHARE_OPTIONS.userShare:
      return 'Udostępnianie pojedynczym osobom';
    case SHARE_OPTIONS.filesScreen:
      return 'Wybrane pliki';
    default:
      return null;
  }
};

const ShareFileMobileManage = ({
  selectedDetailsSharingOption,
  setSelectedDetailsSharingOption,
  selectedSharingOption
}) => {
  const { isDisabled } = useShareFileContext();

  return (
    <Grid container sx={{ p: 1, justifyContent: 'space-between', alignItems: 'center', flexWrap: 'nowrap' }}>
      <Typography>{screenName(selectedSharingOption)}</Typography>
      {selectedSharingOption !== SHARE_OPTIONS.filesScreen && (
        <StyledToggleButtonGroup
          size="small"
          value={selectedDetailsSharingOption}
          exclusive
          onChange={(event, newValue) => {
            if (newValue !== null && newValue !== selectedDetailsSharingOption)
              setSelectedDetailsSharingOption(newValue);
          }}
          aria-label="text alignment">
          <ToggleButton value={SHARE_DETAILS_OPTIONS.availableScreen} disabled={isDisabled} aria-label="centered">
            <ListIcon
              sx={{
                color:
                  selectedDetailsSharingOption === SHARE_DETAILS_OPTIONS.availableScreen
                    ? (theme) => theme.palette.color.color25
                    : undefined
              }}
            />
          </ToggleButton>
          <ToggleButton value={SHARE_DETAILS_OPTIONS.selectedScreen} disabled={isDisabled} aria-label="justified">
            <DoneListIcon
              sx={{
                color:
                  selectedDetailsSharingOption === SHARE_DETAILS_OPTIONS.selectedScreen
                    ? (theme) => theme.palette.color.color25
                    : undefined
              }}
            />
          </ToggleButton>
        </StyledToggleButtonGroup>
      )}
    </Grid>
  );
};

ShareFileMobileManage.propTypes = {
  selectedDetailsSharingOption: PropTypes.string,
  selectedSharingOption: PropTypes.number,
  setSelectedDetailsSharingOption: PropTypes.func
};

export default ShareFileMobileManage;
