import * as SortTypes from '../../../constants/sortTypes';

export const virtualDiscSort = (value, array) => {
  const sortArray = [...array];
  switch (value) {
    case SortTypes.LATEST:
      return sortArray.sort((a, b) => new Date(a.uploadedAt) - new Date(b.uploadedAt));
    case SortTypes.NEWEST:
      return sortArray.sort((a, b) => new Date(b.uploadedAt) - new Date(a.uploadedAt));
    case SortTypes.A_Z:
      return sortArray.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), 'pl'));
    case SortTypes.Z_A:
      return sortArray.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase(), 'pl'));
    default:
      return null;
  }
};
