import PropTypes from 'prop-types';
import React from 'react';
import EmojiIncrementer from '../../common/EmojiIncrementer';
import EmojiCheckboxList from '../../common/EmojiCheckboxList';
import { Mood } from '../../../constants/moodTypes';
import { Meal } from '../../../constants/mealTypes';
import { tryConvertMinutesToString } from '../../../utils/TimeSpanConverter';
import _ from 'lodash';
import TextField from '../../common/TextField';
import SelectField from '../../common/SelectField';
import { Box, Grid, MenuItem } from '@mui/material';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { MealsEatenPercentages } from '../../common/MealsEatenPercentages';

const ReportDaycareForm = ({ entry, onChange, propertiesDefinition, errors }) => {
  return (
    <Box sx={{ mx: 2 }}>
      <FieldsetWrapper legend="Aktywność dziecka">
        {propertiesDefinition.mood && (
          <EmojiCheckboxList labelText="Nastrój" emoji={Mood} name="mood" values={entry.mood} onChange={onChange} />
        )}
        {propertiesDefinition.meals && (
          <>
            <EmojiCheckboxList
              labelText="Posiłki"
              emoji={Meal}
              name="meals"
              mealsScale={entry.mealsScale}
              values={entry.meals}
              onChange={onChange}
              secondaryname="mealsScale"
            />
            <MealsEatenPercentages
              onSelection={onChange}
              formname="mealsScale"
              mealsScale={entry.mealsScale}
              disabledMeals={entry.meals}
            />
          </>
        )}
        {propertiesDefinition.mealsNote && (
          <TextField
            floatingLabelText="Posiłki (opis)"
            hintText="Wprowadź notatkę"
            floatingLabelFixed={true}
            name="mealsNote"
            errorText={errors.mealsNote}
            value={entry.mealsNote || ''}
            onChange={onChange}
            fullWidth={true}
          />
        )}
        <Grid container sx={{ my: 2 }} spacing={0}>
          <Grid item xs={12} md={4}>
            {propertiesDefinition.nappies && (
              <EmojiIncrementer
                labelText="Pieluszki"
                emoji="🚽"
                name="nappies"
                value={entry.nappies}
                onChange={onChange}
              />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            {propertiesDefinition.poos && (
              <EmojiIncrementer labelText="Kupki" emoji="💩" name="poos" value={entry.poos} onChange={onChange} />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            {propertiesDefinition.sleepTimeInMinutes && (
              <SelectField
                contrast
                sx={{ mt: 4 }}
                floatingLabelText="Czas trwania drzemki"
                floatingLabelFixed={true}
                id="base"
                value={entry.sleepTimeInMinutes}
                fullWidth
                onChange={(e) => onChange({ target: { name: 'sleepTimeInMinutes', value: e.target.value } })}>
                <MenuItem key={0} value={0}>
                  Brak
                </MenuItem>
                {_.range(10, 190, 10).map((minutes) => (
                  <MenuItem key={minutes} value={minutes}>
                    {tryConvertMinutesToString(minutes)}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </Grid>
        </Grid>
        {propertiesDefinition.note && (
          <TextField
            floatingLabelText="Dodatkowe informacje"
            hintText="Wprowadź notatkę"
            floatingLabelFixed={true}
            name="note"
            errorText={errors.note}
            value={entry.note || ''}
            onChange={onChange}
            fullWidth={true}
          />
        )}
      </FieldsetWrapper>
    </Box>
  );
};

ReportDaycareForm.propTypes = {
  entry: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  propertiesDefinition: PropTypes.object.isRequired
};

export default ReportDaycareForm;
