import React from 'react';
import PropTypes from 'prop-types';
import Sort from '../Sort/Sort';
import Filter from '../Filter/Filter';
import { Alert, Box, Grid, Tab, Tabs, Tooltip, Typography, useTheme } from '@mui/material';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import { useToolkitStyles } from '../Toolkit/ToolkitStyles';
import IconButtonPipe from '../../../common/IconButtonPipe';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import RichTooltip from '../../../richTooltip/richTooltip';
import { useStateFromProp } from '../../../../utils/useStateFromProps';

function CloneProps(props) {
  const { children, ...other } = props;
  return children(other);
}

const Header = ({
  sortType,
  isTable,
  handleSort,
  isMobile,
  onSelectedView,
  handleOpenFolderDialog,
  selectedView,
  addFile,
  isManagement,
  rowsPerPageVisible,
  onSelectedUserRolesChange,
  ...rest
}) => {
  const cl = useToolkitStyles({ isMobile });
  const localStorageVirtualDiscUploadTooltip = JSON.parse(localStorage.getItem('virtualDiscUploadTooltip'));
  const [isTooltipOpen, setIsTooltipOpen] = useStateFromProp(!localStorageVirtualDiscUploadTooltip);
  const theme = useTheme();

  const handleTooltip = () => {
    localStorage.setItem('virtualDiscUploadTooltip', JSON.stringify(true));
    setIsTooltipOpen(false);
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tabs
          sx={{ p: 1 }}
          value={selectedView}
          onChange={onSelectedView}
          className={cl.tabs}
          aria-label="Przełącz pomiędzy tabelą a kafelkami"
          classes={{
            scroller: cl.scroller
          }}
          TabIndicatorProps={{
            sx: {
              display: 'none'
            }
          }}>
          <CloneProps>
            {(tabProps) => (
              <Tooltip title="Widok kafelek">
                <Tab
                  sx={{
                    '&.Mui-selected': {
                      background: theme.palette.background.hover
                    }
                  }}
                  {...tabProps}
                  className={cl.tab}
                  icon={<GridViewIcon sx={{ color: theme.palette.color.contrast }} />}
                  classes={{ selected: cl.selectedTab }}
                  aria-label="Kafelki"
                />
              </Tooltip>
            )}
          </CloneProps>
          <CloneProps>
            {(tabProps) => (
              <Tooltip title="Widok tabeli">
                <Tab
                  sx={{
                    '&.Mui-selected': {
                      background: theme.palette.background.hover
                    }
                  }}
                  {...tabProps}
                  className={cl.tab}
                  icon={<TableChartOutlinedIcon sx={{ color: theme.palette.color.contrast }} />}
                  classes={{ selected: cl.selectedTab }}
                  aria-label="Tabela"
                />
              </Tooltip>
            )}
          </CloneProps>
        </Tabs>
        {isManagement && (
          <>
            <RichTooltip
              content={
                <Alert severity="info" onClose={handleTooltip}>
                  <Typography sx={{ width: '180px' }}>
                    Możesz dodawać pliki przeciągając je na ekran dysku wirtualnego!
                  </Typography>
                </Alert>
              }
              open={isTooltipOpen}
              placement="bottom"
              arrow={false}>
              <Box>
                <IconButtonPipe onClick={addFile} tooltip="Dodaj pliki do dysku">
                  <UploadFileOutlinedIcon />
                </IconButtonPipe>
              </Box>
            </RichTooltip>
            <IconButtonPipe onClick={handleOpenFolderDialog} tooltip="Utwórz folder" aria-label="Utwórz folder">
              <CreateNewFolderIcon />
            </IconButtonPipe>
          </>
        )}
      </Box>
      <Box sx={{ display: 'flex' }}>
        {!isTable && <Sort sortType={sortType} handleSort={handleSort} />}
        <Filter isManagement={isManagement} {...rest} onSelectedUserRolesChange={onSelectedUserRolesChange} />
      </Box>
    </Grid>
  );
};

Header.propTypes = {
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
  filesExtensions: PropTypes.array.isRequired,
  handleSort: PropTypes.func.isRequired,
  isTable: PropTypes.bool,
  onFileExtensionsChange: PropTypes.func.isRequired,
  setDateFrom: PropTypes.func.isRequired,
  setDateTo: PropTypes.func.isRequired,
  sortType: PropTypes.string.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  selectedUserRoles: PropTypes.array,
  onSelectedUserRolesChange: PropTypes.func.isRequired,
  isOwnerFile: PropTypes.bool.isRequired,
  onOwnerFileChange: PropTypes.func.isRequired,
  isManagement: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  selectedView: PropTypes.number.isRequired,
  onSelectedView: PropTypes.func.isRequired,
  addFile: PropTypes.func.isRequired,
  rowsPerPageVisible: PropTypes.bool.isRequired
};

export default Header;
