import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
// import SingleMemberChip from '../../gallery/Components/Albums/SingleMemberChip';

const PostRecipients = ({ limitedToGroups, limitedToActivities, sx }) => {
  const renderAllRecipients = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mt: 2, ...sx }}>
        <Typography sx={{ m: 1, color: (theme) => theme.palette.grey[600] }}>Dotyczy:</Typography>
        <Typography sx={{ m: 1, color: (theme) => theme.palette.color.color6 }}> Wszystkie grupy</Typography>
      </Box>
    );
  };

  const renderLimitedRecipients = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', mt: 2, ...sx }}>
        <Typography sx={{ m: 1, color: (theme) => theme.palette.grey[600] }}>Dotyczy:</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
          }}>
          {limitedToGroups.map((item, index) => (
            <Typography key={index} sx={{ m: 1, color: (theme) => theme.palette.color.color6 }}>
              {item.name}
            </Typography>
          ))}

          {limitedToActivities.map((item, index) => (
            <Typography key={index} sx={{ m: 1, color: (theme) => theme.palette.color.color6 }}>
              {item.name}
            </Typography>
          ))}
        </Box>
      </Box>
    );
  };

  return !limitedToGroups.length && !limitedToActivities.length ? renderAllRecipients() : renderLimitedRecipients();
};

PostRecipients.propTypes = {
  limitedToGroups: PropTypes.array,
  limitedToActivities: PropTypes.array,
  sx: PropTypes.object
};

export default PostRecipients;
