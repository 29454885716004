import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import ApplicationActionsButtons from '../common/applications/ApplicationActionsButtons';
import ApplicationStatusChip from '../common/applications/ApplicationStatusChip';

const GuardianApplicationItem = (props) => {
  const { application, archive, edit, descriptionComponent } = props;

  return (
    <ListItem>
      <ListItemText
        disableTypography
        primary={
          <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, mr: 2 }}>
              {application.data.name}
            </Typography>
            <ApplicationStatusChip sx={{ ml: 2, mb: '4px' }} application={application} />
          </Box>
        }
        secondaryTypographyProps={{ component: 'div' }}
        secondary={descriptionComponent}
      />
      <ListItemSecondaryAction>
        <ApplicationActionsButtons application={application} archive={archive} edit={edit} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

GuardianApplicationItem.propTypes = {
  archive: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
  edit: PropTypes.func,
  descriptionComponent: PropTypes.object
};

export default GuardianApplicationItem;
