import activitiesApi from '../api/ActivitiesApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../routePaths';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';

export function loadActivitiesAsync() {
  return (dispatch, getState) => {
    if (getState().activities.length === 0) {
      dispatch({ type: types.LOAD_ACTIVITIES });
      return activitiesApi
        .getActivities()
        .then((activities) => dispatch({ type: types.LOAD_ACTIVITIES_SUCCESS, activities }))
        .catch((error) => {
          dispatch(notificationActions.showError('Nie udało się pobrać listy zajęć dodatkowych'));
          logger.error(error);
        });
    }
    return Promise.resolve();
  };
}

export function loadActivitiesArchiveAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_ACTIVITIES_ARCHIVE });
    return activitiesApi
      .getActivitiesArchive()
      .then((activities) => dispatch({ type: types.LOAD_ACTIVITIES_ARCHIVE_SUCCESS, activities }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać listy zarchiwizowanych zajęć dodatkowych'));
        return logger.error(error);
      });
  };
}

export function loadActivityDetailsAsync(id) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_ACTIVITY_DETAILS });
    return activitiesApi
      .getActivity(id)
      .then((activityDetails) => dispatch({ type: types.LOAD_ACTIVITY_DETAILS_SUCCESS, activityDetails }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać szczegółów zajęć dodatkowych '${get.activityName(getState, id)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function loadActivityArchiveDetailsAsync(id) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_ACTIVITY_ARCHIVE_DETAILS });
    return activitiesApi
      .getActivityArchive(id)
      .then((activityDetails) => dispatch({ type: types.LOAD_ACTIVITY_ARCHIVE_DETAILS_SUCCESS, activityDetails }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać szczegółów zarchiwizowanych zajęć dodatkowych '${get.activityArchiveName(
              getState,
              id
            )}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function createActivityDetailsStart() {
  return { type: types.CREATE_ACTIVITY_DETAILS };
}

export function createActivityDetailsCancel() {
  return { type: types.CREATE_ACTIVITY_DETAILS_CANCEL };
}

export function createActivityDetailsAsync(nextActivityDetails) {
  const activityDetails = Object.assign({}, nextActivityDetails);
  return (dispatch) => {
    dispatch({ type: types.CREATE_ACTIVITY_DETAILS_SUBMIT });
    return activitiesApi
      .createActivity(activityDetails)
      .then((id) => {
        dispatch(notificationActions.showSuccess(`Utworzono zajęcia dodatkowe '${activityDetails.name}'`));
        dispatch({
          type: types.CREATE_ACTIVITY_DETAILS_SUCCESS,
          activityDetails: Object.assign(activityDetails, { id })
        });
        browserHistory.push(fromTemplate(routePaths.activityDetails, [id]));
      })
      .catch((error) => {
        if (error.status === 412) {
          return dispatch({ type: types.CREATE_ACTIVITY_DETAILS_FAILURE, errors: error.response.body });
        }
        dispatch(notificationActions.showError(`Nie udało się utworzyć zajęć dodatkowych '${activityDetails.name}'`));
        return logger.error(error);
      });
  };
}

export function updateActivityDetailsStart() {
  return { type: types.UPDATE_ACTIVITY_DETAILS };
}

export function updateActivityDetailsCancel() {
  return { type: types.UPDATE_ACTIVITY_DETAILS_CANCEL };
}

export function updateActivityDetailsAsync(nextActivityDetails) {
  const activityDetails = Object.assign({}, nextActivityDetails);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_ACTIVITY_DETAILS_SUBMIT });
    return activitiesApi
      .updateActivity(activityDetails.id, activityDetails)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zaktualizowano zajęcia dodatkowe '${activityDetails.name}'`));
        dispatch({ type: types.UPDATE_ACTIVITY_DETAILS_SUCCESS, activityDetails });
      })
      .catch((error) => {
        if (error.status === 412) {
          return dispatch({ type: types.UPDATE_ACTIVITY_DETAILS_FAILURE, errors: error.response.body });
        }
        dispatch(
          notificationActions.showError(`Nie udało się zaktualizować zajęć dodatkowych '${activityDetails.name}'`)
        );
        return logger.error(error);
      });
  };
}

export function archiveActivityDetailsAsync(activityId) {
  return (dispatch, getState) => {
    dispatch({ type: types.ARCHIVE_ACTIVITY_DETAILS });
    return activitiesApi
      .archiveActivity(activityId)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Zarchiwizowano zajęcia dodatkowe '${get.activityName(getState, activityId)}'`
          )
        );
        dispatch({ type: types.ARCHIVE_ACTIVITY_DETAILS_SUCCESS, activityId });
        browserHistory.push(routePaths.activities);
      })
      .catch((error) => {
        const errorText =
          error.response && error.response.body && error.response.body.generalError
            ? error.response.body.generalError
            : '';
        dispatch(
          notificationActions.showError(
            `Nie udało się zarchiwizować zajęć dodatkowych '${get.activityName(getState, activityId)}'. ${errorText}`
          )
        );
        return logger.error(error);
      });
  };
}

export function updateEnrolledChildrenStart() {
  return { type: types.ENROLL_CHILDREN_TO_ACTIVITY };
}

export function updateEnrolledChildrenCancel() {
  return { type: types.ENROLL_CHILDREN_TO_ACTIVITY_CANCEL };
}

export function updateEnrolledChildrenAsync(activityId, nextChildrenIds) {
  const childrenIds = [...nextChildrenIds];
  return (dispatch, getState) => {
    dispatch({ type: types.ENROLL_CHILDREN_TO_ACTIVITY_SUBMIT });
    return activitiesApi
      .patchActivityChildren(activityId, childrenIds)
      .then(() => {
        const allChildren = getState().children;
        const children = childrenIds.map((id) => allChildren.find((child) => child.id === id));
        dispatch(notificationActions.showSuccess('Zaktualizowano listę przypisanych dzieci'));
        dispatch({ type: types.ENROLL_CHILDREN_TO_ACTIVITY_SUCCESS, children, activityId });
      })
      .catch((error) => {
        if (error.status === 412) {
          return dispatch({ type: types.ENROLL_CHILDREN_TO_ACTIVITY_FAILURE, errors: error.response.body });
        }
        dispatch(notificationActions.showError('Nie udało się zaktualizować listy przypisanych dzieci'));
        return logger.error(error);
      });
  };
}

export function removeChildAsync(activityId, childId) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_CHILD_FROM_ACTIVITY });
    return activitiesApi
      .removeChild(activityId, childId)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Odłączono dziecko '${get.childName(getState, childId)}' od zajęć dodatkowych '${get.activityName(
              getState,
              activityId
            )}'`
          )
        );
        dispatch({ type: types.REMOVE_CHILD_FROM_ACTIVITY_SUCCESS, childId, activityId });
      })
      .catch((error) => {
        const errorText =
          error.response && error.response.body && error.response.body.generalError
            ? error.response.body.generalError
            : '';
        dispatch(
          notificationActions.showError(
            `Nie udało się odłączyć dziecka '${get.childName(
              getState,
              childId
            )}' od zajęć dodatkowych '${get.activityName(getState, activityId)}'. ${errorText}`
          )
        );
        return logger.error(error);
      });
  };
}
