'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import { MenuItem } from '@mui/material';
import SelectField from '../../common/SelectField';

const FilterSelectField = ({ label, onChange, items, selectedItem, style, id, sx }) => {
  return (
    <SelectField
      floatingLabelText={label}
      value={selectedItem || 'all'}
      id={id}
      onChange={(e) => onChange(e.target.value === 'all' ? null : e.target.value)}
      sx={{ width: 200, ...style, ...sx }}
      selectStyle={{ width: 200, ...style, ...sx }}>
      <MenuItem key={null} value="all">
        Wszystkie
      </MenuItem>
      {items.map((item) => {
        return (
          <MenuItem key={item.value} value={item.value}>
            {item.name}
          </MenuItem>
        );
      })}
    </SelectField>
  );
};

FilterSelectField.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  selectedItem: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.any,
  sx: PropTypes.object
};

export default FilterSelectField;
