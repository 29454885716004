import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import moment from 'moment';
import PersonAvatar from '../../forms/PersonAvatar';
import ActionConfirmationButton from '../../forms/buttons/ActionConfirmationButton';

import { routePaths, fromTemplate } from '../../../routePaths';
import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import userRoles from '../../../constants/userRoles';

const ActivityChildrenList = ({ pupils, onRemoveChild }) => {
  const user = useSelector((state) => state.auth);
  return (
    <List>
      {pupils.map((info) => {
        return (
          <ListItem key={info.pupil.id}>
            <ListItemAvatar>
              <PersonAvatar
                initials
                alt={info.pupil.firstName}
                url={info.pupil.photoUrl}
                firstName={info.pupil.firstName}
                lastName={info.pupil.lastName}
              />
            </ListItemAvatar>
            <ListItemText
              disableTypography
              onClick={() => {
                if (user.userRole !== userRoles.staffMemberTeacher)
                  browserHistory.push(fromTemplate(routePaths.childDetails, info.pupil.id));
              }}
              primary={
                <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                  {info.pupil.lastName + ' ' + info.pupil.firstName}
                </Typography>
              }
              secondary={
                <Box sx={{ display: 'flex' }}>
                  {info.enrollmentInfo ? (
                    <Typography sx={{ mr: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      Uczestniczy od {moment(info.enrollmentInfo.enrolledAt).format('YYYY-MM-DD')}
                    </Typography>
                  ) : null}
                  |
                  <Typography sx={{ ml: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    Data urodzenia {moment(info.pupil.birthDate).format('YYYY-MM-DD')}
                  </Typography>
                </Box>
              }
            />
            <ActionConfirmationButton
              actionLabel="odłącz"
              confirmationText="Czy na pewno chcesz odłączyć dziecko od zajęć dodatkowych?"
              confirmationTitle="Odłącz dziecko"
              confirmLabel="odłącz"
              variant="clearText"
              onConfirm={() => onRemoveChild(info.pupil.id)}
              onCancel={() => {}}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

ActivityChildrenList.propTypes = {
  pupils: PropTypes.array.isRequired,
  onRemoveChild: PropTypes.func.isRequired
};

export default ActivityChildrenList;
