import { FilterByRoleType } from '../../../components/common/filters/filterByRole/FilterByRole';
import userRoles from '../../../constants/userRoles';
import * as GlobalTypes from '../../../constants/groupsAndActivitiesTypes';

export const shareAvailableListFilter = (keyword, filterBy, selectedGroup, array) => {
  let filterArray = array;

  if (keyword.length) {
    filterArray = filterArray.map((item) => ({
      ...item,
      users: item.users.filter(
        (user) =>
          `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.includes(keyword.toLowerCase()) ||
          `${user.lastName.toLowerCase()} ${user.firstName.toLowerCase()}`.includes(keyword.toLowerCase())
      )
    }));
  }

  if (filterBy !== FilterByRoleType.ALL) {
    filterArray = filterArray.filter((item) => {
      switch (filterBy) {
        case FilterByRoleType.LEGAL_GUARDIAN:
          return item.type === userRoles.legalGuardian || item.type === GlobalTypes.GROUP;
        case FilterByRoleType.TEACHER:
          return item.type === userRoles.staffMemberTeacher;
        case FilterByRoleType.OTHERS:
          return (
            item.type === userRoles.staffMemberAccountant ||
            item.type === userRoles.staffMemberCanteenWorker ||
            item.type === userRoles.staffMemberDigitalDiary ||
            item.type === userRoles.staffMemberPrincipal ||
            item.type === userRoles.staffMemberSecretary ||
            item.type === userRoles.staffMemberSpecialist
          );
        default:
          return false;
      }
    });
  }

  if (!!selectedGroup && filterBy === FilterByRoleType.TEACHER) {
    filterArray = filterArray.map((item) => ({
      ...item,
      users: item.users.filter((user) => item.groups && user.groups.some((group) => group.id === selectedGroup))
    }));
  }

  if (!!selectedGroup && filterBy === FilterByRoleType.LEGAL_GUARDIAN) {
    filterArray = filterArray.filter((item) => item.groupId === selectedGroup);
  }
  return filterArray;
};
