import PropTypes from 'prop-types';
import React from 'react';
import { Box, Input, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { InputFieldStyles } from '../../constants/ComponentStyles/inputFieldStyles';
import { Search } from '@mui/icons-material';

const useStyle = makeStyles(InputFieldStyles);

const FilterBarV2 = ({ contrast, itemsToFilter, filter, onFilter, onKeywordChange, value, ...rest }) => {
  const cl = useStyle();

  const runFiltering = (items, newKeyword) => {
    if (onFilter) onFilter(newKeyword !== null ? items.filter((item) => filter(item, newKeyword)) : items);
    if (onKeywordChange) onKeywordChange(newKeyword);
  };

  const handleInputChange = (event) => {
    const newWord = event.target.value.toLowerCase();
    runFiltering(itemsToFilter, newWord);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: 1,
        px: 0
      }}>
      <Input
        startAdornment={
          <InputAdornment position="start">
            <Search
              sx={{ color: (theme) => (contrast ? theme.palette.color.contrast : theme.palette.color.primary) }}
            />
          </InputAdornment>
        }
        value={value}
        placeholder="Wyszukaj na liście"
        inputProps={{ 'aria-label': 'Wyszukaj na liście – lista zostanie automatycznie zaktualizowana.' }}
        aria-label="Wyszukaj na liście – lista zostanie automatycznie zaktualizowana."
        onChange={handleInputChange}
        sx={{ ml: 1, color: (theme) => (contrast ? theme.palette.color.contrast : theme.palette.color.primary) }}
        classes={{
          underline: cl.underline
        }}
        fullWidth={true}
        {...rest}
      />
    </Box>
  );
};

FilterBarV2.propTypes = {
  itemsToFilter: PropTypes.array.isRequired,
  filter: PropTypes.func,
  onFilter: PropTypes.func,
  onKeywordChange: PropTypes.func,
  initialKeyword: PropTypes.string,
  containerWidth: PropTypes.number,
  classes: PropTypes.any,
  style: PropTypes.object,
  contrast: PropTypes.bool
};

export default FilterBarV2;
