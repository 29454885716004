import PropTypes from 'prop-types';
import React from 'react';
import { Avatar } from '@mui/material';

const GroupAvatar = ({ group, ...other }) => {
  return (
    <Avatar
      sx={{
        background: (theme) => theme.palette.color.wcag || group.color,
        color: (theme) => theme.palette.color.wcag || group.color
      }}
      {...other}>
      {' '}
    </Avatar>
  );
};

GroupAvatar.propTypes = {
  group: PropTypes.object.isRequired
};

export default GroupAvatar;
