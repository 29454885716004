import PropTypes from 'prop-types';
import React from 'react';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import Checkbox from '../../common/Checkbox';
import { Box } from '@mui/material';
import { ReportTypes } from '../../../constants/reportTypes';
import DateAndGroupPickerTemplate from './common/DateAndGroupPickerTemplate';

const ListOfTransfersReport = ({ groupId, startDate, endDate, groups, splitPayments, onChange, errors }) => {
  return (
    <Box>
      <FieldsetWrapper legend="Pola dla generowania raportu zestawienia przelewów">
        <DateAndGroupPickerTemplate
          legend={`Pola dla generowania raportu ${ReportTypes.listOfTransfersReport.name}`}
          groupId={groupId}
          startDate={startDate}
          endDate={endDate}
          groups={groups}
          onChange={onChange}
          errors={errors}
        />
        <Checkbox
          label="Pokaż kwoty poszczególnych rozliczeń"
          defaultChecked={splitPayments}
          onCheck={(event, isChecked) => onChange({ target: { name: 'splitPayments', value: isChecked } })}
        />
      </FieldsetWrapper>
    </Box>
  );
};

ListOfTransfersReport.propTypes = {
  groupId: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  splitPayments: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ListOfTransfersReport;
