import ApiBase, { ROOT_URL } from './ApiBase';

class SpecialistNotesApi extends ApiBase {
  static loadAllSpecialists() {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/staffMembers/specialistTeachers`).type('application/json')
    );
  }

  static loadSpecialistDetails(specialistId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/specialistWorkRecord/${specialistId}`).type('application/json')
    );
  }

  static loadSpecialistClassSchedulePlan(date, helpId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/digitalDiary/specialistClassSchedule/${date}/${helpId}`).type('application/json')
    );
  }

  static loadSpecialistWorkPlanProgress(date, helpId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/digitalDiary/specialistWorkPlanProgress/${date}/${helpId}`).type('application/json')
    );
  }

  static loadSpecialistGroupActivities(date, helpId) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/digitalDiary/specialistGroupActivities/${date}/${helpId}`).type('application/json')
    );
  }

  static createSpecialistWorkPlan(data) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/specialistWorkRecord`).send(data).type('application/json')
    );
  }

  static deleteSpecialistWorkPlan(specialistWorkRecordId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/specialistWorkRecord/${specialistWorkRecordId}`).type('application/json')
    );
  }

  static updateSpecialistWorkPlan(data, specialistWorkRecordId) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/specialistWorkRecord/${specialistWorkRecordId}`)
        .send({ ...data })
        .type('application/json')
    );
  }

  static loadAllSpecialistWorkNotes() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/specialistWorkRecord`).type('application/json'));
  }

  static addNewEvent(data, specialistWorkRecordId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/specialistWorkRecord/${specialistWorkRecordId}/records`)
        .send(data)
        .type('application/json')
    );
  }

  static editEvent(data, specialistWorkRecordId) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/specialistWorkRecord/${specialistWorkRecordId}/records/${data.id} `)
        .send(data)
        .type('application/json')
    );
  }

  static deleteEvent(recordId, workId) {
    return super.toPromise((agent) =>
      agent
        .delete(`${ROOT_URL}/specialistWorkRecord/${workId}/records/${recordId}?recordType=workPlan`)
        .send({ recordType: 'workPlan' })
        .type('application/json')
    );
  }

  //kontakty

  static addContact(data, workId) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/specialistWorkRecord/${workId}/contacts`).send(data).type('application/json')
    );
  }

  static editContact(data, workId) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/specialistWorkRecord/${workId}/contacts/${data.id}`).send(data).type('application/json')
    );
  }

  static deleteContact(contactId, workId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/specialistWorkRecord/${workId}/contacts/${contactId}`).type('application/json')
    );
  }

  static createSpecialistWorkRecordElement(element, specialistWorkRecordId) {
    return super.toPromise((agent) =>
      agent
        .post(`${ROOT_URL}/specialistWorkRecord/${specialistWorkRecordId}/records`)
        .send(element)
        .type('application/json')
    );
  }

  static editSpecialistWorkRecordElement(element, specialistWorkRecordId) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/specialistWorkRecord/${specialistWorkRecordId}/records/${element.id}`)
        .send(element)
        .type('application/json')
    );
  }

  static deleteSpecialistWorkRecordElement(id, recordType, specialistWorkRecordId) {
    return super.toPromise((agent) =>
      agent
        .delete(`${ROOT_URL}/specialistWorkRecord/${specialistWorkRecordId}/records/${id}`)
        .send({ recordType })
        .type('application/json')
    );
  }
}

export default SpecialistNotesApi;
