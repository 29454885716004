import { useState } from 'react';
import { Box } from '@mui/system';
import { Skeleton } from '@mui/lab';

const GifMessage = ({ src }) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Box sx={{ height: 150, minWidth: 120, maxWidth: 200 }}>
      {isLoading && <Skeleton variant="rectangular" sx={{ height: '100%', width: '100%' }} />}
      <img
        alt="GIF"
        src={src}
        style={isLoading ? { display: 'none' } : { height: '100%', width: '100%' }}
        onLoad={() => setIsLoading(false)}
      />
    </Box>
  );
};

export default GifMessage;
