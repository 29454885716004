import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as psychologicalGroupActions from '../../actions/psychologicalChoosenGroupActions';
import { Box } from '@mui/material';
import GroupSwitch from '../common/groups/GroupSwitch';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import moment from 'moment';
import { SearchBar } from '../common/SearchBar';
import IndividualAttendanceSheetList, {
  actionTypes
} from '../individualPsychologicalClasses/IndividualAtendanceSheetList';
import { browserHistory } from 'react-router';
import { fromTemplate, routePaths } from '../../routePaths';
import {
  loadIndividualJournalClassesAttendanceData,
  reportIndividualJournalAbsence,
  reportIndividualJournalAttendance
} from '../../actions/psychologicalIndividualJournalAttendanceActions';

const IndividualClassesJournalPage = ({ location, params }) => {
  const dispatch = useDispatch();

  const pupils = useSelector((state) => state.children);
  const children = useSelector((state) => state.psychologicalChildrenList);
  const psychologicalChoosenGroup = useSelector((state) => state.psychologicalChoosenGroup);

  const attendances = useSelector((state) => state.psychologicalIndividualAttendanceJournal?.childrenAttendance);

  const [searchValue, setSearchValue] = useState('');
  const [attendantChildren, setAttendantChildren] = useState([]);
  const [absenceChildren, setAbsenceChildren] = useState([]);
  const [unregisteredChildren, setUnregisteredChildren] = useState([]);

  useEffect(() => {
    if (psychologicalChoosenGroup?.id) {
      dispatch(loadIndividualJournalClassesAttendanceData(psychologicalChoosenGroup.id));
    }
  }, [dispatch, psychologicalChoosenGroup.id]);

  useEffect(() => {
    const setVariable = async () => {
      if (children.length === 0 || Object.keys(attendances).length === 0) {
        setUnregisteredChildren([]);
        setAbsenceChildren([]);
        setAttendantChildren([]);
        return;
      }

      const unregisteredChildren = [];
      const attendantChildren = [];
      const absenceChildren = [];

      attendances.forEach((childAttendance) => {
        const child = pupils.find((pupil) => pupil.id === childAttendance.child.id);

        if (!child) return;

        if (!childAttendance.droppedOffAt && !childAttendance.absenceFrom) {
          unregisteredChildren.push(child);
        } else if (childAttendance.droppedOffAt) {
          attendantChildren.push(child);
        } else if (childAttendance.absenceFrom) {
          absenceChildren.push(child);
        }
      });

      setUnregisteredChildren(unregisteredChildren);
      setAttendantChildren(attendantChildren);
      setAbsenceChildren(absenceChildren);
    };

    setVariable();
  }, [children, attendances, pupils]);

  const handleGroupChange = (value) => {
    dispatch(psychologicalGroupActions.changePsychologicalGroup(value));
    dispatch(psychologicalGroupActions.loadPsychologicalGroupWorkPlan(value));
    dispatch(loadIndividualJournalClassesAttendanceData(value?.id));
  };

  const handleReportAttendance = (child) => {
    dispatch(reportIndividualJournalAttendance(child, psychologicalChoosenGroup?.id));
  };
  const handleReportAbsence = (child) => {
    dispatch(reportIndividualJournalAbsence(child, psychologicalChoosenGroup?.id));
  };

  const handleCustomRedirect = (entry) => {
    const { individualGroupDiaryId } = attendances.find((attendance) => attendance.child.id === entry.id);
    browserHistory.push(
      fromTemplate(routePaths.individualClassesJournalDetails, [
        entry.id,
        psychologicalChoosenGroup.id,
        individualGroupDiaryId,
        moment().year(),
        moment().month() + 1
      ])
    );
  };

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText
          title="Indywidualne dzienniki zajęć specjalistycznych"
          titleIcon={<SwitchAccountIcon />}
          additionalInfo={moment().format('DD.MM.YYYY')}
        />

        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <GroupSwitch onGroupChange={handleGroupChange} showArchivedGroups={false} isPsychological />
          </Box>
          <SearchBar label="Wyszukaj" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
        </Box>

        <IndividualAttendanceSheetList
          searchText={searchValue}
          entries={unregisteredChildren}
          onReportAttendance={handleReportAttendance}
          onReportAbsence={handleReportAbsence}
          onCustomRedirect={handleCustomRedirect}
        />
        <IndividualAttendanceSheetList
          searchText={searchValue}
          title="Obecne"
          entries={attendantChildren}
          onReportAttendance={handleReportAttendance}
          onReportAbsence={handleReportAbsence}
          onCustomRedirect={handleCustomRedirect}
          actionTypes={actionTypes}
          actionType={actionTypes.attendant}
        />
        <IndividualAttendanceSheetList
          searchText={searchValue}
          title="Nieobecne"
          entries={absenceChildren}
          onReportAttendance={handleReportAttendance}
          onReportAbsence={handleReportAbsence}
          onCustomRedirect={handleCustomRedirect}
          actionTypes={actionTypes}
          actionType={actionTypes.absence}
        />
      </Box>
    </LoadingRenderWrapper>
  );
};

export default IndividualClassesJournalPage;
