/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function duesFkExportsReducer(state = initialState.duesFkExports, action) {
  switch (action.type) {
    case types.LOAD_DUES_FK_EXPORTS_HISTORY_SUCCESS: {
      return action.history;
    }
    default:
      return state;
  }
}
