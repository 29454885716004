/*eslint global-require: "off"*/

'use strict';

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { InputFieldStyles } from '../../constants/ComponentStyles/inputFieldStyles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker as Picker } from '@mui/x-date-pickers';
import { useMediaQuery, useTheme, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { pl } from 'date-fns/locale';

const useStyle = makeStyles(InputFieldStyles);

const DatePicker = ({
  floatingLabelText,
  onChange,
  value,
  name,
  format = 'dd/MM/yyyy',
  style,
  id,
  errorText,
  disabled = false,
  defaultValue,
  errorContent,
  displayError,
  maxDate,
  minDate,
  unlimitedMaxDate,
  sx,
  contrast
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyle();

  const getMaxDate = useMemo(() => {
    if (unlimitedMaxDate) return null;
    if (maxDate) return new Date(maxDate);
    return moment().add(3, 'M').toDate();
  }, [unlimitedMaxDate, maxDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
      <Picker
        sx={{
          minWidth: 110,
          maxWidth: 500,
          mr: isMobile ? 0 : 1,
          m: 0,
          border: 'none !important',

          ...style,
          ...sx
        }}
        disabled={disabled}
        variant="inline"
        format={format}
        cancelText="Anuluj"
        okText="Zatwierdź"
        invalidDateMessage="Nieprawidłowa data. Podaj datę w formacie DD/MM/RRRR"
        maxDateMessage="Data nie powinna przekraczać 2100 roku"
        margin="normal"
        minDate={minDate ? new Date(minDate) : undefined}
        maxDate={getMaxDate}
        id={id || floatingLabelText}
        InputProps={{ classes: { underline: contrast ? classes.underlineContrast : classes.underline } }}
        inputFormat={format}
        label={floatingLabelText}
        value={value}
        inputProps={{
          'aria-label': 'label',
          name: name
        }}
        onChange={(date) => {
          onChange(date, date);
        }}
        renderInput={(params) => (
          <TextField
            helperText={errorText}
            error={!!errorText}
            sx={{
              underline: `1px solid ${
                errorText
                  ? theme.palette.color.error
                  : contrast
                  ? theme.palette.color.contrast
                  : theme.palette.color.primary
              } !important`,
              minWidth: 110,
              maxWidth: 500,

              mr: 1,
              svg: {
                color: errorText
                  ? theme.palette.color.error
                  : contrast
                  ? theme.palette.color.primary
                  : theme.palette.color.contrast
              },
              input: {
                color: errorText
                  ? theme.palette.color.error
                  : contrast
                  ? theme.palette.color.primary
                  : theme.palette.color.contrast
              },
              label: {
                color: errorText
                  ? theme.palette.color.error
                  : contrast
                  ? theme.palette.color.primary
                  : theme.palette.color.contrast,
                '&.Mui-focused': {
                  color: errorText
                    ? theme.palette.color.error
                    : contrast
                    ? theme.palette.color.primary
                    : theme.palette.color.contrast
                }
              },
              color: (theme) => theme.palette.color.error,
              '& .Mui-error': {
                color: (theme) => theme.palette.color.error
              },
              '& .MuiFormHelperText-root': {
                color: (theme) => theme.palette.color.error
              },
              ...sx
            }}
            variant="standard"
            {...params}
          />
        )}
      />
      {displayError === true && (
        <Typography sx={{ pt: 1, fontSize: 12, color: (theme) => theme.palette.background.error, width: 220 }}>
          {errorContent}
        </Typography>
      )}
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  floatingLabelText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  format: PropTypes.string,
  sx: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  id: PropTypes.string,
  errorText: PropTypes.string,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  displayError: PropTypes.bool,
  errorContent: PropTypes.string
};

export default DatePicker;
