import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as attributesActions from '../../../actions/attributesActions';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { Paper, Box, Button } from '@mui/material';
import AttributesSetsList from './AttributesSetsList';
import AttributeSetDialog from './dialogs/AttributeSetDialog';
import { AttributeSetModel } from '../../../models/configuration/attributes/AttributeSetModel';

//v4
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';

class AttributesSetsPage extends React.Component {
  constructor() {
    super();
    this.handleCreate = this.handleCreate.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.renderAttributeSetDialog = this.renderAttributeSetDialog.bind(this);
    this.state = {
      attributeSet: null
    };
  }

  handleCreate() {
    const attributeSet = new AttributeSetModel();
    this.setState({ attributeSet }, () => this.props.actions.addAttributesSetStart());
  }

  handleUpdate(set) {
    this.setState({ attributeSet: set }, () => this.props.actions.updateAttributesSetStart());
  }

  handleCancel() {
    if (this.state.attributeSet.id) this.props.actions.updateAttributesSetCancel();
    else this.props.actions.addAttributesSetCancel();
  }

  handleSave(set) {
    if (set.id) this.props.actions.updateAttributesSetAsync(set);
    else this.props.actions.addAttributesSetAsync(set);
  }

  renderAttributeSetDialog() {
    if (this.props.ui.isEditing) {
      return (
        <AttributeSetDialog
          attributesSet={this.state.attributeSet}
          onCancel={this.handleCancel}
          onSave={this.handleSave}
          isProcessing={this.props.ui.isProcessing}
          isDialogOpen={this.props.ui.isEditing}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText title="Zestawy atrybutów" titleIcon={<CreateNewFolderIcon />} />
          <ContentWrapper>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" aria-label="Dodaj zestaw" onClick={this.handleCreate} sx={{ mb: 2 }}>
                Dodaj zestaw
              </Button>
            </Box>
            <Paper sx={{ p: 2, mt: 2 }}>
              <AttributesSetsList
                sets={this.props.attributesSets}
                onRemove={(set) => this.props.actions.removeAttributesSetAsync(set)}
                onSelected={(set) => this.handleUpdate(set)}
              />
            </Paper>
            {this.renderAttributeSetDialog()}
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

AttributesSetsPage.propTypes = {
  attributesSets: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    attributesSets: state.configuration.attributesSets,
    ui: state.configuration.attributesSetsUi.details
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(attributesActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributesSetsPage);
