import { BaseModelValidator } from '../../../../models/BaseModelValidator';
import { check } from '../../../../models/validation/validateHelper';

export class UnitSchemeValidator extends BaseModelValidator {
  constructor() {
    super();

    this.constraints = {
      templateName: (value) => {
        if (check.isEmpty(value)) {
          return 'Nie podano nazwy schematu';
        }
        if (value.length > 100) return 'Tytuł może mieć maksymalnie 100 znaków';
      },
      content: (value) => check.isEmpty(value) && 'Nie podano treści'
    };
  }
}
