import PropTypes from 'prop-types';
import React from 'react';
import CalendarDayContent from '../../calendar/Grid/CalendarDayContent';
import { Box, Popover } from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DaycareReadonlyDetails from '../../../daycare/DaycareReadonlyDetails';

const DaycareContent = ({ day, containerWidth, daycareEntry }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{ display: 'flex', height: '100%', width: '100%' }}
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}>
      <CalendarDayContent day={day} containerWidth={containerWidth} primaryText={<InfoOutlinedIcon />} />
      <Popover
        id="mouse-over-popover"
        sx={{
          display: 'flex',

          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Box sx={{ p: 2 }}>
          <DaycareReadonlyDetails day={day} containerWidth={containerWidth} daycareEntry={daycareEntry} />
        </Box>
      </Popover>
    </Box>
  );
};

DaycareContent.propTypes = {
  day: PropTypes.object.isRequired,
  containerWidth: PropTypes.number.isRequired,
  daycareEntry: PropTypes.object.isRequired
};

export default DaycareContent;
