import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import { Input, Paper, IconButton, Box, Popper } from '@mui/material';
import SendMessageIcon from '@mui/icons-material/Send';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import GifSelector from './GifSelector';
import ClearButtonWrapper from '../../common/ClearButtonWrapper';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { isGif } from '../../common/messageFunctions';
import SendFileFromDisc from '../components/SendFileFromDisc';
import FilePermissionDialog from '../components/FilePermissionDialog';

const SendMessageCard = ({
  text,
  isEditing,
  onChange,
  onSubmit,
  onCancelEdit,
  onGifSelected,
  onFileSelected,
  isGifSelected,
  maxHeight,
  isFileSelected,
  user,
  thread,
  participants
}) => {
  const textBoxRef = useRef(null);
  const [gif, setGif] = useState();
  const [file, setFile] = useState();
  const [inputText, setInputText] = useState();
  const [currentCursor, setCurrentCursor] = useState(0);
  const [isSendFileFromDiscOpen, setIsSendFileFromDiscOpen] = useState(false);
  const [sendFileAnchor, setSendFileAnchor] = useState(null);
  const [openFilePermission, setOpenFilePermission] = useState(false);

  useEffect(() => {
    textBoxRef.current.setAttribute('aria-label', 'HiddenTextArea');
  }, []);
  useEffect(() => {
    text?.split(' ').forEach((word) => {
      if (isGif(word)) {
        onGifSelected(word);
        setGif(word);
      }
    });
    const newText = text
      ?.split(' ')
      .filter((word) => !isGif(word))
      .join(' ');
    textBoxRef.current.value = newText;
    setInputText(newText);
  }, [text]);

  useEffect(() => {
    text?.split(' ').forEach((word) => {
      if (isGif(word)) {
        onGifSelected(word);
        setGif(word);
      }
    });
    const newText = text
      ?.split(' ')
      .filter((word) => !isGif(word))
      .join(' ');
    textBoxRef.current.value = newText;
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFileClose = () => {
    setIsSendFileFromDiscOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onEmojiClick = (event, emojiObject) => {
    if (text) onChange(text.concat(emojiObject.emoji));
    if (text) onChange(text.slice(0, currentCursor) + emojiObject.emoji + text.slice(currentCursor));
    else onChange(emojiObject.emoji);
    setCurrentCursor((text.slice(0, currentCursor) + emojiObject.emoji).length);
  };
  const handleSubmitEdit = () => {
    if (!!gif || !!inputText) {
      onSubmit(
        inputText
          .split(' ')
          .filter((word) => !isGif(word))
          .join(' '),
        gif
      );
    }
  };
  const handleFileSelect = (file) => {
    setFile(file);
    setIsSendFileFromDiscOpen(false);
    onFileSelected(file);
    setOpenFilePermission(true);
  };
  const checkFilePermission = () => {
    if (file) {
      const rolePermission = file.shareForUserRoles;
      const userPermission = file.shareForUserIds;
      const participantsList = participants ? participants : thread.participants;
      return participantsList.filter(
        (p) =>
          userPermission.includes(p.id) ||
          rolePermission.includes(p.role) ||
          p.role === 'staffMemberPrincipal' ||
          p.role === 'principal'
      );
    }
    return;
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          background: (theme) => theme.palette.components.messenger.inputBackground,
          border: (theme) => `2px solid ${theme.palette.components.messenger.background}`
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'start',
            mx: 'auto',
            justifyContent: 'space-between'
          }}>
          <Box
            sx={{
              width:
                !!user && user.userRole !== 'legalGuardian' && !isEditing
                  ? {
                      xs: 'calc( 100% - 100px )',
                      sm: 'calc( 100% - 200px )'
                    }
                  : 'calc( 100% - 100px )'
            }}>
            <Input
              disableUnderline
              sx={{ px: 2, py: 1.5, width: '100%' }}
              placeholder="Napisz wiadomość..."
              multiline={true}
              fullWidth={true}
              aria-label="wyslij wiadomosc"
              inputProps={{ 'aria-label': 'opis' }}
              inputRef={textBoxRef}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              onBlur={(e) => setCurrentCursor(e.target.selectionStart)}
              onClick={(e) => setCurrentCursor(e.target.selectionStart)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey && !isEditing) {
                  e.preventDefault();
                  if (text?.trim().length || !!gif || !!isGifSelected) onSubmit();
                }
              }}
            />

            {gif && isGifSelected !== '' && (
              <Box sx={{ display: 'flex', p: 1, justifyContent: 'start' }}>
                <ClearButtonWrapper
                  childStyles={{ mb: 0 }}
                  contrast
                  onClear={() => {
                    onGifSelected('');
                    setGif('');
                  }}>
                  <img src={gif || isGifSelected} style={{ marginTop: 8, maxWidth: 70 }} />
                </ClearButtonWrapper>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              flexWrap: 'wrap',
              width:
                !!user && user.userRole !== 'legalGuardian' && !isEditing
                  ? {
                      xs: 150,
                      sm: 200
                    }
                  : isEditing
                  ? 100
                  : 200
            }}>
            <Tooltip title="ikony">
              <IconButton
                aria-label="ikony"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large">
                <InsertEmoticonIcon sx={{ color: (theme) => theme.palette.color.color25 }} />
              </IconButton>
            </Tooltip>
            <GifSelector
              onChange={(href) => {
                onGifSelected(href);
                if (textBoxRef.current) textBoxRef.current.focus();
              }}
              setGif={setGif}
              sx={{ maxHeight: maxHeight }}
            />
            {!!user && user.userRole !== 'legalGuardian' && !isEditing && (
              <Tooltip title="udostępnij z dysku">
                <IconButton
                  aria-label="udostępnij z dysku"
                  size="large"
                  id="simple-popper"
                  onClick={(e) => {
                    setSendFileAnchor(e.currentTarget.parentElement);
                    setIsSendFileFromDiscOpen((prev) => !prev);
                  }}>
                  <InsertDriveFileIcon sx={{ color: (theme) => theme.palette.color.color25 }} />
                </IconButton>
              </Tooltip>
            )}
            <Popper
              id="simple-popper"
              open={isSendFileFromDiscOpen}
              anchorEl={sendFileAnchor}
              onClose={handleFileClose}
              placement="top-end"
              sx={{ zIndex: 99999 }}>
              <SendFileFromDisc
                sx={{ maxHeight: maxHeight }}
                user={user}
                onFileSelect={handleFileSelect}
                onClose={() => setIsSendFileFromDiscOpen(false)}
              />
            </Popper>
            {openFilePermission && isFileSelected && (
              <FilePermissionDialog
                open={openFilePermission}
                participants={participants ? participants : thread.participants}
                file={isFileSelected}
                usersWithPermission={checkFilePermission()}
                user={user}
                onSend={onSubmit}
                onClose={() => {
                  setOpenFilePermission(false);
                  onFileSelected(null);
                }}
              />
            )}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}>
              <Picker
                onEmojiClick={onEmojiClick}
                skinTone={SKIN_TONE_MEDIUM_DARK}
                groupNames={{ smileys_people: 'PEOPLE' }}
                native
                disableSearchBar
              />
            </Popover>
            {!isEditing && (
              <Tooltip title="wyślij">
                <IconButton
                  aria-label="wyślij"
                  onClick={() => (text?.trim().length || !!gif) && onSubmit()}
                  size="large">
                  <SendMessageIcon sx={{ color: (theme) => theme.palette.color.color25 }} />
                </IconButton>
              </Tooltip>
            )}
            {isEditing && (
              <Box flexDirection="row" display="flex">
                <Tooltip title="cofnij">
                  <IconButton aria-label="cofnij" onClick={() => onCancelEdit()} size="large">
                    <CloseIcon style={{ color: (theme) => theme.palette.grey[600] }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="edytuj">
                  <IconButton
                    aria-label="edytuj"
                    onClick={() => (text?.trim().length || !!gif || !!isGifSelected) && handleSubmitEdit()}
                    size="large">
                    <EditIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

SendMessageCard.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  onCancelEdit: PropTypes.func
};

export default SendMessageCard;
