import React from 'react';
import { useSelector } from 'react-redux';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import { Link } from 'react-router';
import { fromTemplate, routePaths } from '../../../routePaths';

const TakenOverByDebtCollectionPage = () => {
  const childrenWithDebtCollection = useSelector((state) => state.childrenTakenOverByDebtCollection);
  return (
    <Box>
      <PageHeaderText title="Dzieci objęte windykacją" titleIcon={<CardTravelIcon />} />
      <TableContainer component={Paper}>
        <Table aria-label="Dzieci objęte windykacją">
          <TableHead>
            <TableRow>
              <TableCell>Nazwisko i imię</TableCell>
              <TableCell>Numer umowy</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {childrenWithDebtCollection.map((child) => (
              <TableRow key={child.id}>
                <TableCell component="th" scope="row">
                  <Link
                    onlyActiveOnIndex={false}
                    to={fromTemplate(routePaths.childDetails, child.id)}
                    style={{ textDecoration: 'none' }}>
                    <Typography
                      sx={{
                        fontWeight: (theme) => theme.typography.fontWeightBold,
                        cursor: 'pointer',
                        color: (theme) => theme.palette.color.contrast,
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}>{`${child.lastName} ${child.firstName}`}</Typography>
                  </Link>
                </TableCell>
                <TableCell>{child.contractNumber || 'Brak'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TakenOverByDebtCollectionPage;
