import BaseModel from '../../BaseModel';
import { BaseModelValidator } from '../../BaseModelValidator';
import { check } from '../../validation/validateHelper';
import moment from 'moment';

export class NoteModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.title = '';
    this.content = '';
    this.date = null;
    this.time = null;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
  }
}

export class NoteValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = NoteModel;

    this.constraints = {
      title: (value) => {
        if (check.isEmpty(value)) return 'Nie podano tytułu';
        return null;
      },
      content: (value) => {
        if (check.isEmpty(value)) return 'Nie podano treści';
        return null;
      },
      date: (value) => {
        if (!!value && (!moment(value).isValid() || moment(value, "DD-MM-YYYY").isBefore('1900', 'year'))) return 'Podana data ma niepoprawny format';
        return null;
      },
      time: (value) => {
        if (!!value && !(moment(value).isValid())) return 'Podany czas ma niepoprawny format';
        return null;
      }
    };
  }
}
