import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import { connect, useDispatch } from 'react-redux';
import OverdueIcon from '@mui/icons-material/PriorityHigh';
import EmptyState from '../../common/EmptyState';
import { Box, Button, Chip, FormControl, InputLabel, MenuItem, Pagination, Select, Table } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import * as definedSortTypes from '../../../constants/definedSortTypes';
import OverdueListHeader from './OverdueListHeader';
import OverdueListBody from './OverdueListBody';
import ContentWrapper from '../../common/contentWrapper';
import _ from 'lodash';
import ClearButtonWrapper from '../../common/ClearButtonWrapper';
import DatePicker from '../../common/DatePicker';
import { sendNotifications } from '../../../actions/settlementsActions';

import ConfirmationDialog from '../../forms/ConfirmationDialog';

const OverdueList = ({ overdue }) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const numberArrayOfOptions = [5, 10, 25, 50];
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState({ property: 'number', type: definedSortTypes.NUMBER_SORT });
  const [filteredOverdues, setFilteredOverdues] = useState(overdue);
  const [overdueType, setOverdueType] = useState('');
  const [filterFrom, setFilterFrom] = useState(null);
  const [filterTo, setFilterTo] = useState(null);

  const [notificationList, setNotificationList] = useState([]);

  const allChecked = useMemo(
    () => notificationList.length === filteredOverdues.length,
    [notificationList, filteredOverdues]
  );

  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();

  const reportTypes = [
    { value: 'stay', name: 'Pobyt' },
    { value: 'catering', name: 'Wyżywienie' },
    { value: 'staffCatering', name: 'Wyżywienie pracowników' },
    { value: 'activities', name: 'Zajęcia dodatkowe' },
    { value: 'other', name: 'Inne' }
  ];

  useEffect(() => {
    setFilteredOverdues(overdue);

    setFilteredOverdues(
      overdue.filter(
        (due) =>
          (due.dueType === overdueType || overdueType === '') &&
          (due.maturityDate >= filterFrom || filterFrom === null) &&
          (due.maturityDate <= filterTo || filterTo === null)
      )
    );
  }, [overdue, overdueType, filterFrom, filterTo, notificationList]);

  const groupedData = useMemo(() => {
    const grouped = filteredOverdues.reduce((acc, element) => {
      const childId = element.person.id;

      if (!acc[childId]) {
        acc[childId] = {
          childId: childId,
          firstName: element.person.firstName,
          lastName: element.person.lastName,
          calculatedTotal: 0,
          overduePayments: [],
          totalPaidAmount: 0
        };
      }
      const calculatedTotal = element.calculatedTotal ? parseFloat(element.calculatedTotal) : 0;
      const paidAmount = element.paidAmount ? parseFloat(element.paidAmount) : 0;
      const netAmount = parseFloat((calculatedTotal - paidAmount).toFixed(2));

      acc[childId].calculatedTotal = parseFloat((acc[childId].calculatedTotal + netAmount).toFixed(2));
      acc[childId].totalPaidAmount += paidAmount;
      acc[childId].overduePayments.push(element);
      return acc;
    }, {});

    return Object.values(grouped);
  }, [filteredOverdues]);

  const handleChangePage = (event, newValue) => {
    setPage(newValue - 1);
  };

  const handleRowsChange = (e) => {
    setRows(e.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property, type) => {
    const isAsc = orderBy.property === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy({ property, type });
    setPage(0);
  };

  const handleTypeChange = (type) => {
    setOverdueType(type);
  };

  const handleFromChange = (value) => {
    setFilterFrom(value);
  };

  const handleToChange = (value) => {
    setFilterTo(value);
  };

  const handleNotificationSend = () => {
    dispatch(sendNotifications(notificationList));
    setNotificationList([]);
    handleCloseDialog();
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box>
      <ConfirmationDialog
        cancelLabel="Anuluj"
        confirmLabel="wyślij"
        onConfirm={() => handleNotificationSend()}
        onCancel={handleCloseDialog}
        isOpen={openDialog}
        confirmationTitle="Czy na pewno chcesz wysłać powiadomienia do rodziców?"
      />
      <PageHeaderText title="Zaległe opłaty" titleIcon={<OverdueIcon />} />

      <ContentWrapper>
        <Box sx={{ mt: 2, mb: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <Box>
            <FormControl variant="standard">
              <ClearButtonWrapper onClear={() => handleTypeChange('')}>
                <>
                  <InputLabel
                    id="overdue-type"
                    sx={{
                      color: (theme) => theme.palette.color.primary,
                      '&.Mui-focused': {
                        color: (theme) => theme.palette.color.primary
                      }
                    }}>
                    Typ opłaty
                  </InputLabel>
                  <Select
                    sx={{ width: 200 }}
                    disableUnderline
                    labelId="overdue-type"
                    id="overdue-type"
                    value={overdueType}
                    label="Age"
                    onChange={(e) => handleTypeChange(e.target.value)}>
                    {reportTypes.map((type) => (
                      <MenuItem value={type.value} key={_.uniqueId()}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              </ClearButtonWrapper>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <ClearButtonWrapper onClear={() => handleFromChange(null)} sx={{ mr: 1, pr: 2 }}>
              <DatePicker
                contrast
                floatingLabelText="Od:"
                sx={{ width: 200 }}
                onChange={(e, date) => setFilterFrom(date?.toISOString().split('T')[0])}
                name="fromDate"
                value={filterFrom}
                id="Filtruj od:"
                maxDate={filterTo ? new Date(filterTo) : null}
              />
            </ClearButtonWrapper>
            <ClearButtonWrapper onClear={() => handleToChange(null)} sx={{ mr: 1 }}>
              <DatePicker
                contrast
                floatingLabelText="Do:"
                sx={{ width: 200 }}
                onChange={(e, date) => handleToChange(date?.toISOString().split('T')[0])}
                value={filterTo}
                name="fromDate"
                id="Filtruj do:"
                minDate={filterFrom ? new Date(filterFrom) : null}
              />
            </ClearButtonWrapper>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <FormControl variant="standard">
              <InputLabel
                htmlFor="rows-per-page"
                sx={{
                  color: (theme) => theme.palette.color.primary,
                  '&.Mui-focused': {
                    color: (theme) => theme.palette.color.primary
                  }
                }}>
                Wierszy na stronę:
              </InputLabel>
              <Select
                disableUnderline
                variant="standard"
                sx={{ minWidth: 104 }}
                inputProps={{
                  id: 'rows-per-page'
                }}
                value={rows}
                onChange={handleRowsChange}>
                {numberArrayOfOptions.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {notificationList.length !== 0 && (
          <Box sx={{ display: 'flex', my: 2, justifyContent: 'end' }}>
            <Button variant="contained" onClick={handleOpenDialog}>
              Wyślij powiadomienia
              <Chip label={notificationList.length} sx={{ ml: 2, color: (theme) => theme.palette.color.primary }} />
            </Button>
          </Box>
        )}
        <TableContainer component={Paper} sx={{ p: 3, mb: 5 }}>
          {filteredOverdues.length ? (
            <>
              <Table aria-label="Zaległe opłaty">
                <OverdueListHeader
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  order={order}
                  setNotificationList={setNotificationList}
                  filteredIds={filteredOverdues.map((due) => due.id)}
                  checked={allChecked}
                />
                <OverdueListBody
                  groupedData={groupedData}
                  page={page}
                  rowsPerPage={rows}
                  order={order}
                  setNotificationList={setNotificationList}
                  notificationList={notificationList}
                  orderBy={orderBy}
                />
              </Table>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Pagination
                  siblingCount={0}
                  count={_.ceil(groupedData.length / rows)}
                  page={page + 1}
                  onChange={handleChangePage}
                />
              </Box>
            </>
          ) : (
            <EmptyState contrast message="Brak zaległych opłat" noIcon={true} />
          )}
        </TableContainer>
      </ContentWrapper>
    </Box>
  );
};

OverdueList.propTypes = {
  overdue: PropTypes.array.isRequired,
  isContrastColor: PropTypes.string
};

function mapStateToProps(state) {
  return {
    isContrastColor: state.contrastColor,
    overdue: state.staffDashboard.overdue
  };
}

export default connect(mapStateToProps)(OverdueList);
