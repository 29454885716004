import React from 'react';
import PropTypes from 'prop-types';
import FileSpecificIcon from '../../../common/FileSpecificIcon/FileSpecificIcon';
import { List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const ShareAndDeleteDialogContent = ({ selectedItems, isMobile, missingPermissionsItemsArray }) => {
  return (
    <List dense={isMobile}>
      {selectedItems.map((item) => (
        <ListItem key={item.id}>
          <ListItemIcon>
            <FileSpecificIcon
              type={item.type === 'folder' ? 'folder' : item.mimeType}
              item={item}
              sx={{ fontSize: isMobile ? 25 : 40 }}
            />
          </ListItemIcon>
          <ListItemText
            primary={item.name}
            primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}
          />
          {missingPermissionsItemsArray.some((x) => x.id === item.id) && (
            <ListItemSecondaryAction sx={{ display: 'flex' }}>
              <Tooltip title="Nie posiadasz wymaganych uprawnień">
                <ErrorIcon color="error" />
              </Tooltip>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
  );
};

ShareAndDeleteDialogContent.propTypes = {
  selectedItems: PropTypes.array,
  isMobile: PropTypes.bool,
  missingPermissionsItemsArray: PropTypes.array.isRequired
};

export default ShareAndDeleteDialogContent;
