export const GDANSK_SUBDOMAINS = [
  'https://z1.4parents.pl',
  'https://z2.4parents.pl',
  'https://z3.4parents.pl',
  'https://z4.4parents.pl',
  'https://z5.4parents.pl',
  'https://z6.4parents.pl',
  'https://z7.4parents.pl',
  'https://z8.4parents.pl',
  'https://z9.4parents.pl',
  'https://z10.4parents.pl',
  'https://z11.4parents.pl',
  'https://z12.4parents.pl',
  'https://z13.4parents.pl',
  'https://test.4parents.pl'
];
