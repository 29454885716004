import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const DeleteAllIcon = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M2 6.5a.5.5 0 0 1 .5-.5h16.154a.5.5 0 0 1 .5.5v1.348a.5.5 0 0 1-.5.5H2.5a.5.5 0 0 1-.5-.5V6.5ZM12.921 17.693a.5.5 0 0 1 0-.707l5.936-5.936a.5.5 0 0 1 .707 0l.477.477.476.477a.5.5 0 0 1 0 .707l-5.935 5.935a.5.5 0 0 1-.707 0l-.954-.953Z" />
    <path d="M13.875 11.05a.5.5 0 0 1 .707 0l5.935 5.936a.5.5 0 0 1 0 .707l-.953.953a.5.5 0 0 1-.707 0l-5.935-5.935a.5.5 0 0 1 0-.707l.953-.954ZM10.948 10.697H2.5a.5.5 0 0 0-.5.5v1.348c0 .277.222.5.498.5h7.037c.471 0 2.826-2.348 1.413-2.348ZM12.066 15.394h-9.57a.497.497 0 0 0-.496.5v1.348c0 .276.222.5.498.5h7.98c.529 0 3.178-2.348 1.588-2.348Z" />
  </SvgIcon>
);

export default DeleteAllIcon;
