import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import IconButtonPipe from '../../../common/IconButtonPipe';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Sorts from '../Multimedia/Sorts';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SearchBar } from '../../../common/SearchBar';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
    padding: 10,
    width: ({ isMobile }) => (isMobile ? '100%' : 'auto')
  },
  button: {
    margin: 4
  },
  icon: {
    fontSize: 32
  }
});

const Header = ({ openDialog, filterAlbums, sortType, handleSort, isEditAvailable }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const cl = useStyles({ isMobile });
  const [searchText, setSearchText] = useState('');
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <SearchBar
          value={searchText}
          label="Wyszukaj"
          SearchOnClick={(e) => {
            setSearchText(e.target.value);
            filterAlbums(e.target.value, true);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
            filterAlbums(e.target.value, true);
          }}
          handleTextSearch={(e) => {
            setSearchText(e.target.value);
            filterAlbums(e.target.value, true);
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Sorts cl={cl} sortType={sortType} handleSort={handleSort} title="Albumy" isAlbums />

        {isEditAvailable && (
          <Button
            onClick={openDialog}
            tooltip="Dodaj album"
            variant="contained"
            sx={{
              ml: isMobile ? 0.5 : 1,
              px: isMobile ? 2 : 5
            }}>
            nowy album
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          component="h3"
          sx={{ color: theme.palette.color.primary, fontWeight: 600, my: 3, mx: 3 }}>
          Albumy
        </Typography>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  openDialog: PropTypes.func.isRequired,
  filterAlbums: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  isEditAvailable: PropTypes.bool.isRequired,
  sortType: PropTypes.string.isRequired
};

export default Header;
