import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';
import api from '../api/SpecialistNotesApi';
import DigitalDiaryApi from '../api/DigitalDiaryApi';

export function loadSpecialistWorkPlanProgress(date, helpId) {
  return (dispatch) => {
    return api
      .loadSpecialistWorkPlanProgress(date, helpId)
      .then((response) => dispatch({ type: types.LOAD_SPECIALIST_WORK_PLAN_PROGRESS, response }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać realizacji planu pracy`));
        return logger.error(error);
      });
  };
}

export function setSpecialistWorkPlanProgressDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.SPECIALIST_WORK_PLAN_PROGRESS_START });
  };
}

export function setSpecialistWorkPlanProgressDialogClosed() {
  return (dispatch) => {
    dispatch({ type: types.SPECIALIST_WORK_PLAN_PROGRESS_CANCEL });
  };
}

export function addSpecialistWorkPlanProgress(data) {
  return (dispatch) => {
    return DigitalDiaryApi.addCustomNewEvent(data, data.eventType)
      .then((response) => {
        dispatch({ type: types.ADD_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT, data: response });
        dispatch(notificationActions.showSuccess('Dodano wpis do realizacji planu pracy'));
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            error?.response?.body?.generalError || 'Wystąpił błąd podczas dodawania wpisu do realizacji planu pracy'
          )
        );
        return logger.error(error);
      });
  };
}

export function editSpecialistWorkPlanProgressElement(data) {
  return (dispatch) => {
    return DigitalDiaryApi.editCustomEvent(data)
      .then(() => {
        dispatch({ type: types.EDIT_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT, data });
        dispatch(notificationActions.showSuccess('Edytowano wpis z realizacji planu pracy'));
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            error?.response?.body?.generalError || 'Wystąpił błąd podczas edycji wpisu z realizacji planu pracy'
          )
        );
        return logger.error(error);
      });
  };
}

export function deleteSpecialistWorkPlanProgressElement(recordId) {
  return (dispatch) => {
    return DigitalDiaryApi.deleteEvent(recordId)
      .then(() => {
        dispatch(notificationActions.showSuccess('Usunięto wpis z realizacji planu pracy'));
        dispatch({ type: types.DELETE_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT, id: recordId });
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć wpisu z realizacji planu pracy`));
        return logger.error(error);
      });
  };
}
