import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const XlsIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M6,2H14L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2M13,3.5V9H18.5L13,3.5M17,11H13V13H14L12,14.67L10,13H11V11H7V13H8L11,15.5L8,18H7V20H11V18H10L12,16.33L14,18H13V20H17V18H16L13,15.5L16,13H17V11Z" />
  </SvgIcon>
);

export default XlsIcon;
