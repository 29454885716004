import groupsApi from '../api/GroupsApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../routePaths';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';

export function loadGroupsAsync() {
  return (dispatch, getState) => {
    if (getState().groups.length === 0) {
      dispatch({ type: types.LOAD_GROUPS });
      return groupsApi
        .getGroups()
        .then((groups) => dispatch({ type: types.LOAD_GROUPS_SUCCESS, groups }))
        .catch((error) => {
          dispatch(notificationActions.showError('Nie udało się pobrać listy grup'));
          return logger.error(error);
        });
    }
    return Promise.resolve();
  };
}

export function loadGroupsArchiveAsync() {
  return (dispatch, getState) => {
    if (getState().archives.groups.length === 0) {
      dispatch({ type: types.LOAD_GROUPS_ARCHIVE });
      return groupsApi
        .getGroupsArchive()
        .then((groups) => dispatch({ type: types.LOAD_GROUPS_ARCHIVE_SUCCESS, groups }))
        .catch((error) => {
          dispatch(notificationActions.showError('Nie udało się pobrać listy zarchiwizowanych grup'));
          return logger.error(error);
        });
    }
    return Promise.resolve();
  };
}

export function loadGroupDetailsAsync(id) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_GROUP_DETAILS });
    return groupsApi
      .getGroup(id)
      .then((groupDetails) => dispatch({ type: types.LOAD_GROUP_DETAILS_SUCCESS, groupDetails }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(`Nie udało się pobrać szczegółów grupy '${get.groupName(getState, id)}'`)
        );
        return logger.error(error);
      });
  };
}

export function loadGroupArchiveDetailsAsync(id) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_GROUP_ARCHIVE_DETAILS });
    return groupsApi
      .getGroupArchive(id)
      .then((groupDetails) => dispatch({ type: types.LOAD_GROUP_ARCHIVE_DETAILS_SUCCESS, groupDetails }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać szczegółów zarchiwizowanej grupy '${get.groupArchiveName(getState, id)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function createGroupDetailsStart() {
  return { type: types.CREATE_GROUP_DETAILS };
}

export function createGroupDetailsCancel() {
  return { type: types.CREATE_GROUP_DETAILS_CANCEL };
}

export function createGroupDetailsAsync(nextGroupDetails) {
  const groupDetails = Object.assign({}, nextGroupDetails);
  return (dispatch) => {
    dispatch({ type: types.CREATE_GROUP_DETAILS_SUBMIT });
    return groupsApi
      .createGroup(groupDetails)
      .then((id) => {
        dispatch(notificationActions.showSuccess(`Utworzono grupę '${groupDetails.name}'`));
        dispatch({ type: types.CREATE_GROUP_DETAILS_SUCCESS, groupDetails: Object.assign(groupDetails, { id }) });
        browserHistory.push(fromTemplate(routePaths.groupDetails, [id]));
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.CREATE_GROUP_DETAILS_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError(`Nie udało się utworzyć grupy '${groupDetails.name}'`));
        return logger.error(error);
      });
  };
}

export function updateGroupDetailsStart() {
  return { type: types.UPDATE_GROUP_DETAILS };
}

export function updateGroupDetailsCancel() {
  return { type: types.UPDATE_GROUP_DETAILS_CANCEL };
}

export function updateGroupDetailsAsync(nextGroupDetails) {
  const groupDetails = Object.assign({}, nextGroupDetails);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_GROUP_DETAILS_SUBMIT });
    return groupsApi
      .updateGroup(groupDetails.id, groupDetails)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zaktualizowano grupę '${groupDetails.name}'`));
        dispatch({ type: types.UPDATE_GROUP_DETAILS_SUCCESS, groupDetails });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.UPDATE_GROUP_DETAILS_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError(`Nie udało się zaktualizować grupy '${groupDetails.name}'`));
        return logger.error(error);
      });
  };
}

export function archiveGroupStart() {
  return { type: types.ARCHIVE_GROUP };
}

export function archiveGroupCancel() {
  return { type: types.ARCHIVE_GROUP_CANCEL };
}

export function archiveGroupDetailsAsync(groupId, archiveChildren, archiveLegalGuardians, archiveBoardPosts) {
  return (dispatch, getState) => {
    dispatch({ type: types.ARCHIVE_GROUP_SUBMIT });
    return groupsApi
      .archiveGroup(groupId, archiveChildren, archiveLegalGuardians, archiveBoardPosts)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zarchiwizowano grupę '${get.groupName(getState, groupId)}'`));
        dispatch({ type: types.ARCHIVE_GROUP_SUCCESS, groupId });
        browserHistory.push(routePaths.groups);
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(`Nie udało się zarchiwizować grupy '${get.groupName(getState, groupId)}'`)
        );
        return logger.error(error);
      });
  };
}

export function updateEnrolledChildrenStart() {
  return { type: types.ENROLL_CHILDREN_TO_GROUP };
}

export function updateEnrolledChildrenCancel() {
  return { type: types.ENROLL_CHILDREN_TO_GROUP_CANCEL };
}

export function updateEnrolledChildrenAsync(groupId, nextChildrenIds) {
  const childrenIds = [...nextChildrenIds];
  return (dispatch, getState) => {
    dispatch({ type: types.ENROLL_CHILDREN_TO_GROUP_SUBMIT });
    return groupsApi
      .patchGroupChildren(groupId, childrenIds)
      .then(() => {
        const allChildren = getState().children;
        const children = childrenIds.map((id) => allChildren.find((child) => child.id === id));
        dispatch(notificationActions.showSuccess('Zaktualizowano listę przypisanych dzieci'));
        dispatch({ type: types.ENROLL_CHILDREN_TO_GROUP_SUCCESS, children, groupId });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.ENROLL_CHILDREN_TO_GROUP_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError('Nie udało się zaktualizować listy przypisanych dzieci'));
        return logger.error(error);
      });
  };
}

export function removeChildAsync(groupId, childId) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_CHILD_FROM_GROUP });
    return groupsApi
      .removeChild(groupId, childId)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Odłączono dziecko '${get.childName(getState, childId)}' od grupy '${get.groupName(getState, groupId)}'`
          )
        );
        dispatch({ type: types.REMOVE_CHILD_FROM_GROUP_SUCCESS, childId });
      })
      .catch((error) => {
        const errorText =
          error.response && error.response.body && error.response.body.generalError
            ? error.response.body.generalError
            : '';
        dispatch(
          notificationActions.showError(
            `Nie udało się odłączyć dziecka '${get.childName(getState, childId)}' od grupy '${get.groupName(
              getState,
              groupId
            )}'.${errorText}`
          )
        );
        return logger.error(error);
      });
  };
}

export function associateTeacherStart() {
  return { type: types.ASSOCIATE_TEACHER_TO_GROUP };
}

export function associateTeacherCancel() {
  return { type: types.ASSOCIATE_TEACHER_TO_GROUP_CANCEL };
}

export function associateTeacherAsync(groupId, teacherId) {
  return (dispatch, getState) => {
    dispatch({ type: types.ASSOCIATE_TEACHER_TO_GROUP_SUBMIT });
    return groupsApi
      .associateTeacher(groupId, teacherId)
      .then(() => {
        const teacher = getState().staffMembers.find((member) => member.id === teacherId);
        dispatch(
          notificationActions.showSuccess(
            `Przypisano nauczyciela '${get.staffName(getState, teacherId)}' do grupy '${get.groupName(
              getState,
              groupId
            )}'`
          )
        );
        dispatch({ type: types.ASSOCIATE_TEACHER_TO_GROUP_SUCCESS, teacher });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.ASSOCIATE_TEACHER_TO_GROUP_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się przypisać nauczyciela '${get.staffName(getState, teacherId)}' do grupy '${get.groupName(
              getState,
              groupId
            )}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function removeTeacherAsync(groupId, teacherId) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_TEACHER_FROM_GROUP });
    return groupsApi
      .removeTeacher(groupId, teacherId)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Odłączono nauczyciela '${get.staffName(getState, teacherId)}' od grupy '${get.groupName(
              getState,
              groupId
            )}'`
          )
        );
        dispatch({ type: types.REMOVE_TEACHER_FROM_GROUP_SUCCESS, teacherId });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się odłączyć nauczyciela '${get.staffName(getState, teacherId)}' od grupy '${get.groupName(
              getState,
              groupId
            )}'`
          )
        );
        return logger.error(error);
      });
  };
}
