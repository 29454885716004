import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grow,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery
} from '@mui/material';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import EmptyState from '../../../common/EmptyState';
import { createFolder, getRootFolderContent, moveToFolder } from '../../../../actions/virtualDiscActions';
import FolderBreadcrumbs from '../Breadcrumbs/FolderBreadcrumbs';
import { LoadingButton } from '@mui/lab';
import FileSpecificIcon from '../../../common/FileSpecificIcon/FileSpecificIcon';
import CreateOrEditFolderDialog from './CreateOrEditFolderDialog';
import Folder from '../Folder/Folder';

export default function MoveToFolder({ onClose, selectedFiles, cancelSelectedFiles }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:768px)');
  const virtualDiscFolder = useSelector((state) => state.virtualDiscFolder);
  const { isProcessing } = useSelector((state) => state.virtualDiscFolderUi.action);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [openFolderDialog, setOpenFolderDialog] = useState(false);

  const preparedFolders = useMemo(() => {
    return virtualDiscFolder.disc.filter(
      (item) => item.type === 'folder' && !selectedFiles.map((item) => item.id).includes(item.id)
    );
  }, [virtualDiscFolder, selectedFiles]);

  const handleSubmitMoveToFolder = async () => {
    const preparedData = {
      items: selectedFiles.map((item) => {
        return {
          id: item.id,
          type: item.type === 'folder' ? 'folder' : 'file'
        };
      }),
      newParentId: selectedFolder
    };
    await dispatch(moveToFolder(preparedData));

    cancelSelectedFiles();
    onClose();
  };
  const handleMoveToCurrentOpenFolder = async () => {
    const preparedData = {
      items: selectedFiles.map((item) => {
        return {
          id: item.id,
          type: item.type === 'folder' ? 'folder' : 'file'
        };
      }),
      newParentId: virtualDiscFolder.path[virtualDiscFolder.path.length - 1].id
    };
    await dispatch(moveToFolder(preparedData));
    await dispatch(getRootFolderContent());

    cancelSelectedFiles();
    onClose();
  };
  const onlyFolder = useMemo(() => {
    return !selectedFiles.some((item) => item.type !== 'folder');
  }, [selectedFiles]);

  const handleCloseFolderDialog = () => {
    setOpenFolderDialog(false);
  };
  const handleSubmitCreateFolder = async (values) => {
    await dispatch(createFolder({ ...values, parentId: virtualDiscFolder.folderId }));
    handleCloseFolderDialog();
  };
  const handleOpenFolderDialog = () => {
    setOpenFolderDialog(true);
  };
  const handleSelect = (id) => {
    if (selectedFolder === id) setSelectedFolder(null);
    else setSelectedFolder(id);
  };

  return (
    <Paper sx={{ p: 2 }}>
      {openFolderDialog && (
        <CreateOrEditFolderDialog
          onSubmit={handleSubmitCreateFolder}
          open={openFolderDialog}
          onClose={handleCloseFolderDialog}
        />
      )}
      <Typography variant="h3" sx={{ fontSize: 24 }}>
        {`Do którego folderu przenieść ${onlyFolder ? 'zaznaczony folder' : 'zaznaczone pliki'}?`}
      </Typography>
      <List sx={{ pb: 0 }}>
        {selectedFiles.map((item) => {
          return (
            <ListItem
              key={item.id}
              sx={{
                background: (theme) => theme.palette.background.color5,
                borderRadius: 50,
                display: 'inline-flex',
                width: 'auto',
                flexWrap: 'nowrap',
                margin: 1,
                zIndex: (theme) => theme.zIndex.fab
              }}>
              <ListItemIcon>
                <FileSpecificIcon type={item.type === 'folder' ? 'folder' : item.mimeType} item={item} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ color: (theme) => theme.palette.text.primary, mr: 1 }}>{item.name}</Typography>
                  </Box>
                }
              />
            </ListItem>
          );
        })}
      </List>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <FolderBreadcrumbs actionAfterFetch={() => setSelectedFolder(null)} />
        <Button startIcon={<CreateNewFolderIcon />} variant="contained" onClick={handleOpenFolderDialog}>
          Utwórz folder
        </Button>
      </Box>
      {preparedFolders.length > 0 ? (
        <>
          <Grow in={true}>
            <List
              sx={{
                display: 'grid',
                gridTemplateColumns: `repeat(auto-fit, minmax(${isMobile ? '125px' : '200px'}, ${
                  isMobile ? '1fr' : '200px'
                }))`,
                gridGap: isMobile ? 10 : 15,
                justifyContent: 'center',
                my: 2
              }}>
              {preparedFolders.map((item) => (
                <Folder
                  isCheckbox
                  onClick={() => handleSelect(item.id)}
                  isSelected={selectedFolder === item.id}
                  onOpenFolder={() => handleSelect(null)}
                  disableDragging
                  hideMenu
                  item={item}
                  itemId={item.id}
                  key={item.id}
                  name={item.name}
                />
              ))}
            </List>
          </Grow>
        </>
      ) : (
        <EmptyState message="Brak folderów" contrast />
      )}
      <Button variant="outlinedContrast" onClick={onClose} disabled={isProcessing}>
        Anuluj
      </Button>
      {preparedFolders.length > 0 && (
        <LoadingButton
          loading={isProcessing}
          onClick={handleSubmitMoveToFolder}
          variant="contained"
          disabled={!selectedFolder}>
          Przenieś do zaznaczonego folderu
        </LoadingButton>
      )}
      <LoadingButton loading={isProcessing} onClick={handleMoveToCurrentOpenFolder} variant="contained">
        Przenieś do aktualnie otwartego folderu
      </LoadingButton>
    </Paper>
  );
}

MoveToFolder.ptopTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  cancelSelectedFiles: PropTypes.func.isRequired,
  selectedFiles: PropTypes.array,
  onlyFolder: PropTypes.bool
};
