import PropTypes from 'prop-types';
import React from 'react';
import PersonAvatar from '../../../forms/PersonAvatar';
import SelectField from '../../SelectField';
import { Box, MenuItem } from '@mui/material';

const renderPupilMenuItem = (pupil) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: 1,
        px: 0
      }}>
      <PersonAvatar
        initials
        alt={pupil.firstName}
        sx={{ mr: 1 }}
        firstName={pupil.firstName}
        lastName={pupil.lastName}
        size={48}
      />
      {`${pupil.firstName} ${pupil.lastName}`}
    </Box>
  );
};

const ChildrenDropDown = ({ pupils, pupilId, onPupilChange }) => {
  return (
    <SelectField
      value={pupilId}
      onChange={(e) => onPupilChange(e.target.value)}
      sx={{ mb: 1 }}
      floatingLabelText="Wybierz dziecko ">
      {pupils.map((pupil) => {
        return (
          <MenuItem key={pupil.id} value={pupil.id}>
            {renderPupilMenuItem(pupil)}
          </MenuItem>
        );
      })}
    </SelectField>
  );
};

ChildrenDropDown.propTypes = {
  pupils: PropTypes.array.isRequired,
  pupilId: PropTypes.string.isRequired,
  onPupilChange: PropTypes.func.isRequired
};

export default ChildrenDropDown;
