import BaseModel from '../../BaseModel';

export class PinDevice extends BaseModel {
  constructor() {
    super();
    this.id = null;
    this.login = '';
    this.password = undefined;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.password = undefined;
  }
}

export class GuardiansPinServiceSettingsModel extends BaseModel {
  constructor() {
    super();
    this.enabled = false;
    this.devices = [];
    this.guardiansWithoutPin = [];
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.devices = assigned.devices.map((d) => new PinDevice().assign(d));
  }
}
