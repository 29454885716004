/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function messageBoardUiReducer(state = initialState.messageBoardUi, action) {
  switch (action.type) {
    //create
    case types.CREATE_MESSAGE_BOARD_POST: {
      return UiModel.start();
    }
    case types.CREATE_MESSAGE_BOARD_POST_CANCEL: {
      return UiModel.cancel();
    }
    case types.CREATE_MESSAGE_BOARD_POST_SUBMIT: {
      return UiModel.submit();
    }
    case types.CREATE_MESSAGE_BOARD_POST_SUCCESS: {
      return UiModel.success();
    }
    case types.CREATE_MESSAGE_BOARD_POST_FAILURE: {
      return UiModel.failure(action.errors);
    }
    //update
    case types.UPDATE_MESSAGE_BOARD_POST: {
      return UiModel.start();
    }
    case types.UPDATE_MESSAGE_BOARD_POST_CANCEL: {
      return UiModel.cancel();
    }
    case types.UPDATE_MESSAGE_BOARD_POST_SUBMIT: {
      return UiModel.submit();
    }
    case types.UPDATE_MESSAGE_BOARD_POST_SUCCESS: {
      return UiModel.success();
    }
    case types.UPDATE_MESSAGE_BOARD_POST_FAILURE: {
      return UiModel.failure(action.errors);
    }
    //
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.messageBoardUi);
    }
    default:
      return state;
  }
}
