import PropTypes from 'prop-types';
import React from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

export const signAuthorizationMenuItem = (isAuthorized, onTouch, key) => {
  return !isAuthorized ? (
    <MuiMenuItem key={key} onClick={() => onTouch()}>
      <ListItemIcon>
        <LaunchIcon />
      </ListItemIcon>
      <ListItemText primary="Podpisz upoważnienie profilem zaufanym" />
    </MuiMenuItem>
  ) : null;
};

const MuiMenuItem = React.forwardRef((props, ref) => {
  return <MenuItem ref={ref} {...props} />;
});

signAuthorizationMenuItem.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  onTouch: PropTypes.func,
  key: PropTypes.any
};
