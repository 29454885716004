import PropTypes from 'prop-types';
import React from 'react';
import { ListItem, ListItemIcon, List, Paper, ListItemButton } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import _ from 'lodash';
import EmptyState from '../common/EmptyState';

const xlsIcon = () => <CalculateOutlinedIcon />;
const pdfIcon = () => <PictureAsPdfOutlinedIcon />;

const icons = {
  pdf: pdfIcon(),
  xls: xlsIcon()
};

const ReportsList = ({ reports, onReportSelected, reportCategory }) => {
  const isReportsObjectEmpty = _.isEmpty(reports);
  return (
    <>
      {isReportsObjectEmpty ? (
        <EmptyState isPaper contrast message="Brak wyników wyszukiwania raportów" />
      ) : (
        <Paper sx={{ py: 1, px: 2 }}>
          <List>
            {reports.map((report) =>
              reportCategory.reports.includes(report.type) ? (
                <ListItem sx={{ p: 0, m: 0 }} key={report.type}>
                  <ListItemButton sx={{ p: 1 }} onClick={() => onReportSelected(report)}>
                    <ListItemText
                      primary={report.name}
                      primaryTypographyProps={{
                        fontWeight: (theme) => theme.typography.fontWeightBold
                      }}
                    />
                    <ListItemIcon sx={{ mr: -3 }}>{icons[report.formats[0]]}</ListItemIcon>
                  </ListItemButton>
                </ListItem>
              ) : null
            )}
          </List>
        </Paper>
      )}
    </>
  );
};

ReportsList.propTypes = {
  reports: PropTypes.array.isRequired,
  onReportSelected: PropTypes.func.isRequired,
  reportCategory: PropTypes.object.isRequired
};

export default ReportsList;
