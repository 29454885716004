import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as daysOffActions from '../../../actions/daysOffActions';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import { Paper } from '@mui/material';
import FullCalendar from '../../common/calendar/FullCalendar';
import CalendarDayContent from '../../common/calendar/Grid/CalendarDayContent';
import { browserHistory } from 'react-router';
import moment from 'moment';
import RemoveDayOffDialog from './dialogs/RemoveDayOffDialog';
import AddDayOffDialog from './dialogs/AddDayOffDialog';
import { DayOffModel } from '../../../models/configuration/daysOff/DaysOffModels';
import { routePaths, fromTemplate } from '../../../routePaths';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';

class DaysOffPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleYearOrMonthSelected = this.handleYearOrMonthSelected.bind(this);
    this.handleDaySelected = this.handleDaySelected.bind(this);
    this.renderCalendarDayContent = this.renderCalendarDayContent.bind(this);
    this.state = {
      day: null,
      isDayOff: false
    };
  }

  handleYearOrMonthSelected(year, month) {
    browserHistory.push(fromTemplate(routePaths.configurationDaysOff, [year, month]));
  }

  handleDaySelected(date) {
    const dayOff = this.props.daysOff.find((d) => date.isSame(d.date));
    const isDayOff = !!dayOff;
    const day = isDayOff ? dayOff : new DayOffModel().assign({ date: moment(date).format('YYYY-MM-DD'), name: '' });
    this.setState({ day, isDayOff });
    if (!isDayOff) {
      this.props.actions.addDayOffStart();
    }
  }

  renderRemoveDialog(day, isDayOff) {
    if (isDayOff) {
      return (
        <RemoveDayOffDialog
          dayOff={day}
          onCancel={() => this.setState({ day: null, isDayOff: false })}
          onConfirm={() => this.setState({ isDayOff: false }, () => this.props.actions.removeDayOffAsync(day))}
        />
      );
    }
    return null;
  }

  renderAddDialog() {
    if (this.props.ui.isEditing) {
      return (
        <AddDayOffDialog
          dayOff={this.state.day}
          onCancel={this.props.actions.addDayOffCancel}
          onSave={this.props.actions.addDayOffAsync}
          isProcessing={this.props.ui.isProcessing}
          isDialogOpen={this.props.ui.isEditing}
        />
      );
    }
    return null;
  }

  renderCalendarDayContent(day, containerWidth) {
    return (
      <CalendarDayContent
        day={day}
        containerWidth={containerWidth}
        primaryText={day.data ? day.data : null}
        primaryIcon={day.isMarked ? <BeachAccessIcon color="primary" /> : null}
      />
    );
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <>
          <PageHeaderText title="Dni Wolne" titleIcon={<BeachAccessIcon />} />
          <ContentWrapper>
            <Paper>
              <FullCalendar
                markedDays={this.props.daysOff.map((d) => {
                  return { date: d.date, data: d.name };
                })}
                defaultMonth={this.props.month}
                defaultYear={this.props.year}
                onYearOrMonthSelected={this.handleYearOrMonthSelected}
                onDaySelected={(day) => this.handleDaySelected(day.date)}
                onDayContentRender={this.renderCalendarDayContent}
              />
            </Paper>
            {this.renderRemoveDialog(this.state.day, this.state.isDayOff)}
            {this.renderAddDialog()}
          </ContentWrapper>
        </>
      </LoadingRenderWrapper>
    );
  }
}

DaysOffPage.propTypes = {
  daysOff: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    daysOff: state.configuration.daysOff,
    ui: state.configuration.daysOffUi,
    year: ownProps.params.year,
    month: ownProps.params.month
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(daysOffActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DaysOffPage);
