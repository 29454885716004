import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link } from 'react-router';
import PersonAvatar from '../forms/PersonAvatar';
import _ from 'lodash';
import { routePaths, fromTemplate } from '../../routePaths';
import ArchivalInfo from '../common/archivalInfo/ArchivalInfo';
import { ListItemText, Typography, ListItem, List, Avatar } from '@mui/material';
import LinkAdapter from '../common/LinkAdapter/LinkAdapter';
import EmptyState from '../common/EmptyState';

const ChildrenList = ({ pupils }) => {
  const children = useMemo(() => {
    const listItems = [];

    if (pupils.length === 0) {
      return <EmptyState contrast message="Brak wyników wyszukiwania" />;
    }

    pupils.forEach((group) => {
      const firstPupilInGroup = _.head(group[1]);

      listItems.push(
        <ListItem
          sx={{
            pl: 0,
            '&:hover': {
              background: (theme) => theme.palette.background.hover
            }
          }}
          key={firstPupilInGroup.id}
          component={LinkAdapter}
          to={fromTemplate(routePaths.childArchiveDetails, firstPupilInGroup.id)}>
          <Avatar
            sx={{
              p: 0,
              left: { xs: 0, md: 16 },
              color: (theme) => theme.palette.color.color25,
              background: (theme) => theme.palette.background.transparent
            }}>
            {group[0]}
          </Avatar>
          <ListItemText
            disableTypography
            sx={{ ml: { xs: 2, md: 5 }, color: (theme) => theme.palette.color.color2 }}
            primary={
              <Typography
                sx={{
                  fontWeight: (theme) => theme.typography.fontWeightBold
                }}>{`${firstPupilInGroup.lastName} ${firstPupilInGroup.firstName}`}</Typography>
            }
            secondary={<ArchivalInfo archivalInfo={firstPupilInGroup.archivalInfo} />}
          />
          <PersonAvatar
            initials
            sx={{
              right: 8
            }}
            alt={firstPupilInGroup.firstName}
            url={firstPupilInGroup.photoUrl}
            firstName={firstPupilInGroup.firstName}
            lastName={firstPupilInGroup.lastName}
          />
        </ListItem>
      );

      _.forEach(_.tail(group[1]), (pupil) => {
        listItems.push(
          <ListItem
            sx={{
              pl: 0,
              '&:hover': {
                background: (theme) => theme.palette.background.hover
              }
            }}
            key={pupil.id}
            component={LinkAdapter}
            to={fromTemplate(routePaths.childArchiveDetails, pupil.id)}
            insetchildren="true"
            containerelement={<Link to={fromTemplate(routePaths.childArchiveDetails, pupil.id)} />}>
            <Avatar
              sx={{
                left: { xs: 0, md: 16 },
                color: (theme) => theme.palette.background.transparent,
                background: (theme) => theme.palette.background.transparent
              }}>
              {group[0]}
            </Avatar>
            <ListItemText
              sx={{ ml: { xs: 2, md: 5 }, color: (theme) => theme.palette.text.primary }}
              primary={
                <Typography
                  sx={{
                    fontWeight: (theme) => theme.typography.fontWeightBold
                  }}>{`${pupil.lastName} ${pupil.firstName}`}</Typography>
              }
              secondary={<ArchivalInfo archivalInfo={pupil.archivalInfo} />}
            />
            <PersonAvatar
              initials
              sx={{
                right: 8
              }}
              alt={pupil.firstName}
              url={pupil.photoUrl}
              firstName={pupil.firstName}
              lastName={pupil.lastName}
            />
          </ListItem>
        );
      });
    });
    return listItems;
  }, [pupils]);

  return <List sx={{ py: 0 }}>{children}</List>;
};

ChildrenList.propTypes = {
  pupils: PropTypes.array.isRequired
};

export default ChildrenList;
