'use strict';

import BaseModel from '../../BaseModel';

export class IntegrationAccountsModel extends BaseModel {
  constructor() {
    super();
    this.cateringOwed = '';
    this.cateringHas = '';
    this.cateringPenaltyOwed = '';
    this.cateringPenaltyHas = '';
    this.cateringPaidOwed = '';
    this.cateringPaidHas = '';

    this.stayOwed = '';
    this.stayHas = '';
    this.stayPenaltyOwed = '';
    this.stayPenaltyHas = '';
    this.stayPaidOwed = '';
    this.stayPaidHas = '';

    this.otherOwed = '';
    this.otherHas = '';
    this.otherPenaltyOwed = '';
    this.otherPenaltyHas = '';
    this.otherPaidOwed = '';
    this.otherPaidHas = '';
  }
}

export class IntegrationModel extends BaseModel {
  constructor() {
    super();
    this.accounts = new IntegrationAccountsModel();
    this.integrationID = '';
    this.integrationURL = '';
  }

  onAssignment(assigned) {
    // eslint-disable-next-line no-param-reassign
    assigned.accounts = new IntegrationAccountsModel().assign(assigned.accounts);
  }
}

export class AdvancedInterest extends BaseModel {
  constructor() {
    super();
    this.startDate = '';
    this.rate = 0;
  }
}

export class SettlementsSettingsModel extends BaseModel {
  constructor() {
    super();
    this.stayDelayInterestCharging = false;
    this.stayDelayInterestRate = '';
    this.stayNumberingSchemeId = null;
    this.stayDuesRounding = false;
    this.staySixYearOldExemption = false;
    this.stayCreditDueOverpaymentsToBalance = false;

    this.cateringDelayInterestCharging = false;
    this.cateringDelayInterestRate = '';
    this.cateringNumberingSchemeId = null;
    this.cateringDuesRounding = false;
    this.cateringCreditDueOverpaymentsToBalance = false;

    this.otherDelayInterestCharging = false;
    this.otherDelayInterestRate = '';
    this.otherNumberingSchemeId = null;
    this.otherDuesRounding = false;
    this.otherCreditDueOverpaymentsToBalance = false;

    this.staffCateringDelayInterestCharging = false;
    this.staffCateringDelayInterestRate = '';
    this.staffCateringNumberingSchemeId = null;
    this.staffCateringDuesRounding = false;

    this.activitiesNumberingSchemeId = null;
    this.mealsNumberingSchemeId = null;

    this.symphonyIntegrationEnabled = false;
    this.integrationEnabled = false;
    this.integration = new IntegrationModel();
    this.advancedInterests = null;
    this.useAdvancedInterestSystemCatering = false;
    this.useAdvancedInterestSystemStay = false;
    this.useAdvancedInterestSystemOther = false;

    this.taxInterestCalculationSystem = false;
    this.separateBasicDuesFromInterestDues = false;
    this.taxInterestMinAmount = 0;

    this.interestToNearestTenRounding = false;
    this.interestToNearestFullRounding = false;

    this.useAdditionalWallets = false;
    this.mergeBMProductsWithSameAccountNumber = false;
    this.paymentDueDay = null;
    this.paymentDueDate = null;
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.integration = new IntegrationModel().assign(assigned.integration);
    assigned.advancedInterests = assigned.advancedInterests
      ? assigned.advancedInterests.map((i) => new AdvancedInterest().assign(i))
      : null;
  }
}
