import { Paper } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomCalendar from '../digitalDiary/customCalendar/calendar/CustomCalendar';
import ClassesDialog from './ClassesDialog';
import { PsychologicalClassesModel } from '../../models/psychological/PsychologicalClassesModel';
import EventDetailsDialog from '../eventsCalendar/dialogs/EventDetailsDialog';
import { browserHistory } from 'react-router';
import { fromTemplate } from '../../routePaths';

const ClassesCalendar = ({
  lessons,
  onSave,
  routePath,
  schemesArray,
  elevation,
  sx,
  ui,
  onOpen,
  onCancel,
  handleDateChange,
  helpId,
  handleRedirect
}) => {
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(moment().month() + 1);
  const [currentPost, setCurrentPost] = useState(null);
  const [eventDetailsDate, setEventDetailsDate] = useState(null);
  const [isEventDetailsDialogOpen, setIsEventDetailsDialogOpen] = useState(false);
  const daysOff = useSelector((state) => state.configuration.daysOff);

  const dispatch = useDispatch();

  const handleCalendarDateChange = (e) => {
    handleDateChange(e);
    setYear(moment(e).year());
    setMonth(moment(e).month() + 1);
  };
  const getEventsAtDay = (date) => {
    return lessons.filter((x) => x.eventDate === date.format('YYYY-MM-DD'));
  };
  const handleDetailsOpen = (date) => {
    const events = getEventsAtDay(date.date);
    if (events.length) openDetailsForStaff(date, events);
    else {
      onOpen();
      setCurrentPost(
        new PsychologicalClassesModel().assign({
          eventDate: date.date || moment().format()
        })
      );
    }
  };
  const handleOpenCreateEvent = () => {
    onOpen();
    if (eventDetailsDate) {
      setCurrentPost(
        new PsychologicalClassesModel().assign({
          eventDate: eventDetailsDate.date || moment().format()
        })
      );
    }
  };

  const handlePostSave = (post) => {
    dispatch(onSave(post));
  };
  const openDetailsForStaff = (date) => {
    setIsEventDetailsDialogOpen(true);
    setEventDetailsDate(date);
  };
  const handlePostCancel = () => {
    setCurrentPost(null);
    onCancel();
  };

  const handleDetailsClose = () => {
    setIsEventDetailsDialogOpen(false);
  };
  const handleShowEventFullDetails = () => {
    if (handleRedirect) {
      handleRedirect(eventDetailsDate);
    } else {
      browserHistory.push(
        fromTemplate(routePath, [
          helpId,
          eventDetailsDate.date.format('YYYY'),
          eventDetailsDate.date.format('MM'),
          eventDetailsDate.date.format('DD')
        ])
      );
    }
  };
  const renderEventDetailsDialog = () => {
    return isEventDetailsDialogOpen ? (
      <EventDetailsDialog
        isDialogOpen={isEventDetailsDialogOpen}
        onRequestClose={handleDetailsClose}
        header="Wpisy"
        events={getEventsAtDay(eventDetailsDate.date)}
        onShowEventFullDetails={handleShowEventFullDetails}
        onCreateNewEvent={handleOpenCreateEvent}
      />
    ) : null;
  };

  return (
    <Paper sx={{ my: 2, py: 1, ...sx }} elevation={elevation !== undefined ? elevation : undefined}>
      {ui.isEditing && (
        <ClassesDialog
          onSave={(post) => handlePostSave(post)}
          onCancel={(post) => handlePostCancel(post)}
          post={currentPost}
          isEditing={ui.isEditing}
          isProcessing={ui.isProcessing}
          canSetDate={true}
          schemesArray={schemesArray}
        />
      )}
      <CustomCalendar
        sx={{ px: { xs: 0, sm: 3 } }}
        eventDays={lessons}
        daysOff={daysOff}
        onDateChange={handleCalendarDateChange}
        onDaySelected={(date) => handleDetailsOpen(date)}
        date={moment(new Date(year, month - 1, 1))}
        year={year.toString()}
        month={month.toString()}
      />
      {renderEventDetailsDialog()}
    </Paper>
  );
};

export default ClassesCalendar;
