import PropTypes from 'prop-types';
import React from 'react';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import { Box, Fade, Paper, Typography } from '@mui/material';

const EmptyState = ({ message, noIcon, icon, style, sx, contrast, isPaper, paperSx }) => {
  const emptyState = (
    <Fade in={true} timeout={500}>
      <Box
        sx={{
          py: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: 2,
          color: (theme) => (contrast ? theme.palette.color.contrast : theme.palette.color.primary),
          my: 2,
          ...style,
          ...sx
        }}>
        {noIcon ? null : icon}
        <Typography
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightLarge,
            fontSize: 18,
            textAlign: 'center',
            ml: 1,
            mt: 0.5
          }}>
          {message}
        </Typography>
      </Box>
    </Fade>
  );

  if (isPaper) {
    return <Paper sx={{ px: 2, py: 4, ...paperSx }}>{emptyState}</Paper>;
  }
  return emptyState;
};

EmptyState.defaultProps = {
  icon: <SentimentDissatisfiedOutlinedIcon sx={{ width: '32px', height: '32px' }} />
};

EmptyState.propTypes = {
  message: PropTypes.string.isRequired,
  noIcon: PropTypes.bool,
  icon: PropTypes.object,
  style: PropTypes.object,
  sx: PropTypes.object,
  contrast: PropTypes.bool,
  isPaper: PropTypes.bool
};

export default EmptyState;
