import React from 'react';
import { MenuItem } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Typography from '@mui/material/Typography';
import { CalculationStatus } from '../../constants/calculationStatus';
import PropTypes from 'prop-types';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ActionConfirmationMenuItem from '../forms/ActionConfirmationMenuItem';
import Divider from '@mui/material/Divider';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import UpdateIcon from '@mui/icons-material/Update';
import XlsIcon from '../../assets/icons/XlsIcon';
import { Box } from '@mui/system';

const MuiMenuItem = React.forwardRef((props, ref) => {
  return <MenuItem ref={ref} {...props} />;
});

const MenuItemBody = ({ icon, text }) => (
  <>
    <ListItemIcon>{icon}</ListItemIcon>
    <Typography variant="inherit">{text}</Typography>
  </>
);

MenuItemBody.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string
};

const approvedValuesMenu = ({
  onPrint,
  onRegisterPayment,
  onCancel,
  onClose,
  onXlsPrint,
  settlement,
  isChildrenSettlementsList = true
}) => (
  <Box>
    <MuiMenuItem onClick={() => onPrint([settlement.id])}>
      <MenuItemBody text="Pobierz wydruk (.pdf)" icon={<PictureAsPdfIcon />} />
    </MuiMenuItem>

    <MuiMenuItem onClick={() => onRegisterPayment(settlement)}>
      <MenuItemBody text="Zarejestruj wpłatę" icon={<AttachMoneyIcon />} />
    </MuiMenuItem>

    <MuiMenuItem onClick={() => onXlsPrint(settlement.person.id, settlement.dueType)}>
      {isChildrenSettlementsList && <MenuItemBody text="Pobierz ewidencje opłat (.xls)" icon={<XlsIcon />} />}
    </MuiMenuItem>
    <ActionConfirmationMenuItem
      actionLabel="Anuluj"
      confirmationText="Czy na pewno chcesz anulować wybraną opłatę? Tej operacji nie można cofnąć."
      confirmationTitle="Usunięcie opłaty"
      confirmLabel="Tak, usuń opłatę"
      cancelLabel="Nie"
      onItemClick={onClose}
      onConfirm={() => onCancel(settlement)}
      onCancel={() => {}}
      leftIcon={<CancelIcon sx={{ mr: 1.5 }} />}
    />
  </Box>
);

const draftValuesMenu = ({ onApprove, onDelete, onClose, settlement }) => [
  <MuiMenuItem key={1} onClick={() => onApprove([settlement.id])}>
    <MenuItemBody text="Zatwierdź" icon={<DoneIcon />} />
  </MuiMenuItem>,
  <ActionConfirmationMenuItem
    actionLabel="Usuń"
    confirmationText="Czy na pewno chcesz usunąć wybraną opłatę? Tej operacji nie można cofnąć."
    confirmationTitle="Usunięcie opłaty"
    confirmLabel="Tak, usuń opłatę"
    cancelLabel="Nie"
    onItemClick={() => onClose()}
    onConfirm={() => onDelete([settlement.id])}
    onCancel={() => {}}
    leftIcon={<DeleteIcon sx={{ mr: 1.5 }} />}
    key={2}
  />
];

const failedValuesMenu = ({ onDelete, settlement, onClose }) => [
  <ActionConfirmationMenuItem
    actionLabel="Usuń"
    confirmationText="Czy na pewno chcesz usunąć wybraną opłatę? Tej operacji nie można cofnąć."
    confirmationTitle="Usunięcie opłaty"
    confirmLabel="Tak, usuń opłatę"
    cancelLabel="Nie"
    onItemClick={() => onClose()}
    onConfirm={() => onDelete([settlement.id])}
    onCancel={() => {}}
    leftIcon={<DeleteIcon sx={{ mr: 1.5 }} />}
    key={1}
  />
];

const paidValuesMenu = ({
  onPrint,
  onRegisterRebate,
  onRemoveRebate,
  onCancel,
  onClose,
  onXlsPrint,
  onCalculateCorrectiveDues,
  settlement,
  isChildrenSettlementsList = true
}) => [
  <MuiMenuItem key={1} onClick={() => onPrint([settlement.id])}>
    <MenuItemBody text="Pobierz wydruk (.pdf)" icon={<PictureAsPdfIcon />} />
  </MuiMenuItem>,
  onCalculateCorrectiveDues != null && settlement.canBeCorrected() ? (
    <MuiMenuItem key={2} onClick={() => onCalculateCorrectiveDues([settlement.id])}>
      <MenuItemBody text="Oblicz korektę" icon={<UpdateIcon />} />
    </MuiMenuItem>
  ) : null,
  <MuiMenuItem key={3} onClick={() => onRegisterRebate(settlement)}>
    <MenuItemBody text="Zarejestruj zwrot" icon={<MoneyOffIcon />} />
  </MuiMenuItem>,
  <MuiMenuItem key={4} onClick={() => onXlsPrint(settlement.person.id, settlement.dueType)}>
    {isChildrenSettlementsList && <MenuItemBody text="Pobierz ewidencje opłat (.xls)" icon={<XlsIcon />} />}
  </MuiMenuItem>,
  settlement.canBeCanceled() ? <Divider key={5} /> : null,
  settlement.canBeCanceled() ? (
    <ActionConfirmationMenuItem
      actionLabel="Anuluj"
      confirmationText="Czy na pewno chcesz anulować wybraną opłatę? Tej operacji nie można cofnąć."
      confirmationTitle="Anulowanie opłaty"
      confirmLabel="Tak, anuluj opłatę"
      cancelLabel="Nie"
      onItemClick={() => onClose}
      onConfirm={() => onCancel(settlement)}
      onCancel={() => {}}
      leftIcon={<CancelIcon sx={{ mr: 1.5 }} />}
      key={6}
    />
  ) : null,
  settlement.paymentRebate ? (
    <MuiMenuItem key={7} onClick={() => onRemoveRebate(settlement)}>
      <MenuItemBody text="Usuń zwrot" icon={<UndoIcon />} />
    </MuiMenuItem>
  ) : null
];

const partlyPaidValuesMenu = ({
  onPrint,
  onRegisterPayment,
  onXlsPrint,
  settlement,
  isChildrenSettlementsList = true
}) => [
  <MuiMenuItem key={1} onClick={() => onPrint([settlement.id])}>
    <MenuItemBody text="Pobierz wydruk (.pdf)" icon={<PictureAsPdfIcon />} />
  </MuiMenuItem>,
  <MuiMenuItem key={2} onClick={() => onRegisterPayment(settlement)}>
    <MenuItemBody text="Zarejestruj wpłatę" icon={<AttachMoneyIcon />} />
  </MuiMenuItem>,
  <MuiMenuItem key={3} onClick={() => onXlsPrint(settlement.person.id, settlement.dueType)}>
    {isChildrenSettlementsList && <MenuItemBody text="Pobierz ewidencje opłat (.xls)" icon={<XlsIcon />} />}
  </MuiMenuItem>
];

export const renderMenuItems = (row, onClose, props) => {
  const settlement = row;
  switch (row.calculationStatus) {
    case CalculationStatus.approved.value:
      return approvedValuesMenu({ ...props, onClose, settlement });
    case CalculationStatus.draft.value:
      return draftValuesMenu({ ...props, onClose, settlement });
    case CalculationStatus.failed.value:
      return failedValuesMenu({ ...props, onClose, settlement });
    case CalculationStatus.paid.value:
      return paidValuesMenu({ ...props, onClose, settlement });
    case CalculationStatus.partlyPaid.value:
      return partlyPaidValuesMenu({ ...props, onClose, settlement });
    default:
      return null;
  }
};
