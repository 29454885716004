import PropTypes from 'prop-types';
import React from 'react';
import { CardActionArea, CardActions, CardContent, Collapse, Paper, Typography } from '@mui/material';
import SaveCancelButtons from './buttons/SaveCancelButtons';
import ValidationWrapper from './ValidationWrapper';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PersonAvatar from './PersonAvatar';

class CardEditForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.state = {
      showAllValidationErrors: false,
      isOpen: true
    };
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleCancel() {
    this.props.onCancel();
  }

  handleOpen() {
    this.setState((prev) => ({ isOpen: !prev.isOpen }));
  }

  handleSave() {
    if (this.props.onValidate && !this.props.onValidate().isValid) {
      this.setState({ showAllValidationErrors: true });
    } else {
      this.props.onSave();
    }
  }

  render() {
    return (
      <Paper sx={{ my: 2, p: 1 }} elevation={1}>
        <CardActionArea onClick={this.handleOpen}>
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              px: 2,
              py: 1,
              alignItems: 'center'
            }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                fontWeight: (theme) => theme.typography.fontWeightBold,
                my: '4px',
                display: 'flex',
                alignItems: 'center'
              }}>
              {this.props.groupColor && (
                <PersonAvatar clear={this.props.title} sx={{ mr: 2 }} color={this.props.groupColor || null} />
              )}
              {this.props.title}
            </Typography>

            {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
          </CardContent>
        </CardActionArea>
        <Collapse in={this.state.isOpen}>
          <CardContent>
            <ValidationWrapper
              validate={this.props.onValidate || (() => {})}
              onValidationDone={this.props.onValidationDone || (() => {})}
              statePathToUi={this.props.statePathToUi}
              showAllErrors={this.state.showAllValidationErrors}>
              {this.props.editForm}
            </ValidationWrapper>
            <CardActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2,
                alignItems: 'center'
              }}>
              {this.props.requiredFieldText && (
                <Typography component="span" sx={{ mr: 'auto', pl: 1, fontSize: 14 }}>
                  * - Pola wymagane
                </Typography>
              )}
              <SaveCancelButtons
                hideCancel={this.props.hideCancel}
                onSave={this.handleSave}
                onCancel={this.handleCancel}
                inProgress={this.props.isProcessing}
              />
            </CardActions>
          </CardContent>
        </Collapse>
      </Paper>
    );
  }
}

CardEditForm.propTypes = {
  groupColor: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  onValidationDone: PropTypes.func,
  isProcessing: PropTypes.bool.isRequired,
  statePathToUi: PropTypes.string,
  //
  isExpanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
  //
  title: PropTypes.string,
  subtitle: PropTypes.string,
  //
  editForm: PropTypes.node.isRequired,
  requiredFieldText: PropTypes.bool
};

CardEditForm.defaultProps = {
  onValidate: null,
  onValidationDone: null
};

export default CardEditForm;
