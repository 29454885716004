import { FormControl, Input, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from './DatePicker';
import { psychologicalSchemesTypes } from '../../constants/psychologicalSchemesTypes';

const AdvancedFormField = ({
  label,
  additionalInfo,
  data,
  setData,
  value,
  fieldLabel,
  isReadonly,
  error,
  schemeType
}) => {
  const [selectValue, setSelectValue] = useState([]);
  const [dateFrom, setDateFrom] = useState(value?.startDate || null);
  const [dateTo, setDateTo] = useState(value?.endDate || null);
  const [number, setNumber] = useState(value?.weeklyHours);
  const [description, setDescription] = useState(value?.content);
  const avileableSchemes = useSelector((state) => state.psychologicalSchemes);

  useEffect(() => {
    additionalInfo
      ? setData({
          startDate: dateFrom,
          endDate: dateTo,
          weeklyHours: number,
          content: description
        })
      : setData({
          content: description
        });
  }, [additionalInfo, dateFrom, dateTo, description, number, selectValue, setData]);

  const possibleSchemes = useMemo(() => {
    return avileableSchemes[schemeType];
  }, [avileableSchemes, schemeType]);

  const onSchemeSelect = (value) => {
    setDescription(value);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: { sm: 'end' },
          justifyContent: { sm: 'space-between' },
          flexDirection: { xs: 'column', sm: 'row' }
        }}>
        <Typography sx={{ mr: 2, my: additionalInfo ? 1 : 2, fontSize: 18, wordWrap: 'break-word' }} variant="h6">
          {label}
        </Typography>
        {!isReadonly && (
          <FormControl sx={{ width: '100%', minWidth: 250, mb: 1 }} variant="standard">
            <InputLabel id={label}>Wybierz z listy</InputLabel>

            <Select
              disabled={isReadonly}
              labelId={label}
              id={`Wybór z listy ${label}`}
              value={selectValue}
              onChange={(e) => {
                setSelectValue(e.target.value);
                onSchemeSelect(e.target.value.content);
              }}
              input={<Input label="Select" />}>
              {possibleSchemes &&
                possibleSchemes.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item}
                    sx={{
                      whiteSpace: 'unset',
                      wordBreak: 'break-all',
                      width: '100%'
                    }}>
                    {item.templateName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </Box>
      {additionalInfo && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { sm: 'end' },
            justifyContent: { sm: 'space-between' },
            my: { sm: 2 }
          }}>
          <Box>
            <DatePicker
              errorText={error?.startDate}
              disabled={isReadonly}
              unlimitedMaxDate
              sx={{ width: 150, mr: 2, mt: 1 }}
              hintText="Realizacja od"
              label="Realizacja od"
              floatingLabelText="Realizacja od"
              name="date_from"
              onChange={(empty, date) => {
                setDateFrom(date);
              }}
              value={dateFrom}
            />
            <DatePicker
              errorText={error?.endDate}
              disabled={isReadonly}
              unlimitedMaxDate
              sx={{ width: 150, mr: { sm: 2 }, mt: 1 }}
              floatingLabelText="Realizacja do"
              hintText="Realizacja do"
              label="Realizacja do"
              name="date_from"
              onChange={(empty, date) => {
                setDateTo(date);
              }}
              value={dateTo}
            />
          </Box>

          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'end' }}>
            <TextField
              error={!!error?.weeklyHours}
              helperText={error?.weeklyHours}
              disabled={isReadonly}
              value={number}
              label="Tygodniowy wymiar godzin"
              onChange={(e) => setNumber(e.target.value)}
              variant="standard"
              sx={{ mr: 2, width: { xs: '100%', sm: 200 }, mt: { xs: 1 }, mb: { xs: 0.5, sm: undefined } }}
            />
          </Stack>
        </Box>
      )}
      <TextField
        error={!!error?.content}
        helperText={error?.content}
        disabled={isReadonly}
        placeholder="Miejsce na uwagi"
        value={description}
        multiline
        minRows="8"
        fullWidth
        onChange={(e) => {
          setSelectValue();
          setDescription(e.target.value);
        }}
      />
    </Box>
  );
};

export default AdvancedFormField;
