import initialState from './initial';
import { ChildDetailsModel } from '../models/children/ChildModels';
import * as types from '../actions/actionTypes';

export default function childOnVacationPeriodReducer(state = initialState.childrenOnVacationPeriod, action) {
  switch (action.type) {
    case types.LOAD_CHILDREN_ON_VACATION_PERIOD_SUCCESS: {
      return action.childrenOnVacationPeriod.map((child) => new ChildDetailsModel().assign(child));
    }
    default:
      return state;
  }
}
