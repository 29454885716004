import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as staffMembersActions from '../../../actions/staffMembersActions';
import CardDialogEditForm from '../../forms/CardDialogEditForm';
import ChargingSchemeForm from '../../common/chargingSchemes/ChargingSchemeForm';
import ChargingSchemesList from '../../common/chargingSchemes/ChargingSchemesList';
import { StaffChargingScheme } from '../../../models/staffMembers/StaffMemberModels';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import EmptyState from '../../common/EmptyState';
import _ from 'lodash';
import ActionButton from '../../forms/buttons/ActionButton';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../../../routePaths';
import { Box } from '@mui/material';
import { StaffChargingSchemeModelValidator } from '../../../models/staffMembers/StaffMemberModelsValidator';

const StaffChargingSchemesCard = ({
  allReliefs,
  allChargingSchemes,
  staffChargingSchemes,
  onSave,
  onCancel,
  onEdit,
  onCreate,
  onRemove,
  isEditing,
  isProcessing,
  staffMember
}) => {
  const [activeScheme, setActiveScheme] = useState({});
  const [isNew, setIsNew] = useState(false);
  const [errors, setErrors] = useState({});

  const handleEditScheme = (scheme) => {
    setActiveScheme(new StaffChargingScheme().assign(scheme));
    setIsNew(false);
    onEdit();
  };

  const handleSchemeChange = (schemeId) => {
    const { id } = allChargingSchemes.find((x) => x.id === schemeId);
    const { reliefId, reliefExpiryDate, singleUse } = activeScheme;

    setActiveScheme(new StaffChargingScheme().assign({ schemeId: id, reliefId, reliefExpiryDate, singleUse }));
  };

  const handleChange = (event) => {
    const field = event.target.name;
    const newActiveScheme = { ...activeScheme, [field]: event.target.value };
    setActiveScheme(newActiveScheme);
  };

  const availableChargingSchemes = useMemo(() => {
    const hasStaySchemeAssigned = staffChargingSchemes.some((cs) =>
      allChargingSchemes.some(
        (scheme) => scheme.id === cs.schemeId && scheme.reportType === ChargingReportTypes.stay.value
      )
    );

    return allChargingSchemes
      .filter((scheme) => !staffChargingSchemes.some((cs) => scheme.id === cs.schemeId))
      .filter((scheme) => scheme.reportType !== ChargingReportTypes.stay.value || !hasStaySchemeAssigned);
  }, [allChargingSchemes, staffChargingSchemes]);

  const handleAssignScheme = () => {
    const defaultScheme = new StaffChargingScheme();
    if (availableChargingSchemes.length > 0) {
      defaultScheme.schemeId = _.first(availableChargingSchemes).id;
    }

    setActiveScheme(defaultScheme);
    setIsNew(true);
    onCreate();
  };

  const renderOpenFinancialDetailsButton = () => (
    <ActionButton
      key="2"
      actionLabel="Rozliczenie nadpłat"
      onAction={() => browserHistory.push(fromTemplate(routePaths.staffFinancialDetails, [staffMember.id]))}
      inProgress={isProcessing}
    />
  );

  const renderEditForm = () => (
    <ChargingSchemeForm
      isEmployee={true}
      scheme={activeScheme}
      isNew={isNew}
      onSchemeChange={handleSchemeChange}
      onChange={handleChange}
      errors={errors}
      chargingSchemes={allChargingSchemes}
      reliefs={allReliefs}
      availableChargingSchemes={availableChargingSchemes}
    />
  );

  const renderReadonlyForm = () => (
    <ChargingSchemesList
      allChargingSchemes={allChargingSchemes}
      allReliefs={allReliefs}
      assignedChargingSchemes={staffChargingSchemes}
      onEdit={handleEditScheme}
      onRemove={onRemove}
    />
  );

  return (
    <Box>
      <CardDialogEditForm
        maxWidth="md"
        title="Opłaty"
        isInitiallyExpanded={true}
        onSave={() => onSave(activeScheme)}
        onCancel={() => onCancel(activeScheme)}
        onAction={handleAssignScheme}
        onValidate={() => new StaffChargingSchemeModelValidator().validate(activeScheme)}
        onValidationDone={setErrors}
        isDialogOpen={isEditing}
        isProcessing={isProcessing}
        statePathToUi="staffMemberDetailsUi.chargingSchemes"
        actionLabel="Przypisz schemat płatności"
        actionTitle="Schemat płatności"
        readonlyForm={renderReadonlyForm()}
        emptyForm={<EmptyState message="Brak przypisanych opłat" contrast />}
        editForm={renderEditForm()}
        isEmpty={staffChargingSchemes.length === 0}
        saveDisabled={availableChargingSchemes.length === 0 && isNew}
        customActions={[renderOpenFinancialDetailsButton()]}
      />
    </Box>
  );
};

StaffChargingSchemesCard.propTypes = {
  allReliefs: PropTypes.array.isRequired,
  allChargingSchemes: PropTypes.array.isRequired,
  staffChargingSchemes: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  staffMember: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return ownProps;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(staffMembersActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffChargingSchemesCard);
