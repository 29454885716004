import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import AssignedChildrenList from './AssignedChildrenList';
import _ from 'lodash';
import Dialog from '../../../common/Dialog';
import { Box, Button, Switch, FormGroup, FormControlLabel, Typography } from '@mui/material';

const AssignedChildrenDialog = ({
  onClose,
  isDialogOpen,
  pupils,
  assignedPupilsSetup,
  scheme,
  onAssignChildren,
  onUnassignChildren
}) => {
  const [isAssigning, setIsAssinging] = useState(false);
  const [isUnassigning, setIsUnassigning] = useState(false);
  const [isReadonly, setIsReadonly] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [assignAsSingleUse, setAssignAsSingleUse] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [reliefId, setReliefId] = useState(null);
  const [reliefExpirationDate, setReliefExpirationDate] = useState(null);

  const getGroupsWithPupils = useCallback(
    (availablePupils) => {
      const filteredPupils = pupils
        .filter((x) => x.enrolledToGroup)
        .filter((x) => availablePupils.some((e) => e.id === x.id))
        .map((x) => {
          const pupilSetup = availablePupils.find((e) => e.id === x.id);
          return {
            ...x,
            isChecked: selectedIds.some((s) => s === x.id),
            singleUse: pupilSetup && pupilSetup.singleUse
          };
        });
      return _.groupBy(filteredPupils, (x) => x.enrolledToGroup.name);
    },
    [pupils, selectedIds]
  );

  const assignedGroupsWithPupils = useMemo(() => {
    return getGroupsWithPupils(assignedPupilsSetup.filter((x) => x.alreadyLinked));
  }, [assignedPupilsSetup, getGroupsWithPupils]);

  const availableGroupsWithPupils = useMemo(() => {
    return getGroupsWithPupils(assignedPupilsSetup.filter((x) => !x.alreadyLinked && x.canLink));
  }, [assignedPupilsSetup, getGroupsWithPupils]);

  const readyFilteredAssignedPupils = useMemo(() => {
    const filteredGroupsWithPupils = Object.entries(assignedGroupsWithPupils).map((pupil) => {
      const filteredPupils = pupil[1].filter(
        (x) =>
          (x.firstName + ' ' + x.lastName).toLowerCase().includes(searchText.toLowerCase()) ||
          (x.lastName + ' ' + x.firstName).toLowerCase().includes(searchText.toLowerCase())
      );
      return [pupil[0], filteredPupils];
    });
    return Object.fromEntries(filteredGroupsWithPupils);
  }, [assignedGroupsWithPupils, searchText]);

  const readyFilteredAvaliablePupils = useMemo(() => {
    const filteredGroupsWithPupils = Object.entries(availableGroupsWithPupils).map((pupil) => {
      const filteredPupils = pupil[1].filter(
        (x) =>
          x.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
          x.lastName.toLowerCase().includes(searchText.toLowerCase())
      );
      return [pupil[0], filteredPupils];
    });
    return Object.fromEntries(filteredGroupsWithPupils);
  }, [availableGroupsWithPupils, searchText]);

  const handleSelected = (ids) => {
    setSelectedIds([...selectedIds, ...ids]);
  };

  const handleDeselected = (ids) => {
    setSelectedIds(selectedIds.filter((s) => !ids.some((x) => x === s)));
  };

  const handleStartAssigning = () => {
    setIsAssinging(true);
    setIsReadonly(false);
  };

  const handleStartUnassigning = () => {
    setIsUnassigning(true);
    setIsReadonly(false);
  };

  const confirmAssign = () => {
    onAssignChildren(scheme.id, selectedIds, assignAsSingleUse, reliefId, reliefExpirationDate);
    handleClear();
  };

  const confirmUnassign = () => {
    onUnassignChildren(scheme.id, selectedIds);
    handleClear();
  };

  const handleClear = () => {
    setIsAssinging(false);
    setIsUnassigning(false);
    setIsReadonly(true);
    setSelectedIds([]);
    setAssignAsSingleUse(false);
    setReliefId(null);
    setReliefExpirationDate(null);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const renderReadonlyList = () => {
    return (
      <AssignedChildrenList
        searchText={searchText}
        setSearchText={setSearchText}
        groupsWithPupils={readyFilteredAssignedPupils}
        isReadonly={true}
        emptyMessage="Nie przypisano jeszcze żadnych dzieci"
        onSelect={() => {}}
        onDeselect={() => {}}
        reliefId={reliefId}
        reliefExpirationDate={reliefExpirationDate}
        onReliefIdChange={() => {}}
        onReliefExpirationDateChange={() => {}}
        isAssigning={isAssigning}
      />
    );
  };

  const renderAssignList = () => {
    return (
      <AssignedChildrenList
        searchText={searchText}
        setSearchText={setSearchText}
        groupsWithPupils={readyFilteredAvaliablePupils}
        isReadonly={false}
        emptyMessage="Wszystkie dzieci zostały już przypisane do tego schematu"
        onSelect={handleSelected}
        onDeselect={handleDeselected}
        reliefId={reliefId}
        reliefExpirationDate={reliefExpirationDate}
        onReliefIdChange={setReliefId}
        onReliefExpirationDateChange={setReliefExpirationDate}
        isAssigning={isAssigning}
      />
    );
  };

  const renderUnassignList = () => {
    return (
      <AssignedChildrenList
        searchText={searchText}
        setSearchText={setSearchText}
        groupsWithPupils={readyFilteredAssignedPupils}
        isReadonly={false}
        emptyMessage="Wszystkie dzieci zostały już odpięte do tego schematu"
        onSelect={handleSelected}
        onDeselect={handleDeselected}
        reliefId={reliefId}
        reliefExpirationDate={reliefExpirationDate}
        onReliefIdChange={() => {}}
        onReliefExpirationDateChange={() => {}}
        isAssigning={isAssigning}
      />
    );
  };

  const renderDialogButtons = () => {
    return [
      <Button variant="outlinedContrast" aria-label="Anuluj" key="2" onClick={handleClear}>
        Anuluj
      </Button>,
      isReadonly && (
        <Button variant="contained" aria-label="zapisz" key="1" onClick={handleClose}>
          zapisz
        </Button>
      ),
      isAssigning && (
        <Button
          variant="contained"
          aria-label="Przypisz"
          key="3"
          onClick={confirmAssign}
          disabled={!selectedIds.length}>
          Przypisz
        </Button>
      ),
      isUnassigning && (
        <Button
          variant="contained"
          aria-label="Odepnij"
          key="4"
          onClick={confirmUnassign}
          disabled={!selectedIds.length}>
          Odepnij
        </Button>
      )
    ];
  };

  const renderAssignOptions = () => {
    return (
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                disabled={!selectedIds.length}
                onChange={(event, value) => {
                  setAssignAsSingleUse(value);
                }}
              />
            }
            label="Schemat jednorazowy"
          />
        </FormGroup>
        <Typography sx={{ fontSize: 14 }}>
          Schemat zostanie automatycznie odpięty po zatwierdzeniu rozliczenia
        </Typography>
      </Box>
    );
  };

  const renderTitle = () => {
    if (isAssigning) return `Przypnij dzieci do schematu ${scheme.name}`;
    if (isUnassigning) return `Odepnij dzieci od schematu ${scheme.name}`;

    return `Dzieci przypisane do schematu ${scheme.name}`;
  };

  return (
    <Dialog
      onClose={() => {
        handleClear();
      }}
      title={renderTitle()}
      actions={renderDialogButtons()}
      open={isDialogOpen}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          {isReadonly && (
            <Button variant="outlinedContrast" aria-label="Odepnij przypisane" onClick={handleStartUnassigning}>
              Odepnij przypisane
            </Button>
          )}
          {isReadonly && (
            <Button variant="contained" aria-label="Przypisz" onClick={handleStartAssigning}>
              Przypisz
            </Button>
          )}
        </Box>

        {isReadonly && renderReadonlyList()}
        {isAssigning && renderAssignList()}
        {isAssigning && renderAssignOptions()}
        {isUnassigning && renderUnassignList()}
      </Box>
    </Dialog>
  );
};

AssignedChildrenDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  pupils: PropTypes.array.isRequired,
  assignedPupilsSetup: PropTypes.array.isRequired,
  scheme: PropTypes.object.isRequired,
  onAssignChildren: PropTypes.func.isRequired,
  onUnassignChildren: PropTypes.func.isRequired
};

export default AssignedChildrenDialog;
