import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import FilterBar from '../../common/FilterBar';
import { Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

class ActivityChildrenEditList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selected: [],
      selectable: [...this.props.allPupils],
      pupils: this.props.allPupils
    };
    this.handleRowClick = this.handleRowClick.bind(this);

    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleRowClick(pupilId) {
    const selectedIndex = this.state.selected.indexOf(pupilId);
    if (selectedIndex === -1) {
      this.setState(
        (state) => ({
          selected: [...state.selected, pupilId]
        }),
        () => {
          this.props.onChange(this.state.selected);
        }
      );
    } else {
      this.setState(
        (state) => ({
          selected: [...state.selected.filter((pupil) => pupil !== pupilId)]
        }),
        () => {
          this.props.onChange(this.state.selected);
        }
      );
    }
  }

  handleFilterChange(filteredList) {
    this.setState({
      selectable: filteredList
    });
  }

  render() {
    if (this.state.pupils.length === 0) {
      return (
        <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
          Wszystkie dzieci zostały już przypisane do zajęć dodatkowych
        </Typography>
      );
    }

    return (
      <Box>
        <FilterBar
          contrast
          itemsToFilter={this.state.pupils}
          onFilter={this.handleFilterChange}
          filter={(selectable, keyword) =>
            selectable.lastName.toLowerCase().includes(keyword) || selectable.firstName.toLowerCase().includes(keyword)
          }
        />

        {this.state.selected.length ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              p: 2
            }}>
            <Typography>Wybrano:</Typography>
            <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, ml: 1 }}>
              {this.state.selected.length}
            </Typography>
          </Box>
        ) : null}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Numer ewidencyjny</TableCell>
              <TableCell>Nazwisko</TableCell>
              <TableCell>Imię</TableCell>
              <TableCell>Data Urodzenia</TableCell>
            </TableRow>
          </TableHead>
          <TableBody displayrowcheckbox="true" deselectonclickaway="false" sx={{ minWidth: 500 }}>
            {this.state.selectable.map((selectable) => {
              return (
                <TableRow onClick={() => this.handleRowClick(selectable.id)} hover role="checkbox" key={selectable.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={this.state.selected.includes(selectable.id)}
                      inputProps={{
                        'aria-labelledby': 'pole wyboru'
                      }}
                    />
                  </TableCell>
                  <TableCell>{selectable.personalId}</TableCell>
                  <TableCell>{selectable.lastName}</TableCell>
                  <TableCell>{selectable.firstName}</TableCell>
                  <TableCell>{moment(selectable.birthDate).format('L')}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    );
  }
}

ActivityChildrenEditList.propTypes = {
  allPupils: PropTypes.array.isRequired,
  enrolledPupilsIds: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ActivityChildrenEditList;
