import React from 'react';
import PropTypes from 'prop-types';
import RecoveryPasswordForm, {
  InitialValues,
  validate,
  VALIDATION_SCHEMA
} from '../../../../../forms/userAccount/RecoveryPasswordForm';
import FormikContentWrapper from '../../../../formik/FormikContentWrapper';
import { browserHistory } from 'react-router';
import { routePaths } from '../../../../../routePaths';
import { Box, Button, Typography } from '@mui/material';

const PasswordRecoveryFormWrap = ({ onSubmit, isLoading }) => {
  return (
    <FormikContentWrapper
      initialValues={new InitialValues()}
      validationSchema={VALIDATION_SCHEMA}
      validate={validate}
      onSubmit={onSubmit}>
      {(isValid) => (
        <Box sx={{ py: 3, px: 0 }}>
          <RecoveryPasswordForm />
          <Typography sx={{ pb: 1, display: 'inline-block' }}>* - Pola wymagane</Typography>
          <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', mx: 5 }}>
            <Button variant="contained" disabled={!isValid || isLoading} aria-label="Wyślij" type="submit" fullWidth>
              Wyślij
            </Button>

            <Button
              variant="outlinedContrast"
              onClick={() => browserHistory.push(routePaths.home)}
              aria-label="Powrót"
              fullWidth>
              Powrót
            </Button>
          </Box>
        </Box>
      )}
    </FormikContentWrapper>
  );
};

PasswordRecoveryFormWrap.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default PasswordRecoveryFormWrap;
