import { check } from '../../validation/validateHelper';
import { NumberingSchemeModel } from './NumberingSchemeModels';
import { BaseModelValidator } from '../../BaseModelValidator';
import { isNumericGreaterThanOrEqual } from '../../../utils/numberValidators';

export class NumberingSchemeModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = NumberingSchemeModel;

    this.constraints = {
      nextNumber: (value) => !isNumericGreaterThanOrEqual(value, 0) && 'Wartość musi być liczbą dodatnią.',
      name: (value) => check.isEmpty(value) && 'Nie podano nazwy.',
      template: (value) => (check.isEmpty(value) || !value.includes('{numer}')) && 'Wzorzec musi zawierać "{numer}"'
    };
  }
}
