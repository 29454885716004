import { check } from '../validation/validateHelper';
import { BaseModelValidator } from '../BaseModelValidator';
import { StaffMemberModel, StaffChargingScheme } from './StaffMemberModels';
import Pesel from '../../utils/Pesel';
import moment from 'moment';

export class StaffMemberModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = StaffMemberModel;

    this.constraints = {
      firstName: (value) => check.isEmpty(value) && 'Nie podano imienia',
      lastName: (value) => check.isEmpty(value) && 'Nie podano nazwiska',
      email: (value) => {
        if (check.isEmpty(value)) return 'Nie podano adresu email';
        if (check.single(value, { email: true })) return 'Podany adres email jest nieprawidłowy';
        return null;
      },
      role: (value) => check.isEmpty(value) && 'Nie podano roli'
    };
  }
}

export class StaffMemberModelWithPersonalIdValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = StaffMemberModel;

    this.constraints = {
      firstName: (value) => check.isEmpty(value) && 'Nie podano imienia',
      lastName: (value) => check.isEmpty(value) && 'Nie podano nazwiska',
      email: (value) => {
        if (check.isEmpty(value)) return 'Nie podano adresu email';
        if (check.single(value, { email: true })) return 'Podany adres email jest nieprawidłowy';
        return null;
      },
      personalId: (value) => {
        if (check.isEmpty(value)) return 'Nie podano numeru pesel';
        if (!new Pesel(value).isValid()) return 'Nieprawidłowy numer pesel';
        return null;
      },
      role: (value) => check.isEmpty(value) && 'Nie podano roli'
    };
  }
}

export class StaffChargingSchemeModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = StaffChargingScheme;
    this.constraints = {
      schemeId: (value) => check.isEmpty(value) && 'Nie wybrano schematu płatności',
      reliefExpiryDate: (value) => {
        if (check.isEmpty(value)) return null;
        if (!moment(value).isValid()) return 'Podana data jest nieprawidłowa';
        return null;
      }
    };
  }
}
