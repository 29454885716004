import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@mui/material';

class ActionConfirmationMenuItem extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isOpen: false
    };
    this.handleAction = this.handleAction.bind(this);
    this.renderActionButtons = this.renderActionButtons.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.confirmButton = React.createRef();
  }

  handleAction(event) {
    event.preventDefault();
    this.setState({ isOpen: true });
  }

  handleConfirm(event) {
    this.setState({ isOpen: false });
    this.props.onConfirm(event);
  }

  handleCancel() {
    this.setState({ isOpen: false });
    this.props.onCancel();
  }

  renderActionButtons() {
    return [
      <Button label={this.props.cancelLabel} onClick={this.handleCancel}>
        {this.props.cancelLabel}
      </Button>,
      <Button label={this.props.confirmLabel} onClick={this.handleConfirm} autoFocus>
        {this.props.confirmLabel}
      </Button>
    ];
  }

  render() {
    return [
      <IconButton
        key="confirmation-icon-button"
        autoFocus={this.props.autofocus}
        onClick={this.handleAction}
        sx={this.props.style}
        aria-haspopup="true"
        aria-controls="confirm-dialog-control"
        aria-label={this.props.actionLabel}
        size="large">
        {this.props.children}
      </IconButton>,
      <Dialog
        sx={{ zIndex: (theme) => theme.zIndex.modal }}
        key="confirm-dialog"
        id="confirm-dialog"
        onClose={() => this.handleCancel()}
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={this.state.isOpen}>
        <DialogTitle>{this.props.confirmationTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{this.props.confirmationText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlinedContrast" autoFocus onClick={this.handleCancel}>
            {this.props.cancelLabel}
          </Button>
          <Button variant="contained" onClick={this.handleConfirm} ref={this.confirmButton}>
            {this.props.confirmLabel}
          </Button>
        </DialogActions>
      </Dialog>
    ];
  }
}

ActionConfirmationMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  actionLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmationText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  confirmationTitle: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  leftIcon: PropTypes.object,
  rightIcon: PropTypes.object,
  style: PropTypes.object
};

ActionConfirmationMenuItem.defaultProps = {
  confirmLabel: 'Ok',
  cancelLabel: 'Anuluj',
  isPrimary: false,
  isSecondary: false,
  isDisabled: false
};

export default ActionConfirmationMenuItem;
