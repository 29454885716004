import onStateChange from 'redux-on-state-change';
import updateRollbarUserTracking from '../utils/updateRollbarUserTracking';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import socketListenerMiddleware from '../middleware/socket/socketListenerMiddleware';
import thunk from 'redux-thunk';

export default function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunk, socketListenerMiddleware, onStateChange(updateRollbarUserTracking))
  );
}
