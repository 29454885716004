import PropTypes from 'prop-types';
import React from 'react';
import { Radio, RadioGroup, FormControlLabel, ListSubheader } from '@mui/material';
import { ChargingCalculationMethod } from '../../../../constants/chargingCalculationMethods';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';

const CalculationMethodOptions = ({ onSelected, defaultSelected }) => {
  return (
    <FieldsetWrapper
      legend={
        <ListSubheader component="span" sx={{ pl: 0, fontSize: 12, color: (theme) => theme.palette.color.contrast }}>
          Sposób naliczania opłat
        </ListSubheader>
      }
      invisible={false}>
      <RadioGroup
        row
        aria-label="metody kalkulacji"
        name="calculationMethod"
        value={defaultSelected || Object.values(ChargingCalculationMethod)[0].value}
        onClick={(e) => onSelected(e.target.value)}>
        {Object.values(ChargingCalculationMethod).map((item) => {
          return (
            // <RadioButton key={item.value} label={item.name} value={}/>
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={<Radio color="primary" />}
              label={item.name}
              labelPlacement="end"
            />
          );
        })}
      </RadioGroup>
    </FieldsetWrapper>
  );
};

CalculationMethodOptions.propTypes = {
  onSelected: PropTypes.func.isRequired,
  defaultSelected: PropTypes.string
};

export default CalculationMethodOptions;
