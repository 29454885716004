import React, { useEffect, useState } from 'react';
import IndividualAttendanceSheetList, {
  actionTypes
} from '../individualPsychologicalClasses/IndividualAtendanceSheetList';
import { useDispatch, useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { fromTemplate, routePaths } from '../../routePaths';
import {
  reportPsychologicalGroupAbsence,
  reportPsychologicalGroupAttendance
} from '../../actions/psychologicalGroupClassesActions';

const PsychologicalChildList = ({ entries, attendance }) => {
  const [attendantChildren, setAttendantChildren] = useState([]);
  const [absenceChildren, setAbsenceChildren] = useState([]);
  const [unregisteredChildren, setUnregisteredChildren] = useState([]);
  const psychologicalChoosenGroup = useSelector((state) => state.psychologicalChoosenGroup);

  const dispatch = useDispatch();

  useEffect(() => {
    const absent = [];
    const present = [];
    const restChildren = [];
    if (
      entries &&
      entries.length > 0 &&
      attendance &&
      attendance.childrenAttendance &&
      attendance.childrenAttendance.length > 0
    ) {
      attendance.childrenAttendance.map((childAttendance) => {
        if (!childAttendance.droppedOffAt && !childAttendance.absenceFrom)
          restChildren.push(entries.find((child) => child.id === childAttendance.child.id));
        if (childAttendance.droppedOffAt) {
          present.push(entries.find((child) => child.id === childAttendance.child.id));
        }
        if (childAttendance.absenceFrom) {
          absent.push(entries.find((child) => child.id === childAttendance.child.id));
        }
      });
    }
    setUnregisteredChildren(restChildren);
    setAbsenceChildren(absent);
    setAttendantChildren(present);
  }, [entries, attendance]);

  const handleReportAttendance = (child) => {
    dispatch(reportPsychologicalGroupAttendance(child, psychologicalChoosenGroup.id));
  };
  const handleReportAbsence = (child) => {
    dispatch(reportPsychologicalGroupAbsence(child, psychologicalChoosenGroup.id));
  };

  const handleRedirect = (entry) => {
    browserHistory.push(fromTemplate(routePaths.childDetails, [entry.id]));
  };
  return (
    <>
      {unregisteredChildren.length > 0 && (
        <IndividualAttendanceSheetList
          isNotPaper
          contrast
          elevation={0}
          searchText=""
          entries={unregisteredChildren}
          onReportAttendance={handleReportAttendance}
          onReportAbsence={handleReportAbsence}
          onCustomRedirect={handleRedirect}
        />
      )}
      {/*childDetails*/}
      <IndividualAttendanceSheetList
        isNotPaper
        contrast
        elevation={0}
        title="Obecne"
        searchText=""
        entries={attendantChildren}
        onReportAttendance={handleReportAttendance}
        onReportAbsence={handleReportAbsence}
        onCustomRedirect={handleRedirect}
        actionTypes={actionTypes}
        actionType={actionTypes.attendant}
      />
      <IndividualAttendanceSheetList
        isNotPaper
        elevation={0}
        contrast
        title="Nieobecne"
        searchText=""
        entries={absenceChildren}
        onReportAttendance={handleReportAttendance}
        onReportAbsence={handleReportAbsence}
        onCustomRedirect={handleRedirect}
        actionTypes={actionTypes}
        actionType={actionTypes.absence}
      />
    </>
  );
  // return (
  //   <List>
  //     {entries.map((entry) => (
  //       <ListItem key={entry.id}>
  //         <ListItemAvatar>
  //           <PersonAvatar initials alt={entry.firstName} firstName={entry.firstName} lastName={entry.lastName} />
  //         </ListItemAvatar>
  //         <ListItemText primary={`${entry.firstName} ${entry.lastName}`} primaryTypographyProps={{ variant: 'h6' }} />
  //       </ListItem>
  //     ))}
  //   </List>
  // );
};

export default PsychologicalChildList;
