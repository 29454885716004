import React from 'react';
import WelcomeScreen from '../../common/welcomeScreen/WelcomeScreen';
import PasswordRecoveryContainer from './Containers/PasswordRecoveryContainer';

const PasswordRecoveryPage = () => {
  return (
    <WelcomeScreen>
      <PasswordRecoveryContainer />
    </WelcomeScreen>
  );
};

export default PasswordRecoveryPage;
