import React, { useState } from 'react';
import { Grid, List, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import ManageCateringApplicationDialog from './ManageCateringApplicationDialog';
import ConfirmationDialog from '../forms/ConfirmationDialog';
import SchemeDescription from '../configuration/chargingSchemes/schemeDescriptions/SchemeDescription';
import { CateringApplicationsHeader } from '../common/applications/ApplicationHeaders';
import { ApplicationItemsWrapper } from '../common/applications/ApplicationWrappers';
import GuardianApplicationItem from './GuardianApplicationItem';

const CateringApplications = (props) => {
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [isOpenManageCateringApplicationDialog, setIsOpenManageCateringApplicationDialog] = useState(false);
  const [isArchiveCateringApplicationDialogOpen, setIsArchiveCateringApplicationDialogOpen] = useState(false);

  const { childApplications, cateringChargingSchemes, applicationsActions, refreshChildApplications, selectedChild } =
    props;

  const onArchiveSelectedApplication = (app) => {
    setSelectedApplication(app);
    setIsArchiveCateringApplicationDialogOpen(true);
  };

  const onAdd = () => {
    setSelectedApplication(null);
    setIsOpenManageCateringApplicationDialog(true);
  };

  const onEdit = (app) => {
    setSelectedApplication(app);
    setIsOpenManageCateringApplicationDialog(true);
  };

  const archiveSelectedApplication = () => {
    applicationsActions.archiveApplication(selectedApplication.id).then(() => {
      setIsArchiveCateringApplicationDialogOpen(false);
      refreshChildApplications();
    });
  };

  const saveCateringApplication = (cateringSchemeId) => {
    const req = {
      schemeId: cateringSchemeId,
      childId: selectedChild.id
    };
    if (selectedApplication === null) {
      applicationsActions.createApplication(req).then(() => {
        refreshChildApplications();
        setIsOpenManageCateringApplicationDialog(false);
      });
    } else {
      applicationsActions.updateApplication(selectedApplication.id, req).then(() => {
        refreshChildApplications();
        setIsOpenManageCateringApplicationDialog(false);
      });
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <CateringApplicationsHeader onAdd={onAdd} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <List>
            <ApplicationItemsWrapper items={childApplications}>
              {childApplications.map((application) => {
                return (
                  <GuardianApplicationItem
                    key={application.id}
                    edit={(app) => onEdit(app)}
                    archive={(app) => onArchiveSelectedApplication(app)}
                    descriptionComponent={<SchemeDescription scheme={application.data} />}
                    application={application}
                  />
                );
              })}
            </ApplicationItemsWrapper>
          </List>
        </Paper>
      </Grid>
      <ManageCateringApplicationDialog
        open={isOpenManageCateringApplicationDialog}
        close={() => setIsOpenManageCateringApplicationDialog(false)}
        cateringChargingSchemes={cateringChargingSchemes}
        childApplications={childApplications}
        selectedApplication={selectedApplication}
        save={saveCateringApplication}
      />
      <ConfirmationDialog
        confirmationText="Czy na pewno chcesz usunąć wniosek?"
        confirmLabel="tak"
        confirmationTitle="Usunięcie wniosku"
        onConfirm={archiveSelectedApplication}
        onCancel={() => setIsArchiveCateringApplicationDialogOpen(false)}
        isOpen={isArchiveCateringApplicationDialogOpen}
      />
    </>
  );
};

CateringApplications.propTypes = {
  selectedChild: PropTypes.object.isRequired,
  childApplications: PropTypes.array.isRequired,
  cateringChargingSchemes: PropTypes.array.isRequired,
  applicationsActions: PropTypes.object.isRequired,
  refreshChildApplications: PropTypes.func.isRequired
};

export default CateringApplications;
