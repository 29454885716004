import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { PaymentMethods } from '../../../constants/paymentMethods';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton, Tooltip, Typography, Box } from '@mui/material';
import EmptyState from '../../common/EmptyState';
import CircularProgress from '@mui/material/CircularProgress';

const getOperationDescription = {
  cashOut: (op) =>
    `Wypłata środków (${op.method === PaymentMethods.cash.value ? 'Wypłata w kasie' : PaymentMethods[op.method].name})`,
  credit: (op) => `Wpłata środków (${PaymentMethods[op.method].name})`,
  creditFromPayment: (op) => `Wycofanie opłaty za należność ${op.dueNumber}`,
  creditFromDueOverpayment: (op) => `Nadpłata za należność ${op.dueNumber}`,
  revertCreditFromDueOverpayment: (op) => `Anulowanie nadpłaty za należność ${op.dueNumber}`,
  cashPayment: (op) => `Opłata za należność ${op.dueNumber} (${PaymentMethods.cash.name})`,
  transferPayment: (op) => `Opłata za należność ${op.dueNumber} (${PaymentMethods.transfer.name})`,
  onlinePayment: (op) => `Opłata za należność ${op.dueNumber} (${PaymentMethods.online.name})`,
  fromBalancePayment: (op) => `Opłata za należność  ${op.dueNumber} (${PaymentMethods.fromBalance.name})`,
  initialFundsSet: () => 'Ustalenie salda',
  dueApproved: (op) => `Zatwierdzenie należności ${op.dueNumber}`,
  dueCanceled: (op) => `Anulowanie należności ${op.dueNumber}`,
  dueTotalReduced: (op) => `Obniżenie opłaty z tytułu nadpłaty ${op.dueNumber}`,
  dueTotalReductionUndone: (op) => `Anulowanie opłaty z tytułu nadpłaty ${op.dueNumber}`
};

function getOperationDate(op) {
  return op.date == null ? '' : moment(op.date).format('YYYY-MM-DD');
}

function getOperationAmount(op) {
  return op.amount == null ? '' : op.amount.toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' });
}

function getFundsBeforeAmount(op) {
  return op.fundsBefore == null ? '' : op.fundsBefore.toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' });
}

function getTransferFundsBeforeAmount(op) {
  return op.fromFundsBefore == null
    ? ''
    : op.fromFundsBefore.toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' });
}

function getFundsAfterAmount(op) {
  return op.fundsAfter == null ? '' : op.fundsAfter.toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' });
}

function getTransferFundsAfterAmount(op) {
  return op.fromFundsBefore == null
    ? ''
    : op.fromFundsAfter.toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' });
}

const BalanceOperationsTablev2 = ({
  personalBalance,
  inProgress,
  onGeneratePayoutConfirmation,
  walletType,
  walletConfig
}) => {
  return (
    <>
      <Typography variant="h6">Historia operacji</Typography>
      {personalBalance.operations.filter((operation) => operation.walletType === walletType).length > 0 ? (
        <Box sx={{ overflowX: 'scroll' }}>
          <Table aria-label="Historia operacji">
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Operacja</TableCell>
                <TableCell>Kwota</TableCell>
                <TableCell>Środki przed</TableCell>
                <TableCell>Środki po</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {personalBalance.operations
                .filter((operation) => operation.walletType === walletType)
                .map((operation, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography>{getOperationDate(operation)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        {operation.type ? (
                          <Typography>{getOperationDescription[operation.type](operation)}</Typography>
                        ) : (
                          <Typography>
                            Przelew między portfelami:{' '}
                            {walletConfig.map((wallet) => (wallet.name === operation.from ? wallet.label : null))}
                            {' -> '}
                            {walletConfig.map((wallet) => (wallet.name === operation.target ? wallet.label : null))}
                          </Typography>
                        )}
                        {operation.type === 'cashOut' ? (
                          <Tooltip title="Wygeneruj potwierdzenie(.pdf)">
                            {inProgress ? (
                              <CircularProgress sx={{ my: 0, mx: 1 }} size={20} />
                            ) : (
                              <IconButton
                                onClick={() => onGeneratePayoutConfirmation(operation.date)}
                                aria-label="Pobierz"
                                size="large">
                                <PictureAsPdfIcon />
                              </IconButton>
                            )}
                          </Tooltip>
                        ) : null}
                      </Box>
                    </TableCell>
                    <TableCell>{getOperationAmount(operation)}</TableCell>
                    <TableCell>
                      {operation.type ? getFundsBeforeAmount(operation) : getTransferFundsBeforeAmount(operation)}
                    </TableCell>
                    <TableCell>
                      {operation.type ? getFundsAfterAmount(operation) : getTransferFundsAfterAmount(operation)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Typography variant="caption" component="span" sx={{ color: (theme) => theme.palette.color.contrast, ml: 2 }}>
            Historia operacji
          </Typography>
        </Box>
      ) : (
        <EmptyState message="Brak historii operacji" contrast />
      )}
    </>
  );
};

BalanceOperationsTablev2.propTypes = {
  personalBalance: PropTypes.object.isRequired,
  inProgress: PropTypes.bool.isRequired,
  onGeneratePayoutConfirmation: PropTypes.func.isRequired,
  walletType: PropTypes.string.isRequired,
  walletConfig: PropTypes.array
};

export default BalanceOperationsTablev2;
