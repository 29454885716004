import { Button, Grid, ListItemAvatar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as attendanceActions from '../../actions/attendanceActions';
import moment from 'moment';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ActivityAvatar from '../common/activities/ActivityAvatar';
import EmptyState from '../common/EmptyState';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ActivitiesAttendanceSheetPageList from './ActivitiesAttendanceSheetPageList';
import ActivityModal from '../common/activities/ActivityModal';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import ContentWrapper from '../common/contentWrapper';
import { SearchBar } from '../common/SearchBar';

const ActivitiesAttendanceSheetPage = () => {
  const dispatch = useDispatch();

  const children = useSelector((state) => state.children);
  const activities = useSelector((state) => state.activities);
  const [searchText, setSearchText] = useState('');

  const [showActivityDialog, setShowActivityDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(activities[0]);
  const [activityAttendance, setActivityAttendance] = useState({});
  const [attendantChildren, setAttendantChildren] = useState([]);
  const [absenceChildren, setAbsenceChildren] = useState([]);
  const [unregisteredChildren, setUnregisteredChildren] = useState([]);

  const date = moment();

  const actionTypes = {
    attendant: 'attendant',
    absence: 'absence'
  };

  useEffect(() => {
    const setVariable = async () => {
      setUnregisteredChildren([]);
      setAbsenceChildren([]);
      setAttendantChildren([]);
      if (children.length > 0 && Object.keys(activityAttendance).length > 0) {
        activityAttendance.childrenAttendance.map((childAttendance) =>
          !childAttendance.droppedOffAt && !childAttendance.absenceFrom
            ? setUnregisteredChildren((prev) => [
                ...prev,
                children.find((child) => child.id === childAttendance.child.id)
              ])
            : null
        );
      }
      if (children.length > 0 && Object.keys(activityAttendance).length > 0) {
        activityAttendance.childrenAttendance.map((childAttendance) =>
          childAttendance.droppedOffAt
            ? setAttendantChildren((prev) => [...prev, children.find((child) => child.id === childAttendance.child.id)])
            : null
        );
      }
      if (children.length > 0 && Object.keys(activityAttendance).length > 0) {
        activityAttendance.childrenAttendance.map((childAttendance) =>
          childAttendance.absenceFrom
            ? setAbsenceChildren((prev) => [...prev, children.find((child) => child.id === childAttendance.child.id)])
            : null
        );
      }
    };
    setVariable();
  }, [children, activityAttendance, selectedActivity]);

  useEffect(() => {
    if (activities && activities.length > 0) {
      setSelectedActivity(activities[0]);
    }

    async function getAttendance() {
      setActivityAttendance(await dispatch(attendanceActions.loadCurrentActivitiesAttendanceAsync(activities[0].id)));
    }

    if (activities.length > 0) {
      getAttendance();
    }
  }, [activities, dispatch]);

  const handleOpenCloseDialog = () => {
    setShowActivityDialog((prev) => !prev);
  };

  const handleChangeActivity = async (activity) => {
    setActivityAttendance(await dispatch(attendanceActions.loadCurrentActivitiesAttendanceAsync(activity.id)));
    setSelectedActivity(activity);
    setShowActivityDialog((prev) => !prev);
  };

  const handleReportAttendance = async (childId) => {
    await dispatch(attendanceActions.reportChildActivityAttendanceAsync(childId, selectedActivity.id, date, date));
    setActivityAttendance(await dispatch(attendanceActions.loadCurrentActivitiesAttendanceAsync(selectedActivity.id)));
  };

  const handleReportAbsence = async (childId) => {
    await dispatch(attendanceActions.reportChildActivityAbsenceAsync(childId, selectedActivity.id, date, date, ''));
    setActivityAttendance(await dispatch(attendanceActions.loadCurrentActivitiesAttendanceAsync(selectedActivity.id)));
  };

  return (
    <>
      <PageHeaderText
        title="Zajęcia dodatkowe"
        additionalInfo={moment().format('DD.MM.YYYY')}
        titleIcon={<EventBusyIcon />}
      />
      <ContentWrapper>
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={12} md={4} lg={6} sx={{ mb: 2 }}>
            <SearchBar
              label="Wyszukaj"
              SearchOnClick={(value) => {
                setSearchText(value.target.value);
              }}
              value={searchText}
              onChange={(value) => {
                setSearchText(value.target.value);
              }}
            />
          </Grid>
          {selectedActivity ? (
            <>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-end' }, px: 2 }}>
                <ListItemAvatar>
                  <ActivityAvatar activity={selectedActivity} />
                </ListItemAvatar>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.color.primary,
                    fontSize: 18,
                    fontWeight: (theme) => theme.typography.fontWeightBold
                  }}>
                  {selectedActivity.name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
                <Button
                  variant="textOutlined"
                  aria-label="Wybierz aktywność"
                  sx={{ p: 'auto', mr: 1, color: (theme) => theme.palette.color.primary }}
                  onClick={() => handleOpenCloseDialog()}>
                  <ChangeCircleOutlinedIcon sx={{ mr: 1 }} />
                  <Typography
                    sx={{
                      textTransform: 'uppercase'
                    }}>
                    Wybierz aktywność
                  </Typography>
                </Button>
              </Grid>
            </>
          ) : null}
        </Grid>

        {unregisteredChildren.length >= 0 || attendantChildren.length >= 0 || absenceChildren.length >= 0 ? (
          <>
            <ActivitiesAttendanceSheetPageList
              searchText={searchText}
              selectedActivity={selectedActivity}
              filterBy={searchText}
              entries={unregisteredChildren}
              onReportAttendance={handleReportAttendance}
              onReportAbsence={handleReportAbsence}
            />
            <ActivitiesAttendanceSheetPageList
              searchText={searchText}
              filterBy={searchText}
              title="Obecne"
              entries={attendantChildren}
              onReportAttendance={handleReportAttendance}
              onReportAbsence={handleReportAbsence}
              actionTypes={actionTypes}
              actionType={actionTypes.attendant}
            />
            <ActivitiesAttendanceSheetPageList
              searchText={searchText}
              filterBy={searchText}
              title="Nieobecne"
              entries={absenceChildren}
              onReportAttendance={handleReportAttendance}
              onReportAbsence={handleReportAbsence}
              actionTypes={actionTypes}
              actionType={actionTypes.absence}
            />
          </>
        ) : (
          <EmptyState isPaper contrast message="Nie przypisano żadnych dzieci dla wybranych zajęć dodatkowych" />
        )}
        <ActivityModal
          selectedActivity={selectedActivity}
          onSelectActivity={handleChangeActivity}
          isDialogOpen={showActivityDialog}
          changeDialogState={handleOpenCloseDialog}
        />
      </ContentWrapper>
    </>
  );
};

export default ActivitiesAttendanceSheetPage;
