import { ArchivalInfoModel } from '../ArchivalInfoModel';
import BaseModel from '../BaseModel';

export class MealModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.color = '';
    this.categoryId = '';
    this.categoryName = '';
  }
}

export class MealDetailsModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.color = '';
    this.archivalInfo = new ArchivalInfoModel();
    // this.enrolledChildren = [];
  }
}

export class ArchivedMealModel extends MealDetailsModel {}

export class MealCategory extends BaseModel {
  constructor() {
    super();
    this.name = '';
    this.meals = [];
    this.id = '';
  }
}
