import PropTypes from 'prop-types';
import React from 'react';
import WaypointScroll from '../../common/lazyLoaders/WaypointScroll';
import ThreadHeader from './ThreadHeader';
import ThreadMultiHeader from './ThreadMultiHeader';
import List from '@mui/material/List';
import { Box, CircularProgress, useTheme } from '@mui/material';

const style = {
  refresh: {
    display: 'inline-block',
    position: 'relative'
  }
};

const ThreadsList = ({
  threads,
  authUser,
  selectUser,
  onLoadMore,
  canLoadMore,
  showLastMessage,
  isLoading,
  onSelect
}) => {
  const theme = useTheme();
  return (
    <WaypointScroll
      canLoadMore={canLoadMore}
      onLoadMore={onLoadMore}
      isLoading={isLoading}
      isInitial={true}
      sx={{ outline: 0, overflowX: 'hidden', height: 'auto' }}
      loadIndicator={
        <Box sx={{ flexDirection: 'row', justifyContent: 'center', display: 'flex' }}>
          <CircularProgress
            size={30}
            left={10}
            top={0}
            status="loading"
            sx={style.refresh}
            loadingColor={theme.palette.augmentColor.color4}
          />
        </Box>
      }>
      <List>
        {threads.map((thread) => {
          const user = selectUser(thread);
          return thread.participants.length > 2 ? (
            <ThreadMultiHeader
              groupThread
              key={thread.threadId}
              showLastMessage={showLastMessage}
              user={authUser}
              thread={thread}
              onSelect={onSelect}
            />
          ) : user ? (
            <ThreadHeader key={thread.threadId} thread={thread} user={user} onSelect={onSelect} />
          ) : (
            <ThreadMultiHeader key={thread.threadId} thread={thread} onSelect={onSelect} />
          );
        })}
      </List>
    </WaypointScroll>
  );
};

ThreadsList.propTypes = {
  threads: PropTypes.array.isRequired,
  selectUser: PropTypes.func,
  canLoadMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSelect: PropTypes.func
};

export default ThreadsList;
