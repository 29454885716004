/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ChildDaycareModel } from '../models/daycare/DaycareModels';

export default function childDaycareReducer(state = initialState.childDaycare, action) {
  switch (action.type) {
    case types.LOAD_CHILD_DAYCARE_SUCCESS: {
      return new ChildDaycareModel().assign(action.daycareEntries);
    }
    case types.UPDATE_CHILD_DAYCARE_SUCCESS: {
      if (state.childId === action.entry.childId) {
        return Object.assign({}, state, {
          entries: [...state.entries.filter((e) => e.id !== action.entry.id), action.entry]
        });
      }
      return state;
    }
    default:
      return state;
  }
}
