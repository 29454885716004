import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as settlementsActions from '../../../actions/settlementsActions';
import * as definedSortTypes from '../../../constants/definedSortTypes';
import { SettlementModel } from '../../../models/settlements/SettlementModels';
import FilePickerWithRef from '../../common/FilePickerWithRef/FilePickerWithRef';
import ImportIcon from '@mui/icons-material/CloudUpload';
import CheckIcon from '@mui/icons-material/Check';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import CorrectPaymentsTable from './Table/CorrectPaymentsTable';
import IncorrectPaymentsList from './Table/IncorrectPaymentsList';
import PropTypes from 'prop-types';
import * as notificationActions from '../../../actions/notificationActions';
import FlatButton from '../../common/FlatButton';
import SelectField from '../../common/SelectField';
import PartlyCorrectPaymentsTable from './Table/PartlyCorrectPaymentsTable';
import ContentWrapper from '../../common/contentWrapper';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CardActionArea,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  MenuItem,
  Paper,
  Typography
} from '@mui/material';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import { Box } from '@mui/system';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const banks = [
  { value: 'PEKAOSA', label: 'Pekao S.A.' },
  { value: 'PKOBP', label: 'PKO Bank Polski' },
  { value: 'ING', label: 'ING Bank Śląski' },
  { value: 'SANTANDER', label: 'Santander Bank Polska' },
  { value: 'SGB', label: 'SGB-Bank' },
];

const schemes = [
  { value: 'GZZ', label: 'NR/NR_PLACOWKI/ROK' },
  { value: 'PZZ', label: 'SYMBOL_ZLOBKA.NR_WEWNETRZNY.NR.ROK' }
];

const SettlementsImport = () => {
  const fileRef = React.createRef();
  const dispatch = useDispatch();

  const fileStateOptions = {
    none: 'none',
    inProgress: 'inProgress',
    loaded: 'loaded',
    importing: 'importing',
    imported: 'imported'
  };

  const [file, setFile] = useState();
  const [fileState, setFileState] = useState(fileStateOptions.none);
  const [errors, setErrors] = useState([]);
  const [fileText, setFileText] = useState();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState({ property: 'name', type: definedSortTypes.STRING_SORT });
  const [correctData, setCorrectData] = useState();
  const [correctDataPayload, setCorrectDataPayload] = useState([]);
  const [incorrectDataPayload, setIncorrectDataPayload] = useState([]);
  const [partlyCorrectData, setPartlyCorrectData] = useState();
  const [selectedPartlyCorrectData, setSelectedPartlyCorrectData] = useState([]);
  const [partlyCorrectDataPayload, setPartlyCorrectDataPayload] = useState([]);
  const [finalPayload, setFinalPayload] = useState([]);
  const [allSettlements, setAllSettlements] = useState([]);
  const [bankName, setBankName] = useState(banks[0].value);
  const [childNumberScheme, setChildNumberScheme] = useState(schemes[0].value);
  const [partlyCorrectDataOpen, setPartlyCorrectDataOpen] = useState(true);

  const handleBankNameChange = (event) => {
    setBankName(event.target.value);
  };

  const handleChildNumberSchemeChange = (event) => {
    setChildNumberScheme(event.target.value);
  };

  // const settlementsList = useSelector((state) => state.settlements);
  const templateURL = 'https://4parentsprod.blob.core.windows.net/assets/wzorzec.png';

  const handleAddFile = (f) => {
    setFile(f);
  };

  const handleLoadAllSettlements = async () => {
    setAllSettlements(await dispatch(settlementsActions.loadAllSettlements()));
  };

  const handleValidateFile = async () => {
    setErrors([]);
    setFileState(fileStateOptions.inProgress);
    try {
      setFileText(await dispatch(settlementsActions.validateMt940File(file, bankName, childNumberScheme)));
      setFileState(fileStateOptions.loaded);
    } catch (error) {
      setErrors(error);
      setFileState(fileStateOptions.none);
      dispatch(notificationActions.showError('Wprowadzone dane są niepoprawne.'));
    }
  };

  const handleRequestSort = (event, property, type) => {
    const isAsc = orderBy.property === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy({ property, type });
  };

  const handleSendData = async () => {
    setFileState(fileStateOptions.importing);
    try {
      await dispatch(settlementsActions.importMt940File(finalPayload));
      setFileState(fileStateOptions.imported);
    } catch (error) {
      dispatch(notificationActions.showError(error.response.body.generalError));
      setFileState(fileStateOptions.loaded);
    }
  };

  const handleChangePartialData = (data) => {
    if (Object.keys(data).length > 0 && data.person) {
      const temp = selectedPartlyCorrectData;
      let index = -1;
      temp.forEach((element, arrIndex) => {
        if (element.person.id === data.person.id) {
          index = arrIndex;
        }
      });
      if (index === -1) {
        temp.push({ ...data });
      }
      setSelectedPartlyCorrectData([...temp]);
    }
  };

  const handleErrors = (id, state) => {
    const err = errors;
    if (state === true) {
      if (!errors.includes(id)) {
        err.push(id);
      }
    } else {
      err.splice(err.indexOf(id), 1);
    }
    setErrors([...err]);
  };

  const handleDisplayTemplate = () => {
    window.open(templateURL, '_blank');
  };

  useEffect(() => {
    if (selectedPartlyCorrectData.length > 0) {
      const temp = [];
      selectedPartlyCorrectData.forEach((partlyCorrect) => {
        if (partlyCorrect.body.payments.length > 0) {
          temp.push({ body: partlyCorrect.body, person: partlyCorrect.person });
        }
      });
      setPartlyCorrectDataPayload([...temp]);
    }
  }, [JSON.stringify(selectedPartlyCorrectData)]);

  useEffect(() => {
    setFinalPayload({ correctPaymentsData: correctDataPayload.concat(partlyCorrectDataPayload) });
  }, [JSON.stringify(partlyCorrectDataPayload), JSON.stringify(correctDataPayload)]);

  useEffect(() => {
    if (file) {
      handleLoadAllSettlements();
      setCorrectData();
      setCorrectDataPayload([]);
      setIncorrectDataPayload([]);
      setPartlyCorrectData();
      setPartlyCorrectDataPayload([]);
      setPartlyCorrectData([]);
      setPartlyCorrectDataPayload([]);
      setErrors([]);
      handleValidateFile();
    }
  }, [file]);

  useEffect(() => {
    if (fileText && fileText.correctPaymentsData) {
      fileText.correctPaymentsData.map((correctPayment) =>
        allSettlements.map((settlement) =>
          correctPayment.body.payments.map((payment) =>
            settlement.id === payment.dueId
              ? setCorrectData((prev) =>
                  prev
                    ? [
                        ...prev,
                        new SettlementModel().assign({
                          ...settlement,
                          name: correctPayment.person.lastName + ' ' + correctPayment.person.firstName,
                          paymentAmount: payment.amount
                        })
                      ]
                    : [
                        new SettlementModel().assign({
                          ...settlement,
                          name: correctPayment.person.lastName + ' ' + correctPayment.person.firstName,
                          paymentAmount: payment.amount
                        })
                      ]
                )
              : null
          )
        )
      );
      let newArray = fileText.correctPaymentsData;
      newArray = newArray.filter((arrayElement) =>
        arrayElement.body.payments.some((element) =>
          allSettlements.some((settlement) => settlement.id === element.dueId)
        )
      );
      setCorrectDataPayload(newArray);
    }
    if (fileText && fileText.incorrectPaymentsData) {
      setIncorrectDataPayload(fileText.incorrectPaymentsData);
    }
    if (fileText && fileText.partlyCorrectPaymentsData) {
      const newArray = fileText.partlyCorrectPaymentsData;
      newArray.forEach((arrayElement, arrIndex) =>
        arrayElement.dues.forEach((due, dueIndex) => {
          newArray[arrIndex].dues[dueIndex] = new SettlementModel().assign({
            ...newArray[arrIndex].dues[dueIndex],
            name: due.person.lastName + ' ' + due.person.firstName
          });
        })
      );
      setPartlyCorrectData(newArray);
    }
  }, [fileText]);

  useEffect(() => {
    handleLoadAllSettlements();
  }, []);

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title="Import MT940" titleIcon={<ImportIcon />} />

        <ContentWrapper>
          <Paper sx={{ p: 3 }}>
            <Box>
              {fileState === fileStateOptions.importing || fileState === fileStateOptions.inProgress ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                  <Typography fontWeight="bold">Trwa wczytywanie pliku, proszę czekać...</Typography>
                  <CircularProgress sx={{ m: 5 }} />
                </Box>
              ) : null}
              {fileState === fileStateOptions.none && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <SelectField
                      sx={{ width: '100%' }}
                      contrast
                      floatingLabelText="Nazwa banku"
                      value={bankName}
                      onChange={handleBankNameChange}>
                      {banks.map((bank) => (
                        <MenuItem key={bank.value} value={bank.value}>
                          {bank.label}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectField
                      sx={{ width: '100%' }}
                      contrast
                      floatingLabelText="Schemat numeru umowy"
                      value={childNumberScheme}
                      onChange={handleChildNumberSchemeChange}>
                      {schemes.map((scheme) => (
                        <MenuItem key={scheme.value} value={scheme.value}>
                          {scheme.label}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                </Grid>
              )}
              {fileState === fileStateOptions.imported ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                  <Typography fontWeight="bold">Pomyślnie zaimportowano dane</Typography>
                  <CheckIcon
                    sx={{
                      color: (theme) => theme.palette.color.color12,
                      transform: 'scale(2)',
                      m: 5
                    }}
                  />
                </Box>
              ) : null}
              {fileState === fileStateOptions.loaded && correctData ? (
                <CorrectPaymentsTable
                  data={correctData}
                  order={order}
                  orderBy={orderBy}
                  handleRequestSort={handleRequestSort}
                />
              ) : null}

              {fileState === fileStateOptions.loaded && partlyCorrectData && partlyCorrectData.length > 0 ? (
                <>
                  <Accordion sx={{ '&:hover': { background: (theme) => theme.palette.background.hover } }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                        Częściowo poprawne płatności
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {partlyCorrectData.map((partlyCorrect, index) => (
                        <PartlyCorrectPaymentsTable
                          key={index}
                          data={partlyCorrect}
                          order={order}
                          orderBy={orderBy}
                          handleRequestSort={handleRequestSort}
                          onChangePartialData={handleChangePartialData}
                          onValidateErrors={handleErrors}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </>
              ) : null}
              {fileState === fileStateOptions.loaded && incorrectDataPayload.length > 0 ? (
                <IncorrectPaymentsList incorrectPayments={incorrectDataPayload} />
              ) : null}
              {errors.length > 0
                ? errors.map((error, index) => (
                    <Typography
                      variant="subtitle2"
                      key={index}
                      sx={{ color: (theme) => theme.palette.color.color10, m: 1 }}>
                      {error.errorMessage}
                    </Typography>
                  ))
                : null}
              <Box sx={{ display: 'flex', justifyContent: { md: 'end', xs: 'space-around' }, flexWrap: 'wrap', mt: 3 }}>
                {fileState === fileStateOptions.none && (
                  <FlatButton sx={{ mb: 2 }} label="Wyświetl wzorzec" onClick={handleDisplayTemplate} />
                )}
                <Button
                  sx={{ mb: 2, color: (theme) => theme.palette.color.wcag }}
                  onClick={() => fileRef.current.open()}
                  variant="outlinedContrast"
                  aria-label="Wgraj plik MT940">
                  Wgraj plik MT940
                </Button>
                <Button
                  aria-label="Zatwierdź"
                  variant="outlinedContrast"
                  disabled={
                    errors.length > 0 ||
                    fileState === fileStateOptions.none ||
                    fileState === fileStateOptions.importing ||
                    fileState === fileStateOptions.inProgress ||
                    fileState === fileStateOptions.imported
                  }
                  onClick={() => handleSendData()}
                  sx={{ mb: 2, color: (theme) => theme.palette.color.wcag }}>
                  Zatwierdź
                </Button>
              </Box>
              <FilePickerWithRef ref={fileRef} onDrop={(f) => handleAddFile(f)} labelId="mt940" />
            </Box>
          </Paper>
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};

SettlementsImport.propTypes = {
  params: PropTypes.object
};

export default SettlementsImport;
