import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import { ListItemText } from '@mui/material';
import { create } from '../../../../utils/AvatarContentGenerator';

const useStyles = makeStyles((theme) => ({
  style: {
    background: ({ color }) => color
  },
  chip: {
    border: ({ color }) => `2px solid ${color || theme.palette.color.color25}`,
    maxWidth: '100%'
  },
  icon: {
    color: ({ color }) => color || theme.palette.color.color25,
    sx: { width: '5px' }
  },
  label: {
    whiteSpace: ({ wrapToNextLine }) => (wrapToNextLine ? 'pre-wrap' : 'nowrap')
  }
}));
const SingleAuthorChip = ({ firstName, fullName, lastName, customClass, onDelete, wrapToNextLine, style }) => {
  const isContrastColor = useSelector((state) => state.contrastColor);
  const effectiveLastName = lastName || (fullName ? fullName.split(' ')[1] : '') || '';
  const effectiveFirstName = firstName || (fullName ? fullName.split(' ')[0] : '') || '';

  const cl = useStyles({
    color: isContrastColor ? null : create().generateColorFromName(effectiveLastName + ' ' + effectiveFirstName),
    wrapToNextLine
  });
  return (
    <Chip
      label={
        <ListItemText
          primary={`${lastName} ${firstName}  `}
          primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
        />
      }
      className={`${cl.chip} ${customClass}`}
      variant="outlined"
      onDelete={onDelete}
      classes={{ label: cl.label }}
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      style={style}
    />
  );
};

SingleAuthorChip.propTypes = {
  firstName: PropTypes.string,
  fullName: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  customClass: PropTypes.object,
  onDelete: PropTypes.func,
  wrapToNextLine: PropTypes.bool,
  style: PropTypes.object
};

export default SingleAuthorChip;
