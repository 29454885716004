import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import SpecificIcon from './SpecificIcon';
import * as AlbumTypes from '../../constants/groupsAndActivitiesTypes';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import { ListItem, Typography, Box } from '@mui/material';
import SingleMemberChip from '../gallery/Components/Albums/SingleMemberChip';
import Avatar from '@mui/material/Avatar';
import RichTooltip from '../richTooltip/richTooltip';
import ArchiveIcon from '@mui/icons-material/Archive';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: ({ color }) => color || theme.palette.color.color4
  },
  button: {
    textTransform: 'none'
  },
  disabledButton: {
    pointerEvents: 'auto !important',
    color: 'inherit !important'
  },
  outlined: {
    border: 'none',
    '&.Mui-disabled': {}
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  chipListItem: {
    padding: 2,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
  },
  avatar: {
    width: ({ tiny }) => (tiny ? 20 : 25),
    height: ({ tiny }) => (tiny ? 20 : 25),
    fontSize: 12,
    display: 'inline-flex',
    background: theme.palette.color.color4
  },
  membershipWrapper: {
    display: 'flex',
    alignContent: 'center',
    marginLeft: 10
  }
}));

const SpecialButton = ({
  data,
  type,
  isError,
  defaultLabel,
  multiple = false,
  tiny = false,
  archiveChild,
  disabled,
  ...rest
}) => {
  const isContrastColor = useSelector((state) => state.contrastColor);
  const [open, setOpen] = React.useState(false);

  let tempData = data;
  if (multiple && data && data.length === 1) tempData = data[0];

  const showHeader = (count) => {
    switch (type) {
      case AlbumTypes.GROUP:
        return count <= 1 ? 'Przypisana grupa:' : 'Przypisane grupy';
      case AlbumTypes.ACTIVITY:
        return count <= 1 ? 'Przypisane zajęcie dodatkowe' : 'Przypisane zajęcia dodatkowe';
      default:
        return 'Album przedszkolny';
    }
  };

  const cl = useStyles({ color: isContrastColor ? null : tempData && tempData.color, isError, tiny, isContrastColor });
  if (multiple && tempData && tempData.length > 1) {
    return (
      <Button
        className={cl.button}
        variant="text"
        classes={{ disabled: cl.disabledButton }}
        component={disabled && 'div'}
        disabled={disabled}
        startIcon={
          <SpecificIcon
            type={type === AlbumTypes.GROUP ? AlbumTypes.MORE_GROUPS : AlbumTypes.MORE_ACTIVITIES}
            style={{ fontSize: tiny ? 40 : 48 }}
            className={cl.icon}
          />
        }
        {...rest}>
        <Typography sx={{ fontSize: 16, mt: 0.5 }}>Wybrano</Typography>
        <Box onMouseEnter={() => setOpen(!open)} onMouseLeave={() => setOpen(!open)} className={cl.membershipWrapper}>
          <RichTooltip
            content={
              <List
                id={open ? 'details-list' : null}
                className={cl.list}
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {showHeader(tempData.length)}
                  </ListSubheader>
                }>
                {Array.isArray(tempData) &&
                  tempData
                    .sort((a, b) => a.name.length - b.name.length)
                    .map((item) => {
                      return (
                        <ListItem component="li" key={item.id} className={cl.chipListItem}>
                          <SingleMemberChip color={item.color} type={type} name={item.name} />
                        </ListItem>
                      );
                    })}
              </List>
            }
            open={open}
            placement="top"
            onClose={() => setOpen(false)}>
            <Avatar className={cl.avatar} sx={{ pt: '4px' }}>
              {tempData.length}
            </Avatar>
          </RichTooltip>
        </Box>
      </Button>
    );
  }
  if (archiveChild) {
    return (
      <Button
        variant="text"
        color="secondary"
        component={disabled && 'div'}
        className={`${cl.button} ${cl.archiveButton}`}
        classes={{ disabled: cl.disabledButton }}
        disabled={disabled}
        startIcon={<ArchiveIcon type={type} color="secondary" />}
        {...rest}>
        <Typography variant="mediumLarge">Archiwizacja!</Typography>
      </Button>
    );
  }

  if (!tempData || (Array.isArray(tempData) && tempData.length === 0)) {
    return (
      <Button
        variant="text"
        className={`${cl.defaultButton} ${cl.button}`}
        classes={{ disabled: cl.disabledButton }}
        aria-label={`${defaultLabel}`}
        component={disabled && 'div'}
        disabled={disabled}
        startIcon={<SpecificIcon type={type} color="primary" />}
        {...rest}>
        <Typography sx={{ fontSize: 16 }}>{defaultLabel}</Typography>
      </Button>
    );
  }

  return (
    <Button
      variant="text"
      className={`${cl.singleButton} ${cl.button}`}
      classes={{ disabled: cl.disabledButton, outlined: cl.outlined }}
      aria-label={`Wybrano ${tempData.name}`}
      component={disabled && 'div'}
      disabled={disabled}
      startIcon={<SpecificIcon type={tempData.id === 'none' ? AlbumTypes.NONE : type} className={cl.icon} />}
      {...rest}>
      <Typography sx={{ fontSize: 16 }}>{tempData.name}</Typography>
    </Button>
  );
};

SpecialButton.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  defaultLabel: PropTypes.string,
  isError: PropTypes.bool,
  multiple: PropTypes.bool,
  tiny: PropTypes.bool,
  type: PropTypes.string,
  archiveChild: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SpecialButton;
