/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ChargingSchemeModel } from '../models/configuration/chargingSchemes/ChargingSchemeModels';

export default function chargingSchemeReducer(state = initialState.configuration.chargingSchemes, action) {
  switch (action.type) {
    case types.LOAD_CHARGING_SCHEMES_SUCCESS: {
      return action.schemes.map((scheme) => ChargingSchemeModel.create(scheme));
    }
    case types.CREATE_CHARGING_SCHEME_DETAILS_SUCCESS: {
      const scheme = ChargingSchemeModel.create(action.scheme);
      return [...state, scheme];
    }
    case types.UPDATE_CHARGING_SCHEME_DETAILS_SUCCESS: {
      const scheme = ChargingSchemeModel.create(action.scheme);
      return [...state.filter((g) => g.id !== scheme.id), scheme];
    }
    case types.ARCHIVE_CHARGING_SCHEME_DETAILS_SUCCESS: {
      return [...state.filter((g) => g.id !== action.schemeId)];
    }
    default:
      return state;
  }
}
