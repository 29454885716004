/*eslint indent:0*/
import initialState from './initial';
import { LOAD_REPORTS_CONFIGURATION_SUCCESS, UPDATE_REPORTS_CONFIGURATION_SUCCESS } from '../actions/actionTypes';
import { ReportModel } from '../models/configuration/reports/ReportModel';

export default function reportsConfigurationReducer(state = initialState.configuration.reportsConfiguration, action) {
  switch (action.type) {
    case LOAD_REPORTS_CONFIGURATION_SUCCESS: {
      return action.reports.map((report) => new ReportModel().assign(report));
    }
    case UPDATE_REPORTS_CONFIGURATION_SUCCESS: {
      return action.reports.map((report) => new ReportModel().assign(report));
    }
    default:
      return state;
  }
}
