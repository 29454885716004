import initialState from './initial';
import * as types from '../actions/actionTypes';
import { ChildDetailsModel } from '../models/children/ChildModels';

export default function childrenTakenOverByDebtCollection(
  state = initialState.childrenTakenOverByDebtCollection,
  action
) {
  switch (action.type) {
    case types.GET_CHILDREN_TAKEN_OVER_BY_DEBT_COLLECTION_SUCCESS: {
      return action.response.map((child) => new ChildDetailsModel().assign(child));
    }
    case types.REMOVE_TAKEN_OVER_BY_DEBT_COLLECTION_CHILDREN_SUCCESS: {
      return [...state.filter((child) => !action.response.includes(child.id))];
    }
    default:
      return state;
  }
}
