import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { FilterByRoleType } from '../../../components/common/filters/filterByRole/FilterByRole';
import userRoles from '../../../constants/userRoles';
import * as GlobalTypes from '../../../constants/groupsAndActivitiesTypes';
import { prepareStaffMemberList } from '../helpers/prepareStaffMemberList';
import { groupByKey } from '../helpers/groupByKey';
import { ShareFileFormFields, SHARE_DETAILS_OPTIONS } from './ShareFileFormContainer';
import { useField, useFormikContext } from 'formik';
import { shareAvailableListFilter } from '../helpers/shareAvailableListFilter';
import AvailableUsersContent from '../components/ShareToUsers/UsersContent/AvailableUsersContent';
import SelectedUsersContent from '../components/ShareToUsers/UsersContent/SelectedUsersContent';
import { useShareFileContext } from '../context/ShareFileContext';

const ShareFileToUsersContainer = ({ onCloseDialog, selectedDetailsSharingOption }) => {
  const { isMobile } = useShareFileContext();

  const [searchText, setSearchText] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [filterBy, setFilterBy] = useState(FilterByRoleType.ALL);
  const [availableList, setAvailableList] = useState([]);
  const [selectedUserWithGroupArray, setSelectedUserWithGroupArray] = useState([]);

  const legalGuardians = useSelector((state) => state.legalGuardians);
  const groups = useSelector((state) => state.groups);
  const staffMembers = useSelector((state) => state.staffMembers);
  const user = useSelector((state) => state.auth);

  const availableGroups = useMemo(() => {
    if (user.userRole === userRoles.staffMemberTeacher) {
      return staffMembers.find((sm) => sm.id === user.userId).groups;
    }
    return groups;
  }, [user.userId, user.userRole, groups, staffMembers]);

  const { setFieldValue } = useFormikContext();
  const [field] = useField(ShareFileFormFields.shareForUserIds);
  const shareForUserIds = field.value;

  const preparedUsersList = useMemo(() => {
    const groupList = availableGroups.map((group) => ({
      title: `${group.name} - Opiekunowie prawni`,
      groupId: group.id,
      color: group.color,
      type: GlobalTypes.GROUP,
      users: legalGuardians.filter((item) => item.children.some((child) => child.groupId === group.id))
    }));

    const legalGuardiansWithoutGroupList = {
      title: 'Opiekunowie prawni, których dziecko nie jest przypisane do żadnej z grup',
      type: userRoles.legalGuardian,
      users: legalGuardians.filter((item) => !item.children.some((child) => child.groupId))
    };

    const staffMemberGroupedList = groupByKey(staffMembers, 'role');

    const preparedStaffMembersList = Object.entries(staffMemberGroupedList).map(([key, value]) =>
      prepareStaffMemberList(key, value)
    );

    let userList = [...groupList, legalGuardiansWithoutGroupList, ...preparedStaffMembersList];
    if (user.userRole === userRoles.staffMemberTeacher) userList = [...groupList, ...preparedStaffMembersList];

    return userList
      .filter((value) => value !== undefined)
      .map((item) => ({
        ...item,
        users: item.users.filter((user) => user.id !== user.userId)
      }));
  }, [groups, legalGuardians, staffMembers, user.userId]);

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  const handleSelectGroup = (value) => {
    setSelectedGroup(value);
  };

  const handleResetFilters = () => {
    setSelectedGroup('');
    setFilterBy(FilterByRoleType.ALL);
  };

  const handleResetGroups = () => {
    setSelectedGroup('');
  };

  const handleFilterBy = (e) => {
    const newValue = e.target.value;
    setFilterBy(newValue);
    setSelectedGroup(
      newValue !== FilterByRoleType.LEGAL_GUARDIAN && newValue !== FilterByRoleType.TEACHER ? '' : selectedGroup
    );
  };

  const handleSingleAction = (id, isRemoveAction, groupId) => {
    if (isRemoveAction) {
      setFieldValue(ShareFileFormFields.shareForUserIds, new Set([...shareForUserIds].filter((item) => item !== id)));
      if (groupId) {
        setSelectedUserWithGroupArray(selectedUserWithGroupArray.filter((item) => item.id !== id));
      }
    } else {
      setFieldValue(ShareFileFormFields.shareForUserIds, new Set([...shareForUserIds, id]));
      if (groupId) {
        setSelectedUserWithGroupArray([...selectedUserWithGroupArray, { groupId, id }]);
      }
    }
  };

  const handleMultiAction = (ids, isRemoveAction, groupId) => {
    if (isRemoveAction) {
      setFieldValue(
        ShareFileFormFields.shareForUserIds,
        new Set([...shareForUserIds].filter((item) => !ids.some((id) => id === item)))
      );

      if (groupId) {
        setSelectedUserWithGroupArray(selectedUserWithGroupArray.filter((item) => !ids.includes(item.id)));
      }
    } else {
      setFieldValue(ShareFileFormFields.shareForUserIds, new Set([...shareForUserIds, ...ids]));
      if (groupId) {
        setSelectedUserWithGroupArray([
          ...selectedUserWithGroupArray,
          ...ids.map((id) => ({
            groupId,
            id
          }))
        ]);
      }
    }
  };

  const handleResetSelectedList = () => {
    setFieldValue(ShareFileFormFields.shareForUserIds, new Set());
    setSelectedUserWithGroupArray([]);
  };

  useEffect(() => {
    const tempArray = preparedUsersList.map((item) => ({
      ...item,
      users: item.users.filter((user) => ![...shareForUserIds].includes(user.id))
    }));
    const filteredList = shareAvailableListFilter(searchText, filterBy, selectedGroup, tempArray);
    setAvailableList(filteredList);
  }, [shareForUserIds, filterBy, searchText, selectedGroup, preparedUsersList]);

  useEffect(() => {
    let tempArray = [];
    [...shareForUserIds].forEach((id) => {
      const group = preparedUsersList.find((item) => item.users.some((user) => user.id === id));
      tempArray = [...tempArray, { groupId: group?.groupId, id }];
    });
    setSelectedUserWithGroupArray(tempArray);
  }, []);

  const selectedList = preparedUsersList.map((item) => {
    if (item.groupId) {
      const tempArray = selectedUserWithGroupArray.filter((userWithGroup) => item.groupId === userWithGroup.groupId);

      return {
        ...item,
        users: item.users.filter((user) => tempArray.some((x) => x.id === user.id))
      };
    }

    return {
      ...item,
      users: item.users.filter((user) => [...shareForUserIds].includes(user.id))
    };
  });

  const areFiltersInUse = searchText.length !== 0 || filterBy !== FilterByRoleType.ALL;

  return (
    <Grid container style={{ flex: 1, minHeight: 0 }}>
      {((isMobile && selectedDetailsSharingOption === SHARE_DETAILS_OPTIONS.availableScreen) || !isMobile) && (
        <AvailableUsersContent
          searchText={searchText}
          groups={availableGroups}
          filterBy={filterBy}
          selectedGroup={selectedGroup}
          onSearch={handleSearch}
          onFilterBy={handleFilterBy}
          onClearSearch={handleClearSearch}
          onSelectGroup={handleSelectGroup}
          onResetFilters={handleResetFilters}
          onResetGroups={handleResetGroups}
          availableList={availableList}
          onSingleAction={handleSingleAction}
          onMultiAction={handleMultiAction}
          areFiltersInUse={areFiltersInUse}
        />
      )}
      {((isMobile && selectedDetailsSharingOption === SHARE_DETAILS_OPTIONS.selectedScreen) || !isMobile) && (
        <SelectedUsersContent
          selectedList={selectedList}
          onSingleAction={handleSingleAction}
          onMultiAction={handleMultiAction}
          onCloseDialog={onCloseDialog}
          onResetSelectedList={handleResetSelectedList}
        />
      )}
    </Grid>
  );
};

ShareFileToUsersContainer.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  selectedDetailsSharingOption: PropTypes.string.isRequired
};

export default ShareFileToUsersContainer;
