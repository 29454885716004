import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as attendanceActions from '../../../actions/attendanceActions';
import moment from 'moment';
import EventsCalendarIcon from '@mui/icons-material/Event';
import ReportAbsenceDialog from '../../attendanceSheet/reportDialogs/ReportAbsenceDialog';
import ChildInfoCard from './childInfoCard/ChildInfoCard';
import BoardPostCard from '../../messageBoard/post/BoardPostCard';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import _ from 'lodash';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ManualLink from '../../ManualLink';
import { Box, Grid, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Masonry from '@mui/lab/Masonry';
import FooterText from '../../footer/FooterText';

class GuardianDashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChild: null
    };
    this.handleDialog = this.handleDialog.bind(this);
    this.renderAbsenceDialog = this.renderAbsenceDialog.bind(this);
    this.renderMessageBoard = this.renderMessageBoard.bind(this);
    this.handleChildAbsenceDialogCancel = this.handleChildAbsenceDialogCancel.bind(this);
    this.handleChildAbsenceDialogSave = this.handleChildAbsenceDialogSave.bind(this);
  }

  handleDialog(child) {
    this.setState({
      selectedChild: child
    });
    this.props.reportChildAbsenceStart();
  }

  handleChildAbsenceDialogCancel() {
    this.props.reportChildAbsenceCancel();
  }

  handleChildAbsenceDialogSave(data) {
    this.props.reportChildAbsence(
      data.childId,
      data.absenceFrom,
      data.absenceTo,
      data.absenceNotes,
      data.absenceReportedAt
    );
  }

  renderAbsenceDialog() {
    return (
      <ReportAbsenceDialog
        pupilFirstName={this.state.selectedChild.firstName}
        pupilLastName={this.state.selectedChild.lastName}
        pupilPhotoUrl={this.state.selectedChild.photoUrl}
        pupilContract={this.state.selectedChild.contract}
        absenceFrom={moment()}
        absenceTo={moment()}
        minDate={moment().toDate()}
        absenceNotes=""
        isAbsenceFromBlocked={false}
        isAbsenceToBlocked={false}
        isProcessing={this.props.childrenAbsenceUi.isProcessing}
        isDialogOpen={this.props.childrenAbsenceUi.isEditing}
        onCancel={this.handleChildAbsenceDialogCancel}
        onSave={({ absenceFrom, absenceTo, absenceNotes, absenceReportedAt }) =>
          this.handleChildAbsenceDialogSave({
            childId: this.state.selectedChild.id,
            absenceFrom,
            absenceTo,
            absenceNotes,
            absenceReportedAt
          })
        }
        statePathToUi="legalGuardianDashboardUi.childrenAbsence"
      />
    );
  }

  renderDialog() {
    if (this.props.childrenAbsenceUi.isEditing) {
      return this.renderAbsenceDialog();
    }
    return null;
  }

  renderMessageBoard(messageBoard) {
    return (
      <Masonry columns={{ xs: 1, md: 2, lg: 3 }} spacing={4} sx={{ mx: -2, mt: 0, p: 0, width: 'auto' }}>
        {messageBoard.map((post) => (
          <BoardPostCard key={post.id} post={post} canEdit={false} />
        ))}
      </Masonry>
    );
  }

  renderChildCard(child) {
    return (
      <Grid item xs={12} md={6} key={child.id}>
        <ChildInfoCard child={child} onAbsenceReport={() => this.handleDialog(child)} />
      </Grid>
    );
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <>
          <Box>
            <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
              <PageHeaderText title={`Witaj ${this.props.loggedUserName}`} titleIcon={<HomeOutlinedIcon />} />
              <ManualLink index="1" />
            </Box>
            <Grid container spacing={2}>
              {this.props.legalGuardiansChildren.map((child) => this.renderChildCard(child))}
              {this.renderDialog()}
            </Grid>

            {this.props.messageBoardLatestEvents.length > 0 && (
              <Typography
                sx={{
                  color: (theme) => theme.palette.color.primary,
                  fontWeight: (theme) => theme.typography.fontWeightBold,
                  opacity: 0.9,
                  display: 'flex',
                  alignItems: 'flex-end',
                  my: 2
                }}
                component="h3"
                variant="h6">
                <EventsCalendarIcon fontSize="large" sx={{ mr: 2 }} /> Najbliższe wydarzenia
              </Typography>
            )}
            {this.renderMessageBoard(this.props.messageBoardLatestEvents)}

            {this.props.messageBoardLatestPosts.length > 0 && (
              <Typography
                sx={{
                  color: (theme) => theme.palette.color.primary,
                  fontWeight: (theme) => theme.typography.fontWeightBold,
                  opacity: 0.9,
                  display: 'flex',
                  alignItems: 'flex-end',
                  my: '16px !important'
                }}
                component="h3"
                variant="h6">
                <EventsCalendarIcon fontSize="large" sx={{ mr: 2 }} /> Najnowsze ogłoszenia
              </Typography>
            )}
            {this.renderMessageBoard(this.props.messageBoardLatestPosts)}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'nowrap',
              mt: 4,
              mb: 2,
              color: (theme) => theme.palette.color.primary
            }}>
            <FooterText />
          </Box>
        </>
      </LoadingRenderWrapper>
    );
  }
}

GuardianDashboardPage.propTypes = {
  reportChildAbsenceStart: PropTypes.func.isRequired,
  reportChildAbsenceCancel: PropTypes.func.isRequired,
  reportChildAbsence: PropTypes.func.isRequired,
  legalGuardiansChildren: PropTypes.array.isRequired,
  childrenAbsenceUi: PropTypes.object,
  loggedUserName: PropTypes.string.isRequired,
  messageBoardLatestPosts: PropTypes.array,
  messageBoardLatestEvents: PropTypes.array
};

function mapStateToProps(state) {
  return {
    loggedUserName: state.auth.userFullName,
    legalGuardiansChildren: state.legalGuardiansChildren,
    childrenAbsenceUi: state.legalGuardianDashboardUi.childrenAbsence,
    messageBoardLatestPosts: _.take(state.messageBoardPosts.posts, 3),
    messageBoardLatestEvents: _.take(state.messageBoardEvents.posts, 3)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportChildAbsenceStart: bindActionCreators(attendanceActions.reportChildAbsenceStart, dispatch),
    reportChildAbsenceCancel: bindActionCreators(attendanceActions.reportChildAbsenceCancel, dispatch),
    reportChildAbsence: bindActionCreators(attendanceActions.reportChildAbsenceAsync, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuardianDashboardPage);
