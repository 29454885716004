import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from '../../common/Checkbox';
import DatePickerAndGroupTemplate from './common/DateAndGroupPickerTemplate';
import { Box } from '@mui/material';
import { ReportTypes } from '../../../constants/reportTypes';

const ChildrenAbsenceInDaysReport = ({
  groupId,
  startDate,
  endDate,
  groups,
  includeArchivedChildren,
  onChange,
  errors
}) => {
  return (
    <Box>
      <DatePickerAndGroupTemplate
        legend={`Pola dla generowania raportu ${ReportTypes.childrenAbsenceInDays.name}`}
        groupId={groupId}
        startDate={startDate}
        endDate={endDate}
        groups={groups}
        onChange={onChange}
        errors={errors}
      />
      <Checkbox
        label="Uwzględnij w raporcie dzieci zarchiwizowane"
        defaultChecked={includeArchivedChildren}
        onCheck={(event, isChecked) => onChange({ target: { name: 'includeArchivedChildren', value: isChecked } })}
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

ChildrenAbsenceInDaysReport.propTypes = {
  groupId: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  includeArchivedChildren: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ChildrenAbsenceInDaysReport;
