import initialState from './initial';
import * as types from '../actions/actionTypes';
import { VirtualDiscFileModel } from '../models/virtualDiscFile/virtualDiscFileModel';

export default function virtualDiscReducer(state = initialState.virtualDisc, action) {
  switch (action.type) {
    case types.VIRTUAL_DISC_FETCH_FILES: {
      const { files } = action;
      return files.map((item) => new VirtualDiscFileModel().assign(item));
    }
    case types.VIRTUAL_DISC_ADD_FILE: {
      const { newFile } = action;
      const file = new VirtualDiscFileModel().assign(newFile);
      file.setNew();
      return [...state, file];
    }
    case types.VIRTUAL_DISC_SHARE_FILES: {
      const { data } = action;
      const { filesIds, shareForUserRoles, shareForUserIds } = data;
      return [
        ...state.map((item) => (filesIds.includes(item.id) ? { ...item, shareForUserRoles, shareForUserIds } : item))
      ];
    }
    case types.VIRTUAL_DISC_DELETE_FILE: {
      const { id } = action;
      return [...state.filter((item) => item.id !== id)];
    }
    case types.VIRTUAL_DISC_DELETE_MANY_FILES: {
      const { ids } = action;
      return [...state.filter((item) => !ids.includes(item.id))];
    }
    default:
      return state;
  }
}
