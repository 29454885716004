import { BaseModelValidator } from '../../../../models/BaseModelValidator';
import { check } from '../../../../models/validation/validateHelper';

export class WorkPlanValidator extends BaseModelValidator {
  constructor() {
    super();

    this.constraints = {
      title: (value) => {
        return check.isEmpty(value) && 'Nie podano tytułu';
      },
      content: (value) => check.isEmpty(value) && 'Nie podano treści notatki'
    };
  }
}
