import PropTypes from 'prop-types';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { Box } from '@mui/material';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import DinnerDiningOutlinedIcon from '@mui/icons-material/DinnerDiningOutlined';
import ActionConfirmationButton from '../forms/buttons/ActionConfirmationButton';
import { useDispatch, useSelector } from 'react-redux';
import { MealDetailsCard } from './MealDetailsCard';
import { useCallback, useState } from 'react';
import { routePaths } from '../../routePaths';
import { browserHistory } from 'react-router';
import { archiveMealAsync, loadMealDetailsAsync, updateMealAsync } from '../../actions/mealActions';

export const MealDetailsPage = () => {
  const meal = useSelector((state) => state.mealDetails);
  const dispatch = useDispatch();
  const [isArchiving, setIsArchiving] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleCancel = useCallback(() => {
    browserHistory.push(routePaths.meals);
  }, []);

  const handleSave = useCallback(
    async (updatedMeal) => {
      try {
        setIsUpdating(true);

        const parsedMeal = {
          id: updatedMeal.id,
          name: updatedMeal.name,
          color: updatedMeal.color
        };

        await dispatch(updateMealAsync(parsedMeal));
      } finally {
        setIsUpdating(false);
      }
    },
    [dispatch]
  );

  const handleArchive = useCallback(
    async (archivedMeal) => {
      try {
        setIsArchiving(true);
        const parsedMeal = {
          name: archivedMeal.name,
          id: archivedMeal.id,
          color: archivedMeal.color
        };

        await dispatch(archiveMealAsync(parsedMeal));
        await dispatch(loadMealDetailsAsync(archivedMeal.id));
        browserHistory.push(routePaths.meals);
      } finally {
        setIsArchiving(false);
      }
    },
    [dispatch]
  );

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title="Posiłki" titleIcon={<DinnerDiningOutlinedIcon />} />
        <MealDetailsCard
          meal={meal}
          title={meal.name}
          onCancel={handleCancel}
          handleSave={handleSave}
          isLoading={isUpdating}
        />
        <ActionConfirmationButton
          actionLabel="archiwizuj"
          confirmationText="Czy na pewno chcesz zarchiwizować posiłek?"
          confirmationTitle="Archiwizuj posiłek"
          confirmLabel="archiwizuj"
          variant="outlined"
          onConfirm={() => {
            handleArchive(meal);
          }}
          onCancel={() => {}}
          isLoading={isArchiving}
        />
      </Box>
    </LoadingRenderWrapper>
  );
};

MealDetailsPage.propTypes = {
  meal: PropTypes.object
};
