// reducers/overpaymentsReducer.js
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { OverpaymentModel } from '../models/settlements/SettlementModels';

export default function overpaymentsReducer(state = initialState.staffDashboard.overpayments, action) {
  switch (action.type) {
    case types.LOAD_OVERPAYMENTS_SUCCESS: {
      return action.overpayments.map((overpayment) => new OverpaymentModel().assign(overpayment));
    }
    case types.PROCESS_REFUNDS_SUCCESS: {
      return state.filter(
        (item) =>
          !action.refundList.some((yItem) => yItem.personId === item.personId && yItem.walletType === item.walletType)
      );
    }
    default:
      return state;
  }
}
