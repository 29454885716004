import PropTypes from 'prop-types';
import React from 'react';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';

export const callMenuItem = (phoneNumber, key) => {
  if (!phoneNumber) return null;
  return (
    <MuiMenuItem
      key={key}
      onClick={() => {
        document.location.href = `tel:${phoneNumber}`;
      }}>
      <ListItemIcon>
        <CallIcon />
      </ListItemIcon>
      <ListItemText primary="Zadzwoń" />
    </MuiMenuItem>
  );
};

const MuiMenuItem = React.forwardRef((props, ref) => {
  return <MenuItem ref={ref} {...props} />;
});

callMenuItem.propTypes = {
  phoneNumber: PropTypes.string,
  key: PropTypes.any
};

export default callMenuItem;
