import PropTypes from 'prop-types';
import React from 'react';
import RichTextEditor from '../../../forms/richTextEditor/RichTextEditor';
import TextField from '../../../common/TextField';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import { Box } from '@mui/material';

const DiagnosticSchemeSetForm = ({ diagnosticSchemeSet, onChange, errors, handleSuperTextEditor }) => {
  return (
    <Box>
      <FieldsetWrapper legend="Informacje na temat szablonu diagnoz">
        <TextField
          hintText="Tytuł"
          floatingLabelText="Tytuł*"
          floatingLabelFixed={false}
          autoFocus={true}
          name="title"
          value={diagnosticSchemeSet.title}
          onChange={onChange}
          fullWidth={true}
          errorText={errors.title}
        />
        <RichTextEditor onChange={handleSuperTextEditor} initialText={diagnosticSchemeSet.content} />
      </FieldsetWrapper>
    </Box>
  );
};

DiagnosticSchemeSetForm.propTypes = {
  diagnosticSchemeSet: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  handleSuperTextEditor: PropTypes.func.isRequired
};

export default DiagnosticSchemeSetForm;
