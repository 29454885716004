import loginHistoryApi from '../api/LoginHistoryApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';

export function loadLoginHistoryAsync(params) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_LOGIN_HISTORY });
    return loginHistoryApi
      .getLoginHistory(params)
      .then((loginHistory) => dispatch({ type: types.LOAD_LOGIN_HISTORY_SUCCESS, loginHistory }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać historii logowań użytkowników'));
        logger.error(error);
      });
  };
}
