import React, { useMemo, useState } from 'react';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import IconButtonPipe from '../../../common/IconButtonPipe';
import DatePicker from '../../../common/DatePicker';
import ClearButtonWrapper from '../../../common/ClearButtonWrapper';
import { Clear } from '@mui/icons-material';
import { Box, Grid, Tooltip, Popover, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const Filters = ({ setDateFrom, setDateTo, dateFrom, dateTo }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const isDateInvalid = useMemo(() => {
    if (dateFrom !== null && dateTo !== null) {
      return dateFrom > dateTo;
    }
    return false;
  }, [dateFrom, dateTo]);

  return (
    <>
      <Box onClick={({ currentTarget }) => setAnchorEl(currentTarget)} sx={{ cursor: 'pointer', p: 1 }}>
        <Tooltip title="Filtruj">
          <TuneRoundedIcon />
        </Tooltip>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <>
          <Grid container justifyContent="space-between" alignItems="center" sx={{ pl: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
              Filtry
            </Typography>
            <IconButtonPipe onClick={() => setAnchorEl(null)}>
              <Clear sx={{ color: (theme) => theme.palette.color.contrast }} />
            </IconButtonPipe>
          </Grid>
          <Box sx={{ p: 2, pt: 0 }}>
            <ClearButtonWrapper contrast onClear={() => setDateFrom(null)} disabled={!dateFrom}>
              <DatePicker
                onChange={(val) => setDateFrom(val)}
                value={dateFrom}
                floatingLabelText={<Box sx={{ color: (theme) => theme.palette.color.contrast }}>Multimedia od</Box>}
                name="timeFrom"
              />
            </ClearButtonWrapper>
            <ClearButtonWrapper contrast onClear={() => setDateTo(null)} disabled={!dateTo} sx={{ mt: 1 }}>
              <DatePicker
                displayError={isDateInvalid}
                onChange={(val) => setDateTo(val)}
                value={dateTo}
                floatingLabelText={<Box sx={{ color: (theme) => theme.palette.color.contrast }}>Multimedia do</Box>}
                name="timeFrom"
                errorContent="Data początkowa nie może być późniejsza od daty końcowej"
              />
            </ClearButtonWrapper>
          </Box>
        </>
      </Popover>
    </>
  );
};

Filters.propTypes = {
  cl: PropTypes.object,
  setDateFrom: PropTypes.func.isRequired,
  setDateTo: PropTypes.func.isRequired,
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object
};

export default Filters;
