import {
  Button,
  MenuItem,
  Paper,
  Typography,
  AccordionSummary,
  Accordion,
  Stack,
  ListItemText,
  ListItem,
  List,
  ListItemIcon
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DotsMenu from '../../common/menu/DotsMenu';
import EmptyState from '../../common/EmptyState';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { deletePsychologicalSchemeAsync } from '../../../actions/psychologicalActions';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import { useDispatch } from 'react-redux';

const UnitSchemeElement = ({ rows = [], title, type = '', onClick, onPreview, onEdit }) => {
  const dispatch = useDispatch();
  const handleDelete = (data) => {
    //TODO
    dispatch(deletePsychologicalSchemeAsync(data));
  };
  return (
    <Accordion sx={{ my: 2 }}>
      <AccordionSummary sx={{ mb: 2, '&.MuiButtonBase-root': { mb: 0 } }} expandIcon={<ExpandMoreIcon />}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: '100%',
            px: { md: 2 }
          }}>
          <Typography variant="h6">{title}</Typography>
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              onClick(type);
            }}>
            Dodaj
          </Button>
        </Stack>
      </AccordionSummary>
      <List sx={{ p: 2 }}>
        {rows.length ? (
          rows.map((element, index) => (
            <ListItem
              onClick={(e) => {
                onPreview(element);
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                '&:hover': { background: (theme) => theme.palette.background.hover, cursor: 'pointer' }
              }}
              key={index}>
              <ListItemIcon>
                <InsertDriveFileOutlinedIcon />
              </ListItemIcon>
              <ListItemText>{element.templateName}</ListItemText>
              {/* <Box
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                <DotsMenu key="dots1">
                  {() => [
                    // <MenuItem
                    //   key={`1 ${element.name} ${index}`}
                    //   onClick={() => {
                    //     onEdit(element);
                    //   }}>
                    //   Edytuj
                    // </MenuItem>,
                    <ActionConfirmationMenuItem
                      actionLabel="Usuń"
                      confirmationText="Czy na pewno chcesz usunąć schamat?"
                      confirmationTitle="Usuń"
                      confirmLabel="Usuń"
                      cancelLabel="Anuluj"
                      onConfirm={() => handleDelete(element)}
                      onCancel={() => {}}
                    />
                  ]}
                </DotsMenu>
              </Box> */}
            </ListItem>
          ))
        ) : (
          <EmptyState message="Brak schematów" contrast />
        )}
      </List>
    </Accordion>
  );
};

export default UnitSchemeElement;
