/*eslint indent:0*/
import initialState from './initial';
import { GroupDetailsModel, AssociatedTeacherModel, EnrolledChildModel } from '../models/groups/GroupModels';
import { compareNames } from '../utils/personNamesComparator';
import * as types from '../actions/actionTypes';

export default function groupDetailsReducer(state = initialState.groupDetails, action) {
  switch (action.type) {
    case types.LOAD_GROUP_DETAILS_SUCCESS: {
      return new GroupDetailsModel().assign(action.groupDetails);
    }
    case types.CREATE_GROUP_DETAILS_SUCCESS: {
      return new GroupDetailsModel().assign(action.groupDetails);
    }
    case types.UPDATE_GROUP_DETAILS_SUCCESS: {
      return new GroupDetailsModel().assign(action.groupDetails);
    }
    case types.ENROLL_CHILDREN_TO_GROUP_SUCCESS: {
      const groupDetails = new GroupDetailsModel().assign(state);
      groupDetails.enrolledChildren = action.children
        .map((child) => new EnrolledChildModel().assign(child))
        .sort(compareNames);
      return groupDetails;
    }
    case types.REMOVE_CHILD_FROM_GROUP_SUCCESS: {
      const groupDetails = new GroupDetailsModel().assign(state);
      groupDetails.enrolledChildren = [...groupDetails.enrolledChildren.filter((child) => child.id !== action.childId)];
      return groupDetails;
    }
    case types.ASSOCIATE_TEACHER_TO_GROUP_SUCCESS: {
      const groupDetails = new GroupDetailsModel().assign(state);
      const newTeacher = new AssociatedTeacherModel().assign(action.teacher);
      newTeacher.associatedAt = new Date();
      groupDetails.associatedTeachers = [...groupDetails.associatedTeachers, newTeacher].sort(compareNames);
      return groupDetails;
    }
    case types.REMOVE_TEACHER_FROM_GROUP_SUCCESS: {
      const groupDetails = new GroupDetailsModel().assign(state);
      groupDetails.associatedTeachers = [
        ...groupDetails.associatedTeachers.filter((teacher) => teacher.id !== action.teacherId)
      ];
      return groupDetails;
    }
    default:
      return state;
  }
}
