import React, { useState } from 'react';
import IconButtonPipe from '../../../common/IconButtonPipe';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import { Clear } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as SortTypes from '../../../../constants/sortTypes';

const Sort = ({ sortType, handleSort }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButtonPipe
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        tooltip="Sortuj"
        aria-haspopup="true"
        aria-describedby={id}>
        <SortIcon />
      </IconButtonPipe>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <>
          <Paper sx={{ p: 1, pl: 2 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h5">Sortowanie</Typography>
              <IconButtonPipe onClick={() => setAnchorEl(null)}>
                <Clear />
              </IconButtonPipe>
            </Grid>
            <Box>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="Sortowanie plików"
                  name="gender1"
                  value={sortType}
                  sx={{ p: 2 }}
                  onChange={(e) => handleSort(e.target.value)}>
                  <FormControlLabel value={SortTypes.NEWEST} control={<Radio />} label="Od najnowszego" />
                  <FormControlLabel value={SortTypes.LATEST} control={<Radio />} label="Od najstarszego" />
                  <FormControlLabel value={SortTypes.A_Z} control={<Radio />} label="Alfabetycznie A-Z" />
                  <FormControlLabel value={SortTypes.Z_A} control={<Radio />} label="Alfabetycznie Z-A" />
                </RadioGroup>
              </FormControl>
            </Box>
          </Paper>
        </>
      </Popover>
    </>
  );
};

Sort.propTypes = {
  sortType: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired
};

export default Sort;
