import { check } from '../../validation/validateHelper';
import { BaseModelValidator } from '../../BaseModelValidator';
import { PinDevice } from './GuardiansPinServiceModels';

export class PinDeviceValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = PinDevice;

    this.constraints = {
      login: (value) => check.isEmpty(value) && 'Nie podano loginu.',
      password: (value) => check.isEmpty(value) && 'Nie podano hasła.'
    };
  }
}
