import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../../../actions/authActions';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import WelcomeScreen from '../welcomeScreen/WelcomeScreen';
import localStorageProvider from '../../../utils/localStorageProvider';

const LoginGovPage = ({ location, actions }) => {
  const samlart = new URLSearchParams(window.location.search).get('SAMLArt').replaceAll(' ', '+');
  const token = localStorageProvider.getItem('authToken');

  useEffect(() => {
    if (location.query.SAMLArt && !token) {
      actions.signInWithToken({ SAMLArt: samlart });
    }
  }, [actions, location.query.SAMLArt, location.query.tgsid, samlart, token]);

  return (
    <WelcomeScreen>
      <Box sx={{ p: 2 }}>
        <Typography paragraph variant="h5" component="p" align="center">
          Trwa logowanie...
        </Typography>
        <Typography paragraph variant="subtitle1" component="p">
          Za chwilę nastąpi przekierowanie do aplikacji.
        </Typography>
        <Box sx={{ my: 2, mx: 0 }}>
          <LinearProgress mode="indeterminate" />
        </Box>
      </Box>
    </WelcomeScreen>
  );
};

LoginGovPage.propTypes = {
  location: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(authActions, dispatch)
});

export default connect(null, mapDispatchToProps)(LoginGovPage);
