import initialState from './initial';
import * as types from '../actions/actionTypes';
import { SettlementModel } from '../models/settlements/SettlementModels';

export default function staffDuesReducer(state = initialState.staffDues, action) {
  switch (action.type) {
    case types.LOAD_STAFF_DUES:
      return {
        ...initialState.staffDues,
        isLoading: true
      };
    case types.LOAD_STAFF_DUES_SUCCESS:
      return {
        balance: action.staffDues.balance,
        dues: action.staffDues.dues.map((due) => new SettlementModel().assign(due)),
        isLoading: false
      };
    default:
      return state;
  }
}
