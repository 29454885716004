import initialState from './initial';
import * as types from '../actions/actionTypes';
import { VirtualDiscFileModel } from '../models/virtualDiscFile/virtualDiscFileModel';
import { VirtualDiscFolderModel } from '../models/virtualDiscFolder/virtualDiscFolderModel';

export default function virtualDiscFolder(state = initialState.virtualDiscFolder, action) {
  switch (action.type) {
    case types.VIRTUAL_DISC_CREATE_FOLDER: {
      const { data } = action;

      return { ...state, disc: [new VirtualDiscFolderModel().assign(data), ...state.disc] };
    }
    case types.VIRTUAL_DISC_DELETE_FOLDER: {
      const { folderId } = action;
      return { ...state, disc: [...state.disc.filter((item) => item.id !== folderId)] };
    }
    case types.VIRTUAL_DISC_EDIT_FOLDER: {
      const { data } = action;
      const newStateDisc = [...state.disc];
      const folderIndex = newStateDisc.findIndex((item) => item.id === data.id);
      newStateDisc[folderIndex].name = data.name;
      newStateDisc[folderIndex].color = data.color;
      return { ...state, disc: newStateDisc };
    }
    case types.VIRTUAL_DISC_MOVE_FOLDER: {
      const {
        data: { folderId }
      } = action;
      return { ...state, disc: [...state.disc.filter((item) => item.id !== folderId)] };
    }
    case types.VIRTUAL_DISC_MOVE_TO_FOLDER: {
      const {
        data: { items }
      } = action;
      const itemsIds = items.map((item) => item.id);
      return { ...state, disc: [...state.disc.filter((item) => !itemsIds.includes(item.id))] };
    }
    case types.VIRTUAL_DISC_GET_FOLDER_CONTENT: {
      const {
        data: { path, files, folders, folderId }
      } = action;
      return {
        ...state,
        path,
        folderId: folderId ? folderId : path[path.length - 1].id,
        disc: [
          ...folders.map((item) => {
            return new VirtualDiscFolderModel().assign(item);
          }),
          ...files.map((item) => new VirtualDiscFileModel().assign(item))
        ]
      };
    }
    case types.VIRTUAL_DISC_SHARE_FOLDER: {
      const { data } = action;
      const { folderId, shareForUserRoles, shareForUserIds } = data;
      const newState = { ...state };
      const itemIdx = newState.disc.findIndex((item) => item.id === folderId);
      newState.disc[itemIdx] = { ...newState.disc[itemIdx], shareForUserRoles, shareForUserIds };

      return newState;
    }

    case types.VIRTUAL_DISC_ADD_FILE: {
      const { newFile } = action;
      const file = new VirtualDiscFileModel().assign(newFile);
      file.setNew();
      return { ...state, disc: [...state.disc, file] };
    }
    case types.VIRTUAL_DISC_SHARE_FILES: {
      const { data } = action;
      const { filesIds, shareForUserRoles, shareForUserIds } = data;
      return {
        ...state,
        disc: [
          ...state.disc.map((item) =>
            filesIds.includes(item.id) ? { ...item, shareForUserRoles, shareForUserIds } : item
          )
        ]
      };
    }
    case types.VIRTUAL_DISC_DELETE_FILE: {
      const { id } = action;
      return { ...state, disc: [...state.disc.filter((item) => item.id !== id)] };
    }
    case types.VIRTUAL_DISC_DELETE_MANY_FILES: {
      const { ids } = action;
      return { ...state, disc: [...state.disc.filter((item) => !ids.includes(item.id))] };
    }
    default:
      return state;
  }
}
