import ApiBase, { ROOT_URL } from './ApiBase';

class EventsApi extends ApiBase {
  static getEvents(params) {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/events`).query(params).type('application/json'));
  }

  static getEventsFile(params) {
    return super.toPromise(
      (agent) => {
        return agent.get(`${ROOT_URL}/events/export`).query(params).type('application/json').responseType('blob');
      },
      (res) => res.xhr
    );
  }
}

export default EventsApi;
