import React from 'react';
import { TableCell, TableRow, CardHeader, Box, Typography } from '@mui/material';
import PersonAvatar from '../../forms/PersonAvatar';
import PropTypes from 'prop-types';
import moment from 'moment';
import AuthorizationChipElement from '../../common/children/guardians/AuthorizationChipElement';
import ToggleIdButton from '../ToggleIdButton/ToggleIdButton';

const AuthorizedToPickUpChildTableRow = (props) => {
  const { tableRow, types } = props;

  const typographyStyles = () => {
    if (
      !!tableRow.guardianAuthorizationExpirationDate &&
      moment(tableRow.guardianAuthorizationExpirationDate).isBefore(new Date())
    )
      return {
        color: (theme) => theme.palette.color.primary,
        background: (theme) => theme.palette.color.error
      };

    return {
      color: (theme) => theme.palette.color.primary,
      background: (theme) => theme.palette.color.success
    };
  };

  return (
    <>
      <TableRow
        sx={{
          '&:hover': {
            background: (theme) => theme.palette.background.hover
          }
        }}>
        <TableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CardHeader
              sx={{ p: 0, mr: 8, fontWeight: (theme) => theme.typography.fontWeightBold }}
              avatar={
                <PersonAvatar
                  initials
                  alt={tableRow.childFirstName}
                  url={tableRow.childPhotoUrl}
                  firstName={tableRow.childFirstName}
                  lastName={tableRow.childLastName}
                />
              }
              title={<Typography sx={{ ml: 2 }}>{tableRow.childFirstName + ' ' + tableRow.childLastName}</Typography>}
            />
          </Box>
        </TableCell>
        {tableRow.guardianFirstName !== null && tableRow.guardianLastName !== null ? (
          <>
            <TableCell sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <PersonAvatar
                initials
                alt={tableRow.guardianFirstName}
                url={tableRow.guardianPhotoUrl}
                firstName={tableRow.guardianFirstName}
                lastName={tableRow.guardianLastName}
              />
              <Typography sx={{ ml: 2 }}>{tableRow.guardianFirstName + ' ' + tableRow.guardianLastName}</Typography>
              {tableRow.guardianType === types.legalGuardians ? (
                <Typography variant="caption" sx={{ ml: 2 }}>
                  (Opiekun prawny)
                </Typography>
              ) : null}
              {tableRow.guardianType === types.guardians ? (
                <Typography variant="caption" sx={{ ml: 2 }}>
                  (Osoba upoważniona)
                </Typography>
              ) : null}
            </TableCell>
            {tableRow.guardianAuthorizationExpirationDate ? (
              <TableCell>
                <AuthorizationChipElement
                  sx={{
                    ...typographyStyles(),
                    width: 124
                  }}>
                  {moment(tableRow.guardianAuthorizationExpirationDate).format('DD/MM/YYYY')}
                </AuthorizationChipElement>
              </TableCell>
            ) : (
              <TableCell>
                <AuthorizationChipElement sx={{ ...typographyStyles(), width: 80 }}>Nigdy</AuthorizationChipElement>
              </TableCell>
            )}
            <TableCell align="center">
              {tableRow.guardianDocumentNumber ? (
                <ToggleIdButton documentNumber={tableRow.guardianDocumentNumber} />
              ) : (
                <Typography>Brak</Typography>
              )}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start'
              }}>
              <Typography>Brak osoby uprawnionej</Typography>
            </TableCell>
            <TableCell>
              <Typography>Brak</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography>Brak</Typography>
            </TableCell>
          </>
        )}
      </TableRow>
    </>
  );
};

AuthorizedToPickUpChildTableRow.propTypes = {
  tableRow: PropTypes.object,
  types: PropTypes.object
};

export default AuthorizedToPickUpChildTableRow;
