'use strict';

import ApiBase, { ROOT_URL } from './ApiBase';

class UserAccountApi extends ApiBase {
  static resetPassword(resetPasswordData) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/users/password/reset`).send(resetPasswordData).type('application/json')
    );
  }

  static acceptAgreement() {
    return super.toPromise((agent) => agent.post(`${ROOT_URL}/users/confirmAcceptance`).type('application/json'));
  }

  static recoverPassword(passwordRecoveryData) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/users/password/recover`).send(passwordRecoveryData).type('application/json')
    );
  }

  static changePassword(changePasswordData) {
    return super.toPromise((agent) =>
      agent.patch(`${ROOT_URL}/users/me/password`).send(changePasswordData).type('application/json')
    );
  }

  static updateProfilePhoto(file) {
    const data = new FormData();
    data.append('file', file, file.name);

    return super.toPromise((agent) => agent.patch(`${ROOT_URL}/users/me/photo`).send(data));
  }

  static removeProfilePhoto() {
    return super.toPromise((agent) => agent.delete(`${ROOT_URL}/users/me/photo`).type('application/json'));
  }

  static restartActivationProcess(login) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/users/account/restartActivation`).send({ login }).type('application/json')
    );
  }

  static resetPasswordProcess(userId) {
    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/users/password/setNewPassword`).send({ userId }).type('application/json'),
      (r) => {
        const password = r.text;
        return { password };
      }
    );
  }

  static getUserState() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/users/me`).withCredentials().type('application/json'));
  }
}

export default UserAccountApi;
