export const FILE_CATEGORY = {
  pdf: ['pdf'],
  spreadsheet: ['.ods', '.xls', '.xlsm', '.xlsx'],
  text: ['.doc', '.docx', '.odt', '.rtf', '.tex', '.txt', '.wpd'],
  audio: ['.aif', '.cda', '.mid', '.midi', '.mp3', '.wav', '.wma', '.wpl'],
  compressed: ['.7z', '.arj', '.deb', '.pkg', '.rar', '.rpm', '.tar.gz', '.z', '.zip'],
  image: ['.ai', '.bmp', '.gif', '.ico', '.jpeg', '.jpg', '.png', '.ps', '.psd', '.svg', '.tif', '.tiff'],
  presentation: ['.key', '.odp', '.pps', '.ppt', '.pptx'],
  system: ['.dll', '.bak', '.cab', '.cfg', '.cpl', '.cur', '.dmp', '.drv', '.ini', '.lnk', '.msi', '.sys', '.tmp'],
  video: [
    '.3g2',
    '.3gp',
    '.avi',
    '.flv',
    '.h264',
    '.m4v',
    '.mkv',
    '.mov',
    '.mp4',
    '.mpg',
    '.mpeg',
    '.rm',
    '.swf',
    '.vob',
    '.wmv'
  ],
  programming: ['.c', '.cgi', '.pl', '.class', '.cpp', '.cs', '.h', '.java', '.php', '.py', '.sh', '.swift', '.vb']
};

export const findFileCategoryByName = (name) => Object.keys(FILE_CATEGORY).find((key) => FILE_CATEGORY[key] === name);
