import { Box, Button, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyState from '../common/EmptyState';
import DotsMenu from '../common/menu/DotsMenu';
import ActionConfirmationMenuItem from '../forms/ActionConfirmationMenuItem';
import NewWorkPlanDialog from './components/dialogs/NewWorkPlanDialog';
import {
  deleteSpecialistWorkPlanElement,
  setSpecialistWorkPlanDialogClosed,
  setSpecialistWorkPlanDialogOpen
} from '../../actions/specialistWorkPlanActions';
import { useIsSpecialist } from '../../hooks/useIsSpecialist';

const WorkPlanList = ({ workId }) => {
  const [currentData, setCurrentData] = useState(null);
  const data = useSelector((state) => state.specialistNotes.workPlan);
  const ui = useSelector((state) => state.specialistNotesUi.workPlan);

  const { isSpecialist } = useIsSpecialist();

  const dispatch = useDispatch();
  const handleSave = () => {
    setCurrentData(null);
  };

  const handleDialogOpen = (post) => {
    setCurrentData(null);
    if (!!post) {
      setCurrentData(post);
    }
    dispatch(setSpecialistWorkPlanDialogOpen());
  };

  const handleCancel = () => {
    setCurrentData(null);
    dispatch(setSpecialistWorkPlanDialogClosed());
  };

  const handleRemove = (id) => {
    dispatch(deleteSpecialistWorkPlanElement(id, workId));
  };

  return (
    <Box>
      {isSpecialist && (
        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <Button variant="contained" onClick={() => handleDialogOpen()}>
            Dodaj
          </Button>
        </Box>
      )}
      {ui.isEditing && (
        <NewWorkPlanDialog
          handleCancel={handleCancel}
          selectedElement={currentData}
          onSave={handleSave}
          workId={workId}
        />
      )}

      <Box sx={{ mb: 4 }}>
        {!!data?.length > 0 ? (
          <List sx={{ width: '100%' }}>
            {data.map((element) => (
              <ListItem key={element.id}>
                <ListItemText
                  primary={element.title}
                  secondary={element.content}
                  primaryTypographyProps={{ variant: 'h6' }}
                  secondaryTypographyProps={{ variant: 'subtitle1', color: (theme) => theme.palette.color.black }}
                />
                {isSpecialist && (
                  <ListItemSecondaryAction>
                    <DotsMenu>
                      {(onClose) => [
                        <MenuItem
                          key="edit"
                          onClick={() => {
                            onClose();
                            handleDialogOpen(element);
                          }}>
                          Edytuj
                        </MenuItem>,
                        <ActionConfirmationMenuItem
                          key="1"
                          onItemClick={onClose}
                          actionLabel="Usuń"
                          confirmationText="Czy na pewno chcesz usunąć wpis?"
                          confirmationTitle="Usuń wpis"
                          confirmLabel="Usuń"
                          onConfirm={() => handleRemove(element.id)}
                          onCancel={() => {}}
                        />
                      ]}
                    </DotsMenu>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        ) : (
          <EmptyState message="Brak" contrast />
        )}
      </Box>
    </Box>
  );
};

export default WorkPlanList;
