import PropTypes from 'prop-types';
import React from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Box,
  useMediaQuery
} from '@mui/material';
import PersonAvatar from '../../../forms/PersonAvatar';
import AuthorizationDetails from './AuthorizationDetails';
import ActionConfirmationMenuItem from '../../../forms/ActionConfirmationMenuItem';
import { callMenuItem } from '../../../forms/CallMenuItem';
import AuthorizeMenuItem from './AuthorizeMenuItem';
import DotsMenu from '../../menu/DotsMenu';

const ChildGuardiansList = ({ guardians, isPinEnabled, onEdit, onRemove, onAuthorize, onUnAuthorize }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <List>
      {guardians.map((guardian) => {
        return (
          <ListItem
            button
            key={guardian.id}
            onClick={() => onEdit(guardian)}
            component="li"
            aria-label={`Edytuj ${guardian.firstName} ${guardian.lastName}`}>
            {!isMobile && (
              <ListItemAvatar>
                <PersonAvatar
                  initials
                  alt={guardian.firstName}
                  firstName={guardian.firstName}
                  lastName={guardian.lastName}
                />
              </ListItemAvatar>
            )}
            <ListItemText
              disableTypography
              sx={{ overflow: 'auto' }}
              primary={
                <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                  {guardian.firstName} {guardian.lastName}
                  {guardian.relationship && (
                    <Typography component="span" sx={{ mx: 1 }}>
                      ({guardian.relationship})
                    </Typography>
                  )}
                </Typography>
              }
              secondary={
                <Box sx={{ mt: 1 }}>
                  <Typography sx={{ display: isMobile ? 'block' : 'inline' }}>
                    Numer dokumentu
                    <Typography
                      component="span"
                      sx={{
                        mx: 1,
                        fontWeight: (theme) => theme.typography.fontWeightBold
                      }}>
                      {guardian.documentNumber}
                    </Typography>
                  </Typography>
                  <Typography sx={{ display: isMobile ? 'block' : 'inline' }}>
                    Numer telefonu
                    <Typography
                      component="span"
                      sx={{
                        mx: 1,
                        fontWeight: (theme) => theme.typography.fontWeightBold
                      }}>
                      {guardian.phoneNumber || 'brak'}
                    </Typography>
                  </Typography>

                  {isPinEnabled && guardian.pin && (
                    <Typography sx={{ display: isMobile ? 'block' : 'inline' }}>
                      Pin
                      <Typography
                        component="span"
                        sx={{
                          mx: 1,
                          fontWeight: (theme) => theme.typography.fontWeightBold
                        }}>
                        {guardian.pin}
                      </Typography>
                    </Typography>
                  )}
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', flexWrap: 'wrap' }}>
                    <AuthorizationDetails
                      isAuthorized={guardian.isAuthorized}
                      authorizationExpirationDate={guardian.authorizationExpirationDate}
                      authorizedBy={guardian.authorizedBy}
                      isAuthorizedBySign={guardian.isAuthorizedBySign}
                      isAuthorizedByAll={guardian.isAuthorizedByAll}
                    />
                  </Box>
                  {guardian.isAuthorizedBySign && (
                    <Typography
                      sx={{
                        fontWeight: (theme) => theme.typography.fontWeightBold
                      }}>
                      Podpisano przez opiekuna prawnego
                    </Typography>
                  )}
                </Box>
              }
              secondaryTypographyProps={{ color: (theme) => theme.palette.text.primary }}
            />
            <ListItemSecondaryAction sx={{ mr: -4 }}>
              <DotsMenu>
                {(onClose) => {
                  if (guardian.phoneNumber) {
                    return [
                      callMenuItem(guardian.phoneNumber, 1),
                      <AuthorizeMenuItem
                        key="2"
                        isAuthorized={guardian.isAuthorized}
                        onAuthorize={() => onAuthorize(guardian.id)}
                        onUnAuthorize={() => onUnAuthorize(guardian.id)}
                      />,
                      <ActionConfirmationMenuItem
                        key="3"
                        onItemClick={onClose}
                        actionLabel="Usuń"
                        confirmationText="Czy na pewno chcesz usunąć osobę upoważnioną do odbioru?"
                        confirmationTitle="Usuń osobę upoważnioną do odbioru"
                        confirmLabel="Usuń"
                        onConfirm={() => onRemove(guardian.id)}
                        onCancel={() => {}}
                      />
                    ];
                  }
                  return [
                    <AuthorizeMenuItem
                      key="2"
                      isAuthorized={guardian.isAuthorized}
                      onAuthorize={() => onAuthorize(guardian.id)}
                      onUnAuthorize={() => onUnAuthorize(guardian.id)}
                    />,
                    <ActionConfirmationMenuItem
                      key="3"
                      onItemClick={onClose}
                      actionLabel="Usuń"
                      confirmationText="Czy na pewno chcesz usunąć osobę upoważnioną do odbioru?"
                      confirmationTitle="Usuń osobę upoważnioną do odbioru"
                      confirmLabel="Usuń"
                      onConfirm={() => onRemove(guardian.id)}
                      onCancel={() => {}}
                    />
                  ];
                }}
              </DotsMenu>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

ChildGuardiansList.propTypes = {
  guardians: PropTypes.array.isRequired,
  isPinEnabled: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAuthorize: PropTypes.func.isRequired,
  onUnAuthorize: PropTypes.func.isRequired
};

export default ChildGuardiansList;
