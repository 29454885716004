import React from 'react';
import PropTypes from 'prop-types';
import { FormikTextField } from '../../../formik/FormikTextField';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import FormikDatePicker from '../../../formik/FormikDatePicker';
import { FieldArray, useFormikContext } from 'formik';
import { Box, Typography, ListItem, Grid } from '@mui/material';

const ChildDetailsFormAdditionalInformation = (props) => {
  const { pupil } = props;
  const { setFieldValue } = useFormikContext();

  const handleChange = (value, name) => {
    setFieldValue(name, value);
  };

  return (
    <FieldsetWrapper
      legend={
        <Typography
          component="span"
          sx={{
            pl: 2,
            color: (theme) => theme.palette.color.contrast,
            fontWeight: (theme) => theme.typography.fontWeightBold
          }}>
          Dodatkowe
        </Typography>
      }
      invisible={false}>
      <FieldArray
        name="notes"
        render={() => (
          <Grid container>
            {pupil.notes.map((note, index) => {
              return (
                <Grid item md={6} lg={4} xs={12} key={note.key}>
                  <ListItem>
                    <FormikTextField
                      onChange={handleChange}
                      name={`notes[${index}].value`}
                      label={note.key}
                      fullWidth
                    />
                  </ListItem>
                </Grid>
              );
            })}
          </Grid>
        )}
      />
      <Box flexDirection={{ xs: 'column', md: 'row' }} sx={{ pb: 3 }} display="flex">
        <Box width={{ xs: '100%', md: '50%' }} justifyContent="flex-start">
          <ListItem>
            <FormikDatePicker name="educationStartDate" label="Data rozpoczęcia edukacji" fullWidth />
          </ListItem>
        </Box>
        <Box width={{ xs: '100%', md: '50%' }} justifyContent="flex-end">
          <ListItem>
            <FormikDatePicker name="educationEndDate" label="Data zakończenia edukacji" fullWidth />
          </ListItem>
        </Box>
      </Box>
    </FieldsetWrapper>
  );
};

ChildDetailsFormAdditionalInformation.propTypes = {
  pupil: PropTypes.object
};

export default ChildDetailsFormAdditionalInformation;
