import PropTypes from 'prop-types';
import React from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Box, Typography } from '@mui/material';
import Resizer from 'react-image-file-resizer';
import localStorageProvider from '../../../utils/localStorageProvider';
import axios from 'axios';
import { ROOT_URL } from '../../../api/ApiBase';
import RichUtils from 'draft-js/lib/RichTextEditorUtil';

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraft(this.props.initialText).contentBlocks)
      )
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyCommandEn = this.handleKeyCommandEn.bind(this);
    this.uploadCallback = this.uploadCallback.bind(this);
    this.resizeFile = this.resizeFile.bind(this);
  }

  resizeFile(file) {
    return new Promise((resolve) => {
      const isImage = (mimeType) => /.*?(gif|jpg|jpeg|tiff|png)$/.test(mimeType);
      if (isImage(file.type)) {
        Resizer.imageFileResizer(file, 1920, 1080, 'JPEG', 80, 0, (uri) => resolve(uri), 'blob');
      } else {
        resolve(file);
      }
    });
  }

  uploadCallback(file, callback) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorageProvider.getItem('authToken')}`,
        'Content-Type': 'multipart/form-data'
      }
    };
    const data = new FormData();
    data.append('file', file, file.name);
    return axios.post(`${ROOT_URL}/files/upload`, data, config).then((res) => {
      return new Promise((resolve, reject) => {
        resolve({ data: { link: res.data.fileUrl } });
      });
    });
  }

  handleChange(text) {
    this.setState({
      editorState: text
    });
    this.props.onChange(draftToHtml(convertToRaw(text.getCurrentContent())));
  }

  handleKeyCommandEn(command) {
    const newState = RichUtils.handleKeyCommand(this.state.editorState, command);
    if (newState) {
      this.handleChange(newState);
      return true;
    }

    return false;
  }

  render() {
    return (
      <Box sx={{ fontFamily: "Roboto Helvetica Arial 'sans-serif'" }}>
        <Editor
          toolbarStyle={{ color: 'black' }}
          readOnly={this.props.disabled}
          editorStyle={{ height: 200, p: 0 }}
          editorState={this.state.editorState}
          localization={{
            locale: 'pl'
          }}
          toolbarHidden={this.props.disabled}
          toolbar={{
            options: [
              'emoji',
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'remove',
              'image'
            ],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            image: { uploadCallback: this.uploadCallback, previewImage: true }
          }}
          onEditorStateChange={this.handleChange}
          handleKeyCommand={this.handleKeyCommandEn}
        />
        {!!this.props.errorText && (
          <Typography sx={{ color: (theme) => theme.palette.color.error, fontSize: '0.75rem', my: 1 }}>
            {this.props.errorText}
          </Typography>
        )}
      </Box>
    );
  }
}

RichTextEditor.propTypes = {
  onChange: PropTypes.func,
  initialText: PropTypes.string,
  readOnly: PropTypes.bool,
  errorText: PropTypes.string,
  disabled: PropTypes.bool
};

RichTextEditor.defaultProps = {
  onChange: () => {},
  readOnly: false,
  initialText: '',
  disabled: false
};

export default RichTextEditor;