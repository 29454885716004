import PropTypes from 'prop-types';
import React from 'react';
import Lightbox from 'react-image-lightbox';
import { withTheme } from '@mui/styles';
import { IconButton, Box } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { GetApp, Delete } from '@mui/icons-material';
import ImageListFuncional from './ImageListFunctional';

const theme = createTheme();

class PhotoGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: 0,
      lightboxIsOpen: false
    };

    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.downloadPhoto = this.downloadPhoto.bind(this);
  }

  openLightbox(index) {
    this.setState({
      currentImage: index,
      lightboxIsOpen: true
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }

  gotoPrevious(length) {
    this.setState({
      currentImage: (this.state.currentImage + length - 1) % length
    });
  }

  gotoNext(length) {
    this.setState({
      currentImage: (this.state.currentImage + 1) % length
    });
  }

  downloadPhoto(url) {
    if (url.fileUrl.startsWith('blob')) {
      const a = document.createElement('a');
      a.href = url.fileUrl;
      a.download = url.caption;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      // download img from server logic
      const path = `${url.fileUrl}?name=${encodeURIComponent(url.name)}&mimeType=${encodeURIComponent(url.mimeType)}`;
      window.open(path, '_blank');
    }
  }

  render() {
    const { photos, onRemoveFile = false, showAllPhotos } = this.props;
    const urls = photos.map((photo) => ({
      src: photo.thumbnailUrl.startsWith('blob') ? photo.thumbnailUrl : photo.fileUrl,
      caption: photo.name
    }));
    const sliceNumber = 1;
    let slicedPhotos;
    if (showAllPhotos) {
      slicedPhotos = photos;
    } else {
      slicedPhotos = photos.length > sliceNumber ? photos.slice(0, sliceNumber) : photos;
    }
    const restPictureNumbers = photos.length - sliceNumber;

    const styles = {
      additionalImagesBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '42px',
        background: theme.palette.text.primary,
        color: theme.palette.text.contrast,
        height: '100%'
      },
      emptyImagesBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        overflowY: 'hidden'
      }
    };

    return (
      <Box>
        <ImageListFuncional
          photos={photos}
          slicedPhotos={slicedPhotos}
          styles={styles}
          restPictureNumbers={restPictureNumbers}
          showAllPhotos={showAllPhotos}
          onRemove={onRemoveFile}
          openLightbox={this.openLightbox}
        />

        {this.state.lightboxIsOpen && (
          <Lightbox
            mainSrc={urls[this.state.currentImage].src}
            onCloseRequest={this.closeLightbox}
            nextSrc={
              urls[(this.state.currentImage + 1) % urls.length] && urls[(this.state.currentImage + 1) % urls.length].src
            }
            prevSrc={
              urls[(this.state.currentImage + urls.length - 1) % urls.length] &&
              urls[(this.state.currentImage + urls.length - 1) % urls.length].src
            }
            onMoveNextRequest={() => this.gotoNext(urls.length)}
            nextLabel="Następne"
            prevLabel="Poprzednie"
            zoomInLabel="Powiększ"
            zoomOutLabel="Pomniejsz"
            imageCaption={
              <p>
                {this.state.currentImage + 1} z {urls.length}
              </p>
            }
            onMovePrevRequest={() => this.gotoPrevious(urls.length)}
            reactModalStyle={{ overlay: { zIndex: theme.zIndex.modal } }}
            reactModalProps={{ autoFocus: true }}
            toolbarButtons={[
              <IconButton
                sx={{ color: (theme) => theme.palette.text.secondary }}
                aria-label="Pobierz"
                onClick={() => this.downloadPhoto(photos[this.state.currentImage])}
                size="large">
                <GetApp />
              </IconButton>,
              onRemoveFile ? (
                <IconButton
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  aria-label="Usuń"
                  onClick={() => {
                    const currentPhoto = this.state.currentImage;
                    this.closeLightbox();
                    onRemoveFile(photos[currentPhoto]);
                  }}
                  size="large">
                  <Delete />
                </IconButton>
              ) : null
            ]}
          />
        )}
      </Box>
    );
  }
}

PhotoGallery.propTypes = {
  photos: PropTypes.array.isRequired,
  onRemoveFile: PropTypes.func,
  showAllPhotos: PropTypes.bool
};

export default withTheme(PhotoGallery);
