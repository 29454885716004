import BaseModel from '../../BaseModel';
import { ChargingSchemeTypes } from '../../../constants/chargingSchemeTypes';
import { ChargingPrecisions } from '../../../constants/chargingPrecisions';
import { ChargingPrecisionRounding } from '../../../constants/chargingPrecisionsRounding';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import { ChargingCalculationMethod } from '../../../constants/chargingCalculationMethods';
import { ChargingDeductionBases } from '../../../constants/chargingDeductionBases';
import { ChargingDeductionModes } from '../../../constants/chargingDeductionModes';
import { TimeModel } from '../../TimeModel';

export class ChargingSchemeDetailsModel extends BaseModel {
  constructor() {
    super();
    this.selectedSchemeId = null;
    this.assignedChildrenSetup = [];
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.assignedChildrenSetup = assigned.assignedChildrenSetup.map((x) => new AssignedChildSetupModel().assign(x));
  }
}

export class AssignedChildSetupModel extends BaseModel {
  constructor() {
    super();
    this.id = null;
    this.singleUse = false;
    this.alreadyLinked = false;
    this.canLink = false;
  }
}

/*eslint no-use-before-define: 0*/
export class ChargingSchemeModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.schemeType = '';
    this.reportType = '';
    this.calculationMethod = '';
    this.rate = '';
    this.absenceReportingDeadlineTime = new TimeModel();
    this.absenceReportingDeadlineDays = undefined;
    this.absenceReportingDeadlineDisableWeekends = false;
    this.accountNumber = '';
    this.onlinePaymentsEnabled = false;
    this.availableForLegalGuardians = false;
  }

  static createEmpty(schemeTypeValue) {
    if (schemeTypeValue === ChargingSchemeTypes.hourly.value) return new HourlyChargingSchemeModel();
    if (schemeTypeValue === ChargingSchemeTypes.daily.value) return new DailyChargingSchemeModel();
    if (schemeTypeValue === ChargingSchemeTypes.monthly.value) return new MonthlyChargingSchemeModel();
    throw new `schema type ${schemeTypeValue} is not supported`();
  }

  static create(scheme) {
    if (scheme.schemeType === ChargingSchemeTypes.hourly.value) return new HourlyChargingSchemeModel().assign(scheme);
    if (scheme.schemeType === ChargingSchemeTypes.daily.value) return new DailyChargingSchemeModel().assign(scheme);
    if (scheme.schemeType === ChargingSchemeTypes.monthly.value) return new MonthlyChargingSchemeModel().assign(scheme);
    throw new `schema with type ${scheme.schemeType} is not supported`();
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.absenceReportingDeadlineTime = assigned.absenceReportingDeadlineTime
      ? new TimeModel().assign(assigned.absenceReportingDeadlineTime)
      : new TimeModel();
  }
}

export class DailyChargingSchemeModel extends ChargingSchemeModel {
  constructor() {
    super();
    this.schemeType = ChargingSchemeTypes.daily.value;
    this.calculationMethod = ChargingCalculationMethod.inArrears.value;
    this.reportType = ChargingReportTypes.stay.value;
    this.unknownStateAsPresence = false;
    this.activityId = null;
    this.mealId = null;
  }
}

export class MonthlyChargingSchemeModel extends ChargingSchemeModel {
  constructor() {
    super();
    this.schemeType = ChargingSchemeTypes.monthly.value;
    this.calculationMethod = ChargingCalculationMethod.inAdvance.value;
    this.reportType = ChargingReportTypes.stay.value;
    this.deductions = [];
    this.activityId = null;
    this.mealId = null;
  }

  onAssignment(assigned) {
    super.onAssignment(assigned);
    /* eslint no-param-reassign: 'off' */
    assigned.deductions = assigned.deductions.map((deduction, i) => new DeductionModel(i).assign(deduction));
  }
}

export class DeductionModel extends BaseModel {
  constructor(id = 0) {
    super();
    this.id = id;
    this.base = ChargingDeductionBases.month.value;
    this.threshold = null;
    this.mode = ChargingDeductionModes.reduceByAmount.value;
    this.amount = undefined;
    this.percentage = undefined;
    this.maximumAmount = undefined;
  }
}

export const NonChargeableMinutesMode = Object.freeze({
  declaredBased: { value: 'declaredBased', name: 'Liczone od zadeklarowanej godziny wejścia dziecka' },
  actualBased: { value: 'actualBased', name: 'Liczone od faktycznej godziny wejścia dziecka' }
});

export class HourlyChargingSchemeModel extends ChargingSchemeModel {
  constructor() {
    super();
    this.schemeType = ChargingSchemeTypes.hourly.value;
    this.calculationMethod = ChargingCalculationMethod.inArrears.value;
    this.reportType = ChargingReportTypes.stay.value;
    this.chargingPrecision = ChargingPrecisions.Hour.value;
    this.chargingPrecisionRounding = ChargingPrecisionRounding.down.value;
    this.nonChargeableFrom = new TimeModel();
    this.nonChargeableTo = new TimeModel();
    this.nonChargeableInMinutes = false;
    this.nonChargeableMinutesAmount = null;
    this.nonChargeableMinutesMode = null;
    this.nonChargeableToleranceInMinutes = null;
    this.chargingPrecisionCalculatePerDay = null;
    this.chargingPrecisionCalculatePerDaySeparatelyBeforeAndAfterNonChargeable = null;
  }

  onAssignment(assigned) {
    super.onAssignment(assigned);
    /* eslint no-param-reassign: 'off' */
    assigned.nonChargeableFrom = assigned.nonChargeableFrom
      ? new TimeModel().assign(assigned.nonChargeableFrom)
      : new TimeModel();
    assigned.nonChargeableTo = assigned.nonChargeableTo
      ? new TimeModel().assign(assigned.nonChargeableTo)
      : new TimeModel();
    if (assigned.nonChargeableInMinutes && !assigned.nonChargeableMinutesMode) {
      assigned.nonChargeableMinutesMode = NonChargeableMinutesMode.declaredBased.value;
    }
  }
}
