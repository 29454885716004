import api from '../api/AttendanceApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import { Person, PersonGuardian } from '../models/attendance/AttendanceModels';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';

function getCurrentUserAsPerson(getState) {
  const { userFullName, userId, profilePhoto } = getState().auth;
  const [firstName, lastName] = userFullName.split(' ');
  return new Person().assign({ firstName, lastName, id: userId, photoUrl: profilePhoto });
}

export function reportChildDropOffStart() {
  return { type: types.REPORT_CHILD_DROPPED_OFF };
}

export function reportChildDropOffCancel() {
  return { type: types.REPORT_CHILD_DROPPED_OFF_CANCEL };
}

export function reportChildDropOffAsync(childId, time, isAdaptation = false) {
  return (dispatch, getState) => {
    dispatch({ type: types.REPORT_CHILD_DROPPED_OFF_SUBMIT });
    return api
      .reportChildDroppedOff(childId, time, isAdaptation)
      .then(() => {
        const receivedBy = getCurrentUserAsPerson(getState);
        // dispatch(
        //   notificationActions.showSuccess(
        //     `${get.displayTime(time)} - zgłoszono przyjęcie dziecka ${get.childName(getState, childId)}`
        //   )
        // );
        dispatch({ type: types.REPORT_CHILD_DROPPED_OFF_SUCCESS, childId, time, receivedBy, isAdaptation });
        return `${get.displayTime(time)} - zgłoszono przyjęcie dziecka ${get.childName(getState, childId)}`;
      })
      .catch((error) => {
        if (error.status === 412) {
          dispatch({ type: types.REPORT_CHILD_DROPPED_OFF_FAILURE, errors: error.response.body });
          throw error.response.body.generalError;
        }
        // dispatch(
        //   notificationActions.showError(`Nie udało się zgłosić przyjęcia dziecka ${get.childName(getState, childId)}`)
        // );
        logger.error(error);
        throw error;
      });
  };
}

export function reportChildAttendanceNoHoursAsync(childId, droppedOffAt, pickedUpAt) {
  return (dispatch, getState) => {
    if (droppedOffAt === null || pickedUpAt === null) {
      dispatch(
        notificationActions.showError(
          `Brak zadeklarowanych godzin obecności dziecka - ${get.childName(getState, childId)}`
        )
      );
      return Promise.reject();
    }
    return api
      .setDailyAttendanceEntry(childId, droppedOffAt, null, pickedUpAt)
      .then(() => {
        const receivedBy = getCurrentUserAsPerson(getState);
        dispatch(notificationActions.showSuccess(`Zgłoszono obecność dziecka - ${get.childName(getState, childId)}`));
        dispatch({
          type: types.REPORT_CHILD_ATTENDANCE_NO_HOURS_SUCCESS,
          childId,
          droppedOffAt,
          pickedUpAt,
          receivedBy
        });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(`Nie udało się zgłosić obecności dziecka - ${get.childName(getState, childId)}`)
        );
        return dispatch({ type: types.REPORT_CHILD_ATTENDANCE_NO_HOURS_FAILURE, errors: error.response.body });
      });
  };
}

export function reportChildPickUpStart() {
  return { type: types.REPORT_CHILD_PICKED_UP };
}

export function reportChildPickUpCancel() {
  return { type: types.REPORT_CHILD_PICKED_UP_CANCEL };
}

export function reportChildPickUpAsync(childId, guardianId, time) {
  return (dispatch, getState) => {
    dispatch({ type: types.REPORT_CHILD_PICKED_UP_SUBMIT });
    return api
      .reportChildPickedUp(childId, guardianId, time)
      .then(() => {
        const updateChild = getState().children.find((c) => c.id === childId);
        if (updateChild) {
          const { id, firstName, lastName, relationship, photoUrl } = [
            ...updateChild.legalGuardians,
            ...updateChild.guardians
          ].find((g) => g.id === guardianId);
          const pickedUpBy = new PersonGuardian().assign({ id, firstName, lastName, relationship, photoUrl });
          const releasedBy = getCurrentUserAsPerson(getState);
          dispatch({ type: types.REPORT_CHILD_PICKED_UP_SUCCESS, childId, pickedUpBy, time, releasedBy });
        }
        // dispatch(
        //   notificationActions.showSuccess(
        //     `${get.displayTime(time)} - zgłoszono odbiór dziecka ${get.childName(getState, childId)}`
        //   )
        // );
        return `${get.displayTime(time)} - zgłoszono odbiór dziecka ${get.childName(getState, childId)}`;
      })
      .catch((error) => {
        if (error.status === 412) {
          dispatch({ type: types.REPORT_CHILD_PICKED_UP_FAILURE, errors: error.response.body });
          throw error.response.body.generalError;
        }
        // dispatch(
        //   notificationActions.showError(`Nie udało się zgłosić odbioru dziecka ${get.childName(getState, childId)}`)
        // );
        logger.error(error);
        throw error;
      });
  };
}

export function reportChildAbsenceStart() {
  return { type: types.REPORT_CHILD_ABSENCE };
}

export function reportChildAbsenceCancel() {
  return { type: types.REPORT_CHILD_ABSENCE_CANCEL };
}

export function reportChildAbsenceAsync(childId, from, to, notes = 'empty') {
  return (dispatch, getState) => {
    dispatch({ type: types.REPORT_CHILD_ABSENCE_SUBMIT });
    return api
      .reportChildAbsence(childId, from, to, notes)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Zgłoszono nieobecność dziecka ${get.childName(getState, childId)} ${get.displayDateRange(from, to)}`
          )
        );
        const absenceReportedBy = getCurrentUserAsPerson(getState);
        dispatch({ type: types.REPORT_CHILD_ABSENCE_SUCCESS, childId, from, to, notes, absenceReportedBy });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się zgłosić nieobecności dziecka ${get.childName(getState, childId)} ${get.displayDateRange(
              from,
              to
            )}`
          )
        );
        logger.error(error);
        return dispatch({ type: types.REPORT_CHILD_ABSENCE_FAILURE, errors: error.response.body });
      });
  };
}

export function revertChildDropOffAsync(childId, date) {
  return (dispatch, getState) => {
    dispatch({ type: types.REVERT_CHILD_DROPPED_OFF_REPORT });
    return api
      .revertChildDroppedOff(childId, date)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Wycofano przyjęcie dziecka ${get.childName(getState, childId)} z dnia ${get.displayDate(date)}`
          )
        );
        dispatch({ type: types.REVERT_CHILD_DROPPED_OFF_REPORT_SUCCESS, childId, date });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się wycofać przyjęcie dziecka ${get.childName(getState, childId)} z dnia ${get.displayDate(
              date
            )}`
          )
        );
        return logger.error(error);
      });
  };
}

export function revertChildPickUpAsync(childId, date) {
  return (dispatch, getState) => {
    dispatch({ type: types.REVERT_CHILD_PICKED_UP_REPORT });
    return api
      .revertChildPickedUp(childId, date)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Wycofano odbiór dziecka ${get.childName(getState, childId)} z dnia ${get.displayDate(date)}`
          )
        );
        dispatch({ type: types.REVERT_CHILD_PICKED_UP_REPORT_SUCCESS, childId, date });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się wycofać odbioru dziecka ${get.childName(getState, childId)} z dnia ${get.displayDate(date)}`
          )
        );
        return logger.error(error);
      });
  };
}

export function revertChildAbsenceAsync(childId, from, to) {
  return (dispatch, getState) => {
    dispatch({ type: types.REVERT_CHILD_ABSENCE });
    return api
      .revertChildAbsence(childId, from, to)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(
            `Wycofano nieobecność dziecka ${get.childName(getState, childId)} ${get.displayDateRange(from, to)}`
          )
        );
        dispatch({ type: types.REVERT_CHILD_ABSENCE_SUCCESS, childId, from, to });
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się wycofać nieobecność dziecka ${get.childName(getState, childId)} ${get.displayDateRange(
              from,
              to
            )}`
          )
        );
        return logger.error(error);
      });
  };
}

export function revertChildAttendanceAsync(childId, date) {
  return (dispatch, getState) => {
    return api
      .revertChildPickedUp(childId, date)
      .then(() => {
        api
          .revertChildDroppedOff(childId, date)
          .then(() => {
            api
              .reportChildAbsence(childId, date, date, '')
              .then(() => {
                const absenceReportedBy = getCurrentUserAsPerson(getState);
                dispatch({ type: types.REVERT_CHILD_ATTENDANCE_SUCCESS, childId, date, absenceReportedBy });
                dispatch(
                  notificationActions.showSuccess(
                    `Wycofano obecność dziecka ${get.childName(getState, childId)} ${get.displayDate(date)}`
                  )
                );
              })
              .catch((err) => {
                throw err;
              });
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        dispatch(
          notificationActions.showError(`Nie udało się wycofać obecności dziecka ${get.childName(getState, childId)} `)
        );
      });
  };
}

export function loadCurrentAttendanceAsync(groupId) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_CURRENT_ATTENDANCE });
    return api
      .getTodayAttendance(groupId)
      .then((attendanceJournal) => dispatch({ type: types.LOAD_CURRENT_ATTENDANCE_SUCCESS, attendanceJournal }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać listy obecności dla grupy '${get.groupName(getState, groupId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function loadChildAttendancesAsync(childId, date) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_CHILD_ATTENDANCES });
    return api
      .getChildAttendances(childId, date)
      .then((attendances) => dispatch({ type: types.LOAD_CHILD_ATTENDANCES_SUCCESS, attendances }))
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się pobrać listy obecności dla dziecka '${get.childName(getState, childId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function loadAllCurrentAttendanceAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_ALL_CURRENT_ATTENDANCE });
    return api
      .getAllTodayAttendances()
      .then((attendanceJournals) => dispatch({ type: types.LOAD_ALL_CURRENT_ATTENDANCE_SUCCESS, attendanceJournals }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać listy obecności'));
        return logger.error(error);
      });
  };
}

export function loadTodayAttendanceSummaryAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_ATTENDANCE_SUMMARY });
    return api
      .getTodayAttendanceSummary()
      .then((attendanceSummary) => dispatch({ type: types.LOAD_ATTENDANCE_SUMMARY_SUCCESS, attendanceSummary }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać statystyk obecności na dziś'));
        return logger.error(error);
      });
  };
}

export function loadAbsentTomorrow() {
  return async (dispatch) => {
    dispatch({ type: types.LOAD_ABSENCE_TOMORROW });
    try {
      const response = await api.getAbsentTomorrow();
      dispatch({
        type: types.LOAD_ABSENCE_TOMORROW_SUCCESS,
        response
      });
    } catch (error) {
      dispatch(notificationActions.showError(`Nie udało się pobrać listy osób nieobecnych jutro.`));
    }
  };
}

export function loadCurrentActivitiesAttendanceAsync(activityId) {
  return async () => {
    try {
      const response = await api.getTodayActivityAttendance(activityId);
      return response;
    } catch (error) {
      return logger.error(error);
    }
  };
}

export function reportChildActivityAttendanceAsync(childId, activityId, from, to) {
  return async (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_ACTIVITY_ATTENDANCE_SUBMIT });
    try {
      const response = await api.setDailyActivityAttendanceEntry(childId, activityId, from, to);
      dispatch({ type: types.REPORT_CHILD_ACTIVITY_ATTENDANCE_SUCCESS, response });
      dispatch(notificationActions.showSuccess(`Pomyślnie zgłoszono obecność dziecka`));
    } catch (error) {
      dispatch({ type: types.REPORT_CHILD_ACTIVITY_ATTENDANCE_FAILURE });
      dispatch(notificationActions.showError(`Nie udało się zgłosić obecności dziecka`));
    }
  };
}

export function reportChildActivityAbsenceAsync(childId, activityId, from, to) {
  return async (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_ACTIVITY_ABSENCE_SUBMIT });
    try {
      const response = await api.reportChildActivityAbsence(childId, activityId, from, to, '');
      dispatch({ type: types.REPORT_CHILD_ACTIVITY_ABSENCE_SUCCESS, response });
      dispatch(notificationActions.showSuccess(`Pomyślnie zgłoszono nieobecność dziecka`));
    } catch (error) {
      dispatch({ type: types.REPORT_CHILD_ACTIVITY_ABSENCE_FAILURE });
      dispatch(notificationActions.showError(`Nie udało się zgłosić nieobecności dziecka`));
    }
  };
}

export function loadActivitiesAttendanceCalendarAsync(activityId, childId, date) {
  return async () => {
    try {
      const response = await api.getMonthlyActivityAttendance(activityId, childId, date);
      return response;
    } catch (error) {
      return logger.error(error);
    }
  };
}
