import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const FlagAttributeTemplate = ({ id, name, value, onChange, className }) => {
  return (
    <FormControlLabel
      label={name}
      control={
        <Checkbox
          checked={value || false}
          onClick={() => onChange({ target: { name: id, value: !value } })}
          className={className}
        />
      }
    />
  );
};

FlagAttributeTemplate.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default FlagAttributeTemplate;
