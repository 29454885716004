import { Box, FormControl, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { FormikTextField } from '../../components/formik/FormikTextField';
import FormikRichMultiSelectField from '../../components/formik/RichMultiSelectField/FormikRichMultiSelectField';
import * as AlbumTypes from '../../constants/groupsAndActivitiesTypes';
import SingleMemberChip from '../../components/gallery/Components/Albums/SingleMemberChip';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import { useField, useFormikContext } from 'formik';
import BaseModel from '../../models/BaseModel';
import PropTypes from 'prop-types';
import SquareRadioButton from '../../components/common/squareRadioButton/SquareRadioButton';
import RadioGroup from '@mui/material/RadioGroup';
import SpecificIcon from '../../components/groupsAndActivities/SpecificIcon';
import { featuresFromPrefixValidator } from '../../utils/featuresFromPrefixValidator';

const nadarzyn = featuresFromPrefixValidator();

const OPTIONS = {
  global: {
    label: nadarzyn ? 'Publiczny szkoły' : 'Publiczny placówki',
    value: AlbumTypes.PUBLIC,
    icon: <SpecificIcon type={AlbumTypes.PUBLIC} />
  },

  activities: {
    label: 'Zajęcia dodatkowe',
    value: AlbumTypes.ACTIVITY,
    icon: <SpecificIcon type={AlbumTypes.MORE_ACTIVITIES} />
  },

  groups: {
    label: 'Grupy',
    value: AlbumTypes.GROUP,
    icon: <SpecificIcon type={AlbumTypes.MORE_GROUPS} />
  }
};

const SubscriptionForm = ({ groups, activities, isEdit }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField('type');
  const { value: type } = field;

  useEffect(() => {
    const { value } = field;
    if (value === OPTIONS.global.value) {
      setFieldValue('isPublic', true);
    }
  }, []);

  const handleTriangleChange = (e) => {
    const { value } = e.target;
    setFieldValue('limitedToGroups', []);
    setFieldValue('limitedToActivities', []);
    if (value === OPTIONS.global.value) setFieldValue('isPublic', true);
    else setFieldValue('isPublic', false);
    setFieldValue('type', e.target.value);
  };

  return (
    <Box sx={{ pr: 3 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={7}>
          <FormControl component="fieldset" fullWidth>
            <Typography variant="subtitle1" sx={{ display: 'flex', justifyContent: 'center', opacity: 0.5, mb: 4 }}>
              Wybierz typ albumu
            </Typography>

            <RadioGroup
              row
              sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', textAlign: 'center' }}
              aria-label="position"
              name="position"
              value={type}
              onChange={handleTriangleChange}>
              <SquareRadioButton {...OPTIONS.global} checked={OPTIONS.global.value === type} disabled={isEdit} />
              {!nadarzyn && (
                <SquareRadioButton
                  {...OPTIONS.activities}
                  checked={OPTIONS.activities.value === type}
                  disabled={isEdit}
                />
              )}
              <SquareRadioButton {...OPTIONS.groups} checked={OPTIONS.groups.value === type} disabled={isEdit} />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box display="flex" flexDirection="column" alignItems="start" justifyContent="center" direction="column">
            <FormikTextField name="name" label="Nazwa albumu*" fullWidth multiline maxRows={2} />
            <FormikTextField name="description" label="Opis albumu" fullWidth multiline maxRows={4} />
            <Box alignItems="center" sx={{ width: 'auto', mt: 2 }}>
              <FormikCheckbox
                name="isPublic"
                label="Udostępnij album dla całej placówki"
                disabled={type === OPTIONS.global.value}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {type !== OPTIONS.global.value && (
        <Grid container sx={{ pl: 3 }}>
          {type === OPTIONS.groups.value && (
            <FormikRichMultiSelectField
              options={groups}
              name="limitedToGroups"
              label="Wybierz grupy*"
              type={AlbumTypes.GROUP}
              isSelectAll={true}
            />
          )}
          {type === OPTIONS.activities.value && (
            <FormikRichMultiSelectField
              options={activities}
              name="limitedToActivities"
              label="Wybierz zajęcia dodatkowe*"
              type={AlbumTypes.ACTIVITY}
              selectElement={<SingleMemberChip />}
              chipElement={<SingleMemberChip />}
              isSelectAll={true}
            />
          )}
        </Grid>
      )}
    </Box>
  );
};

SubscriptionForm.propTypes = {
  groups: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  isEdit: PropTypes.bool
};

export default SubscriptionForm;

export class InitialValues extends BaseModel {
  constructor() {
    super();
    this.name = '';
    this.description = '';
    this.limitedToGroups = [];
    this.limitedToActivities = [];
    this.isPublic = false;
    this.type = OPTIONS.global.value;
  }

  setType(type) {
    this.type = type;
  }
}

export const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Pole jest wymagane').max(100, 'Opis może zawierać maksymalnie 100 znaków'),
  description: Yup.string().max(500, 'Opis może zawierać maksymalnie 500 znaków')
});
export const validate = ({ type, limitedToActivities, limitedToGroups }) => {
  const errors = {};
  switch (type) {
    case OPTIONS.activities.value:
      if (!limitedToActivities.length) errors.limitedToActivities = 'Należy wybrać conajmniej jedno zajęcie dodatkowe';
      break;
    case OPTIONS.groups.value:
      if (!limitedToGroups.length) errors.limitedToGroups = 'Należy wybrać conajmniej jedną grupę';
      break;
    default:
      return null;
  }

  return errors;
};
