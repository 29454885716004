import PropTypes from 'prop-types';
import React from 'react';
import { useField, useFormikContext } from 'formik';
import PersonAvatar from '../forms/PersonAvatar';
import SingleMemberChip from '../gallery/Components/Albums/SingleMemberChip';
import * as AlbumTypes from '../../constants/groupsAndActivitiesTypes';
import { Typography, Autocomplete, TextField, List, ListItem } from '@mui/material';

const FormikAutocompletePickChild = ({ name, childrenList, groups, sx }) => {
  const childs = childrenList
    .map((item) => {
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        enrolledToGroup: item.enrolledToGroup ? item.enrolledToGroup : { id: 'brak' },
        enrolledToActivities: item.enrolledToActivities,
        id: item.id
      };
    })
    .sort((a, b) => {
      const aVal = a.enrolledToGroup.id;
      const bVal = b.enrolledToGroup.id;
      if (aVal < bVal) return -1;
      if (aVal > bVal) return 1;
      return 0;
    });
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const autoCompleteConfig = {
    childs,
    autoHighlight: true,
    value: field.value,
    disabled: !!field.value
  };

  return (
    <Autocomplete
      {...autoCompleteConfig}
      options={childs}
      groupBy={(option) => option.enrolledToGroup.name}
      getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
      sx={{ width: 300, ...sx }}
      renderInput={(params) => <TextField {...params} label="Wybierz dziecko" variant="standard" />}
      onChange={(event, newValue) => {
        setFieldValue(name, newValue);
      }}
      renderOption={(props, option) => {
        return (
          <ListItem
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
            {...props}
            key={props.id}>
            <PersonAvatar
              initials
              alt={option.firstName}
              url={option.photoUrl}
              firstName={option.firstName}
              lastName={option.lastName}
            />
            <Typography sx={{ ml: 1, fontWeight: (theme) => theme.typography.fontWeightMedium }}>
              {option.firstName + ' ' + option.lastName}
            </Typography>
          </ListItem>
        );
      }}
      renderGroup={(params) => {
        const groupData = groups.find((item) => item.name === params.group);
        return (
          <List
            key={params.key}
            sx={{
              position: 'relative'
            }}>
            <ListItem sx={{ pl: 1 }} key={params.key}>
              <SingleMemberChip
                type={groupData ? AlbumTypes.GROUP : AlbumTypes.NONE}
                name={groupData ? groupData.name : 'Brak grupy'}
                color={groupData ? groupData.color : 'error'}
                onClick={(e) => e.stopPropagation()}
              />
            </ListItem>

            {params.children}
          </List>
        );
      }}
    />
  );
};

FormikAutocompletePickChild.propTypes = {
  childrenList: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
};

export default FormikAutocompletePickChild;
