import { Typography } from '@mui/material';
import React from 'react';
import { Box } from '@mui/system';
import MealScale from './welcomeScreen/MealScale';

export const MealsEatenPercentages = ({ onSelection, mealsScale, formname, disabledMeals }) => {
  return (
    <>
      <Typography sx={{ my: 1 }}>Ilość zjedzonego posiłku</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
        <MealScale
          title="śniadanie"
          disabledMeals={disabledMeals}
          mealsScale={mealsScale}
          onSelection={onSelection}
          formname={formname}
          type="breakfast"
        />
        <MealScale
          title="drugie śniadanie"
          disabledMeals={disabledMeals}
          mealsScale={mealsScale}
          onSelection={onSelection}
          formname={formname}
          type="secondBreakfast"
        />
        <MealScale
          title="zupa"
          disabledMeals={disabledMeals}
          mealsScale={mealsScale}
          onSelection={onSelection}
          formname={formname}
          type="soup"
        />
        <MealScale
          title="obiad"
          disabledMeals={disabledMeals}
          mealsScale={mealsScale}
          onSelection={onSelection}
          formname={formname}
          type="lunch"
        />
        <MealScale
          title="podwieczorek"
          disabledMeals={disabledMeals}
          mealsScale={mealsScale}
          onSelection={onSelection}
          formname={formname}
          type="afternoonMeal"
        />
      </Box>
    </>
  );
};
