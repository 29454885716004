import PropTypes from 'prop-types';
import React from 'react';
// import ActionConfirmationMenuItem from '../../../forms/ActionConfirmationMenuItem';
import EmptyState from '../../../common/EmptyState';
import TemplatesProvider from '../../../common/attributes/definitionTemplates/TemplatesProvider';

//v4
import ActionConfirmationButton from '../../../forms/buttons/ActionConfirmationButton';
import StackRow from '../../../common/StackRow';
import { Box, Button, Stack, Typography } from '@mui/material';

const AttributesList = ({ attributes, onChange, onRemove, onCreate }) => {
  const renderListItem = (att) => {
    return (
      <StackRow key={att.id} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <TemplatesProvider
          attributeDefinition={att}
          onChange={(e) => onChange(Object.assign({}, att, { defaultValue: e.target.value }))}
        />
        <ActionConfirmationButton
          variant="clearText"
          onConfirm={() => onRemove(att.id)}
          confirmationText="Czy na pewno chcesz usunąć atrybut?"
          onCancel={() => {}}
          confirmationTitle="Usuń atrybut"
          actionLabel="usuń"
          confirmLabel="usuń"
        />
      </StackRow>
    );
  };

  return (
    <Stack>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Atrybuty</Typography>
        <Button variant="contained" aria-label="Dodaj Atrybut" onClick={onCreate} sx={{ my: 2 }}>
          Dodaj Atrybut
        </Button>
      </Box>
      {!attributes.length ? (
        <EmptyState contrast message="Nie zdefiowano jeszcze żadnych atrybutów" />
      ) : (
        <Box>{attributes.map((att) => renderListItem(att))}</Box>
      )}
    </Stack>
  );
};

AttributesList.propTypes = {
  attributes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired
};

export default AttributesList;
