'use strict';

import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function generateReportUiReducer(state = initialState.generateReportUi, action) {
  switch (action.type) {
    case types.GENERATE_REPORT: {
      return UiModel.start();
    }
    case types.GENERATE_REPORT_CANCEL: {
      return UiModel.cancel();
    }
    case types.GENERATE_REPORT_SUBMIT: {
      return UiModel.submit();
    }
    case types.GENERATE_REPORT_SUCCESS: {
      return UiModel.success();
    }
    case types.GENERATE_REPORT_FAILURE: {
      return UiModel.failure(action.errors);
    }
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.generateReportUi);
    }
    default:
      return state;
  }
}
