import PropTypes from 'prop-types';
import React from 'react';
import WelcomeScreen from '../../common/welcomeScreen/WelcomeScreen';
import AccountActivationContainer from './Containers/AccountActivationContainer';

const AccountActivationPage = ({ location }) => {
  return (
    <WelcomeScreen>
      <AccountActivationContainer location={location} />
    </WelcomeScreen>
  );
};

AccountActivationPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default AccountActivationPage;
