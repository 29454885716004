import React from 'react';
import PropTypes from 'prop-types';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import TableChartIcon from '@mui/icons-material/TableChart';
import GridOnIcon from '@mui/icons-material/GridOn';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useToolkitStyles } from './ToolkitStyles';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDial from '@mui/material/SpeedDial';
import makeStyles from '@mui/styles/makeStyles';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MobileSearch from '../MobileSearch/MobileSearch';
import ShareIcon from '@mui/icons-material/Share';

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: 80,
      right: theme.spacing(2)
    }
  },
  actionButton: {
    background: theme.palette.background.default
  },
  actions: {
    alignItems: 'flex-end'
  }
}));

const MobileToolkit = ({
  addFile,
  downloadFiles,
  shareFiles,
  deleteFiles,
  searchText,
  onSearch,
  onClearSearch,
  selectedView,
  onSelectedView,
  cancelSelectedFiles,
  onOpenMoveToFolder,
  isOpen,
  handleOpen,
  handleClose,
  isSelected,
  isManagement
}) => {
  const cl = useToolkitStyles({ isMobile: false });
  const clInside = useStyles();
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const actions = [
    {
      icon: <GetAppIcon sx={{ color: (theme) => theme.palette.color.color22 }} />,
      name: 'Pobierz zaznaczone pliki',
      onClick: downloadFiles
    },
    isManagement && {
      icon: <ShareIcon sx={{ color: (theme) => theme.palette.color.color22 }} />,
      name: 'Upublicznij zaznaczone pliki',
      onClick: shareFiles
    },
    isManagement && {
      icon: <DriveFileMoveIcon sx={{ color: (theme) => theme.palette.color.color22 }} />,
      name: 'Przenieś zaznaczone pliki do folderu',
      onClick: onOpenMoveToFolder
    },
    isManagement && {
      icon: <DeleteForeverIcon sx={{ color: (theme) => theme.palette.color.color22 }} />,
      name: 'Usuń zaznaczone pliki',
      onClick: deleteFiles
    },
    {
      icon: <CancelOutlinedIcon sx={{ color: (theme) => theme.palette.color.color22 }} />,
      name: 'Anuluj',
      onClick: () => {
        cancelSelectedFiles();
        handleClose();
      }
    }
  ];

  return (
    <SpeedDial
      ariaLabel="udostępnianie dla poszczególnych ról lub pojedyńczych osób"
      hidden={false}
      className={clInside.speedDial}
      icon={<MenuOpenIcon />}
      onClose={() => {
        handleClose();
        setIsSearchOpen(false);
      }}
      onOpen={handleOpen}
      sx={{
        alignItems: 'flex-end',
        '& .MuiFab-primary': { background: (theme) => theme.palette.color.color25 },
        '& .MuiFab-primary:hover': { background: (theme) => theme.palette.color.color25 }
      }}
      classes={{ actions: clInside.actions }}
      open={isOpen}
      direction="down">
      {[
        <MobileSearch
          key="search"
          searchText={searchText}
          onSearch={onSearch}
          isSearchOpen={isSearchOpen}
          setIsSearchOpen={setIsSearchOpen}
          onClearSearch={onClearSearch}
        />,
        <SpeedDialAction
          key="changeTable"
          title={`Przełącz na ${selectedView === 0 ? 'Tabele' : 'Kafelki'}`}
          icon={
            selectedView === 0 ? <GridOnIcon className={cl.tabIcons} /> : <TableChartIcon className={cl.tabIcons} />
          }
          onClick={() => onSelectedView(null, selectedView === 0 ? 1 : 0)}
        />,
        isManagement && (
          <SpeedDialAction
            key="add"
            title="Dodaj plik"
            icon={<UploadFileOutlinedIcon sx={{ color: (theme) => theme.palette.color.color22 }} />}
            onClick={addFile}
          />
        ),
        ...(isSelected
          ? actions.map(
              (action) =>
                action && (
                  <SpeedDialAction
                    className={clInside.actionButton}
                    key={action.name}
                    title={action.name}
                    {...action}
                  />
                )
            )
          : [])
      ]}
    </SpeedDial>
  );
};

MobileToolkit.propTypes = {
  addFile: PropTypes.func.isRequired,
  deleteFiles: PropTypes.func.isRequired,
  downloadFiles: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelectedView: PropTypes.func.isRequired,
  shareFiles: PropTypes.func.isRequired,
  selectedView: PropTypes.number.isRequired,
  cancelSelectedFiles: PropTypes.func.isRequired,
  handleOpenMoveToFolderDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isManagement: PropTypes.bool.isRequired
};

export default MobileToolkit;
