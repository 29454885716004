import userRoles from '../../../constants/userRoles';
import uuid from 'uuid/v4';

export const reducedUserRolesOptions = [
  {
    id: uuid(),
    name: 'Uczniowie',
    role: userRoles.legalGuardian,
    tooltip: 'Udostępniony dla uczniów'
  },
  {
    id: uuid(),
    name: 'Nauczyciele',
    role: userRoles.staffMemberTeacher,
    tooltip: 'Udostępniony dla nauczycieli'
  }
];
