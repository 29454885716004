import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as authActions from '../../actions/authActions';
import * as testActions from '../../actions/testActions';
import * as colorContrastActions from '../../actions/colorContrastActions';
import PersonAvatar from '../forms/PersonAvatar';
import { Link } from 'react-router';
import { routePaths } from '../../routePaths';
import DotsMenu from '../common/menu/DotsMenu';
import { Alert, Box, Divider, MenuItem, Typography } from '@mui/material';
import ContrastRoundedIcon from '@mui/icons-material/ContrastRounded';
import IconButtonPipe from '../common/IconButtonPipe';
import { CHANGE_THEME } from '../../actions/actionTypes';
import { themeTypes } from '../../styles/appTheme';
import LinkAdapter from '../common/LinkAdapter/LinkAdapter';
import RichTooltip from '../richTooltip/richTooltip';
import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material';

const NavigationAvatar = ({ userFirstName, userLastName, profilePhoto, actions }) => {
  const dispatch = useDispatch();
  const selectedTheme = useSelector((state) => state.theme);
  const auth = useSelector((state) => state.auth);

  const localStorageThemeTooltip = JSON.parse(localStorage.getItem('themeTooltip'));
  const localStorageContrastTooltip = JSON.parse(localStorage.getItem('contrastTooltip'));

  const [isThemeTooltipOpen, setIsThemeTooltipOpen] = useState(!localStorageThemeTooltip);
  const [isContrastTooltipOpen, setIsContrastTooltipOpen] = useState(localStorageContrastTooltip);

  const handleChangeTheme = (payload) => {
    localStorage.setItem('previousTheme', selectedTheme);
    localStorage.setItem('theme', payload);
    dispatch({ type: CHANGE_THEME, payload });
  };

  const handleThemeTooltip = () => {
    localStorage.setItem('themeTooltip', JSON.stringify(true));
    setIsThemeTooltipOpen(false);
    setIsContrastTooltipOpen(true);
  };

  const handleContrastTooltip = () => {
    setIsContrastTooltipOpen(false);
    localStorage.setItem('contrastTooltip', JSON.stringify(false));
  };

  const themeOptions = useMemo(() => {
    return Object.keys(themeTypes).map((key) => (
      <MenuItem
        selected={selectedTheme === themeTypes[key].value}
        key={themeTypes[key].value}
        onClick={() => handleChangeTheme(themeTypes[key].value)}>
        {themeTypes[key].name}
      </MenuItem>
    ));
  }, [selectedTheme, themeTypes]);

  return (
    <Box
      sx={{
        textAlign: 'end',
        color: 'white',
        mt: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        flex: '0 1 auto',
        justifyContent: 'flex-end',
        alignItems: 'center',
        textDecoration: 'none'
      }}>
      <Typography
        sx={{
          mx: 5,
          font: 'normal normal 300 16px League Spartan',
          letterSpacing: '0.12px',
          color: (theme) => theme.palette.color.primary,
          opacity: 0.9,
          fontWeight: 500,
          '@media (max-width: 1050px)': {
            display: 'none'
          }
        }}>{`${userFirstName} ${userLastName}`}</Typography>
      <Link
        to={routePaths.userProfile}
        title="Mój profil"
        style={{ textDecoration: 'none' }} //Element react-router , musi byc style
      >
        <PersonAvatar alt={userFirstName} url={profilePhoto} firstName={userFirstName} lastName={userLastName} />
      </Link>

      <IconButtonPipe
        onClick={() =>
          selectedTheme === 'contrast'
            ? handleChangeTheme(localStorage.getItem('previousTheme') === 'dark' ? 'light' : 'dark')
            : selectedTheme === 'dark'
            ? handleChangeTheme('light')
            : handleChangeTheme('dark')
        }
        sx={{ ml: 1, color: (theme) => theme.palette.color.primary }}
        aria-label="zmien motyw">
        {selectedTheme === 'light' && <DarkModeOutlined />}
        {selectedTheme === 'dark' && <LightModeOutlined />}
        {selectedTheme === 'contrast' && localStorage.getItem('previousTheme') === 'light' && <DarkModeOutlined />}
        {selectedTheme === 'contrast' && localStorage.getItem('previousTheme') === 'dark' && <LightModeOutlined />}
      </IconButtonPipe>

      {isContrastTooltipOpen ? (
        <RichTooltip
          content={
            <Alert severity="info" onClose={handleContrastTooltip}>
              <Typography sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                Klikając w ikonę <ContrastRoundedIcon sx={{ mx: 1 }} /> możesz zmienić kontrast aplikacji
              </Typography>
            </Alert>
          }
          open={isContrastTooltipOpen}
          placement="bottom">
          <IconButtonPipe
            onClick={() =>
              selectedTheme === 'contrast'
                ? handleChangeTheme(localStorage.getItem('previousTheme') || 'contrast')
                : handleChangeTheme('contrast')
            }
            sx={{ ml: 1, color: (theme) => theme.palette.color.primary }}
            aria-label="zmien kontrast">
            <ContrastRoundedIcon />
          </IconButtonPipe>
        </RichTooltip>
      ) : (
        <IconButtonPipe
          onClick={() =>
            selectedTheme === 'contrast'
              ? handleChangeTheme(localStorage.getItem('previousTheme') || 'contrast')
              : handleChangeTheme('contrast')
          }
          sx={{ ml: 1, color: (theme) => theme.palette.color.primary }}
          aria-label="zmien kontrast">
          <ContrastRoundedIcon />
        </IconButtonPipe>
      )}
      {isThemeTooltipOpen ? (
        <RichTooltip
          content={
            <Alert severity="info" onClose={handleThemeTooltip}>
              <Typography sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                Możesz zmienić motyw aplikacji klikając w ikonę
                <DarkModeOutlined sx={{ mx: 1 }} />
              </Typography>
            </Alert>
          }
          open={isThemeTooltipOpen}
          placement="bottom">
          <DotsMenu buttonStyle={{ color: (theme) => theme.palette.color.primary }}>
            {() => [
              <MenuItem key={1} component={LinkAdapter} to={routePaths.userProfile}>
                Profil
              </MenuItem>,
              <Divider key="d1" />,
              ...themeOptions,
              <Divider key="d2" />,
              <MenuItem key={2} onClick={() => actions.signOut()}>
                Wyloguj
              </MenuItem>
            ]}
          </DotsMenu>
        </RichTooltip>
      ) : (
        <DotsMenu buttonStyle={{ color: (theme) => theme.palette.color.primary }}>
          {() => [
            <MenuItem key={1} component={LinkAdapter} to={routePaths.userProfile}>
              Profil
            </MenuItem>,
            <Divider key="d1" />,
            <MenuItem key={2} onClick={() => actions.signOut(auth?.trustedProfileUserId)}>
              Wyloguj
            </MenuItem>
          ]}
        </DotsMenu>
      )}
    </Box>
  );
};

NavigationAvatar.propTypes = {
  actions: PropTypes.object.isRequired,
  userFirstName: PropTypes.string.isRequired,
  userLastName: PropTypes.string.isRequired,
  profilePhoto: PropTypes.string
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...testActions, ...authActions, ...colorContrastActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationAvatar);
