import React from 'react';
import PropTypes from 'prop-types';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import SpecialistWorkNotesPage from './SpecialistWorkNotesPage';

const SpecialistWorkNotesWrapper = ({ location }) => {
  return (
    <LoadingRenderWrapper>
      <SpecialistWorkNotesPage location={location} />
    </LoadingRenderWrapper>
  );
};

SpecialistWorkNotesWrapper.alerpropTypes = {
  location: PropTypes.object.isRequired
};

export default SpecialistWorkNotesWrapper;
