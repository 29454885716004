import PropTypes from 'prop-types';
import React from 'react';
import SelectField from '../../common/SelectField';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { Box, MenuItem } from '@mui/material';
import SchoolYearPicker from '../../common/SchoolYearPicker';

const KindergartenActivityLog = ({ groupId, year, groups, onChange, errors }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FieldsetWrapper
        legend="Pola dla raportu dziennik zajęć przedszkola"
        sx={{ color: (theme) => theme.palette.color.contrast }}>
        <SelectField
          errorText={errors.groupId}
          contrast
          style={{ width: 'auto', overflow: 'hidden', mb: 1 }}
          floatingLabelText="Grupa"
          id="groupId"
          value={groupId}
          onChange={(event) => {
            onChange({ target: { name: 'groupId', value: event.target.value } });
          }}>
          {groups
            .filter((el) => el.id !== 'all')
            .map((group) => {
              return (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              );
            })}
        </SelectField>

        <SchoolYearPicker
          onChange={(event) => {
            onChange({ target: { name: 'year', value: event.target.value } });
          }}
          value={year}
          contrast
          label="Rok"
          labelId="rok szkolny"
          minYear={new Date().getFullYear() - 5}
          maxYear={new Date().getFullYear()}></SchoolYearPicker>
      </FieldsetWrapper>
    </Box>
  );
};

KindergartenActivityLog.propTypes = {
  groupId: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default KindergartenActivityLog;
