import React from 'react';
import { featuresFromPrefixValidator } from '../../utils/featuresFromPrefixValidator';

import logoParents from '../../img/sign-in/4parens-logo.png';
import logoStudents from '../../img/sign-in/4students.png';

import bobrowice from '../../img/herby/bobrowice.png';
import bytom_odrzanski from '../../img/herby/bytom_odrzanski.jpg';
import dabie from '../../img/herby/dabie.png';
import dobiegniew from '../../img/herby/dobiegniew.jpg';
import lagow from '../../img/herby/lagow.png';
import miedzyrzecz from '../../img/herby/miedzyrzecz.jpg';
import otyn from '../../img/herby/otyn.png';
import santok from '../../img/herby/santok.jpg';
import slawa from '../../img/herby/slawa.png';
import torzym from '../../img/herby/torzym.png';

const DOMAINS_WITH_LOGO = [
  {
    domains: [
      'https://pdychow.4parents.pl',
      'https://pbobrowice.4parents.pl',
      'https://spdychow.4parents.pl',
      'https://spbobrowice.4parents.pl'
    ],
    logo: bobrowice
  },
  {
    domains: ['https://przedszkolebytomo.4parents.pl', 'https://spbytomo.4parents.pl'],
    logo: bytom_odrzanski
  },
  {
    domains: [
      'https://spdabie.4parents.pl',
      'https://sppolupnie.4parents.pl',
      'https://spkosierz.4parents.pl',
      'https://spgronowo.4parents.pl',
      'https://spszczawno.4parents.pl',
      'https://ppolupnie.4parents.pl',
      'https://kdmszczawno.4parents.pl'
    ],
    logo: dabie
  },
  {
    domains: ['https://spdobiegniew.4parents.pl', 'https://przedszkoledobiegniew.4parents.pl'],
    logo: dobiegniew
  },
  {
    domains: [
      'https://splagow.4parents.pl',
      'https://plagow.4parents.pl',
      'https://sptoporow.4parents.pl',
      'https://ptoporow.4parents.pl',
      'https://spsieniawa.4parents.pl',
      'https://psieniawa.4parents.pl'
    ],
    logo: lagow
  },
  {
    domains: [
      'https://p1miedzyrzecz.4parents.pl',
      'https://p4miedzyrzecz.4parents.pl',
      'https://p6miedzyrzecz.4parents.pl',
      'https://sp1miedzyrzecz.4parents.pl',
      'https://sp2miedzyrzecz.4parents.pl',
      'https://sp3miedzyrzecz.4parents.pl',
      'https://spkalawa.4parents.pl',
      'https://spbukowiec.4parents.pl',
      'https://sp4miedzyrzecz.4parents.pl',
      'https://sp6miedzyrzecz.4parents.pl',
      'https://sp2oddzialmiedzyrzecz.4parents.pl'
    ],
    logo: miedzyrzecz
  },
  {
    domains: ['https://spotyn.4parents.pl', 'https://przedszkoleotyn.4parents.pl', 'https://spniedoradz.4parents.pl'],
    logo: otyn
  },
  {
    domains: [
      'https://spsantok.4parents.pl',
      'https://splipkiwielkie.4parents.pl',
      'https://spwawrow.4parents.pl',
      'https://spjanczewo.4parents.pl',
      'https://pwawrow.4parents.pl',
      'https://psantok.4parents.pl',
      'https://plipkiwielkie.4parents.pl'
    ],
    logo: santok
  },
  {
    domains: [
      'https://pslawa.4parents.pl',
      'https://pciosan.4parents.pl',
      'https://pkrzepielow.4parents.pl',
      'https://pstarestracze.4parents.pl',
      'https://spslawa.4parents.pl',
      'https://spciosan.4parents.pl',
      'https://spkrzepielow.4parents.pl',
      'https://spstarestracze.4parents.pl'
    ],
    logo: slawa
  },
  {
    domains: [
      'https://sptorzym.4parents.pl',
      'https://spboczow.4parents.pl',
      'https://ptorzym.4parents.pl',
      'https://pboczow.4parents.pl',
      'https://spgadowwielki.4parents.pl',
      'https://pgadowwielki.4parents.pl',
      'https://spwalewice.4parents.pl'
    ],
    logo: torzym
  }
];
const SpecialLogo = () => {
  const getLogo = () => {
    const currentDomain = window.location.origin;
    const nadarzyn = featuresFromPrefixValidator();

    let logo = nadarzyn ? (
      <img src={logoStudents} width={260} alt="4student_logo" />
    ) : (
      <img src={logoParents} alt="4parents_logo" />
    );
    DOMAINS_WITH_LOGO.forEach((group) => {
      if (group.domains.includes(currentDomain)) {
        logo = <img style={{ width: '30%' }} src={group.logo} alt="logo" />;
      }
    });
    return logo;
  };

  return (
    <>
      {getLogo()}
    </>
  );
};

export default SpecialLogo;
