/*eslint indent:0*/
import initialState from './initial';
import { DailyGroupDaycareSheetModel, ChildDaycareEntryModel } from '../models/daycare/DaycareModels';
import * as types from '../actions/actionTypes';

export default function daycareReducer(state = initialState.daycare, action) {
  switch (action.type) {
    case types.LOAD_DAYCARE_GROUP_ENTRIES_SUCCESS: {
      return new DailyGroupDaycareSheetModel().assign(action.daycareSheet);
    }
    case types.UPDATE_CHILD_DAYCARE_SUCCESS: {
      const nextEntries = state.entries.map((e) => {
        return e.child.id === action.entry.childId ? new ChildDaycareEntryModel().assign(e).assign(action.entry) : e;
      });

      return Object.assign({}, state, { entries: [...nextEntries] });
    }
    default:
      return state;
  }
}
