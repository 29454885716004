import { attributeObjectTypes } from '../../../constants/attributeObjectTypes';
import PropTypes from 'prop-types';
import React from 'react';
import SelectField from '../../common/SelectField';
import Checkbox from '../../common/Checkbox';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { Box, MenuItem } from '@mui/material';
import { ReportTypes } from '../../../constants/reportTypes';

const ChildrenAttributeSetValuesReport = ({
  groupId,
  groups,
  attributeSetId,
  attributeSets,
  includePresentChildren,
  includeNotReportedChildren,
  onChange,
  errors
}) => {
  return (
    <Box>
      <FieldsetWrapper legend={`Pola dla generowania raportu ${ReportTypes.childrenAttributeSetValuesSummary.name}`}>
        <SelectField
          contrast
          errorText={errors.groupId}
          sx={{ width: 'auto', overflow: 'hidden' }}
          floatingLabelText="Grupa"
          id="groupId"
          value={groupId}
          onChange={(event) => {
            onChange({ target: { name: 'groupId', value: event.target.value } });
          }}>
          {groups.map((group) => {
            return (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            );
          })}
        </SelectField>
        <SelectField
          contrast
          sx={{ width: 'auto', overflow: 'hidden', mt: 2 }}
          floatingLabelText="Atrybuty"
          id="attributeSetId"
          value={attributeSetId}
          onChange={(event) => {
            onChange({ target: { name: 'attributeSetId', value: event.target.value } });
          }}>
          {attributeSets
            .filter((set) => set.objectType === attributeObjectTypes.child.value)
            .map((attributeSet) => {
              return (
                <MenuItem key={attributeSet.id} value={attributeSet.id}>
                  {attributeSet.name}
                </MenuItem>
              );
            })}
        </SelectField>
        <Box sx={{ mt: 2 }}>
          <Checkbox
            label="Generuj dla dzieci obecnych w placówce"
            defaultChecked={includePresentChildren}
            onCheck={(event, isChecked) => onChange({ target: { name: 'includePresentChildren', value: isChecked } })}
          />
          <Checkbox
            label="Generuj dla dzieci niezgłoszonych"
            defaultChecked={includeNotReportedChildren}
            onCheck={(event, isChecked) =>
              onChange({ target: { name: 'includeNotReportedChildren', value: isChecked } })
            }
          />
        </Box>
      </FieldsetWrapper>
    </Box>
  );
};

ChildrenAttributeSetValuesReport.propTypes = {
  groupId: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  attributeSets: PropTypes.array.isRequired,
  attributeSetId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  includePresentChildren: PropTypes.bool.isRequired,
  includeNotReportedChildren: PropTypes.bool.isRequired,
  errors: PropTypes.object
};

export default ChildrenAttributeSetValuesReport;
