import { useTheme } from '@mui/material';
import { generateFolderColorsArray } from '../../../components/virtualDisc/utils/folderColor';
import FolderIcon from '@mui/icons-material/Folder';
import { useSelector } from 'react-redux';
import { themeTypes } from '../../../styles/appTheme';

const DiscFolderIcon = ({ item, ...rest }) => {
  const theme = useTheme();
  const folderIconColor = theme.palette.components.fileIcons.folder;
  const selectedTheme = useSelector((state) => state.theme);
  return (
    <>
      {item?.color && selectedTheme !== themeTypes.contrast.value ? (
        <>
          <svg width={0} height={0}>
            <linearGradient id={`linearColors-${item.id}`} x1={1} y1={0} x2={1} y2={1}>
              {generateFolderColorsArray(item.color).map((color, index) => (
                <stop key={color} offset={index} stopColor={color} />
              ))}
            </linearGradient>
          </svg>
          <FolderIcon sx={{ fill: `url(#linearColors-${item.id})` }} {...rest} />
        </>
      ) : (
        <FolderIcon sx={{ fill: folderIconColor }} {...rest} />
      )}
    </>
  );
};
export default DiscFolderIcon;
