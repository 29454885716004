import PropTypes from 'prop-types';
import React from 'react';
import Toggle from '../../../common/Toggle';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';

const AvailableForLegalGuardians = ({ scheme, onChange }) => {
  return (
    <FieldsetWrapper legend="Dodatkowe" invisible={false}>
      <Toggle
        label="Zezwól na składanie wniosków przez opiekuna prawnego"
        labelPosition="right"
        defaultToggled={scheme.availableForLegalGuardians}
        onToggle={(event, value) => onChange({ target: { name: 'availableForLegalGuardians', value } })}
      />
    </FieldsetWrapper>
  );
};

AvailableForLegalGuardians.propTypes = {
  scheme: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default AvailableForLegalGuardians;
