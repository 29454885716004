import React from 'react';
import { TableContainer, Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material';
import { tableConfig } from '../config/TableConfig';

const ImportInformationComponent = () => {
  return (
    <TableContainer sx={{ maxHeight: '420px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '30%' }}>Nazwa kolumny</TableCell>
            <TableCell style={{ width: '40%' }}>Opis</TableCell>
            <TableCell style={{ width: '20%' }}>Format danych</TableCell>
            <TableCell style={{ width: '10%' }}>Wymagane</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableConfig.map((element) => (
            <TableRow
              key={element.id}
              sx={{
                '&:hover': {
                  background: (theme) => theme.palette.background.hover
                }
              }}>
              <TableCell>{element.name}</TableCell>
              <TableCell>{element.description}</TableCell>
              <TableCell>{element.format}</TableCell>
              <TableCell>{element.required ? 'Tak' : 'Nie'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ImportInformationComponent;
