import PropTypes from 'prop-types';
import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { ChargingSchemeTypes } from '../../../../constants/chargingSchemeTypes';

const ChargingSchemesList = ({ onSelected }) => {
  return (
    <List>
      {Object.values(ChargingSchemeTypes).map((type) => {
        return (
          <ListItem
            key={type.name}
            button
            onClick={() => onSelected(type)}
            divider
            component="li"
            aria-label={`Edytuj naliczanie ${type.name}`}>
            <ListItemText
              primary={`Naliczanie ${type.name}`}
              secondary={type.description}
              secondaryTypographyProps={{ color: (theme) => theme.palette.color.contrast }}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

ChargingSchemesList.propTypes = {
  onSelected: PropTypes.func.isRequired
};

export default ChargingSchemesList;
