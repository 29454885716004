import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import * as messageBoardActions from '../../actions/messageBoardActions';
import * as messageBoardFilterActions from '../../actions/messageBoardFilterActions';
import EventsCalendar from './calendar/EventsCalendar';
import PostEditor from '../messageBoard/dialog/BoardPostEditDialog';
import EventDetailsDialog from './dialogs/EventDetailsDialog';
import { BoardPostModel } from '../../models/messageBoard/MessageBoardModels';
import moment from 'moment';
import { browserHistory } from 'react-router';
import { routePaths, fromTemplate } from '../../routePaths';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import { routePathActions } from '../../routePathActions';
import FilterDialog from '../messageBoard/dialog/FilterDialog';
import { MessageBoardFilterModel } from '../../models/messageBoardFilter/MessageBoardFilterModels';
import { Paper, Box, Button, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { InputFieldStyles } from '../../constants/ComponentStyles/inputFieldStyles';
import * as childrenActions from '../../actions/childrenActions';
import * as userRoles from '../../constants/userRoles';
import ManualLink from '../ManualLink';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import { SearchBar } from '../common/SearchBar';
import ContentWrapper from '../common/contentWrapper';

class MessageBoardPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleCalendarDateChange = this.handleCalendarDateChange.bind(this);
    this.handlePostSave = this.handlePostSave.bind(this);
    this.handlePostCancel = this.handlePostCancel.bind(this);
    this.handlePostEdit = this.handlePostEdit.bind(this);
    this.renderCreateEventDialog = this.renderCreateEventDialog.bind(this);
    this.renderCreateEventButton = this.renderCreateEventButton.bind(this);
    this.handleDetailsOpen = this.handleDetailsOpen.bind(this);
    this.handleDetailsClose = this.handleDetailsClose.bind(this);
    this.handleShowEventFullDetails = this.handleShowEventFullDetails.bind(this);
    this.getEventsAtDay = this.getEventsAtDay.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);
    this.handleTextSearchChange = this.handleTextSearchChange.bind(this);
    this.handleTextSearch = this.handleTextSearch.bind(this);
    this.handleSearchByEnter = this.handleSearchByEnter.bind(this);
    this.state = {
      currentPost: null,
      isEventDetailsDialogOpen: false,
      eventDetailsDate: null,
      textFilter: this.props.filters.textFilter
    };
  }

  componentDidMount() {
    if (this.props.auth.userRole === userRoles.legalGuardian)
      this.props.childrenActions.loadLegalGuardiansChildrenAsync();
  }

  getEventsAtDay(date) {
    return this.props.posts
      .filter((x) => x.eventDate === date.format('YYYY-MM-DD'))
      .sort((a, b) => {
        const a1 = moment({ hour: a.eventStartTime.hourLocal, minute: a.eventStartTime.minuteLocal });
        const a2 = moment({ hour: b.eventStartTime.hourLocal, minute: b.eventStartTime.minuteLocal });
        return a1.isBefore(a2) ? -1 : 1;
      });
  }

  getGroups() {
    const user = this.props.staffMembers.find((obj) => obj.id === this.props.auth.userId);
    if (this.props.auth.userRole === userRoles.legalGuardian) {
      return this.props.children
        ? this.props.groups.filter(({ id }) =>
            this.props.children.some(({ enrolledToGroup }) => enrolledToGroup && enrolledToGroup.id === id)
          )
        : [];
    }
    if (user) {
      const groups =
        user.role === 'teacher'
          ? this.props.groups.filter((group) => {
              return user.groups.find((userGroup) => userGroup.id === group.id);
            })
          : this.props.groups;
      return groups;
    }
    return [];
  }

  handleCalendarDateChange(date) {
    const d = moment(date);
    browserHistory.push(fromTemplate(routePaths.eventsCalendar, [d.format('YYYY'), d.format('MM')]));
  }

  handlePostFilterStart() {
    this.props.filterActions.filterPostStart();
  }

  handlePostFilterSave(filters) {
    if (filters.groups.length === this.getGroups().length) filters.groups = [];
    if (filters.activities.length === this.props.activities.length) filters.activities = [];
    if (filters.authors.length === this.props.staffMembers.length) filters.authors = [];
    const data = {
      ...filters,
      textFilter: this.state.textFilter,
      filtered: true
    };
    const date = `${this.props.year}-${this.props.month}`;
    this.props.actions.loadFilteredEventsByRoleAtMonthAsync(date, data);
  }

  handleFilterPostCancel() {
    this.props.filterActions.filterPostCancel();
  }

  handleClearFilters() {
    this.setState({
      textFilter: ''
    });
    this.props.actions.clearFilters(`${this.props.year}-${this.props.month}`);
  }

  handleTextSearchChange(e) {
    e.persist();
    this.setState({ textFilter: e.target.value });
  }

  handleTextSearch() {
    const date = `${this.props.year}-${this.props.month}`;
    this.props.actions.loadFilteredEventsByRoleAtMonthAsync(
      date,
      new MessageBoardFilterModel().assign({ ...this.props.filters, textFilter: this.state.textFilter, filtered: true })
    );
  }

  handleSearchByEnter(event) {
    if (event.keyCode === 13) {
      this.handleTextSearch();
    }
  }

  handleShowEventFullDetails(date) {
    browserHistory.push(
      fromTemplate(routePaths.messageBoardEvents, [date.format('YYYY'), date.format('MM'), date.format('DD')])
    );
  }

  handlePostEdit(date) {
    this.setState({ currentPost: new BoardPostModel().assign({ eventDate: date || moment().format() }) });
    this.props.actions.createPostStart();
  }

  handlePostSave(post) {
    this.props.actions.createPostAsync(post);
  }

  handlePostCancel() {
    this.setState({ currentPost: null });
    this.props.actions.createPostCancel();
  }

  handleDetailsOpen(date) {
    const events = this.getEventsAtDay(date.date);
    if (this.props.isAllowed(routePathActions.eventsCalendar.editEvent)) {
      this.openDetailsForStaff(date, events);
    } else {
      this.openDetailsForLegalGuardian(date, events);
    }
  }

  openDetailsForLegalGuardian(date, events) {
    if (events.length) {
      this.handleShowEventFullDetails(date.date);
    }
  }

  openDetailsForStaff(date, events) {
    if (events.length) {
      this.setState({ isEventDetailsDialogOpen: true, eventDetailsDate: date });
    } else {
      this.handlePostEdit(date.date);
    }
  }

  handleDetailsClose() {
    this.setState({ isEventDetailsDialogOpen: false, eventDetailsDate: null });
  }

  renderCreateEventButton() {
    if (this.props.isAllowed(routePathActions.eventsCalendar.createEvent)) {
      return (
        <Grid container sx={{ mb: 3 }}>
          <Grid item xs={12} md={5} sx={{ mb: 2 }}>
            <SearchBar
              label="Szukaj"
              handleTextSearch={this.handleTextSearch}
              onKeyDown={this.handleSearchByEnter}
              value={this.state.textFilter}
              onChange={this.handleTextSearchChange}
            />
          </Grid>
          <Grid item xs={12} md={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {this.props.filters.filtered ? (
              <Button
                variant="outlined"
                aria-label="Wyczyść filtry"
                sx={{ py: 1, lineHeight: 1 }}
                onClick={this.handleClearFilters}>
                Wyczyść filtry
              </Button>
            ) : null}
            <Button
              variant="outlined"
              aria-label="Filtruj"
              sx={{ py: 1, lineHeight: 1 }}
              onClick={() => this.handlePostFilterStart()}>
              Filtruj
            </Button>
            <Button
              variant="contained"
              aria-label="Nowe wydarzene"
              sx={{ py: 1, lineHeight: 1 }}
              onClick={() => this.handlePostEdit()}>
              Nowe wydarzenie
            </Button>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container sx={{ mb: 3 }}>
        <Grid item xs={12} md={5} sx={{ mb: 2 }}>
          <SearchBar
            label="Szukaj"
            handleTextSearch={this.handleTextSearch}
            onKeyDown={this.handleSearchByEnter}
            value={this.state.textFilter}
            onChange={this.handleTextSearchChange}
          />
        </Grid>
        <Grid item xs={12} md={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {this.props.filters.filtered ? (
            <Button
              variant="outlined"
              aria-label="Wyczyść filtry"
              sx={{ my: 1, mx: 2 }}
              onClick={this.handleClearFilters}>
              Wyczyść filtry
            </Button>
          ) : null}
          <Button variant="contained" aria-label="Filtruj" sx={{ m: 1 }} onClick={() => this.handlePostFilterStart()}>
            Filtruj
          </Button>
        </Grid>
      </Grid>
    );
  }

  renderCreateEventDialog() {
    return this.props.ui.isEditing && this.state.currentPost ? (
      <PostEditor
        type="event"
        post={this.state.currentPost}
        groups={this.props.groups}
        activities={this.props.activities}
        onSave={(post) => this.handlePostSave(post)}
        onCancel={(post) => this.handlePostCancel(post)}
        isEditing={this.props.ui.isEditing}
        isProcessing={this.props.ui.isProcessing}
        canClearDate={false}
      />
    ) : null;
  }

  renderEventDetailsDialog() {
    return this.state.isEventDetailsDialogOpen ? (
      <EventDetailsDialog
        isDialogOpen={this.state.isEventDetailsDialogOpen}
        onRequestClose={this.handleDetailsClose}
        header="Wydarzenia"
        events={this.getEventsAtDay(this.state.eventDetailsDate.date)}
        onShowEventFullDetails={() => this.handleShowEventFullDetails(this.state.eventDetailsDate.date)}
        onCreateNewEvent={() => this.handlePostEdit(this.state.eventDetailsDate.date.format())}
      />
    ) : null;
  }

  renderFilterDialog() {
    const allowedRoles = [
      userRoles.staffMemberAccountant,
      userRoles.staffMemberPrincipal,
      userRoles.staffMemberTeacher,
      userRoles.staffMemberSecretary
    ];
    return this.props.filterUi.isEditing ? (
      <FilterDialog
        auth={this.props.auth}
        filters={this.props.filters}
        groups={this.getGroups()}
        isLegalGuardian={this.props.auth.userRole === userRoles.legalGuardian}
        activities={this.props.activities}
        staffMembers={this.props.staffMembers}
        onSave={(filters) => this.handlePostFilterSave(filters)}
        onCancel={(post) => this.handleFilterPostCancel(post)}
        isEditing={this.props.filterUi.isEditing}
        isProcessing={this.props.filterUi.isProcessing}
        canChooseActivities={allowedRoles.includes(this.props.auth.userRole)}
        canChooseAuthors={allowedRoles.includes(this.props.auth.userRole)}
      />
    ) : null;
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <Box flexDirection="row" display="flex" alignItems="center">
            <PageHeaderText title="Kalendarz wydarzeń" titleIcon={<EventOutlinedIcon />} />
            {this.props.auth.userRole === userRoles.legalGuardian && <ManualLink index="3" />}
          </Box>
          <ContentWrapper>
            <Box>{this.renderCreateEventButton()}</Box>
            <Paper>
              <EventsCalendar
                eventDays={this.props.posts}
                daysOff={this.props.daysOff}
                onDateChange={this.handleCalendarDateChange}
                onDaySelected={(date) => this.handleDetailsOpen(date)}
                date={moment(new Date(this.props.year, this.props.month - 1, 1))}
                year={this.props.year}
                month={this.props.month}
              />
            </Paper>
            {this.renderCreateEventDialog()}
            {this.renderEventDetailsDialog()}
            {this.renderFilterDialog()}
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

MessageBoardPage.propTypes = {
  posts: PropTypes.array.isRequired,
  daysOff: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isAllowed: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  filterUi: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  staffMembers: PropTypes.array.isRequired,
  filterActions: PropTypes.object.isRequired,
  classes: PropTypes.object,
  childrenActions: PropTypes.object.isRequired,
  children: PropTypes.array,
  nadarzyn: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
  return {
    posts: state.messageBoardEvents.posts,
    daysOff: state.configuration.daysOff,
    ui: state.messageBoardUi,
    groups: state.groups,
    activities: state.activities,
    isAllowed: state.auth.isAllowed.bind(state.auth),
    year: ownProps.params.year,
    month: ownProps.params.month,
    filters: state.messageBoardFilter,
    filterUi: state.messageBoardFilterUi,
    auth: state.auth,
    staffMembers: state.staffMembers,
    children: state.legalGuardiansChildren,
    nadarzyn: state.configuration.unit.features.nadarzyn
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(messageBoardActions, dispatch),
    filterActions: bindActionCreators(messageBoardFilterActions, dispatch),
    childrenActions: bindActionCreators(childrenActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(InputFieldStyles)(MessageBoardPage));
