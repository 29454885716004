import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  useTheme,
  useMediaQuery,
  Typography,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse
} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import ApplicationActionsButtons from '../common/applications/ApplicationActionsButtons';
import SchemeDescription from '../configuration/chargingSchemes/schemeDescriptions/SchemeDescription';
import ApplicationStatusChip from '../common/applications/ApplicationStatusChip';
import {
  AdditionalActivitiesApplicationsHeader,
  CateringApplicationsHeader
} from '../common/applications/ApplicationHeaders';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const PrincipalApplicationsTable = (props) => {
  const { applications, reject, approve, captionText, activitiesHeader, title } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const getApplicationDescription = (application) => {
    if (application.schemeId !== null) {
      return (
        <>
          <Typography
            sx={{
              fontWeight: (t) => t.typography.fontWeightBold,
              fontSize: 16
            }}>
            {application.data.name}
          </Typography>
          <br />
          <SchemeDescription scheme={application.data} />
        </>
      );
    }

    return (
      <Typography
        sx={{
          fontWeight: (t) => t.typography.fontWeightBold
        }}>
        {application.data.name}
      </Typography>
    );
  };

  return (
    <Paper sx={{ my: 2, p: 1 }} elevation={1}>
      <ListItem sx={{ p: 0, m: 0 }}>
        <ListItemButton sx={{ px: 2, py: 1 }} onClick={() => handleOpenClose()}>
          <ListItemText
            primary={title}
            primaryTypographyProps={{
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}
          />
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={isOpen}>
        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
          {activitiesHeader ? (
            <AdditionalActivitiesApplicationsHeader contrast />
          ) : (
            <CateringApplicationsHeader contrast />
          )}
          <Table size={isTablet ? 'small' : 'medium'} aria-label={`regre${captionText}`}>
            {/* <caption>{captionText}</caption> */}
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="medium_bold">Wniosek</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Dziecko</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Rodzic</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Data złożenia</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Status</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Akcje</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applications.length === 0 && (
                <TableRow>
                  <TableCell align="center">
                    <Typography>Brak wniosków do wyświetlenia</Typography>
                  </TableCell>
                </TableRow>
              )}
              {applications.map((application) => (
                <TableRow
                  sx={{
                    '&:hover': {
                      background: (theme) => theme.palette.background.hover
                    }
                  }}
                  key={application.id}>
                  <TableCell sx={{ minWidth: { xs: 170, md: 240 } }}>
                    {getApplicationDescription(application)}
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      {application.childData.firstName} {application.childData.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      {application.parentData !== null
                        ? application.parentData.firstName + ' ' + application.parentData.lastName
                        : 'Brak rodzica'}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 150 }} align="center">
                    {moment(application.createdAt).format('YYYY-MM-DD HH:mm')}
                  </TableCell>
                  <TableCell sx={{ maxWidth: 200 }} align="center">
                    <ApplicationStatusChip application={application} />
                  </TableCell>
                  <TableCell sx={{ width: 120 }} align="center">
                    <ApplicationActionsButtons application={application} approve={approve} reject={reject} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Paper>
  );
};

PrincipalApplicationsTable.propTypes = {
  applications: PropTypes.array.isRequired,
  reject: PropTypes.func.isRequired,
  approve: PropTypes.func.isRequired,
  captionText: PropTypes.string.isRequired,
  activitiesHeader: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

export default PrincipalApplicationsTable;
