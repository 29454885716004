import PropTypes from 'prop-types';
import { React, useMemo } from 'react';
import DatePicker from '../DatePicker';
import _ from 'lodash';
import TextField from '../TextField';
import { tryConvertToNumber, tryConvertToInteger } from '../../../utils/numberConverters';
import {
  ListItemText,
  Switch,
  FormControlLabel,
  MenuItem,
  Box,
  Typography,
  Autocomplete,
  Input,
  Grid
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SelectField from '../SelectField';

const ChargingSchemeForm = ({
  isEmployee,
  chargingSchemes,
  reliefs,
  scheme,
  availableChargingSchemes,
  isNew,
  onSchemeChange,
  onChange,
  errors
}) => {
  const order = isEmployee ? ['staffCatering'] : ['stay', 'catering', 'activities', 'other'];
  const useStyles = makeStyles((theme) => ({
    listtitle: {
      borderBottom: '1px solid lightgray',
      borderTop: '1px solid lightgray',
      background: theme.palette.background.hover,
      padding: '5px 15px 0px 15px',
      marginTop: -5
    }
  }));
  const classes = useStyles();

  const firstDayOfMonth = useMemo(() => {
    const date = new Date();
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  if (availableChargingSchemes.length === 0 && isNew) {
    return <Box>Wszystkie schematy płatności zostały już przypisane</Box>;
  }

  const renderCategoryTitle = (category) => {
    switch (category) {
      case 'catering':
        return (
          <ListItemText
            className={classes.listtitle}
            primary="Wyżywienie"
            primaryTypographyProps={{
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}
          />
        );
      case 'stay':
        return (
          <ListItemText
            className={classes.listtitle}
            primary="Pobyt"
            primaryTypographyProps={{
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}
          />
        );
      case 'other':
        return (
          <ListItemText
            className={classes.listtitle}
            primary="Inne"
            primaryTypographyProps={{
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}
          />
        );
      case 'staffCatering':
        return (
          <ListItemText
            className={classes.listtitle}
            primary="Wyżywienie pracownika"
            primaryTypographyProps={{
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}
          />
        );
      case 'activities':
        return (
          <ListItemText
            className={classes.listtitle}
            primary="Zajęcia Dodatkowe"
            primaryTypographyProps={{
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}
          />
        );
      default:
        console.log('category not found');
    }
    return 0;
  };

  return (
    <Box>
      <Grid container columnSpacing={2}>
        <Grid item sx={{ my: 2 }} xs={12} md={6}>
          <Autocomplete
            sx={{ width: '100%', mb: 1 }}
            classes={{ paper: classes.paper }}
            onChange={(event, target) => onSchemeChange(target.id)}
            id="combo-box-demo"
            options={availableChargingSchemes
              .sort((a, b) => -b.reportType.localeCompare(a.reportType))
              .filter((element) => order.includes(element.reportType))}
            groupBy={(option) => option.reportType}
            getOptionLabel={(option) => option.name.toString()}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.id}>
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <Box ref={params.InputProps.ref}>
                <Input
                  disableUnderline
                  sx={{
                    width: '100%',
                    outline: 'none',
                    border: 'none',
                    borderBottom: (theme) => `1px solid ${theme.palette.color.contrast}`
                  }}
                  placeholder="Wybierz schemat"
                  type="text"
                  inputProps={{ ...params.inputProps }}
                />
              </Box>
            )}
            renderGroup={(group) => (
              <Box key={group.key} sx={{ m: 0 }}>
                {renderCategoryTitle(group.group)}
                {group.children}
              </Box>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            label="Schemat jednorazowy"
            control={
              <Switch
                sx={{ transform: 'scale(1.5)', mx: 2, my: 3 }}
                checked={scheme.singleUse}
                value={scheme.singleUse}
                onChange={(event, value) => onChange({ target: { name: 'singleUse', value } })}
              />
            }
          />
          <Typography sx={{ fontSize: 13, mb: 1 }}>
            Schemat zostanie automatycznie odpięty po zatwierdzeniu rozliczenia
          </Typography>
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Dotacja</Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: '', md: 'space-between' },
              columnGap: 2,
              alignItems: 'start',
              flexDirection: { xs: 'column', sm: 'row' }
            }}>
            <TextField
              hintText="Pakiet godzin (na dzień)"
              floatingLabelText="Pakiet godzin (na dzień)"
              floatingLabelFixed={false}
              name="grantHoursAmount"
              value={scheme.grantHoursAmount || ''}
              onChange={onChange}
              onBlur={(e) => {
                onChange({ target: { name: 'grantHoursAmount', value: tryConvertToInteger(e.target.value) } });
              }}
              errorText={errors.grantHoursAmount}
            />
            <TextField
              hintText="Stawka (zł/godzinę)"
              floatingLabelText="Stawka (zł/godzinę)"
              floatingLabelFixed={false}
              name="grantHourlyRate"
              value={scheme.grantHourlyRate || ''}
              onChange={onChange}
              onBlur={(e) => {
                onChange({ target: { name: 'grantHourlyRate', value: tryConvertToNumber(e.target.value) } });
              }}
              errorText={errors.grantHourlyRate}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Ulga</Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: '', md: 'space-between' },
              alignItems: 'start',
              flexDirection: { xs: 'column', sm: 'row' },
              columnGap: 2
            }}>
            <SelectField
              contrast
              sx={{ width: '50%', my: 1, mx: 0 }}
              id="reliefId"
              value={scheme.reliefId || ''}
              onChange={(e) => onChange({ target: { name: 'reliefId', value: e.target.value } })}
              floatingLabelText="Ulga"
              errorText={errors.reliefId}>
              <MenuItem
                key={null}
                value=""
                onClick={() => onChange({ target: { name: 'reliefExpiryDate', value: '' } })}>
                Brak ulgi
              </MenuItem>
              {reliefs.map((relief) => {
                return (
                  <MenuItem key={relief.id} value={relief.id}>
                    {relief.name}
                  </MenuItem>
                );
              })}
            </SelectField>

            <DatePicker
              floatingLabelText={<Box sx={{ color: (theme) => theme.palette.color.contrast }}>Data ważności ulgi</Box>}
              onChange={(e, date) => onChange({ target: { name: 'reliefExpiryDate', value: date } })}
              value={scheme.reliefExpiryDate ? new Date(scheme.reliefExpiryDate) : undefined}
              name="reliefExpiryDate"
              disabled={!scheme.reliefId}
              errorText={errors.reliefExpiryDate}
              sx={{ my: 1 }}
              unlimitedMaxDate={true}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Data rozpoczęcia naliczeń</Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: '', md: 'space-between' },
              columnGap: 2,
              alignItems: 'start',
              flexDirection: { xs: 'column', sm: 'row' }
            }}>
            <DatePicker
              floatingLabelText={<Box sx={{ color: (theme) => theme.palette.color.contrast }}>Data rozpoczęcia</Box>}
              onChange={(e, date) => onChange({ target: { name: 'calculationStartDate', value: date } })}
              value={scheme.calculationStartDate ? new Date(scheme.calculationStartDate) : firstDayOfMonth}
              name="calculationStartDate"
              errorText={errors.calculationStartDate}
              sx={{ my: 1 }}
              unlimitedMaxDate={true}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

ChargingSchemeForm.propTypes = {
  isEmployee: PropTypes.bool.isRequired,
  scheme: PropTypes.object.isRequired,
  isNew: PropTypes.bool.isRequired,
  onSchemeChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  chargingSchemes: PropTypes.array.isRequired,
  reliefs: PropTypes.array.isRequired,
  availableChargingSchemes: PropTypes.array
};

export default ChargingSchemeForm;
