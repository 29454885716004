import React from 'react';
import PropTypes from 'prop-types';
import PeopleIcon from '@mui/icons-material/People';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DrawerButton = ({ closeDrawer, sx }) => {
  const theme = useTheme();
  return (
    <IconButton
      aria-label="rozwiń"
      onClick={closeDrawer}
      sx={{
        position: 'fixed',
        right: 0,
        pl: 1,
        top: 110,
        zIndex: 999,
        background: theme.palette.color.color7,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        '&:hover': {
          background: theme.palette.color.color7
        },
        ...sx
      }}>
      <PeopleIcon sx={{ fontSize: 32, color: theme.palette.color.primary }} />
    </IconButton>
  );
};

DrawerButton.propTypes = {
  closeDrawer: PropTypes.func,
  sx: PropTypes.object
};

export default DrawerButton;
