import BaseModel from '../../BaseModel';

/*eslint no-use-before-define: 0*/
export class DayOffModel extends BaseModel {
  constructor() {
    super();
    this.name = '';
    this.date = '';
  }
}
