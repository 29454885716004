const RODO = `
1. Administratorem Pani/Pana danych osobowych jest Gdański Zespół Żłobków z siedzibą w Gdańsku; e-mail: biuro@zlobki.gda.pl. 
2. Inspektorem ochrony danych osobowych Gdańskiego Zespołu Żłobków jest wyznaczony pracownik GZŻ posiadający stosowne uprawnienia; e-mail:  kskelnik@zlobki.gda.pl.
3. Dane osobowe będą przetwarzane w celu zawarcia i realizacji umowy o świadczenie usług opiekuńczych, wychowawczych i edukacyjnych, na podstawie której sprawowana jest opieka nad dzieckiem w żłobku zgodnie z przepisami ustawy z dnia 4 lutego 2011r. o opiece nad dziećmi w wieku do lat 3 (t. j. Dz.U. z 2021 r. poz. 75).
4. Dane osobowe mogą być udostępniane organowi nadzorującemu w celu prawidłowej realizacji usług. Odbiorcami Pani/Pana danych osobowych będą wyłącznie podmioty uprawnione do uzyskania danych osobowych na podstawie przepisów prawa
5. Dane osobowe nie będą przetwarzane poza Europejskim Obszarem Gospodarczym.
6. Dane osobowe będą przetwarzane przez okres zgodny z przepisami ustawy z dnia 4 lutego 2011r. o opiece nad dziećmi w wieku do lat 3 (t. j. Dz.U. z 2021 r. poz. 75).
7. W przypadku naruszenia przepisów o ochronie danych osobowych przysługuje Pani/Panu prawo wniesienia skargi do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych Osobowych. 
8. Podanie danych osobowych jest wymagane celem przyjęcia dziecka do żłobka Gdańskiego Zespołu Żłobków oraz do zawarcia umowy o świadczenie usług opiekuńczych wychowawczych i edukacyjnych, na podstawie której sprawowana jest opieka nad dzieckiem w placówce Gdańskiego Zespołu Żłobków. 
9. Administrator informuje o zautomatyzowanym podejmowaniu decyzji, w tym o profilowaniu na zasadach przydzielania pierwszeństwa przyjęcia niepełnosprawnego dziecka do żłobka albo ustalenia miesięcznej odpłatności za pobyt dziecka w żłobku ze względu na przynależność dziecka do rodziny wielodzietnej lub pobierania przez przedstawiciela ustawowego dziecka zasiłku rodzinnego na to dziecko oraz ze względu na równoczesne korzystanie z opieki Gdańskiego Zespołu Żłobków drugiego dziecka rodziców lub prawnych opiekunów dziecka albo zwolnienia z opłaty za pobyt dziecka w żłobku samotnych rodziców dzieci (matka lub ojciec) skierowanych przez Miejski Ośrodek Pomocy Rodzinie w Gdańsku na pobyt w Domu Samotnej Matki lub w innych specjalistycznych palcówkach, którzy w okresie pozostawienia bez pracy zdobywają kwalifikacje zawodowe umożliwiające podjęcie zatrudnienia lub rodziców dzieci niepełnosprawnych z terenu Miasta Gdańska posiadających aktualne orzeczenie o niepełnosprawności dziecka, celem wykonania postanowień uchwały Rady Miasta Gdańska w sprawie ustalenia odpłatności za pobyt dziecka w żłobkach i klubach dziecięcych Gdańskiego Zespołu Żłobków.
10. Ma Pani/Pan prawo dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania.
11. Ma Pani/Pan prawo do wniesienia sprzeciwu wobec dalszego przetwarzania danych osobowych, a w przypadku wyrażenia zgody na przetwarzanie danych osobowych, do jej wycofania. Skorzystanie z prawa cofnięcia zgody nie ma wpływu na przetwarzanie, które miało miejsce do momentu wycofania zgody.
12. Ma Pani/Pan prawo do przenoszenia danych osobowych, w szczególności uzyskania informacji dotyczącej historii przetwarzania swoich danych osobowych w związku z prowadzoną rekrutacją do żłobków Gdańskiego Zespołu Żłobków oraz zawarciem umowy o świadczenie usług opiekuńczych, wychowawczych i edukacyjnych, przez okres do 6 (sześciu) miesięcy od dnia zawarcia w/w umowy lub usunięcia zgłoszenia z listy oczekujących na miejsce w gdańskim żłobku samorządowym.`;

export default RODO;
