import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormDialog from '../../formik/formDialog/FormDialog';
import { FormikTextField } from '../../formik/FormikTextField';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WYSIWYG from '../../formik/FormikWYSIWYG';
import FileUploader from '../../common/fileUploader/FileUploader';
import { useUpload } from '../../../hooks/useUpload';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js';

const useStyles = makeStyles({
  editor: {
    margin: '16px 0'
  }
});

const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Podaj tytuł';
  }

  const blocksFromHTML = convertFromHTML(values.content);
  const content = convertToRaw(
    ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
  );
  if (content.blocks.length === 0) {
    errors.content = 'Podaj treść';
  }

  return errors;
};

const PostDialog = (props) => {
  const { onClose, open, onSubmit, initialValues, title, inProgress } = props;

  const classes = useStyles();
  const [files, handleFileAdded, handleFileDeleted, setFiles] = useUpload();

  useEffect(() => {
    setFiles(initialValues.files);
  }, []);

  const handleSubmit = (values) => {
    onSubmit({
      ...values,
      files
    });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <FormDialog
      onClose={handleClose}
      open={open}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      title={title}
      requiredFieldText
      inProgress={inProgress}
      validate={validate}>
      <Grid container direction="column">
        <Grid item xs={12}>
          <FormikTextField name="title" label="Tytuł*" fullWidth />
        </Grid>
        <Grid item className={classes.editor}>
          <WYSIWYG name="content" />
        </Grid>
        <Grid item>
          <FileUploader files={files} onFileUploaded={handleFileAdded} onFileRemoved={handleFileDeleted} />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

PostDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  inProgress: PropTypes.bool.isRequired
};

export default PostDialog;
