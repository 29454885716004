import styled from '@emotion/styled';
import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import DotsMenu from '../common/menu/DotsMenu';
import _ from 'lodash';
import SettlementStatus from './SettlementStatus';
import { memo, useCallback, useMemo } from 'react';

const StyledCell = styled(TableCell)({
  fontSize: 16,
  fontWeight: 500,
  py: 0,
  px: 2,
  align: 'left'
});

const handleStopPropagation = (e) => e.stopPropagation();

const SettlementRow = ({
  row,
  rowIndex,
  onChoose,
  itemSelected,
  onSelect,
  statusesWithActions,
  renderMenuItems,
  ChargingReportTypes,
  numberFormatter,
  props,
  canBePaid
}) => {
  const shouldRederActions = useMemo(
    () => statusesWithActions.some((a) => a === row.calculationStatus),
    [row.calculationStatus, statusesWithActions]
  );
  const isItemSelected = useMemo(() => itemSelected(row.id), [itemSelected, row.id]);

  const totalAmount = useMemo(() => row.getTotalAmount(), [row]);
  const amountToPaid = useMemo(() => row.getAmountToPaid(), [row]);
  const isCorrective = useMemo(() => row.isCorrective(), [row]);

  const formatedTotalAmount = useMemo(() => numberFormatter.format(totalAmount), [numberFormatter, totalAmount]);
  const formatedAmountToPaid = useMemo(() => numberFormatter.format(amountToPaid), [amountToPaid, numberFormatter]);
  const formatedCalculationDate = useMemo(
    () => moment(row.calculationDate).format('YYYY-MM-DD'),
    [row.calculationDate]
  );
  const formatedMaturityDate = useMemo(() => {
    if (!row.maturityDate || !moment(row.maturityDate).isValid()) {
      return 'Brak';
    }
    return moment(row.maturityDate).format('YYYY-MM-DD');
  }, [row.maturityDate]);

  const handleSelect = useCallback(() => onSelect({ id: row.id }), [onSelect, row.id]);
  const handleChoose = useCallback(() => onChoose(row), [onChoose, row]);
  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13) onChoose(row);
    },
    [onChoose, row]
  );

  // const Actions = useMemo(
  //   () =>

  //     ),
  //   [props, renderMenuItems, row, shouldRederActions]
  // );

  const renderMenu = useCallback((onClose) => renderMenuItems(row, onClose, props), [props, renderMenuItems, row]);

  return (
    <TableRow
      sx={{
        p: 0,
        cursor: 'pointer',
        background: (theme) =>
          `${canBePaid ? theme.palette.background.color11 : isItemSelected ? theme.palette.background.hover : null}`,
        '&:hover': {
          background: (theme) => `${canBePaid ? theme.palette.background.color11 : theme.palette.background.hover}`
        }
      }}
      hover
      onClick={handleChoose}
      onKeyDown={handleKeyDown}>
      <TableCell align="center" sx={{ p: 0, m: 0 }}>
        <Box onClick={handleStopPropagation} onKeyDown={handleStopPropagation}>
          <Checkbox
            sx={{ color: (theme) => theme.palette.color.contrast }}
            inputProps={{ 'aria-label': `wybierz wiersz ${rowIndex}` }}
            checked={isItemSelected}
            onChange={handleSelect}
          />
        </Box>
      </TableCell>
      <StyledCell>{row.number || '---'}</StyledCell>
      <StyledCell sx={{ whiteSpace: 'nowrap' }}>{`${row.name}`}</StyledCell>
      <StyledCell sx={{ whiteSpace: 'nowrap' }}>
        {isCorrective ? `${ChargingReportTypes[row.dueType].name} (korekta)` : ChargingReportTypes[row.dueType].name}
      </StyledCell>
      <StyledCell>
        <Typography variant="caption" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 16 }}>
          {totalAmount !== undefined && totalAmount !== null ? formatedTotalAmount : '-'}
        </Typography>
      </StyledCell>
      <StyledCell>
        <Typography variant="caption" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 16 }}>
          {amountToPaid !== undefined && amountToPaid !== null ? formatedAmountToPaid : '-'}
        </Typography>
      </StyledCell>
      <StyledCell>
        <SettlementStatus calculationStatus={row.calculationStatus} />
      </StyledCell>
      <StyledCell>{formatedCalculationDate}</StyledCell>
      <StyledCell>{formatedMaturityDate}</StyledCell>
      <TableCell
        sx={{
          position: 'sticky',
          right: 0,
          m: 0,
          p: 0
        }}
        onClick={handleStopPropagation}
        align="center"
        onKeyDown={handleStopPropagation}>
        {shouldRederActions ? (
          <DotsMenu
            sx={{
              p: 0.75,
              borderLeft: (theme) => `1px solid ${theme.palette.text.primary}`,
              background: (theme) => theme.palette.background.default
            }}>
            {renderMenu}
          </DotsMenu>
        ) : (
          <Box
            sx={{
              height: '74px',
              borderLeft: (theme) => `1px solid ${theme.palette.text.primary}`,
              background: (theme) => theme.palette.background.default
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default memo(SettlementRow);
