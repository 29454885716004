import { Paper, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import AuthorizedToPickUpChildHeader from './AuthorizedToPickUpChildHeader';
import AuthorizedToPickUpChildTable from './Table/AuthorizedToPickUpChildTable';
import Pagination from '@mui/material/Pagination';
import * as definedSortTypes from '../../constants/definedSortTypes';
import { getComparator, stableSort } from '../../utils/tableSorting';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ContentWrapper from '../common/contentWrapper';
import userRoles from '../../constants/userRoles';

const AuthorizedToPickUpChildPage = () => {
  const types = {
    children: 'children',
    guardians: 'guardians',
    legalGuardians: 'legalGuardians',
    empty: 'empty'
  };

  const pupils = useSelector((state) => state.children);
  const groups = useSelector((state) => state.groups);

  const [filteredGroups, setFilteredGroups] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableRows, setTableRows] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState({ property: 'childLastName', type: definedSortTypes.STRING_SORT });
  const [rowsAmount, setRowsAmount] = useState(tableRows.length);
  const [filteredRows, setFilteredRows] = useState([]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const staffMembers = useSelector((state) => state.staffMembers);
  const userId = useSelector((state) => state.auth.userId);
  const auth = useSelector((state) => state.auth);
  const unit = useSelector((state) => state.configuration.unit);

  useEffect(() => {
    if (pupils) {
      let table = [];
      pupils.forEach((pupil) => {
        if (pupil.legalGuardians.length > 0) {
          pupil.legalGuardians.map((legalGuardian) =>
            table.push({
              childId: pupil.id,
              childFirstName: pupil.firstName,
              childLastName: pupil.lastName,
              childPhotoUrl: pupil.photoUrl,
              childGroup: pupil.enrolledToGroup ? pupil.enrolledToGroup.id : 0,
              guardianId: legalGuardian.id,
              guardianType: types.legalGuardians,
              guardianFirstName: legalGuardian.firstName,
              guardianLastName: legalGuardian.lastName,
              guardianPhotoUrl: legalGuardian.photoUrl,
              guardianAuthorizationExpirationDate: null
            })
          );
        }
        if (pupil.guardians.length > 0) {
          pupil.guardians.map((guardian) =>
            table.push({
              childId: pupil.id,
              childFirstName: pupil.firstName,
              childLastName: pupil.lastName,
              childGroup: pupil.enrolledToGroup ? pupil.enrolledToGroup.id : 0,
              guardianId: guardian.id,
              guardianType: types.guardians,
              guardianFirstName: guardian.firstName,
              guardianLastName: guardian.lastName,
              guardianPhotoUrl: guardian.photoUrl,
              guardianAuthorizationExpirationDate: guardian.authorizationExpirationDate
                ? guardian.authorizationExpirationDate
                : null,
              guardianDocumentNumber: guardian.documentNumber
            })
          );
        }
        if (pupil.guardians.length === 0 && pupil.legalGuardians.length === 0) {
          table.push({
            childId: pupil.id,
            childFirstName: pupil.firstName,
            childLastName: pupil.lastName,
            childGroup: pupil.enrolledToGroup ? pupil.enrolledToGroup.id : 0,
            guardianId: 0,
            guardianType: types.empty,
            guardianFirstName: null,
            guardianLastName: null,
            guardianPhotoUrl: null,
            guardianAuthorizationExpirationDate: null,
            guardianDocumentNumber: null
          });
        }
      });
      setTableRows(table);
    }
  }, [pupils]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleChangeFilteredGroups = React.useCallback((value) => {
    setFilteredGroups(value);
  }, []);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleRequestSort = (event, property, type) => {
    const isAsc = orderBy.property === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy({ property, type });
    setPage(1);
  };

  const sortedTable = useMemo(() => {
    return stableSort(tableRows, getComparator(order, orderBy));
  }, [order, orderBy, tableRows]);

  const teacherGroupsId = useMemo(() => {
    return staffMembers.filter((e) => e.id === userId)[0]?.groups.map((element) => element.id);
  }, [staffMembers, userId]);

  useEffect(() => {
    let temp =
      auth.userRole === userRoles.staffMemberTeacher && !unit.teacherAllGroupVisibility
        ? sortedTable.filter((e) => teacherGroupsId.includes(e.childGroup))
        : sortedTable;

    if (filteredGroups.length || searchText) {
      if (filteredGroups.length) {
        temp = temp.filter((row) =>
          row.childGroup ? filteredGroups.some((group) => row.childGroup === group.id) : false
        );
      }
    }
    if (searchText) {
      temp = temp.filter(
        (row) =>
          (row.childFirstName + ' ' + row.childLastName).toLowerCase().includes(searchText.toLowerCase()) ||
          (row.childLastName + ' ' + row.childFirstName).toLowerCase().includes(searchText.toLowerCase()) ||
          (row.guardianFirstName ? row.guardianFirstName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
          (row.guardianLastName ? row.guardianLastName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
          (row.guardianFirstName && row.guardianLastName
            ? (row.guardianFirstName + ' ' + row.guardianLastName).toLowerCase().includes(searchText.toLowerCase())
            : false) ||
          (row.guardianFirstName && row.guardianLastName
            ? (row.guardianLastName + ' ' + row.guardianFirstName).toLowerCase().includes(searchText.toLowerCase())
            : false)
      );
    }

    setRowsAmount(temp.length);
    temp = temp.slice(rowsPerPage * (page - 1), rowsPerPage * page);

    setFilteredRows(temp);
  }, [
    tableRows,
    searchText,
    filteredGroups,
    page,
    rowsPerPage,
    auth.userRole,
    unit.teacherAllGroupVisibility,
    sortedTable,
    teacherGroupsId
  ]);

  return (
    <LoadingRenderWrapper>
      <>
        <PageHeaderText title="Osoby upoważnione do odbioru dziecka" titleIcon={<PeopleAltOutlinedIcon />} />
        <ContentWrapper>
          <AuthorizedToPickUpChildHeader
            groups={groups}
            filteredGroups={filteredGroups}
            onChangeFilteredGroups={handleChangeFilteredGroups}
            handleChangePage={setPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            searchText={searchText}
            setSearchText={setSearchText}
            types={types}
          />
          <Paper sx={{ py: 3 }}>
            <Typography
              variant="h6"
              sx={{ my: 1, mx: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              Tabela upoważnień do odbioru
            </Typography>

            {filteredRows.length > 0 ? (
              <AuthorizedToPickUpChildTable
                filteredRows={filteredRows}
                onSort={handleRequestSort}
                types={types}
                order={order}
                orderBy={orderBy}
              />
            ) : null}

            <Pagination
              siblingCount={isMobile ? 0 : 1}
              count={Math.ceil(rowsAmount / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              sx={{ mt: 2, mx: 'auto', display: 'flex', justifyContent: 'center' }}
            />
          </Paper>
        </ContentWrapper>
      </>
    </LoadingRenderWrapper>
  );
};

export default AuthorizedToPickUpChildPage;
