import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../../forms/DialogForm';
import UnitAddressForm from '../forms/UnitAddressForm';
import { UnitAddressValidator } from '../../../../models/configuration/unit/UnitModelsValidators';

class UnitAddressDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.address,
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const field = event.target.name;
    const address = Object.assign({}, this.state.address);
    address[field] = event.target.value;
    return this.setState({ address });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    return (
      <DialogForm
        header="Zmiana danych placówki"
        onSave={() => onSave(this.state.address)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidate={() => new UnitAddressValidator().validate(this.state.address)}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="configuration.unitAddressUi"
        requiredFieldText>
        <UnitAddressForm address={this.state.address} onChange={this.handleChange} errors={this.state.errors} />
      </DialogForm>
    );
  }
}

UnitAddressDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  address: PropTypes.object.isRequired
};

export default UnitAddressDialog;
