import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as importChildrenActions from '../../../actions/importChildrenActions';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '../../common/Dialog';
import FilePickerWithRef from '../../common/FilePickerWithRef/FilePickerWithRef';
import { Box, Button, FormControl, Typography } from '@mui/material';

class ImportChildrenDialog extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleFilePicked = this.handleFilePicked.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.renderImportSummary = this.renderImportSummary.bind(this);
    this.fileRef = React.createRef();
  }

  handleFilePicked(file) {
    if (this.props.nadarzyn) this.props.actions.importStudentAsync(file);
    else this.props.actions.importChildrenAsync(file);
  }

  handleClose() {
    this.props.actions.importChildrenClose();
  }

  renderImportSummary() {
    const actions = [
      <Button
        variant="contained"
        aria-label="OK"
        key={1}
        onClick={() => this.props.actions.importChildrenSummaryCancel()}>
        OK
      </Button>
    ];
    return (
      <Dialog
        title={`Importowanie ${this.props.nadarzyn ? 'uczniów' : 'dzieci'} - podsumowanie`}
        actions={actions}
        modal={false}
        open={this.props.summaryUi.isEditing}
        onRequestClose={this.props.actions.importChildrenSummaryCancel}>
        <Typography>
          {this.props.nadarzyn ? 'Dodanych uczniów' : 'Dodanych dzieci:'} {this.props.summary.addedChildrenCount}
        </Typography>
        {!this.props.nadarzyn && <Typography>Dodanych opiekunów: {this.props.summary.addedGuardiansCount}</Typography>}
        {!!this.props.summary.errorList ? (
          <Box>
            <Typography>Nie udało się zaimportować: </Typography>
            <Box sx={{ overflow: 'auto', maxHeight: '200px' }}>
              {this.props.summary.errorList.map((family, index) => {
                return (
                  <Box key={index}>
                    {Object.keys(family).map((key) => {
                      return (
                        <Typography key={key}>{`- ${key === 'Child' ? 'Dziecko' : 'Opiekun prawny'}: ${
                          family[key].firstName
                        } ${family[key].lastName}`}</Typography>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          </Box>
        ) : null}
      </Dialog>
    );
  }

  render() {
    const actions = [
      <Button
        variant="outlinedContrast"
        aria-label="Anuluj"
        key={1}
        onClick={() => this.props.actions.importChildrenCancel()}
        disabled={this.props.ui.isProcessing}>
        Anuluj
      </Button>,
      <Button
        variant="contained"
        aria-label="Wgraj plik"
        key={2}
        onClick={() => this.fileRef.current.open()}
        disabled={this.props.ui.isProcessing}>
        Wgraj plik
      </Button>
    ];

    return (
      /*eslint no-return-assign: 0 */
      /*eslint react/jsx-no-bind: 0 */
      <FormControl hiddenLabel>
        <Dialog
          title={`Importowanie ${this.props.nadarzyn ? 'uczniów' : 'dzieci'}`}
          actions={actions}
          modal={false}
          open={this.props.ui.isEditing}
          onRequestClose={this.handleClose}>
          {this.props.ui.isProcessing ? <LinearProgress sx={{ width: '100%' }} /> : null}
        </Dialog>
        <FilePickerWithRef ref={this.fileRef} onDrop={this.handleFilePicked} />
        {this.renderImportSummary()}
      </FormControl>
    );
  }
}

ImportChildrenDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  summaryUi: PropTypes.object.isRequired,
  summary: PropTypes.object.isRequired,
  nadarzyn: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    ui: state.configuration.importChildrenUi,
    summaryUi: state.configuration.importChildrenSummaryUi,
    summary: state.configuration.importChildrenSummary,
    nadarzyn: state.configuration.unit.features.nadarzyn
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(importChildrenActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportChildrenDialog);
