import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as profileHistoryActions from '../../../actions/profileHistoryActions';
import EmptyState from '../../common/EmptyState';
import LegalGuardianHistoryDialog from './LegalGuardianHistoryDialog';
import SingleLegalGuardianItem from './SingleLegalGuardianItem';
import { Box, List } from '@mui/material';

const LegalGuardiansList = (props) => {
  const { legalGuardians, actions, profileHistory } = props;

  const [dialogState, setDialogState] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [loading, setLoading] = useState(false);
  const openProfileHistoryDialog = (id) => {
    setSelectedUser(id);
    setDialogState(true);
    setLoading(true);
    actions.loadProfileHistoryAsync(id, { fetchCount: 10, page: 0 }).then(() => {
      setLoading(false);
    });
  };

  const loadProfileHistory = (id, params = { fetchCount: 10, page: 0 }) => {
    setLoading(true);
    actions.loadProfileHistoryAsync(id, params).then(() => {
      setLoading(false);
    });
  };
  const closeProfileHistoryDialog = () => {
    setDialogState(false);
  };

  return (
    <Box sx={{ p: 2 }}>
      {legalGuardians.length ? (
        <List sx={{ p: 0 }}>
          {legalGuardians.map((legal, index) => (
            <SingleLegalGuardianItem
              key={legal.id}
              lastItem={index === legalGuardians.length - 1}
              legal={legal}
              openProfileHistoryDialog={openProfileHistoryDialog}
              {...props}
            />
          ))}
        </List>
      ) : (
        <EmptyState message="Nie znaleziono żadnej pozycji na liście" contrast />
      )}
      <LegalGuardianHistoryDialog
        loading={loading}
        dialogState={dialogState}
        profileHistory={profileHistory}
        userId={selectedUser}
        onOpen={loadProfileHistory}
        onFilter={loadProfileHistory}
        onClose={closeProfileHistoryDialog}
      />
    </Box>
  );
};

LegalGuardiansList.propTypes = {
  legalGuardians: PropTypes.array.isRequired,
  isPinEnabled: PropTypes.bool.isRequired,
  isAllowed: PropTypes.func.isRequired,
  isStaffMember: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRestartActivation: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  profileHistory: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(profileHistoryActions, dispatch)
  };
};

const mapStateToProps = (state) => {
  return {
    profileHistory: state.profileHistory
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalGuardiansList);
