import PropTypes from 'prop-types';
import React from 'react';
import ChildAttendanceCalendar from '../../common/children/attendance/calendar/ChildAttendanceCalendar';
import PreviousAttendanceEditDialogs from '../../attendanceSheet/previousJournal/PreviousAttendanceEditDialogs';
import AttendanceDetailsDialog from './dialogs/AttendanceDetailsDialog';
import NotificationDialog from '../../forms/NotificationDialog';
import moment from 'moment';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { CardActionArea, Typography, CardContent, Paper, Collapse } from '@mui/material';

class ChildAttendanceCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      currentDay: null,
      isDetailsDialogOpen: false,
      isReportingDisabledDialogOpen: false,
      isOpen: this.props.isInitiallyExpanded
    };
    this.getAttendanceDetailsByDay = this.getAttendanceDetailsByDay.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  getAttendanceDetailsByDay(date) {
    return date
      ? this.props.attendances.entries.find(
          (a) => moment(a.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
        )
      : null;
  }

  handleDetailsOpen(day) {
    this.setState({
      currentDay: day,
      isDetailsDialogOpen: true
    });
  }

  handleOpen() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  }

  filterAuthorizedGuardians(guardians) {
    if (!this.state.currentDay) return [];
    return guardians.filter(
      (g) =>
        g.isAuthorized &&
        (g.authorizationExpirationDate == null ||
          !moment(g.authorizationExpirationDate).isBefore(
            moment(this.state.currentDay.date || moment()).startOf('day')
          ))
    );
  }

  whenChildIsEnrolledToGroup(day, actionWhenEnrolled) {
    if (this.props.childDetails.enrolledToGroup) {
      this.setState({ currentDay: day }, actionWhenEnrolled);
    } else {
      this.setState({ isReportingDisabledDialogOpen: true });
    }
  }

  render() {
    return (
      <Paper elevation={1} sx={{ my: 2, p: 1 }}>
        <CardActionArea onClick={this.handleOpen}>
          <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2, py: 1 }}>
            <Typography
              variant="subtitle1"
              sx={{
                my: '4px',
                fontWeight: (theme) => theme.typography.fontWeightBold
              }}>
              Obecności
            </Typography>
            {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
          </CardContent>
        </CardActionArea>
        <Collapse in={this.state.isOpen}>
          <ChildAttendanceCalendar
            allDays={this.props.attendances}
            markedDays={this.props.attendances.entries}
            attendanceSummary={this.props.attendances.summary}
            daysOff={this.props.daysOff}
            onDateChange={this.props.onDateChange}
            onDaySelected={() => {}}
            onReportAttendance={(day) =>
              this.whenChildIsEnrolledToGroup(
                day,
                this.props.attendanceHistoryAction.reportPreviousChildAttendanceStart
              )
            }
            onReportAbsence={(day) =>
              this.whenChildIsEnrolledToGroup(
                day,
                this.props.attendanceHistoryAction.revertPreviousChildAttendanceStart
              )
            }
            onRevertAbsence={(day) =>
              this.whenChildIsEnrolledToGroup(day, () =>
                this.props.attendanceAction.revertChildAbsenceAsync(this.props.childDetails.id, day.date, day.date)
              )
            }
            onDetailsOpen={(day) => this.handleDetailsOpen(day)}
            isLoading={this.props.isCalendarLoading}
          />
          <PreviousAttendanceEditDialogs
            reportAttendanceUi={this.props.attendanceUi.reportAttendance}
            onReportAttendanceCancel={this.props.attendanceHistoryAction.reportPreviousChildAttendanceCancel}
            onReportAttendance={this.props.attendanceHistoryAction.reportPreviousChildAttendanceAsync}
            revertAttendanceUi={this.props.attendanceUi.revertAttendance}
            onRevertAttendanceCancel={this.props.attendanceHistoryAction.revertPreviousChildAttendanceCancel}
            onRevertAttendance={this.props.attendanceHistoryAction.revertPreviousChildAttendanceAsync}
            childId={this.props.childDetails.id}
            absenceFrom={this.state.currentDay ? this.state.currentDay.data.absenceFrom : null}
            absenceTo={this.state.currentDay ? this.state.currentDay.data.absenceTo : null}
            isAbsenceFromBlocked={!!(this.state.currentDay && this.state.currentDay.data.droppedOffAt)}
            isAbsenceToBlocked={!!(this.state.currentDay && this.state.currentDay.data.droppedOffAt)}
            droppedOffAt={this.state.currentDay ? this.state.currentDay.data.droppedOffAt : null}
            pickedUpAt={this.state.currentDay ? this.state.currentDay.data.pickedUpAt : null}
            pickedUpBy={this.state.currentDay ? this.state.currentDay.data.pickedUpBy : null}
            childFirstName={this.props.childDetails.firstName}
            childLastName={this.props.childDetails.lastName}
            childPhotoUrl={this.props.childDetails.photoUrl}
            childAllGuardians={[
              ...this.props.childDetails.legalGuardians,
              ...this.filterAuthorizedGuardians(this.props.childDetails.guardians)
            ]}
            childContract={this.props.childDetails.contract}
            journalDate={this.state.currentDay ? this.state.currentDay.date : null}
          />
          <AttendanceDetailsDialog
            isDialogOpen={this.state.isDetailsDialogOpen}
            childDetails={this.props.childDetails}
            childAttendance={this.getAttendanceDetailsByDay(this.state.currentDay ? this.state.currentDay.date : null)}
            onRequestClose={() => this.setState({ isDetailsDialogOpen: false })}
          />
          <NotificationDialog
            notificationText="Nie można zgłosić obecności/nieobecności. Dziecko nie jest przypisane do grupy."
            notificationTitle="Uwaga"
            onConfirm={() => this.setState({ isReportingDisabledDialogOpen: false })}
            isOpen={this.state.isReportingDisabledDialogOpen}
          />
        </Collapse>
      </Paper>
    );
  }
}

ChildAttendanceCard.propTypes = {
  attendances: PropTypes.object.isRequired,
  onDateChange: PropTypes.func.isRequired,
  daysOff: PropTypes.array.isRequired,
  isCalendarLoading: PropTypes.bool.isRequired,

  attendanceUi: PropTypes.object.isRequired,
  attendanceHistoryAction: PropTypes.object.isRequired,
  attendanceAction: PropTypes.object.isRequired,
  childDetails: PropTypes.object.isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired
};

export default ChildAttendanceCard;
