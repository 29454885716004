import PropTypes from 'prop-types';
import React from 'react';
import RichTextEditor from '../../forms/richTextEditor/RichTextEditor';
import { TimeModel } from '../../../models/TimeModel';
import localStorageProvider from '../../../utils/localStorageProvider';
import { TextField, Switch, Box, Alert, Typography, Grid } from '@mui/material';
import DialogForm from '../../forms/DialogForm';
import RecipientOptions from './RecipientsOptions';
import {
  BoardPostModelValidator,
  BoardPostWithoutDateModelValidator
} from '../../../models/messageBoard/MessageBoardModelsValidator';
import { BoardPostModel } from '../../../models/messageBoard/MessageBoardModels';
import DatePicker from '../../common/DatePicker';
import TimePicker from '../../common/TimePicker';
import ClearButtonWrapper from '../../common/ClearButtonWrapper';
import withMediaQuery from '../../../hoc/withMediaQuery';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import FileUploader from '../../common/fileUploader/FileUploader';
import EventNote from '@mui/icons-material/FeedbackOutlined';
import { options } from '../../../constants/recipientsOptionsToChoose';

class PostEditor extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      post: new BoardPostModel().assign(this.props.post),
      limitedToGroups: props.post.limitedToGroups,
      limitedToActivities: props.post.limitedToActivities,
      errors: {},
      uploadErrors: [],
      postType: this.props.type
    };
    this.handleGroupSelectionChanged = this.handleGroupSelectionChanged.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
    this.handlePinnedPostStateChange = this.handlePinnedPostStateChange.bind(this);
    this.handleSelectedPublishOptionValueChange = this.handleSelectedPublishOptionValueChange.bind(this);
    this.handleFileAdded = this.handleFileAdded.bind(this);
    this.handleFileRemoved = this.handleFileRemoved.bind(this);
    this.token = localStorageProvider.getItem('authToken');
  }

  componentDidMount() {
    if (this.state.post.limitedToGroups.length)
      this.setState({ post: Object.assign(this.state.post, { selectedPublishOptionValue: options.limited.value }) });
    else if (this.state.post.limitedToActivities.length)
      this.setState({
        post: Object.assign(this.state.post, { selectedPublishOptionValue: options.limitedToActivities.value })
      });
    else this.setState({ post: Object.assign(this.state.post, { selectedPublishOptionValue: options.all.value }) });
  }

  handleGroupSelectionChanged(limitedToGroups, limitedToActivities) {
    this.setState({
      limitedToGroups,
      limitedToActivities,
      post: Object.assign(this.state.post, { limitedToGroups, limitedToActivities })
    });
  }

  handleTitleChange(event) {
    this.setState({ post: Object.assign(this.state.post, { title: event.target.value }) });
  }

  handleContentChange(htmlContent) {
    this.setState({ post: Object.assign(this.state.post, { content: htmlContent }) });
  }

  handleSelectedPublishOptionValueChange(event) {
    this.setState({ post: Object.assign(this.state.post, { selectedPublishOptionValue: event.target.value }) });
    if (event.target.value === options.all.value)
      this.setState({ post: Object.assign(this.state.post, { limitedToGroups: [], limitedToActivities: [] }) });
    if (event.target.value === options.limited.value)
      this.setState({ post: Object.assign(this.state.post, { limitedToActivities: [] }) });
    if (event.target.value === options.limitedToActivities.value)
      this.setState({ post: Object.assign(this.state.post, { limitedToGroups: [] }) });
  }

  handleEventDateChange(date) {
    this.setState({ post: Object.assign(this.state.post, { eventDate: date }) });
    if (!date) {
      this.handleEventStartTimeChange(undefined);
      this.handleEventEndTimeChange(undefined);
    }
  }

  handleEventStartTimeChange(time) {
    this.setState({
      post: Object.assign(this.state.post, {
        eventStartTime: time ? new TimeModel().parse(time) : new TimeModel()
      })
    });
    if (!time) {
      this.handleEventEndTimeChange(undefined);
    }
  }

  handleEventEndTimeChange(time) {
    this.setState({
      post: Object.assign(this.state.post, { eventEndTime: time ? new TimeModel().parse(time) : new TimeModel() })
    });
  }

  handlePinnedPostStateChange(event) {
    this.setState({ post: Object.assign(this.state.post, { pinned: event.target.checked }) });
  }

  handleFileAdded(file) {
    this.setState((prevState) => ({
      post: Object.assign(prevState.post, { files: [...(prevState.post.files || []), file] })
    }));
  }

  handleFileRemoved(file) {
    this.setState((prevState) => ({
      post: Object.assign(prevState.post, { files: prevState.post.files.filter((f) => f.id !== file.id) })
    }));
  }

  render() {
    const isGroupsWarning =
      this.state.limitedToGroups &&
      this.state.limitedToGroups.length > 0 &&
      this.props.post.limitedToGroups &&
      this.props.post.limitedToGroups.some(
        (item) => !this.state.limitedToGroups.some((newItem) => newItem.id === item.id)
      );
    const { isMobile } = this.props;
    return (
      <DialogForm
        onSave={() => {
          this.props.onSave(this.state.post);
        }}
        onCancel={() => this.props.onCancel(this.state.post)}
        onValidate={() =>
          this.state.postType === 'event'
            ? new BoardPostModelValidator().validate(this.state.post)
            : new BoardPostWithoutDateModelValidator().validate(this.state.post)
        }
        onValidationDone={(errors) => {
          this.setState({ errors });
        }}
        statePathToUi="messageBoardUi"
        isDialogOpen={this.props.isEditing}
        isProcessing={this.props.isProcessing}
        requiredFieldText
        sx={{ fontFamily: 'League Spartan' }}
        icon={<EventNote fontSize="large" />}
        header={this.state.postType === 'event' ? 'Utwórz wydarzenie' : `Utwórz ogłoszenie`}>
        <FieldsetWrapper
          sx={{ color: (theme) => theme.palette.color.contrast }}
          legend={
            this.state.postType === 'event'
              ? 'Pola opisujące tworzone wydarzenie'
              : 'Pola opisujące tworzone ogłoszenie'
          }>
          <TextField
            sx={{
              fontSize: 24,
              lineHeight: 36
            }}
            label="Tytuł*"
            autoFocus={true}
            name="title"
            id="title"
            variant="standard"
            defaultValue={this.props.post.title}
            onChange={this.handleTitleChange}
            fullWidth={true}
            error={!!this.state.errors.title}
            helperText={this.state.errors.title}
            FormHelperTextProps={{ role: 'status' }}
          />
          <RichTextEditor
            errorText={this.state.errors.content}
            onChange={this.handleContentChange}
            initialText={this.state.post.content}
          />
          <FileUploader
            files={this.state.post && this.state.post.files}
            onFileUploaded={this.handleFileAdded}
            onFileRemoved={this.handleFileRemoved}
          />

          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row'
            }}>
            {this.props.canSetDate && (
              <>
                <Grid item xs={12} md={4} lg={3}>
                  <ClearButtonWrapper
                    onClear={() => this.handleEventDateChange(undefined)}
                    disabled={!this.props.canClearDate}
                    contrast>
                    <DatePicker
                      floatingLabelText={this.state.postType === 'event' ? 'Data wydarzenia' : 'Data ogłoszenia'}
                      onChange={(e, date) => this.handleEventDateChange(date)}
                      value={this.state.post.eventDate ? new Date(this.state.post.eventDate) : undefined}
                      name="eventDate"
                      errorText={this.state.errors.eventDate}
                    />
                  </ClearButtonWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <ClearButtonWrapper
                    onClear={() => this.handleEventStartTimeChange(null)}
                    contrast
                    disabled={!this.props.canClearDate}>
                    <TimePicker
                      floatingLabelText="Rozpoczęcie"
                      disabled={!this.state.post.eventDate}
                      onChange={(e, time) => this.handleEventStartTimeChange(time)}
                      value={!this.state.post.eventStartTime.isEmpty() ? this.state.post.eventStartTime.toDate() : null}
                      name="eventStartTime"
                      errorText={this.state.errors.eventStartTime}
                    />
                  </ClearButtonWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <ClearButtonWrapper
                    onClear={() => this.handleEventEndTimeChange(null)}
                    contrast
                    disabled={!this.props.canClearDate}>
                    <TimePicker
                      floatingLabelText="Zakończenie"
                      disabled={this.state.post.eventStartTime.isEmpty()}
                      onChange={(e, time) => this.handleEventEndTimeChange(time)}
                      value={!this.state.post.eventEndTime.isEmpty() ? this.state.post.eventEndTime.toDate() : null}
                      name="eventEndTime"
                      errorText={this.state.errors.eventEndTime}
                    />
                  </ClearButtonWrapper>
                </Grid>
              </>
            )}
            <Grid item xs={12} lg={3} sx={{ pt: { xs: 2, lg: 0 } }}>
              <RecipientOptions
                selectedPublishOptionValue={this.state.post.selectedPublishOptionValue}
                groups={this.props.groups}
                activities={this.props.activities}
                selectedGroups={this.state.post.limitedToGroups}
                limitedToGroupsError={!!this.state.errors.limitedToGroups}
                limitedToGroupsHelperText={this.state.errors.limitedToGroups}
                limitedToActivitiesError={!!this.state.errors.limitedToActivities}
                limitedToActivitiesHelperText={this.state.errors.limitedToActivities}
                selectedActivities={this.state.post.limitedToActivities}
                onSelectedPublishOptionValueChange={this.handleSelectedPublishOptionValueChange}
                onSelectionChanged={this.handleGroupSelectionChanged}
              />
            </Grid>
          </Grid>

          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row', mt: 2 }}>
            {!this.props.canSetDate && (
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  mx: 7,
                  my: 0,
                  letterSpacing: '2px'
                }}
                variant="subtitle2">
                Przypnij do tablicy
                <Switch
                  sx={{ transform: 'scale(1.5)', mx: 4 }}
                  defaultChecked={this.props.post.pinned}
                  onChange={this.handlePinnedPostStateChange}
                />
              </Typography>
            )}
          </Box>
          {isGroupsWarning && (
            <Alert severity="warning">
              Usunięcie uprawnień do ogłoszenia dla grupy może spowodować błąd po stronie rodzica z odczytem wiadomości
            </Alert>
          )}
        </FieldsetWrapper>
        {this.state.errors && this.state.errors.authorId && (
          <Alert severity="error" sx={{ mb: 10 }}>
            {this.state.errors.authorId}
          </Alert>
        )}
      </DialogForm>
    );
  }
}

PostEditor.propTypes = {
  post: PropTypes.object.isRequired,
  type: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  canClearDate: PropTypes.bool,
  canSetDate: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool
};

PostEditor.defaultProps = {
  canClearDate: true,
  canSetDate: true
};

export default withMediaQuery()(PostEditor);
