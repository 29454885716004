/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { SettlementsFiltersModel } from '../models/settlements/SettlementsFiltersModel';

export default function settlementsFiltersReducer(state = initialState.settlementsFilters, action) {
  switch (action.type) {
    case types.SAVE_SETTLEMENTS_FILTERS: {
      return new SettlementsFiltersModel().assign({ ...state, ...action.filters });
    }
    case types.RESET_SETTLEMENTS_FILTERS: {
      return new SettlementsFiltersModel();
    }
    default:
      return state;
  }
}
