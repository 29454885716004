import { ListItem, Typography, Grid } from '@mui/material';
import React from 'react';
import { FormikTextField } from '../../../formik/FormikTextField';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';

const StudentGuardianFormAddress = () => {
  return (
    <>
      <FieldsetWrapper
        legend={
          <Typography component="span" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, pl: 2 }}>
            Dane opiekuna
          </Typography>
        }
        invisible={false}
        labelStyle={{ color: (theme) => theme.palette.color.contrast }}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <ListItem>
              <FormikTextField label="Imie*" name="guardianFirstName" fullWidth />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItem>
              <FormikTextField label="Nazwisko*" name="guardianLastName" fullWidth />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItem>
              <FormikTextField label="Numer telefonu*" name="guardianContactNumber" fullWidth />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItem>
              <FormikTextField label="Adres*" name="guardiansAddress.address" fullWidth />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItem>
              <FormikTextField label="Kod pocztowy*" name="guardiansAddress.zipCode" fullWidth />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItem>
              <FormikTextField label="Miejscowość*" name="guardiansAddress.city" fullWidth />
            </ListItem>
          </Grid>
        </Grid>
      </FieldsetWrapper>
    </>
  );
};

export default StudentGuardianFormAddress;
