import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../../actions/authActions';
import PinSignInForm from './PinSignInForm';
import backgroundAdorment from '../../assets/svg/hero-bg-dots.svg';

class PinSignInPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      signInData: { userName: '', password: '' },
      errorMessage: null
    };

    this.onUpdateSignInState = this.onUpdateSignInState.bind(this);
    this.onSignIn = this.onSignIn.bind(this);
    this.handleChangeErrorState = this.handleChangeErrorState.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lastAuthErrorMessage !== this.props.lastAuthErrorMessage) {
      this.handleChangeErrorState(this.props.lastAuthErrorMessage);
    }
  }

  handleChangeErrorState(value) {
    this.setState({ errorMessage: value });
  }

  onUpdateSignInState(event) {
    const field = event.target.name;
    const { signInData } = this.state;
    signInData[field] = event.target.value;
    return this.setState({ signInData, errorMessage: null });
  }

  onSignIn(event) {
    event.preventDefault();
    this.props.actions.signInPinDeviceAsync(this.state.signInData);
  }

  render() {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: (theme) =>
            `url(${backgroundAdorment}), transparent linear-gradient(33deg, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor2} 100%) 0% 0% no-repeat padding-box`
        }}>
        <Box>
          <PinSignInForm
            signInData={this.state.signInData}
            onChange={this.onUpdateSignInState}
            onSignIn={this.onSignIn}
            errorMessage={this.state.errorMessage}
            isProcessing={this.props.isProcessing}
          />
        </Box>
      </Box>
    );
  }
}

PinSignInPage.propTypes = {
  actions: PropTypes.object.isRequired,
  lastAuthErrorMessage: PropTypes.string,
  isProcessing: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    lastAuthErrorMessage: state.auth.lastAuthErrorMessage,
    isProcessing: state.auth.isProcessing
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PinSignInPage);
