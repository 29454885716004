import api from '../api/NumberingSchemesApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import { browserHistory } from 'react-router';
import { routePaths } from '../routePaths';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';

export function loadNumberingSchemesAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_NUMBERING_SCHEMES });
    return api
      .getNumberingSchemes()
      .then((schemes) => dispatch({ type: types.LOAD_NUMBERING_SCHEMES_SUCCESS, schemes }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać schematów numeracji'));
        return logger.error(error);
      });
  };
}

export function loadNumberingSchemeDetailsAsync(id) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_NUMBERING_SCHEME_DETAILS });
    return api
      .getNumberingScheme(id)
      .then((scheme) => dispatch({ type: types.LOAD_NUMBERING_SCHEME_DETAILS_SUCCESS, scheme }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać szczegółów schematu numeracji'));
        return logger.error(error);
      });
  };
}

export function createNumberingSchemeDetailsStart() {
  return { type: types.CREATE_NUMBERING_SCHEME_DETAILS };
}

export function createNumberingSchemeDetailsCancel() {
  return { type: types.CREATE_NUMBERING_SCHEME_DETAILS_CANCEL };
}

export function createNumberingSchemeDetailsAsync(nextScheme) {
  const scheme = Object.assign({}, nextScheme);
  return (dispatch) => {
    dispatch({ type: types.CREATE_NUMBERING_SCHEME_DETAILS_SUBMIT });
    return api
      .createNumberingScheme(scheme)
      .then((id) => {
        dispatch(notificationActions.showSuccess(`Dodano serię numeracji '${scheme.name}'`));
        dispatch({ type: types.CREATE_NUMBERING_SCHEME_DETAILS_SUCCESS, scheme: Object.assign(scheme, { id }) });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.CREATE_NUMBERING_SCHEME_DETAILS_FAILURE, errors: error.response.body });
        dispatch(notificationActions.showError(`Nie udało się dodać serii numeracji '${scheme.name}'`));
        return logger.error(error);
      });
  };
}

export function updateNumberingSchemeStart() {
  return { type: types.UPDATE_NUMBERING_SCHEME_DETAILS };
}

export function updateNumberingSchemeCancel() {
  return { type: types.UPDATE_NUMBERING_SCHEME_DETAILS_CANCEL };
}

export function updateNumberingSchemeAsync(nextNumberingScheme) {
  const numberingScheme = Object.assign({}, nextNumberingScheme);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_NUMBERING_SCHEME_DETAILS_SUBMIT });
    return api
      .updateNumberingScheme(numberingScheme.id, numberingScheme)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Zaktualizowano serię numeracji '${numberingScheme.name}'`));
        dispatch({ type: types.UPDATE_NUMBERING_SCHEME_DETAILS_SUCCESS, scheme: numberingScheme });
      })
      .catch((error) => {
        if (error.status === 412)
          return dispatch({ type: types.UPDATE_NUMBERING_SCHEME_DETAILS_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(`Nie udało się zaktualizować schematu numeracji '${numberingScheme.name}'`)
        );
        return logger.error(error);
      });
  };
}

export function deleteNumberingSchemeDetailsAsync(id) {
  return (dispatch, getState) => {
    dispatch({ type: types.DELETE_NUMBERING_SCHEME_DETAILS });
    return api
      .deleteNumberingScheme(id)
      .then(() => {
        dispatch(
          notificationActions.showSuccess(`Usunięto serię numeracji '${get.numberingSchemeName(getState, id)}'`)
        );
        dispatch({ type: types.DELETE_NUMBERING_SCHEME_DETAILS_SUCCESS, schemeId: id });
        browserHistory.push(routePaths.configurationNumberingSchemes);
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się usunąć serii numeracji '${get.numberingSchemeName(getState, id)}'`
          )
        );
        return logger.error(error);
      });
  };
}
