/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function duesExportSettingsUiReducer(state = initialState.configuration.duesFkExportSettingsUi, action) {
  switch (action.type) {
    case types.UPDATE_DUES_FK_EXPORT_SETTINGS: {
      return Object.assign({}, state, { details: UiModel.start() });
    }
    case types.UPDATE_DUES_FK_EXPORT_SETTINGS_SUCCESS: {
      return Object.assign({}, state, { details: UiModel.success() });
    }
    default:
      return state;
  }
}
