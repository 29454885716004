import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as privateMessagesActions from '../../actions/privateMessagesActions';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import ThreadsList from './ThreadView/ThreadsList';
import MessagesList from './MessageView/MessagesList';
import MessageHeader from './MessageView/MessageHeader';
import MailIcon from '@mui/icons-material/Mail';
import EmptyState from '../common/EmptyState';
import withMediaQuery from '../../hoc/withMediaQuery';
import DrawerButton from './DrawerButton/DrawerButton';
import Box from '@mui/material/Box';
import { Drawer, Paper } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButtonPipe from '../common/IconButtonPipe';
import moment from 'moment';
import { SearchBar } from '../common/SearchBar';

class AllMessagesPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleThreadSelection = this.handleThreadSelection.bind(this);
    this.handleLoadMoreMessages = this.handleLoadMoreMessages.bind(this);
    this.handleLoadMoreThreads = this.handleLoadMoreThreads.bind(this);
    this.handleThreadsFiltered = this.handleThreadsFiltered.bind(this);
    this.handleChangeDrawer = this.handleChangeDrawer.bind(this);
    this.handleSearchValueChange = this.handleSearchValueChange.bind(this);
    this.state = {
      thread: null,
      threads: [],
      isDrawerOpen: false,
      searchValue: '',
      threadsOffSet: 20
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.state.thread && this.props.threads.length) {
      this.handleThreadSelection(this.props.threads[0]);
    }
    if (prevProps.threads !== this.props.threads) {
      this.handleThreadsFiltered(this.props.threads);
    }
  }

  handleSearchValueChange(e) {
    this.setState({ searchValue: e.target.value });
  }

  handleThreadSelection(thread) {
    this.setState({ thread }, () => this.props.actions.loadMessagesAsync(thread.threadId, 10, 0, 'all'));
  }

  handleLoadMoreMessages() {
    this.props.actions.loadNextMessagesAsync(this.state.thread.threadId, 10, this.props.messages.length, 'all');
  }

  handleLoadMoreThreads() {
    this.setState({
      threadsOffSet: this.state.threadsOffSet + 10
    });
  }

  handleThreadsFiltered() {
    const filteredThreads = this.props.threads.filter((thread) =>
      thread.participants.some(
        (p) => p.id !== this.props.userId && p.fullName.toLowerCase().includes(this.state.searchValue.toLowerCase())
      )
    );
    const threads = filteredThreads.sort((a, b) => moment(b.lastMessage.sentAt) - moment(a.lastMessage.sentAt));
    this.setState({ threads: threads });
  }

  handleChangeDrawer() {
    this.setState((prevState) => {
      return { isDrawerOpen: !prevState.isDrawerOpen };
    });
  }

  render() {
    const { isRequiredSize } = this.props;
    const { isDrawerOpen } = this.state;

    return (
      <LoadingRenderWrapper>
        {this.props.threads.length && (
          <Paper
            sx={{
              background: (theme) => theme.palette.components.messenger.background,
              mr: { xs: 0, lg: 35 },
              height: '100%',
              maxHeight: '90vh'
            }}>
            <Box sx={{ overflow: 'hidden', mx: 0, height: '100%' }}>
              {isRequiredSize && <DrawerButton closeDrawer={this.handleChangeDrawer} />}
              <Box
                sx={{
                  display: 'flex',
                  height: 'calc( 100vh - 150px )'
                }}>
                {this.state.thread && (
                  <Box sx={{ width: '100%' }}>
                    <MessageHeader
                      allMessages
                      thread={this.state.thread}
                      selectUser={(x) => x.participants.filter((p) => p.id !== this.props.userId)}
                      display="left"
                    />
                    <MessagesList
                      messages={this.props.messages}
                      canLoadMore={this.props.canLoadMoreMessages}
                      onLoadMore={this.handleLoadMoreMessages}
                      isLoading={this.props.isLoadingMessages}
                      isInitial={this.props.isInitialLoadingMessages}
                      userId={this.props.userId}
                      thread={this.state.thread}
                      files={this.props.virtualDiscFolder}
                    />
                  </Box>
                )}
                {!!this.props.threads.length && (
                  <Drawer
                    PaperProps={{
                      sx: {
                        width: 320,
                        borderRadius: 0,
                        borderTopLeftRadius: 10,
                        mt: 8,
                        zIndex: 600,
                        height: 'calc(100vh - 64px)'
                      }
                    }}
                    anchor="right"
                    onClose={() => {
                      this.setState({
                        threads: this.props.threads,
                        isDrawerOpen: !this.state.isDrawerOpen
                      });
                    }}
                    open={isDrawerOpen}
                    variant={isRequiredSize ? 'temporary' : 'permanent'}
                    sx={{
                      overflow: 'hidden'
                    }}>
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {isRequiredSize && (
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <IconButtonPipe
                              sx={{ m: 1 }}
                              onClick={() => {
                                this.setState({
                                  isDrawerOpen: !this.state.isDrawerOpen
                                });
                              }}
                              tooltip="Zwiń">
                              <ArrowForwardIosIcon
                                sx={{ fontSize: 32 }}
                                onClick={() => {
                                  this.setState({
                                    isDrawerOpen: !this.state.isDrawerOpen
                                  });
                                }}
                              />
                            </IconButtonPipe>
                          </Box>
                        )}
                        <Box sx={{ width: '100%', height: '100%' }}>
                          <SearchBar
                            contrast
                            SearchOnClick={this.handleThreadsFiltered}
                            value={this.state.searchValue}
                            onChange={this.handleSearchValueChange}
                            handleTextSearch={this.handleThreadsFiltered}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <ThreadsList
                      threads={this.state.threads.slice(0, this.state.threadsOffSet)}
                      selectUser={() => null}
                      onSelect={this.handleThreadSelection}
                      onLoadMore={this.handleLoadMoreThreads}
                      isLoading={this.props.isLoadingThreads}
                      canLoadMore={true}
                    />
                  </Drawer>
                )}
              </Box>
            </Box>
          </Paper>
        )}
        {!this.props.threads.length && (
          <EmptyState isPaper paperSx={{ mt: 4 }} contrast message="Brak wiadomości" icon={<MailIcon />} />
        )}
      </LoadingRenderWrapper>
    );
  }
}

AllMessagesPage.propTypes = {
  threads: PropTypes.array.isRequired,
  messages: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  canLoadMoreMessages: PropTypes.bool.isRequired,
  canLoadMoreThreads: PropTypes.bool.isRequired,
  isLoadingMessages: PropTypes.bool.isRequired,
  isInitialLoadingMessages: PropTypes.bool.isRequired,
  isLoadingThreads: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  isRequiredSize: PropTypes.bool,
  isMobile: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    threads: state.privateThreads.threads,
    messages: state.privateMessages.messages,
    canLoadMoreMessages: state.privateMessages.canLoadMore,
    isInitialLoadingMessages: state.privateMessages.isInitial,
    canLoadMoreThreads: state.privateThreads.canLoadMore,
    isLoadingMessages: state.privateMessages.isLoading,
    isLoadingThreads: state.privateThreads.isLoading,
    userId: state.auth.userId,
    virtualDiscFolder: state.virtualDiscFolder.disc
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(privateMessagesActions, dispatch)
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withMediaQuery('lg'))(AllMessagesPage);
