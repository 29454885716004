import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, TableHead, Typography, Checkbox } from '@mui/material';

const ScheduledTransferTableHeader = ({ allItemsSelected, onSelect, isHistory = false }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" sx={{ display: 'flex', alignItems: 'center', minWidth: 320 }}>
          {!isHistory && (
            <Checkbox
              size="medium"
              inputProps={{ 'aria-label': 'Zaznacz wszystkie' }}
              sx={{ mr: 3 }}
              checked={allItemsSelected}
              onChange={() => (allItemsSelected ? onSelect('none') : onSelect('all'))}
            />
          )}
          <Typography variant="tableHeader">Imie Nazwisko</Typography>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            minWidth: 128
          }}>
          <Typography variant="tableHeader">Data</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="tableHeader">Przeniesienie</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="tableHeader">{isHistory ? 'Status' : 'Akcje'}</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

ScheduledTransferTableHeader.propTypes = {
  allItemsSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  isHistory: PropTypes.bool
};

export default ScheduledTransferTableHeader;
