/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import UserRoleIcon from '../../../userRoles/UserRoleIcon';
import { useSelector } from 'react-redux';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import { userRolesOptions } from '../../../../forms/virtualDisc/helpers/userRolesOptions';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 24
  },
  container: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box'
  }
}));

export const FileAvatar = ({
  role,
  color,
  tooltip,
  isPrivate = false,
  isOwnByUser = false,
  isShareToUsers = false
}) => {
  const theme = useTheme();

  const isContrastColor = useSelector((state) => state.theme);
  const cl = useStyles({ color, isContrastColor });

  const selectIcon = () => {
    if (isOwnByUser)
      return (
        <FolderSharedOutlinedIcon
          sx={{
            color:
              isContrastColor === 'contrast'
                ? `${theme.palette.color.wcag} !important`
                : `${theme.palette.color.color9} !important`
          }}
          className={cl.icon}
        />
      );
    if (isPrivate)
      return (
        <LockOutlinedIcon
          sx={{
            color:
              isContrastColor === 'contrast'
                ? `${theme.palette.color.wcag} !important`
                : `${theme.palette.color.color21} !important`
          }}
          className={cl.icon}
        />
      );
    if (isShareToUsers)
      return (
        <AccountCircleOutlinedIcon
          sx={{
            color:
              isContrastColor === 'contrast'
                ? `${theme.palette.color.wcag} !important`
                : `${theme.palette.color.color25} !important`
          }}
          className={cl.icon}
        />
      );
    return <UserRoleIcon role={role} className={cl.icon} />;
  };

  return (
    <Tooltip title={tooltip}>
      <Box className={cl.container}>{selectIcon()}</Box>
    </Tooltip>
  );
};

FileAvatar.propTypes = {
  role: PropTypes.string,
  color: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool,
  isOwnByUser: PropTypes.bool,
  isShareToUsers: PropTypes.bool
};

const FileShareAvatars = ({ userRolesList, isOwnByUser, isShareToUsers, containerStyles, children }) => {
  const theme = useTheme();
  const isPrivate = userRolesList.length === 0 && !isShareToUsers;
  if (isOwnByUser && isPrivate)
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, 30px)`,
          gridGap: 2,
          width: '100%',
          ...containerStyles
        }}>
        <FileAvatar tooltip="Jesteś właścicielem tego pliku" isOwnByUser={true} color={theme.palette.color.color4} />
        <FileAvatar tooltip="Plik prywatny" isPrivate={true} color={theme.palette.grey[600]} />
      </Box>
    );
  if (isPrivate) return <FileAvatar tooltip="Plik prywatny" isPrivate={true} color={theme.palette.grey[600]} />;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, 30px)`,
        gridGap: 2,
        ...containerStyles
      }}>
      {isOwnByUser && (
        <FileAvatar tooltip="Jesteś właścicielem tego pliku" isOwnByUser={true} color={theme.palette.color.color4} />
      )}
      {userRolesList.map((role) => {
        const option = userRolesOptions.find((item) => item.role === role);
        return (
          <FileAvatar
            key={option.id}
            role={role}
            color={theme.palette.components.role[role]}
            tooltip={option.tooltip}
          />
        );
      })}
      {isShareToUsers && (
        <FileAvatar
          tooltip="Udostępnione bezpośrednio dla co najmniej jednej osoby"
          isShareToUsers={true}
          color={theme.palette.color.color7}
        />
      )}
      {children}
    </Box>
  );
};

FileShareAvatars.propTypes = {
  userRolesList: PropTypes.array.isRequired,
  isOwnByUser: PropTypes.bool.isRequired,
  isShareToUsers: PropTypes.bool,
  containerStyles: PropTypes.object,
  children: PropTypes.node
};

export default FileShareAvatars;
