import * as types from './actionTypes';
import * as reportsActions from './reportsActions';
import * as userAccountActions from './userAccountActions';
import * as groupAction from './groupsActions';
import * as activitiesActions from './activitiesActions';
import * as childrenAction from './childrenActions';
import * as membersActions from './staffMembersActions';
import * as messageBoardActions from './messageBoardActions';
import * as daysOffActions from './daysOffActions';
import * as unitActions from './unitActions';
import * as chargingSchemesActions from './chargingSchemesActions';
import * as reliefsActions from './reliefsActions';
import * as numberingSchemesActions from './numberingSchemesActions';
import * as psychologicalActions from './psychologicalActions';
import * as psychologicalGroupsActions from './psychologicalGroupsActions';
import * as psychologicalIndividualClassesActions from './psychologicalIndividualClassesActions';
import * as psychologicalIndividualJournalClassesActions from './psychologicalIndividualJournalClassesActions';
import * as psychologicalGroupClassesActions from './psychologicalGroupClassesActions';
import * as settlementsActions from './settlementsActions';
import * as settlementsSettingsActions from './settlementsSettingsActions';
import * as historyActions from './attendanceHistoryActions';
import * as attendanceActions from './attendanceActions';
import * as guardianDuesActions from './guardianDuesActions';
import * as reportsConfigurationActions from './reportsConfigurationActions';
import * as attributesActions from './attributesActions';
import * as privateMessagesActions from './privateMessagesActions';
import * as guardianPinServiceActions from './guardianPinServiceActions';
import * as daycareSettingsActions from './daycareSettingsActions';
import * as daycareActions from './daycareActions';
import * as digitalDiaryActions from './digitalDiaryActions';
import * as digitalDiaryWorkPlanActions from './digitalDiaryWorkPlanActions';
import * as messageBoardDigitalDiaryActions from './messageBoardDigitalDiaryActions';
import * as diagnosticSchemesActions from './diagnosticSchemesActions';
import * as entrancesActions from './entrancesActions';
import * as eventsActions from './eventsActions';
import * as specialistNotesActions from './specialistNotesActions';
import * as loginHistoryActions from './loginHistoryActions';
import * as profileHistoryActions from './profileHistoryActions';
import * as currentUsersActions from './currentUsersActions';
import * as galleryActions from './galleryActions';
import * as virtualDiscActions from './virtualDiscActions';
import * as staffMessageBoardActions from './staffMessageBoardActions';
import * as mealActions from './mealActions';
import * as mealCalendarActions from './mealCalendarActions';
import * as mealCategoriesActions from './mealCategoriesActions';
import * as archivedMealsActions from './archivedMealsActions';
import * as dietActions from './dietActions';

import moment from 'moment';
import { routePaths } from '../routePaths';
import userRoles from '../constants/userRoles';

function getInitActionsForRole(auth) {
  if (auth.isStaffMember([userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal])) {
    return [
      userAccountActions.loadUserState,
      unitActions.loadUnitDataAsync,
      daycareSettingsActions.loadDaycareConfiguration,
      staffMessageBoardActions.loadUnreadedPosts,
      settlementsSettingsActions.loadSettlementsSettingsAsync,
      privateMessagesActions.loadUnreadMessagesCountAsync,
      privateMessagesActions.loadMyThreads
    ];
  } else if (auth.isStaffMember()) {
    return [
      userAccountActions.loadUserState,
      unitActions.loadUnitDataAsync,
      daycareSettingsActions.loadDaycareConfiguration,
      staffMessageBoardActions.loadUnreadedPosts,
      privateMessagesActions.loadUnreadMessagesCountAsync,
      settlementsSettingsActions.loadSettlementsSettingsAsync,
      privateMessagesActions.loadUnreadMessagesCountAsync,
      privateMessagesActions.loadMyThreads
    ];
  } else if (auth.isStaffMember([userRoles.staffMemberDigitalDiary, userRoles.staffMemberTeacherSupport])) {
    return [
      userAccountActions.loadUserState,
      unitActions.loadUnitDataAsync,
      staffMessageBoardActions.loadUnreadedPosts
    ];
  } else if (auth.isLegalGuardian()) {
    return [
      userAccountActions.loadUserState,
      unitActions.loadUnitDataAsync,
      childrenAction.loadLegalGuardiansChildrenAsync,
      privateMessagesActions.loadUnreadMessagesCountAsync,
      privateMessagesActions.loadMyThreads,
      virtualDiscActions.fetchFiles,
      virtualDiscActions.getRootFolderContent
    ];
  } else if (auth.isPinDevice()) {
    return [unitActions.loadUnitDataAsync, guardianPinServiceActions.loadGuardianPinServiceConfiguration];
  }
  return [];
}

export function cleanUp() {
  return { type: types.CLEANUP_DATA };
}

export function preloadData() {
  return (dispatch, getState) => {
    const { auth } = getState();
    if (!auth.userRole) {
      dispatch(unitActions.loadUnitDataAsync());
    } else {
      dispatch({ type: types.PRELOAD_DATA });
      Promise.all(getInitActionsForRole(auth).map((a) => dispatch(a()))).then(() =>
        dispatch({ type: types.PRELOAD_DATA_SUCCESS })
      );
    }
  };
}

function getLoadActionsForUrl(location, getState, params, matchedRoute = '') {
  if (matchedRoute === routePaths.groups) {
    return [childrenAction.loadChildrenAsync, membersActions.loadStaffMembersAsync, groupAction.loadGroupsAsync];
  }
  if (matchedRoute === routePaths.groupsArchive) {
    return [groupAction.loadGroupsArchiveAsync];
  }
  if (matchedRoute === routePaths.groupRegister) {
    return [membersActions.loadStaffMembersAsync];
  }
  if (matchedRoute === routePaths.groupDetails) {
    return [
      () => groupAction.loadGroupDetailsAsync(params.id),
      membersActions.loadStaffMembersAsync,
      childrenAction.loadChildrenAsync
    ];
  }
  if (matchedRoute === routePaths.groupArchiveDetails) {
    return [
      () => groupAction.loadGroupArchiveDetailsAsync(params.id),
      childrenAction.loadChildrenAsync,
      membersActions.loadStaffMembersAsync
    ];
  }
  if (matchedRoute === routePaths.activities) {
    return [childrenAction.loadChildrenAsync, activitiesActions.loadActivitiesAsync];
  }
  if (matchedRoute === routePaths.activitiesArchive) {
    return [activitiesActions.loadActivitiesArchiveAsync];
  }
  if (matchedRoute === routePaths.activityRegister) {
    return [];
  }
  if (matchedRoute === routePaths.configurationNumberingSchemes) {
    return [numberingSchemesActions.loadNumberingSchemesAsync];
  }
  if (matchedRoute === routePaths.activityDetails) {
    return [() => activitiesActions.loadActivityDetailsAsync(params.id), childrenAction.loadChildrenAsync];
  }
  if (matchedRoute === routePaths.activityArchiveDetails) {
    return [() => activitiesActions.loadActivityArchiveDetailsAsync(params.id), childrenAction.loadChildrenAsync];
  }
  if (matchedRoute === routePaths.children) {
    return [childrenAction.loadChildrenAsync, membersActions.loadStaffMembersAsync];
  }
  if (matchedRoute === routePaths.childDetails) {
    const { auth } = getState();
    return [
      mealActions.loadMealsAsync,
      childrenAction.loadChildrenAsync,
      mealCategoriesActions.loadMealsCategoriesAsync,
      dietActions.loadDietsAsync,
      reliefsActions.loadReliefsAsync,
      psychologicalGroupsActions.loadPsychologicalGroupsAsync,
      () => childrenAction.loadChildAsync(params.id),
      () => childrenAction.loadChildAttributesAsync(params.id),
      () => attendanceActions.loadChildAttendancesAsync(params.id, moment()),
      () => daysOffActions.loadDaysOffAsync(moment().format('YYYY')),
      () => mealCalendarActions.loadChildMealsByMonthAsync(params.id, moment().year(), moment().format('MM')),
      diagnosticSchemesActions.loadDiagnosticSchemesSetAsync,
      activitiesActions.loadActivitiesAsync,
      guardianPinServiceActions.loadGuardianPinServiceConfiguration,
      ...(auth.isStaffMember([userRoles.staffMemberAccountant, userRoles.staffMemberPrincipal]) === true
        ? [chargingSchemesActions.loadChargingSchemesAsync]
        : [])
    ];
  }
  if (matchedRoute === routePaths.studentDetails) {
    return [() => childrenAction.loadStudentAsync(params.id)];
  }
  if (matchedRoute === routePaths.meals) {
    return [mealActions.loadMealsAsync, mealCategoriesActions.loadMealsCategoriesAsync];
  }
  if (matchedRoute === routePaths.mealRegister) {
    return [];
  }
  if (matchedRoute === routePaths.mealDetails) {
    return [() => mealActions.loadMealDetailsAsync(params.id), mealActions.loadMealsAsync];
  }
  if (matchedRoute === routePaths.mealsArchive) {
    return [archivedMealsActions.loadArchivedMealsAsync];
  }
  if (matchedRoute === routePaths.diets) {
    return [
      dietActions.loadDietsAsync,
      childrenAction.loadChildrenAsync,
      mealActions.loadMealsAsync,
      mealCategoriesActions.loadMealsCategoriesAsync
    ];
  }
  if (matchedRoute === routePaths.createDiet) {
    return [dietActions.loadDietsAsync, mealActions.loadMealsAsync, mealCategoriesActions.loadMealsCategoriesAsync];
  }
  if (matchedRoute === routePaths.dietDetails) {
    return [
      () => dietActions.loadDietDetailsAsync(params.id),
      mealActions.loadMealsAsync,
      mealCategoriesActions.loadMealsCategoriesAsync,
      childrenAction.loadChildrenAsync
    ];
  }
  if (matchedRoute === routePaths.childFinancialDetails) {
    return [() => childrenAction.loadChildAsync(params.id)];
  }
  if (matchedRoute === routePaths.staffFinancialDetails) {
    return [() => membersActions.loadStaffMemberDetailsAsync(params.id)];
  }
  if (matchedRoute === routePaths.childSummary) {
    return [() => childrenAction.loadChildAsync(params.id)];
  }
  if (matchedRoute === routePaths.childrenArchive || matchedRoute === routePaths.studentArchive) {
    return [childrenAction.loadChildrenArchiveAsync];
  }
  if (matchedRoute === routePaths.childArchiveDetails) {
    return [() => childrenAction.loadChildArchiveAsync(params.id)];
  }
  if (matchedRoute === routePaths.staffMembers) {
    return [membersActions.loadStaffMembersAsync, chargingSchemesActions.loadChargingSchemesAsync];
  }
  if (matchedRoute === routePaths.staffMembersArchive) {
    return [membersActions.loadStaffMembersArchiveAsync];
  }
  if (matchedRoute === routePaths.expiringReliefs) {
    return [reliefsActions.loadExpiringReliefsAsync];
  }
  if (matchedRoute === routePaths.takenOverByDebtCollection) {
    return [childrenAction.loadChildrenTakenOverByDebtCollection];
  }
  if (matchedRoute === routePaths.staffMemberDetails) {
    const { auth } = getState();
    return [
      reliefsActions.loadReliefsAsync,
      chargingSchemesActions.loadChargingSchemesAsync,
      () => membersActions.loadStaffMemberDetailsAsync(params.id),
      ...(auth.isStaffMember([userRoles.staffMemberPrincipal]) === true
        ? [
            () =>
              profileHistoryActions.loadProfileHistoryAsync(params.id, {
                fetchCount: 10,
                page: 0
              })
          ]
        : [])
    ];
  }
  if (matchedRoute === routePaths.messageBoard) {
    const { auth } = getState();
    return [
      groupAction.loadGroupsAsync,
      activitiesActions.loadActivitiesAsync,
      ...(auth.isStaffMember([
        userRoles.staffMemberAccountant,
        userRoles.staffMemberPrincipal,
        userRoles.staffMemberTeacher,
        userRoles.staffMemberSecretary
      ]) === true
        ? [childrenAction.loadAllLegalGuardiansAsync, membersActions.loadStaffMembersAsync]
        : []),
      () => messageBoardActions.loadPostsByRoleAsync(10, 0)
    ];
  }
  if (matchedRoute === routePaths.electronicDiaryMessageBoard) {
    return [
      groupAction.loadGroupsAsync,
      () =>
        messageBoardDigitalDiaryActions.loadEventsByRoleAtDayAsync({
          eventType: params.eventType,
          eventDate: moment([params.year, params.month - 1, params.day]).format('YYYY-MM-DD'),
          groupId: params.groupId
        }),
      activitiesActions.loadActivitiesAsync
    ];
  }
  if (matchedRoute === routePaths.messageBoardEvents) {
    return [
      groupAction.loadGroupsAsync,
      activitiesActions.loadActivitiesAsync,
      () => messageBoardActions.loadEventsByRoleAtDayAsync(moment([params.year, params.month - 1, params.day]))
    ];
  }
  if (matchedRoute === routePaths.eventsCalendar) {
    const filters = getState().messageBoardFilter;
    const { auth } = getState();
    return [
      groupAction.loadGroupsAsync,
      ...(auth.isStaffMember([
        userRoles.staffMemberAccountant,
        userRoles.staffMemberPrincipal,
        userRoles.staffMemberTeacher,
        userRoles.staffMemberSecretary
      ]) === true
        ? [membersActions.loadStaffMembersAsync]
        : []),
      activitiesActions.loadActivitiesAsync,
      () => daysOffActions.loadDaysOffAsync(params.year),
      // eslint-disable-next-line no-confusing-arrow
      () =>
        filters.filtered
          ? messageBoardActions.loadFilteredEventsByRoleAtMonthAsync(
              moment().set({ year: params.year, month: params.month - 1 }),
              filters
            )
          : messageBoardActions.loadEventsByRoleAtMonthAsync(
              moment().set({ year: params.year, month: params.month - 1 })
            )
    ];
  }
  if (matchedRoute === routePaths.attendance) {
    return [
      // childrenAction.loadChildrenAsync,
      groupAction.loadGroupsAsync,
      membersActions.loadStaffMembersAsync
    ];
  }
  if (matchedRoute === routePaths.absentTomorrow) {
    return [
      // childrenAction.loadChildrenAsync,
      groupAction.loadGroupsAsync,
      membersActions.loadStaffMembersAsync
    ];
  }
  if (matchedRoute === routePaths.attendanceMonthlyHistory) {
    return [
      groupAction.loadGroupsAsync,
      membersActions.loadStaffMembersAsync,
      () =>
        historyActions.loadAttendanceDaysInMonthAsync(
          params.groupId,
          moment().set({ year: params.year, month: params.month - 1 })
        ),
      () => daysOffActions.loadDaysOffAsync(params.year)
    ];
  }
  if (matchedRoute === routePaths.attendanceDailyHistory) {
    return [
      childrenAction.loadChildrenAsync,
      groupAction.loadGroupsAsync,
      membersActions.loadStaffMembersAsync,
      () =>
        historyActions.loadAttendanceDaysInMonthAsync(
          params.groupId,
          moment().set({ year: params.year, month: params.month - 1 })
        )
    ];
  }
  if (matchedRoute === routePaths.activitiesAttendanceSheetPage) {
    return [childrenAction.loadChildrenAsync, activitiesActions.loadActivitiesAsync];
  }
  if (matchedRoute === routePaths.configurationDaysOff) {
    return [() => daysOffActions.loadDaysOffAsync(params.year)];
  }
  if (matchedRoute === routePaths.configurationUnit) {
    return [unitActions.loadUnitDataAsync];
  }
  if (matchedRoute === routePaths.configurationSettlementsSettings) {
    return [settlementsSettingsActions.loadSettlementsSettingsAsync, numberingSchemesActions.loadNumberingSchemesAsync];
  }
  if (matchedRoute === routePaths.configurationChargingSchemes) {
    return [
      chargingSchemesActions.loadChargingSchemesAsync,
      activitiesActions.loadActivitiesAsync,
      childrenAction.loadChildrenAsync,
      mealActions.loadMealsAsync,
      mealCategoriesActions.loadMealsCategoriesAsync,
      reliefsActions.loadReliefsAsync
    ];
  }
  if (matchedRoute === routePaths.configurationReliefs) {
    return [reliefsActions.loadReliefsAsync];
  }
  if (matchedRoute === routePaths.configurationReports) {
    return [reportsConfigurationActions.loadReportsConfigurationAsync];
  }
  if (matchedRoute === routePaths.settlements) {
    const { previous } = getState().routing;
    return [
      groupAction.loadGroupsAsync,
      membersActions.loadStaffMembersAsync,
      groupAction.loadGroupsArchiveAsync,
      () => settlementsActions.loadSettlementsAsync(params.year, params.month)
    ];
  }
  if (matchedRoute === routePaths.settlementsGroup) {
    const { previous } = getState().routing;
    const { isPopAction } = getState().routing.current;
    if (previous.matchedRoute === routePaths.settlements) return [];
    if (
      previous.matchedRoute === routePaths.settlementsGroup &&
      params.year === previous.params.year &&
      params.month === previous.params.month
    ) {
      return [];
    }
    if (
      params.year === previous.params.year &&
      params.month === previous.params.month &&
      previous.matchedRoute === routePaths.settlementsGroupDetails &&
      isPopAction
    )
      return [];
    return [
      groupAction.loadGroupsAsync,
      membersActions.loadStaffMembersAsync,
      groupAction.loadGroupsArchiveAsync,
      () => settlementsActions.loadSettlementsAsync(params.year, params.month)
    ];
  }
  if (matchedRoute === routePaths.settlementsGroupDetails) {
    const { previous } = getState().routing;
    if (
      previous.matchedRoute === routePaths.settlements &&
      params.year === previous.params.year &&
      params.month === previous.params.month
    )
      return [];
    if (
      previous.matchedRoute === routePaths.settlementsGroup &&
      params.year === previous.params.year &&
      params.month === previous.params.month
    )
      return [];
    if (
      previous.matchedRoute === routePaths.settlementsGroupDetails &&
      params.year === previous.params.year &&
      params.month === previous.params.month
    )
      return [];
    return [
      groupAction.loadGroupsAsync,
      groupAction.loadGroupsArchiveAsync,
      membersActions.loadStaffMembersAsync,
      () => settlementsActions.loadSettlementsAsync(params.year, params.month)
    ];
  }
  if (matchedRoute === routePaths.staffMembersSettlements) {
    return [
      () => settlementsActions.loadStaffSettlementsAsync(params.year, params.month),
      membersActions.loadStaffMembersAsync
    ];
  }
  if (matchedRoute === routePaths.legalGuardians) {
    return [
      childrenAction.loadAllLegalGuardiansAsync,
      guardianPinServiceActions.loadGuardianPinServiceConfiguration,
      groupAction.loadGroupsAsync
    ];
  }
  if (matchedRoute === routePaths.guardianDues) {
    return [() => guardianDuesActions.loadDues(params.year)];
  }
  if (matchedRoute === routePaths.guardianChildAttendance) {
    return [
      () => attendanceActions.loadChildAttendancesAsync(params.childId, moment([params.year, params.month - 1])),
      () => daysOffActions.loadDaysOffAsync(params.year),
      childrenAction.loadLegalGuardiansChildrenAsync
    ];
  }
  if (matchedRoute === routePaths.guardianChildDetails) {
    return [
      mealActions.loadMealsAsync,
      mealCategoriesActions.loadMealsCategoriesAsync,
      () => childrenAction.loadLegalGuardiansChildAsync(params.id),
      () => attendanceActions.loadChildAttendancesAsync(params.id, moment()),
      () => daycareActions.loadChildDaycare(params.id, moment()),
      () => daysOffActions.loadDaysOffAsync(moment().format('YYYY')),
      () => mealCalendarActions.loadChildMealsByMonthAsync(params.id, moment().year(), moment().add(1, 'month').month())
    ];
  }
  if (matchedRoute === routePaths.returnFromOnlinePayments) {
    return [() => guardianDuesActions.loadDues(moment().year())];
  }
  if (matchedRoute === routePaths.reports) {
    return [
      groupAction.loadGroupsAsync,
      reliefsActions.loadReliefsAsync,
      activitiesActions.loadActivitiesAsync,
      reportsActions.loadReportsAsync,
      attributesActions.loadAttributesSetsAsync,
      membersActions.loadStaffMembersAsync,
      childrenAction.loadChildrenAsync
    ];
  }
  if (matchedRoute === routePaths.configurationAttributesSets) {
    return [attributesActions.loadAttributesSetsAsync];
  }
  if (matchedRoute === routePaths.pinKeypad) {
    return [guardianPinServiceActions.loadGuardianPinServiceConfiguration];
  }
  if (matchedRoute === routePaths.configurationGuardiansPinService) {
    return [
      guardianPinServiceActions.loadGuardianPinServiceConfiguration,
      guardianPinServiceActions.loadGuardiansWithoutPins
    ];
  }
  if (matchedRoute === routePaths.configurationDaycare) {
    return [daycareSettingsActions.loadDaycareConfiguration];
  }
  if (matchedRoute === routePaths.home) {
    const actions = [
      () => messageBoardActions.loadLatestEventsByRoleAsync(moment(), 3, 0),
      () => messageBoardActions.loadPostsByRoleAsync(3, 0),
      groupAction.loadGroupsAsync
    ];
    const isStaffMember = getState().auth.isStaffMember();
    if (isStaffMember) actions.push(membersActions.loadStaffMembersAsync);
    return isStaffMember ? [...actions] : actions;
  }
  if (matchedRoute === routePaths.staffMemberPrivateMessages) {
    return [
      () => privateMessagesActions.loadMyThreads(999, 0),
      membersActions.loadStaffMembersAsync,
      childrenAction.loadAllLegalGuardiansAsync,
      childrenAction.loadChildrenAsync,
      groupAction.loadGroupsAsync,
      virtualDiscActions.fetchFiles,
      virtualDiscActions.getRootFolderContent
    ];
  }

  if (matchedRoute === routePaths.guardianPrivateMessages) {
    return [() => privateMessagesActions.loadMyThreads(999, 0)];
  }
  if (matchedRoute === routePaths.privateMessages) {
    return [
      () => privateMessagesActions.loadThreadsAsync(99999, 0),
      childrenAction.loadChildrenAsync,
      virtualDiscActions.fetchFiles,
      virtualDiscActions.getRootFolderContent
    ];
  }
  if (matchedRoute === routePaths.daycare) {
    return [
      // childrenAction.loadChildrenAsync,
      groupAction.loadGroupsAsync,
      membersActions.loadStaffMembersAsync
    ];
  }
  if (
    matchedRoute === routePaths.electronicDiaryAdditionalActivities ||
    matchedRoute === routePaths.electronicDiaryTimeTable ||
    matchedRoute === routePaths.electronicDiaryTrip ||
    matchedRoute === routePaths.electronicDiaryWorkWithParents ||
    matchedRoute === routePaths.electronicDiarySaveWork
  ) {
    const { auth } = getState();
    return [
      groupAction.loadGroupsAsync,
      membersActions.loadStaffMembersAsync,
      ...(auth.isStaffMember([
        userRoles.staffMemberAccountant,
        userRoles.staffMemberPrincipal,
        userRoles.staffMemberTeacher,
        userRoles.staffMemberSecretary
      ]) === true
        ? [groupAction.loadGroupsArchiveAsync]
        : []),
      activitiesActions.loadActivitiesAsync,
      () =>
        digitalDiaryActions.initLoadEventsAsync({
          eventType: params.eventType,
          year: params.year,
          month: params.month
        }),
      () => daysOffActions.loadDaysOffAsync(params.year),
      () =>
        messageBoardActions.loadEventsByRoleAtMonthAsync(moment().set({ year: params.year, month: params.month - 1 }))
    ];
  }

  if (matchedRoute === routePaths.electronicDiaryFrameworkDaySchedule) {
    return [groupAction.loadGroupsAsync, membersActions.loadStaffMembersAsync];
  }

  if (matchedRoute === routePaths.electronicDiaryWorkPlan) {
    const { auth } = getState();
    return [
      attributesActions.loadAttributesSetsAsync,
      groupAction.loadGroupsAsync,
      membersActions.loadStaffMembersAsync,
      ...(auth.isStaffMember([
        userRoles.staffMemberAccountant,
        userRoles.staffMemberPrincipal,
        userRoles.staffMemberTeacher,
        userRoles.staffMemberSecretary
      ]) === true
        ? [groupAction.loadGroupsArchiveAsync]
        : []),
      () => digitalDiaryWorkPlanActions.initLoadWorkPlanSetStart(`${params.year}-${params.month}`)
    ];
  }
  if (matchedRoute === routePaths.configurationDiagnosisTemplate) {
    return [diagnosticSchemesActions.loadDiagnosticSchemesSetAsync];
  }
  if (matchedRoute === routePaths.entrances) {
    return [entrancesActions.loadEntrances];
  }
  if (matchedRoute === routePaths.events) {
    return [() => eventsActions.loadEventsAsync({ fetchCount: 10, page: 0 })];
  }
  if (matchedRoute === routePaths.loginHistory) {
    return [() => loginHistoryActions.loadLoginHistoryAsync({ fetchCount: 10, page: 0 })];
  }
  if (matchedRoute === routePaths.applications) {
    return [chargingSchemesActions.loadChargingSchemesAsync];
  }
  if (matchedRoute === routePaths.currentUsers) {
    return [currentUsersActions.loadCurrentUsersAsync];
  }
  if (matchedRoute === routePaths.gallery) {
    return [groupAction.loadGroupsAsync, activitiesActions.loadActivitiesAsync, galleryActions.fetchAlbums];
  }
  if (matchedRoute === routePaths.overdueList) {
    return [settlementsActions.loadOverdueAsync];
  }
  if (matchedRoute === routePaths.overpaymentsList) {
    return [settlementsActions.loadOverpaymentsAsync];
  }
  if (matchedRoute === routePaths.childrenOnVacationPeriod) {
    return [childrenAction.loadChildrenOnVacationPeriodAsync];
  }
  if (matchedRoute === routePaths.scheduledTransfer) {
    return [activitiesActions.loadActivitiesAsync, childrenAction.loadChildrenAsync, groupAction.loadGroupsAsync];
  }

  if (matchedRoute === routePaths.psychologicalHelp) {
    return [
      groupAction.loadGroupsAsync,
      psychologicalActions.loadPsychologicalHelpChildrenList,
      psychologicalActions.loadPsychologicalSchemesAsync,
      childrenAction.loadChildrenAsync
    ];
  }
  if (matchedRoute === routePaths.psychologicalHelpChild) {
    return [
      () => childrenAction.loadChildAsync(params.id),
      () => psychologicalActions.loadPsychologicalHelpDetails(params.id),
      psychologicalActions.loadPsychologicalSchemesAsync
    ];
  }
  if (matchedRoute === routePaths.psychologicalSchemes) {
    return [psychologicalActions.loadPsychologicalSchemesAsync];
  }
  if (matchedRoute === routePaths.individualClasses) {
    return [
      psychologicalActions.loadPsychologicalHelpChildrenList,
      psychologicalIndividualClassesActions.loadIndividualClassesAttendanceData,
      psychologicalActions.loadPsychologicalSchemesAsync,
      childrenAction.loadChildrenAsync
    ];
  }
  if (matchedRoute === routePaths.individualClassesDetails) {
    return [
      psychologicalActions.loadPsychologicalHelpChildrenList,
      psychologicalIndividualClassesActions.loadIndividualClassesAttendanceData,
      () => psychologicalIndividualClassesActions.loadIndividualClassesDataDetails(params.id),
      () => childrenAction.loadChildAsync(params.childId),
      psychologicalActions.loadPsychologicalSchemesAsync
    ];
  }
  if (matchedRoute === routePaths.individualClassesEventsDetails) {
    return [
      psychologicalActions.loadPsychologicalHelpChildrenList,
      psychologicalIndividualClassesActions.loadIndividualClassesAttendanceData,
      psychologicalActions.loadPsychologicalSchemesAsync,
      () =>
        psychologicalIndividualClassesActions.loadIndividualClassesWorkPlan(
          moment()
            .year(params.year)
            .month(params.month - 1)
            .format('YYYY-MM'),
          params.id
        )
    ];
  }

  if (matchedRoute === routePaths.groupClasses) {
    return [
      psychologicalActions.loadPsychologicalHelpChildrenList,
      membersActions.loadStaffMembersAsync,
      psychologicalActions.loadPsychologicalSchemesAsync,
      psychologicalGroupsActions.loadPsychologicalGroupsAsync
    ];
  }

  if (matchedRoute === routePaths.individualClassesJournal) {
    return [
      childrenAction.loadChildrenAsync,
      psychologicalActions.loadPsychologicalHelpChildrenList,
      membersActions.loadStaffMembersAsync,
      psychologicalActions.loadPsychologicalSchemesAsync,
      psychologicalGroupsActions.loadPsychologicalGroupsAsync
    ];
  }
  if (matchedRoute === routePaths.individualClassesJournalDetails) {
    return [
      psychologicalActions.loadPsychologicalHelpChildrenList,
      () =>
        psychologicalIndividualJournalClassesActions.loadIndividualJournalClassesDataDetails(
          params.childId,
          params.psychologicalGroupId
        ),
      () => childrenAction.loadChildAsync(params.childId),
      psychologicalActions.loadPsychologicalSchemesAsync
    ];
  }
  if (matchedRoute === routePaths.individualClassesJournalEventDetails) {
    return [psychologicalActions.loadPsychologicalHelpChildrenList, psychologicalActions.loadPsychologicalSchemesAsync];
  }

  if (matchedRoute === routePaths.groupClassesEventsDetails) {
    return [
      psychologicalActions.loadPsychologicalHelpChildrenList,
      psychologicalActions.loadPsychologicalSchemesAsync,
      () =>
        psychologicalGroupClassesActions.loadGroupClassesWorkPlan(
          moment()
            .year(params.year)
            .month(params.month - 1)
            .format('YYYY-MM'),
          params.id
        )
    ];
  }

  if (matchedRoute === routePaths.groupDiaryEventsDetails) {
    return [
      psychologicalActions.loadPsychologicalHelpChildrenList,
      psychologicalActions.loadPsychologicalSchemesAsync,
      () =>
        psychologicalGroupClassesActions.loadGroupDiaryCalendar(
          moment()
            .year(params.year)
            .month(params.month - 1)
            .format('YYYY-MM'),
          params.id
        )
    ];
  }

  if (matchedRoute === routePaths.psychologicalGroups) {
    return [psychologicalGroupsActions.loadPsychologicalGroupsAsync];
  }
  if (matchedRoute === routePaths.psychologicalGroupDetails) {
    return [
      () => psychologicalGroupsActions.loadPsychologicalGroupDetailsAsync(params.id),
      membersActions.loadStaffMembersAsync,
      childrenAction.loadChildrenAsync
    ];
  }

  if (matchedRoute === routePaths.specialistNotes) {
    return [
      specialistNotesActions.loadAllSpecialists,
      specialistNotesActions.loadAllSpecialistNotes,
      membersActions.loadStaffMembersAsync
    ];
  }

  if (matchedRoute === routePaths.specialistNotesDetails) {
    return [
      membersActions.loadStaffMembersAsync,
      specialistNotesActions.loadAllSpecialists,
      () => specialistNotesActions.loadSpecialistsDetails(params.id),
      () => daysOffActions.loadDaysOffAsync(moment().format('YYYY'))
      // () =>
      //   psychologicalGroupClassesActions.loadGroupDiaryCalendar(
      //     moment()
      //       .year(params.year)
      //       .month(params.month - 1)
      //       .format('YYYY-MM'),
      //     params.id
      //   )
    ];
  }
  if (matchedRoute === routePaths.specialistClassScheduleDetails) {
    return [() => specialistNotesActions.loadSpecialistsDetails(params.id)];
  }
  if (matchedRoute === routePaths.specialistWorkPlanProgressDetails) {
    return [() => specialistNotesActions.loadSpecialistsDetails(params.id)];
  }
  if (matchedRoute === routePaths.specialistGroupActivitiesDetails) {
    return [() => specialistNotesActions.loadSpecialistsDetails(params.id)];
  }

  if (matchedRoute === routePaths.virtualDisc) {
    const { auth } = getState();
    return [
      virtualDiscActions.getRootFolderContent,
      virtualDiscActions.fetchFiles,

      ...(auth.isStaffMember([
        userRoles.staffMemberPrincipal,
        userRoles.staffMemberAccountant,
        userRoles.staffMemberTeacher,
        userRoles.staffMemberDigitalDiary,
        userRoles.staffMemberSecretary
      ]) === true
        ? [groupAction.loadGroupsAsync, childrenAction.loadAllLegalGuardiansAsync, membersActions.loadStaffMembersAsync]
        : [])
    ];
  }

  if (matchedRoute === routePaths.authorizedToPickUpChild) {
    return [
      childrenAction.loadAllLegalGuardiansAsync,
      childrenAction.loadChildrenAsync,
      groupAction.loadGroupsAsync,
      membersActions.loadStaffMembersAsync
    ];
  }
  return [];
}

export function loadOnNavigateTo(dispatch, getState, location, params, matchedRoute) {
  if (location.pathname === getState().routing.current.url) {
    return Promise.resolve();
  }
  dispatch({
    type: types.NAVIGATE_REQUEST,
    nextUrl: location.pathname,
    nextActionName: location.action,
    matchedRoute,
    nextParams: params
  });
  const actions = getLoadActionsForUrl(location, getState, params, matchedRoute);
  if (actions.length > 0) {
    dispatch({ type: types.PRELOAD_ON_NAVIGATION });
    return Promise.all(actions.map((a) => dispatch(a()))).then(() =>
      dispatch({ type: types.PRELOAD_ON_NAVIGATION_SUCCESS })
    );
  }
  dispatch({ type: types.PRELOAD_ON_NAVIGATION_SUCCESS });
}
