import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import EmptyState from '../../common/EmptyState';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogForm from '../../forms/DialogForm';
import DatePicker from '../../common/DatePicker';
import SelectField from '../../common/SelectField';
import PersonAvatar from '../../forms/PersonAvatar';
import { useDispatch, useSelector } from 'react-redux';
import { enrollChildrenToDietAsync, changeChildEnrollment } from '../../../actions/dietActions';
import { loadChildMealsByMonthAsync } from '../../../actions/mealCalendarActions';
import { loadChildAsync } from '../../../actions/childrenActions';

const useStyles = makeStyles(() => ({
  listItemWithGap: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export const ChildMealsCard = ({ isInitiallyExpanded, pupil }) => {
  const dispatch = useDispatch();
  const cl = useStyles();
  const [isOpen, setIsOpen] = useState(isInitiallyExpanded);
  const [isDietDialogOpen, setIsDietDialogOpen] = useState(false);
  const [newDietStartDate, setNewDietStartDate] = useState(moment().add(1, 'days'));
  const [minNewDietStartDate, setMinNewDietStartDate] = useState(moment().add(1, 'days'));
  const [newDiet, setNewDiet] = useState(null);
  const [isDietLoading, setIsDietLoading] = useState(false);

  const diets = useSelector((state) => state.diets);

  const handleDietDialogOpen = () => {
    setIsDietDialogOpen((prev) => !prev);
  };

  const handleDietDialogClose = () => {
    setIsDietDialogOpen((prev) => !prev);
    setNewDietStartDate(minNewDietStartDate);
    setNewDiet(null);
  };

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleNewDietStartDateChange = (date) => {
    setNewDietStartDate(date);
  };

  const handleDietChange = (event) => {
    setNewDiet(event.target.value);
  };

  const childDiets = pupil.enrolledToMenu;

  const sortedDiets = useMemo(() => {
    return childDiets.sort((a, b) => moment(a.startDate) - moment(b.startDate));
  }, [childDiets]);

  const pastDiets = useMemo(() => {
    return sortedDiets.filter((diet) => diet.endDate && moment(diet.endDate).isBefore(moment()));
  }, [sortedDiets]);

  const currentDietDate = useMemo(() => {
    return sortedDiets.find(
      (diet) =>
        moment(diet.startDate) <= moment(moment().format('YYYY-MM-DD')) &&
        (moment(diet.endDate) >= moment(moment().format('YYYY-MM-DD')) || diet.endDate === null)
    );
  }, [sortedDiets]);

  const futureDiets = useMemo(() => {
    return sortedDiets.filter((diet) => moment(diet.startDate) > moment());
  }, [sortedDiets]);

  const currentDietMealNames = useMemo(() => {
    return currentDietDate?.meals?.length > 0
      ? currentDietDate.meals.map((meal) => meal.name).join('  |  ')
      : 'Brak posiłków';
  }, [currentDietDate]);

  const currentChildEnrollment = useMemo(() => {
    return sortedDiets.find((diet) => !diet.endDate);
  }, [sortedDiets]);

  const handleChangeDiet = useCallback(async () => {
    if (!newDiet || moment(newDietStartDate).isBefore(minNewDietStartDate)) return;

    const action = currentChildEnrollment ? changeChildEnrollment : enrollChildrenToDietAsync;
    const formattedDate = moment(newDietStartDate).format('YYYY-MM-DD');
    const payload = {
      childIds: [pupil.id],
      menuId: newDiet,
      startDate: formattedDate
    };

    try {
      setIsDietLoading(true);
      await dispatch(action(payload));
      await dispatch(loadChildMealsByMonthAsync(pupil.id, moment().format('YYYY'), moment().format('MM') + 1));
      await dispatch(loadChildAsync(pupil.id));
    } finally {
      setIsDietLoading(false);
      setIsDietDialogOpen(false);
    }
  }, [currentChildEnrollment, dispatch, minNewDietStartDate, newDiet, newDietStartDate, pupil.id]);

  useEffect(() => {
    const currentMinDate = moment().add(1, 'days');
    const enrollmentMinDate =
      currentChildEnrollment?.startDate && moment(currentChildEnrollment.startDate).add(1, 'days');
    const correctMinDate =
      enrollmentMinDate && enrollmentMinDate.isAfter(currentMinDate) ? enrollmentMinDate : currentMinDate;

    setNewDietStartDate(correctMinDate);
    setMinNewDietStartDate(correctMinDate);
  }, [currentChildEnrollment]);

  return (
    <>
      <Paper elevation={1} sx={{ p: 1, mb: 2 }}>
        <ListItem className={cl.listItemWithGap} sx={{ p: 0, m: 0, display: 'flex', alignItems: 'center' }}>
          <ListItemButton onClick={() => handleOpen()}>
            <ListItemText
              primary={
                <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                  Posiłki
                </Typography>
              }
            />
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={isOpen}>
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, ml: 2, mt: 1 }}>
            Aktualna dieta
          </Typography>
          {currentDietDate ? (
            <List>
              <ListItem
                sx={{
                  '&:hover': {
                    background: (theme) => theme.palette.background.hover
                  }
                }}
                key={currentDietDate.id}>
                <ListItemText
                  disableTypography
                  primary={
                    <>
                      <Typography
                        sx={{
                          fontWeight: (theme) => theme.typography.fontWeightBold
                        }}>
                        {`${currentDietDate.name} (obowiązująca) `}
                      </Typography>
                      <Typography>
                        {currentDietDate.meals.length > 0
                          ? currentDietDate.meals.map((meal) => meal.name).join('  |  ')
                          : 'Brak posiłków'}
                      </Typography>
                    </>
                  }
                  secondary={`Dieta obowiązuje od ${moment(currentDietDate.startDate).format('YYYY-MM-DD')}`}
                />
              </ListItem>
            </List>
          ) : (
            <EmptyState message="Brak aktualnie obowiązującej diety" contrast />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              onClick={handleDietDialogOpen}
              aria-label={currentChildEnrollment ? 'Zmień dietę' : 'Przypisz dietę'}>
              {currentChildEnrollment ? 'Zmień dietę' : 'Przypisz dietę'}
            </Button>
          </Box>
          {futureDiets.length !== 0 && (
            <>
              <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, ml: 2 }}>
                Nadchodzące diety
              </Typography>
              <List>
                {futureDiets.map((diet) => {
                  const mealNames =
                    diet.meals.length > 0 ? diet.meals.map((meal) => meal.name).join('  |  ') : 'Brak posiłków';
                  return (
                    <ListItem
                      sx={{
                        '&:hover': {
                          background: (theme) => theme.palette.background.hover
                        }
                      }}
                      key={diet.id}>
                      <ListItemText
                        disableTypography
                        primary={
                          <>
                            <Typography
                              sx={{
                                fontWeight: (theme) => theme.typography.fontWeightBold
                              }}>
                              {diet.name}
                            </Typography>
                            <Typography>{mealNames}</Typography>
                          </>
                        }
                        secondary={
                          <>
                            {`Dieta obowiązuje od ${moment(diet.startDate).format('YYYY-MM-DD')}`}
                            <br />
                            {diet.endDate && `Dieta obowiązuje do ${moment(diet.endDate).format('YYYY-MM-DD')}`}
                          </>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
          {pastDiets.length !== 0 && (
            <Accordion>
              <AccordionSummary
                sx={{ '& .MuiAccordionSummary-content': { my: 0 } }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>Przeszłe diety</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, m: 0 }}>
                <List>
                  {pastDiets.map((diet) => {
                    const mealNames =
                      diet.meals.length > 0 ? diet.meals.map((meal) => meal.name).join('  |  ') : 'Brak posiłków';
                    return (
                      <ListItem
                        sx={{
                          '&:hover': {
                            background: (theme) => theme.palette.background.hover
                          }
                        }}
                        key={diet.id}>
                        <ListItemText
                          disableTypography
                          primary={
                            <>
                              <Typography
                                sx={{
                                  fontWeight: (theme) => theme.typography.fontWeightBold
                                }}>
                                {diet.name}
                              </Typography>
                              <Typography>{mealNames}</Typography>
                            </>
                          }
                          secondary={
                            <>
                              {`Dieta obowiązywała od ${moment(diet.startDate).format('YYYY-MM-DD')}`}
                              <br />
                              {diet.endDate && `Dieta obowiązywała do ${moment(diet.endDate).format('YYYY-MM-DD')}`}
                            </>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
          )}
        </Collapse>
      </Paper>
      <DialogForm
        maxWidth="xs"
        header={currentDietDate ? 'Zmień dietę' : 'Przypisz dietę'}
        isProcessing={isDietLoading}
        isDialogOpen={isDietDialogOpen}
        onCancel={handleDietDialogClose}
        onSave={handleChangeDiet}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <PersonAvatar
            initials
            alt={pupil.firstName}
            url={pupil.photoUrl}
            firstName={pupil.firstName}
            lastName={pupil.lastName}
          />
          <Typography
            sx={{
              textAlign: 'center',
              fontWeight: (theme) => theme.typography.fontWeightBold,
              my: 2,
              mx: 0
            }}>{`${pupil.firstName} ${pupil.lastName}`}</Typography>
        </Box>
        {currentDietDate && (
          <Box sx={{ pb: 2 }}>
            <Typography>Aktualna dieta:</Typography>
            <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
              {currentDietDate.name}
            </Typography>
            <Typography>{currentDietMealNames}</Typography>
            <Typography component="span" sx={{ display: 'flex' }}>
              Dieta obowiązuje od
              <Typography sx={{ ml: 0.5, fontWeight: (theme) => theme.typography.fontWeightBold }}>
                {moment(currentDietDate.startDate).format('YYYY-MM-DD')}
              </Typography>
            </Typography>
          </Box>
        )}

        <SelectField value={newDiet} contrast sx={{ pb: 2 }} floatingLabelText="Dieta" onChange={handleDietChange}>
          {diets.map((diet) => (
            <MenuItem value={diet.id} key={diet.id}>
              {diet.name}
            </MenuItem>
          ))}
        </SelectField>
        <DatePicker
          name="dietStartDate"
          hintText="Początek obowiązywania"
          floatingLabelText="Początek obowiązywania"
          value={newDietStartDate}
          minDate={minNewDietStartDate.toDate()}
          onChange={handleNewDietStartDateChange}
        />
      </DialogForm>
    </>
  );
};

ChildMealsCard.propTypes = {
  isInitiallyExpanded: PropTypes.bool,
  pupil: PropTypes.object.isRequired
};
