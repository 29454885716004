import PropTypes from 'prop-types';
import React from 'react';
import CalendarDayContent from '../../common/calendar/Grid/CalendarDayContent';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Typography } from '@mui/material';
import Strong from '../../common/Strong';

const EventDayContent = ({ day, containerWidth, events }) => {
  const getEventDescription = (length) => {
    if (length === 1) return ' wpis';
    if (length <= 4) return ' wpisy';
    return ' wpisów';
  };

  return (
    <CalendarDayContent
      day={day}
      containerWidth={containerWidth}
      primaryText={
        <Typography>
          <Strong>{events}</Strong>
          {getEventDescription(events)}
        </Typography>
      }
      primaryIcon={<EventAvailableIcon />}
    />
  );
};

EventDayContent.propTypes = {
  day: PropTypes.object.isRequired,
  containerWidth: PropTypes.number.isRequired,
  events: PropTypes.number.isRequired
};

export default EventDayContent;
