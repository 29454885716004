import React, { useEffect, useState } from 'react';
import { getComparator, stableSort } from '../../../../utils/tableSorting';
import {
  TableBody,
  TableRow,
  Table,
  TableContainer,
  TableHead,
  ListItem,
  ListItemText,
  List,
  Box,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import SortedTableCell from '../../../table/SortedTableCell';
import * as definedSortTypes from '../../../../constants/definedSortTypes';
import PartlyCorrectPaymentsTableRow from './PartlyCorrectPaymentsTableRow';
import { tryConvertToNumber } from '../../../../utils/numberConverters';

const _ = require('lodash');

const useCellHeaderStyles = makeStyles({
  root: {
    width: 100,
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: 13
  }
});

const PartlyCorrectPaymentsTable = ({
  data,
  handleRequestSort,
  order,
  orderBy,
  onChangePartialData,
  onValidateErrors
}) => {
  const [availableFunds, setAvailableFunds] = useState(data.amount);
  const [paymentErrors, setPaymentErrors] = useState(false);
  const [payments, setPayments] = useState({
    // transactionId: data.transaction.id,
    person: null,
    body: {
      balance: null,
      paymentDate: null,
      paymentMethod: null,
      payments: [],
      paymentAmount: 0
    }
  });
  const [errors, setErrors] = useState();

  const handleChangeAmount = (id, amount, paymentDate, person) => {
    if (person) {
      const temp = payments;
      let index = -1;
      temp.body.payments.forEach((element, arrIndex) => {
        if (element.dueId === id) {
          index = arrIndex;
        }
      });
      if (index !== -1) {
        temp.body.balance = null;
        temp.body.paymentDate = paymentDate;
        temp.body.paymentMethod = 'transfer';
        temp.body.payments[index].amount = tryConvertToNumber(amount);
        temp.body.paymentAmount = temp.body.payments.reduce((prev, payment) => prev + payment.amount, 0);
        temp.person = person;
      } else {
        temp.body.balance = null;
        temp.body.paymentDate = paymentDate;
        temp.body.paymentAmount = 0;
        temp.body.paymentMethod = 'transfer';
        temp.person = person;
        temp.body.payments.push({ dueId: id, method: 'transfer', amount, date: data.date });
      }
      setPayments({ ...temp });
    }
  };

  const handleCheckboxChange = (id) => {
    const temp = payments;
    let index = -1;
    temp.body.payments.forEach((element, arrIndex) => {
      if (element.dueId === id) {
        index = arrIndex;
      }
    });
    if (index === -1) {
      temp.body.payments.push({ dueId: id, method: 'transfer', amount: 0, date: data.date });
    } else {
      temp.body.payments.splice(index, 1);
    }
    setPayments({ ...temp });
  };

  const handlePaymentError = (id, state) => {
    onValidateErrors(id, state);
    if (state) {
      setPaymentErrors(true);
    } else {
      setPaymentErrors(false);
    }
  };

  useEffect(() => {
    onChangePartialData(payments);
    let paymentsSum = 0;
    payments.body.payments.forEach((payment) => {
      paymentsSum += tryConvertToNumber(payment.amount);
    });
    setAvailableFunds(data.amount - paymentsSum);
  }, [JSON.stringify(payments)]);

  useEffect(() => {
    if (availableFunds < 0) {
      setErrors(true);
    } else {
      setErrors(false);
    }
  }, [availableFunds]);

  useEffect(() => {
    onValidateErrors(data.legalGuardianData.id, errors);
  }, [errors]);

  const sortedCells = [
    {
      id: 'checkbox',
      label: ''
    },
    {
      id: 'number',
      label: 'Numer',
      type: definedSortTypes.STRING_SORT
    },
    {
      id: 'name',
      label: 'Dziecko',
      type: definedSortTypes.STRING_SORT
    },
    {
      id: 'dueType',
      label: 'Opłata',
      type: definedSortTypes.CHARGING_REPORT_TYPE
    },
    {
      id: 'calculatedTotal',
      label: 'Kwota',
      type: definedSortTypes.NUMBER_SORT
    },
    {
      id: 'calculatedTotalUnadjusted',
      label: 'Do zapłaty',
      type: definedSortTypes.NUMBER_SORT
    },
    {
      id: 'calculationStatus',
      label: 'Status',
      type: definedSortTypes.STRING_SORT
    },
    {
      id: 'calculationDate',
      label: 'Wygenerowano',
      type: definedSortTypes.DATE_SORT
    },
    {
      id: 'paymentAmount',
      label: 'Wpłacono',
      type: definedSortTypes.NUMBER_SORT
    },
    {
      id: 'toPay',
      label: 'Kwota do wpłaty',
      type: definedSortTypes.NUMBER_SORT
    }
  ];

  const cellHeaderStyles = useCellHeaderStyles();

  return (
    <Box>
      <Typography>
        {`Dane osoby wpłacającej: ${data.legalGuardianData.firstName} ${data.legalGuardianData.lastName}`}
      </Typography>

      <Typography>{`Dostępne środki: ${_.round(availableFunds, 2)}zł`}</Typography>

      {data.descriptions.map((description, index) => (
        <Typography>{description}</Typography>
      ))}
      {errors ||
        (paymentErrors && (
          <Typography sx={{ color: 'theme=>theme.palette.color.error' }}>
            primary="Podane kwoty nie mogą przekraczać dostępnych środków lub maksymalnej wartości wpłaty"
          </Typography>
        ))}

      <List>
        <ListItem>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {sortedCells.map((cell) => (
                    <SortedTableCell
                      cell={cell}
                      key={cell.id}
                      order={order}
                      orderBy={orderBy.property}
                      classes={{ root: cellHeaderStyles.root }}
                      align="left"
                      onRequestSort={handleRequestSort}
                    />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(data.dues, getComparator(order, orderBy)).map((row, rowIndex) => {
                  return (
                    <PartlyCorrectPaymentsTableRow
                      date={data.date}
                      legalGuardianData={data.legalGuardianData}
                      onChangeAmount={handleChangeAmount}
                      onCheckboxChange={handleCheckboxChange}
                      onValidateErrors={handlePaymentError}
                      key={`${row.id}-${rowIndex}`}
                      row={row}
                      rowIndex={rowIndex}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </ListItem>
      </List>
    </Box>
  );
};

PartlyCorrectPaymentsTable.propTypes = {
  // title: PropTypes.string,
  data: PropTypes.object,
  order: PropTypes.string,
  orderBy: PropTypes.object,
  handleRequestSort: PropTypes.func,
  onChangePartialData: PropTypes.func,
  onValidateErrors: PropTypes.func
  // onAddPayment: PropTypes.func,
  // onChangeAmount: PropTypes.func,
  // dueType: PropTypes.string,
  // availableFunds: PropTypes.number
};

export default PartlyCorrectPaymentsTable;
