import PropTypes from 'prop-types';
import React from 'react';
import FullCalendar from '../../common/calendar/FullCalendar';
import CalendarDay from '../../common/calendar/Grid/CalendarDay';
import CalendarDayContent from '../../common/calendar/Grid/CalendarDayContent';
import DayOffDayContent from './DayOffDayContent';
import EventDayContent from './EventDayContent';
import moment from 'moment';
import _ from 'lodash';

const EventsCalendar = ({ eventDays, daysOff = [], onDateChange, onDaySelected, month, year }) => {
  const normalizeDaysData = () => {
    const groupedEvents = _.groupBy(eventDays, 'eventDate');

    const eventDaysNormalized = Object.keys(groupedEvents).map((key) => ({
      date: moment(key).format('YYYY-MM-DD'),
      data: {
        eventsCount: groupedEvents[key].length,
        isEventDay: true
      }
    }));

    const dayOffNormalized = daysOff.map((x) => ({
      date: moment(x.date).format('YYYY-MM-DD'),
      data: {
        dayOffName: x.name,
        isDayOff: true
      }
    }));

    return _.union(dayOffNormalized, eventDaysNormalized, 'date');
  };

  const renderDayContent = (day, containerWidth, data) => {
    if ((day.isWeekend && !data.isEventDay) || !day.isCurrentMonth) {
      return <CalendarDayContent day={day} containerWidth={containerWidth} />;
    }
    if (data.isDayOff) {
      return <DayOffDayContent day={day} containerWidth={containerWidth} dayOffName={data.dayOffName} />;
    }
    if (data.isEventDay) {
      return <EventDayContent day={day} containerWidth={containerWidth} events={data.eventsCount} />;
    }
    return <CalendarDayContent day={day} containerWidth={containerWidth} />;
  };

  return (
    <FullCalendar
      markedDays={normalizeDaysData()}
      onYearOrMonthSelected={(nextYear, nextMonth) => onDateChange(moment(new Date(nextYear, nextMonth - 1, 1)))}
      defaultMonth={month}
      defaultYear={year}
      onDaySelected={(date) => onDaySelected(date)}
      onDayRender={(day, contentRender, onDaySelectedFunc, containerWidth, isDisabled) => (
        <CalendarDay
          isDisabled={(day.data.isDayOff || isDisabled) && !day.isWeekend}
          day={day}
          onDaySelected={onDaySelectedFunc}
          contentRender={contentRender}
          containerWidth={containerWidth}
        />
      )}
      onDayContentRender={(day, containerWidth) => renderDayContent(day, containerWidth, day.data)}
    />
  );
};

EventsCalendar.propTypes = {
  eventDays: PropTypes.array.isRequired,
  daysOff: PropTypes.array.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onDaySelected: PropTypes.func.isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired
};

export default EventsCalendar;
