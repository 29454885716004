/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { DaycareSettingsModel } from '../models/configuration/daycare/DaycareSettingsModels';

export default function daycareSettingsReducer(state = initialState.configuration.daycare, action) {
  switch (action.type) {
    case types.LOAD_DAYCARE_SETTINGS_SUCCESS: {
      return new DaycareSettingsModel().assign({ ...action.configuration });
    }
    case types.UPDATE_DAYCARE_SETTINGS_SUCCESS: {
      return new DaycareSettingsModel().assign({ ...action.configuration });
    }
    default:
      return state;
  }
}
