import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function dietDetailsUiReducer(state = initialState.dietDetailsUi, action) {
  switch (action.type) {
    case types.CREATE_DIET: {
      return Object.assign({}, state, { details: UiModel.start() });
    }
    case types.CREATE_DIET_CANCEL: {
      return Object.assign({}, state, { details: UiModel.cancel() });
    }
    case types.CREATE_DIET_SUBMIT: {
      return Object.assign({}, state, { details: UiModel.submit() });
    }
    case types.CREATE_DIET_SUCCESS: {
      return Object.assign({}, state, { details: UiModel.success() });
    }
    case types.CREATE_DIET_FAILURE: {
      return Object.assign({}, state, { details: UiModel.failure(action.errors) });
    }
    default:
      return state;
  }
}
