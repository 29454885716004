import StaffMessageBoardApi from '../api/StaffMessageBoardApi';
import * as notificationActions from './notificationActions';
import {
  LOAD_STAFF_MESSAGE_BOARD_POSTS_BEGIN,
  LOAD_STAFF_MESSAGE_BOARD_POSTS_SUCCESS,
  LOAD_STAFF_MESSAGE_BOARD_POSTS_FAILURE,
  LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_BEGIN,
  LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_FAILURE,
  LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_SUCCESS,
  CREATE_STAFF_MESSAGE_BOARD_POST_BEGIN,
  CREATE_STAFF_MESSAGE_BOARD_POST_SUCCESS,
  CREATE_STAFF_MESSAGE_BOARD_POST_FAILURE,
  UPDATE_STAFF_MESSAGE_BOARD_POST_BEGIN,
  UPDATE_STAFF_MESSAGE_BOARD_POST_SUCCESS,
  UPDATE_STAFF_MESSAGE_BOARD_POST_FAILURE,
  DELETE_STAFF_MESSAGE_BOARD_POST_BEGIN,
  DELETE_STAFF_MESSAGE_BOARD_POST_SUCCESS,
  DELETE_STAFF_MESSAGE_BOARD_POST_FAILURE,
  READALL_STAFF_MESSAGE_BOARD_START,
  READALL_STAFF_MESSAGE_BOARD_SUCCESS,
  READALL_STAFF_MESSAGE_BOARD_FAILURE,
  LOAD_UNREADED_POSTS_START,
  LOAD_UNREADED_POSTS_SUCCESS,
  LOAD_UNREADED_POSTS_FAILURE
} from './actionTypes';
import { showError } from './notificationActions';

export const loadStaffMessageBoardPosts = (pagination) => {
  return async (dispatch) => {
    dispatch({ type: LOAD_STAFF_MESSAGE_BOARD_POSTS_BEGIN });
    try {
      const response = await StaffMessageBoardApi.getStaffPosts(pagination);
      dispatch({ type: LOAD_STAFF_MESSAGE_BOARD_POSTS_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: LOAD_STAFF_MESSAGE_BOARD_POSTS_FAILURE });
      dispatch(showError(error));
    }
  };
};

export const loadFileteredStaffMessageBoardPosts = (filters) => {
  return async (dispatch) => {
    dispatch({ type: LOAD_STAFF_MESSAGE_BOARD_POSTS_BEGIN });
    try {
      const response = await StaffMessageBoardApi.getFilteredStaffPosts(filters);
      dispatch({ type: LOAD_STAFF_MESSAGE_BOARD_POSTS_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: LOAD_STAFF_MESSAGE_BOARD_POSTS_FAILURE });
      dispatch(showError(error));
    }
  };
};

export const loadMoreStaffMessageBoardPosts = (pagination) => {
  return async (dispatch) => {
    dispatch({ type: LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_BEGIN });
    try {
      const response = await StaffMessageBoardApi.getStaffPosts(pagination);
      dispatch({ type: LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_FAILURE });
      dispatch(showError(error));
    }
  };
};

export const loadMoreFileteredStaffMessageBoardPosts = (params) => {
  return async (dispatch) => {
    dispatch({ type: LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_BEGIN });
    try {
      const response = await StaffMessageBoardApi.getFilteredStaffPosts(params);
      dispatch({ type: LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: LOAD_MORE_STAFF_MESSAGE_BOARD_POSTS_FAILURE });
      dispatch(showError(error));
    }
  };
};

export const createStaffMessageBoardPosts = (post, data) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_STAFF_MESSAGE_BOARD_POST_BEGIN });
    try {
      const id = await StaffMessageBoardApi.createStaffPost(post);
      dispatch({ type: CREATE_STAFF_MESSAGE_BOARD_POST_SUCCESS, payload: { ...data, id } });
      dispatch(notificationActions.showSuccess(`Dodano wpis na tablicy: '${post.title}'`));
    } catch (error) {
      dispatch({ type: CREATE_STAFF_MESSAGE_BOARD_POST_FAILURE });
      dispatch(showError(error));
    }
  };
};

export const updateStaffMessageBoardPosts = (post, data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_STAFF_MESSAGE_BOARD_POST_BEGIN });
    try {
      await StaffMessageBoardApi.updateStaffPost(post);
      dispatch({ type: UPDATE_STAFF_MESSAGE_BOARD_POST_SUCCESS, payload: data });
      dispatch(notificationActions.showSuccess(`Zaktualizowano wpis na tablicy: '${post.title}'`));
    } catch (error) {
      dispatch({ type: UPDATE_STAFF_MESSAGE_BOARD_POST_FAILURE });
      dispatch(showError(error));
    }
  };
};

export const deleteStaffMessageBoardPosts = (post) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_STAFF_MESSAGE_BOARD_POST_BEGIN });
    try {
      await StaffMessageBoardApi.deleteStaffPost(post);
      dispatch({ type: DELETE_STAFF_MESSAGE_BOARD_POST_SUCCESS, payload: post });
      dispatch(notificationActions.showSuccess(`Usunięto wpis '${post.title}'`));
    } catch (error) {
      dispatch({ type: DELETE_STAFF_MESSAGE_BOARD_POST_FAILURE });
      dispatch(showError(error));
    }
  };
};

export const readAllStaffMessageBoard = () => {
  return async (dispatch) => {
    dispatch({ type: READALL_STAFF_MESSAGE_BOARD_START });
    try {
      await StaffMessageBoardApi.readAll();
      dispatch({ type: READALL_STAFF_MESSAGE_BOARD_SUCCESS });
    } catch (error) {
      dispatch({ type: READALL_STAFF_MESSAGE_BOARD_FAILURE });
      dispatch(showError(error));
    }
  };
};

export const loadUnreadedPosts = () => {
  return async (dispatch) => {
    dispatch({ type: LOAD_UNREADED_POSTS_START });
    try {
      const unreadedPosts = await StaffMessageBoardApi.loadUnreadedPosts();
      dispatch({ type: LOAD_UNREADED_POSTS_SUCCESS, payload: unreadedPosts.map((value) => value.id) });
    } catch (error) {
      dispatch({ type: LOAD_UNREADED_POSTS_FAILURE });
      dispatch(showError(error));
    }
  };
};
