import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Menu } from '@mui/material';

export default function IconMenu({ children, icon, color = '',isParent=false, isReadOnly = false, dense = false, sx }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Icon = icon;

  return (
    <Box sx={{ ...sx }}>
      <IconButton
        aria-label="więcej"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={isReadOnly && !isParent}
        sx={{ p: dense ? '0' : '' }}
        size="large">
        <Icon size={10} style={{ color }} />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {children(handleClose)}
      </Menu>
    </Box>
  );
}

IconMenu.propTypes = {
  children: PropTypes.func.isRequired,
  icon: PropTypes.any.isRequired,
  dense: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  color: PropTypes.string
};
