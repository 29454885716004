import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';
import api from '../api/PsychologicalAttendanceApi';

export function loadChildIndividualClassesAttendanceAsync(childId, date) {
  return (dispatch) => {
    return api
      .loadMonthlyIndividualPsychologicalAttendance(childId, date)
      .then((attendance) => dispatch({ type: types.LOAD_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE, attendance }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać obecności na zajęciach indywidualnych'));
        return logger.error(error);
      });
  };
}

export function loadChildGroupClassesAttendanceAsync(assistanceId, childId, date) {
  return (dispatch) => {
    return api
      .loadMonthlyGroupPsychologicalAttendance(assistanceId, childId, date)
      .then((attendance) => dispatch({ type: types.LOAD_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE, attendance }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać obecności zajęć grupowych'));
        return logger.error(error);
      });
  };
}

//attendance
export function reportAttendanceStartAsync() {
  return (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_START });
  };
}

export function reportAttendanceCancelAsync() {
  return (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_CANCEL });
  };
}

export function reportGroupAttendanceStartAsync() {
  return (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_START });
  };
}

export function reportGroupAttendanceCancelAsync() {
  return (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_CANCEL });
  };
}

export function reportIndividualJournalAttendanceStartAsync() {
  return (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_START });
  };
}

export function reportIndividualJournalAttendanceCancelAsync() {
  return (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_CANCEL });
  };
}

export function reportAttendance(data) {
  return (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_SUBMIT });
    api
      .reportIndividualPreviousAttendance(data)
      .then(() =>
        dispatch({
          type: types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ATTENDANCE_SUCCESS,
          data
        })
      )
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się zgłosić obecności'));
        return logger.error(error);
      });
  };
}
export function reportGroupAttendance(data) {
  return (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_SUBMIT });
    api
      .reportGroupPreviousAttendance(data)
      .then(() =>
        dispatch({
          type: types.REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_SUCCESS,
          data
        })
      )
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się zgłosić obecności'));
        return logger.error(error);
      });
  };
}

export function reportAbsenceStartAsync() {
  return (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ABSENCE_START });
  };
}

export function reportAbsenceCancelAsync() {
  return (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_ABSENCE_CANCEL });
  };
}

export function reportAbsence({
  childId,
  absenceFrom,
  absenceTo,
  absenceNotes,
  absenceDeadlineViolated = null,
  absenceReportedBy = null
}) {
  return (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_REVERT_SUBMIT });
    api
      .reportIndividualPreviousAbsence(childId, absenceFrom, absenceTo, absenceNotes)
      .then(() =>
        dispatch({
          type: types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_REVERT_SUCCESS,
          ...childId,
          absenceDeadlineViolated,
          absenceReportedBy,
          absenceFrom,
          absenceTo
        })
      )
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się zgłosić obecności'));
        return logger.error(error);
      });
  };
}

export function reportGroupAbsence({
  childId,
  absenceFrom,
  groupId,
  absenceTo,
  absenceNotes,
  absenceDeadlineViolated = null,
  absenceReportedBy = null
}) {
  return (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_PSYCHOLOGICAL_GROUP_ATTENDANCE_SUBMIT });
    api
      .reportGroupPreviousAbsence(childId, groupId, absenceFrom, absenceTo, absenceNotes)
      .then(() =>
        dispatch({
          type: types.REPORT_CHILD_PSYCHOLOGICAL_GROUP_REVERT_SUCCESS,
          ...childId,
          absenceDeadlineViolated,
          absenceReportedBy,
          absenceFrom,
          absenceTo,
          absenceNotes
        })
      )
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się zgłosić nieobecności'));
        return logger.error(error);
      });
  };
}

export function revertIndividualAbsence(childId, from, to, assistanceId) {
  console.log(childId, from, to);
  return (dispatch, getState) => {
    return api
      .revertIndividualCLassesChildAbsence(childId, from, to, assistanceId)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Wycofano nieobecność`));
        dispatch({ type: types.REPORT_CHILD_PSYCHOLOGICAL_INDIVIDUAL_REVERT_ABSENCE_SUCCESS, from, to });
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się wycofać nieobecności`));
        return logger.error(error);
      });
  };
}

export function revertGroupAbsence(childId, from, to, groupId) {
  return (dispatch, getState) => {
    return api
      .revertGroupCLassesChildAbsence(childId, from, to, groupId)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Wycofano nieobecność`));
        dispatch({ type: types.REPORT_CHILD_PSYCHOLOGICAL_GROUP_REVERT_ABSENCE_SUCCESS, from, to });
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się wycofać nieobecności`));
        return logger.error(error);
      });
  };
}

export function reportChildIndividualJournalAbsence({
  childId,
  absenceFrom,
  groupId,
  absenceTo,
  absenceNotes,
  absenceDeadlineViolated = null,
  absenceReportedBy = null
}) {
  return (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_INDIVIDUAL_JOURNAL_ABSENCE_SUBMIT });
    api
      .reportIndividualJournalPreviousAbsence(childId, groupId, absenceFrom, absenceTo, absenceNotes)
      .then(() => {
        dispatch(notificationActions.showSuccess('Zgłoszono nieobecność'));

        dispatch({
          type: types.REPORT_CHILD_INDIVIDUAL_JOURNAL_ABSENCE_SUCCESS,
          ...childId,
          absenceDeadlineViolated,
          absenceReportedBy,
          absenceFrom,
          absenceTo,
          absenceNotes
        });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się zgłosić nieobecności'));
        return logger.error(error);
      });
  };
}

export function reportChildIndividualJournalAttendance(data) {
  return (dispatch) => {
    dispatch({ type: types.REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_SUBMIT });
    api
      .reportChildIndividualJournalAttendance(data)
      .then(() => {
        dispatch(notificationActions.showSuccess('Zgłoszono obecność'));

        dispatch({
          type: types.REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_SUCCESS,
          data
        });
      })
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się zgłosić obecności'));
        return logger.error(error);
      });
  };
}

export function revertIndividualJournalAbsence(childId, from, to, groupId) {
  return (dispatch, getState) => {
    return api
      .revertIndividualJournalChildAbsence(childId, from, to, groupId)
      .then(() => {
        dispatch(notificationActions.showSuccess(`Wycofano nieobecność`));
        dispatch({ type: types.REPORT_CHILD_INDIVIDUAL_JOURNAL_REVERT_ABSENCE_SUCCESS, from, to });
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się wycofać nieobecności`));
        return logger.error(error);
      });
  };
}
