/* eslint-disable import/no-anonymous-default-export */
import { AuthModel } from '../models/auth/AuthModel';
import { GroupDetailsModel, GroupModel } from '../models/groups/GroupModels';
import {
  ChildDetailsModel,
  ChildPersonalBalanceModel,
  ImportChildrenSummaryModel,
  StudentDetailsModel
} from '../models/children/ChildModels';
import { RoutingModel } from '../models/navigation/RoutingModel';
import { MessageBoardModel } from '../models/messageBoard/MessageBoardModels';
import { MessageBoardFilterModel } from '../models/messageBoardFilter/MessageBoardFilterModels';
import {
  AttendanceJournalModel,
  AttendancesHistoryModel,
  ChildAttendanceModel
} from '../models/attendance/AttendanceModels';
import {
  ChildBalanceSummaryModel,
  ChildDuesModel,
  SettlementModel,
  StaffDuesModel
} from '../models/settlements/SettlementModels';
import { SettlementsFiltersModel } from '../models/settlements/SettlementsFiltersModel';
import { SettlementsSettingsModel } from '../models/configuration/settlementsSettings/SettlementsSettingsModel';
import { UnitModel } from '../models/configuration/unit/UnitModels';
import { UiModel } from '../models/UiModel';
import { GroupArchiveDetailsModel } from '../models/groups/GroupArchiveModels';
import { ChildArchiveDetailsModel } from '../models/children/ChildArchiveModels';
import { StaffMemberArchiveModel } from '../models/staffMembers/StaffMemberArchiveModels';
import { ActivityDetailsModel } from '../models/activities/ActivityModels';
import { ActivityArchiveDetailsModel } from '../models/activities/ActivityArchiveModels';
import { StaffMemberDetailsModel } from '../models/staffMembers/StaffMemberModels';
import { PrivateMessagePagingModel, PrivateThreadPagingModel } from '../models/privateMessages/PrivateMessagesModels';
import { ChargingSchemeDetailsModel } from '../models/configuration/chargingSchemes/ChargingSchemeModels';
import { GuardiansPinServiceSettingsModel } from '../models/configuration/guardiansPinService/GuardiansPinServiceModels';
import { DaycareSettingsModel } from '../models/configuration/daycare/DaycareSettingsModels';
import { DuesExportSettingsModel } from '../models/configuration/duesExport/DuesExportSettingsModels';
import { DuesFkExportSettingsModel } from '../models/configuration/duesExport/DuesFkExportSettingsModels';
import { PinEntriesModel } from '../models/pins/PinModels';
import { DailyGroupDaycareSheetModel } from '../models/daycare/DaycareModels';
import { WorkPlanModel } from '../models/digitalDiary/workPlan/WorkPlanModel';
import { TestApiModel } from '../models/testApi/TestApiModel';
import { DigitalDiaryEventsModel } from '../models/digitalDiary/digitalDiaryEvent/DigitalDiaryEventModel';
import { themeTypes } from '../styles/appTheme';
import { featuresFromPrefixValidator } from '../utils/featuresFromPrefixValidator';
import { AbsenceTomorrowList } from '../models/attendance/AttendanceTomorrowList';
import { PsychologicalGroupModel } from '../models/psychological/PsychologicalGroupModel';
import { MealDetailsModel } from '../models/meals/MealModels';
import { ChildMealsCalendarModel } from '../models/meals/ChildMealsCalendarModel';
import { DietDetailsModel } from '../models/meals/DietModels';
import { DailyScheduleModel } from '../models/digitalDiary/dailySchedule/DailyScheduleModel';

const nadarzyn = featuresFromPrefixValidator();

export default {
  //infrastructure
  auth: new AuthModel(null),
  routing: { current: new RoutingModel(), previous: new RoutingModel() },
  notifications: [],
  isLoading: true,
  //domain
  groups: [],
  groupDetails: new GroupDetailsModel(),
  groupDetailsUi: {
    details: new UiModel(),
    enrolledChildren: new UiModel(),
    associatedTeachers: new UiModel(),
    archival: new UiModel()
  },
  gallery: {
    albums: [],
    ui: { isLoading: false, isSuccess: null, target: null },
    multimedia: {
      list: [],
      fetchCount: 0,
      albumId: null
    }
  },
  activities: [],
  activityDetails: new ActivityDetailsModel(),
  activityDetailsUi: {
    details: new UiModel(),
    enrolledChildren: new UiModel()
  },
  meals: [],
  archivedMeals: [],
  mealsCategories: [],
  mealDetails: new MealDetailsModel(),
  mealDetailsUi: new UiModel(),
  children: [],
  childrenOnVacationPeriod: [],
  psychologicalChildren: [],
  diets: [],
  dietDetails: new DietDetailsModel(),
  dietDetailsUi: new UiModel(),
  childDetails: nadarzyn ? new StudentDetailsModel() : new ChildDetailsModel(),
  childPersonalBalance: new ChildPersonalBalanceModel(),
  childDetailsSummary: [],
  childDetailsUi: {
    details: new UiModel(),
    legalGuardians: new UiModel(),
    guardians: new UiModel(),
    opinions: new UiModel(),
    chargingSchemes: new UiModel(),
    attachments: new UiModel(),
    surveys: new UiModel(),
    diagnoses: new UiModel(),
    openingBalance: new UiModel(),
    isCalendarLoading: false,
    archival: new UiModel(),
    detailsSummary: new UiModel(),
    individualAttendanceUi: new UiModel(),
    groupAttendanceUi: new UiModel(),
    debtCollection: new UiModel(),
    removeDebtCollection: new UiModel(),
    individualJournalAttendanceUi: new UiModel(),
    vacationPeriods: new UiModel()
  },
  childAttendances: new ChildAttendanceModel(),
  childMealsCalendar: new ChildMealsCalendarModel(),
  childPsychologicalIndividualAttendances: {},
  // new ChildAttendanceModel(),
  childPsychologicalGroupAttendances: {},
  childDaycare: {},
  dailySchedules: new DailyScheduleModel(),
  dailySchedulesUi: {
    schedule: new UiModel(),
    items: new UiModel()
  },
  staffMembers: [],
  staffMemberDetails: new StaffMemberDetailsModel(),
  staffMemberDetailsUi: {
    details: new UiModel(),
    chargingSchemes: new UiModel()
  },
  messageBoardPosts: new MessageBoardModel([], 0),
  messageBoardEvents: new MessageBoardModel([], 0),
  messageBoardDigitalDiary: new DigitalDiaryEventsModel([], 0),
  messageBoardDigitalDiaryUi: new UiModel(),
  messageBoardUi: new UiModel(),
  messageBoardFilterUi: new UiModel(),
  messageBoardFilter: new MessageBoardFilterModel(),

  privateMessages: new PrivateMessagePagingModel(),
  privateThreads: new PrivateThreadPagingModel(),

  childrenTakenOverByDebtCollection: [],

  //Schematy pomocy PP

  psychologicalChildrenList: [],
  psychologicalChildrenListUI: new UiModel(),
  psychologicalHelpDetails: {},
  psychologicalSchemes: [],
  psychologicalSchemesUI: new UiModel(),
  psychologicalGroups: [],
  psychologicalGroupDetails: new GroupDetailsModel(),
  psychologicalChoosenGroup: new PsychologicalGroupModel(),
  PsychologicalGroupClassesAttendance: [],
  psychologicalGroupDetailsUi: {
    details: new UiModel(),
    enrolledChildren: new UiModel(),
    associatedTeachers: new UiModel(),
    archival: new UiModel()
  },
  //Zajęcia indywidualne
  psychologicalIndividualAttendances: {},
  psychologicalIndividualProgramUi: {
    individualProgram: new UiModel(),
    individualClasses: new UiModel(),
    notes: new UiModel(),
    observations: new UiModel()
  },
  psychologicalIndividualWorkProgram: {
    posts: [],
    basicGoals: {},
    specificGoals: {},
    conclusions: {},
    methods: {},
    teachingResources: {},
    notes: [],
    observations: []
  },
  // Indywidualne dzienniki zajęć specjalistycznych
  psychologicalIndividualJournalAttendances: {},
  psychologicalIndividualJournalUi: {
    individualProgram: new UiModel(),
    individualClasses: new UiModel(),
    notes: new UiModel(),
    observations: new UiModel()
  },

  psychologicalIndividualJournalWorkProgram: {
    posts: [],
    basicGoals: {},
    specificGoals: {},
    conclusions: {},
    methods: {},
    teachingResources: {},
    notes: [],
    observations: []
  },

  //Zajęcia grupowe
  psychologicalGroupAttendance: [],
  psychologicalGroupProgramUi: { groupProgram: new UiModel(), groupClasses: new UiModel(), groupDiary: new UiModel() },
  psychologicalGroupWorkProgram: {
    posts: [],
    diary: [],
    basicGroupGoals: {},
    specificGoals: {},
    groupConclusions: {},
    groupMethods: {}
  },
  psychologicalHelpUi: {
    notify: new UiModel(),
    ipet: new UiModel(),
    wopfu: new UiModel()
  },
  //Zapis pracy specjalisty

  specialists: [],

  specialistNotes: {
    //lista wszystkich zapisów
    specialistWorksList: [],
    //dane wybranego zapisu
    specialistsIds: [],
    classSchedule: [],
    workPlan: [],
    workPlanProgress: [],
    contacts: [],
    observations: [],
    groupActivities: [],
    notes: []
  },

  specialistNotesUi: {
    specialistWorksList: new UiModel(),
    classSchedule: new UiModel(),
    workPlan: new UiModel(),
    workPlanProgress: new UiModel(),
    contacts: new UiModel(),
    observations: new UiModel(),
    groupActivities: new UiModel(),
    notes: new UiModel()
  },

  attendance: new AttendanceJournalModel(),
  absenceTomorrow: new AbsenceTomorrowList(),
  attendanceUi: {
    dropOff: new UiModel(),
    pickUp: new UiModel(),
    absence: new UiModel(),
    revertAttendance: new UiModel(),
    reportAttendance: new UiModel()
  },
  attendanceHistory: new AttendancesHistoryModel(),
  legalGuardiansChildren: [],
  legalGuardianDashboardUi: {
    childrenAbsence: new UiModel()
  },
  legalGuardians: [],
  generateReportUi: new UiModel(),
  settlements: [],
  settlementsFilters: new SettlementsFiltersModel(),
  settlementsUi: new UiModel(),
  settlementsCalculationUi: new UiModel(),
  correctiveDuesCalculationUi: new UiModel(),
  registerPaymentUi: new UiModel(),
  registerPaymentsUi: new UiModel(),
  registerRebateUi: new UiModel(),
  approveDuesUi: new UiModel(),
  settlementsListUi: new UiModel(),
  configuration: {
    unit: new UnitModel(),
    unitAddressUi: new UiModel(),
    unitWorkingHoursUi: new UiModel(),
    unitAbsenceRemovalDeadlineUi: new UiModel(),
    unitPenaltiesUi: new UiModel(),
    daysOff: [],
    daysOffUi: new UiModel(),
    chargingSchemeDetails: new ChargingSchemeDetailsModel(),
    chargingSchemes: [],
    chargingSchemesUi: new UiModel(),
    settlementsSettings: new SettlementsSettingsModel(),
    settlementsSettingsUi: new UiModel(),
    reliefs: [],
    reliefsUi: new UiModel(),
    penalties: [],
    numberingSchemes: [],
    numberingSchemesUi: new UiModel(),
    reportsConfiguration: [],
    reportsConfigurationUi: new UiModel(),
    attributesSets: [],
    attributesSetsUi: {
      details: new UiModel()
    },
    diagnosticSchemesSets: [],
    diagnosticSchemesSetsUi: {
      details: new UiModel()
    },
    guardiansPinService: new GuardiansPinServiceSettingsModel(),
    daycare: new DaycareSettingsModel(),
    duesExportSettings: new DuesExportSettingsModel(),
    duesExportSettingsUi: new UiModel(),
    duesFkExportSettings: new DuesFkExportSettingsModel(),
    duesFkExportSettingsUi: new UiModel(),
    importChildrenUi: new UiModel(),
    exportChildrenUi: new UiModel(),
    exportChildren: {},
    importChildren: {},
    importChildrenSummary: new ImportChildrenSummaryModel(),
    importChildrenSummaryUi: new UiModel(),
    entrances: [],
    importEntranceUi: new UiModel()
  },
  childDues: new ChildDuesModel(),
  childBalanceSummary: new ChildBalanceSummaryModel(),
  staffDues: new StaffDuesModel(),
  guardianDues: [],
  guardianDueDetails: new SettlementModel(),
  archives: {
    groups: [],
    groupDetails: new GroupArchiveDetailsModel(),
    activities: [],
    activityDetails: new ActivityArchiveDetailsModel(),
    children: [],
    childDetails: new ChildArchiveDetailsModel(),
    staffMembers: [],
    staffMembersDetails: new StaffMemberArchiveModel()
  },
  staffDashboard: {
    overdue: [],
    overpayments: [],
    expiringReliefs: [],
    attendanceSummary: {
      present: 0,
      enrolled: 0,
      absentTomorrow: 0,
      groups: []
    }
  },
  resetPasswordUi: new UiModel(),
  reports: [],
  pinEntries: new PinEntriesModel(),
  daycare: new DailyGroupDaycareSheetModel(),
  daycareUi: new UiModel(),
  duesExports: [],
  duesFkExports: [],

  digitalDiary: {
    workPlan: new WorkPlanModel(),
    workPlanUi: new UiModel(),
    digitalDiaryEventUi: new UiModel(),
    events: new DigitalDiaryEventsModel([], 0),
    choosenGroup: new GroupModel()
  },
  legalGuardianApplications: {
    cateringChargingSchemes: [],
    childApplications: []
  },
  applications: [],
  // store for test api
  testApi: new TestApiModel(),
  contrastColor: localStorage.getItem('theme') || false,
  theme: localStorage.getItem('theme') || themeTypes.light.value,
  events: {
    eventsList: [],
    rowNumber: 0
  },
  loginHistory: {
    loginHistoryList: [],
    rowNumber: 0
  },
  profileHistory: {
    profileHistoryList: [],
    rowNumber: 0
  },
  currentUsers: {
    currentUsersList: [],
    rowNumber: 0
  },
  rightBottomNotification: [],
  staffCalendar: {
    events: [],
    loading: false,
    eventDetails: new MessageBoardModel([], 0)
  },
  staffMessageBoard: {
    posts: new MessageBoardModel([], 0),
    loading: false,
    loadingMore: false,
    readingAll: false,
    unreadedPosts: []
  },
  scheduledTransfers: [],
  virtualDisc: [],
  virtualDiscFolder: {
    path: [],
    disc: [],
    folderId: null
  },
  virtualDiscFolderUI: {
    action: new UiModel(),
    fetch: new UiModel()
  }
};
