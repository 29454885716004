import PropTypes from 'prop-types';
import React from 'react';
import { Box, Grid, Paper, Typography, useMediaQuery } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => {
  return {
    root: {
      minHeight: '80vh',
      padding: '36px'
    },
    mainWrapper: {
      width: '60%',
      margin: 'auto auto',
      padding: '16px'
    }
  };
});

const WelcomeScreen = ({ children }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Box>
      <Box className={classes.root}>
        <Box sx={{ mx: 'auto' }}>
          <Paper className={classes.mainWrapper}>{children}</Paper>
        </Box>
      </Box>
      <Grid container>
        <Grid row>
          <Grid sm={6} xs={12}>
            <a href="http://softwarehub.pl">
              <img
                src="/img/sh_logo.png"
                alt="Software Hub"
                sx={{
                  display: 'block',
                  my: 0,
                  mx: 'auto'
                }}
              />
            </a>
            <a href="https://www.w3.org/WAI/WCAG2A-Conformance" title="Explanation of WCAG 2 Level A Conformance">
              <img
                height="32"
                width="88"
                sx={{
                  display: 'block',
                  my: 0,
                  mx: 'auto'
                }}
                src="https://www.w3.org/WAI/WCAG21/wcag2.1A-v"
                alt="Level A conformance, W3C WAI Web Content Accessibility Guidelines 2.1"
              />
            </a>
          </Grid>
          <Grid sm={6} xs={12} />
        </Grid>
        <hr />
        <Grid row sx={{ textAlign: 'center' }}>
          <Grid sm={8}>
            <Typography>&copy; Copyrights 2017 - 2021 Software Hub sp. z o.o.</Typography>
            <Typography>
              02-495 Warszawa, ul. Ryżowa 33a/7 | <a href="tel: +48 22 398 74 85"> +48 22 398 74 85</a> |{' '}
              <a href="tel:biuro@softwarehub.pl">biuro@softwarehub.pl</a>
            </Typography>
            <Typography>
              <strong>Inspektor ochrony danych</strong> Małgorzata Ganczar{' '}
              <a href="mailto:iod@softwarehub.pl">iod@softwarehub.pl</a>
            </Typography>
            <Typography>
              KRS: 0000629823, NIP: 7831745232, REGON: 36508360600000. Kapitał zakładowy 100 000 PLN (wpłacony w
              całości).
            </Typography>
          </Grid>
          <Grid sm={4} sx={isMobile ? { textAlign: 'center' } : { textAlign: 'right' }}>
            <a href="https://facebook.com/softwarehub2016"> </a>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

WelcomeScreen.propTypes = {
  children: PropTypes.element.isRequired
};

export default WelcomeScreen;
