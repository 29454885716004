import { useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ToggleIdButton = (props) => {
  const { documentNumber } = props;
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      {!isVisible ? (
        <Button
          onClick={toggleVisibility}
          variant="text"
          startIcon={<VisibilityIcon />}
          aria-label="Pokaż numer dokumentu">
          Pokaż
        </Button>
      ) : (
        <Tooltip title="Ukryj">
          <Button variant="text" onClick={toggleVisibility} aria-label={`Numer dokumentu: ${documentNumber}`}>
            {documentNumber}
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default ToggleIdButton;
