import BaseModel from '../BaseModel';

/*eslint no-use-before-define: 0*/
export class SettlementsFiltersModel extends BaseModel {
  constructor() {
    super();
    this.selectedChargingType = null;
    this.selectedStatus = null;
    this.filterKeyword = '';
  }
}
