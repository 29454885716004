import { Search } from '@mui/icons-material';
import { Box, FormControl, IconButton, InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const SearchBar = ({ handleTextSearch, onKeyDown, value, onChange, label, contrast, sx }) => {
  return (
    <Box
      sx={{
        borderBottom: (theme) => `1px solid ${contrast ? theme.palette.color.contrast : theme.palette.color.primary}`,
        width: '100%',
        ...sx
      }}>
      <FormControl sx={{ width: '100%' }}>
        <TextField
          hiddenLabel
          label="Wyszukaj"
          InputLabelProps={{ sx: { display: 'none' } }}
          fullWidth
          onKeyDown={onKeyDown}
          id="standard-basic"
          value={value}
          onChange={(e) => onChange(e)}
          onKeyPress={(e) => {
            if (e.code === 'Enter') handleTextSearch(value);
          }}
          aria-label="szukaj"
          placeholder={label || 'Szukaj'}
          inputProps={{
            sx: {
              color: contrast ? (theme) => theme.palette.color.contrast : (theme) => theme.palette.color.primary
            }
          }}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  mr: 1
                }}>
                <IconButton
                  aria-label="szukaj"
                  onClick={() => handleTextSearch(value)}
                  tooltip="Szukaj"
                  size="large"
                  sx={{
                    color: (theme) => (contrast ? theme.palette.color.contrast : theme.palette.color.primary),
                    width: '100%'
                  }}>
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
            'aria-label': 'opis'
          }}
          sx={{
            width: '100%',
            '& .MuiInput-underline:before': {
              borderBottom: (theme) => `1px solid ${theme.palette.color.primary}`
            },
            '& .MuiInput-underline:after': {
              borderBottom: (theme) => `1px solid ${theme.palette.color.primary}`
            },
            '& .MuiInput-underline:hover': {
              borderBottom: (theme) => `1px solid ${theme.palette.color.primary}`
            }
          }}
          variant="standard"
        />
      </FormControl>
    </Box>
  );
};
SearchBar.defaultProps = {
  value: ''
};

SearchBar.propTypes = {
  handleTextSearch: PropTypes.func,
  onKeyDown: PropTypes.func,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string
};
