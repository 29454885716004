import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  AppBar,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import EmptyState from '../../common/EmptyState';
import TabContent from '../components/ChooseMemberMessageDialog/TabContent';
import FilterByRole from '../../common/filters/filterByRole/FilterByRole';
import ParticipantsAvatarList from '../groupMessagesComponents/ParticipantsAvatarList';
import { useSelector } from 'react-redux';
import SearchInputV2 from '../../searchInputV2/SearchInputV2';
import userRoles from '../../../constants/userRoles';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'capitalize'
  },
  tab: {
    textTransform: 'none',
    flexDirection: 'row'
  }
}));

const RecipientsDialog = ({
  recipients,
  isOpen,
  user,
  onClose,
  onSelect,
  groups,
  isCheckbox,
  selectedRecipents,
  onSelectFew,
  onMultiMessage,

  onSave
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [selectedGroup, setSelectGroup] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const legalGuardians = useSelector((state) => state.legalGuardians);
  const reduxStaffMember = useSelector((state) => state.staffMembers);
  const possibleRecipients = useSelector((state) => state.privateMessages.possibleRecipients);

  const availableGroups = useMemo(() => {
    if (user.userRole === userRoles.staffMemberTeacher) {
      return reduxStaffMember.find((sm) => sm.id === user.userId).groups;
    }
    return groups;
  }, [user, reduxStaffMember]);

  const availableLegalGuardians = useMemo(() => {
    if (user.userRole === userRoles.staffMemberTeacher) {
      const availableGroupsIds = availableGroups.map((group) => group.id);
      return legalGuardians.filter((lg) => lg.children.some((child) => availableGroupsIds.includes(child.groupId)));
    }
    return legalGuardians;
  }, [user, legalGuardians, availableGroups]);

  const staffMembers = useMemo(
    () =>
      reduxStaffMember.filter(
        (u) =>
          u.role !== 'digitalDiary' && u.role !== 'teacherSupport' && u.role !== 'canteenWorker' && u.id !== user.userId
      ),
    [reduxStaffMember, user.userId]
  );

  const filteredStaffMembers = useMemo(() => {
    let list = staffMembers;
    if (user.userRole === 'legalGuardian') {
      list = possibleRecipients;
    }
    return list.filter(
      (staffMember) =>
        (staffMember.firstName + ' ' + staffMember.lastName).toLowerCase().includes(filterValue.toLowerCase()) ||
        (staffMember.lastName + ' ' + staffMember.firstName).toLowerCase().includes(filterValue.toLowerCase())
    );
  }, [filterValue, user.userRole, possibleRecipients, staffMembers]);

  const [filteredLegalGuardians, setFilteredLegalGuardians] = useState(availableLegalGuardians);

  const cl = useStyles();

  const handleFilterLegalGuardians = useCallback(
    (searchValue, selectedGroup = '') => {
      setSelectGroup(selectedGroup);
      let searchResult = availableLegalGuardians.filter(
        (legalGuardian) =>
          (legalGuardian.firstName + ' ' + legalGuardian.lastName).toLowerCase().includes(searchValue.toLowerCase()) ||
          (legalGuardian.lastName + ' ' + legalGuardian.firstName).toLowerCase().includes(searchValue.toLowerCase()) ||
          legalGuardian.children.filter(
            (child) =>
              (child.firstName + ' ' + child.lastName).toLowerCase().includes(searchValue.toLowerCase()) ||
              (child.lastName + ' ' + child.firstName).toLowerCase().includes(searchValue.toLowerCase())
          ).length > 0
      );
      if (!!selectedGroup) {
        searchResult = searchResult.filter(
          (legalGuardian) => legalGuardian.children.filter((child) => child.groupId === selectedGroup).length > 0
        );
      }

      setFilteredLegalGuardians(searchResult);
    },
    [availableLegalGuardians, user]
  );

  useEffect(() => {
    if (
      selectedRecipents.filter((selectedId) => filteredLegalGuardians.find((search) => search.id === selectedId))
        .length === filteredLegalGuardians.length
    ) {
      setIsSelectedAll(true);
    } else {
      setIsSelectedAll(false);
    }
  }, [filteredLegalGuardians, selectedRecipents]);

  useEffect(() => {
    handleFilterLegalGuardians(filterValue, selectedGroup);
  }, [recipients, filterValue, selectedGroup, handleFilterLegalGuardians]);

  const handleRecipientSearch = (searchValue) => {
    setFilterValue(searchValue);
    handleFilterLegalGuardians(searchValue, selectedGroup);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    onClose();
  };

  const handleReset = () => {
    handleFilterLegalGuardians(filterValue);
  };
  const handleSelectRecipient = (recipient) => {
    onSelect(recipient);
  };

  const handleSelectAll = () => {
    const resultSelectedRecipients = selectedRecipents;
    filteredLegalGuardians.map((lg) => {
      if (!selectedRecipents.find((selectedId) => selectedId === lg.id)) resultSelectedRecipients.push(lg.id);
    });
    onSelectFew(resultSelectedRecipients);
    setIsSelectedAll(true);
  };
  const handleDeleteAll = () => {
    const resultSelectedRecipients = selectedRecipents.filter(
      (selectedId) => !filteredLegalGuardians.find((lg) => lg.id === selectedId)
    );
    onSelectFew(resultSelectedRecipients);
    setIsSelectedAll(false);
  };

  const guardianExist = recipients.some((x) => x.hasOwnProperty('documentNumber'));

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth={true} onClose={handleClose}>
      <DialogTitle sx={{ py: { xs: 1, sm: 2, md: 3 } }}>Wybierz odbiorcę</DialogTitle>
      <DialogContent sx={{ px: { xs: 1.5, sm: 2, md: 3 }, pb: { xs: 1, sm: 2 } }}>
        {recipients.length === 0 ? (
          <EmptyState message="Brak odbiorców" contrast />
        ) : (
          <>
            <Box
              sx={{ background: (theme) => theme.palette.background.default, position: 'sticky', top: 0, zIndex: 900 }}>
              <ParticipantsAvatarList
                selectedRecipients={selectedRecipents}
                recipients={recipients}
                onRemove={handleSelectRecipient}
              />
              <AppBar
                position="static"
                sx={{
                  background: (theme) => theme.palette.color.hidden,
                  boxShadow: 'none',
                  maxHeight: 55,
                  p: 0
                }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="Przełącz"
                  variant="fullWidth"
                  TabIndicatorProps={{
                    sx: { background: (theme) => theme.palette.color.color25 }
                  }}>
                  <Tab
                    sx={{
                      '&.Mui-selected': {
                        color: (theme) => theme.palette.color.color25
                      },
                      color: (theme) => theme.palette.text.primary,
                      py: 0
                    }}
                    icon={
                      <GroupsOutlinedIcon
                        sx={{
                          mr: 1,
                          '&.Mui-selected': {
                            color: (theme) => theme.palette.color.color25
                          },
                          fontSize: 28
                        }}
                      />
                    }
                    className={cl.tab}
                    label={
                      <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }} className={cl.text}>
                        pracownicy
                      </Typography>
                    }
                  />
                  {guardianExist ? (
                    <Tab
                      sx={{
                        '&.Mui-selected': {
                          color: (theme) => theme.palette.color.color25
                        },
                        color: (theme) => theme.palette.text.primary,
                        p: 0
                      }}
                      icon={
                        <GroupOutlinedIcon
                          sx={{
                            mr: 1,
                            '&.Mui-selected': {
                              color: (theme) => theme.palette.color.color25
                            },
                            fontSize: 28
                          }}
                        />
                      }
                      className={cl.tab}
                      label={
                        <Typography
                          sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}
                          className={cl.text}>
                          opiekunowie prawni
                        </Typography>
                      }
                    />
                  ) : null}
                </Tabs>
              </AppBar>
              <Box>
                <SearchInputV2
                  contrast
                  searchText={filterValue}
                  onSearch={handleRecipientSearch}
                  onClear={() => {}}
                  isDivider={false}
                  style={{ flex: 1 }}
                  sx={{ p: 1 }}
                  InputProps={{
                    style: { width: '100%', fontSize: '16px' },
                    placeholder: 'Szukaj',
                    inputProps: { 'aria-label': 'Wyszukaj pojedyncze osoby z listy' }
                  }}
                  endAdornment={
                    tabValue === 1 ? (
                      <FilterByRole
                        groups={availableGroups}
                        selectedGroup={selectedGroup}
                        onSelectGroup={(value) => {
                          handleFilterLegalGuardians(filterValue, value);
                        }}
                        resetFilters={handleReset}
                        onResetGroups={handleReset}
                      />
                    ) : undefined
                  }
                />
              </Box>
            </Box>
            <Box>
              <TabContent
                selectedRecipents={selectedRecipents}
                isCheckbox={isCheckbox}
                value={tabValue}
                index={0}
                filteredRecipients={filteredStaffMembers}
                label="Pracownicy"
                onSelect={handleSelectRecipient}
              />
              {guardianExist && (
                <TabContent
                  canSelectAll={!!selectedGroup}
                  isSelectedAll={isSelectedAll}
                  onSelectAll={handleSelectAll}
                  onDeleteAll={handleDeleteAll}
                  isCheckbox={isCheckbox}
                  selectedRecipents={selectedRecipents}
                  value={tabValue}
                  index={1}
                  filteredRecipients={filteredLegalGuardians}
                  label="Opiekunowie prawni"
                  onSelect={handleSelectRecipient}
                />
              )}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Button
          disabled={!selectedRecipents.length}
          onClick={onSave}
          sx={{ mr: 2 }}
          variant="contained"
          aria-label="Otwórz czat">
          Otwórz czat
        </Button>
        {user.userRole !== 'legalGuardian' && (
          <Button
            disabled={selectedRecipents.length < 2}
            onClick={onMultiMessage}
            sx={{ mr: 2 }}
            variant="contained"
            aria-label="Napisz wiadomość pojedyńczą">
            Napisz wiadomość pojedyńczą
          </Button>
        )}
        <Button onClick={handleClose} sx={{ mr: 2 }} variant="outlinedContrast" aria-label="Anuluj">
          Anuluj
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RecipientsDialog.propTypes = {
  onSelect: PropTypes.func.isRequired,
  recipients: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired
};

export default RecipientsDialog;
