import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import PersonAvatar from '../../../forms/PersonAvatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExceededAbsenceReportDeadlineWarning from '../../../attendanceSheet/reportDialogs/ExceededAbsenceReportDeadlineWarning';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';

class AttendanceDetailsDialog extends React.Component {
  renderAvatarWithName(person, actionDate) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'center',
          gap: 2,
          fontWeight: (theme) => theme.typography.fontWeightMedium
        }}>
        <Box flexDirection="row">
          {`${person?.firstName} ${person?.lastName} ${person?.relationship ? `(${person?.relationship})` : ''}`}
          <Typography component="span" sx={{ fontSize: '12px' }}>
            {actionDate && moment(actionDate).format('YYYY-MM-DD HH:mm')}
          </Typography>
        </Box>
        <PersonAvatar
          initials
          sx={{ m: 1 }}
          alt={person?.firstName}
          size={30}
          url={person?.photoUrl}
          firstName={person?.firstName}
          lastName={person?.lastName}
        />
      </Box>
    );
  }

  renderAttendanceDetails(
    droppedOffAt,
    pickedUpAt,
    pickedUpBy,
    receivedBy,
    releasedBy,
    droppedOffModifiedAt,
    pickedUpModifiedAt,
    isAdaptation
  ) {
    return droppedOffAt && pickedUpAt ? (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '&:hr': {
            alignSelf: 'stretch',
            my: 1
          }
        }}>
        <Typography
          component="span"
          sx={{ fontWeight: (theme) => theme.typography.fontWeightLarge, fontSize: '20px', my: 1 }}>
          {`${moment(droppedOffAt).format('HH:mm')} - ${moment(pickedUpAt).format('HH:mm')}`}
        </Typography>
        <Typography
          component="span"
          sx={{ fontWeight: (theme) => theme.typography.fontWeightLarge, fontSize: '20px', my: 1 }}>
          {isAdaptation ? 'Adaptacja' : ''}
        </Typography>
        <Divider />
        {!this.props.isPsychological && (
          <Box>
            <Typography component="span" sx={{ fontWeight: (theme) => theme.typography.fontWeightLarge, mt: 3 }}>
              Odebrane przez
            </Typography>
            {this.renderAvatarWithName(pickedUpBy)}

            <Divider />
            <Typography component="span" sx={{ fontWeight: (theme) => theme.typography.fontWeightLarge, mt: 3 }}>
              Wejście zarejestrowane przez
            </Typography>
            {this.renderAvatarWithName(receivedBy, droppedOffModifiedAt)}
            <Typography component="span" sx={{ fontWeight: (theme) => theme.typography.fontWeightLarge, mt: 3 }}>
              Wyjście zarejestrowane przez
            </Typography>
            {this.renderAvatarWithName(releasedBy, pickedUpModifiedAt)}
          </Box>
        )}
      </Box>
    ) : null;
  }

  renderAbsenceDetails(absenceFrom, absenceTo, absenceNotes, absenceReportedBy, absenceReportedAt) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '&:hr': {
            alignSelf: 'stretch',
            my: 1
          }
        }}>
        <Box
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightLarge,
            fontSize: '1.4em',
            my: 1
          }}>
          {`${moment(absenceFrom).format('DD.MM')} - ${moment(absenceTo).format('DD.MM')}`}
        </Box>
        <Divider />
        <Typography component="span">Notatka</Typography>
        <Box
          sx={{
            m: 0.5
          }}>
          {absenceNotes || 'Brak'}
        </Box>
        {!this.props.isPsychological && absenceReportedBy && absenceReportedBy.lastName ? (
          <>
            <Typography component="span" sx={{ fontWeight: (theme) => theme.typography.fontWeightLarge, mt: 3 }}>
              Nieobecność zarejestrowana przez
            </Typography>
            {this.renderAvatarWithName(absenceReportedBy, absenceReportedAt)}
          </>
        ) : null}
      </Box>
    );
  }

  render() {
    if (!this.props.isDialogOpen || !this.props.childAttendance) return null;

    const {
      date,
      droppedOffAt,
      pickedUpAt,
      pickedUpBy,
      receivedBy,
      releasedBy,
      absenceFrom,
      absenceTo,
      absenceNotes,
      absenceReportedBy,
      absenceReportedAt,
      violatedSchemeDeadlines,
      droppedOffModifiedAt,
      pickedUpModifiedAt,
      isAdaptation
    } = this.props.childAttendance;
    const child = this.props.childDetails;
    const isAbsence = absenceFrom && absenceTo;
    const isAttendance = droppedOffAt && pickedUpAt;

    return (
      <Dialog open={this.props.isDialogOpen} fullWidth maxWidth="xs">
        {' '}
        <DialogTitle>
          <Box
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
              '&:svg': {
                mr: 1
              }
            }}>
            <AccountCircleIcon
              fontSize="large"
              sx={{ m: 1, color: (theme) => (isAbsence ? theme.palette.color.color14 : theme.palette.color.color12) }}
            />
            <Typography
              component="span"
              sx={{ fontSize: '20px', fontWeight: (theme) => theme.typography.fontWeightLarge }}>
              {isAbsence ? ' Nieobecność - ' : ' Obecność - '}
              {moment(date).format('DD.MM (dddd)')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            {isAbsence && !!violatedSchemeDeadlines.length ? (
              <ExceededAbsenceReportDeadlineWarning
                absenceFrom={absenceFrom}
                absenceReportedAt={absenceReportedAt}
                schemes={this.props.childDetails.contract.schemes || []}
                force={violatedSchemeDeadlines.length > 0}
              />
            ) : null}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <PersonAvatar
                initials
                sx={{ m: 1 }}
                alt={child.firstName}
                url={child.photoUrl}
                firstName={child.firstName}
                lastName={child.lastName}
              />
              <Typography
                component="span"
                sx={{
                  fontSize: '18px',
                  fontWeight: (theme) => theme.typography.fontWeightBold
                }}>{`${child.firstName} ${child.lastName}`}</Typography>{' '}
            </Box>

            {isAbsence
              ? this.renderAbsenceDetails(absenceFrom, absenceTo, absenceNotes, absenceReportedBy, absenceReportedAt)
              : null}
            {isAttendance
              ? this.renderAttendanceDetails(
                  droppedOffAt,
                  pickedUpAt,
                  pickedUpBy,
                  receivedBy,
                  releasedBy,
                  droppedOffModifiedAt,
                  pickedUpModifiedAt,
                  isAdaptation
                )
              : null}
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" aria-label="Ok" onClick={this.props.onRequestClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AttendanceDetailsDialog.propTypes = {
  childAttendance: PropTypes.object,
  childDetails: PropTypes.object.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired
};

export default AttendanceDetailsDialog;
