import PropTypes from 'prop-types';
import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import ActionConfirmationButton from '../../forms/buttons/ActionConfirmationButton';
import numberFormatter from '../../../utils/numberFormatter';
import { ReliefType } from '../../../constants/reliefType';
import _ from 'lodash';

const ReliefsList = ({ reliefs, onRemove }) => {
  return (
    <List sx={{ p: 0 }}>
      {reliefs.map((relief) => {
        return (
          <ListItem
            key={_.uniqueId()}
            sx={{
              '&:hover': {
                background: (theme) => theme.palette.background.hover
              }
            }}>
            <ListItemText
              primaryTypographyProps={{ sx: { fontSize: 18, ffontWeight: (theme) => theme.typography.fontWeightBold } }}
              primary={relief.name}
              secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.text.primary } }}
              secondary={`${numberFormatter.format(relief.rate)} ${
                relief.type === ReliefType.percentage.value ? '%' : 'zł'
              }`}
              disabled={true}
            />
            <ActionConfirmationButton
              variant="clearText"
              actionLabel="usuń"
              confirmationText="Czy na pewno chcesz usunąć ulgę? Ulga zostanie odłączona od wszystkich dzieci do których jest aktualnie przypisana."
              confirmationTitle="Usuń ulgę"
              isSecondary={true}
              confirmLabel="Usuń"
              onConfirm={() => onRemove(relief.id)}
              onCancel={() => {}}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

ReliefsList.propTypes = {
  reliefs: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default ReliefsList;
