import React, { useState } from 'react';
import {
  Paper,
  Snackbar,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  Fade,
  Grow,
  List,
  Collapse,
  Grid
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as targetTypes from '../../constants/rightBottomNotificationTypes';
import makeStyles from '@mui/styles/makeStyles';
import IconButtonPipe from '../common/IconButtonPipe';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Download from '../gallery/Components/Multimedia/Notifications/Download/Download';
import UploadMultimedia from '../gallery/Components/Multimedia/Notifications/Upload/UploadMultimedia';
import VirtualDiscUpload from '../virtualDisc/components/Notifications/Upload/UploadMultimedia';
import * as types from '../../actions/actionTypes';
import * as Status from '../../constants/status';

import StackRow from '../common/StackRow';

export const styles = (theme) => ({
  content: {
    minWidth: 'auto',
    background: theme.palette.color.color4,
    padding: 0
  },
  message: ({ isMobile }) => ({
    padding: 0,
    width: isMobile ? '100%' : 'auto'
  })
});

const useStyles = makeStyles(styles);

const RightBottomNotification = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const cl = useStyles({ isMobile });
  const notifyArray = useSelector((state) => state.rightBottomNotification);
  const [expanded, setExpanded] = useState(true);
  const dispatch = useDispatch();

  const isCloseButton = !notifyArray.some(
    (item) => item.data.status === Status.PENDING || item.data.status === Status.UPLOADED
  );

  let operationNumber = 0;
  notifyArray.forEach((item) => {
    if (item.data.status === Status.PENDING || item.data.status === Status.UPLOADED) operationNumber += 1;
  });

  const renderDependsOnType = ({ target, uuid, data }) => {
    switch (target) {
      case targetTypes.DOWNLOAD:
        return <Download uuid={uuid} data={data} />;
      case targetTypes.GALLERY_UPLOAD:
        return <UploadMultimedia uuid={uuid} data={data} />;
      case targetTypes.VIRTUAL_DRIVE_UPLOAD:
        return <VirtualDiscUpload uuid={uuid} data={data} />;
      default:
        return null;
    }
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{ pb: 2, zIndex: theme.zIndex.drawer + 50 }}
      open={notifyArray.length > 0}
      onClose={() => {}}
      ContentProps={{
        className: cl.content,
        classes: { message: cl.message }
      }}
      key="test">
      <Paper sx={{ minWidth: 300 }}>
        <Grid container sx={{ p: 1, alignItems: 'center', justifyContent: 'space-between' }}>
          {expanded ? (
            <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, pl: 2 }}>
              Lista operacji
            </Typography>
          ) : (
            <>
              {operationNumber === 0 ? (
                <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, pl: 2 }}>
                  Ukończono wszystkie operacje
                </Typography>
              ) : (
                <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, pl: 2 }}>
                  Operacje w toku ({operationNumber})
                </Typography>
              )}
            </>
          )}
          <StackRow>
            <IconButtonPipe
              onClick={() => setExpanded((prev) => !prev)}
              aria-expanded={expanded}
              tooltip={expanded ? 'Minimalizuj' : 'Maksymalizuj'}
              sx={{ ml: 1 }}>
              {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButtonPipe>
            {isCloseButton && (
              <Fade in={isCloseButton}>
                <Box>
                  <IconButtonPipe
                    tooltip="Zamknij"
                    onClick={() => dispatch({ type: types.RIGHT_BOTTOM_NOTIFICATION_RESET })}>
                    <CloseIcon />
                  </IconButtonPipe>
                </Box>
              </Fade>
            )}
          </StackRow>
        </Grid>
        <Collapse in={expanded} sx={{ maxHeight: 300, overflowY: 'auto' }}>
          <List>
            {notifyArray.map((item, index) => (
              <Grow in={true} {...{ timeout: 1400 }} key={index}>
                <Box> {renderDependsOnType(item)}</Box>
              </Grow>
            ))}
          </List>
        </Collapse>
      </Paper>
    </Snackbar>
  );
};

RightBottomNotification.propTypes = {};

export default RightBottomNotification;
