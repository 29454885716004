import { BaseModelValidator } from '../../../../models/BaseModelValidator';
import { check } from '../../../../models/validation/validateHelper';

export class NotifyValidator extends BaseModelValidator {
  constructor() {
    super();

    this.constraints = {
      name: (value) => check.isEmpty(value) && 'Nie podano tytułu',
      type: (value) => check.isEmpty(value) && 'Nie wybrano typu zawiadomienia',
      dateOfMeeting: (value) => check.isEmpty(value) && 'Nie podano daty spotkania',
      hourOfMeeting: (value) => check.isEmpty(value) && 'Nie podano godziny spotkania',
      placeOfMeeting: (value) => check.isEmpty(value) && 'Nie podano miejsca spotkania',
      purposeOfMeeting: (value) => check.isEmpty(value) && 'Nie podano celu spotkania'
    };
  }
}
