import ApiBase, { ROOT_URL } from './ApiBase';

class MessageBoardApi extends ApiBase {
  static getThreads(limit, offset) {
    const paging = `limit=${limit}&offset=${offset}`;
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/privateMessages/threads/all?${paging}`).type('application/json')
    );
  }

  static getMyThreads(limit, offset) {
    const paging = `limit=${limit}&offset=${offset}`;
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/privateMessages/threads/my?${paging}`).type('application/json')
    );
  }

  static getUnreadThreadsByRecipient(limit, offset) {
    const paging = `limit=${limit}&offset=${offset}`;
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/privateMessages/threads/my/unread?${paging}`).type('application/json')
    );
  }

  static getMessages(threadId, limit, offset) {
    const paging = `limit=${limit}&offset=${offset}`;
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/privateMessages/threads/${threadId}?${paging}`).type('application/json')
    );
  }

  static updateMessage(messageId, message) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/privateMessages/${messageId}`).send(message).type('application/json')
    );
  }

  static getPossibleRecipients() {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/privateMessages/possible-recipients`).type('application/json')
    );
  }
}

export default MessageBoardApi;
