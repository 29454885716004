import initialState from './initial';
import * as types from '../actions/actionTypes';
import { GroupModel } from '../models/groups/GroupModels';
import { PsychologicalGroupModel } from '../models/psychological/PsychologicalGroupModel';

export default function psychologicalGroupsReducer(state = initialState.psychologicalGroups, action) {
  switch (action.type) {
    case types.LOAD_PSYCHOLOGICAL_GROUPS: {
      return action.groups.map((g) => new PsychologicalGroupModel().assign(g));
    }
    case types.ARCHIVE_PSYCHOLOGICAL_GROUP_SUCCESS: {
      return [...state.filter((g) => g.id !== action.groupId)];
    }
    case types.ADD_PSYCHOLOGICAL_GROUP: {
      const group = new GroupModel().assign(action.groupDetails);
      return [...state, group];
    }
    default:
      return state;
  }
}
