import makeStyles from '@mui/styles/makeStyles';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Badge from '@mui/material/Badge';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import React from 'react';
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import ClearIcon from '@mui/icons-material/Clear';
import IconButtonPipe from '../../../common/IconButtonPipe';
import { Box } from '@mui/material';

const useMobileSearchStyles = makeStyles((theme) => ({
  fab: {
    margin: 0
  },
  wrapper: {
    display: ({ isMenuOpen }) => (isMenuOpen ? 'flex' : 'none'),
    margin: 8,
    background: '#fff',
    borderBottomLeftRadius: 24,
    borderTopLeftRadius: 24,
    borderBottomRightRadius: ({ isSearchOpen }) => (isSearchOpen ? undefined : 24),
    borderTopRightRadius: ({ isSearchOpen }) => (isSearchOpen ? undefined : 24),
    border: ({ isSearchOpen }) => (isSearchOpen ? `1px solid ${theme.palette.color.contrast}` : 'none'),
    boxShadow: ({ isSearchOpen }) =>
      isSearchOpen
        ? '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);'
        : 'none',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  }
}));

const MobileSearch = ({ searchText, onSearch, open, isSearchOpen, onClearSearch, setIsSearchOpen, ...rest }) => {
  const cl = useMobileSearchStyles({ isMenuOpen: open, isSearchOpen });
  return (
    <Box className={cl.wrapper}>
      <SpeedDialAction
        {...rest}
        open={open}
        sx={{ m: 0 }}
        classes={{ fab: cl.fab }}
        key="search"
        title="Wyszukaj"
        icon={
          searchText.length > 0 ? (
            <Badge badgeContent="!">
              <SearchIcon sx={{ color: (theme) => theme.palette.color.color22 }} />
            </Badge>
          ) : (
            <SearchIcon sx={{ color: (theme) => theme.palette.color.color22 }} />
          )
        }
        onClick={() => {
          setIsSearchOpen((prev) => !prev);
        }}
      />
      {isSearchOpen && (
        <Slide in={isSearchOpen} direction="left">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', pr: 1 }}>
            <InputBase
              sx={{ ml: 3 }}
              value={searchText}
              onChange={(e) => onSearch(e.target.value)}
              placeholder="Wyszukaj"
              inputProps={{ 'aria-label': 'Wyszukaj w dysku wirutalnym' }}
            />
            <IconButtonPipe className={cl.iconButton} onClick={onClearSearch} tooltip="Wyczyść" sx={{ p: 0 }}>
              <ClearIcon />
            </IconButtonPipe>
          </Box>
        </Slide>
      )}
    </Box>
  );
};

MobileSearch.propTypes = {
  isSearchOpen: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  open: PropTypes.bool,
  searchText: PropTypes.string.isRequired,
  setIsSearchOpen: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired
};

export default MobileSearch;
