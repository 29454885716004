import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';
import { MenuItem, ListItemText, Checkbox, ListItemIcon, Box } from '@mui/material';
import SelectField from '../../../common/SelectField';
import { useSelector } from 'react-redux';
import userRoles from '../../../../constants/userRoles';

const ManyGroupsSelector = ({ groups, onChange, errorText }) => {
  const user = useSelector((state) => state.auth);
  const teacherAllGroupVisibility = useSelector((state) => state.configuration.unit.teacherAllGroupVisibility);

  const filteredGroups = useMemo(() => {
    if (user.userRole !== userRoles.staffMemberTeacher) {
      return _.tail(groups);
    } else if (teacherAllGroupVisibility) {
      return _.tail(groups);
    }
    return groups;
  }, [user.userRole, teacherAllGroupVisibility, groups]);

  const [selected, setSelected] = useState([]);
  const isAllSelected = filteredGroups.length > 0 && selected.length === filteredGroups.length;

  const handleChange = (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === filteredGroups.length ? [] : filteredGroups);
      onChange({ target: { name: 'groupIds', value: selected.length === filteredGroups.length ? [] : undefined } });
      return;
    }
    setSelected(value);
    onChange({ target: { name: 'groupIds', value: value.map((x) => x.id) } });
  };

  return (
    <Box>
      <FieldsetWrapper legend="Grupy">
        <SelectField
          errorText={errorText}
          contrast
          floatingLabelText="Grupy"
          multiple
          value={selected}
          onChange={handleChange}
          renderValue={(x) =>
            filteredGroups.length > 0 && selected.length === filteredGroups.length
              ? 'Wszystkie'
              : x.map((a) => a.name).join(', ')
          }>
          {(user.userRole !== userRoles.staffMemberTeacher || teacherAllGroupVisibility) && (
            <MenuItem key={0} value="all">
              <ListItemIcon>
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={selected.length > 0 && selected.length < filteredGroups.length}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  filteredGroups.length > 0 && selected.length === filteredGroups.length
                    ? 'Odznacz wszystkie'
                    : 'Wybierz wszystkie (w tym archiwalne)'
                }
              />
            </MenuItem>
          )}
          {filteredGroups.map((option) => (
            <MenuItem key={option.id} value={option}>
              <ListItemIcon>
                <Checkbox checked={selected.some((item) => item.id === option.id)} />
              </ListItemIcon>
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </SelectField>
      </FieldsetWrapper>
    </Box>
  );
};

ManyGroupsSelector.propTypes = {
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ManyGroupsSelector;
