import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as attendanceHistoryActions from '../../actions/attendanceHistoryActions';
import PreviousAttendanceList from './previousJournal/PreviousAttendanceList';
import EmptyState from '../common/EmptyState';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import moment from 'moment';
import ContentWrapper from '../common/contentWrapper';
import * as reportsActions from '../../actions/reportsActions';
import * as digitalDiaryActions from '../../actions/digitalDiaryActions';
import { browserHistory } from 'react-router';
import GroupSwitch from '../common/groups/GroupSwitch';
import { fromTemplate, routePaths } from '../../routePaths';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import { Box, Button, Grid, Paper } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { SearchBar } from '../common/SearchBar';
import { emptyUrlGroup } from '../../constants/emptyUrlGroup';
import userRoles from '../../constants/userRoles';

const PreviousAttendanceJournalPage = (props) => {
  const [searchText, setSearchText] = useState();

  useEffect(() => {
    props.groupId !== emptyUrlGroup && props.actions.loadPreviousAttendanceAsync(props.groupId, props.journalDate);
  }, [props.actions, props.groupId, props.journalDate]);

  const filterAuthorizedGuardians = (guardians) => {
    return guardians.filter(
      (g) =>
        g.isAuthorized &&
        (g.authorizationExpirationDate == null ||
          !moment(g.authorizationExpirationDate).isBefore(moment(props.journalDate).startOf('day')))
    );
  };

  const getJournalWithPupils = (childrenAttendance) => {
    return childrenAttendance
      .map((a) => {
        const { child, pickedUpBy, ...attendance } = a;
        const childDetails = props.pupils.find((c) => c.id === child.id);
        const isArchived = !childDetails;
        return isArchived
          ? Object.assign({}, attendance, child, {
              childId: child.id,
              allGuardians: [{ ...pickedUpBy }],
              isArchived: true
            })
          : Object.assign({}, attendance, child, {
              childId: child.id,
              contract: childDetails.contract,
              allGuardians: [...childDetails.legalGuardians, ...filterAuthorizedGuardians(childDetails.guardians)],
              isArchived: false
            });
      })
      .filter((a) => a !== null)
      .sort((a, b) => {
        return a.lastName.localeCompare(b.lastName, 'pl');
      });
  };

  const handleFilter = (text) => {
    setSearchText(text);
  };

  const handleGroupChange = (group) => {
    props.actions.loadPreviousAttendanceAsync(group.id, props.journalDate);
    props.digitalDiaryEvents.changeChoosenGroup(group);
    browserHistory.push(
      fromTemplate(routePaths.attendanceDailyHistory, [
        group.id,
        props.defaultYear,
        props.defaultMonth,
        props.defaultDay
      ])
    );
  };

  const journalEntries = getJournalWithPupils(props.childrenAttendance);

  const filteredJournalEntries = useMemo(() => {
    if (
      props.groupId === emptyUrlGroup &&
      props.auth.userRole === userRoles.staffMemberTeacher &&
      !props.unit.teacherAllGroupVisibility
    )
      return [];
    if (!!searchText)
      return journalEntries.filter(
        (obj) =>
          (obj.firstName + ' ' + obj.lastName).toLowerCase().includes(searchText.toLowerCase()) ||
          (obj.lastName + ' ' + obj.firstName).toLowerCase().includes(searchText.toLowerCase())
      );
    else return journalEntries;
  }, [journalEntries, props.groupId, searchText]);

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText
          title="Dziennik obecności"
          titleIcon={<AssignmentIcon />}
          additionalInfo={moment(props.journalDate).format('DD.MM.YYYY')}
        />
        <ContentWrapper>
          <Grid container>
            <Grid item xs={12} md={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <GroupSwitch onGroupChange={handleGroupChange} showArchivedGroups={false} />
            </Grid>
            <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button
                variant="outlined"
                aria-label="Wróć"
                onClick={() =>
                  browserHistory.push(
                    fromTemplate(routePaths.attendanceMonthlyHistory, [
                      props.defaultGroupId,
                      props.defaultYear,
                      props.defaultMonth
                    ])
                  )
                }>
                Wróć
              </Button>
              <Button
                variant="contained"
                aria-label="Generuj raport PDF"
                sx={{ whiteSpace: 'nowrap' }}
                disabled={!props.choosenGroup.id}
                onClick={() =>
                  props.reportsActions.generateReportAsync('childrenAttendanceJournal', 'pdf', {
                    endDate: moment(props.journalDate).format('YYYY-MM-DD'),
                    startDate: moment(props.journalDate).format('YYYY-MM-DD'),
                    groupId: props.groupId
                  })
                }>
                Generuj raport PDF
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <SearchBar
                label="Szukaj"
                SearchOnClick={(e) => handleFilter(e.target.value)}
                value={searchText}
                onChange={(e) => handleFilter(e.target.value)}
              />
            </Grid>
          </Grid>
          {props.childrenAttendance.length <= 0 && (
            <EmptyState isPaper contrast message="Do wybranej grupy nie zostały jeszcze przypisane dzieci" />
          )}
          {props.childrenAttendance.length > 0 && journalEntries.length <= 0 && (
            <EmptyState isPaper message="Nie znaleziono dziecka" />
          )}
          <Box sx={{ my: 2 }}>
            <PreviousAttendanceList
              title=""
              journalDate={moment(props.journalDate)}
              journalEntries={filteredJournalEntries}
              onRevertAttendanceStart={props.actions.revertPreviousChildAttendanceStart}
              onRevertAttendanceCancel={props.actions.revertPreviousChildAttendanceCancel}
              onRevertAttendance={props.actions.revertPreviousChildAttendanceAsync}
              onReportAttendanceStart={props.actions.reportPreviousChildAttendanceStart}
              onReportAttendanceCancel={props.actions.reportPreviousChildAttendanceCancel}
              onReportAttendance={props.actions.reportPreviousChildAttendanceAsync}
              ui={props.ui}
            />
          </Box>
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};

PreviousAttendanceJournalPage.propTypes = {
  childrenAttendance: PropTypes.array.isRequired,
  journalDate: PropTypes.string.isRequired,
  defaultYear: PropTypes.string.isRequired,
  defaultDay: PropTypes.string.isRequired,
  defaultMonth: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  pupils: PropTypes.array.isRequired,
  groupId: PropTypes.string,
  actions: PropTypes.object.isRequired,
  reportsActions: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  choosenGroup: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  digitalDiaryEvents: PropTypes.object.isRequired,
  staffMembers: PropTypes.array.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    groups: state.groups,
    groupId: ownProps.params.groupId,
    defaultYear: ownProps.params.year,
    defaultMonth: ownProps.params.month,
    defaultDay: ownProps.params.day,
    eventType: ownProps.params.eventType,
    year: ownProps.params.year,
    month: ownProps.params.month,
    journalDate: `${ownProps.params.year}-${ownProps.params.month}-${ownProps.params.day}`,
    childrenAttendance: state.attendance.childrenAttendance,
    pupils: state.children,
    ui: state.attendanceUi,
    auth: state.auth,
    choosenGroup: state.digitalDiary.choosenGroup,
    staffMembers: state.staffMembers,
    defaultGroupId: ownProps.params.groupId,
    userId: state.auth.userId,
    unit: state.configuration.unit
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(attendanceHistoryActions, dispatch),
    reportsActions: bindActionCreators(reportsActions, dispatch),
    digitalDiaryEvents: bindActionCreators(digitalDiaryActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviousAttendanceJournalPage);
