'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import YearAndMonthOptionsSlider from '../../common/calendar/YearAndMonthOptionsSlider';
import DatePicker from '../../common/DatePicker';
import Dialog from '../../common/Dialog';
import { Box, Button, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

class DuesExportDialog extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: moment().startOf('month'),
      overrideDate: new Date()
    };

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateChange(date) {
    this.setState({ date });
  }

  render() {
    const actions = [
      <LoadingButton
        variant="contained"
        aria-label="Wyślij"
        key={1}
        loading={this.props.inProgress}
        onClick={() =>
          this.props.onSubmit(this.state.date.format('YYYY-MM'), moment(this.state.overrideDate).format('DD/MM/YYYY'))
        }
        disabled={this.props.inProgress}>
        Wyślij
      </LoadingButton>,
      <Button variant="outlinedContrast" aria-label="Anuluj" key={2} onClick={this.props.onClose}>
        Anuluj
      </Button>
    ];

    return (
      <Dialog
        title="Eksport danych"
        modal={false}
        open={this.props.open}
        actions={actions}
        onRequestClose={this.props.onClose}
        overlayClassName="dialog-overlay">
        <Box>
          <Typography>Wybierz okres rozliczeń</Typography>
        </Box>
        <YearAndMonthOptionsSlider
          contrast
          onSelected={(year, month) => {
            const date = moment(`${year}-${month}`, 'YYYY-MM');
            this.handleDateChange(date.startOf('month'));
          }}
          defaultMonth={this.state.date.format('MM')}
          defaultYear={this.state.date.format('YYYY')}
        />
        <Box sx={{ my: 1 }}>
          <Typography>Data wygenerowania pliku</Typography>
        </Box>
        <DatePicker
          name="export"
          floatingLabelText="Wybierz datę"
          value={this.state.overrideDate}
          onChange={(e, date) => this.setState({ overrideDate: date })}
        />
      </Dialog>
    );
  }
}

DuesExportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired
};

export default DuesExportDialog;
