/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { DuesExportSettingsModel } from '../models/configuration/duesExport/DuesExportSettingsModels';

export default function duesExportSettingsReducer(state = initialState.configuration.duesExportSettings, action) {
  switch (action.type) {
    case types.LOAD_DUES_EXPORT_SETTINGS_SUCCESS: {
      return new DuesExportSettingsModel().assign({ ...action.configuration });
    }
    case types.UPDATE_DUES_EXPORT_SETTINGS_SUCCESS: {
      return new DuesExportSettingsModel().assign({ ...action.configuration });
    }
    default:
      return state;
  }
}
