/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function activityDetailsUiReducer(state = initialState.activityDetailsUi, action) {
  switch (action.type) {
    //create
    case types.CREATE_ACTIVITY_DETAILS: {
      return Object.assign({}, state, { details: UiModel.start() });
    }
    case types.CREATE_ACTIVITY_DETAILS_CANCEL: {
      return Object.assign({}, state, { details: UiModel.cancel() });
    }
    case types.CREATE_ACTIVITY_DETAILS_SUBMIT: {
      return Object.assign({}, state, { details: UiModel.submit() });
    }
    case types.CREATE_ACTIVITY_DETAILS_SUCCESS: {
      return Object.assign({}, state, { details: UiModel.success() });
    }
    case types.CREATE_ACTIVITY_DETAILS_FAILURE: {
      return Object.assign({}, state, { details: UiModel.failure(action.errors) });
    }
    //update
    case types.UPDATE_ACTIVITY_DETAILS: {
      return Object.assign({}, state, { details: UiModel.start() });
    }
    case types.UPDATE_ACTIVITY_DETAILS_CANCEL: {
      return Object.assign({}, state, { details: UiModel.cancel() });
    }
    case types.UPDATE_ACTIVITY_DETAILS_SUBMIT: {
      return Object.assign({}, state, { details: UiModel.submit() });
    }
    case types.UPDATE_ACTIVITY_DETAILS_SUCCESS: {
      return Object.assign({}, state, { details: UiModel.success() });
    }
    case types.UPDATE_ACTIVITY_DETAILS_FAILURE: {
      return Object.assign({}, state, { details: UiModel.failure(action.errors) });
    }
    //enrolled
    case types.ENROLL_CHILDREN_TO_ACTIVITY: {
      return Object.assign({}, state, { enrolledChildren: UiModel.start() });
    }
    case types.ENROLL_CHILDREN_TO_ACTIVITY_CANCEL: {
      return Object.assign({}, state, { enrolledChildren: UiModel.cancel() });
    }
    case types.ENROLL_CHILDREN_TO_ACTIVITY_SUBMIT: {
      return Object.assign({}, state, { enrolledChildren: UiModel.submit() });
    }
    case types.ENROLL_CHILDREN_TO_ACTIVITY_SUCCESS: {
      return Object.assign({}, state, { enrolledChildren: UiModel.success() });
    }
    case types.ENROLL_CHILDREN_TO_ACTIVITY_FAILURE: {
      return Object.assign({}, state, { enrolledChildren: UiModel.failure(action.errors) });
    }
    //associated
    // case types.ASSOCIATE_TEACHER_TO_ACTIVITY: {
    //   return Object.assign({}, state, {associatedTeachers: UiModel.start()});
    // }
    // case types.ASSOCIATE_TEACHER_TO_ACTIVITY_CANCEL: {
    //   return Object.assign({}, state, {associatedTeachers: UiModel.cancel()});
    // }
    // case types.ASSOCIATE_TEACHER_TO_ACTIVITY_SUBMIT: {
    //   return Object.assign({}, state, {associatedTeachers: UiModel.submit()});
    // }
    // case types.ASSOCIATE_TEACHER_TO_ACTIVITY_SUCCESS: {
    //   return Object.assign({}, state, {associatedTeachers: UiModel.success()});
    // }
    // case types.ASSOCIATE_TEACHER_TO_ACTIVITY_FAILURE: {
    //   return Object.assign({}, state, {associatedTeachers: UiModel.failure(action.errors)});
    // }
    //
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.activityDetailsUi);
    }
    default:
      return state;
  }
}
