/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { RoutingModel } from '../models/navigation/RoutingModel';

export default function routingReducer(state = initialState.routing, action) {
  switch (action.type) {
    case types.NAVIGATE_REQUEST: {
      return {
        current: new RoutingModel().assign({
          url: action.nextUrl,
          context: action.nextContext,
          matchedRoute: action.matchedRoute || action.nextUrl,
          isPopAction: action.nextActionName === 'POP',
          params: action.nextParams
        }),
        previous: state.current
      };
    }
    default:
      return state;
  }
}
