import * as types from './actionTypes';
// import * as logger from '../utils/logger';
import { getSocketService } from '../middleware/socket/socketListenerMiddleware';

export function loadCurrentUsersAsync() {
  return (dispatch) => {
    dispatch({ type: types.LOAD_CURRENT_USERS });
    return getSocketService().getMyList((err, currentUsers) => {
      dispatch({ type: types.LOAD_CURRENT_USERS_SUCCESS, currentUsers });
    });
  };
}
