import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../forms/DialogForm';
import Checkbox from '../common/Checkbox';

class ArchiveChildDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { deleteLegalGuardians: false };

    this.handleChanged = this.handleChanged.bind(this);
  }

  handleChanged(value) {
    this.setState({ deleteLegalGuardians: value });
  }

  render() {
    const { onArchive, onCancel, isDialogOpen, isProcessing } = this.props;
    return (
      <DialogForm
        maxWidth="sm"
        header={`Archiwizowanie dziecka - ${this.props.pupil.firstName} ${this.props.pupil.lastName}`}
        onSave={() => onArchive(this.state.deleteLegalGuardians)}
        onCancel={onCancel}
        saveLabel="Archiwizuj"
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        noValidate>
        <Checkbox
          label="Archiwizuj także opiekunów prawnych"
          defaultChecked={this.state.deleteLegalGuardians}
          onCheck={(event, isChecked) => this.handleChanged(isChecked)}
        />
      </DialogForm>
    );
  }
}

ArchiveChildDialog.propTypes = {
  onArchive: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  pupil: PropTypes.object.isRequired
};

export default ArchiveChildDialog;
