import PropTypes from 'prop-types';
import React from 'react';
import WelcomeScreen from '../../common/welcomeScreen/WelcomeScreen';
import ResetPasswordContainer from './Containers/ResetPasswordContainer';

const ResetPasswordPage = ({ location }) => {
  return (
    <WelcomeScreen>
      <ResetPasswordContainer location={location} />
    </WelcomeScreen>
  );
};

ResetPasswordPage.propTypes = {
  location: PropTypes.object.isRequired
};
export default ResetPasswordPage;
