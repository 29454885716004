export const ReportsWithCategories = [
  {
    id: 0,
    title: 'Wszystkie',
    category: 'all',
    reports: [
      'kindergartenActivityLog',
      'digitalDiaryWorkPlan',
      'digitalDiaryDaySchedule',
      'digitalDiaryWorkWithParents',
      'digitalDiaryAdditionalActivities',
      'digitalDiarySaveWork',
      'childrenAttendanceJournal',
      'childrenSummaryPerGroup',
      'childrenList',
      'childrenActivities',
      'childrenAbsenceInDays',
      'childrenAbsenceInDaysXls',
      'childrenAttendanceInDays',
      'childrenAttendanceInDaysXls',
      'childrenAttendanceInHours',
      'childrenAttendanceInHoursXls',
      'childrenAttendanceInHoursDuesXls',
      'childrenPlannedAttendances',
      'childrenAbsenceReportedAtDetails',
      'childrenAttendanceInHourIntervalsMonth',
      'childrenOpinionsSummary',
      'childrenDiagnosesReport',
      'childrenOpinionReport',
      'childrenAverageAttendanceInHourIntervals',
      'childrenAttributeSetValues',
      'childrenAttributeSetValuesSummary',
      'schoolYearInauguration',
      'groupAttendanceDailySummary',
      'groupActivitiesAttendanceDailySummary',
      'paymentsSummaryPerChild',
      'simplifiedPaymentsSummaryPerChild',
      'paymentsSummary',
      'reliefsSummary',
      'reliefsByChildren',
      'detailedPaymentsSummaryPerChild',
      'duesOtherDetailsOverall',
      'duesDetailsOverall',
      'duesDetailsXls',
      'duesStayDetailsOverall',
      'duesCateringDetailsOverall',
      'duesActivitiesDetailsOverall',
      'attributeSetAttendanceReport',
      'stayDuesPayments',
      'cateringDuesPayments',
      'boardPostsReport',
      'legalGuardianDebts',
      'childrenAttendanceInDaysByGroup',
      'canteenSummaryReport',
      'canteenSummaryByMealsAtDayReport',
      'listOfManDaysReport',
      'staySettlementsDetailsFromReaderReport',
      'cateringSettlementsDetailsFromReaderReport',
      'listOfManDaysReport',
      'listOfArrearsForMonthReport',
      'listOfTransfersReport',
      'statementOfInterestReport',
      'statementOfOverpaymentsReport',
      'listOfFeesPaidReport',
      'calendarEventsListReport',
      'dailyAbsenceOfChildrenReport',
      'reportedAbsencesByTheHourOfPreviousDayReport',
      'statementOfAccrualsForMonthReport',
      'statementOfSettlementsForMonthReport',
      'statementOfExpectedInterestAmountForDayReport',
      'kindergartenActivityLog',
      'psychologicalPedagogicalIndividualClassesTimetable',
      'deductionAndSettlementOfPaymentForStayAndCatering',
      'summaryOfAttendanceAtMealsReport',
      'settlementForTheMonth',
      'paymentAssignment'
    ]
  },
  {
    id: 1,
    title: 'Dziennik elektroniczny',
    category: 'digitalDiary',
    reports: [
      'digitalDiaryWorkPlan',
      'digitalDiaryDaySchedule',
      'digitalDiaryWorkWithParents',
      'digitalDiaryAdditionalActivities',
      'digitalDiarySaveWork',
      'kindergartenActivityLog',
      'psychologicalPedagogicalIndividualClassesTimetable'
    ]
  },
  {
    id: 2,
    title: 'Dzieci oraz obecności',
    category: 'childrenWithAttendance',
    reports: [
      'kindergartenActivityLog',
      'childrenAttendanceJournal',
      'childrenSummaryPerGroup',
      'childrenList',
      'childrenActivities',
      'childrenAbsenceInDays',
      'childrenAbsenceInDaysXls',
      'childrenAttendanceInDays',
      'childrenAttendanceInDaysXls',
      'childrenAttendanceInHours',
      'childrenAttendanceInHoursXls',
      'childrenAttendanceInHoursDuesXls',
      'childrenPlannedAttendances',
      'childrenAbsenceReportedAtDetails',
      'childrenAttendanceInHourIntervalsMonth',
      'childrenOpinionsSummary',
      'childrenDiagnosesReport',
      'childrenOpinionReport',
      'childrenAverageAttendanceInHourIntervals',
      'childrenAttributeSetValues',
      'childrenAttributeSetValuesSummary',
      'schoolYearInauguration',
      'childrenAttendanceInDaysByGroup',
      'listOfManDaysReport',
      'dailyAbsenceOfChildrenReport',
      'reportedAbsencesByTheHourOfPreviousDayReport',
      'summaryOfAttendanceAtMealsReport'
    ]
  },
  {
    id: 3,
    title: 'Grupy',
    category: 'groups',
    reports: ['groupAttendanceDailySummary', 'groupActivitiesAttendanceDailySummary']
  },
  {
    id: 4,
    title: 'Płatności',
    category: 'payments',
    reports: [
      'paymentsSummaryPerChild',
      'paymentsSummary',
      'reliefsSummary',
      'reliefsByChildren',
      'detailedPaymentsSummaryPerChild',
      'simplifiedPaymentsSummaryPerChild',
      'duesOtherDetailsOverall',
      'duesDetailsOverall',
      'duesDetailsXls',
      'duesStayDetailsOverall',
      'duesCateringDetailsOverall',
      'duesActivitiesDetailsOverall',
      'attributeSetAttendanceReport',
      'stayDuesPayments',
      'cateringDuesPayments',
      'listOfArrearsForMonthReport',
      'listOfTransfersReport',
      'statementOfInterestReport',
      'statementOfOverpaymentsReport',
      'staySettlementsDetailsFromReaderReport',
      'cateringSettlementsDetailsFromReaderReport',
      'listOfFeesPaidReport',
      'statementOfAccrualsForMonthReport',
      'statementOfSettlementsForMonthReport',
      'deductionAndSettlementOfPaymentForStayAndCatering',
      'summaryOfAttendanceAtMealsReport',
      'settlementForTheMonth',
      'paymentAssignment'
    ]
  },
  {
    id: 5,
    title: 'Pozostałe raporty',
    category: 'other',
    reports: [
      'boardPostsReport',
      'legalGuardianDebts',
      'canteenSummaryReport',
      'canteenSummaryByMealsAtDayReport',
      'calendarEventsListReport'
    ]
  }
];
