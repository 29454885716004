import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../actions/childrenActions';
import DialogForm from '../../forms/DialogForm';
import { Typography } from '@mui/material';

const RemoveTakenOverByDebtCollectionChildrenConfirmationDialog = () => {
  const dispatch = useDispatch();
  const { childId, isEditing, isProcessing } = useSelector((state) => ({
    childId: state.childDetails.id,
    isEditing: state.childDetailsUi.removeDebtCollection.isEditing,
    isProcessing: state.childDetailsUi.removeDebtCollection.isProcessing
  }));

  const handleSave = () => {
    dispatch(actions.removeTakenOverByDebtCollectionChildren([childId]));
  };

  const handleCancel = () => {
    dispatch(actions.removeTakenOverByDebtCollectionChildrenCancel());
  };

  return (
    <DialogForm
      maxWidth="sm"
      header="Anulowanie statusu windykacji dla dziecka"
      onSave={handleSave}
      onCancel={handleCancel}
      isDialogOpen={isEditing}
      isProcessing={isProcessing}
      noValidate={true}>
      <Typography>Uwaga, czy na pewno chcesz anulować status windykacji dla danego dziecka?</Typography>
    </DialogForm>
  );
};

export default RemoveTakenOverByDebtCollectionChildrenConfirmationDialog;
