import BaseModel from '../BaseModel';

export class StaffChargingScheme extends BaseModel {
  constructor() {
    super();
    this.schemeId = null;
    this.singleUse = false;
    this.reliefId = null;
    this.reliefExpiryDate = null;
  }
}

export class Contract extends BaseModel {
  constructor() {
    super();
    this.schemes = [];
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.schemes = assigned.schemes.map((s) => new StaffChargingScheme().assign(s));
  }
}

export class StaffMemberModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.role = '';
    this.groups = [];
    this.photoUrl = '';
    this.groups = '';
    this.personalId = '';
  }
}

export class StaffMemberDetailsModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.role = '';
    this.groups = [];
    this.personalId = '';
    this.photoUrl = '';
    this.groups = '';
    this.contract = new Contract();
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.contract = assigned.contract ? new Contract().assign(assigned.contract) : null;
  }
}
