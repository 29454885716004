import { Box } from '@mui/system';
import React, { useMemo, useState } from 'react';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import { SearchBar } from '../common/SearchBar';
import PsychologicalTable from './PsychologicalTable';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PsychologicalHeader from './PsychologicalHeader';
import PsychologicalDialog from './dialog/PsychologicalDialog';
import { useDispatch, useSelector } from 'react-redux';
import { cancelAddingPsychologicalHelp, startAddingPsychologicalHelp } from '../../actions/psychologicalActions';

const PsychologicalHelpContainer = () => {
  const dispatch = useDispatch();
  const numberArrayOfOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(numberArrayOfOptions[0]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const isDialogOpen = useSelector((state) => state.psychologicalChildrenListUI.isEditing);
  const psychologicalList = useSelector((state) => state.psychologicalChildrenList);
  const [selectedHelp, setselectedHelp] = useState();
  const childrenList = useSelector((state) => state.children);

  const psychologicalChildrenList = useMemo(() => {
    return psychologicalList
      .filter((help) => {
        let childrenIds = childrenList.map((child) => child.id);
        if (childrenIds.includes(help.childId)) {
          return true;
        } else {
          return false;
        }
      })
      .map((help) => {
        let childData = childrenList.find((child) => child.id === help.childId);
        help.childFirstName = childData.firstName;
        help.childLastName = childData.lastName;
        return help;
      });
  }, [childrenList, psychologicalList]);

  const filteredData = useMemo(() => {
    return psychologicalChildrenList
      .filter(
        (child) =>
          (child.childFirstName + ' ' + child.childLastName).toLowerCase().includes(searchText.toLowerCase()) ||
          (child.childLastName + ' ' + child.childFirstName).toLowerCase().includes(searchText.toLowerCase())
      )
      .sort((a, b) => (a.childFirstName.toLowerCase() > b.childFirstName.toLowerCase() ? 1 : -1));
  }, [psychologicalChildrenList, searchText]);

  const handleChangePage = (event, newValue) => {
    setPage(newValue - 1);
  };

  const renderDialog = () => {
    return (
      isDialogOpen && (
        <PsychologicalDialog
          setDialogOpen={() => {
            dispatch(cancelAddingPsychologicalHelp());
          }}
          open={isDialogOpen}
          selectedHelp={selectedHelp}
        />
      )
    );
  };

  const handleOpenEditDialog = (child) => {
    setselectedHelp(child);
    dispatch(startAddingPsychologicalHelp());
  };

  return (
    <>
      <PageHeaderText title="Pomoc psychologiczno-pedagogiczna" titleIcon={<AssignmentIndIcon />} />
      <PsychologicalHeader
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        numberArrayOfOptions={numberArrayOfOptions}
        setDialogOpen={() => {
          setselectedHelp();
          dispatch(startAddingPsychologicalHelp());
        }}
      />
      <Box sx={{ display: 'flex' }}>
        <SearchBar
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          value={searchText}
          label="Wyszukaj"
        />
      </Box>
      <Box>
        <PsychologicalTable
          page={page}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          childrenToHelp={filteredData}
          openEditDialog={handleOpenEditDialog}
        />
      </Box>
      {renderDialog()}
    </>
  );
};

export default PsychologicalHelpContainer;
