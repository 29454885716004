import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WorkPlanIcon from '@mui/icons-material/AccessTime';
import TopicIcon from '@mui/icons-material/School';
import GoalsIcon from '@mui/icons-material/Poll';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import YearAndMonthOptionsSlider from '../../common/calendar/YearAndMonthOptionsSlider';
import moment from 'moment';
import * as workPlanActions from '../../../actions/digitalDiaryWorkPlanActions';
import { GoalModel, TopicModel } from '../../../models/digitalDiary/workPlan/WorkPlanModel';
import WorkPlanEducationalDialog from './dialogs/WorkPlanEducationalDialog';
import WorkPlanTopicDialog from './dialogs/WorkPlanTopicDialog';
import WorkPlanListv2 from './WorkPlanListv2';
import WorkPlanTopicList from './WorkPlanTopicList';
import * as digitalDiaryActions from '../../../actions/digitalDiaryActions';
import userRoles from '../../../constants/userRoles';
import ReportsIcon from '@mui/icons-material/InsertChart';
import { browserHistory } from 'react-router';
import { fromTemplate, routePaths } from '../../../routePaths';
import { eventTypes } from '../../../constants/eventTypes';
import GroupSwitch from '../../common/groups/GroupSwitch';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import { Box, Button, Grid, Typography, Paper } from '@mui/material';
import ContentWrapper from '../../common/contentWrapper';
import EmptyState from '../../common/EmptyState';
import ErrorAlertBox from '../../common/ErrorAlertBox';

class WorkPlanPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workPlanSetGoal: new GoalModel(),
      workPlanChoosenId: null,
      workPlanSetTopic: new TopicModel(),
      isEducationalEditing: true,
      month: this.props.month,
      year: this.props.year,
      showAlert: this.props.workPlan.content.approved
    };
    this.handleCreate = this.handleCreate.bind(this);
    this.handleCreateTopic = this.handleCreateTopic.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleUpdateTopic = this.handleUpdateTopic.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSaveWorkPlan = this.handleSaveWorkPlan.bind(this);
    this.handleApproveWorkPlan = this.handleApproveWorkPlan.bind(this);
    this.handleCancelApprovmentWorkPlan = this.handleCancelApprovmentWorkPlan.bind(this);
    this.handleGenerateReport = this.handleGenerateReport.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workPlan.content.approved !== this.props.workPlan.content.approved)
      this.setState({ showAlert: this.props.workPlan.content.approved });
  }

  handleCreate() {
    this.setState(
      {
        workPlanSetGoal: new GoalModel(),
        isEducationalEditing: true,
        workPlanChoosenId: null
      },
      () => this.props.actions.addWorkPlanSetStart()
    );
  }

  handleCreateTopic() {
    this.setState(
      {
        workPlanSetTopic: new TopicModel(),
        isEducationalEditing: false,
        workPlanChoosenId: null
      },
      () => this.props.actions.addWorkPlanSetStart()
    );
  }

  handleUpdate(set, i) {
    this.setState(
      {
        workPlanSetGoal: set,
        isEducationalEditing: true,
        workPlanChoosenId: i
      },
      () => this.props.actions.updateWorkPlanSetStart()
    );
  }

  handleUpdateTopic(set, i) {
    this.setState(
      {
        workPlanSetTopic: set,
        isEducationalEditing: false,
        workPlanChoosenId: i
      },
      () => this.props.actions.updateWorkPlanSetStart()
    );
  }

  handleSave(set) {
    if (this.state.workPlanChoosenId !== null) {
      this.props.actions.updateWorkPlanEducationalSet({
        newGoal: set,
        id: this.state.workPlanChoosenId
      });
    } else {
      this.props.actions.addWorkPlanEducationalSet(set);
    }
  }

  handleTopicSave(set) {
    if (this.state.workPlanChoosenId !== null) {
      this.props.actions.updateWorkPlanTopicSet({
        newTopic: set,
        id: this.state.workPlanChoosenId
      });
    } else {
      this.props.actions.addWorkPlanTopicSet(set);
    }
  }

  handleDateChange(date) {
    const d = moment(date);
    this.setState({ month: d.format('MM'), year: d.format('YYYY') }, () =>
      this.props.actions.loadWorkPlanSetStart({
        group: this.props.choosenGroup.id,
        eventDate: `${this.state.year}-${this.state.month}`
      })
    );
    browserHistory.push(
      fromTemplate(`/dziennik-elektroniczny/${eventTypes.workPlan.path}/:year/:month`, [
        d.format('YYYY'),
        d.format('MM')
      ])
    );
  }

  handleGroupChange(group) {
    this.props.digitalDiaryEvents.changeChoosenGroup(group);
    this.props.actions.loadWorkPlanSetStart({
      group: group.id,
      eventDate: `${this.state.year}-${this.state.month}`
    });
  }

  handleSaveWorkPlan() {
    if (this.props.workPlan.id) {
      this.props.actions.updateWorkPlanData(this.props.workPlan);
    } else {
      this.props.actions.saveWorkPlanData({
        ...this.props.workPlan,
        group: this.props.choosenGroup.id,
        eventDate: `${this.state.year}-${this.state.month}-01`,
        approved: false,
        approvedBy: ''
      });
    }
  }

  handleApproveWorkPlan() {
    this.props.actions.updateWorkPlanData({
      ...this.props.workPlan,
      content: { ...this.props.workPlan.content, approved: true, approvedBy: this.props.auth.userFullName }
    });
  }

  handleCancelApprovmentWorkPlan() {
    this.props.actions.updateWorkPlanData({
      ...this.props.workPlan,
      content: { ...this.props.workPlan.content, approved: false, approvedBy: '' }
    });
  }

  handleGenerateReport() {
    browserHistory.push(fromTemplate(routePaths.reports));
  }

  renderWorkPlanEducationalSetDialog() {
    if (this.props.ui.isEditing && this.state.isEducationalEditing && !this.props.workPlan.content.approved) {
      return (
        <WorkPlanEducationalDialog
          workPlanSetGoal={this.state.workPlanSetGoal}
          onCancel={() => this.props.actions.addWorkPlanSetCancel()}
          onSave={(set) => this.handleSave(set)}
          isProcessing={this.props.ui.isProcessing}
          isDialogOpen={this.props.ui.isEditing}
          isEditing={false}
        />
      );
    }
    return null;
  }

  renderWorkPlanTopicSetDialog() {
    if (this.props.ui.isEditing && !this.state.isEducationalEditing && !this.props.workPlan.content.approved) {
      return (
        <WorkPlanTopicDialog
          workPlanSetTopic={this.state.workPlanSetTopic}
          onCancel={() => this.props.actions.addWorkPlanSetCancel()}
          onSave={(set) => this.handleTopicSave(set)}
          isProcessing={this.props.ui.isProcessing}
          isDialogOpen={this.props.ui.isEditing}
          isEditing={false}
        />
      );
    }
    return null;
  }

  renderApproveButton() {
    if (!this.props.workPlan.content.approved) {
      return (
        <Button
          variant="outlined"
          aria-label="Zatwierdź"
          disabled={
            (!(this.props.workPlan.content.goals.length !== 0 || this.props.workPlan.content.topics.length !== 0) &&
              !this.props.workPlan.id) ||
            this.props.archivedGroups.some((group) => group.id === this.props.choosenGroup.id)
          }
          onClick={this.handleApproveWorkPlan}>
          Zatwierdź
        </Button>
      );
    }
    return (
      <Button
        variant="outlined"
        aria-label="Wycofaj zatwierdzenie"
        disabled={
          (!(this.props.workPlan.content.goals.length !== 0 || this.props.workPlan.content.topics.length !== 0) &&
            !this.props.workPlan.id) ||
          this.props.archivedGroups.some((group) => group.id === this.props.choosenGroup.id)
        }
        onClick={this.handleCancelApprovmentWorkPlan}>
        Wycofaj
      </Button>
    );
  }

  renderSubmitButton() {
    return !this.props.workPlan.content.approved ? (
      <Button
        variant="contained"
        aria-label="Zapisz"
        sx={{
          ml: 1
        }}
        disabled={
          !(this.props.workPlan.content.goals.length !== 0 || this.props.workPlan.content.topics.length !== 0) &&
          !this.props.workPlan.id
        }
        onClick={this.handleSaveWorkPlan}>
        Zapisz
      </Button>
    ) : null;
  }

  renderGeneratePdfButton() {
    return this.props.workPlan.content.approved ? (
      <Button
        variant="contained"
        aria-label="Generuj raport PDF"
        sx={{ ml: 1 }}
        onClick={this.handleGenerateReport}
        startIcon={<ReportsIcon />}>
        Generuj (.pdf)
      </Button>
    ) : null;
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText title="Plan pracy" titleIcon={<WorkPlanIcon />} />
          <ContentWrapper>
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: 'flex',
                  justifyContent: {
                    xs: 'center',
                    sm: 'flex-start'
                  }
                }}>
                <YearAndMonthOptionsSlider
                  onSelected={(nextYear, nextMonth) => {
                    this.handleDateChange(moment(new Date(nextYear, nextMonth - 1, 1)));
                  }}
                  defaultMonth={this.state.month}
                  defaultYear={this.state.year}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  mb: 2,
                  display: 'flex',
                  justifyContent: {
                    xs: 'center',
                    sm: 'flex-end'
                  }
                }}>
                <GroupSwitch onGroupChange={this.handleGroupChange} showArchivedGroups={true} />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <ErrorAlertBox
                  severity="warning"
                  message={`Widoczny Plan Pracy został zatwierdzony${
                    this.props.auth.isStaffMember(userRoles.staffMemberPrincipal)
                      ? '. Wycofaj go, aby przywrócić możliwość edycji.'
                      : ' przez dyrektora placówki. Nie podlega on edycji.'
                  }`}
                  state={this.state.showAlert}
                  onClose={() => this.setState({ showAlert: false })}
                />
              </Grid>

              <Grid item xs={12} sm={8} sx={{ display: 'flex', alignItems: 'center' }}>
                <GoalsIcon
                  fontSize="large"
                  sx={{
                    mr: 2,
                    display: 'flex',
                    alignContent: 'center',
                    color: (theme) => theme.palette.color.primary
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: (theme) => theme.palette.color.primary,
                    fontWeight: (theme) => theme.typography.fontWeightBold,
                    display: 'inline-flex',
                    textAlign: 'start'
                  }}>
                  Zamierzenia wychowawczo-dydaktyczne
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {!this.props.workPlan.content.approved ? (
                  <Button
                    variant="contained"
                    aria-label="Dodaj zamierzenie"
                    disabled={this.props.archivedGroups.some((group) => group.id === this.props.choosenGroup.id)}
                    onClick={this.handleCreate}>
                    Dodaj zamierzenie
                  </Button>
                ) : null}
              </Grid>
            </Grid>
            {this.renderWorkPlanEducationalSetDialog()}
            <Paper sx={{ my: 2 }}>
              {this.props.workPlan.content.goals.length > 0 ? (
                <WorkPlanListv2
                  disabled={this.props.workPlan.content.approved}
                  sets={this.props.workPlan.content.goals}
                  onSelected={this.handleUpdate}
                  onRemove={(id) => this.props.actions.removeWorkPlanEducationalSet(id)}
                />
              ) : (
                <Box sx={{ py: 4 }}>
                  <EmptyState message="Nie zdefiniowano jeszcze żadnych zamierzeń wychowawczo-dydaktycznych" contrast />
                </Box>
              )}
            </Paper>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 4
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <TopicIcon
                  fontSize="large"
                  sx={{
                    mr: 2,
                    display: 'flex',
                    alignContent: 'center',
                    color: (theme) => theme.palette.color.primary
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: (theme) => theme.palette.color.primary,
                    fontWeight: (theme) => theme.typography.fontWeightBold,
                    display: 'inline-flex',
                    textAlign: 'center'
                  }}>
                  Tematy
                </Typography>
              </Box>
              {!this.props.workPlan.content.approved ? (
                <Button
                  variant="contained"
                  aria-label="Dodaj temat"
                  disabled={this.props.archivedGroups.some((group) => group.id === this.props.choosenGroup.id)}
                  onClick={this.handleCreateTopic}>
                  Dodaj temat
                </Button>
              ) : null}
            </Box>
            <Paper sx={{ my: 2 }}>
              {this.renderWorkPlanTopicSetDialog()}
              {this.props.workPlan.content.topics.length > 0 ? (
                <WorkPlanTopicList
                  isApproved={this.props.workPlan.content.approved}
                  sets={this.props.workPlan.content.topics}
                  onSelected={this.handleUpdateTopic}
                  onRemove={(id) => this.props.actions.removeWorkPlanTopicSet(id)}
                />
              ) : (
                <Box sx={{ py: 4 }}>
                  <EmptyState message="Nie zdefiniowano jeszcze żadnych tematów" contrast />
                </Box>
              )}
            </Paper>
            <Box
              sx={{
                my: 2,
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              {this.props.auth.isStaffMember(userRoles.staffMemberPrincipal) ? this.renderApproveButton() : null}
              {this.renderSubmitButton()}
              {this.renderGeneratePdfButton()}
            </Box>
          </ContentWrapper>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

WorkPlanPage.propTypes = {
  groups: PropTypes.array.isRequired,
  workPlan: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  choosenGroup: PropTypes.object.isRequired,
  digitalDiaryEvents: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  staffMembers: PropTypes.array.isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  archivedGroups: PropTypes.array.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth,
    groups: state.groups,
    workPlan: state.digitalDiary.workPlan,
    ui: state.digitalDiary.workPlanUi,
    year: ownProps.params.year,
    month: ownProps.params.month,
    choosenGroup: state.digitalDiary.choosenGroup,
    staffMembers: state.staffMembers,
    archivedGroups: state.archives.groups
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(workPlanActions, dispatch),
    digitalDiaryEvents: bindActionCreators(digitalDiaryActions, dispatch) //For group change
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkPlanPage);
