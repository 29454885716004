import { useSelector } from 'react-redux';
import React from 'react';
import StaffDashboardPage from './staffMember/StaffDashboardPage';
import CanteenWorkerDashboardPage from './staffMember/CanteenWorkerDashboardPage';
import DigitalDiaryTeacherDashboardPage from './staffMember/DigitalDiaryTeacherDashboardPage';
import TeacherDashboardPage from './staffMember/TeacherDashboardPage';
import GuardianDashboardPage from './legalGuardian/GuardianDashboardPage';
import SecretaryDashboardPage from './staffMember/SecretaryDashboardPage';
import userRoles from '../../constants/userRoles';
import ReducedTeacherDashboardPage from './staffMember/ReducedTeacherDashboardPage';
import ReducedStaffDashboardPage from './staffMember/ReducedStaffDashboardPage';
import ReducedGuardianDashboardPage from './legalGuardian/ReducedGuardianDashboardPage';

const NavigationDrawer = () => {
  const auth = useSelector((state) => state.auth);
  const nadarzyn = useSelector((state) => state.configuration.unit.features.nadarzyn);
  if (auth.isStaffMember(userRoles.staffMemberCanteenWorker)) return <CanteenWorkerDashboardPage />;
  if (auth.isStaffMember(userRoles.staffMemberTeacherSupport)) return <TeacherDashboardPage />;
  if (auth.isStaffMember(userRoles.staffMemberTeacher)) {
    if (nadarzyn) return <ReducedTeacherDashboardPage />;
    return <TeacherDashboardPage />;
  }
  if (auth.isStaffMember(userRoles.staffMemberSecretary)) return <SecretaryDashboardPage />;
  if (auth.isStaffMember(userRoles.staffMemberDigitalDiary)) return <DigitalDiaryTeacherDashboardPage />;
  if (auth.isStaffMember()) {
    if (nadarzyn) return <ReducedStaffDashboardPage />;
    return <StaffDashboardPage />;
  }
  if (auth.isLegalGuardian()) {
    if (nadarzyn) return <ReducedGuardianDashboardPage />;
    return <GuardianDashboardPage />;
  }
  return null;
};

export default NavigationDrawer;
