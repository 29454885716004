import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPscyhologicalHelp,
  cancelAddingPsychologicalHelp,
  editPscyhologicalHelp
} from '../../../actions/psychologicalActions';
import DialogForm from '../../forms/DialogForm';
import { PsychologicalChildValidator } from '../PsychologicalChildValidator';
import PsychologicalHelpForm from './PsychologicalHelpForm';

const PsychologicalDialog = ({ open, setDialogOpen, selectedHelp }) => {
  const [errors, setErrors] = useState();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const UI = useSelector((state) => state.psychologicalChildrenListUI);

  const handleCancel = () => {
    dispatch(cancelAddingPsychologicalHelp());
  };

  const handleSave = () => {
    setDisabled(true);
    selectedHelp ? dispatch(editPscyhologicalHelp(data)) : dispatch(addPscyhologicalHelp(data));
  };

  return (
    <DialogForm
      header="Dodaj dziecko"
      maxWidth="md"
      onSave={handleSave}
      onCancel={handleCancel}
      isDialogOpen={UI.isEditing}
      saveDisabled={disabled}
      isProcessing={UI.isProcessing}
      onValidate={() => new PsychologicalChildValidator().validate(data)}
      onValidationDone={(errors) => setErrors({ errors })}
      statePathToUi=""
      requiredFieldText>
      <PsychologicalHelpForm selectedHelp={selectedHelp || []} setData={setData} errors={errors} />
    </DialogForm>
  );
};

export default PsychologicalDialog;
