import PropTypes from 'prop-types';
import React, { useState } from 'react';
import MenuRoute from './MenuRoute';
import { routePaths } from '../../../routePaths';
import { List, ListItem, ListItemButton, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import insuranceLogoUrl from '../../../img/logo_b_1280.svg';
import insuranceLogoWhiteUrl from '../../../img/logo_b_1280_white.svg';
import InsuranceInfoDialog from '../../insuranceInfo/InsuranceInfoDialog';
import moment from 'moment';
import { useSelector } from 'react-redux';

import HomeIcon from '@mui/icons-material/HomeOutlined';
import BookIcon from '@mui/icons-material/Book';
import AssignmentIndIcon from '@mui/icons-material/PermContactCalendarOutlined';
import MessageBoardIcon from '@mui/icons-material/SmsFailedOutlined';
import EventsCalendarIcon from '@mui/icons-material/Event';
import ReportsIcon from '@mui/icons-material/InsertChartOutlined';
import ChangeLogIcon from '@mui/icons-material/VerifiedOutlined';
import StorageIcon from '@mui/icons-material/SaveOutlined';
import TimeTable from '@mui/icons-material/DateRange';
import { useThemeMode } from '../../../hooks/useThemeMode';
import { themeTypes } from '../../../styles/appTheme';

const CanteenWorkerMenu = ({ currentRoute, onRouteSelect, insurance }) => {
  const themeMode = useThemeMode();
  const [insuranceDialog, setInsuranceDialog] = useState(false);
  const year = moment().format('YYYY');
  const month = moment().format('MM');
  let constKey = 0;
  const getNextKey = () => constKey++;
  const rest = { selectedRoutePath: currentRoute, onClick: onRouteSelect };
  const unreadPostsCount = useSelector((state) => state.staffMessageBoard.unreadedPosts).length;
  const theme = useTheme();
  const isContrastColor = useSelector((state) => state.theme);
  return (
    <List>
      <MenuRoute
        text="Pulpit"
        icon={
          <HomeIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
          />
        }
        routePath={routePaths.home}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        primaryText="Pracownicy"
        icon={
          <AssignmentIndIcon
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color25 }}
          />
        }
        badgeValue={unreadPostsCount}
        primaryTogglesNestedList={true}
        nestedItems={[
          <MenuRoute
            text="Ogłoszenia"
            icon={
              <MessageBoardIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color25 }}
              />
            }
            badgeValue={unreadPostsCount}
            routePath={routePaths.staffMessageBoard}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Kalendarz"
            icon={
              <EventsCalendarIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color25 }}
              />
            }
            routePath={routePaths.staffCalendar}
            routeParams={[year, month]}
            key={getNextKey()}
            {...rest}
          />
        ]}
      />
      <MenuRoute
        text="Dysk wirtualny"
        icon={
          <StorageIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color7 }}
          />
        }
        routePath={routePaths.virtualDisc}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Raporty"
        icon={
          <ReportsIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color5 }}
          />
        }
        routePath={routePaths.reports}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Roczny ramowy plan dnia"
        icon={
          <TimeTable
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
          />
        }
        routePath={routePaths.electronicDiaryFrameworkDaySchedule}
        key={getNextKey()}
        {...rest}
      />
      <Divider />
      {insurance && insurance.isActive && (
        <ListItem key={getNextKey()} component="li">
          <ListItemButton onClick={() => setInsuranceDialog(true)}>
            <img
              src={themeMode !== themeTypes.dark.value ? insuranceLogoUrl : insuranceLogoWhiteUrl}
              width="70%"
              alt="Ubezpieczenia"
            />
          </ListItemButton>
        </ListItem>
      )}
      <MenuRoute
        text="Blog 4Parents"
        icon={
          <BookIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color6 }}
          />
        }
        key={getNextKey()}
        component="a"
        href="https://4parents.education/blog/"
        target="_blank"
      />
      <MenuRoute
        text="Co nowego?"
        icon={
          <ChangeLogIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color6 }}
          />
        }
        routePath={routePaths.changeLog}
        key={getNextKey()}
        {...rest}
      />
      {insuranceDialog && (
        <InsuranceInfoDialog insurance={insurance} onClose={() => setInsuranceDialog(false)} open={insuranceDialog} />
      )}
    </List>
  );
};

CanteenWorkerMenu.propTypes = {
  firstGroupId: PropTypes.string,
  currentRoute: PropTypes.string.isRequired,
  onRouteSelect: PropTypes.func.isRequired,
  insurance: PropTypes.object
};

export default CanteenWorkerMenu;
