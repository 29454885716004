import { check } from '../validation/validateHelper';
import { GroupDetailsModel } from './GroupModels';
import { BaseModelValidator } from '../BaseModelValidator';

export class GroupDetailsModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = GroupDetailsModel;

    this.constraints = {
      name: (value) => check.isEmpty(value) && 'Grupa musi mieć nazwę'
    };
  }
}
