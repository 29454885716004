import PropTypes from 'prop-types';
import React from 'react';
import MenuRoute from './MenuRoute';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { routePaths } from '../../../routePaths';
import UnreadThreadsBadgeWrapper from '../../common/privateMessages/UnreadThreadsBadgeWrapper';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';

import HomeIcon from '@mui/icons-material/HomeOutlined';
import ChildrenIcon from '@mui/icons-material/FormatListNumberedOutlined';
import AssignmentIndIcon from '@mui/icons-material/PermContactCalendarOutlined';
import MessageBoardIcon from '@mui/icons-material/SmsFailedOutlined';
import EventsCalendarIcon from '@mui/icons-material/Event';
import ReportsIcon from '@mui/icons-material/InsertChartOutlined';
import PrivateMessagesIcon from '@mui/icons-material/EmailOutlined';
import PhotoLibraryIcon from '@mui/icons-material/CollectionsOutlined';
import StorageIcon from '@mui/icons-material/SaveOutlined';
import ManualIcon from '@mui/icons-material/DescriptionOutlined';
import config from '../../../config/config';

const ReducedTeacherMenu = ({ currentRoute, onRouteSelect }) => {
  const year = moment().format('YYYY');
  const month = moment().format('MM');
  let constKey = 0;
  const getNextKey = () => constKey++;
  const rest = { selectedRoutePath: currentRoute, onClick: onRouteSelect };
  const unreadPostsCount = useSelector((state) => state.staffMessageBoard.unreadedPosts).length;
  const theme = useTheme();
  const isContrastColor = useSelector((state) => state.theme);

  return (
    <List>
      <MenuRoute
        text="Pulpit"
        icon={
          <HomeIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
          />
        }
        routePath={routePaths.home}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Wiadomości"
        icon={
          <PrivateMessagesIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color3 }}
          />
        }
        routePath={routePaths.staffMemberPrivateMessages}
        key={getNextKey()}
        badge={<UnreadThreadsBadgeWrapper />}
        {...rest}
      />
      <MenuRoute
        text="Galeria"
        icon={
          <PhotoLibraryIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color6 }}
          />
        }
        routePath={routePaths.gallery}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Ogłoszenia"
        icon={
          <MessageBoardIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color4 }}
          />
        }
        routePath={routePaths.messageBoard}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Kalendarz wydarzeń"
        icon={
          <EventsCalendarIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color5 }}
          />
        }
        routePath={routePaths.eventsCalendar}
        routeParams={[year, month]}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Dysk wirtualny"
        icon={
          <StorageIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color21 }}
          />
        }
        routePath={routePaths.virtualDisc}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Rejestr uczniów"
        icon={
          <ChildrenIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color7 }}
          />
        }
        routePath={routePaths.student}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        primaryText="Wewnętrzne"
        icon={
          <AssignmentIndIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color25 }}
          />
        }
        primaryTogglesNestedList={true}
        badgeValue={unreadPostsCount}
        nestedItems={[
          <MenuRoute
            text="Ogłoszenia"
            icon={
              <MessageBoardIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color25 }}
              />
            }
            routePath={routePaths.staffMessageBoard}
            badgeValue={unreadPostsCount}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Kalendarz"
            icon={
              <EventsCalendarIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color25 }}
              />
            }
            routePath={routePaths.staffCalendar}
            routeParams={[year, month]}
            key={getNextKey()}
            {...rest}
          />
        ]}
      />
      <MenuRoute
        text="Raporty"
        icon={
          <ReportsIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color5 }}
          />
        }
        routePath={routePaths.reports}
        key={getNextKey()}
        {...rest}
      />
      <Divider />
      <ListItem key={getNextKey()}>
        <ListItemButton onClick={() => window.open(config.staffManualReducedUrl, '_blank')}>
          <ListItemIcon>
            <ManualIcon
              fontSize="medium"
              sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
            />
          </ListItemIcon>
          <ListItemText
            sx={{ '& .MuiListItemText-primary': { fontWeight: (theme) => theme.typography.fontWeightBold } }}
            primary="Instrukcja obsługi"
          />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

ReducedTeacherMenu.propTypes = {
  currentRoute: PropTypes.string.isRequired,
  onRouteSelect: PropTypes.func.isRequired
};

export default ReducedTeacherMenu;
