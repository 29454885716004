import { Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';

const UnitSchemeElementForm = ({ selectedScheme, setData, errors, isEditing }) => {
  const [templateName, setTemplateName] = useState(selectedScheme?.templateName || '');
  const [content, setContent] = useState(selectedScheme?.content || '');

  useEffect(() => {
    setData({
      templateName: templateName,
      content: content
    });
  }, [content, setData, templateName, selectedScheme, isEditing]);
  return (
    <Box>
      <FieldsetWrapper legend="Dane atrybutu">
        <TextField
          disabled={!isEditing && !!selectedScheme}
          error={!!errors?.errors?.templateName}
          helperText={errors?.errors?.templateName}
          variant="standard"
          sx={{ my: 2 }}
          label="Nazwa schematu"
          autoFocus={true}
          name="name"
          value={templateName}
          onChange={(e) => {
            if (e.target.value.length < 100) setTemplateName(e.target.value);
          }}
        />
        <TextField
          disabled={!isEditing && !!selectedScheme}
          error={!!errors?.errors?.content}
          helperText={errors?.errors?.content}
          multiline
          minRows="8"
          fullWidth
          variant="outlined"
          sx={{ my: 2 }}
          label="Zawartość"
          autoFocus={true}
          name="content"
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
        />
      </FieldsetWrapper>
    </Box>
  );
};

export default UnitSchemeElementForm;
