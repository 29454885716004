import { check } from '../validation/validateHelper';
import { ActivityDetailsModel } from './ActivityModels';
import { BaseModelValidator } from '../BaseModelValidator';

export class ActivityDetailsModelValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = ActivityDetailsModel;

    this.constraints = {
      name: (value) => check.isEmpty(value) && 'Zajęcia muszą mieć nazwę'
    };
  }
}
