import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import * as childrenActions from '../../actions/childrenActions';
import * as applicationsActions from '../../actions/applicationsActions';
import * as chargingSchemesActions from '../../actions/chargingSchemesActions';
import * as activitiesActions from '../../actions/activitiesActions';
import { Box, Button, Grid } from '@mui/material';
import ChildInfo from '../common/applications/ChildInfo';
import CateringApplications from './CateringApplications';
import ActivitiesApplications from './ActivitiesApplications';
import SelectChildDialog from './SelectChildDialog';
import ManualLink from '../ManualLink';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import ContentWrapper from '../common/contentWrapper';
import FeedIcon from '@mui/icons-material/Feed';

const GuardianApplicationsPage = ({
  children,
  cateringChargingSchemes,
  childApplications,
  activities,
  childrenActions,
  chargingSchemesActions,
  applicationsActions,
  activitiesActions
}) => {
  const [isOpenSelectChildDialog, setIsOpenSelectChildDialog] = useState(true);
  const [selectedChild, setSelectedChild] = useState({});

  useEffect(() => {
    childrenActions.loadLegalGuardiansChildrenAsync();
    chargingSchemesActions.loadCateringChargingSchemesAsync();
    activitiesActions.loadActivitiesAsync();
  }, []);

  const onSelectChild = (newSelectedChild) => {
    setSelectedChild(newSelectedChild);
    refreshChildApplications(newSelectedChild.id);
    setIsOpenSelectChildDialog(false);
  };

  const refreshChildApplications = (childId) => {
    applicationsActions.loadLegalGuardianApplications(childId);
  };

  const isChildSelected = useMemo(() => !_.isEmpty(selectedChild), [selectedChild]);
  const disableChangeChildButton = useMemo(() => isChildSelected && children?.length <= 1, [isChildSelected, children]);

  return (
    <LoadingRenderWrapper>
      <Box>
        <Box flexDirection="row" display="flex" alignItems="center">
          <PageHeaderText title="Wnioski" titleIcon={<FeedIcon />} />
          <ManualLink index="6" />
        </Box>
        <ContentWrapper>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>{isChildSelected && <ChildInfo child={selectedChild} />}</Box>
            {disableChangeChildButton ? null : (
              <Button variant="contained" sx={{ ml: 3 }} onClick={() => setIsOpenSelectChildDialog(true)}>
                {isChildSelected ? 'Zmień dziecko' : 'Wybierz dziecko'}
              </Button>
            )}
          </Box>
          {isChildSelected && (
            <Grid container spacing={3} sx={{ mt: 3 }}>
              <CateringApplications
                childApplications={childApplications.filter((a) => a.schemeId !== null)}
                cateringChargingSchemes={cateringChargingSchemes}
                applicationsActions={applicationsActions}
                refreshChildApplications={() => refreshChildApplications(selectedChild.id)}
                selectedChild={selectedChild}
              />
              <ActivitiesApplications
                childApplications={childApplications.filter((ca) => ca.activityId !== null)}
                selectedChild={selectedChild}
                applicationsActions={applicationsActions}
                refreshChildApplications={() => refreshChildApplications(selectedChild.id)}
                activities={activities}
              />
            </Grid>
          )}
          <SelectChildDialog
            onSelect={onSelectChild}
            open={isOpenSelectChildDialog}
            onCancel={() => setIsOpenSelectChildDialog(false)}>
            {children}
          </SelectChildDialog>
        </ContentWrapper>
      </Box>
    </LoadingRenderWrapper>
  );
};

GuardianApplicationsPage.propTypes = {
  childrenActions: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
  cateringChargingSchemes: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  chargingSchemesActions: PropTypes.object.isRequired,
  childApplications: PropTypes.array.isRequired,
  applicationsActions: PropTypes.object.isRequired,
  activitiesActions: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  children: state.legalGuardiansChildren,
  cateringChargingSchemes: state.legalGuardianApplications.cateringChargingSchemes,
  activities: state.activities,
  childApplications: state.legalGuardianApplications.childApplications
});

const mapDispatchToProps = (dispatch) => ({
  childrenActions: bindActionCreators(childrenActions, dispatch),
  chargingSchemesActions: bindActionCreators(chargingSchemesActions, dispatch),
  applicationsActions: bindActionCreators(applicationsActions, dispatch),
  activitiesActions: bindActionCreators(activitiesActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GuardianApplicationsPage);
