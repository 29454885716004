import ApiBase, { ROOT_URL } from './ApiBase';

class ReportsApi extends ApiBase {
  static getReports() {
    return super.toPromise((agent) => agent.get(`${ROOT_URL}/reports`).type('application/json'));
  }

  static handleArrayParameters(parameters) {
    const result = {};

    Object.keys(parameters).forEach((key) => {
      if (Array.isArray(parameters[key])) {
        result[`${key}[]`] = parameters[key];
      } else {
        result[key] = parameters[key];
      }
    });

    return result;
  }

  static downloadReport(reportType, reportFormat, parameters) {
    return super.toPromise(
      (agent) => {
        const params = ReportsApi.handleArrayParameters(parameters);
        return agent
          .get(`${ROOT_URL}/reports/download`)
          .query({ reportType })
          .query({ reportFormat })
          .query(params)
          .responseType('blob');
      },
      (r) => r.xhr
    );
  }

  static downloadDuesList(duesIds) {
    return super.toPromise(
      (agent) => agent.post(`${ROOT_URL}/reports/download/dues`).send(duesIds).responseType('blob'),
      (r) => r.xhr
    );
  }

  static downloadBalanceFundsPayoutConfirmation(childId, operationDate) {
    return super.toPromise(
      (agent) =>
        agent
          .post(`${ROOT_URL}/reports/download/balanceFundsPayoutConfirmation`)
          .send({ childId, operationDate })
          .responseType('blob'),
      (r) => r.xhr
    );
  }

  static downloadPaidDuesReport(dueId) {
    return super.toPromise(
      (agent) => agent.get(`${ROOT_URL}/reports/dues/paid`).query({ id: dueId }).responseType('blob'),
      (r) => r.xhr
    );
  }

  static downloadGuardianRequest(childId, guardianId) {
    return super.toPromise(
      (agent) =>
        agent.get(`${ROOT_URL}/reports/children/${childId}/guardianRequest/${guardianId}`).responseType('blob'),
      (r) => r.xhr
    );
  }
}

export default ReportsApi;
