import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import DialogForm from '../forms/DialogForm';
import CalculateSettlementsChildrenList from './CalculateSettlementsChildrenList';
import {
  List,
  ListItem,
  Checkbox,
  ListItemIcon,
  ListSubheader,
  Divider,
  ListItemText,
  ListItemButton,
  Switch,
  AlertTitle,
  Alert,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class CalculateSettlementsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calculationParameters: {
        groupIds: [],
        calculateStayDues: true,
        calculateCateringDues: true,
        calculateOtherDues: true,
        calculationActivitiesDue: true,
        calculateMealsDues: true,
        inAdvanceAbsencesCalculation: false,
        withoutPreviousMonthCorrectionCalculation: false,
        calculateCorrectionsWithCurrentChargingScheme: false,
        inArrearsUseDeclaredHoursOnMissingEntries: false,
        checkAbsencesInRowFromPreviousMonth: false,
        addExtraChargeableMinutes: false,
        extraChargeableMinutes: {}
      },
      showAlert: true,
      errors: {}
    };

    this.handleParamsChange = this.handleParamsChange.bind(this);
    this.handleExtraMinutesChange = this.handleExtraMinutesChange.bind(this);
  }

  handleParamsChange(field) {
    const { calculationParameters } = this.state;
    calculationParameters[field] = !calculationParameters[field];
    if (field === 'withoutPreviousMonthCorrectionCalculation' && calculationParameters[field]) {
      calculationParameters.calculateCorrectionsWithCurrentChargingScheme = false;
    }
    return this.setState({ calculationParameters });
  }

  handleExtraMinutesChange(event) {
    const childId = event.target.name;
    const { calculationParameters } = this.state;
    calculationParameters.extraChargeableMinutes[childId] = event.target.value;
    return this.setState({ calculationParameters });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    const { calculationParameters } = this.state;

    const cheboxList = [
      {
        name: 'calculateStayDues',
        checked: calculationParameters.calculateStayDues,
        description: 'Pobyt'
      },
      {
        name: 'calculateCateringDues',
        checked: calculationParameters.calculateCateringDues,
        description: 'Wyżywienie'
      },
      {
        name: 'calculationActivitiesDue',
        checked: calculationParameters.calculationActivitiesDue,
        description: 'Zajęcia dodatkowe'
      },
      {
        name: 'calculateOtherDues',
        checked: calculationParameters.calculateOtherDues,
        description: 'Inne'
      },
      {
        name: 'calculateMealsDues',
        checked: calculationParameters.calculateMealsDues,
        description: 'Posiłki'
      }
    ];

    let switchList = [
      {
        name: 'inAdvanceAbsencesCalculation',
        checked: calculationParameters.inAdvanceAbsencesCalculation,
        primaryText: 'Uwzględniaj zaplanowane nieobecności',
        secondaryText: 'Od opłat naliczanych z góry zostaną odpisane zaplanowane w tym miesiącu nieobecności'
      },
      {
        name: 'inArrearsUseDeclaredHoursOnMissingEntries',
        checked: calculationParameters.inArrearsUseDeclaredHoursOnMissingEntries,
        primaryText: 'Używaj deklarowanych godzin obecności w przypadku braku faktycznych wpisów',
        secondaryText: 'Wpływa tylko na opłaty określone schematem dziennym o sposobie naliczania z dołu'
      },
      {
        name: 'withoutPreviousMonthCorrectionCalculation',
        checked: calculationParameters.withoutPreviousMonthCorrectionCalculation,
        primaryText: 'Nie wyrównuj opłat z poprzedniego miesiąca',
        secondaryText: 'Nie zostaną wyliczone odpisy korygujące rozliczenia z poprzedniego miesiąca'
      },
      {
        name: 'calculateCorrectionsWithCurrentChargingScheme',
        checked: calculationParameters.calculateCorrectionsWithCurrentChargingScheme,
        disabled: calculationParameters.withoutPreviousMonthCorrectionCalculation,
        primaryText: 'Wyrównaj opłaty z poprzedniego miesiąca korzystając z obecnego schematu płatności',
        secondaryText:
          'Odpisy korygujące rozliczenia z poprzedniego miesiąca zostaną obliczone za pomocą aktualnych schematów płatności'
      },
      {
        name: 'checkAbsencesInRowFromPreviousMonth',
        checked: calculationParameters.checkAbsencesInRowFromPreviousMonth,
        primaryText: 'Pobierz ciąg obecności z poprzedniego miesiąca',
        secondaryText: 'Ciąg nieobecności z poprzedniego miesiąca zostanie kontynuowany w obecnym'
      },
      {
        name: 'addExtraChargeableMinutes',
        checked: calculationParameters.addExtraChargeableMinutes,
        primaryText: 'Dolicz dodatkowe minuty płatne',
        secondaryText: 'Pozwala na doliczenie dodatkowych płatnych minut do wartości wyliczonych przez system'
      }
    ];
    // console.log(children);

    // if (children && children.length > 0) {
    //   switchList = [
    //     ...switchList,
    //     {
    //       name: 'addExtraChargeableMinutes',
    //       checked: calculationParameters.addExtraChargeableMinutes,
    //       primaryText: 'Dolicz dodatkowe minuty płatne',
    //       secondaryText: 'Pozwala na doliczenie dodatkowych płatnych minut do wartości wyliczonych przez system'
    //     }
    //   ];
    // }

    return (
      <DialogForm
        maxWidth="md"
        header={`Generowanie opłat - ${this.props.groupName}`}
        onSave={() => onSave(this.state.calculationParameters)}
        onCancel={onCancel}
        saveLabel="Przelicz"
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="settlementsCalculationUi">
        {this.props.individualBankAccounts && this.state.showAlert ? (
          <Alert
            severity="success"
            state={this.state.showAlert ? 'true' : 'false'}
            onClose={() => this.setState({ showAlert: false })}>
            <AlertTitle>Ważna informacja</AlertTitle>W konfiguracji placówki uruchomione są indywidualne numery kont
            bankowych dzieci. Prosimy o upewnienie się, czy dzieci mają wprowadzone indywidualne numery kont bankowych
            dla wybranych rodzajów rozliczeń.W przypadku braku wprowadzonych kont, przelew zostanie wykonany na konto
            bankowe zdefiniowane w schemacie płatności
          </Alert>
        ) : null}
        <List>
          <ListSubheader disableSticky sx={{ color: (theme) => theme.palette.color.contrast, fontSize: 16 }}>
            Wylicz opłaty za
          </ListSubheader>
          {cheboxList.map((item) => (
            <ListItem
              key={item.name}
              role={undefined}
              onClick={() => this.handleParamsChange(item.name)}
              component="li"
              aria-label={`Wybierz ${item.description}`}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': `${item.description}` }}
                  checked={item.checked}
                />
              </ListItemIcon>
              <ListItemText id="pobyt">{item.description}</ListItemText>
            </ListItem>
          ))}
        </List>
        <Divider />

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography sx={{ pl: 2, fontWeight: (theme) => theme.typography.fontWeightMedium }}>
              Sposób naliczania odpisów
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, m: 0 }}>
            <List sx={{ p: 0, m: 0 }}>
              {switchList.map((item) => (
                <ListItem
                  sx={{ py: 0, my: '2px' }}
                  key={item.name}
                  role={undefined}
                  component="li"
                  aria-label={`Wybierz ${item.primaryText}`}>
                  <ListItemButton
                    sx={{ my: 0, py: 0 }}
                    onClick={() => this.handleParamsChange(item.name)}
                    disabled={item.disabled}>
                    <ListItemText
                      id="pobyt"
                      primary={item.primaryText}
                      secondary={item.secondaryText}
                      primaryTypographyProps={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}
                      secondaryTypographyProps={{ color: (theme) => theme.palette.color.contrast }}
                    />
                    <Switch
                      tabIndex={-1}
                      disableRipple
                      disabled={item.disabled}
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.handleParamsChange(item.name);
                      }}
                      inputProps={{ 'aria-labelledby': `${item.primaryText}` }}
                      checked={item.checked}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        {this.state.calculationParameters.addExtraChargeableMinutes ? (
          <CalculateSettlementsChildrenList onChange={this.handleExtraMinutesChange}>
            {this.props.children}
          </CalculateSettlementsChildrenList>
        ) : null}
      </DialogForm>
    );
  }
}

CalculateSettlementsDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  children: PropTypes.array,
  individualBankAccounts: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
  return {
    children: state.children,
    individualBankAccounts: state.configuration.unit.individualBankAccounts
  };
}

export default connect(mapStateToProps)(CalculateSettlementsDialog);
