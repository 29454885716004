import React from 'react';
import ScheduledTransferContainer from './containers/ScheduledTransferContainer';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';

const ScheduledTransferPage = () => {
  return (
    <LoadingRenderWrapper>
      <ScheduledTransferContainer />
    </LoadingRenderWrapper>
  );
};

ScheduledTransferPage.propTypes = {};

export default ScheduledTransferPage;
